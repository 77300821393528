import React, { useState, useEffect, useRef } from 'react';
import selfCheckData from '../../data/selfCheckData';
import { getSelfCheckState } from './selfCheckLogic';

export default function HomeHistoryGraph(props) {
  const { selfCheckInfo } = props;
  const selfCheckResource = selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId);

  // const resultHistoryList = [
  //   {
  //     score: 12,
  //     isAbnormal: false,
  //     date: '2022-07-06',
  //   },
  //   {
  //     score: 14,
  //     isAbnormal: false,
  //     date: '2022-07-07',
  //   },
  //   {
  //     score: 12,
  //     isAbnormal: false,
  //     date: '2022-07-06',
  //   },
  //   {
  //     score: 14,
  //     isAbnormal: false,
  //     date: '2022-07-07',
  //   },
  //   {
  //     score: 0,
  //     isAbnormal: false,
  //     date: '2022-07-06',
  //   },
  //   {
  //     score: 27,
  //     isAbnormal: true,
  //     date: '2022-07-07',
  //   },
  // ];

  const [currentIdx, setCurrentIdx] = useState(selfCheckInfo.resultHistoryList.length - 1);

  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth - scrollRef.current.offsetWidth;
    }
    // console.log(selfCheckInfo.resultHistoryList);
    selfCheckInfo.resultHistoryList.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full flex text-[12px] leading-[18px] relative">
      <div
        className=" w-[10px] h-[140px] absolute right-[20px] z-30 translate-y-[1px]"
        style={{ background: `linear-gradient(to left, white, rgba(255,255,255,0))` }}
      />
      <div
        className="w-[10px] h-[140px] absolute left-0 top-0 z-30 translate-y-[1px]"
        style={{ background: `linear-gradient(to right, white, rgba(255,255,255,0))` }}
      />
      <div
        className="w-[10px] h-[40px] absolute right-[20px] z-30 translate-y-[143px]"
        style={{ background: `linear-gradient(to left, white, rgba(255,255,255,0))` }}
      />
      <div
        className="w-[10px] h-[40px] absolute  left-[0px] top-0 z-30 translate-y-[143px]"
        style={{ background: `linear-gradient(to right, white, rgba(255,255,255,0))` }}
      />
      <div className=" w-[calc(100%-20px)] h-[142px] absolute border-y-[1px] border-b-[#E1E4EB] border-t-[rgba(225,228,235,0.3)] " />
      <div className="h-full flex-1 w-full overflow-x-auto scrollbar-hide relative " ref={scrollRef}>
        <div className="w-full min-w-full h-[142px] relative">
          <div className="h-full py-[10px] translate-y-[7px] ">
            <div className="w-full h-full relative ">
              <div className="min-w-full h-full absolute  flex justify-end  ">
                <div className="flex w-fit mx-auto h-full ">
                  {selfCheckInfo.resultHistoryList.map((history, index) => (
                    <div
                      key={`score${index}`}
                      className="h-full  relative w-[60px] min-w-[60px] "
                      onClick={() => {
                        setCurrentIdx(index);
                      }}
                    >
                      <div
                        className={`z-20 w-[14px] h-[14px] rounded-[12px] absolute left-[50%] translate-x-[-50%]  border-[2px] border-white
                    ${
                      index === currentIdx
                        ? selfCheckInfo.resultHistoryList[index].isAbnormal
                          ? 'bg-[#EF3E3E]'
                          : 'bg-[#2C4BEC]'
                        : 'bg-[#D1D5DC]'
                    }`}
                        style={{
                          bottom: `${(history.score / selfCheckResource.maxScore) * 100}%`,
                        }}
                      />
                      {index === currentIdx && (
                        <div
                          className={`z-30  absolute left-[50%] translate-x-[-50%]  ${
                            (history.score / selfCheckResource.maxScore) * 100 >= 66
                              ? 'translate-y-[4px]'
                              : 'rotate-180 translate-y-[-18px]'
                          }`}
                          style={{
                            bottom:
                              (history.score / selfCheckResource.maxScore) * 100 < 66 &&
                              `${(history.score / selfCheckResource.maxScore) * 100}%`,
                            top:
                              (history.score / selfCheckResource.maxScore) * 100 >= 66 &&
                              `${100 - (history.score / selfCheckResource.maxScore) * 100}%`,
                          }}
                        >
                          <div
                            className={`w-full h-full relative ${
                              (history.score / selfCheckResource.maxScore) * 100 >= 66 ? '' : 'rotate-180 '
                            }`}
                          >
                            <div
                              className={`absolute text-[12px]  left-[50%] translate-x-[-50%] leading-[18px] font-bold text-white
                            ${(history.score / selfCheckResource.maxScore) * 100 >= 66 ? 'top-[6px]' : 'bottom-[7px]'}`}
                            >
                              {history.score}
                            </div>
                          </div>
                          {history.score >= 10 && (
                            <svg
                              width="34"
                              height="27"
                              viewBox="0 0 34 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.5522 0.738559C17.7518 -0.246527 16.2482 -0.246526 15.4478 0.73856L12 4.98197L22 4.98197L18.5522 0.738559Z"
                                fill="#3A3C40"
                              />
                              <rect x="0.5" y="3.98242" width="33" height="23" rx="11.5" fill="#3A3C40" />
                            </svg>
                          )}
                          {history.score < 10 && (
                            <svg
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="28"
                                height="23"
                                rx="11.5"
                                transform="matrix(1 0 0 -1 0 27.1543)"
                                fill="#3A3C40"
                              />
                              <path
                                d="M15.5522 0.910434C14.7518 -0.0746518 13.2482 -0.0746514 12.4478 0.910435L9 5.15385L19 5.15384L15.5522 0.910434Z"
                                fill="#3A3C40"
                              />
                            </svg>
                          )}
                        </div>
                      )}

                      <div
                        className={`z-0 w-[2px] h-[142px] rounded-[1px] absolute left-[50%] translate-x-[-50%] translate-y-[-17px]
                    ${
                      index === currentIdx
                        ? selfCheckInfo.resultHistoryList[index].isAbnormal
                          ? 'bg-[#EF3E3E]'
                          : 'bg-[#2C4BEC]'
                        : ''
                    }`}
                      />
                      <div
                        className={`text-[#80838B] absolute left-[50%] translate-x-[-50%] bottom-[-48px] text-center whitespace-nowrap
                    ${index === currentIdx ? 'font-bold' : 'font-normal'}`}
                      >
                        <p className={`${index === currentIdx ? 'text-[#26282C]' : ''}`}>
                          {selfCheckInfo.resultHistoryList[index].date
                            .split('-')
                            .slice(1)
                            .map((each, index) => parseInt(each))
                            .join('.')}
                        </p>

                        <p
                          className={`${
                            index === currentIdx
                              ? selfCheckInfo.resultHistoryList[index].isAbnormal
                                ? 'text-[#EF3E3E]'
                                : 'text-[#2C4BEC]'
                              : 'opacity-0'
                          }`}
                        >
                          {selfCheckResource.result[getSelfCheckState(selfCheckInfo.selfCheckId, history.score)].label}
                        </p>
                      </div>

                      {index < selfCheckInfo.resultHistoryList.length - 1 && (
                        <div className="z-10 w-full h-full absolute left-[50%] translate-y-[-7px] pointer-events-none">
                          {!(
                            (history.score === 0 && selfCheckInfo.resultHistoryList[index + 1].score === 0) ||
                            (history.score === selfCheckResource.maxScore &&
                              selfCheckInfo.resultHistoryList[index + 1].score === selfCheckResource.maxScore)
                          ) ? (
                            <svg style={{ width: '100%', height: '100%' }} xmlns="http://www.w3.org/2000/svg">
                              <line
                                x1="0"
                                y1={`${100 - (history.score / selfCheckResource.maxScore) * 100}%`}
                                x2="100%"
                                y2={`${
                                  100 -
                                  (selfCheckInfo.resultHistoryList[index + 1].score / selfCheckResource.maxScore) * 100
                                }%`}
                                stroke="#D1D5DC"
                                strokeWidth="2px"
                              />
                            </svg>
                          ) : (
                            <div
                              className={`w-full h-[2px] bg-[#D1D5DC] absolute  
                                ${
                                  history.score === 0 && selfCheckInfo.resultHistoryList[index + 1].score === 0
                                    ? 'bottom-0 translate-y-[1px]'
                                    : 'top-0 translate-y-[-1px]'
                                }`}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[20px] h-full relative">
        <div className="absolute top-0  h-[142px] py-[10px] ">
          <div className="w-full h-full relative">
            <div className="w-[10px]  absolute top-[-8px] right-[-15px] text-[#A4A6B0]">
              {selfCheckResource.maxScore}
            </div>
            <div className="w-[10px]  absolute bottom-[-8px] right-[-15px] text-[#A4A6B0]">
              {selfCheckResource.minScore}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
