/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../context';
import { BottomAction } from '../../stories/page/BottomAction';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import selfCheckData from '../../data/selfCheckData';
import useFetch from '../../hooks/useFetch';

export default function FirstMindScanPreview(props) {
  const context = useContext(AuthContext);
  const { id } = props;

  // eslint-disable-next-line no-unused-vars
  const [selfCheckSubmitResult, selfCheckSubmit] = useFetch({
    url: `/mindscan/submit`,
    requestBody: JSON.stringify(context.selfcheckAnswer[id - 1]),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    document.title = `${
      selfCheckData.find((element) => element.selfCheckId === context.selfCheckOrder[id]).selfCheckName
    } 검사 시작`;
    context.setShowProgressingbar(false);
    context.setCompleted(0);
    if (id > 0) {
      selfCheckSubmit();
    }
  }, []);

  const goNext = useNavigateWithParams(`/firstMindScan/${id}/1`);

  const Element = (
    <div className="w-full pt-[56px] pb-[40px] px-[20px] flex flex-col gap-[10px]">
      <div className="flex flex-col gap-[10px]">
        {[
          { id: 0, title: 'PART 1. 일상', subtitle: '나는 얼마나 행복한 일상을 보내고 있을까요?' },
          { id: 1, title: 'PART 2. 직장', subtitle: '나의 회사 생활은 어떨까요?' },
          { id: 2, title: 'PART 3. 멘탈헬스', subtitle: '내 마음건강 상태는 어떨까요?' },
        ].map((category, index) =>
          parseInt(id / 3) === category.id ? (
            <div className="w-full bg-[#EFF2FF] rounded-[16px] p-[20px]" key={`category${index}`}>
              <div className="flex flex-col gap-[7px] mb-[24px] ">
                <p className="text-[17px] leading-[23px] font-bold text-[#26282C] ">{category.title}</p>
                <p className="text-[17px] leading-[23px]  text-[#6B6D76] ">{category.subtitle}</p>
              </div>
              <div className="flex flex-col gap-[10px]">
                {context.selfCheckOrder.slice(3 * parseInt(id / 3), 3 * parseInt(id / 3) + 3).map((each, index) => (
                  <div
                    className="w-full rounded-[16px] pl-[20px] pr-[15px] py-[20px] bg-white border-[#E1E7FF] border-[1px] flex items-center"
                    key={`category2${index}`}
                  >
                    <div
                      className={`w-[24px] h-[24px] rounded-[50%] ring-[1px] ring-inset text-[14px] leading-[20px] font-semibold ${
                        index === id % 3
                          ? 'bg-[#2C4BEC] ring-[#373EED] text-white'
                          : index < id % 3
                          ? 'ring-[#2C4BEC] '
                          : 'ring-[#D1D5DC] text-[#A4A6B0]'
                      }  flex justify-center items-center  z-10 shrink-0 mr-[10px] relative overflow-hidden transition-all`}
                    >
                      {index < id % 3 ? (
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1.52198 2.76914C1.22455 2.48086 0.749731 2.48828 0.461449 2.78571C0.173168 3.08315 0.180587 3.55796 0.47802 3.84624L1.52198 2.76914ZM4.80952 7L4.28754 7.53855C4.57843 7.82048 5.04062 7.82048 5.3315 7.53855L4.80952 7ZM11.522 1.53855C11.8194 1.25027 11.8268 0.775453 11.5386 0.47802C11.2503 0.180587 10.7755 0.173168 10.478 0.461449L11.522 1.53855ZM0.47802 3.84624L4.28754 7.53855L5.3315 6.46145L1.52198 2.76914L0.47802 3.84624ZM5.3315 7.53855L11.522 1.53855L10.478 0.461449L4.28754 6.46145L5.3315 7.53855Z"
                            fill="#2C4BEC"
                          />
                        </svg>
                      ) : (
                        index + 1
                      )}
                    </div>
                    <div className="flex-1 flex flex-col gap-[3px] ">
                      <p className="text-[16px] leading-[22px] font-bold text-[#26282C] ">
                        {`${selfCheckData.find((element) => element.selfCheckId === each).selfCheckName} (${
                          selfCheckData.find((element) => element.selfCheckId === each).numberOfQuestion
                        }문항)`}
                      </p>
                      <p className="text-[14px] leading-[20px] text-[#6B6D76] ">
                        {`${selfCheckData.find((element) => element.selfCheckId === each).guide}`}
                      </p>
                    </div>
                    <div className="w-[47px] shrink-0">
                      <img
                        src={selfCheckData.find((element) => element.selfCheckId === each).image_card}
                        alt={'empty'}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div
              className="w-full bg-[#F3F5F8] rounded-[16px] p-[20px] flex items-center text-[17px] leading-[23px] font-bold text-[#26282C]"
              key={`category3${index}`}
            >
              {category.title}
            </div>
          ),
        )}
      </div>
    </div>
  );
  return <BottomAction content={Element} buttonText={'다음'} buttonState={'ACTIVE'} action={goNext} />;
}
