import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from 'recoil/common/device';
import headerAtom, { BUTTON_NONE } from 'recoil/common/header/atom';
import { AnxyActionButton } from 'stories/button/ActionButton';
import { FullPageAnxy } from 'stories/page/FullPageAnxy';

export default function AnxyNotReady() {
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  const [headerState, setHeaderState] = useRecoilState(headerAtom);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
  }, []);

  return (
    <FullPageAnxy
      content={
        <div className={`${marginClassName} m-auto flex flex-col justify-between items-center h-full`}>
          <div className="m-auto w-full text-black h-full flex flex-col items-center justify-center my-auto px-[20px]">
            <svg width="156" height="150" viewBox="0 0 156 150" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="75" cy="75" r="75" fill="white" />
              <path
                d="M57.0742 103.352C61.1216 110.388 67.3783 114.902 74.4 114.902C81.4217 114.902 87.6784 110.388 91.7258 103.352H57.0742Z"
                fill="#803F3F"
                stroke="#803F3F"
                strokeWidth="2.1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M64.692 109.755C67.3695 112.129 70.7392 113.543 74.4 113.543C78.0608 113.543 81.4305 112.129 84.1079 109.755C85.3785 108.629 84.4453 106.749 82.7474 106.749H66.0526C64.3546 106.749 63.4215 108.629 64.692 109.755Z"
                fill="#AA6565"
              />
              <ellipse cx="58.0311" cy="74.993" rx="6.32989" ry="10.0223" fill="#181818" />
              <ellipse cx="90.7352" cy="74.993" rx="6.32989" ry="10.0223" fill="#181818" />
              <path
                d="M75.7007 64.6863L80.9616 88.6354C81.0532 89.0522 80.9496 89.4881 80.6804 89.8191L75.4196 96.2874C74.8593 96.9763 73.8076 96.9763 73.2473 96.2874L67.9865 89.8191C67.7173 89.4881 67.6137 89.0522 67.7053 88.6354L72.9659 64.6863C73.288 63.2201 75.3787 63.2201 75.7007 64.6863Z"
                fill="#CB6932"
              />
              <path
                d="M47.4814 54.4463C55.2219 54.4463 63.197 50.3509 68.3915 47.03C71.8188 44.8389 76.4472 44.954 79.8354 47.2051C84.7841 50.493 92.4814 54.4463 100.884 54.4463"
                stroke="#181818"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <g filter="url(#filter0_i_3492_71708)">
                <path
                  d="M156 59.4082C156 68.6461 148.413 76.1349 139.054 76.1349C129.695 76.1349 122.108 68.6461 122.108 59.4082C122.108 50.1704 139.054 23.8105 139.054 23.8105C139.054 23.8105 156 50.1704 156 59.4082Z"
                  fill="#1CADFF"
                />
              </g>
              <g filter="url(#filter1_i_3492_71708)">
                <path
                  d="M130.432 22.4169C130.432 27.4558 126.306 31.5405 121.216 31.5405C116.126 31.5405 112 27.4558 112 22.4169C112 17.3781 121.216 3 121.216 3C121.216 3 130.432 17.3781 130.432 22.4169Z"
                  fill="#1CADFF"
                />
              </g>
              <defs>
                <filter
                  id="filter0_i_3492_71708"
                  x="122.108"
                  y="23.8105"
                  width="33.8916"
                  height="52.3242"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="-10" dy="-10" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3492_71708" />
                </filter>
                <filter
                  id="filter1_i_3492_71708"
                  x="112"
                  y="3"
                  width="18.4326"
                  height="28.541"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="-10" dy="-10" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3492_71708" />
                </filter>
              </defs>
            </svg>

            <p className="text-[#26282C] text-[24px] leading-[30px] font-bold pt-[30px]">아직 준비 중인 기능이에요</p>
            <p className="text-[17px] leading-[23px] text-[#6B6D76] text-center pt-[8px]">
              죄송해요. 열심히 만들고 있으니 조금만 기다려주세요. 문의사항은 고객센터에 남겨주세요.
            </p>
          </div>
          <div className="w-full px-[20px]">
            <AnxyActionButton text="확인" buttonState="ACTIVE" round action={() => navigate(-1)} />
          </div>
        </div>
      }
      bgColor="#F3F5F8"
    />
  );
}
