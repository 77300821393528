import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { hasTouch } from 'detect-touch';

export function MultipleSelectButton(props) {
  const { selected, option, text, isClicked, normalCheck } = props;
  const [buttonDown, setButtonDown] = useState(false);

  function mouseUp(e) {
    setButtonDown(false);
  }

  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  return (
    <motion.div animate={{ scale: buttonDown ? 0.95 : 1 }} transition={{ duration: 0.2 }}>
      <div
        className={`w-full 
   py-[13px] px-[20px] rounded-[12px] ring-[1px] ring-inset mb-[10px] flex items-center 
   ${
     selected ? 'ring-[#3953D9] font-semibold text-[#3953D9] bg-[#F5F6FD]' : 'ring-[#D1D5DC] font-normal text-[#26282C]'
   }`}
        onMouseDown={(e) => {
          if (!hasTouch) {
            setButtonDown(true);
          }
        }}
        onClick={(e) => {
          if (!hasTouch) {
            isClicked(option);
          }
        }}
        onTouchStart={(e) => {
          setButtonDown(true);
        }}
        onTouchEnd={(e) => {
          setButtonDown(false);
          if (
            document
              .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
              .includes(e.currentTarget)
          ) {
            isClicked(option);
          }
        }}
      >
        {normalCheck ? (
          <div className={`h-[24px] w-[24px] min-w-[24px] rounded-[12px] mr-[10px]`}>
            <div className="w-full h-full flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                <path
                  d="M1.51435 3.64647C1.21289 3.3624 0.738226 3.3765 0.454158 3.67796C0.17009 3.97942 0.184189 4.45408 0.485649 4.73815L1.51435 3.64647ZM5.57143 8.5L5.05708 9.04584C5.34595 9.31805 5.7969 9.31805 6.08578 9.04584L5.57143 8.5ZM13.5144 2.04584C13.8158 1.76177 13.8299 1.28711 13.5458 0.985649C13.2618 0.684189 12.7871 0.67009 12.4856 0.954158L13.5144 2.04584ZM0.485649 4.73815L5.05708 9.04584L6.08578 7.95416L1.51435 3.64647L0.485649 4.73815ZM6.08578 9.04584L13.5144 2.04584L12.4856 0.954158L5.05708 7.95416L6.08578 9.04584Z"
                  fill={selected ? '#2C4BEC' : '#A4A6B0'}
                />
              </svg>
            </div>
          </div>
        ) : (
          <div
            className={`h-[24px] w-[24px] min-w-[24px] rounded-[12px] mr-[10px]  ${
              selected ? 'bg-qna ' : 'bg-[#E1E4EB] '
            }`}
          >
            <div className="w-full h-full flex justify-center items-center">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.52198 2.76914C1.22455 2.48086 0.749731 2.48828 0.461449 2.78571C0.173168 3.08315 0.180587 3.55796 0.47802 3.84624L1.52198 2.76914ZM4.80952 7L4.28754 7.53855C4.57843 7.82048 5.04062 7.82048 5.3315 7.53855L4.80952 7ZM11.522 1.53855C11.8194 1.25027 11.8268 0.775453 11.5386 0.47802C11.2503 0.180587 10.7755 0.173168 10.478 0.461449L11.522 1.53855ZM0.47802 3.84624L4.28754 7.53855L5.3315 6.46145L1.52198 2.76914L0.47802 3.84624ZM5.3315 7.53855L11.522 1.53855L10.478 0.461449L4.28754 6.46145L5.3315 7.53855Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        )}
        <div className=" select-none text-[17px] leading-[26px] mb-[2px] ">{text}</div>
      </div>
    </motion.div>
  );
}
