import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { hasTouch } from 'detect-touch';
import { useContext } from 'react';
import { CoachingContext } from '../../../coachingContext';
// import { useNavigateWithParam } from '../../../hooks/useWorkbookNavigate';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';

export default function QuizSelectButton({ textList, answer }) {
  const { id, page } = useParams();
  const [clickedId, setClickedId] = useState();
  const [isPressed, setIsPressed] = useState(false);
  const [select, setSelect] = useState('');
  const [myAnswer, setMyAnswer] = useState('');
  const context = useContext(CoachingContext);
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  function mouseUp(e) {
    setIsPressed(false);
    setMyAnswer(select);
  }

  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  useEffect(() => {
    if (!hasTouch) {
      if (myAnswer === answer) {
        goNext();
      } else {
        if (context.showWorkbookToast === false) {
          context.setShowWorkbookToast(true);
        }
      }
    }
  }, [myAnswer]);

  return (
    <div className="font-normal">
      {textList.map((text, index) => (
        <motion.div
          animate={{ scale: isPressed && clickedId === index ? 0.95 : 1 }}
          transition={{ duration: 0.2 }}
          className={`text-left px-20px py-12px w-full rounded-[50px] text-[17px] leading-[26px] text-center ${
            index !== textList.length - 1 && 'mb-10px'
          } text-[#26282C]
          ${myAnswer === text && text === answer && 'text-white bg-[#F5F6FD]'}}

          ${myAnswer === text && text !== answer && 'text-[#E32940] bg-[#E32940]'}}
          `}
          style={{
            border:
              myAnswer === text && text !== answer
                ? '1px solid #E32940'
                : myAnswer === text && text === answer
                ? '1px solid #3953D9'
                : '1px solid #D1D5DC',

            background: myAnswer === text && text === answer && 'linear-gradient(272.73deg, #5513F0 0%, #2C4BEC 100%)',
          }}
          onMouseDown={(e) => {
            if (!hasTouch) {
              setClickedId(index);
              setIsPressed(true);
              setSelect(e.currentTarget.textContent);
            }
          }}
          onTouchStart={(e) => {
            setClickedId(index);
            setIsPressed(true);
          }}
          onTouchEnd={(e) => {
            setIsPressed(false);

            if (
              document
                .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
                .includes(e.currentTarget)
            ) {
              setMyAnswer(e.currentTarget.textContent);
              if (e.currentTarget.textContent === answer) {
                goNext();
                context.setShowWorkbookToast(false);
              } else {
                if (context.showWorkbookToast === false) {
                  context.setShowWorkbookToast(true);
                }
              }
            }
          }}
        >
          <p className="mt-[1px] mb-[1px]">{text}</p>
        </motion.div>
      ))}
    </div>
  );
}
