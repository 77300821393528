import React, { useState, useEffect, useContext, useRef } from 'react';
import { motion } from 'framer-motion';
import lottie from 'lottie-web';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { CoachingContext } from '../../coachingContext';
import webviewToast from '../../development/webviewToast';
import { CheckLottie } from '../../stories/animation/CheckLottie';
import { impactFeedback, keepScreenEvent } from '../../utils/webview';
import axios from '../../utils/axios2';
import { useGetWorkbookParams } from '../../hooks/useGetWorkbookParams';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import { useRecoilState } from 'recoil';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

function BreathTool() {
  const [authToken, appId] = useGetWorkbookParams();
  const [selectMinute, setSelectMinute] = useState(1);
  const [vw, setVw] = useState(window.innerWidth);
  const [isLoad, setIsLoad] = useState(false);

  const [breathState, setBreathState] = useState('init');
  const [breathText, setBreathText] = useState('들이쉬세요');
  const [breathStart, setBreathStart] = useState(false);
  const [breathEnd, setBreathEnd] = useState(false);

  const [countDownStart, setCountDownStart] = useState(false);
  const [countDownNumber, setCountDownNumber] = useState(3);
  const [minutes, setMinutes] = useState(selectMinute);
  const [seconds, setSeconds] = useState(0);
  const [headerState, setHeaderState] = useRecoilState(headerAtom);

  const context = useContext(CoachingContext);
  const container = useRef();

  // 타이머
  const time = useRef(minutes * 60 + seconds);
  const timer = useRef(null);

  const goToHome = useNavigateWithParams('../anxyHome');

  // 호흡 state (들숨, 기다리기, 날숨)
  const isInit = breathState === 'init';
  const isIn = breathState === 'in';
  const isOut = breathState === 'out';
  const isHold = breathText === '잠시 멈추고';

  // 타이머 3초
  useEffect(() => {
    if (countDownStart) {
      setTimeout(() => {
        setCountDownNumber(2);
        setTimeout(() => {
          setCountDownNumber(1);
          setTimeout(() => {
            // 카운트다운 3초 뒤 호흡 시작
            keepScreenEvent({ isKeepScreenOn: true });
            setBreathStart(true);
            setBreathState('in');
          }, 1000);
        }, 1000);
      }, 1300);
    }
  }, [countDownStart]);

  // 타이머 설정하는 함수
  const setTimer = () => {
    timer.current = setInterval(() => {
      time.current -= 1;
      setMinutes(parseInt(time.current / 60));
      setSeconds(parseInt(time.current % 60));
    }, 1000);
    return () => clearInterval(timer.current);
  };

  const setFeedback = () => {
    impactFeedback('selection');
  };

  useEffect(() => {
    if (countDownNumber === 1) {
      time.current = 60 * selectMinute;
      setTimer();
    }
  }, [countDownNumber, selectMinute]);

  useEffect(() => {
    if (selectMinute !== minutes) {
      setMinutes(selectMinute);
      if (!countDownStart) {
        setFeedback();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectMinute]);

  // 타이머 종료
  useEffect(() => {
    if (time.current === 0) {
      keepScreenEvent({ isKeepScreenOn: false });
      // 호흡 state 초기화
      setBreathStart(false);
      setBreathEnd(true);
      clearInterval(timer.current);
      // 카운트다운 초기화
      setCountDownStart(false);
      setCountDownNumber(3);
    }
  }, [seconds]);

  const handleResize = () => {
    setVw(window.innerWidth);
  };

  useEffect(() => {
    document.title = '호흡 타이머';
    window.scrollTo(0, 0);

    setTimeout(() => {
      setIsLoad(true);
    }, 800);

    setTimeout(() => {
      const instance = lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
      });
      return () => instance.destroy();
    }, 400);

    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#FFFFFF', headerTitle: '' }));

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const minuteList = [1, 2, 3, 4, 5];

  // 호흡 시작
  const startBreath = () => {
    if (breathEnd) {
      webviewToast('end');
      goToHome();
      // postToolComplete();
    } else {
      setCountDownStart(true);
    }
  };

  useEffect(() => {
    // 들숨
    if (breathState === 'in' && breathStart && !breathEnd) {
      setBreathText('들이쉬세요');
      setFeedback();
      setTimeout(() => {
        // 1초
        setFeedback();
        setTimeout(() => {
          // 2초
          setFeedback();
          setTimeout(() => {
            // 3초
            setFeedback();
            setBreathText('잠시 멈추고');
            setTimeout(() => {
              setBreathText('내쉬세요');
              setBreathState('out');
            }, 2000);
          }, 1000);
        }, 1000);
      }, 1000);
    }

    if (breathState === 'out' && breathStart && !breathEnd) {
      setBreathText('내쉬세요');
      setFeedback();
      setTimeout(() => {
        // 1초
        setFeedback();
        setTimeout(() => {
          // 2초
          setFeedback();
          setTimeout(() => {
            // 3초
            setFeedback();
            setTimeout(() => {
              setTimeout(() => {
                setBreathState('in');
              }, 1500);
            }, 500);
          }, 1000);
        }, 1000);
      }, 1000);
    }
  }, [breathState, breathStart, breathEnd]);

  const postToolComplete = async (index) => {
    try {
      await axios(3, authToken, appId)
        .post(
          `/breath-timer/complete`,
          JSON.stringify({
            timeSec: selectMinute * 60,
          }),
        )
        .then((response) => {
          webviewToast('success: ' + selectMinute + '분');
        });
    } catch (error) {
      webviewToast('error' + error);
    }
  };

  return (
    <div
      className={`w-screen h-screen bg-[#121213] flex flex-col justify-between text-[50px] leading-[59.67px] font-bold relative`}
    >
      <div className="h-full flex flex-col justify-center items-center ">
        {countDownStart && !breathEnd && (
          <motion.div
            className={`opacity-0 w-[100px] h-[85px] text-white absolute top-[49px] flex justify-center items-center`}
            animate={{ y: countDownNumber === 1 ? 10 : 0, opacity: countDownNumber === 1 ? 0 : 1 }}
            transition={{
              duration: 0.2,
              ease: 'easeInOut',
              delay: countDownNumber === 1 ? 1 : countDownStart ? 0.3 : 1,
            }}
          >
            {countDownNumber}
          </motion.div>
        )}
        <motion.div
          className={`opacity-0 w-[100px] h-[44px] text-white absolute top-[61px] flex justify-center items-center text-[24px] leading-[30px]`}
          style={{ whiteSpace: 'nowrap' }}
          animate={{ opacity: countDownNumber === 1 ? 1 : 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut', delay: countDownNumber === 1 ? 1.1 : breathEnd ? 0.1 : 1 }}
        >
          {breathText}
        </motion.div>
        {isInit ? (
          <motion.div
            key={isLoad}
            className={`rounded-[100%] ${
              countDownStart ? 'w-[100px] h-[100px]' : isLoad ? 'w-[100px] h-[100px]' : 'w-[0px] h-[0px] opacity-0'
            }`}
            initial={{
              opacity: 1,
              backgroundColor: '#FFFFF00',
              border: '1px solid #98A3E0',
            }}
            animate={{
              width: breathEnd ? '0px' : isLoad && !countDownStart ? ['100px', '90px', '100px'] : '100px',
              height: breathEnd ? '0px' : isLoad && !countDownStart ? ['100px', '90px', '100px'] : '100px',
            }}
            transition={{
              repeat: isLoad && !countDownStart && Infinity,
              duration: countDownStart ? 0 : isLoad ? 1.4 : 0.5,
              ease: (!isLoad || breathEnd) && 'easeInOut',
            }}
          />
        ) : (
          <motion.div
            className={`rounded-[100%]`}
            initial={{
              width: breathEnd ? '0px' : isOut ? '255px' : '100px',
              height: breathEnd ? '0px' : isOut ? '255px' : '100px',
              backgroundColor: isInit ? '#FFFFFF00' : isIn || (isHold && isOut) ? '#FFFFFF00' : '#2C4BEC',
              border: isInit
                ? '1px solid #98A3E0'
                : isIn || (isHold && isOut)
                ? '1px solid #98A3E0'
                : '1px solid #2C4BEC',
            }}
            animate={{
              opacity: breathEnd ? 0 : 1,
              height: breathEnd ? '0px' : isIn ? '255px' : '100px',
              width: breathEnd ? '0px' : isIn ? '255px' : '100px',
              backgroundColor: isInit ? '#FFFFFF00' : isIn || (isHold && isIn) ? '#2C4BEC' : '#FFFFFF00',
              border: isInit
                ? '1px solid #98A3E0'
                : isIn || (isHold && isIn)
                ? '1px solid #2C4BEC'
                : '1px solid #98A3E0',
            }}
            transition={{
              duration: breathEnd ? 0.5 : isIn ? 4 : isOut ? 4 : 0.7,
              ease: [0.38, 0.11, 0.02, 0.99],
            }}
          />
        )}
        {breathEnd && (
          <motion.div
            className={`w-[242px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[calc(-50%-54px)] flex flex-col items-center text-white opacity-0`}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut', delay: 0.5 }}
          >
            <CheckLottie containerSize="72px" gradient />
            <p className="text-[24px] leading-[30px] mt-[26px]">호흡을 마쳤어요</p>
            <p className="text-[17px] leading-[23px] mt-[7px] text-[#80838B] font-normal">
              기분이 조금 나아지셨으면 좋겠네요.
            </p>
          </motion.div>
        )}
        <motion.div
          className={`opacity-0 text-[20px] leading-[26px] w-[100px] h-[41px] text-white absolute ${
            context.biggerMargin ? 'bottom-[54px]' : 'bottom-[40px]'
          } flex justify-center items-center`}
          animate={{ opacity: countDownStart ? 1 : 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut', delay: breathEnd ? 0.1 : 0.3 }}
        >
          {`${minutes}:${seconds.toString().length === 1 ? `0${seconds}` : seconds}`}
        </motion.div>
      </div>
      <motion.div
        key={breathEnd}
        className={`flex flex-col relative opacity-0 bottom-[-30px]`}
        animate={{
          y: context.biggerMargin ? -64 : -50,
          opacity: countDownStart ? 0 : 1,
          height: countDownStart ? 0 : '',
        }}
        transition={{
          duration: countDownStart ? 0.3 : 0.5,
          ease: 'easeInOut',
          delay: breathEnd ? 0.5 : 0,
        }}
      >
        {!breathEnd && (
          <div className=" mb-[40px] text-white font-bold relative bottom-[54px]">
            <div
              class="abc"
              className="w-full overflow-x-scroll snap-x text-white scrollbar-hide"
              style={{ scrollBehavior: 'smooth' }}
              onTouchMove={(e) => {}}
              onScroll={(e) => {
                if (e.target.scrollLeft > 65) {
                  setSelectMinute(parseInt(e.target.scrollLeft / 65) + 1);
                } else {
                  setSelectMinute(1);
                }
                console.log(e.target.scrollLeft);
              }}
            >
              <div
                className={`h-[60px] text-center flex relative left-[50%] translate-x-[-37.5px]`}
                style={{ width: (vw - 75) / 2 + 375 }}
              >
                <div className="relative w-full left-[50%] translate-x-[-50%]">
                  {minuteList.map((item, index) => (
                    <div
                      className={`snap-center w-[75px] text-center shrink-0 ${
                        selectMinute - 1 === index
                          ? 'opacity-1'
                          : selectMinute - 2 === index || selectMinute === index
                          ? 'opacity-[0.3]'
                          : 'opacity-[0.1]'
                      } `}
                      style={{ display: 'table-cell' }}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <p className="text-[20px] leading-[26px] relative left-[50%] translate-x-[-9px] text-wthie">분</p>
          </div>
        )}

        <div className={`px-[20px] absolute w-full bottom-0`}>
          <PrimaryActionButton state="ACTIVE" text={breathEnd ? '마치기' : '시작하기'} action={startBreath} />
        </div>
      </motion.div>
    </div>
  );
}

export default BreathTool;
