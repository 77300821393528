import React, { useEffect, useState } from 'react';
import ContainerByDeviceAnxy from '../../../components/elements/ContainerByDeviceAnxy';
import { updateNotch } from '../../../utils/webview';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_MORE } from '../../../recoil/common/header/atom';
import { LoadingLottie } from '../../../stories/animation/LoadingLottie';
import SessionCarousel from '../../../components/somny/SessionCarousel';
import somnySessionData from '../../../data/somny/somnySessionData';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import Test from '../../../development/Test';
import somnyChairLottie from '../../../stories/animation/somny_chair.json';
import Lottie from '../../../components/elements/Lottie';
import { PressedEffect } from '../../../stories/button/PressedEffect';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import deviceAtom from '../../../recoil/common/device';

export default function SomnyHomeInReview() {
  const [sessionList, setSessionList] = useState(somnySessionData);

  // 데이터 로드 여부
  const [isLoaded, setIsLoaded] = useState(false);

  const deviceState = useRecoilValue(deviceAtom);
  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  const initializeState = () => {
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_MORE,
      headerTitle: '',
      headerColor: '#FFFFFF',
      progressBar: {
        isProgressBarVisible: false,
      },
    }));

    setProgressBarState({ isProgressBarVisible: false });
  };

  useEffect(() => {
    document.title = 'Somny 홈';
    document.body.style.backgroundColor = '#FFFFFF';

    updateNotch({ isFullScreen: true });

    initializeState();

    setTimeout(() => {
      setIsLoaded(true);
    }, 400);
  }, []);

  const goToTail = useNavigateWithParams('/somnyTail/0');
  const goToRecord = useNavigateWithParams('/somnyRecord/0');

  const SomnyLottie = () => (
    <div className="flex items-center">
      <Lottie lottieData={somnyChairLottie} loop autoplay width="100px" height="100px" />
      <div className="ml-[2px]">
        <PressedEffect
          element={
            <p className="text-white border-1 font-semibold rounded-[16px] text-center p-[5px] mb-[10px]">수면 동화</p>
          }
          action={goToTail}
        />
        <PressedEffect
          element={<p className="text-white border-1 font-semibold rounded-[16px] text-center p-[5px]">수면 기록</p>}
          action={goToRecord}
        />
      </div>
    </div>
  );

  return (
    <ContainerByDeviceAnxy noHeader css={'flex flex-col'} bgColor="#050C26">
      <div
        className="flex-1 flex flex-col"
        style={{
          opacity: isLoaded ? 1 : 0,
        }}
      >
        <div
          className="absolute left-[50%] translate-x-[-50%]"
          style={{
            top: deviceState.notchSize + 30,
          }}
        >
          <Test element={<SomnyLottie />} />
        </div>
        <div className={`flex-1 flex flex-col justify-center py-[50px]`}>
          <SessionCarousel sessionList={sessionList} />
        </div>
      </div>
      {!isLoaded && (
        <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
          <LoadingLottie containerSize="50px" />
        </div>
      )}
    </ContainerByDeviceAnxy>
  );
}
