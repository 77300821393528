import React, { useState, useEffect } from 'react';
import selfCheckData from '../../../data/selfCheckData';
import { useGetWorkbookParams } from '../../../hooks/useGetWorkbookParams';
import axios from '../../../utils/axios2';
import TestResultChart from '../../selfCheck/TestResultChart';

export default function SelfCheckResult(props) {
  const { examName } = props;
  const [result, setResult] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const [authToken, appId, activityKey, isFirst] = useGetWorkbookParams();

  const getResult = async () => {
    try {
      const id = selfCheckData.filter((item) => item.selfCheckName === examName)[0].selfCheckId;
      const response = await axios(3, authToken, appId).get(`/self-check/v2/latest-result/detail?self_check_id=${id}`);
      setResult(response.data.result);
      setIsLoaded(true);
      console.log(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getResult();
  }, []);

  useEffect(() => {
    console.log(result);
    if (result) {
      setIsLoaded(true);
    }
  }, [result]);

  return (
    <div>
      {isLoaded ? (
        <div className="mt-[40px] w-full rounded-[16px] mb-[40px]" style={{ border: '1px solid #E1E4EB' }}>
          <p className="pt-[24px] px-[24px] text-[#EF3E3E] text-[20px] leading-[26px] font-bold">우울</p>
          <div
            className={`pl-[22px] pr-[16px] pb-[30px] w-full  text-[12px] leading-[18px] text-center font-normal text-[#A4A6B0] flex items-start mt-[25px]`}
          >
            <TestResultChart examName={examName} isAbnormal={result.isAbnormal} score={result.score} />
          </div>
        </div>
      ) : (
        <div className="mt-[40px] w-full rounded-[16px] mb-[40px]" style={{ border: '1px solid #E1E4EB' }}>
          <p className="pt-[24px] px-[24px] text-[#EF3E3E] text-[20px] leading-[26px] font-bold">우울</p>
          <div
            className={`pl-[22px] pr-[16px] pb-[30px] w-full  text-[12px] leading-[18px] text-center font-normal text-[#A4A6B0] flex items-start mt-[25px]`}
          >
            <p>이전 검사 내역이 없습니다.</p>
          </div>
        </div>
      )}
    </div>
  );
}
