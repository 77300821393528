import React from 'react';

export function Title24(props) {
  const { text, color, className } = props;

  return (
    <div
      className={`font-bold text-[24px] leading-[30px] text-white ${className}`}
      style={{ wordBreak: 'keep-all', color: color }}
    >
      {text}
    </div>
  );
}

export function SubTitle18(props) {
  const { text, color, className } = props;

  return (
    <div
      className={`font-semibold text-[18px] leading-[23px] text-anxyBlack ${className}`}
      style={{ wordBreak: 'keep-all', color: color }}
    >
      {text}
    </div>
  );
}

export function Text17(props) {
  const { text, color, className } = props;

  return (
    <div className={`text-[17px] leading-[25.5px] text-[#CACABE] ${className}`} style={{ color: color }}>
      {text}
    </div>
  );
}

export function Caption15(props) {
  const { text, color, className } = props;

  return (
    <div className={`text-[15px] leading-[21px] text-[#BED7FF] ${className} z-[40]`} style={{ color: color }}>
      {text}
    </div>
  );
}
