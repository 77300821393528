import { atom } from 'recoil';

const sleepRecordAtom = atom({
  key: 'sleep_record',
  default: {
    inBed: {
      startDateTime: undefined,
      endDateTime: undefined,
    },
    sleep: {
      startDateTime: undefined,
      endDateTime: undefined,
    },
    napTimeMinutes: 0,
    struggleTimeMinutes: 0,
    submittedAt: undefined,
  },
});

export default sleepRecordAtom;
