import React, { useState, useEffect, useRef } from 'react';
import ActivityCard from './ActivityCard';
import activityData from '../../data/activityData';

export default function ActivityList(props) {
  const { courseId, currentDay, totalDays, activityList, isStartable, scrollToResult, scrollTop } = props;
  const [loaded, setLoaded] = useState(false);
  const days = Array.from({ length: totalDays }, () => 0);
  const weeks = ['1주', '2주', '3주', '4주'];
  const [currentWeekIdx, setCurrentWeekIdx] = useState(0);
  const weekHeaderRef = useRef();
  const [forceMove, setForceMove] = useState(false);

  useEffect(() => {
    if (activityList !== undefined) {
      setLoaded(true);
    }
  }, [activityList]);

  useEffect(() => {
    if (forceMove) {
      if (document.getElementById(`day${currentWeekIdx * 7}`)) {
        scrollToResult(
          document.getElementById(`day${currentWeekIdx * 7}`).offsetTop - weekHeaderRef.current.offsetHeight,
        ).then(() => {
          console.log('scrollto 끝남');
          setForceMove(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeekIdx]);

  useEffect(() => {
    if (parseInt(courseId) === 5 && document.getElementById(`day${currentWeekIdx * 7}`) !== null && !forceMove) {
      if (
        currentWeekIdx < 3 &&
        scrollTop >
          document.getElementById(`day${(currentWeekIdx + 1) * 7}`).offsetTop - weekHeaderRef.current.offsetHeight
      ) {
        setCurrentWeekIdx((currentWeekIdx) => currentWeekIdx + 1);
      } else if (
        currentWeekIdx > 0 &&
        scrollTop <
          document.getElementById(`day${currentWeekIdx * 7 - 1}`).offsetTop +
            document.getElementById(`day${currentWeekIdx * 7 - 1}`).offsetHeight -
            weekHeaderRef.current.offsetHeight
      ) {
        setCurrentWeekIdx((currentWeekIdx) => currentWeekIdx - 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop]);

  return (
    loaded && (
      <div>
        {parseInt(courseId) === 5 && (
          <div className="mt-[10px] bg-white flex border-b-[1px] border-[rgba(0,0,0,0.08)] relative sticky top-0 z-10">
            {weeks.map((week, index) => (
              <div
                key={`week${index}`}
                className={`text-[17px] leading-[26px] text-center flex-1 py-[11px] ${
                  currentWeekIdx === index ? 'font-semibold text-[#26282C]' : 'font-normal text-[#A4A6B0]'
                } `}
                ref={weekHeaderRef}
                onClick={() => {
                  setCurrentWeekIdx(index);
                  setForceMove(true);
                }}
              >
                {week}
              </div>
            ))}
            <div
              className="w-[25%] h-[1px] bg-[#26282C] absolute bottom-[-1px] transition-all "
              style={{ left: `${currentWeekIdx * 25}%` }}
            />
          </div>
        )}
        <div className="pt-[20px] pb-[40px]">
          {days.map((day, index) => (
            <div className="w-full  pb-[30px] px-[20px] " id={`day${index}`} key={`day${index}`}>
              <div
                className={`px-[4px] pt-[24px] pb-[12px] mb-[10px]  ${index > 0 && 'border-t-[1px] border-[#F3F5F8]'}`}
              >
                <p className="text-[20px] leading-[24px] font-semibold text-[#26282C]">{index + 1}일차</p>
              </div>
              <div className="flex flex-col gap-[10px]">
                {activityList
                  .filter((item) => item.availableDay === index + 1)
                  .map((activity, index2) => (
                    <ActivityCard
                      key={`activityCard${index2}`}
                      activity={activity}
                      activityData={
                        activityData.find((item) => item.courseId === parseInt(courseId)) &&
                        activityData
                          .find((item) => item.courseId === parseInt(courseId))
                          .activityList.find((item) => item.activityId === activity.activityId)
                      }
                      disable={
                        activity.availableDay > currentDay ||
                        (activity.availableDay === currentDay && isStartable && !activity.isDone)
                      }
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
}
