import { atom } from 'recoil';

const notificationSettingAtom = atom({
  key: 'notification_setting',
  default: {
    worryNoteNumber: 2,
    sessionNumber: 2,
    session: {
      timeList: [],
    },
    worryNote: {
      timeList: [],
    },
  },
});

export default notificationSettingAtom;
