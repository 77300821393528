export default function SwiperContent(props) {
  const { step } = props;
  return (
    <div
      className="pb-20px "
      style={{
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
        WebkitUserModify: 'none',
      }}
    >
      <p className="text-[17px] font-semibold mt-32px mb-4px  leading-[26px]">{step[0]}</p>
      <div className=" text-content text-gray-500 leading-6 mb-20px">{step[1]}</div>
      <div className="w-full rounded-2xl bg-[#E1E4EB] mx-auto mb-5">
        <img className={`object-contain w-full mx-auto`} src={step[2]} alt="empty" />
      </div>
    </div>
  );
}
