import React, { useEffect, useContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CoachingContext } from '../coachingContext';
import axios from '../utils/axios2';
import { changeHeader, close } from '../utils/webview';
import { Title } from '../stories/text/Title';
import { PrimaryActionButton } from '../stories/button/ActionButton';
import { motion } from 'framer-motion';
import webviewToast from '../development/webviewToast';
import corn from '../image/corn.png';

export default function ComingSoon() {
  const context = useContext(CoachingContext);
  const container = useRef();
  const contentRef = useRef();

  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const activitykey = searchParams.get('activity_key');

  useEffect(() => {
    document.title = '준비 중';
    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'close' });
  }, []);

  const postActivityDone = async (index) => {
    try {
      webviewToast('info: ' + activitykey);
      await axios(3, authToken, 'w:123')
        .post(
          `/activity/complete`,
          JSON.stringify({
            activityKey: activitykey,
          }),
        )
        .then((response) => {
          webviewToast('success' + response);
          close({ refresh: true });
        });
    } catch (error) {
      webviewToast('error' + error);
      close({ refresh: true });
    }
  };

  return (
    <div className={`h-screen bg-[#EFF2FF] w-screen overflow-hidden `}>
      <div
        className={`${
          context.biggerMargin ? 'h-[calc(100%-88px)]' : 'h-[calc(100%-74px)]'
        } relative flex flex-col items-center justify-center`}
      >
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3, ease: 'easeIn' }}
          viewport={{ once: true }}
          ref={contentRef}
          className="w-full relative"
        >
          <div className=" w-fit absolute top-[-40px]  left-[50%] translate-x-[-50%]">
            <div className=" max-w-[375px] min-w-[375px]" ref={container} />
          </div>
          <div className="w-full">
            <img src={corn} alt={'empty'} className="" />
          </div>
          <div className="pt-[7px] px-[20px] mb-[8px]" style={{ textAlign: 'center' }}>
            <Title title={['아직 준비 중인 기능이에요']} />
          </div>
          <div className="text-[17px] leading-[23px]  font-normal text-[#80838B] text-center">
            조금만 기다려주세요. <br /> 완성되면 알림으로 알려드릴게요.
          </div>
        </motion.div>
      </div>
      <div className={`px-20px ${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'} w-full rounded-[16px]`}>
        <PrimaryActionButton
          state={'ACTIVE'}
          text={'확인'}
          action={() => {
            if (activitykey) {
              postActivityDone();
            } else {
              close({ refresh: true });
            }
          }}
        />
      </div>
    </div>
  );
}
