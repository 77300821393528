export function SomnyTimeline({ isNight, dayList, nightList }) {
  const fullWidth = parseInt((window.innerWidth - 43) / 2) - 22;

  const Sun = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99725 5.50037C10.2734 5.50037 10.4972 5.72422 10.4972 6.00037V7.15777C10.4972 7.43392 10.2734 7.65777 9.99725 7.65777C9.72111 7.65777 9.49725 7.43392 9.49725 7.15777V6.00037C9.49725 5.72422 9.72111 5.50037 9.99725 5.50037ZM9.99869 8.54614C8.50694 8.54614 7.2687 9.6316 7.03072 11.0558L3.88885 11.0558C3.61271 11.0558 3.38885 11.2796 3.38885 11.5558C3.38885 11.8319 3.61271 12.0558 3.88885 12.0558L16.1111 12.0558C16.3872 12.0558 16.6111 11.8319 16.6111 11.5558C16.6111 11.2796 16.3872 11.0558 16.1111 11.0558L12.9667 11.0558C12.7287 9.6316 11.4905 8.54614 9.99869 8.54614ZM6.42502 7.2734C6.22976 7.07814 5.91318 7.07814 5.71791 7.2734C5.52265 7.46866 5.52265 7.78524 5.71791 7.98051L6.53632 8.79892C6.73159 8.99418 7.04817 8.99418 7.24343 8.79892C7.43869 8.60365 7.43869 8.28707 7.24343 8.09181L6.42502 7.2734ZM14.2796 7.98066C14.4748 7.7854 14.4748 7.46881 14.2796 7.27355C14.0843 7.07829 13.7677 7.07829 13.5725 7.27355L12.7541 8.09196C12.5588 8.28722 12.5588 8.60381 12.7541 8.79907C12.9493 8.99433 13.2659 8.99433 13.4612 8.79907L14.2796 7.98066Z"
        fill="white"
      />
    </svg>
  );

  const Moon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.81807 5.57963C8.67503 6.6105 8.36608 8.33328 9.16667 9.71994C10.0871 11.3143 12.1258 11.8605 13.7201 10.94C14.1885 10.6696 14.5664 10.3026 14.8433 9.87878C14.909 11.502 14.0938 13.1058 12.5893 13.9744C10.4303 15.2209 7.66968 14.4812 6.4232 12.3222C5.17673 10.1633 5.91644 7.40262 8.0754 6.15614C8.62865 5.83673 9.2214 5.64774 9.81807 5.57963Z"
        fill="white"
      />
    </svg>
  );

  const CheckFlag = ({ percentage, isPast, progress }) => (
    <div
      className="absolute top-[2px]"
      style={{
        left: `calc(${fullWidth * percentage * 0.01}px + 20px)`,
      }}
    >
      <div
        className="w-[2px] h-[16px] rounded-[37px] bg-white relative"
        style={{
          opacity: isPast ? 0.3 : 1,
        }}
      />
      <div
        className="absolute mt-[2px] left-0 top-[20px] translate-x-[calc(-50%+1px)] z-[1000]"
        style={{
          opacity: isPast ? 0.3 : 1,
        }}
      >
        <svg className="" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.811 4.18227L9 0L7.18903 4.18227C3.08646 5.02039 0 8.64975 0 13C0 17.9706 4.02944 22 9 22C13.9706 22 18 17.9706 18 13C18 8.64975 14.9135 5.02039 10.811 4.18227Z"
            fill="white"
          />
          <path
            d="M5.5 12.5L8 15.5L12.5 10.5"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );

  return (
    <div className="flex items-center text-white w-full gap-[3px] z-[100]">
      <div className="flex flex-col items-center w-full">
        <p className="text-[13px] leading-[18px] pb-[4px]">기록 타임</p>
        <div
          className="w-full h-[20px] relative"
          style={{
            borderRadius: '20px 0px 0px 20px',
            background: 'rgba(0,0,0,0.3)',
            webkitBackdropFilter: 'blur(8px)',
            backdropFilter: 'blur(8px)',
          }}
        >
          <div
            className="h-[20px] absolute top-0"
            style={{
              width: !isNight ? `calc(${fullWidth * dayList.progressPercentage * 0.01}px + 22px)` : '100%',
              borderRadius: '20px 0px 0px 20px',
              background: 'rgba(255,255,255,0.3)',
            }}
          />
          <Sun />
          {dayList.completedPercentageList.map((item) => (
            <CheckFlag isPast={isNight} percentage={item} progress={dayList.progressPercentage} />
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center w-full">
        <p className="text-[13px] leading-[18px] pb-[4px]">동화 타임</p>
        <div
          className="w-full h-[20px] relative"
          style={{
            borderRadius: '0px 20px 20px 0px',
            background: 'rgba(0,0,0,0.3)',
            webkitBackdropFilter: 'blur(8px)',
            backdropFilter: 'blur(8px)',
          }}
        >
          <div
            className="h-[20px] absolute top-0"
            style={{
              width: isNight && `calc(${fullWidth * nightList.progressPercentage * 0.01}px + 22px)`,
              // borderRadius: '0px 20px 20px 0px',
              background: 'rgba(255,255,255,0.3)',
            }}
          />
          <Moon />
          {nightList.completedPercentageList.map((item) => (
            <CheckFlag isPast={!isNight} percentage={item} progress={nightList.progressPercentage} />
          ))}
        </div>
      </div>
    </div>
  );
}
