import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { changeHeader } from '../utils/webview';
import axios from '../utils/axios2';
import { LoadingLottie } from '../stories/animation/LoadingLottie';
import useMixpanelEvent from '../hooks/useMixpanelEvent';

function MyCoupon() {
  const [couponList, setCouponList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appId = searchParams.get('app_id');

  const setMixPanel = useMixpanelEvent({
    eventName: '상담권',
  });

  const getCouponList = async (token, isBack) => {
    try {
      // alert('authToken defined ' + token);
      console.log('api call');
      await axios(3, token, appId)
        .get(`/coupon/list`)
        .then((response) => {
          const data = response.data.result.couponList;
          setIsLoaded(true);
          setCouponList(data);
        });
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getCouponList(authToken);
    setMixPanel();
  }, []);

  useEffect(() => {
    changeHeader({ backgroundColor: '#FFFFFF', text: '나의 상담권', buttonType: 'close' });
  }, []);

  return (
    <div
      className="w-screen h-screen select-none "
      style={{
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      }}
    >
      {isLoaded ? (
        <div className="px-20px pt-30px w-full h-full overflow-auto scrollbar-hide">
          <p className="text-[20px] leading-[26px] font-bold text-[#26282C] mb-[12px]">{`보유 ${
            couponList && couponList.length
          }장`}</p>
          {couponList && couponList.length === 0 ? (
            <div className="relative top-[50%] translate-y-[calc(-50%-50px)]">
              <div className="flex flex-col items-center justify-center m-auto">
                <div className="w-[50px] h-[50px]">
                  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.5 14C5.5 11.7909 7.29086 10 9.5 10H41.5C43.7091 10 45.5 11.7909 45.5 14V20.8C45.5 20.9105 45.4104 20.9995 45.3 21.0049C43.1838 21.1091 41.5 22.8579 41.5 25C41.5 27.1421 43.1838 28.8909 45.3 28.9951C45.4104 29.0005 45.5 29.0895 45.5 29.2V36C45.5 38.2091 43.7091 40 41.5 40H9.5C7.29086 40 5.5 38.2091 5.5 36V29.2C5.5 29.0895 5.58965 29.0005 5.69997 28.9951C7.81621 28.8909 9.5 27.1421 9.5 25C9.5 22.8579 7.81621 21.1091 5.69997 21.0049C5.58965 20.9995 5.5 20.9105 5.5 20.8V14Z"
                      fill="#2C4BEC"
                    />
                    <path
                      d="M25.024 15.4636C25.1736 15.003 25.8253 15.003 25.975 15.4636L27.8569 21.2554C27.9238 21.4615 28.1158 21.6009 28.3324 21.6009L34.4223 21.6009C34.9066 21.6009 35.108 22.2207 34.7162 22.5054L29.7894 26.085C29.6141 26.2123 29.5408 26.438 29.6077 26.644L31.4896 32.4358C31.6393 32.8964 31.112 33.2795 30.7202 32.9948L25.7934 29.4153C25.6181 29.288 25.3808 29.288 25.2056 29.4153L20.2788 32.9948C19.8869 33.2795 19.3597 32.8964 19.5094 32.4358L21.3912 26.644C21.4582 26.438 21.3848 26.2123 21.2096 26.085L16.2828 22.5054C15.891 22.2207 16.0923 21.6009 16.5767 21.6009L22.6666 21.6009C22.8832 21.6009 23.0751 21.4615 23.1421 21.2554L25.024 15.4636Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <p className="font-bold text-[#26282C] text-[24px] leading-[30px] mt-[30px]">보유한 상담권이 없어요</p>
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col items-center mb-[40px]">
              {couponList &&
                couponList.map((item, index) => (
                  <div
                    className={`relative w-full bg-[#F3F5F8] rounded-[16px] p-[20px] ${
                      index !== couponList.length - 1 && 'mb-[10px]'
                    }`}
                  >
                    <div className="w-full flex items-center justify-between">
                      <p className="text-[#26282C] text-[17px] leading-[23px] font-bold">{item.title}</p>
                      <div
                        className={`${
                          item.remainDayUntilExpiration > 7 ? 'bg-[#6B6D76]' : 'bg-[#EF3E3E]'
                        }  px-[8px] rounded-[7px] text-white h-[20px] text-[12px] leading-[18px] flex items-center`}
                        style={{ fontWeight: 400 }}
                      >
                        <p className="mb-[1px]">{`D-${item.remainDayUntilExpiration}`}</p>
                      </div>
                    </div>
                    <p className="text-[#4E4F53] text-[14px] leading-[20px] mt-[4px]" style={{ fontWeight: 400 }}>
                      {item.subTitle}
                    </p>
                  </div>
                ))}
              <p className="mr-[3.5px] text-[#80838B] text-[14px] leading-[17px] mt-[20px]">
                예약할 때 상담권을 적용해서 결제할 수 있어요.
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <LoadingLottie containerSize="72px" />
        </div>
      )}
    </div>
  );
}

export default MyCoupon;
