import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context';
import { SelectButton } from '../../stories/button/SelectButton';
import { Title } from '../../stories/text/Title';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function SelectTemplate(props) {
  const { id, category, question, options } = props;
  const context = useContext(AuthContext);
  const [disable, setDisable] = useState(false);

  var step = '';
  if (category === 'onboarding1') {
    if (id === 0) {
      step = context.presurvey.work;
    } else if (id === 1) {
      step = context.presurvey.position;
    } else if (id === 2) {
      step = context.presurvey.job;
    } else if (id === 3) {
      step = context.presurvey.hasCounselingExperience;
    } else if (id === 4) {
      step = context.presurvey.hasMedicalTreatmentExperience;
    }
  } else if (category === 'coachMatching') {
    step = context.coachingStyle;
  }

  const [answer, setAnswer] = useState(step);

  const goNext = useNavigateWithParams(`/onboarding1/${id + 1}`);
  const goDoubleNext = useNavigateWithParams(`/onboarding1/${id + 2}`);
  const goSelfCheck = useNavigateWithParams(`/onboardingPreview/1`);
  const goCoachMatching = useNavigateWithParams(`/coachMatching/1`);
  function select(option) {
    console.log(option);
    setAnswer(option);
    if (category === 'onboarding1') {
      if (id === 0) {
        context.presurvey['work'] = option;
      } else if (id === 1) {
        context.presurvey['position'] = option;
      } else if (id === 2) {
        context.presurvey['job'] = option;
      } else if (id === 3) {
        context.presurvey['hasCounselingExperience'] = option;
      } else if (id === 4) {
        context.presurvey['hasMedicalTreatmentExperience'] = option;
      }
      if (id === 1) {
        setTimeout(() => {
          goDoubleNext();
        }, 400);
      } else if (id === 4 && option === '아니요, 없어요') {
        setTimeout(() => {
          goSelfCheck();
        }, 400);
      } else {
        setTimeout(() => {
          goNext();
        }, 400);
      }
    } else if (category === 'coachMatching') {
      context.setCoachingStyle(option);
      setTimeout(() => {
        goCoachMatching();
      }, 400);
    }
  }

  function isClicked(option) {
    if (option !== '') {
      console.log('이미클릭함');
      setDisable(true);
      select(option);
    }
  }

  return (
    <div className={` h-full overflow-scroll scrollbar-hide px-[20px] relative  `}>
      {/* <div className=" w-full h-[7px] sticky top-0 left-0 bg-gradient-to-b from-white to-transparent" /> */}
      <div className="h-full flex flex-col">
        <div className="pt-[7px] ">
          <Title title={question} />
        </div>
        <div className="pt-[43px] pb-[30px] flex-1">
          {options.map((option, index) => (
            <SelectButton
              selected={answer === option}
              option={option}
              text={option}
              isClicked={isClicked} //버튼 컴포넌트에서 알려줌
              disable={disable} // 버튼 컴포넌트로 알려줌
            />
          ))}
        </div>
      </div>
      <div
        className="w-full h-[40px] fixed bottom-0 left-0 "
        style={{ background: `linear-gradient(to top, white, rgba(255,255,255,0))` }}
      />
    </div>
  );
}
