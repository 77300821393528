import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import { LoadingLottie } from '../../../stories/animation/LoadingLottie';
import deviceAtom from '../../../recoil/common/device';
import ContainerByDeviceAnxy from '../../../components/elements/ContainerByDeviceAnxy';
import useLoadImage from '../../../hooks/useLoadImage';
import { somnyHomeImageList } from '../../../data/somny/somnyHomeImageList';
import useImagePreloader from '../../../hooks/useImagePreloader';
import useMixpanelEventSomny from '../../../hooks/useMixpanelEventSomny';
import TaleCarousel from '../../../components/somny/sleepTale/TaleCarousel';
import { somnyFairyTaleData } from '../../../data/somny/somnyFairyTaleData';
import useFetchAnxy from '../../../hooks/useFetchAnxy';

export default function SleepTaleHome() {
  // 데이터 로드 여부
  const { imagesPreloaded } = useImagePreloader(somnyFairyTaleData.map((item) => item.image));
  const [taleList, setTaleList] = useState();
  const [sleepTale, getSleepTale] = useFetchAnxy({
    url: `/somny/sleep-fairy-tale/list`,
  });

  useEffect(() => {
    if (sleepTale) {
      setTaleList(sleepTale.result);
    }
  }, [sleepTale]);

  const [scrollTo, setScrollTo] = useState();

  const setMixPanel = useMixpanelEventSomny({
    eventName: '동화 책장 진입',
  });

  const setHeaderState = useSetRecoilState(headerAtom);

  const preloadImage = useLoadImage(somnyHomeImageList);

  const deviceState = useRecoilValue(deviceAtom);

  const initializeState = () => {
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_BACK,
      headerTitle: '동화 책장',
      headerColor: '#FFFFFF',
    }));
  };

  useEffect(() => {
    document.title = 'Somny 책장';
    initializeState();
    preloadImage();
    getSleepTale();
    setMixPanel();
  }, []);

  return imagesPreloaded && taleList ? (
    <ContainerByDeviceAnxy bgColor="#060727" css={'flex flex-col'}>
      <div className="flex-1 flex flex-col">
        <div className={`flex-1 flex flex-col justify-center pb-[70px]`}>
          <TaleCarousel taleList={taleList} />
        </div>
      </div>
    </ContainerByDeviceAnxy>
  ) : (
    <div className="h-screen w-full bg-[#060727] flex justify-center items-center">
      <LoadingLottie containerSize="50px" />
    </div>
  );
}
