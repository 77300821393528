/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { getConvertedDay, convertMinutesToStringTime } from '../../../logic/method';

export default function BarGraph(props) {
  const { recordByDateList, dummyData, fieldName } = props;
  const scrollRef = useRef();
  const tooltipRef = useRef();
  const barRef = useRef();
  const data = recordByDateList && recordByDateList.length !== 0 ? recordByDateList : dummyData;

  const [selected, setSelected] = useState(data[data.length - 1]);
  const [loaded, setLoaded] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(0);

  const table = [
    { fieldName: 'napTimeMinutes', title: '낮잠 잔 시간', barColor: '#F1B0A2' },
    { fieldName: 'struggleTimeMinutes', title: '중간에 깨서 뒤척인 시간', barColor: '#98D7DF' },
  ];

  const graphInfo = table.find((element) => element.fieldName === fieldName);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
      // console.log(window.innerWidth / 2, barRef.current.offsetWidth);
      if (scrollRef.current) {
        const offset =
          scrollRef.current.offsetWidth / 2 +
          40 +
          barRef.current.offsetWidth * (data.length >= 7 ? 3 : data.length / 2 - 0.5) +
          tooltipRef.current.offsetWidth / 2 -
          (scrollRef.current.offsetWidth + 40);
        if (offset > 0) {
          setTooltipPosition(-offset);
        }
      }
    }, 100);
  }, []);

  const maxMinutes = data
    .map((element) => element[fieldName])
    .sort(function (a, b) {
      return -(a - b);
    })[0];
  const ceiledMaxMinutes =
    maxMinutes % 60 === 0 ? (maxMinutes === 0 ? 60 : maxMinutes) : (parseInt(maxMinutes / 60) + 1) * 60;

  useEffect(() => {
    if (scrollRef.current) {
      // console.log(scrollRef.current.scrollWidth, scrollRef.current.offsetWidth);
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth - scrollRef.current.offsetWidth;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full rounded-[16px] overflow-hidden z-[50]">
      <div className="w-full h-[241px] shrink-0 flex rounded-[16px] bg-[#222455] relative overflow-hidden">
        {recordByDateList && recordByDateList.length === 0 && (
          <div className="absolute mt-[47px] w-full h-full rounded-[16px] z-[40] bg-[#222455E6] pointer-events-none" />
        )}

        <div
          className={`absolute top-[24px] left-[20px] text-[17px] leading-[23px] text-[#F3F5F8] font-bold ${
            !recordByDateList && 'z-30'
          } `}
        >
          {graphInfo.title}
        </div>
        {recordByDateList && recordByDateList.length === 0 && (
          <div className="">
            <div className="absolute top-[50%] translate-y-[calc(-50%+10px)] left-[50%] translate-x-[-50%] w-fit mx-auto z-50 ">
              <p className="text-[15px] leading-[21px] text-[#A4A6B0] whitespace-nowrap">아직 기록된 데이터가 없어요</p>
            </div>
          </div>
        )}
        <div className=" flex-1 min-w-0 h-full shrink-0 relative pl-[20px] ">
          <div
            className=" w-[10px] h-[109px] absolute top-[63px] right-[-1px] z-10 translate-y-[1px]"
            style={{ background: `linear-gradient(to left, #222455, rgb(34, 36, 85))` }}
          />
          <div
            className="w-[10px] h-[109px] absolute  top-[63px] left-[19px] z-10 translate-y-[1px]"
            style={{ background: `linear-gradient(to left, #222455, rgb(34, 36, 85))` }}
          />
          <div
            className="w-[10px] h-[40px] absolute top-[63px] right-[-1px] z-10 translate-y-[113px]"
            style={{ background: `linear-gradient(to left, #222455, rgb(34, 36, 85))` }}
          />
          <div
            className="w-[10px] h-[40px] absolute   top-[63px] left-[19px] z-10 translate-y-[113px]"
            style={{ background: `linear-gradient(to left, #222455, rgb(34, 36, 85))` }}
          />
          <div className="absolute top-[63px] w-[calc(100%-20px)] h-[111px] border-y-[1px] opacity-[0.1]"></div>
          <div
            className={`w-full h-full scrollbar-hide  flex ${data.length < 7 ? 'justify-center' : ''}  pt-[63px] ${
              recordByDateList && recordByDateList.length === 0 ? 'overflow-hidden' : 'overflow-auto'
            }`}
            ref={scrollRef}
            onScroll={(e) => {
              // console.log(e.target.scrollLeft);
            }}
          >
            {data.map((each, index) => (
              <div
                className="w-[calc(100%/7)] min-w-[calc(100%/7)]  h-full relative"
                key={`sleepingRecord${index}`}
                ref={barRef}
                onClick={(e) => {
                  setSelected(each);
                  setTimeout(() => {
                    if (
                      e.target.getBoundingClientRect().left +
                        e.target.offsetWidth / 2 +
                        tooltipRef.current.offsetWidth / 2 >=
                      scrollRef.current.offsetWidth + 40
                    ) {
                      console.log('오른쪽 넘어가');
                      setTooltipPosition(
                        -(
                          e.target.getBoundingClientRect().left +
                          e.target.offsetWidth / 2 +
                          tooltipRef.current.offsetWidth / 2 -
                          (scrollRef.current.offsetWidth + 40)
                        ),
                      );
                    } else if (
                      e.target.getBoundingClientRect().left +
                        e.target.offsetWidth / 2 -
                        tooltipRef.current.offsetWidth / 2 <=
                      40
                    ) {
                      console.log('왼쪽 넘어가');
                      setTooltipPosition(
                        40 -
                          (e.target.getBoundingClientRect().left +
                            e.target.offsetWidth / 2 -
                            tooltipRef.current.offsetWidth / 2),
                      );
                    } else {
                      setTooltipPosition(0);
                    }
                  }, 0);
                }}
              >
                <div className={`w-full h-[110px] pt-[10px]   ${loaded ? 'opacity-100' : 'opacity-0'}`}>
                  <div className="h-full relative  flex justify-center ">
                    {each.date === selected.date && (
                      <div
                        className={`z-10 absolute translate-y-[-36px] left-[50%] translate-x-[-50%] flex flex-col items-center
                         `}
                        style={{
                          top: `calc(${((ceiledMaxMinutes - each[fieldName]) / ceiledMaxMinutes) * 100}%)`,
                        }}
                      >
                        <div
                          className="py-[2px] px-[10px] bg-white rounded-[20px]  text-[#26282C]  pointer-events-none absolute"
                          style={{ transform: `translate(${tooltipPosition}px,0)` }}
                          ref={tooltipRef}
                        >
                          <div className="font-bold whitespace-nowrap">
                            {convertMinutesToStringTime(selected[fieldName])}
                          </div>
                        </div>
                        <div className="absolute translate-y-[20px]">
                          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M6.55223 5.24195C5.75184 6.22703 4.24815 6.22703 3.44777 5.24195L0 0.998535L10 0.998536L6.55223 5.24195Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                    {each.date === selected.date && (
                      <div className="w-[2px] h-[109px] rounded-[1px] translate-y-[-9px] bg-[#A4A6B0] absolute" />
                    )}
                    <div
                      className="w-[14px] rounded-t-[2px]  absolute bottom-0"
                      style={{
                        backgroundColor: graphInfo.barColor,
                        height: ceiledMaxMinutes !== 0 && `${(each[fieldName] / ceiledMaxMinutes) * 100}%`,
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`pt-[8px]  text-center ${
                    each.date === selected.date ? 'font-bold text-white' : 'font-normal text-[#80838B] '
                  }`}
                >
                  {each.date
                    .split('-')
                    .slice(1)
                    .map((each, index) => parseInt(each))
                    .join('.')}
                  <br />
                  {getConvertedDay(each.date, ['어제'])}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="ml-[10px] translate-y-[64px] w-[30px] h-[108px]  flex flex-col justify-between text-[#A4A6B0]">
          {[ceiledMaxMinutes / 60, '0'].map((each, index) => (
            <div key={`axis${index}`}>{each === 0 ? 24 : each}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
