/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import EndProcessing from '../../components/selfCheck/EndProcessing';
import { AuthContext } from '../../context';
import webviewToast from '../../development/webviewToast';

export default function BetterMeAnalysis(props) {
  const { state } = props;
  const context = useContext(AuthContext);
  const [done, setDone] = useState(false);
  const score = getScore();

  useEffect(() => {
    document.title = `betterme 자가검사 로딩`;
    console.log(context.qcAnswerList);
    context.setShowProgressingbar(false);
    context.setMaxCompleted(0);
    context.setCompleted(0);
  }, []);

  function getScore() {
    return arraySum(context.qcAnswerList.map((element) => element.score));
  }

  function arraySum(arr) {
    return arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
  }

  function isDone() {
    setDone(true);
  }

  useEffect(() => {
    if (done) {
      // const score = getScore();
      webviewToast(score);
      if (state === 'diet') {
        if (score >= 27) {
          window.location.href = 'https://inside.im/betterme-2/result-cv';
        } else if (score >= 22) {
          window.location.href = 'https://inside.im/betterme-2/result-mh';
        } else if (score >= 18) {
          window.location.href = 'https://inside.im/betterme-2/result-mb';
        } else {
          window.location.href = 'https://inside.im/betterme-2/result-nt';
        }
      } else if (state === 'disorder') {
        if (score >= 27) {
          window.location.href = 'https://inside.im/betterme-3/result-cv';
        } else if (score >= 22) {
          window.location.href = 'https://inside.im/betterme-3/result-mh';
        } else if (score >= 18) {
          window.location.href = 'https://inside.im/betterme-2/result-mb';
        } else {
          window.location.href = 'https://inside.im/betterme-3/result-nt';
        }
      } else if (state === 'disorder2') {
        if (score >= 27) {
          window.location.href = 'https://inside.im/betterme-4/result-cv';
        } else if (score >= 22) {
          window.location.href = 'https://inside.im/betterme-4/result-mh';
        } else if (score >= 18) {
          window.location.href = 'https://inside.im/betterme-4/result-mb';
        } else {
          window.location.href = 'https://inside.im/betterme-4/result-nt';
        }
      }
    }
  }, [done]);

  return (
    <div
      className={`w-screen h-screen`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <EndProcessing steps={['검진 답변 분석 중', '결과지 구성 중']} isDone={isDone} />
    </div>
  );
}
