import React, { useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context';
import { changeHeader } from '../../utils/webview';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import { motion } from 'framer-motion';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import RecommendedCourseCard from '../../components/course/RecommendedCourseCard';

export default function CourseRecommendation() {
  const context = useContext(AuthContext);

  useEffect(() => {
    document.title = '코스 추천';
    window.document.body.style.backgroundColor = '#EFF2FF';

    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'back' });
  }, []);

  const goFinishOnboarding = useNavigateWithParams(`/finishOnboarding`);
  function goNext() {
    console.log('다음');
    goFinishOnboarding();
  }

  const scrollRef = useRef();

  const Element = (
    <div className="w-full h-full flex pt-[50px]">
      <div className="w-full h-full  max-h-[400px] ">
        <div className="w-full h-full  flex overflow-auto scrollbar-hide pointer-events-none">
          <motion.div
            className={` flex-1  flex`}
            animate={{ x: scrollRef.current && -(scrollRef.current.clientWidth * 2 + 30) }}
            transition={{ duration: 1, ease: 'easeInOut', delay: 0.1 }}
          >
            <div style={{ width: window.innerWidth - 80 > 295 ? (window.innerWidth - 295) / 2 : 40 }} />
            {context.recommendedCourse.map((course, index) => (
              <motion.div
                key={`courseRecommendation${index}`}
                style={{ width: window.innerWidth - 80 > 295 ? 295 : window.innerWidth - 80 }}
                ref={scrollRef}
                animate={{
                  y: index < context.recommendedCourse.length - 1 && 30,
                  opacity: index < context.recommendedCourse.length - 1 && 0,
                }}
                transition={{ duration: 0.2, ease: 'easeInOut', delay: 1.1 }}
                className={`w-full h-full mx-auto ${index < context.recommendedCourse.length - 1 ? 'mr-[15px]' : ''}`}
              >
                <RecommendedCourseCard course={course} hideButton />
              </motion.div>
            ))}
            <div style={{ width: window.innerWidth - 80 > 295 ? (window.innerWidth - 295) / 2 : 40 }} />
          </motion.div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`w-screen h-screen bg-[#EFF2FF] overflow-hidden  relative `}>
      <TopTitleBottomAction
        title={['목표 달성을 위해 가장 먼저하면 좋을 추천 코스예요']}
        content={Element}
        buttonText={'다음'}
        buttonState={'ACTIVE'}
        action={goNext}
        bgColor={'#EFF2FF'}
      />
    </div>
  );
}
