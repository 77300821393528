const defaultState = {
  answerIdList: [],
  haveTakeTime: undefined,
  haveOwnDefinition: undefined,
  wantToAchieveAsCareer: undefined,
  canDoWorkWant: undefined,
  haveOwnWayToOvercomeAdversity: undefined,
  mobileNumber: '',
  worryList: [''],
};

export default defaultState;
