import betterMeSession1 from '../../image/betterme/session/betterMeSession1.png';
import thumbnail_2 from '../../image/betterme/session/thumbnail_2.png';
import thumbnail_3 from '../../image/betterme/session/thumbnail_3.png';
import thumbnail_4 from '../../image/betterme/session/thumbnail_4.png';
import thumbnail_5 from '../../image/betterme/session/thumbnail_5.png';
import thumbnail_6 from '../../image/betterme/session/thumbnail_6.png';
import thumbnail_7 from '../../image/betterme/session/thumbnail_7.png';
import thumbnail_8 from '../../image/betterme/session/thumbnail_8.png';

const betterMeSessionData = [
  {
    sessionId: 'ac',
    eventProperty: 'Intro',
    seq: 1,
    sessionName: 'Intro',
    image: betterMeSession1,
    title: '식이장애 이해하기',
    subtitle: '폭식은 어떻게 유발되고, 우리는 왜 다시 살을 빼려고 시도하는 걸까요?',
    playTime: 1,
    checkPoint: [''],
  },
  {
    sessionId: 'bd',
    eventProperty: '1-1',
    seq: 2,
    sessionName: 'Session 1',
    image: thumbnail_2,
    title: '신체적 배고픔 다스리기',
    subtitle: '나는 무엇 때문에 불안을 느끼는 걸까요? 불안을 만드는 요인을 알아봐요.',
    playTime: 2,
    checkPoint: [''],
  },
  {
    sessionId: 'pk',
    eventProperty: '1-2',
    seq: 3,
    image: thumbnail_3,
    sessionName: 'Session 2',
    title: '나쁜 환경과 규칙 제거하기',
    subtitle: '불안은 어떻게 동작하는 걸까요? 불안을 공략할 수 있는 비법을 알아봐요.',
    playTime: 4,
    checkPoint: [''],
  },
  {
    sessionId: 'dk',
    eventProperty: '2',
    seq: 4,
    sessionName: 'Session 3',
    image: thumbnail_4,
    title: '마인드풀 이팅으로 식사 즐기기',
    subtitle: '몸의 긴장을 이완하는 방법을 배워요. 언제든 불안에 대응할 수 있는 강력한 무기가 될 거예요.',
    playTime: 3,
  },
  {
    sessionId: 'lm',
    eventProperty: '3',
    seq: 5,
    sessionName: 'Session 4',
    image: thumbnail_5,
    title: '스트레스에 대한 회복 탄력성 가지기',
    subtitle: '불안을 유발하는 반복적인 생각 패턴을 찾고 이를 합리적으로 바꿀 방법을 알아봐요.',
    playTime: 3,
  },
  {
    sessionId: 'sd',
    eventProperty: '4',
    seq: 6,
    sessionName: 'Session 5',
    image: thumbnail_6,
    title: '정서적 배고픔 이해하기',
    subtitle: '불안한 마음 기저에 깔린 경직된 믿음을 찾고 이를 유연하고 느슨하게 바꾸어봐요.',
    playTime: 4,
  },
  {
    sessionId: 'tl',
    eventProperty: '5',
    seq: 7,
    sessionName: 'Session 6',
    image: thumbnail_7,
    title: '감정 걸음마 떼기',
    subtitle: '불안한 상황과 생각에 직접 마주하고 경험하며 내가 느끼는 불안을 약화시켜요.',
    playTime: 4,
  },
  {
    sessionId: 'ps',
    eventProperty: 'Outro',
    seq: 8,
    sessionName: 'Outro',
    image: thumbnail_8,
    title: '나에 대한 부정적인 생각 바로잡기',
    subtitle: '악순환을 끊고 이제 불안을 자유자재로 다뤄요. 불안으로부터 완전히 자유로워져요.',
    playTime: 3,
  },
];

export default betterMeSessionData;
