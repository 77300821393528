import React, { useContext, useEffect, useState } from 'react';
import { CoachingContext } from '../../../coachingContext';
import useSessionStorage from '../../../hooks/useSessionStorage';

const defaultValue = {
  hour: 7,
  minute: 0,
};

export default function WakeUpCard() {
  const context = useContext(CoachingContext);
  const [alarmTime, setAlarmTime] = useSessionStorage('alarmTime', defaultValue);
  const [time, setTime] = useState({ hour: 7, minute: 0 });

  useEffect(() => {
    console.log('alarm', alarmTime);
    const updated = time;
    updated.hour = alarmTime.hour;
    updated.minute = alarmTime.minute;
    setTime(updated);
  }, []);

  return (
    <div className={`flex flex-col items-center justify-center h-[127px] my-[40px]`}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.2493 3.00391H15.7493V7.50391H14.2493V3.00391ZM19.4993 15.0039C19.4993 17.4892 17.4846 19.5039 14.9993 19.5039C12.514 19.5039 10.4993 17.4892 10.4993 15.0039C10.4993 12.5186 12.514 10.5039 14.9993 10.5039C17.4846 10.5039 19.4993 12.5186 19.4993 15.0039ZM7.0444 5.9745L5.98374 7.03516L9.16572 10.2171L10.2264 9.15648L7.0444 5.9745ZM26.9993 14.2539V15.7539H22.4993V14.2539H26.9993ZM24.0146 7.06457L22.954 6.00391L19.772 9.18589L20.8326 10.2465L24.0146 7.06457ZM14.2493 22.4945H15.7493V26.9945H14.2493V22.4945ZM20.833 19.7745L19.7723 20.8352L22.9543 24.0171L24.015 22.9565L20.833 19.7745ZM7.49927 14.2539V15.7539H2.99927L2.99927 14.2539H7.49927ZM10.2263 20.8505L9.16567 19.7898L5.98369 22.9718L7.04435 24.0325L10.2263 20.8505Z"
          fill="#FFC700"
        />
      </svg>

      <p className="text-[30px] leading-[36px] font-bold mt-[20px]">{`${time.hour}:${
        time.minute === 0 ? '00' : time.minute
      }`}</p>
    </div>
  );
}
