import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ko';
import { impactFeedback } from '../../../utils/webview';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import sleepRecordAtom from '../../../recoil/somny/sleepRecord/atom';
import deviceAtom, { marginWithHomeIndicator } from '../../../recoil/common/device';
import { SomnyActionButton } from '../../../stories/button/RoundActionButton';
import { DateTime } from 'luxon';
import { getDiffTime } from '../../../logic/method';
import SlideTimePickerWithArea from '../../../stories/time/SlideTimePickerWithArea';
import SlideTimePicker from '../../../stories/time/SlideTimePicker';
import TimePickerInMinute from '../../../stories/time/TimePickerInMinute';

// start: 48시간 전, end: 현재 시각(분은 내림), selected: 이전 기록

export default function TimePointerRecord({
  currentData,
  startTime,
  endTime,
  selectedTime,
  setSelectedTime,
  handleSubmit,
  isAreaPicker,
}) {
  const [sleepingRecord, setSleepingRecord] = useRecoilState(sleepRecordAtom);
  const now = new Date();
  const nowDate = DateTime.now()
    .set({ minute: Math.floor(now.getMinutes() / 10) * 10 })
    .toISO();

  const [dayType, setDayType] = useState('오늘');

  const [prevTime, setPrevTime] = useState();

  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const deviceState = useRecoilValue(deviceAtom);

  const defaultFormat = 'yyyy-MM-dd';

  const caculateDayType = (prevTime, afterTime) => {
    const prevFormatted = DateTime.fromISO(prevTime).toFormat(defaultFormat);
    const afterFormatted = DateTime.fromISO(afterTime).toFormat(defaultFormat);

    const dayDiff = getDiffTime(prevFormatted, afterFormatted).as('days');
    switch (dayDiff) {
      case 0:
        setDayType('오늘');
        break;
      case 1:
        setDayType('어제');
        break;
      case 2:
        setDayType('그저께');
        break;
      default:
        setDayType('오늘');
        break;
    }
  };

  useEffect(() => {
    if (selectedTime) {
      caculateDayType(selectedTime, nowDate);
    }
  }, [selectedTime]);

  useEffect(() => {
    if (sleepingRecord.sleep.startDateTime !== '') {
      setPrevTime(sleepingRecord.sleep.startDateTime);
    }
  }, [sleepingRecord]);

  const sendFeedback = () => {
    impactFeedback('selection');
  };

  const changeSelectedTime = (changedTime) => {
    setSelectedTime(changedTime);
  };

  useEffect(() => {
    sendFeedback();
  }, [selectedTime]);

  const getTimeDiff = (startTime, endTime) => {
    return moment.duration(moment(endTime).diff(startTime));
  };

  const StarBackground = () => (
    <svg width="193" height="142" viewBox="0 0 193 142" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3.53166" cy="140.397" r="1.46765" fill="white" />
      <circle r="1.46765" transform="matrix(-1 0 0 1 189.833 80.6398)" fill="white" />
      <circle cx="190.908" cy="128.583" r="1.95687" fill="white" />
      <circle r="1.46765" transform="matrix(-1 0 0 1 33.4102 46.0753)" fill="white" />
      <ellipse cx="190.857" cy="2.23252" rx="0.733827" ry="0.978435" fill="white" />
      <ellipse rx="0.733827" ry="0.978435" transform="matrix(-1 0 0 1 1.04031 35.0406)" fill="white" />
      <circle cx="138.375" cy="1.06089" r="0.978435" fill="white" />
      <circle r="0.978435" transform="matrix(-1 0 0 1 106.153 20.9793)" fill="white" />
      <circle cx="152.628" cy="39.3344" r="0.585853" fill="white" />
      <circle r="0.585853" transform="matrix(-1 0 0 1 27.3584 90.8893)" fill="white" />
    </svg>
  );

  const selectedAsMinutes = Math.round(getTimeDiff(startTime, selectedTime).asMinutes());
  return (
    <div
      className={`w-full overflow-hidden text-white`}
      style={{
        background: 'linear-gradient(180deg, #05062D 39.58%, #161743 100%)',
        height: `calc(100vh - ${61 + deviceState.notchSize}px)`,
      }}
    >
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="px-[20px] ">
          <div className="flex-none font-bold text-[24px] leading-[30px] pt-[30px]" style={{ wordBreak: 'keep-all' }}>
            {currentData.title}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-[110px] relative">
          <div className="absolute scale-[1.7]">
            <StarBackground />
          </div>
          {!isAreaPicker && (
            <div className="flex flex-col items-center">
              <img className="w-[30px]" src={currentData.icon} alt="empty" />
              <p className="mt-[10px] font-bold text-[40px] leading-[48px] whitespace-nowrap">
                {isAreaPicker
                  ? `${
                      selectedAsMinutes / 60 >= 1 && selectedAsMinutes % 60 !== 0
                        ? `${parseInt(selectedAsMinutes / 60)}시간 ${selectedAsMinutes % 60}분`
                        : selectedAsMinutes / 60 >= 1
                        ? `${parseInt(selectedAsMinutes / 60)}시간`
                        : `${selectedAsMinutes}분`
                    }`
                  : `${dayType} ${moment(selectedTime).format('HH')}:${moment(selectedTime).format('mm')}`}
              </p>
            </div>
          )}
        </div>
        <div className={`${marginClassName} w-full rounded-[16px]`}>
          <div className="relative w-screen">
            {isAreaPicker ? (
              <TimePickerInMinute
                propsName={currentData.type}
                startTime={startTime}
                endTime={endTime}
                changeSelectedTime={changeSelectedTime}
              />
            ) : (
              // <SlideTimePickerWithArea
              //   startTime={startTime}
              //   endTime={endTime}
              //   selectedTime={selectedTime}
              //   changeSelectedTime={changeSelectedTime}
              //   prevTime={prevTime}
              // />
              <SlideTimePicker
                isStart={currentData && currentData.type === 'inBedStart'}
                startTime={startTime}
                endTime={endTime}
                selectedTime={selectedTime}
                changeSelectedTime={changeSelectedTime}
                prevTime={prevTime}
              />
            )}
          </div>
          <div className={`w-full px-[20px] mt-[50px]`}>
            <SomnyActionButton state="ACTIVE" text={'다음'} action={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}
