import React from 'react';
import { ActionButton, AnxyActionButton } from '../button/ActionButton';
import AnxyNotch from '../../components/anxy/AnxyNotch';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import deviceAtom from '../../recoil/common/device';
import { RoundBottomActionWithScroll } from './RoundBottomActionWithScroll';
import { LoomyActionButton, SomnyActionButton } from '../button/RoundActionButton';

export function RoundBottomAction(props) {
  const {
    content,
    buttonText,
    buttonBgColor,
    buttonTextColor,
    buttonState,
    action,
    bgColor,
    noGradient,
    noHeader,
    fixedRoot,
    isAnxy,
    isSomny,
    isLoomy,
  } = props;
  const { isIOS } = useRecoilValue(deviceAtom);
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return noGradient ? (
    <div
      className="h-screen pt-safe"
      style={{
        background: bgColor ? bgColor : '#FFFFFF',
        height: fixedRoot ? 'calc(var(--vh,1vh) * 100)' : '100vh',
        minHeight: fixedRoot ? 'calc(var(--vh.1vh) * 100)' : '100vh',
      }}
    >
      <AnxyNotch notchColor={bgColor || '#ffffff'} noHeader={noHeader} />
      <div className={`h-full ${!noHeader && 'pt-[44px]'} flex flex-col`}>
        <div className={`flex-1 flex flex-col `}>
          <div className="flex-1">{content}</div>
        </div>
        <div className={`flex-none w-full relative `}>
          <div className={`px-[20px]  ${marginClassName} `} style={{ backgroundColor: bgColor || 'white' }}>
            {isAnxy ? (
              <AnxyActionButton
                state={buttonState || 'ACTIVE'}
                text={buttonText}
                action={action}
                bgColor={bgColor}
                round
              />
            ) : isSomny ? (
              <SomnyActionButton state={buttonState || 'ACTIVE'} text={buttonText} action={action} />
            ) : isLoomy ? (
              <LoomyActionButton state={buttonState || 'ACTIVE'} text={buttonText} action={action} />
            ) : (
              <AnxyActionButton
                state={buttonState || 'ACTIVE'}
                text={buttonText}
                action={action}
                bgColor={bgColor}
                round
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <RoundBottomActionWithScroll
      content={content}
      buttonText={buttonText}
      buttonState={buttonState || 'ACTIVE'}
      action={action}
      bgColor={bgColor}
      noHeader={noHeader}
      isAnxy={isAnxy}
      isSomny={isSomny}
      isLoomy={isLoomy}
    />
  );
}
