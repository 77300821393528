import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AnxyActionButton } from 'stories/button/ActionButton';
import PaymentMain from './PaymentMain';
import ContainerByDeviceAnxy from 'components/elements/ContainerByDeviceAnxy';
import anxy_cycle from 'image/anxy/anxy_cycle.png';
import anxy_body from 'image/anxy/anxy_body.png';
import anxy_thought from 'image/anxy/anxy_thought.png';
import anxy_behavior from 'image/anxy/anxy_behavior.png';
import anxy_intro from 'image/anxy/anxy_intro.png';
import anxy_session1 from 'image/anxy/anxy_session1.png';
import anxy_session2 from 'image/anxy/anxy_session2.png';
import anxy_session3 from 'image/anxy/anxy_session3.png';
import anxy_session4 from 'image/anxy/anxy_session4.png';
import anxy_session5 from 'image/anxy/anxy_session5.png';
import anxy_outro from 'image/anxy/anxy_outro.png';
import { updateNotch } from 'utils/webview';
import useMixpanelEventAnxy from 'hooks/useMixpanelEventAnxy';
import { motion } from 'framer-motion';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import deviceAtom from 'recoil/common/device';
import headerAtom, { BUTTON_BACK } from 'recoil/common/header/atom';
import homeTypeAtom from 'recoil/anxy/home/atom';
import PaymentTrialSection from './PaymentTrialSection';
import useRequestPayment from 'hooks/useRequestPayment';

function Title(props) {
  const { text, white, css } = props;
  return (
    <p className={`text-[30px] leading-[36px] font-bold ${white ? 'text-white' : 'text-[#26282C]'} ${css}`}>{text}</p>
  );
}

function Subtitle(props) {
  const { text, white, css } = props;
  return (
    <p className={`text-[24px] leading-[30px] font-bold ${white ? 'text-white' : 'text-[#26282C]'} ${css}`}>{text}</p>
  );
}

function Text(props) {
  const { text, color } = props;
  return (
    <p className="text-[17px] leading-[23px] text-[#D1D5DC]" style={{ color: color }}>
      {text}
    </p>
  );
}

function TitleWithText(props) {
  const { title, text, white, textColor, subtitle, css } = props;
  return (
    <div className={`flex flex-col gap-[12px] ${css}`}>
      {!subtitle ? <Title text={title} white={white} /> : <Subtitle text={title} white={white} />}
      <Text text={text} color={textColor} />
    </div>
  );
}

const Container = forwardRef((props, ref) => {
  const { children, bgColor, css } = props;

  return (
    <div
      ref={ref}
      className={`relative z-[10] w-full py-[60px] px-[20px] flex flex-col justify-center justify-center  text-center ${css}`}
      style={{ backgroundColor: bgColor }}
    >
      {children}
    </div>
  );
});
export default function Payment() {
  const containerRefs = useRef([]);
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('at');
  const appId = searchParams.get('app_id');
  const [appNotch, setAppNotch] = useState(false);

  const [invoiceData, requestPayment] = useRequestPayment({
    fetchInvoiceDetailUrl: `/anxy/payment/invoice/detail`,
    successUrlPath: `payment-success?at=${accessToken}&app_id=${appId}`,
    failUrlPath: `anxyHome?at=${accessToken}&app_id=${appId}`,
  });

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '결제 안내',
  });

  const deviceState = useRecoilValue(deviceAtom);
  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_BACK, headerTitle: '' });
    setMixPanel();

    return updateNotch({ isFullScreen: true });
  }, []);

  useEffect(() => {
    if (!appNotch) {
      updateNotch({ isFullScreen: true });
    } else {
      updateNotch({ isFullScreen: false });

      setTimeout(() => {
        if (invoiceData) {
          requestPayment();
          setTimeout(() => {
            setAppNotch(false);
            updateNotch({ isFullScreen: true });
          }, 1500);
        }
      }, 500);
    }
  }, [appNotch]);

  return (
    <ContainerByDeviceAnxy initialScrollTo={0} noHeader appNotch={appNotch}>
      <motion.div className={'w-full fixed bottom-0 z-[20]'}>
        <div
          className="w-full h-[40px]"
          style={{ background: 'linear-gradient(to top, #FFFFFF, rgba(255,255,255,0))' }}
        />
        <div className={`px-[20px] bg-white ${deviceState.hasHomeIndicator ? 'pb-[34px]' : 'pb-[20px]'} `}>
          <AnxyActionButton
            state={!appNotch ? 'ACTIVE' : 'LOADING'}
            text={'결제하기'}
            action={() => {
              setAppNotch(true);
            }}
          />
        </div>
      </motion.div>
      {/* <PaymentTrialSection /> */}
      <div
        ref={(element) => {
          containerRefs.current[0] = element;
        }}
      >
        <PaymentMain
          setAppNotch={setAppNotch}
          appNotch={appNotch}
          payAmount={invoiceData && invoiceData.result.payAmount}
        />
      </div>

      <Container
        bgColor={'#353535'}
        ref={(element) => {
          containerRefs.current[1] = element;
        }}
      >
        <TitleWithText
          title={'과학에 기반, 임상적 증명'}
          white
          text={
            'Anxy는 과학에 기반하고 임상적으로 증명된 불안 관리 앱이에요. 정신건강 전문가들이 직접 만들었어요. 걱정을 관리하고 불안을 극복하는 방법을 배울 수 있어요.'
          }
          textColor={'#D1D5DC'}
        />
      </Container>
      <Container
        bgColor={'#FDF0E7'}
        ref={(element) => {
          containerRefs.current[2] = element;
        }}
      >
        <Title text={'입증된 개선 효과'} />

        <div className="flex flex-col gap-[40px] py-[60px] px-[20px]">
          {[
            { ratio: 71, text: '불안 증상의 임상적 개선' },
            { ratio: 57, text: '전반적인 기분 개선' },
            { ratio: 47, text: '불면증 개선' },
          ].map((each, index) => (
            <div key={`개선${index}`} className="flex flex-col gap-[8px] items-center">
              <p className="text-[50px] font-bold text-[#EF670B]">{each.ratio}%</p>
              <Text text={each.text} color={'#6B6D76'} />
            </div>
          ))}
        </div>
        <div className="text-[14px] leading-[20px] text-[#26282C] opacity-[0.4]">
          Carl, J. R., Miller, C. B., Henry, A. L., Davis, M. L., Stott, R., Smits, J. A., … & Espie, C. A. (2020).
          Efficacy of digital cognitive behavioral therapy for moderate-to-severe symptoms of generalized anxiety
          disorder: A randomized controlled trial. Depression and Anxiety, 37(12), 1168-1178.
        </div>
      </Container>
      <Container
        ref={(element) => {
          containerRefs.current[3] = element;
        }}
        bgColor={'#FFFFFF'}
      >
        <TitleWithText
          title={'불안 관리의 정석'}
          text={'Anxy는 불안의 본질에 정확히 파고듭니다. 불안의 3요소를 공략해 순환고리를 끊는 것이 코스의 목적입니다.'}
          textColor={'#6B6D76'}
          css={'mb-[40px]'}
        />
        <img src={anxy_cycle} alt={'empty'} className="w-[280px] mx-auto mb-[40px]" />
        <div className="flex flex-col gap-[10px] mb-[21px]">
          {[
            {
              type: '생각',
              text: '“부장님의 말씀을 정확히 메모하지 못했어. 내일 발표에서 엉뚱한 소리를 하게 될 것 같아.”',
            },
            { type: '신체', text: '입이 마르고 몸이 긴장한다. 집중력이 떨어진다.' },
            { type: '행동', text: '불안감을 달래기 위해 밤새 게임을 한다.' },
            { type: '생각', text: '“밤새 게임을 해서 발표 준비를 하나도 못했어. 나는 발표를 정말 망쳐버릴거야.”' },
          ].map((each, index) => (
            <div
              key={`블안관리의정석${index}`}
              className="flex flex-col gap-[10px] items-center w-full p-[20px] bg-[#F3F5F8] rounded-[16px]  text-center "
            >
              <div className="w-fit px-[12px] rounded-[20px] bg-[#26282C] text-[14px] leading-[20px] text-white flex items-center justify-center ">
                {each.type}
              </div>
              <Text text={each.text} color={'#26282C'} />
            </div>
          ))}
        </div>
        <div className="text-[17px] leading-[23px] font-bold text-[#26282C] relative">
          <div
            className="w-full h-[66px] absolute top-[-76px]"
            style={{
              background: 'linear-gradient(to top, #FFFFFF, rgba(255,255,255,0))',
            }}
          />
          {'생각 - 신체 - 행동 악순환의 반복'}
        </div>
      </Container>
      <Container
        bgColor={'#353535'}
        ref={(element) => {
          containerRefs.current[4] = element;
        }}
      >
        <TitleWithText
          title={'세 가지 연습 활동'}
          white
          text={
            "불안의 순환고리를 끊기 위해 3요소를 공략합니다. 신체에서는 '온몸 이완하기', 생각에서는 '생각함정 찾기' 행동에서는 '불안 직면하기' 연습 활동을 진행합니다."
          }
          textColor={'#D1D5DC'}
        />
      </Container>
      {[
        {
          type: '신체',
          typeColor: '#F89146',
          title: '온몸 이완하기',
          text: '점진적 근육 이완법을 통해 긴장된 몸을 푸는 다양한 방법들을 알려드리고 꾸준히 연습할 수 있도록 도와드립니다.',
          image: anxy_body,
          bgColor: '#FEF8E3',
        },
        {
          type: '생각',
          typeColor: '#2E733C',
          title: '생각함정 찾기',
          text: '인지재구성을 통해 불안을 유발하는 생각들에 대해 반박하는 힘을 기릅니다.',
          image: anxy_thought,
          bgColor: '#E1ECE3',
        },
        {
          type: '행동',
          typeColor: '#F0843B',
          title: '불안 직면하기',
          text: '상상 속의 불안을 마주하는 노출 기법을 통해 불안한 상황에 익숙해지도록 도와드립니다.',
          image: anxy_behavior,
          bgColor: '#FDE8DA',
        },
      ].map((each, index) => (
        <Container
          bgColor={each.bgColor}
          key={`세가지연습${index}`}
          ref={(element) => {
            containerRefs.current[5 + index] = element;
          }}
        >
          <div className="text-[20px] leading-[26px] font-bold mb-[10px]" style={{ color: each.typeColor }}>
            {each.type}
          </div>

          <TitleWithText title={each.title} text={each.text} textColor={'#6B6D76'} css={'mb-[40px]'} />

          <img src={each.image} alt={'empty'} className="w-[262px] mx-auto" />
        </Container>
      ))}
      <Container
        bgColor={'#353535'}
        ref={(element) => {
          containerRefs.current[8] = element;
        }}
      >
        <TitleWithText
          title={'매일 1분씩 만나는 이론 학습'}
          white
          text={'약 20개의 세션으로 불안에 대해 알아봐요.'}
          textColor={'#D1D5DC'}
        />
      </Container>
      <Container
        ref={(element) => {
          containerRefs.current[9] = element;
        }}
        bgColor={'#FFFFFF'}
      >
        <TitleWithText
          title={'체계적인 커리큘럼'}
          text={
            '총 4개의 챕터로 나눠 진행해요. 챕터를 하나씩 마칠 때마다 불안 관리 능력이 쑥쑥 자라나는 것을 느끼실 수 있을 거예요.'
          }
          textColor={'#6B6D76'}
          css={'mb-[60px]'}
        />

        <div className="flex flex-col gap-[60px]">
          {[
            {
              id: 'Chapter 1',
              title: '불안 이해하기',
              text: '나는 왜 불안해하는 건지 그 원인을 알아봐요. 불안감이 어떻게 나를 괴롭히는지 작동 원리를 이해하고 이를 공략할 전략을 세워봐요.',
              image: anxy_intro,
            },
            {
              id: 'Chapter 2',
              title: '불안한 몸 달래기',
              text: '불안할 때 나오는 나의 신체 반응에 집중해봐요. 몸의 긴장을 이완하는 방법을 배워요. 불안할 때마다 언제든 쓸 수 있는 강력한 무기가 될 거예요.',
              image: anxy_session2,
            },
            {
              id: 'Chapter 3',
              title: '불안한 생각 덜기',
              text: '불안을 만드는 반복적인 생각 패턴을 찾아봐요. 이를 합리적으로 바꾸는 훈련도 함께해요. 여기까지 잘 해낸다면, 일상에서 불안을 느끼는 일이 확 줄 거예요.',
              image: anxy_session3,
            },
            {
              id: 'Chapter 4',
              title: '불안한 행동 맞서기',
              text: '불안한 생각을 반복적으로 떠올리며 이에 익숙해져요. 또한 불안한 상황에 일부러 나를 노출시켜 보기도 합니다. 생각보다 내가 불안해 하는 대상이 어마무시하지 않다는 것을 스스로 깨닫게 돼요.',
              image: anxy_session5,
            },
            // {
            //   id: 'session 4',
            //   title: '생각의 뿌리 찾기',
            //   text: '불안한 마음 기저에 깔린 경직된 믿음을 찾고 이를 유연하고 느슨하게 바꾸어봐요.',
            //   image: anxy_session4,
            // },
            // {
            //   id: 'session 5',
            //   title: '불안에 맞서기',
            //   text: '불안한 상황과 생각에 직접 마주하고 경험하며 내가 느끼는 불안을 약화시켜요.',
            //   image: anxy_session5,
            // },
            // {
            //   id: 'outro',
            //   title: '내 삶의 주인 되기',
            //   text: '악순환을 끊고 이제 불안을 자유자재로 다뤄요. 불안으로부터 완전히 자유로워져요.',
            //   image: anxy_outro,
            // },
          ].map((each, index) => (
            <div key={`커리큘럼${index}`} className="flex flex-col items-center">
              <div className="w-fit bg-[#26282C] rounded-[20px] px-[12px] text-white text-[14px] leading-[20px] mb-[6px]">
                {each.id}
              </div>

              <TitleWithText title={each.title} text={each.text} textColor={'#6B6D76'} subtitle css={'mb-[20px]'} />
              <img src={each.image} alt={'empty'} className="w-full" />
            </div>
          ))}
        </div>
      </Container>
      <Container
        bgColor={'#353535'}
        ref={(element) => {
          containerRefs.current[10] = element;
        }}
      >
        <Title text={'어떻게 나아질 수 있나요?'} white css={'mb-[32px]'} />
        <div className="flex flex-col gap-[10px]">
          {[
            { title: '맘편히 휴식을 취할 수 있게 돼요', text: '몸의 긴장을 느끼고 이완하여 차분한 마음을 가져요' },
            { title: '걱정을 조절할 수 있게 돼요', text: '특정한 시간과 장소에서만 걱정할 수 있어요.' },
            { title: '도움되지 않는 생각을 안 하게 돼요', text: '비이성적인 생각 함정을 발견하고 다시 구조화해요.' },
            { title: '두려움을 다스릴 수 있게 돼요', text: '두려움을 직면하고 극복할 힘을 얻을 수 있어요.' },
          ].map((each, index) => (
            <div
              key={`나아져${index}`}
              className="flex gap-[10px] items-center w-full p-[20px] bg-[#F3F5F8] rounded-[16px]  "
            >
              <div className="w-[24px] h-[24px] rounded-[50%] bg-[#26282C] text-[15px] leading-[21px] text-white font-bold flex items-center justify-center shrink-0">
                {index + 1}
              </div>
              <div className="flex flex-col gap-[4px] text-[#26282C] text-left">
                <p className="font-bold text-[17px] leading-[23px]">{each.title}</p>
                <p className=" text-[15px] leading-[21px]">{each.text}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
      <div
        ref={(element) => {
          containerRefs.current[11] = element;
        }}
      >
        <PaymentMain end setAppNotch={setAppNotch} payAmount={invoiceData && invoiceData.result.payAmount} />
      </div>
    </ContainerByDeviceAnxy>
  );
}
