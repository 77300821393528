import { useContext, useEffect } from 'react';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { CoachingContext } from '../../../coachingContext';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useParams } from 'react-router-dom';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import MessageBlockWithTail from '../../../stories/workbook/MessageBlockWithTail';
import { useState } from 'react';
import WorryNoteInput from './WorryNoteInput';
import { useRef } from 'react';
import thoughtNoteData from '../../../data/thoughtNoteData';

const defaultValue = {
  incident: '',
  thought: '',
  thoughtTrapIdList: [],
  newThought: '',
};

const NewThoughtInput = () => {
  const [trapInfo, setTrapInfo] = useSessionStorage('trapInfo', defaultValue);
  const { id, page } = useParams();
  const [buttonState, setButtonState] = useState('INACTIVE');

  useEffect(() => {
    console.log(trapInfo);
    if (trapInfo.newThought === '') {
      setButtonState('INACTIVE');
    } else {
      setButtonState('ACTIVE');
    }
  }, [trapInfo]);

  const context = useContext(CoachingContext);
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  const currentRef = useRef();

  return (
    <div className="w-full px-20px h-[calc(100vh-17px)] relative">
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="mt-[50px]">
          <p className="font-bold text-[24px] leading-[30px] text-[#26282C]" style={{ wordBreak: 'keep-all' }}>
            그럼 다시 생각해 볼까요?
          </p>
          <MessageBlockWithTail type="gray" textList={[[trapInfo.thought]]} my />
          <p className="text-[20px] leading-[26px] font-bold mb-[12px]">생각 함정</p>
          <div className="w-full flex mb-[40px]" style={{ gap: '6px', flexWrap: 'wrap' }}>
            {trapInfo.thoughtTrapIdList.map((item) => (
              <div>
                <div className="flex items-center px-[12px] py-[8px] bg-[#EFF2FF] rounded-[20px]">
                  <img
                    src={thoughtNoteData.find((data) => data.id === item).image}
                    alt="empty"
                    className="w-[24px] h-[24px] mr-[6px]"
                  />
                  <p className="font-semibold text-[15px] leading-[21px]">
                    {thoughtNoteData.find((data) => data.id === item).text}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <WorryNoteInput
            index={0}
            title="새로운 생각"
            placeholder="ex) 충분한 근거 없이 넘겨짚어선 안돼, 경험에 근거해서 판단해야 해"
            currentRef={currentRef}
            param={'newThought'}
            data={trapInfo}
            setData={setTrapInfo}
          />
        </div>
        <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px] mt-[40px]`}>
          <SecondaryActionButton state={buttonState} text="다음" action={goNext} />
        </div>
      </div>
    </div>
  );
};

export default NewThoughtInput;
