const emotionButtonData = [
  {
    emotion: '기쁨',
    buttonList: [
      '평안한',
      '기쁜',
      '황홀한',
      '만족한',
      '행복한',
      '충만한',
      '편안한',
      '즐거운',
      ' 환호하는',
      '뿌듯한',
      '대견한',
      '자유로운',
      '상쾌한',
      '재밌는',
      '신나는',
      '기운찬',
      '후련한',
      '흥분되는',
      '기대되는',
      '희망찬',
      '감격한',
    ],
  },
  {
    emotion: '슬픔',
    buttonList: [
      '근심한',
      '슬픈',
      '비통한',
      '서글픈',
      '불행한',
      '좌절한',
      '외로운',
      '처량한',
      '참담한',
      '공허한',
      '우울한',
      '암담한',
      '상처받은',
      '상심한',
      '애통한',
      '그리운',
      '눈물나는',
      '애끓는',
      '후회되는',
      '서운한',
      '막막한',
    ],
  },
  {
    emotion: '불쾌함',
    buttonList: [
      '지루한',
      '불쾌한',
      '증오하는',
      '못마땅한',
      '실망한',
      '경멸하는',
      '거슬리는',
      '반발하는',
      '혐오하는',
      '심드렁한',
      '거북한',
      '구역질 나는',
      '메스꺼운',
      '역겨운',
      '애통한',
      '싫은',
      '따분한',
      '지겨운',
      '어색한',
      '부담스러운',
      '불편한',
    ],
  },
  {
    emotion: '화남',
    buttonList: [
      '짜증나는',
      '화나는',
      '격노한',
      '억울한',
      '원망하는',
      ' 분한',
      '욱하는',
      '열받는',
      '울분한',
      '불만스러운',
      '성질나는',
      '폭발하는',
      '신경질 나는',
      '광분하는',
      '격분한',
      '언짢은',
      '분통터지는',
      '노여운',
    ],
  },
  {
    emotion: '두려움',
    buttonList: [
      '불안한',
      '두려운',
      '공포스러운',
      '긴장한',
      '조급한',
      '압도된',
      '초조한',
      '떨리는',
      '살벌한',
      '겁나는',
      '무서운',
      '섬뜩한',
      '걱정하는',
      '당황한',
      '충격적인',
      '답답한',
      '어이없는',
      '혼란한',
    ],
  },
];

export default emotionButtonData;
