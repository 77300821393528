/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import usePrevious from '../../hooks/usePrevious';
import './gaugeGraph.css';

function TopLayer(props) {
  const { radius, circleRatio } = props;
  return (
    <div className={` relative`} style={{ width: radius, height: radius }}>
      <div className="absolute right-0 flex ">
        <div
          className="origin-bottom-right "
          style={{ transform: `rotate(${180 * circleRatio - 45}deg) translate(0.0px,0) ` }}
        >
          <div id={'remain1'} className={` relative overflow-hidden`} style={{ width: radius, height: radius }}></div>
        </div>

        <div
          className="origin-bottom-left"
          style={{ transform: `rotate(${-180 * circleRatio + 135}deg) translate(-0.0px,0) ` }}
        >
          <div id={'remain'} className={` relative overflow-hidden`} style={{ width: radius, height: radius }} />
        </div>
      </div>
    </div>
  );
}

function CircularSector(props) {
  const { percentage, radius } = props;
  const circularSectorRef = useRef();

  useEffect(() => {
    if (circularSectorRef.current) {
      circularSectorRef.current.style.setProperty('--percentage1', `-${Math.max(90 - percentage, 0)}deg`);
      circularSectorRef.current.style.setProperty('--percentage1-before', `${Math.max(90 - percentage, 0)}deg`);

      circularSectorRef.current.style.setProperty(
        '--percentage2',
        `${percentage > 90 ? `${Math.max(180 - percentage, 0)}deg` : '90deg'}`,
      );
      circularSectorRef.current.style.setProperty(
        '--percentage2-before',
        `-${percentage > 90 ? `${Math.max(180 - percentage, 0)}deg` : '90deg'}`,
      );

      circularSectorRef.current.style.setProperty(
        '--percentage3',
        `${percentage > 180 ? `${Math.max(270 - percentage, 0)}deg` : '90deg'}`,
      );
      circularSectorRef.current.style.setProperty(
        '--percentage3-before',
        `-${percentage > 180 ? `${Math.max(270 - percentage, 0)}deg` : '90deg'}`,
      );

      circularSectorRef.current.style.setProperty(
        '--percentage4',
        `-${percentage > 270 ? `${Math.max(360 - percentage, 0)}deg` : '90deg'}`,
      );
      circularSectorRef.current.style.setProperty(
        '--percentage4-before',
        `${percentage > 270 ? `${Math.max(360 - percentage, 0)}deg` : '90deg'}`,
      );
    }
  }, [circularSectorRef, percentage]);

  return (
    <div ref={circularSectorRef}>
      <div className="flex scale-x-[-1] translate-y-[0.2px] ">
        <div style={{ transform: 'translate(0.2px,0) ' }}>
          <div className="origin-bottom-right " style={{ transform: 'rotate(var(--percentage2))' }}>
            <div
              id={'circularSector2'}
              className={` relative overflow-hidden`}
              style={{ width: radius, height: radius }}
            />
          </div>
        </div>
        <div style={{ transform: 'translate(-0.2px,0) ' }}>
          <div
            id={'circularSector1'}
            className={` relative overflow-hidden`}
            style={{ width: radius, height: radius }}
          />
        </div>
      </div>
      <div className="flex translate-y-[-0.2px]">
        <div style={{ transform: ` translate(0.2px,0)` }}>
          <div className="origin-top-right " style={{ transform: `rotate(var(--percentage4))` }}>
            <div
              id={'circularSector4'}
              className={` relative overflow-hidden`}
              style={{ width: radius, height: radius }}
            />
          </div>
        </div>
        <div style={{ transform: ` translate(-0.2px,0)` }}>
          <div
            id={'circularSector3'}
            className={` relative overflow-hidden`}
            style={{ width: radius, height: radius }}
          />
        </div>
      </div>
    </div>
  );
}

export default function GaugeGraph(props) {
  const { containerSize, strokeWidth, pathBackground, percentage, circleRatio } = props;
  const angle = (Math.atan2(strokeWidth / 2, containerSize / 2 - strokeWidth / 2) * 180) / Math.PI + 0.25;

  //(100,180*(1-circleRatio)) (0,360+2*angle-180*(1-circleRatio))
  // const converted = -2.9 * percentage + 335;
  // const converted = -2.6 * percentage + 320;

  const converted = -((2 * angle + 360 * circleRatio) / 100) * percentage + 180 * (1 + circleRatio) + 2 * angle;

  const start = usePrevious(converted, converted);
  const end = converted;
  const [circularSectorPercentage, setCircularSectorPercentage] = useState(start);
  const percentageRef = useRef(circularSectorPercentage);
  const gaugeRef = useRef();

  useEffect(() => {
    gaugeRef.current.style.setProperty('--radius', `${strokeWidth / 2}px`);
    gaugeRef.current.style.setProperty('--circleRatio', `${90 - (360 * (1 - circleRatio)) / 2}deg`);
    gaugeRef.current.style.setProperty('--circleRatio-reverse', `-${90 - (360 * (1 - circleRatio)) / 2}deg`);
    console.log(percentage, start, end);
  }, [circleRatio]);

  useEffect(() => {
    let n = 0;
    // const animation = setInterval(() => {
    //   if ((start >= end && percentageRef.current <= end) || (start < end && percentageRef.current >= end)) {
    //     clearInterval(animation);
    //   } else {
    //     const unit = Math.abs(start - end) * Math.sin((Math.PI * n) / (2 * Math.abs(start - end)));
    //     n += 0.01;
    //     percentageRef.current += end < start ? -unit : unit;
    //     setCircularSectorPercentage(percentageRef.current);
    //   }
    // }, 1);

    const loop = (n) => {
      // Your logic here
      if (!((start >= end && percentageRef.current <= end) || (start < end && percentageRef.current >= end))) {
        setTimeout(() => {
          const unit = Math.abs(start - end) * Math.sin((Math.PI * n) / (2 * Math.abs(start - end)));
          n += 0.2;
          percentageRef.current += end < start ? -unit : unit;
          setCircularSectorPercentage(percentageRef.current);
          loop(n);
        }, 1);
      }
    };
    loop(0);
    // setCircularSectorPercentage(end);
  }, [percentage]);

  return (
    <div
      className="transition-transform duration-[2000ms] relative"
      style={{ width: containerSize, height: containerSize }}
      ref={gaugeRef}
    >
      {/* 테두리 삐져나온부분 가리기 */}
      <div className="w-[calc(100%+1px)] h-[calc(100%+1px)] rounded-[50%] border-[1.4px] border-white absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] z-[500]" />
      {/* 100% 링 */}
      <div className="w-full h-full rounded-[50%] bg-[#F3F5F8] relative ">
        {/* 중앙 하단 빈 부분 */}
        <div
          className={`absolute origin-bottom-left  z-[500] `}
          style={{
            transform: `rotate(135deg) translate(${-containerSize / 2 / Math.sqrt(2)}px,${
              -containerSize / 2 / Math.sqrt(2)
            }px) `,
          }}
        >
          <TopLayer radius={containerSize / 2} strokeWidth={strokeWidth} circleRatio={circleRatio} />
        </div>

        {/* 가운데 흰 원 */}
        <div
          className={` rounded-[50%] bg-white absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] z-[300]`}
          style={{ width: `calc(100% - ${strokeWidth * 2}px)`, height: `calc(100% - ${strokeWidth * 2}px)` }}
        />
        {/* 게이지 */}
        <div
          className="w-full h-full rounded-[50%] relative rotate-[180deg] "
          style={{
            background:
              pathBackground || 'conic-gradient(from 0deg, #398047 45deg,#398047 60deg, #f8cf46, #ef670b 300deg)',
          }}
        >
          <div style={{ transform: `rotate(${90 - circularSectorPercentage}deg)` }}>
            <CircularSector
              percentage={circularSectorPercentage}
              radius={containerSize / 2}
              strokeWidth={strokeWidth}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
