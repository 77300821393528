import React, { useEffect, useRef } from 'react';
import { getScrollEvent } from '../../utils/webview';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import deviceAtom from '../../recoil/common/device';
import { useRecoilValue } from 'recoil';

export default function ContainerByDevice({ css, children, handleScroll, indicatorPadding }) {
  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const deviceState = useRecoilValue(deviceAtom);
  const scrollRef = useRef();
  const onScroll = (e) => {
    const scrollTop = e.target.scrollTop ? e.target.scrollTop : window.scrollY;
    if (handleScroll) {
      handleScroll(scrollTop);
    }
  };

  useEffect(() => {
    getScrollEvent(scrollRef);
    window.addEventListener('scroll', onScroll);
    return () => {
      if (window !== null) {
        window.removeEventListener('scroll', onScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`w-screen min-h-screen ${deviceState.isIOS && ' h-screen overflow-auto scrollbar-hide '} ${
        indicatorPadding && marginClassName
      } ${css}`}
      onScroll={(e) => {
        onScroll(e);
      }}
      ref={scrollRef}
    >
      <div className={` w-full  ${deviceState.isIOS ? 'min-h-[101vh] pb-[1vh]' : 'min-h-screen'} `}>{children}</div>
    </div>
  );
}
