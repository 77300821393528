import example1 from '../../image/inside/example1.png';
import example2 from '../../image/inside/example2.png';
import example3 from '../../image/inside/example3.png';
import example4 from '../../image/inside/example4.png';
import example5 from '../../image/inside/example5.png';
import preview_1 from '../../image/inside/preview_1.png';
import preview_2 from '../../image/inside/preview_2.png';
import preview_3 from '../../image/inside/preview_3.png';
import preview_4 from '../../image/inside/preview_4.png';
import preview_5 from '../../image/inside/preview_5.png';
import preview_6 from '../../image/inside/preview_6.png';
import preview_7 from '../../image/inside/preview_7.png';
import preview_8 from '../../image/inside/preview_8.png';

const imageList = [
  example1,
  example2,
  example3,
  example4,
  example5,
  preview_1,
  preview_2,
  preview_3,
  preview_4,
  preview_5,
  preview_6,
  preview_7,
  preview_8,
];

export default imageList;
