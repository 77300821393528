import * as React from 'react';
import kakao_logo from '../../image/kakao_logo.png';
import { login } from '../../utils/webview';
import { AnxyActionButton } from '../../stories/button/ActionButton';

export default function KakaoButton() {
  return (
    <div className="w-full">
      <AnxyActionButton
        state={'ACTIVE'}
        activeColor={'#FEE500'}
        inactiveColor={'#E1E4EB'}
        textColor={'#3A3C40'}
        borderColor={'rgba(0,0,0,0.1)'}
        text={
          <div className="flex justify-center gap-[10px]">
            <img src={kakao_logo} alt={'empty'} className="w-[24px] " />
            <p> 카카오 로그인</p>
          </div>
        }
        action={() => {
          login({ type: 'kakao' });
        }}
      />
    </div>
  );
}
