import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Main from './Main';
import useSessionStorage from '../../hooks/useSessionStorage';
import InsideWHiteQA from './InsideWhiteQA';
import Result from './Result';
import EndTest from './EndTest';

export default function InsideWhite() {
  const { id } = useParams();
  const [selfCheckState, setSelfCheckState] = useSessionStorage('selfCheckState', []);

  const root = document.getElementById('root');
  root.style.position = 'fixed';
  root.style.overflow = 'hidden';

  useEffect(() => {
    if (id === 'start') {
      setSelfCheckState([]);
    }
  }, [id]);

  useEffect(() => {
    setSelfCheckState((state) => ({ ...state, qcList: qcList }));
  }, []);

  const choiceYes = [
    { selfCheckChoiceId: 1, score: 0, choice: '아니요' },
    { selfCheckChoiceId: 2, score: 10, choice: '네' },
  ];

  const choiceNo = [
    { selfCheckChoiceId: 1, score: 10, choice: '아니요' },
    { selfCheckChoiceId: 2, score: 0, choice: '네' },
  ];

  const qcList = [
    {
      selfCheckQuestionId: 1,
      question: '더 나은 삶을 위해 시간과 돈을 투자할 수 있어요.',
      choices: choiceYes,
    },
    {
      selfCheckQuestionId: 2,
      question: '내 경험과 생각, 감정을 말로 잘 표현할 수 있어요.',
      choices: choiceYes,
    },
    {
      selfCheckQuestionId: 3,
      question: '불편하고 싫은 마음을 솔직하게 드러내지 못해요.',
      choices: choiceNo,
    },
    {
      selfCheckQuestionId: 4,
      question: '상처를 터놓는 것은 매우 어려워요.',
      choices: choiceNo,
    },
    {
      selfCheckQuestionId: 5,
      question: '나도 모르게 상대방이 좋아할 만한 이야기와 반응을 하게 돼요.',
      choices: choiceNo,
    },
    {
      selfCheckQuestionId: 6,
      question: '내가 상담을 받는다는 사실을 주변 사람들이 알면 불편할 것 같아요.',
      choices: choiceNo,
    },
    {
      selfCheckQuestionId: 7,
      question: '상담을 하면 모든 것이 좋아질 거라고 생각해요.',
      choices: choiceNo,
    },
    {
      selfCheckQuestionId: 8,
      question: '상담을 받으면 전문가가 정답을 알려줄 거라 생각해요.',
      choices: choiceNo,
    },
    {
      selfCheckQuestionId: 9,
      question: '상담을 변화와 성장에 활용할 수 있다고 생각해요.',
      choices: choiceYes,
    },
    {
      selfCheckQuestionId: 10,
      question: '상담을 받더라도 스스로의 노력이 필요하다는 것을 알고 있어요.',
      choices: choiceYes,
    },
  ];

  return (
    <div
      className={`w-screen`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      {id === 'start' && <Main />}
      {parseInt(id) >= 0 && !isNaN(parseInt(id)) && (
        <InsideWHiteQA
          qcList={selfCheckState.qcList}
          selfCheckState={selfCheckState}
          setSelfCheckState={setSelfCheckState}
        />
      )}
      {id === 'end' && <EndTest />}
      {id === 'result' && <Result selfCheckState={selfCheckState} />}
    </div>
  );
}
