/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import somnyChairLottie from '../../stories/animation/somny_chair.json';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from '../../recoil/common/header/atom';
import { TopTitleBottomActionSomny } from '../../stories/page/TopTitleBottomActionSomny';
import Lottie from '../../components/elements/Lottie';
import { somnyImageList } from '../../data/somnyImageList';
import { useState } from 'react';
import useLoadImage from '../../hooks/useLoadImage';
import { SomnyAlarmCard } from '../../components/somny/SomnyAlarmCard';
import { SomnyTimePicker } from '../../components/somny/time/SomnyTimePicker';
import notificationAtom from '../../recoil/somny/notification/atom';
import webviewToast from '../../development/webviewToast';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import usePostNotification from '../../hooks/somny/usePostNotification';
import useMixpanelEventSomny from '../../hooks/useMixpanelEventSomny';
import Graphic from '../../components/somny/Graphic';

export default function SomnyOnboarding() {
  let { id } = useParams();
  id = parseInt(id);
  const [notificationState, setNotificationState] = useRecoilState(notificationAtom);

  const checkpointList = [
    {
      id: 0,
      checkPoint: 2,
      eventName: '코스 설명',
    },
    {
      id: 8,
      checkPoint: 3,
      eventName: '핵심 경험 설명',
    },
    {
      id: 9,
      checkPoint: 4,
      eventName: '효과 설명',
    },
    {
      id: 13,
      checkPoint: 5,
      eventName: '알림 설정 안내',
    },
  ];

  const checkPointId =
    checkpointList.find((item) => item.id === id) && checkpointList.find((item) => item.id === id).checkPoint;
  const eventName =
    checkpointList.find((item) => item.id === id) && checkpointList.find((item) => item.id === id).eventName;

  const [onboardingCheck, setOnboardingCheck] = useFetchAnxy({
    url: `/somny/onboarding/checkpoint/save`,
    requestBody: {
      checkpointId: checkPointId,
    },
  });

  const [timeSave, setTimeSave] = useFetchAnxy({
    url: `/somny/routine-time/save`,
    requestBody: notificationState,
  });

  const [buttonText, setButtonText] = useState('다음');
  const [buttonState, setButtonState] = useState('ACTIVE');

  const preloadImage = useLoadImage(somnyImageList);

  const goNext = useNavigateWithParams(`../somnyOnboarding/${parseInt(id) + 1}`);
  const goHome = useNavigateWithParams(`../somnyHome`);

  const setNotification = usePostNotification(notificationState, goNext);

  const setMixPanel = useMixpanelEventSomny({
    eventName: eventName,
  });

  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    if (timeSave) {
      webviewToast('루틴 설정 완료: ' + JSON.stringify(notificationState));
      goNext();
    }
  }, [timeSave]);

  useEffect(() => {
    // setMixPanel();
    if (id === 0) {
      preloadImage();
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE, headerColor: '#FFFFFF', headerTitle: '' }));
    } else {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#FFFFFF', headerTitle: '' }));
    }

    if (contentList[id].buttonText) {
      setButtonText(contentList[id].buttonText);
    }
  }, [id]);

  useEffect(() => {
    document.title = 'Somny, 오늘 밤을 위한 수면 관리';
  }, []);

  const BottomImage = ({ image, css, lottie }) => (
    <div className={`h-full relative max-w-[375px] mx-auto flex items-end pt-[20px] ${css}`}>
      <div className="relative">
        <img src={image} alt="empty" className="object-contain " />
        {lottie && (
          <div className="absolute top-[186px] left-[50%] translate-x-[-50%]">
            <Lottie lottieData={lottie} loop autoplay width="220px" height="220px" />
          </div>
        )}
      </div>
    </div>
  );

  const CenteredImage = ({ image, css, component }) => (
    <div className={`${css} h-full relative max-w-[375px] mx-auto flex items-center py-[20px]`}>
      <div className="relative">
        {component ? component : <img src={image} alt="empty" className="object-contain " />}
      </div>
    </div>
  );

  const FixedTopImage = ({ image, css, lottie }) => (
    <div className={`${css} h-full relative max-w-[375px] min-h-[610px] mx-auto flex items-start pt-[50px]`}>
      <img src={image} alt="empty" className="object-contain" />
      {lottie && (
        <div className="absolute top-[240px] left-[50%] translate-x-[-50%]">
          <Lottie lottieData={lottie} loop autoplay width="220px" height="220px" />
        </div>
      )}
    </div>
  );

  const contentList = [
    {
      title: 'Somny에서는 세 가지 요소를 이용하여 수면을 관리해요',
      content: <CenteredImage image={somnyImageList[0]} css="px-[10px]" />,
    },
    {
      title: 'Somny는 뜨개질을 좋아하는 토끼랍니다',
      subtitle:
        '많은 사람들이 somny와 함께 불면증을 극복하고 더 깊이 잠들 수 있었어요. 사람들은 어떻게 somny와 함께 더 깊게 잠들 수 있었을까요?',
      content: <BottomImage image={somnyImageList[1]} css="px-[56px]" />,
    },
    {
      title: '밤에는 수면 동화를 들려줄 거예요',
      subtitle: 'somny는 매일 다양한 수면 동화를 들려준답니다',
      content: <FixedTopImage image={somnyImageList[2]} />,
    },
    {
      title: '자는 동안 곁에서 지켜줘요',
      subtitle: '밤 사이 somny는 뜨개질을 하며 내 곁을 지켜요',
      content: <FixedTopImage image={somnyImageList[3]} lottie={somnyChairLottie} />,
    },
    {
      title: '아침이 되면 잘 잤는지 확인해요',
      subtitle: 'Somny는 내가 수면 기록을 해야 비로소 잠에 든답니다',
      content: <FixedTopImage image={somnyImageList[4]} />,
    },
    {
      title: '수면 시간을 관리해 줄 거예요',
      subtitle: 'Somny는 기록을 바탕으로 나의 수면을 관리해요',
      content: <FixedTopImage image={somnyImageList[5]} />,
    },
    {
      title: '기록을 잘하면 선물을 줄게요',
      subtitle: 'Somny의 뜨개엔 잠에 관한 팁이 들어있어요',
      content: <FixedTopImage image={somnyImageList[6]} css="px-[50px]" />,
    },
    {
      title: '기록을 잘하면 새로운 동화도 받을 수 있어요',
      subtitle: '수면기록을 확인한 Somny는 꿈나라로 가 동화를 채집해요',
      content: <FixedTopImage image={somnyImageList[7]} css="px-[50px]" />,
    },
    {
      title: '다시 밤이에요, somny를 만나요',
      subtitle: 'Somny는 일어나 다시 내게 수면 동화를 들려줘요',
      content: <FixedTopImage image={somnyImageList[9]} />,
    },
    {
      title: '이렇게 Somny와 함께 매일을 보내면 된답니다',
      content: <CenteredImage component={<Graphic />} />,
    },
    {
      title: '그럼 먼저 Somny에게 나의 시간을 알려주세요',
      content: <CenteredImage component={<Graphic question />} />,
    },
    {
      title: '보통 언제 잠에 드나요?',
      subtitle: '동화를 들으며 잠에 들 시각이에요.',
      content: (
        <SomnyTimePicker
          staticHour="23"
          staticMinute="00"
          state={notificationState}
          setState={setNotificationState}
          propsName="sleepTime"
        />
      ),
    },
    {
      title: '보통 언제 일어나나요?',
      subtitle: '일어나서 지난 밤의 수면을 기록하는 시각이에요.',
      content: (
        <SomnyTimePicker
          staticHour="07"
          staticMinute="00"
          state={notificationState}
          setState={setNotificationState}
          propsName="wakeUpTime"
        />
      ),
    },
    {
      title: '좋아요, 이렇게 하면 될 것 같네요',
      content: <CenteredImage component={<Graphic state={notificationState} />} />,
      action: () => setTimeSave(),
    },
    {
      title: 'Somny가 맞춰서 알려드릴게요',
      content: <SomnyAlarmCard />,
      buttonText: '알림 받기',
      action: setNotification,
      linkText: '다음에',
      linkAction: goNext,
      noGradient: true,
    },
    {
      title: '자, 그럼 이제 Somny를 만나세요',
      content: <BottomImage image={somnyImageList[13]} css="px-[37px]" />,
      buttonText: 'somny 시작하기',
      noGradient: true,
    },
  ];
  console.log(notificationState);

  const currentData = contentList[id];
  return (
    <div>
      {currentData.replaceComponent ? (
        currentData.replaceComponent
      ) : (
        <TopTitleBottomActionSomny
          title={currentData.title && currentData.title}
          subtitle={currentData.subtitle && currentData.subtitle}
          content={currentData.content}
          buttonText={buttonText}
          buttonState={buttonState}
          noGradient={currentData.noGradient ? currentData.noGradient : false}
          action={() => {
            if (checkPointId) {
              setOnboardingCheck();
              setMixPanel();
            }
            if (id === contentList.length - 1) {
              goHome();
            } else {
              if (currentData.action) {
                currentData.action();
              } else {
                goNext();
              }
            }
          }}
          linkText={currentData.linkText}
          linkAction={currentData.linkAction}
          noScroll
        />
      )}
    </div>
  );
}
