import ReactPlayer from 'react-player';
import webviewToast from '../../development/webviewToast';
import useMixpanelEvent from '../../hooks/useMixpanelEvent';

const CounselorYoutube = ({ videoId, isHome }) => {
  const setMixPanel = useMixpanelEvent({
    eventName: '상담사 영상 재생',
    params: {
      화면: isHome ? '홈' : '상담사 배정 완료',
    },
  });

  return (
    <div className="mt-[30px]">
      <div
        className="w-[calc(100vw-40px)] bg-black overflow-hidden rounded-[16px] relative py-[0px] z-[50]"
        style={{
          boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.22)',
        }}
      >
        <div className="relative top-[0px] left-[-50%] translate-x-[-11%]">
          <ReactPlayer
            onStart={() => {
              webviewToast('start');
              setMixPanel();
            }}
            url={`https://youtu.be/${videoId}`} // 플레이어 url
            width="222%"
            pip={false}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  cc_load_policy: 1,
                  fs: 0,
                  color: 'white',
                  rel: 0,
                  modestbranding: 1,
                  playsinline: 1,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CounselorYoutube;
