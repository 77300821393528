import React from 'react';
import AnxyNotch from '../../components/anxy/AnxyNotch';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import present from '../../image/inside/present.png';
import { SomnyActionButton } from '../button/RoundActionButton';
import LinkButton from '../button/LinkButton';

export function TopTitleBottomActionSomny(props) {
  const {
    title,
    subtitle,
    content,
    buttonText,
    buttonState,
    action,
    topColor,
    noHeader,
    isBottom,
    isApp,
    linkText,
    linkAction,
    noGradient,
    noScroll,
  } = props;

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return (
    <div
      className="pt-safe h-screen"
      style={{
        backgroundColor: '#121B40',
        height: !isApp && 'calc(var(--vh,1vh) * 100)',
        minHeight: !isApp && 'calc(var(--vh.1vh) * 100)',
      }}
    >
      {!noHeader && <AnxyNotch notchColor={'#121B40'} />}
      <div className={`h-full ${!noHeader && 'pt-[44px]'} flex flex-col ${marginClassName}  `}>
        <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col`}>
          {title && (
            <div className="px-[20px] pt-[12px]" style={{ backgroundColor: topColor }}>
              <p className="text-[24px] leading-[30px] font-bold text-white">{title}</p>
              {subtitle && typeof subtitle === 'string' && (
                <p className="font-light opacity-[0.8] text-white text-[17px] leading-[28px] pt-[24px]">{subtitle}</p>
              )}
              {subtitle && typeof subtitle !== 'string' && (
                <p className="pt-[24px]">
                  {subtitle.map((text, index) => (
                    <span
                      className={`${
                        index % 2 === 0 ? 'font-light' : 'font-bold'
                      } opacity-[0.8] text-white text-[17px] leading-[28px]`}
                    >
                      {text}
                    </span>
                  ))}
                </p>
              )}
            </div>
          )}
          <div
            className="flex-1 flex flex-col"
            style={{
              overflow: noScroll ? 'hidden' : '',
            }}
          >
            {content}
          </div>
        </div>
        <div className={`flex-none w-full relative `}>
          {!noGradient && (
            <div
              className="w-full h-[40px] absolute top-[-40px]"
              style={{
                background: `linear-gradient(to top, #121B40, rgba(18,26,64,0))`,
              }}
            />
          )}

          <div className="px-[20px]">
            <div>
              {isBottom && (
                <div className="flex justify-center items-center pb-[10px]">
                  <img src={present} alt="empty" className="w-[143px]" />
                </div>
              )}
              <SomnyActionButton state={buttonState} text={buttonText} action={action} noGradient />
              {linkText && (
                <div className="w-full flex justify-center">
                  <LinkButton text={linkText} action={linkAction} noLine isSomny />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
