import { useContext } from 'react';
import { CoachingContext } from '../../../coachingContext';

const UserWorryAndSolution = () => {
  const context = useContext(CoachingContext);

  return (
    <div className="w-full py-[30px]">
      <div className="rounded-[12px] bg-[#F3F5F8] mx-[23px] p-20px mb-[10px]">
        <div className="mb-[6px] text-center rounded-[4px] bg-[#4E4F53] w-[56px] text-[12px] leading-[18px] h-[19px] text-white font-bold">
          <p className="pt-[0.5px]">나의 걱정</p>
        </div>
        <div className="text-[17px] leading-[23px] font-semibold">
          <p>{context.workbookAnswer[0]}</p>
        </div>
      </div>
      <div className="rounded-[12px] bg-[#F3F5F8] mx-[23px] p-20px mb-[10px]">
        <div className="mb-[6px] text-center rounded-[4px] bg-[#4E4F53] w-[56px] text-[12px] leading-[18px] h-[19px] text-white font-bold">
          <p className="pt-[0.5px]">해결 방안</p>
        </div>
        <div className="text-[#26282C] text-[17px] leading-[23px] font-semibold">
          <p>{context.workbookAnswer[1]}</p>
        </div>
      </div>
    </div>
  );
};
export default UserWorryAndSolution;
