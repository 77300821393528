// 0~13 course-1 감정을 만드는 생각 찾기
// 14~20 course-2 생각의 발자취 따라가기
// 21~31 course-3 내 감정 돌아보기
// 32~37 course-0 중요한 건 내 마음
// 38~47 course-4 부정적인 감정 다루기
// 48~ course-5 잃어버린 밤을 찾아서
const workbookProgressData = [
  { courseId: 0, name: 'Intro' },
  { courseId: 1, name: '생각에 집중하기' },
  { courseId: 2, name: '자동적 사고' },
  { courseId: 3, name: '자동적 사고 인지하기' },
  { courseId: 4, name: 'ABC로 감정 파악하기' },
  { courseId: 5, name: '상황, 생각, 그리고 결과' },
  { courseId: 6, name: '내 마음 살펴보기' },
  { courseId: 7, name: '생각 함정 1' },
  { courseId: 8, name: '생각 함정 2' },
  { courseId: 9, name: '생각 함정 3' },
  { courseId: 10, name: '생각 함정 4' },
  { courseId: 11, name: 'D로 생각 점검하기' },
  { courseId: 12, name: 'E로 생각 바꾸기' },
  { courseId: 13, name: 'Outro' },
  { courseId: 14, name: '생각의 발자취 따라가기' },
  { courseId: 15, name: '내 마음을 이루는 ‘기본 가정’' },
  { courseId: 16, name: '내 마음의 근원' },
  { courseId: 17, name: '기본 가정을 통한 예상' },
  { courseId: 18, name: '삶의 규칙' },
  { courseId: 19, name: '기본 가정과 규칙 조정하기' },
  { courseId: 20, name: '생각의 발자취를 따라가며' },
  { courseId: 21, name: 'Intro. 내 감정 돌아보기' },
  { courseId: 22, name: '우리를 돕는 감정' },
  { courseId: 23, name: '감정에 이름 붙이기' },
  { courseId: 24, name: '감정이 보내는 몸의 신호' },
  { courseId: 25, name: '감정 알아차리기 - 기쁨' },
  { courseId: 26, name: '감정 알아차리기 - 슬픔' },
  { courseId: 27, name: '감정 알아차리기 - 불쾌함' },
  { courseId: 28, name: '감정 알아차리기 - 화남' },
  { courseId: 29, name: '감정 알아차리기 - 두려움' },
  { courseId: 30, name: '흐르는 감정의 물결' },
  { courseId: 31, name: 'Outro. 내 감정 돌아보기' },
  { courseId: 32, name: '인사이드에 오신 걸 환영해요!' },
  { courseId: 33, name: '안녕하세요, 닥터 스마일입니다' },
  { courseId: 34, name: '감정 노트: 감정과 생각 기록하기' },
  { courseId: 35, name: '앨리스의 생각에 대해' },
  { courseId: 36, name: '회고 노트: 생각 되짚어보기 1' },
  { courseId: 37, name: '회고 노트: 생각 되짚어보기 2' },
  { courseId: 38, name: 'Intro. 부정적인 감정 다스리기' },
  { courseId: 39, name: '불난 감정의 소화기 ‘심호흡’' },
  { courseId: 40, name: '슬픔과 우울증' },
  { courseId: 41, name: '마음의 근육 기르기' },
  { courseId: 42, name: '죄책감을 부르는 생각 함정' },
  { courseId: 43, name: '죄책감 벗어던지기' },
  { courseId: 44, name: '양날의 검, 분노' },
  { courseId: 45, name: '분노를 부르는 생각 함정' },
  { courseId: 46, name: '분노를 다스리는 방법' },
  { courseId: 47, name: 'Outro. 부정적인 감정 다스리기' },
  { courseId: 48, name: 'Intro: 잃어버린 밤을 찾아서' },
  { courseId: 49, name: '불면증 인지행동치료에 대해' },
  { courseId: 50, name: '코스를 성공적으로 활용하는 법' },
  { courseId: 51, name: '적당한 수면이란 무엇일까' },
  { courseId: 52, name: '불면증에 대하여' },
  { courseId: 53, name: '밤의 흔적을 찾아서' },
  { courseId: 54, name: '수면 기록' },
  { courseId: 55, name: '수면 기록' },
  { courseId: 56, name: '불면증의 숨겨진 이유 찾아보기' },
  { courseId: 57, name: '불면증이 길어지는 이유' },
  { courseId: 58, name: '수면 기록' },
  { courseId: 59, name: '약물을 통해 맞이하는 밤' },
  { courseId: 60, name: '약 없이도 잘 잘 수 있는 방법' },
  { courseId: 61, name: '수면 기록' },
  { courseId: 62, name: '생체 시계와 항상성 이해하기' },
  { courseId: 63, name: '수면 기록' },
  { courseId: 64, name: '밤을 잃어버린 나의 이야기' },
  { courseId: 65, name: '침실을 수면을 위한 공간으로' },
  { courseId: 66, name: '수면 기록' },
  { courseId: 67, name: '수면 시간 처방 받기' },
  { courseId: 68, name: '수면 기록' },
  { courseId: 69, name: '수면 위생: 낮 시간의 활동' },
  { courseId: 70, name: '수면 기록' },
  { courseId: 71, name: '수면 위생: 잠 자기 전 시간' },
  { courseId: 72, name: '수면 기록' },
  { courseId: 73, name: '수면 위생: 잠자리 환경' },
  // 수면 위생
  { courseId: 74, name: '수면 기록' },
  { courseId: 75, name: '수면과 관련된 미신들' },
  { courseId: 76, name: '수면 기록' },
  { courseId: 77, name: '이완요법: 걱정 덜어두기' },
  { courseId: 78, name: '걱정 덜어두기' },
  { courseId: 79, name: '수면 기록' },
  { courseId: 80, name: '이완요법: 인지통제기법' },
  { courseId: 81, name: '상상하기' },
  { courseId: 82, name: '숫자 빼기' },
  { courseId: 83, name: '수면 기록' },
  { courseId: 84, name: '이완요법: 긴장 풀기' },
  { courseId: 85, name: '긴장 풀기' },
  { courseId: 86, name: '다음주 수면 처방 받기' },
  { courseId: 87, name: '목표 중간 점검하기' },
  // 15일차
  { courseId: 88, name: '수면 기록' },
  { courseId: 89, name: '불면증의 뇌: 걱정' },
  { courseId: 90, name: '상상하기' },
  { courseId: 91, name: '수면 기록' },
  { courseId: 92, name: '불면증의 뇌: 생각 패턴' },
  { courseId: 93, name: '숫자 빼기' },
  { courseId: 94, name: '수면 기록' },
  { courseId: 95, name: '불면증의 뇌: 생각 함정 1' },
  { courseId: 96, name: '긴장 풀기' },
  { courseId: 97, name: '수면 기록' },
  { courseId: 98, name: '불면증의 뇌: 생각 함정 2' },
  { courseId: 99, name: '걱정 덜어두기' },
  { courseId: 100, name: '수면 기록' },
  { courseId: 101, name: '불면증의 뇌: 생각 노트' },
  { courseId: 102, name: '생각 노트' },
  { courseId: 103, name: '긴장 풀기' },
  // 20일차
  { courseId: 104, name: '수면 기록' },
  { courseId: 105, name: '정리하기' },
  { courseId: 106, name: '상상하기 연습' },
  { courseId: 107, name: '수면 기록' },
  { courseId: 108, name: '긴장 풀기 연습' },
  { courseId: 109, name: '수면 기록' },
  { courseId: 110, name: '숫자 빼기 연습' },
  { courseId: 111, name: '생각 노트' },
  // 25일차
  { courseId: 112, name: '수면 기록' },
  { courseId: 113, name: '긴장 풀기 연습' },
  { courseId: 114, name: '수면 기록' },

  { courseId: 115, name: '수면 기록' },
  { courseId: 116, name: '긴장 풀기 연습' },
  { courseId: 117, name: '생각 노트' },
  { courseId: 118, name: '수면 기록' },
  { courseId: 119, name: '수면 기록' },
  { courseId: 120, name: '수면 기록' },
  { courseId: 121, name: '생각 노트' },
  { courseId: 122, name: '목표 최종 점검하기' },
  { courseId: 123, name: 'Outro: 잃어버린 밤을 찾아서' },
];

export default workbookProgressData;
