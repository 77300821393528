import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useChangeLocation(url) {
  const [searchParams] = useSearchParams();

  const [params, setParams] = useState('');

  const navigateWithParams = () => {
    // window.location.href = `${url}${params}`;
    window.location.replace(`${url}${params}`);
  };

  useEffect(() => {
    var prefix = url.includes('?');
    let temp = '';
    for (var [key, value] of searchParams.entries()) {
      // console.log('---------', searchParams.entries(), key, value);
      if (!(url.includes(`?${key}=`) || url.includes(`&${key}=`))) {
        if (prefix) {
          temp += `&${key}=${value}`;
        } else {
          temp += `?${key}=${value}`;
          prefix = true;
        }
      }
    }
    setParams(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return navigateWithParams;
}
