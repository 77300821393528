import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { hasTouch } from 'detect-touch';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function QuestionSelectButton({ textList, answer }) {
  const { id, page } = useParams();
  const [clickedId, setClickedId] = useState();
  const [isPressed, setIsPressed] = useState(false);
  const [select, setSelect] = useState('');
  const [myAnswer, setMyAnswer] = useState('');

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  function mouseUp(e) {
    setIsPressed(false);
    setMyAnswer(select);
  }

  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  useEffect(() => {
    if (!hasTouch) {
      if (myAnswer === answer) {
        goNext();
      }
    }
  }, [myAnswer]);

  return (
    <div className="mb-10px mt-40px">
      {textList.map((text, index) => (
        <motion.div
          animate={{ scale: isPressed && clickedId === index ? 0.95 : 1 }}
          transition={{ duration: 0.2 }}
          className={`text-left px-20px py-12px w-full rounded-[12px] text-[17px] leading-[26px] mb-10px text-[#26282C]
          ${myAnswer === text && text === answer && 'font-semibold text-[#3953D9] bg-[#F5F6FD]'}}

          ${myAnswer === text && text !== answer && 'font-normal text-[#E32940] bg-[#E32940]'}}
          `}
          style={{
            border:
              myAnswer === text && text !== answer
                ? '1px solid #E32940'
                : myAnswer === text && text === answer
                ? '1px solid #3953D9'
                : '1px solid #D1D5DC',
          }}
          onMouseDown={(e) => {
            if (!hasTouch) {
              setClickedId(index);
              setIsPressed(true);
              setSelect(e.currentTarget.textContent);
            }
          }}
          onTouchStart={(e) => {
            setClickedId(index);
            setIsPressed(true);
          }}
          onTouchEnd={(e) => {
            setIsPressed(false);

            if (
              document
                .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
                .includes(e.currentTarget)
            ) {
              setMyAnswer(e.currentTarget.textContent);
              if (e.currentTarget.textContent === answer) {
                goNext();
              }
            }
          }}
        >
          <p className="mb-[2px]">{text}</p>
        </motion.div>
      ))}
      {myAnswer !== answer && myAnswer !== '' && (
        <p className="text-[#E32940] text-[17px] leading-[28px mt-[24px]">다시 생각해 보세요.</p>
      )}
    </div>
  );
}
