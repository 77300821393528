import React, { useEffect, useState } from 'react';
import { Caption15, Text17, Title24 } from 'stories/text/AnxyText';
import worrynote_reason_empty from 'image/anxy/tool/worrynote_reason_empty.png';
import worrynote_thought_empty from 'image/anxy/tool/worrynote_thought_empty.png';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import { ProgressingBar } from 'stories/else/ProgressingBar';
import { arraySum } from 'logic/method';
import hexOpacity from 'hex-opacity';

export default function WorryNoteStatistics({ worryNoteStatisticsData }) {
  // 데이터 로드 여부Atom);

  const [worryNoteStatistics, setWorryNoteStatistics] = useState();

  const graphData = [
    {
      title: '불안 원인',
      subtitle_default: '분석을 보려면 걱정 기록을 더 써주세요',
      image: worrynote_reason_empty,
    },
    { title: '신체 반응', graphType: 'horizontal', graphColor: '#F2D060', key: 'countByBody' },
    {
      title: '생각 반응',
      subtitle_default: '분석을 보려면 생각 함정 찾기를 진행해주세요',
      image: worrynote_thought_empty,
    },
    { title: '행동 반응', graphType: 'horizontal', graphColor: anxyColorPalette.orange, key: 'countByBehavior' },
    {
      title: '시간대별 불안 점수',
      subtitle_default: '분석을 보려면 걱정 기록을 더 써주세요',
      graphType: 'vertical',
      graphColor: '#4C7E4C',
      key: 'avgScoreByHour',
      subtitle_format: '주로 --에 불안해해요',
    },
    {
      title: '요일별 불안 점수',
      subtitle_default: '분석을 보려면 걱정 기록을 더 써주세요',
      graphType: 'vertical',
      graphColor: anxyColorPalette.orange,
      key: 'avgScoreByDayOfWeek',
      subtitle_format: '주로 --요일에 불안해해요',
    },
  ];

  useEffect(() => {
    if (worryNoteStatisticsData) {
      const worryNoteStatisticsResult = worryNoteStatisticsData.result;
      const newWorryNoteStatistics = {};
      const avgScoreByHour = worryNoteStatisticsResult.avgScoreByHour;
      const avgScoreByDayOfWeek = worryNoteStatisticsResult.avgScoreByDayOfWeek;
      const dayByIdx = { 1: '월', 2: '화', 3: '수', 4: '목', 5: '금', 6: '토', 7: '일' };

      const avgScoreByTime = { 오전: [], 오후: [], 저녁: [], 밤: [] }; // 4~12 / 12~18 / 18~22 / 22~4
      const avgScoreByDay = {};

      Object.keys(dayByIdx).forEach((each) => {
        avgScoreByDay[dayByIdx[each]] = avgScoreByDayOfWeek[each] || 0;
      });

      Object.keys(avgScoreByHour)
        .map((element) => parseInt(element))
        .forEach((each) => {
          if (each >= 4 && each < 12) {
            avgScoreByTime['오전'].push(avgScoreByHour[each.toString()]);
          } else if (each >= 12 && each < 18) {
            avgScoreByTime['오후'].push(avgScoreByHour[each.toString()]);
          } else if (each >= 18 && each < 22) {
            avgScoreByTime['저녁'].push(avgScoreByHour[each.toString()]);
          } else {
            avgScoreByTime['밤'].push(avgScoreByHour[each.toString()]);
          }
        });

      Object.keys(avgScoreByTime).forEach((each) => {
        avgScoreByTime[each] =
          avgScoreByTime[each].length > 0 ? parseInt(arraySum(avgScoreByTime[each]) / avgScoreByTime[each].length) : 0;
      });

      const countByBody = Object.entries(worryNoteStatisticsResult.countByBody)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

      const countByBehavior = Object.entries(worryNoteStatisticsResult.countByBehavior)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

      if (arraySum(Object.values(countByBody)) > 0) {
        newWorryNoteStatistics['countByBody'] = countByBody;
      }
      if (arraySum(Object.values(countByBehavior)) > 0) {
        newWorryNoteStatistics['countByBehavior'] = countByBehavior;
      }

      if (arraySum(Object.values(avgScoreByTime)) > 0) {
        newWorryNoteStatistics['avgScoreByHour'] = avgScoreByTime;
      }
      if (arraySum(Object.values(avgScoreByDay)) > 0) {
        newWorryNoteStatistics['avgScoreByDayOfWeek'] = avgScoreByDay;
      }
      setWorryNoteStatistics({
        countByBehavior: countByBehavior,
        countByBody: countByBody,
        avgScoreByHour: avgScoreByTime,
        avgScoreByDayOfWeek: avgScoreByDay,
      });

      // setWorryNoteStatistics(newWorryNoteStatistics);
    }
  }, [worryNoteStatisticsData]);

  useEffect(() => {
    if (worryNoteStatistics) {
      console.log(worryNoteStatistics);
    }
  }, [worryNoteStatistics]);

  return (
    worryNoteStatistics &&
    graphData
      .filter((element) => !element.key || worryNoteStatistics[element.key])
      .map((graph, index) => (
        <div
          className="h-full snap-always snap-start w-full px-[20px] pt-[54px]"
          style={{ transform: `translateY(${-100 * (index + 2)}px)` }}
          key={`statistic${index}`}
        >
          <div className="flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[8px]">
              <Title24 title={graph.title} />
              {graph.subtitle_default && (
                <Text17
                  text={
                    graph.subtitle_format && !Object.values(worryNoteStatistics[graph.key]).includes(0)
                      ? graph.subtitle_format.split('--').join(
                          Object.keys(
                            Object.entries(worryNoteStatistics[graph.key])
                              .sort(([, a], [, b]) => b - a)
                              .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
                          )[0],
                        )
                      : graph.subtitle_default
                  }
                />
              )}
            </div>
            {graph.image && <img src={graph.image} alt={'empty'} className="w-full max-w-[375px] mx-auto" />}
            {graph.graphType === 'horizontal' && (
              <div className="flex flex-col gap-[12px]">
                {Object.keys(worryNoteStatistics[graph.key])
                  .slice(0, 5)
                  .map((label, index) => (
                    <div className="flex flex-col gap-[8px]" key={`${graph.title}${index}`}>
                      <Text17 text={label} />

                      <ProgressingBar
                        maxCompleted={Object.values(worryNoteStatistics[graph.key])[0]}
                        completed={worryNoteStatistics[graph.key][label]}
                        height={24}
                        color={hexOpacity.create(
                          graph.graphColor,
                          1 -
                            0.1 *
                              Object.values(worryNoteStatistics[graph.key])
                                .slice(0, 5)
                                .findIndex((element) => element === worryNoteStatistics[graph.key][label]),
                        )}
                        baseColor={`${anxyColorPalette.black}1A`}
                        animateNone
                        maskColor={anxyColorPalette.oat}
                      />
                    </div>
                  ))}
              </div>
            )}

            {graph.graphType === 'vertical' && (
              <div className="w-full flex gap-[8px]">
                {Object.keys(worryNoteStatistics[graph.key]).map((label, index) => (
                  <div className="flex-1 flex flex-col gap-[16px] items-center" key={`${graph.title}${index}`}>
                    <div
                      className="w-full h-[400px] rounded-[16px] overflow-hidden flex flex-col justify-end"
                      style={{ backgroundColor: `${anxyColorPalette.black}1A` }}
                    >
                      <div
                        className="w-full rounded-[16px] flex flex-col items-center justify-center"
                        style={{
                          backgroundColor: hexOpacity.create(
                            graph.graphColor,
                            1 -
                              (Object.keys(worryNoteStatistics[graph.key]).length >= 5 ? 0.1 : 0.2) *
                                Object.values(worryNoteStatistics[graph.key])
                                  .sort()
                                  .reverse()
                                  .findIndex((element) => element === worryNoteStatistics[graph.key][label]),
                          ),
                          height: `${worryNoteStatistics[graph.key][label]}%`,
                        }}
                      >
                        {worryNoteStatistics[graph.key][label] > 0 && (
                          <Caption15 caption={worryNoteStatistics[graph.key][label]} color="#ffffff" />
                        )}
                      </div>
                    </div>

                    <Text17 text={label} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))
  );
}
