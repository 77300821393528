import React, { useContext, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../coachingContext';
import { SecondaryActionButton } from '../../stories/button/ActionButton';
export default function WorkbookTemplate({ content, action }) {
  const { id, page } = useParams();
  const { bg, image, fullImage, height, title, subtitle, texts, buttonText, customButtonText } = content;

  const imageRef = useRef();
  const scrollRef = useRef();
  const containerRef = useRef();

  const context = useContext(CoachingContext);

  return (
    <div className="w-full px-20px h-[calc(100vh-17px)] relative">
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`} ref={containerRef}>
        <div ref={scrollRef} className="mt-[50px] mb-[40px]">
          <div className="flex flex-col relative">
            {image && (
              <div
                className={`w-full h-[190px] relative overflow-hidden rounded-2xl mb-[30px] flex align-center`}
                style={{ backgroundColor: bg ? bg : '#F3F5F8', height: height ? height : '190px' }}
              >
                <div
                  className="w-[335px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] "
                  style={{ height: height ? height : '190px' }}
                >
                  <img className="w-full h-full object-fit" src={image} alt="empty" />
                </div>
              </div>
            )}
            {fullImage && (
              <div
                className={`w-full relative overflow-hidden rounded-2xl mb-30px flex align-center`}
                style={{ minHeight: height ? `${height}px` : '190px' }}
              >
                <div
                  className="w-full min-w-[335px]  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] "
                  ref={imageRef}
                >
                  <img className="w-full h-full object-fit" src={fullImage} alt="empty" />
                </div>
              </div>
            )}
            <div className="">
              {title && (
                <p
                  className="font-bold text-[24px] leading-[30px]  mb-[15px] text-[#26282C]"
                  style={{ wordBreak: 'keep-all' }}
                >
                  {title}
                </p>
              )}
              {subtitle && (
                <p
                  className="font-bold text-[20px] leading-[26px] mb-[15px] text-[#26282C] "
                  style={{ wordBreak: 'keep-all' }}
                >
                  {subtitle}
                </p>
              )}
              {texts.map((each) => (
                <span className={`text-[17px] leading-[28px] text-[#3A3C40]`}>
                  {each.text && (
                    <div className="">
                      {!each.bold && !each.numbering && <span className={`font-light`}>{each.text}</span>}
                      {each.bold && !each.numbering && (
                        <span className={`bg-[#FFEB3564] pb-[1.5px] font-light`}>{each.text}</span>
                      )}
                    </div>
                  )}
                  {each.component && each.component}
                </span>
              ))}
            </div>
          </div>
        </div>
        {buttonText && (
          <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px]`}>
            <SecondaryActionButton
              state="ACTIVE"
              text={customButtonText ? customButtonText : context.progressSum === parseInt(page) ? '마치기' : '다음'}
              action={action}
            />
          </div>
        )}
      </div>
    </div>
  );
}
