/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Progressbar from 'react-js-progressbar';
import { FullPageAnxy } from './FullPageAnxy';
import { AnxyLoadingLottie } from '../animation/AnxyLoadingLottie';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import { useRecoilValue } from 'recoil';

function CircularProgressingPage(props) {
  const { bgColor, labelColor, textColor, trailColor, pathColor, steps, isDone, disclaimer, fixedRoot } = props;
  const [percentage, setPercentage] = useState(0);
  const [current, setCurrent] = useState(0);
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  const duration = 1050;
  const percentageList =
    steps.length === 4
      ? [21, 53, 75, 100]
      : steps.length === 3
      ? [24, 62, 100]
      : steps.length === 2
      ? [53, 100]
      : [100];

  function eachStepDone(i) {
    setTimeout(() => {
      setPercentage(percentageList[i]);
      setCurrent(i);
      if (i === steps.length - 1) {
        setTimeout(() => {
          setTimeout(() => {
            isDone();
          }, 1000);
        }, duration);
      }
    }, 500 + i * duration);
  }

  useEffect(() => {
    for (var i = 0; i < steps.length; i++) {
      eachStepDone(i);
    }
  }, []);

  return (
    <FullPageAnxy
      content={
        <div className={`h-full px-[20px] flex flex-col justify-between  ${marginClassName}`}>
          <div className="h-full flex flex-col items-center justify-center pb-[40px]">
            <div className="w-[90px] h-[200px] flex flex-col relative" id="circularProgressingAnxy">
              <Progressbar
                input={percentage}
                pathWidth={10}
                pathColor={pathColor} // use an array for gradient color.
                trailWidth={10}
                trailColor={trailColor} // use a string for solid color.
                textStyle={{ fill: '#26282C', fontSize: '50px', fontWeight: '700', color: labelColor }} // middle text style
                textPosition={{ x: '50%', y: '140%' }}
                pathLinecap={'round'}
                pathShadow={'none'}
                animation={{
                  duration: 1000,
                  delay: 0,
                  ease: 'easeInSine',
                }}
              />
            </div>
            <div className="text-[17px] leading-[23px] " style={{ color: textColor }}>
              {steps[current]}
            </div>
          </div>
          {disclaimer && (
            <div className="border-t-[1px] border-[#E1E4EB] pt-[20px] text-[14px] leading-[20px] ">{disclaimer}</div>
          )}
        </div>
      }
      noHeader
      bgColor={bgColor}
      fixedRoot={fixedRoot}
    />
  );
}

export default CircularProgressingPage;
