import moment from 'moment';
import anxySessionByType from '../data/anxy/anxySessionByType';
import anxySessionData from '../data/anxy/anxySessionData';
import anxyColorPalette from '../data/anxy/contents/anxyColorPalette';
import anxyContents from '../data/anxy/contents/anxyContents';

export const getDataById = (sessionId, data) => {
  const sessionData = anxySessionData.find((content) => content.sessionId === sessionId);
  return sessionData[data];
};

export const getDataBySeq = (seq, data) => {
  return anxySessionData.find((content) => content.seq === seq)[data];
};

export const getDataByType = (type, data, index) => {
  const dataByType = anxySessionByType.find((content) => content.type === parseInt(type))[data];
  if (index) {
    return dataByType[index];
  } else {
    return dataByType;
  }
};

export const getLeftOpenTime = (completeTime) => {
  const isoFormat = 'YYYY-MM-DDTHH:mm:ss';
  // 날짜 차이 구하기
  const nowTime = moment(new Date()).format(isoFormat);
  const after4Days = moment(completeTime).add({ hours: 24 * 4 });
  const timeDiff = moment.duration(moment(after4Days).diff(nowTime));
  const format = moment(after4Days).format('');
  console.log(Math.floor(timeDiff.asDays()), format);

  const leftDays = Math.floor(timeDiff.asDays());
  const leftHours = timeDiff.asHours();
  console.log(leftHours);

  const leftTimeFormat = `${leftDays}일 ${timeDiff.format('HH시간 mm분')} `;

  return leftTimeFormat;
};

export const findColor = (color) => {
  return anxyColorPalette.find((item) => item.name === color).color;
};

export const getSessionData = (sectionId) => {
  return anxySessionData.find((item) => item.sectionId === sectionId);
};

export const getSessionContents = (sectionId) => {
  console.log(anxyContents.find((item) => item.sectionId === sectionId));
  return anxyContents.find((item) => item.sectionId === sectionId).contents;
};
