/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../stories/page/BottomActionAnxy';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import anxyWebAboutCourse0 from '../../image/anxy/anxyWeb/anxyWebAboutCourse0.png';
import anxyWebAboutCourse1 from '../../image/anxy/anxyWeb/anxyWebAboutCourse1.png';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import TokenTest from '../../development/TokenTest';

function Guide({ title, subtitle, noGradient, content, buttonText, action }) {
  const [searchParams] = useSearchParams();
  const at = searchParams.get('at');
  return (
    <BottomActionAnxy
      content={
        <div className="h-full flex flex-col px-[20px]">
          {/* <div className="fixed top-[100px]">
            <TokenTest />
          </div> */}
          <div className="pt-[12px] text-[24px] leading-[30px] font-bold">{title}</div>
          {subtitle && <div className="pt-[12px] text-[17px] leading-[28px] font-light">{subtitle}</div>}
          <div className={`flex-1 flex flex-col justify-center`}> {content}</div>
        </div>
      }
      noGradient={noGradient}
      buttonText={buttonText}
      action={action}
      bgColor={'#ffffff'}
      fixedRoot={at === undefined}
    />
  );
}

export default function AnxyWebAboutCourse(props) {
  let { id } = useParams();
  id = parseInt(id);

  const [searchParams] = useSearchParams();
  const withoutTest = searchParams.get('a') === 'true';
  const setHeaderState = useSetRecoilState(headerAtom);

  const [checkpointSaveData, checkpointSave] = useFetchAnxy({
    url: `anxy/v2/onboarding/checkpoint/save`,
    requestBody: { checkpointId: 2 },
    bg: '#FFFFFF',
  });

  const goNext = useNavigateWithParams(`/anxyWebAboutCourse/${id + 1}`);
  const goCoreExp = useNavigateWithParams(`/anxyWebCoreExp/0`);

  useEffect(() => {
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_BACK,
      progressBar: { maxCompleted: 17, completed: id + 2 },
    }));
  }, []);

  return id === 0 ? (
    <Guide
      title={'불안의 3요소를 공략할 거예요'}
      subtitle={
        '호흡이 가빠진다면 ‘신체’, 무언가 나쁜 일이 일어날 것처럼 여겨진다면 ‘생각’, 불안함에 자꾸 무언가를 재차 확인한다면 ‘행동’이라고 할 수 있죠.'
      }
      noGradient
      justifyEnd
      content={
        <div className=" w-full  max-w-[375px] mx-auto">
          <img src={anxyWebAboutCourse0} alt={'empty'} />
        </div>
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : (
    <Guide
      title={'모든 내용은 불안 인지행동치료에 기반했어요'}
      content={
        <div className="w-full max-w-[375px] pt-[40px]">
          <img src={anxyWebAboutCourse1} alt={'empty'} />
        </div>
      }
      buttonText={'다음'}
      action={() => {
        if (id < 1) {
          goNext();
        } else {
          checkpointSave();
          goCoreExp();
        }
      }}
    />
  );
}
