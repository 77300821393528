import { FullPageAnxy } from '../../../stories/page/FullPageAnxy';
import anxyColorPalette from '../../../data/anxy/contents/anxyColorPalette';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { marginWithHomeIndicator } from '../../../recoil/common/device';
import { useRecoilValue } from 'recoil';
import { useRef, useState } from 'react';
import { BlackRoundActionButton, SessionBlackRoundActionButton } from '../../../stories/button/RoundActionButton';
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { TextFadeTransition, getTitleDuration } from '../../../stories/transition/TextFadeTransition';
import useInterval from '../../../hooks/useInterval';
import { FocusingLottie } from '../../../stories/animation/FocusingLottie';
import useSaveViewedPage from '../../../hooks/useSaveViewedPage';
import webviewToast from '../../../development/webviewToast';

export default function TrialTemplate({ data, isSessionEnd, isExampleEnd, isExample }) {
  const { id, page } = useParams();
  const [currentTitleId, setCurrentTitleId] = useState(1);
  const [allowNavigate, setAllowNavigate] = useState(false);

  const [searchParams] = useSearchParams();
  const uiType = searchParams.get('uiType');

  const {
    title,
    image,
    bottomImage,
    bottomCss,
    imageLottie,
    imageCss,
    component,
    isBottom,
    isFirst,
    timeout,
    focusType,
    fixedTitleMarginBottom,
    slideUp,
  } = data;

  const location = useLocation();

  const textTimer = useRef(null);

  const setTextTimer = (action) => {
    textTimer.current = setTimeout(() => {
      setAllowNavigate(true);
    }, getTitleDuration(title[currentTitleId - 1]));
    return () => clearTimeout(textTimer.current);
  };

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  const pathname = location.pathname.split('/')[1];

  const goNext = useNavigateWithParams(`../${pathname}/${id}/${parseInt(page) + 1}`, isExample);
  const goTool = useNavigateWithParams(`../managementTool`, true);
  const goHomeA = useNavigateWithParams(`../anxyHomea`);
  const goHomeB = useNavigateWithParams(`../anxyHome`);

  const isPhoneImage = image && image.includes('phone');
  const isEndImage = image && image.includes('end');

  const handleNavigate = () => {
    if (!isSessionEnd) {
      if (isExampleEnd) {
        if (uiType === 'a') {
          goHomeA();
        } else {
          goTool();
        }
      } else {
        goNext();
      }
    } else {
      if (uiType === 'a') {
        goHomeA();
      } else {
        goHomeB();
      }
    }
  };

  useEffect(() => {
    if (allowNavigate) {
      if (!isBottom) {
        handleNavigate();
        setCurrentTitleId(1);
      }
    }
  }, [allowNavigate]);

  useEffect(() => {
    if (title && currentTitleId === title.length) {
      setTextTimer();
    }
    if (currentTitleId === 1) {
      clearTimeout(textTimer.current);
      clearInterval(lottieTimer);
    }
  }, [currentTitleId, title]);

  const handleClick = () => {
    if ((!title || typeof title === 'string' || currentTitleId === title.length) && allowNavigate) {
      setAllowNavigate(false);
      setCurrentTitleId(1);
      handleNavigate();
    }
  };

  useEffect(() => {
    setCurrentTitleId(1);
    setAllowNavigate(false);
  }, [page]);

  const timerId = useInterval(
    () => {
      if (title && (typeof title === 'string' || title.length === 1) && !imageLottie) {
        setAllowNavigate(true);
      } else {
        if (title && currentTitleId < title.length) {
          setCurrentTitleId((currentTitleId) => Math.min(currentTitleId + 1, title.length));
        } else {
          clearInterval(timerId);
        }
      }
    },
    title
      ? typeof title === 'string'
        ? getTitleDuration(title)
        : title[currentTitleId - 1]
        ? getTitleDuration(title[currentTitleId - 1])
        : getTitleDuration(title[0])
      : 0,
    page,
  );

  const lottieTimer = useInterval(
    () => {
      if (timeout) {
        setAllowNavigate(true);
      } else {
        clearInterval(lottieTimer);
      }
    },
    timeout,
    page,
  );

  const focusTypeData = [
    { type: 'button', css: 'right-[10%] bottom-[5%]' },
    { type: 'possibility', css: 'right-[8.6%] bottom-[16.5%]' },

    { type: 'input-1', css: 'right-[10%] top-[24.5%]' },
    { type: 'input-2', css: 'right-[10%] top-[40.5%]' },
    { type: 'input-3_1', css: 'right-[10%] top-[52%]' },
    { type: 'input-3_2', css: 'right-[10%] top-[56%]' },

    { type: 'input_arrow-1_1', css: 'right-[10%] top-[45.5%]' },
    { type: 'input_arrow-1_2', css: 'right-[10%] top-[49.5%]' },
    { type: 'input_arrow-2_1', css: 'right-[10%] bottom-[26%]' },
    { type: 'input_arrow-2_2', css: 'right-[10%] bottom-[22%]' },
    { type: 'input_arrow-3', css: 'right-[10%] bottom-[15.5%]' },

    { type: 'input_progressbar-1', css: 'right-[10%] top-[27%]' },
    { type: 'input_progressbar-2_1', css: 'right-[10%] top-[43%]' },
    { type: 'input_progressbar-2_2', css: 'right-[10%] top-[46.5%]' },
    { type: 'input_progressbar-3_1', css: 'right-[10%] top-[59%]' },
    { type: 'input_progressbar-3_2', css: 'right-[10%] top-[62.5%]' },
    { type: 'input_progressbar-3_3', css: 'right-[10%] top-[66%]' },

    { type: 'behavior_progressbar-1', css: 'right-[10%] top-[24%]' },
    { type: 'behavior_progressbar-2_1', css: 'right-[10%] top-[40%]' },
    { type: 'behavior_progressbar-2_2', css: 'right-[10%] top-[44%]' },
  ];

  useEffect(() => {
    if (window.innerHeight <= 700) {
      webviewToast(window.innerHeight);
    }
  }, []);

  console.log(data);

  return (
    <FullPageAnxy
      content={
        <motion.div className="w-screen h-full  flex flex-col justify-between relative overflow-hidden">
          {title && (
            <div className="text-white pt-[12px]  ">
              <TextFadeTransition
                textList={
                  typeof title === 'string'
                    ? [{ id: 1, title: title }]
                    : title.map((each, index) => ({ id: index + 1, title: each }))
                }
                currentTextId={currentTitleId}
                page={page}
              />
            </div>
          )}
          <div
            className={`w-full flex flex-col ${
              !fixedTitleMarginBottom && 'justify-center'
            } items-center h-full relative`}
          >
            {!isBottom && (image || imageLottie) && (
              <div
                className={`flex flex-col items-center ${
                  fixedTitleMarginBottom
                    ? isSessionEnd
                      ? 'h-[calc(100%-74px)] overflow-auto scrollbar-hide  pt-[30px] pb-[40px] '
                      : 'h-full  pt-[30px]  '
                    : 'h-full  justify-center'
                }`}
              >
                {fixedTitleMarginBottom && isSessionEnd && (
                  <div
                    className="w-full h-[30px] absolute z-[100] translate-y-[-30px]"
                    style={{ background: 'linear-gradient(to bottom, rgba(38, 40, 44, 1), rgba(38, 40, 44, 0))' }}
                  />
                )}
                <motion.div className="relative">
                  {image && (
                    <AnimatePresence exitBeforeEnter>
                      <motion.img
                        key={`${image}`}
                        initial={{
                          opacity: isPhoneImage || isEndImage ? 1 : 0,
                          bottom: isFirst && -110,
                          y: slideUp && 30,
                        }}
                        animate={{ opacity: 1, bottom: 0, y: 0 }}
                        exit={{ opacity: isPhoneImage && !isEndImage ? 1 : 0 }}
                        transition={{ duration: 0.5, opacity: { duration: 0.4 }, ease: 'easeInOut' }}
                        src={image}
                        alt="empty"
                        className={`max-w-[360px] relative z-[50] ${imageCss}`}
                        style={{
                          maxWidth:
                            window.innerHeight <= 667 && (isPhoneImage || isEndImage || fixedTitleMarginBottom) && 330,
                          width: (isPhoneImage || isEndImage || fixedTitleMarginBottom) && 'calc(100vw - 40px)',
                        }}
                      />
                    </AnimatePresence>
                  )}
                  {focusType && (
                    <motion.div
                      key={`${image}${focusType}`}
                      initial={{
                        opacity: 0,
                        display: 'none',
                      }}
                      animate={{ opacity: 1, display: 'flex' }}
                      transition={{ delay: 3 }}
                      className={`absolute ${focusTypeData.find((item) => item.type === focusType).css} z-[50]`}
                      onClick={() => {
                        handleNavigate();
                      }}
                    >
                      <FocusingLottie />
                    </motion.div>
                  )}
                  {imageLottie && isFirst && (
                    <motion.div
                      className="relative h-full "
                      initial={{ opacity: 0, transform: isFirst && 'translateY(110px)' }}
                      animate={{ opacity: 1, transform: isFirst && 'translateY(0px)' }}
                      transition={{ duration: 0.7, opacity: { duration: 0.2 }, ease: 'easeInOut' }}
                    >
                      {imageLottie}
                    </motion.div>
                  )}
                  {imageLottie && !isFirst && (
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        key={`${imageLottie}${page}`}
                        className="z-[50]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {imageLottie}
                      </motion.div>
                    </AnimatePresence>
                  )}
                </motion.div>
              </div>
            )}

            {component && component}

            {fixedTitleMarginBottom && isSessionEnd && (
              <motion.div
                initial={{ opacity: 0, y: 88 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                className={`z-[50] w-full px-[20px] fixed bottom-0`}
              >
                <div
                  className="w-full h-[40px] translate-y-[0px]"
                  style={{ background: 'linear-gradient(to top, rgba(38, 40, 44, 1), rgba(38, 40, 44, 0))' }}
                />
              </motion.div>
            )}
          </div>
          <div className="">
            {/* style={{ height: `calc(100vh - ${titleHeight + 44 + deviceState.notchSize}px)` }}> */}
            {isBottom && image && (
              <div className={`relative overflow-hidden max-w-[375px] m-auto`}>
                {bottomImage && (
                  <motion.img
                    key={`bottomImage${page}`}
                    className={`${bottomCss} absolute top-[11%] translate-y-[40px] left-[50%] translate-x-[-50%] z-[50] object-contain`}
                    alt="empty"
                    src={bottomImage}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7, ease: 'easeInOut' }}
                  />
                )}
                <motion.img
                  key={`image${page}`}
                  src={image}
                  alt="empty"
                  className={`relative z-[40] bottom-[-30px] px-[20px] m-auto`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: isPhoneImage ? 0.7 : 0.5, ease: 'easeInOut' }}
                  style={{
                    width: 'calc(100wv - 40px)',
                  }}
                />
                <div className={`max-w-[375px] ${marginClassName} fixed bottom-0 z-[50] w-full px-[45px]`}>
                  <BlackRoundActionButton state="ACTIVE" text={'체험하기'} round disable action={() => {}} />
                  {allowNavigate && (
                    <motion.div
                      className={`absolute right-[64px] ${marginClassName} bottom-[2px]`}
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      <FocusingLottie />
                    </motion.div>
                  )}
                </div>
              </div>
            )}
          </div>
        </motion.div>
      }
      bgColor={anxyColorPalette.black}
    />
  );
}
