import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AnxyLoadingLottie } from 'stories/animation/AnxyLoadingLottie';
import homeTypeAtom from 'recoil/anxy/home/atom';
import { useSetRecoilState } from 'recoil';
import PaymentConfirmTemplate from 'pages/PaymentConfirmTemplate';

export default function PaymentSuccess() {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('at');
  const appId = searchParams.get('app_id');

  const setHomeType = useSetRecoilState(homeTypeAtom);

  useEffect(() => {
    // setHomeType('anxy')
  }, []);

  return (
    <PaymentConfirmTemplate
      confirmUrl={`/anxy/payment/confirm`}
      sucessUrlPath={`/anxyHome?at=${accessToken}&app_id=${appId}`}
      failUrlPath={`/payment-confirm-fail?at=${accessToken}&app_id=${appId}`}
      loadingComponent={<AnxyLoadingLottie />}
    />
  );
}
