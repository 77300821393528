import React, { useEffect } from 'react';

export default function Content(props) {
  const { image, category, title, subtitle, texts } = props;

  useEffect(() => {
    // window.scrollTo(0, 0);
    document.title = title;
  }, []);

  return (
    <div className={`h-screen `}>
      <div
        className={`w-screen h-full overflow-y-scroll scrollbar-hide select-none `}
        style={{
          WebkitUserSelect: 'none',
          WebkitTouchCallout: 'none',
        }}
      >
        <div className="w-full px-20px ">
          <p
            className="font-bold text-questionTitle leading-title mt-12px mb-24px px-4px "
            style={{ wordBreak: 'keep-all' }}
          >
            {' '}
            {title}
          </p>

          <div
            className={`h-170px rounded-2xl mb-24px 
          ${category === '인사이드 이야기' ? 'bg-[#EFF2FF]' : ''} 
          ${category === '인사이드가 만난 사람들' ? 'bg-[#EBF6FE]' : ''}
          ${category === '상담사 이야기' ? 'bg-[#F1EFFF]' : ''}
          ${
            category !== '인사이드 이야기' && category !== '인사이드가 만난 사람들' && category !== '상담사 이야기'
              ? 'bg-[#FEF7EB]'
              : ''
          } 
          flex align-center `}
          >
            <img className=" m-auto h-140px " src={image} alt="empty" />
          </div>

          <div className="mx-4px">
            <p className="font-semibold text-title mb-10px text-[#26282C]" style={{ wordBreak: 'keep-all' }}>
              {subtitle}
            </p>
            <div>
              {texts.map((each) => (
                <span className="text-content leading-content text-[#4E4F53]">
                  {!each.bold && !each.numbering && <span className={`  `}>{each.text}</span>}
                  {each.bold && each.numbering && (
                    <span className={` font-semibold text-title mt-24px mb-8px block`}>{each.text}</span>
                  )}
                  {each.bold && !each.numbering && <span className={`font-semibold`}>{each.text}</span>}
                </span>
              ))}
            </div>
            <div className="w-auto h-40px bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
}
