import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { hasTouch } from 'detect-touch';

export default function SelectButton(props) {
  const { selected, option, text, isClicked, isWhite } = props;
  const [buttonDown, setButtonDown] = useState(false);
  const [disable, setDisable] = useState(false);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);

  function mouseUp(e) {
    setButtonDown(false);
  }

  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  return (
    <motion.div
      animate={{ scale: buttonDown ? 0.95 : 1 }}
      transition={{ duration: 0.2 }}
      className={`text-left px-[18px] py-[14px] text-[15px] leading-[21px] font-bold
        ${
          selected
            ? isWhite
              ? 'ring-[2px] ring-inset ring-[#2C4BEC] bg-white font-bold text-[#2C4BEC] text-center rounded-[60px] w-fit'
              : 'ring-[1px] ring-inset ring-[#FF6F6F] w-full rounded-[100px] text-[#FF6F6F]'
            : isWhite
            ? 'text-black text-center rounded-[60px] w-fit'
            : 'text-white w-full rounded-[100px]'
        }
        `}
      style={{
        backgroundColor: selected ? (isWhite ? '#FFFFFF' : '') : isWhite ? '#F3F5F8' : 'rgba(255, 255, 255, 0.2)',
      }}
      onMouseDown={(e) => {
        if (!hasTouch) {
          setButtonDown(true);
        }
      }}
      onClick={(e) => {
        if (!hasTouch) {
          isClicked(option);
        }
      }}
      onTouchStart={(e) => {
        e.stopPropagation();
        setButtonDown(true);
        setTouchStartY(e.changedTouches[0].clientY);
        setTouchStartX(e.changedTouches[0].clientX);
        setDisable(false);
      }}
      onTouchMove={(e) => {
        if (
          Math.abs(e.changedTouches[0].clientY - touchStartY) > 10 ||
          Math.abs(e.changedTouches[0].clientX - touchStartX) > 10
        ) {
          setDisable(true);
        }
      }}
      onTouchEnd={(e) => {
        setButtonDown(false);

        if (
          document
            .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
            .includes(e.currentTarget) &&
          !disable
        ) {
          isClicked(option);
        }
      }}
    >
      <p className="mb-[0px] w-fit">{text}</p>
    </motion.div>
  );
}
