/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';

export function Input(props) {
  const {
    backgroundColor,
    inactiveColor,
    activeColor,
    placeholder,
    value,
    maxLength,
    errorMessage,
    autofocus,
    updateValue,
    updateErrorMessage,
    noBlank,
    resetButton,
    autoWidth,
    deleteIfEmpty,
    deleteAction,
    focusAction,
    margin,
  } = props;

  const [isFocused, setFocused] = useState(autofocus);
  const [inputValue, setInputValue] = useState(value);

  const [isInvisible, setInvisible] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (isFocused) {
      updateValue(inputValue);
    }
  }, [inputValue]);

  return (
    !isInvisible && (
      <div style={{ margin: margin || 0 }}>
        <div
          className={`w-auto  rounded-[16px]  ${
            inputValue !== '' ? 'font-bold' : 'font-semibold'
          } flex justify-between relative`}
          style={{
            border:
              errorMessage && errorMessage !== ''
                ? '1.2px solid #E32940'
                : isFocused || inputValue !== ''
                ? `1.2px solid ${activeColor}`
                : `1px solid ${inactiveColor}1A`,
            backgroundColor: isFocused || inputValue !== '' ? `${activeColor}0A` : '',
          }}
        >
          {autoWidth && value === '' && !isFocused && (
            <p className="absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] pointer-events-none text-[17px] leading-[24px] text-[#26282C99] ">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                  <rect x="11" y="5" width="2" height="14" rx="1" fill="#26282C" />
                  <rect x="19" y="11" width="2" height="14" rx="1" transform="rotate(90 19 11)" fill="#26282C" />
                </g>
              </svg>
            </p>
          )}
          <textarea
            className={`w-full py-[15px] rounded-[12px] px-[20px]  text-[17px] leading-[24px]  placeholder:text-[#26282C99] 
     outline-none overflow-visible text-ellipsis `}
            style={{
              width: autoWidth ? '60px' : '100%',
              height: '54px',
              caretColor: activeColor || '#3953D9',
              backgroundColor: backgroundColor || '#ffffff00',
              color: errorMessage && errorMessage !== '' ? '#E32940' : activeColor,
              resize: 'none',
            }}
            enterKeyHint={'done'}
            rows={1}
            wrap={autoWidth ? 'off' : 'soft'}
            placeholder={placeholder}
            value={inputValue}
            maxLength={maxLength}
            ref={inputRef}
            onClick={(e) => {
              e.target.focus();
            }}
            onChange={(e) => {
              if (autoWidth) {
                if (
                  parseInt(inputRef.current.style.width.substring(0, inputRef.current.style.width.length - 2)) + 20 <
                  window.innerWidth - 40
                ) {
                  inputRef.current.style.width = `1px`;
                  inputRef.current.style.width = `${inputRef.current.scrollWidth + 20}px`;
                }
                if (
                  parseInt(inputRef.current.style.width.substring(0, inputRef.current.style.width.length - 2)) + 20 >=
                  window.innerWidth - 40
                ) {
                  inputRef.current.wrap = 'soft';
                  inputRef.current.style.width = `${window.innerWidth - 40}px`;
                }
              }

              inputRef.current.style.height = `1px`;
              inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
              if (autoWidth) {
                if (
                  parseInt(inputRef.current.style.height.substring(0, inputRef.current.style.height.length - 2)) <= 54
                ) {
                  inputRef.current.wrap = 'off';
                  inputRef.current.style.width = `1px`;
                  inputRef.current.style.width = `${inputRef.current.scrollWidth + 20}px`;
                }
              }

              if (noBlank) {
                const noBlank = e.target.value.substring(0, 10).replace(/ /g, '');
                setInputValue(noBlank);
              } else {
                const noLineBreak = e.target.value.replace('\n', '');
                setInputValue(noLineBreak);
              }

              if (updateErrorMessage) {
                updateErrorMessage('');
              }
            }}
            onKeyPress={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
                inputRef.current.blur();

                return false;
              }
            }}
            onFocus={(e) => {
              setFocused(true);
              if (focusAction) {
                focusAction();
              }
            }}
            onBlur={(e) => {
              setFocused(false);
              if (inputValue === '' && deleteIfEmpty) {
                setInvisible(true);
                deleteAction();
              }
            }}
          />
          {resetButton && inputValue !== '' && (
            <div
              className="mr-[20px] flex items-center"
              onClick={(e) => {
                setInputValue('');
                updateErrorMessage('');
              }}
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM12.495 5.50503C12.7683 5.77839 12.7683 6.22161 12.495 6.49497L9.98995 9L12.495 11.505C12.7683 11.7784 12.7683 12.2216 12.495 12.495C12.2216 12.7683 11.7784 12.7683 11.505 12.495L9 9.98995L6.49497 12.495C6.22161 12.7683 5.77839 12.7683 5.50503 12.495C5.23166 12.2216 5.23166 11.7784 5.50503 11.505L8.01005 9L5.50503 6.49497C5.23166 6.22161 5.23166 5.77839 5.50503 5.50503C5.77839 5.23166 6.22161 5.23166 6.49497 5.50503L9 8.01005L11.505 5.50503C11.7784 5.23166 12.2216 5.23166 12.495 5.50503Z"
                  fill="#D1D5DC"
                />
              </svg>
            </div>
          )}
        </div>
        {errorMessage && <div className="text-[#E32940] text-[15px] leading-[18px] mt-[10px] ">{errorMessage}</div>}
      </div>
    )
  );
}

// SelectButton.propTypes = {
//   selected:PropTypes.bool,
//    option:undefined,
//    text:PropTypes.string,
//    isClicked:PropTypes.func,
//    disable:PropTypes.bool
// };

// SelectButton.defaultProps = {
//   state: 'ACTIVE',
//   activeColor: '#3953D9',
//   inactiveColor: '#E1E4EB',
//   textColor: '#ffffff',
//   text: '다음',
//   action: undefined,
//   small: false,
// };
