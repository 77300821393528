import { atom } from 'recoil';

export const STATUS_DREAM = 'dream';
export const STATUS_RECORD = 'record';
export const STATUS_TALE = 'tale';
export const STATUS_TALE_DONE = 'tale_done';
export const STATUS_KNIT_OPEN = 'knit_open';

const statusAtom = atom({
  key: 'somny_status',
  default: {
    availableSleepFairyTaleId: undefined,
    isCompleteTodaySleepFairyTale: undefined,
    isCompleteTodaySleepRecord: undefined,
    isPaymentRequired: true,
    somnyStatus: STATUS_TALE,
    totalCountCompletedFairyTale: undefined,
    totalCountCompletedSleepRecord: undefined,
    payedAt: undefined,
  },
});

export default statusAtom;
