import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import 'moment/locale/ko';
import WorryNoteInput from './WorryNoteInput';
import useFetch from '../../../hooks/useFetch';
import useSessionStorage from '../../../hooks/useSessionStorage';

const defaultValue = {
  situation: '',
  thought: '',
  thoughtTrapIdList: [],
  newThought: '',
};

function SituationAndThought() {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);
  const [trapInfo, setTrapInfo] = useSessionStorage('trapInfo', defaultValue);

  const [buttonState, setButtonState] = useState('INACTIVE');
  const [buttonText, setButtonText] = useState('다음');

  const [worryInfo, getWorryInfo] = useFetch({
    url: '/sleep/thought-note/submit',
  });

  const firstRef = useRef();
  const secondRef = useRef();

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  useEffect(() => {
    if (worryInfo) {
      if (worryInfo.result) {
        let updated = { ...trapInfo };
        updated.situation = worryInfo.result.situation;
        updated.thought = worryInfo.result.thought;
        setTrapInfo(updated);
      }
    }
  }, [worryInfo]);

  useEffect(() => {
    // 정보 불러오기
    // getWorryInfo();
    // 초기화
    setButtonState('INACTIVE');
  }, []);

  useEffect(() => {
    if (trapInfo.situation === '' || trapInfo.thought === '') {
      setButtonState('INACTIVE');
    } else {
      setButtonState('ACTIVE');
    }
  }, [trapInfo]);

  const noteData = [
    {
      title: '사건',
      placeholder: '어떤 일이 있었는지 사실을 기반으로 상황을 객관적으로 적어보세요. ',
    },
    {
      title: '생각',
      placeholder: '그 일을 겪기 직전 혹은 겪은 이후에 어떤 생각을 했나요? ',
    },
  ];

  return (
    <div className={`w-full px-20px h-[calc(100vh-17px)] overflow-hidden relative `}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="flex flex-col">
          <div className="mb-[40px]">
            <div
              className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C] mt-[50px]"
              style={{ wordBreak: 'keep-all' }}
            >
              {'최근 불면증과 관련된 사건 중 나의 심기를 건드린 일에 대해 적어보세요.'}
            </div>
          </div>
          <div className="grid gap-[40px]">
            {noteData.map((item, index) => (
              <WorryNoteInput
                index={index}
                title={item.title}
                placeholder={item.placeholder}
                currentRef={index === 0 ? firstRef : secondRef}
                nextRef={index === 0 ? secondRef : ''}
                param={index === 0 ? 'situation' : 'thought'}
                data={trapInfo}
                setData={setTrapInfo}
              />
            ))}
          </div>
        </div>
        <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px] mt-[40px]`}>
          <SecondaryActionButton state={buttonState} text={buttonText} action={goNext} />
        </div>
      </div>
    </div>
  );
}
export default SituationAndThought;
