/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { BottomActionAnxy } from 'stories/page/BottomActionAnxy';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from 'recoil/common/header/atom';
import userAtom from 'recoil/anxy/user/atom';
import Wori from 'components/anxy/anxy12ndA/Wori';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { impactFeedback } from 'utils/webview';

export default function WorryScore({ title, action, buttonText, buttonState, bgColor, initialScore, isInitialMax }) {
  const setHeaderState = useSetRecoilState(headerAtom);
  const setUserState = useSetRecoilState(userAtom);

  const woriContainerRef = useRef();

  const [score, setScore] = useState(initialScore);

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
  }, []);

  useDidMountEffect(() => {
    impactFeedback('selection');
  }, [score]);

  useEffect(() => {
    setUserState((state) => ({
      ...state,
      anxietyScore: score,
    }));
  }, [score]);

  return (
    <BottomActionAnxy
      content={
        <div className="h-full flex flex-col">
          <div className="pt-[12px] text-[24px] leading-[30px] font-bold px-[20px]">{title}</div>
          <div className={`flex-1 flex flex-col justify-center`}>
            <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
              <div className="flex-1 relative" ref={woriContainerRef}>
                <div
                  className="absolute bottom-0 left-[50%]  origin-bottom "
                  style={{
                    transform: `translateX(-50%) scale(${
                      Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                    })`,
                  }}
                >
                  <Wori score={score} duration={0} />
                </div>
              </div>
              <div className="w-full px-[20px] flex flex-col ">
                <p className="text-[50px] font-bold text-center">{score}</p>
                <div className="flex px-[4px] w-full justify-between text-[15px] leading-[21px] text-[#6B6D76] mb-[8px]">
                  <p>매우 평온</p>
                  <p>매우 불안</p>
                </div>
                <div
                  className="w-full h-[24px] rounded-[50px]  px-[22px]"
                  style={{
                    background: `linear-gradient(90deg, rgba(239, 103, 11) 50%, rgba(253, 240, 231) 50%)`,
                  }}
                >
                  <div
                    className="w-full h-full  relative"
                    style={{
                      background: `linear-gradient(90deg, rgba(239, 103, 11) ${score}%, rgba(253, 240, 231) ${score}%)`,
                    }}
                  >
                    <div
                      className=" w-[44px] h-[44px] rounded-[50%] bg-white ring-[1px] ring-inset ring-[#F3F5F8] absolute top-[50%] translate-x-[-50%] translate-y-[-50%]
                flex items-center justify-center"
                      style={{ left: `${score}%`, boxShadow: '0px 6px 8px -2px rgba(0, 0, 0, 0.2)' }}
                      onTouchMove={(e) => {
                        setScore(
                          !isInitialMax
                            ? Math.round(
                                Math.max(
                                  0,
                                  Math.min(
                                    100,
                                    parseInt(((e.changedTouches[0].clientX - 20) / (window.innerWidth - 40)) * 100),
                                  ),
                                ) / 10,
                              ) * 10
                            : Math.min(
                                Math.round(
                                  Math.max(
                                    0,
                                    Math.min(
                                      100,
                                      parseInt(((e.changedTouches[0].clientX - 20) / (window.innerWidth - 40)) * 100),
                                    ),
                                  ) / 10,
                                ) * 10,
                                initialScore,
                              ),
                        );
                      }}
                    >
                      <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="9" width="3" height="18" rx="1.5" fill="#D1D5DC" />
                        <rect width="3" height="18" rx="1.5" fill="#D1D5DC" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      noGradient
      buttonState={buttonState || 'ACTIVE'}
      buttonText={buttonText}
      action={() => {
        action(score);
      }}
      bgColor={bgColor || '#ffffff'}
    />
  );
}
