import React, { useState, useEffect, useRef } from 'react';
import { ProgressingBarThin } from '../../stories/else/ProgressingBarThin';
import { CheckLottie } from '../../stories/animation/CheckLottie';

export default function CurrentProcess(props) {
  const { id } = props;
  const [progressbarAnimation, setProgressbarAnimation] = useState(false);
  const [focusNext, setFocusNext] = useState(false);
  const [progressbarLength, setProgessbarLength] = useState([0, 0]);
  const textRef1 = useRef();
  const textRef2 = useRef();
  const textRefs = [textRef1, textRef2];

  useEffect(() => {
    if (id === 0) {
      setFocusNext(true);
    } else {
      setTimeout(() => {
        setProgressbarAnimation(true);
        setTimeout(() => {
          setFocusNext(true);
        }, 600);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (textRef1.current && textRef2.current) {
      console.log(textRef1.current.offsetHeight, textRef2.current.offsetHeight);
      setProgessbarLength([textRef1.current.offsetHeight, textRef2.current.offsetHeight]);
    }
  }, []);

  const steps = [
    {
      title: '기본적인 정보를 알려주세요.',
      subtitle: '직업이나 과거의 상담/진료 경험을 토대로 더욱 개인화된 멘탈 케어를 제공해드려요.',
    },
    {
      title: '현재 마음 건강 상태를 알아볼게요.',
      subtitle: '마음 종합검진으로 나의 상태를 정확히 파악해요. 그리고 최적의 목표를 설정해요.',
    },
    // { title: '함께할 코치를 찾아볼게요.', subtitle: '나의 상태와 목표, 성향에 꼭맞는 코치를 찾아 배정해드려요.' },
  ];

  return (
    <div className="w-full  ">
      <div className="pt-[10px] px-[20px]  ">
        {steps.map((step, index) => (
          <div className={` flex  items-start  relative pb-[30px] `} key={`step${index}`} ref={textRefs[index]}>
            <div className="flex flex-col ">
              <div
                className={`w-[28px] h-[28px] rounded-[28px] bg-white ring-[1px] ring-inset ${
                  index <= id && focusNext ? 'ring-[#373EED] text-[#2C4BEC]' : 'ring-[#D1D5DC] text-[#A4A6B0]'
                } flex justify-center items-center  z-10 shrink-0 mr-[10px] relative overflow-hidden transition-all`}
              >
                {index < id - 1 && (
                  <div className="w-full h-full bg-qna flex justify-center items-center absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.68848 3.65376C1.39105 3.36548 0.916235 3.37289 0.627953 3.67033C0.339672 3.96776 0.347091 4.44258 0.644524 4.73086L1.68848 3.65376ZM5.61095 8.5L5.08897 9.03855C5.37985 9.32048 5.84205 9.32048 6.13293 9.03855L5.61095 8.5ZM13.3552 2.03855C13.6526 1.75027 13.66 1.27545 13.3717 0.97802C13.0834 0.680587 12.6086 0.673168 12.3112 0.961449L13.3552 2.03855ZM0.644524 4.73086L5.08897 9.03855L6.13293 7.96145L1.68848 3.65376L0.644524 4.73086ZM6.13293 9.03855L13.3552 2.03855L12.3112 0.961449L5.08897 7.96145L6.13293 9.03855Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
                {index === id - 1 && (
                  <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                    <CheckLottie containerSize={'29px'} gradient />
                  </div>
                )}
                <p className={`text-[15px] leading-[21px] font-normal `}>{index + 1}</p>
              </div>
              {index !== steps.length - 1 && (
                <div className="w-[28px]  ">
                  <div
                    className="origin-left rotate-90 translate-y-[-1px] translate-x-[14px] z-0"
                    style={{ width: `${progressbarLength[index] - 28}px` }}
                  >
                    <ProgressingBarThin
                      completed={index <= id - 1 ? (index < id - 1 ? 1 : progressbarAnimation ? 1 : '') : 0}
                      color={'#2C4BEC'}
                      height={'1.2px'}
                      animateOnRender={index === id - 1}
                      duration={'0.6s'}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <p className="text-[18px] leading-[24px]  text-[#26282C] font-bold mb-[6px]">{step.title}</p>
              <p className="text-[15px] leading-[21px]  text-[#80838B] font-normal ">{step.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
