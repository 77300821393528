import React, { useEffect, useState, useRef } from 'react';
import { AnimatedNumber } from '../../stories/animation/AnimatedNumber';
import { motion } from 'framer-motion';

export default function SubtractAnxy() {
  const [num, setNum] = useState(80);
  const wrapperRef = useRef();

  useEffect(() => {
    setNum(() => 30);
  }, []);
  return (
    <div className="w-full h-[60px] flex flex-col items-center justify-center">
      <div className="w-full flex justify-center items-center absolute" ref={wrapperRef}>
        <motion.div className="flex justify-center absolute">
          <AnimatedNumber isAnxy num={parseInt((num % 100) / 10)} digit={2} />
          <AnimatedNumber isAnxy num={num % 10} digit={3} />
        </motion.div>
      </div>
    </div>
  );
}
