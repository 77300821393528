import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FullPageAnxy } from '../../../stories/page/FullPageAnxy';
import { motion } from 'framer-motion';
import anxyColorPalette from '../../../data/anxy/contents/anxyColorPalette';
import { FocusingLottie } from '../../../stories/animation/FocusingLottie';
import { 생각, 행동, 신체 } from './textSvg.js';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { TextFadeTransition, getTitleDuration } from '../../../stories/transition/TextFadeTransition';

function CircleModule({
  id,
  backgroundColor,
  isFocused,
  setCurrentFocusId,
  isNext,
  showFocusingLottie,
  setShowFocusingLottie,
  isInitialized,
}) {
  return (
    <div
      className="w-fit h-fit relative"
      onClick={() => {
        if (showFocusingLottie && isNext) {
          setCurrentFocusId((currentFocusId) => currentFocusId + 1);
          setShowFocusingLottie(false);
        }
      }}
    >
      <motion.div
        initial={{ transform: 'scale(1)' }}
        animate={{
          transform: !isInitialized ? (isFocused ? 'scale(1.083)' : 'scale(0.833)') : 'scale(1)',
          opacity: isFocused || isInitialized ? 1 : 0.2,
        }}
        transition={{ duration: 0.5 }}
        className="w-[120px] h-[120px] rounded-[50%] flex items-center justify-center"
        style={{ backgroundColor: backgroundColor }}
      >
        {id === 0 ? 생각 : id === 1 ? 행동 : 신체}
      </motion.div>
      {isNext && showFocusingLottie && (
        <div className="absolute bottom-0 right-0">
          <FocusingLottie />
        </div>
      )}
    </div>
  );
}

export default function Intro0() {
  const { id, page } = useParams();
  const [currentTitleId, setCurrentTitleId] = useState(1);
  const [currentTextId, setCurrentTextId] = useState(1);

  const location = useLocation();
  const pathname = location.pathname.split('/')[1];

  const goNext = useNavigateWithParams(`../${pathname}/${id}/${parseInt(page) + 1}`);
  // const goNext = useNavigateWithParams(`/intro/1`);
  const titles = [
    { id: 1, title: '바로 불안의 3요소\n때문인데요' },
    { id: 2, title: '불안의 3요소는\n신체, 생각, 행동으로' },
    { id: 3, title: '서로 영향을 끼치며\n불안을 가중시키죠' },
  ];

  useEffect(() => {
    setTimeout(() => {
      console.log('duration', getTitleDuration(titles[currentTitleId - 1].title));
      if (currentTitleId < titles.length) {
        setCurrentTitleId((currentTitleId) => currentTitleId + 1);
      } else {
        goNext();
      }
      if (currentTitleId === 1) {
        setCurrentTextId(2);
      }
    }, getTitleDuration(titles[currentTitleId - 1].title));
  }, [currentTitleId]);

  return (
    <FullPageAnxy
      content={
        <div className={`w-screen h-full flex flex-col pt-[11px]`}>
          <TextFadeTransition textList={titles} currentTextId={currentTitleId} />

          <div className="flex-1 flex flex-col justify-center  ">
            <div className="w-full h-fit relative px-[20px]">
              <div
                className={`w-full max-w-[310px] max-h-[300px] mx-auto   relative`}
                style={{ height: window.innerWidth * (30 / 31) }}
              >
                {[
                  { id: 0, name: '생각', backgroundColor: '#398047', className: 'absolute bottom-[0px] left-[0px] ' },
                  { id: 1, name: '행동', backgroundColor: '#EF670B', className: 'absolute bottom-[0px] right-[0px] ' },
                  {
                    id: 2,
                    name: '신체',
                    backgroundColor: '#F8CF46',
                    className: 'absolute top-[0px] left-[50%] translate-x-[-50%]',
                  },
                ].map((each, index) => (
                  <div className={`${each.className}`} key={`불안순환고리${index}`}>
                    <CircleModule id={each.id} backgroundColor={each.backgroundColor} isInitialized />
                  </div>
                ))}

                <div className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[calc(-50% - 10px)]">
                  <TextFadeTransition
                    textList={[
                      { id: 1, title: '' },
                      { id: 2, title: '불안의\n순환고리' },
                    ]}
                    currentTextId={currentTextId}
                    height={'46px'}
                    fontSize={'17px'}
                    lineHeight={'23px'}
                  />
                </div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: currentTitleId > 1 ? 1 : 0 }}
                  transition={{ duration: 1 }}
                >
                  {/* 아래 화살표 */}
                  <div className="absolute bottom-[10px] left-[50%] translate-x-[-50%]">
                    <svg width="56" height="18" viewBox="0 0 56 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.1"
                        d="M55.3944 2.1183C55.3944 1.28987 54.7228 0.618298 53.8944 0.618296L40.3944 0.618297C39.566 0.618297 38.8944 1.28987 38.8944 2.1183C38.8944 2.94673 39.566 3.6183 40.3944 3.6183L52.3944 3.6183L52.3944 15.6183C52.3944 16.4467 53.066 17.1183 53.8944 17.1183C54.7228 17.1183 55.3944 16.4467 55.3944 15.6183L55.3944 2.1183ZM1.56849 0.618296C0.740066 0.618298 0.0684928 1.28987 0.0684918 2.1183L0.0684923 15.6183C0.0684923 16.4467 0.740065 17.1183 1.56849 17.1183C2.39692 17.1183 3.06849 16.4467 3.06849 15.6183L3.06849 3.6183L15.0685 3.6183C15.8969 3.6183 16.5685 2.94673 16.5685 2.1183C16.5685 1.28987 15.8969 0.618297 15.0685 0.618297L1.56849 0.618296ZM52.8337 1.05764C38.9701 14.9212 16.4928 14.9212 2.62915 1.05764L0.507832 3.17896C15.543 18.2141 39.9199 18.2141 54.9551 3.17896L52.8337 1.05764Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  {/* 왼쪽 화살표 */}
                  <div className="absolute top-[95px] left-[50%] translate-x-[calc(-50%-95px)]">
                    <svg width="38" height="58" viewBox="0 0 38 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.1"
                        d="M13.4091 57.0239C14.1637 57.3659 15.0526 57.0315 15.3946 56.277L20.9679 43.9811C21.3099 43.2265 20.9754 42.3376 20.2209 41.9956C19.4664 41.6536 18.5775 41.988 18.2354 42.7426L13.2814 53.6722L2.35175 48.7182C1.59722 48.3762 0.708297 48.7106 0.366293 49.4652C0.0242891 50.2197 0.358713 51.1086 1.11325 51.4506L13.4091 57.0239ZM36.9966 8.61822C37.3386 7.86368 37.0041 6.97476 36.2496 6.63276L23.9537 1.05948C23.1992 0.717476 22.3103 1.0519 21.9683 1.80644C21.6263 2.56097 21.9607 3.44989 22.7152 3.7919L33.6449 8.74592L28.6909 19.6756C28.3489 20.4301 28.6833 21.319 29.4378 21.6611C30.1924 22.0031 31.0813 21.6686 31.4233 20.9141L36.9966 8.61822ZM15.4323 55.1295C8.52864 36.7791 17.8081 16.3066 36.1585 9.4029L35.1022 6.59503C15.201 14.0821 5.13737 36.2847 12.6244 56.1859L15.4323 55.1295Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  {/* 오른쪽 화살표 */}
                  <div className="absolute top-[95px] left-[50%] translate-x-[calc(-50%+95px)]">
                    <svg width="38" height="58" viewBox="0 0 38 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.1"
                        d="M24.6397 57.0239C23.8852 57.3659 22.9962 57.0315 22.6542 56.277L17.081 43.9811C16.739 43.2265 17.0734 42.3376 17.8279 41.9956C18.5825 41.6536 19.4714 41.988 19.8134 42.7426L24.7674 53.6722L35.6971 48.7182C36.4516 48.3762 37.3405 48.7106 37.6825 49.4652C38.0245 50.2197 37.6901 51.1086 36.9356 51.4506L24.6397 57.0239ZM1.05227 8.61822C0.710261 7.86368 1.04468 6.97476 1.79922 6.63276L14.0951 1.05948C14.8496 0.717476 15.7386 1.0519 16.0806 1.80644C16.4226 2.56097 16.0881 3.44989 15.3336 3.7919L4.40394 8.74592L9.35796 19.6756C9.69996 20.4301 9.36554 21.319 8.611 21.6611C7.85647 22.0031 6.96754 21.6686 6.62554 20.9141L1.05227 8.61822ZM22.6165 55.1295C29.5202 36.7791 20.2407 16.3066 1.8903 9.4029L2.94665 6.59503C22.8478 14.0821 32.9115 36.2847 25.4244 56.1859L22.6165 55.1295Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      }
      bgColor={anxyColorPalette.black}
      transparentHeader
    />
  );
}
