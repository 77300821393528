/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import AssignedCounselorContent from '../../components/counselor/AssignedCounselorContent';
import { changeHeader, goToHome } from '../../utils/webview';
import { TopTitleBottomActionWithDefault } from '../../stories/page/TopTitleBottomActionWithDefault';
import { goToScreen } from '../../utils/webview';
import useMixpanelEvent from '../../hooks/useMixpanelEvent';

export default function AssignCounselorDone(props) {
  const { counselor } = props;
  console.log('상담사', counselor);
  const setMixPanel = useMixpanelEvent({
    eventName: '상담사 배정 완료',
  });

  useEffect(() => {
    changeHeader({ backgroundColor: '#FFFFFF', buttonType: 'none' });
    setMixPanel();
  }, []);

  const goCounselor = (id) => {
    goToScreen({
      screenName: 'counselorDetail',
      counselorId: id,
    });
  };

  // const goHome = useNavigateWithParams(`/home`);
  // const goHome = () => goToTab({ tabName: 'home' });
  const goHome = () => goToHome();
  // const goCounselorReservation = (id, name) => {
  //   goToScreen({
  //     screenName: 'reservation',
  //     counselorId: id,
  //     counselorName: name,
  //   });
  // };

  return (
    <div className={`w-screen h-screen  overflow-auto bg-white`}>
      {counselor.counselorId && (
        <TopTitleBottomActionWithDefault
          title={'전담 상담사와 첫 상담을 예약하세요'}
          content={
            <div className="w-screen h-full px-[20px]  py-[30px] flex flex-col justify-center  relative">
              <AssignedCounselorContent counselor={counselor} />
            </div>
          }
          buttonText={'선생님 더 알아보기'}
          defaultButtonText={'예약은 다음에 할게요'}
          buttonState={'ACTIVE'}
          action={() => goCounselor(counselor.counselorId)}
          defaultAction={() => {
            goHome();
          }}
          isGradient
        />
      )}
    </div>
  );
}
