/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { LoadingLottie } from '../../stories/animation/LoadingLottie';

export default function LoadSid() {
  const [searchParams] = useSearchParams();
  const [sid, setSid] = useState(searchParams.get('sid'));
  const [data, getSid] = useFetch({
    url: `/mindscan/sid/load`,
    bg: '#F3F5F8',
  });

  const goMindScanHome = useNavigateWithParams(`/mindScanHome?sid=${sid}`);

  useEffect(() => {
    if (data) {
      console.log(data.result);
      setSid(data.result);
    }
  }, [data]);

  useEffect(() => {
    if (sid) {
      goMindScanHome();
    }
  }, [sid]);

  useEffect(() => {
    window.document.title = '마음 스캔';
    window.document.body.style.backgroundColor = '#F3F5F8';

    const root = document.getElementById('root');
    root.style.position = 'relative';
    root.style.overflow = 'auto';
    if (!sid) {
      getSid();
    }
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <LoadingLottie containerSize="72px" />
    </div>
  );
}
