export default function InsideTitle({ title, isCenter, insideBlack }) {
  return (
    <p
      className={`${
        insideBlack
          ? 'text-[28px] leading-[33.6px] font-semibold text-white'
          : 'text-[24px] leading-[30px] font-bold text-black pt-[12px]'
      } ${isCenter && 'text-center'} `}
      style={{
        wordBreak: 'keep-all',
      }}
    >
      {title}
    </p>
  );
}
