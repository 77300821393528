/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React from 'react';

export default function SleepSolution(props) {
  const { id, time } = props;
  console.log(time);
  return (
    <div className="rounded-[16px] bg-white pl-[24px] pr-[20px] h-[90px] flex items-center">
      <div>
        {id === 1 ? (
          <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.6084 1.95527C1.6084 0.961162 2.41429 0.155273 3.4084 0.155273H18.591C19.5851 0.155273 20.391 0.961161 20.391 1.95527V6.41614H1.6084V1.95527ZM5.13135 5.07341C5.13135 4.0793 5.93724 3.27341 6.93135 3.27341H15.0705C16.0646 3.27341 16.8705 4.07929 16.8705 5.07341V6.40384H5.13135V5.07341ZM1.24297 7.3457C0.580227 7.3457 0.0429688 7.88296 0.0429688 8.5457V11.1262V13.4761V15.8219H2.39079V13.4761H19.6084V15.8219H21.9562V11.1262H21.956V8.5457C21.956 7.88296 21.4187 7.3457 20.756 7.3457H1.24297Z"
              fill="#A0A5BE"
            />
          </svg>
        ) : (
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.248 0.0045166H13.748V4.50452H12.248V0.0045166ZM17.498 12.0045C17.498 14.4898 15.4833 16.5045 12.998 16.5045C10.5128 16.5045 8.49805 14.4898 8.49805 12.0045C8.49805 9.51924 10.5128 7.50452 12.998 7.50452C15.4833 7.50452 17.498 9.51924 17.498 12.0045ZM5.04455 2.9792L3.98389 4.03986L7.16587 7.22184L8.22653 6.16118L5.04455 2.9792ZM24.9995 12.7545H20.4995V11.2545H24.9995V12.7545ZM22.0123 4.06109L20.9517 3.00043L17.7697 6.18241L18.8303 7.24307L22.0123 4.06109ZM13.748 19.4984V23.9984H12.248V19.4984H13.748ZM18.8312 16.7768L17.7705 17.8375L20.9525 21.0194L22.0131 19.9588L18.8312 16.7768ZM5.49756 11.2545V12.7545H0.997559V11.2545H5.49756ZM8.2257 17.8485L7.16504 16.7878L3.98306 19.9698L5.04372 21.0305L8.2257 17.8485Z"
              fill="#FFC700"
            />
          </svg>
        )}
      </div>
      <div className="flex-1 text-[16px] leading-[22px] pl-[12px]">
        {id === 1 ? '침대에 들어가는 시각' : '잠에서 깨는 시각'}
      </div>

      {time ? (
        <div className="text-[18px] leading-[24px] text-[#2C4BEC] font-bold">
          {time.split(':')[0]}:{time.split(':')[1]}
        </div>
      ) : (
        <div className="text-[18px] leading-[24px] text-[#D1D5DC] font-bold">처방 예정</div>
      )}
    </div>
  );
}
