/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { RoundBottomAction } from '../../stories/page/RoundBottomAction';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE, BUTTON_BACK } from '../../recoil/common/header/atom';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import loomyWebPreview1 from '../../image/loomy/web/loomyWebPreview1.png';
import loomyWebPreview2 from '../../image/loomy/web/loomyWebPreview2.png';
import loomyPreview2 from '../../image/loomy/loomyPreview2.png';
import loomyWalkthrough1 from '../../image/loomy/loomyWalkthrough1.png';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

function Template({ title, subtitle, noGradient, image, buttonText, action, justifyEnd, css, fixedRoot }) {
  // return (
  //   <RoundBottomAction
  //     content={
  //       <div className="h-full flex flex-col">
  //         <div className="flex flex-col gap-[12px] px-[20px] text-[#11383B]">
  //           <div className="pt-[12px] text-[24px] leading-[30px] font-bold">{title}</div>
  //           <div className="text-[17px] leading-[24px]">{subtitle}</div>
  //         </div>
  //         <div
  //           className={`flex-1 flex flex-col ${
  //             justifyEnd ? 'justify-end' : 'justify-center'
  //           } relative max-w-[375px] mx-auto`}
  //         >
  //           <img src={image} alt={'empty'} className={` ${css}`} />
  //         </div>
  //       </div>
  //     }
  //     noGradient={noGradient}
  //     buttonText={buttonText}
  //     action={action}
  //     bgColor={'#F3F0E7'}
  //     fixedRoot={fixedRoot}
  //     isLoomy
  //   />
  // );
  return (
    <RoundBottomAction
      content={
        <div className="h-full flex flex-col">
          <div className="flex flex-col gap-[12px] px-[20px] text-[#11383B]">
            <div className="pt-[12px] text-[24px] leading-[30px] font-bold">{title}</div>
            <div className="text-[17px] leading-[24px]">{subtitle}</div>
          </div>
          <div className={`w-full h-full flex-1 flex flex-col justify-center relative max-w-[375px] mx-auto `}>
            <img
              src={image}
              alt={'empty'}
              className={`px-[20px] absolute left-[50%] translate-x-[-50%] h-full object-contain ${css}`}
            />
          </div>
        </div>
      }
      noGradient={noGradient}
      buttonText={buttonText}
      action={action}
      bgColor={'#F3F0E7'}
      fixedRoot={fixedRoot}
      isLoomy
    />
  );
}

export default function LoomyPreview(props) {
  let { id } = useParams();
  id = parseInt(id);

  const [searchParams] = useSearchParams();
  const app = searchParams.get('app') === 'true';

  const goNext = useNavigateWithParams(`/loomyPreview/${id + 1}`);
  const goWalkthrough = useNavigateWithParams(`/loomyWalkthrough/0`);
  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  const previewData = [
    {
      id: 0,
      title: 'Loomy는 우울의 네 가지 요소를 공략해 우울감을 개선하는 앱이에요',
      image: loomyWalkthrough1,
      css: `bottom-[-35px] ${app ? 'max-w-[300px]' : 'max-w-[270px]'} object-contain object-bottom`,

      mixpanel: '(L1) 도입부 1',
    },
    {
      id: 1,
      title: '우울에는 네 가지 원인이 있어요',
      subtitle:
        '단순히 기분이 가라앉아서 우울한 것이 아니에요. 다섯 가지 요인이 복합적으로 얽혀 우울과 무기력을 만든답니다.',
      image: loomyWebPreview1,
      mixpanel: '(L1) 도입부 2',
    },
    {
      id: 2,
      title: '사람마다 주요 원인이 달라요',
      subtitle: '부정적인 생각 때문에 우울해지는 사람도 있고, 아무런 외부 활동도 하지 않아 우울해지는 사람도 있어요.',
      image: loomyWebPreview2,
      mixpanel: '(L1) 도입부 3',
    },
  ];

  const currentPreviewData = previewData.find((element) => element.id === id);

  const setMixpanel = useMixpanelWeb({
    eventName: `${currentPreviewData.mixpanel}`,
    projectName: 'loomy',
  });

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    document.title = `Loomy`;

    if (id === 0) {
      setHeaderState({ headerButtonType: BUTTON_NONE, headerTitle: '' });
    } else {
      setHeaderState({ headerButtonType: BUTTON_BACK, headerTitle: '' });
    }
    setMixpanel();
  }, []);

  return (
    <Template
      title={currentPreviewData.title}
      subtitle={currentPreviewData.subtitle}
      noGradient
      image={currentPreviewData.image}
      css={currentPreviewData.css}
      buttonText={'다음'}
      action={id < 2 ? goNext : goWalkthrough}
      fixedRoot={!app}
    />
  );
}
