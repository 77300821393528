import moment from 'moment';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import faceByScoreData from '../../../data/anxy/faceByScroreData';
import webviewToast from '../../../development/webviewToast';
import useNavigateSession from '../../../hooks/useNavigateSession';
import worryNoteAtom from '../../../recoil/anxy/worryNote/atom';
import backgroundColorAtom from '../../../recoil/common/backgroundColor/atom';
import deviceAtom, { marginWithHomeIndicator } from '../../../recoil/common/device';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import { AnxyActionButton } from '../../../stories/button/ActionButton';
import ContainerByDeviceAnxy from '../../elements/ContainerByDeviceAnxy';
import { getDay } from '../../sleepingReport/calculateTime';

const TitleAndTextList = ({ title, textList }) => {
  return (
    <div>
      <p className="text-[17px] leading-[23px] font-bold mb-[13px]">{title}</p>
      <div className="flex flex-col gap-[10px]">
        {textList.map((item, index) => (
          <div>
            <div className="bg-[#F3F5F8] rounded-[12px] px-[20px] py-[14px] text-[17px] leading-[23px]">
              {item === '' && index === 0 ? '이전에 입력한 내용이 없어요' : item}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const WorryNoteResult = () => {
  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const worryNoteState = useRecoilValue(worryNoteAtom);

  const goToNextPage = useNavigateSession({});

  const deviceState = useRecoilValue(deviceAtom);
  const [headerState, setHeaderState] = useRecoilState(headerAtom);
  const backgroundColor = useRecoilValue(backgroundColorAtom);

  useEffect(() => {
    console.log(headerState);
    setTimeout(() => {
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_BACK,
        headerTitle: '걱정 일기',
      }));
    }, 300);
    return () => {
      setHeaderState((state) => ({ ...state, headerTitle: '', headerBgColor: '' }));
    };
  }, []);

  function handleScroll(scrollTop) {
    const notchSize = deviceState.notchSize;
    const headerHeight = 44;
    const limitHeight = notchSize === 0 ? headerHeight : notchSize;

    if (scrollTop > limitHeight) {
      setHeaderState({
        headerButtonType: BUTTON_BACK,
        headerTitle: '걱정 일기',
        headerColor: '#26282C',
        headerBgColor: '#FFFFFF',
      });
    } else {
      setHeaderState({
        headerButtonType: BUTTON_BACK,
        headerTitle: '걱정 일기',
        headerColor: '#FFFFFF',
        headerBgColor: backgroundColor,
      });
    }
  }

  return (
    <ContainerByDeviceAnxy bgColor={backgroundColor} handleScroll={handleScroll}>
      <div
        className="w-full text-white text-center"
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <p className="text-[14px] leading-[20px] opacity-[0.8]">{`${moment(new Date()).format(
          'YYYY. MM. DD. ',
        )} ${getDay(moment(new Date()).format('YYYY-MM-DD'))}요일`}</p>
        <div className="flex flex-col justify-center items-center pb-[40px]">
          <div className="w-full h-[206px] my-[25px]">
            <img
              src={faceByScoreData[parseInt(worryNoteState.score / 20)].image}
              alt="empty"
              className="h-full w-full object-contain"
            ></img>
          </div>
          <p className="text-[30px] leading-[36px] font-bold">{`불안 ${worryNoteState.score}`}</p>
        </div>
        <div
          className={`bg-white w-full text-[#26282C] text-left px-[20px] pt-[40px] gap-[40px]
        ${marginClassName}
        `}
          style={{ borderRadius: '20px 20px 0 0' }}
        >
          <div className="flex flex-col gap-[40px]">
            <TitleAndTextList title="외부 요인" textList={worryNoteState.externalFactorList} />
            <TitleAndTextList title="내부 요인" textList={worryNoteState.internalFactorList} />
            <TitleAndTextList title="신체" textList={worryNoteState.bodyReactionList} />
            <TitleAndTextList title="생각" textList={worryNoteState.thoughtList} />
            <TitleAndTextList title="행동" textList={worryNoteState.behaviorList} />
          </div>
          <div className="mt-[53px]">
            <AnxyActionButton state={'ACTIVE'} text={'완료'} action={goToNextPage} />
          </div>
        </div>
      </div>
    </ContainerByDeviceAnxy>
  );
};

export default WorryNoteResult;
