import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export function AnxyLoadingLottie(props) {
  const { containerSize } = props;
  const likecontainer = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('./anxy_loading.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <div>
      <div
        style={{ width: containerSize ? containerSize : '50px', height: containerSize ? containerSize : '50px' }}
        ref={likecontainer}
      ></div>
    </div>
  );
}
