/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { BottomActionAnxy } from '../../../stories/page/BottomActionAnxy';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { CheckLottie } from '../../../stories/animation/CheckLottie';
import { useSetRecoilState } from 'recoil';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import useMixpanelWeb from '../../../hooks/useMixpanelWeb';

export default function AnxtWebEnd(props) {
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const setHeaderState = useSetRecoilState(headerAtom);
  const setMixpanel = useMixpanelWeb({ eventName: '(AT) 검사 완료', projectName: 'anxy' });

  useEffect(() => {
    document.title = `anxy 자가검사 종료`;
    setHeaderState({ headerButtonType: BUTTON_BACK, headerTitle: '' });
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    setMixpanel();
  }, []);

  const goNext = useNavigateWithParams(`/anxyWebTest/resultLoading`, true);

  return (
    <BottomActionAnxy
      content={
        <div className="w-full h-full flex flex-col items-center justify-center text-center ">
          <div className="mb-[30px]">
            <CheckLottie containerSize={'77px'} green />
          </div>
          <p className=" font-bold text-[24px] leading-[30px] text-[#26282C]  mb-[7px] ">{'테스트를 마쳤어요'}</p>
          <p className="  font-normal text-[17px] leading-[23px] text-[#6B6D76] whiteSpace-pre-line  ">
            {'결과를 보러 갈까요?'}
          </p>
        </div>
      }
      buttonText={'결과 보기'}
      buttonState={'ACTIVE'}
      action={goNext}
      noGradient
      fixedRoot
    />
  );
}
