/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SelectButtonCircle } from '../../stories/button/SelectButtonCircle';

export default function SelfCheckTemplate(props) {
  const { question, optionList, answer, isClicked, disable, anxy, insideWhite, loomy } = props;

  return (
    <div className={`w-full h-full relative `}>
      <div className={`w-full ${insideWhite ? 'h-[calc(100%/2)]' : 'h-[calc((100%-78px)/2)]'} relative`}>
        <div
          className={`w-full px-[20px]  font-bold text-[24px] leading-[30px]  absolute bottom-[40px] text-center left-[50%] translate-x-[-50%]
          ${loomy && 'text-[#11383B]'}`}
          style={{ wordBreak: 'keep-all' }}
        >
          {loomy && (
            <div className={`text-[14px] leading-[20px] font-bold text-[#9DA992] mb-[10px]`}>{'지난 2주간'}</div>
          )}
          {question}
        </div>
      </div>

      <div
        className={`w-[calc(100%-80px)] flex justify-between ${
          optionList.length <= 2 ? 'max-w-[194px]' : optionList.length <= 5 ? 'min-w-[305px]' : 'min-w-[335px]'
        } mx-auto shrink-0`}
      >
        {optionList.map((option, index) => (
          <SelectButtonCircle
            key={`selfcheck${index}`}
            selected={option.selfCheckChoiceId ? answer === option.selfCheckChoiceId : answer === option.score}
            option={option}
            text={option.choice}
            isClicked={isClicked} //버튼 컴포넌트에서 알려줌
            disable={disable} // 버튼 컴포넌트로 알려줌
            anxy={anxy}
            loomy={loomy}
          />
        ))}
      </div>
    </div>
  );
}
