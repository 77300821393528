/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import webviewToast from '../development/webviewToast';

export default function TimePickerTest() {
  const nowTime = moment(new Date());

  const hours = Array.from({ length: 24 }, (v, i) => (i < 10 ? `0${i}` : `${i}`));
  const minutes = ['00', '10', '20', '30', '40', '50'];
  const repeatNum = 50;
  const repeatedHours = Array(repeatNum).fill(hours).flat();
  const repeatedMinutes = Array(repeatNum).fill(minutes).flat();
  const [hourIdx, setHourIdx] = useState(hours.length * (repeatNum / 2) - 1 + parseInt(nowTime.format('H')));
  const [minuteIdx, setMinuteIdx] = useState(
    minutes.length * (repeatNum / 2) - 1 + Math.floor(nowTime.format('mm') * 0.1),
  );
  const [numRefHeight, setNumRefHeight] = useState(63);

  const hourPickerRef = useRef();
  const minutePickerRef = useRef();
  const hourRef = useRef();
  const minuteRef = useRef();

  useEffect(() => {
    console.log(hourIdx);

    setTimeout(() => {
      if (hourPickerRef.current && hourRef.current) {
        hourPickerRef.current.scrollTop = hourRef.current.offsetHeight * hourIdx;
      }
      if (minutePickerRef.current && minuteRef.current) {
        console.log(hourRef.current.offsetHeight);
        // setNumRefHeight(hourRef.current.offsetHeight);
        minutePickerRef.current.scrollTop = minuteRef.current.offsetHeight * minuteIdx;
      }
    }, 0);
  }, []);

  useEffect(() => {
    console.log(hourIdx);
    console.log('selected: ', hours[hourIdx % hours.length], ':', minutes[minuteIdx % minutes.length]);
  }, [hourIdx, minuteIdx]);

  return (
    <div
      className={`w-screen h-[189px] text-center flex justify-center border-[1px] relative`}
      style={{ gap: '32px' }}
      height={hourRef.current && repeatNum * hourRef.current.offsetHeight}
    >
      <div className="absolute top-[50%] translate-y-[-50%] w-full px-[20px] h-[63px] ">
        <div className="w-full h-full rounded-[12px] bg-[#F3F5F8]" />
        <p className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] font-bold text-[30px] leading-[36px] pb-[3px]">
          :
        </p>
      </div>
      <div
        className="h-full overflow-auto snap-y relative scrollbar-hide"
        ref={hourPickerRef}
        onScroll={(e) => {
          console.log(e.target.scrollTop);
          setHourIdx(parseInt((e.target.scrollTop + numRefHeight / 2) / numRefHeight) + 1);
        }}
      >
        {repeatedHours.map((hour, index) => (
          <div
            key={`hour${index}`}
            style={{
              opacity: hours[hourIdx % hours.length] !== hour ? 0.15 : 1,
              // fontSize: hours[hourIdx % hours.length] === hour ? '28px' : '25px',
              lineHeight: hours[hourIdx % hours.length] !== hour ? '40px' : '40px',
            }}
            className="flex items-center h-[63px] text-[30px] font-bold py-[5px] snap-center "
            ref={hourRef}
          >
            {hour}
          </div>
        ))}
      </div>
      <div
        className="h-full overflow-auto snap-y relative ml-[1px] scrollbar-hide"
        ref={minutePickerRef}
        onScroll={(e) => {
          setMinuteIdx(parseInt((e.target.scrollTop + numRefHeight / 2) / numRefHeight) + 1);
        }}
      >
        {repeatedMinutes.map((min, index) => (
          <div
            key={`min${index}`}
            style={{ opacity: minutes[minuteIdx % minutes.length] !== min ? 0.15 : 1 }}
            className="flex items-center h-[63px] text-[30px] leading-[40px] font-bold py-[5px] snap-center "
            ref={minuteRef}
          >
            {min}
          </div>
        ))}
      </div>
    </div>
  );
}
