import { useCallback } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import axios from '../../utils/axiosAnxy';
import webviewToast from '../../development/webviewToast';
import useMixpanelEventSomny from '../useMixpanelEventSomny';
import useNavigateWithParams from '../useNavigateWithParams';
import { somnyKnitData } from '../../data/somny/somnyKnitData';
import { useRecoilState, useSetRecoilState } from 'recoil';
import somnyHomeAtom from '../../recoil/somny/home/atom';
import { useEffect } from 'react';

export default function usePostKnitDone(action) {
  const { knitId } = useParams();
  const [searchParams] = useSearchParams();

  const [homeState, setHomeState] = useRecoilState(somnyHomeAtom);
  const goToDone = useNavigateWithParams('/somnySessionDone');

  const currentData = somnyKnitData.find((item) => item.knitId === knitId);
  const setMixPanel = useMixpanelEventSomny({
    eventName: '뜨개 완료',
    params: {
      제목: currentData.title,
    },
  });

  useEffect(() => {
    if (homeState && typeof homeState.scrollToSession !== 'undefined') {
      if (action) {
        action();
      } else {
        goToDone();
      }
    }
  }, [homeState]);

  const handleEnd = () => {
    setMixPanel();
    setHomeState((state) => ({ ...state, scrollToSession: knitId }));
  };

  const postSessionDone = useCallback(async () => {
    try {
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          `/somny/knit/complete`,
          JSON.stringify({
            knitId: knitId,
          }),
        )
        .then((response) => {
          webviewToast('knit name: ' + currentData.title);
          handleEnd();
        });
    } catch (error) {
      handleEnd();
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return postSessionDone;
}
