import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AnxyActionButton } from '../../stories/button/ActionButton';
import { useModalDrag } from '../../hooks/useModalDrag';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import { logout, userLeave } from '../../utils/webview';
import { useRecoilState, useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import LinkButton from '../../stories/button/LinkButton';
import myPageAtom from '../../recoil/betterme/myPage/atom';
import sessionAtom from '../../recoil/betterme/session/atom';
import BottomSheetModal from '../../stories/modal/BottomSheetModal';
import modalAtom from '../../recoil/betterme/modal/atom';

export default function BetterMeModal(props) {
  const { red } = props;
  const [searchParams] = useSearchParams();
  const appId = searchParams.get('app_id');
  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const myPageState = useRecoilValue(myPageAtom);
  const [modalState, setModalState] = useRecoilState(modalAtom);
  const [sessionState, setSessionState] = useRecoilState(sessionAtom);

  const navigate = useNavigate();

  const [logoutResponse, callLogoutApi] = useFetchAnxy({
    url: `/better-me/user/logout`,
    bg: '#FFFFFF',
  });

  const [userLeaveResponse, callUserLeaveApi] = useFetchAnxy({
    url: `/better-me/user/leave`,
    requestBody: JSON.stringify({ reason: myPageState.leaveReason }),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (userLeaveResponse) {
      userLeave();
      setModalState((state) => ({ ...state, isModalVisible: false }));
      navigate(`/betterMeLogin?app_id=${appId}`, { replace: true });
    }
  }, [userLeaveResponse]);

  useEffect(() => {
    if (logoutResponse.code === 0) {
      logout();
      setModalState((state) => ({ ...state, isModalVisible: false }));

      navigate(`/betterMeLogin?app_id=${appId}`, { replace: true });
    }
  }, [logoutResponse]);

  const data = [
    {
      type: 'LOGOUT',
      title: '정말 로그아웃할까요?',
      buttonText: '로그아웃하기',
      action: () => {
        callLogoutApi();
      },
    },
    {
      type: 'LEAVE',
      title: '정말 탈퇴할까요?',
      info: 'Better Me 이용/결제 내역은 영구적으로 삭제되어 복구할 수 없어요. 나중에 Better Me를 다시 사용하려면 처음부터 시작해야 해요.',
      buttonText: '탈퇴하기',
      red: true,
      action: () => {
        callUserLeaveApi();
      },
    },
  ];

  const dataPerType = data.find((item) => item.type === modalState.modalType);
  const { sheet, showModal } = useModalDrag(modalState.isModalVisible);

  useEffect(() => {
    setModalState((state) => ({ ...state, isModalVisible: showModal }));
  }, [showModal]);

  return (
    <BottomSheetModal
      modalState={modalState}
      setModalState={setModalState}
      content={
        <div className={`z-[50]`}>
          {dataPerType && (
            <div className={`h-full w-full text-[15px] leading-[18px] text-center relative`}>
              <div className="flex flex-col gap-[24px]">
                <div className="flex flex-col gap-[6px]">
                  <p className="text-[#26282C] text-[20px] leading-[24px] font-semibold">{dataPerType.title}</p>
                  {dataPerType.info && <p className="text-[17px] leading-[26px] text-[#6B6D76]">{dataPerType.info}</p>}
                </div>
                {dataPerType.content}
              </div>
              <div className="pt-[30px] flex flex-col items-center">
                <AnxyActionButton
                  state="ACTIVE"
                  text={dataPerType.buttonText}
                  action={dataPerType.action}
                  activeColor={dataPerType.red && '#EF3E3E'}
                />
                {dataPerType.linkText && (
                  <LinkButton state="ACTIVE" text={dataPerType.linkText} action={dataPerType.linkAction} />
                )}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}
