import React, { useState } from 'react';
import SwiperView from './SwiperView';

export default function TutorialItem(props) {
  const { category, steps } = props;
  const [isSeeAnswer, setIsSeeAnswer] = useState(false);

  function seeAnswer() {
    setIsSeeAnswer((isSeeAnswer) => !isSeeAnswer);
  }
  return (
    <div className="w-screen px-20px select-none ">
      <div className={` py-17px ${category !== '상담 후기 남기기' ? 'border-b-1' : ''} `}>
        <div className=" flex  " onClick={seeAnswer}>
          <div className="flex items-start">
            <p className="text-[20px] leading-[24px] text-[#3953D9] font-semibold  mr-2">{'Q'}</p>

            <p className="text-question font-semibold inline">{category}</p>
          </div>
          <p className={`inline-block my-auto p-2 ${isSeeAnswer ? 'rotate-180' : ''} absolute right-[22px] text-[0px]`}>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1.5L6 6.5L11 1.5"
                stroke="#26282C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              {category === '선생님 찾기' && '선생님 찾기'}
              {category === '상담 예약하기' && '상담 예약하기'}
              {category === '상담받기' && '상담받기'}
              {category === '상담 후기 남기기' && '상담 후기 남기기'}
            </svg>
          </p>
        </div>
        {isSeeAnswer && <SwiperView key={`${steps}`} steps={steps} />}
      </div>
    </div>
  );
}
