import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import whiteUnlock from './unlocked_white.json';
import { replaceColor } from 'lottie-colorify';

export function UnlockLottie(props) {
  const { containerSize, isStop, gray, white } = props;
  const likecontainer = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: isStop ? false : true,
      animationData: white
        ? whiteUnlock
        : gray
        ? replaceColor('#FFFFFF', '#b6b8bb', whiteUnlock)
        : require('./unlocked_b.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <div>
      <div
        style={{ width: containerSize ? containerSize : '150px', height: containerSize ? containerSize : '150px' }}
        ref={likecontainer}
      ></div>
    </div>
  );
}
