const ABCModel = () => {
  return (
    <div className="my-[40px] px-[25px] text-black" style={{ whiteSpace: 'nowrap' }}>
      <div className="mb-10px text-[17px] leading-[24px] bg-[#F3F5F8] rounded-[16px] w-full h-[50px] flex items-center px-20px py-14px">
        <p className="font-bold">A</p>
        <p>ctivating events - 사건</p>
      </div>
      <div className="mb-10px text-[17px] leading-[24px] bg-[#F3F5F8] rounded-[16px] w-full h-[50px] flex items-center px-20px py-14px">
        <p className="font-bold">B</p>
        <p>eliefs - 믿음</p>
      </div>
      <div className="mb-10px text-[17px] leading-[24px] bg-[#F3F5F8] rounded-[16px] w-full h-[50px] flex items-center px-20px py-14px">
        <p className="font-bold">C</p>
        <p>onsequences - 결과</p>
      </div>
      <div className="mb-10px text-[17px] leading-[24px] bg-[#F3F5F8] rounded-[16px] w-full h-[50px] flex items-center px-20px py-14px">
        <p className="font-bold">D</p>
        <p>ispute beliefs - 회고 (믿음 반박)</p>
      </div>
      <div className="text-[17px] leading-[24px] bg-[#F3F5F8] rounded-[16px] w-full h-[50px] flex items-center px-20px py-14px">
        <p className="font-bold">E</p>
        <p>ffect - 변화 (효과)</p>
      </div>
    </div>
  );
};

export default ABCModel;
