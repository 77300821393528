import React, { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
import { ProgressingBar } from '../../stories/else/ProgressingBar';

function WorkbookProgressingBar() {
  const context = useContext(CoachingContext);

  return (
    context.showProgressingbar && (
      <div
        className={`w-full px-[20px] pt-[12px] ${
          context.isQuizTemplate ? 'bg-[#26282C]' : context.showActivityModal ? 'bg-[#00000080]' : 'bg-white'
        }`}
      >
        <ProgressingBar
          maxCompleted={context.progressSum}
          completed={context.current}
          color={'#3953D9'}
          baseColor={context.isQuizTemplate && '#FFFFFF1A'}
        />
      </div>
    )
  );
}

export default WorkbookProgressingBar;
