import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { NotificationSettingCard } from '../../../components/anxy/notification/NotificationSettingCard';
import webviewToast from '../../../development/webviewToast';
import { TopTitleBottomActionAnxy } from '../../../stories/page/TopTitleBottomActionAnxy';

const listPerTimes = [
  { num: 1, range: ['13:00'] },
  { num: 2, range: ['08:00', '22:00'] },
  { num: 3, range: ['08:00', '13:00', '22:00'] },
  { num: 4, range: ['08:00', '13:00', '19:00', '22:00'] },
];

const DEFAULTTYPE = '저녁';

export default function NotificationSettingCardList({
  timeList,
  state,
  setState,
  cardNumber,
  action,
  linkAction,
  isSession,
}) {
  const [buttonState, setButtonState] = useState('ACTIVE');
  const cardRef = useRef([]);
  const divRef = useRef();

  useEffect(() => {
    if (buttonState === 'LOADING') {
      setTimeout(() => {
        setButtonState('ACTIVE');
      }, 5000);
    }
  }, [buttonState]);

  const sessionNumber = cardNumber ? cardNumber : 2;
  const currentList = sessionNumber
    ? listPerTimes.find((item) => item.num === sessionNumber).range
    : listPerTimes.find((item) => item.type === DEFAULTTYPE).range;

  const [currentTime, setCurrentTime] = useState(timeList && timeList.length !== 0 ? timeList : currentList);
  console.log('updated current', currentList, currentTime);
  console.log('updated value', timeList);

  const isLock = useRef(true);
  const [selected, setIsSelected] = useState(currentTime ? currentTime[0] : 1);
  const setSelected = (time) => {
    setIsSelected(time);
  };

  useEffect(() => {
    setState((prev) => {
      const updated = { ...prev };
      if (isSession) {
        updated.session = {
          timeList: currentTime,
        };
      } else {
        updated.worryNote = {
          timeList: currentTime,
        };
      }
      console.log('updated', updated);
      return updated;
    });
  }, []);

  return (
    <TopTitleBottomActionAnxy
      title={'언제 알려드릴까요?'}
      subtitle="설정에서 변경할 수 있어요."
      content={
        <div className="px-[20px] pt-[24px] pb-[40px]">
          <div
            ref={divRef}
            className="flex flex-col gap-[12px] outline-none"
            tabIndex={0}
            onFocus={() => {
              isLock.current = false;
            }}
            onBlur={() => {
              isLock.current = true;
              setTimeout(() => {
                if (isLock.current) {
                  setSelected();
                }
              }, 300);
            }}
          >
            {timeList &&
              timeList.map((item, index) => (
                <NotificationSettingCard
                  key={`noti${item}`}
                  ref={(element) => {
                    cardRef.current[index] = element;
                  }}
                  id={item.id}
                  state={state}
                  setState={setState}
                  time={item}
                  isSelected={selected === item}
                  setSelected={setSelected}
                  type={isSession ? 'session' : 'worryNote'}
                  notDelete
                />
              ))}
          </div>
        </div>
      }
      buttonText={'알림 받기'}
      buttonState={buttonState}
      action={action}
      bgColor="#F1EEEB"
      linkText={'다음에'}
      linkAction={linkAction}
    />
  );
}
