import React, { useRef } from 'react';
import { AnxyActionButton } from '../button/ActionButton';
import { LoomyActionButton, SomnyActionButton } from '../button/RoundActionButton';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import ContainerByDeviceAnxy from '../../components/elements/ContainerByDeviceAnxy';

export function RoundBottomActionWithScroll(props) {
  const {
    content,
    buttonText,
    buttonBgColor,
    buttonTextColor,
    buttonState,
    action,
    bgColor,
    noHeader,
    isAnxy,
    isSomny,
    isLoomy,
  } = props;
  const buttonRef = useRef();
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return (
    <ContainerByDeviceAnxy noHeader={noHeader} bgColor={bgColor}>
      <div
        className={`h-full flex flex-col `}
        style={{
          minHeight: `${window.innerHeight - 44}px`,
        }}
      >
        <div
          className={`flex-1 flex flex-col pb-[80px]`}
          style={{ paddingBottom: `${buttonRef.current && buttonRef.current.offsetHeight + 40}px` }}
        >
          {content}
        </div>

        <div className={`fixed bottom-0 w-full `} ref={buttonRef}>
          <div
            className="w-full h-[40px]  "
            style={{
              background: `linear-gradient(to top, ${bgColor ? bgColor : 'white'}, rgba(255,255,255,0)) `,
            }}
          />

          <div className={`px-[20px] bg-white  ${marginClassName} `} style={{ backgroundColor: bgColor }}>
            {isAnxy ? (
              <AnxyActionButton
                state={buttonState || 'ACTIVE'}
                text={buttonText}
                action={action}
                bgColor={bgColor}
                round
              />
            ) : isSomny ? (
              <SomnyActionButton state={buttonState || 'ACTIVE'} text={buttonText} action={action} />
            ) : isLoomy ? (
              <LoomyActionButton state={buttonState || 'ACTIVE'} text={buttonText} action={action} />
            ) : (
              // <AnxyActionButton
              //   state={buttonState || 'ACTIVE'}
              //   text={buttonText}
              //   action={action}
              //   bgColor={bgColor}
              //   round
              // />
              <LoomyActionButton state={buttonState || 'ACTIVE'} text={buttonText} action={action} />
            )}
          </div>
        </div>
      </div>
    </ContainerByDeviceAnxy>
  );
}
