/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { changeHeader } from '../../utils/webview';
import ContainerByDevice from '../../components/elements/ContainerByDevice';
import useFetch from '../../hooks/useFetch';
import { LoadingLottie } from '../../stories/animation/LoadingLottie';
import UserProfileImage from '../../components/home/UserProfileImage';
import useMixpanelEvent from '../../hooks/useMixpanelEvent';

function EachInfo({ title, info }) {
  return (
    info &&
    info !== '' && (
      <div className="w-full flex gap-[10px] justify-between  text-[17px] leading-[26px]">
        <p className="flex-none text-[#26282C]">{title}</p>
        <p className="text-[#80838B]">{info}</p>
      </div>
    )
  );
}

export default function UserInfo() {
  const profileRef = useRef();
  const [showHeader, setShowHeader] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [userData, getUserData] = useFetch({
    url: `/user/info-for-counselor/detail`,
    bg: '#FFFFFF',
  });

  const setMixPanel = useMixpanelEvent({
    eventName: '내담자 알아보기',
  });

  useEffect(() => {
    if (userData) {
      console.log(userData.result);
      setUserInfo(userData.result);
    }
  }, [userData]);

  useEffect(() => {
    getUserData();
    changeHeader({ backgroundColor: '#FFFFFF', color: '#26282C', text: '', buttonType: 'close' });
    setMixPanel();
  }, []);

  const handleScroll = (scrollTop) => {
    if (scrollTop > profileRef.current.offsetHeight) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  };

  useEffect(() => {
    if (showHeader) {
      console.log('헤더 보임');
      changeHeader({ backgroundColor: '#FFFFFF', color: '#26282C', text: userInfo.nickname, buttonType: 'close' });
    } else {
      console.log('헤더 안보임');
      changeHeader({ backgroundColor: '#FFFFFF', color: '#26282C', text: '', buttonType: 'close' });
    }
  }, [showHeader]);

  return userInfo ? (
    <ContainerByDevice handleScroll={handleScroll} indicatorPadding>
      <div className="w-full h-full pb-[30px] px-[20px]">
        <div className="flex flex-col items-center pt-[12px] mb-[40px] " ref={profileRef}>
          <UserProfileImage
            profileImageUrl={userInfo.profileImageUrl}
            nickname={userInfo.nickname}
            containerSize={'65px'}
          />

          <div className={`text-[24px] leading-[30px] font-bold text-[#26282C] mt-[14px] mb-[8px]`}>
            {userInfo.nickname}
          </div>
          {userInfo.memberType === 'company' && (
            <div
              className={`py-[1px] px-[8px] bg-[#2C4BEC] text-white font-semibold rounded-[7px] text-[12px] leading-[18px]   whitespace-nowrap`}
            >
              기업회원
            </div>
          )}
        </div>

        <div className=" divide-y divide-[#F3F5F8]">
          <div className="flex flex-col gap-[12px] pb-[20px]">
            {[
              ['성별', userInfo.gender === 'f' ? '여' : '남'],
              ['나이', userInfo.age + '세'],
              ['휴대폰번호', userInfo.mobileNumberWithHyphen],
              ['연인 관계', userInfo.lover],
              ['아이 여부', userInfo.child],
            ].map(([title, info], index) => (
              <EachInfo title={title} info={info} key={`인적사항${index}`} />
            ))}
          </div>
          {userInfo.company && (
            <div className=" py-[20px]">
              <p className="text-[20px] leading-[28px] text-[#26282C] font-bold mb-[20px]">직장 정보</p>
              <div className="flex flex-col gap-[12px] ">
                {[
                  ['회사 이름', userInfo.company.name],
                  ['회사 규모', userInfo.company.size],
                  ['회사 업종', userInfo.company.industry],
                  ['직무', userInfo.company.work],
                  ['직책', userInfo.company.position],
                ].map(([title, info], index) => (
                  <EachInfo title={title} info={info} key={`직장정보${index}`} />
                ))}
              </div>
            </div>
          )}
          <div className=" pt-[20px]">
            <p className="text-[20px] leading-[28px] text-[#26282C] font-bold mb-[20px]">마음 관리 경험</p>
            <div className="flex flex-col gap-[12px] ">
              {[
                ['마음관리 활동', userInfo.mindfulnessActivity],
                ['심리상담 경험', userInfo.hasCounselingExperience ? '네, 있어요' : '아니요, 없어요'],
                ['정신과 진료 경험', userInfo.hasMedicalTreatmentExperience ? '네, 있어요' : '아니요, 없어요'],
                ['정신과 진단', userInfo.subjectOfTreatment],
              ].map(([title, info], index) => (
                <EachInfo title={title} info={info} key={`마음관리경험${index}`} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </ContainerByDevice>
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <LoadingLottie containerSize="72px" />
    </div>
  );
}
