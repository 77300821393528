import session1 from '../../image/somny/session1.png';
import session2 from '../../image/somny/session2.png';
import session3 from '../../image/somny/session3.png';
import session4 from '../../image/somny/session4.png';
import session5 from '../../image/somny/session5.png';
import session6 from '../../image/somny/session6.png';
import session7 from '../../image/somny/session7.png';
import session8 from '../../image/somny/session8.png';

const somnySessionData = [
  {
    sessionId: 'ac',
    eventProperty: 'Intro',
    seq: 1,
    sessionName: 'Intro',
    image: session1,
    title: '잠들 수 없던 날들',
    subtitle: '포근하게 잠들던 날이 언제였나요? 나는 왜 잠들지 못했는지 알아봐요.',
    playTime: 1,
    checkPoint: [''],
  },
  {
    sessionId: 'bd',
    eventProperty: '1-1',
    seq: 2,
    sessionName: 'Session 1',
    image: session2,
    title: '적당한 수면이란',
    subtitle: '언제, 어떻게 불안이 만들어 질까요? 불안을 극복하기 위한 목표를 세워요',
    playTime: 2,
    checkPoint: [''],
  },
  {
    sessionId: 'pk',
    eventProperty: '1-2',
    sessionName: 'Session 2',
    seq: 3,
    image: session3,
    title: '불면증에 관하여',
    subtitle: '불안을 만드는 3요소인 생각, 신체, 행동을 직접 통제하는 방법을 배워요',
    playTime: 4,
    checkPoint: [''],
  },
  {
    sessionId: 'dk',
    eventProperty: '2',
    seq: 4,
    sessionName: 'Session 3',
    image: session4,
    title: '수면을 기록하기',
    subtitle: '불안을 만드는 신체적 긴장을 이완해요',
    playTime: 3,
  },
  {
    sessionId: 'lm',
    eventProperty: '3',
    seq: 5,
    sessionName: 'Session 4',
    image: session5,
    title: '약 없이도 잘자기',
    subtitle: '불안을 키우는 생각 함정들을 배워요',
    playTime: 3,
  },
  {
    sessionId: 'sd',
    eventProperty: '4',
    seq: 6,
    sessionName: 'Session 5',
    image: session6,
    title: '우리 몸의 시계',
    subtitle: '부정적인 생각을 만들어내는 근원을 찾아요',
    playTime: 4,
  },
  {
    sessionId: 'tl',
    eventProperty: '5',
    seq: 7,
    sessionName: 'Session 6',
    image: session7,
    title: '침실 준비하기',
    subtitle: '회피했던 불안 요인들을 직접 마주하고 견디는 힘을 키워요',
    playTime: 4,
  },
  {
    sessionId: 'ps',
    eventProperty: 'Outro',
    seq: 8,
    sessionName: 'Session 7',
    image: session8,
    title: '다시 포근히 잠들기',
    subtitle: '3주간의 활동을 통해 불안을 통제하는 새로운 나를 만나요',
    playTime: 3,
  },
];

export default somnySessionData;
