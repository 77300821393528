/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { CheckLottie } from '../animation/CheckLottie';
import { BottomActionAnxy } from './BottomActionAnxy';
import Lottie from '../../components/elements/Lottie';
import congrats_particle from '../../image/congrats_particle.json';
import { impactFeedback } from '../../utils/webview';

export function CompletePage(props) {
  const { title, image, buttonText, action } = props;

  useEffect(() => {
    impactFeedback('success');
  }, []);

  return (
    <BottomActionAnxy
      content={
        <div className="w-full h-full flex flex-col gap-[30px] justify-center items-center px-[20px] text-[#26282C] relative">
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[50] w-full pointer-events-none">
            <Lottie lottieData={congrats_particle} autoplay />
          </div>
          {image ? (
            <div className="w-[80px] h-[80px] rounded-[50%] bg-[#E1E4EB] flex items-center justify-center">{image}</div>
          ) : (
            <CheckLottie green containerSize="72px" autoplay />
          )}
          <div className="text-[24px] font-bold ">{title}</div>
        </div>
      }
      noHeader
      noGradient
      buttonText={buttonText}
      action={action}
      bgColor={'#F3F5F8'}
    />
  );
}
