import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import lottie from 'lottie-web';
import { AnxyActionButton } from '../../stories/button/ActionButton';
import { getRefresh } from '../../utils/webview';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';

function AnxyErrorPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const container = useRef();
  const bg = searchParams.get('bg');
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  function refreshAction() {
    navigate(-1, { replace: true });
  }

  useEffect(() => {
    getRefresh(refreshAction);
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('../../image/anxyError.json'),
    });
    return () => instance.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={`${bg ? `#${bg}` : '#FFFFFF'} h-screen flex flex-col justify-between items-center`}>
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="w-[80px] h-[80px] mb-[30px]">
            <div className="w-full h-full" ref={container}></div>
          </div>
          <p className="font-bold text-[24px] leading-[30px] mt-[1.38px]">데이터를 불러올 수 없어요</p>
          <p className="text-[#6B6D76] text-[17px] leading-[23px] mt-[8px]">잠시 후 다시 시도해주세요.</p>
        </div>
        <div className={`${marginClassName} w-full px-[20px]`}>
          <AnxyActionButton state="ACTIVE" text="다시 시도하기" action={() => navigate(-1, { replace: true })} />
        </div>
      </div>
    </div>
  );
}

export default AnxyErrorPage;
