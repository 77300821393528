import React, { useEffect, useState } from 'react';
import { updateNotch } from '../../utils/webview';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_MORE } from '../../recoil/common/header/atom';
import { LoadingLottie } from '../../stories/animation/LoadingLottie';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import ContainerByDeviceAnxy from '../../components/elements/ContainerByDeviceAnxy';
import somnyHomeAtom, { TYPE_HOME } from '../../recoil/somny/home/atom';
import { HomeWithReport } from './home/HomeWithReport';
import { HomeBottomTab } from '../../components/somny/home/HomeBottomTab';
import { HomeWIthTale } from './home/HomeWIthTale';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import useSessionStorage from '../../hooks/useSessionStorage';
import statusAtom, {
  STATUS_DREAM,
  STATUS_KNIT_OPEN,
  STATUS_RECORD,
  STATUS_TALE,
  STATUS_TALE_DONE,
} from '../../recoil/somny/status/atom';
import useLoadImage from '../../hooks/useLoadImage';
import { somnyHomeImageList } from '../../data/somny/somnyHomeImageList';
import { getNowDateInIOSFormat } from '../../logic/method';
import useImagePreloader from '../../hooks/useImagePreloader';
import SleepReport from './sleepReport/SleepReport';
import useMixpanelEventSomny from '../../hooks/useMixpanelEventSomny';

export default function SomnyHome() {
  // 데이터 로드 여부
  const { imagesPreloaded } = useImagePreloader(somnyHomeImageList);

  const [homeDetail, getHomeDetail] = useFetchAnxy({
    url: `/somny/home/detail?std_ts=${getNowDateInIOSFormat()}`,
    bg: '#060727',
  });

  const [sleepingReportData, getSleepingData] = useFetchAnxy({
    url: `/somny/sleep-record/list`,
    bg: '#060727',
  });

  const [sleepingReport, setSleepingReport] = useState(null);
  const [knitList, setKnitList] = useSessionStorage('knitList', []);
  const [scrollTo, setScrollTo] = useState();

  const [homeType, setHomeType] = useRecoilState(somnyHomeAtom);
  const [result, setResult] = useState();
  const [taleId, setTaleId] = useState(undefined);
  const [statusState, setStatusState] = useRecoilState(statusAtom);

  const setMixPanel = useMixpanelEventSomny({
    eventName: '홈',
    params: {
      타입: homeType.type === TYPE_HOME ? '루틴' : '리포트',
    },
  });

  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  const preloadImage = useLoadImage(somnyHomeImageList);

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  useEffect(() => {
    if (homeDetail) {
      const result = homeDetail.result;

      // result는 session API로부터 받은 data
      const changedList = [...result.knitList];
      if (knitList) {
        knitList.forEach((beforeItem) => {
          changedList.forEach((afterItem) => {
            if (beforeItem.knitId === afterItem.knitId) {
              if (beforeItem.isLock === true && afterItem.isLock === false) {
                afterItem.isFirstUnlock = true;
              }
              if (!beforeItem.completedAt && afterItem.completedAt) {
                afterItem.isFirstComplete = true;
              }
            }
          });
        });
      }
      setKnitList(changedList);
      // setKnitState((state) => ({ ...state, knitList: changedList }));
      setTaleId(result.availableSleepFairyTaleId);

      const isNight = result.isNight;
      const isCompleteTale = result.isCompleteTodaySleepFairyTale;
      const isCompleteRecord = result.isCompleteTodaySleepRecord;

      const openLength = changedList.filter((item) => !item.isLock).length;
      const completeLength = changedList.filter((item) => item.isComplete).length;
      const isKnitOpen = openLength !== 0 && openLength !== completeLength;

      let status = undefined;
      if (isNight) {
        if (isCompleteTale) {
          status = STATUS_TALE_DONE;
        } else {
          status = STATUS_TALE;
        }
      } else {
        if (isCompleteRecord) {
          if (isKnitOpen) {
            status = STATUS_KNIT_OPEN;
          } else {
            status = STATUS_DREAM;
          }
        } else {
          status = STATUS_RECORD;
        }
      }
      setResult(result);
      setStatusState({
        somnyStatus: status,
        availableSleepFairyTaleId: result.availableSleepFairyTaleId,
        isCompleteTodaySleepFairyTale: result.isCompleteTodaySleepFairyTale,
        isCompleteTodaySleepRecord: result.isCompleteTodaySleepRecord,
        isPaymentRequired: result.isPaymentRequired,
        totalCountCompletedFairyTale: result.totalCountCompletedFairyTale,
        totalCountCompletedSleepRecord: result.totalCountCompletedSleepRecord,
        payedAt: result.payedAt,
      });
    }
  }, [homeDetail]);

  useEffect(() => {
    if (sleepingReportData) {
      setSleepingReport(sleepingReportData.result);
    }
  }, [sleepingReportData]);

  const initializeState = () => {
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_MORE,
      headerTitle: '',
      headerColor: '#FFFFFF',
      progressBar: {},
    }));

    setProgressBarState({ isProgressBarVisible: false });
  };

  useEffect(() => {
    if (homeType.type === TYPE_HOME) {
      getHomeDetail();
      window.scrollTo(0, 0);
    } else {
      getSleepingData();
    }
    setMixPanel();
  }, [homeType]);

  useEffect(() => {
    document.title = 'Somny 홈';
    document.body.style.backgroundColor = '#060727';

    updateNotch({ isFullScreen: true });

    initializeState();
    getHomeDetail();
    getSleepingData();
    preloadImage();
  }, []);

  console.log(statusState);
  return knitList && taleId && imagesPreloaded && sleepingReportData && result ? (
    <ContainerByDeviceAnxy scrollto={scrollTo} noHeader bgColor="#060727" appNotch={homeType.type === TYPE_HOME}>
      <div className={`flex-1 flex flex-col relative ${marginClassName} pb-[86px]`}>
        {homeType.type === TYPE_HOME ? (
          <HomeWIthTale
            isNight={result.isNight}
            dayList={result.dayTimeSeriesPercentage}
            nightList={result.nightTimeSeriesPercentage}
            knitList={knitList}
            taleId={taleId}
            setScrollTo={setScrollTo}
          />
        ) : (
          <SleepReport sleepingReportData={sleepingReport} />
        )}
        <HomeBottomTab />
      </div>
    </ContainerByDeviceAnxy>
  ) : (
    <div className="h-screen w-full bg-[#060727] flex justify-center items-center">
      <LoadingLottie containerSize="50px" />
    </div>
  );
}
