import course5_1_1 from '../image/course5/course5_1_1.png';
import course5_1_2 from '../image/course5/course5_1_2.png';
import course5_1_3 from '../image/course5/course5_1_3.png';
import course5_1_4 from '../image/course5/course5_1_4.png';
import course5_2_1 from '../image/course5/course5_2_1.png';
import course5_2_2 from '../image/course5/course5_2_2.png';
import course5_2_3 from '../image/course5/course5_2_3.png';
import course5_2_4 from '../image/course5/course5_2_4.png';
import course5_2_5 from '../image/course5/course5_2_5.png';
import course5_2_6 from '../image/course5/course5_2_6.png';
import course5_3_1 from '../image/course5/course5_3_1.png';
import course5_3_2 from '../image/course5/course5_3_2.png';
import course5_3_3 from '../image/course5/course5_3_3.png';
import course5_3_4 from '../image/course5/course5_3_4.png';
import course5_3_5 from '../image/course5/course5_3_5.png';
import course5_3_6 from '../image/course5/course5_3_6.png';
import course5_3_7 from '../image/course5/course5_3_7.png';
import course5_4_1 from '../image/course5/course5_4_1.png';
import course5_4_2 from '../image/course5/course5_4_2.png';
import course5_4_3 from '../image/course5/course5_4_3.png';
import course5_4_4 from '../image/course5/course5_4_4.png';
import course5_4_5 from '../image/course5/course5_4_5.png';
import course5_4_6 from '../image/course5/course5_4_6.png';
import course5_4_7 from '../image/course5/course5_4_7.png';
import course5_5_1 from '../image/course5/course5_5_1.png';
import course5_5_2 from '../image/course5/course5_5_2.png';
import course5_5_3 from '../image/course5/course5_5_3.png';
import course5_6_1 from '../image/course5/course5_6_1.png';
import course5_6_2 from '../image/course5/course5_6_2.png';
import course5_6_3 from '../image/course5/course5_6_3.png';
import course5_7_1 from '../image/course5/course5_7_1.png';
import course5_7_2 from '../image/course5/course5_7_2.png';
import course5_7_3 from '../image/course5/course5_7_3.png';
import course5_7_4 from '../image/course5/course5_7_4.png';
import course5_7_5 from '../image/course5/course5_7_5.png';
import course5_8_1 from '../image/course5/course5_8_1.png';
import course5_8_2 from '../image/course5/course5_8_2.png';
import course5_8_3 from '../image/course5/course5_8_3.png';
import course5_8_4 from '../image/course5/course5_8_4.png';
import course5_8_5 from '../image/course5/course5_8_5.png';
import course5_9_1 from '../image/course5/course5_9_1.png';
import course5_9_2 from '../image/course5/course5_9_2.png';
import course5_9_3 from '../image/course5/course5_9_3.png';
import course5_10_1 from '../image/course5/course5_10_1.png';
import course5_10_2 from '../image/course5/course5_10_2.png';
import course5_10_3 from '../image/course5/course5_10_3.png';
import course5_10_4 from '../image/course5/course5_10_4.png';
import course5_11_1 from '../image/course5/course5_11_1.png';
import course5_11_2 from '../image/course5/course5_11_2.png';
import course5_11_3 from '../image/course5/course5_11_3.png';
import course5_11_4 from '../image/course5/course5_11_4.png';
import course5_11_5 from '../image/course5/course5_11_5.png';
import course5_11_6 from '../image/course5/course5_11_6.png';
import _12_1 from '../image/course5/12_1.png';
import _12_2 from '../image/course5/12_2.png';
import _12_3 from '../image/course5/12_3.png';
import _12_4 from '../image/course5/12_4.png';
import _13_1 from '../image/course5/13_1.png';
import _13_2 from '../image/course5/13_2.png';
import _13_3 from '../image/course5/13_3.png';
import _13_4 from '../image/course5/13_4.png';
import _13_5 from '../image/course5/13_5.png';
import _13_6 from '../image/course5/13_6.png';
import _14_1 from '../image/course5/14_1.png';
import _14_2 from '../image/course5/14_2.png';
import _14_3 from '../image/course5/14_3.png';
import _14_4 from '../image/course5/14_4.png';
import _14_5 from '../image/course5/14_5.png';
import _14_6 from '../image/course5/14_6.png';
import _14_7 from '../image/course5/14_7.png';

import day8_2_1 from '../image/course5/day8_2_1.png';
import day8_2_2 from '../image/course5/day8_2_2.png';
import day8_2_3 from '../image/course5/day8_2_3.png';
import day8_2_4 from '../image/course5/day8_2_4.png';
import day8_2_5 from '../image/course5/day8_2_5.png';
import day8_2_6 from '../image/course5/day8_2_6.png';
import day8_2_7 from '../image/course5/day8_2_7.png';
import day8_2_8 from '../image/course5/day8_2_8.png';

import day9_2_1 from '../image/course5/day9_2_1.png';
import day9_2_2 from '../image/course5/day9_2_2.png';
import day9_2_3 from '../image/course5/day9_2_3.png';

import day10_2_1 from '../image/course5/day10_2_1.png';
import day10_2_2 from '../image/course5/day10_2_2.png';
import day10_2_3 from '../image/course5/day10_2_3.png';
import day10_2_4 from '../image/course5/day10_2_4.png';
import day10_2_5 from '../image/course5/day10_2_5.png';
import day10_2_6 from '../image/course5/day10_2_6.png';

import day11_2_1 from '../image/course5/day11_2_1.png';
import day11_2_2 from '../image/course5/day11_2_2.png';
import day11_2_3 from '../image/course5/day11_2_3.png';
import day11_2_4 from '../image/course5/day11_2_4.png';
import day11_2_5 from '../image/course5/day11_2_5.png';
import day11_2_6 from '../image/course5/day11_2_6.png';

import day12_2_1 from '../image/course5/day12_2_1.png';
import day12_2_2 from '../image/course5/day12_2_2.png';
import day12_2_3 from '../image/course5/day12_2_3.png';

import day13_2_1 from '../image/course5/day13_2_1.png';
import day13_2_2 from '../image/course5/day13_2_2.png';
import day13_2_3 from '../image/course5/day13_2_3.png';

import day14_2_1 from '../image/course5/day14_2_1.png';
import day14_2_2 from '../image/course5/day14_2_2.png';

import day15_2_1 from '../image/course5/day15_2_1.png';
import day15_2_2 from '../image/course5/day15_2_2.png';
import day15_2_3 from '../image/course5/day15_2_3.png';

import day16_2_1 from '../image/course5/day16_2_1.png';
import day16_2_2 from '../image/course5/day16_2_2.png';
import day16_2_3 from '../image/course5/day16_2_3.png';

import wb0_5_2 from '../image/wb0_5_2.png';
import wb0_5_3 from '../image/wb0_5_3.png';
import wb0_5_4 from '../image/wb0_5_4.png';
import wb0_5_5 from '../image/wb0_5_5.png';
import wb0_5_6 from '../image/wb0_5_6.png';
import wb0_5_7 from '../image/wb0_5_7.png';
import wb0_6_2 from '../image/wb0_6_2.png';
import wb0_6_4 from '../image/wb0_6_4.png';
import wb0_6_6 from '../image/wb0_6_6.png';

import day17_2_1 from '../image/course5/day17_2_1.png';
import day18_2_1 from '../image/course5/day18_2_1.png';

import day19_2_1 from '../image/course5/day19_2_1.png';
import day19_2_2 from '../image/course5/day19_2_2.png';
import day19_2_3 from '../image/course5/day19_2_3.png';
import day19_2_4 from '../image/course5/day19_2_4.png';
import day19_2_5 from '../image/course5/day19_2_5.png';

import day19_3_1 from '../image/course5/day19_3_1.png';

import day20_2_1 from '../image/course5/day20_2_1.png';
import day20_2_2 from '../image/course5/day20_2_2.png';

import { TextWithBold } from '../components/workbook/TextWithBold';
import SleepingSurvey from '../components/workbook/course5/SleepingSurvey';
import EmotionalInput from '../components/workbook/EmotionalInput';
import QuizTemplate from '../components/workbook/quiz/QuizTemplate';
import AnswerTemplate from '../components/workbook/quiz/AnswerTemplate';
import PageBySelected from '../components/workbook/PageBySelected';
import MessageBlock from '../stories/workbook/MessageBlock';
import MessageBlockWithTail from '../stories/workbook/MessageBlockWithTail';
import { MessageBlockWithTailCross } from '../stories/workbook/MessageBlockWithTailCross';
import AlarmTimePicker from '../components/workbook/course5/AlarmTimePicker';
import CardSharePage from '../components/workbook/course5/CardSharePage';
import SurveyInBedStart from '../components/workbook/course5/SurveyInBedStart';
import SurveyInSleepStart from '../components/workbook/course5/SurveyInSleepStart';
import SurveyInSleepEnd from '../components/workbook/course5/SurveySleepEnd';
import SurveyInBedEnd from '../components/workbook/course5/SurveyInBedEnd';
import SurveyInStruggle from '../components/workbook/course5/SurveyInStruggle';
import SurveyInNap from '../components/workbook/course5/SurveyInNap';
import SurveyDone from '../components/workbook/course5/SurveyDone';
import TextWithGradientCircle from '../components/workbook/course5/TextWithGradientCircle';
import FullImage from '../components/workbook/FullImage';
import SleepingDiseaseSummary from '../components/workbook/course5/SleepingDiseaseSummary';
import TextWithDot from '../components/workbook/TextWithDot';
import NavigateSelectButton from '../components/workbook/NavigateSelectButton';
import SleepingSurveySquareButton from '../components/workbook/course5/SleepingSurveySquareButton';
import WakeUpTimePicker from '../components/workbook/course5/WakeUpTimePicker';
import WakeUpCard from '../components/workbook/course5/WakeUpCard';
import SleepingReport from '../pages/workbook/SleepingReport';
import GoToBedCard from '../components/workbook/course5/GoToBedCard';
import SleepResultAnalysis from '../components/workbook/course5/SleepResultAnalysis';
import SleepEfficiencyResult from '../components/workbook/course5/SleepEfficiencyResult';
import SleepingRuleCard from '../components/workbook/course5/SurveyRuleCard';
import { Image } from '../components/workbook/Image';
import SurveyHygiene from '../components/workbook/course5/SurveyHygiene';
import WorryNote from '../components/workbook/course5/WorryNote';
import ThoughtNote from '../components/workbook/course5/ThoughtNote';
import EmagineCard from '../components/workbook/course5/EmagineCard';
import CountNumberCard from '../components/workbook/course5/CountNumberCard';
import ObjectiveFeedback from '../components/workbook/course5/ObjectiveFeedback';
import FeedbackDone from '../components/workbook/course5/FeebackDone';
import SurveyCas from '../components/workbook/course5/SurveyCas';
import AudioPlayer from '../pages/workbook/AudioPlayer2';
import AccordianContent from '../components/workbook/AccordianContent';
import NewThoughtInput from '../components/workbook/course5/NewThoughtInput';
import UserWorryAndSolution from '../components/workbook/course5/UserWorryAndSolution';
import WorrySingleInput from '../components/workbook/course5/WorrySingleInput';
import SelectTrapOfThought from '../components/workbook/course5/SelectTrapOfThought';
import SituationAndThought from '../components/workbook/course5/SituationAndThought';

function basicFormat({ title, texts, image, fullImage, bg, height, submit }) {
  return {
    title: title,
    fullImage: fullImage,
    height: height ? height : fullImage ? 215 : 190,
    bg: bg ? bg : '',
    image: image,
    texts: [
      {
        component: <TextWithBold texts={texts} />,
      },
    ],
    buttonText: ' ',
    submit: submit ? submit : '',
  };
}

function withComponentFormat({ title, components, image, fullImage, bg, height, submit }) {
  return {
    title: title,
    fullImage: fullImage,
    height: height ? height : fullImage ? 215 : 190,
    bg: bg ? bg : '',
    image: image,
    texts: components,
    buttonText: ' ',
    submit: submit ? submit : '',
  };
}

const SleepingSurveyWithCas = [
  {
    replaceComponent: <SurveyInBedStart type="inBedStart" />,
  },
  {
    replaceComponent: <SurveyInSleepStart type="sleepStart" />,
  },
  {
    replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
  },
  {
    replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
  },
  {
    replaceComponent: <SurveyInStruggle type="struggleTime" />,
  },
  {
    replaceComponent: <SurveyInNap type="napTime" />,
  },
  {
    replaceComponent: <SleepingRuleCard title="졸릴 때만 침대에 누웠나요?" />,
  },
  {
    replaceComponent: <SleepingRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" />,
  },
  {
    replaceComponent: <SleepingRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" />,
  },
  {
    isHygiene: true,
    replaceComponent: <SurveyCas />,
  },
  {
    isHygiene: true,
    replaceComponent: <SurveyCas />,
  },
  {
    isHygiene: true,
    replaceComponent: <SurveyCas />,
  },
  {
    replaceComponent: (
      <SurveyHygiene title="취침 2시간 전을 제외하고 시간을 내어 최소 20분 동안 달리기, 계단 오르기 등 숨이 차고 심장박동이 빨라지는 운동을 했나요?" />
    ),
  },
  {
    replaceComponent: <SurveyHygiene title="자기 전 공복감이 들었을 때, 가벼운 간식을 먹었나요?" />,
  },
  {
    replaceComponent: <SurveyHygiene title="침실은 시원하고, 어둡고, 조용하게 유지되고 있나요?" />,
  },
  {
    replaceComponent: (
      <SurveyHygiene title="시계와 스마트폰은 침실 밖에 두었나요? 혹은 나의 시야나 손에 닿지 않는 곳에 있나요?" />
    ),
  },
  {
    replaceComponent: <SurveyDone />,
    progressHide: true,
  },
];

const ImagineContent = [
  {
    replaceComponent: <EmagineCard />,
  },
  {
    title: '이제 눈을 감고 상상하기를 해보세요',
    texts: [
      {
        component: (
          <TextWithBold texts={['잠이 안오는 날에 반복적으로 해보시길 권장 드려요, 그럼 오늘도 고생 많으셨습니다']} />
        ),
      },
    ],
    buttonText: ' ',
  },
];

const CountNumberContent = [
  {
    replaceComponent: <CountNumberCard />,
  },
  {
    title: '이제 눈을 감고 숫자 빼기를 해보세요',
    texts: [
      {
        component: (
          <TextWithBold
            texts={[
              '잠이 안올 때 자연스럽게 숫자 빼기를 해야겠다고 생각이 드실거예요.\n그럼 오늘도 편안한 밤 보내세요.',
            ]}
          />
        ),
      },
    ],
    buttonText: ' ',
  },
];

const AudioPlayerContent = [
  {
    title: '긴장 풀기를 진행 할게요',
    fullImage: day14_2_1,
    height: 215,
    texts: [
      {
        component: (
          <TextWithBold
            texts={[
              '편안하고 조용한 곳에서 눈을 감고, 오디오가 안내하는 대로 따라서 진행합니다.\n오디오를 끝까지 들어야 활동을 마칠 수 있으니 충분히 준비된 상태에서 시작해 주세요. ',
            ]}
          />
        ),
      },
    ],
    buttonText: ' ',
  },
  {
    replaceComponent: <AudioPlayer />,
  },
  {
    title: '오늘도 수고하셨어요',
    texts: [
      {
        component: (
          <TextWithBold
            texts={[
              '잘하고 계시네요. 몸의 긴장이 좀 풀어졌나요?',
              '\n',
              '자, 그러면 우리는 내일 또 만나죠. \n 오늘도 편안한 밤 보내세요.',
            ]}
          />
        ),
      },
    ],
    buttonText: ' ',
  },
];

const ThinkingNote = [
  basicFormat({
    title: '생각 노트',
    fullImage: day19_3_1,
    height: 215,
    texts: [
      '때때로 생각은 우리의 안 좋은 면만 포착하는 경향이 있지요. 인지 재구성을 통해 나를 불쾌하게 했던 기억에 생각 함정이 있지는 않았는지 다시 생각해 보는 과정을 거칠 거예요.',
    ],
  }),
  {
    replaceComponent: <SituationAndThought />,
  },
  {
    replaceComponent: <SelectTrapOfThought />,
  },
  {
    replaceComponent: <NewThoughtInput />,
  },
  {
    replaceComponent: <ThoughtNote />,
  },
];

const course5Data = [
  // 48
  [
    {
      title: '포근했던 밤, 아늑했던 밤',
      fullImage: course5_1_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                "해가 지네요. 어둠이 찾아오고 있어요. 어린 나는 소파에 누워 노을빛에 변해가는 색색의 구름들을 하염없이 바라봅니다. 엄마는 정리를 모두 마치시고 내 곁에 앉으셨지요. 동화책을 몇 권 읽고 나니, 어느새 밤이 찾아왔네요. '이제 자야지.' 엄마의 다정한 목소리가 들려와요. 방으로 들어가 은은한 작은 전등을 켜고, 엄마의 품 속에서 자장가를 들어요. 내가 가장 좋아하는 엄마 목소리와 냄새. 엄마는 나의 머리카락을 쓸어 넘겨 줍니다. 나는 조금씩, 조금씩 눈을 감다가 스르르 잠이 듭니다.",
                '\n',
                '잠시 후, 커튼 사이로 옅은 빛이 비치면 천천히 눈을 떠봅니다. 발가락 끝까지 기지개를 쭈욱 켜고, "아 잘 잤다." 기분도 정말 좋네요. 갓 지은 밥 냄새가 나는 부엌으로 달려나갑니다. 안녕 밤. ',
                '참으로 포근하고 아늑한 밤이었어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잃어버린 밤',
      fullImage: course5_1_2,
      height: 190,
      bg: '#182D58',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 언젠가부터 밤은 길어져만 갑니다. 정신없는 하루를 보내고 집에 도착합니다. 낮의 시간은 어릴 적보다 더 고된 것 같아요. 하지만 이상하게 깊게 잠들 수가 없어요. 침실 문을 열면 침대 위엔 걱정과 불안이 놓여있죠. 오늘은 또 어떤 일들이 있었는지, 내일은 또 어떤 일들을 이겨내야 하는지, 오늘 밤은 깊이 잠들 수 있을지. 침대에 눕기 전부터 나의 밤은 길어져만 갑니다. 어릴 적의 짧고 포근한 밤은 더 이상 남아있지 않아요. ',
                '그런 밤은 오래전에 잃어버리고 말았죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '다시 찾아보도록 해요',
      image: course5_1_3,
      bg: '#132446',

      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 밤이 누구에게나 동일하게 다가오듯이, 우리도 ',
                '누구나 아늑한 밤을 보낼 수 있답니다.',
                ' 우리의 몸과 마음은 그렇게 되도록 만들어졌거든요. 참 예쁘죠. 우리의 몸과 마음이 아늑한 밤을 보낼 수 있도록 만들어졌다는 건 참 다행인 일이에요. 조금씩 노력하다 보면 다시 아늑한 밤을 보낼 수도 있다는 말이니까요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잃어버린 밤을 찾아서',
      image: course5_1_4,
      bg: '#132446',

      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <p />,
                '우리는 앞으로 4주 동안 잃어버린 밤을 되찾아올 거예요.',
                ' 쉬운 일은 아닐 거랍니다. 하지만 다행히 좋은 방법들이 많이 있어요. ‘잃어버린 밤을 찾아서’ 코스는 ',
                '밤에 대해 연구한 수많은 의사와 학자들의 연구와 이론에 기반',
                '하여 만들어졌어요. 이미 전 세계적으로 수많은 사람들이 효과를 본 방법이기도 하죠. 성실하게 참여하기만 한다면 불면증을 해결하는 데에 불면증 인지행동치료보다 더 좋은 방법은 없답니다. 성실히 참여하기만 한다면요. ',
                '결국 나를 변화시킬 수 있는 건 스스로의 마음이라는 점',
                '을 잊지 마세요. 물론 인사이드가 잘 도와드릴 거랍니다.',
                '\n',
                '그럼 이제 떠나보도록 해요, 잃어버린 밤을 찾아서.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '반갑습니다',
      fullImage: course5_2_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '잠을 푹 자지 못 고생하고 계시군요. 많이 힘드셨겠어요. 여기까지 찾아오시기까지 많은 노력들을 하셨을 것 같네요. 때로는 뜬눈으로 밤 지새우기도 했을 테죠. 따뜻한 우유를 마셔보기도 했을 거고요. 침대에 누워 헤아렸던 양의 수만 하더라도 한가득 되겠죠? 고생 많으셨어요.',
                '\n',
                '어쩌면 운이 좋아 작은 문제를 겪고 있을 때 저를 바로 찾아왔을지도 모르겠네요. 운이 좋다는 말은 제가 가장 확실한 도움을 드릴 수 있다는 말이기도 해요. 저는 이번 코스에 수면과 관련되어 제가 알고 있는 모든 정보와 지식들을 공유해 드릴 거예요. 불면의 밤을 끝낼 수 있는 구체적인 방법들도 알려드릴 거고요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '인지행동치료를 적용할 거예요',
      image: course5_2_2,
      bg: '#21222C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '제가 진행하는 코스는 ',
                '인지행동치료(Cognitive Behavioral Therapy: CBT)',
                '에 기반을 두고 있어요. 인지행동치료는 수많은 학자들에 의해 연구되어 이미 ',
                '의학과 심리학 전반에 거쳐 매우 활발하게 이용',
                '되고 있죠. 인지행동치료는 우울, 불안, 수면 등 다양한 영역에 적용돼요. 물론, 저는 여러분의 잃어버린 밤을 찾는 데에 치료를 활용하고자 해요. ',
                '수면 문제와 관련하여서는 특히 인지행동치료 기법이 가장 효과가 좋은 방법',
                '이라고도 알려져 있어요. ',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '효과가 있는 치료입니다',
      image: course5_2_3,
      bg: '#21222C',

      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '제가 다룰 기법들은 수없이 많은 불면증 환자들을 대상으로 오랜 기간 면밀하게 연구한 내용들에 기반하고 있어요. 연구에 따르면 불면증 인지행동치료는 ',
                '수면제를 포함한 다른 치료법과 비교하여 비슷하거나 더 뛰어난 효과',
                '를 보이고 있어요. 따라서 많은 기관들이 불면증 인지행동치료를 적극 활용하고 추천하고 있죠. ',
                '영국 국립 보건임상 연구원',
                '은 불면증 치료에 있어서 인지행동치료를 우선적으로 추천하고 있으며, ',
                '국내 서울대학교병원',
                ' 또한 인지행동치료에 기반하고 필요에 따라 단기간 최소한의 약물치료를 병행할 수 있다고 이야기하고 있어요. ',
                '즉, 인지행동치료는 불면증을 교정하는 데에 가장 효과적인 방법입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '하지만 많은 분들이 잘 모르고 있죠',
      texts: [
        {
          text: '많은 분들이 여전히 잘못된 방법으로 수면 문제를 해결하려고 하고 있어요. 제가 진료실에서 만나왔던 사람들도 마찬가지죠.',
        },
        {
          component: (
            <MessageBlockWithTail
              type={'gray'}
              textList={[
                [
                  '잠을 못 잘까 걱정돼서 요즘은 잠자리에 일찍 들어요. 그래야 뒤척이는 시간을 감안하더라도 최소한의 시간은 잘 수 있으니까요.',
                ],
                ['자기 전에 위스키를 모금 마셔요. 조금 알딸딸해지면 잠이 잘 오는 것 같거든요.'],
                [
                  '어두운 방안에 홀로 버티고 있는 느낌이 싫어서 잠을 못 자는 것 같아요. 그래서 최근엔 무드등을 하나 샀어요. 조금 환하게 해두고 자면 잠이 잘 올 것 같아서요.',
                ],
              ]}
              opposite
              my
            />
          ),
        },
        {
          text: '안타깝지만 이러한 모든 방법들을 오히려 수면을 방해해요. 객관적인 연구에 따르면 그렇답니다. 경우에 따라 일시적으로 심리적인 위안이 되어 조금 더 편하게 잠들 수는 있을 테죠. 하지만 그러한 방법들은 궁극적으로 우리들의 밤을 더 길게 만들어요.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '깊은 밤을 되찾아올 겁니다',
      fullImage: course5_2_5,
      height: 190,

      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '저 또한 불면증으로 고생했던 적이 있어요. 그리고 수많은 시행착오를 겪었죠. 침대에 오래 누워있어보기도 했고, 술을 마셔보기도 했고, 자기 전 격렬한 운동을 해 본 적도 있어요. 하지만 그 모든 것들은 상황을 오히려 악화시키기만 했죠. 제가 불면증을 이겨낼 수 있었던 건 ',
                '구조화된 치료적 접근',
                ' 덕분이었어요.',
                '\n',
                '저는 이제 인지행동치료를 이용해 여러분의 깊은 밤을 되찾아올 겁니다. 이를 위해 수면에 대해 알고 있는 모든 지식과 방법들을 공유하려고 해요. ',
                '이번 코스가 끝나면 여러분도 수면 전문가가 될 수 있어요.',
                ' 물론 가장 중요한 건 여러분이 잃어버린 밤을 되찾아오기 위해 구체적으로 ‘어떠한 것들을 어떻게 해야 하는지’겠지요. ',
                '앞으로 4주 동안 구체적인 방법과 과제들을 제시할 겁니다.',
                ' 그리고 반복적으로 말했듯, 여러분이 의지를 가지고 잘 따라오기만 하면 분명히 효과를 볼 수 있을 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '쉽진 않을 거예요',
      image: course5_2_6,
      bg: '#132446',

      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '4주 동안 코스를 따라오는 건 쉬운 일이 아니죠. 더군다나 여러분이 제 진료실로 직접 방문하는 게 아니니까요. 여러분을 일일이 만나 이야기를 나누면 좋겠지만, 안타깝게도 저를 ‘닥터 스마일’이라고 부르며 찾아오는 사람이 너무 많아 모든 사람을 다 만날 수는 없답니다.',
                '\n',
                '하지만 걱정할 건 없어요. 여러분이 혼자서 잘 따라올 수 있도록 제가 체계적으로 도와드릴 거니까요. 실제 연구에 따르면 불면증 인지행동치료는 전산화된 버전도 충분히 효과를 보이고 있어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '어떤가요, 기대가 되나요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '의지를 가지고 여기까지 찾아오신 건 정말 잘 한 일이에요. 함께 많은 것들을 바꿔볼 수 있을 거예요.',
                '\n',
                '이번 시간에는 불면증 인지행동치료를 소개하는 데에 시간을 조금 할애했어요. 스스로가 어떠한 것들을 진행하는지 이해하고 있어야 더 납득이 되니까요. 혹시나 이해가 되지 않거나 궁금한 게 있다면 언제든 질문하셔도 좋아요. ',
                '인사이드의 코치나 상담사',
                '라면 충분히 잘 답변해 줄 수 있을 거예요.',
                '\n',
                '다음 시간에는 이번 코스를 조금 더 잘 이용할 수 있는 방법에 대해 말씀드리고자 합니다. 잠시 쉬었다가 만날까요? 조용한 노래를 한 곡 듣고 와도 좋아요. 저는 Frank Sinatra의 “Silent Night”이라는 노래를 듣고 올까 해요. 그러면 곧 다시 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '코스를 성공적으로 활용하기 위해',
      fullImage: course5_3_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '잘 쉬고 오셨나요? 그러면 다시 이야기를 이어나가볼게요. 오늘은 첫 시간이라 코스 전반적인 내용에 대한 이야기를 많이 할 거예요. 가벼운 마음으로 따라오셔도 됩니다. 저도 오늘은 제 이야기를 많이 전해드릴까 해요.',
                '\n',
                '그중 가장 중요한 건 제가 진료실에 만난 사람들이겠지요. 제가 만났던 사람들 모두가 완벽하게 불면증을 해결했던 건 아닙니다. 세상 일이 모두 그렇듯, 저도 잘 안 풀릴 때가 있기 마련이죠. 하지만 ',
                '인지행동치료로도 불면증을 해결하지 못했던 분들은 모두 비슷한 특징',
                '이 있었어요. 저는 그러한 특징들을 간추려 ‘수면 코스를 잘 활용하는 법’을 정리하였어요. 하나씩 살펴볼게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '1. 적절한 시기인지 살펴보세요',
      image: course5_3_2,
      bg: '#101A4F',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '4주로 구성된 수면 코스에는 다양한 활동들이 진행됩니다. 기억해야 하는 다양한 내용들을 알려드리기도 하고, 수면 시간을 함께 정해서 규칙적으로 잠자리에 들고 일어나기도 할 거예요. 분명 효과가 있는 방법이지만 4주 동안 그러한 일을 지키는 게 쉬운 일은 아니죠. 따라서 ',
                '‘지금이 나의 수면 문제를 해결하기에 최적의 시기인지’',
                '를 확실히 해야 합니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              type={'black'}
              textList={[
                ['중요한 시험이 임박해있다거나 중요한 업무를 처리해야 하는 시기는 아닌가요?'],
                ['4주 내에 해외로 나가서 시차 변화가 생길 가능성이 있나요?'],
              ]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                <p />,
                '지금이 내 수면 패턴을 바꿀 수 있을 정도의 여유가 있고 심적으로 안정된 상태라면,',
                ' 잃어버린 밤을 찾아오는 과정을 시작해도 괜찮아요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '2. 적절한 시간을 찾으세요',
      image: course5_3_3,
      bg: '#132446',
      texts: [
        {
          text: '나의 수면 패턴을 바꾸기 위해서는 많은 노력이 필요해요. 제가 매일 보내드리는 글 중에는 편하게 읽을 ‘밤에 대한 재밌는 이야기’도 있겠지만, 제법 집중을 하고 학습하여야 하는 내용도 있답니다. 따라서 코스를 효과적으로 진행하기 위해서는 어느 시간에 코스를 진행할까 미리 계획하는 것이 좋습니다. 제가 만난 분의 이야기를 한번 들어볼까요?',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[
                ['지난 일주일은 어떠셨어요?'],
                [
                  '시간을 구체적으로 정하니까 더 효과적으로 진행할 수 있는 것 같아요. ',
                  '나를 위한 약속처럼 여겼죠.',
                  ' 그 시간에는 꼭 수면 코스를 진행하려고 노력했어요.',
                ],
              ]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                <p />,
                '무언가에 집중할 수 있고 나에게 온전히 시간을 쓸 수 있는 시간대',
                '를 찾아보세요. 그리고 매일 그 시간에 수면 코스를 진행하세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: <AlarmTimePicker type="push" />,
    },
    {
      title: '3. 코스는 ‘읽을거리’가 아닙니다',
      image: course5_3_4,
      bg: '#1A4062',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '인지행동치료 기반으로 만들어진 수면 코스는 단순한 읽을거리가 아니에요. 지식을 습득한다고 해서 불면증이 해결되진 않죠. ',
                '더 중요한 건 내가 정말 그걸 실천하는가입니다.',
                ' 따라서 코스를 진행하며 제시된 과제는 성실하게 수행해야 잃어버린 밤을 되찾아올 수 있어요.',
                '\n',
                '물론 제가 단계적으로 천천히 도와드릴 거랍니다. 그러니까 너무 압박감을 느끼진 않아도 돼요. 쉬운 일들부터 조금씩 실천해 나갈 수 있도록 할 거니까요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '4. 조급함을 버려야 합니다',
      image: course5_3_5,
      texts: [
        {
          text: '코스를 진행하는 많은 분들이 조급함을 감추지 못할 때가 많아요.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['일주일이 지났는데 별로 바뀐 게 없는 것 같아요. 이게 정말 효과가 있는 걸까요?']]}
              type={'gray'}
              opposite
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우선 수면 코스는 4주 동안 진행됩니다. ',
                '길었던 밤을 단숨에 짧게 만들 순 없습니다.',
                ' 우리들의 습관은 하루 이틀 만에 바꿀 수 있는 게 아니죠. 그러니 ',
                '짧은 시간 내에 효과가 없다고 좌절하거나 포기하면 안 됩니다.',
                <span />,
                '\n',
                '혹은 잘하고 있다가 한 번 흔들렸을 때 와르르 무너지는 분들도 계시죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '정말 잘하고 있었는데 어제 친구와 싸운 후 한숨도 못 잤어요. 저는 다시 처음으로 돌아가게 되는 걸까요? 그동안의 노력이 무의미해질까봐 걱정돼요.',
                ],
              ]}
              type={'gray'}
              opposite
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '삶이 늘 그렇듯, ',
                '좋은 날도 있고 나쁜 날도 있습니다.',
                ' 수면도 마찬가지예요. 가끔씩 잠을 못 자는 날도 얼마든지 있을 수 있죠. 이제는 깊은 밤을 보내고 있는 저조차 가끔씩은 잠 못 이루는 밤을 보내기도 합니다. 그러니 ',
                '하룻밤 뒤척였다고 하여 좌절하거나 포기해서는 안 됩니다.',
                ' 꾸준히 노력하고 개선해 나가는 게 더 중요하답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '5. 목표를 세우세요',
      fullImage: course5_3_6,
      height: 190,
      texts: [
        {
          text: '수면 코스를 통해 구체적으로 어떠한 목표를 달성할지 정하는 건 중요한 일이에요. 내가 어떠한 것을 얻을 수 있는지 예상할 수 있고, 목표에 잘 다가가고 있는지 확인해 볼 수 있으니까요. 하지만 대부분의 사람들은 구체적으로 어떠한 목표를 달성하고 싶은지 잘 생각하지 않습니다.',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[['수면 코스로 어떤 목표를 달성하고 싶나요?'], ['글쎄요... 그냥 밤에 ‘잘 자고’ 싶어요']]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '잘 잔다는 건 어떤 의미일까요? 침대에 누워서 잠에 들기까지의 시간을 최소화하고 싶다는 이야기일 수도 있죠. 자고 일어났을 때 더 개운했으면 좋겠다는 말일 수도 있고요. 아니면 밤에 자주 깨지 않았으면 좋겠다는 말이기도 해요. 그러니 ',
                '목표를 구체적으로 정하는 게 중요',
                '하답니다. 단, ',
                '가능하면 현실적인 목표를 세우길 바랄게요.',
                ' 지금 하루에 겨우 4시간 자고 있다면, 하루에 6시간을 자게 되는 것도 충분히 큰 발전이라고 할 수 있습니다.',
                '\n',
                '자, 그럼 한번 목표를 세워볼게요. 세운 목표는 2주 차에 한 번, 4주 차에 한 번, 총 두 번에 걸쳐 평가를 할 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={1}
          title="달성하고 싶은 목표를 적으세요"
          placeholder="ex) 하루 6시간 숙면하기, 오전 8시엔 규칙적으로 일어나기"
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      title: '6. 주변에 알리고 함께 하세요',
      image: course5_3_7,
      bg: '#B3C0D2',
      texts: [
        {
          text: '수면 문제는 우리들의 일상과 밀접하게 맞닿아있어요. 잠을 잘 못 잔 날이면 주변 사람들에게 더 신경질적으로 반응하곤 하죠. ',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '아침에 일어나면 짜증밖에 나지 않아요. 남자친구가 연락이 와도 대충 대응해버리고 말죠. 왜 그러냐고 물어오면 잠을 못 자서 그렇다고 해요. 사실인데도 남자친구는 그것 때문에 이렇게 본인에게 짜증을 내는 게 맞냐고 물어와요. 맞는 말이긴 한데... 저도 너무 힘들고 짜증 나는 걸 어떡해요.',
                ],
              ]}
              type={'gray'}
              opposite
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우선 ',
                '내가 수면 문제를 해결하기로 마음먹었다는 사실을 주변에게 알리는 게 중요',
                '해요. 여러분을 진정으로 아끼는 사람이라면 수면 코스를 잘 끝낼 수 있도록 지지해 주고 도와줄 테니까요. ',
                '함께 사는 가족이나 연인에게 이야기를 하는 것도 좋습니다.',
                <span />,
                '\n',
                '가장 좋은 건 함께 하는 거예요. 함께 코스를 진행하면 서로 지지해 주며 더 많은 효과를 볼 수 있습니다. 통계적으로 보았을 때 ',
                '인지행동치료는 누군가가 지지해 줄 때 그 효과가 가장 좋습니다.',
                ' 그러니 ',
                '주변의 가까운 사람들과 함께 하세요.',
                ' 4주 간 진행되는 코스에는 수면과 밤에 대한 다양한 이야기들을 나누게 됩니다. 임상적인 것들도 있지만 수면 문제가 없는 사람도 흥미를 느낄 만한 이야기들도 많지요. ',
                '수면 코스는 누구든 진행할 수 있습니다.',
                ' 그러니 주변 사람들과 함께 해 보세요. 자연스럽게 말을 꺼낼 수 있도록 공유할 수 있는 카드를 마지막에 드릴게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '좋아요, 준비를 마쳤어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '자, 그럼 수면 코스를 진행할 준비를 모두 마쳤어요. 내일부터는 수면 기록을 시작해 볼 거예요. 그리고 점차 수면 시간을 조정하고, 나의 수면을 방해하는 요인들을 제거해 나갈 거예요.',
                '\n',
                '오늘은 여기까지 할까요? 항상 첫 시작이 힘든데 여기까지 오느라 고생 많았어요. 보통 여기까지 무난히 잘 도달한 분들은 코스를 잘 이어나가더라고요. 저도 많은 기대가 되네요. 그러면 오늘 밤도 잘 주무시길 바랄게요. 조금 뒤척이라도 걱정하지 마세요. 이제 함께 점차 바꿔나갈 거니까요. ',
                '항상 좋은 날도 있고, 나쁜 날도 있다는 걸 잊지 마세요. 지금 조금 힘든 시기를 겪고 있을 뿐이에요.',
                ' 그리고 곧 다시 깊고 아늑한 잠을 잘 수 있을 거예요. 우리의 몸과 마음이 원래 그러하니까요. 저를 믿어보세요.',
                '\n',
                '고생 많았어요. 그럼 내일 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: <CardSharePage />,
    },
  ],
  [
    {
      title: '적당한 수면을 하고 있나요?',
      fullImage: course5_4_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '어서 오세요. 오늘부터는 조금 더 자세한 이야기들을 시작해 볼 거랍니다. 그리고 오늘부터 4주 동안 내 수면 패턴을 기록할 거예요. 4주 동안 수면을 기록하는 게 쉽진 않겠지만 꾸준히 작성해야 해요. 혼자 힘으로는 쉽지 않을 것 같다면 주변 친구나 가족과 함께 해 보도록 해요. 연구에 따르면 누군가와 함께 했던 사람들이 훨씬 더 많은 효과를 보았습니다.',
                '\n',
                '자, 본격적으로 이야기를 시작하기 전에 하나 생각해 볼 게 있어요. 여기까지 찾아오신 이후는 스스로가 적당한 수면을 하고 있지 않다고 생각했기 때문이겠죠? 그렇다면 대체 적당한 수면이 뭘까요? ',
                '어느 정도 자야 ‘잘 잤다’고 말할 수 있는 걸까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '정답은 “없다”랍니다',
      image: course5_4_2,
      bg: '#3A4F6C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '적당한 수면이라는 건 없어요. 사람마다 다 다르기 때문이죠. 누구는 하루에 네 시간만 자도 멀쩡한 반면, 다른 누구는 하루에 여섯 시간으로도 부족해요. 물론 하루에 네 시간을 자는 사람들은 대부분 낮잠을 통해 잠을 보충하는 경향이 있긴 합니다.',
                '\n',
                '평균적으로 보았을 때 성인의 경우 ',
                '대부분 7시간에서 8시간 정도의 수면이 필요',
                '합니다. 2021년 세계수면학회의 발표 자료에 따르면 세계인의 평일 평균 수면시간은 6.9시간, 주말은 7.7시간인 반면, ',
                '한국인의 평일 평균 수면시간은 6.7시간, 주말은 7.4시간 정도',
                '입니다.',
                '\n',
                '하지만 이는 모두 평균적인 값일 뿐, 역시 적당한 수면의 양은 사람마다 달라요. 그러니 ',
                '“최소한 얼마는 자야 한다"라는 생각은 잠시 접어두길 바랄게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잠자리에 드는 시간도 다르죠',
      image: course5_4_3,
      bg: '#24446F',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '침실로 향하는 시간도 사람마다 다 달라요. 통계적으로 보았을 때 ',
                '대부분의 사람들은 저녁 11시에 잠자리에 들어서 아침 7시 정도에 일어납니다.',
                ' 우리 중 10% 정도는 늦게 잠들고 늦게 일어나는 저녁형 인간이고, 또 다른 10%는 일찍 잠들고 일찍 일어나는 아침형 인간이랍니다.',
                '\n',
                '잠자리에 드는 시간은 사람마다 다르기도 하지만, 시기에 따라 조금씩 다르기도 합니다. 따라서 마찬가지로 ',
                '“최소한 언제는 잠자리에 들어야 한다”라는 생각도 잠시 접어두시길 바랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '나이에 따라 변하기도 합니다',
      image: course5_4_4,
      bg: '#B4CFDE',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '10대 시절에는 주로 저녁형 인간이 되고, 깊은 잠을 한 번에 쭉 이어서 잡니다. 반면 할머니, 할아버지들은 아침 일찍부터 일어나 활동하죠. ',
                '나이가 들면 총 수면 시간이 감소하고 아침형 인간',
                '으로 바뀌기 때문이에요. 그뿐만 아니라 ',
                '나이가 들어감에 따라 더 얕은 잠을 자고, 밤에 깨는 일도 더 빈번하게 발생',
                '한답니다. 이러한 수면의 변화는 수면장애로 이어질 수도 있긴 하지만 항상 그런 것은 아니에요. 그러니 ',
                '나이가 들면 수면의 양과 질에 대한 기대를 다소 낮출 필요',
                '도 있어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '한편 수면은 저축이 되기도 합니다',
      image: course5_4_5,
      bg: '#3A4F6C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '즉, 주중에 잠을 많이 못 잤다면 주말에 충분히 자서 이를 보충할 수 있답니다. ',
                '오늘 놓친 잠은 앞으로도 영영 놓쳐버리는 게 아니에요. 다음에 조금 더 많이 자면 그만',
                '입니다.',
                '\n',
                '사실 우리는 매일 조금씩 수면 시간이 달라요. 그러니 ',
                '매일 비슷한 양의 수면 시간을 기대해서는 안 돼요. 많이 자는 날도 있는 반면, 적게 자는 날도 있기 마련',
                '입니다. 실제 경험에 비추어보아도 주중보다는 주말에 더 많이 자지 않았나요? 아플 때는 더 많이 자기도 했었죠. 즉, 수면 시간은 상황에 따라 다를 수도 있어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수면 부족의 영향은 생각보다 크지 않습니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '불면증을 앓고 있는 많은 사람들이 모자란 수면을 보상하기 위해 많은 것들을 시도해요. 주로 낮잠을 자거나 오랜 시간 잠자리에 누워있는 것과 같은 것들이지요. 그러한 분들 중에는 대부분 지금 부족한 수면을 다시 보충하지 않으면 내 삶이 크게 흔들릴 거라고 생각하는 경우가 많습니다.',
                '\n',
                '물론 잠을 잘 못 자면 다음날 짜증이 나거나 집중력이 떨어질 수는 있죠. 하지만 연구 결과에 따르면 ',
                '대부분의 사람들은 하룻밤을 꼬박 새운 다음 짜증 나고 집중력이 떨어지더라도 대체로 정상적인 일상생활을 유지',
                '할 수 있었어요. 더 재밌는 사실도 있죠. 그렇게 ',
                '하룻밤을 꼬박 새운 다음날 굳이 애써 낮잠을 자거나 하는 식으로 모자란 수면을 보충하지 않더라도 우리의 몸은 충분히 회복',
                '할 수 있었답니다.',
                '\n',
                '그러니 잠을 ',
                '못 잔 것에 대해 불안해하며 잃어버린 수면 시간을 보상하려고 애쓰지 않아도 괜찮습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '그러니 조급해 할 필요가 없어요',
      image: course5_4_6,
      bg: '#B4CFDE',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '지금까지의 이야기를 하나로 요약하면 ',
                '“적당한 수면은 사람마다 다르고, 상황에 따라 다르다”,',
                ' 그리고 설령 우리의 수면에 문제가 있다고 하더라도, ',
                '“부족한 수면 시간을 모두 보충하지 않아도 충분히 회복할 수 있다”',
                '는 것이에요.',
                '\n',
                '그러니 조급해 할 필요가 없습니다. 수면 때문에 너무 힘들어 저를 찾아오신 것, 잘 알고 있어요. 지금 얼마나 피곤하고 지친 상태인지도 잘 이해합니다. 그러나 오늘 침대에 누워 잠이 안 온다고 너무 걱정할 필요는 없어요. 제가 그 문제를 해결해드리고자 여기에 있으니까요. 그저 ',
                '잠은 잘 잘 때도 있고 그렇지 않을 때도 있다',
                '는 걸 마음에 새기고 조금은 차분한 마음으로 상황을 이해했으면 합니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate
          question="우리는 몇 시간을 자야 “적당하다”라고 이야기할 수 있을까요?"
          buttonList={['4시간', '사람마다, 상황에 따라 다르다', '10시간', '6시간']}
          answer="사람마다, 상황에 따라 다르다"
        />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="맞아요, 경우에 따라 다르답니다" buttonText="다음" />,
    },
    {
      title: '하지만 문제가 있는 경우는 있어요',
      image: course5_4_7,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '그럼에도 분명 불면증이라는 상태는 존재합니다. 다음 시간에는 ‘병적으로 잠을 못 자는 상태’인 불면증에 대해 알아볼 거랍니다.',
                '\n',
                '자, 그러면 잠시 쉬었다가 또 이어서 말하도록 할까요? 저도 잠깐 쉬고 오는 게 좋을 것 같아요. 잠시 제가 키우는 고양이에게 밥을 주고 올게요. 그러니 충분히 쉬었다가 다시 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  // 52: 불면증에 대하여
  [
    {
      title: '잘 쉬고 왔나요?',
      fullImage: course5_5_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '항상 말씀드리는 거지만 코스는 본인의 속도에 맞춰서 하나씩 진행하면 돼요. 너무 무리할 필요는 없습니다. 꾸준히 하는 게 중요한 거니까요. ',
                '\n',
                '앞선 시간에는 ‘적당한 수면’은 없으니 ',
                '“최소한 얼마는 자야 한다”',
                ' 혹은 ',
                '“최소한 언제는 잠자리에 들어야 한다”',
                '라는 ',
                '생각은 하지 않기로 했어요.',
                ' 중요한 말이니까 잘 기억하시길 바랄게요.',
                '\n',
                '수면과 관련하여 “조금 못 자는 날도 있다”라고 말했지만 분명 불면증은 존재합니다. 이번 시간에는 그 불면증에 대해 알아볼 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '불면증이란',
      image: course5_5_2,
      bg: '#172135',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '불면증은 ',
                '잠드는 것이 어렵거나, 잠에서 자주 깨거나,',
                ' 혹은 ',
                '깊이 못 자는 상태',
                '를 의미해요. 깊이 못 잔다고 이야기하는 사람들은 주로 “자고 나도 개운하지 않다고 말하기도 하죠.',
                '\n',
                '통계적으로 보면 ',
                '성인의 10~15%',
                '는 만성적인 불면증으로 고통받고 있어요. 다른 질환으로 인해 불면증이 발생할 수도 있지만, ',
                '약 1~2% 정도는 특별한 이유 없이',
                ' 불면증이 발생하기도 한답니다.',
                '\n',
                '불면증은 그 자체로 매우 불편한 병이지만, 불면증이 있으면 ',
                '우울증, 불안장애, 알코올 중독과 같은 질환의 발병을 높입니다.',
                <span />,
                '\n',
                '아주 고약한 병이지요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '실제 진단 기준은 조금 더 복잡해요',
      image: course5_5_3,
      bg: '#B3C0D2',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '조금 더 자세히 알아볼까요? ',
                '미국 정신의학회(APA: American Psychiatric Association)의 DSM-5에 명시된 불면증의 진단 기준에 따라 불면증에 해당할 가능성이 있는지',
                ' 살펴보도록 하죠. 단, 여기서 제가 이야기하는 건 의학적 진단에 해당하지 않습니다. 진단은 직접 만나서 할 수 있는 거니까요. 그냥 참고로만 생각하시면 됩니다.',
                '\n',

                '자, 그럼 하나씩 여쭤볼게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: <SleepingSurvey title="잠들기가 어려워 잠들기까지 30분 이상 걸리나요?" isFirstPage />,
    },
    {
      replaceComponent: (
        <SleepingSurvey title="잠에서 자주 깨거나 깬 뒤에 다시 잠들기가 어려워 30분 이상 깨어 있을 때가 있나요?" />
      ),
    },
    {
      replaceComponent: <SleepingSurvey title="이른 아침에 잠에서 일어나 다시 잠들기가 어렵나요?" />,
    },
    {
      replaceComponent: (
        <SleepingSurvey title="이로 인해 낮 시간에 사회생활이나 직장생활, 학업 등의 일상 활동을 하는 데에 어려움을 겪고 있나요?" />
      ),
    },
    {
      replaceComponent: <SleepingSurvey title="그러한 문제가 일주일에 세 번 이상 발생하나요?" />,
    },
    {
      replaceComponent: <SleepingSurvey title="그러한 문제가 3개월 이상 지속됐나요?" />,
    },
    {
      replaceComponent: <SleepingSurvey title="잠을 잘 기회나 시간이 충분히 있었는데도 이러한 어려움을 겪고 있나요?" />,
    },
    {
      replaceComponent: (
        <SleepingSurvey title="심한 코골이나 수면무호흡, 낮 시간에 갑자기 수면에 빠지게 되는 기면병 등의 증상이 있나요?" />
      ),
    },
    {
      replaceComponent: (
        <SleepingSurvey title="너무 늦게 자고 늦게 일어나거나, 너무 일찍 자고 일찍 일어나는 생활패턴이 있거나 밤낮이 자주 바뀌는 교대근무를 하고 있나요?" />
      ),
    },
    {
      replaceComponent: (
        <SleepingSurvey title="자려고 누웠을 때 다리가 저리고 불편한 증상, 혹은 다리에 벌레가 기어다니는 느낌이 있나요?" />
      ),
    },
    {
      replaceComponent: <SleepingSurvey title="술이나 담배, 카페인 음료를 지나치게 많이 이용하나요?" />,
    },
    {
      replaceComponent: <SleepingSurvey title="처방을 받아 정기적으로 복용하고 있는 약물이 있나요?" />,
    },
    {
      replaceComponent: (
        <SleepingSurvey title="심한 스트레스, 우울감, 불안감 등 정신과적 문제가 있거나 관련된 문제로 진료를 받고 있나요?" />
      ),
    },
    {
      replaceComponent: (
        <PageBySelected
          index={0}
          info={[
            {
              title: '고생 많았어요, 한번 살펴볼까요?',
              texts: [
                {
                  component: (
                    <TextWithBold
                      texts={[
                        '다시 한번 이건 의학적 진단이 아니라는 걸 명심하세요. 정확한 진단을 원한다면 직접 의사를 만나서 진단 받아야 합니다. \n\n',
                        '우선 불면증에 해당할 가능성이 있는 것 같네요.',
                        ' 불면증에 해당하는 증상은 대부분 겪고 계신 것 같아요. ',
                        '다만 문제는 불면증 이외의 다른 이유 때문에 잠을 못 주무시고 계실 확률도 있을 것 같아요.',
                        ' 더 자세한 진단은 직접 의사를 만나서 받아보는 것도 좋겠어요. 만약 다른 이유 때문에 잠을 못 자는 것이라면 그 이유만 해결하여도 잠을 잘 자는 경우가 많기 때문이에요. \n\n 하나만 더 여쭤보도록 할게요.',
                      ]}
                    />
                  ),
                },
              ],
              buttonText: ' ',
            },
            {
              title: '고생 많았어요, 한번 살펴볼까요?',
              texts: [
                {
                  component: (
                    <TextWithBold
                      texts={[
                        '다시 한번 이건 의학적 진단이 아니라는 걸 명심하세요. 정확한 진단을 원한다면 직접 의사를 만나서 진단 받아야 합니다. \n\n',
                        '우선 불면증에 해당할 가능성이 있는 것 같네요.',
                        ' 불면증에 해당하는 증상은 대부분 겪고 계신 것 같고, 불면증이 아니라 다른 이유 때문에 잠을 못 자고 있을 가능성은 조금 낮은 것 같아요. 이런 경우라면 저의 코스가 많은 도움이 될 수도 있겠어요. 고생 많았을 텐데 잘 오셨어요. \n\n 하나만 더 여쭤보도록 할게요.',
                      ]}
                    />
                  ),
                },
              ],
              buttonText: ' ',
            },
            {
              title: '고생 많았어요, 한번 살펴볼까요?',
              texts: [
                {
                  component: (
                    <TextWithBold
                      texts={[
                        '우선 ',
                        '불면증에 해당할 가능성은 조금 낮은 것 같네요.',
                        ' 하지만 불편함을 겪고 계시기 때문에 여기까지 오신 거겠죠? 그러한 경우에도 수면 코스를 진행하는 데에 큰 무리는 없답니다. 다만 제가 말씀드린 건 의학적 진단이 아니니, 수면 문제로 고생하고 계시고 정확한 진단을 원한다면 직접 의사를 만나서 진단 받아야 합니다. \n\n 하나만 더 여쭤보도록 할게요!',
                      ]}
                    />
                  ),
                },
              ],
              buttonText: ' ',
            },
          ]}
        />
      ),
    },
    {
      title: '다음과 같은 질환으로 치료받고 있나요?',
      texts: [
        {
          component: (
            <div className="mt-[40px] text-[17px] leading-[22px] text-[#4E4F53] w-full rounded-[16px] bg-[#F3F5F8] p-20px">
              <div className="flex">
                <span className="mr-[3.5px] font-light">•</span>
                <span>조울증(양극성장애)</span>
              </div>
              <div className="flex">
                <span className="mr-[3.5px] font-light">•</span>
                <span>뇌전증(간질) </span>
              </div>
              <div className="flex">
                <span className="mr-[3.5px] font-light">•</span>
                <span>사건수면: 혼돈각성, 야경증, 몽유병 </span>
              </div>
              <div className="flex">
                <span className="mr-[3.5px] font-light">•</span>
                <span>기면병 및 심한 주간졸림과 관련된 질환 </span>
              </div>
              <div className="flex">
                <span className="mr-[3.5px] font-light">•</span>
                <span>그밖의 우울증, 공황장애 등 정신과적 질환</span>
              </div>
            </div>
          ),
        },
        {
          component: <NavigateSelectButton textList={['네', '아니요']} pageIndex={1} />,
        },
      ],
    },
    {
      replaceComponent: (
        <PageBySelected
          index={1}
          info={[
            {
              title: '현재 상황을 먼저 해결하는 건 어떨가요?',
              texts: [
                {
                  component: (
                    <TextWithBold
                      texts={[
                        '제가 진행할 수면 코스는 부작용이 적고 매우 안전한 치료예요. 다만, 코스 중 수면 시간을 제한하는 과정이 치료하고 계신 질환의 증상을 더 악화시킬 수 있어요. 또한 앓고 있는 질환 때문에 이번 코스를 집중해서 진행하지 못할 수도 있고요. \n\n  그래서 저의 제안은, ',
                        '동반된 질환을 치료하고 호전된 이후에 수면 코스를 진행했으면 좋겠어요.',
                        ' 혹은 담당 의사 선생님의 동의 하에 동반 질환에 대한 치료를 수면 코스와 병행하였으면 합니다. \n\n중요한 내용이니까 꼭 기억했으면 해요. 우선은 이번 수업까지 마치고 제가 말씀드렸던 방향대로 진행하기로 해요.',
                      ]}
                    />
                  ),
                },
              ],
              buttonText: ' ',
            },
            {
              title: '다행히 괜찮은 것 같아요',
              texts: [
                {
                  component: (
                    <TextWithBold
                      texts={[
                        '제가 진행할 수면 코스는 부작용이 적고 매우 안전한 치료예요. 다만, 코스 중 수면 시간을 제한하는 과정이 일부 질환의 증상을 더 악화시킬 수 있어요. 또한 그러한 질환 때문에 이번 코스를 집중해서 진행하지 못할 수도 있고요. \n\n',
                        '다행히 크게 염려가 되는 질환을 앓고 계신 것 같진 않아요.',
                        ' 이러한 경우에는 얼마든지 수면 코스를 진행할 수 있답니다. \n\n 자, 그러면 계속 이야기해 볼까요?',
                      ]}
                    />
                  ),
                },
              ],
              buttonText: ' ',
            },
          ]}
        />
      ),
    },
    {
      title: '불면증에 대해 정리해 볼게요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '제가 여쭤본 것들을 정리해 보면, \n\n 불면증으로 분류되려면 ',
                '잠이 드는 것이 어렵거나 깨지 않고 자는 데에 어려움',
                '이 있어야 해요. 보통은 불면증이 있는 사람들은 잠들기 전까지 30분 이상 걸리기도 하고, 밤에 30분 이상 깨어있기도 합니다. \n\n 중요한 건 이러한 일들 때문에 ',
                '일상생활에 지장이 발생',
                '해야 한다는 점이에요. 아무리 잠을 뒤척인 것 같더라도 일상생활은 멀쩡히 잘 하고 집중력 저하, 피로, 스트레스 등이 발생하지 않는다면, 그건 큰 문제가 아닐 수도 있죠. \n\n 그리고 마지막으로 ',
                '최소 일주일에 3일 이상, 적어도 3개월 지속',
                '되어야 하죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '이제 조금씩 해결책을 드릴 거예요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '어떠한 이론에 근거하여 이러한 코스를 진행하는지, 그리고 불면증이 무엇인지 배웠다면 이제 조금씩 해결책을 찾아나설 거랍니다. ',
                '앞으로는 조금씩 과제를 드리면서 불면증에 대한 추가적인 설명들을 덧붙일 거예요.',
                ' 때로는 무거운 얘기도 있고, 때로는 가볍게 읽을 재미난 이야기도 있을 거랍니다. 밤에 대해 친숙해지고 편안한 마음을 가지는 게 중요하니까요. \n\n 자, 여기서 잠시 쉬었다 가도록 해요. 이번 시간에 너무 많은 걸 이야기했죠? 잠시 기지개도 켜고 좋은 음악도 한 곡 듣고 오세요. 오늘은 Erik Satie의 <Gymnopedie No. 1>라는 노래를 추천해드릴게요. 노래를 들으며 차 한 잔 마시고 오는 것도 좋겠네요. 다음 시간에는 수면 기록에 대해 알려드릴 거예요. \n\n 그럼 이따 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '지난 밤에 어떤 일이 일어났는지 알고 있나요?',
      fullImage: course5_6_1,
      height: 215,
      texts: [
        {
          text: '우리가 잠 못 이루는 밤을 보내는 날에는 많은 일들이 일어나죠. 뜬눈으로 침대 위에서 시간을 보내기도 하고, 자다가 깨어나기도 합니다. 화장실을 가기 위해 일어나기도 하죠. 수많은 일들이 벌어지지만 아침이 되어 잠에서 깨고 활동을 하다 보면 지난 밤에 어떠한 일이 일어났는지 깜빡 잊어버리고 만답니다. 그리고 저녁이 다가오면 생각하죠. ‘오늘 밤도 못 자면 어떻게 하지.’',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수면 기록을 시작해 볼 거예요',
      image: course5_6_3,
      height: 215,
      bg: '#E5EBF3',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘부터는 ',
                '아침에 일어나서 한 시간 내로 지난 밤의 수면을 기록',
                ' 할거예요. 구체적으로는 침대에 들어가는 시각, 잠든 시각, 중간에 깨서 뒤척인 시간, 침대에서 나온 시각, 낮잠  시간, 그리고 이를 바탕으로한 수면 효율을 기록할 거랍니다. 기록할 게 많죠? 하지만 걱정 말아요. 제가 도와드릴게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '가장 중요한 과제랍니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '매일의 수면 기록은 수면 코스 중에서 가장 중요한 과제예요. 앞으로 저는 ',
                '일주일마다 작성하시는 수면 기록들을 바탕으로 지난 일주일의 수면에 대한 리포트를 작성',
                '해 드리고, ',
                '구체적으로 잠자리에 드는 시간과 잠을 자는 시간까지 정해 드릴 거랍니다.',
                ' 따라서 수면 기록은 코스의 가장 중요한 과정이에요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '어렵지 않아요',
      image: course5_6_2,
      bg: '#24436E',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '아침에 일어나자마자, ',
                '늦어도 한 시간 이내',
                '에는 수면을 기록하는 게 좋아요. 침대에 들어간 시각과 잠든 시각, 중간에 깨서 뒤척인 시간은 모두 ',
                '최대한 잘 어림',
                '해서 적으면 됩니다. ',
                '잠에서 깼을 때 몇 시인지 보려고 휴대폰을 켠다거나, 졸리기 시작한 시점이 언제인지 기억하기 위해 불을 켜는 행동은 하지 않는 게 좋습니다.',
                ' 오히려 수면을 방해하거든요. 그냥 ',
                '다음날 아침에 일어나 잘 어림해서 적으면 충분',
                '합니다. 기록을 위해 불을 켠다거나 휴대폰을 보는 것처럼 수면을 방해하는 행동을 하진 말아요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '할 일이 제법 많죠?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 제 경험에 비추어보았을 때 사람들은 수면 기록을 통해 자신의 수면 상태에 대해 가장 객관적으로 이해할 수 있었어요. 자신의 생각과는 전혀 다른 수면 패턴이 발견되기도 하고요. 수면 인지행동치료는 수면 기록에 기반하여 맞춤화된 수면 관리를 제공해요. ',
                '최고의 수면 치료는 가장 성실한 수면 기록을 통해 이뤄진다',
                '는 점을 꼭 기억하세요.',
                '\n',
                '물론 4주 동안 수면을 기록 하는 것이 쉽지는 않을 거예요. 아침부터 할 일이 생겨 짜증이 나기도 하겠지요. 하지만 잠 못 이루는 밤을 보내 고생했던 지난 시간들을 생각해 보세요. 잃어버린 밤에 대해서도 생각해 보세요. 이번엔 앞으로 살면서 얻게 될 포근한 밤에 대해 떠올려보시고요. 내 삶이 얼마나 많이 바뀔지 기대되지 않나요? 그러니 ',
                '수면 기록은 잃어버린 밤을 되찾아오는 투자라고 생각하시는 게 좋습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },

    {
      title: '몇 가지 팁을 드릴게요',
      texts: [
        {
          component: (
            <TextWithGradientCircle
              texts={[
                {
                  title: '일어난 후 1시간 이내에 작성하세요.',
                  text: '까먹기 전에 바로 쓰는게 좋겠죠?',
                },
                {
                  title: '자다가 일어나서 기록하지 마세요.',
                  text: '기록이 중요하다고 하지만, 지금 이 순간의 수면보다 중요하진 않답니다.',
                },
                {
                  title: '스트레스를 받지 않았으면 해요.',
                  text: '정답이 있는 것은 아니기 때문에 그저 효율적인 관리를 위한 최소한의 정보를 남기는 과정이라고 생각해 주세요.',
                },
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '자, 그럼 작성하러 가볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '꾸준히 기록하면 ',
                '일주일에 한 번씩 수면 리포트',
                '를 보내드릴 거예요. 보고서에는 지난 일주일의 수면 패턴과 수면 효율과 같은 정보들이 담겨있답니다. 나의 수면 상태를 알아보는 데에 많은 도움이 될 거예요. 그리고 ',
                '그 정보를 바탕으로 제가 구체적으로 어떠한 것들을 해야 하는지 추천해드릴 거랍니다.\n\n',
                '다시 한번 강조할게요. ',
                '수면을 매일 최대한 정확하게 기록 할수록 더 효과적으로 수면 코스를 진행할 수 있어요.',
                ' 잃어버린 밤을 되찾고 포근한 밤을 보내기 위해 조금만 노력해봐요.',
                '\n',
                '오늘은 여기까지 해요. 연습으로 오늘의 수면은 어젯밤을 떠올리면서 기록해 보시고요. 내일부터는 아침에 일어나서 한 시간 이내로 작성하는 거예요. 필요하다면 수면 기록을 위한 알람을 매일 아침 보내주도록 설정할 수도 있답니다.',
                '\n',
                '자, 그럼 이제 수면을 기록하러 가볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },

    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SurveyDone notHasCas={true} />,
      progressHide: true,
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SurveyDone notHasCas={true} />,
      progressHide: true,
    },
  ],
  // 56
  [
    {
      title: '불면증의 숨겨진 이유 찾아보기',
      fullImage: course5_7_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요, 잘 찾아오셨어요. 지난 밤은 어떠셨어요? 수면은 잘 기록하신 것 같네요. 잘하셨어요. 처음엔 조금 귀찮더라도 매일 아침 1~2분만 투자하면 금방 할 수 있을 거예요. 제가 3일 뒤부터는 수면 패턴을 분석하여 불면증을 이겨낼 구체적인 방법들을 알려드릴게요.',
                '\n',
                '그럼 오늘도 불면증에 대한 이야기를 나눠볼까요? 첫 시간에 말씀드린 것처럼 ‘잃어버린 밤을 찾아서’ 코스의 목표는 ',
                '수면 패턴을 안정화',
                '하고 ',
                '수면에 대한 전문가가 되도록 하는 것',
                '이랍니다. 많이 알고 있을수록 보다 유연한 대처가 가능하거든요.',
                '\n',
                '오늘은 불면증의 숨겨진 이유들에 대해 알아볼 거예요. 생각보다 다른 질환들 때문에 불면증이 발생하는 경우도 있답니다. 하나씩 살펴보도록 해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '몇 가지 질문을 드릴 거예요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우선 다음 질문들에 답하면서 혹시나 내가 그러한 경우에 해당하지 않는지 살펴보도록 해요. 크게 다섯 가지의 수면 관련 질환에 대해 알아볼 거예요. 자, 그러면 하나씩 알아볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <SleepingSurveySquareButton
          title="저녁 혹은 밤에 잠자리에 누웠을 때 다리에 벌레가 기어다니는 것 같은 느낌이 드나요?"
          isFirstPage
        />
      ),
    },
    {
      title: '하지불안증후군',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '생각보다 많은 사람들이 자려고 누웠을 때 다리에 벌레가 기어다니는 것과 같이 이상하고 안절부절못하는 느낌 때문에 잠을 잘 자지 못합니다. 그러한 느낌이 들 때 자꾸 다리를 움직이거나 걷어차는 동작을 반복하게 되고, 그러한 행동은 수면을 방해하게 되죠. 의학적으로는 이를 ',
                '하지불안증후군',
                '이라고 부른답니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={course5_7_2} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '하지불안증후군은 ',
                '늦은 저녁에 심해져',
                '서 밤새도록 우리를 괴롭히곤 하죠. ',
                '전체 인구 중 5%',
                '는 이러한 증상을 경험하고, ',
                '불면증으로 병원을 찾는 사람들 중 약 10%',
                '는 사실 하지불안증후군으로 인해 불면증을 경험하고 있답니다.',
                '\n',
                '하지불안증후군은 우리 몸의 ',
                '철분 수치',
                '와 관련이 깊습니다. 따라서 의사들은 종종 하지불안증후군에 대해 철분제를 처방하곤 하죠. 하지만 경우에 따라 ',
                '간단한 생활습관 교정',
                '만으로도 증상이 개선되기도 합니다. 카페인, 니코틴, 알코올 섭취를 줄이는 것과 같이 말이죠.',
                '\n',
                '만약 여러분이 자려고 누웠을 때 다리에 자꾸 불편한 느낌이 들어 쉽사리 잠에 들지 못한다면 의사를 찾아가보세요. 하지불안증후군만 치료하여도 생각보다 빠르게 불면증을 개선시킬 수 있을 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <SleepingSurveySquareButton
          title="잘 때 코를 심하게 고나요? 코를 고는 도중 잠시 숨을 멈추는 순간이 있나요?"
          isFirstPage
        />
      ),
    },
    {
      title: '폐쇄성수면무호흡증',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '코를 심하게 고는 것은 옆사람뿐만 아니라 나의 수면 또한 방해한답니다. 물론 코 고는 소리 때문에 그런 것은 아니에요. 중요한 건 코를 골다가 호흡이 일시정지되는 순간이랍니다.',
                '\n',
                '사람들은 흔히 코를 곤답니다. 통계적으로는 약 5명 중 1명은 코를 골죠. 하지만 이들 중 일부에게는 코를 골며 숨을 들이쉬고 내쉬는 과정 사이에 일시적으로 호흡이 멈추는 현상이 나타난답니다. 우리는 이를 ',
                '폐쇄성수면무호흡증',
                '이라고 부른답니다.',
                '\n',
                '물론 호흡이 멈춘다고 해서 심각하게 걱정할 건 없습니다. 생명에 지장을 줄 정도로 호흡을 멈추는 건 아니거든요. 하지만 중요한 건 ',
                '수면 도중 호흡이 멈추면 그때 ‘우리 뇌는 잠에서 깨어난다’',
                '는 점입니다. 즉, 몸은 자고 있어도 실제 자고 있는 게 아닌 것이지요. 이는 수면을 방해하고 수면을 파편화시킨답니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={course5_7_3} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '그렇다 보니 아침에 일어나면 ',
                '자도 개운하지 않고',
                ' 밤에 ',
                '자주 잠에서 깨기도 하죠.',
                ' 폐쇄성수면무호흡증의 흔한 증상은 아침에 일어났을 때 ',
                '목이 건조한 것과 두통',
                '입니다.',
                '\n',
                '흔하 ',
                'BMI가 높은 사람, 목둘레가 넓은 사람, 체중이 많이 나가는 사람',
                '에서 폐쇄성수면무호흡증이 자주 나타난답니다. 만약 여러분이 자고 일어났을 때 개운하지도 않고 목이 건조한데, 옆사람이 코골이 때문에 잠을 못 자겠다고 이야기한다면, 의사에게 찾아가 폐쇄성수면무호흡증에 대해 검사해 보는 것도 좋은 방법일 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <SleepingSurveySquareButton
          title="자면서 걸어 다니나요? 몸부림이 너무 심해 옆사람이 다치기도 하나요?
          아니면 잠이 깰 정도로 생생한 악몽을 꾸나요?"
          isFirstPage
        />
      ),
    },
    {
      title: '사건수면',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '수면 중에 의도하지 않는 행동을 하거나 비정상적인 정서를 느끼는 것을 총칭하여 사건수면이라고 합니다. 흔히 자면서 걸어다니기도 하고, 잠꼬대를 하기도 합니다. 심한 이갈이를 하기도 하며, 마음을 어지럽힐 정도의 생생한 악몽을 경험하기도 합니다. 악몽을 꾸다가 주먹을 휘둘렀는데 실제 몸이 움직여서 옆사람의 얼굴에 멍이 들기도 하죠.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={course5_7_4} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '사건수면은 그 자체로 불면증을 유발할 수 있고, 불면증이 이를 더 심화시키기도 한답니다. 그러니 위에서 이야기한 것과 같은 증상이 있는 경우에는 의사에게 찾아 상담해 보시길 바랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <SleepingSurveySquareButton title="낮 시간에 갑자기 참을 수 없이 잠에 빠져드는 일이 있나요?" isFirstPage />
      ),
    },

    {
      title: '기면병',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '기면병은 10대 또는 20대 초기에 시작되는 비교적 드문 질환이랍니다. 가장 흔한 증상으로는 갑자기 잠에 드는 것이 있죠. 주로 안 자야 하는 상황, 그러니까 직장에서 중요한 회의를 하고 있다거나 식사 혹은 대화를 하는 상황에서 갑자기 잠이 듭니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={course5_7_5} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '기면병이 있을 때에는 흔히 감정적 자극이 있을 때 근육에 힘이 빠지는 증상이 나타납니다. 가령 정말 웃긴 이야기를 들어 웃으려고 할 때 얼굴 근육에 힘이 들어가지 않아 못 웃게 되는 경우가 있죠. 또한 기면병이 있는 경우에는 가위눌림을 종종 경험하기도 합니다.',
                '\n',
                '기면병은 수면을 방해하지만, 이를 인지행동치료로 해결할 수는 없습니다. 주로 약물치료를 진행해야 하죠. 그러니 만약 위에서 언급한 것과 같은 증상이 있다면 근처 병원에 방문해 보시기를 권유드립니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '요약해보도록 할까요?',
      texts: [
        {
          text: '앞서 불면증을 일으키는 다른 질환들에 대해 살펴보았어요. 요약해서 보도록 할게요.',
        },
        {
          component: <SleepingDiseaseSummary />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '위 질환과 관련된 문제는 의사와 직접 만나 상의해 보는 게 좋을 거예요. 저는 마음을 이용해 불면증을 개선시키는 방법들을 알려드리고 있지만, 위에 나온 질환들은 그보다 더 적절한 치료방법들이 있기 때문이에요.',
                '\n',
                '그러면 잠시 쉬었다가 진행하도록 할까요? 다음 시간에는 더 중요한 이야기를 나눌 거예요. 잠시 쉬었다가 만나요!',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '불면증이 길어지는 이유',
      fullImage: course5_8_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '잘 쉬고 오셨나요? 그러면 다시 이야기를 이어나가볼게요. 이번 시간에는 제법 중요한 이야기를 하거예요. 그러니 잘 따라오셔야 해요.',
                '\n',
                '사실 우리 모두 잠을 잘 못 자는 시기가 있죠. 걱정이 우리들의 마음을 사로잡기도 하고, 아침에 낮잠을 너무 많이 자서 도통 잠이 오지 않기도 하고요. 가끔은 시차적응이 지독하게 오래 걸려서 제법 오래 잠을 못 자기도 합니다. 하지만 왜 일부는 왜 지속적인 불면증에 시달리게 되는 걸까요? 오늘은 ',
                '불면증이 길어지는 이유',
                '를 살펴보려고 합니다. 아마 지금 불면증으로 고생하고 계시다면 지금의 상황을 이해하는 데에 많은 도움이 될 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '불면증을 만드는 세 가지 요인',
      image: course5_8_2,
      bg: '#21222C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '불면증을 만드는 요인에는 크게 세 가지 요인이 있습니다. 선행 요인, 유발 요인, 그리고 지속 요인이죠. 쉽게 이야기하면 어떠한 선행 요인이 있는 상황에서 유발 요인이 작용하여 불면증이 발생하고, 이후 지속 요인에 의해 불면증이 만성화된다는 뜻입니다. 그러면 하나씩 알아볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '선행 요인',
      bg: '',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '선행 요인은 ',
                '우리들의 수면 시스템을 취약하게 할 수 있는 모든 요인',
                '들을 의미합니다. 제가 만났던 분들의 이야기를 들어볼까요?',
              ]}
            />
          ),
        },
        {
          component: (
            <div>
              <MessageBlockWithTail
                textList={[
                  ['저는 원래 사소한 일들에도 걱정이 좀 많은 편이에요...'],
                  ['저희 어머니도 잠을 좀 못 주무셔요.'],
                  ['남편이 3교대를 해서요. 저와 수면리듬이 달라요. 제가 잘 때 들어오곤 하죠.'],
                ]}
                type={'gray'}
                my
              />
            </div>
          ),
        },
        {
          text: '이처럼 걱정이 많은 성격, 불면증의 가족력, 수면리듬이 다른 사람과의 생활 등은 모두 불면증이 더 쉽게 발생하도록 하는 선행 요인으로 작용할 수 있습니다.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '유발 요인',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '유발 요인은 ',
                '불면증의 방아쇠를 당기는 것',
                '이라고 생각하면 됩니다. 즉, 선행 요인에 의해 수면에 대해 예민한 상태에서 어떠한 사건이 발생하여 불면증을 유발하는 것이지요. 제가 만났던 분들의 이야기를 몇 가지 살펴볼게요.',
              ]}
            />
          ),
        },
        {
          component: (
            <div>
              <MessageBlockWithTail
                textList={[
                  ['천식을 앓고 나서부터는 밤마다 기침이 나서 잠을 못 자요'],
                  ['어머니가 돌아가신 후 도통 잠이 오지 않아요'],
                  ['육아를 시작하고 나서부터는 잠을 못 자요'],
                ]}
                type={'gray'}
                my
              />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '위와 같이 의학적 질환, 일시적인 스트레스, 이별 및 사별로 인한 정서 변화, 생활 패턴의 변화 등이 유발 요인에 해당합니다.',
                '\n',
                '불면증이 발생했을 때 ',
                '유발 요인을 교정하면 불면증이 호전되는 경우가 있습니다.',
                ' 질환을 치료하면 다시 잠을 잘 자기도 하고, 이별 및 사별한 날로부터 시간이 조금 더 지나면 다시 정서가 안정적으로 자리잡기도 하죠. 육아를 도와줄 사람이 생기고 아이가 커감에 따라 내 시간이 확보되면 다시 잠을 잘 자기도 한답니다.',
                '\n',
                '문제은 유발 요인에 의해 불면증이 발생했을 때 이것이 ',
                '지속 요인에 의해 고질적인 문제로 발전한다는 것',
                '이지요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '지속 요인 : 불면증의 만성화',
      image: course5_8_3,
      bg: '#21222C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '지속 요인은 ',
                '불면증을 만성화하는 모든 요인들',
                '을 뜻합니다. 크게 인지적인 요소와 행동적인 요소가 있죠. 인지적인 요소는 수면을 방해하는 생각들을 뜻하고, 행동적인 요소는 수면을 방해하는 잘못된 습관들을 뜻한답니다. 예를 들어볼게요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['잠자리에 누우면 오늘도 잠들지 못하면 어쩌나 하고 초조해요'],
                ['잠이 오지 않으면 집 밖으로 나가 운동을 하고 와요. 신체를 피곤하게 하면 잠이 더 잘 오니까요.'],
                [
                  '잠이 안 올 땐 일단 무작정 누워있어요. 다른 것들을 하면 더 잠이 안 오잖아요. 지루하기라도 해야 잠이 잘 오겠죠.',
                ],
              ]}
              type={'gray'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '불면증이 있는 사람들은 종종 수면습관을 바꾸거나 부족한 잠을 보충하기 위해 노력하지만, 흔히 그러한 노력들이 외려 상황을 악화시키는 경우가 많습니다.',
                '\n',
                '가령 잠자리에 오래 누워있다보면 깨어 있는 상태로 침대에 머무르는 시간이 길어지고, 이는 높은 수준의 스트레스를 유발합니다. 그리고 시간이 지나면 슬슬 이러한 생각이 들곤 하죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['잠이 안 오면 어떡하지? 잘 수 있는 시간이 네 시간밖에 안 남았는데...']]}
              type={'gray'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '그 결과 결국 정말로 잠에 못 들게 되죠. ',
                '가장 좋은 수면은 오히려 수면에 대해 생각하지 않는 것',
                '이랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '저는 지속 요인을 끊을 겁니다',
      image: course5_8_4,
      bg: '#21222C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '일상생활에서도 선행 요인과 유발 요인에 의해 잠을 잘 못 자는 시기가 발생할 수 있어요. 자연스러운 일입니다. 갑자기 정말 근심스러운 일이 발생했을 때, 혹은 요즘 어려운 시기를 보내고 있다면 잠이 잘 올 수가 없지요. 하지만 일시적인 불면 증상은 지속 요인이 없다면 자연적으로 회복되기 마련입니다.',
                '\n',
                <span />,
                '문제는 지속 요인',
                '이지요. 수면과 불면증에 대한 잘못된 생각들, 건강하지 않은 수면 습관, 불면증에 대한 걱정과 같은 지속 요인들이 오랜 기간 지속되는 불면증을 유발하고 있답니다. 저는 앞으로 이 지속 요인을 끊을 거예요.',
                '\n',
                '우리가 어떠한 부분에 집중해서 불면증을 치료할지 조금 이해가 되었을까요? 우선 오늘 내용을 조금 되짚어보도록 해요. 제가 몇 가지 여쭤볼게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="불면증의 인지행동치료에서 타겟으로 하는 요인은?"
          buttonList={['유발 요인', '선행 요인', '지속 요인', '자연 요인']}
          answer="지속 요인"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="지속 요인을 끊을 거예요!"
          answerInfo="선행 요인과 유발 요인에 의해 발생한 불면 증상은 시간이 지나면 회복되기 마련이지만, 지속 요인에 의해 만성화된 불면증은 치료적 접근이 필요하답니다. 특히 3개월 이상 만성화된 불면증에서는 약물에 의한 치료보다 인지행동치료를 통해 불면증의 만성 고리를 끊어버리는 게 중요해요."
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="선행 요인과 유발 요인만으로 발생한 불면증은 시간이 지나도 자연 회복되지 않는다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="일시적인 불면 증상은 지속 요인이 없다면 자연적으로 회복됩니다" />,
    },
    {
      replaceComponent: (
        <QuizTemplate index={3} question="경우에 따라 유발 요인을 해결하면 불면증이 개선되기도 한다" answer="O" isOX />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="맞아요, 때에 따라 유발 요인을 교정하면 불면증이 호전되기도 합니다" />,
    },
    {
      title: '좋아요, 잘 이해하셨네요.',
      bg: '',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '금방 잘 이해하시네요. 지속 요인이 중요하다는 사실을 생각하며 제 치료를 따라오다보면 제가 ‘인지적 요소’와 ‘행동적 요소’를 바꾸려고 다양한 것들을 제시한다는 사실을 느낄 수 있을 거예요.',
                '\n',
                '오늘은 여기까지 할까요? 지난 이틀간 잠자리는 조금 어떠셨나요? 치료를 시작했다는 생각만으로도 위안이 많이 되어 조금은 편히 잘 수 있었나요? 혹은 여전히 아무런 개선이 되지 않아 답답하고 힘든 시간을 보내고 계실 수도 있겠죠. 걱정마세요. 이제 곧 다양한 것들을 시도해보면 분명 방법을 찾을 수 있을 거예요.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={course5_8_5} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '내일도 수면을 기록하는 것 잊으면 안 돼요. 앞으로 수면 기록을 기반으로 많은 것들을 진행할 계획이거든요. 자, 그럼 이제 저도 오늘을 마무리 해 볼까해요. 오늘은 Debussy의 <Claire de lune>이라는 노래를 들을까 봐요. 듣고 있다 보면 금새 마음이 편해지고 졸음이 찾아오기도 한답니다.',
                '\n',
                '자, 그럼 우리는 내일 또 만나요. 오늘 밤도 편안히 보내시길 바랄게요. 설령 그러지 않더라도 걱정마시고요. 제가 옆에서 도와드릴 거니까요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SurveyDone notHasCas={true} />,
      progressHide: true,
    },
  ],
  [
    {
      title: '지난 밤은 잘 잤나요?',
      fullImage: course5_9_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '저는 지난 밤에 조금 뒤척였답니다. 어릴 적에 썼던 감정 노트들을 살펴보니 무언가 쓸쓸한 마음이 들어서 이런저런 생각이 많았어요. 그러다 보니 밤늦게 잠들었지 뭐예요. 웃기죠. 수면에 대해 가르치는 사람도 이렇게 뒤척일 수 있다니요. 하지만 사실 그 부분이 중요한 거랍니다. ',
                '좋은 날도 있고, 나쁜 날도 있다',
                '는 것 말이에요.',
                '\n',
                '자, 그럼 오늘 이야기를 시작해 볼까요? 오늘은 불면증의 약물 치료와 비약물 치료에 대해 알아볼 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '약물에 대해 먼저 알아볼게요',
      image: course5_9_2,
      bg: '#212E52',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '흔히 수면 문제를 겪을 때 가장 많이 찾는 게 바로 수면제랍니다. 수면제를 먹으면 곧바로 깊은 잠에 빠지고 잃어버린 밤을 되찾을 수 있을 거라고 생각하죠.',
                '\n',
                '물론 오래전부터 의사들은 불면증에 약물 처방을 해 왔어요. 하지만 최근 연구결과들에 따르면 ',
                '수면제는 3개월 이상 지속된 불면증에 한하여 단기적으로만 사용',
                '해야 합니다. 일반적으로 ',
                '2주 이상 복용하면 약효는 떨어지고 아침에 주의 집중력이 떨어지는 부작용 등이 발생',
                '할 수 있어요. 수면제로 사용하는 몇몇 약들에 대해서는 ',
                '중독성이 있다는 결과',
                '들도 있답니다. ',
                '\n',
                '결정적으로 수면제가 만성적인 수면 문제에 도움이 된다는 연구 결과는 거의 없습니다. ',
                '만성적인 수면 문제에 도움이 된다고 밝혀진 방법은 지금 저희가 진행하고 있는 수면 코스, 즉, 수면 인지행동치료입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '조금 더 자세히 이야기해 볼게요',
      fullImage: course5_9_3,
      height: 190,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '많은 연구에서 ',
                '수면제를 복용하면 조금 더 빨리 잠들거나 조금 더 오래 잠을 잘 수 있다',
                '고 합니다. 이런 약물들은 대체로 효과가 빠르게 나타나고 만성화되지 않은 불면증에 도움이 되죠. 하지만 ',
                '3개월 이상의 만성화된 불면증에는 거의 도움이 안 돼요.',
                '\n',
                <span />,
                '또 많은 약물들이 ',
                '시간이 지나면 내성이 생기고 효과가 감소',
                '합니다. 즉, 초기와 동일한 효과를 보려면 더 많은 수면제를 복용해야 한다는 뜻이지요. 정말 ',
                '최악의 경우에는 수면제는 별로 효과가 없지만 약을 중단하면 불면증이 더 심해지는 상황',
                '에 빠질 수도 있답니다.',
                '\n',
                '내성을 피하려면 수면제를 단기간으로만 사용해야 하고, 그런 이유에서 많은 의사들이 수면제를 2주 이상 처방하지 않고 있어요. 그러니 ',
                '오래전 잃어버린 나의 밤을 되찾기 위해 수면제에 의존하는 것은 현명한 선택이 아니랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '약물을 조금만 소개해 드릴게요',
      texts: [
        {
          text: '불면증에 사용되는 약물으로는 벤조디아제핀, 비벤조디아제핀, 멜라토닌, 항우울제 등이 있어요.',
        },
        {
          component: (
            <MessageBlock
              textList={[
                {
                  title: '1. 벤조디아제핀 계열 수면제',
                  text: [
                    '벤조디아제핀은 가장 많이 사용하는 약물이지만 일부 약물은 불안 증상에 대해서만 승인을 받았으며 불면 증상에 대해서는 아직 승인을 받지 못했어요. 특히 ',
                    '내성과 중독',
                    '이 있기 때문에 조심해서 사용해야 하는 약물이기도 하죠.',
                  ],
                },
                {
                  title: '2. 비벤조디아제핀 계열 수면제',
                  text: [
                    '비벤조디아제핀 계열의 수면제로는 대표적으로 ',
                    '졸피뎀',
                    '이 있어요. 졸피뎀은 벤조디아제핀보다 약효가 빠르고 지속 시간이 짧다는 장점이 있지만 ',
                    '두통, 몽유병, 기분장애 등 다양한 부작용',
                    '이 있기 때문에 역시 조심해서 사용해야 하는 약물입니다.',
                  ],
                },
                {
                  title: '3. 멜라토닌',
                  text: [
                    '멜라토닌은 수면유도제입니다. 수면제가 생체시계를 꺼서 수면을 유도하는 것이라고 하면, 멜라토닌은 ',
                    '생체시계의 시간 설정을 바꿔 ‘지금이 자야 하는 시기’라고 알려주는 역할',
                    '을 하죠. 그렇기 때문에 멜라토닌은 수면리듬장애, 시차로 인한 불면 등에 더 효과적으로 작용합니다. 연구 결과에 따르면 멜라토닌은 잠이 들기까지의 시간을 조금 줄여주고 수면의 질을 높여준다는 보고가 있지만 ',
                    '불면증에 대한 효과는 아직 명확히 입증된 바가 없어요.',
                  ],
                },
                {
                  title: '4. 항우울제',
                  text: [
                    '일부 항우울제는 수면을 유도하는 효과가 있다고 알려져 있지만, 이 약들이 장기적으로 불면증에 효과가 있다는 연구는 거의 없답니다.',
                  ],
                },
              ]}
              type={'light_blue'}
              margin="mt-[30px]"
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '많은 내용들을 배웠네요',
      texts: [
        {
          text: '저희는 수면 코스에서 약물을 사용하진 않지만 수면제가 어떠한 경우에 효과가 있는지 명확히 이해하고 있는 건 중요하답니다. 그러면 이번 시간에 배운 내용을 한번 복습해 볼까요?',
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate index={1} question="수면제는 3개월 이상의 만성화된 불면증에 충분한 효과가 있다" answer="X" isOX />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="수면제는 3개월 이상의 만성화된 불면증에는 거의 도움이 안 돼요" />,
    },
    {
      replaceComponent: (
        <QuizTemplate index={2} question="수면제는 대부분 부작용이 적어 안전하게 이용할 수 있다" answer="X" isOX />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate title="시간이 지나면 내성이 생기고 불면증이 심해지는 부작용이 있을 수 있어요" />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate index={3} question="수면제는 사용하더라도 단기적으로만 사용하는 것이 원칙이다" answer="O" isOX />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="수면제의 내성을 피하기 위해 2주 이상 처방하지 않아요" />,
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={4}
          question="불면증에 있어 인지행동치료는 수면제보다 더 지속적이고 안전한 치료이다"
          answer="O"
          isOX
        />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="맞아요, 만성적인 수면에 도움이 되는 치료법은 인지행동치료입니다" />,
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={5}
          question="멜라토닌은 부작용이 없고 불면증에 대한 뛰어난 효과를 보여 최근 불면증의 주요 치료 방법으로 떠오르고 있다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate title="전체적인 수면의 질이나 수면 시간대에 대한 효과를 제대로 입증한 바가 없어요" />
      ),
    },
    {
      title: '약물에 대해서는 의사와 상의하세요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '약물과 관련하여서는 담당 의사와 상의를 하는 게 좋아요. 담당 의사의 판단에 따라 수면제를 사용할 수도 있고, 그게 나쁜 것만은 아니랍니다. 가령 인지행동치료를 진행하며 수면제를 사용하면 치료 초반에 나타날 수 있는 긴장이나 걱정을 줄여 치료 효과를 높일 수 있기도 해요. 여러분의 건강과 관련하여 가장 잘 알고 있는 사람은 여러분을 직접 만나고 가장 많은 이야기를 직접 들어온 담당 의사일 겁니다. 그러니 ',
                '약물과 관련하여서는 그들의 조언에 집중하세요.',
                <span />,
                '\n',
                '약물에 대한 이야기는 여기까지 하도록 할까요? 이 정도만 알고 있어도 여러분은 수면제에 대해 제법 깊은 지식을 가지고 있다고 할 수 있답니다. 제가 알려드렸던 것들이 향후 수면제를 복용할 일이 있을 때 많은 도움이 될 거예요.',
                '\n',
                '오늘은 Erik Satie의 <Gymnopedie No. 3>라는 노래를 추천해 드릴게요. 느린 박자에 선율이 포근하여 잠이 솔솔 올 것만 같은 노래죠. 자, 그러면 잠시 쉬었다가 만날까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '약 없이도 잘 잘 수 있는 방법',
      fullImage: course5_10_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '잘 쉬고 왔나요? 그럼 조금만 더 이야기해 봐요. 이제 오늘의 마지막 수업이랍니다. 앞선 수업에서 제가 수면제에 대해 너무 안 좋게 말했나요? 하지만 모두 연구결과에 기반하여 말씀드린 것이랍니다.',
                '\n',
                '많은 분들이 수면 문제가 있을 때 수면제에 많이 의지합니다. 알약을 하나 먹으면 모든 문제들이 해결될 것이라 생각하죠. 그리곤 점차 약에 의지하다가 더 깊은 불면에 빠져버리게 됩니다. 저는 그런 사람들을 정말 많이 보았어요.',
                '\n',
                '혹시 지금 퀭한 눈으로 이 글을 읽고 있는 분에게는 제가 희망을 꺾어버렸을지도 모르겠어요. 하지만 걱정하지 않아도 된답니다. ',
                '약 없이도 잃어버린 잠을 되찾아 온 사람들은 정말 많거든요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '역사 깊은 치료방법이랍니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '약물 없이 불면증을 치료하는 방법을 ',
                '‘불면증의 비약물적 치료’',
                '라고 한답니다. 비약물적 치료는 오래전부터 많은 학자들에 의해 연구되어왔어요. 가끔은 뛰어난 선배 학자분들의 이름을 빌리는 게 더 신뢰성을 줄 때도 있더라고요. 그분들의 이름을 빌려 불면증의 비약물적 치료가 어떻게 발전해 왔는지 간단하게 설명드려볼게요.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={course5_10_4} />,
        },
        {
          component: <p className="text-[20px] leading-[26px] mb-[15px] font-bold">1. 몸을 이완하는 것이 효과 있다</p>,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '얼핏 생각하면 당연한 말이라 생각이 되지만, 당연하다고 생각하는 것과 실제 엄격한 연구를 통해 그러하다고 이야기하는 것 사이에는 많은 차이가 있답니다. ',
                '\n',
                '1959년, Schultz와 Luthe라고 하는 학자들은 ',
                '몸과 마음을 이완시키는 것',
                '이 불면증에 효과가 있다고 발표했죠.',
                '\n',
                '이어서 Jacobson이 1964년 ',
                '점진적 근육 이완법',
                '이 불면을 호전시킨다고 발표하였고, 이를 기반으로 1970년에는 많은 연구들에 의해 이완요법이 불면에 효과가 있다는 사실이 밝혀졌답니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={course5_10_2} />,
        },
        {
          component: (
            <p className="text-[20px] leading-[26px] mb-[15px] font-bold">2. 자극과 리듬을 조절하는 것이 효과 있다</p>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '1972년, Bootzin은 ',
                '잠자리에 들고 잠자리에서 나오는 스케줄을 일정',
                '하게 하고, ',
                '낮잠을 자지 않는 자극 조절',
                '이 수면을 개선한다고 발표했어요.',
                '\n',
                '또한 1987년에는 Spielman이 ',
                '침대에 누워있는 시간을 줄이는 수면 제한요법',
                '이 수면 유지의 개선에 효과가 있다는 사실을 밝혔습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '그 모든 것의 집결체가 바로 지금 하고 있는 이 치료랍니다',
      image: course5_10_3,
      bg: '#B3C0D2',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이후 Edinger와 Morin을 비롯한 많은 학자들이 불면증의 비약물적 치료가 효과적이라는 사실을 밝혀냈답니다. 그리고 그 결과물이 바로 지금 하고 있는 이 치료예요. 저도 괜히 자신있게 효과가 있다고 말씀드렸던 게 아니랍니다.',
                '\n',
                '불면증의 비약물적 치료는 ',
                '수면에 대한 잘못된 믿음을 바로잡고, 이완요법, 자극 조절, 수면 제한 등을 진행',
                '하게 됩니다. 앞으로 이 모든 것들을 진행할 거예요.',
                '\n',
                '지금처럼만 꾸준히 수면을 기록하다 보면 제가 다른 과제들을 제시해 드릴 거예요. 그때부터는 조금씩 변화하고 있다는 게 느껴질 거랍니다. 그러니 힘들어도 참고 계속 진행해 보도록 해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '비약물적 치료는 인지행동치료에 기반합니다',
      texts: [
        {
          text: '비약물적 치료는 인지행동치료라는 이론에 기반하고 있습니다. 인지행동치료는 크게 두 부분으로 구성되어 있죠.',
        },
        {
          component: (
            <MessageBlock
              textList={[
                {
                  title: '생각에 근거한 치료방법입니다',
                  text: [
                    '침대에 누우면 “오늘도 잠에 들지 못하면 어쩌지”하며 불안하진 않나요? 잠을 자야 한다는 압박감에 더 잠을 못 주무시진 않나요? 불면증을 앓고 계신 많은 분들이 수면에 대한 강박관념과 걱정, 그리고 불안을 가지고 계십니다. 그리고 그러한 생각들은 오히려 우리들의 잠을 방해하죠. 인지행동치료의 큰 축 중 하나는 그러한 생각을 바꾸는 데에 있어요.',
                  ],
                },
                {
                  title: '행동에 근거한 치료방법입니다',
                  text: [
                    '생각과 별도로 우리 몸에는 생체시계가 있답니다. 생체시계는 지금이 낮인지 밤인지 인지하고 24시간 주기로 수면과 각성을 조절하죠. 많은 경우, 불면증에서는 이러한 생체시계가 고장나있는 경우가 많답니다. 인지행동치료는 생체시계를 교란하는 행동들을 교정하고, 우리 몸이 수면 주기에 최적화할 수 있도록 한답니다.',
                  ],
                },
              ]}
              type={'light_blue'}
              my
            />
          ),
        },
        {
          text: '한 가지 추가로 더 말씀드리고 싶은 게 있어요. 인지행동치료는 치료의 특성상 초반에는 큰 변화가 없을 수도 있다는 점이에요. 하지만 치료를 꾸준히 지속해 나가면 분명 큰 변화를 느낄 수 있을 거예요. 이미 많은 연구들이 입증하고 있답니다.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '단점보다는 장점이 훨씬 많은 치료예요',
      texts: [
        {
          text: '언젠가 치료를 진행하기 전에 걱정이 많은 제임스가 제게 물었어요.',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['gray', 'white']}
              texts={[
                [
                  '선생님, 비약물적 치료는 부작용이 없나요? 4주 동안 진행된다고 하는데 혹시나 부작용은 없을지 걱정돼요.',
                ],
                ['비약물적 치료는 상당히 안전하고 효과적인 치료랍니다'],
              ]}
              my
              opposite
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '제가 자신감 있게 말한 이유가 있답니다. 비약물적 치료의 부작용은 대개 일시적이고 대부분은 적절히 조절할 수 있답니다. 가장 큰 부작용이라고 하면 ',
                '치료 초반에 낮 동안 졸리는 것',
                '이라고 말할 수 있겠네요.',
                '\n',
                '다른 부작용으로는 가끔은 수면 시간을 제한하는 것에 ',
                '불안감',
                '을 느끼는 분들이 있다는 것입니다. 하지만 이런 불안감은 ',
                '대부분 일시적이며 이완요법으로 완화',
                '할 수 있으며, 치료가 진행되며 수면시간을 늘려감에 따라 충분히 해소가 가능하답니다.',
                '\n',
                '단점보다는 오히려 장점이 많은 치료랍니다. 우선 한 번 ',
                '치료를 잘 수행하면 그 효과가 2년까지도 지속',
                '되는 것으로 알려져 있어요. 적절하게 치료를 받은 사람들은 ',
                '치료 후 수면할 때나 혹은 낮 시간을 보낼 때 약물치료를 받은 사람들보다 더 만족도가 높다',
                '고도 알려져 있죠.',
                '\n',
                '즉, 큰 부작용은 없으며, 잘 진행하기만 하면 상당히 효과적인 치료라고 할 수 있겠어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '비약물적 치료를 하지 않아야 하는 경우도 있답니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '주로 이전 수업에서 많이 물어본 것들이에요. 우선 ',
                '다른 내과적, 정신과적 질환이 있는 경우',
                '라면 수면 시간을 제한할 때 해당 질환의 증상이 악화될 수 있답니다. 수면 시간이 주는 것이니 당연하겠지요? 따라서 흔히 동반된 질환이 있는 경우에는, 해당 질환을 먼저 치료하고 호전된 이후 불면증의 비약물적 치료에 참여할 것으로 권하고 있어요. 물론 동반 질환이 잘 조절이 되고 있다면 동반 질환에 대한 치료와 불면증의 비약물적 치료를 병행할 수도 있어요. 그러니 만약 동반질환이 있는 경우라면 ',
                '담당 의사에게 불면증의 비약물적 치료를 시작해도 되는지 문의',
                '하기를 바랄게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '조금만 복습해 볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘도 많은 것들을 배웠네요. 지금 진행하고 있는 인지행동치료가 얼마나 중요한지 잘 이해가 되었으면 해요.',
                '\n',
                '마치기 전에 간단하게 퀴즈를 풀어볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate index={1} question="불면증의 비약물적 치료는 인지행동치료를 기반으로 한다" isOX answer="O" />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="맞아요. 인지행동치료라는 이론에 기반하고 있습니다." />,
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="불면증의 비약물적 치료의 부작용은 주로 치료 초반 낮 시간에 졸리는 것으로 대개 일시적이고 조절될 수 있는 것들이다"
          isOX
          answer="O"
        />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="치료 초반에 졸릴 수 있지만 대부분 일시적인 현상입니다" />,
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="불면증의 비약물적 치료는 잘 수행하면 그 효과가 2년까지 지속되기도 한다"
          isOX
          answer="O"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate title="최대 2년까지도 지속되고, 수면할 때, 낮 시간을 보낼 때 만족도가 높은 치료예요" />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={4}
          question="다른 내과적, 정신과적 질환이 있다고 하더라도 불면증의 비약물적 치료는 매우 안전하므로 담당 의사와의 논의 없이 바로 진행하여도 괜찮다"
          isOX
          answer="X"
        />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="다른 질환이 있는 경우 비약물적 치료보다는 담당 의사와 상의해야 해요" />,
    },
    {
      title: '좋아요, 잘 이해하셨네요.',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '그러면 오늘은 여기까지 해 보기로 해요. 정말 많은 것들을 배우셨답니다. 다시 말씀드리지만 4주 동안의 코스가 끝나면 여러분은 의과대학을 막 졸업한 의사보다 불면증에 대해 더 많은 지식을 가지고 있을 거예요. 수면 전문가가 되는 거죠.',
                '\n',
                '아참, 수면 기록은 반드시 꾸준히 하셔야 해요. 조만간 제가 수면 기록을 분석해서 앞으로 언제 잠자리에 가야 하는지, 지켜야 할 것들은 무엇인지 등에 대해 말씀드릴 거예요.',
                '\n',
                '오늘도 고생 많았어요. 저는 고양이와 조금 더 시간을 보내면서 하루를 정리할까 봐요. 요즘 고양이가 너무 외로워하는 것 같거든요. 제가 진료실에 오는 분들에게만 너무 집중했나 봐요. 자, 그러면 다음 시간에 만나요. 혹 잠에 쉽사리 들지 못하더라도 오늘 밤은 마음 편하게 먹는 거예요. 곧 괜찮아질 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SurveyDone notHasCas={true} />,
      progressHide: true,
    },
  ],
  [
    {
      title: '생체 시계와 항상성 이해하기',
      fullImage: course5_11_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요. 지난 밤은 어떠셨나요? 오늘도 수면을 기록하셨군요. 참 잘하셨습니다. 이틀 뒤에는 제가 본격적으로 수면 기록을 기반으로 한 수면 스케줄을 관리해 드릴 거예요. 조금만 더 기록해 보도록 해요.',
                '\n',
                '앞선 수업에서 불면증의 인지행동치료는 크게 인지를 교정하는 것, 그리고 행동을 교정하는 것, 두 가지로 구성된다고 말씀드렸었죠. 오늘은 ',
                '행동을 교정하는 것의 원리',
                '에 대해 설명드리고자 합니다. 내일부터 실제 실천해야 하는 것들을 제시할 계획인데, 이걸 왜 해야 하는지 모르고 할 수는 없으니까요. 오늘 수업의 내용이 앞으로 불면을 개선해 나가는 데에 분명 많은 도움이 될 겁니다.',
                '\n',
                '자, 그럼 시작해 볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '우리 몸에도 시계가 있답니다',
      image: course5_11_2,
      bg: '#2F3E54',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '밤이 되면 잠에 들고, 낮이 되면 다시 잠에서 깹니다. 우린 대체 어떻게 그렇게 규칙적으로 생활할 수 있는 것일까요? 창밖에 해가 뜨고 지는 것을 보고 몸이 알게 되는 걸까요? 단순히 하늘을 보고 아는 건 아닐 겁니다. 시차가 있는 해외로 가면 해가 떠 있는 시간에도 졸음이 몰려오거든요.',
                '\n',
                '사실 우리 몸에는 ',
                '생체 시계',
                '가 있답니다. ',
                '24시간 주기로 작동',
                '하면서 ',
                '소화, 체온, 수면 등 신체의 일과를 조정',
                '하죠. 예를 들면 사람의 체온은 새벽 3시 즈음에 가장 낮게 내려가고, 오후 3시에는 가장 높아집니다. 생체 시계가 이를 조절하는 것이죠. 생체 시계는 수면-각성 주기, 즉, 우리가 잠들고 깨어나는 주기를 조절하기도 합니다. 그렇기 때문에 불면을 이해하기 위해서는 생체 시계에 대해 잘 알 필요가 있지요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '생체 시계는 빛에 반응해요',
      fullImage: course5_11_3,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리 뇌의 ',
                '시신경교차상핵(SCN)',
                '이라고 하는 부위는 생체 시계에 신호를 보내 ',
                '수면-각성 주기를 조절',
                '한답니다. 중요한 것은 이때 ',
                'SCN이 가장 크게 참조하는 것은 바로 빛',
                '이라는 점입니다. 우리의 눈은 빛의 강도와 밝기를 측정하여 SCN에 신호를 보내죠. SCN은 이를 토대로 ',
                '어두울 때에는 더 졸리게, 밝은 곳에서는 더 각성',
                '되도록 생체 시계를 조절한답니다.',
                '\n',
                '이러한 내용을 바탕으로 앞으로 저는 빛에 의해 생체 시계가 교란되지 않도록 몇 가지 규칙과 과제를 제시할 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수면 항상성을 지켜야 해요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '항상성이란 ',
                '우리 몸의 상태를 일정하게 유지하려는 성질',
                '을 뜻합니다. 수면도 마찬가지로 항상성이 있답니다. 조금 더 쉽게 비유해 볼게요. 우리는 사실 우리 몸에게 시간을 빌리고 있답니다. 그 시간을 잠을 잠으로써 다시 보상할 수 있어요. 평균적으로 우리 몸은 8시간 잠을 자면 16시간 정도의 시간을 빌려준답니다.',
                '\n',
                '우리가 8시간 정도 자면 우리의 몸은 다음과 같이 말하죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['좋아. 그러면 16시간 정도 시간을 줄게. 그 시간 동안 너가 하고 싶은 걸 해.']]}
              type={'gray'}
              opposite
              my
            />
          ),
        },
        {
          text: '하지만 우리 몸이 꼭 그렇게 엄격한 건 아니랍니다. 언젠가는 우리가 몸에게 이렇게 이야기할 수도 있죠.',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[
                ['정말 미안한데 내가 오늘은 진짜 해야 할 일들이 있어서 그런데, 오늘은 6시간만 자도 될까?'],
                ['좋아. 대신 다음에 언젠가는 8시간보다 조금 더 자서 갚아야 할 거야.'],
              ]}
              my
            />
          ),
        },
        {
          text: '그러다가 우리가 갈수록 뻔뻔해지면 어떻게 될까요?',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['나 오늘은 4시간만 잘게...'],
                ['오늘은 정말 급해서 그래. 5시간만...'],
                ['6시간만 자도 될까?'],
              ]}
              type={'white'}
              my
            />
          ),
        },
        {
          text: '몸은 폭발하고 맙니다.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '안 돼. 지난번에 빌려준 것도 다 안 갚았잖아. 왜 계속 갚지도 않고 더 빌리려고만 해? 이번엔 빌려주지 못해. 이번엔 자야 할 거야.',
                ],
              ]}
              type={'gray'}
              opposite
              my
            />
          ),
        },
        {
          text: '결국 우리의 의지와는 무관하게 눈꺼풀이 내려오고, 정신이 혼미해집니다. 그동안 빌렸던 잠을 되갚느라 하루를 10시간 동안 꼬박 잠들 수도 있죠.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '항상성에는 두 가지 특징이 있어요',
      texts: [
        {
          text: '이전 대화로부터 수면 항상성의 두 가지 특징을 확인할 수 있답니다.',
        },
        {
          component: <FullImage image={course5_11_4} />,
        },
        {
          component: <p className="text-[20px] leading-[26px] mb-[15px] font-bold">1. 잠은 빌릴 수도 있다</p>,
        },
        {
          text: '우리 몸은 한두 번 적게 잤다고 하여 화내지 않습니다. 언젠가는 조금 더 자라고만 말하죠. 즉, 오늘 못 잔 잠은 다음 번 언젠가 조금 더 보충하면 그만입니다. 그러니 잠이 안 올 때 “오늘 못 자면 어떻게 하지”라며 불안해할 필요 없답니다. 잠은 빌릴 수도 있으며, 우리 몸은 하루 불충분하게 자더라도 일상생활을 해 나가는 데에 큰 문제가 없답니다.',
        },
        {
          component: <FullImage image={course5_11_5} />,
        },
        {
          component: (
            <p className="text-[20px] leading-[26px] mb-[15px] font-bold">2. 다른 시간에 자면 밤엔 덜 자게 된다</p>
          ),
        },
        {
          text: [
            '당연한 이야기이지만 항상성의 입장에서 보면 보다 명확합니다. 가령 우리가 낮잠을 잔다면, 밤에는 더 적은 시간을 자도 된다는 의미입니다. 문제는 밤에 더 적은 시간을 자게 되면 우리는 잠자리 누워 “왜 잠이 안 오지, 이대로 못 자면 어떻게 하지”하며 불안해한다는 것이죠.',
            <br />,
            <br />,
            '저는 앞으로 여러분의 수면 시간과 낮잠을 제한할 거랍니다. 수면 기록을 기반으로 구체적으로 언제 잠자리에 들고 일어나야 하는지 알려드릴 거예요. 그 원리에는 수면 항상성이 있다는 점을 기억하시면 많은 도움이 될 거랍니다.',
          ],
        },
      ],
      buttonText: ' ',
    },
    {
      title: '많은 것들을 배웠습니다',
      texts: [
        {
          text: '앞으로 행동치료의 근간이 되는 생체 시계와 수면 항상성의 개념에 대해 배웠어요. 어렵지 않았나요? 잘 이해하셨는지 살펴보기 위해 제가 몇 가지만 여쭤보도록 할게요. 틀려도 괜찮으니 편안한 마음으로 답해보세요.',
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="우리 몸을 24시간 주기로 조절하는 장치를 뭐라고 부를까요?"
          buttonList={['리듬 분석기', '주기 조절기', '생체 시계', '수면 항상성']}
          answer="생체 시계"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="생체 시계가 우리 몸을 조절해요"
          answerInfo="생체 시계는 우리 몸이 24시간을 주기로 조절될 수 있도록 도와준답니다. 특히 생체 시계는 수면-각성 주기를 조절하여 우리들의 수면에 영향을 주고 있어요. 따라서 불면증을 이해하기 위해서는 생체 시계가 무엇인지 잘 알고 있는 게 중요하답니다."
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="생체 시계는 주로 어떠한 자극을 기반으로 수면-각성 주기를 조절하게 될까요?"
          buttonList={['정서', '온도', '소리', '빛']}
          answer="빛"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="생체 시계는 빛에 반응합니다"
          answerInfo="우리 뇌의 SCN이라고 하는 부위는 눈으로부터 빛 자극을 받아 이를 기반으로 생체 시계에 신호를 보낸답니다. 생체 시계는 이를 기반으로 밝을 때에는 각성이 되도록, 어두울 때에는 졸리도록 수면-각성 주기를 조절하게 되죠."
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="수면 항상성의 원리에 따르면, 수면 시간을 줄이면 자연스럽게 수면에 대한 욕구는 더 커지기 마련이다"
          isOX
          answer="O"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="적게 자면 더 졸리기 마련입니다"
          answerInfo="당연한 이야기지요? 하지만 우리 몸이 정말 그러한 원리로 구성되어 있다는 것을 아는 것 자체가 중요하답니다. 저는 앞으로 여러분들의 수면시간을 제한하면서 생체시계를 안정화시키고 수면에 대한 욕구를 극대화할 계획이랍니다. 이를 통해 분명 잃어버린 밤을 되찾아올 수 있을 거예요."
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate index={4} question="낮잠을 자면 밤 시간의 수면에 대한 욕구는 줄어든다" isOX answer="O" />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="낮잠을 자면 밤에 잠이 안옵니다"
          answerInfo="수면 항상성에 따르면 하루에 자야 하는 시간은 대부분 일정하게 정해져있답니다. 그렇기 때문에 낮 시간에 잠을 자면 밤 시간의 수면에 대한 욕구는 당연히 줄어들기 마련입니다. 따라서 저는 여러분의 낮잠을 제한할 거예요. 조금 힘든 시기만 지나면 분명 건강하고 포근한 수면을 되찾을 수 있을 거랍니다."
        />
      ),
    },
    {
      title: '고생 많으셨어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘은 여기까지 할까요? 이미 오늘 수업에서 본격적으로 어떠한 치료들을 진행할지에 대해 많은 이야기를 나눈 것 같아요. ',
                '내일부터는 자극조절요법을 시작',
                '할 거랍니다. ',
                '그 다음날에는 수면 패턴을 안정화시키는 작업',
                '을 할 거예요. 자세한 이야기는 내일하도록 하죠.',
                '\n',
                '어제 밤은 어땠나요? 여전히 많이 뒤척였나요? 괜찮아요. 분명 같이 해결할 수 있습니다. 초조해하지 말고 저와 함께 하나씩 해보아요.',
                '\n',
                '저는 요즘 잠을 잘 자지 못해요. 키우던 고양이가 아파서 병원에 갔거든요. 걱정이 많아요. 유발 요인이 생긴 것이겠죠? 물론 지속 요인이 지금의 상황을 만성화하지 않도록 잘 관리하면 앞으로는 잘 잘 수 있을 거예요. 하지만 여전히 걱정은 많이 돼요. 고양이가 없으면 영 제 집이 쓸쓸할 것 같거든요. 고양이는 제가 유일하게 가까이 교류하는 친구니까요.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={course5_11_6} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '괜한 이야기를 한 것 같군요. 그냥 ',
                '새벽 시간에 깨어있는 사람이 혼자만은 아니라는 말',
                '을 해주고 싶었어요. 다른 누구도 그 깊은 밤에 나름의 이유로 인해 눈을 말똥말똥 뜨고 시간을 보내고 있답니다. 잠이 안 와서 불안할 때는 제가 고양이를 걱정하며 밤을 지새우는 모습을 상상해 보세요. 이 밤에 혼자 잠들지 못하여 큰일이 났다는 생각은 들지 않을 거예요. 저는 가끔 그런 밤이면 Chopin의 <Berceuse Op.57>를 듣는답니다. 듣고 있으면 이내 다시 편안한 마음이 찾아오죠.',
                '\n',
                '좋아요, 그럼 오늘도 고생 많았어요. 우린 내일 만나요. 아침에 일어나면 수면 기록 작성하는 것 잊지 마시고요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SurveyDone notHasCas={true} />,
      progressHide: true,
    },
  ],
  [
    {
      title: '밤을 잃어버린 나의 이야기',
      fullImage: _12_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요, 잘 찾아오셨네요. 지난 밤은 어떠셨나요? 여전히 진척이 없는 것 같아 걱정이 되시나요? 괜찮습니다. 오늘부터 본격적으로 중요한 일들을 시작할 거니까요.',
                '\n',
                '오늘부터는 침실을 수면을 위한 공간으로 만드는 일들을 할 거랍니다. 흔히 ',
                '침실과 수면 사이의 연결성을 강화',
                '한다고 말하죠. 이러한 작업이 왜 필요한지는 다음 이야기를 보면 금방 아실 수 있을 거랍니다. ',
                '\n',
                '만약 여러분이 불면증으로 고생하고 계신 분이라면, 저는 여러분과 대화를 해 보지 않아도 여러분이 보냈던 지난 밤의 상황들을 잘 알고 있습니다. 지금부터 여러분이 ‘어떻게 밤을 잃어버리게 되었는지’ 이야기해 볼게요. ',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '여러분의 이야기',
      fullImage: _12_2,
      texts: [
        {
          text: '여러분은 여느 때와 다르지 않게 침실로 향했습니다. 잠을 잘 자지 못할 거란 생각은 하지 않았죠. 다만 걱정거리가 있거나 중요한 일을 앞두고 있었을 수도 있죠.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['그 일은 어쩐담...'], ['이번 주에 있을 시험을 잘 쳐야 해'], ['이틀 뒤에 중요한 발표가 있지']]}
              type={'gray'}
              my
            />
          ),
        },
        {
          text: '하지만 이상하게 눈이 말똥말똥합니다. 그리고 잠에 대한 생각이 계속 들기 시작하죠.',
        },
        {
          component: <MessageBlockWithTail textList={[['어라, 이상하다...']]} type={'gray'} my />,
        },
        {
          text: '결국 뒤척이다가 휴대폰을 보기도 합니다. 벌써 시간이 많이 지났지요. 잠을 자보려고 하지만 도무지 잠이 오지 않습니다. 그럴수록 자꾸만 잠에 대한 생각이 들죠.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['왜 잠이 안 오지? 양이라도 세어볼까...']]}
              type={'gray'}
              margin={'mt-[40px]'}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '여러분의 이야기',
      fullImage: _12_3,
      texts: [
        {
          text: '어라? 나도 모르게 잠이 들었네요. 벌써 아침입니다. 컨디션은 좋지 않습니다. 지난밤 밤늦게까지 잠들지 못하고 많이 뒤척였거든요. 우선은 겨우 잠자리에서 몸을 일으켜 하루를 시작합니다. 그리고 다시 밤이 다가옵니다. 여러분은 생각하죠.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['어제 잠드는 데에 시간이 너무 오래 걸렸어. 거의 한 시간은 누워있었던 것 같은데.']]}
              type={'gray'}
              my
            />
          ),
        },
        {
          text: '잠을 잘 자야 한다는 생각이 강해진 여러분은 기가 막힌 방법을 하나 떠올립니다.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '그러면 오늘은 한 시간 더 일찍 눕자. 한 시간 정도 뒤척이겠지만 그래도 최종적으로 잠드는 시간은 비슷할 거야. 그러면 내일 하루는 좋은 컨디션으로 보낼 수 있겠지.',
                ],
              ]}
              type={'gray'}
              my
            />
          ),
        },
        {
          text: '안전한 생각이었죠. 더 일찍 누워있으면 아무리 오래 잠에 들지 못하더라도 결국에 잠들기만 하면 그래도 어느 정도 수면 시간을 확보할 수 있으니까요. 그렇게 여러분은 침실에 일찍 들어가게 됩니다.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '여러분의 이야기',
      texts: [
        {
          text: '하지만 당연히 잠은 오지 않습니다. 다른 변화 없이 잠자리에 일찍 들기만 했으니까요. 생체 시계와 수면 항상성을 떠올려보면 당연한 이야기입니다. 그렇게 시간은 흘러갑니다. 결국 여러분이 기존에 잠자리에 들던 시간까지 말똥말똥 깨어있게 됩니다. 그리고 여러분의 불안감은 증폭되기 시작하죠.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['뭐야... 일찍 자려고 한 시간이나 일찍 누웠는데 결국 어제와 다를 바 없게 됐네.']]}
              type={'gray'}
              margin="mt-[30px]"
            />
          ),
        },
        {
          component: <FullImage image={_12_4} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '안전하게 충분한 시간을 일찍 누웠는데도 계획한 대로 흘러가지 않자 불안감은 훨씬 더 커집니다. 초조함은 침실을 압도해버립니다. 그리고 그날 밤도 역시나 늦은 새벽이 돼서야 겨우 잠들게 되죠.',
                '\n',
                '다음날도 동일합니다. 물론 여러분은 다양한 시도를 해볼 겁니다. 베개를 바꿔보기도 하고, 자기 전 운동을 해보기도 하겠죠. 안타깝게도 다 실질적인 도움이 되지 않는 것들이지만요. 심지어는 오히려 수면을 방해하기만 할 뿐이죠.',
                '\n',
                '그렇게 여러분은 밤을 잃어버리게 되었습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '어떤가요, 비슷한가요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '여러분의 상황과 비슷한가요? 모든 내용이 동일하지는 않더라도 결국 ',
                '불안감과 초조함이 침실을 압도해버리는 결과',
                '는 비슷할 겁니다. 그게 불면증의 핵심이기 때문이죠.',
                '\n',
                '이러한 문제는 ',
                '침실과 수면 사이의 연결성이 약해졌기 때문에 발생',
                '합니다. 침대 위에서 깨어 있는 상태로 보내는 시간이 많아지고, 그에 따라 침실은 아늑하게 잠드는 곳이 아니라 말똥말똥하게 깨어 있는 공간이 되는 것이지요. 그러한 공간 속에서 여러분은 잠에 들기는커녕, 오히려 좌절감만 깊어지게 됩니다.',
                '\n',
                '저는 오늘부터 침실과 수면 사이의 연결성을 강화하는 일들을 시작할 겁니다. 오늘은 자극조절요법이라는 것부터 시작할 계획입니다.',
                '\n',
                '우선 잠시 쉬었다가 만날까요? 따뜻한 차를 한 잔 하고 와도 좋아요. 그럼 이따 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '침실을 수면을 위한 공간으로',
      fullImage: _13_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '잘 쉬었다가 오셨나요? 이제 다시 시작해 볼까요? 이번 수업에서는 세 가지 규칙에 대해 안내해 드리려고 해요. 그리고 앞으로 매일 수면 기록을 하고 난 이후면 세 가지 규칙을 잘 지켰는지 확인해 보고자 합니다.',
                '\n',
                '세 가지 규칙은 다른 말로 ',
                '자극조절요법',
                '이라고 불리기도 합니다. 자극을 조절한다는 뜻은 침실에서의 자극을 조절한다는 의미입니다. 즉, 깨어있는 동안 ',
                '침실에서 보내는 시간과 행동을 제한하여 침실과 수면 사이의 연관성을 강화',
                '하는 것이지요.',
                '\n',
                '하나씩 살펴볼게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '규칙 1. 졸릴 때만 잠자리에 눕는다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이제 앞으로는 졸릴 때만 잠자리에 누울 겁니다. 졸리다는 건 무슨 뜻일까요? 제가 말하는 ',
                '졸리다는 건 단순히 조금 피곤하다거나 지루하다는 것을 뜻하는 게 아닙니다.',
                ' 정말 많이 졸려서 ',
                '눈꺼풀이 무거워지고, 눈이 감기며, 하품이 나오는 순간',
                '이 바로 졸릴 때입니다. 그때 잠자리에 누우면 됩니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={_13_2} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '흔히 전날 잠을 잘 못 잤다거나, 다음날 중요한 일들이 있는 경우에는 졸리지도 않음에도 더 일찍 자야 한다고 생각하고 잠자리로 향하는 경우가 많습니다. 하지만 그러한 방법은 침실과 수면 사이의 연결성을 약화시킬 뿐입니다.',
                '\n',
                '이제 앞으로는 졸릴 때만 잠자리에 누우셔야 합니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '규칙 2. 누웠지만 15분이 지나도 졸리지 않을 때에는 잠자리에서 일어난다',
      texts: [
        {
          text: '침대에 들어가고 난 후 15분이 지났음에도 깨어 있다면 다시 침대에서 나옵니다. 휴대폰이나 시계를 보면서 정확히 15분을 측정하라는 말은 결코 아닙니다. 그러한 일들은 오히려 수면을 방해하지요.',
        },
        {
          component: <FullImage image={_13_3} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '대략적으로 느끼기에 15분 정도 지난 것 같다면 침실 밖으로 나오셔야 합니다.',
                ' 이렇게 하는 이유는 역시나 침실과 수면 사이의 연결성을 강화하기 위함입니다. 즉, 우리 뇌가 침실은 오롯이 졸리고 아늑하며 포근하게 잠들 수 있는 공간으로 인지하도록 하기 위함입니다. 침대에 누워서 오지도 않을 잠을 무작정 기다리면 더 불안해져 불면 증상이 깊어질 뿐입니다.',
                '\n',
                '다만 잠들지 못해 침실을 벗어날 때를 대비하여 어떻게 할지 계획을 세워두는 것이 좋습니다.',
                '\n',
                '우선 침실을 벗어나 이동할 장소는 아래와 같은 특징을 가진 곳들이 좋습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              type="gray"
              textList={[['따뜻하고 안락한 곳'], ['무드등과 같은 낮은 조도의 조명이 있는 곳']]}
              my
            />
          ),
        },
        {
          text: '만일 원룸이나 오피스텔처럼 침실이 별도로 분리되어 있지 않은 경우라면, 침대에서 내려와 낮은 조도의 조명을 켜고 책상이나 소파, 의자 등에 앉는 것도 좋습니다.',
        },
        {
          component: <FullImage image={_13_4} />,
        },
        {
          text: '일단 침실을 벗어났다면 간단한 활동을 하는 게 좋습니다. 너무 활동적이어서 뇌를 각성시키고 자극하는 활동은 하지 않는 게 좋아요. 저는 흔히 아래와 같은 활동들을 추천한답니다.',
        },
        {
          component: (
            <MessageBlock
              type="gray"
              textList={[
                ['독서 (업무 관련된 것들은 피할 것)'],
                ['음악 감상 (잔잔한 곡들을 위주로)'],
                ['낱말퍼즐'],
                ['신문이나 잡지 읽기'],
                ['빨래 개기'],
                ['라디오, 오디오북, 팟캐스트 듣기'],
              ]}
              my
            />
          ),
        },
        {
          text: '독서를 할 경우에는 짧은 글이 가장 좋습니다. 빨래 개기와 같은 집안일을 하는 것도 좋지만, 찬물에 손을 닿게 하는 설거지와 같은 일은 피하는 게 좋습니다. 지루하다면 라디오, 오디오북, 팟캐스트 같은 것들을 청취하는 것도 괜찮지만 마찬가지로 너무 자극적인 주제는 피하는 게 좋습니다.',
        },
        {
          component: <FullImage image={_13_5} />,
        },
        {
          text: '마지막으로 TV나 휴대폰은 보지 않는 것이 좋습니다. 앞서 설명했듯 우리 몸의 생체 시계는 빛에 반응하기 때문에 강한 빛을 보면 자야 될 시간이 아니라고 인지하기 때문입니다.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '규칙 3. 침실에서 잠자는 것 이외의 활동은 하지 않는다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '침실은 오롯이 수면을 위한 공간입니다. 잠자리에서 다른 활동들을 하다 보면 우리 뇌는 잠자리를 잠자는 곳으로 인지하지 않습니다. 결국은 침실과 수면 사이의 연결성이 약해지죠. 따라서 잠자리에서는 자는 것 이외의 행동을 하지 않는 것이 좋습니다.',
                '\n',
                '저를 찾아오시는 분들은 흔히 침대에 누워서, 혹은 앉아서 다음과 같은 활동들을 많이 하곤 합니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock textList={[['책 읽기'], ['휴대폰 하기'], ['업무하기'], ['TV 보기']]} type={'gray'} my />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '침대가 그러한 활동들을 하기 너무 매력적인 장소임은 잘 알고 있습니다. 다만, 잃어버린 밤을 되찾아오기 위해서는 ',
                '우리 뇌에 “침실은 자는 곳”이라는 사실을 알려줄 필요',
                '가 있습니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={_13_6} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '그러니 오늘부터는 침실에서 다른 활동을 하지 않기로 합니다. 만약 침실이 별도로 분리되어 있는 공간이라면 낮 시간 동안에는 침실에 이유 없이 들어가지 않는 것도 좋은 방법입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '다시 정리해 볼게요',
      texts: [
        {
          text: '중요한 이야기들입니다. 다시 정리해 볼게요.',
        },
        {
          component: (
            <TextWithGradientCircle
              texts={[
                {
                  title: '졸릴 때만 침대에 들어가기',
                  text: ' 눈꺼풀이 무거워지고, 눈이 감기며, 하품이 나오는 순간에만 잠자리에 누울 거예요.',
                },
                {
                  title: '15분 이상 잠이 안 오면 나오기',
                  text: '눕고 나서 잠이 안 온다면 침실을 빠져나와 따뜻하고 안락한 장소로 가세요. 그곳에서 독서, 음악 감상 등 자극적이지 않은 활동들을 하면 된답니다. 단, TV나 휴대폰 사용은 안 돼요.',
                },
                {
                  title: '침실에서 잠만 자기',
                  text: '침실은 잠을 자기 위한 공간이에요. 앞으로는 침대에서 책을 읽거나 휴대폰을 하거나 업무를 하는 것과 같은 활동은 하지 않기로 해요.',
                },
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: <QuizTemplate index={1} question="잠자리에는 졸릴 때만 눕는다" isOX answer="O" />,
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="졸릴 때만 누우셔야 해요"
          answerInfo="졸리다는 건 단순히 피곤하다거나 지루하다는 것을 뜻하는 게 아니랍니다. 눈꺼풀이 무거워지고, 눈이 감기며, 하품이 나오는 순간, 바로 그 순간에 잠자리로 가시는 걸 잊지 마세요!"
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="일단 잠자리에 누우면 최대한 장소를 변경하지 않고 수면에 집중하는 것이 중요하다"
          isOX
          answer="X"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="졸리지 않으면 잠자리에서 나와야 해요"
          answerInfo="누웠지만 15분이 지나도 졸리지 않을 때에는 잠자리에서 빠져나오는 것이 중요합니다. 잠자리에 오래 누워있는 것은 침실과 수면 사이의 연관성을 약화시켜 오히려 수면을 방해한답니다."
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate index={3} question="침대 위에서는 잠자는 것 이외의 활동은 하지 않는다" isOX answer="O" />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="침대에서는 잠만 잡니다"
          answerInfo="침대에서 다른 활동들을 계속하는 순간, 우리 뇌는 침대를 잠자는 장소로 인지하지 않습니다. 따라서 침실에 들어갔을 때 어려움 없이 잠들기 위해서는 침대 위에서는 오직 잠만 자는 것이 중요하답니다."
        />
      ),
    },
    {
      title: '이제 실천해 볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '제가 수면 리포트에 오늘 배운 규칙을 다시 요약해 둘 거랍니다. 그러니 규칙들을 잘 지켜보도록 해요. 내일 아침부터는 수면 기록 후에 오늘 배운 규칙을 잘 지켰는지 여쭤볼게요.',
                '\n',
                '오늘 배운 내용은 불면증 코스의 가장 중요한 요소 중 하나랍니다. 내일은 수면 제한이라고 하는 또 다른 중요한 요소를 하나 알려드릴 거예요. ',
                '수면 제한과 자극 조절은 불면증 인지행동치료의 가장 중요한 부분',
                '이랍니다. 이미 수많은 연구들이 그 효과를 입증하고 있지요. 그러니 오늘부터 열심히 지켜보도록 해요.',
                '\n',
                '자, 그러면 오늘은 여기서 마무리할까요? 이제 점점 무언가 새로운 것들을 시작하는 것 같죠? 기대하셔도 좋아요. 앞으로 많은 것들을 바꿀 수 있을 거랍니다.',
                '\n',
                '아차, 오늘의 잠자리 음악을 잊었군요. 오늘은 Poella의 <Brahms Lullaby for Cello and Piano>를 소개해드릴게요. 자기 전에 한번 들어보세요. 자, 그러면 우리는 내일 만나요.',
                '\n',
                '오늘도 편안한 밤 보내요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="졸릴 때만 침대에 누웠나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" />,
    },
    {
      replaceComponent: <SurveyDone notHasCas={true} />,
      progressHide: true,
    },
  ],
  [
    {
      title: '수면 시간 처방 받기',
      fullImage: _14_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘도 수면 기록을 잘 하셨네요. 좋습니다. 불면증 점수는 좀 어떻게 변했나요? 혹시나 아직 큰 변화가 없다고 하더라도 상심하지 않아도 됩니다. 이제까지는 본격적인 치료를 위한 기틀을 마련한 거니까요. ',
                '\n',
                '제가 알려드린 자극조절요법 세 가지 규칙은 잘 지켰나요? 자극조절요법이 잘 지켜지지 않으면 오늘 시행할 방법도 큰 의미가 없을 수 있으니 ',
                '자극조절요법의 세 가지 규칙은 반드시 잘 지켜주셔야 해요.',
                ' 제가 매일 아침에 수면 기록을 한 후 여쭤볼 거랍니다.',
                '\n',
                '이제 오늘의 이야기를 시작해 볼까요? 오늘은 수면 제한요법을 시작해 볼 계획입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수면 제한요법',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '지난번에 살펴본 우리들의 이야기 기억하나요? 더 잘 자기 위해 더 일찍 잠자리에 들었다가 오히려 수면에 대한 불안감만 증폭했던 이야기 말입니다. 오늘은 그러한 일을 예방하기 위한 수면 제한요법을 시행할 계획입니다.',
                '\n',
                <span />,
                '수면 제한요법',
                '은 불면증 환자들이 잠자리에서 과도하게 많은 시간을 보낸다는 점에 집중합니다. 잠자리에서 ',
                '오랜 시간을 보내면서 수면과 침실 사이의 연관성이 약해지는 상황을 막기 위해 고안',
                '되었죠.',
                '\n',
                '저는 앞으로 여러분의 수면노트를 기반으로 ',
                '평균 총 수면시간, 수면 효율을 평가하여 잠자리에 머무르는 시간을 조정',
                '하고자 합니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수면 시간을 처방할 계획입니다',
      image: _14_2,
      bg: '#E5EBF3',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '지금 우리가 진행하고 있는 건 의사에 의해 직접 진행되는 ‘진료’는 아닙니다. 하지만 저는 그럼에도 수면 시간을 ‘처방’한다는 표현을 사용하려고 합니다. 수면 효율을 기반으로 계산한 수면 시간을 지키는 것이 ',
                '처방된 약을 복용하는 것만큼 중요하다는 것을 강조하기 위함',
                '이지요. 물론 실제 의사에 의한 처방은 아니니 혼동하지 않으셨으면 합니다. 그럼에도 제가 ‘처방’이라는 표현을 사용하는 건, 이건 그냥 권장사항이 아니라는 것을 강조하기 싶기 때문이에요. ',
                '불면증의 인지행동치료에서 가장 중요한 점은 계산된 수면 시간을 지키는 것',
                '입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수면을 제한한다는 말이에요',
      image: _14_3,
      bg: '#223B5C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '수면 제한요법이라는 말이 조금 어렵죠? 조금 더 쉽게 말하면 저는 ',
                '여러분의 수면을 제한할 계획',
                '이에요. 이를 통해 여러분의 ',
                '수면 패턴을 리셋',
                '하고 ',
                '다시 설정',
                '하고자 합니다. 컴퓨터로 치면 재부팅을 하는 것이지요. 수면에 대한 우리의 생각과 습관 또한 재부팅을 할 수 있답니다. 저는 수면 제한요법을 통해 수면에 대한 우리들의 생각과 습관을 재부팅할 계획입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수면 효율 때문이에요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '수면 효율이라고 들어보셨나요? 수면 효율은 ',
                '잠자리에 누워서 얼마나 효과적으로 잠들었는가',
                '를 뜻합니다. 즉, 수면 효율이 높으면 잠자리에 누워 뒤척이지 않고 바로 잠에 드는 것이지요. ',
                '수면을 제한하는 이유는 바로 이 수면 효율을 높이기 위해서',
                '랍니다.',
                '\n',
                '수면 효율은 다음과 같이 계산됩니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={_14_4} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우리 경험을 토대로 생각해 보면 쉽게 이해할 수 있습니다. 불면증이 있었던 지난 밤들은 어땠나요? 침대에 누워도 잠이 오지 않고 말똥말똥한 눈으로 밤을 지새우지 않았나요? 즉, ',
                '침대에 들어가 있었던 시간은 길었지만 실제로 잠든 시간은 짧았던 것',
                '이지요. ',
                '수면 효율이 낮았던 밤',
                '이었습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수면 효율을 높여볼 거예요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이제 수면 제한을 통해 수면 효율을 높여볼 거랍니다. 수면 효율은 85~90% 정도를 유지하는 것이 좋습니다. 만일 지난 일주일 간의 수면 효율이 85%를 넘지 않으면 침대에 머문 시간을 줄여서 수면 효율이 85%가 되도록 만들 거랍니다. 이처럼 ',
                '침대에 머무는 시간을 줄이는 것',
                '을 ',
                '수면 제한',
                '이라고 한답니다.',
                '\n',
                '자, 그럼 이제 수면 시간을 처방해 볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '1. 잠에서 깨는 시각 정하기',
      image: _14_5,
      bg: '#DBE4F0',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우선 첫 번째로 잠에서 깨는 시각을 정해보려고 합니다. 앞으로는 ',
                '몇 시에 자던 무조건 정해진 시각에 일어나야 합니다.',
                ' 아침에 눈을 뜨는 것이 피곤하고 고된 일임을 잘 알고 있습니다. 지난 밤을 꼬박 지새웠다면 더더욱이 그렇겠지요. 하지만 정해진 시각에 일어나는 것은 매우 중요합니다. ',
                '오히려 몇 시에 잠드는지 보다 더 중요한 문제',
                '입니다. 잠에서 깨는 시각을 지켰는지에 대한 여부는 인지행동치료 요법의 효과를 좌지우지하기도 하죠. 그러니 힘든 일이라도 조금만 의지를 가지고 진행해 보도록 해요. 많은 것들을 바꿀 수 있을 겁니다.',
                '\n',
                '잠에서 깨는 시각은 흔히 ',
                '출근 시간, 등교 시간',
                '에 맞춰 설정하곤 합니다. 정해진 시각이 없다면 ',
                '평소 본인의 패턴',
                '에 맞춰 정하면 됩니다. 단, 너무 늦은 시각으로 정하면 안 됩니다. 일반적으로 ',
                '25세 이상 성인의 경우 오전 6시에서 8시 정도의 시간대로 설정',
                '합니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: <AlarmTimePicker type="wakeup" />,
    },
    {
      title: '좋아요, 잠에서 깨는 시각을 정했어요',
      texts: [
        {
          text: '이제부터 아래와 같은 시각에 깨어나는 것으로 할게요.',
        },
        {
          component: <WakeUpCard />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '잠에서 깨는 시각은 한 주 동안 잘 지켜주셔야 합니다. 걱정이 된다면 설정한 ',
                '시각에 맞춰 알람',
                '을 켜두는 것이 좋아요. 그리고 잠에서 깨는 시각은 ',
                '주말에도 지켜야 합니다.',
                ' 주말까지 동일한 시간에 일어나야 안정된 수면패턴을 가지고, 생체시계를 안정되게 유지할 수 있답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '2. 침대에 들어가는 시각 정하기',
      image: _14_6,
      bg: '#6C85A8',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이번에는 ',
                '침대에 들어가는 시각',
                '을 정할 거랍니다. 침대에 들어가는 시각을 정하는 목적은 ',
                '수면 패턴을 리셋하고 실제로 잠들지 않은 상황을 최소화하여 수면 효율을 올리기 위함',
                '입니다.',
                '\n',
                '물론 이 시간과 별개로 어제 설명한 자극조절요법의 규칙들은 잘 지키셔야 합니다. 즉, 침대에 들어가고 나서 15분이 지나도 잠이 오지 않으면 침실에서 나오셔야 해요. 이 규칙은 ',
                '수면 효율을 증가시키고 수면에 대한 우리들의 좌절감을 줄여 침실과 수면 사이의 연결성을 강화',
                '시킵니다.',
                '\n',
                '잠에서 깨는 시각과 달리 침대에 들어가는 시각은 제가 직접 정해드리는 거랍니다. 앞서 말씀드렸던 것처럼 수면 효율을 먼저 계산해 보고, 수면 효율이 85% 이하라면 앞으로 4주 동안 수면 효율을 85% 수준으로 맞추는 데에 노력할 거랍니다. 만약 85% 이상이라면 그 상태를 유지할 수 있는 최적의 시각을 침대에 들어가는 시각으로 정해 드릴 거예요. 자, 그럼 지난 일주일간 나의 수면 효율은 어땠는지 살펴볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      progressHide: true,
      replaceComponent: <SleepResultAnalysis title="지난 수면 기록을 분석하고 있어요" />,
    },
    {
      replaceComponent: <SleepEfficiencyResult />,
    },
    {
      progressHide: true,
      replaceComponent: <SleepResultAnalysis title="수면 시간을 계산하고 있어요" />,
    },
    {
      title: '침대에 들어가는 시각을 정했어요',
      texts: [
        {
          text: '좋아요. 이제부터 아래와 같은 시각에 침실로 가시면 됩니다.',
        },
        {
          component: <GoToBedCard />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '생각보다 잠을 못 자게 되어 놀랐나요? 아마 "예상보다 더 잠을 못 자는구나" 하고 생각하실지도 모르겠네요. 처방된 수면 시간들을 지키는 것이 쉬운 일은 아닐 겁니다. 다만 ',
                '앞으로도 이 패턴을 유지해야 하는 것은 아닙니다.',
                ' 침실과 수면 사이의 연결성을 강화하여 ',
                '조금 더 푹 자고 개운하게 일어날 수 있을 때까지만 제가 제시한 패턴대로 자고 일어난다고 생각',
                '하시면 됩니다. 조금은 힘들겠지만 수면시간은 ',
                '앞으로 조금씩 늘려갈 테니',
                ' 조금만 노력해 보시길 바랍니다.',
                '\n',
                '컴퓨터는 재부팅 버튼을 누르기만 하면 되지만, 우리의 뇌는 조금 더 시간이 필요한 법이니까요.  코스를 마친 후 편히 잘 수 있는 시간들을 생각해 보면 그리 오랜 시간은 아닐 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '혹시 너무 늦게 잠드는 것 같나요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '아마 평소보다 잠드는 시간이 너무 늦어 놀라셨을 수도 있을 것 같습니다. 최근 불면증으로 고생하고 계셨다면 더더욱이 그럴 겁니다. 늦게 자라고 하는 이유는 다음과 같습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <div className="font-light mx-[15px] my-[40px]">
              <TextWithBold texts={['1. 수면 효율을 높이기 위해서 ', '‘당분간만’ 평소보다 적게 잔다.']} />
              <TextWithBold texts={['2. 따라서 ', '평소와 기상시간이 같다고 가정하면 평소보다 늦게 잘 수밖에 없다.']} />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '물론 갑자기 평소보다 늦게 자라고 하니 많이 걱정되시는 점 잘 이해합니다. 하지만 이 방법이 불면증을 가장 빨리, 가장 지속적으로, 그리고 가장 안전하게 해결하는 방법임에는 틀림이 없습니다. 그러니 저를 믿고 조금만 수면 패턴을 바꿔보도록 해요. 약속한 취침시간과 기상시간을 잘 지켜서 조금씩 수면 효율이 올라오면 제가 조금씩 수면시간을 더 늘려드릴 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: <AlarmTimePicker type="isSet" />,
    },
    {
      title: '일주일 뒤에 시간을 다시 조정해 드릴 거예요',
      image: _14_7,
      bg: '#1C3963',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '다음 일주일 간의 수면 기록을 바탕으로 한 주가 지난 뒤 다시 수면 시간을 조정',
                '해드릴 거랍니다. 일주일이 지난 뒤 수면 효율이 일정 기준 이상으로 올라오면 총 수면 시간을 조금 더 늘려드릴 거예요. 그러니 당분간은 제가 정해준 기상 시간과 취침 시간을 꼭 지키시길 바랍니다.',
                '\n',
                '물론 ',
                '초반에는 조금 졸리고 힘들 수도 있습니다.',
                ' 하지만 분명한 사실은 그 과정을 거치고 나면 잃어버린 밤을 되찾아올 수 있으리라는 점이지요. 불안하고 초조했던 지난 밤은 더 이상 없을 거랍니다. 저희가 지금 방식대로 함께 노력한다면요. 그러니 조금 힘들더라도 잘 지켜나가보도록 해요.',
                '\n',
                '자, 그럼 오늘은 여기까지 해 볼까요? 처방된 수면 시간은 수면을 기록할 때 바로 확인하실 수 있을 거예요. 오늘부터 잘 실천해보세요.',
                '\n',
                '오늘은 Yiruma의 <Sweet Dream My Dear>라는 노래를 추천해드릴 거예요. 마음을 편안히 하는 데에 도움이 될 거예요.',
                '\n',
                '그럼 우린 내일 또 만나요. 오늘도 편안한 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
      submit: 'sleepPrescription',
      customButtonText: '다음',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="졸릴 때만 침대에 누웠나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" />,
    },
    {
      replaceComponent: <SurveyDone notHasCas={true} />,
      progressHide: true,
    },
  ],
  [
    {
      title: '수면 위생: 낮 시간의 활동',
      fullImage: day8_2_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요, 어서 오세요. 지난 밤은 조금 어땠나요? 어쩌면 처음보다 더 잠자리가 불편했을 수도 있을 겁니다. 제가 마음대로 수면 패턴을 정해주었으니 당연한 이야기일지도 모릅니다. 처음 며칠 동안은 그럴 수도 있답니다.',
                '\n',
                '하지만 실망하실 필요는 없습니다. 제가 제시한 방법들을 잘 지켜나가다 보면 분명 조금씩 잠자리가 편안해질 테니까요. 힘들어도 조금만 더 노력해 보도록 하지요.',
                '\n',
                '오늘은 수면 위생이라는 것에 대해 배워볼 겁니다. 수면 위생이라는 단어 들어보셨나요? 아마 생소한 표현일 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수면에 영향을 주는 습관과 행동',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '불면증 때문에 고생하는 사람들 중 많은 분들이 문제를 밤에서만 찾습니다. 하지만 ',
                '우리가 하는 행동 중 의외로 많은 것들이 우리가 생각했던 것보다 수면에 더 큰 영향',
                '을 미친답니다.',
                '\n',
                '낮 동안의 생활습관, 잠자기 전의 일상들, 그리고 잠자리 자체의 환경. 이 모든 것들이 수면에 영향을 미칩니다. 우리는 이러한 요소들을 통틀어 ',
                '수면 위생',
                '이라고 부르죠. 즉, 수면 위생이 좋다는 말은 우리 생활습관과 패턴들이 수면에 긍정적인 영향을 미치고 있다는 뜻이고, 반대로 수면 위생이 좋지 않다는 말은 우리 생활습관과 패턴들이 수면에 부정적인 영향을 미치고 있다는 뜻입니다. ',
                '\n',
                '앞으로 4일 동안은 수면 위생에 포함되는 요소들로는 어떠한 것들이 있는지 살펴보도록 하겠습니다. 오늘은 ',
                '낮 시간의 활동',
                '에 대해서 먼저 알아보도록 하죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '낮 시간의 활동: 카페인',
      fullImage: day8_2_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '첫 번째는 카페인입니다. 카페인이 정신을 각성하여 우리를 더 깨어있게 만든다는 사실은 아마 여러분도 다 잘 알고 계실 겁니다. 어쩌면 이미 “커피쯤이야 진작에 끊어봤지”라고 생각할지도 모르죠. 따라서 단순히 “커피를 끊으세요”라는 뻔한 이야기는 하지 않을 겁니다. 오늘은 카페인에 대한 조금 더 다른 이야기를 나눠보죠.',
                '\n',
                '첫 번째는 카페인을 포함하고 있는 식품입니다. 생각보다 많은 식품들이 카페인을 포함하고 있습니다. 일반적으로 ',
                '에스프레소 샷 한 잔',
                '은 ',
                '100mg',
                '의 카페인을 포함하고 있습니다. 흔히 즐겨마시는 아메리카노의 경우, 에스프레소가 샷이 두 잔 정도 들어가므로 약 200mg의 카페인을 포함하고 있죠. 하지만 ',
                '차, 콜라, 에너지 음료, 다크초콜렛, 진통제, 심지어는 디카페인 커피',
                '까지 생각보다 많은 식품들이 카페인을 포함하고 있습니다. 에스프레소 샷에 비유해서 살펴볼게요.',
              ]}
            />
          ),
        },
        {
          component: <Image image={day8_2_8} height={358} padding={'px-[30px] py-[20px]'} my />,
        },
        {
          text: '이와 같이 생각보다 많은 식품들에 카페인이 포함되어 있습니다. 일상 속에서 흔히 섭취하는 초콜렛, 탄산음료 등에도 생각보다 많은 카페인이 포함되어 있답니다. 잃어버린 밤을 되찾을 계획이라면 이러한 식품들은 당분간 가까이하지 않는 것이 현명합니다.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '낮 시간의 활동: 카페인',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '두 번째로는 카페인을 섭취하는 시간입니다. 저녁 시간 정도부터는 커피를 마시지 않아야 잠을 잘 잘 수 있다는 사실은 잘 알고 계실 겁니다. 보통 취침 4~6시간 전에 카페인을 섭취하지 말 것을 권장하고 있습니다. 이는 우리 몸이 카페인을 제거하는 데에 걸리는 시간 때문입니다. ',
                '우리 몸이 어떠한 물질을 혈액에서 절반 제거하는 데에 걸리는 시간',
                '을 ',
                '반감기',
                '라고 합니다. 예를 들어 반감기가 1시간인 식품이 있다고 하면, 식품을 섭취하고 한 시간 뒤에는 우리들의 몸속에 그 식품이 절반만 남아있습니다.',
                '\n',
                '유전적인 특성에 따라 사람마다 차이가 나긴 하지만, 평균적으로 ',
                '카페인의 반감기는 약 5시간',
                '입니다. 반감기를 생각해 보면 커피를 섭취하는 게 얼마나 많이 우리 수면을 방해하는지 알 수 있습니다. ',
                '오후 5시에 커피 두 잔을 마셨다고 하면 저녁 10시가 되어 잠자리에 들 시간 즈음에 우리 몸에는 커피가 한 잔 남아있는 셈',
                '입니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day8_2_3} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '따라서 오후 시간에는 카페인을 포함하고 있는 식품을 일절 먹지 않는 게 좋습니다. 따라서 앞으로 ',
                '카페인 음료는 오전 시간대에만, 한 잔 이하로 마셨으면 합니다.',
                ' 물론 가장 좋은 것은 일절 마시지 않는 겁니다.',
                '\n',
                '커피를 줄이는 게 쉽지는 않을 겁니다. 갑자기 카페인을 끊으면 며칠간은 두통이 발생할 수도 있죠. 하지만 대부분의 경우에 그러한 증상은 곧 없어집니다. 그러니 너무 걱정하지 말고 카페인을 줄였으면 합니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '낮 시간의 활동: 알코올',
      fullImage: day8_2_4,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '술을 마시다가 취하면 졸음이 찾아오는 경험을 해 보신 적이 있나요? 그러한 경험 때문에 많은 분들이 숙면을 위해 가볍게 알코올을 섭취하기도 합니다. 물론 우리들의 경험대로 알코올이 조금 더 빠르게 잠들 수 있도록 도와줄 수 있기도 합니다. 하지만 결론적으로 ',
                '알코올은 수면을 방해',
                '합니다. 알코올은 ',
                '체내에서 특정한 물질로 바뀌어 사람들에게 생생한 꿈을 꾸게 하고 코골이를 악화',
                '시킵니다. 때로는 ',
                '배뇨 활동을 자극',
                '하여 화장실에 가기 위해 깨어나도록 만들기도 하죠. 따라서 알코올은 불면증의 해결책이 될 수 없습니다. 여러분이 만약 불면증을 앓고 있다면 ',
                '알코올은 단 한 잔으로 줄이거나 아예 마시지 않는 것이 좋습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '낮 시간의 활동: 담배',
      texts: [
        {
          text: '담배를 피우면 정신이 또렷해진다고 생각들 때가 많을 겁니다. 담배에 포함된 니코틴에는 각성 효과가 있기 때문입니다. 하지만 오랜 기간 흡연을 한 분들은 담배를 피웠을 때 불안감이 해소되어 오히려 흡연이 긴장을 풀어준다고 느끼기도 합니다. 그렇기 때문에 취침 전에 습관적으로 담배를 피우기도 하죠.',
        },
        {
          component: <FullImage image={day8_2_5} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '허나 앞서 이야기한 것처럼 당장에 흡연을 중단하는 것이 힘든 일임은 잘 알고 있습니다. 불면증과 흡연 문제를 동시에 해결하는 건 쉽지 않을 겁니다. 따라서 이번 코스를 밟는 동안은 ',
                '적어도 취침 2시간 전부터는 담배를 피우지 않는 걸',
                '로 했으면 합니다. 물론 한밤중에 잠에서 깨어났을 때에도 흡연을 해서는 안 됩니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '낮 시간의 활동: 운동',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '운동은 수면에 도움이 됩니다. 정말 간단하게 생각해 보면 몸을 많이 쓰면 이후에 더 졸린 법은 당연한 이야기겠지요. 특히 고강도 운동이 효과적이랍니다. ',
                '고강도 운동',
                '이란 ',
                '운동을 하면서 완전한 문장을 끝까지 쉽게 말하기 힘든 운동',
                '을 뜻합니다. 자전거 타기, 달리기, 수영 등이 그 예시에 해당하지만, ',
                '최소 20~30분 동안 심장박동수를 높이는 운동',
                '이라면 어떠한 것이든 상관없습니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day8_2_6} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '운동은 ',
                '일주일에 약 150분 정도',
                ' 하는 것이 좋습니다. 시간은 낮 시간이라면 언제든 상관없지만, ',
                '잠자리에 들기 전 2시간 이내에는 되도록 운동을 하지 않는 것',
                '이 좋습니다. 잠자리에 들기 직전 과도한 신체 활동은 오히려 수면을 방해할 수도 있으니까요.',
                '\n',
                '불면증으로 고생하고 계시다면 낮 시간을 이용하여 운동을 시작하시기 바랍니다. 운동은 우리가 더 빨리, 그리고 더 깊게 개운한 잠을 잘 수 있도록 도와줄 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '낮 시간의 활동: 햇볕쬐기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '낮 시간 동안 충분한 햇볕을 쬐는 것은 수면에 많은 도움을 줍니다. 얼마 전에 배웠던 생체 시계 기억하시나요? ',
                '생체 시계',
                '는 ',
                '빛에 따라 작동',
                '한다고 배웠습니다. 생체 시계는 빛이 많은 곳에서는 깨어있어야 한다, 빛이 적은 곳에서는 잠들어야 한다고 이야기해 주죠. ',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day8_2_7} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '따라서 ',
                '낮 시간에 충분한 햇볕을 쬐는 것',
                '은 ',
                '생체 시계가 정확하게 작동하도록 도와줍니다.',
                ' 낮 시간에는 아직 깨어있어야 한다는 것을 알려줘서 깨어있어야 하는 시간과 잠들어야 하는 시간을 우리 몸이 명확히 인지할 수 있도록 도와주죠. 낮 시간에 생체 시계가 잘 작동한 결과, 이어지는 밤 시간에는 우리 몸은 자연스럽게 “이제는 잘 시간”이라는 것을 인지하게 됩니다.',
                '\n',
                '곧 다가올 밤 시간대에 편안하게 잠들고 싶다면 ',
                '낮 시간 동안 충분히 햇볕을 쬐기를 바랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '퀴즈를 풀어볼까요?',
      texts: [
        {
          text: '낮 시간의 활동과 관련된 수면 위생은 여기까지랍니다. 카페인, 알코올, 담배, 운동, 햇볕 쬐기에 대해서 알아봤죠. 많은 내용을 배워서 다 기억하기 힘들지 모르겠네요. 핵심적인 부분만 제가 퀴즈로 여쭤보도록 할게요. 편안한 마음으로 풀어보세요.',
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="카페인은 커피 이외의 식품에는 아주 소량으로 포함되어 있어 크게 신경을 쓸 정도는 아니다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="카페인은 다양한 식품에 있어요"
          answerInfo={'일상 속에서 흔히 섭취하는 초콜렛, 탄산음료 등에도 생각보다 많은 카페인이 포함되어 있답니다!'}
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate index={2} question="자기 전 한 잔의 와인은 긴장을 완화시켜 숙면을 돕는다" answer="X" isOX />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="알코올은 수면에 도움되지 않습니다"
          answerInfo={
            '알코올은 체내에서 특정한 물질로 바뀌어 사람들에게 생생한 꿈을 꾸게 하고 코골이를 악화시킵니다. 때로는 배뇨 활동을 자극하여 화장실에 가기 위해 깨어나도록 만들기도 하죠. 따라서 알코올은 불면증의 해결책이 될 수 없습니다.'
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="니코틴은 안정제이기 때문에 흡연을 하는 건 의외로 수면에 도움을 줄 수 있다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="흡연은 수면에 도움되지 않습니다"
          answerInfo={
            '니코틴은 각성제이기 때문에 오히려 우리들을 깨어있게 만들 뿐입니다. 따라서 흡연을 하는 것은 수면에 별 도움이 되지 않습니다.'
          }
        />
      ),
    },
    {
      title: '낮 시간의 활동과 관련된 수면 위생',
      texts: [
        {
          text: '이번 수업에는 낮 시간 동안의 활동들이 수면에 어떠한 영향을 미치는지 살펴보았습니다. 다시 한번 살펴볼까요?',
        },
        {
          component: (
            <TextWithGradientCircle
              texts={[
                { title: '카페인', text: '오전 시간대에, 아메리카노 한 잔 이하의 양(카페인 200mg) 이하로만 섭취하기' },
                { title: '알코올', text: '피할 수 없다면 한 잔으로 줄이거나 아예 마시지 않기' },
                { title: '담배', text: '끊는 것이 가장 좋으나 그러기 힘들다면 취침 2시간 전부터는 흡연하지 않기' },
                {
                  title: '운동',
                  text: '취침 2시간 전을 제외하고 언제든, 최소 20분 동안 달리기, 계단 오르기 등 숨이 차고 심장박동이 빨라지는  운동하기',
                },
              ]}
              margin="my-[40px]"
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '생각보다 많은 활동들이 수면에 영향을 미치고 있지 않나요? 하나씩 지켜나가다 보면 많은 효과를 볼 수 있을 겁니다.',
                '\n',
                '오늘은 이쯤 할까요? 매일 만나서 말씀 나누니 저도 참 좋은 것 같아요. 누군가 저에게 의지할 수 있다는 점은, 한편으로는 제가 그 사람에게 의지할 수도 있다는 뜻이거든요. 오늘도 마음을 평화롭게 하는 노래를 한 곡 추천해 드릴게요. Franz Liszt의 <Berceuse S.174>라는 곡이랍니다.',
                '\n',
                '그러면 오늘도 편안한 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="졸릴 때만 침대에 누웠나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" />,
    },
    {
      replaceComponent: <SurveyDone notHasCas={true} />,
      progressHide: true,
    },
  ],
  [
    {
      title: '수면 위생: 잠자기 전 시간',
      fullImage: day9_2_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '어서 오세요, 오늘도 하루가 지났네요. 오늘도 어제에 이어 수면 위생에 대해 배워보려고 합니다. 이전 수업에서는 낮 시간의 활동과 관련된 수면 위생에 대해 알아보았죠.',
                '\n',
                '이번 수업에서는 ',
                '잠자기 전 시간의 수면 위생',
                '에 대해 알아보겠습니다. 크게 낮일 치워 놓기, 그리고 허기 다스리기, 두 가지에 대해 알아볼 거랍니다. 오늘은 금방 끝낼 거니까 조금만 집중해 보세요. 하나씩 살펴보도록 하죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잠자기 전 시간: 걱정 덜어두기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '흔히 늦게까지 일을 하거나 공부를 한 사람들은 걱정을 침실로까지 가지고 오는 경우가 많습니다. 여러분이 만약 그러한 경우에 해당한다면 ',
                '취침 한 시간 전에 무슨 일을 하는지',
                ' 살펴볼 필요가 있습니다.',
                '\n',
                '취침 한 시간 전까지 일을 하거나, 혹은 그 다음 날의 계획에 대해 고민하다 보면 잠이 달아날 수 있습니다. 따라서 ',
                '일이나 공부와 같은 활동은 적어도 취침 1시간 전에는 끝내는 것이 중요',
                '합니다. 물론 1시간 전에 끝낸다고 하여 걱정이 달아나지 않는다는 점을 잘 알고 있습니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day9_2_2} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '한 가지 도움이 되는 방법은 ',
                '저녁 시간대에 업무를 마무리하며 다음 날에 해야 할 일들을 노트에 간단하게 적어두는 것',
                '입니다. 이 방법에 대해서는 별도로 시간을 내어서 조금 더 자세히 설명드리도록 하겠습니다.',
                '\n',
                <span />,
                '업무나 과제를 끝낸 후부터 잠들기 전까지는 음악 듣기, 책 읽기 등 업무나 공부와는 관련이 없는 편안한 활동',
                '들을 하시기 바랍니다. 편안하게 할 수 있으면서 업무나 공부와 같이 우리 삶을 이끌어나가는 활동들과는 관련이 없는 일이라면 무엇이든 괜찮습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잠자기 전 시간: 간식',
      fullImage: day9_2_3,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '자기 전에 허기가 진다면 이 또한 수면을 방해할 수 있습니다. 공복감은 우리 수면을 방해하기 때문입니다. 자려고 하는데 허기가 질 때에는 ',
                '간식을 조금 섭취하는 게 도움',
                '이 될 수 있습니다. 간식은 ',
                '자극적이지 않은 것',
                '들이 좋으며 ',
                '가벼운 우유, 치즈, 땅콩버터',
                ' 등을 소량 섭취하는 것이 도움 됩니다. 하지만 기본적으로 음식을 섭취하면 위장관운동이 진행되어 수면을 방해할 수 있기 때문에, 이러한 음식들 또한 너무 많이 드시지 않는 것이 좋습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '오늘은 여기까지에요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '잠자기 전 시간의 수면 위생은 조금 간단하죠? 그래도 오늘 배웠던 내용을 한번 복습해 보도록 해요. 제가 몇 가지 퀴즈를 내볼게요. 편한 마음으로 풀어보세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="아직 일을 마무리하지 못해 걱정이 든다면, 수면 시간을 미루더라도 일을 마무리하고 걱정을 덜어내는 것이 중요하다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="낮의 일은 치워 놓아야 합니다"
          answerInfo="낮의 일을 잠자리로 가지고 오면 안 됩니다. 아직 일을 마무리하지 못했다고 하더라도 저녁 시간대에 내일 할 일 목록을 작성해둔 후 잠자는 시간대에는 이를 마음속에서 치워 놓는 것이 중요합니다."
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="공복감이 들더라도 음식을 섭취하면 위장관운동이 진행되어 수면을 방해하므로 아무것도 섭취하지 않는 것이 수면에 더 도움이 된다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="침대에서는 잠만 잡니다"
          answerInfo="공복감은 수면을 방해할 수 있습니다. 따라서 가벼운 우유, 치즈, 땅콩버터 등을 소량 섭취하는 것이 좋습니다. 다만, 위장관운동이 활발하게 진행되면 수면에 방해가 되는 것은 사실이므로 자극적이지 않은 음식으로 소량만 섭취하는 것이 중요합니다."
        />
      ),
    },
    {
      title: '잠자기 전 시간의 수면 위생',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '잘하셨어요. 별로 어렵지 않죠? 잠자기 전 시간에는 그저 편안하게 보내는 것이 중요하기 때문에 이런저런 활동들을 많이 시키지 않는답니다. 그러니 걱정을 덜어내고 허기가 진다면 가벼운 간식을 섭취한 뒤 편안한 시간을 보내시길 바랍니다.',
                '\n',
                '내일은 마지막으로 잠자리 환경과 관련된 수면 위생에 대해서 알아보도록 하겠습니다. 이번 수업은 조금 빨리 끝내도록 할게요. 아차, 오늘은 Erik Satie의 <Gymnopedie No. 1>이라는 노래를 들어보세요. 조금 더 차분한 마음으로 잠들 수 있을 거랍니다.',
                '\n',
                '그럼 오늘도 좋은 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="졸릴 때만 침대에 누웠나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" />,
    },
    {
      replaceComponent: <SurveyDone notHasCas={true} />,
      progressHide: true,
    },
  ],
  [
    {
      title: '수면 위생: 잠자리 환경',
      fullImage: day10_2_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요. 지난 밤도 잘 주무셨나요? 여기까지 잘 따라오셨다면 코스의 후반부를 진행하는 것도 전혀 무리가 없을 거랍니다. 잘 하고 계십니다. 이쯤 되면 생각보다 해야 할 것이 너무 많아 다 해낼 수 있을지 걱정이 들지도 모릅니다. 하지만 다행히 대부분의 과제들이 반복적이기 때문에 한번 습관화되면 이후부터는 크게 노력을 기울이지 않아도 됩니다. 그러니 초반부에만 조금 더 노력을 기울여보도록 해요.',
                '\n',
                '오늘은 수면 위생의 마지막 단계인, 잠자리 환경과 관련된 수면 위생에 대해 알아보도록 하겠습니다. 자, 그럼 하나씩 알아보도록 할까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잠자리 환경: 침실',
      texts: [
        {
          component: <MessageBlock textList={[['1. 침실을 시원하게']]} type="gray" my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '여러분의 침실은 아늑한가요? 침실은 ',
                '시원하고, 어둡고, 조용한 분위기',
                '로 만들어야 합니다.',
                '\n',
                '우선 온도는 ',
                '덥지도 춥지도 않게 느끼는 정도',
                '가 가장 적절합니다. 일반적으로는 ',
                '23~24’C 정도가 적절',
                '하다고 알려져 있지만 이는 사람마다 다를 수 있습니다. 너무 춥거나 더워서 이를 인지하는 수준이 아니라면 이미 괜찮은 온도일 가능성이 높습니다. 그게 어떠한 정도인지 모르겠다면 ',
                '누웠을 때 편안하게 “시원하다”라고 말할 수 있는 정도',
                '면 괜찮습니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day10_2_2} margin="mt-[30px]" />,
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잠자리 환경: 침실',
      texts: [
        {
          component: <MessageBlock textList={[['2. 침실을 어둡게']]} type="gray" my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '앞서 생체 시계에 대해 이야기한 것을 기억하실 겁니다. 낮 시간에 생체 시계를 더 잘 맞추기 위해 햇볕을 쬐기로 했다면, 마찬가지로 잠자리에서는 빛에 노출되지 않는 것이 중요합니다. ',
                '자꾸만 빛에 노출되다 보면 우리 생체 시계는 지금이 ‘깨어있어야 하는 시간’으로 착각',
                '하기 때문이죠. 무드등과 같이 따뜻한 느낌의 조명이 마음에 안정감을 주어 잠을 더 잘 수 있도록 한다고 생각하는 분들이 많지만, 우리 생체 시계는 그렇게 작동하지 않습니다. ',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day10_2_3} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '따라서 ',
                '침실은 어두운 것이 좋고',
                ' 가능하다면 ',
                '암막 커튼을 통해 빛을 완전히 차단하여 깜깜하도록',
                ' 만드는 것이 좋습니다. 만약 침실이 빛을 차단할 수 있는 환경이 아니라면 ',
                '수면 안대',
                '를 이용하는 것도 괜찮은 방법이랍니다. ',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잠자리 환경: 침실',
      texts: [
        {
          component: <MessageBlock textList={[['3. 침실을 조용하게']]} type="gray" my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '침실을 조용한 환경으로 만드는 것 또한 중요합니다. 한 가지 놀라운 사실은 연구 결과 ',
                '소음 자체가 불면증을 일으키는 건 아니라는 사실',
                '입니다. 사실 우리가 잘 자던 시기를 되돌아보면 당시에는 조금 시끄러워도 잘 잤음을 알 수 있습니다. 사실은 소음 그 자체보다는 ',
                '잠자리에 누운 우리가 ‘수면’이라는 것에 대해 과도한 불안감과 초조함을 느껴 주변 환경에 더 민감하게 반응하는 것이 문제',
                '입니다. 잠자리에 누워도 잠에 들지 않으니 그 긴 공백을 채울 만한 다른 자극에 주의를 기울이게 되는 것이죠. 하릴없이 누워있는 우리들의 뇌는 다른 자극에 더 집중하기 마련입니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day10_2_4} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이처럼 소음 자체보다는 소음에 집중하는 것 자체가 문제이기 때문에, ',
                '소리의 크기와 무관하게 우리의 신경을 곤두세우는 소음이 있다면 귀마개를 사용해 보는 것도 좋은 방법',
                '입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잠자리 환경: 시계',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '침실 안의 시계는 여러 방면에서 수면에 도움이 되지 않습니다. ',
                '째깍째깍 하는 소리는 우리 마음을 더 초조하게',
                ' 만들죠. 앞서 말했듯 소리 자체보다는 ',
                '‘시간이 흘러가고 있다’는 사실을 굳이 우리에게 매 초마다 알려준다는 것이 문제',
                '입니다.',
                '\n',
                '소리만 문제인 것은 아닙니다. 우리는 어두운 침실에서 잠들기로 약속했습니다. 어두운 공간에서 시계를 어떻게 보실 건가요? ',
                '어두운 공간에서 시계를 보려면 휴대폰을 켜거나, 불을 잠시 켜거나, 시계의 야광 조명을 활용할 수밖에',
                ' 없습니다. 이는 모두 생체 시계를 교란하게 됩니다. ',
                '\n',
                '설령 시간을 확인했다고 하더라도 잠을 자기 위해 한참을 기다렸음에도 겨우 10분 밖에 지나지 않았다는 사실을, 혹은 벌써 뜬 눈으로 시간을 보낸 지 1시간이나 지났다는 사실을 알게 되었을 때 기분이 좋아질 사람은 아무도 없습니다. ',
                '시간을 자꾸 상기하는 것은 오히려 수면에 대한 우리들의 불안감을 자극하기 마련',
                '입니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day10_2_5} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이처럼 어떠한 방면으로 보더라도 ',
                '침실 안의 시계는 우리들의 수면에 아무런 도움이 되지 않습니다.',
                ' 그러니 오늘부터 ',
                '침실의 시계는 치우시기 바랍니다.',
                ' 가급적이면 ',
                '우리들의 시야에 보이지 않고 손에 닿지 않는 곳으로',
                ' 옮겨두는 것이 좋지만, ',
                '가장 좋은 건 시계를 침실 밖에 두는 것',
                '입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잠자리 환경: 스마트폰',
      fullImage: day10_2_6,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '스마트폰은 여러 방면에서 수면을 방해합니다. 잠에 들기 전 침대에 누워 스마트폰을 하는 게 그 어떠한 일보다 즐거운 일임을 잘 알고 있습니다. 하지만 적어도 불면증으로 인해 고생하고 있다면, 이번 코스를 통해 잃어버린 밤을 되찾아올 계획이라면 스마트폰은 침실로 가지고 가지 않아야 합니다.',
                '\n',
                '우선 ',
                '스마트폰을 침실에 가지고 가는 건 수많은 인간관계, 업무 메일들, 걱정과 고민들을 침실로 가지고 오는 것과 동일',
                '합니다. 앞서 이야기했듯 걱정거리나 업무를 침실로 가지고 와서는 안 됩니다. 스마트폰에는 수많은 걱정거리와 업무들이 포함되어 있습니다.',
                '\n',
                '뿐만 아니라 ',
                '스마트폰의 불빛은 생체 시계를 교란',
                '합니다. 자기 직전까지 스마트폰의 불빛으로 인해 “지금은 깨어있을 시간이야”라는 신호를 받던 생체 시계가 갑자기 불을 끈다고 하여 금세 “그래, 이제 잘 시간이야” 할 리는 없습니다. ',
                '\n',
                '스마트폰은 ',
                '침실 밖',
                '에, ',
                '적어도 침대에 누운 상태에서는 손이 닿지 않는 곳',
                '에 두는 것이 바람직합니다. 지루한 밤이 길어지면 거의 무의식적으로 하는 첫 번째 행동은 스마트폰을 켜는 것이기 때문입니다. 따라서 스마트폰은 침실 혹은 침대 밖에 두는 것이 좋습니다. 이는 노트북, TV 등과 같은 전자기기에도 동일하게 해당하는 내용입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '퀴즈를 풀어볼까요?',
      texts: [
        {
          text: '잠자리 환경에서의 수면 위생에 대한 내용은 여기까지입니다. 그러면 퀴즈를 통해 복습해 볼까요?',
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate index={1} question="무드등을 켜서 아늑한 분위기를 만드는 건 숙면에 도움이 된다" answer="X" isOX />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="생체 시계를 교란할 수 있어요"
          answerInfo={
            '물론 무드등이 일시적으로 심적인 안정감을 줄 수도 있습니다. 하지만 장기적으로 보았을 때 잠자리에서의 빛 자극은 생체 시계를 교란하여 우리가 더 깊은 잠을 자는 것을 방해한답니다.'
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="작은 소음은 수면에 큰 영향을 미치지 않기 때문에 크게 신경쓰지 않아도 괜찮다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="소리의 크기가 중요한 게 아니에요"
          answerInfo={
            '밤을 잃어버린 우리들은 잠자리에 눕게 되면 다른 자극에 매우 예민한 상태입니다. 따라서 아무리 작은 소음이라도 우리의 신경에 거슬리는 소음은 수면을 방해할 수 있습니다. 따라서 작은 소음이라도 자꾸 나의 생각이 집중되는 것이 있다면 귀마개 등을 이용하여 차단하는 것도 좋은 방법입니다.'
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="인사이드의 수면 코스가 기억나지 않을 때에는 잠자리에서 휴대폰을 활용하여 어떠한 것들을 지켜야 하는지 확인하면 된다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="침실에서 스마트폰은 사용하지 않아야 해요"
          answerInfo={
            '침실에서 스마트폰은 사용하지 않아야 합니다. 스마트폰은 생각과 걱정거리를 증폭시키고, 불빛을 통해 생체 시계를 교란하며, 시간이 흘러가는 것을 우리에게 인지시켜 초조함을 증폭시킵니다. 인사이드의 수면 코스는 낮 시간을 활용하여 익히고 복습하는 것이 좋습니다. 잠자리에서는 무언가를 배운다는 생각을 떨쳐내시길 바랍니다. 잠자리는 여러분만의 아늑한 휴식처로 남아야 합니다.'
          }
        />
      ),
    },
    {
      title: '잠자리 환경의 수면 위생',
      texts: [
        {
          text: '이번 시간에는 잠자리 환경과 관련된 수면 위생을 알아보았습니다. 다시 한번 정리해 볼까요?',
        },
        {
          component: (
            <TextWithGradientCircle
              texts={[
                { title: '침실', text: '시원하고, 어둡고, 조용한 분위기를 유지하도록 하기' },
                { title: '시계', text: '침실 밖에 두기' },
                { title: '스마트폰', text: '손에 닿지 않는 곳에 두거나, 침실 밖에 두기' },
              ]}
              margin="my-[40px]"
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '생각보다 챙겨야 할 수면 위생이 많죠? 다행인 점은 이러한 행동들이 그다지 어려운 일이 아니라 오늘부터 바로 적용할 수 있는 것들이라는 것입니다. 오늘부터는 수면 위생을 잘 챙기고 있는지 매일 질문지를 드릴 계획이랍니다. 잘 챙기지 못하고 있는 것들은 수면 리포트에 표시되니, 수면 위생을 잘 챙기도록 노력해 보세요.',
                '\n',
                '자, 그러면 오늘도 여기서 마무리할까요? 오늘도 정말 고생 많았습니다. 오늘도 마음을 편안하게 할 수 있는 노래를 한 곡 추천해드릴게요. Yiruma의 <Love me>라는 노래랍니다.',
                '\n',
                '그럼 우리는 내일 또 만나요. 오늘도 편안한 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="졸릴 때만 침대에 누웠나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },

    {
      replaceComponent: (
        <SurveyHygiene title="취침 2시간 전을 제외하고 시간을 내어 최소 20분 동안 달리기, 계단 오르기 등 숨이 차고 심장박동이 빨라지는 운동을 했나요?" />
      ),
    },
    {
      replaceComponent: <SurveyHygiene title="자기 전 공복감이 들었을 때, 가벼운 간식을 먹었나요?" />,
    },
    {
      replaceComponent: <SurveyHygiene title="침실은 시원하고, 어둡고, 조용하게 유지되고 있나요?" />,
    },
    {
      replaceComponent: (
        <SurveyHygiene title="시계와 스마트폰은 침실 밖에 두었나요? 혹은 나의 시야나 손에 닿지 않는 곳에 있나요?" />
      ),
    },
    {
      replaceComponent: <SurveyDone />,
      progressHide: true,
    },
  ],
  [
    {
      title: '수면과 관련된 미신들',
      fullImage: day11_2_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요, 오늘도 잘 찾아오셨네요. 좋습니다. 이번 시간에는 수면과 관련된 미신들, 즉, 옳지 않은 수면 위생에 대해 알아보고자 합니다. ',
                '\n',
                '많은 분들이 더 좋은 수면을 위해 다양한 일들을 시도합니다. 하지만 안타까운 사실은 그러한 일들이 대부분 수면에 도움이 되지 않거나, 심지어는 오히려 수면을 방해하고 있다는 점입니다. 오늘은 이와 같은 수면과 관련된 미신들에 대해 알아보고자 합니다.',
                '\n',
                '자, 그럼 시작해 볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '미신 1. 베개를 바꾸면 잘 잘 수 있다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '많은 분들이 잠자리 환경 중 베개를 가장 중요하게 생각합니다. 물론 베개가 너무 높거나 낮아서 불편하다고 느낀다면 베개를 바꾸는 것이 좋습니다. 하지만 베개를 바꾼다고 잠을 더 잘 잘 수 있는 것은 아닙니다. ',
                '베개를 바꿔서 수면에 변화가 생겼다는 사실을 증명하는 잘 설계된 연구는 거의 찾아볼 수 없습니다.',
                ' 느낌과 사실은 다른 문제입니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day11_2_2} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '그러니 불면증을 베개를 찾아 나서고 있다면, 큰 비용을 지불하고 베개를 구입할 계획이라면 이쯤에서 그만두는 것이 더 좋습니다. ',
                '마약 베개, 숙면 베개, 꿀잠 베개 등은 결국 우리들의 침실에 있는 것과 유사한 베개 중 하나일 뿐',
                '입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '미신 2. 특정한 향의 스프레이를 사용하면 잠을 잘 잘 수 있다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '특정한 향을 통해 숙면을 취하려는 시도들이 많이 이루어지고 있지만, ',
                '안타깝게도 현재까지는 특정한 향이 불면증을 낫게 하고 더 좋은 수면을 제공해 준다는 근거는 부족한 상황',
                '입니다. 물론 그러한 향기가 우리들의 마음을 편안하게 하고, 이를 통해 하루 이틀 정도는 더 편한 마음으로 잠들 수는 있을 겁니다. 하지만 역시나 느낌과 사실은 다른 문제입니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day11_2_3} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '더군다나 ',
                '신체의 감각 중 가장 적응을 잘하는 감각이 후각',
                '이라는 점을 생각해 본다면, ',
                '향기를 통해 더 나은 수면을 유도한다는 방법은 장기적인 관점에서 그다지 효과적이지 않습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '미신 3. 잠은 몰아서 자도 충분하다',
      fullImage: day11_2_4,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '앞서 잠은 빚을 질 수도 있는 것이라고, 즉, 못 잔 잠은 몰아 자서 보충할 수 있다고 설명드렸습니다. 하지만 이는 어디까지나 오늘 하루 조금 못 잔다고 큰 문제가 발생한다는 점을 강조하기 위함이지, 늘 그렇게 수면에 대한 빚을 지고 다시 갚으며 살 수는 없는 노릇입니다. ',
                '생체 시계는 규칙적인 것을 좋아합니다. 수면을 미루다가 하루에 몰아서 잠을 자는 방식은 생체 시계를 교란',
                '할 수밖에 없습니다. 따라서 잠을 몰아서 자는 전략은 장기적인 관점에서 그다지 현명한 전략이 아닙니다. 그보다는 규칙적인 수면 패턴을 가지려고 노력하시기 바랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '미신 4. 노력한다면 수면 시간을 더 줄일 수도 있다.',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '바쁜 학업이나 업무를 이어 나가다 보면 시간이 부족할 때도 많습니다. 특히 수험생과 같이 1년 동안의 장기적인 노력이 필요한 경우라면 어떻게든 잠을 줄이는 것이 중요하다고 생각할지도 모릅니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day11_2_5} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 안타깝게도 ',
                '우리 몸이 필요한 수면 시간은 유전적으로 타고나는 것',
                '으로 이를 우리가 마음대로 줄일 수는 없는 노릇입니다. 허나 너무 걱정하지 않으셔도 좋습니다. 하루에 4~5시간만 자도 괜찮은 사람들은 극히 일부에 불과하고, 그들도 사실은 부족한 잠을 낮 시간대에 어떻게든 보충하고 있는 경우가 많기 때문입니다. ',
                '\n',
                '따라서 학업이나 업무 등으로 시간이 부족한 상황이라도 ',
                '내 몸이 필요로 하는 수면 시간을 충분히 확보하고 나머지 시간에서의 효율을 더 높이기 바랍니다.',
                ' 수면 시간을 줄여야 모든 게 해결될 것이라 생각이 들지만 잘 생각해 보면 그렇지 않고도 좋은 결과를 내는 경우는 우리 주변에 수없이 많습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '미신 5. 와인 한 잔은 수면에 도움이 된다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '생각보다 많은 사람들이 와인에는 관대한 측면이 있습니다. 와인이 주는 이미지 때문일지도 모르겠습니다. 와인잔만 보더라도 소주잔이나 맥주잔보다 훨씬 더 기품 있고 부드러우며 덜 거친 측면이 있으니까요. 그래서 많은 분들이 잠들기 전 와인을 한 잔 마시기도 합니다. 그러한 행동이 다가올 밤에 많은 도움이 되리라고 생각하기 때문이죠.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day11_2_6} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 애석하게도 와인도 동일하게 알코올일 뿐입니다. ',
                '우리 몸은 와인에 포함된 알코올과 소주에 포함된 알코올을 다르게 인지하지 않습니다.',
                ' 그리고 앞서 이야기했듯이 ',
                '알코올은 수면을 방해하기 마련',
                '입니다. 그러니 만약 수면을 위해 와인을 마시고 있다면 오늘부터는 그만두는 것이 좋습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '퀴즈를 풀어볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '자, 어떤가요? 그간 잘못된 방법에 의지하고 있었구나 싶은 부분도 많을 겁니다. 중요한 내용을 한 번 더 복습해 보도록 하죠. 제가 몇 가지 퀴즈를 내보도록 하겠습니다. 틀려도 괜찮으니 편안하게 풀어보세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="연구 결과, 높은 베개보다 낮은 베개가 더 수면에 도움이 되며, 낮은 베개는 수면의 질을 높이는 데에 중요한 역할을 한다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="베개는 수면과 큰 관련이 없습니다"
          answerInfo="물론 사용했을 때 불편감을 느끼는 베개라면 바꾸는 것이 옳습니다. 하지만 지금 당장 베개 큰 문제를 느끼지 않는다면 애써 더 나은 베개를 찾는 데에 시간을 쓸 필요가 없습니다. 특정한 형태의 베개가 우리들의 수면에 도움이 된다는 객관적이고 잘 설계된 연구는 아직 존재하지 않으니까요."
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="허브향의 스프레이는 불면증을 해결하는 데에 많은 도움을 준다"
          answer="X"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="향과 수면은 큰 관련이 없습니다"
          answerInfo="많은 연구들이 진행되고 있지만 아직까지 향기를 이용한 방법은 주목할 만한 연구 결과를 만들어내지 못하고 있습니다."
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="사람마다 본인에게 적당한 수면 시간은 타고나는 것으로 의지를 통해 줄일 수 있는 것이 아니다"
          answer="O"
          isOX
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="수면 시간은 정해져 있는 경우가 많습니다"
          answerInfo="연구 결과에 따르면 수면 시간은 유전적으로 타고나는 경우가 많습니다. 그러니 바쁜 일상이라도 억지로 잠을 줄이기보다는 낮 시간을 더 효율적으로 사용하는 데에 집중하는 것이 바람직합니다."
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate index={4} question="와인 한 잔을 마시는 것은 수면을 오히려 방해할 뿐이다" answer="O" isOX />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="와인도 알코올입니다"
          answerInfo="와인도 알코올입니다. 알코올은 우리를 조금 더 빨리 잠들게 하는 경향이 있지만, 결국 우리가 더 깊게 잠들지 못하게 하고 더 자주 깨게 만들어 수면을 방해하게 됩니다."
        />
      ),
    },
    {
      title: '수면과 관련된 미신들',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘은 수면과 관련된 미신에 대해 알아보았습니다. 물론 “내 경험상으로는 그렇지 않은데” 싶은 게 있을지도 모릅니다. 누군가에게는 베개가 많은 도움이 되었을 수도 있죠. 하지만 거듭 강조하듯 나의 주관적인 생각이나 느낌과 사실은 다른 문제입니다. ',
                '객관적으로 잘 설계된 연구들은 베개나 향초 같은 방법들이 수면에 그다지 영향을 주지 않는다고 말하고 있습니다.',
                ' 그러니 올바른 방법을 찾아 거기에 집중하는 것이 더 현명한 일입니다. 그리고 ',
                '불면증에서 가장 효과적이라고 연구된 방법은 지금 진행하고 있는 인지행동치료입니다.',
                <span />,
                '\n',
                '오늘도 많은 것들을 배웠네요. 고생 많으셨습니다. 그러면 우리는 내일 또 만나볼까요? 오늘은 Robert Schumann의 <Kinderszenen (Scenes from Childhood), Op. 15. VII. Träumerei (Dreaming)>라는 곡을 소개해 드릴게요.',
                '\n',
                '오늘도 편안한 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="졸릴 때만 침대에 누웠나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      replaceComponent: (
        <SurveyHygiene title="취침 2시간 전을 제외하고 시간을 내어 최소 20분 동안 달리기, 계단 오르기 등 숨이 차고 심장박동이 빨라지는 운동을 했나요?" />
      ),
    },
    {
      replaceComponent: <SurveyHygiene title="자기 전 공복감이 들었을 때, 가벼운 간식을 먹었나요?" />,
    },
    {
      replaceComponent: <SurveyHygiene title="침실은 시원하고, 어둡고, 조용하게 유지되고 있나요?" />,
    },
    {
      replaceComponent: (
        <SurveyHygiene title="시계와 스마트폰은 침실 밖에 두었나요? 혹은 나의 시야나 손에 닿지 않는 곳에 있나요?" />
      ),
    },
    {
      replaceComponent: <SurveyDone />,
      progressHide: true,
    },
  ],
  [
    {
      title: '이완요법: 걱정 덜어두기',
      fullImage: day12_2_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요, 반갑습니다. 어젯밤은 어떠셨나요? 제가 처방해 드렸던 수면 시간대로 잠을 자는 게 쉬운 일은 아닐 겁니다. 하지만 분명 효과가 있는 방법이니 조금만 참고 조금 더 따라와보도록 해요.',
                '\n',
                '수면 시간을 제한하고 잠이 오지 않으면 잠자리에서 벗어나는 방법을 사용하더라도 ',
                '여전히 잠자리에 누웠을 때 또렷하게 각성되는 상황',
                '이 있을지도 모릅니다. 이 시기에 흔히 아래와 같은 불편을 호소하는 분들이 많습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTailCross
              texts={[
                ['선생님, 수면 시간은 잘 지키고 있어요.'],
                ['좋아요, 그러면 다른 어려운 점이 있나요?'],
                [
                  '졸리지 않을 때는 거실에서 빨래를 개기도 하고 책을 읽기도 하거든요. 그러다가 분명 하품이 나고 졸릴 때가 있어요. 그렇지만 이상하게 잠자리에 눕기만 하면 정신이 또렷해져요. 어떻게 해야 할까요?',
                ],
              ]}
              types={['gray', 'white']}
              opposite
              margin="mt-[40px]"
              fit
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '생각에 집중되는 현상',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이러한 일이 발생하는 이유로는 크게 두 가지가 있습니다. 첫 번째는 ',
                '여전히 우리의 뇌가 ‘잠을 자야 한다’는 사실에 너무 집중하고 있다는 점',
                '입니다. 한 가지 예를 들어볼게요. 지금 제가 말씀드리는 바를 잘 지켜보세요.',
              ]}
            />
          ),
        },
        {
          component: <MessageBlock type="gray" textList={[['분홍색 코끼리를 떠올리지 마세요!']]} my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '어떤가요? 분명 여러분이 아무리 애를 써도 분홍색 코끼리의 이미지는 우리 머릿속에 들어오게 됩니다. 수면도 이와 마찬가지입니다. ',
                '‘잠을 자야 한다’는 생각은 수면에 대한 초조함을 증폭시켜 수면을 방해할 뿐',
                '입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '몸이 긴장하는 현상',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '침대에 눕기만 하면 다시 각성되는 두 번째 이유는 ',
                '우리 몸이 여전히 침대에서 긴장하고 있기 때문',
                '입니다. 불안감과 초조함으로 심박동 수가 더 증가할 수도 있고, 몸에 힘이 들어가 도무지 잠들기 편안하게 잠들지 못할 수도 있습니다. 밤새 잠 못 이루며 어딘가 모르게 불편해서 자꾸 뒤척였던 지난밤을 떠올려보세요. 하지불안증후군과 같은 다른 질환이 있다거나, 실제 몸에 불편한 곳이 있는 것이 아니라면, ',
                '‘불편한 느낌’은 오히려 마음의 문제일 가능성이 높습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '그래서 이완요법을 알려드릴 거예요',
      fullImage: day12_2_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이러한 문제를 해결하기 위한 방법 중 하나로 ',
                '이완요법',
                '이라는 것이 있습니다. ',
                '잠자리에 들 때 신체적으로, 심리적으로 각성된 상태가 되는 것을 막아 잠이 잘 들고 자주 깨지 않게 하는 것',
                '이지요. 오늘부터 3일간은 이완요법에 대한 이야기를 해 볼 계획입니다. ',
                '\n',
                '오늘은 첫 번째로 ',
                '‘걱정 덜어두기’',
                '에 대해 이야기해 볼게요. 아마 지난 수업에서 간단하게 들어봤던 이야기일 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '걱정 덜어두기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '고민과 걱정은 침대로 가지고 오지 않기로 하였습니다. 저는 오늘 그 구체적인 방법에 대해 말씀드리려고 합니다. 아마 직장을 다니거나 중요한 시험을 앞두고 있는 경우라면, 이 방법이 많은 도움이 될 겁니다.',
                '\n',
                '우선 ',
                '저녁 식사 후 약 10분 정도',
                ' 시간을 내십시오. 그리고 자리에 앉아 ',
                '지난 낮 동안에 벌어진 일의 목록을 짧게 작성',
                '해 보세요. 오늘 한 일, 어렵게 해낸 일, 아직 끝내지 못하여 마음에 남아있는 일 등이 있을 겁니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day12_2_3} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '다음으로는 ',
                '내일 할 일에 대해 적어보세요.',
                ' 먼 미래의 계획이 아니라 오로지 다음 날에 할 일말입니다. 아마 업무 끝내기, 치과 다녀오기, 강의 듣기 등이 있을 겁니다. 할 일 목록은 짧게 만드는 게 좋습니다. 완벽하게 계획하는 게 목적은 아니니까요.',
                '\n',
                '오늘 벌어진 일과 내일 할 일을 적다 보면 어느 정도 일상에 대한 통제감을 느낄 수 있습니다. “오늘은 이런 일이 있었고, 내일은 이런 것들을 하면 되겠지”라고 생각하는 것이지요. 그 이상의 미래에 대해서는 생각할 필요가 없습니다. ',
                '잠자리에 누웠는데 해야 하는 일들, 아직 못 한 일들이 자꾸 떠오른다면 “그건 따로 적어두었지. 그냥 내일 일어나서 한번 살펴보자”라고 생각',
                '하시면 됩니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '걱정 덜어두기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이 작업은 ',
                '침실 밖에서 하는 것이 좋습니다.',
                ' 침실에서 목록을 작성하는 건 걱정거리를 잠자리로 가지고 오는 것이기 때문이죠. 그러니 ',
                '잠자기 4~5시간 정도 전, 즉, 저녁 식사 후에 침실이 아닌 다른 공간에서 오늘과 내일에 대한 일들을 간단하게 작성하시기 바랍니다.',
                '\n',
                <span />,
                '그다지 고민이 있는 상황이 아니라면 이러한 과정이 필요하지 않을 수도 있습니다. 반면 해야 할 일들이 많고 그런 것들로 인해 근심 걱정이 생기는 것이라면 걱정을 마음속에서 치워 놓는 일이 많은 도움이 될 수도 있습니다. 따라서 걱정 덜어두기는 ',
                '본인에게 필요하다고 생각되는 경우에만 사용',
                '하면 됩니다. 우선해 보고 도움이 된다 싶으면 계속하면 되고, 그렇지 않으면 애써 이곳에 많은 힘을 쓰지 않아도 괜찮습니다. ',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '수고하셨어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘은 이쯤에서 마무리하고 걱정 덜어두기를 연습하러 가보도록 하죠. 걱정을 덜어둘 공간은 필요할 때마다 사용할 수 있도록 수면 리포트에 넣어드리겠습니다. 일단 한번 시작해 보죠. 그 다음엔 인지통제기법을 통해 복잡한 생각을 다스리는 방법에 대해 알려드릴 거랍니다.',
                '\n',
                '그럼 잠시 쉬었다가 하루를 마무리하는 시점에 걱정 덜어두기를 직접 해보지요.',
                '\n',
                '있다가 뵐게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    basicFormat({
      title: '걱정 덜어두기',
      fullImage: day12_2_1,
      texts: [
        '고민과 걱정은 침대로 가지고 오지 않아야 숙면에 도움이 됩니다. 잠에 들기 4~5시간 전 오늘과 내일에 대한 일들을 간단히 작성해 보세요.',
        '\n',
        '물론 이 작업은 침실 밖에서 하셔야 하는 것도 잊지 않으셨겠죠? 그럼 충분히 준비가 되었을 때 진행해 보세요.',
      ],
    }),
    {
      replaceComponent: <WorryNote />,
    },
    {
      title: '훌륭합니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '저는 매번 수면과 관련하여 이토록 할 수 있는 일이 많다는 사실이 신비롭기만 합니다. 자연스럽게 찾아오는 밤이었지만 이를 되찾아오기는 이렇게 힘든 일이지요. 하지만 다시 되찾아오기만 하면 분명 다시 편안하게 주무실 수 있을 거랍니다.',
                '\n',
                '오늘은 Robert Schumann의 <Scenes from Childhood, Op. 15, I. Of Foreign Lands And Peoples>라는 노래를 알려드릴게요. 부디 마음을 다스리는 데에 많은 도움이 되었으면 합니다.',
                '\n',
                '그럼 오늘도 편안한 밤 보내시길 바랍니다. 저희는 내일 또 만나도록 하죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
      submit: 'sleepingWorry',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="졸릴 때만 침대에 누웠나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      replaceComponent: (
        <SurveyHygiene title="취침 2시간 전을 제외하고 시간을 내어 최소 20분 동안 달리기, 계단 오르기 등 숨이 차고 심장박동이 빨라지는 운동을 했나요?" />
      ),
    },
    {
      replaceComponent: <SurveyHygiene title="자기 전 공복감이 들었을 때, 가벼운 간식을 먹었나요?" />,
    },
    {
      replaceComponent: <SurveyHygiene title="침실은 시원하고, 어둡고, 조용하게 유지되고 있나요?" />,
    },
    {
      replaceComponent: (
        <SurveyHygiene title="시계와 스마트폰은 침실 밖에 두었나요? 혹은 나의 시야나 손에 닿지 않는 곳에 있나요?" />
      ),
    },
    {
      replaceComponent: <SurveyDone />,
      progressHide: true,
    },
  ],
  [
    {
      title: '이완요법: 인지통제기법',
      fullImage: day13_2_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요, 반갑습니다. 지난 밤은 좀 어떠셨나요? 걱정 덜어두기가 도움이 되셨는지 모르겠네요. 당장은 도움이 되지 않는다고 하더라도 며칠만 더 해보시길 바랍니다. 근심과 걱정 때문에 잠을 못 이루고 있다면, 특히 근심과 걱정이 업무나 과제에 해당하는 것이라면, 조금만 더 진행하다 보면 분명 도움이 될 거랍니다.',
                '\n',
                '오늘은 복잡한 생각을 다스리는 두 번째 방법인 ',
                '인지통제기법',
                '에 대해 이야기해 보겠습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '이완요법: 인지통제기법',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이름은 거창하지만 ',
                '인지통제기법의 목적',
                '은 간단합니다. ',
                '복잡한 생각을 진정시키기 위함',
                '이지요. 잠이 안 올 때 사람들이 가장 많이 했던 일들이 무엇일까요? 양을 세는 방법이었습니다. 양 한 마리, 두 마리, 세다 보면 어느새 졸려서 잠이 든다는 이야기였지요. 하지만 이미 경험해 보셨을지도 모르지만, 양을 세는 방법은 너무 단순하고 지루해서 우리들의 생각은 금세 다른 방향으로 흘러갈 수도 있습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['양 한 마리... 🐑 '],
                ['양 두 마리...🐑 🐑'],
                ['양 세 마리... 🐑 🐑 🐑 '],
                ['......'],
                ['내일 제출해야 할 과제가 세 건이네. 충분히 준비한 걸까…'],
              ]}
              type="white"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '따라서 저는 여러분에게 ',
                '단순히 양을 세는 것보다 조금 더 복잡하지만 지루한 방법',
                '에 대해 알려드리려고 합니다. 침대에 누웠는데 자꾸 복잡한 생각이 드는 상황이라면 제가 알려드리는 방법이 도움이 될지도 모른답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '상상하기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '침대에 누웠는데 자꾸 일상과 관련된 생각이 드나요? 아니면 잠을 자야 한다는 생각이 자꾸 강박적으로 떠오르나요? 그렇다면 조금 우리들의 상상력을 더 발휘해 볼 시간입니다. 어릴 적 상상한 것들이 머릿속에서 뭉게뭉게 피어올라 선명한 이미지로 떠올랐던 경험을 기억하시나요? 아마 너무 어릴 적 일이라 잘 기억하지 못할지도 모릅니다. 하지만 우리 모두 분명 어릴 적엔 우리 나름의 세상을 그리고 있었답니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day13_2_2} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우리는 이제 우리만의 과일을 만들어볼 거랍니다. 이번에는 사과라고 해 보죠. ',
                '눈을 감고 머릿속으로 사과의 이미지를 아주 생생하게 그려보세요.',
                ' 우리가 지금껏 살면서 가장 완벽하다고 생각했던 사과로 만들어보죠. 아마 꼭지 부분에서 조금 녹색빛을 돌지도 모른답니다. 꼭지는 먼지가 없이 깔끔한 모습이죠. 몸통은 매우 붉은빛을 띄고 있습니다. 연한 빛의 작은 점들이 몸통을 뒤덮고 있습니다. 표면은 매끄럽고 윤기가 나네요. 참 맛나 보이는 사과입니다.',
                '\n',
                '충분히 맛있어 보이고 완벽한 사과를 그려보았나요? 그렇다면 이번엔 사과를 다른 색으로 바꾸어보죠. 푸른색으로 바꿔봅니다. 붉은빛이었던 사과가 신비로운 모습으로 천천히, 천천히 푸른색으로 변합니다. 꼭지도 파란빛으로 변해버렸네요. 푸른빛으로 변하여도 여전히 윤기가 나는 것 같습니다. ',
                '\n',
                '다시 완벽한 모습의 푸른 사과를 만들어보았나요? 이번엔 또 다른 색으로 바꾸어볼까요?',
                '\n',
                ' 이러한 작업을 하다 보면 어느샌가 여러분은 잠에 빠져있을 겁니다. 아마 여러 가지 색깔을 동원할 필요도 없을지도 모르죠. 잠들기까지 오래 걸리지 않을 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '숫자 빼기',
      image: day13_2_3,
      bg: '#21222C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '누군가에게는 상상력을 발휘하는 것 자체가 고된 일임을 잘 알고 있습니다. 그럴 때에는 보다 간편한 방법이 있습니다. 1,000부터 시작해서 천천히 7씩 빼는 것이죠.',
                '\n',
                '993, 986, 979 …',
                '\n',
                '이런 식으로 천천히 내려가봅니다. ',
                '답이 중요한 것은 아닙니다. 틀려도 상관없으며, 틀렸다면 그곳에서부터 다시 7씩 빼나가면 됩니다. \n\n',
                '993, 987, 980, 973, 965 …',
                '\n',
                '틀려도 계속 7씩 빼려고 노력만 하면 그뿐입니다. 다시 한번 강조하자면 답을 정확히 맞추는 건 중요하지 않습니다. 답을 맞히는 데에 집중하려고 애쓰지 않기를 바랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '두 가지 인지통제기법을 배웠습니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '상상하기와 숫자 빼기 중 ',
                '어떠한 것들을 사용하여도 상관없습니다.',
                ' 각자에게 더 잘 맞는 방법이 있을 겁니다. 그저 ',
                '생각이 복잡해지기 시작하거나, 잠자는 것 자체에 대한 생각이 또렷해지는 순간에 편한 기법을 사용',
                '하면 됩니다. 이러한 방법들은 여러분이 수면에 과도하게 집착하는 과정을 막아줄 겁니다. ',
                '\n',
                '오늘은 잠자리에서의 잡다한 생각들을 떨쳐내는 방법에 대해 배워보았습니다. 분명 양을 세는 것보다는 더 효과적일 거랍니다. 잠자리에 누웠는데 자꾸 잡다한 생각이 든다면 편하게 한번 시도해 보세요. ',
                '\n',
                '자, 그러면 오늘은 여기까지 하도록 할까요? 이 내용을 떠올리기 힘들지도 모르니 제가 따로 수면 리포트에 각각의 기법들을 실천할 수 있는 도구를 제공해 드리겠습니다. 낮 시간대에 충분히 연습해 보고 잠자리에 누웠을 때 활용해 보세요. ',
                '\n',
                '단, ',
                '당연히 잠자리에 누워서 휴대폰을 보며 그러한 연습을 해서는 안 됩니다.',
                ' 그보다는 생체시계를 교란시키지 않는 것이 더 중요하니까요. ',
                '\n',
                ' 오늘도 고생 많으셨습니다. 그러면 우리는 내일 만나도록 하죠. 오늘은 Robert Schumann의 <06. Scenes from Childhood, Op. 15, VIII. At The Fireside>라는 곡을 추천해드리겠습니다. 요즘은 Robert Schumann의 노래를 자주 추천하는 것 같네요. 제가 최근 Robert Schumann의 좋은 LP판을 구매했기 때문이랍니다.',
                '\n',
                ' 오늘도 편안한 밤 보내시길 바랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <EmagineCard />,
    },
    {
      title: '이제 눈을 감고 상상하기를 해보세요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '상상하기에 정답은 없답니다. 내가 생각할 수 있는 형태로 구체화 해보시면 됩니다. 잠이 오는데 분명 도움이 될 거예요. 그럼 다음엔 숫자 빼기 활동을 알려드릴게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <CountNumberCard />,
    },
    {
      title: '이제 눈을 감고 숫자 빼기를 해보세요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '계산이 정확하지 않아도 됩니다. 정답이 있는 것은 아니에요. 떠올린 숫자부터 천천히 7을 빼 나가다 보면 분명 잠이 올 겁니다.',
                '\n',
                '그럼 우리는 내일 또 뵙죠.',
                '\n',
                '오늘도 편안한 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      replaceComponent: <SurveyInBedStart type="inBedStart" />,
    },
    {
      replaceComponent: <SurveyInSleepStart type="sleepStart" />,
    },
    {
      replaceComponent: <SurveyInSleepEnd type="sleepEnd" />,
    },
    {
      replaceComponent: <SurveyInBedEnd type="inBedEnd" />,
    },
    {
      replaceComponent: <SurveyInStruggle type="struggleTime" />,
    },
    {
      replaceComponent: <SurveyInNap type="napTime" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="졸릴 때만 침대에 누웠나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" />,
    },
    {
      replaceComponent: <SleepingRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      isHygiene: true,
      replaceComponent: <SurveyCas />,
    },
    {
      replaceComponent: (
        <SurveyHygiene title="취침 2시간 전을 제외하고 시간을 내어 최소 20분 동안 달리기, 계단 오르기 등 숨이 차고 심장박동이 빨라지는 운동을 했나요?" />
      ),
    },
    {
      replaceComponent: <SurveyHygiene title="자기 전 공복감이 들었을 때, 가벼운 간식을 먹었나요?" />,
    },
    {
      replaceComponent: <SurveyHygiene title="침실은 시원하고, 어둡고, 조용하게 유지되고 있나요?" />,
    },
    {
      replaceComponent: (
        <SurveyHygiene title="시계와 스마트폰은 침실 밖에 두었나요? 혹은 나의 시야나 손에 닿지 않는 곳에 있나요?" />
      ),
    },
    {
      replaceComponent: <SurveyDone />,
      progressHide: true,
    },
  ],
  [
    {
      title: '이완요법: 긴장 풀기',
      fullImage: day14_2_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요. 오늘도 잘 찾아오셨습니다. 조금씩 수면이 개선되는 느낌이 드는지 모르겠네요. 별다른 진척이 없는 것 같더라도 아직 코스의 절반도 지나지 않았으니 조금만 더 참고 진행해 보시길 바랍니다. 분명 많은 도움이 있을 거예요.',
                '\n',
                '상상하기와 숫자 빼기는 어떠셨나요? 그 자체가 큰 해결책을 주는 건 아니지만 분명히 잠자리에서의 어지러운 마음을 다스리는 데에는 많은 도움이 될 거랍니다.',
                '\n',
                '오늘은 조금 더 ',
                '신체를 편안하게 하는 방법',
                '에 대해 이야기해 보고자 합니다. 침대에 누웠을 때 편안함을 느끼시나요? 아니면 ',
                '어딘가 모르게 긴장되고 꿈틀거리고 자꾸 뒤척이고 계신가요?',
                ' 만약 후자라면 오늘 알려드리는 방법이 많은 도움이 될지도 모릅니다. 단, 다리에 벌레가 기어 다니는 것과 같은 기분이 들어서 자꾸 다리를 털어야 한다면 의사를 먼저 찾아가 보시길 바랍니다. 이전에 이야기했던 하지불안증후군 때문에 그러한 증상이 나타날 수도 있기 때문입니다. 그렇지 않은 경우라면 오늘 알려드리는 ',
                '‘긴장 풀기’',
                '에 대해 집중해 보세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '긴장 풀기',
      fullImage: day14_2_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '긴장 풀기는 ',
                '주요 신체 부위의 근육을 의도적으로, 그리고 점진적으로 수축시켰다가 서서히 풀어주는 방법',
                '입니다. 이러한 방법을 반복하면 ',
                '근육긴장이 완화',
                '되며, ',
                '호흡과 심장박동이 안정',
                '됩니다. 방법은 다음과 같습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['1.  편안하고 조용한 곳에 앉거나 누워서 눈을 감고 몸에 힘을 뺍니다.'],
                [
                  '2.  숨을 들이마시며 몸의 한 곳에 힘을 줍니다. 이렇게 힘을 주는 것을 5초 가량 지속합니다. 힘을 주면서 근육의 긴장도를 느껴봅니다.',
                ],
                ['3. 숨을 내쉬며 근육의 긴장을 빠르게 풉니다. 15초 가량 긴장을 푼 상태로 유지합니다.'],
                [
                  '4. 발, 종아리, 허벅지, 손가락, 팔, 엉덩이, 가슴, 배, 목, 어깨, 눈, 입 등 다양한 근육들에 대해 이러한 동작을 반복합니다.',
                ],
              ]}
              type="gray"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '처음에 연습을 할 때에는 ',
                '침실 밖에서 하는 것이 좋습니다.',
                ' 침실 위에서 잠을 자기 위한 고군분투하는 모습을 우리 뇌에 보여주면 안 되니까요. 다시 한번 강조하지만 침실은 잠을 자는 곳이지 무언가를 배우고 공부하는 곳이 아닙니다. 그러니 ',
                '침실 밖에서 약 15~20분 정도 연습해 보며 충분히 익숙해졌을 때 침대 위에서 활용',
                '해 보시길 바랍니다. 점진적으로 근육을 이완시키다 보면 몸의 긴장이 줄어들고, 마음이 편안해지는 것을 느낄 수 있을 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '많이 낯설 거예요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '혼자서도 할 수 있을 정도로 간단하지만 구체적인 가이드 없이 이를 진행하는 게 쉽진 않을 겁니다. 그래서 제가 코스를 진행하면서 긴장 풀기를 시행할 수 있도록 적절한 시점에 제공해드리고자 합니다. 이번 수업이 마치고 한번 살펴보도록 하죠.',
                '\n',
                '지난 3일 동안 이완요법에 대해 배워보았습니다. 크게 ',
                '걱정 덜어두기, 인지통제기법(상상하기, 숫자 빼기),',
                ' 그리고 ',
                '긴장 풀기',
                '에 대해 배워보았죠. 이 중에서 ',
                '편한 방법을 사용',
                '하시면 되며, ',
                '모든 방법이 도움이 된다면 매일 밤 다른 방법을 사용하셔도 괜찮습니다. \n\n',
                '만약 이러한 방법들이 여러분에게 맞지 않는다고 해도 걱정할 필요는 없습니다. 이러한 방법들이 불면증을 교정하는 핵심적인 요소는 아니기 때문입니다. 그러니 편안한 마음으로 시도해 보시길 바라며, 설령 이러한 방법들이 도움이 되지 않는다고 해도 좌절하지 않기를 바랍니다. ',
                '\n',
                '다만, 많은 분들이 걱정을 덜어내는 방법, 상상하기와 숫자 빼기를 통해 복잡한 생각을 치워 두는 방법, 그리고 몸을 이완시켜 신체를 편안하게 만다는 방법이 도움 된다고 이야기하고 있습니다. 잘 활용하면 분명 좋은 도구가 될 겁니다. ',
                '\n',
                '오늘은 이쯤에서 마무리하도록 하겠습니다. 수업을 마치고 긴장 풀기 도구를 살펴보고 따라서 연습해 보시길 바랍니다.',
                '\n',
                '오늘도 Robert Schumann의 곡이랍니다. <08. Scenes from Childhood, Op. 15, XII. Child Falling Asleep>이라는 노래를 한 번 들어보세요. 부디 근심 걱정을 덜어내고 편안한 마음을 가지는 데에 도움이 되었으면 좋겠습니다.',
                '\n',
                '그러면 우리는 내일 만나도록 하죠. ',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '긴장 풀기를 진행 할게요',
      fullImage: day14_2_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '편안하고 조용한 곳에서 눈을 감고, 오디오가 안내하는 대로 따라서 진행합니다.\n오디오를 끝까지 들어야 활동을 마칠 수 있으니 충분히 준비된 상태에서 시작해 주세요. ',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: <AudioPlayer />,
    },
    {
      title: '오늘도 수고하셨어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '처음엔 어색할 수 있겠지만, 계속 반복해서 진행하다보면 분명히 도움이 되실 겁니다.\n잠이 안올때 마다 침대 밖에서 긴장 풀기를 진행해 보세요.',
                '\n',
                '그러면 우리는 내일 만나도록 하죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '1. 잠에서 깨는 시각 정하기',
      image: _14_5,
      bg: '#DBE4F0',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우선 첫 번째로 잠에서 깨는 시각을 정해보려고 합니다. 앞으로는 ',
                '몇 시에 자던 무조건 정해진 시각에 일어나야 합니다.',
                ' 아침에 눈을 뜨는 것이 피곤하고 고된 일임을 잘 알고 있습니다. 지난 밤을 꼬박 지새웠다면 더더욱이 그렇겠지요. 하지만 정해진 시각에 일어나는 것은 매우 중요합니다. ',
                '오히려 몇 시에 잠드는지 보다 더 중요한 문제',
                '입니다. 잠에서 깨는 시각을 지켰는지에 대한 여부는 인지행동치료 요법의 효과를 좌지우지하기도 하죠. 그러니 힘든 일이라도 조금만 의지를 가지고 진행해 보도록 해요. 많은 것들을 바꿀 수 있을 겁니다.',
                '\n',
                '잠에서 깨는 시각은 흔히 ',
                '출근 시간, 등교 시간',
                '에 맞춰 설정하곤 합니다. 정해진 시각이 없다면 ',
                '평소 본인의 패턴',
                '에 맞춰 정하면 됩니다. 단, 너무 늦은 시각으로 정하면 안 됩니다. 일반적으로 ',
                '25세 이상 성인의 경우 오전 6시에서 8시 정도의 시간대로 설정',
                '합니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: <AlarmTimePicker type="wakeup" />,
    },
    {
      title: '좋아요, 잠에서 깨는 시각을 정했어요',
      texts: [
        {
          text: '이제부터 아래와 같은 시각에 깨어나는 것으로 할게요.',
        },
        {
          component: <WakeUpCard />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '잠에서 깨는 시각은 한 주 동안 잘 지켜주셔야 합니다. 걱정이 된다면 설정한 ',
                '시각에 맞춰 알람',
                '을 켜두는 것이 좋아요. 그리고 잠에서 깨는 시각은 ',
                '주말에도 지켜야 합니다.',
                ' 주말까지 동일한 시간에 일어나야 안정된 수면패턴을 가지고, 생체시계를 안정되게 유지할 수 있답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '2. 침대에 들어가는 시각 정하기',
      image: _14_6,
      bg: '#6C85A8',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이번에는 ',
                '침대에 들어가는 시각',
                '을 정할 거랍니다. 침대에 들어가는 시각을 정하는 목적은 ',
                '수면 패턴을 리셋하고 실제로 잠들지 않은 상황을 최소화하여 수면 효율을 올리기 위함',
                '입니다.',
                '\n',
                '물론 이 시간과 별개로 어제 설명한 자극조절요법의 규칙들은 잘 지키셔야 합니다. 즉, 침대에 들어가고 나서 15분이 지나도 잠이 오지 않으면 침실에서 나오셔야 해요. 이 규칙은 ',
                '수면 효율을 증가시키고 수면에 대한 우리들의 좌절감을 줄여 침실과 수면 사이의 연결성을 강화',
                '시킵니다.',
                '\n',
                '잠에서 깨는 시각과 달리 침대에 들어가는 시각은 제가 직접 정해드리는 거랍니다. 앞서 말씀드렸던 것처럼 수면 효율을 먼저 계산해 보고, 수면 효율이 85% 이하라면 앞으로 4주 동안 수면 효율을 85% 수준으로 맞추는 데에 노력할 거랍니다. 만약 85% 이상이라면 그 상태를 유지할 수 있는 최적의 시각을 침대에 들어가는 시각으로 정해 드릴 거예요. 자, 그럼 지난 일주일간 나의 수면 효율은 어땠는지 살펴볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      progressHide: true,
      replaceComponent: <SleepResultAnalysis title="지난 수면 기록을 분석하고 있어요" />,
    },
    {
      replaceComponent: <SleepEfficiencyResult />,
    },
    {
      progressHide: true,
      replaceComponent: <SleepResultAnalysis title="수면 시간을 계산하고 있어요" />,
    },
    {
      title: '침대에 들어가는 시각을 정했어요',
      texts: [
        {
          text: '좋아요. 이제부터 아래와 같은 시각에 침실로 가시면 됩니다.',
        },
        {
          component: <GoToBedCard />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '물론 처방된 수면 시간들을 지키는 것이 쉬운 일은 아닐 겁니다. 그리고 앞으로도 계속 이 패턴을 유지해야 하는 건 아닙니다. 침실과 수면 사이의 연결성을 강화하여 조금 더 푹 자고 개운하게 일어날 수 있을 때까지만 제가 제시한 패턴대로 자고 일어난다고 생각하시면 됩니다.',
                '\n',
                '컴퓨터는 재부팅 버튼을 누르기만 하면 되지만, 우리의 뇌는 조금 더 시간이 필요한 법이니까요.  코스를 마친 후 편히 잘 수 있는 시간들을 생각해 보면 그리 오랜 시간은 아닐 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '혹시 너무 늦게 잠드는 것 같나요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '아마 평소보다 잠드는 시간이 너무 늦어 놀라셨을 수도 있을 것 같습니다. 최근 불면증으로 고생하고 계셨다면 더더욱이 그럴 겁니다. 늦게 자라고 하는 이유는 다음과 같습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <div className="font-light mx-[15px] my-[40px]">
              <TextWithBold texts={['1. 수면 효율을 높이기 위해서 ', '‘당분간만’ 평소보다 적게 잔다.']} />
              <TextWithBold texts={['2. 따라서 ', '평소와 기상시간이 같다고 가정하면 평소보다 늦게 잘 수밖에 없다.']} />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '물론 갑자기 평소보다 늦게 자라고 하니 많이 걱정되시는 점 잘 이해합니다. 하지만 이 방법이 불면증을 가장 빨리, 가장 지속적으로, 그리고 가장 안전하게 해결하는 방법임에는 틀림이 없습니다. 그러니 저를 믿고 조금만 수면 패턴을 바꿔보도록 해요. 약속한 취침시간과 기상시간을 잘 지켜서 조금씩 수면 효율이 올라오면 제가 조금씩 수면시간을 더 늘려드릴 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: <AlarmTimePicker type="isSet" />,
    },
    {
      title: '일주일 뒤에 시간을 다시 조정해 드릴 거예요',
      image: _14_7,
      bg: '#1C3963',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '다음 일주일 간의 수면 기록을 바탕으로 한 주가 지난 뒤 다시 수면 시간을 조정',
                '해드릴 거랍니다. 일주일이 지난 뒤 수면 효율이 일정 기준 이상으로 올라오면 총 수면 시간을 조금 더 늘려드릴 거예요. 그러니 당분간은 제가 정해준 기상 시간과 취침 시간을 꼭 지키시길 바랍니다.',
                '\n',
                '물론 ',
                '초반에는 조금 졸리고 힘들 수도 있습니다.',
                ' 하지만 분명한 사실은 그 과정을 거치고 나면 잃어버린 밤을 되찾아올 수 있으리라는 점이지요. 불안하고 초조했던 지난 밤은 더 이상 없을 거랍니다. 저희가 지금 방식대로 함께 노력한다면요. 그러니 조금 힘들더라도 잘 지켜나가보도록 해요.',
                '\n',
                '자, 그럼 오늘은 여기까지 해 볼까요? 처방된 수면 시간은 수면을 기록할 때 바로 확인하실 수 있을 거예요. 오늘부터 잘 실천해보세요.',
                '\n',
                '오늘은 Yiruma의 <Sweet Dream My Dear>라는 노래를 추천해드릴 거예요. 마음을 편안히 하는 데에 도움이 될 거예요.',
                '\n',
                '그럼 우린 내일 또 만나요. 오늘도 편안한 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
      submit: 'sleepPrescription',
      customButtonText: '다음',
    },
  ],
  [
    {
      progressHide: true,
      showHeader: true,
      replaceComponent: <ObjectiveFeedback />,
    },
    {
      progressHide: true,
      replaceComponent: <FeedbackDone bg="#EFF2FF" title="평가를 마쳤어요" />,
    },
  ],
  SleepingSurveyWithCas,
  [
    basicFormat({
      title: '불면증의 뇌: 걱정',
      fullImage: day15_2_1,
      texts: [
        '안녕하세요. 지난 밤은 잘 주무셨나요? 이제 코스의 절반을 지나가고 있네요. 이제 치료에 대해 믿음이 생겨서 잘 따라오고 계실까요? 아니면 생각보다 체감되는 효과가 없어서 조금은 의심이 생긴 상황일 수도 있겠죠. 치료 초기에 말씀드렸듯 ',
        '인지행동치료로 2주 만에 불면증을 치료할 수 있는 것은 아닙니다.',
        ' 하지만 주어진 코스를 잘 마무리하면 ',
        '분명 규칙적인 수면 패턴을 찾아나갈 수 있을 거예요.',
        ' 많은 연구 결과들이 이를 증명하고 있으니까요.',
        '\n',
        '오늘부터는 조금 더 다른 이야기를 해 보고자 합니다. 바로 우리들의 생각에 대해서 말이죠. 생각보다 우리들의 생각은 많은 것들을 바꿀 수 있답니다. 굳건한 의지로 많은 것들을 변화시켰던 사람들의 이야기를 들어본 적 있나요? 그들뿐만 아니라 우리도 마찬가지입니다. 우리 모두 그러한 마음의 힘이 있으니까요. 오늘부터 며칠간은 바로 그 마음에 대해 이야기해 보고자 합니다.',
      ],
    }),
    withComponentFormat({
      title: '불면증의 뇌',
      components: [
        {
          text: '불면증은 다양한 원인에 의해 시작될 수 있습니다. 하지만 연구자들은 신기한 점을 하나 발견했습니다. 바로 불면증을 앓고 계신 분들이 수면과 관련된 특정한 생각을 가지고 있다는 점입니다. 우리는 이를 “불면증의 뇌”라고 부릅니다',
        },
        {
          component: <FullImage image={day15_2_2} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '불면증의 뇌에서는 ',
                '특정한 생각 패턴',
                '이 나타납니다. 불면증상이 없었을 때는 하지 않았던 생각이지만, 불면증상이 생기고 나서부터 발생하는 생각들이지요. 이러한 생각들은 걱정을 유발하여 불안함, 초조함을 느끼게 하고, 이것이 다시 잠을 자지 못하도록 합니다. 잠을 자지 못하니 결국 불안함과 초조함이 증폭되고, 결국엔 악순환의 고리에 빠지게 됩니다.',
              ]}
            />
          ),
        },
      ],
    }),
    basicFormat({
      title: '걱정',
      fullImage: day15_2_3,
      height: 190,
      texts: [
        '불면증의 뇌에서 자주 나타나는 생각 패턴들은 ',
        '걱정',
        '을 만듭니다. 그리고 그 걱정은 우리들의 밤을 조금씩 조금씩 뺏어가죠. 그러다 보면 결국 우리들은 밤을 잃어버리고 맙니다. 오늘은 ‘걱정’에 대해 먼저 이야기해 보고자 합니다. 내일은 불면증의 뇌에서 자주 나타나는 생각 패턴에 대해 이야기해 볼 겁니다.',
      ],
    }),
    basicFormat({
      title: '걱정으로 인한 악순환',
      texts: [
        '불면증이 있는 분들이라면 잠자리에 눕자마자 갑자기 정신이 번쩍 들면서 “오늘도 못 자나” 생각했던 경험이 있을 겁니다. 불면증이 있을 때 이러한 생각들을 순식간에 확산되죠. ',
        '모든 생각들이 부정적으로 변하고 부정적인 생각들이 서로 꼬리를 물며 연결',
        '되기도 합니다. 불면증의 뇌 때문이지요. ',
        '잠자리 자체가 걱정을 유발하는 신호가 되었기 때문',
        '입니다. 이처럼 잠자리에서 걱정을 하는 것은 습관이 될 수 있습니다. 그러한 습관이 지속되다 보면 ',
        '뇌가 깨어있고, 불안과 초조함이 증폭되며, 결국 불면증의 악순환 고리에 빠져버리게 되는 것',
        '이지요.',
        '\n',
        '다행인 사실은 이러한 생각과 습관을 인지치료로 충분히 변화시킬 수 있다는 점입니다.',
      ],
    }),
    withComponentFormat({
      title: '잠자리에 들 때 많이 하는 걱정',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘은 가벼운 활동을 해보고자 합니다. 이전에 했던 걱정 덜어두기와 비슷한 활동입니다. 걱정 덜어두기에서는 오늘 할 일과 내일 해야 할 일을 적어보았죠. 기억나시나요? 자신의 하루를 통제하고 있다는 생각을 가지기 위함이었습니다. ',
                '\n',
                '이번에는 할 일이 아니라 ',
                '잠자리에 들 때 가장 많이 하는 걱정',
                '에 대해 생각해 보도록 하죠. 그리고 그러한 ',
                '걱정을 어떻게 해결할지',
                '에 대해 생각해 보겠습니다. ',
                '\n',
                '해결책은 다음과 같은 부분을 참고하여 적어보시면 됩니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['문제를 ', '완전히 해결하는 방법', '을 적어도 좋습니다.'],
                ['큰 문제가 아니라서 ', '그냥 기다리기로 했다면 그것도 충분히 좋은 해결 방법', '입니다.'],
                ['무엇을 해야 할지 모르겠다면, ', '누구와 이것에 대해 상의해 볼지', '를 적어봅니다.'],
                [
                  '정말 문제라고 생각하지만 방법이 없는 일이라면, ',
                  '시간이 지나면서 해결 방법이 나올 수도 있을 것',
                  '이라고 적어봅니다.',
                ],
              ]}
              type="gray"
              my
            />
          ),
        },
        {
          text: '자, 그러면 한번 적어볼까요?',
        },
      ],
    }),
    {
      replaceComponent: (
        <WorrySingleInput
          num={0}
          sum={2}
          title="잠자리에서 가장 많이 하는 걱정이 있나요?"
          placeholder="업무에 관한 것, 친구나 연인과의 관계 등 최근 나를 잠못들게 하는 걱정을 적어보세요."
          isFirstPage={true}
        />
      ),
    },
    {
      replaceComponent: (
        <WorrySingleInput
          num={1}
          sum={2}
          title="어떻게 해결할 수 있을까요?"
          placeholder="구체적인 해결 방법이 있다면 적어보세요. 방법이 없는 일이라 그냥 기다리기로 했다면 그것도 충분히 좋은 해결 방법입니다. 문제 해결을 위해 다른 사람과 상의가 필요하다면 누구와 이야기할지 적어 보아도 좋습니다."
          isFirstPage={true}
        />
      ),
    },
    withComponentFormat({
      title: '걱정과 해결 방법을 적어보았습니다',
      components: [
        {
          component: <UserWorryAndSolution />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '잘 하셨습니다. 이제 걱정으로 잠에 들기 어려울 때마다 해결 방안에 대해서 생각해 보시길 바랍니다. 앞서 살펴보았듯 ',
                '그냥 기다리기로 했다는 것, 혹은 누구와 상의를 해보겠다고 생각한 것도 충분히 해결 방법',
                '이 될 수 있습니다. 당장 해결책이 떠오르지 않더라도 살다 보면 많은 일들이 시간이 흘러감에 따라 저절로 해결되기도 하고, 또 예상치도 못한 곳에서 해결책을 얻을 때도 많으니까요.',
                '\n',
                '새로운 걱정이 떠오를 때마다 위처럼 해결 방안에 대해 생각해 보면 불안함과 초조함이 줄어들 것이고, 수면을 방해하는 걱정도 자연스럽게 없어질 겁니다.',
                '\n',
                '그럼 조금 쉬었다 상상하기를 다시 연습해 볼게요.',
              ]}
            />
          ),
        },
      ],
      submit: 'worrySolution',
    }),
  ],
  [
    {
      replaceComponent: <EmagineCard />,
    },
    {
      title: '이제 눈을 감고 상상하기를 해보세요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '상상하기가 필요한 시점에 까먹지 않도록 반복적으로 알려드릴게요. 잠이 안 오는 날에 분명 도움이 될 겁니다.',
                '\n',
                '오늘도 고생 많으셨습니다. 내일은 불면증의 뇌에서 자주 나타나는 생각 패턴에 대해 알아보도록 하겠습니다. 오늘은 Chopin의 <Nocturne in B flat minor, Op.9 No.1>라는 곡을 추천해 드릴게요. 부디 마음을 다스리는 데에 도움이 되었으면 좋겠네요.',
                '\n',
                '그러면 우리는 내일 또 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  SleepingSurveyWithCas,
  [
    basicFormat({
      title: '불면증의 뇌: 생각 패턴',
      fullImage: day16_2_1,
      texts: [
        '안녕하세요, 지난 밤은 어떠셨나요? 이주 넘게 꾸준히 노력하고 계신 것을 보면 분명 좋은 결과가 있을 겁니다. 어제는 잠자리에서 하는 걱정들과 그에 대한 해결책을 적어보았죠. 오늘은 불면증의 뇌에서 자주 나타나는 생각 패턴에 대해 알아보고자 합니다.',
        '\n',
        '불면증의 뇌에 대해 한 번 더 설명드릴게요. 연구자들은 수많은 사람들을 대상으로 연구해 본 결과, ',
        '불면증을 앓고 있는 사람들에게서 특정한 형태의 생각 패턴',
        '이 있다는 사실을 발견하였습니다. 크게 세 가지 생각 패턴이 있죠. 각각의 생각 패턴들은 독립적으로 작용하기도 하고, 서로 연관되어 작용하기도 합니다. 우리는 그러한 생각 패턴을 모아 ',
        '“불면증의 뇌”',
        '라고 부르기로 했습니다. 불면증의 뇌에서는 ',
        '특정한 생각 패턴이 반복',
        '하여 나타나고, ',
        '그러한 생각들은 걱정을 야기하여 침실을 “걱정하는 공간”으로 만들고, 수면이라는 개념 자체를 부담스러운 과제로 만듭니다.',
        ' 그로 인해 결국엔 우리는 밤을 잃어버리고 말죠. 오늘은 세 가지 생각 패턴에 대해 알아보겠습니다.',
      ],
    }),
    withComponentFormat({
      title: '생각 1. “수면 때문에 큰일났어...”',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '불면증으로 고생하고 있는 많은 분들이 ',
                '낮 시간의 피로감, 능률 저하, 짜증 등이 모두 밤에 잠을 잘 못 잤기 때문이라고 생각',
                '합니다. ',
                '잠만 잘 잔다면 그 모든 것들이 해결되리라 생각',
                '하기도 하죠. 걱정이 많은 분들 중에서는 “이러다가는 일찍 죽고 말 거야”라고 생각하시는 분들도 있답니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day16_2_2} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우리 스스로의 경우를 살펴보죠. 밤에 잠을 뒤척인 다음날에는 항상 피곤했나요? 잠을 못 잔 날은 항상 모든 게 뜻대로 되지 않았나요? 설령 그렇다고 하더라도 그 정도가 늘 일정했나요? 불면이 우리 삶의 질을 매우 떨어트리고 있는 것은 사실입니다. 하지만 ',
                '모든 문제의 원인이 불면인 것은 아닙니다.',
                ' 똑같이 잠을 못 잔 날이더라도 다음날의 생활엔 차이가 있을 수 있죠. 매일 매일을 최상의 컨디션으로 지낼 수는 없는 노릇입니다. “큰일났다”에 해당하는 예시를 몇 가지 들어보죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              type="gray"
              textList={[
                ['잠을 못 자면 다음날 업무에 큰 지장이 생길 거야.'],
                ['이렇게 계속 잠을 못 자면 병이 생길 거야.'],
                ['내가 이렇게 짜증 나고 불안정한 것은 잠을 잘 못 잤기 때문이야.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
    }),
    withComponentFormat({
      title: '생각 2. “이 정도는 돼야...”',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '많은 사람들이 ',
                '수면과 관련하여 비현실적인 기대',
                '를 가지고 있습니다. 누군가는 “최소한 이 정도는 자야” 정상적인 생활이 가능하다고 믿고 낮잠을 자는 등 다양한 노력을 하기도 하고, 또 다른 사람은 자신의 상황을 남들과 비교하기도 합니다. ',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day16_2_3} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 수면시간은 사람마다 차이가 큽니다. 같은 사람이라도 날마다 다르죠. 남과 비교하여, 혹은 ',
                '수면에 대한 비현실적인 기대를 가지고 수면과 관련된 과도한 규칙을 세우는 건 오히려 수면에 대한 집착과 불안함을 증폭시킬 뿐',
                '입니다. “이 정도는 돼야…”에 해당하는 예시를 몇 가지 들어보죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              type="gray"
              textList={[
                ['공부를 제대로 하려면 그래도 8시간은 자야 해.'],
                ['나는 6시간 이하로는 절대 못 자. 그러니 낮잠이라도 잠깐 자서 잠을 보충해야 해.'],
                ['내 친구는 눕자마자 잘 자. 나도 그렇게 되어야 해.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
    }),
    withComponentFormat({
      title: '생각 3. “나는 글렀어...”',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '많은 사람들이 불면과 관련하여서는 ',
                '자신의 의지와 능력을 의심',
                '하곤 합니다. 앞으로도 계속해서 잠을 잘 자지 못하리라 생각하거나, 약을 먹어야만 잠을 잘 수 있을 것이라고 생각하죠. 이러한 ',
                '생각과 걱정으로 인해 결국 수면은 더욱 방해',
                '를 받게 됩니다. 예를 들어보죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              type="gray"
              textList={[
                ['잠자는 건 앞으로 내 의지대로 안 될 것 같아.'],
                ['앞으로 평생 수면제 없이 잠들 수는 없을 거야.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
    }),
    basicFormat({
      title: '불면증의 뇌: 생각 패턴',
      texts: [
        '어떠셨나요? 여러분들도 비슷한 생각을 하고 계시진 않았나요? 아마 불면증으로 고생하셨던 분들이라면, 특히 3개월 이상 오랜 기간 동안 고생하셨던 분들이라면 비슷한 생각들을 하셨을 겁니다. ',
        '\n',
        '오늘은 조금 일찍 끝내려고 합니다. 내일부터 본격적인 활동들을 시작해 볼 계획이거든요. 다음 시간에는 ',
        '어떠한 생각 때문에 우리가 이러한 생각 패턴을 가지게 되었는지, 그 기저에는 어떠한 사고 과정이 작용하고 있었는지',
        '에 대해 알아보고자 합니다. 그리고 앞으로는 그러한 내용을 바탕으로 ',
        '불면증의 뇌를 다시 숙면의 뇌로 전환시키는 과제',
        '를 진행하고자 합니다.',
        '\n',
        '그전에 숫자 빼기를 한번 더 가르쳐 드릴게요.',
      ],
    }),
  ],
  [
    {
      replaceComponent: <CountNumberCard />,
    },
    {
      title: '이제 눈을 감고 숫자 빼기를 해보세요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '계산이 정확하지 않아도 됩니다. 정답이 있는 것은 아니에요. 떠올린 숫자부터 천천히 7을 빼 나가다 보면 분명 잠이 올 겁니다.',
                '\n',
                '그럼 우리는 내일 또 뵙죠.',
                '\n',
                '오늘도 편안한 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  SleepingSurveyWithCas,
  [
    withComponentFormat({
      title: '불면증의 뇌: 생각 함정 1',
      fullImage: day17_2_1,
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '어서 오세요. 지난 밤도 잘 주무셨나요? 저는 오랜만에 조금 뒤척였답니다. 밤사이에 비가 얼마나 많이 오던지 빗소리를 듣다 보니 이런저런 생각이 많이 들어 잠을 잘 수가 없었어요. 잠자리에 누운지 15분이 지나도 잠이 오지 않아 서재로 가서 책을 읽었답니다. 덕분에 지난 밤에는 네 시간밖에 못 잤지만, 그래도 괜찮아요. 하루 잠을 조금 못 잔다고 큰일이 생기는 건 아니니까요. 생각보다 컨디션도 나쁘지 않고요.',
                '\n',
                '어제는 ',
                '불면증에서 흔히 나타나는 세 가지 생각 패턴',
                '에 대해 알아보았습니다. 복습을 해 볼까요?',
              ]}
            />
          ),
        },
        {
          component: (
            <TextWithGradientCircle
              texts={[
                {
                  title: '수면 때문에 큰일 났어...',
                  text: '모든 안 좋은 일의 원인을 불면 탓으로 돌리고, 불면 때문에 큰일이 났다고 생각하는 것',
                },
                {
                  title: '이 정도는 돼야...',
                  text: '수면에 대한 비현실적인 기대를 가지고 그 기대에 부합하지 않으면 초조해하거나 좌절하는 것',
                },
                {
                  title: '나는 글렀어...',
                  text: '수면과 관련하여 자신의 의지와 능력을 의심하고 패배감에 빠지는 것',
                },
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
    }),
    basicFormat({
      title: '생각 함정',
      texts: [
        '그런데 불면증을 앓고 계신 분들에게는 왜 이런 생각이 나타나는 것일까요? 연구자들은 그 기저에 깔려있는 사고 과정을 분석해 보습니다. 그리곤 ',
        '크게 일곱 가지의 무의식적인 사고방식',
        '이 있다는 것을 알게 되었죠. 우리는 이를 ',
        '불면증의 “생각 함정”',
        '라고 부를 겁니다. 나도 모르게 부정적인 생각 함정에 빠지게 되는 것이지요.',
        '\n',
        '오늘은 네 가지 생각 함정에 대해 먼저 알아보고자 합니다. 하나씩 살펴보죠.',
      ],
    }),
    withComponentFormat({
      title: '1. 일반화',
      image: wb0_5_4,
      bg: '#EFF2FF',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '한 가지 사건을 가지고 자신 혹은 상황 전체를 평가해버리는 것',
                '을 “일반화”라고 합니다. 흔히 잠을 잘 못 자거나 노력을 했음에도 수면의 양과 질 측면에서 개선이 없어 실망감을 느낄 때 이런 생각을 할 때가 많습니다. 일이 잘 안 풀려서 발생한 감정이 이성적인 사고를 지배해버리는 것이지요. 예를 들어보겠습니다.',
                '\n',
                '수면제를 먹고 있는 케이티는 매일 자기 전 알약을 찾는 자신을 바라보며 문득 이런 생각을 합니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail textList={[['앞으로는 평생 수면제 없이는 살 수 없을 거야.']]} type="gray" my />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '지금 수면제에 의존하고 있다면 짧은 기간 내에 수면제를 완전히 끊기는 어려운 건 사실입니다. 하지만 ',
                '그렇다고 평생 수면제를 먹어야 하는 것은 아닙니다.',
                ' 케이티는 지금 수면제를 먹는다는 사실만을 가지고 앞으로 상황 전체를 평가하고 있죠.',
                '\n',
                '한 가지 예시를 더 살펴볼까요?',
                '\n',
                '지난 밤도 뒤척이며 잠을 제대로 못 잔 팀은 아침에 일어나서 이런 생각을 합니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail textList={[['지난 밤도 잘 못 잤네… 이번 주도 내내 이런 식이겠지.']]} type="gray" my />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '지난 밤에 잠을 잘 못 자 피곤한 것은 사실입니다. 그렇다고 이번 주 전체가 그렇다는 보장은 없습니다. 겨우 하루 잠을 못 잤을 뿐이죠. 하지만 팀은 ',
                '지난 밤에 뒤척였다는 사실에 몰두되어 앞으로의 상황을 부정적으로만 바라보고 있습니다.',
                ' 그리고 이런 생각이 든 이상, ',
                '이번 주의 잠자리도 편할 리가 없죠.',
              ]}
            />
          ),
        },
      ],
    }),
    withComponentFormat({
      title: '2. 경험과 다른 결론',
      image: wb0_5_7,
      bg: '#EFF2FF',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '과거의 경험에 비추어보면 그렇지 않음에도 순간적인 감정에 압도되어 그간의 경험과는 다르게 생각하는 것',
                '을 “경험과 다른 결론”이라고 합니다. 흔히 잠에 대한 걱정이 너무 커져서 그간 경험과는 달리 비이성적인 사고를 하게 되는 상황에서 발생하죠. 예를 들어볼까요?',
                '\n',
                '밤새 뒤척인 왓슨은 잠자리에서 나오며 다음과 같이 생각했었습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail textList={[['오늘 하루도 모든 일을 제대로 할 수 없을 거야.']]} type="gray" my />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '왓슨은 제법 오랜 기간 불면증으로 고생하고 있었습니다. ',
                '그렇다고 그 기간 동안 모든 일을 제대로 하지 못했던 건 아니죠.',
                ' 업무에 지장이 있었던 날도 있었지만, ',
                '생각보다 해야 할 일들을 무난히 끝낸 날도 정말 많았습니다.',
                ' 하지만 왓슨은 지난 밤도 잘 자지 못했다는 실망감에 벌써부터 깊은 패배감에 빠져버렸죠. 그리고 이러한 패배감을 느낀 왓슨은 ',
                '“오늘 밤에는 더 잘 자야 한다”라는 부담감',
                '에 휩싸여 버리게 됩니다.',
              ]}
            />
          ),
        },
      ],
    }),
    withComponentFormat({
      title: '3. 최악의 시나리오 떠올리기',
      image: wb0_6_6,
      bg: '#EFF2FF',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '수면과 관련하여 걱정할 때 가장 좋지 않은 경우만 떠올리는 것',
                '을 “최악의 시나리오 떠올리기”라고 합니다. 주로 불면증 때문에 좌절감을 느낄 때, 혹은 과도한 걱정과 불안이 마음을 휩쓸 때 발생하죠. 예를 들어볼게요.',
                '\n',
                '불면증 때문에 고생하고 있는 제시는 잠자리에 누워 다음과 같이 생각합니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '또 잠이 안 오네. 단 30분도 못 자고 출근하게 되면 어쩌지? 매번 이렇게 살다가는 회사에서도 쫓겨나고, 몸도 안 좋아져서 일찍 죽게 될 거야.',
                ],
              ]}
              type="gray"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '제시가 불면증으로 고생하고 있지만 단 30분도 못 잔 적은 없었습니다. 가끔 회의 시간에 졸긴 했지만 회사에서 쫓겨날 정도도 아니죠. 몸이 피곤하긴 하지만 그것 때문에 생명에 지장이 있을 정도는 아니죠. 제시는 걱정이 커져서 상상할 수 있는 가장 안 좋은 경우만 떠올리고 있습니다. ',
                '최악의 시나리오를 떠올리는 것은 수면에 대한 걱정을 과도하게 증폭',
                '시킬 뿐입니다.',
              ]}
            />
          ),
        },
      ],
    }),
    withComponentFormat({
      title: '4. 당위 부여',
      image: wb0_6_4,
      bg: '#EFF2FF',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '흔히 ',
                '남들과 비교를 하거나, 혹은 수면에 대한 잘못된 지식으로 인해 반드시 지켜야 하는 규칙들에 집착하는 것',
                '을 “당위 부여”라고 합니다. 흔히 ',
                '“~해야 한다”라는 형태',
                '로 나타나곤 하죠. 예를 들어볼까요?',
                '\n',
                '불면증으로 고생하고 있는 제임스는 다음과 같은 생각을 가지고 있습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['아내는 눕자마자 바로 잠들던데 저게 정상이야. 나도 저렇게 되어야 해.'],
                ['어디선가 듣기로는 정상적인 생활을 할 수 있으려면 최소한 6시간 이상은 무조건 자야 한다던데…'],
              ]}
              type="gray"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '당위 부여를 하여 만든 규칙들이 지켜지지 않을 때마다 제임스는 더 불안해지고 초조해집니다.\n\n',
                '남이 그렇다고 해서 내가 그렇게 되어야 하는 것은 아닙니다.',
                ' 누구나 자신만의 페이스가 있지요. 뿐만 아니라 최소한 6시간 자야 정상적인 생활을 할 수 있는 건 아닙니다. 과거를 떠올려보세요. 대학생 때는 밤을 새고 시험을 쳤던 경우도 많을 겁니다. 아주 바쁜 업무가 있을 때에는 5시간만 자고 일어나서 회사에 출근해 업무를 수행했던 적도 있었죠. 제임스는 ',
                '남과 비교를 하며, 잘못된 지식을 토대로 스스로에게 엄격한 규칙을 부여',
                '하고 있습니다. ',
                '이러한 규칙들은 수면에 대한 걱정과 강박을 증가시킬 뿐',
                '입니다.',
              ]}
            />
          ),
        },
      ],
    }),
    basicFormat({
      title: '퀴즈를 풀어볼까요?',
      texts: [
        '어렵지 않았죠? 자, 그러면 복습을 한번 해 볼까요? 제가 간단한 사례를 보여드릴 테니 어떠한 생각 함정이 작용하고 있는지 살펴보세요. 앞으로는 우리 스스로에게도 이러한 분석을 진행할 계획이니 잘 생각하면서 풀어보도록 해요.',
      ],
    }),
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          buttonList={['일반화', '경험과 다른 결론', '최악의 시나리오 떠올리기', '당위 부여']}
          contents={[
            {
              component: (
                <div>
                  <p>불면증을 위해 알약을 먹던 크리스는 수많은 약 봉투를 보고 문득 다음과 같이 생각합니다..</p>
                  <MessageBlockWithTail
                    textList={[['나는 앞으로 평생 약을 끊을 수 없을 거야.']]}
                    type={'white'}
                    margin="my-[30px]"
                    isQuiz
                  />
                  <p>이런 생각이 들자 문득 지금 하고 있는 치료도 다 부질없어 보였죠.</p>
                </div>
              ),
            },
          ]}
          answer="일반화"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="지금 그렇다고 앞으로 그런 건 아니랍니다"
          answerInfo="크리스는 현재 불면증 약을 먹고 있기 때문에 앞으로도 평생 약을 먹을 수밖에 없을 거라고 생각하고 좌절감을 느끼고 있어요. 이렇게 일반화를 하다 보면 좌절감은 더욱 커지고 인지행동치료를 효과적으로 진행할 수 없게 된답니다."
          buttonText="다음"
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          buttonList={['일반화', '경험과 다른 결론', '최악의 시나리오 떠올리기', '당위 부여']}
          contents={[
            {
              component: (
                <div>
                  <p>
                    중요한 발표를 앞둔 빌리는 어제 불면증 때문에 3시간 밖에 못 잤어요. 그리고 아침에 일어나 다음과 같이
                    생각했죠.
                  </p>
                  <MessageBlockWithTail
                    textList={[['잠을 못 잤으니 오늘 발표도 잘 할 수 없겠지.']]}
                    type={'white'}
                    margin="my-[30px]"
                    isQuiz
                  />
                  <p>
                    사실 빌리는 지난 3개월 동안 매번 제대로 잔 적이 없었답니다. 하지만 업무에 치명적인 지장이 있는 건
                    아니었어요.
                  </p>
                </div>
              ),
            },
          ]}
          answer="경험과 다른 결론"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="과거를 떠올려보면 그렇지 않았던 적이 많답니다."
          answerInfo="빌리는 지난 3개월 동안 불면증으로 고생했어요. 힘든 시간이긴 했지만 그렇다고 업무에 치명적인 지장이 있는 건 아니었죠. 하지만 빌리는 중요한 발표를 앞둔 지금, 오늘도 3시간 밖에 못 잤다는 생각에 이전 경험과는 다르게 발표를 잘 할 수 없을 것이라고 생각하고 있어요. 이렇게 생각한 이상 발표는 잘 될 리 없고, 발표마저 망치게 되면 수면에 대한 부담감은 더욱 커지게 된답니다."
          buttonText="다음"
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          buttonList={['일반화', '경험과 다른 결론', '최악의 시나리오 떠올리기', '당위 부여']}
          contents={[
            {
              component: (
                <div>
                  <p>
                    불면증으로 고생하던 알렉스는 유튜브에서 “사람이 정상적인 생활을 하기 위해서는 적어도 6시간은 자야
                    한다”라는 정보를 접했어요.
                    <br />
                    <br />그 후 알렉스는 어떻게든 6시간 이상 자려고 노력했죠. 설령 낮잠을 통해 그 시간을 보충하더라더요.
                  </p>
                </div>
              ),
            },
          ]}
          answer="당위 부여"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="과도한 규칙을 세우면 오히려 부담감만 커지기 마련입니다."
          answerInfo="빌리는 유튜브에서 잘못된 정보를 접하고 그게 사실인 것처럼 믿고 있죠. 그리곤 무조건 6시간 이상은 자야 한다는 규칙을 세우고 말았어요. 그렇게 되면 수면에 대한 강박과 불안감은 더욱 커지기 마련이랍니다. 잠자리에 일찍 눕기도 하고, 낮잠을 자기도 하고, 새벽에 눈을 떴을 때 ‘지금부터 쭉 자도 6시간을 못 채운다’는 생각이 들 땐 좌절감을 느끼기도 하죠."
          buttonText="다음"
        />
      ),
    },
    basicFormat({
      title: '고생 많았습니다.',
      texts: [
        '어땠나요? 어렵지 않죠? 혹시 나도 모르게 그러한 생각을 하면서 지내진 않았나요? 이러한 생각 함정들은 수면에 대한 걱정과 강박을 증폭시켜 수면을 방해하게 됩니다.',
        '\n',
        '오늘은 네 가지 생각함정에 대해 먼저 알아보았습니다. 내일은 나머지 세 가지 생각함정에 대해 알아보도록 하겠습니다. 그 이후에는 우리들이 그러한 생각을 하면서 지내진 않았는지 기록하고 분석해보도록 하죠.',
        '\n',
        '아 침대에 들어가기 전 긴장 풀기를 한번 해볼게요. 그럼 있다가 뵈어요.',
      ],
    }),
  ],
  [
    basicFormat({
      title: '긴장 풀기를 진행 할게요',
      fullImage: day14_2_1,
      texts: [
        '편안하고 조용한 곳에서 눈을 감고, 오디오가 안내하는 대로 따라서 진행합니다. \n오디오를 끝까지 들어야 활동을 마칠 수 있으니 충분히 준비된 상태에서 시작해 주세요.',
      ],
    }),
    {
      replaceComponent: <AudioPlayer />,
    },
    {
      title: '오늘도 수고하셨어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '몸의 긴장이 좀 풀어졌나요?',
                '\n',
                '오늘은 Bach의 <Goldberg Variations, BWV 988 - Aria>라는 곡을 추천해드릴게요. 조용한 피아노 선율이 마음에 안정감을 줄 수 있기를 바랄게요.',
                '\n',
                '자, 그러면 우리는 내일 또 만나죠. \n오늘도 편안한 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  SleepingSurveyWithCas,
  [
    basicFormat({
      title: '불면증의 뇌: 생각 함정 2',
      fullImage: day18_2_1,
      texts: [
        '어서 오세요. 지난 밤도 잘 주무셨을까요? 저는 오랜만에 푹 잤습니다. 분명 그 전 날은 잠을 잘 못 자서 고생했는데 오늘은 잘 잤네요. 역시 세상사 모든 일들은 좋을 때도 있고, 안 좋을 때도 있기 마련인가 봅니다. 수면도 마찬가지겠지요. ',
        '\n',
        '어제는 불면증의 뇌를 만드는 일곱 가지 생각 함정 중 네 가지 생각 함정에 대해 알아보았습니다. 오늘은 나머지 세 가지 생각 함정에 대해 배워보도록 하겠습니다.',
        '\n',
        '자, 그러면 하나씩 알아볼까요?',
      ],
    }),
    withComponentFormat({
      title: '5. 성급한 결론',
      image: wb0_6_2,
      bg: '#EFF2FF',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '성급한 결론은 ',
                '아직 충분한 근거가 없는 상황에서 상황을 해석해 버리는 것',
                '을 뜻합니다. 흔히 잠이 오지 않을 때 불안한 마음에 성급한 결론을 많이 내리곤 하죠. 예시를 하나 살펴볼까요?',
                '\n',
                '불면증을 앓고 있는 브루스는 침대에 누운 지 10분이 지나도 잠이 오지 않아 다음과 같이 생각했어요.',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithTail textList={[['오늘 밤도 잘 자긴 글렀구나...']]} type="gray" my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이러한 생각이 들자마자 브루스는 불안감을 느꼈습니다. 그후로는 정말로 밤새 걱정만 하다가 잠을 제대로 자지 못했죠.',
                '\n',
                '10분이 지나도 잠이 오지 않는다고, 설령 15분이 지나도 잠이 오지 않아 침대 밖으로 나가 다른 활동을 한다고 하더라도 그날 수면이 엉망으로 되는 것은 아닙니다. ',
                '곧 또 졸려서 잠들지도 모르는 일이죠. 한 번에 자는 날도 있고, 그렇지 않은 날도 있습니다.',
                ' 하지만 브루스는 10분이 지나도 잠이 오지 않아 오늘 하루도 글렀다고 성급하게 결론을 내려버렸죠. ',
                '이미 그렇게 결론을 내린 이상 잘 잘리는 없습니다. 이미 불안감이 그의 마음을 휩쓸었으니까요.',
              ]}
            />
          ),
        },
      ],
    }),
    withComponentFormat({
      title: '6. 좁은 시야',
      image: wb0_5_3,
      bg: '#EFF2FF',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '모든 일에는 긍정적인 면도 있고 부정적인 면도 있는 법입니다. 하지만 ',
                '긍정적인 면은 보지 못한 채, 부정적인 면에만 집중하여 상황을 판단하는 것',
                '을 좁은 시야라고 부릅니다. 흔히 불면으로 인해 안 좋은 일이 발생했을 때 그 일에만 집중하여 불면으로 인한 영향을 과대해석할 때 발생하죠. 예시를 살펴볼까요?',
                '\n',
                '지난 밤 잠을 2시간 밖에 못 잔 토마스는 점심을 먹고 난 후 업무를 하다가 잠깐 졸았어요. 그 모습을 지켜본 상사 피터가 토마스에게 “많이 피곤하면 산책이라도 하고 와”라고 말했어요. 토마스는 자신이 직장에서 졸았다는 사실이 너무 부끄러웠죠. 그리고 저녁이 다가오자 다음과 같이 생각했어요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['오늘 하루는 불면증 때문에 망해버렸어. 잘 된 일이 하나도 없었지. 오늘은 잘 자야 할 텐데…']]}
              type="gray"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '사실 토마스는 그날 하루 잠깐 졸았던 것 외에는 그다지 못한 일이 없었어요. 마감일에 맞춰서 업무 전달도 잘 했고, 출근 시간을 어긴 것도 아니었죠. 하지만 토마스는 ',
                '잘 해낸 일은 생각하지 못하고 본인의 실수에만 집중',
                '했죠. 그러자 ',
                '잠에 대한 강박이 더 심해졌죠. 오늘도 못 자면 내일 하루도 망해버릴 것이라고 생각',
                '하면서요.',
              ]}
            />
          ),
        },
      ],
    }),
    withComponentFormat({
      title: '7. 흑백 논리',
      image: wb0_5_2,
      bg: '#EFF2FF',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '흑백논리는 ',
                '극단적인 두 가지 선택지 사이에서 모 아니면 도라는 논리로 상황을 접근하는 것',
                '을 뜻합니다. 수면과 관련해서는 조금 못 잤을 때 “오늘도 잘 못 잤다”라고 결론 내려버리는 것이지요. 한 번도 깨지 않고 깊은 잠을 잤을 때만 그제서야 잘 잔 것이고 그 이외의 수면은 모두 “못 잤다” 혹은 “역시나 불면의 밤이었다”라고 결론 짓는 것을 뜻합니다.',
                '\n',
                '예시를 들어보죠. 수잔은 불면증 인지행동치료를 진행하고 있습니다. 생각보다 효과가 있어서 큰 고생없이 잔 날도 있지만, 역시나 인지행동치료는 단기간 내에 완성되는 것은 아니기 때문에 잘 못 잔 날도 있죠. 하지만 치료를 진행하다가 조금이라도 뒤척인 날이면 수잔은 다음과 같이 생각합니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail textList={[['지난 밤은 실패네… 치료가 효과가 없는 걸까?']]} type="gray" my />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '성공 또는 실패만 있는 것은 아닙니다. 조금 뒤척인 날도 있고, 점점 나아지는 순간일 수도 있죠. ',
                '불면증을 앓고 계신 분들은 종종 수면에 대한 기대를 처음부터 너무 높게 잡기도 합니다.',
                ' 초조한 마음 때문이지요. 그렇기 때문에 ',
                '조금이라도 수면에 방해를 받은 날에는 “실패” 또는 “불면증으로 고생한 날”로 규정',
                ' 지어버럽니다. 그리고 그러한 행동은 ',
                '수면에 대한 걱정과 불안감을 더 증폭',
                '시키죠.',
              ]}
            />
          ),
        },
      ],
    }),
    basicFormat({
      title: '퀴즈를 풀어볼까요?',
      texts: [
        '어렵지 않았죠? 자, 그러면 복습을 한번 해 볼까요? 제가 간단한 사례를 보여드릴 테니 어떠한 생각 함정이 작용하고 있는지 살펴보세요. 앞으로는 우리 스스로에게도 이러한 분석을 진행할 계획이니 잘 생각하면서 풀어보도록 해요.',
      ],
    }),
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          buttonList={['성급한 결론', '좁은 시야', '흑백 논리', '당위 부여']}
          contents={[
            {
              component: (
                <div>
                  <p>
                    안나는 잠자리에 누웠는데 정신이 말똥말똥해요. 무언가 머리가 환해지는 느낌이 드는 게 예감이 좋지
                    않았죠. 그리곤 생각했어요.
                    <MessageBlockWithTail
                      type="white"
                      isQuiz
                      textList={[['하... 오늘도 망했네. 대체 왜 이러는 거지? 잠을 잘 수가 없어.']]}
                      margin="my-[30px]"
                    />
                  </p>
                  <p>이런 생각이 들자 안나는 초조해지기 시작했어요. 그리고 당연히, 밤새 뒤척였답니다.</p>
                </div>
              ),
            },
          ]}
          answer="성급한 결론"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="밤이 얼마나 긴데 눕자마자 결론을 내리는 걸까요?"
          answerInfo="안나는 아직 누운지 얼마되지도 않았는데 지난번과 비슷한 느낌이라는 이유로 “오늘 수면도 망했다”라고 생각하고 있습니다. 그렇게 생각하는 이상 잠이 잘 올리가 없죠. "
          buttonText="다음"
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          buttonList={['성급한 결론', '좁은 시야', '흑백 논리', '당위 부여']}
          contents={[
            {
              component: (
                <div>
                  <p>
                    케이티는 불면증 치료를 받고 있어요. 기대했던 것과는 달리 모든 날을 편안히 자는 건 아니지만 그래도
                    이전과 다르게 크게 힘들이지 않고 잠드는 날도 있답니다. 하지만 치료 과정을 돌이켜보며 케이티는 다음과
                    같이 생각해요.
                    <MessageBlockWithTail
                      type="white"
                      isQuiz
                      textList={[['이번주에도 며칠씩이나 못 잤네. 답이 없는 걸까...']]}
                      margin="my-[30px]"
                    />
                  </p>
                  <p>사실 지난 일주일 중 4일은 잘 자고, 나머지 3일은 조금 뒤척였던 케이티였답니다.</p>
                </div>
              ),
            },
          ]}
          answer="좁은 시야"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="부정적인 면에만 집중하면 안 돼요"
          answerInfo='케이티는 불면증 치료로 조금씩 상황을 개선시켜나가고 있습니다. 지난 일주일 중에서는 무려 4일은 잘 잤었죠. 하지만 케이티는 여전히 잘 못 잔 3일에만 집중하며 “답이 없다"라고 생각하고 있어요. 긍정적인 면을 보지 못하고 부정적인 면에만 집중하는 생각 함정을 좁은 시야라고 한답니다.'
          buttonText="다음"
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          buttonList={['성급한 결론', '좁은 시야', '흑백 논리', '당위 부여']}
          contents={[
            {
              component: (
                <div>
                  <p>
                    사실 케이티가 앞과 같이 생각한 데에는 이유가 있어요.
                    <br />
                    <br />
                    케이티는 정말 말끔하게 푹 자고 일어난 날이 아니면 모두 “불면증으로 고생한 날”로 규정짓기 때문이에요.
                    케이티에게 “그럭저럭 무난했던 날”이라는 개념은 없습니다.
                    <br />
                    <br />
                    수면에 대한 강박과 집착 때문에 기대했던 것처럼 푹 잔 게 아니면 모두 “못 잔 날”인 것이지요. 그래서
                    케이티에게는 사실 지난 일주일이 모두 “못 잔 날”이었어요.
                  </p>
                </div>
              ),
            },
          ]}
          answer="흑백 논리"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="모 아니면 도, 비이성적이지 않나요?"
          answerInfo="그럭저럭 무난했던 날도 있기 마련입니다. 세상사 모든 일들이 최고 혹은 최악으로 분류되는 게 아니죠. 사실 케이티뿐만 아니라 불면증을 앓고 있는 많은 분들이 조금만이라도 뒤척이면 “실패”로 생각하곤 한답니다. 그러한 생각은 수면에 대한 과도한 집착을 더욱 증폭시킵니다. 이처럼 “모 아니면 도”와 같이 판단하는 생각 함정, 바로 흑백 논리랍니다."
          buttonText="다음"
        />
      ),
    },
    withComponentFormat({
      title: '배운 내용들을 한번 요약해 볼까요?',
      components: [
        { text: '수면과 관련된 일곱 가지 생각 함정들을 복습해 볼게요.' },
        { component: <AccordianContent /> },
      ],
    }),
    basicFormat({
      title: '어렵지 않죠?',
      texts: [
        '어때요, 별로 어렵지 않죠? 좋습니다. 내일은 우리가 이러한 생각 함정에 빠져있지 않았는지 살펴볼 거랍니다. 그리고 주기적으로 이를 점검하여 불면증의 뇌에서 빠져나올 수 있도록 도와드릴 거예요.',
        '\n',
        '우선 오늘은 여기서 마무리하도록 하죠. 한 번에 너무 많은 것들을 시작하면 힘드니까요. 오늘은 생각 함정을 잘 익힌 것만으로도 충분합니다. ',
        '\n',
        '있다가 걱정 덜어두기를 하는 시점에 다시 뵐게요.',
      ],
    }),
  ],
  [
    basicFormat({
      title: '걱정 덜어두기',
      fullImage: day12_2_1,
      texts: [
        '고민과 걱정은 침대로 가지고 오지 않아야 숙면에 도움이 됩니다. 잠에 들기 4~5시간 전 오늘과 내일에 대한 일들을 간단히 작성해 보세요.',
        '\n',
        '물론 이 작업은 침실 밖에서 하셔야 하는 것도 잊지 않으셨겠죠? 그럼 충분히 준비가 되었을 때 진행해 보세요.',
      ],
    }),
    {
      replaceComponent: <WorryNote />,
    },
    {
      title: '수고하셨어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '걱정을 덜어두었으니 맘 편히 잠들 수 있을겁니다.',
                '\n',
                '자, 그러면 우리는 내일 만날까요? 오늘은 Beethoven의 <Piano Sonata No.8 in C minor ‘Pathetique’, Op.13 II. Adagio cantabile>을 들어보세요. 아마 알고 계신 노래일지도 모르겠네요.',
                '\n',
                '그럼 편안한 밤 보내시길 바랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
      submit: 'sleepingWorry',
    },
  ],
  SleepingSurveyWithCas,
  [
    basicFormat({
      title: '불면증의 뇌: 생각 노트',
      fullImage: day19_2_1,
      texts: [
        '어서 오세요. 지난 밤은 잘 보내셨나요? 저는 잘 잤답니다. 매우 포근하고 깊은 잠을 잤죠. 혹시 방금 제가 한 이야기를 듣고 “나도 한번도 깨지 않고 포근하고 깊은 잠을 자야 하는데…”라고 생각했나요? 그렇다면 당위 부여 생각 함정에 해당합니다. 사실 저도 그리 푹 잔 건 아니랍니다. 지난 시간에 배웠던 걸 한 번 더 상기시키기 위해 그랬어요.',
      ],
    }),
    withComponentFormat({
      title: '불면증의 뇌 벗어나기',
      components: [
        {
          component: (
            <TextWithBold
              texts={[
                '자, 이제 오늘부터는 불면증의 뇌 벗어나기를 시작해 볼겁니다. 앞으로는 ',
                '제가 3일에 한 번씩 총 4회에 걸쳐서 불면에서 비롯된 상황, 감정, 생각을 적어보라',
                '고 할 거예요. 그리곤 곧이어 혹시나 그러한 생각에 “불면증의 뇌”에서 자주 나타나는 “생각 함정”이 없는지 살펴볼 거랍니다.',
              ]}
            />
          ),
        },
        {
          component: <FullImage image={day19_2_2} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '방법은 간단합니다. 우선은 불면증과 관련된 사건 중 나의 심기를 건드린 일들을 적어봅니다. 그리고 그때 어떠한 감정을 느꼈는지 기록해 보세요. 이후에는 그러한 감정을 느꼈을 때 어떠한 생각을 했는지 적어본 후, 그러한 생각에 생각 함정이 있진 않는지, 그리고 다르게 생각할 순 없는지 적어보도록 하죠.',
                '\n',
                '다른 분들이 썼던 예시를 보여드릴게요.',
              ]}
            />
          ),
        },
      ],
    }),
    withComponentFormat({
      title: '불면증의 뇌: 생각 노트',
      components: [
        {
          component: (
            <div
              className={`w-full relative overflow-hidden rounded-2xl flex align-center`}
              style={{ backgroundColor: '#F3F5F8', height: '632px', border: '1px solid #0000000D' }}
            >
              <div
                className={`w-[300px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`}
                style={{ height: '632px' }}
              >
                <img className="w-full h-full object-fit" src={day19_2_5} alt="empty" />
              </div>
            </div>
          ),
        },
      ],
    }),
    withComponentFormat({
      title: '불면증의 뇌: 생각 노트',
      components: [
        {
          component: (
            <div
              className={`w-full relative overflow-hidden rounded-2xl flex align-center`}
              style={{ backgroundColor: '#F3F5F8', height: '666px', border: '1px solid #0000000D' }}
            >
              <div
                className={`w-[300px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`}
                style={{ height: '666px' }}
              >
                <img className="w-full h-full object-fit" src={day19_2_4} alt="empty" />
              </div>
            </div>
          ),
        },
      ],
    }),
    basicFormat({
      title: '불면증에 대해 통제감 가지기',
      texts: [
        '어떤가요? 이러한 일이 별거 아닌 것 같아 보이지만 사실 생각 노트를 반복하여 작성하다 보면 잠자리에 누워서 걱정과 불안에 압도되는 상황을 끊어낼 수 있답니다. 잠을 조금 못 자더라도, 잠이 오지 않더라도 그다지 불안해 하지 않고 침착하게 대응할 수 있죠. “오늘은 그런 날인가보다… 어쩔 수 없지”하고 말이에요. 그리곤 다음과 같이 생각하겠죠. “내일 좀 피곤하긴 하겠지만 오늘 좀 못 잔다고 큰일이 발생하는 건 아닐거야. 잠이 안 오면 나가서 책이나 좀 읽자. 마침 독서도 좀 하고 싶었는데 잘 됐다고 생각해야지.”',
        '\n',
        '이처럼 ',
        '불면증에 대해 통제감을 가지는 건 중요한 일',
        '입니다. 걱정과 불안이 우리를 압도하여 침대에 눕자마자 머리가 환해지는 현상이 불면증이 발생하고 유지되는 핵심적인 동력이거든요. 생각 노트는 불면증에 대한 통제감을 가질 수 있도록 해 주어 걱정과 불안이 우리를 압도하는 상황을 예방해 준답니다.',
      ],
    }),
    basicFormat({
      title: '생각 노트 시작하기',
      image: day19_2_3,
      bg: '#2C3640',
      texts: [
        '자, 그러면 오늘부터 불면증에 대한 생각을 정리할 수 있도록 “생각 노트”를 드릴게요. 3일에 한 번씩 드릴 예정이니, 지난 3일 동안 내 생각들을 떠올려보며 요약해 보세요.',
        '\n',
        '쓸 말이 없을 것 같아도 곰곰이 생각해 보면 분명 나를 불안하고 초조하게 만드는 순간이 있을 거예요. 불면증을 앓고 계신 분들 대부분이 그랬거든요. 그러니 잘 이용해 보시길 바랍니다. ',
        '\n',
        '적어둔 생각 노트는 불면으로 인한 불안이 찾아올 때마다 다시 열어 볼 수 있도록 수면 리포트에 넣어드릴게요. 잠이 오지 않을 때 참고하시면 분명 도움이 되실 겁니다.\n 그럼 다음 활동에서 바로 생각 노트를 적어보도록 하죠. 조금 있다 뵐게요.',
      ],
    }),
  ],
  ThinkingNote,
  [
    basicFormat({
      title: '긴장 풀기를 진행 할게요',
      fullImage: day14_2_1,
      texts: [
        '편안하고 조용한 곳에서 눈을 감고, 오디오가 안내하는 대로 따라서 진행합니다. \n오디오를 끝까지 들어야 활동을 마칠 수 있으니 충분히 준비된 상태에서 시작해 주세요.',
      ],
    }),
    {
      replaceComponent: <AudioPlayer />,
    },
    {
      title: '오늘도 수고하셨어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '내일부터는 수면과 관련된 다양한 이야기를 들려드릴 거예요. 물론 제가 알려드렸던 것들을 꾸준히 할 수 있도록 과제도 드릴 거고요. 오늘은 Beethoven의 <Piano Sonata No.5 in C Minor Op.10 II. Adagio molto>를 들어보세요. 피아노 선율이 마음을 편안하게 할 수 있도록 도와줄 거예요.',
                '\n',
                '자, 그러면 오늘도 편안한 밤 보내세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  // day 20
  SleepingSurveyWithCas,
  [
    basicFormat({
      title: '정리하기',
      fullImage: day20_2_1,
      texts: [
        '어서오세요. 지난 밤은 잘 보내셨나요? 여기까지 오시느라 고생 많았습니다. 이제 우리는 숙면을 위해 배워야 할 것들을 모두 다 배웠습니다. 지금부터 28일까지는 배웠던 것들을 잘 실천할 수 있도록 매일 과제를 전달해드릴 거랍니다.',
        '\n',
        '원래는 수면과 밤에 대한 재미있는 이야기들을 전달해드리려고 했어요. 하지만 저도 이야기를 정리하는 데에 시간이 조금 필요하더라고요. 조금만 기다려주시면 제가 재미난 이야기들을 가지고 올게요.',
        '\n',
        '그나저나 과제를 반복하기에 앞서 지난 코스는 어땠나요? 혹시나 개선할 부분이나 추가되었으면 하는 것들이 있을까요? 알려주시면 제가 반영하여 코스를 조금 더 수정해 보도록 할게요.',
      ],
    }),
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={1}
          title="코스에 대한 피드백을 남겨주세요"
          placeholder="개선할 부분을 알려주시면 반영하여 코스를 더 멋지게 만들어볼게요."
        />
      ),
    },
    basicFormat({
      title: '고마워요',
      image: day20_2_2,
      bg: '#121E2F',
      texts: [
        '의견 고마워요. 의견을 반영하여 제가 빨리 코스를 더 효과적으로 만들어보도록 하죠. 자, 그러면 지금부터 28일까지는 과제를 드릴 거랍니다. 이미 배웠던 것들이니 혼자서도 잘 하실 수 있을 거예요. 혼자서 하기 힘들다면 제가 이전에 알려드렸던 내용들을 다시 열여보셔도 된답니다.',
        '\n',
        '그러면 다음 일주일도 좋은 밤 보내세요.',
      ],
      submit: 'courseFeedback',
    }),
  ],
  ImagineContent,
  SleepingSurveyWithCas,
  AudioPlayerContent,
  SleepingSurveyWithCas,
  CountNumberContent,
  ThinkingNote,
  SleepingSurveyWithCas,
  AudioPlayerContent,
  SleepingSurveyWithCas,
  SleepingSurveyWithCas,
  AudioPlayerContent,
  ThinkingNote,
  SleepingSurveyWithCas,
  SleepingSurveyWithCas,
  SleepingSurveyWithCas,
  ThinkingNote,
  [
    {
      progressHide: true,
      showHeader: true,
      replaceComponent: <ObjectiveFeedback />,
    },
    {
      progressHide: true,
      replaceComponent: <FeedbackDone bg="#EFF2FF" title="평가를 마쳤어요" />,
    },
  ],
  [
    basicFormat({
      title: 'Outro',
      fullImage: course5_1_1,
      texts: [
        '28일 동안 고생 많았습니다. 오늘이 마지막 날이네요. 불면증은 많이 개선되었나요? 조금씩 개선되고 있을 수도 있고, 아직은 효과를 못 보고 있을지도 모르겠네요. 만약 시간이 더 필요하다면 코스를 한 번 더 진행해 보는 것도 좋은 방법이랍니다.',
        '\n',
        '저는 수면코스를 한 번 더 개선하는 작업을 하려고 해요. 새로운 수면 코스가 나오면 꼭 다시 한 번 이용해 보세요. 더 많은 도움을 얻을 수 있을 거예요.',
        '\n',
        '그러면 4주 동안 고생 많았어요. 앞으로도 편안한 밤 보내길 바랄게요.',
      ],
    }),
  ],
];

export default course5Data;
