import wb21_4 from '../image/wb21_4.png';
import wb3_1_1 from '../image/wb3_1_1.png';
import wb3_1_2 from '../image/wb3_1_2.png';
import wb3_1_3 from '../image/wb3_1_3.png';
import wb3_1_4 from '../image/wb3_1_4.png';
import wb3_1_5 from '../image/wb3_1_5.png';
import wb3_1_6 from '../image/wb3_1_6.png';
import wb3_2_1 from '../image/wb3_2_1.png';
import wb3_2_2 from '../image/wb3_2_2.png';
import wb3_2_3 from '../image/wb3_2_3.png';
import wb3_2_4 from '../image/wb3_2_4.png';
import wb3_2_5 from '../image/wb3_2_5.png';
import wb3_3_1 from '../image/wb3_3_1.png';
import wb3_3_2 from '../image/wb3_3_2.png';
import wb3_3_3 from '../image/wb3_3_3.png';
import wb3_3_4 from '../image/wb3_3_4.png';
import wb3_3_5 from '../image/wb3_3_5.png';
import wb3_3_6 from '../image/wb3_3_6.png';
import wb3_3_7 from '../image/wb3_3_7.png';
import wb3_4_1 from '../image/wb3_4_1.png';
import wb3_4_2 from '../image/wb3_4_2.png';
import wb3_4_3 from '../image/wb3_4_3.png';
import wb3_4_4 from '../image/wb3_4_4.png';
import wb3_5_1 from '../image/wb3_5_1.png';
import wb3_5_2 from '../image/wb3_5_2.png';
import wb3_5_3 from '../image/wb3_5_3.png';
import wb3_6_1 from '../image/wb3_6_1.png';
import wb3_6_2 from '../image/wb3_6_2.png';
import wb3_6_3 from '../image/wb3_6_3.png';
import wb3_7_1 from '../image/wb3_7_1.png';
import wb3_7_2 from '../image/wb3_7_2.png';
import wb3_7_3 from '../image/wb3_7_3.png';
import wb3_8_1 from '../image/wb3_8_1.png';
import wb3_8_2 from '../image/wb3_8_2.png';
import wb3_8_3 from '../image/wb3_8_3.png';
import wb3_8_4 from '../image/wb3_8_4.png';
import wb3_9_1 from '../image/wb3_9_1.png';
import wb3_9_2 from '../image/wb3_9_2.png';
import wb3_9_3 from '../image/wb3_9_3.png';
import wb3_10_1 from '../image/wb3_10_1.png';
import wb3_10_2 from '../image/wb3_10_2.png';
import wb3_10_3 from '../image/wb3_10_3.png';
import EmotionalInput from '../components/workbook/EmotionalInput';
import { TextWithBold } from '../components/workbook/TextWithBold';
import UserAnswerGrayMessage from '../components/workbook/UserAnswerGrayMessage';
import QuestionSelectButton from '../components/workbook/QuestionSelectButton';
import GradientEmotionBox from '../components/workbook/GradientEmotionBox';
import EmotionSelectBox from '../components/workbook/EmotionSelectBox';
import UserEmotionInput from '../components/workbook/UserEmotionInput';
import UserAnswerGrayMessage2 from '../components/workbook/UserAnswerGrayMessage2';
import TextWithDot from '../components/workbook/TextWithDot';
import ExpressEmotionInfo from '../components/workbook/ExpressEmotionInfo';
import FinishEmotionSituation from '../components/workbook/FinishEmotionSituation';
import { Image } from '../components/workbook/Image';
import UserEmotionRecord from '../components/workbook/UserEmotionRecord';
import UserSituationEmotionBox from '../components/workbook/course3/UserSituationEmotionBox';
import ChooseRoute from '../components/workbook/course3/ChooseRoute';
import EmotionSummary from '../components/workbook/course3/EmotionSummary';
import PageBySelected from '../components/workbook/PageBySelected';
import MessageBlock from '../stories/workbook/MessageBlock';
import MessageBlockWithQuote from '../stories/workbook/MessageBlockWithQuote';
import MessageBlockWithTail from '../stories/workbook/MessageBlockWithTail';

const course3Data = [
  [
    {
      title: '내 마음 속 세상',
      subtitle: '어서오세요, 환영해요.',
      image: wb3_1_1,
      bg: '#463995',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '지난 코스에서 나의 감정과 연결된 생각을 되돌아보니 어떠셨나요? 물론 여전히 내 마음 속 생각을 돌아보는 것이 어려울 수 있어요. 저도 마찬가지에요. 살아오면서 굳어진 생각과 마음의 패턴을 바꾸기란 쉽지 않은 일입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '그래도 흥미로웠어요!',
    },
    {
      id: 1,
      image: wb3_1_2,
      bg: '#5963BA',
      title: '감정 알아차리기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '파도 위에서 돛단배를 타고 있다고 생각해보세요. 돛단배가 앞으로 나아가기 위해서는 어느정도 힘있는 파도와 적당한 바람이 필요할 거예요. 하지만 항상 이렇게 좋은 날씨만 있는 것이 아닙니다. 때로는 비바람이 치기도 하겠죠. 날씨가 변화함에 따라 우리는 돛단배의 돛을 펼치거나 노젓기를 함으로써 균형을 잡고 앞으로 나아갈 수 있을 거예요.',
                <br />,
                <br />,
                <span />,
                '우리의 생각과 감정 또한 마찬가지입니다. ',
                '인생은 항상 변하고, 다양한 일이 발생하는 건 누구에게나 마찬가지',
                '에요. 우리는 단지, ',
                '오르락내리락하는 감정의 파도를 타고 돛단배가 뒤집어지지 않도록 균형을 잡으며',
                ' 살아가면 되는 겁니다.',
                <br />,
                <br />,
                <span />,
                '그러기 위해서는 내가 어떤 감정을 느끼고 있는지 잘 인지하고 있어야겠죠. 그래서 이번 코스에서는 나의 생각을 알아보는 것의 첫 단계였던 ',
                '나의 감정 알아차리기',
                '에 대해서 조금 더 자세히 배워볼 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '감정은 그냥 느끼면 되는 게 아닌가요?',
      link: '',
    },
    {
      title: '나의 감정을 모를 때도 있어요',
      texts: [
        {
          text: "감정을 건강하게 다루는 첫 단계는 ‘감정을 알아차리는 것'입니다. 굉장히 간단해 보이죠? 그런데 많은 사람들이 이것을 어려워한답니다.",
        },
        {
          component: (
            <div className="my-[30px]">
              <Image image={wb3_1_3} bg="#31289A" />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우리는 생각보다 지금 내가 느끼고 있는 감정이 무엇인지 잘 모를 때가 많아요. 생각과 감정은 순식간에 밀려오기 때문이에요. ',
                '우리는 실타래처럼 얽혀있는 생각과 감정을 구분하지 않고 하나의 덩어리로 그저 받아들이는 경우가 많습니다.',
                ' 우리가 어떤식으로 감정을 제대로 알아차리지 못하고 지나치는지 함께 알아볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '네, 좋아요!',
    },
    {
      category: '인사이드 이야기',
      image: wb3_1_4,
      bg: '#423F53',
      title: '아무것도 느껴지지 않을 때',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우울하고 어려운 시기에 있을 때, 아무것도 느끼지 못하는 경우가 있어요. 슬픔도, 기쁨도 없어요. 그저 ',
                '텅 빈 우주 한 가운데 나 혼자 둥둥 떠있는 것처럼 공허하고 어떠한 것도 느껴지지 않죠.',
                <br />,
                <br />,
                '반대로, ',
                '한꺼번에 너무 많은 감정의 소용돌이를 마주해서 감정에 대한 문을 닫아버린 것일 수도 있어요.',
                ' 얽혀있는 실타래를 풀기 어렵듯이, 뒤죽박죽 섞인 나의 감정을 그저 외면하는 경우입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '저도 그런 적이 있어요...!',
    },
    {
      image: wb3_1_6,
      bg: '#9A76FF',
      title: '나의 감정을 아는 것의 중요성',
      texts: [
        {
          text: '원인을 알 수 없는 피로감, 두통, 복통, 소화불량과 같은 신체 증상을 겪은 적이 있지 않으신가요? ',
        },
        {
          component: (
            <MessageBlockWithTail type="black" textList={[['나는 스트레스를 잘 안 받는 체질인 것 같아']]} my />
          ),
        },

        {
          component: (
            <TextWithBold
              texts={[
                '위와 같이 스트레스를 잘 받지 않는 체질이라고 생각하고 있지만, ',
                '실제로는 감정의 소용돌이를 무의식적으로 회피하고 억압하고 있는 경우',
                '가 많습니다. 이러한 감정들을 다루는 방법을 알지 못해 억압하기만 한다면, ',
                '억압된 감정이 한순간에 폭발해버리거나 두통, 복통, 소화불량과 같은 신체적인 증상으로 표출',
                '되는 거예요. 따라서 우리는 일상속에서 감정을 적절하게 다루고 돌볼 필요가 있어요. ',
                <br />,
                <br />,
                <span />,
                '이러한 감정을 다루는 일의 가장 첫 단계는 ',
                '나의 감정을 알아차리는 일',
                '입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '감정을 알아차리는 일이 중요하군요!',
    },
    {
      image: wb3_1_5,
      bg: '#59AEBA',
      title: '우리가 도와드릴게요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이번 코스에서는 우선 어떤 감정들이 있는지 살펴보고, ',
                '뒤죽박죽 섞여 알아차리기 어려운 감정들을 쉽게 들여다볼 수 있는 단서',
                '를 알아볼 거예요. 이를 통해 ',
                '나의 감정을 느끼고, 있는 그대로 바라보는 연습',
                '을 해보겠습니다. 만약 나의 감정 때문에 힘들었던 사람이라면, 이번 코스가 특히 도움이 될 거예요.',
                <br />,
                <br />,
                <span />,
                '이 코스를 마치면 맞닥뜨리기 어려운 감정을 마주했을 때, 어떻게 해야할 지 몰라 회피하고 무시했던 이전과는 달리 능숙하게 내 감정을 다루는 나를 발견할 수 있을 것이랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '이번 시간 마치기',
    },
  ],
  [
    {
      title: '우리를 돕는 감정',
      fullImage: wb3_2_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리는 하루에도 수백 번 다양한 감정을 느끼며 살아갑니다. 편안함, 즐거움, 흐뭇함, 행복함 등의 긍정적인 감정부터 불안함, 초조함, 두려움, 화남 등의 부정적인 감정까지 말이죠.',
                <br />,
                <br />,
                <span />,
                '어떤 날은 에너지가 넘치고 기분 좋은 날이 있다면, 어떤 날에는 아무것도 하기 싫고 온몸에 힘이 빠지는 날도 있을 겁니다. 이런 ',
                '감정 변화의 물결은 우리 삶의 한 부분',
                '이에요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '감정이 항상 똑같을 수는 없군요.',
    },
    {
      id: 1,
      image: wb3_2_2,
      title: '감정이 변하는 이유',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리가 편안한 상태라고 느끼려면, 기본 심리적 욕구가 채워져야 한다는 사실을 알고 계셨나요?',
                <br />,
                <br />,
                <span />,
                '인간은 태어날 때부터 이러한 기본 욕구를 채우는 방향으로 행동하도록 되어 있어요. 그리고 ',
                '어떤 욕구가 충족되지 않았을 때 불편한 감정이 발생',
                '합니다.',
                <br />,
                <br />,
                <span />,
                ' 따라서 ',
                '우리가 스스로의 감정을 알아차리지 못한다면, 그 순간에 내가 실제로 무엇을 원하고 있는지를 발견하기 어려울 거예요.',
                ' 이렇게 되면 나의 욕구는 충족되지 못한 채 불편한 감정만 남게 되는 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '어떤 욕구들이 있는지 궁금해요!',
      link: '',
    },
    {
      image: wb3_2_3,
      title: '기본 심리적 욕구 5가지',
      texts: [
        {
          text: '심리학의 다양한 이론들에서 가장 공통적으로 말하는 다섯 가지 심리적 욕구는 다음과 같아요.',
        },
        {
          component: (
            <MessageBlock
              textList={[
                {
                  title: '생존의 욕구',
                  text: [
                    '한 사람으로서 살아가는데 필요한 기본적인 것들로, 음식, 물, 공기, 휴식, 수면, 안전한 거주지, 건강과 성적인 충족, 경제적 안정 등을 의미합니다.',
                  ],
                },
                {
                  title: '관계의 연결',
                  text: [
                    '친구나, 가족, 직장동료와 같은 주변 사람과 사랑과 관심을 주고받으며 얻는 사회적인 연결성을 의미합니다.',
                  ],
                },
                {
                  title: '성취',
                  text: ['유능하고 무언가 할 수 있을 것 같은 느낌, 자존감, 자신감, 자기 신뢰와 인정 등을 의미합니다.'],
                },
                { title: '자유', text: ['자율성과 독립성을 가지고 선택하여 행동할 수 있는 느낌을 의미합니다.'] },
                {
                  title: '즐거움',
                  text: [
                    '말 그대로 즐거움입니다. 취미나 관심, 개인적인 흥미에 쏟는 시간을 통해 즐거움과 기쁨, 편안함을 경험하는 것입니다.',
                  ],
                },
              ]}
              type="white_dotted"
              my
            />
          ),
        },
        {
          text: '최근 한 달 사이에 여러분에게 특히 중요했던 심리적 욕구는 무엇이었나요?',
        },
      ],
      buttonText: '생각해 봐야겠어요!',
    },
    {
      image: wb3_2_4,
      title: '행동을 변화시키는 감정',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '욕구와 감정이 관련이 있다는 말을 다르게 생각해 보면, ',
                '감정은 우리가 원하는 것으로 다가갈 수 있도록 우리들의 행동을 변화시킨다는 뜻',
                '이기도 해요.',
                <br />,
                <br />,
                <span />,
                '예를 들어 볼게요. 여러분이 차를 타고 이동하기 위해 밖으로 나갔는데 비가 내리고 있습니다. 만약 여러분이 운전에 미숙한 상태라면, 그러한 상황에서 어떤 생각이 들까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '적어보기',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={2}
          title="이런 생각이 들 것 같아요"
          placeholder="편하게 작성해 보세요"
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      title: '그런 생각이 드셨군요!',
      texts: [
        {
          component: <UserAnswerGrayMessage index={0} />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '저와 비슷한 생각을 하셨나요? 또는 조금 다른 생각을 하셨나요? 우리 모두는 다양한 시각을 가지고 있기 때문에 생각과 감정은 이렇게 다양할 수 있어요.',
                <br />,
                <br />,
                <span />,
                '이번에는 조금 다른 상황을 생각해 보죠. 여러분이 집을 떠나기 전 일기예보에서 아침의 빗줄기가 저녁이면 엄청난 비바람과 태풍으로 변할 거라는 소식을 들었다고 해봅시다. 그렇다면 어떤 생각이 들 것 같나요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '적어보기',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={2}
          title="이런 생각이 들 것 같아요"
          placeholder="편하게 작성해 보세요"
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      title: '그런 생각이 드셨군요!',
      texts: [
        {
          component: (
            <div className="my-[20px]">
              <UserAnswerGrayMessage index={1} />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '첫번째 예시에서는 비가 내리는 길을 운전해야 하는 상황에 조금 긴장되고 두려웠을 거예요. 비가 오기는 하지만 그래도 조심스럽게 운전을 해 볼까 생각이 들기도 했겠죠.',
                '\n',
                '두번째 예시에서는 여러분이 곧 날씨가 더욱 악화될 거라는 사실을 알고 있었다는 것만으로도 훨씬 더 큰 걱정과 두려움을 느꼈을 겁니다.',
                '\n',
                <span />,
                '이처럼 같은 상황이라도 내가 알고 있는 사실에 따라서 생각이 달라지고, 감정 또한 달라집니다.',
                ' 그리고 이때 생긴 감정은 어떠한 심리적 욕구가 충족되지 못 할 것으로 예상되어 발생했다고 할 수 있어요.',
                '\n',
                '위 상황들에서 여러분이 지키고자 했던 심리적 욕구는 무엇이었을까요?',
              ]}
            />
          ),
        },
        {
          component: (
            <QuestionSelectButton
              textList={['성취의 욕구', '생존의 욕구', '관계의 연결', '자유의 욕구', '즐거움의 욕구']}
              answer="생존의 욕구"
            />
          ),
        },
      ],
    },
    {
      image: wb3_2_5,
      title: '감정은 우리를 돕기 위해 존재해요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '그렇습니다. 위험과 마주하면 두려움이란 감정이 생기는 것은 당연한 일이에요. 이 감정이 우리로 하여금 생존의 욕구를 충족시키기 위해 재빨리 어떤 행동을 취하게 해줌으로써 우리를 잠재적으로 위험한 상황에서 벗어나도록 이끌어준 것입니다.',
                <br />,
                <br />,
                <span />,
                '이렇듯, ',
                '감정은 우리가 원하는 상태를 유지하거나 얻도록 하는 방향으로 행동하게 하여',
                ' 우리를 돕습니다.',
                <br />,
                <br />,
                <span />,
                '이와 같이 ',
                '모든 감정은 우리를 돕기 위해 존재하고 각자의 기능이 있습니다.',
                ' 이에 대해서는 다음 챕터부터 차근차근 함께 살펴보도록 해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '이번 시간 마치기',
    },
  ],
  [
    {
      title: '감정에 이름붙이기',
      subtitle: '감정에는 어떤 종류가 있을까요?',
      fullImage: wb3_3_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '감정은 크게 긍정적인 감정과 부정적인 감정으로 구분할 수 있어요. 우리는 태어나면서부터 감정을 느낄 수 있는 상태로 태어나고, 이를 언어로 표현하는 능력은 성장하면서 획득하게 됩니다.',
                <br />,
                <br />,
                <span />,
                '갓 태어난 아기는 말을 하지 못하지만 신기하게도 엄마는 이 아기가 무엇을 원하는지 알아차릴 수 있어요. 엄마는 아기의 말을 어떻게 알아듣는 걸까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '아기의 표정을 보고 알 수 있는걸까요...?',
    },
    {
      title: '감정 단어를 알면 좋은 이유',
      texts: [
        {
          text: [
            '그렇습니다. 아기들은 좋으면 웃고, 불편하면 울기 때문이에요. 크게 ‘긍정적인 느낌’, ‘부정적인 느낌’으로 나뉘는 감정은 살아가면서 더욱 세밀하고 다양해집니다.',
            <br />,
            <br />,
          ],
        },
        {
          component: (
            <div
              className={`w-full h-[190px] relative overflow-hidden rounded-2xl mb-[30px] flex align-center`}
              style={{ backgroundColor: '#0C2667' }}
            >
              <div className="w-[335px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
                <img className="w-full h-full object-fit" src={wb3_3_2} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                "우리는 뇌의 편도체라는 부위를 통해 감정을 느끼고, 감정은 대뇌피질이라는 부위를 거쳐 언어로 표현된답니다. 즉 우리는 우리가 느낀 것을 ‘생각'하고, 이를 각자의 언어로 다르게 ‘표현'하게 됩니다.",
                <br />,
                <br />,
                <span />,
                '그렇기 때문에 ',
                '언어로 표현되는 감정 단어를 많이 알고 있다면, 보다 세밀하게 감정을 설명',
                '할 수 있어 ',
                '나의 감정을 인지하는 데 도움',
                '이 된답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '그럼 감정단어에는 어떤 것들이 있나요?',
      link: '',
    },
    {
      title: '감정 단어 목록',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '수많은 심리학자들이 분류한 여러 감정 분류 체계 중 가장 대표적인 감정은 ',
                '기쁨, 슬픔, 불쾌함, 화남, 두려움',
                '으로 5가지입니다. 우리가 느끼는 수많은 감정은 대표 감정의 강도가 달라지거나, 이들이 서로 섞여서 만들어진 복합감정이라고 볼 수 있어요. 먼저 기본 감정 5가지를 소개해드릴게요.',
              ]}
            />
          ),
        },
        {
          component: (
            <div className="my-[40px]">
              <GradientEmotionBox />
            </div>
          ),
        },
        {
          text: '다음 페이지부터는 각 감정별로 세밀한 감정 단어에 대해 살펴보고, 내가 자주 느끼는 감정에는 무엇이 있는지 적절한 단어를 선택해봅시다 :)',
        },
      ],
      buttonText: '네, 좋아요!',
    },

    {
      image: wb3_3_4,
      bg: '#FFC657',
      title: '기쁨',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '기쁨은 동기부여를 해주어 삶의 의미를 느끼게 해 주죠.',
                ' 누구나 긍정적인 감정을 좋아할 거예요. 그렇기 때문에 우리는 이러한 좋은 기분을 느끼게 하는 행동을 반복하게 됩니다.',
                '\n',
                '아래는 기쁨과 관련된 다양한 감정 단어입니다. 이 중, 지금 느끼고 싶은 감정 단어를 선택하고 주로 언제 그 감정을 느끼는지 적어볼 거예요. 단어는 최대 3개까지 원하는 만큼 선택해 보세요.',
              ]}
            />
          ),
        },
        {
          component: (
            <EmotionSelectBox
              index={0}
              emotion="기쁨"
              extraText="잘하셨어요. 이번엔 여러분이 주로 언제 이 감정을 느끼는지 적어볼게요. 최근에 있었던 특정한 상황을 적으셔도 좋고, 평소에 자주 있는 일을 적어 보아도 좋습니다. 이렇게 특정 감정을 느끼는 상황을 되새겨 보는 것은 내 감정을 파악하는 데 도움이 됩니다."
            />
          ),
        },
      ],
    },
    {
      replaceComponent: (
        <UserEmotionInput
          emotion="기쁨"
          buttonText="네, 좋아요!"
          bottomText={['잘하셨어요! 다음으로는 슬픔에 대해 살펴볼게요.']}
        />
      ),
    },
    {
      image: wb3_3_3,
      title: '슬픔',
      bg: '#213459',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '슬픔은 누구에게나 피하고 싶은 감정이에요. 하지만 슬픔 또한 우리를 돕기 위해 존재합니다. ',
                '슬픔은 어려운 사건 이후에 우리를 쉬고 회복하도록 해 준답니다.',
                '\n',
                <span />,
                '아래는 슬픔과 관련된 다양한 감정 단어입니다. 아래의 감정 단어 중, 여러분이 최근 한 달간 가장 많이 느꼈던 감정 단어를 선택해 보세요. (최대 3개까지 선택 가능)',
              ]}
            />
          ),
        },
        {
          component: (
            <EmotionSelectBox
              index={1}
              emotion="슬픔"
              extraText="잘하셨어요. 이제 상황을 적어볼 차례입니다. 최근에 있었던 특정한 상황을 적으셔도 좋고, 평소에 자주 있는 일을 적어 보아도 좋습니다. "
            />
          ),
        },
      ],
      bottomBoxHide: true,
    },
    {
      replaceComponent: (
        <UserEmotionInput
          emotion="슬픔"
          buttonText="감정을 알아차리는 일이 중요하군요!"
          bottomText={['잘하셨어요! 다음으로는 불쾌함에 대해 살펴볼게요.']}
        />
      ),
    },
    {
      image: wb3_3_6,
      bg: '#21593B',
      title: '불쾌함',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '불쾌함은 우리가 피하고 싶고, 원치 않는 자극을 만났을 때 드는 반감의 감정이에요. ',
                '불쾌함은 해로울 수 있는 자극을 피하도록 해줍니다.',
                '\n',
                <span />,
                '아래의 감정 단어 중, 최근 한 달간 여러분이 가장 피하고 싶었던 감정 단어를 선택해보세요. (최대 3개까지 선택 가능)',
              ]}
            />
          ),
        },
        {
          component: (
            <EmotionSelectBox
              index={2}
              emotion="불쾌함"
              extraText={
                <TextWithBold
                  texts={[
                    '잘하셨어요. 이제 상황을 적어볼 차례입니다. 평소 감정을 글로 표현한 경험이 적었다면 이 과정이 생소하게 느껴질 것이지만 자연스러운 일이에요. 다시 한번 강조하지만, ',
                    '감정을 언어로 표현하는 것은 내 감정을 이해하는 것의 중요한 부분입니다.',
                    ' 더불어 감정을 유발하는 상황을 파악함으로써 비슷한 상황에서 감정을 이전보다 편하게 받아들일 수 있게 될 거예요.',
                  ]}
                />
              }
            />
          ),
        },
      ],
      bottomBoxHide: true,
    },
    {
      replaceComponent: (
        <UserEmotionInput
          emotion="불쾌함"
          bottomText={[
            '이런 상황에서 불쾌한 감정을 느끼셨군요! 이제 거의 다 왔습니다. 남은 감정은 화남과 두려움이에요. 나머지 두 감정도 함께 살펴볼까요?',
          ]}
          buttonText="네, 좋아요!"
        />
      ),
    },
    {
      fullImage: wb3_3_7,
      height: 190,
      title: '화남',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리는 무시당하거나 부당한 일을 겪었을 때 분노의 감정을 느낍니다. ',
                '분노는 우리가 피해를 보는 것으로부터 보호해주기 때문에, 무작정 참는 것이 아닌 똑똑하게 표출할 줄 알아야 해요.',
                '\n',
                <span />,
                '아래의 감정 단어 중, 최근 한 달 간 자주 느꼈던 감정 단어를 선택해 볼까요? (최대 3개까지 선택 가능)',
              ]}
            />
          ),
        },
        {
          component: (
            <EmotionSelectBox
              index={3}
              emotion="화남"
              extraText="잘하셨어요. 이번엔 여러분이 주로 언제 이 감정을 느끼는지 적어볼게요. 최근에 있었던 특정한 상황을 적으셔도 좋고, 평소에 자주 있는 일을 적어 보아도 좋습니다."
            />
          ),
        },
      ],
      bottomBoxHide: true,
    },
    {
      replaceComponent: (
        <UserEmotionInput
          emotion="화남"
          bottomText={[
            '기쁨, 슬픔, 불쾌함, 화남까지 살펴보았어요.',
            <span />,
            '이제 마지막으로 두려움에 대해 알아보겠습니다.',
          ]}
          buttonText="와, 정말 감정 단어가 많이 있었네요!"
        />
      ),
    },
    {
      title: '두려움',
      fullImage: wb3_3_5,
      height: 190,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '위험한 상황으로부터 우리를 보호해주는 감정인 두려움',
                '입니다. 불안하고 걱정되는 감정부터 무섭고 공포스러운 감정까지 두려움은 다양한 모습으로 다가와요.',
                '\n',
                '아래의 감정 단어들 중, 여러분이 가장 힘들어 하는 감정 단어를 선택해 보세요. (최대 3개까지 선택 가능)',
              ]}
            />
          ),
        },
        {
          component: (
            <EmotionSelectBox
              index={4}
              emotion="두려움"
              extraText="잘하셨어요. 이번엔 여러분이 주로 언제 이 감정을 느끼는지 적어볼게요. 최근에 있었던 특정한 상황을 적으셔도 좋고, 평소에 자주 있는 일을 적어 보아도 좋습니다. "
            />
          ),
        },
      ],
      bottomBoxHide: true,
    },
    {
      replaceComponent: <UserEmotionInput emotion="두려움" buttonText="네, 좋아요!" />,
    },
    {
      title: '감정 단어로 표현해보니 어땠나요?',
      texts: [
        {
          text: [
            '이곳에 보여드린 감정 단어 외에도 우리는 정말 다양한 언어로 감정을 표현할 수 있어요. 하지만 처음부터 쉽진 않을 수 있습니다.',
            <br />,
            <br />,

            '아직 자신의 감정을 정확하게 표현하는 것이 어렵다면, 가장 대표적인 다섯 가지 감정으로 표현하는 것으로도 충분해요!',
          ],
        },
        {
          component: <GradientEmotionBox />,
        },
      ],
      buttonText: '일상 속에 적용해보면 좋을 것 같아요',
    },
    {
      title: '구체적인 말로 표현해 보세요',
      texts: [
        {
          component: <FinishEmotionSituation />,
        },
      ],
      buttonText: '이번 시간 마치기',
      submit: 'emotionSituation',
    },
  ],
  [
    {
      title: '감정이 보내는 몸의 신호',
      subtitle: '꾸준히 감정을 표현해 보세요',
      fullImage: wb3_4_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '지난 시간에는 감정 단어들을 활용해 나의 감정에 이름을 붙여보는 연습을 해 보았습니다. 앞으로 감정기록을 하실 때, 다양한 표현을 활용해서 감정을 표현해보세요! 글로 적는 것만으로도 마음 속에 쌓인 감정을 분출하는 효과가 있답니다.',
                '\n',
                '아직 어려우시더라도 괜찮아요. 계속 연습하다보면 어느새 자연스럽게 나의 감정을 잘 파악하고 있는 모습을 발견하실 거예요.',
                '\n',
                '이제 ',
                '감정으로 유발된 신체 반응',
                '을 통해 나의 감정 상태를 파악하는 방법을 함께 알아볼게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '준비됐어요!',
    },
    {
      title: '감정이 보내는 몸의 신호',
      texts: [
        {
          text: [
            '시험을 보거나 면접을 볼 때, 발표를 할 때와 같이 긴장되는 상황에서 배가 부글부글 끓고 손발에 땀이 나는 경험을 한 적이 있지 않나요?',
            <br />,
            <br />,
          ],
        },
        {
          component: (
            <div
              className={`w-full h-[190px] relative overflow-hidden rounded-2xl mb-[30px] flex align-center`}
              style={{ background: '#F3F5F8' }}
            >
              <div className="w-[335px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
                <img className="w-full h-full object-fit" src={wb3_4_2} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이렇게 우리의 감정은 신체감각에 영향을 주고 또 영향을 받기도 합니다. 따라서 ',
                '신체반응은 우리가 느끼는 감정을 파악할 수 있는 좋은 신호',
                '입니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <div>
              <p className="text-[20px] leading-[24px] font-semibold text-[#26282C] mt-[30px] mb-[15px]">
                감정에 대한 신체반응의 예시
              </p>
              <TextWithDot
                texts={[
                  '얼굴이 달아오르는 느낌',
                  '몸의 어떤 부위의 통증이나 조이는 느낌',
                  '심장박동이나 호흡이 빨라짐',
                  '이완되고 부드러움',
                ]}
                margin="mt-[15px] mb-[40px]"
              />
            </div>
          ),
        },
        {
          text: '이러한 신체 반응은 감정 변화에 따른 우리 몸의 자율신경계 변화에 의해서 일어나게 됩니다.',
        },
      ],
      buttonText: '긴장하면 배가 아픈 것과 같은 거군요!',
      link: '',
    },
    {
      title: '감정과 호르몬의 상호작용',
      texts: [
        {
          text: ['감정과 신체반응 사이의 관계를 이해하기 위해 두려움을 예로 들어볼게요.', <br />, <br />],
        },
        {
          component: (
            <div
              className={`w-full h-[190px] relative overflow-hidden rounded-2xl mb-[30px] flex align-center`}
              style={{ background: '#F3F5F8' }}
            >
              <div className="w-[335px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
                <img className="w-full h-full object-fit" src={wb3_4_3} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '두려움을 느꼈을 때 우리의 신체반응은 어떻게 나타날까요? 갑자기 바퀴벌레가 나타났을 때, 운전 중 심하게 비가 내릴 때와 같이 일상에서의 두려움부터 막연하게 알 수 없는 미래로부터 오는 두려움까지, 우리는 다양한 상황에 의해서 두려움이라는 감정을 느낍니다.',
                '\n',
                '만약 여러분이 수백 명 앞에서 발표를 한다고 생각해보세요. 무대 뒤에서 기다리고 있는 여러분은 긴장됨과 두려움을 느끼고 있는 상황이에요.',
                '\n',
                '이럴 때 여러분의 신체 반응은 어떻게 나타날 것 같나요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '적어보기',
      isBottom: true,
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={2}
          title="두려울 때 몸의 느낌은 어떨까요?"
          placeholder="편하게 작성해 보세요"
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      title: '감정과 호르몬의 상호작용',
      texts: [
        {
          text: ['이번에는 여러분이 전력질주를 했을 때의 상황을 생각해볼게요.', <br />, <br />],
        },
        {
          component: (
            <div
              className={`w-full h-[190px] relative overflow-hidden rounded-2xl mb-[30px] flex align-center`}
              style={{ background: '#F3F5F8' }}
            >
              <div className="w-[335px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
                <img className="w-full h-full object-fit" src={wb3_4_4} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          text: '약속 시간에 늦은 여러분이 버스정류장으로 가고 있는데, 저 멀리 보이는 버스가 막 떠나려고 하고 있어요. 전력질주하여 겨우 버스를 잡아 탔습니다. 이 때의 여러분의 신체 반응은 어떨까요?',
        },
      ],
      buttonText: '적어보기',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={2}
          title="전력질주 했을 때 몸의 느낌은 어떨까요?"
          placeholder="편하게 작성해 보세요"
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      title: '감정과 호르몬의 상호작용',
      texts: [
        { component: <UserAnswerGrayMessage2 /> },
        {
          component: (
            <TextWithBold
              texts={[
                '이렇게 나타날 것으로 적어주셨어요.',
                '\n',
                '실제로 두려움을 느꼈을 때와 달리기를 했을 때 우리의 신체반응은 비슷하게 나타납니다. 숨이 가빠지고, 두근거리고, 몸이 화끈 달아올라 땀을 흘릴거예요.',
                '\n',
                '이는 우리 몸의 자율신경계 중 교감신경이 작용했기 때문인데요, 이것의 중요한 기능 중 하나는 아드레날린과, 코티솔과 같이 ',
                '스트레스를 받을 때 발생하는 호르몬을 분비',
                '시키는 거예요. 이를 통해 우리 몸이 ',
                '위험한 상황에 빠지면 에너지를 방출',
                '하여 행동에 필요한 추진력을 얻을 수 있도록 합니다. 한 마디로 우리 몸이 에너지를 내고 집중할 수 있도록 ',
                "'전투 태세'",
                '로 돌입하는 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '호르몬을 통해 신체 반응을 유발하군요!',
    },
    {
      title: '감정에 따른 신체감각 느끼는 방법',
      texts: [
        {
          text: '여러분에게 다짜고짜 감정에 따라 느껴지는 신체감각을 적어보라고 해서 당황하셨을지도 모르겠네요.',
        },
        {
          component: <MessageBlockWithTail type="black" textList={[['대체 어떤 느낌을 말하는 거지...?']]} my />,
        },
        {
          text: [
            '그래서 앞으로는 다섯 가지 감정을 느꼈을 때 유발되는 신체감각에 대해 자세히 살펴볼 거예요. 그 전에 먼저 어떤 신체 반응을 주의 깊게 살펴보면 좋을지 팁을 드려볼게요.',
            <br />,
          ],
        },
        {
          component: <TextWithDot texts={['심장박동', '호흡', '감각/통증', '온도감']} />,
        },
        {
          text: '이렇게 네 가지 감각으로 나누어 보면 내 몸의 반응을 어떻게 살펴보아야 할지 감이 오실 겁니다. 어렵지 않아요. 하나씩 간단하게 살펴보겠습니다.',
        },
      ],
      buttonText: '네, 좋아요!',
    },
    {
      title: '감정에 따른 신체감각 느끼는 방법',
      texts: [
        {
          text: '감정은 우리가 특정한 행동을 취하도록 신경계에 영향을 미쳐 다양한 신체 반응이 나타나도록 하죠. 그중 앞서 말씀드렸던 네 가지 영역으로 나누어 감각을 느껴보세요.',
        },
        {
          component: (
            <div className="my-[40px]">
              <MessageBlock
                type={'white_dotted'}
                textList={[
                  {
                    title: '심장박동',
                    text: ['두근거리는지 편안한 상태인지 느껴보세요'],
                  },
                ]}
                margin="mb-[10px]"
              />
              <MessageBlock
                type={'white_dotted'}
                textList={[
                  {
                    title: '호흡',
                    text: ['숨이 가쁜지, 얕고 빠르게 쉬고 있는지, 숨이 막히지는 않은지 느껴보세요'],
                  },
                ]}
                margin="mb-[10px]"
              />
              <MessageBlock
                type={'white_dotted'}
                textList={[
                  {
                    title: '감각/통증',
                    text: ['배가 아프거나 어깨가 뭉치는 것처럼 신체 부위 어느 곳이든 특정 감각이나 통증을 느껴보세요'],
                  },
                ]}
                margin="mb-[10px]"
              />
              <MessageBlock
                type={'white_dotted'}
                textList={[
                  {
                    title: '온도감',
                    text: [
                      '얼굴이 화끈 달아오르거나 손발이 차갑게 얼어붙는 것과 같이 나의 몸의 온도감이 변화하는지 느껴보세요',
                    ],
                  },
                ]}
              />
            </div>
          ),
        },
        {
          text: '다음 시간에는 이를 바탕으로 감정이 유발하는 신체반응을 하나씩 살펴보도록 해요.',
        },
      ],
      buttonText: '이번 시간 마치기',
    },
  ],
  [
    {
      title: '감정 알아차리기 - 기쁨',
      fullImage: wb3_5_1,
      height: 253,
      texts: [
        {
          text: "감정은 우리들의 몸에서 어떤 방식으로 나타날까요? 이제부터 다섯 가지 감정들을 하나씩 살펴볼게요.  먼저 긍정적인 감정의 대표 감정인 ‘기쁨'입니다.",
        },
        {
          component: (
            <div className="mt-[30px] mb-[15px] text-[20px] leading-[24px] font-semibold text-[#26282C]">기쁨</div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '여러분은 언제 기쁨을 느끼시나요? 즐거움, 행복, 신이 나고 기대되는 감정 등 세상에는 다양한 종류의 긍정적인 감정이 있어요. 기쁨의 감정은 정말 다양한 상황에서 느낄 수 있죠.',
                '\n',
                '오랜 기간 준비한 시험에서 좋은 성적을 거두었을 때, 직장이나 학교에서 인정받았을 때, 보고싶었던 친구를 만났을 때와 같이 ',
                '특별한 상황에서도 기쁨을 느끼는 반면,',
                ' 따사로운 햇살 아래에서 거닐 때, 좋은 향기를 맡을 때, 맛있는 음식을 먹을 때와 같이 ',
                '평범하고 반복되는 일상 속에서 은은하게 느껴지는 기쁨',
                '도 있습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '여러 상황에서 기쁨을 느낄 수 있죠!',
      isBottom: true,
    },
    {
      title: '감정 알아차리기 - 기쁨',
      texts: [
        {
          text: [
            '지치고 피곤한 일상 속에서 우리는 기쁨이란 감정에 무뎌지고 잘 알아차리지 못하는 경우가 많아요.',
            <br />,
            <br />,
            '아래는 지난 번 감정에 이름붙이기에서 다뤘던 “나는 언제 기쁜 감정을 느끼는가?”에 대한 내용이에요.',
          ],
        },
        {
          component: <UserSituationEmotionBox emotion="기쁨" />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '내가 어떤 상황에서 어떤 감정을 느끼는지 말로 표현하려면 나의 느낌을 곰곰이 생각해 보아야 합니다. 그 과정 속에서 나의 감정을 이해하게 되죠. 이제부터는 그 느낌 중에서도 ',
                '특히 몸의 느낌, 즉 신체반응',
                '에 대해 알아볼 거예요. 우선 기쁜 감정을 느낄 때 몸의 느낌을 함께 살펴 볼게요. ',
                '신체반응을 통해 감정을 알아차리고 감정에 이름을 붙이는 과정을 반복하다 보면 나의 감정을 다루는 법에 익숙해지실 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '네, 좋아요!',
      isBottom: true,
    },
    {
      title: '기쁨이 유발하는 신체반응',
      texts: [
        {
          text: [
            '사람마다 감정이 유발하는 신체반응은 굉장히 다양하지만, 여기에서는 기쁨을 느꼈을 때 흔히 나타나는 신체반응에 대한 예시를 보여드릴게요.',
            <br />,
            <br />,
          ],
        },
        {
          component: (
            <div className="my-[30px] min-h-[297px] w-full">
              <img src={wb3_5_2} alt="empty" className="h-full w-full max-w-[390px] mx-auto" />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '기쁠 때는 ',
                '전반적으로 몸에 열감이 오르며 몸이 이완되어 편안한 상태',
                '가 됩니다. 하지만 긍정적인 감정 또한 너무 강렬할 경우 또 다른 느낌이 들 수 있어요.',
              ]}
            />
          ),
        },
        {
          component: <TextWithDot texts={['심장이 빠르게 뛰고', '손발이 떨리거나 속이 간지러운 느낌']} />,
        },

        {
          component: (
            <TextWithBold
              texts={[
                '이를 테면, 사랑에 빠지거나 매우 신이 나서 흥분되는 경우와 같아요. ',
                '사람마다 감정의 종류와 강도에 따라 느끼는 신체 감각이 매우 다양하기 때문에 자신만의 느낌을 표현해보는 것이 중요합니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '그렇군요',
      isBottom: true,
    },
    {
      title: '나의 감정 알아차리기',
      texts: [
        {
          text: '지금까지 나의 감정을 알아차리기 위한 두 가지 전략에 대해 이야기했어요.',
        },
        {
          component: <ExpressEmotionInfo />,
        },
        {
          text: '‘감정을 만드는 생각 찾기’ 수업에서 제공해드렸던 감정노트 잘 사용하고 계신가요? 감정노트️에는 어떻게 기뻤는지를 적는 단계가 있습니다. 이 방법을 활용해서 다음과 같이 감정을 표현할 수 있을 거예요.',
        },
        {
          component: (
            <MessageBlock
              textList={[{ title: '기쁨', text: ['즐거워요, 편안해요, 신이나요'] }]}
              type="blue_border"
              margin="mt-[40px] mb-[10px]"
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                {
                  title: '무슨 사건이 있었나요?',
                  text: ['오전에 내리던 비가 그치고 오후부터 날씨가 개고 시원한 바람이 불었다.'],
                },
              ]}
              type="gray"
              margin="mb-[10px]"
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[{ title: '어떻게 기뻤나요?', text: ['상쾌했다. 온 몸이 긴장이 풀리고 편안했다.'] }]}
              type="white_dotted"
              margin="mb-[40px]"
            />
          ),
        },
        {
          text: '다음과 같이 적어볼 수도 있을 거예요.',
        },
        {
          component: (
            <MessageBlock
              textList={[{ title: '기쁨', text: ['즐거워요, 편안해요, 신이나요'] }]}
              type="blue_border"
              margin="mt-[40px] mb-[10px]"
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                {
                  title: '무슨 사건이 있었나요?',
                  text: [
                    '좋아하는 가수의 콘서트에서 이벤트에 당첨되어 무대 위로 올라가 사인CD를 받고 사진을 함께 찍었다.',
                  ],
                },
              ]}
              type="gray"
              margin="mb-[10px]"
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                {
                  title: '어떻게 기뻤나요?',
                  text: ['덥고 심장이 두근거리고 손에 땀이 났지만 좋은 느낌이었다. 엄청 흥분되고 신이 났다.'],
                },
              ]}
              type="white_dotted"
            />
          ),
        },
      ],
      buttonText: '이런 식으로 하면 되는거군요!',
    },
    {
      title: '여러분이 직접 해볼까요?',
      texts: [
        {
          component: <ExpressEmotionInfo />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '오늘 혹은 최근 일주일 간 기분이 좋았던 때를 떠올리며 여러분이 직접 적어볼게요. ',
                '지금은 당장은 마음이 힘들어 즐거운 상황이 잘 떠오르지 않는다면, 이런 행동을 하면 기분이 좋아질 것 같다라는 것을 적어보아도 좋아요.',
                ' 잘 떠오르지 않는 분들을 위해 예시를 들어볼게요.',
              ]}
            />
          ),
        },
        {
          component: (
            <TextWithDot
              texts={[
                '따사로운 날씨 아래 산책할 때',
                '좋아하는 음악을 들으며 샤워할 때',
                '동료나 가족과 대화를 나눌 때',
              ]}
            />
          ),
        },
        {
          text: '기분이 좋았던 상황이 떠오르셨나요? 혹은 기분이 좋아질 것 같은 행동이나 상황이 있으신가요? 그럼 이제 적어볼게요.',
        },
      ],
      buttonText: '적어보기',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={2}
          title="최근에 언제 기분이 좋았나요?"
          placeholder="장소, 시기, 인물 등 &#13;&#10;상황을 객관적으로 적어보세요."
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={2}
          title="어떻게 기뻤나요?"
          placeholder="당시에 느낀 감정이나 신체적 반응을 &#13;&#10;자세히 기록해 보세요."
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      title: '어떠셨나요?',
      texts: [
        { component: <UserEmotionRecord emotion="기쁨" /> },
        {
          component: (
            <TextWithBold
              texts={[
                '직접 적어보니 어떠셨나요? ',
                '글로 적기 위해서는 나의 감정이 어떤 느낌이었는지, 어떤 단어로 표현하면 좋을지에 대해 생각',
                '하게 됩니다. ',
                '그 과정에서 나의 감정을 섬세하고 깊게 들여다볼 수 있어요.',
                <span />,
                '\n',
                "처음부터 쉽지 않을 수 있습니다. 아직 어렵다면 ‘좋다' 혹은 ‘나쁘다' 정도로 간단히 표현하는 것만으로도 충분합니다. 감정노트️에 나의 감정을 기록하다보면 어느새 감정표현에 능숙해진 여러분을 발견할 수 있을 거예요.",
              ]}
            />
          ),
        },
      ],
      buttonText: '잘 적을 수 있을 것 같아요!',
    },
    {
      image: wb3_5_3,
      title: '이번 시간을 마치며',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '기쁨이라는 감정은 ',
                '우리로 하여금 삶의< 동기를 얻게 해주며, 활동적이고 의미 있는 삶을 살아가게 해 준답니다.',
                <span />,
                '\n',
                '기분을 전환시켜주는 활력소가 될 만한 상황이나 행동을 기억해두는 것은 나의 감정을 다스리는 데에 좋은 전략이 됩니다. ',
                '감정노트️를 지속하다보면 여러분은 언제 기분이 좋아지는지 파악하는 데 도움이 될 거예요.',
                ' 이를 통해 점차 내 삶을 더 기쁨의 감정이 가득한 날들로 만들어나갈 수도 있을 거랍니다.',
                '\n',
                '긍정적인 감정에 대해서는 별도의 코스에서 조금 더 다뤄보도록 할게요. 이번 코스에서 기쁨은 여기까지만 다루고, 다음 시간에는 슬픔이라는 감정에 대해 살펴볼 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '이번 시간 마치기',
      submit: 'Pleasure',
    },
  ],
  [
    {
      title: '감정 알아차리기 - 슬픔',
      fullImage: wb3_6_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이번에는 슬픔에 대해서 다뤄볼게요. ',
                '슬픔은 무언가를 상실한 데서 비롯되는 감정',
                '입니다. 죄책감, 외로움, 공허함 등 슬픔은 다양한 모습으로 존재합니다. 그리움 또한 과거의 행복한 시간을 상실한데서 오는 슬픔의 한 종류이기도 해요.',
                '\n',
                '그 누구도 슬픔을 좋아하는 사람은 없을 거예요. 하지만 많은 심리학자들이 이런 말을 합니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithQuote
              type="light_blue"
              textList={['밝음이나 즐거움을 인지하기 위해서는 어둠이나 슬픔을 알아야 한다']}
              normal
              my
            />
          ),
        },

        {
          component: <TextWithBold texts={['이렇듯, ', '슬픔 또한 우리를 돕기 위한 감정입니다.']} />,
        },
      ],
      buttonText: '슬픔은 싫지만, 우리에게 도움이 되는군요',
    },
    {
      title: '슬픔이 유발하는 신체반응',
      texts: [
        {
          component: <MessageBlock type="gray" textList={[['총 맞은 것처럼, \n 가슴이 너무 아파.']]} my />,
        },
        {
          component: (
            <div className="text-[#3A3C40]">
              <p>
                <span className="font-bold">코</span>
                <span>끝이 찡하고 시큰해지며,</span>
              </p>
              <p>
                <span className="font-bold">목</span>
                <span>이 메고,</span>
              </p>
              <p>
                <span className="font-bold">가슴</span>
                <span>이 뻥 뚫린 것처럼 공허한 오늘,</span>
              </p>
              <p>
                <span className="font-bold">발걸음</span>
                <span>이 무겁다.</span>
              </p>
            </div>
          ),
        },
        {
          text: [
            <br />,
            '슬픔을 표현할 때 쓰이는 표현들을 보면, 놀랍게도 우리는 이미 우리 몸에서 느껴지는대로 슬픔을 표현하고 있었다는 사실을 발견할 수 있어요.',
            <br />,
            <br />,
            '정답은 없어요. 슬픔이 우리 몸에서 나타나는 반응은 정말로 다양하거든요. 그러니 얼마든지 나의 방식대로 느끼고, 표현해 보아도 좋아요.',
          ],
        },
      ],
      buttonText: '슬픔을 어떻게 표현해 보면 좋을까요',
    },
    {
      title: '감정 알아차리기 - 슬픔',
      texts: [
        {
          text: '아래는 지난 번 ‘감정에 이름붙이기’에서 다뤘던 ‘나는 언제 슬픈 감정을 느끼는가?’에 대한 내용이에요.',
        },
        {
          component: <UserSituationEmotionBox emotion="슬픔" />,
        },
        {
          text: '이번에도 여기에 더해서, 슬픈 감정을 느낄 때 몸의 느낌을 함께 살펴 볼게요. 신체반응을 통해 감정을 알아차리고 이름을 붙이는 과정을 통해 스스로의 감정을 다루는 법에 익숙해지실 거예요.',
        },
      ],
      buttonText: '슬픈 감정을 느낄 때 몸의 느낌은 어떨까요?',
    },
    {
      title: '슬픔이 유발하는 신체반응',
      texts: [
        {
          component: (
            <div className="my-[30px] min-h-[262.4px] w-full">
              <img src={wb3_6_2} alt="empty" className="h-full w-full max-w-[390px] mx-auto" />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '어떤가요? 여러분이 슬픔을 느낄 때와 비슷한 느낌인 것 같나요? 우리는 ',
                '슬프면 흔히 숨쉬기 답답하며 온몸이 무겁고 심하게는 통증이나 차갑게 굳은 느낌',
                '이 들기도 합니다.',
                '\n',
                '실제로 이러한 신체반응은 ',
                '슬픔과 통증을 조절하는 호르몬이 동일',
                '하기 때문에 나타나게 됩니다. 이러한 호르몬의 균형이 깨졌을 때, 슬픔과 함께 다양한 신체감각이 느껴지는 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '정말 가슴이 ‘아픈’ 거네요...!',
    },
    {
      title: '나의 감정 알아차리기',
      texts: [
        {
          component: <ExpressEmotionInfo />,
        },
        {
          text: '이번에도 위의 방법을 활용해서 슬픔을 표현하는 예시를 보여드릴게요.',
        },
        {
          component: (
            <div className="my-[40px]">
              <MessageBlock
                textList={[{ title: '슬픔', text: ['우울해요, 외로워요, 무기력해요'] }]}
                type="blue_border"
                margin="mb-[10px]"
              />
              <MessageBlock
                textList={[{ title: '무슨 사건이 있었나요?', text: ['전학을 온 첫 날이다. 밥을 혼자 먹었다.'] }]}
                type="gray"
                margin="mb-[10px]"
              />
              <MessageBlock
                textList={[
                  {
                    title: '어떻게 슬펐나요?',
                    text: [
                      '예전 학교에서의 즐거웠던 생활이 그립고, 외로웠다. 하루종일 몸이 축 처지고 어깨가 아픈 거 같다.',
                    ],
                  },
                ]}
                type="white_dotted"
              />
            </div>
          ),
        },
        {
          text: '이렇게 표현할 수도 있을 거예요.',
        },
        {
          component: (
            <div className="my-[40px]">
              <MessageBlock
                textList={[{ title: '슬픔', text: ['우울해요, 외로워요, 무기력해요'] }]}
                type="blue_border"
                margin="mb-[10px]"
              />
              <MessageBlock
                textList={[
                  {
                    title: '무슨 사건이 있었나요?',
                    text: [
                      '호감이 가는 친구에게 용기내서 영화를 보러 가자고 물어봤는데 바쁜 일이 있어서 안 될 것 같다며 거절당했다.',
                    ],
                  },
                ]}
                type="gray"
                margin="mb-[10px]"
              />
              <MessageBlock
                textList={[
                  {
                    title: '어떻게 슬펐나요?',
                    text: [
                      '가슴이 답답하고 숨이 잘 안 쉬어진다. 눈꺼풀이 떨리고 목이 메인다. 이런 게 비참한건가...? 내 마음이 상처받은 것 같다.',
                    ],
                  },
                ]}
                type="white_dotted"
              />
            </div>
          ),
        },
        {
          text: '예시를 참고해서 오늘 혹은 최근 일주일 간 슬픔을 느꼈던 때에 대하여 여러분이 직접 적어볼게요. 특별하게 생각나는 경험이 없다면, 슬픈 일이 있을 때 어떻게 느낄 것인지 상상해서 적어보아도 좋아요.',
        },
      ],
      buttonText: '적어보기',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={2}
          title="최근에 언제 슬펐나요?"
          placeholder="장소, 시기, 인물 등 &#13;&#10;상황을 객관적으로 적어보세요."
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={2}
          title="어떻게 슬펐나요?"
          placeholder="당시에 느낀 감정이나 신체적 반응을 &#13;&#10;자세히 기록해 보세요."
          buttonText="다음"
        />
      ),
    },
    {
      title: '어떠셨나요?',
      texts: [
        { component: <UserEmotionRecord emotion="슬픔" /> },
        {
          text: '우리는 다양한 상황에서 슬픔을 느낍니다.',
        },
        {
          component: (
            <TextWithDot
              texts={[
                '가까운 사람을 잃었을 때',
                '거절당하거나 무시당했을 때',
                '최선을 다했으나 목표를 달성하지 못했을 때',
              ]}
              margin="my-[30px]"
            />
          ),
        },
        {
          text: [
            '슬픔을 알아차려보세요. 가벼운 느낌이더라도 잠시 멈추고 바라봐 보세요. 그리고 이름을 붙여 보세요. 감정을 판단 없이 있는 그대로 바라보는 겁니다.',
            <br />,
            <br />,
          ],
        },
        {
          component: (
            <TextWithBold
              texts={[
                '그러다 보면 그 감정이 얼마나 강하든지 간에 폭풍우처럼 불어왔다가 어느새 강도가 약해질 거예요. ',
                '어떤 감정이 찾아와도 결국 지나갈 것을 안다는 것만으로도 그 감정을 다루는 것이 쉬워질 것',
                '이랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '용기내 볼 수 있을 것 같아요',
    },
    {
      title: '슬픔의 기능',
      texts: [
        {
          text: [
            '이렇게 실제로도 우리를 아프게 하는 슬픈 감정은 당연히 모두에게 피하고 싶은 감정일 것입니다. 하지만 앞서 이야기했듯이 슬픔 또한 우리를 돕는 기능을 한답니다.',
            <br />,
            <br />,
          ],
        },
        {
          component: <p className="font-bold text-[17px] leading-[28px]">기능</p>,
        },
        {
          component: (
            <TextWithDot
              texts={[
                '우리에게 도움과 안정이 필요한 것을 다른이에게 보여주어요',
                '더이상 도움이 되지 않는 활동을 멈추게 해줘요',
                '어려운 사건 이후에 쉬고 회복하도록 해주어요',
              ]}
              margin="mb-[30px]"
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우리는 ',
                '슬픔을 느낌으로써 활동을 멈추고 훗날 회복하기 위한 에너지를 비축하게 됩니다.',
                ' 하지만 슬픔을 느끼는 그 순간에 너무나도 힘들 수도 있어요. 그럴 때는 다음의 말을 떠올려보세요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithQuote
              type="light_blue"
              textList={['영원한 감정은 없다. 감정은 마치 날씨처럼 다가오고, 또한 지나갈 것이다']}
              normal
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '맞아요. 매번 다 지나갔던 거 같아요.',
    },
    {
      fullImage: wb3_6_3,
      height: 253,
      title: '오늘은 여기까지 다루도록 해요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '기쁨과 슬픔, 두 가지 감정에 대해 이야기해 보았네요. 아직 감정에 따른 몸의 감각을 느끼기 어려울 수도 있어요. 괜찮아요. ',
                '부정적이거나 강렬한 감정을 다루는 것은 어려울 수 있습니다.',
                ' 스스로 감정을 알아차리고자 시도한 것만으로 뿌듯함을 느끼셔도 좋아요.',
                '\n',
                '오늘 여러분의 하루는 어떤 하루였나요? 내일 하루는 어떤 하루가 될 예정인가요? ',
                '하루 동안 기쁨과 슬픔이 언제 느껴지는지 관찰하고 감정노트에 적어보세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '이번 시간 마치기',
      submit: 'Sadness',
    },
  ],
  [
    {
      title: '감정 알아차리기 - 불쾌함',
      subtitle: '지난 시간에는 기쁨과 슬픔에 대해 알아봤어요.',
      fullImage: wb3_7_3,
      height: 253,
      texts: [
        {
          text: '안녕하세요, 오늘도 잘 찾아오셨네요. 지난 시간에는 우리가 기쁘고 슬플 때 몸의 느낌이 어떤지 알아차려보는 연습을 했습니다. 지난 시간 이후 하루동안 수많은 감정이 여러분을 스쳐지나갔을 거예요. 그 감정들 중에 기쁨과 슬픔이 있었나요?',
        },
      ],
      buttonText: '그랬던 것 같기도 해요!',
    },
    {
      title: '감정 알아차리기 - 불쾌함',
      fullImage: wb3_7_1,
      height: 253,

      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘은 불쾌함과 화남, 그리고 내일은 마지막으로 두려움에 대해 살펴볼 겁니다. 먼저 불쾌함이에요.',
                '\n',
                '기쁨이나 슬픔과는 다르게 어쩐지 생소하게 들렸을 수도 있어요. 이 감정은 ',
                '싫다고 여겨지는 것에 대한 반감의 감정',
                '이에요. 꺼림칙해서 피하고 싶은 상황이나 대상(음식, 냄새, 촉감 등)을 만났을 때 우리는 불쾌하고 거부감이 든다고 이야기합니다.',
                '\n',
                '여러분은 언제 불쾌함이란 감정을 느끼나요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '지난 번에 적어봤던 것 같아요!',
    },
    {
      title: '감정 알아차리기 - 불쾌함',
      texts: [
        {
          text: '아래는 지난 시간에 여러분이 적어주신 불쾌함을 느끼는 순간들이에요.',
        },
        {
          component: <UserSituationEmotionBox emotion="불쾌함" />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '불쾌함에는 까다로움, 예민함, 혐오나 역겨움과 같은 반감의 감정들이 있는데요. 우리는 불쾌함이라는 감정을 통해 ',
                '우리를 해롭게 하는 것으로부터 신체적, 사회적으로 스스로를 보호',
                '하죠. 때로는 실패에 따른 절망감을 경멸이나 혐오의 감정으로 전환하여 자신을 가혹하게 채찍질하거나 누군가에게 복수를 하는 것처럼 건강하지 못한 방향으로 연결되는 경우도 있습니다. 하지만 반대로, 우리는 ',
                '불쾌함이란 감정을 나를 이해하는 데 활용',
                '할 수 있어요. 불쾌함을 통해 ',
                '우리는 내가 무엇을 좋아하고, 무엇을 싫어하는지 알 수 있기 때문',
                '입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '불쾌함 또한 중요한 감정이네요',
    },
    {
      title: '불쾌함이 유발하는 신체반응',
      texts: [
        {
          text: '이런 불쾌함은 다양한 감정과 합쳐져 불쾌함을 느낀 대상에 특별한 인상을 남기게 되는데요. 예를 들어, 불쾌함이 두려움과 합쳐지면 섬뜩하다고 느끼기도 하며, 분노와 합쳐지면 매우 강하게 싫어하는 감정인 혐오로 이어지기도 해요. 그렇다면, 불쾌함을 느꼈을 때 우리 몸에는 어떤 반응이 일어나는지 봅시다.',
        },
        {
          component: (
            <div className="my-[30px] min-h-[300.18px] w-full">
              <img src={wb3_7_2} alt="empty" className="h-full w-full max-w-[390px] mx-auto" />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '불쾌함은 가장 흔하게는 고약한 냄새나 맛없는 음식과 같이 우리 몸에 해롭거나 반감이 드는 물질에 접촉했을 때 드는 감정이에요. 따라서 ',
                '대표적으로 위장관이 반응하여 메스껍고 역한 느낌',
                '이 듭니다. 우리는 이 해로운 느낌을 원하지 않는 상황이나 피하고 싶은 사람을 만났을 때에도 느낄 수 있죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: '불쾌함은 기본적으로 거부감이 드는 거군요',
    },
    {
      title: '나의 감정 알아차리기',
      texts: [
        {
          component: <ExpressEmotionInfo />,
        },
        {
          text: '제가 먼저 위의 방법을 활용해서 불쾌함을 표현해볼게요. 부담을 느낄 필요는 없습니다. 감정은 내가 느끼는 것을 자연스럽게 표현하면 되는 거예요.',
        },
        {
          component: (
            <div className="my-[40px]">
              <MessageBlock
                textList={[{ title: '불쾌함', text: ['싫어요, 거슬려요, 실망스러워요'] }]}
                type="blue_border"
                margin="mb-[10px]"
              />
              <MessageBlock
                textList={[
                  {
                    title: '무슨 사건이 있었나요?',
                    text: ['점심을 먹으러 들어간 식당 테이블이 끈적하고 이상한 냄새가 났다.'],
                  },
                ]}
                type="gray"
                margin="mb-[10px]"
              />
              <MessageBlock
                textList={[
                  {
                    title: '어떻게 불쾌했나요?',
                    text: ['속이 울렁거렸다. 검색해서 찾아간 곳인데 엄청 실망스러웠다.'],
                  },
                ]}
                type="white_dotted"
              />
            </div>
          ),
        },
        {
          text: '이렇게 표현해볼 수도 있어요.',
        },
        {
          component: (
            <div className="my-[40px]">
              <MessageBlock
                textList={[{ title: '불쾌함', text: ['싫어요, 거슬려요, 실망스러워요'] }]}
                type="blue_border"
                margin="mb-[10px]"
              />
              <MessageBlock
                textList={[
                  {
                    title: '무슨 사건이 있었나요?',
                    text: ['사람이 많은 모임 자리에 갔다.'],
                  },
                ]}
                type="gray"
                margin="mb-[10px]"
              />
              <MessageBlock
                textList={[
                  {
                    title: '어떻게 불쾌했나요?',
                    text: [
                      '어색하고 어깨가 굳었다. 부담스럽고 따분했다. 식은땀이 나고 손에 땀이 났던 걸 보니 긴장도 많이 했던 것 같다.',
                    ],
                  },
                ]}
                type="white_dotted"
              />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '지난 코스에서 배웠듯이 ',
                '우리가 감정을 느끼는 것의 기저에는 우리의 자동적인 생각이 깔려 있습니다.',
                ' 첫 번째 불쾌함의 경우 누구라도 역할 수 있는 상황이었겠지만, 두 번째 상황에서는 사람마다 느끼는 감정이 달랐을 거예요. 저는 꽤나 내향적인 면이 있어서 사람이 많은 모임에 가면 사교적인 모습을 유지하기 위해 꽤 노력이 필요한 편이었어요.',
                '\n',
                '이번에는 여러분 차례입니다. 오늘 혹은 최근 일주일 간 불쾌함을 느꼈던 때에 대하여 여러분이 직접 적어볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '적어보기',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={2}
          title="최근에 언제 불쾌했나요?"
          placeholder="장소, 시기, 인물 등 &#13;&#10;상황을 객관적으로 적어보세요."
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={2}
          title="어떻게 불쾌했나요?"
          placeholder="당시에 느낀 감정이나 신체적 반응을 &#13;&#10;자세히 기록해 보세요."
          buttonText="다음"
        />
      ),
    },
    {
      title: '어떠셨나요?',
      texts: [
        { component: <UserEmotionRecord emotion="불쾌함" /> },
        {
          component: (
            <TextWithBold
              texts={[
                '우리가 경험하는 모든 것은 즐겁거나 불쾌하거나 중립적인 감정을 불러일으킵니다. 우리는 즐거운 느낌을 유발하는 경험은 자연스럽게 반복하고자 하는 반면에, ',
                '불쾌한 느낌을 유발하는 경험은 피함',
                '으로써 그런 경험이 일어나는 것을 막으려고 해요. 마치 더러운 오물이나 역겨운 냄새를 마주하면 불쾌함을 느껴 피하는 것과 같습니다.',
                '\n',
                '이렇게 ',
                '불쾌함',
                '은 ',
                '우리에게 해로울 수 있는 자극을 알아차리고 피할 수 있게 해주는 기능',
                '이 있습니다. 하지만 무작정 불쾌한 감정을 느끼고 싶지 않아서, 불쾌함을 유발하는 모든 상황을 피하게 된다면 어떻게 될까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋은 거 아닐까요...?',
    },
    {
      title: '불쾌함을 회피할 때',
      texts: [
        {
          text: [
            '이번에는 여러분이 모르는 사람이 많은 모임 자리에 나갔다가 어색하고 즐겁지 못했던 경험이 있다고 상상해봅시다. 그리고 얼마 후, 지난 번과 비슷한 모임에 다시 초대되었습니다. ',
            <br />,
          ],
        },
        {
          component: (
            <MessageBlockWithTail
              type="black"
              textList={[
                [
                  '지난 번 모임은 너무 어색하고 기분이 좋지 않았어. 이번에도 그럴 것 같으니 핑계를 대고 나가지 말아야겠다.',
                ],
              ]}
              my
            />
          ),
        },

        {
          component: (
            <TextWithBold
              texts={[
                '지난 번의 불쾌했던 감정을 다시 경험하고 싶지 않았던 여러분은 새로운 경험을 회피해 버렸어요. 습관적으로 불쾌함을 느꼈던 경험과 비슷한 일을 회피하는 선택만을 하게 된다면, ',
                '우리를 불쾌하게 만들었던 원인과 진정으로 내면의 욕구가 원하는 것이 무엇인지 알아차릴 기회',
                '를 잃어버리게 됩니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock type="gray" textList={[['우리 삶에서 불쾌한 일이 발생하는 것을 완전히 막을 수 없어요']]} my />
          ),
        },

        {
          component: (
            <TextWithBold
              texts={[
                '따라서 ',
                '불쾌함에 능숙하게 대처하는 방법을 배우기 위해',
                '서는 먼저 ',
                '나의 불쾌한 감정을 있는 그대로 바라보고 알아차려 보는 연습',
                '이 도움이 된답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '이번 시간 마치기',
      submit: 'Discomfort',
    },
  ],
  [
    {
      title: '감정 알아차리기 - 화남',
      fullImage: wb3_8_1,
      height: 253,
      texts: [
        {
          text: '이번에는 화가 나는 감정인 분노에 대해서 알아볼게요. ',
        },
        {
          component: (
            <MessageBlockWithTail type="black" textList={[['아 뚜껑 열리네'], ['열받는다'], ['짜증나 진짜!']]} my />
          ),
        },

        {
          text: '우리는 일상 속에서 다양한 화남의 감정을 경험하고 있어요. 짜증이나 신경질이 나는 것부터 격분하고 욱하는 것까지 모두 다양하게 표현되는 화남의 감정들입니다.',
        },
      ],
      buttonText: '화가 나지 않으면 좋을텐데 말이에요',
    },
    {
      title: '감정 알아차리기 - 화남',
      texts: [
        {
          text: '여러분은 주로 언제 화가 나나요?',
        },
        {
          component: <TextWithDot texts={['무시당했을 때', '불공평한 일을 당했을 때', '피해를 입었을 때']} />,
        },
        {
          text: '다양한 상황에서 우리는 화가 나는 것을 느끼게 됩니다. 때로는 이런 생각이 들지도 몰라요.',
        },
        {
          component: (
            <MessageBlockWithTail
              type="white_dotted"
              textList={[['화날 만한 일을 애초에 겪지 않는다면 얼마나 좋을까?']]}
              my
            />
          ),
        },

        {
          text: '하지만 살면서 화가 나는 일이 발생하는 것을 완전히 막을 순 없어요. 그러나 화남의 감정 또한 우리를 도우려고 있다는 것을 알고 계신가요? 우리는 이 감정을 잘 활용하여 우리에게 유익한 것을 이끌어 낼 수 있어요.',
        },
        {
          component: <p className="font-bold text-[17px] leading-[28px] mt-[30px] text-[#26282C]">기능</p>,
        },
        {
          component: (
            <TextWithDot
              texts={[
                '우리가 원하는 것을 얻기 위한 에너지를 준다',
                '우리가 피해를 입는 것으로부터 보호해준다',
                '누군가 우리를 부당하게 대하는 것을 막아준다.',
              ]}
              margin="mb-0"
            />
          ),
        },
      ],
      buttonText: '화도 낼 줄 알아야 하는 거군요',
    },
    {
      title: '감정 알아차리기 - 화남',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '아래는 지난 번 ',
                '감정에 이름 붙이기',
                '에서 다뤘던 ',
                '“나는 언제 화남의 감정을 느끼는가?”',
                '에 대한 내용이에요.',
              ]}
            />
          ),
        },
        {
          component: <UserSituationEmotionBox emotion="화남" />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '화남이라는 감정을 먼저 알아차리는 것이 중요한 이유는 ',
                '감정이 이끄는대로 충동적인 행동을 하는 것을 예방',
                '할 수 있기 때문이에요. 여러분, ',
                '“나는 화가 났을 때 어떤 행동을 할까?”',
                '에 대해서 알고 계신가요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '어렴풋이 알 것 같아요',
    },
    {
      title: '화남이 유발하는 행동충동',
      image: wb3_8_2,
      texts: [
        {
          component: <p className="font-semibold text-[20px] leading-[24px] mb-[15px] text-[#26282C]">상상해 보세요</p>,
        },
        {
          text: '여러분이 자려고 누웠는데, 옆집에서 시끄러운 음악소리가 들리기 시작했다고 상상해봅시다. 쿵쾅쿵쾅쿵쾅쿵쾅. 분노가 차오른 당신은 침대를 박차고 일어나 옆집으로 달려가 소리치는 상상을 합니다. ',
        },
        {
          component: <MessageBlockWithTail type="black" textList={[['노래 좀 꺼 !!!']]} my />,
        },

        {
          text: '선택권은 여러분에게 있습니다. 어떤 선택을 하실 건가요?',
        },
        {
          component: <ChooseRoute textList={['달려가서 문을 두드리고 소리 질러야겠어', '일단 진정해보자']} />,
        },
      ],
    },
    {
      replaceComponent: (
        <PageBySelected
          info={[
            {
              title: '화남이 유발하는 행동충동',
              texts: [
                {
                  component: (
                    <p className="font-semibold text-[20px] leading-[24px] mb-[15px] text-[#26282C] mt-[30px]">
                      행동충동에 따랐을 때
                    </p>
                  ),
                },
                {
                  text: '결국 옆집으로 달려가 소리를 질렀습니다. 깜짝 놀란 옆집 이웃은 노래가 들릴 줄 몰랐다며 진심으로 사과했어요. 당신은 만족스럽게 돌아와 다시 침대에 누웠습니다. 그런데 이상하게도 머리를 대고 눕자마자 이런 생각이 스멀스멀 올라옵니다.',
                },
                {
                  component: (
                    <MessageBlockWithTail
                      type="white_dotted"
                      textList={[
                        ['옆집 사람 굉장히 친절하던데, 내가 너무 소리를 질렀나? 그 사람이 나를 어떻게 생각할까?'],
                      ]}
                      my
                    />
                  ),
                },
                {
                  text: '다음날 아침 기분이 좋지 않게 일어납니다. 죄책감이 속삭입니다.',
                },
                {
                  component: (
                    <MessageBlockWithTail
                      type="white_dotted"
                      textList={[['“그렇게 화를 냈던 건 맞지 않았던 것 같은데… 다시 가서 사과를 해야할까?”']]}
                      my
                    />
                  ),
                },

                {
                  text: [
                    '출근을 하는 길에 옆집 이웃을 마주쳤어요. 어젯 밤의 행동 때문에 반갑게 인사하기가 민망하고 부끄러워 어색하게 자리를 피했어요.',
                    <br />,
                    <br />,
                    '만약 여러분이 어젯밤에 감정에 따라 충동적으로 행동하지 않았다면 어떻게 됐을까요?',
                  ],
                },
              ],
              buttonText: '상황이 달라지지 않았을까요?',
            },
            {
              title: '화남이 유발하는 행동충동',
              texts: [
                {
                  component: (
                    <p className="font-semibold text-[20px] leading-[24px] mb-[15px] text-[#26282C] mt-[30px]">
                      감정에 따라 행동하지 않았을 때
                    </p>
                  ),
                },
                {
                  text: [
                    '감정적인 폭발을 참고 여러분은 소음이 조금 더 적게 들리는 거실에 가서 스스로를 진정시켰습니다. 여전히 잠들기 쉽지 않았지만, 운이 좋게도 노래가 잠시 후에 멈췄습니다. 그 후 여러분은 다시 방으로 돌아와 잠에 들 수 있었어요.',
                    <br />,
                    <br />,
                    '다음날 아침 출근을 하며 옆집 이웃을 마주쳤어요. 어젯밤의 일에 대해서 조심스럽게 이야기를 꺼냈습니다. 이웃이 이렇게 이야기 합니다.',
                  ],
                },
                {
                  component: (
                    <MessageBlockWithTail
                      type="gray"
                      textList={[
                        [
                          '음악을 틀었다가 옆집에 들릴지도 모른다는 생각에 금방 껐어요! 정말 죄송합니다. 다음부터는 정말 조심할게요!',
                        ],
                      ]}
                      my
                    />
                  ),
                },
                {
                  text: '원만하게 문제가 해결되고 즐거운 발걸음으로 아침을 시작합니다..',
                },
              ],
              buttonText: '두 상황의 결말이 굉장히 다르네요',
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <PageBySelected
          info={[
            {
              title: '화남이 유발하는 행동충동',
              texts: [
                {
                  component: (
                    <p className="font-semibold text-[20px] leading-[24px] mb-[15px] text-[#26282C] mt-[30px]">
                      감정에 따라 행동하지 않았을 때
                    </p>
                  ),
                },
                {
                  text: [
                    '감정적인 폭발을 참고 여러분은 소음이 조금 더 적게 들리는 거실에 가서 스스로를 진정시켰습니다. 여전히 잠들기 쉽지 않았지만, 운이 좋게도 노래가 잠시 후에 멈췄습니다. 그 후 여러분은 다시 방으로 돌아와 잠에 들 수 있었어요.',
                    '\n',
                    '다음날 아침 출근을 하며 옆집 이웃을 마주쳤어요. 어젯밤의 일에 대해서 조심스럽게 이야기를 꺼냈습니다. 이웃이 이렇게 이야기 합니다.',
                  ],
                },
                {
                  component: (
                    <MessageBlockWithTail
                      type="gray"
                      textList={[
                        [
                          '음악을 틀었다가 옆집에 들릴지도 모른다는 생각에 금방 껐어요! 정말 죄송합니다. 다음부터는 정말 조심할게요!',
                        ],
                      ]}
                      my
                    />
                  ),
                },

                {
                  text: '원만하게 문제가 해결되고 즐거운 발걸음으로 아침을 시작합니다. 만약 어젯밤의 분노를 이기지 못하고 당장 옆집으로 달려가 화를 냈다면 어떻게 되었을까요?',
                },
              ],
              buttonText: '상황이 달라졌을 것 같아요',
            },
            {
              title: '화남이 유발하는 행동충동',
              texts: [
                {
                  component: (
                    <p className="font-semibold text-[20px] leading-[24px] mb-[15px] text-[#26282C] mt-[30px]">
                      행동충동에 따랐을 때
                    </p>
                  ),
                },
                {
                  text: [
                    '결국 옆집으로 달려가 소리를 질렀습니다. 깜짝 놀란 옆집 이웃은 노래가 들릴 줄 몰랐다며 진심으로 사과했어요. 당신은 만족스럽게 돌아와 다시 침대에 누웠습니다. 그런데 이상하게도 머리를 대고 눕자마자 이런 생각이 스멀스멀 올라옵니다.',
                  ],
                },
                {
                  component: (
                    <MessageBlockWithTail
                      type="white_dotted"
                      textList={[
                        ['옆집 사람 굉장히 친절하던데, 내가 너무 소리를 질렀나? 그 사람이 나를 어떻게 생각할까?'],
                      ]}
                      my
                    />
                  ),
                },
                {
                  text: '다음날 아침 기분이 좋지 않게 일어납니다. 죄책감이 속삭입니다.',
                },
                {
                  component: (
                    <MessageBlockWithTail
                      type="white_dotted"
                      textList={[['“그렇게 화를 냈던 건 맞지 않았던 것 같은데… 다시 가서 사과를 해야할까?”']]}
                      my
                    />
                  ),
                },
                {
                  text: '출근을 하는 길에 옆집 이웃을 마주쳤어요. 어젯 밤의 행동 때문에 반갑게 인사하기가 민망하고 부끄러워 어색하게 자리를 피했어요.',
                },
              ],
              buttonText: '두 상황의 결말이 굉장히 다르네요',
            },
          ]}
        />
      ),
    },
    {
      title: '행동, 우리가 선택할 수 있어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이 이야기는 왜 감정이 시키는대로만 항상 행동하면 안 되는지를 잘 보여주는 예시입니다. 무조건 화를 내는 것을 참으라는 의미는 아니에요. 다만 ',
                '화가 난 것을 알아차리고 잠시 멈출 수 있다면, 우리에게 도움이 되는 행동을 선택할 기회를 얻게 되는 것',
                '입니다.',
                '\n',
                '감정을 느끼면, 잠시 멈추고 바라보는 거예요. 충동적인 행동이 나오기 이전에 어떠한 판단이나 회피도 없이 그 감정을 그대로 느껴보세요. 심리학자 빅터프랭클은 다음과 같은 말을 했죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[['자극과 반응 사이에는 빈 공간이 있으며, 그곳에서의 선택이 우리 삶의 질을 결정짓는다']]}
              type="gray"
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '화가 나면 잠시 멈추어 볼게요',
    },
    {
      title: '화남이 유발하는 신체반응',
      texts: [
        {
          text: '그렇다면 이번에는 화가 이끄는 대로 행동하기 전에 재빨리 내가 화가 났다는 사실을 알아차릴 수 있는 단서에 대해서 알아보도록 해요.',
        },
        {
          component: (
            <div className="my-[30px] min-h-[297px] w-full">
              <img src={wb3_8_3} alt="empty" className="h-full w-full max-w-[390px] mx-auto" />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '화가 나면 심박수와 혈압이 높아지고, 얼굴과 상체에 열이 오르며 근육이 긴장됩니다. 이러한 몸의 변화는 대표적으로 ',
                '분노의 호르몬이라 불리는 노르아드레날린',
                ' 때문인데요. 이 호르몬은 화가 난 상황에서 분비되어 이러한 ',
                '신체 반응을 일으킴과 동시에 주의와 충동성을 억제하는 뇌부위에 영향',
                '을 미치기도 합니다. 화가 나면 나도 모르게 충동적으로 행동하여 비합리적인 판단을 내리기 쉬운 이유, 이제 이해되시나요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '어떻게 하면 화를 다스릴 수 있을까요?',
    },
    {
      title: '나의 감정 알아차리기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '그렇다고 해서 화가 나는 상황을 항상 참아야 하는 걸까요? 그렇진 않습니다. 우리가 ‘화’라는 감정을 이해하는 것은 결국 ',
                '분노를 조절하기 위함',
                '입니다. 적재적소에 합리적으로 행동하고 후회할 만한 충동 행위를 막기 위해서는 평소에 내가 언제 화가 나는지를 파악해야 하죠.',
                '\n',
                '감정노트를 통해 나의 화남을 표현해 본다면, 객관적으로 화가 났던 상황을 바라볼 수 있음과 동시에 그 당시의 느낌을 자연스레 떠올리게 돼요. 그렇게 같은 상황이 반복되었을 때 화가 났다는 사실을 인지하고 전보다 나에게 도움이 되는 선택을 할 수 있게 됩니다.',
              ]}
            />
          ),
        },
        {
          component: <ExpressEmotionInfo />,
        },
        {
          text: '이번에도 위의 방법을 활용해서 최근에 화가 났던 때를 떠올리며 나의 감정을 표현해볼까요?',
        },
      ],
      buttonText: '이런 식으로 하면 되는거군요!',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={2}
          title="최근에 언제 화가났나요?"
          placeholder="장소, 시기, 인물 등 &#13;&#10;상황을 객관적으로 적어보세요."
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={2}
          title="어떻게 화가났나요?"
          placeholder="당시에 느낀 감정이나 신체적 반응을 &#13;&#10;자세히 기록해 보세요."
          buttonText="다음"
        />
      ),
    },
    {
      title: '어떠셨나요?',
      texts: [
        { component: <UserEmotionRecord emotion="화남" /> },
        {
          text: [
            '감정기록을 지속하다보면, 화가 나면 어떤 행동을 충동적으로 하고 있었는지 공통점이 보일 겁니다. 앞으로 그런 순간이 오면, 기억하세요. 잠시 멈추고, 감정이 그대로 머물도록 바라보세요. 잠시 멈추는 것에 익숙해지셨다면, 무작정 화남을 표출하기 보다는 진짜 나에게 도움이 될 수 있는 행동을 선택하세요.',
            <br />,
            <br />,
            '처음에는 어려울 수 있습니다. 또한 누군가의 도움이 필요할 수도 있어요. 그렇다면 주저하지 말고 인사이드 내의 코치와 상담사 선생님들께 도움을 청하셔도 좋습니다.',
          ],
        },
      ],
      buttonText: '노력해 볼게요!',
    },
    {
      fullImage: wb3_8_4,
      height: 253,
      title: '다음 시간에는',
      texts: [
        {
          text: [
            "이렇게 불쾌함과 화남까지 네 가지 대표 감정에 대하여 살펴보았어요. 다음 시간에는 마지막으로 ‘두려움'에 대하여 알아보겠습니다.",
            <br />,
            <br />,
            '다음 수업 전까지 불쾌함과 분노가 느껴지는 상황을 눈여겨 보세요.그 때의 신체감각은 어땠는지, 나의 생각과 반응은 어땠는지 주의를 기울여 살펴보고 이를 감정노트에 적어보세요.',
          ],
        },
      ],
      buttonText: '이번 시간 마치기',
      submit: 'Anger',
    },
  ],
  [
    {
      title: '감정 알아차리기 - 두려움',
      subtitle: '벌써 마지막 수업이네요',
      fullImage: wb3_9_1,
      height: 253,
      texts: [
        {
          text: [
            '벌써 감정 알아차리기 코스의 마지막 날입니다. 여기까지 오시느라 고생 많으셨어요. 지난 시간에는 불쾌함과 화남의 감정을 함께 보았습니다. 이제 마지막 감정인 두려움을 보도록 할게요.',
            <br />,
            <br />,
            '일본의 한 심리상담가가 불안을 다스리는 방법에 대하여 쓴 책의 제목은 다음과 같습니다.',
          ],
        },
        {
          component: <MessageBlock textList={[['걱정을 해서 걱정이 없어지면 걱정이 없겠네']]} type="white_dotted" my />,
        },
        {
          text: "걱정이라는 단어가 세 번이나 나오네요. 우리가 일상 속에서 가장 흔하게 접하는 두려움은 바로 ‘불안'과 ‘걱정'입니다. 하지만 과연 걱정을 한다고 해서 걱정이 사라질까요?",
        },
      ],
      buttonText: '아니요, 그렇지 않은 것 같아요',
    },
    {
      title: '걱정이 눈덩이처럼 불어날 때',
      texts: [
        {
          text: [
            '걱정을 한다고 해서 걱정이 사라지진 않아요. 오히려 걱정이 눈덩이처럼 불어날 때가 많죠. 정말 사소한 일에서 시작한 걱정이 꼬리에 꼬리를 물고 불어난 적이 있지 않나요? 실제로 조그만 기분 변화에도 부정적 사고가 크게 일어나는 사람들이 있습니다. 심리학 연구 결과들에 따르면, 이러한 사람들은 걱정을 하면 상황을 이해하고 해결하는데 도움이 될 거라고 생각하지만, 실제로 부정적인 감정이 증폭될 뿐, 문제를 해결하는 능력은 오히려 감소하는 것으로 나타났어요.',
            <br />,
            <br />,
            '감정은 감정일 뿐입니다. 불안감을 느끼고 걱정이 시작되려 할 때 이를 알아차릴 줄 알아야 해요. ',
          ],
        },
        {
          component: (
            <MessageBlockWithTail
              type="white_dotted"
              textList={[['나는 지금 불안을 느끼고 있고, 걱정을 함으로써 불안을 없애려 하고 있구나.']]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '감정은 없애려고 한다고 해서 없어지는 것이 아닙니다.',
                ' 그렇기 때문에 그저 바라보고 인정할 줄 알아야 합니다. 감정을 알아차리는 순간, 감정의 뒤에 숨어 있던 나의 자동적인 생각을 발견할 수 있을 겁니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '감정노트를 열심히 써봐야겠어요!',
    },
    {
      title: '감정 알아차리기 - 두려움',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '아래는 지난 번 ',
                '감정에 이름 붙이기',
                '에서 다뤘던 ',
                '“나는 언제 두려움의 감정을 느끼는가?”',
                '에 대한 내용이에요.',
              ]}
            />
          ),
        },
        {
          component: <UserSituationEmotionBox emotion="두려움" />,
        },
        {
          text: [
            '이런 상황들에서 느낀 불안감이나 두려움에 대한 감정기록을 되돌아보면, 내가 어떤 생각을 하고 걱정의 골짜기로 굴러들어갔는지 보일 거예요.',
            '그렇다면 앞으로 불안감이나 두려움을 알아차릴 수 있는 신체 반응에 대해 살펴봅시다.',
          ],
        },
      ],
      buttonText: '좋아요!',
    },
    {
      title: '두려움이 유발하는 신체반응',
      texts: [
        {
          text: '두려움을 느끼면 온몸이 긴장되고 열이 달아오르며, 다리가 후들거리는 등의 신체반응이 나타납니다.',
        },
        {
          component: (
            <div className="my-[30px] min-h-[262.83px] w-full">
              <img src={wb3_9_2} alt="empty" className="h-full w-full max-w-[390px] mx-auto" />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우리 뇌가 위험을 감지하고 스스로를 보호하기 위해 전투 태세로 돌입한 겁니다.',
                '\n',
                '그런데 ',
                '실제 위험 상황이 아닌 가상의 위험에도 우리 몸이 전투 태세로 돌입하는 경우',
                '가 있어요. 이를 테면, ',
                '공황장애',
                '나 ',
                '특정공포증',
                '과 같이 ',
                '특정 상황에서 과하게 공포감을 느끼는 경우',
                '입니다. 뿐만 아니라 평소에도 불안감이나 두려운 감정을 쉽게 느끼는 사람은 내면에 숨겨진 부정적인 생각이 두려움을 증폭시키고 있을 수 있어요.',
                '\n',
                '요즘 여러분을 불안하게 만드는 것은 무엇이었나요? 최근에 가장 불안하거나 걱정되었던 때를 떠올려 보세요. 그때 몸의 느낌은 어땠나요? 이번에는 그때 어떤 생각을 했는지도 함께 적어봅시다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '적어보기',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={2}
          title="최근에 언제 두려웠나요?"
          placeholder="장소, 시기, 인물 등 &#13;&#10;상황을 객관적으로 적어보세요."
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={2}
          title="어떻게 두려웠나요?"
          placeholder="당시에 느낀 감정이나 신체적 반응을 &#13;&#10;자세히 기록해 보세요."
          buttonText="다음"
        />
      ),
    },
    {
      title: '어떠셨나요?',
      texts: [
        { component: <UserEmotionRecord emotion="두려움" /> },
        {
          component: (
            <p className="font-semibold text-[#26282C] text-[20px] leading-[24px] mb-[15px]">
              여러분이 두려워하는 것은 무엇인가요?
            </p>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우리는 살면서 다양한 두려움을 느끼고 살아갑니다. 하지만 꼭 부정적인 것만은 아니랍니다. 우리는 두려움을 발판 삼아 더 발전하고 문제를 해결하고자 노력하게 돼요. ',
                '두려움이라는 감정을 받아들이는 건 쉽진 않겠지만, 그래도 두려움에 매몰되기보다는 두려움 또한 감정 그 자체로 바라볼 줄 알아야 한답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '용기내 볼 수 있을 것 같아요',
    },
    {
      title: '두려움을 악화시키는 생각',
      texts: [
        {
          text: [
            '감정노트를 통해 내가 두려움을 느끼는 상황은 주로 어떤 상황인지 되돌아보고, 그때 불현듯 떠오른 생각은 무엇이었는지 되짚어보세요. 그 생각이 비현실적인 것인지 스스로 검증하는 연습을 통해 우리는 불필요한 불안감과 두려움을 낮출 수 있습니다.',
          ],
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['그 생각만 하면 숨 쉬기 힘들어요'],
                ['지하철에서 갑자기 식은 땀이 나고 가슴이 갑갑했어요'],
                ['발표하기 전에는 항상 숨이 가쁘고 어지러워요'],
              ]}
              type="black"
              my
            />
          ),
        },

        {
          text: [
            '불안감을 느끼면 교감신경이 항진되어 얕고 빠른 숨을 쉬게 됩니다. 이 정도가 심하면 가슴이 답답하고 목이 졸리는 느낌 또한 들게 되어 더욱 공포심이 증가하게 돼요. 하지만 많은 사람들이 이러한 호흡의 불편함이 불안감에서 비롯된 것인줄 모르는 경우가 많습니다.',
            <br />,
            <br />,
            '이제 여러분은 숨이 가쁘거나, 몸에 식은땀이 나고 두근거릴 때 이제는 이렇게 생각할 수 있어요.',
          ],
        },
        {
          component: <MessageBlockWithTail textList={[['지금 내가 불안감을 느끼고 있구나']]} type="gray" my />,
        },
        {
          text: '이렇게 나의 감정을 인지하는 것만으로도 훨씬 도움이 될 거예요.',
        },
      ],
      buttonText: '저도 그렇게 해볼게요',
    },
    {
      title: '두려움을 느낄 때',
      fullImage: wb3_9_3,
      height: 253,
      texts: [
        {
          text: [
            '계단을 내려가다가 발을 헛디뎌 넘어질까봐 두렵나요? 열심히 준비하고 있는 시험에서 좋은 성적을 거두지 못할까봐 걱정되나요? 또는 나에게 기대를 하는 친구나 부모님의 기대를 져버리지는 않을까봐 부담스럽고 마음이 무겁지는 않으신가요?',
            <br />,
            <br />,
            '어떤 종류의 두려움이든 괜찮습니다. 두려움이 느껴진다면 감정노트에 기록해 보세요. 그때 나의 신체감각과 마주해보세요. 그리고 두려움과 함께 떠오른 나의 생각을 그대로 바라보세요. 판단하거나 억지로 피하기 보다는 다음과 같이 생각해 보는 거예요.',
          ],
        },
        {
          component: (
            <MessageBlock
              textList={[['두려운 느낌이 드는 건 좋지 않지만, 이 또한 나를 도우려는 감정이야.']]}
              type="gray"
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '이번 시간 마치기',
      submit: 'Fear',
    },
  ],
  [
    {
      title: '흐르는 감정의 물결',
      fullImage: wb3_10_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이때까지 우리는 감정을 알아차리는 방법에 대해서 배웠어요. 그런데 만약 이렇게 알아차린 감정이 너무나 강렬해서 감당하기 어렵다면 어떻게 해야할까요?',
                '\n',
                '나를 한없이 어둠 속으로 끌어내릴만큼 강렬한 감정을 다루는 것은 누구에게나 어려운 일입니다. 자신의 감정을 완벽히 다루는 사람은 더더욱 없고요. 그래서 ',
                '스스로 감정을 알아차리려는 시도를 하는 것 자체만으로 뿌듯함을 느끼셔도 좋아요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '힘이 되는 것 같아요!',
    },
    {
      title: '마음이라는 하늘, 날씨라는 감정',
      image: wb3_10_2,
      texts: [
        {
          text: [
            '우리는 종종 강렬한 감정이 올라왔을 때, 그 감정과 자신을 동일시하기도 합니다. 하지만 감정은 나 자신이 아닙니다. 감정은 그저 나에게 다가와 지나갈 뿐입니다.',
          ],
        },
        {
          component: (
            <p className="mt-[30px] mb-[15px] font-semibold text-[20px] leading-[24px] text-[#26282C]">
              우리 마음은 텅 빈 맑은 하늘이에요
            </p>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '구름과 햇살, 눈과 비바람은 하늘을 왔다가 지나가지만 하늘은 항상 그 자리에 있습니다. 날씨는 하늘을 지나가지만 하늘 그 자체에는 영향을 미치지 않아요.',
                '\n',

                '우리의 감정 또한 마찬가지입니다. ',
                '감정은 우리 마음에 하루에도 수백 번 휘몰아치지만, 결국 지나가는 날씨와 같습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋은 이야기네요!',
    },
    {
      title: '감정의 폭풍우가 나를 덮칠 때',
      texts: [
        {
          text: '때때로 우리는 이런 걱정을 합니다.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['폭풍우처럼 강렬한 감정이 나를 덮쳐 버리면 어쩌지...?']]}
              type="black"
              my
            />
          ),
        },
        {
          text: '물론 감정은 짧은 시간 안에 변화하고 순식간에 나를 강렬해져 나를 덮쳐올 수도 있어요. 하지만 결국 폭풍우는 지나가고, 강도는 약해집니다. 시간이 지나면 그 순간에 대해 이렇게 되돌아볼 수 있을 거예요.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['정말 안 좋은 날씨였어. 그래도 지나갔구나']]}
              type="white_dotted"
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '기억할게요',
    },
    {
      title: '감정의 폭풍우가 나를 덮칠 때',
      image: wb3_10_3,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '물론 강렬한 감정이 일어났을 때, 누군가의 도움이 필요할 경우가 있을 수 있어요. 그런 경우라면 언제든지 전문가에게 도움을 구해보세요. 인사이드에는 전문가 선생님들이 함께 하고 계시답니다.',
                '\n',
                '그러나 대부분의 경우 그저 감정을 관찰하고 지나가도록 두는 것만으로도 충분한 경우가 많습니다. 기록해둔 감정을 놓고 찬찬히 생각해 보세요. 지난 날의 감정을 오늘도 동일하게 느끼고 있나요? 아닐 거예요. ',
                '영원히 지속되는 감정은 없답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '이번 시간 마치기',
    },
  ],
  [
    {
      title: '내 감정 바라보기',
      texts: [
        {
          text: [
            '여기까지 왔다면, 여러분은 감정을 보다 행복한 방향으로 다루는 방법에 대한 기반을 다진 거예요!',
            <br />,
            <br />,
            '가장 중요한 핵심들에 대해 보여 드릴게요.',
          ],
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['불쾌하거나 부정적인 감정이더라도 감정은 우리를 돕기 위한 것이에요.'],
                ['판단하고, 후회하고, 감정이 사라지길 바라는 것은 도움이 되지 않는 방식이에요.'],
                ['감정을 다루는 건강한 방법은 그것을 바라보고 있는 그대로 받아들이는 것입니다.'],
              ]}
              type="gray"
              my
            />
          ),
        },
        {
          text: '감정을 알아차리기 위해 몸의 느낌이 어떤지 느껴보고, 감정에 이름을 붙여보세요.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['나는 지금 심장이 두근거리고, 긴장하고 있구나!']]}
              type="white_dotted"
              my
            />
          ),
        },
        {
          text: '나는 지금 심장이 두근거리고, 긴장하고 있구나!',
        },
      ],
      buttonText: '이제부터 해봐야겠어요',
    },

    {
      replaceComponent: <EmotionSummary />,
    },
    {
      title: '지금 나의 감정은 어떤가요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이제 현재로 돌아와 볼게요. 지금 몸에서 느껴지는 감각은 어떤가요? 어느 부위에서 어떤 느낌이 느껴지나요? 지금 여러분은 어떤 감정을 느끼고 있으신가요?',
                '\n',
                '어떤 감정이 되었든, 여러분이 이전에 적어두었던 감정과는 또 다른 새로운 감정이란 것을 알아차리셨을 거예요.',
                '\n',
                '우리는 아침을 시작하며 오늘 하루는 힘내보자며 기분 좋게 집을 나섰다가도, 저녁이 되면 지치고 피곤한 몸을 이끌고 집에 돌아와 아무런 의욕 없이 침대에 몸을 쓰러뜨리곤 합니다. ',
                '이렇게 하루에도 수없이 많은 감정과 생각이 우리를 찾아오게 되지만, 그것이 영원히 지속되지는 않아요.',
                '\n',
                <span />,
                '이 코스를 마친 여러분은 감정에 매몰되는 내가 아닌, 내가 감정의 주인인 삶을 살아가기 위한 첫 발자국을 떼신 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '저도 감정의 주인으로 지내고 싶어요!',
    },
    {
      title: '앞으로는 이렇게 해 볼게요',
      texts: [
        {
          text: '하루에 10~15분정도 하루의 감정을 되돌아보는 시간을 내어보세요. 그리고 슬픔, 행복, 분노 그 어떤 감정을 느끼더라도 말해보세요. ',
        },
        {
          component: (
            <MessageBlock
              textList={[['그래도 괜찮아. 감정은 나를 도우려고 있는거고, 이 또한 지나갈거야']]}
              type="gray"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '조용히 감정을 되돌아보는 시간에 언제든지 ',
                '감정노트',
                '를 꺼내어 기록해 보세요.',
                '\n',
                '때로는 너무나 강렬하거나 어려운 감정에 마음이 힘들 수 있어요. 저 또한 힘든 시기가 많았답니다. 하지만 지금은 또 평온한 나날들을 보내고 있죠. 인생은 항상 변하고, ',
                '우리들은 단지 변화의 물결 위에서 조용히 균형을 잡는 방법을 익혀가며 살아갈 뿐',
                '입니다. ',
                '누구든 균형을 잃고 뒤집히거나 심해 속으로 가라앉을 때도 있을 거예요.',
                ' 하지만 괜찮아요. ',
                '결국 다시 균형을 잡고 수면 위로 올라오게 되죠.',
                ' 삶이란 애초에 그러한 굴곡이 있는 것인 걸요.',
                '\n',
                '다음 코스에서는 보다 더 능숙하게 감정을 다스리기 위한 연습을 할 거예요. 아래와 같은 내용들을 다뤄볼 거랍니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <TextWithDot
              texts={['부정적이거나 강렬한 감정을 다루는 법', '기분이 좋아지는 활동 찾기']}
              margin="my-[30px]"
            />
          ),
        },
        {
          text: '여러분 오늘도 수고 많으셨습니다. 그럼 이만, 다음 시간에 뵙도록 하죠!',
        },
      ],
      buttonText: '이번 코스 마치기',
    },
  ],
];

export default course3Data;
