import mock1 from '../../image/mock1.png';
import { showWebView } from '../../utils/webview';

export default function SwiperContent({ step }) {
  const { id, category, title, subtitle, image } = step;

  return (
    <div
      className="pb-20px px-20px"
      style={{
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
        WebkitUserModify: 'none',
      }}
      onClick={() => {
        showWebView({
          url: `content/${id}?navigation=close`,
        });
      }}
    >
      <div
        className={`h-170px rounded-2xl mb-24px 
          ${category === '인사이드 이야기' ? 'bg-[#EFF2FF]' : ''} 
          ${category === '인사이드가 만난 사람들' ? 'bg-[#EBF6FE]' : ''}
          ${category === '상담사 이야기' ? 'bg-[#F1EFFF]' : ''}
          ${
            category !== '인사이드 이야기' && category !== '인사이드가 만난 사람들' && category !== '상담사 이야기'
              ? 'bg-[#FEF7EB]'
              : ''
          } 
          flex align-center `}
      >
        <img className=" m-auto h-140px " src={image} alt="empty" />
      </div>
      {/* <div className="rounded-2xl bg-[#E1E4EB] mb-5">
        <img className="object-contain mx-auto " src={image} alt="empty" />
      </div> */}
      <p className="text-[#3953D9] text-[15px] font-semibold mt-16px leading-[24px]">{category}</p>
      <p className="text-[20px] font-semibold mt-6px mb-5px leading-[24px] noWrap">{title}</p>
      <p className="text-[#4E4F53] text-[17px] leading-[26px]">{subtitle}</p>
    </div>
  );
}
