import { useSearchParams } from 'react-router-dom';
import { showWebView } from '../utils/webview';

export default function useShowWebviewWithParams(url, backgroundColor) {
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appId = searchParams.get('app_id');

  const useShowWebviewWithParams = () =>
    !url.includes('?')
      ? showWebView({ url: `${url}?at=${authToken}&app_id=${appId}`, backgroundColor: backgroundColor })
      : showWebView({ url: `${url}&at=${authToken}&app_id=${appId}`, backgroundColor: backgroundColor });

  return useShowWebviewWithParams;
}
