/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../../stories/page/BottomActionAnxy';
import { useSearchParams } from 'react-router-dom';
import anxyTypeData from '../../../data/anxyTypeData';
import { useSetRecoilState } from 'recoil';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import betterMeTypeData from '../../../data/betterMe/betterMeTypeData';

export function AnxyTypeCard(props) {
  const { type, clickable } = props;
  const myType = betterMeTypeData.find((element) => element.type === type);

  return (
    myType && (
      <div
        className={`w-full rounded-[20px] pt-[51px] pb-[50px] flex flex-col items-center`}
        style={{ backgroundColor: myType.bgColor }}
      >
        <img src={myType.image} alt={'empty'} className="w-[120px] mb-[20px]" />
        {/* <p className="text-[18px] leading-[24px] text-[#6B6D76] mb-[6px]">{myType.sentence}</p> */}
        <div className={`flex items-center ${clickable && 'translate-x-[12px]'}`}>
          <p className="text-[24px] leading-[30px] font-bold text-[#26282C] ">{myType.name}</p>
          {clickable && (
            <div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.5 17L14.5 12L9.5 7"
                  stroke="#26282C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
    )
  );
}
export function AnxyTypeDetail(props) {
  const { type } = props;
  const myType = betterMeTypeData.find((element) => element.type === type);
  return (
    myType && (
      <div className="flex flex-col gap-[60px]">
        {[
          { title: '결과 설명', text: myType.explanation },
          // { title: '해결 방법', text: myType.solution },
        ].map((each, index) => (
          <div key={`detail${index}`}>
            <div className="text-[17px] leading-[24px] text-[#26282C] font-bold mb-[10px]">{each.title}</div>
            <div
              className="text-[17px] leading-[28px] text-[#26282C]"
              style={{
                fontWeight: 300,
              }}
            >
              {each.list
                ? each.text.map((each, index) => (
                    <div key={`summary${index}`} className="flex items-start">
                      <div className="w-[28px] h-[28px] relative">
                        <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[3px] h-[3px] rounded-[50%] bg-[#26282C] shrink-0" />
                      </div>
                      <div className="">{each}</div>
                    </div>
                  ))
                : each.text}
            </div>
          </div>
        ))}

        <div className="border-t-[1px] border-[#E1E4EB] pt-[20px] text-[14px] leading-[20px] text-[rgba(0,0,0,0.4)]">
          본 검사는 Orwell Health, Inc에서 연구개발한 것으로 의사의 검수를 거쳤지만 본 검사 결과가 불안장애를 진단해
          주는 것은 아닙니다. 불안장애에 대한 진단은 정신건강의학과 전문의와 상의하시길 바랍니다.
        </div>
      </div>
    )
  );
}

export default function BetterMeResult(props) {
  const [searchParams] = useSearchParams();
  const type = parseInt(searchParams.get('type'));
  const goToHome = useNavigateWithParams(`/betterMeHome`);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
  }, []);

  return (
    <BottomActionAnxy
      content={
        <div className="w-screen h-full px-[20px] pt-[24px]   ">
          <div className="mb-[40px]">
            <AnxyTypeCard type={type} />
          </div>
          <AnxyTypeDetail type={type} />
        </div>
      }
      buttonText={
        <div className="flex justify-center items-center gap-[10px]">
          {/* <div>
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.605225 1.88707C0.605225 1.08465 1.50275 0.609034 2.16673 1.05959L11.1753 7.17252C11.7599 7.56926 11.7599 8.43074 11.1753 8.82748L2.16672 14.9404C1.50275 15.391 0.605225 14.9153 0.605225 14.1129V1.88707Z"
                fill="white"
              />
            </svg>
          </div> */}
          <div>나를 위한 코스 구성하기</div>
        </div>
      }
      buttonState={'ACTIVE'}
      action={() => {
        goToHome();
      }}
      noHeader
      round
    />
  );
}
