import React, { useRef } from 'react';
import { AnxyActionButton } from '../button/ActionButton';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import ContainerByDeviceAnxy from '../../components/elements/ContainerByDeviceAnxy';
import hexOpacity from 'hex-opacity';

export function BottomActionAnxyWithScroll(props) {
  const { content, buttonText, buttonState, action, bgColor, noHeader, round } = props;
  const buttonRef = useRef();
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return (
    <ContainerByDeviceAnxy noHeader={noHeader} bgColor={bgColor}>
      <div
        className={`h-full flex flex-col `}
        style={{
          minHeight: `${window.innerHeight - 44}px`,
        }}
      >
        <div
          className={`flex-1 flex flex-col pb-[80px]`}
          style={{ paddingBottom: `${buttonRef.current && buttonRef.current.offsetHeight + 40}px` }}
        >
          {content}
        </div>

        <div className={`fixed bottom-0 w-full `} ref={buttonRef}>
          {/* <div
            className="w-full h-[40px] "
            style={{
              background: `linear-gradient(to top, ${bgColor ? bgColor : 'white'}, rgba(255,255,255,0)) `,
            }}
          /> */}

          <div
            className={`px-[20px]   ${marginClassName} `}
            // style={{ backgroundColor: bgColor }}

            style={{
              background: `linear-gradient(to top, ${bgColor || '#ffffff'}, ${hexOpacity.create(
                bgColor || '#ffffff',
                0,
              )} `,
            }}
          >
            <AnxyActionButton state={buttonState} text={buttonText} action={action} bgColor={bgColor} round={round} />
          </div>
        </div>
      </div>
    </ContainerByDeviceAnxy>
  );
}
