import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import worryNoteAtom from '../../../recoil/anxy/worryNote/atom';
import useNavigateSession from '../../../hooks/useNavigateSession';
import backgroundColorAtom from '../../../recoil/common/backgroundColor/atom';
import TextAreaTemplate from '../../../stories/page/TextAreaTemplate';

function InputTemplate({ title, placeholder, name }) {
  const [worryNoteState, setWorryNoteState] = useRecoilState(worryNoteAtom);
  const backgroundColor = useRecoilValue(backgroundColorAtom);

  const goNext = useNavigateSession({});

  function updateValue(value) {
    setWorryNoteState((state) => {
      const updated = { ...state };
      updated[name] = value;
      return updated;
    });
  }

  return (
    <TextAreaTemplate
      bgColor={backgroundColor}
      title={title}
      titleColor={'#ffffff'}
      textareaBgColor={'#FAFBFD'}
      placeholder={placeholder}
      value={worryNoteState.problem}
      updateValue={updateValue}
      action={goNext}
      noBorder
      caretColor={backgroundColor}
    />
  );
}
export default InputTemplate;
