import React from 'react';
import { InsideBlackActionButton, PrimaryActionButton } from '../button/ActionButton';
import { SecondaryActionButton } from '../button/ActionButton';
import { AnxyActionButton } from '../button/ActionButton';
import { motion } from 'framer-motion';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import { useRecoilValue } from 'recoil';

export function BottomAction(props) {
  const {
    content,
    buttonText,
    buttonState,
    action,
    bgColor,
    secondary,
    noGradient,
    anxy,
    fadeIn,
    insideBlack,
    isHeader,
  } = props;

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return (
    <div
      className={`h-screen flex flex-col ${isHeader && 'pt-[44px]'} ${marginClassName} `}
      style={{
        background: bgColor ? bgColor : '#FFFFFF',
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col`}>
        <div className="flex-1">{content}</div>
      </div>
      <div className={`flex-none w-full relative `}>
        {!noGradient && (
          <div
            className="w-full h-[40px] absolute top-[-40px]"
            style={{
              background: `linear-gradient(to top, ${bgColor ? bgColor : 'white'}, rgba(${
                bgColor === '#000000' ? '0,0,0' : '255,255,255'
              },0))`,
            }}
          />
        )}
        <div className="px-[20px]">
          {!secondary ? (
            insideBlack ? (
              <motion.div
                initial={{ opacity: fadeIn ? 0 : 1, y: fadeIn ? 0 : 0 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: 'easeInOut' }}
              >
                <InsideBlackActionButton state={buttonState} text={buttonText} action={action} noGradient />
              </motion.div>
            ) : anxy ? (
              <motion.div
                initial={{ opacity: fadeIn ? 0 : 1 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.2, ease: 'easeInOut' }}
              >
                <AnxyActionButton state={buttonState} text={buttonText} action={action} bgColor={bgColor} noGradient />
              </motion.div>
            ) : (
              <PrimaryActionButton state={buttonState} text={buttonText} action={action} noGradient />
            )
          ) : (
            <SecondaryActionButton state={buttonState} text={buttonText} action={action} noGradient />
          )}
        </div>
      </div>
    </div>
  );
}
