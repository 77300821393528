export default function useLoadImage(imageList) {
  const imagePreload = () => {
    let n = imageList.length;
    for (let i = 0; i < n; i++) {
      let img = new Image();
      img.src = imageList[i];
    }
  };

  return imagePreload;
}
