import { useState, useEffect } from 'react';

export default function useDetectIOS() {
  const [isIOS, setIsIOS] = useState();
  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;

  const iOSMobile = /(iPhone|iPod|iPad)/i.test(navigator.platform);

  useEffect(() => {
    if (isSafari || iOSMobile) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isIOS];
}
