/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import DonutChart from 'react-donut-chart';
import emoji_unstable from '../../image/emoji_unstable.png';
import emoji_tired from '../../image/emoji_tired.png';
import emoji_soso from '../../image/emoji_soso.png';
import emoji_good from '../../image/emoji_good.png';
import { AnxyActionButton } from '../../stories/button/ActionButton';
import { arraySum } from '../../logic/method';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function Somny2Result2(props) {
  const { types } = props;
  const [searchParams] = useSearchParams();
  const type = searchParams
    .get('type')
    .split(',')
    .map((element) => parseInt(element));

  const setMixpanel = useMixpanelWeb({ eventName: '5-2-5. 수면 문제', projectName: 'somny' });
  const goNext = useNavigateWithParams('/somnyOnboarding/0');

  useEffect(() => {
    document.title = `somny 결과`;
    // setMixpanel();
  }, []);

  const title1 = '불면증 때문에 제법 고생하고 계신 것 같아요';
  const title2 = '뒤척이는 밤을 보낼 때도 있겠군요';
  const title3 = '잠자리가 편안한 상태인 것 같네요';
  const text1 =
    '누워도 쉽게 잠들지 못하고, 자다가 잠에서 깨는 날도 많나요? 고생이 많겠어요. 불면증의 원인은 다양하답니다. 스트레스나 환경의 변화로 인해 근심거리가 생겨 잠을 뒤척일 수도 있어요. 잘못된 생활습관으로 인해 생활리듬이 바뀌어버렸을 수도 있고요. 하지만 별다른 원인이 없고 잘 수 있는 적절한 시간과 기회가 주어지는데도 불구하고 수면에 문제가 생겼나요? 그렇다면 그건 불면증이라고 이야기할 수 있답니다. 불면증이 생기면 자고 일어나도 개운하지 않고 하루종일 피곤하죠. 그리고 잠자리에 눕게 되면 ‘이렇게 피곤한데 오늘은 잘 자야해’와 같은 생각을 하며 걱정을 하고, 결국엔 또 걱정 때문에 잠을 뒤척이며 악순환에 빠져요. 불면증이 장기화될 가능성이 있다면 약물 사용하기도 하지만, 일반적인 경우에는 약물 없이도 개선이 가능하답니다. 조금만  노력하면 지금보다 더 푹 자고 개운하게 일어날 수 있을 거예요.';
  const text2 =
    '아침에 일어나면 개운하지 않나요? 밤에 종종 잠에 못 드는 날도 있나요? 아마 가벼운 불면증이 있는 것 같아요. 불면증은 여러 요인으로 인해 발생할 수 있지만, 가벼운 형태의 불면증은 주로 수면 습관과 관련된 경우가 많답니다. 하지만 그렇게 심각한 정도는 아니에요. 조금만 노력하면 지금보다 더 푹 자고 개운하게 일어날 수 있답니다. ';
  const text3 =
    '불면증에 해당할 가능성은 낮아보여요. 혹시나 검사 결과와 무관하게 자고 일어나도 개운하지 않고 피곤하다면, 수면을 방해하는 다른 요인이 있진 않은지 살펴봐야 해요. 불면증은 아니더라도 잘못된 수면 습관 때문에 수면이 방해 받고 있을 수 있으니까요. 잘못된 수면 습관만 교정하여도 지금보다 더 푹 자고 개운하게 일어날 수 있답니다.';
  const resultData = [
    {
      image: emoji_unstable,
      title: '이런, 불면증이\n심각해요',
      firstAnalysis: { title: title1, text: text1 },
    },
    {
      image: emoji_tired,
      title: '중간 정도의 불면증을\n가지고 있어요',
      firstAnalysis: { title: title1, text: text1 },
    },
    {
      image: emoji_soso,
      title: '가벼운 불면증이\n있는 것 같아요',
      firstAnalysis: { title: title2, text: text2 },
    },
    {
      image: emoji_good,
      title: '잠은 잘 주무시고 계시네요',
      firstAnalysis: { title: title3, text: text3 },
    },
  ];

  const donutColor = ['#2973E3', '#2C29E3', '#232798', '#101364'];

  return (
    <div className={`w-screen h-full min-h-screen overflow-h-scroll px-[20px] pt-[24px] flex flex-col`}>
      <div className="flex flex-col gap-[25px] mb-[60px] ">
        <p className="text-[24px] leading-[30px] font-bold text-[#26282C]">나에게서 발견된 수면 문제</p>
        <div className="flex gap-[20px] items-center ">
          <div className="flex-1 relative">
            <div className="absolute w-[120px] h-[120px] flex justify-center items-center text-[17px] leading-[23px] font-bold">
              {arraySum(type)}개
            </div>
            <DonutChart
              className="rotate-[-90deg]"
              width={120}
              height={120}
              colors={donutColor}
              strokeColor={'#00000000'}
              innerRadius={0.55}
              outerRadius={0.9}
              legend={false}
              interactive={false}
              formatValues={''}
              emptyOffset={0}
              data={[
                {
                  label: '',
                  value: (type[0] / arraySum(type)) * 100,
                  isEmpty: type[0] === 0,
                },
                {
                  label: '',
                  value: (type[1] / arraySum(type)) * 100,
                  isEmpty: type[1] === 0,
                },
                {
                  label: '',
                  value: (type[2] / arraySum(type)) * 100,
                  isEmpty: type[2] === 0,
                },
                {
                  label: '',
                  value: (type[3] / arraySum(type)) * 100,
                  isEmpty: type[3] === 0,
                },
              ]}
            />
          </div>

          <div className="flex flex-col gap-[8px] ">
            {types.map((each, index) => (
              <div
                className="flex justify-between items-center gap-[8px] text-[14px] leading-[20px]"
                key={`type${index}`}
              >
                <div className="flex gap-[8px] items-center ">
                  <div
                    className={`w-[15px] h-[15px] rounded-[50%] `}
                    style={{
                      backgroundColor:
                        index === 0
                          ? donutColor[0]
                          : index === 1
                          ? donutColor[1]
                          : index === 2
                          ? donutColor[2]
                          : donutColor[3],
                    }}
                  />
                  <p className=" ">{each}</p>
                </div>
                <p className="font-bold">{type[index]}개</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="flex flex-col gap-[20px]">
          {[
            {
              title: '수면 문제는',
              text: [
                '활동 문제, 습관 문제, 정서 문제가 중요해요',
                '각각의 문제가 복합적으로 작용할 때가 많아요',
                '몇 가지 문제만 고쳐도 훨씬 더 푹 잠들 거예요',
              ],
            },
            {
              title: '이런 부분도 수면에 영향을 미쳐요',
              text: [
                '낮 시간에 얼마나 많은 햇볕을 쬐는지가 중요해요. 우리 생체 시계는 빛에 따라 움직이거든요.',
                '술은 잠이 오게 하는 게 아니라 뇌의 능력을 떨어뜨리는 거예요. 오히려 숙면을 방해한답니다.',
                '잠이 안 올 땐 침대에서 벗어나는 게 좋아요. 침실과 수면 사이의 연결성을 약하게 만들거든요.',
              ],
            },
          ].map((each, index) => (
            <div key={`detail${index}`}>
              <div className="text-[17px] leading-[24px] text-[#26282C] font-bold mb-[10px]">{each.title}</div>
              <div className="text-[17px] leading-[28px] text-[#26282C] font-light">
                {each.text.map((each, index) => (
                  <div key={`summary${index}`} className="flex items-start">
                    <div className="w-[28px] h-[28px] relative shrink-0">
                      <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[3px] h-[3px] rounded-[50%] bg-[#26282C] shrink-0" />
                    </div>
                    <div className="">{each}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex-none w-full pb-[20px] pt-[30px]">
        <AnxyActionButton
          state={'ACTIVE'}
          text={'관리 방법 알아보기'}
          action={() => {
            goNext();
          }}
        />
      </div>
    </div>
  );
}
