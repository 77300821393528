import { atom } from 'recoil';

const deviceAtom = atom({
  key: 'device',
  default: {
    isIOS: false,
    hasHomeIndicator: false,
    notchSize: 0,
  },
});

export default deviceAtom;
