import { Caption15, Title24 } from '../../../stories/text/SomnyText';
import emptyBook from '../../../image/somny/sleepTale/empty_book.png';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { PressedEffect } from '../../../stories/button/PressedEffect';
import { somnyFairyTaleData } from '../../../data/somny/somnyFairyTaleData';
import { useRecoilValue } from 'recoil';
import statusAtom from '../../../recoil/somny/status/atom';

export function TaleItem({ taleId, title, isLock, isFirstLock }) {
  const statusState = useRecoilValue(statusAtom);
  const goToPayment = useNavigateWithParams(`../somnyPayment`);
  const goToTale = useNavigateWithParams(`../somnyTale/${taleId}/0`);
  const currentData = taleId && somnyFairyTaleData.find((item) => item.taleId === taleId);
  return (
    <PressedEffect
      element={
        <div className="flex flex-col items-center">
          <div>
            <img src={isLock ? emptyBook : currentData.image} alt="empty" className="w-[177px]" />
          </div>
          <div className="flex flex-col items-center">
            {(isFirstLock || !isLock) && taleId && (
              <Title24 text={isFirstLock ? '채집 중...' : currentData.title} className="pt-[16px]" />
            )}
            {(isFirstLock || !isLock) && !taleId && (
              <Title24 text={isFirstLock ? '준비 중...' : currentData.title} className="pt-[16px]" />
            )}
            {isFirstLock && taleId && <Caption15 text={'1번 더 기록하면 줄게요'} className="pt-[8px]" />}
            {isFirstLock && !taleId && <Caption15 text={'열심히 만들고 있어요'} className="pt-[8px]" />}
          </div>
        </div>
      }
      action={statusState.isPaymentRequired ? goToPayment : goToTale}
      disable={isLock}
    />
  );
}
