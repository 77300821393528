/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../context';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { AnxyActionButton } from '../../stories/button/ActionButton';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function Somny2Start(props) {
  const context = useContext(AuthContext);
  const goNext = useNavigateWithParams(`/somnyTest/1`);

  const setMixpanel = useMixpanelWeb({ eventName: '5-2-1. 나는 어떤 수면 문제', projectName: 'somny' });

  useEffect(() => {
    document.title = `somny 검사 시작`;
    // setMixpanel();
    context.setShowProgressingbar(false);
    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';
  }, []);

  return (
    <div
      className="w-full h-screen bg-[#EFF2FF] overflow-x-hidden  px-[20px] flex flex-col pb-[20px]"
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <div className="flex-1 flex flex-col gap-[10px] items-center justify-center text-center text-[#26282C]]">
        <p className=" text-[30px] leading-[36px] font-bold">
          나는 어떤 수면 문제를
          <br />
          가지고 있을까?
        </p>
        <p className=" text-[17px] leading-[23px] ">2분만에 나의 수면을 평가해보세요!</p>
      </div>

      <div className="w-full ">
        <AnxyActionButton state={'ACTIVE'} text={'수면 평가하기'} action={goNext} />
      </div>
    </div>
  );
}
