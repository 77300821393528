/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { changeHeader } from '../../utils/webview';
import CoachingLeave0 from './CoachingLeave0';
import CoachingLeave1 from './CoachingLeave1';
import { DonePage } from '../../stories/page/DonePage';

export default function CoachingLeave() {
  let { id } = useParams();

  useEffect(() => {
    if (parseInt(id) === 0) {
      changeHeader({ buttonType: 'close' });
    } else if (parseInt(id) === 1) {
      changeHeader({ buttonType: 'back' });
    } else if (parseInt(id) === 2) {
      changeHeader({ buttonType: 'none' });
    }
  }, []);

  return (
    <div className={`w-screen h-screen overflow-hidden  `}>
      {parseInt(id) === 0 && <CoachingLeave0 />}
      {parseInt(id) === 1 && <CoachingLeave1 />}
      {parseInt(id) === 2 && <DonePage title={'의견을 남겼어요'} subtitle={'소중한 의견 주셔서 감사해요.'} gradient />}
    </div>
  );
}
