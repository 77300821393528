/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import './videoStyle.css';
import { AnxyLoadingLottie } from '../../../stories/animation/AnxyLoadingLottie';
import { BUILD_TARGET } from '../../../config';
import { useRecoilValue } from 'recoil';
import useNavigateSession from '../../../hooks/useNavigateSession';
import webviewToast from '../../../development/webviewToast';
import axios from '../../../utils/axiosAnxy';
import deviceAtom, { marginWithHomeIndicator } from '../../../recoil/common/device';
import backgroundColorAtom from '../../../recoil/common/backgroundColor/atom';
import Test from '../../../development/Test';
import useLoadImage from '../../../hooks/useLoadImage';
import useInterval from '../../../hooks/useInterval';
import { getSessionContents } from '../../../utils/anxySessionLogic';
import { AnxyActionButton } from '../../../stories/button/ActionButton';
import { motion } from 'framer-motion';

export default function VideoPlayer({ video, isFirst, thumbnail, isMeditation }) {
  const { sessionId, page } = useParams();
  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);
  const [loaded, setLoaded] = useState(false);
  const [post, setPost] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [isVideoEnd, setIsVideoEnd] = useState(false);

  const deviceState = useRecoilValue(deviceAtom);
  const backgroundColor = useRecoilValue(backgroundColorAtom);

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  const hideThumbnail = useRef(false);
  const playTime = useRef(0);
  const isEnd = useRef(false);

  const [searchParams] = useSearchParams();

  const currentTime = searchParams.get('currentTime');
  const viewedPage = searchParams.get('viewedPage');

  const isDev = BUILD_TARGET === 'dev';

  const videoRef = useRef();

  const handleResize = useCallback(() => {
    setViewportSize(window.visualViewport.height);
  }, [viewportSize]);

  const loadThumbnail = useLoadImage([thumbnail]);

  const timerId = useInterval(
    () => {
      hideThumbnail.current = true;
      setShowVideo(true);
      clearInterval(timerId);
    },
    1500,
    [],
  );

  const contents = getSessionContents(sessionId);
  const isHome = contents.length === parseInt(page);
  const isFirstPage = parseInt(page) === 1 || isFirst;

  const goToNextPage = useNavigateSession({ isHome: isHome, currentTime: playTime.current, isVideo: true });

  const saveViewedPage = async () => {
    try {
      await axios(3, searchParams.get('at'), searchParams.get('app_id')).post(
        '/anxy/chapter/section/page-viewed/save',
        JSON.stringify({
          sectionId: sessionId,
          sectionPageId: JSON.stringify({
            page: page,
            currentTime: playTime.current,
            isDone: isEnd.current,
          }),
        }),
      );
    } catch (error) {
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleTime = (e) => {
    if (videoRef.current) {
      // 현재 시간 설정
      const nowTime = videoRef.current.currentTime;
      playTime.current = nowTime;
      if (thumbnail) {
        if (parseInt(nowTime) % 2 === 0 && hideThumbnail.current === true) {
          saveViewedPage();
        }
      } else {
        if (parseInt(nowTime) % 2 === 0) {
          saveViewedPage();
        }
      }
    }
  };

  const handleVideoEnded = (e) => {
    isEnd.current = true;
    if (isMeditation) {
      setIsVideoEnd(true);
    } else {
      goToNextPage();
    }
  };

  const handleLoaded = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (post) {
      handleVideoEnded();
    }
  }, [post]);

  useEffect(() => {
    loadThumbnail();
    if (thumbnail) {
      saveViewedPage();
    }

    if (currentTime !== 'undefined' && page === viewedPage) {
      if (videoRef.current && parseInt(currentTime) !== 0) {
        videoRef.current.currentTime = currentTime.toString();
        hideThumbnail.current = true;
        setShowVideo(true);
      }
    }

    videoRef.current.addEventListener('ended', handleVideoEnded);
    videoRef.current.addEventListener('canplaythrough', handleLoaded);
    videoRef.current.addEventListener('timeupdate', handleTime);

    window.visualViewport.addEventListener('resize', handleResize);
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('ended', handleVideoEnded);
        videoRef.current.removeEventListener('canplaythrough', handleLoaded);
        videoRef.current.removeEventListener('timeupdate', handleTime);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const SkipButton = () => {
    return (
      <Test
        element={
          <div
            className="absolute z-[50] top-[5px] right-[15px] bg-[#FFFFFF80] px-[10px] py-[5px] text-center rounded-[12px]"
            style={{
              marginTop: `${deviceState.notchSize + 5}px`,
            }}
            onClick={() => {
              if (isDev && !post) {
                setPost(true);
              }
              if (isMeditation) {
                setShowVideo(true);
                videoRef.current.currentTime = '78';
              }
            }}
          >
            SKIP
          </div>
        }
      />
    );
  };

  return (
    <div
      className={`relative flex justify-center items-end w-full h-screen`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div class="video-container" className={`${loaded ? 'opacity-1' : 'opacity-0'}`}>
        <div class="video-wrapper">
          {thumbnail && (
            <img
              className="w-full h-full object-cover min-h-[710px] max-w-[420px] m-auto"
              style={{
                display: (viewedPage && currentTime && currentTime !== '0') || showVideo === true ? 'none' : 'normal',
              }}
              src={thumbnail}
              alt="empty"
            />
          )}
          <video
            ref={videoRef}
            src={video}
            autoPlay
            muted="muted"
            volume="0"
            playsInline
            poster={<div className="w-full h-full" style={{ backgroundColor: backgroundColor }} />}
          />
          <SkipButton />
        </div>
      </div>
      {isVideoEnd && (
        <motion.div
          className={`w-full px-[20px] ${marginClassName}`}
          initial={{ opacity: 0, y: 88 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
        >
          <AnxyActionButton text="계속하기" buttonState="ACTIVE" action={goToNextPage} />
        </motion.div>
      )}
      {isFirstPage && !loaded && (
        <div className="absolute top-[50%] translate-y-[-50%]">
          <AnxyLoadingLottie />
        </div>
      )}
    </div>
  );
}
