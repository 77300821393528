/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import AnxyWebStart from './AnxyWebStart';
import AnxyWebQA from './AnxyWebQA';
import AnxyWebEnd from './AnxyWebEnd';
import AnxyWebResultLoading from './AnxyWebResultLoading';
import AnxyWebResult from './AnxyWebResult';
import AnxyWebTypeInfo from './AnxyWebTypeInfo';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import headerAtom from '../../../recoil/common/header/atom';
import { useRecoilState, useSetRecoilState } from 'recoil';

export default function AnxyWebTest() {
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const GAD = searchParams.get('GAD-7') === 'true';
  const [selfCheckState, setSelfCheckState] = useRecoilState(selfCheckAtom);
  const setHeaderState = useSetRecoilState(headerAtom);

  const [selfCheckQuestion, getSelfCheckQuestion] = useFetchAnxy({
    url: `/self-check/v2/qc/detail`,
    params: { self_check_id: 2 },
    bg: '#EFF2FF',
  });

  useEffect(() => {
    if (selfCheckQuestion) {
      setSelfCheckState((state) => ({ ...state, qcList: selfCheckQuestion.result.qcList }));
    }
  }, [selfCheckQuestion]);

  useEffect(() => {
    document.title = `Anxy`;
    setHeaderState((state) => ({ ...state, headerColor: '#26282C' }));
    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';

    if (id === '0') {
      if (GAD) {
        getSelfCheckQuestion();
      } else {
        setSelfCheckState((state) => ({ ...state, qcList: qcList }));
      }
    }
  }, []);

  const choices = [
    { selfCheckChoiceId: 1, score: 0, choice: '전혀 아님' },
    { selfCheckChoiceId: 2, score: 1, choice: '아님' },
    { selfCheckChoiceId: 3, score: 2, choice: '보통' },
    { selfCheckChoiceId: 4, score: 3, choice: '그럼' },
    { selfCheckChoiceId: 5, score: 4, choice: '자주 그럼' },
  ];

  const types = [
    { id: 1, name: '불확실성 공포자', qcIdList: [1, 7, 13, 19, 25] },
    { id: 2, name: '완벽 추구자', qcIdList: [3, 9, 15, 21, 27] },
    { id: 3, name: '재앙적 사상가', qcIdList: [5, 11, 17, 23, 29] },
    { id: 4, name: '부담 감내자', qcIdList: [6, 12, 18, 24, 30] },
    { id: 5, name: '감정 회피자', qcIdList: [4, 10, 16, 22, 28] },
    { id: 6, name: '자기 의심가', qcIdList: [2, 8, 14, 20, 26] },
  ];

  const qcList = [
    {
      selfCheckQuestionId: 1,
      question: '주변에서 발생하는 일들이 종종 안전하지 않다고 느껴진다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 2,
      question: '내가 뭘 하든 충분하지 않을 거라는 생각이 든다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 3,
      question: '모든 게 나의 기준에 맞아야 마음이 편하다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 4,
      question: '부정적인 감정을 그대로 마주하는 것보다 모른 체하는 게 마음 편하다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 5,
      question: '한번 일이 어긋나면 최악으로 치닫기는 쉽다고 생각한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 6,
      question: '어려운 일이 있어도 꿋꿋이 이겨내는 게 유일한 해결책이다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 7,
      question: '발생할 확률이 적은 일이라도 충분히 대비를 해두어야 한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 8,
      question: '내 결정을 믿다가는 일을 그칠 수도 있다고 생각한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 9,
      question: '모든 것들을 완벽하게 해낼 수 있어야 한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 10,
      question: '내 감정을 이해하지 못하거나, 굳이 이해하지 않아도 된다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 11,
      question: '실패로 무언가를 배우기에는 실패의 대가가 너무 크다고 생각한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 12,
      question: '가끔은 나 혼자만 모든 일들을 신경쓰고 있는 것 같다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 13,
      question: '종종 나쁜 일이 발생할 것 같은 기분이 든다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 14,
      question: '중요한 일을 할 때, 내가 잘 할 수 있을지 반복해서 생각한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 15,
      question: '실수를 할 때 스스로에게 화가 나는 편이다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 16,
      question: '불안한 감정은 가능한 외면하는 게 더 도움이 된다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 17,
      question: '어떠한 일이 불러올 결과가 너무 클 것 같아 일을 진행하기 망설여진다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 18,
      question: '힘든 일이 많아도 상당수는 어쩔 수 없이 받아들여야 한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 19,
      question: '무언가를 예측할 수 없으면 초조해진다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 20,
      question: '내 의견보다 남의 의견을 따르는 게 마음이 편하다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 21,
      question: '일이 정확하게 될 때까지 계속 수정하고 보완해야 마음이 편하다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 22,
      question: '나를 방해하는 감정은 되도록 생각하지 않는 것이 좋다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 23,
      question: '생각에 꼬리를 물며 따라가다보면 어느새 가장 나쁜 상황까지 고려한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 24,
      question: '남을 믿고 맡기기 보다는 직접 해결하는 게 더 마음 편하다.',
      choices: choices,
    },
  ];

  return (
    <div className={`w-screen h-full`}>
      {parseInt(id) === 0 && <AnxyWebStart />}
      {parseInt(id) > 0 && !isNaN(parseInt(id)) && <AnxyWebQA qcList={selfCheckState.qcList} />}
      {id === 'end' && <AnxyWebEnd />}
      {id === 'resultLoading' && <AnxyWebResultLoading types={types} />}
      {id === 'result' && <AnxyWebResult />}
      {id.includes('typeInfo') && <AnxyWebTypeInfo />}
    </div>
  );
}
