import React, { useEffect, useRef, useContext } from 'react';
import Thumbnail from '../../components/feed/Thumbnail';
import contents from '../../data/contents';

import { changeHeader } from '../../utils/webview';
import { CoachingContext } from '../../coachingContext';

function Feed() {
  const context = useContext(CoachingContext);

  const scrollRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    document.title = '스토리';
    scrollRef.current.scrollTop = context.feedScrollPosition;

    changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'close' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`h-screen overflow-hidden `}>
      <div
        className="w-screen h-full  bg-[#FFFFFF]  overflow-auto scrollbar-hide"
        ref={scrollRef}
        onScroll={(e) => {
          console.log('scroll: ', e.target.scrollTop, e.target.scrollHeight);
          context.setFeedScrollPosition(e.target.scrollTop);
          if (e.target.scrollTop > titleRef.current.offsetHeight) {
            console.log('헤더 노출');
            changeHeader({ backgroundColor: '#FFFFFF', text: '스토리' });
          } else {
            changeHeader({ backgroundColor: '#FFFFFF', text: '' });
          }
        }}
      >
        <div
          ref={titleRef}
          className="ml-[20px] mt-[12px] mb-[20px] font-bold text-questionTitle leading-title text-[#26282C]"
        >
          스토리
        </div>
        {contents.map((content) => (
          <div key={content.id}>
            <Thumbnail
              id={content.id}
              image={content.image}
              category={content.category}
              title={content.title}
              subtitle={content.subtitle}
              link={content.link}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Feed;
