import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import 'moment/locale/ko';
import WorryNoteInput from './WorryNoteInput';
import useFetch from '../../../hooks/useFetch';
import webviewToast from '../../../development/webviewToast';

function WorryNote({ type }) {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);

  const [buttonState, setButtonState] = useState('INACTIVE');
  const [buttonText, setButtonText] = useState('다음');

  const [worryInfo, getWorryInfo] = useFetch({
    url: '/sleep/ease-worry/load',
  });

  const firstRef = useRef();
  const secondRef = useRef();

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  useEffect(() => {
    // if (worryInfo) {
    //   if (worryInfo.result) {
    //     let updated = {};
    //     updated.todayWork = worryInfo.result.todayWork;
    //     updated.tomorrowWork = worryInfo.result.tomorrowWork;
    //     context.setWorkbookAnswer(updated);
    //   }
    // }
  }, [worryInfo]);

  useEffect(() => {
    // 정보 불러오기
    // getWorryInfo();
    // 초기화
    setButtonState('INACTIVE');
    let updated = {};
    if (context.workbookAnswer.length === 0) {
      updated.todayWork = '';
      updated.tomorrowWork = '';
      context.setWorkbookAnswer(updated);
    }
    console.log(updated);
  }, []);

  useEffect(() => {
    console.log(context.workbookAnswer);
    const inputData = context.workbookAnswer;
    if (Object.keys(inputData).length !== 0) {
      if (inputData.todayWork === '' || inputData.tomorrowWork === '') {
        setButtonState('INACTIVE');
        console.log(inputData);
      } else {
        setButtonState('ACTIVE');
      }
    }
  }, [context.workbookAnswer]);

  const worryData = [
    {
      title: '오늘 한 일',
      placeholder: '낮에 있었던 일, 어렵게 해낸 일, 아직 끝내지 못해 마음에 남아있는 일 등을 자유롭게 적으세요.',
    },
    {
      title: '내일 할 일',
      placeholder:
        '완벽하게 계획하지 않아도 됩니다. 업무 끝내기, 치과 다녀오기, 강의 듣기 등 내일 할 일을 짧게 적어보세요.',
    },
  ];

  const noteData = [
    {
      title: '사건',
      placeholder: '어떤 일이 있었는지 사실을 기반으로 상황을 객관적으로 적어보세요. ',
    },
    {
      title: '생각',
      placeholder: '그 일을 겪기 직전 혹은 겪은 이후에 어떤 생각을 했나요? ',
    },
  ];

  const data = type === 'note' ? noteData : worryData;

  return (
    <div className={`w-full px-20px h-[calc(100vh-17px)] overflow-hidden relative `}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="flex flex-col">
          <div className="mb-[40px]">
            <div
              className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C] mt-[50px]"
              style={{ wordBreak: 'keep-all' }}
            >
              {type === 'note'
                ? '최근 불면증과 관련된 사건 중 나의 심기를 건드린 일에 대해 적어보세요.'
                : '지난 낮 동안에 있었던 일들의 목록을 짧게 작성해 보세요'}
            </div>
          </div>
          <div className="grid gap-[40px]">
            {data.map((item, index) => (
              <WorryNoteInput
                index={index}
                title={item.title}
                placeholder={item.placeholder}
                currentRef={index === 0 ? firstRef : secondRef}
                nextRef={index === 0 ? secondRef : ''}
                param={index === 0 ? 'todayWork' : 'tomorrowWork'}
                data={context.workbookAnswer}
                setData={context.setWorkbookAnswer}
              />
            ))}
          </div>
        </div>
        <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px] mt-[40px]`}>
          <SecondaryActionButton state={buttonState} text={buttonText} action={goNext} />
        </div>
      </div>
    </div>
  );
}
export default WorryNote;
