/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useSetRecoilState, useRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import CircularProgressingPage from '../../../stories/page/CircularProgressingPage';

export default function LoomyResultLoading(props) {
  const { types } = props;
  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const [selfCheckState, setSelfCheckState] = useRecoilState(selfCheckAtom);

  const goResult = useNavigateWithParams(`/loomyTest/result`);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_NONE, headerTitle: '' });
    setProgressBarState({ isProgressBarVisible: false, maxCompleted: 0, completed: 0 });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
    getMaxScoreId();
  }, []);

  function getMaxScoreId() {
    const sortedList = types
      .map((type) => getEachTypeScoreWithId(type.id))
      .sort(function (a, b) {
        return b.score - a.score || a.id - b.id;
      });
    setSelfCheckState((state) => ({ ...state, selfCheckResult: sortedList }));
    return sortedList[0].id;
  }

  function getEachType(id) {
    return selfCheckState.selfCheckAnswer.filter((element) =>
      types.find((type) => type.id === id).qcIdList.includes(element.selfCheckQuestionId),
    );
  }

  function getEachTypeScore(id) {
    return arraySum(getEachType(id).map((element) => element.score));
  }

  function getEachTypeScoreWithId(id) {
    return { id: id, score: getEachTypeScore(id) };
  }

  function arraySum(arr) {
    return arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
  }

  const isDone = () => {
    goResult();
  };

  return (
    <CircularProgressingPage
      bgColor={'#F3F0E7'}
      labelColor={'#11383B'}
      textColor={'#11383B'}
      trailColor={'#D9D8B7'}
      pathColor={'#11383B'}
      steps={['답변 분석 중', '테스트 결과 구성 중']}
      isDone={isDone}
      fixedRoot
    />
  );
}
