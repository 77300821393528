/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { LoadingLottie } from '../../stories/animation/LoadingLottie';

export default function Analysis2() {
  const [exit, setExit] = useState(false);

  const goAnalysis3 = useNavigateWithParams('/counselorSearchWizard/analysis3');
  useEffect(() => {
    setTimeout(() => {
      setExit(true);
    }, 1600);
  }, []);

  useEffect(() => {
    if (exit) {
      setTimeout(() => {
        goAnalysis3();
      }, 200);
    }
  }, [exit]);

  return (
    <div
      className={`w-screen h-screen overflow-hidden  bg-[#2C4BEC] px-[20px] text-white flex flex-col justify-center items-center text-center  font-bold`}
    >
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: !exit ? 1 : 0, y: 0 }}
        transition={{ duration: exit ? 0.2 : 0.3, delay: !exit && 0.2 }}
        className=" pb-[30px]"
      >
        <div className="w-screen  flex justify-center items-center">
          <LoadingLottie containerSize="72px" white />
        </div>
        <motion.div className=" text-[24px] leading-[30px] mt-[30px] ">{'꼭 맞는 상담사를 찾고 있어요'}</motion.div>
      </motion.div>
    </div>
  );
}
