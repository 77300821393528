import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import 'moment/locale/ko';
import useFetch from '../../../hooks/useFetch';
import useSessionStorage from '../../../hooks/useSessionStorage';
import day19_2_3 from '../../../image/course5/day19_2_3.png';
import moment from 'moment';
import { getDay } from '../../sleepingReport/calculateTime';
import ThoughtDisabledInput from './ThoughtDisabledInput';
import thoughtNoteData from '../../../data/thoughtNoteData';
import usePostWorkbookDone from '../../../hooks/usePostWorkbookDone';
import { changeHeader } from '../../../utils/webview';
import webviewToast from '../../../development/webviewToast';
import { Image } from '../Image';

const defaultValue = {
  situation: '',
  thought: '',
  thoughtTrapIdList: [],
  newThought: '',
};

function ThoughtNote() {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);
  const [trapInfo, setTrapInfo] = useSessionStorage('trapInfo', defaultValue);

  const [buttonState, setButtonState] = useState('INACTIVE');
  const [searchParams] = useSearchParams();
  const navigation = searchParams.get('navigation') || 'none';

  const [submitResult, submit] = useFetch({
    url: `/sleep/thought-note/submit`,
    requestBody: JSON.stringify(trapInfo),
  });

  const postWorkbookDone = usePostWorkbookDone();

  useEffect(() => {
    if (submitResult) {
      postWorkbookDone();
    }
  }, [submitResult]);

  useEffect(() => {
    if (trapInfo.situation === '' || trapInfo.thought === '') {
      setButtonState('INACTIVE');
    } else {
      setButtonState('ACTIVE');
    }
  }, [trapInfo]);

  const nowDate = moment(new Date());

  const Title = ({ title }) => <p className="text-[20px] leading-[26px] font-bold mb-[12px]">{title}</p>;

  const submitThoughtNote = () => {
    submit();
  };
  return (
    <div
      className={`w-full px-20px ${
        navigation === 'back' ? 'h-screen' : 'h-[calc(100vh-17px)]'
      }  overflow-hidden relative `}
    >
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="flex flex-col">
          <div className="mt-[50px] mb-[40px]">
            <Image image={day19_2_3} height={190} bg="#2C3640" />
            <div>
              <p className="font-bold text-[24px] leading-[29px] mb-[2px]">생각 노트</p>
              <p className="text-[14px] leading-[20px] text-[#6B6D76]">
                {moment(new Date()).locale('ko').format('YYYY. MM. DD.')}{' '}
                {`${getDay(moment(nowDate).format('YYYY-MM-DD'))}요일`}
              </p>
            </div>
          </div>
          <div className="grid gap-[40px]">
            <div>
              <Title title="사건" />
              <ThoughtDisabledInput data={trapInfo.situation} />
            </div>
            <div>
              <Title title="생각" />
              <ThoughtDisabledInput data={trapInfo.thought} />
            </div>
            <div>
              <Title title="생각 함정" />
              <div className="w-full flex" style={{ gap: '6px', flexWrap: 'wrap' }}>
                {trapInfo.thoughtTrapIdList.map((item) => (
                  <div>
                    <div className="flex items-center px-[12px] py-[8px] bg-[#EFF2FF] rounded-[20px]">
                      <img
                        src={thoughtNoteData.find((data) => data.id === item).image}
                        alt="empty"
                        className="w-[24px] h-[24px] mr-[6px]"
                      />
                      <p className="font-semibold text-[15px] leading-[21px]">
                        {thoughtNoteData.find((data) => data.id === item).text}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <Title title="새로운 생각" />
              <ThoughtDisabledInput data={trapInfo.newThought} />
              {navigation === 'back' && <div className={`${context.biggerMargin ? 'h-[54px]' : 'h-[40px]'}`} />}
            </div>
          </div>
        </div>
        {navigation !== 'back' && (
          <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px] mt-[40px]`}>
            <SecondaryActionButton state={buttonState} text={'마치기'} action={submitThoughtNote} />
          </div>
        )}
      </div>
    </div>
  );
}
export default ThoughtNote;
