import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import useInterval from 'hooks/useInterval';
import { getCountdown } from 'logic/method';
import { marginWithHomeIndicator } from 'recoil/common/device';
import headerAtom, { BUTTON_NONE } from 'recoil/common/header/atom';
import { AnxyActionButton } from 'stories/button/ActionButton';
import { FullPageAnxy } from 'stories/page/FullPageAnxy';

export default function AnxyComingSoon() {
  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const [leftDate, setLeftDate] = useState('');

  const [headerState, setHeaderState] = useRecoilState(headerAtom);
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');

  const navigate = useNavigate();

  const countdownFormat = 'hh:mm:ss';
  const timerId = useInterval(
    () => {
      setLeftDate(getCountdown(date, 4, countdownFormat));
    },
    1000,
    [],
  );

  useEffect(() => {
    if (date) {
      setLeftDate(getCountdown(date, 4, countdownFormat));
    } else {
      navigate(-1);
    }

    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <FullPageAnxy
      content={
        <div className={`${marginClassName} m-auto flex flex-col justify-between items-center h-full`}>
          <div className="m-auto w-full text-black h-full flex flex-col items-center justify-center my-auto px-[20px]">
            <p className="text-[#26282C] text-[24px] leading-[30px] font-bold opacity-[0.6]">다음 세션 열리기까지</p>
            <p className="text-[60px] leading-[71.6px] font-bold pt-[12px] pb-[24px]">{`${leftDate}`}</p>
            <p className="text-[15px] leading-[21px] opacity-[0.6] text-center">
              4일 마다 세션을 들을 수 있어요. 세션을 기다리는 동안 불안 관리 도구로 배운 것을 실습해보세요.
            </p>
          </div>
          <div className="w-full px-[20px]">
            <AnxyActionButton text="확인" buttonState="ACTIVE" round action={() => navigate(-1)} />
          </div>
        </div>
      }
    />
  );
}
