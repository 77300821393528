const onboarding1Q = [
  {
    id: 0,
    question: '직업이 무엇인가요?',
    type: 'select',
    options: ['직장인', '전문직', '공무원', '사업가', '프리랜서', '주부', '학생', '무직'],
  },
  {
    id: 1,
    question: '현재 연인이 있나요?',
    type: 'select',
    options: ['싱글', '연애 중', '결혼했음', '이혼했음', '별거 중', '기타'],
  },
  {
    id: 2,
    question: '아이가 있나요?',
    type: 'select',
    options: ['네, 둘 이상 있어요', '네, 한 명 있어요', '없어요'],
  },
  {
    id: 3,
    question: '평소 마음건강을 위해 하는 활동이 있나요?',
    type: 'multiple_select_with_default',
    options: ['요가', '명상', '건강하게 먹기', '규칙적인 운동', '일기 쓰기', '기타'],
  },
  {
    id: 4,
    question: '심리상담을 받아본 적이 있나요?',
    type: 'select',
    options: ['네, 있어요', '아니요, 없어요'],
  },

  {
    id: 5,
    question: '정신과 진료를 받아본 적이 있나요?',
    type: 'select',
    options: ['네, 있어요', '아니요, 없어요'],
  },
  {
    id: 6,
    question: '어떤 문제로 방문했었나요?',
    type: 'multiple_select',
    options: ['우울', '불안', '공황장애', '강박', '수면장애', '트라우마', '인격장애', '섭식장애', '중독', '기타'],
  },
];

export default onboarding1Q;
