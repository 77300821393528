import React from 'react';

import courseData from '../../data/courseData';
import { PrimaryActionButton } from '../../stories/button/ActionButton';

export default function PrecedingCourse(props) {
  const { course, goPrecedingCourse } = props;

  return (
    <div className="w-full bg-[#F3F5F8] rounded-[16px] py-[20px] px-[20px] ">
      <div className="flex justify-between mb-[15px] items-center">
        <div className=" ">
          <p className="text-[17px] leading-[23px] text-[#26282C] font-bold mb-[4px]">{course.precedingCourse.name}</p>
          <p className="text-[15px] leading-[21px] text-[#4E4F53] font-normal">코스를 먼저 마쳐주세요</p>
        </div>
        <div
          className="w-[60px] h-[60px] rounded-[12px] "
          style={{
            backgroundColor: courseData.find((element) => element.courseId === course.precedingCourse.courseId).bg,
          }}
        >
          <img
            src={courseData.find((element) => element.courseId === course.precedingCourse.courseId).image}
            alt="empty"
          />
        </div>
      </div>
      <PrimaryActionButton state={'ACTIVE'} text={'알아보기'} action={goPrecedingCourse} small={true} />
    </div>
  );
}
