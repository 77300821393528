import React, { useEffect, useState } from 'react';
import ContainerByDeviceAnxy from 'components/elements/ContainerByDeviceAnxy';
import headerAtom, { BUTTON_BACK } from 'recoil/common/header/atom';
import userAtom from 'recoil/anxy/user/atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Caption15, SubTitle18, Title24 } from 'stories/text/AnxyText';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import { arraySum, getTimeDifference } from 'logic/method';
import { PressedEffect } from 'stories/button/PressedEffect';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import useMixpanelEventAnxy from 'hooks/useMixpanelEventAnxy';
import hexOpacity from 'hex-opacity';

export default function WorryNoteHistory() {
  // 데이터 로드 여부

  const setHeaderState = useSetRecoilState(headerAtom);
  const { worryNoteHistory } = useRecoilValue(userAtom);

  const [idx, setIdx] = useState();
  const goHome = useNavigateWithParams(`/anxyHome`);
  const goDetail = useNavigateWithParams(`/worryNoteHistory/${idx}`);

  const setMixpanel = useMixpanelEventAnxy({
    eventName: '지난 기록',
  });

  useEffect(() => {
    if (idx !== undefined) {
      goDetail();
    }
  }, [idx]);

  useEffect(() => {
    // header, layout settings
    if (!worryNoteHistory) {
      goHome();
    } else {
      setMixpanel();
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_BACK,
      }));
    }
  }, []);

  return (
    <ContainerByDeviceAnxy css={' px-[20px] flex flex-col '} bgColor={anxyColorPalette.oat}>
      <Title24 title={'히스토리'} className="pt-[12px] mb-[40px]" />

      <div class={`grid grid-cols-2 divide-x mb-[40px] `}>
        <div className="text-center">
          <Caption15 caption={'쌓은 불안'} />
          <p className="text-[50px] leading-[60px] font-bold">
            {arraySum(
              worryNoteHistory
                .filter((element) => element.reason !== '심호흡하기' && element.reason !== '심호흡하기 전')
                .map((each) => each.score),
            )}
          </p>
        </div>
        <div className="text-center" style={{ borderColor: hexOpacity.create(anxyColorPalette.black, 0.1) }}>
          <Caption15 caption={'낮춘 불안'} />
          <p className="text-[50px] leading-[60px] font-bold" style={{ color: anxyColorPalette.orange }}>
            {arraySum(
              worryNoteHistory.filter((element) => element.reason === '심호흡하기 전').map((each) => each.score),
            ) -
              arraySum(worryNoteHistory.filter((element) => element.reason === '심호흡하기').map((each) => each.score))}
          </p>
        </div>
      </div>

      {worryNoteHistory && (
        <div className="flex flex-col gap-[10px] pb-[100px] ">
          {worryNoteHistory
            .filter((element) => element.reason !== '심호흡하기 전')
            .map((each, index) => (
              <PressedEffect
                key={`worry-note-history${index}`}
                element={
                  <div className="flex  justify-between items-center gap-[10px] w-full rounded-[16px] bg-white p-[24px] ">
                    <div className="flex-1 overflow-hidden flex flex-col gap-[8px] ">
                      <SubTitle18 subtitle={each.reason} className="w-full line-clamp-2 " />
                      <Caption15 caption={getTimeDifference(each.submittedAt)} />
                    </div>
                    <p
                      className="text-[30px] leading-[36px] font-bold shrink-0 flex-none"
                      style={{ color: each.reason === '심호흡하기' && anxyColorPalette.orange }}
                    >
                      {each.reason === '심호흡하기'
                        ? each.score -
                          worryNoteHistory[
                            worryNoteHistory.findIndex((element) => element.worryNoteId === each.worryNoteId) + 1
                          ].score
                        : each.score}
                    </p>
                  </div>
                }
                action={() => {
                  setIdx(each.worryNoteId);
                }}
              />
            ))}
        </div>
      )}
    </ContainerByDeviceAnxy>
  );
}
