import SessionTemplate from '../../pages/betterme/session/SessionTemplate';
import { betterMeColor } from './betterMeConstant';
import intro_1 from '../../image/betterme/session/intro/intro_1.png';
import intro_2 from '../../image/betterme/session/intro/intro_2.png';
import intro_3 from '../../image/betterme/session/intro/intro_3.png';
import intro_4 from '../../image/betterme/session/intro/intro_4.png';
import intro_5 from '../../image/betterme/session/intro/intro_5.png';
import intro_6 from '../../image/betterme/session/intro/intro_6.png';

const imageTemplate = ({ image, bgColor, timeout }) => {
  return {
    image: image,
    bgColor: bgColor,
    timeout: timeout,
  };
};

const Intro = [
  imageTemplate({ image: intro_1, bgColor: betterMeColor.lightYellow }),
  imageTemplate({ image: intro_2, bgColor: betterMeColor.lightYellow }),
  imageTemplate({ image: intro_3, bgColor: betterMeColor.lightYellow }),
  imageTemplate({ image: intro_4, bgColor: betterMeColor.lightYellow, timeout: 4000 }),
  imageTemplate({ image: intro_5, bgColor: betterMeColor.mediumYellow, timeout: 5000 }),
  imageTemplate({ image: intro_6, bgColor: betterMeColor.mediumYellow, timeout: 4000 }),
];
const betterMeSessionList = Intro;

export default betterMeSessionList;
