import { atom } from 'recoil';

const sessionAtom = atom({
  key: 'session',
  default: {
    currentSession: undefined,
    currentTool: undefined,
    scrollToSession: undefined,
    toolList: [],
    currentSessionImageList: undefined,
  },
});

export default sessionAtom;
