import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AnxyActionButton } from '../../../stories/button/ActionButton';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import { goToSetting, logout, userLeave } from '../../../utils/webview';
import { useRecoilState, useRecoilValue } from 'recoil';
import myPageAtom from '../../../recoil/anxy/myPage/atom';
import LinkButton from '../../../stories/button/LinkButton';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import sessionAtom from '../../../recoil/anxy/session/atom';
import BottomSheetModal from '../../../stories/modal/BottomSheetModal';
import modalAtom from '../../../recoil/common/modal/atom';
import deviceAtom from '../../../recoil/common/device';
import allow_notification_ios from '../../../image/common/allow_notification_ios.png';
import allow_notification_android from '../../../image/common/allow_notification_android.png';

export default function AnxyModal(props) {
  const [searchParams] = useSearchParams();
  const appId = searchParams.get('app_id');
  const myPageState = useRecoilValue(myPageAtom);
  const [modalState, setModalState] = useRecoilState(modalAtom);
  const [sessionState, setSessionState] = useRecoilState(sessionAtom);
  const { isIOS } = useRecoilValue(deviceAtom);

  const [currentSession, setCurrentSession] = useState(sessionState.currentSession);

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionState.currentSession) {
      console.log(sessionState.currentSession);
      setCurrentSession(sessionState.currentSession);
    }
  }, [sessionState]);

  useEffect(() => {
    if (modalState) {
      setSessionState((state) => ({ ...state, scrollToSession: undefined }));
    }
  }, [modalState]);

  const sectionId = currentSession && currentSession.sectionId;
  const pageLastViewed = currentSession && currentSession.pageLastViewed;

  const goSessionFirst = useNavigateWithParams(`../anxy/${sectionId}/0`);
  const goViewedSession = useNavigateWithParams(`/anxy/${sectionId}/${pageLastViewed + 1}`);

  const [logoutResponse, callLogoutApi] = useFetchAnxy({
    url: `/anxy/user/logout`,
    bg: '#FFFFFF',
  });

  const [userLeaveResponse, callUserLeaveApi] = useFetchAnxy({
    url: `anxy/user/leave`,
    requestBody: JSON.stringify({ reason: myPageState.leaveReason }),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (userLeaveResponse) {
      userLeave();
      setModalState((state) => ({ ...state, isModalVisible: false }));
      navigate(`/login?app_id=${appId}`, { replace: true });
    }
  }, [userLeaveResponse]);

  useEffect(() => {
    if (logoutResponse.code === 0) {
      logout();
      setModalState((state) => ({ ...state, isModalVisible: false }));
      navigate(`/login?app_id=${appId}`, { replace: true });
    }
  }, [logoutResponse]);

  const data = [
    {
      type: 'LOGOUT',
      title: '정말 로그아웃할까요?',
      buttonText: '로그아웃하기',
      action: () => {
        callLogoutApi();
      },
    },
    {
      type: 'LEAVE',
      title: '정말 탈퇴할까요?',
      info: 'Anxy 이용/결제 내역은 영구적으로 삭제되어 복구할 수 없어요. 나중에 Anxy를 다시 사용하려면 처음부터 시작해야 해요.',
      buttonText: '탈퇴하기',
      red: true,
      action: () => {
        callUserLeaveApi();
      },
    },
    {
      type: 'VIEWED',
      title: '이전에 보던 부분이 있어요',
      buttonText: '이어서 보기',
      linkText: '처음부터 볼래요',
      action: () => {
        setModalState((state) => ({ ...state, isModalVisible: false }));
        goViewedSession();
      },
      linkAction: () => {
        setModalState((state) => ({ ...state, isModalVisible: false }));
        goSessionFirst();
      },
    },
    {
      type: 'ALLOW_NOTIFICATION',
      title: '알림을 허용해주세요',
      info: `설정 > ${!isIOS ? '애플리케이션 > ' : ''}Anxy > 알림에서 알림을 허용해주세요`,
      buttonText: '설정으로 이동하기',
      content: (
        <div className="bg-anxyOat p-[40px] rounded-[16px]">
          <img
            src={isIOS ? allow_notification_ios : allow_notification_android}
            alt={'empty'}
            className="w-full max-w-[255px] mx-auto"
          />
        </div>
      ),
      action: () => {
        goToSetting();
        // goViewedSession();
      },
    },
  ];

  const dataPerType = data.find((item) => item.type === modalState.modalType);

  return (
    <BottomSheetModal
      modalState={modalState}
      setModalState={setModalState}
      content={
        <div className={''}>
          {dataPerType && (
            <div className={`h-full w-full text-[15px] leading-[18px] text-center relative`}>
              <div className="flex flex-col gap-[24px]">
                <div className="flex flex-col gap-[6px]">
                  <p className="text-[#26282C] text-[20px] leading-[24px] font-semibold">{dataPerType.title}</p>
                  {dataPerType.info && <p className="text-[17px] leading-[26px] text-[#6B6D76]">{dataPerType.info}</p>}
                </div>
                {dataPerType.content}
              </div>
              <div className="pt-[30px] flex flex-col items-center">
                <AnxyActionButton
                  state="ACTIVE"
                  text={dataPerType.buttonText}
                  action={dataPerType.action}
                  activeColor={dataPerType.red && '#EF3E3E'}
                />
                {dataPerType.linkText && (
                  <LinkButton state="ACTIVE" text={dataPerType.linkText} action={dataPerType.linkAction} />
                )}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}
