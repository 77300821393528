/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { motion } from 'framer-motion';
import lottie from 'lottie-web';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../recoil/common/header/atom';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import { betterMeColor } from '../../data/betterMe/betterMeConstant';

export default function BettyInReview(props) {
  const goStart = useNavigateWithParams('/betterMeTest/0', true);
  const container = useRef();

  const [goNext, setGoNext] = useState(false);
  const [firstTextOut, setFirstTextOut] = useState(false);
  const [secondTextIn, setSecondTextIn] = useState(false);
  const [secondTextOut, setSecondTextOut] = useState(false);

  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    setHeaderState({ headerButtonType: BUTTON_NONE, headerTitle: '' });

    setTimeout(() => {
      setFirstTextOut(true);
      setTimeout(() => {
        setSecondTextIn(true);
        setTimeout(() => {
          setSecondTextOut(true);
          setTimeout(() => {
            setGoNext(true);
          }, 600);
        }, 2200);
      }, 500);
    }, 2200);
  }, []);

  useEffect(() => {
    if (goNext) {
      goStart();
    }
  }, [goNext]);

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('../../image/congrats_particle.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <div
      className={`w-screen h-screen flex flex-col justify-center relative`}
      style={{
        backgroundColor: betterMeColor.yellow,
      }}
    >
      <div className=" w-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] pointer-events-none z-[40]">
        <div className="h-full" ref={container} />
      </div>
      {!secondTextIn && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: !firstTextOut ? 1 : 0, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5, ease: 'easeInOut' }}
          className=" text-[24px] leading-[30px] font-bold  text-center  pb-[30px]"
        >
          {'Better Me에 오신 것을 \n 환영해요'}
        </motion.div>
      )}
      {secondTextIn && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: !secondTextOut ? 1 : 0, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5, ease: 'easeInOut' }}
          className=" text-[24px] leading-[30px] font-bold  text-center  pb-[30px]"
        >
          {'Better Me로 \n 식이장애에서 벗어나세요'}
        </motion.div>
      )}
    </div>
  );
}
