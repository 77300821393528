/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SelfCheckTemplate from '../../components/selfCheck/SelfCheckTemplate';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import deviceAtom from '../../recoil/common/device';
import selfCheckAtom from '../../recoil/common/selfCheck/atom';
import SelectMultiInQA from '../../components/inside/SelectMultiInQA';
import webviewToast from '../../development/webviewToast';

export default function InsideWHiteQA(props) {
  const { qcList, selfCheckState, setSelfCheckState } = props;

  const setHeaderState = useSetRecoilState(headerAtom);
  const deviceState = useRecoilValue(deviceAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  let { id } = useParams();
  id = parseInt(id);

  const [answer, setAnswer] = useState('');
  const [disable, setDisable] = useState(false);

  const goNextQuestion = useNavigateWithParams(`/inside-white/${parseInt(id) + 1}`);
  const goEndTest = useNavigateWithParams(`/inside-white/end`);

  console.log(selfCheckState);
  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerColor: '#26282C' }));
    setSelfCheckState((state) => ({ ...state, qcList: qcList }));
  }, []);

  useEffect(() => {
    if (
      id !== 0 &&
      selfCheckState.selfCheckAnswer &&
      selfCheckState.selfCheckAnswer.find(
        (element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId,
      )
    ) {
      const selected = selfCheckState.selfCheckAnswer.find(
        (element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId,
      );
      console.log(selected.selfCheckChoiceId);
      setAnswer(selected.selfCheckChoiceId);
    }
  }, []);

  function select(option) {
    setAnswer(option.selfCheckChoiceId);
    var arr1 = selfCheckState.selfCheckAnswer ? selfCheckState.selfCheckAnswer.slice() : [];

    if (arr1.find((element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId)) {
      arr1 = arr1.filter((element) => element.selfCheckQuestionId !== qcList[id - 1].selfCheckQuestionId);
    }
    if (option.score !== undefined) {
      arr1.push({
        selfCheckQuestionId: qcList[id - 1].selfCheckQuestionId,
        selfCheckChoiceId: option.selfCheckChoiceId,
        score: option.score,
      });
    } else {
      arr1.push({
        selfCheckQuestionId: qcList[id - 1].selfCheckQuestionId,
        selfCheckChoiceId: option.selfCheckChoiceId,
      });
    }

    console.log('answers: ', arr1);
    setSelfCheckState((state) => ({ ...state, selfCheckAnswer: arr1 }));

    setTimeout(() => {
      if (parseInt(id) < qcList.length) {
        goNextQuestion();
      } else if (parseInt(id) === qcList.length) {
        goEndTest();
      }
    }, 400);
  }

  function isClicked(option) {
    if (option !== '') {
      setDisable(true);
      select(option);
    }
  }

  useEffect(() => {
    setProgressBarState({
      isProgressBarVisible: true,
      maxCompleted: qcList.length + 1,
      completed: parseInt(id + 1),
      color: '#3953D9',
    });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerTitle: '' }));
  }, []);

  //   webviewToast('in' + id);
  return (
    <div className={`w-screen bg-white `} style={{ height: window.innerHeight - (deviceState.notchSize + 44 + 17) }}>
      {id === 0 ? (
        <SelectMultiInQA
          selfCheckState={selfCheckState}
          setSelfCheckState={setSelfCheckState}
          action={goNextQuestion}
        />
      ) : (
        <div className="h-full">
          {qcList[id - 1] && (
            <SelfCheckTemplate
              question={qcList[id - 1].question}
              optionList={qcList[id - 1].choices}
              answer={answer}
              isClicked={isClicked}
              disable={disable}
              insideWhite
            />
          )}
        </div>
      )}
    </div>
  );
}
