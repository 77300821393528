import React from 'react';
import { PressedEffect } from '../../stories/button/PressedEffect';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import webviewToast from '../../development/webviewToast';
import { goToScreen } from '../../utils/webview';

export default function ReservationCard({ data, allData, index }) {
  const goCounselorReservation = () => {
    const id = data ? data.counselorId : 43;
    const name = data ? data.name : '참다미';
    const appointmentId = data ? data.previousAppointmentId : '';
    webviewToast('Counselor Info:' + id + ' ' + name);
    goToScreen({
      screenName: 'reservation',
      counselorId: id,
      counselorName: name,
      appointmentId: appointmentId,
    });
  };

  const prevCancleLength = allData
    .slice(-1 * index)
    .filter((item) => item.counselingStatus && item.counselingStatus.id === 3).length;

  return (
    <PressedEffect
      element={
        <div className={`w-full h-[125px] bg-[#FFFFFF] rounded-[16px] justify-center p-[20px] flex-col mb-[10px]`}>
          <div className="w-full flex items-center">
            <div className="w-[31.2px] shrink-0 font-bold text-[20px] leading-[24px] text-[#26282C] text-center">
              {index - prevCancleLength}
            </div>
            <div className="w-full pl-[15px]">
              <div className="w-full flex justify-between">
                <p className="text-[17px] leading-[23px] font-bold">다음 상담 예약하기</p>
              </div>
            </div>
          </div>
          <div className="mt-[15px]">
            <PrimaryActionButton state="ACTIVE" text={'예약하기'} medium action={() => goCounselorReservation()} />
          </div>
        </div>
      }
      action={() => goCounselorReservation()}
    />
  );
}
