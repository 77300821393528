import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { PressedEffect } from '../../stories/button/PressedEffect';
import icon_locked from '../../image/icon_locked.png';
import { goToScreen, showWebView } from '../../utils/webview';

import CheckLottie from '../coaching/CheckLottie';
import toolData from '../../data/toolData';

import UnlockLottie from '../coaching/UnlockLottie';

export default function ActivityCard(props) {
  const { activity, activityData, disable } = props;

  const [isLockTouched, setIsLockTouched] = useState(false);

  function goWorkbook() {
    if (!activity.isLock) {
      switch (activity.webToAppEvent.eventName) {
        case 'showWebView': {
          const message = JSON.parse(activity.webToAppEvent.body);
          showWebView({ url: message.url + `&isFirst=${!activity.isDone}`, backgroundColor: '#FFFFFF' });
          break;
        }
        case 'goEmotionalRecordStart': {
          goToScreen({
            screenName: 'emotionalRecord',
          });
          break;
        }
        case 'goRetrospectNote': {
          goToScreen({
            screenName: 'retrospectNote',
          });
          break;
        }
        case 'goRuleCard': {
          goToScreen({
            screenName: 'ruleCard',
          });
          break;
        }
        default: {
          console.log('error');
          break;
        }
      }
    } else {
      setIsLockTouched(true);
    }
  }

  useEffect(() => {
    if (isLockTouched) {
      setTimeout(() => {
        setIsLockTouched(false);
      }, 1200);
    }
  }, [isLockTouched]);

  return (
    <PressedEffect
      element={
        <div
          className={`relative w-full rounded-[20px] py-[23px] px-[20px] ${disable ? 'opacity-50' : 'opacity-100'}`}
          style={{
            backgroundColor:
              activityData && activityData.toolId
                ? toolData.filter((item) => item.toolId === activityData.toolId)[0].backgroundColor
                : '#F3F5F8',
          }}
        >
          {activity.isDone && activity.isFirstCallAfterDone && (
            <div className="absolute top-[-11px] right-[64px] z-[10]">
              <CheckLottie />
            </div>
          )}

          <div className="w-full flex items-center ">
            <div className="flex-1 min-w-0 ">
              <div className="w-full flex items-center  pr-[6px] ">
                <div
                  className="w-[45px] h-[45px] rounded-[45px] overflow-hidden shrink-0  mr-[15px] flex items-center justify-center "
                  style={{
                    backgroundColor: activityData
                      ? !activityData.toolId
                        ? activityData.backgroundColor
                        : toolData.find((item) => item.toolId === activityData.toolId).backgroundColor
                      : '#dddddd',
                  }}
                >
                  <img
                    src={
                      activityData &&
                      (!activityData.toolId
                        ? activityData.image
                        : toolData.find((item) => item.toolId === activityData.toolId).image)
                    }
                    alt="empty"
                  />
                </div>

                <div className="flex-1 overflow-hidden ">
                  <motion.p
                    className="absolute bottom-[36px] text-[14px] leading-[20px] text-[#6B6D76] opacity-0"
                    animate={{ opacity: isLockTouched ? 1 : 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    이전 활동을 먼저 마무리 하세요
                  </motion.p>
                  <motion.div animate={{ opacity: isLockTouched ? 0 : 1 }} transition={{ duration: 0.2 }}>
                    <p className="text-[16px] leading-[26px] text-[#26282C] font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
                      {activity.name}
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
            {!activity.isLock && !activity.isFirstUnlocked && (
              <div
                className={`w-[24px] h-[24px]  rounded-[24px] flex items-center justify-center shrink-0
              ${activity.isDone && !activity.isFirstCallAfterDone ? 'bg-qna' : 'bg-white'} `}
                style={{ border: !activity.isDone && '1px solid #373EED' }}
              >
                {activity.isDone && !activity.isFirstCallAfterDone && (
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.69597 2.58962C1.2994 2.20525 0.666308 2.21514 0.281932 2.61172C-0.102443 3.0083 -0.0925511 3.64138 0.304027 4.02576L1.69597 2.58962ZM4.80952 7L4.11355 7.71807C4.50139 8.09398 5.11765 8.09398 5.5055 7.71807L4.80952 7ZM11.696 1.71807C12.0926 1.33369 12.1024 0.700605 11.7181 0.304027C11.3337 -0.0925511 10.7006 -0.102443 10.304 0.281932L11.696 1.71807ZM0.304027 4.02576L4.11355 7.71807L5.5055 6.28193L1.69597 2.58962L0.304027 4.02576ZM5.5055 7.71807L11.696 1.71807L10.304 0.281932L4.11355 6.28193L5.5055 7.71807Z"
                      fill="white"
                    />
                  </svg>
                )}
              </div>
            )}
            {activity.isFirstUnlocked && (
              <motion.div
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: 1.2 }}
                className="opacity-0 w-[24px] h-[24px] rounded-[100%] bg-white"
                style={{ border: '1px solid #373EED' }}
              />
            )}
            {activity.isFirstUnlocked && (
              <div className={`absolute right-[20px] top-[34px]`}>
                <motion.div className="" animate={{ opacity: 0 }} transition={{ duration: 0.2, delay: 1.2 }}>
                  <UnlockLottie />
                </motion.div>
              </div>
            )}
            {activity.isLock && <img className=" h-[15px] pr-[6px]" src={icon_locked} alt="empty" />}
          </div>
        </div>
      }
      disable={disable}
      action={goWorkbook}
    />
  );
}
