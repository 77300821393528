import React, { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
import blueQuoteLarge from '../../image/blueQuoteLarge.png';
import grayStar from '../../image/grayStar.png';

const NewRuleBox = ({ full }) => {
  const context = useContext(CoachingContext);

  return (
    <div className={`bg-[#EFF2FF] w-full rounded-[12px] ${full ? 'h-[calc(100vh-227px)]' : 'h-[391px]'} text-center`}>
      <div className="relative top-[50%] translate-y-[-50%]">
        <p className="text-[#80838B] font-semibold text-[17px] leading-[23px] ">새로운 규칙</p>
        <div className="flex flex-col items-center mt-[30px]">
          <img className="w-[30.9px]" src={blueQuoteLarge} alt="empty" />
          <p className="text-[#26282C] mt-[5px] text-[30px] leading-[36px] font-bold py-[30px]">
            {context.workbookAnswer && context.workbookAnswer[0]}
          </p>
          <img className="w-[40px]" src={grayStar} alt="empty" />
        </div>
      </div>
    </div>
  );
};

export default NewRuleBox;
