import { useEffect } from 'react';
import { useState } from 'react';
import useInterval from '../../../hooks/useInterval';
import dreamMessage from '../../../image/somny/home/dream_message.png';
import { AnimatePresence, motion } from 'framer-motion';

export default function AnimateDreamMessage({ taleData }) {
  const [index, setIndex] = useState(0);
  const [icon, setIcon] = useState(taleData && taleData.dreamImageList && taleData.dreamImageList[0]);

  useEffect(() => {
    if (taleData && taleData.dreamImageList) {
      setIcon(taleData.dreamImageList[index]);
    }
  }, [index]);

  const timerId = useInterval(
    () => {
      if (taleData && taleData.dreamImageList) {
        if (index === 0) {
          setIndex(1);
        } else if (index === 1) {
          setIndex(2);
        } else {
          setIndex(0);
        }
      }
    },
    6000,
    [],
  );

  useEffect(() => {
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <motion.div
      className="w-[205px] relative top-[35px]"
      animate={{
        width: ['205px', '220px', '205px'],
      }}
      transition={{
        repeat: Infinity,
        duration: 2.5,
        ease: 'easeInOut',
      }}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.p
          key={icon}
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          className="absolute left-[50%] translate-x-[-50%] top-[37px] text-[50px] leading-[26px]"
        >
          {icon}
        </motion.p>
      </AnimatePresence>

      <img src={dreamMessage} alt="empty" className="mx-auto" />
    </motion.div>
  );
}
