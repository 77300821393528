import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export function DoneLottie(props) {
  const { containerSize, isStop } = props;
  const likecontainer = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('./done_particle.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <div>
      <div style={{ width: containerSize, height: containerSize }} ref={likecontainer}></div>
    </div>
  );
}
