import React, { useCallback, useContext } from 'react';
import axios from '../../utils/axios2';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../coachingContext';
import TitleTextList from './TitleTextList';
import { SecondaryActionButton } from '../../stories/button/ActionButton';
import webviewToast from '../../development/webviewToast';
import useDebounce from '../../hooks/useDebounce';
import { useGetWorkbookParams } from '../../hooks/useGetWorkbookParams';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import usePostWorkbookDone from '../../hooks/usePostWorkbookDone';

const BasicAssumptionBox = ({ title, buttonText }) => {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  const [authToken, appId, activityKey, isFirst] = useGetWorkbookParams();
  const postWorkbookDone = usePostWorkbookDone();

  const texts = [
    { title: '1. 배운 규칙과 가치', info: context.workbookAnswer.length !== 0 && context.workbookAnswer[1] },
    { title: '2. 나에게 자주 했던 말과 행동', info: context.workbookAnswer.length !== 0 && context.workbookAnswer[2] },
    {
      title: '3. 문제나 도전에 직면했을 때 나를 대한 태도',
      info: context.workbookAnswer.length !== 0 && context.workbookAnswer[3],
    },
    { title: '4. 방치되거나 무시당한 경험', info: context.workbookAnswer.length !== 0 && context.workbookAnswer[4] },
    {
      title: '5. 나를 과하게 보호하려 해던 경험',
      info: context.workbookAnswer.length !== 0 && context.workbookAnswer[5],
    },
    { title: '6. 그 외 강렬한 경험', info: context.workbookAnswer.length !== 0 && context.workbookAnswer[6] },
  ];

  const goNextPage = () => {
    if (context.progressSum === parseInt(page)) {
      postOnce();
    } else {
      context.setCurrent(context.current + 1);
      goNext();
    }
  };

  const postOnce = useCallback(
    useDebounce(() => submitBasicAssumption(), 300),
    [],
  );

  const submitBasicAssumption = async (index) => {
    try {
      await axios(3, authToken, appId)
        .post(
          `/footsteps-of-thought/basic-assumption/submit`,
          JSON.stringify({
            keyPerson: context.workbookAnswer[0],
            rulesAndValuesLearned: context.workbookAnswer[1],
            wordsAndActionsOftenHeard: context.workbookAnswer[2],
            attitudeWhenFacedProblem: context.workbookAnswer[3],
            experienceOfNeglect: context.workbookAnswer[4],
            experienceOfOverProtection: context.workbookAnswer[5],
            experienceOfIntense: context.workbookAnswer[6],
            basicAssumption: context.workbookAnswer[7],
          }),
        )
        .then((response) => {
          submitRule();
        });
    } catch (error) {
      console.log(error);
      webviewToast('error' + error);
    }
  };

  const submitRule = async () => {
    try {
      await axios(3, authToken, appId)
        .post(
          `/footsteps-of-thought/rule-of-life/submit`,
          JSON.stringify({
            rule: context.workbookAnswer[7],
          }),
        )
        .then((response) => {
          postWorkbookDone();
          webviewToast('success');
        });
    } catch (error) {
      console.log(error);
      webviewToast('error' + error);
    }
  };

  return (
    <div className={`w-full px-20px h-[calc(100vh-17px)] overflow-hidden relative text-[#3A3C40]`}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="mt-[50px] mb-[40px]">
          <div className="flex flex-col relative">
            <p className="font-bold text-[24px] leading-[30px]  text-[#26282C]" style={{ wordBreak: 'keep-all' }}>
              {title}
            </p>
            <div className="mt-40px">
              <p
                className="font-bold text-[20px] leading-[26px]  mb-[15px] text-[#26282C]"
                style={{ wordBreak: 'keep-all' }}
              >
                나의 중요 인물
              </p>
              <div className="rounded-[14px] w-full bg-[#F3F5F8] pt-[22px] pb-[26px] px-[20px] text-[14px] leading-[20px]">
                {context.workbookAnswer.length !== 0 && context.workbookAnswer[0]}
              </div>
              <p
                className="font-bold text-[20px] leading-[26px]  mb-[15px] text-[#26282C] mt-[40px]"
                style={{ wordBreak: 'keep-all' }}
              >
                중요 인물들과의 경험
              </p>
              <div className="rounded-[14px] w-full bg-[#F3F5F8] pt-[22px] pb-[26px] px-[20px]">
                <TitleTextList texts={texts} />
              </div>
              <p
                className="font-bold text-[20px] leading-[26px]  mb-[15px] text-[#26282C] mt-[40px]"
                style={{ wordBreak: 'keep-all' }}
              >
                경험으로 형성된 나의 기본 가정
              </p>
              <div className="rounded-[14px] w-full bg-[#F3F5F8] pt-[22px] pb-[26px] px-[20px] text-[14px] leading-[20px]">
                {context.workbookAnswer.length !== 0 && context.workbookAnswer[7]}
              </div>
            </div>
          </div>
        </div>
        <div className={`w-full rounded-[16px] ${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'}`}>
          <SecondaryActionButton state="ACTIVE" text={buttonText || '다음'} action={goNextPage} />
        </div>
      </div>
    </div>
  );
};

export default BasicAssumptionBox;
