import AccordianContent from '../components/workbook/AccordianContent';
import wb0_2 from '../image/wb0_2.png';
import wb0_3 from '../image/wb0_3.png';
import wb0_4 from '../image/wb0_4.png';
import wb0_5 from '../image/wb0_5.png';
import wb2_1 from '../image/wb2_1.png';
import wb2_2 from '../image/wb2_2.png';
import wb2_3 from '../image/wb2_3.png';
import wb2_4 from '../image/wb2_4.png';
import wb3_1 from '../image/wb3_1.png';
import wb3_2 from '../image/wb3_2.png';
import wb3_3 from '../image/wb3_3.png';
import wb3_4 from '../image/wb3_4.png';
import wb4_1 from '../image/wb4_1.png';
import wb4_3 from '../image/wb4_3.png';
import wb4_4 from '../image/wb4_4.png';
import wb4_5 from '../image/wb4_5.png';
import wb5_1 from '../image/wb5_1.png';
import wb5_2 from '../image/wb5_2.png';
import wb5_3 from '../image/wb5_3.png';
import wb5_4 from '../image/wb5_4.png';
import wb5_6 from '../image/wb5_6.png';
import wb6_1 from '../image/wb6_1.png';
import wb6_2 from '../image/wb6_2.png';
import wb6_3 from '../image/wb6_3.png';
import wb7_1 from '../image/wb7_1.png';
import wb7_2 from '../image/wb7_2.png';
import wb7_3 from '../image/wb7_3.png';
import wb7_4 from '../image/wb7_4.png';
import wb8_1 from '../image/wb8_1.png';
import wb8_2 from '../image/wb8_2.png';
import wb8_3 from '../image/wb8_3.png';
import wb8_5 from '../image/wb8_5.png';
import wb8_6 from '../image/wb8_6.png';
import wb8_7 from '../image/wb8_7.png';
import wb8_8 from '../image/wb8_8.png';
import wb8_9 from '../image/wb8_9.png';
import wb9_1 from '../image/wb9_1.png';
import wb9_2 from '../image/wb9_2.png';
import wb9_3 from '../image/wb9_3.png';
import wb9_4 from '../image/wb9_4.png';
import wb9_5 from '../image/wb9_5.png';
import wb9_6 from '../image/wb9_6.png';
import wb9_7 from '../image/wb9_7.png';
import wb10_1 from '../image/wb10_1.png';
import wb10_2 from '../image/wb10_2.png';
import wb10_3 from '../image/wb10_3.png';
import wb10_4 from '../image/wb10_4.png';
import wb10_5 from '../image/wb10_5.png';
import wb10_6 from '../image/wb10_6.png';
import wb10_7 from '../image/wb10_7.png';
import wb10_8 from '../image/wb10_8.png';
import wb11_1 from '../image/wb11_1.png';
import wb11_2 from '../image/wb11_2.png';
import wb11_3 from '../image/wb11_3.png';
import wb11_4 from '../image/wb11_4.png';
import wb11_5 from '../image/wb11_5.png';
import wb11_6 from '../image/wb11_6.png';
import wb11_7 from '../image/wb11_7.png';
import wb12_1 from '../image/wb12_1.png';
import wb12_2 from '../image/wb12_2.png';
import wb12_3 from '../image/wb12_3.png';
import wb12_6 from '../image/wb12_6.png';
import wb13_1 from '../image/wb13_1.png';
import wb13_2 from '../image/wb13_2.png';
import wb13_3 from '../image/wb13_3.png';
import wb13_4 from '../image/wb13_4.png';
import wb13_5 from '../image/wb13_5.png';
import wb14_1 from '../image/wb14_1.png';
import wb14_2 from '../image/wb14_2.png';
import wb14_3 from '../image/wb14_3.png';
import EmotionalInput from '../components/workbook/EmotionalInput';
import UserAnswerBox from '../components/workbook/UserAnswerBox';
import { TextWithBold } from '../components/workbook/TextWithBold';
import QuizTemplate from '../components/workbook/quiz/QuizTemplate';
import AnswerTemplate from '../components/workbook/quiz/AnswerTemplate';
import MessageBlock from '../stories/workbook/MessageBlock';
import MessageBlockWithTail from '../stories/workbook/MessageBlockWithTail';
import ABCModel from '../components/workbook/course1/ABCModel';
import MessageBlockAndTail from '../stories/workbook/MessageBlockAndTail';
import MessageBlockWithBold from '../stories/workbook/MessageBlockWithBold';

const course1Data = [
  [
    {
      title: '감정을 만드는 생각 찾기',
      image: wb13_1,
      bg: '#7655FA',
      subtitle: '어서오세요, 환영해요.',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '감정을 만드는 생각 찾기 코스는 나의 마음을 들여다보고 나에게 부정적인 영향을 미치는 생각들을 바로잡기 위한 목적으로 만들어졌어요. 같은 상황에서도 ',
                '늘 안 좋은 방향으로 생각',
                '이 드는 분, ',
                '낮은 자존감이나 불안정한 감정의 원인',
                '을 찾고 싶은 분들에게 많은 도움이 될 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '기대돼요!',
      link: '',
    },
    {
      title: '과학에 기반한 수업이랍니다',
      image: wb0_2,
      bg: '#FB8A4B',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '인사이드가 제공하는 프로그램은 과학에 기반하여 만들어졌어요. ',
                '서울대학교 의과대학 출신 의사들과 심리전문가들',
                '이 모여 많은 문헌들을 참고하고 연구를 통해 프로그램을 설계하였답니다. 프로그램은 ',
                '의학과 심리학에 기반',
                '되어 만들어졌고, 실제 ',
                '임상적으로 효과가 입증된 이론에 근거',
                '하여 만들어졌어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '어떤 이론 말인가요?',
      link: '',
    },
    {
      title: '인지행동치료 이론에 기반했어요',
      image: wb0_3,
      bg: '#FFCA43',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '인지행동치료는 불안장애, 신체증상, 섭식장애, 수면 등 다양한 정신의학영역에 널리 적용되고 있어요. 인지행동치료는 ',
                '단순한 심리테스트 혹은 심리컨텐츠가 아니랍니다.',
                ' 이미 의학적으로 체계적 문헌고찰(Systematic Review) 및 무작위대조시험(Randomized Controlled Trial)을 통해 그 ',
                '효과가 충분히 입증된 이론',
                '이에요. 하지만 인지행동치료는 정신질환뿐만 아니라 ',
                '자존감, 스트레스 등 다양한 영역에 적용',
                '될 수 있어요. 저희는 ',
                '본인의 마음에 관심이 있는 모든 사람들을 위한 수업',
                '을 제공할 계획이랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '과학에 기반한 과정을 진행하는 거네요',
      link: '',
    },
    {
      title: '하지만 성실한 참여가 필요해요',
      image: wb0_4,
      bg: '#4621D9',
      texts: [
        {
          text: [
            `아무리 잘 정립된 이론이라도 참여자가 건성으로 프로그램을 대하거나, 별로 깊게 생각하지 않는다면 별다른 효과가 없을 거예요. 인사이드의 프로그램도 마찬가지랍니다. 인사이드의 프로그램은 이용자가 충분히 학습하고 여유를 가지고 이론을 체화할 수 있도록 그 분량과 속도를 계산하여 설계되었어요. 따라서 어떤 과제가 주어졌을 때, 혹은 하나의 워크북이 제공되었을 때 정말로 마음을 들여다본다는 마음으로 임해야 충분한 효과를 가질 수 있어요.`,
          ],
          bold: false,
          numbering: false,
        },
      ],
      buttonText: '결국 제가 노력하는 만큼 얻을 수 있겠네요',
      link: '',
    },
    {
      texts: [
        {
          component: (
            <div
              className={`w-[full] h-[190px] relative overflow-hidden rounded-2xl mb-30px flex align-center bg-[#7655FA]`}
            >
              <div className="w-[335px] h-[234px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
                <img className="w-full h-full object-fit" src={wb0_5} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          component: (
            <p
              className="font-bold text-[24px] leading-[30px] mb-[15px] text-[#26282C]"
              style={{ wordBreak: 'keep-all' }}
            >
              열심히 참여하기로 약속해요
            </p>
          ),
        },
        {
          text: [
            '프로그램을 진지한 마음으로 꾸준히 참여하다 보면 보다 성숙한 나를 발견할 수 있을 거예요. 이미 전세계적으로 입증된 프로그램에 기반하여 만들어졌으니까요. 열심히 참여한다면 앞으로도 더 다양한 마음관리 도구들과 자료들을 보내드릴 거랍니다.',
            <br />,
            <br />,
            '자, 그러면 마음을 들여다볼 준비가 되었나요? 그러면 첫 장부터 진행해보도록 해요. 이번에도 닥터 스마일이 친절하게 알려줄 거예요.',
          ],
          bold: false,
          numbering: false,
        },
      ],
      buttonText: '준비됐어요!',
      link: '',
    },
  ],
  [
    {
      title: '생각에 집중하기',
      subtitle: '안녕하세요, 다시 보네요. 잘 왔어요.',
      fullImage: wb2_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이렇게 저를 찾아왔다는 말은 마음이 작동하는 원리에 대해 더 자세히 공부를 해 보고 싶다는 뜻이겠죠? 잘 생각했어요. 이번 코스에서 조금 더 자세한 내용을 배우고 나면 감정 노트와 회고 노트를 더 잘 활용할 수 있을 거예요.',
                '\n',
                '이번 코스 결론부터 말해볼게요. 생각은 감정을 만들어요.  “나는 왜 이런 기분을 느끼는 걸까” 이런 고민해 보신 적 있나요? 갑작스럽게 밀려오는 감정들이 가끔은 감당하기 힘들 때도 있죠. 그런데 실은 ',
                '나의 감정들은 ‘내가 무의식적으로 하고 있었던 생각’에서 비롯된답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '내 감정이 생각에서 비롯된다고요?',
      link: '',
    },
    {
      image: wb2_2,
      title: ['우리는 ‘생각된’ 감정을 느껴요'],
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '세계적인 심리 및 정신의학전문가인 Albert Elis와 Aaron T. Beck이 창립한 ',
                '인지행동이론',
                '에서는 우리들의 감정이나 행동이 어떤 사건의 직접적인 결과라고 보는 대신, 그 사람의 평가가 작용한 결과라고 보고 있어요. 그러니까 일어난 상황이 중요한 게 아니라 ',
                '그 상황을 어떠한 틀로 보느냐가 중요하다',
                '는 말이지요. 즉, 특정한 상황이 있을 때 우리는 그 상황에 대해 우리들의 관점으로 생각을 하고, 그 결과 우리는 특정한 감정을 느끼거나 행동을 해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '조금 더 쉽게 설명해주세요!',
      link: '',
    },
    {
      image: wb2_3,
      title: '예를 들어볼까요?',
      texts: [
        {
          text: [
            `길에서 지나가다가 직장 상사를 만나서 인사를 했다고 생각해 볼까요? 그런데 직장 상사가 인사를 받아주지 않는 거예요. 이때 어떤 감정을 느낄 수 있을까요? 그리고 그 감정의 이면에는 어떠한 생각이 자리잡고 있을까요?`,
          ],
        },
        {
          component: <AccordianContent />,
        },
      ],
      buttonText: '우울하고 짜증날 것 같아요',
    },
    {
      image: wb2_4,
      title: '다르게 생각하면 어떨까요?',
      texts: [
        {
          text: '그런데 만약 그냥 ‘날 잘 보지 못했나보다’라고 생각했다면 어떨까요? 그러면 불안, 우울, 분노와 같은 부정적인 감정을 느끼지 않앗을 수도 있지 않을까요? 그냥 ‘그러려니’하고 지나갔을 수도 있죠. 이처럼 생각을 바꾸는 것만으로도 나의 감정이나 행동을 바꿀 수 있답니다. 이러한 접근을 ‘인지행동치료’라고 한답니다. 인지를 교정하여 행동 변화를 이끌어낸다는 뜻이지요. 이제 우리가 왜 감정 노트와 회고 노트를 시작했는지 이해가 되나요?',
        },
      ],
      buttonText: '생각하는 방식에 따라 감정이 달라지는군요!',
      link: '',
    },
    {
      replaceComponent: (
        <QuizTemplate
          question="우리들의 감정을 만드는 것 중 핵심적인 부분은 무엇일까요?"
          buttonList={['상황', '생각', '행동', '의지']}
          answer="생각"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요!"
          buttonText="마치기"
          answerInfo={
            <TextWithBold
              texts={[
                '우리들의 인식, 감정, 그리고 행동은 ',
                '생각과 밀접한 관련',
                '이 있어요. 이제부터는 추측과 예상, 믿음으로 이루어지는 생각의 작동 방식에 대해 살펴보려고 해요. 또한 ',
                '생각이 어떻게 패턴이 되어 감정과 정신 건강에 영향을 미치는지',
                '도 알아볼 거예요. 그리고 그러한 지식을 토대로 우리들이 더 객관적으로 우리 자신을 바라보고 생각할 수 있도록 할 계획이랍니다.',
                '\n',
                '감정을 만드는 생각 찾기, 나를 만드는 생각 찾기, 이제 시작이에요 :)',
              ]}
            />
          }
          isFinalPage
        />
      ),
    },
  ],
  [
    {
      title: '자동적 사고 파악하기',
      fullImage: wb3_1,
      height: 253,
      subtitle: '문득 빠르게 떠오르는 생각들이 있어요',
      texts: [
        {
          text: [`다음과 같은 상황을 생각해 볼까요?`],
          component: (
            <MessageBlock
              textList={[
                ['새 휴대폰을 구입했는데 24시간 동안 먹통이에요. 몹시 짜증 나고 답답하겠죠.'],
                [
                  '새 휴대폰을 구입했는데 24시간 동안 먹통이에요. 휴대폰을 한쪽으로 치워 두고 하던 일을 마저 하러 간다면 몇 시간 후에 휴대폰이 생각이 불쑥 나더라도 감정이 널뛰진 않을 거예요.',
                ],
                [
                  '새 휴대폰을 구입했는데 24시간 동안 먹통이에요. 곧장 옆 사람 휴대폰을 빌려 가게에 전화하고 새 휴대폰을 집으로 보내 달라고 부탁하며 강단 있고 자신감 넘치는 방식으로 대응할 수도 있을 거예요.',
                ],
              ]}
              type="gray"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이런 인식의 차이는 대개 자동적 사고 때문에 발생하는데요, 자동적 사고란 ',
                '어떤 감정을 유발하는 상황이나 환경에 반응해 빛처럼 빠르게 떠오르는 생각',
                '을 뜻해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '저는 그런 걸 느껴본 적이 없는 걸요?',
      link: '',
    },
    {
      image: wb3_2,
      title: '쉽게 인지할 수는 없답니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '보통 이런 생각은 마치 버릇처럼 의식할 새도 없이 순식간에 스쳐 지나가죠. 그래서 ',
                '자동적 사고',
                '라고 부른답니다. 우리는 자동적 사고를 쉽게 인지하지 못해요. 그보다는 ',
                '자동적 사고가 일어나는 순간에 느끼는 감정만 인지',
                '하게 되죠. 이때 감정을 유발한 생각이 뭔지 파악하지 못하면 어째서 갑자기 이런 기분이 드는지 몰라 어리둥절할 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '그럼 늘 어리둥절해야 하나요...?',
      link: '',
    },
    {
      image: wb3_3,
      title: ['하지만 노력하면 인지할 수 있어요'],
      texts: [
        {
          text: [
            `노력을 기울이면 자동적 사고를 인식하고 감정을 불러일으키는 근본적인 원인을 제어할 수 있답니다. 이게 바로 이번 수업에서 우리가 살펴볼 내용이에요. 새 휴대폰을 구입한 상황으로 다시 돌아가볼까요? 이번에는 감정이 발생하기 전에 떠올랐던 자동적 사고까지 함께 확인해 보죠.`,
          ],
          bold: false,
          numbering: false,
        },
      ],
      buttonText: '생각에 따라 감정이 달라지네요!',
      link: '',
    },
    {
      title: '다시 한 번 살펴볼까요?',
      texts: [
        {
          text: [
            `자동적 사고가 감정과 어떠한 관련이 있는지 생각해보며 살펴볼게요.`,
            <br />,
            <br />,
            '새 휴대폰을 구입했는데 24시간 동안 먹통이에요.',
          ],
          component: (
            <MessageBlockWithTail
              textList={[['물건을 새로 사면 어김없이 고장이 난다니까. 난 저주받은 게 틀림없어. 언제나 불행해.']]}
              type="white"
              my
            />
          ),
        },
        {
          text: [`자동으로 이런 생각이 드는 사람은 몹시 짜증나고 답답하겠죠.`],
        },
      ],
      buttonText: '정말 짜증나는 상황이에요',
      link: '',
    },
    {
      title: '다르게 생각했으면 어떨까요?',
      texts: [
        {
          text: [`이렇게 생각했으면 어떨까요?`, <br />, <br />, '새 휴대폰을 구입했는데 24시간 동안 먹통이에요.'],
          component: (
            <MessageBlockWithTail
              textList={[['휴대폰이 안 되네. 가게에 도로 가져가면 해결해 주겠지.']]}
              type="white"
              my
            />
          ),
        },
        {
          text: [
            `이렇게 생각한다면 휴대폰을 한쪽으로 치워 두고 하던 일을 마저 하러 갈 테고 몇 시간이 지나고 나서야 다시 휴대폰 생각이 날 거예요.`,
            <br />,
            <br />,
            `조금은 반응이 다르죠?`,
          ],
        },
      ],
      buttonText: '별 일이 아닌 것 같기도 하네요',
      link: '',
    },
    {
      title: '더 다르게 반응할 수도 있어요',
      texts: [
        {
          text: [`혹은 이렇게 생각했을 수도 있겠죠.`, <br />, <br />, '새 휴대폰을 구입했는데 24시간 동안 먹통이에요.'],
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '이게 말이 돼? 휴대폰 사느라 깨진 돈이 얼만데! 애초에 문제가 있는 제품인데 고치러 가는 시간이 아깝다.',
                ],
              ]}
              type="white"
              my
            />
          ),
        },
        {
          text: [
            `이런 생각이 든다면 곧장 옆 사람 휴대폰을 빌려 가게에 전화해서 새 휴대폰을 집으로 보내 달라고 부탁하겠죠. 강단 있고 자신감 넘치는 방식으로 대응하는 거예요.`,
            <br />,
            <br />,
            `조금은 반응이 다르죠? 이처럼 자동적 사고와 감정은 밀접한 연관이 있어요.`,
          ],
        },
      ],
      buttonText: '상황을 통제하고 있다는 기분이 들어요',
    },
    {
      image: wb3_4,
      title: '마음이 작동하는 방식이랍니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '자동적 사고는 자연스럽게 감정을 유발하고 곧바로 사라지는데요, 의식하지 못하다 보니 자동적 사고가 ',
                '일어나지 않는다고 생각할 수 있지만 누구나 자동적 사고를 한답니다.',
                ' 자동적 사고는 우리 마음이 작동하는 방식이에요. 그리고 이런 생각 덕분에 기분이 좋아지기도 하지만 오히려 기분이 상하기도 해요. 앞서 예시로 살펴봤듯이 말이에요. (물론 기분이 좋거나 나쁜 정도는 다양하겠지만요.)',
              ]}
            />
          ),
        },
      ],
      buttonText: '생각의 습관 같은 거네요!',
    },
    {
      title: '그리고 우리는 자동적 사고를 통해 유발된 감정을 바탕으로 세상을 이해해요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '똑같은 상황에 직면하더라도 생각하는 방식은 사람마다 제각기 다르답니다. 각자 예전 경험을 바탕으로 상황을 받아들이기 마련이거든요. 이미 우리가 살펴봤듯이 ',
                '생각은 감정에 영향',
                '을 미쳐요. 하지만 반대로 ',
                '감정에 따라 상황을 다르게 판단',
                '할 수도 있죠.',
              ]}
            />
          ),
        },
        {
          text: [
            <br />,
            '톰와 제니가 만나기로 약속했다가 30분 전에 톰이 취소했다고 가정해 볼까요. 제니가 어떤 반응을 보일지는 그 순간의 감정이나 예전에 다른 사람에게 바람 맞았던 경험, 혹은 약속이 취소된 상황을 어떻게 인지하는지에 달려 있을 거예요.',
            <br />,
            <br />,
            "제니가 보일 수 있는 반응은 아주 다양하겠죠. 미리 약속까지 잡았는데 안 만나겠다고 하니 상대방이 날 싫어하나 싶어 괜스레 속상할지도 몰라요. 기분이 좋은 상태라면 별로 개의치 않을 수도 있어요. '톰이 날 싫어하진 않잖아. 피치 못할 사정이 있었겠지.' 이렇게 생각하고 그냥 넘어갈 수 있죠.",
            <br />,
            <br />,
            '중요한 사실은 그러한 생각들이 거의 자동적으로 발생해서 우리가 이를 인지하지 못할 때가 많다는 것이에요.',
          ],
        },
      ],
      link: '',
      buttonText: '결국 자동적 사고가 중요한 거네요!',
    },
    {
      replaceComponent: (
        <QuizTemplate
          question="쉽게 인지할 순 없지만 우리들의 감정 형성에 큰 영향을 미쳐서 우리가 세상을 이해하는 방식에 관여하는 건 무엇일까요?"
          buttonList={['자동적 사고', '공상', '체계적 학습', '지식 기반 사고']}
          answer="자동적 사고"
        />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="정답이에요! 금방 배우시네요!" buttonText="제가 맞췄네요!" />,
    },
    {
      title: '정리해볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '정리하면 삶의 경험, 가정 환경, 생물학적인 특성 등 아주 다양한 요인이 생각에 영향을 미쳐요. 그리고 그러한 생각, 그중에서도 특히 ',
                '우리가 알아차리지 못했던 자동적 사고는 우리들의 감정을 형성',
                '하죠. 우리는 ',
                '이를 바탕으로 상황을 인지하고 빠르게 판단해서 주변 세상을 이해',
                '해요.',
                '\n',
                '즉, 특정 상황에서 자동적 사고가 작용하여 우라들의 감정을 형성하고, 우리는 그러한 감정을 바탕으로 세상을 이해하고 인지해요.',
                '\n',
                '앞으로는 자동적 사고가 무엇인지 배운 뒤 이런 생각을 인식하고 확인하는 방법도 알아볼 거예요. 자동적 사고를 파악하면 도움이 되지 않는 생각을 적절하게 처리할 수 있답니다. 이 방법은 인지행동치료의 핵심이라 할 수 있는데요, 이미 널리 알려져 있고 효과도 좋은 심리 치료 방법이랍니다.',
                '\n',
                '그럼 오늘은 여기까지 하는 걸로 해요. 한번에 너무 많은 것들을 배우면 너무 벅찰 거예요. 내일은 이러한 자동적 사고를 어떻게 인지하는지에 대해 배워볼 거예요. 그동안 감정 노트 잘 이용하는 것 잊지 말고요!',
              ]}
            />
          ),
        },
      ],
      link: '',
      buttonText: '제 자신을 돌아볼 수 있을 것 같아요!',
    },
  ],
  [
    {
      title: '자동적 사고 인지하기',
      fullImage: wb4_1,
      subtitle: '오늘은 더 깊은 이야기를 나눠볼 거예요.',
      height: 390,
      texts: [
        {
          text: [
            `오늘은 한 번의 수업으로 진행할 거예요. 그러니 집중해서 배워보도록 해요. 우선 지난 수업에서 했던 이야기들을 조금 더 살펴볼까요?`,
          ],
        },
      ],
      buttonText: '좋아요. 준비됐어요!',
      link: '',
    },
    {
      replaceComponent: (
        <QuizTemplate
          question="말꼬리로 표시된 것과 같은 생각의 과정을 무엇이라고 말할까요?"
          buttonList={['지식 기반 사고', '체계적 학습', '자동적 사고', '공상']}
          answer="자동적 사고"
          contents={[
            {
              component: (
                <div>
                  <p>지나가던 선배에게 인사를 했는데 선배가 인사를 무시했어요.</p>

                  <MessageBlockWithTail
                    textList={[['뭐야. 왜 내 인사 무시해? 나 싫어하는 거야? 내가 뭐 잘못했나?']]}
                    type={'black_dotted'}
                    margin="my-[30px]"
                  />
                  <p>나도 모르게 이런 생각이 든 나는 곧 불안하고 초조해지기 시작했어요.</p>
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: <AnswerTemplate title="정답이에요!" buttonText="제가 맞췄네요!" />,
    },
    {
      image: wb4_3,
      title: '잘했어요! 그럼 더 이야기해볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '자동적 사고는 자발적으로 떠오르는 것이지 강렬한 사고 과정의 결과는 아니에요. 일부러 ',
                '주의를 기울이지 않는 한 명확하게 의식에 남지도 않는답니다.',
                ' 대신 ',
                '자동적 사고가 스쳐 지나가는 순간에 느끼는 감정이나 떠오르는 행동만 의식',
                '하게 될 거예요.',
                '\n',
                '물론 의식할 순 없지만 누구나 자동적 사고를 해요. 자동적 사고 덕분에 깊이 생각하지 않더라도 상황에 재빨리 반응할 수 있죠. 불이 났다고 가정해 볼까요? 위험한 상황이니 어서 빠져나가야 한다는 생각이 자동으로 들 거예요. 두려운 마음에 서둘러 달려 나가겠죠. 이런 자동적 사고가 일어나지 않는다면 상황을 파악하고 적절히 반응하는 데 훨씬 오랜 시간이 걸릴 거예요. 따라서 ',
                '자동적 사고',
                '는 ',
                '일종의 생존 유지 장치',
                '라고 볼 수 있어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '자동적 사고도 꼭 필요한 거군요',
      link: '',
    },
    {
      title: '하지만 문제가 있을 때도 있어요',
      fullImage: wb4_4,
      height: 420,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '위험에 빠르게 대처하도록 돕는 자동적 사고는 아주 유용하죠. 하지만 모든 자동적 사고가 도움이 되는 건 아니에요. ',
                '끝없이 자신을 갉아먹는 자기비판이나 삐딱하게 꼬인 자동적 사고',
                '는 ',
                '극단적이고 부정확하며 과장된 경우가 대부분',
                '인데요, 우리에게 고통을 안겨 주고 상황을 순조롭게 해결하지 못하도록 훼방을 놓죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: '그런 생각들로는 어떤 게 있죠?',
      link: '',
    },
    {
      title: '예시를 들어볼게요',
      texts: [
        { text: '이처럼 문제를 유발하는 자동적 사고의 예시를 몇 가지 들어 볼게요.' },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['난 쓸모없는 인간이야'],
                ['내 맘을 알아주는 사람은 아무도 없어'],
                ['누가 날 사랑하겠어'],
                ['난 주변 사람들한테 도움이 안 돼'],
                ['전부 다 내 잘못이야'],
                ['아무도 날 진심으로 좋아하지 않아'],
              ]}
              type={'gray'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '민수와 영희가 만나기로 약속했다가 30분 전에 민수가 취소한 상황을 다시 떠올려볼까요? 민수가 약속을 취소했을 때 ',
                '자기를 싫어해서 그랬을 거라고 생각한다면(“누가 날 사랑하겠어")',
                ' 영희는 크게 낙담할 거예요. 민수를 평소에 많이 아꼈다면 충격이 더 크겠죠. 혹시나 더 상처 입진 않을까 싶어 두려운 마음에 민수에게 다시는 연락하지 않을지도 몰라요. ',
                '\n',
                '하지만 민수가 약속을 취소한 이유가 영희에게 불만이 있어서가 아니라 몸이 아팠거나 당장 처리해야 할 급한 업무 때문이었다면 어떨까요? 갑자기 자신을 밀어내는 영희의 모습에 오히려 민수가 당혹스러울 거예요.',
              ]}
            />
          ),
        },
      ],
      link: '',
      buttonText: '좋지 않은 생각의 습관이네요',
    },
    {
      image: wb4_5,
      title: '문제를 유발하는 자동적 사고를 인식할 수 있어야 해요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '문제를 유발하는 자동적 사고를 해결하려면 우선 생각 자체를 인식해야겠죠. 이후 ',
                '생각이 떠오르는 패턴',
                '을 찾아내고 이에 따라 나타나는 반응을 주의 깊게 살펴봐야 해요.',
                '\n',
                '자동적 사고는 특별히 주의를 기울이지 않는 이상 의식하기 힘들기 때문에 ',
                '생각을 알아차리는 방법을 배우고 순간순간 포착하는 연습',
                '이 필요하답니다. 가장 좋은 방법은 ',
                '기대했거나 원하던 바와 다르게 느끼고 행동하는 상황에 관심을 기울이는 것',
                '이에요. 예를 들면 친구의 말 한 마디에 괜히 화가 나거나, 회사에서 실수를 저지르는 바람에 당혹스럽다거나, 가족의 반가운 소식에 오히려 기분이 언짢아질 수 있잖아요? 그러한 경우를 잘 포착해야 한답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '어떻게 연습을 할 수 있을까요?',
    },
    {
      title: '몇 가지 팁을 드릴게요!',
      texts: [
        {
          text: ['이런 상황들을 주의 깊게 관찰해 보세요.', <br />],
        },

        {
          component: (
            <MessageBlockWithBold
              textList={[
                { bold: '1. 감정 변화', text: ['\n갑자기 슬프거나 화가 나나요?'], type: 'light_blue', tail: true },
                {
                  bold: '2. 행동 변화',
                  text: [
                    '\n좋지 않은 행동이라는 걸 알면서도 실행에 옮기고 있나요? 혹은 중요한 상황을 그저 회피하고 있나요?',
                  ],
                  type: 'light_blue',
                  tail: true,
                },
                {
                  bold: '3. 신체적인 반응',
                  text: ['\n갑자기 이유없이 가슴이 두근거리거나 속이 울렁거리세요?'],
                  type: 'light_blue',
                  tail: true,
                },
              ]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '감정과 행동, 신체적인 반응에서 변화가 있는지 관찰하면서 그때 상황과 생각을 감정 노트에 적어 보세요. 우선은 ',
                '감정 변화, 행동 변화, 신체적인 반응에 초점을 맞추어 기록',
                '해 보세요. ',
                '\n',
                '다음 수업에서는 이러한 기록들을 어떻게하면 더 체계적으로 작성할 수 있는지에 대해 알려드릴 거예요. 오늘도 고생 많았어요. 저는 노곤해서 잠시 자야겠어요. 그럼 우리는 내일 다시 만나기로 해요.',
              ]}
            />
          ),
        },
      ],
      link: '',
      buttonText: '좋아요, 기대돼요!',
    },
  ],
  [
    {
      title: 'ABC로 감정 파악하기',
      fullImage: wb5_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이제 함께 감정을 기록해 보려고 해요. 그 전에 감정을 왜 기록해야 하는지 기억나시나요? 다시 한번 되짚고 넘어가볼까요? 우리들은 특정한 상황에서 특정한 생각을 해요. 이때 우리가 하는 생각은 대부분 우리가 알아차리지 못하는 ‘자동적 사고’로부터 기인한 것들이죠. 그리고 이러한 생각들은 우리들의 감정을 형성해요. 결국 우리들은 그러한 감정을 가지고 다시 상황을 파악하고 현재를 이해하게 되죠. 자세히 살펴보면 ',
                '결국 자동적 사고로부터 상황에 대한 우리들의 이해와 반응이 결정',
                '된답니다. 우리는 이러한 자동적 사고를 살펴보기 위해 감정기록을 하고 있는 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '준비됐어요. 더 알려주세요!',
      link: '',
    },
    {
      id: 1,
      image: wb5_2,
      category: '인사이드 이야기',
      title: ['좋아요. 이제 시작해볼까요?'],
      texts: [
        {
          text: [
            `
            여러분이 지난 며칠간 생각을 주의 깊게 살펴보았다면 어떤 생각은 유달리 자주 떠오른다는 점을 알게 되었을지도 몰라요.`,
            <br />,
            <br />,

            `또한 특정 생각이 특정 감정을 계속 유발하고 이런 감정이 여러분의 신체와 행동에 영향을 미친다는 점을 알게 되었을 수도 있죠. 이런 연관성은 스스로 파악하기 어렵기 때문에 전혀 눈치채지 못한 분도 있을 수 있어요.`,
            <br />,
            <br />,
            `이제 그러한 연관성을 더 쉽게 파악할 수 있도록 하나의 방법을 알려드릴게요!
          `,
          ],
        },
      ],
      link: '',
      buttonText: '어떤 방법이요?',
    },
    {
      id: 2,
      category: '인사이드 이야기',
      title: 'A-B-C-D-E 모형이에요!',
      texts: [
        {
          text: [
            `A-B-C-D-E 모형은 생각이 감정과 행동에 어떻게 영향을 미치는지 이해할 때 유용한 도구랍니다. 이 모형은 해외의 뛰어난 학자들에 의해 만들어져서 이름이 A-B-C-D-E 모형이랍니다. 각각이 의미하는 바는 다음과 같아요.
            `,
          ],
          component: (
            <MessageBlockWithBold
              textList={[
                { bold: 'A', text: ['ctivating events - 사건'], type: 'gray' },
                { bold: 'B', text: ['eliefs - 믿음'], type: 'gray' },
                { bold: 'C', text: ['onsequences - 결과'], type: 'gray' },
                { bold: 'D', text: ['ispute beliefs - 믿음 반박'], type: 'gray' },
                { bold: 'E', text: ['ffect - 효과'], type: 'gray' },
              ]}
              my
            />
          ),
          bold: false,
          numbering: false,
        },
        {
          text: [
            `A-B-C-D-E  모형은 상황을 정확하게 설명하는 데 도움이 된답니다. 또한 생각과 감정의 근본적인 원인이 무엇이고 행동과 어떻게 연관되는지도 파악할 수 있어요.`,
          ],
          bold: false,
          numbering: false,
        },
      ],
      buttonText: '어디선가 본 것 같은 내용이에요',
      link: '',
    },
    {
      image: wb5_3,
      title: '이렇게 말하면 더 쉬울까요?',
      texts: [
        {
          text: [
            `위 도식 생각나나요? 사실 위 도식으로 설명했던 것과 같은 이야기예요. 아래와 같이 기억하면 더 이해가 쉬울 거예요!
            `,
          ],
          bold: false,
          numbering: false,
          component: (
            <div className="rounded-2xl bg-[#F3F5F8] my-24px ">
              <img alt="empty" src={wb5_6} className="w-full object-contain h-[190px]" />
            </div>
          ),
        },
        {
          text: [
            `감정과 행동을 묶어서 하나의 결과라고 생각하시면 이해하기 더 쉬울 거예요.`,
            <br />,
            <br />,

            `우선은 A-B-C-D-E 중에서 A-B-C에 대해 먼저 알아봐요. D-E에 대해서는 우리 감정을 들여다보는 데에 조금 더 익숙해지고 난 뒤 배우도록 해요.`,
          ],
        },
      ],
      buttonText: '좋아요! A-B-C 먼저 살펴봐요!',
      link: '',
    },
    {
      replaceComponent: (
        <QuizTemplate
          question="아래 그림에서 A-B-C에 어울리는 단어는 무엇일까요?"
          buttonList={[
            'A: 믿음, B: 결과, C: 사건',
            'A: 사건, B: 결과, C: 믿음',
            'A: 사건, B: 믿음, C: 결과',
            'A: 결과, B: 믿음, C: 사건',
          ]}
          answer="A: 사건, B: 믿음, C: 결과"
          image={wb5_4}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요! 금방 배우시네요!"
          buttonText="마치기"
          answerInfo={
            <TextWithBold
              texts={[
                '좋아요. 이제 각각의 내용들을 조금 자세히 살펴볼 준비가 된 것 같아요. 다음 수업에서는 A, B, C 각각에 대해 자세히 살펴보고, 실제 여러분의 A-B-C를 분석해보는 시간을 가질 거예요.',
                '\n',
                '그러면 잠시 쉬고 와서 만날까요? 저도 커피 한 잔 하고 와야겠어요.',
              ]}
            />
          }
          isFinalPage
        />
      ),
    },
  ],
  [
    {
      id: 0,
      category: '자동적 사고 인지하기',
      title: '상황, 생각, 그리고 결과',
      fullImage: wb6_1,
      height: 345,
      subtitle: '이제 더 자세히 살펴볼 거예요',
      texts: [
        {
          text: [
            `벌써 여기까지 왔군요. 잘 따라오셔서 저도 즐겁네요. 이번 수업을 통해서는 A-B-C의 구체적인 내용에 대해 자세히 살펴볼 거예요. 이번 수업을 확실히 익히고 나면 다음 수업에서 내 마음을 들여다볼 때 도움이 많이 될 거예요.`,
          ],
        },
      ],
      buttonText: '좋아요!',
      link: '',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: ['Activating events - 사건'],
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '여기서 사건은 ',
                '감정이나 신체적인 반응을 유발하는 상황',
                '을 의미해요. 방금 벌어진 일이나 예전 일에 대한 기억, 상상 속 모습처럼요. 이를테면',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              type="black"
              textList={[
                ['면박을 줬던 직장 상사와 나눴던 대화'],
                ['친구와 만났다가 왠지 소외감을 느꼈던 경험'],
                ['먼저 떠나보낸 사람과의 추억'],
                ['어린아이나 가까운 친구의 죽음'],
              ]}
              my
            />
          ),
        },
        {
          text: '이러한 일들이 사건에 해당할 수 있어요. 예시를 하나 들어볼게요. 앞으로 이 예시를 통해 설명을 할 거예요.',
          component: (
            <MessageBlock
              type="black"
              textList={[['매일 대화를 주고 받던 친구가 3일 동안 전화 한 통이 없어요']]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '감정을 유발한 상황이 사건이군요!',
    },
    {
      id: 2,
      category: '인사이드 이야기',
      title: 'Beliefs - 믿음',
      texts: [
        {
          component: (
            <TextWithBold
              texts={['믿음은 ', '선행 사건과 연관된 생각', '이에요. 자동적 사고 혹은 의식적인 상황 판단을 의미하죠.']}
            />
          ),
        },
        {
          text: [
            <br />,
            `친한 친구가 3일 동안 전화 한 통이 없던 상황 기억하고 계시죠? 그런 사건이 발생했을 때 여러분은 다음과 같이 믿을 거예요.
            `,
          ],
          component: (
            <MessageBlockWithTail
              textList={[['스트레스를 많이 받아서 머릿속이 복잡한가 봐'], ['몸이 안 좋은가 보지']]}
              type="white_dotted"
              my
            />
          ),
          bold: false,
          numbering: false,
        },
        {
          text: '이처럼 믿음은 선행 사건과 관련된 생각이라고 할 수 있어요.',
        },
      ],
      buttonText: '사건과 관련된 생각이 믿음!',
      link: '',
    },
    {
      id: 3,
      category: '인사이드 이야기',
      title: 'Consequences - 결과',
      image: wb6_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '결과는 ',
                '믿음에 대한 감정적, 신체적, 혹은 행동적 반응',
                '을 뜻해요.',
                '\n',
                <p />,
                '감정적 결과',
                '로는 분노, 짜증, 슬픔, 행복, 두려움, 불안감 등이 있죠. 심장박동이 증가하거나 땀이 나고 닭살이 돋으며 근육이 긴장하고 울음을 터뜨리는 건 ',
                '신체적 결과',
                '이고요. ',
                '행동적 결과',
                '는 뒤로 물러나거나 피하는 동작부터 공연히 트집을 잡는 태도, 혹은 사랑하는 사람에게서 위로와 위안을 얻으려는 모습을 의미해요.',
                '\n',
                '여기서 결과는 사건에 대한 결과가 아니라 믿음에 대한 결과랍니다. 다시 말해 ',
                '감정적, 신체적 느낌, 그리고 행동이 선행 사건 자체에 의해 결정되는 것이 아니에요. 우리들의 감정, 느낌, 행동은 특정한 감정과 행동을 유발하는 사건을 어떻게 믿고 판단하는지에 의해 결정된답니다.',
                '\n',
                <p />,
                '참 다행이죠. 이미 일어난 일은 돌이킬 수 없지만 믿음은 상황을 정확히 인지하고 해결하도록 점검할 수 있으니까요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '믿음에 따라 결과가 달라지네요!',
      link: '',
    },
    {
      id: 4,
      category: '인사이드 이야기',
      title: 'Consequences - 결과',
      texts: [
        {
          text: '스트레스를 많이 받아 힘들어할 친구를 생각하면 여러분은 안타까운 마음이 들겠죠. 어디 아픈가 싶어 걱정이 될 테고요. 어쩌면 친구가 괜찮은지 확인차 친구 집에 직접 방문을 할지도 몰라요. 이러한 모든 반응들이 결과에 해당한답니다.',
          component: (
            <MessageBlockWithBold
              textList={[
                {
                  bold: '사건: ',
                  text: ['매일 대화를 주고 받던 친구가 3일 동안 전화 한 통이 없어요'],
                  type: 'black',
                },
                {
                  bold: '믿음: ',
                  text: ['‘스트레스를 많이 받아서 머릿속이 복잡한가 봐’'],
                  type: 'white_dotted',
                  tail: true,
                },
                { bold: '결과: ', text: ['친구 집에 방문하여 친구가 괜찮은지 확인해 봐요'], type: 'light_blue' },
              ]}
              margin="mt-[40px]"
            />
          ),
          bold: false,
          numbering: false,
        },
      ],
      buttonText: '사건-믿음-결과, 잘 이해됐어요!',
    },
    {
      id: 5,
      category: '인사이드 이야기',
      title: '한번 같이 분석해 볼까요?',
      texts: [
        {
          text: [
            `다음과 같은 상황을 생각해 봐요. 회사에서 컴퓨터 앞에 앉아 있는 샘에게 동료 사만다가 다가오더니 상사가 찾는다고 말해줘요.`,
            <br />,
            <br />,

            `샘은 말을 듣자마자 속이 뒤집히고 손바닥에 땀이 나기 시작해요. 사만다가 거들먹대는 걸 보니 무슨 일인지 아는 눈치인데, 어쨌든 예감이 불길하네요.`,
            <br />,
            <br />,

            ` 샘은 혹시 본인이 잘못한 게 있는지 빠르게 되짚어 보다가 어제 10분 지각한 일을 번뜩 떠올리죠. 동료 중 누군가 상사에게 일러바친 게 틀림없어요. 배신감에 치를 떨다가 머리끝까지 화가 치민 샘은 사만다에게 벌컥 화를 내고 말아요. 
           `,
            <br />,

            '“네 자리로 돌아가. 남 일에 신경 끄라고!”',
            <br />,
            <br />,

            `이 내용을 한번 같이 분석해 볼까요?`,
          ],
          bold: false,
        },
      ],
      link: '',
      buttonText: '충분히 생각했어요!',
    },
    {
      id: 6,
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={3}
          title="어떤 상황이었나요?"
          placeholder="사건을 객관적으로 적어보세요"
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      id: 7,
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={3}
          title="이때 샘은 어떤 생각을 했나요?"
          placeholder="사건 속에서 샘의 생각을 떠올려 보세요"
          buttonText="다음"
        />
      ),
    },
    {
      id: 8,
      replaceComponent: (
        <EmotionalInput
          num={2}
          sum={3}
          title="샘의 반응은 어떨까요?"
          placeholder="결국 샘은 어떤 반응을 했나요?"
          buttonText="다음"
        />
      ),
    },
    {
      id: 9,
      category: '인사이드 이야기',
      title: '한번 비교해 볼까요?',
      texts: [
        {
          text: [`여러분은 아래와 같이 분석했어요.`],
          component: <UserAnswerBox />,
          bold: false,
        },
        {
          text: '저는 아래와 같이 분석해봤답니다. 정답이 있는 건 아니에요. 비슷한 내용으로 분석했는지 한번 살펴보세요!',
          component: (
            <MessageBlockWithBold
              textList={[
                {
                  bold: '사건: ',
                  text: ['상사가 호출한다는 소식을 동료 사만다로부터 전달받았어요.'],
                  type: 'black',
                },
                {
                  bold: '믿음: ',
                  text: [
                    '예감이 좋지 않다고 느껴요. 상사가 기분이 언짢은 게 틀림없는 것 같아요. 아무래도 지각한 사실을 누가 일러바친 것 같아요. 사만다의 반응을 보니 분명 사만다가 일러바친 게 틀림없다고 생각해요.',
                  ],
                  type: 'white_dotted',
                  tail: true,
                },
                {
                  bold: '결과: ',
                  text: [
                    '상사가 뭐라고 할지 몰라 불안하기도 하고 두려운 마음에 긴장되고 화도 많이 나요. 결국 분을 참지 못하고 동료에게 소리를 지르며 스트레스를 표출했어요.',
                  ],
                  type: 'blue',
                },
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '이제 혼자서도 분석할 수 있을 거 같아요!',
    },
    {
      id: 10,
      category: '인사이드 이야기',
      image: wb6_3,
      title: '좋아요. 이제 우리 마음을 살펴봐요!',
      texts: [
        {
          text: [
            `이제 정말 A-B-C, 사건-믿음-결과에 대해 잘 알고 계신 것 같아요. 다음 시간에는 이번 수업의 내용을 바탕으로 실제 우리들의 A-B-C를 적어볼 거예요. 그리고 이후에는 우리들의 A-B-C를 분석하는 이론과 방법들에 대해 배울 예정이랍니다.`,
            <br />,
            <br />,

            `이제 조금씩 우리들의 마음에 다가가고 있는 것 같지 않나요?`,
          ],
        },
      ],
      link: '',
      buttonText: '기대돼요!',
    },
  ],
  [
    {
      title: '내 마음 살펴보기',
      fullImage: wb7_1,
      height: 399,
      subtitle: '이제 우리들의 마음에 대해 살펴봐요',
      texts: [
        {
          text: [
            `감정적, 신체적으로 불쾌했던 상황 혹은 바람직하지 않은 방식으로 행동했던 경험을 떠올리되, 당시 상황을 최대한 자세히 기억해 보세요. `,
          ],
        },
      ],
      link: '',
      buttonText: '준비됐어요!',
    },
    {
      id: 2,
      category: '인사이드 이야기',
      title: '순서를 조금 바꿔 생각해 볼 거예요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리는 결과, 그중에서도 감정에 초점을 맞추어 기록을 시작할 거예요. 감정이 우리들에게 가장 강렬하게 다가와 기록을 하기 용이하기 때문이에요. 그리고 감정에서 거슬러올라가 당시의 상황과 그때 했던 생각들을 되짚어볼 거랍니다.',
                '\n',
                '우선 최근에 있었던 일 중, ',
                '내 감정에 큰 영향을 미쳤던 일들, 나를 기쁘고, 슬프고, 불안하고, 화나고, 짜증나게 했던 일들',
                '을 떠올려볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '한번 생각해 볼게요!',
      link: '',
    },
    {
      id: 3,
      category: '인사이드 이야기',
      title: '다음은 사건이에요!',
      image: wb7_2,

      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '그 감정을 느꼈을 때 어떠한 사건이 있었는지, ',
                '나의 감정을 불러일으킨 사건 혹은 기억',
                '은 어떠한 것이었는지 적어보는 거예요. 주의할 점은 ',
                '믿음이나 판단, 평가를 배제하고 사실 위주로 적어야 한다는 점',
                '이에요. 무슨 일이 일어났는지 다음과 같은 내용들을 순서대로 적어보도록 해요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['처음 상황은 어땠나요?'],
                ['그때 누가 있었나요?'],
                ['그 일이 발생한 장소는 어디예요?'],
                ['그 일이 발생한 시기는 언제였어요?'],
              ]}
              type="gray"
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '적어볼게요!',
      link: '',
    },
    {
      id: 4,
      category: '인사이드 이야기',
      title: '이번엔 당시의 믿음, 즉 생각이에요',
      image: wb7_3,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '여기엔 ',
                '그때 당시 떠올랐던 생각',
                '을 써넣으면 돼요. 상황을 겪기 직전, 겪는 도중, 겪은 후에 어떤 생각이 들었나요? 어떤 판단을 내렸었나요? 그 상황 속에서 내가 왜 그렇게 반응했는지 되뇌이며, 당시에 어떻게 생각해서 그렇게 반응하게 된 것인지 기록해봐요!',
              ]}
            />
          ),
        },
      ],
      buttonText: '그때 왜 그렇게 반응했냐면요...',
    },
    {
      id: 5,
      category: '인사이드 이야기',
      title: '예를 들어볼게요.',
      texts: [
        {
          text: [
            `
            일을 하는데 동료가 계속 내 의견을 반대해서 짜증이 많이 났다고 생각해 볼게요. 만약 짜증난 이유가 ‘동료가 나를 무시하는 것 같다’라면 아래와 같이 감정을 요약할 수 있을 거예요.`,
          ],
          bold: false,
        },
        {
          component: (
            <MessageBlockWithBold
              textList={[
                {
                  bold: '감정: ',
                  text: ['진짜 짜증나요. 뭔가 존중받지 못하는 것 같고, 화가 나요.'],
                  type: 'light_blue',
                },
                {
                  bold: '사건: ',
                  text: ['동료가 미팅에서 자꾸 내 의견에 반대했어요.'],
                  type: 'black',
                },
                {
                  bold: '믿음: ',
                  text: ['동료는 나를 무시하는 게 틀림없어. 나를 깔보고 있는 걸까?'],
                  type: 'white_dotted',

                  tail: true,
                },
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '이해가 됐어요!',
    },
    {
      id: 5,
      image: wb7_4,
      category: '인사이드 이야기',
      title: '첫 감정기록을 시작해 볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이제 감정기록 연습을 며칠 동안 여러 차례 반복하세요. ',
                '힘든 상황과 마주하게 되거나 스스로 이해하기 힘든 방식으로 느끼고 행동하는 자신을 발견한다면 곧바로 감정기록을 작성하는 게 좋아요.',
                ' 최근에 발생한 사건일수록 정확하고 자세하게 기억할 수 있답니다.',
                '\n',
                '처음엔 A-B-C 방법을 사용해서 자동적 사고와 믿음을 알아차리고 감정이나 신체적인 감각, 행동과 어떤 연관이 있는지 찾아내는 게 힘들겠지만 시간을 충분히 들이고 연습하다 보면 점점 더 수월해질 거예요.',
                '\n',
                '자, 그럼 오늘은 여기까지 할까요? 많은 걸 배워서 머리가 아플 거예요. 수업이 끝나면 오늘 배운 내용을 찬찬히 되짚어보고, 우리는 내일 또 만나도록 해요. 감정 노트 꾸준히 사용하는 거 잊지 마시고요.',
              ]}
            />
          ),
        },
      ],
      link: '',
      buttonText: '매일 기록해 볼게요!',
    },
  ],
  [
    {
      title: '생각 함정 1',
      fullImage: wb8_1,
      height: 167,
      subtitle: ' 어제의 감정기록은 어땠나요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '저도 오랜만에 예전의 감정기록을 꺼내보았어요. 의과대학에서 공부를 하던 시절에 쓴 기록이었죠. 온통 힘들다며 삶은 고되다는 이야기밖이 없더라고요. 지금 꺼내보면 그렇게까지 힘들었던가 싶기도 한데 말이죠.',
                '\n',
                '감정기록을 다시 꺼내두고 살펴보면 ‘그렇게까지 생각할 만한 일은 아니었네’ 싶을 때가 있어요. 사실 생각이 진리처럼 느껴지더라도 실은 꽤나 주관적이라는 사실을 흔히들 놓치곤 하죠.',
                '\n',
                '다시 말해 인식은 환경에 따라 바뀔 수 있기 때문에 ',
                '똑같은 상황에 직면하더라도 그때그때 상황에 대한 생각은 달라지기 마련',
                '이랍니다. 예를 들어 잠을 몇 시간이나 잤는지, 배가 얼마나 고픈지, 혹은 그날 기분이나 이전 경험 등에 따라서요.',
              ]}
            />
          ),
        },
      ],
      link: '',
      buttonText: '다시 보면 또 다르게 생각할 것 같아요',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '하지만 어떤 생각은 반복적인 패턴을 가지고 나타나기도 한답니다',
      image: wb8_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '그때그때 달라지는 생각이지만 때로는 반복적인 패턴을 가지고 나타나는 생각도 있답니다. 특히 오류를 가지고 있는 생각들이 그래요. 우리는 이제 앞으로 이러한 생각들을 ',
                '생각 함정',
                '이라고 부를 거예요. 마치 함정처럼, 생각이 잘 나아가다가도 ‘쿵’하고 함정에 빠져버리는 경우가 있기 때문이죠.',
                '\n',
                '이전 코스에서 배웠던 내용 기억하시나요? 이번에는 그 내용을 조금 더 자세히 살펴본다고 생각하면 되겠어요.',
              ]}
            />
          ),
        },
      ],
      link: '',
      buttonText: '함정에 빠지지 않게 조심해야겠네요',
    },
    {
      id: 2,
      category: '인사이드 이야기',
      title: '대표적인 생각 함정으로는 다음과 같은 것들이 있어요',
      texts: [
        {
          component: (
            <MessageBlock
              textList={[
                ['흑백논리'],
                ['일반화'],
                ['좋은 일 회피하기'],
                ['경험과 다른 결론'],
                ['좁은 시야'],
                ['성급한 결론'],
                ['감정으로 판단하기'],
                ['당위 부여'],
                ['꼬리표 붙이기'],
                ['과도한 책임감'],
                ['최악의 시나리오 떠올리기'],
              ]}
              type="gray"
              my
            />
          ),
        },

        {
          text: [
            `매번 하는 모든 생각이 정답일 수는 없어요. 특히 생각 함정은 흔히 자동적 사고와 연관되어 세상을 있는 그대로 보지 못하도록 방해한답니다.`,
            <br />,
            <br />,
            `이번 수업에서는 가장 흔하게 발생하는 생각의 덫을 소개하고 대안으로 어떤 접근법이 있을지 예시를 살펴볼게요. 도움이 되지 않는 생각을 바로잡을 수 있도록요.`,
            <br />,
            <br />,
            `그럼 하나씩 살펴볼까요?`,
          ],
        },
      ],
      link: '',
      buttonText: '생각 함정 설명해 주세요!',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '흑백논리',
      image: wb8_3,

      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '흑백논리는 ',
                '극단적인 두 선택지 사이에서 타협점 없이 모 아니면 도라는 논리로 상황에 접근하는 방식',
                '이에요. 예를 들어볼게요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                ['브라운은 보고서를 작성하다 작은 실수가 있었다는 걸 깨닫고 이렇게 생각해요.'],
                ['보고서를 완전히 망쳐버렸잖아! 이대로 제출하면 안 되겠어.'],
                ['제임스는 아침부터 일진이 안 좋아요. 하는 일마다 자꾸 잘 풀리지 않네요.'],
                ['내 인생은 정말 최악이야!'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '최악의 하루네요',
    },
    {
      id: 2,
      category: '인사이드 이야기',
      title: '흑백논리, 가운데도 생각해 볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '사실 세상이 전부 이런 흑백논리로 돌아가진 않잖아요. ',
                '흑과 백 사이에는 훨씬 다양한 색이 존재',
                '하니까요.',
                '\n',
                '흑백논리로 상황을 판단하고 있었다면, ',
                '직면한 상황의 뉘앙스를 구체적으로 짚어 보세요.',
                ' 자세히 살펴보면 상황이 꼭 모 아니면 도에만 해당하진 않을 거랍니다. 이러한 방법은 불필요하게 느끼는 실망감, 슬픔, 심리적 고통, 분노 등과 같은 감정들을 누그러뜨리는 데에 도움이 될 거예요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                ['브라운은 같은 상황에서도 이렇게 생각했을 수 있을 거예요.'],
                [
                  '에고, 보고서에 오류가 있네. 휴, 어쩔 수 없네. 실수는 누구나 하는 걸. 잘못된 부분을 고쳐서 수정본을 제출하자.',
                ],
                ['제임스도 조금 더 가볍게 생각할 수 있지 않았을까요?'],
                ['오늘은 유달리 힘든 아침이었네.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '다르게 생각하니 훨씬 마음이 편하네요!',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '일반화',
      image: wb8_5,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '일반화는 ',
                '한 가지 사건을 가지고 자신 혹은 상황 전체를 전부 평가해버리는 것',
                '이에요. 흔히 실패를 경험하거나 실망감을 느낀 후에 이를 불행의 징조로 여길 때 사고의 일반화가 발생한답니다. 일이 잘 안 풀려서 발생한 감정이 이성적인 사고를 지배하는 것이죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                ['조는 서빙을 하던 도중 음료를 담은 쟁반을 엎지르고 말았어요.'],
                ['하, 음료를 엎질러서 엉망이 되었네. 식당에 손해만 끼쳤어. 난 왜 이렇게 덜렁댈까?'],
                [
                  '수잔은 회사에 지각했어요. 아파트에서 역까지 죽어라 뛰어갔지만, 도착하는 순간 지하철이 떠나버렸거든요.',
                ],
                ['내 인생은 엉망이야. 제대로 되는 일이 없어.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '스스로가 한심할 것 같아요',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '일반화, 과연 그럴까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이성적으로 생각해 볼까요? ',
                '사건 하나로 인생 전체를 결정짓거나 내가 어떤 사람인지 정의할 수는 없어요.',
                ' 실망감과 실수, 실패와 같은 경험에는 여러 요소가 작용하는 법이니까요. 내가 통제할 수 있는 경우도 있지만 통제할 수 없는 경우가 더 많죠. ',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['서빙을 하다 실수를 한 조는 다음과 같이 생각할 수도 있지 않을까요?'],
                [
                  '음료를 엎질렀잖아. 그래도 지난 한 달 간 엎지르지 않고 옮긴 쟁반이 더 많은 걸. 이런 사고는 언제든 발생할 수 있으니까 유리잔 옮길 때 더 조심해야겠어.',
                ],
                ['수잔도 다르게 생각할 수 있어요'],
                ['열차를 놓쳐 버렸네. 운이 나빴던 거야. 늦을 거 같다고 회사에 미리 연락해야겠다.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '어라? 별일 아닌 것 같아요!',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '좋은 일 회피하기',
      image: wb8_7,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리나라 사람들에게 칭찬을 하면 가장 많이 하는 답변이 뭘까요? 바로 “아니에요”랍니다. 작은 일인 것 같지만 ',
                '긍정적인 사건이나 상황을 무시하고 깎아내리는 경향',
                '도 흔히 발생하는 생각 함정이랍니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                ['케이티는 회사에서 프로젝트를 완성하고 동료에게 칭찬을 받았어요. 하지만 이렇게 생각하죠.'],
                ['누구나 할 수 있는 일이야. 이게 뭐 별거라고.'],
                ['이발을 하고 출근한 브룩에게 동료들이 멋지다고 칭찬해 주지만 브룩은 이렇게 생각해요.'],
                ['빈말이겠지. 별 뜻 없이 칭찬해 주는 게 직장 동료들의 버릇이잖아.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '의미를 둘 일은 아닌 것 같아요',
    },
    {
      title: '좋은 일은 받아들이기로 해요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 ',
                '좋은 일들을 회피하다 보면 인생에서 좋은 부분도 있다는 사실을 놓치게 된답니다.',
                ' 부정적인 일에만 몰두한다면 주변에서 일어나는 일을 제대로 파악할 수 없을 테고, 무엇보다도 희망차고 풍요로운 경험 덕분에 느끼는 즐거움을 누리기 힘들겠죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['케이티는 이렇게 생각할 자격이 있어요.'],
                ['좋았어! 이번에 내가 아주 잘했나 봐.'],
                ['브룩도 충분히 이렇게 생각할 수 있답니다.'],
                ['맞아. 이번에 자른 머리 스타일이 나한테 잘 어울리는 것 같아!'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '자존감이 올라가는 것 같아요!',
    },
    {
      title: '경험과 다른 결론',
      image: wb8_7,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리는 매번 경험한 일들을 까먹곤 하죠. 과거의 ',
                '경험에 비추어보면 그렇지 않음에도 매번 경험과는 다르게 생각',
                '할 때가 많아요. 주로 감정에 압도되었기 때문이죠. 제가 만났던 제이크의 이야기를 한번 살펴볼게요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                [
                  '제이크는 내일 중요한 시험이 있어요. 그래서 일찍 잠자리에 누웠죠. 오늘 최고의 컨디션을 유지해야 내일 시험을 잘 볼 수 있으니까요. 그런데 잠이 오지 않는 거예요. ',
                ],
                ['왜 이러지...?'],
                [
                  '뒤척이던 제이크는 침대에서 일어나 시계를 보았어요. 벌써 새벽 3시였죠. 제이크는 문득 이런 생각이 들었어요.',
                ],
                ['지금 잠들어도 다섯 시간밖에 못 자네. 망했다... 내일 하루는 망했어. 시험 어떡해...'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '시험을 못 보면 어쩌죠?',
    },
    {
      title: '경험에 비추어 생각해 보세요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리 모두 살면서 다섯 시간 정도밖에 잘 수 없었던 날이 있지 않았나요? 그 다음날은 항상 모든 게 엉망이었을까요? 생각보다 많은 일들을 잘 해냈던 적도 많았죠. 지금 제이크를 압도하고 있는 건 다섯 시간밖에 못 잔다는 ‘생각’ 자체이지, 다섯 시간밖에 못 자서 생기는 피로감이 아니에요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['제이크는 이렇게 생각해 볼 수도 있었겠죠.'],
                [
                  '잠이 안 오네. 괜찮아. 이런 날도 있는 거지. 지금 잠이 안 오는 건 내 몸이 그만큼 잠을 필요로 하지 않기 때문일 수도 있지. 오늘 조금 덜 잔다고 해서 내일 하루가 엉망이 되는 건 아니야. 그냥 누워있다가 졸릴 때 잠들자. 그리고 내일 똑같은 하루를 시작하면 되는 거야.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '불안함이 줄어드는 것 같아요',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '그럼 복습을 해 볼까요?',
      texts: [
        {
          text: [
            '오늘도 많은 것들을 배웠네요. 생각 함정, 생각보다 어렵지 않죠? 하지만 이렇게 읽기만 하면 머리에 잘 안 남는다는 걸 잘 알고 있어요. 그래서 제가 몇 가지 문제를 내볼 거예요. 너무 부담가지진 마요. 틀려도 괜찮아요. 많은 내용들을 배웠으니 다 맞추지 못했다고 좌절하지 말아요. 그건 성급한 일반화니까요. 자, 그럼 시작해 볼까요?',
          ],
        },
      ],
      link: '',
      buttonText: '한번 풀어볼게요!',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['흑백논리', '일반화', '좋은 일 회피하기']}
          answer="흑백논리"
          contents={[
            {
              component: (
                <div>
                  <p>
                    존이 수능 시험을 쳤는데 기대했던 것보다는 더 낮은 점수가 나왔어요. 하지만 그렇게 최악의 점수가 나온
                    것은 아니랍니다.
                  </p>
                  <MessageBlockWithTail
                    textList={[['점수가 최악이야. 이 점수로는 아무것도 못해.']]}
                    type={'black_dotted'}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요!"
          buttonText="이해됐어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '시험은 ‘잘 본 것’과 ‘못 본 것’ 두 가지의 경우만 있진 않답니다. 아쉽기는 하지만 그래도 그렇게 나쁘지 않은 점수를 받은 상황도 있을 수 있겠죠. 존은 기대했던 점수가 나오지 않자 ‘점수가 최악’이라며 흑백논리로 상황을 이해하고 있어요.',
                '\n',
                '그렇다 보니 ‘내 인생은 끝났다’라는 잘못된 결론에 도달하기도 했네요!',
              ]}
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['흑백논리', '일반화', '좋은 일 회피하기']}
          answer="일반화"
          contents={[
            {
              component: (
                <div>
                  <p>이 점수로는 아무것도 못한다고 생각한 존은 집으로 돌아와 일기장에 다음과 같은 글을 썼어요.</p>
                  <MessageBlockWithTail textList={[['내 인생은 끝났어']]} type={'black_dotted'} margin="mt-[30px]" />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="잘했어요! 정말 이해력이 좋네요!"
          buttonText="이해됐어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '시험 한 번 잘 못 봤다고 인생이 끝나는 건 아니에요. 앞으로도 수많은 기회와 도전의 날들이 있는 걸요. 어쩌면 시험 점수는 큰 영향이 없을지도 몰라요. 수능 시험 못 보고도 성공한 사람들의 이야기, 세상에 수두룩하잖아요? 존은 수능 시험을 못 봤다는 것 하나만으로만 스스로의 삶 전체를 평가하는 일반화 생각 함정에 빠지고 있어요!',
              ]}
            />
          }
        />
      ),
    },
    {
      image: wb8_9,
      title: '생각 함정, 어렵지 않죠?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '어때요, 생각 함정, 생각보다 간단하지 않나요? 하지만 감정을 기록하고 시간이 지난 뒤에 다시 살펴보면 우리는 수없이 많은 생각 함정에 빠져서 지낸다는 것을 알 수 있어요. 당시에는 정말 화가 나서 “저 자식은 나를 싫어하는 게 틀림없어”라고 기록했다고 하더라도, ',
                '시간이 흐른 뒤 다시 살펴보면 지나친 일반화, 혹은 흑백논리에 기반한 생각이었다는 것',
                '을 알아차릴 때도 있답니다.',
                '\n',

                '이번 수업에서는 생각 함정 중 흑백논리, 일반화, 좋은 일 회피하기, 경험과 다른 결론에 대해 알아보았어요. 다음 시간에는 또 다른 생각 함정들을 소개해드릴게요. 오늘은 내용이 제법 많아서 머리가 아플 거예요. 충분히 쉬고 와서 다음 수업을 진행하도록 하죠. 저도 잠시 음악을 한 곡 듣고 오겠어요. 베토벤의 음악을 들을 거랍니다. 오늘 같은 날에 잘 어울리겠어요. ',
                '\n',
                '자, 그러면 이따 만나도록 하죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요!',
    },
  ],
  [
    {
      title: '생각 함정 2',
      fullImage: wb9_1,
      height: 230,
      subtitle: '생각 함정 재밌지 않나요?',
      texts: [
        {
          text: [
            '생각 함정 내용이 많아서 힘들진 않았나요? 혹시나 내용이 너무 많다면 천천히 진행해도 좋아요. 저는 늘 여기 있으니까요. 너무 무리하진 않아도 돼요. 내가 이해하고 받아들일 수 있을 만큼씩 진도를 나가면 된답니다.',
            <br />,
            <br />,
            '앞서 이야기했듯 우리는 생각보다 비슷한 방식으로 잘못된 생각들을 하곤 한답니다. 생각 함정들을 충분히 공부하면 우리가, 혹은 우리 주변의 사람들이 얼마나 불합리한 생각들로 본인과 주변을 힘들게 하는지 파악하실 수 있을 거예요.',
            <br />,
            <br />,
            '자, 이제 다른 생각 함정들도 공부해 볼까요?',
          ],
        },
      ],
      link: '',
      buttonText: '준비됐어요!',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="그 전에 퀴즈를 한번 내볼까요?
          다음 상황엔 어떠한 생각 함정이 있을까요?"
          buttonList={['흑백논리', '일반화', '좋은 일 회피하기']}
          answer="좋은 일 회피하기"
          contents={[
            {
              component: (
                <div>
                  <p>
                    회사를 다니던 챙은 밤새워 발표를 준비했고, 발표 후 평소 무심하던 선배로부터 ‘수고했어. 발표 좋았어.
                    많이 배웠어.’라는 칭찬을 들었어요. 그리고 챙은 생각했죠.
                  </p>
                  <MessageBlockWithTail
                    textList={[['내가 너무 피곤해보여서 위로해 준다고 하는 말이겠구나.']]}
                    type="black_dotted"
                    margin="mt-[40px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요. 잘 하시는 걸요?"
          buttonText="좋아요 :)"
          answerInfo={
            <TextWithBold
              texts={[
                '선배는 정말 챙의 발표가 좋아서 칭찬을 했을 가능성이 높아요. 가뜩이나 원래는 무심하던 선배잖아요? 하지만 챙은 선배의 칭찬을 긍정적으로 받아들이지 않고 별 의미없는 일로 치부해버리고 말았어요.',
                '\n',
                '정말 금방 배우시네요. 조금 있으면 스스로의 마음을 객관적으로 분석할 수 있을 것 같아요. 자, 그럼 이제 다른 생각 함정들도 공부해 볼까요?',
              ]}
            />
          }
        />
      ),
    },
    {
      title: '좁은 시야',
      image: wb9_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '모든 상황에는 긍정적인 면과 더불어 부정적이거나 까다로운 면이 있기 마련이에요. 하지만 부정적인 면에만 집중하고 긍정적인 면은 무시하거나 신경 쓰지 않는 일이 종종 하곤 해요. 이처럼 ',
                '긍정적인 면을 보지 못하고 부정적인 면에만 집중',
                '하는 생각 함정을 ',
                '좁은 시야',
                '라고 한답니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                [
                  '케이티는 친구들과 근사한 저녁 식사를 하고 있어요. 모두 즐겁게 웃음꽃을 피우는 가운데 케이티도 편한 마음으로 자리를 즐기고 있죠. \n\n 식사가 마무리되어 갈 무렵, 친구 한 명이 케이티를 바라보더니 모든 사람들 앞에서 약속에 자주 늦는 이유가 무엇인지 대뜸 묻는 거예요. 케이티는 그러한 질문을 자신에 대한 공격으로 받아들이고 방어적으로 대응할 수밖에 없었죠.',
                ],
                ['쟤는 왜 저런 말을 해서 나를 망신주는 거지?'],
                [
                  '몇 주가 흐른 뒤 이날 저녁 식사를 되돌아봤을 때 케이티의 기억에 남는 건 마지막 대화뿐일 테고, 다른 친구들에게는 당시의 상황이 얼마나 당황스러웠는지만 토로할 거예요.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '최악의 식사자리였네요',
    },
    {
      title: '좁은 시야, 다른 면도 생각해 봐요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '좁은 시야로 상황을 바라보는 내 모습을 발견한다면, 불쾌했거나 기분 나빴던 사건을 떠올리고 ',
                '긍정적인 면과 부정적인 면을 모두 적어 보는 게 도움',
                '이 된답니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['케이티는 대신 이렇게 생각할 수 있겠죠.'],
                [
                  '친구들과 함께한 저녁 식사는 아주 즐거웠어. 다들 즐겁게 웃고 떠들었지. 물론 그중 한 명이 왜 자꾸 약속에 늦는지 물어봤을 때 공격받는 느낌이 들었고 방어적으로 대응하게 됐지만, 그 사건 말고는 꽤 재밌는 시간이었어.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '다시 보니 나쁘지만은 않았던 것 같아요',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '추측',
      image: wb9_4,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '사람들은 ',
                '충분한 정보가 없을 때 타인이 취하는 행동의 근거를 마음대로 추정',
                '하기도 한답니다. 이러한 생각 함정을 ',
                '추측',
                '이라고 해요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                [
                  '엘런은 상사로부터 이번주 남은 기간 내내 쉬어도 좋다는 이야기를 들었어요. 그리곤 다음과 같이 생각했답니다.',
                ],
                ['내가 한 일이 마음에 안 드나봐. 나 대신 다른 사람을 고용하려고 하는 거겠지?'],
                [
                  '육아를 하고 있는 에밀리가 길을 걷고 있는데 옆집 이웃이 인사 한마디도 없이 옆을 지나갔어요. 에밀리는 문득 이런 생각이 들었어요.',
                ],
                ['밤새 아기가 울어서 짜증 난다고 시위하는 거야. 울음소리 때문에 계속 깼나 봐.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '불안하고 초조할 것 같아요',
    },
    {
      title: '추측, 정말 그런 걸까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '어떤 사람이 나에게 상처를 주는 행동을 하고 있고 그 사람이 그러는 이유가 뭔지 확실히 알 것 같지만 딱히 직접적인 증거는 없나요? 그렇다면 나 때문에 불쾌하거나 언짢고 화가 났을 거라는 ',
                '추론 외에 그렇게 행동한 다른 이유가 있진 않을지',
                ' 곰곰이 생각해 보는 게 도움이 된답니다. 간단한 질문이더라도 굉장히 도움이 많이 돼요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['앨런은 분명 이렇게 생각해 볼 수도 있을 거예요.'],
                [
                  '지난주에 우리 팀이 너무 열심히 달려서 보상으로 조금 휴식을 주시나 봐. 아니면 그냥 회사 상황이 힘들어 인건비를 아끼려고 하는 걸지도 몰라. 나중에 이유가 뭔지 여쭤봐야지.',
                ],
                ['에밀리도 다르게 생각할 수 있어요.'],
                [
                  '혼자 생각에 잠겨 있다가 인사를 못 했나 봐. 아니면 진짜로 아기 울음소리 때문에 화가 난 건지도 모르지. 솔직히 나 같아도 옆집 소음 때문에 밤잠 설치면 짜증 나겠어. 오늘내일 사이에 호박빵이라도 가져다주면서 혹시 언짢은 일이 있는지 물어보자.',
                ],
              ]}
              my
            />
          ),
        },
        {
          text: '이처럼 어떤 상황에서 긍정적인 면과 부정적인 면을 다 고려하는 습관은 상황을 더 객관적이고 이성적으로 바라볼 수 있게 도와준답니다. 설령 정말로 부정적인 상황이었다고 하더라도 더 침착하게 대응할 수 있기도 하고요!',
        },
      ],
      link: '',
      buttonText: '내가 상황을 이끌어나가는 기분이에요!',
    },
    {
      title: '성급한 결론',
      image: wb9_6,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <p />,
                '적절한 근거가 없는 상태에서 상황을 해석',
                '해버리는 생각 함정을 ',
                '성급한 결론',
                '이라고 해요. 성급한 결론 생각 함정에 빠지면 보통은 부정적으로 결론을 짓는 경우가 많답니다. 왜냐면 우리는 좋은 일은 회피하는 습관이 있거든요. 지난 시간에 배웠던 좋은 일 회피하기를 떠올려보세요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                [
                  '릴리는 심한 두통 때문에 정신을 차릴 수가 없어요. 약을 먹고 10분이 지났는데 효과가 없자 이렇게 생각해요.',
                ],
                ['약발이 통 듣질 않네. 효과가 있을 리 없지. 약을 먹어도 항상 소용없다니까.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '짜증날 것 같아요',
    },
    {
      title: '성급한 결론, 다시 생각해 볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '앞으로 일어날 일에 대해 성급하게 결정을 내리는 스타일이라면, ',
                '시간을 들여 근거를 찾아보세요.',
                ' 결론을 뒷받침할 만한 사실이 있나요? 원래 45분 정도 지나야 효과를 보는 약일지도 몰라요.',
                '\n',
                '상황에 대한 결론을 내리기가 아직 이르지는 않나요? 사람들은 보통 혹시 모를 경우를 대비해 최악의 상황을 가정하는데 늘 성급하게 최악의 시나리오만 떠올리면 피해를 볼 수도 있어요. 꽤 큰 대가를 치르게 되죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['릴리도 다르게 생각할 수 있어요.'],
                [
                  '아직 10분밖에 안 지났잖아. 약발이 들 때까지 1시간은 더 기다려 보자. 그래도 계속 아프면 병원 가서 나한테 맞는 약인지, 복용량은 적당한지 물어봐야지.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '침착하게 생각할 수 있는 것 같아요!',
    },
    {
      title: '조금 헷갈리시나요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이쯤되면 흑백논리, 일반화, 성급한 결론 세 가지가 조금은 헷갈리실 거예요. 다시 한번 정리해 볼까요?',
                '\n',
                <p />,
                '흑백논리',
                '는 모 아니면 도, 양쪽 극단으로만 생각하는 방식이에요. ',
                '일반화',
                '는 어떤 사건이 발생했을 때 그 사건을 기반으로 전체를 판단해 버리는 것이랍니다. 마지막으로 ',
                '성급한 결론',
                '은 아직 판단할 만한 충분한 근거가 없음에도 그렇게 판단해 버리는 것이지요. 여기서 일반화는 이미 어떠한 상황이 발생해서 판단을 할 수 있는 경우이고, 성급한 결론은 아직 상황에 대한 판단이 불가능한 경우라고 생각하시면 조금 더 이해가 쉬울 거예요. 한편 ',
                '추측',
                '은 성급한 결론과는 달리 어떠한 근거가 없이 정말 추측만으로 상황을 판단하는 것이랍니다. 정보가 조금 있을 때 그것만으로 결론을 내버리는 성급한 결론과의 차이, 잘 이해가 되나요?',
                '\n',
                '예를 하나 들어볼게요. 시험을 친다고 생각해 보세요. 수학, 영어, 과학 세 가지 과목이 있었고, 막 첫 시험으로 수학 시험을 끝냈어요. 그중에서 나는 수학을 70점 받았어요. 우리 학교의 수학 점수 전체 평균은 약 80점 정도에요. 아직 나머지 두 과목이 남아있죠.',
                '\n',
                '이러한 상황에서',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail type="white_dotted" textList={[['이번 시험은 최악의 성적이야. 다 망쳐버렸어.']]} my />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={['라고 생각하면 ', '흑백논리', ' 함정에 빠진 거예요.', <br />, '만약 이번 수학 성적만으로']}
            />
          ),
        },
        {
          component: <MessageBlockWithTail type="white_dotted" textList={[['나는 수학에 재능이 없어.']]} my />,
        },

        {
          component: (
            <TextWithBold
              texts={[
                '라고 생각하면 ',
                '일반화',
                ' 함정에 빠진 거랍니다.',
                <br />,
                '만약 아직 첫 번째 시험밖에 안 쳤는데',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithTail type="white_dotted" textList={[['이번 시험은 완전 망했어.']]} my />,
        },
        {
          component: <TextWithBold texts={['라고 생각하면 ', '성급한 결론', ' 함정에 빠진 거예요.']} />,
        },
      ],
      link: '',
      buttonText: '어떻게 다른지 감이 와요!',
    },
    {
      title: '그럼 복습을 해 볼까요?',
      texts: [
        {
          text: [
            `몇 가지 예시를 보여드릴게요. 각각의 예시에서 어떠한 생각 함정이 존재하는지 맞춰보는 거예요. 많은 내용들을 배웠으니 한 번에 맞추지 못했다고 좌절하지 말아요. 문제 한두 가지로 나를 판단할 순 없답니다.`,
          ],
        },
      ],
      link: '',
      buttonText: '한번 풀어볼게요!',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['좁은 시야', '추측', '성급한 결론']}
          answer="좁은 시야"
          contents={[
            {
              component: (
                <div>
                  <p>
                    루시는 영화를 보러갔어요. 코믹 영화라 재밌는 부분도 있지만 군데군데 나오는 폭력적인 장면들이 조금
                    불편하기도 했죠. 그래도 제법 웃고 즐길 수 있었던 영화였어요. 영화를 다 보고 나와서 루시는 다음과
                    같이 생각했죠.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[['영화는 정말 별로였어. 시간만 낭비한 것 같아.']]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요!"
          buttonText="이해됐어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '영화는 분명 재밌는 부분도 있었고, 별로인 부분도 있었죠. 하지만 루시는 영화의 긍정적인 부분은 고려하지 않고 부정적인 면에만 집중해서 상황을 판단하고 있어요.',
              ]}
            />
          }
          shortGray
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['좁은 시야', '추측', '성급한 결론']}
          answer="성급한 결론"
          contents={[
            {
              component: (
                <div>
                  <p>
                    벨라는 남자친구와 데이트를 하기 위해 준비를 하고 있었어요. 하지만 갑자기 남자친구로부터 ‘오늘 급한
                    일이 생겨서 내일 만나야 할 것 같아’라고 연락이 왔죠. 벨라는 다음과 같은 생각이 들었어요.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[['남자친구가 나를 좋아하지 않는 것 같아. 아마 조만간 헤어지자고 말하겠지.']]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="잘했어요!"
          buttonText="이해됐어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '남자친구가 다음번에 만나자고 하자 벨라는 남자친구의 마음을 추측해서 결론을 내리고 있어요.',
                '\n',
                '만약 남자친구가 “요즘 관계가 힘들다”라고 한마디 말하자 이미 모든 것이 끝났다고 생각해버린다면 성급한 결론에 해당하겠죠. 차이를 잘 아시겠나요?',
                '\n',
                '추측은 ',
                '판단을 할 근거가 전혀 없음에도 그렇게 결론을 짓는 것',
                '이고, 성급한 결론은 ',
                '작은 정보만으로 결론을 지어버리는 것',
                '이랍니다!',
              ]}
            />
          }
          shortGray
        />
      ),
    },
    {
      category: '인사이드 이야기',
      title: '지금까지 내용을 복습해 볼까요?',
      texts: [
        {
          text: [`지금까지 아래와 같은 생각 함정들을 배웠어요. 잘 기억이 나나요?`],
        },
        {
          component: <AccordianContent />,
        },
        {
          component: <div className="w-full h-[30px]" />,
        },
        {
          text: [
            `특히 헷갈리는 흑백논리, 일반화, 추측, 성급한 결론을 더 명확하게 구별하고 싶다면 아래와 같은 차이에 집중해서 생각해 보세요.`,
            <br />,
            <br />,
          ],
        },
        {
          component: (
            <div className="mb-[28px]">
              <div className="relative flex">
                <span>•</span>
                <div className="ml-[4px]">
                  <span className="font-bold">흑백논리</span>
                  <span>: 중간은 없다는 식의 극단적인 생각</span>
                </div>
              </div>
              <div className="relative flex">
                <span>•</span>
                <div className="ml-[4px]">
                  <span className="font-bold">일반화</span>
                  <span>
                    : 어떤 사건이 발생했고 그에 대한 판단이 어느 정도가 가능할 때, 해당 사건만을 가지고 전체를 평가하는
                    생각.
                  </span>
                </div>
              </div>
              <div className="relative flex">
                <span>•</span>
                <div className="ml-[4px]">
                  <span className="font-bold">추측</span>
                  <span>: 그렇게 판단할 근거가 없음에도 불구하고 작은 정보만으로 결론을 지어버리는 생각.</span>
                </div>
              </div>
              <div className="relative flex">
                <span>•</span>
                <div className="ml-[4px]">
                  <span className="font-bold">성급한 결론</span>
                  <span>: 작은 정보만으로 결론을 지어버리는 생각.</span>
                </div>
              </div>
            </div>
          ),
        },
        {
          text: [
            '이제 조금 더 명확한가요? 다음 수업에서는 나머지 여섯 가지 생각 함정들에 대해 배워볼 거예요. 이후에는 생각 함정들을 이용해 내 감정을 돌아보는 방법들을 알려드릴 계획이랍니다 :)',
          ],
        },
      ],
      buttonText: '그럼 다음 시간에 또 만나요!',
    },
  ],
  [
    {
      id: 0,
      category: '인사이드 이야기',
      title: '생각 함정 3',
      fullImage: wb10_1,
      height: 215,
      subtitle: '이제 나머지 함정들을 공부해 볼 거예요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘은 어떤 하루를 보내고 계신가요? 저는 키우고 있던 고양이가 아파서 마음이 좋지 않았어요. 매일 같이 있던 친구인데 혹시나 저를 떠나가버릴까 불안했죠. 언제나 함께 있을 순 없겠지만요. 제가 너무 어두운 이야기를 한 것 같네요. 자, 그러면 시작해 볼까요?',
                '\n',
                '지난 시간에 배웠던 여섯 가지 생각 함정들을 잘 기억하고 계시나요? 오늘은 나머지 여섯 가지 생각 함정들을 배워볼 거예요. 이렇게 잘 알려진 생각 함정들만 충분히 익혀도 우리가 무의식적으로 행하고 있는 불합리한 생각들은 대부분 파악하고 개선시킬 수 있을 거랍니다.',
              ]}
            />
          ),
        },
      ],
      link: '',
      buttonText: '나머지도 금방 배울 수 있어요!',
    },

    {
      id: 1,
      image: wb10_2,
      category: '인사이드 이야기',
      title: '감정으로 판단하기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '생각은 그저 생각일 뿐, 진리는 아니에요. 앞서 살펴보았듯 생각은 수면 시간, 기분, 과거 경험 등 환경에 따라 바뀔 수 있으니까요. 마찬가지로 감정 또한 그저 감정일 뿐이며 꼭 진리라고 말할 수 없답니다. 하지만 많은 사람들이 ',
                '현재 느끼는 감정이 진리라고 믿고 현실적 근거 없이 감정에 따라 상황을 판단',
                '하곤 해요. 이러한 생각 함정을 ',
                '감정으로 판단하기',
                '라고 한답니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                [
                  '에릭은 죄책감과 부끄러움을 느낄 만한 행동을 하거나 관련 있는 사건을 겪은 적도 없는데 이런 감정을 떨쳐 낼 수가 없어요. 그리고 그러한 감정을 토대로 다음과 같이 생각하죠.',
                ],
                ['마음이 불편한 걸 보니 내가 무슨 잘못을 저지른 모양이야.'],
                ['에릭을 지켜보던 친구가 “왜 그렇게 생각하냐”라고 묻자 에릭은 답하죠.'],
                ['몰라. 그냥 느낌이 그래. 그런 것 같아.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '위축되고 자존감이 낮아질 것 같아요',
    },
    {
      title: '감정으로 판단하기, 다시 생각해 봐요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '때론 강한 감정이 들 때도 있어요. 이유를 못 찾을 때도 있죠. 하지만 극심한 죄책감이나 부끄러움, 두려움, 분노를 비롯해 힘든 감정을 발견한다면, ',
                '감정과 사실을 분리해 생각',
                '하세요. 부끄러운 마음이 든다고 해서 꼭 잘못한 게 있는 건 아니에요. 죄책감이 든다고 해서 꼭 잘못한 것도 아니고요. 상황에서 사실을 더욱 자세히 살펴보고 찾아내서 감정과 분리해야 해요. ',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['에릭은 이렇게 생각할 수 있겠죠.'],
                [
                  '죄책감이 들고 부끄럽지만, 자세히 들여다보면 난 이런 감정을 느낄 만한 일을 한 적이 없어. 누구에게도 상처 주고 싶지 않아서 말과 행동에 늘 신경 쓰는 걸.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '감정에 휘둘리지 않게 되는 것 같아요',
    },
    {
      title: '당위 부여',
      image: wb10_4,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '“~해야 한다”라고 말하는 사람들, 종종 본 적 있지 않나요? 이처럼 ',
                '나 자신, 다른 사람, 혹은 이 세상이 어떤 모습으로 어떻게 행동해야 하는지 명확히 이해하고 전부 다 내 믿음과 일치하기를 바라는',
                ' 생각 함정을 ',
                '당위 부여',
                '라고 해요. 그래야 한다고 당위를 부여하는 거죠. 이런 접근 방식은 제한적이고 결국 비현실적이죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                [
                  '마이클은 주변 사람을 돕고 사는 일이 특히나 중요하다고 배우며 자랐기 때문에 이렇게 사는 게 세상을 대하는 올바른 방식이라고 굳게 믿어요.',
                ],
                ['누구든 어려울 때 의지가 되어 줘야지.'],
                ['사라는 어릴 적 봤던 영화나 드라마, 이야기에 나오는 연인의 모습을 꿈꾸며 살아요.'],
                ['연인이라면 말하지 않아도 서로를 이해해야지.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '딱딱하게 생각하게 되네요',
    },
    {
      title: '당위 부여, 그러지 않을 수도 있어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 이렇게 형성된 기대는 융통성이 떨어져요. 매순간 스스로가 만족하기 힘들 수 있죠. 항상 틀에 맞춰서 살 수는 없는 노릇이니까요. 다시 말해 ',
                '당위에 기반한 기대는 비현실적이라 실망감과 분노, 짜증을 유발',
                '할지도 몰라요.',
              ]}
            />
          ),
        },
        {
          text: [<br />],
        },
        {
          component: (
            <TextWithBold
              texts={[
                '‘그래야 한다’는 기대로 세상을 바라보는 사람도 있지만 당연하게도 모두가, 모든 상황이 이런 기대를 충족시킬 순 없어요. ',
                '세상이 돌아가는 방식, 삶의 모습, 적절한 행동 등에 관한 믿음은 사람마다 아주 다를 수 있답니다.',
              ]}
            />
          ),
        },
        {
          text: [
            <br />,
            `만약 혼자 생각할 때조차 당위적 진술을 반복한다면 기대하고 바라는 점을 다시 한번 생각해 보고 좀 더 유연하게 사고하는 방법을 배워 다양한 접근법을 시도해 보세요.`,
          ],
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['마이클은 이렇게 생각할 수 있겠죠.'],
                ['친구들이 어려울 때 함께하면 좋겠지만, 모든 사람을 돕고 살 순 없어.'],
                ['사라도 달리 생각할 수 있죠.'],
                [
                  '연애할 때 말이 필요 없을 정도로 서로를 잘 알게 된다면 정말 좋겠지만, 마음속 생각을 늘 한눈에 알 순 없지. 가끔 오해가 발생하면 서로 대화로 풀면 돼.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '더 마음이 편한 것 같아요',
    },
    {
      title: '꼬리표 붙이기',
      image: wb10_6,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '가끔은 어떤 것에 대해 꼬리표를 붙여버리는 경우도 있답니다. 특히 꼬리표를 붙여서 상황이 어떤지 혹은 누군가의 역량이 어떠한지 등에 대해 단정적으로 생각해버리기도 하죠. 이와 같은 생각 함정을 ',
                '꼬리표 붙이기',
                '라고 해요.',
              ]}
            />
          ),
        },
        {
          text: [<br />],
        },
        {
          component: (
            <TextWithBold
              texts={[
                '꼬리표 붙이기는 ',
                '나 자신 혹은 다른 사람에게 실제로는 존재하지 않는 특성을 영구적으로 부여',
                '하는 생각 함정이에요. 사람에게는 여러 측면이 있음에도 말이죠.',
              ]}
            />
          ),
        },

        {
          component: (
            <MessageBlock
              type={'black'}
              textList={[
                ['론은 스스로를 ‘찌질이’라며 비난하고 자책해요.'],
                ['매리는 종종 회의에 늦는 톰을 믿을 수 없는 사람이라고 생각해요.'],
              ]}
              my
            />
          ),
        },
        {
          text: [
            '이처럼 꼬리표를 붙이게 되면 더이상 그 사람의 가능성은 생각하지 않게 되죠. 누구든 바뀔 수 있고, 또 그러할 가능성이 있지만 꼬리표를 붙이게 되는 순간 그러한 가능성은 무시돼요.',
            <br />,
            <br />,
            '혹시 앞서 배웠던 일반화와 헷갈리나요? 크게 구분하지 않아도 괜찮아요. 꼬리표를 붙이면 ‘사람에 대한 일반화’로 이어지기 마련이니까요. 구분하고 싶다면 일반화는 상황에 대한 것, 꼬리표 붙이기는 사람에 대한 것이라고 생각하면 좋을 것 같아요.',
          ],
        },
      ],
      link: '',
      buttonText: '꼬리표를 붙이면 늘 그렇게 보일 것 같아요',
    },

    {
      title: '꼬리표, 이제 떼기로 해요',
      texts: [
        {
          text: [
            `꼬리표는 사람이 바뀔 수 있다는 사실을 제쳐 두고서 별개의 사건에 대해 나 자신 혹은 다른 사람에게 영구적이고 계속되는 특성을 부여해요. 하지만 사람은 변할 수 있답니다. 상황도 변할 수 있죠. 그에 따라 우리 생각도 변할 수 있고, 그 사람과 우리의 관계도 변할 수도 있고요.`,
          ],
        },

        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['론은 이렇게 생각할 수 있겠죠.'],
                ['이번에는 잘하지 못했지만, 그동안 내가 잘해 냈던 적도 많았는 걸.'],
                ['매리도 이렇게 생각할 수 있어요.'],
                ['톰이 요즘 연속해서 회의에 참석하질 않네. 최근 들어 조금 미덥지 않은 걸.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '조금 더 유연하게 생각할 수 있네요',
    },
    {
      title: '그럼 복습을 해 볼까요?',
      texts: [
        {
          text: [
            `몇 가지 예시를 보여드릴게요. 각각의 예시에서 어떠한 생각 함정이 존재하는지 맞춰보는 거예요. 많은 내용들을 배웠으니 한 번에 맞추지 못했다고 스스로를 멍청이라고 생각하면 안 돼요. 그렇게 생각하면 꼬리표 붙이기 생각 함정에 빠지게 된답니다.`,
          ],
        },
      ],
      buttonText: '좋아요!',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['감정으로 판단하기', '당위 부여', '꼬리표 붙이기']}
          answer="감정으로 판단하기"
          contents={[
            {
              component: (
                <div>
                  <p>
                    멜라니아는 아이들에 대한 죄책감이 들어요. 뭔가 더 잘 못 돌봐주고 있는 것 같죠. 이런 감정을 기반으로
                    은지는 다음과 같이 생각해요.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[['나는 정말 최악의 엄마야.']]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요!"
          buttonText="감정과 사실을 구분해야겠어요"
          answerInfo={
            <TextWithBold
              texts={[
                '멜라니아는 단지 자신의 기분에 의지해서 상황을 판단하고 그걸 사실이라고 믿고 있죠. 이처럼 감정에 의지해서 세상을 바라보면 매우 주관적으로 상황을 판단하게 된답니다!',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['감정으로 판단하기', '당위 부여', '꼬리표 붙이기']}
          answer="당위 부여"
          contents={[
            {
              component: (
                <div>
                  <p>어렸을 때부터 형 밑에서 커온 브루스는 이성을 대할 때 매번 다음과 같이 생각해요.</p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[['남자는 여자를 지켜줘야 해. 여자친구 앞에서 약한 모습을 보여서도 안 되지.']]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="잘했어요!"
          buttonText="내 모습을 그대로 사랑할 수 있을 것 같아요"
          answerInfo={
            <TextWithBold
              texts={[
                '브루스는 ‘그래야 한다’라고 생각하는 전형적인 당위 부여를 하고 있어요. 이와 같은 비현실적인 생각은 짜증과 분노, 좌절감을 유발할지도 모른답니다. 세상에 약한 모습이 없는 사람이 어디있겠어요. 특정 성별의 사람이라고 그러한 모습을 감춰야 하는 건 아니랍니다.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      image: wb10_8,
      title: '생각 함정, 어떠셨어요?',
      texts: [
        {
          text: [
            `이번 시간에는 감정으로 판단하기, 당위 부여, 꼬리표 붙이기와 같은 생각 함정들을 배워보았어요. 이제 거의 다 왔답니다. 다음 시간에는 마지막 두 가지 생각 함정들에 대해 배워볼 거예요.`,
            <br />,
            <br />,
            `생각 함정들을 충분히 공부하고, 그러한 틀을 통해 내 감정과 생각들을 바라보면 어떨까요? 나의 머릿속에서는 어떠한 자동적 사고들이 작동하고 있을까요? 자신의 마음을 알아가는 과정은 참 흥미로운 일이에요. 나를 변화시킬 수도 있기도 하고요.`,
            <br />,
            <br />,
            `그러면 조금만 쉬고 나서 만나도록 할까요?`,
          ],
        },
      ],
      buttonText: '좋아요!',
    },
  ],
  [
    {
      title: '생각 함정 4',
      fullImage: wb11_1,
      height: 215,
      subtitle: '이제 마지막 두 가지 생각 함정이에요',
      texts: [
        {
          text: [
            '벌써 여기까지 왔네요. 시간이 참 빠른 것 같아요. 감정 노트는 잘 이용하고 있나요? 저희가 진행하고 있는 코스는 감정 노트와 회고 노트를 병행해야 가장 효과가 좋답니다. 그러니 꾸준히 기록하고 회고해보도록 해요. 분명히 많은 도움이 될 거예요.',
            <br />,
            <br />,
            '오늘은 마지막 두 가지 생각 함정들을 배워볼 거예요. 그리고 전체 생각 함정들을 다시 한번 요약해드릴게요. 마지막엔 복습 차원에서 몇 가지 퀴즈를 드릴 거예요. 이번 시간을 끝으로 생각 함정들을 완전히 습득해보도록 해요.',
          ],
        },
      ],
      buttonText: '나머지도 금방 배울 수 있어요!',
    },
    {
      title: '과도한 책임감',
      image: wb11_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '인생을 살다 보면 여러 이유로 실망감이나 실패, 좌절을 겪기 마련이죠. 이러한 어려움에 부딪쳤을 때 ',
                '과도한 책임감',
                '을 느끼는 모습은 흔하게 발생하는 생각 함정이랍니다. 과도한 책임감은 종종 ',
                '나와 관련 없는 일도 나와 관련된 일로 생각',
                '하면서 ',
                '‘내 탓’을 하는 양상',
                '으로 나타나기도 한답니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                ['베일리의 아들이 중요한 시험을 망치고 말았어요.'],
                ['내가 종일 나가서 일하지 않고 집에 있었으면 아들 성적이 잘 나왔을 텐데...'],
                ['연인으로부터 이별 통보를 받은 찰스이는 다음과 같이 생각해요.'],
                ['내가 조금 더 자주 만나러 왔으면 여자친구가 행복해했을 테고 우리가 헤어지는 일도 없었을 거야.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '나 때문이라 마음이 힘들 것 같아요',
    },
    {
      title: '과도한 책임감, 느끼지 않아도 돼요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '타인이 취하는 행동과 태도의 원인을 내 탓으로 돌린다면, 과도한 책임감을 느끼는 생각 함정에 빠진 거예요. 내 마음은 내가 원하는 대로 할 수 있지만, 타인이 선택한 행동은 그들이 스스로 책임을 져야겠죠. 다른 사람의 마음까지 통제할 순 없어요. ',
                '내 의지와 관계없이 실망스러운 상황은 종종 발생하기 마련',
                '이랍니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['베일리는 이렇게 생각할 수 있겠죠.'],
                ['아들이 시험 준비는 스스로 할 수 있을 정도로 많이 컸잖아. 실패한 경험도 소중한 인생 교훈이 될 거야.'],
                ['찰스도 다르게 생각할 수 있죠'],
                [
                  '연애하면서 최선을 다했어. 여자 친구가 행복하지 않았다니 안타깝지만, 어쨌든 여자 친구의 감정이 내 탓은 아니야.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '내 의지와 무관한 일들도 있기 마련이네요',
    },
    {
      title: '최악의 시나리오 떠올리기',
      image: wb11_4,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '최악의 시나리오 떠올리기는 ',
                '그리 중요하지 않은 사건에서 가장 나쁜 상황을 상상',
                '하는 거예요. 이런 방식으로 생각하면 별거 아닌 일이 대형 참사의 조짐이 될 수 있죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['black', 'white_dotted']}
              textList={[
                ['안나는 회사 재무 보고서를 읽다가 손실이 발생한 걸 확인해요.'],
                ['수입이 이렇게 줄어들면 내가 제일 먼저 해고될 텐데.'],
                ['수능 시험을 치던 카이는 모르는 문제를 만나자 이런 생각이 들었어요.'],
                ['이 문제 모르겠는데? 다른 문제들도 이렇게 어려우면 어떻게 하지? 올해 대학 못 갈 것 같아.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '불안하고 초조해요',
    },
    {
      title: '최악은 어디까지나 최악의 이야기!',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '주어진 상황이 실망을 안겨 주거나 실패할 가능성이 있다고 해서 ',
                '꼭 모든 일이 최악으로 잘못되라는 법은 없어요.',
                ' 사소한 걱정거리를 듣고 최악의 상황을 상상하게 된다면, 생각하는 방식을 바꿔서 ',
                '머릿속에 떠오른 암울한 사건이 실제로 발생할 가능성이 얼마나 되는지',
                ' 따져 보면 도움이 된답니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['안나는 이렇게 생각할 수 있죠.'],
                [
                  '회사 수입이 줄었네. 그래도 난 항상 열심히 일했고, 평가를 조금 안 좋게 받을 수도 있겠지만 해고 얘기는 나오지 않았으니까 괜히 걱정하지 말자.',
                ],
                ['카이도 다르게 생각할 수 있죠'],
                [
                  '이 문제 제법 어렵네. 다른 것들 먼저 풀어볼까? 다른 문제는 괜찮을지도 몰라. 그리고 모두가 어려워하는 걸 수도 있잖아. 아직 남아있는 문제들도 많은 걸. 한번 해보자.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '상황을 긍정적으로 볼 수 있네요',
    },
    {
      title: '생각 함정 배우느라 고생 많았어요!',
      texts: [
        {
          component: <TextWithBold texts={['지금까지 총 11개의 생각 함정들을 배웠어요. 잘 기억이 나나요?']} />,
        },
        {
          component: <AccordianContent />,
        },
      ],
      link: '',
      buttonText: '정말 많은 걸 배웠네요!',
    },
    {
      title: '이제 퀴즈를 풀어볼까요?',
      image: wb11_6,
      texts: [
        {
          text: [
            `몇 가지 사례들을 소개해드릴 테니 사례에서 나타나는 생각 함정들을 고르면 돼요. 틀려도 괜찮답니다. 정말 많은 생각 함정들을 배웠으니 복습하는 차원에서 가볍게 문제들을 풀어보세요.`,
          ],
        },
      ],
      link: '',
      buttonText: '준비됐어요!',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['좁은 시야', '과도한 책임감', '성급한 결론']}
          answer="좁은 시야"
          contents={[
            {
              component: (
                <div>
                  <p>
                    엠버는 회사에서 열심히 보고서를 만들어 제출했어요. 보고서를 본 상사는 다음과 같이 말했죠. “보고서가
                    잘 만들어졌네요. 요약도 잘 되어있고요. 방향성만 조금 더 수정되면 참 좋을 것 같아.” 이에 엠버는
                    다음과 같이 생각했어요.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[['그냥 내 보고서가 별로란 거구나.']]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요 :)"
          buttonText="균형 잡힌 시선이 필요할 것 같아요"
          answerInfo={
            <TextWithBold
              texts={[
                '엠버의 상사는 분명 좋은 이야기도 많이 했어요. 그리고 수정할 부분들을 더 말씀주셨죠. 하지만 엠버는 좋은 부분은 신경쓰지 않고 오롯이 부정적인 면에만 집중해서 상황을 해석하고 있어요. ',
                '\n',
                '긍정적인 면을 보지 못하고 부정적인 면에만 집중하는 것, ',
                '좁은 시야',
                ' 생각 함정이죠!',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['최악의 시나리오 떠올리기', '일반화', '추측']}
          answer="일반화"
          contents={[
            {
              component: (
                <div>
                  <p>
                    개발자인 해리는 이번주에 중요한 프로젝트를 하나 잘 마무리했어요. 그러던 중 주말에 해리의 휴대폰으로
                    전화가 왔어요. <br /> “제품에 버그가 있어서 서비스가 중단됐어!” <br />
                    수습을 하기 위해 부랴부랴 회사로 뛰어가며 해리는 생각했죠.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[['나는 정말 꼼꼼하지 못한 개발자인 게 분명해.']]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="좋았어요!"
          buttonText="전체를 볼 줄 알아야겠어요"
          answerInfo={
            <TextWithBold
              texts={[
                '해리가 실수를 한 건 분명해요. 하지만 세상에 실수를 하지 않는 사람은 없는 걸요. 그렇다고 정식이가 매번 실수를 하는 사람인 것도 아니에요. 중요한 프로젝트였지만 이번에 실수가 발생했을 뿐이에요. 하지만 해리는 이번 사건만을 가지고 자신이 꼼꼼하지 못한 개발자라고 판단해버렸죠.',
                '\n',
                '한 가지 사건을 가지고 자신 혹은 상황 전체를 전부 평가해버리는 것, ',
                '일반화',
                ' 생각 함정이죠!',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['감정으로 판단하기', '좁은 시야', '꼬리표 붙이기']}
          answer="감정으로 판단하기"
          contents={[
            {
              component: (
                <div>
                  <p>
                    회사를 설립한 주디는 어떻게든 사업을 성공시키고자 애를 쓰고 있어요. 불확실한 것들이 많으니 스트레스
                    쌓이고 짜증나는 경험도 많죠. 아직 초기 회사이니 어쩔 수 없는 측면도 있어요. 하지만 주디는 매번
                    팀원들과 대화할 때 이상하게 신경질적인 기분이 들어요.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[['저 사람은 왜 맨날 내 말에 반대할까? 팀원들이랑 대화하기 짜증나.']]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="좋았어요!"
          buttonText="감정과 사실을 구분해야겠어요"
          answerInfo={
            <TextWithBold
              texts={[
                '주디는 스트레스를 많이 받는 상황에 놓여있어요. 그렇더 보니 답답하고 짜증나는 날들이 많죠. 하지만 팀원들이 주디에게 잘못한 건 없어요. 주디는 팀원들에 대한 평가에 자신의 감정을 투영하고 있죠.',
                '\n',
                '현재 느끼는 감정이 진리라고 믿고 현실적 근거 없이 감정에 따라 상황을 판단하는 것, ',
                '감정으로 판단하기',
                ' 생각 함정이죠!',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={4}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['꼬리표 붙이기', '흑백논리', '당위부여']}
          answer="꼬리표 붙이기"
          contents={[
            {
              component: (
                <div>
                  <p>
                    토마스는 중요한 발표를 앞두고 있어요. 하지만 토마스는 이전 발표에서 실수를 한 적이 있어요. 자주
                    그러는 건 아니지만 그래도 지난번 실수 이후로 계속 신경이 쓰이죠. 이번 발표를 준비하면서 토마스는
                    다음과 같이 생각했어요.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[
                      [
                        '나는 ‘소심이’라서 발표는 늘 긴장돼. 많은 사람들 앞에서 유창하게 발표를 하는 건 나같은 소심이가 할 일이 아니야.',
                      ],
                    ]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요!"
          buttonText="특정 단어로 규정짓는 걸 경계해야겠어요"
          answerInfo={
            <TextWithBold
              texts={[
                '토마스가 지난번에 실수를 한 건 맞지만 그렇다고 늘 그랬던 건 아니에요. 실수를 한 번 했다고 해서 ‘소심이’인 것도 아니죠. 누구나 실수를 할 수 있어요. 물론 부끄럽고 숨기고 싶은 경험이겠죠. 하지만 누구나 실수를 할 수 있는 걸요. 토마스는 지난 실수를 바탕으로 스스로에게 ‘소심이’라는 꼬리표를 붙이고 있어요.',
                '\n',
                '나 자신 혹은 다른 사람에게 실제로는 존재하지 않는 특성을 영구적으로 부여하는 것, ',
                '꼬리표 붙이기',
                '랍니다.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={5}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['과도한 책임감', '성급한 결론', '당위 부여']}
          answer="성급한 결론"
          contents={[
            {
              component: (
                <div>
                  <p>
                    줄리아는 스타트업을 운영하고 있어요. 그리고 이번에 투자자로부터 투자금을 유치하기 위해 발표를
                    진행하고 있죠. 발표를 시작하고 얼마 지나지 않아 줄리아의 발표를 끊고 투자자가 질문을 던졌어요.
                    <br />
                    “그런데 이 부분은 조금 약점이 있지 않아요?” <br /> 줄리아는 당황한 마음에 횡설수설하며 답했죠.
                    그리곤 생각했어요.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[['이번 투자는 망했다. 끝났어.']]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요!"
          buttonText="충분한 근거 없이 결론을 내리면 안 되겠어요"
          answerInfo={
            <TextWithBold
              texts={[
                '투자자는 궁금한 질문을 했을 뿐이에요. 아직 줄리아가 진행하는 전체에 대한 판단을 하지 않았죠. 투자자의 다음 질문에 잘 답변을 한다면 오히려 투자자가 더 좋은 시각을 가질지도 몰라요. 하지만 줄리아는 투자자의 첫 질문을 듣자마자 전체 발표에 대한 결론을 지어버렸죠.',
                '\n',
                '적절한 근거가 없는 상태에서 상황을 해석해버리는 것, ',
                '성급한 결론',
                ' 생각 함정이랍니다',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={6}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['최악의 시나리오 떠올리기', '당위 부여', '좁은 시야']}
          answer="최악의 시나리오 떠올리기"
          contents={[
            {
              component: (
                <div>
                  <p>
                    에이미는 비행기를 타고 일본으로 가고 있어요. 그러던 중 비행기가 갑자기 심하게 흔들렸죠. ‘기류가
                    불안정하기 때문에 자리에서 일어나지 말고 안전벨트를 하라'는 방송이 나왔죠. 불안한 에이미는 다음과
                    같이 생각했어요.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[
                      [
                        '비행기가 심하게 흔들리네. 이랬던 적은 없었는데. 이러다가 비행기가 추락하는 건 아닐까? 그렇게 죽게 되면 어쩌지?',
                      ],
                    ]}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요 👍"
          buttonText="객관적인 시야가 필요할 것 같아요!"
          answerInfo={
            <TextWithBold
              texts={[
                '비행기가 흔들린다고 해서 꼭 추락하는 건 아니죠. 실제 비행기가 추락할 확률은 정말 낮답니다. 하지만 에이미는 현재 상황을 가지고 최악의 시나리오까지 연결하며 과도하게 걱정하고 있어요. 이렇게 되면 불안감이 엄습하고 자기 통제를 상실할 수밖에 없답니다.',
                '\n',
                '그리 중요하지 않은 사건에서 가장 나쁜 상황을 상상하는 것, ',
                '최악의 시나리오 떠올리기',
                ' 생각 함정이지요!',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={7}
          question="어떠한 생각 함정이 있을까요?"
          buttonList={['당위 부여', '흑백 논리', '좋은 일 회피하기']}
          answer="당위 부여"
          contents={[
            {
              component: (
                <div>
                  <p>
                    테일러는 회사에서 팀의 리더예요. 테일러 밑에는 제법 많은 팀원들이 있죠. 테일러는 리더가 된 후부터
                    다음과 같은 생각을 가지고 있어요.
                  </p>
                  <MessageBlockWithTail
                    type="black_dotted"
                    textList={[['리더는 항상 모범을 보여야 해.']]}
                    margin="my-[30px]"
                  />
                  <p>
                    그래서 테일러는 할 일이 없는 날에도 절대 팀원보다 일찍 퇴근하지 않아요. 그렇다 보니 과도한 피로가
                    쌓여서 건강이 안 좋아지기도 해요.
                  </p>
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="정답이에요!"
          buttonText="특정 단어로 규정짓는 걸 경계해야겠어요"
          answerInfo={
            <TextWithBold
              texts={[
                "테일러는 무조건 리더가 모범을 보여야 한다는 생각을 가지고 있어요. 하지만 리더가 모든 면에서 모범이 될 수는 없답니다. 퇴근을 늦게 한다는 게 꼭 모범을 뜻하는 것도 아닌 걸요. 하지만 테일러는 리더라면 ‘그래야만 해'라고 생각하며 본인에게 과도한 당위를 부여하고 있어요.",
                '\n',
                '나 자신, 다른 사람, 혹은 이 세상이 어떤 모습으로 어떻게 행동해야 하는지 명확히 이해하고 전부 다 내 믿음과 일치하기를 바라는 생각 함정, ',
                '당위 부여',
                '랍니다.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      title: '생각 함정, 어떠셨어요?',
      image: wb11_7,
      texts: [
        {
          text: [
            `지금까지 총 12개의 생각 함정들을 배워보았어요. 제법 많아 아직은 헷갈리나요? 하지만 12개의 생각 함정만 잘 익혀도 우리가 흔히 행하는 불합리한 생각들을 상당 부분 바로 잡을 수 있답니다.`,
            <br />,
            <br />,
            `우선은 감정기록을 매일 진행하면서 생각 함정에 해당하는 오류들을 범하고 있지 않은지 살펴보세요. 그리고 회고를 진행해 보시고요. 찬찬히 살펴보면 그간 했던 생각들 중에 상당히 많은 오류가 있었다는 것을 알 수 있을 거예요.`,
            <br />,
            <br />,
            `그럼 오늘은 여기까지 하기로 해요. 고생 많았어요. 다음 시간부터는 회고에 대해 조금 더 알려드릴 거랍니다. 다음에 봐요. 고생 많았어요.`,
          ],
        },
      ],
      buttonText: '다음에 만나요!',
    },
  ],
  [
    {
      title: 'D로 생각 점검하기',
      fullImage: wb14_1,
      height: 215,
      texts: [
        {
          text: [
            `오늘은 지난 시간에 공부한 생각 함정들을 이용하여 내 마음을 살펴보는 방법에 대해 이야기를 나눌 거예요.`,
            <br />,
            <br />,
            `그 전에, 감정기록 잘 진행하고 계신가요? 우리가 어떠한 이론과 원리에 입각하여 감정기록을 진행하고 있는지 다시 한번 복습해볼까요.`,
          ],
        },
      ],
      link: '',
      buttonText: 'ABCDE 모델이었던가요?',
    },

    {
      id: 1,
      category: '인사이드 이야기',
      title: 'ABCDE 모형 중 ABC에 집중했죠',
      texts: [
        {
          text: [
            `우리는 ABC를 이용해서 감정기록을 진행하고 있었어요. 우리가 감정기록을 하는 방식을 하나씩 되짚어볼게요.`,
            <br />,
            <br />,
            `우선 ABC 중 C, 그중에서도 제일 포착하기 쉬운 감정에 먼저 집중하기로 했어요.`,
          ],
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['1. 어떤 감정을, 얼마나 느꼈나요?'],
                ['Consequences (결과): 믿음에 대한 감정적, 신체적, 혹은 행동적 반응'],
              ]}
              my
            />
          ),
        },
        {
          text: '다음으로 그런 감정을 느꼈던 상황, 즉 A에 대해 써보기로 했답니다.',
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['2. 무슨 일이 있었나요?'],
                ['Activating events (사건): 감정이나 신체적인 반응을 유발하는 상황'],
              ]}
              my
            />
          ),
        },
        {
          text: '마지막으로 사건과 연관된 생각, 즉 B를 기록하기로 했죠.',
        },
        {
          component: (
            <MessageBlockAndTail
              types={['light_blue', 'white_dotted']}
              textList={[
                ['3. 어떤 생각을 했나요?'],
                ['Beliefs (믿음): 사건과 연관된 생각, 즉 자동적인 사고 혹은 의식적인 상황 판단'],
              ]}
              my
            />
          ),
        },
        {
          text: '요악하면 우리는 지금까지 결과(감정)-사건-믿음 순서로 감정을 기록하고 있었어요. CAB 감정기록이라고도 볼 수 있겠네요!',
        },
      ],
      buttonText: 'CAB 감정기록, 외우기 쉽네요!',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '다시 한번, ABCDE 모형',
      texts: [
        {
          text: '다시 한번 설명하면, A-B-C-D-E 모형은 생각이 감정과 행동에 어떻게 영향을 미치는지 이해할 때 유용한 도구랍니다. 이 모형은 해외의 뛰어난 학자들에 의해 만들어져서 이름이 A-B-C-D-E 모형이랍니다. 각각이 의미하는 바는 다음과 같아요.',
        },
        {
          component: <ABCModel />,
        },
        {
          text: 'A-B-C-D-E 모형은 상황을 정확하게 설명하는 데 도움이 된답니다. 또한 생각과 감정의 근본적인 원인이 무엇이고 행동과 어떻게 연관되는지도 파악할 수 있어요.',
        },
      ],
      buttonText: '좋아요. 이제 DE에 대해 알려주세요!',
    },
    {
      title: 'Dispute beliefs - 회고',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '회고는 앞에서 살펴보았던 믿음에 대한 의문을 제기하면서 우리들의 생각을 다시 한번 되짚어보는 과정이에요. 특히 ',
                '자동적 사고, 그중에서도 생각 함정에 해당하는 것들이 있진 않은지 검토',
                '하는 과정이랍니다.',
              ]}
            />
          ),
        },
        {
          text: [<br />, '이전에 작성한 감정기록들을 살펴보며 다음과 같이 생각해 보는 거예요.'],
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['해당 사건이 발생했을 때 그렇게 생각하는 것이 나에게 유익할까?'],
                ['사건에 대한 믿음이 오히려 상황을 이해하는 데에 방해가 되진 않을까?'],
                ['믿음은 현실적일까? 혹은 근거가 있는 생각일까?'],
                ['현재 내 감정이 믿음에 영향을 미치고 있진 않을까? 즉 내 기분 때문에 그렇게 생각하는 건 아닐까?'],
              ]}
              type="white_dotted"
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: '조금 더 체계적인 방법인 없을까요?',
    },
    {
      title: '회고는 다음과 같이 해 봐요',
      texts: [
        {
          text: [
            '혼자서 생각을 되짚어보기에는 아직 많이 낯선가요? 그렇다면 조금 더 간단하게 두 단계로만 검토해보기로 해요.',
          ],
        },
        {
          component: (
            <MessageBlock
              type="light_blue"
              textList={[
                ['1. 감정기록에 적어둔 생각 중에 생각 함정에 해당하는 믿음은 없을까?'],
                ['2. 나에게 더 도움이 되는 생각은 없을까?'],
              ]}
              my
            />
          ),
        },
        {
          text: [
            '생각 함정은 앞에서 충분히 공부했으니 어떠한 함정들을 찾아야 하는지 잘 알고 계실 거예요. 아직 익숙하지 않다면 앞에서 공부했던 내용들을 다시 살펴보고 와도 좋아요.',
            <br />,
            <br />,
            '그렇다면 나에게 도움이 되는 생각은 어떻게 찾아야 하는 걸까요?',
          ],
        },
      ],
      link: '',
      buttonText: '특별한 방법이 있는 걸까요?',
    },
    {
      title: '도움이 되는 생각 찾기',
      image: wb14_2,
      texts: [
        {
          text: [
            '감정기록에 적어둔 생각이 도움이 되지 않는다는 사실을 깨달으면서 생각 함정을 발견했다면, 기존 생각에 빈틈이 있다는 사실을 발견할 거예요. 타당하면서 더욱 도움이 되는 생각으로 이런 빈틈을 메우고 싶은 마음이 들겠죠.',
            <br />,
            <br />,
            '도움이 되는 생각의 예시를 몇 개 들어 볼게요. ',
          ],
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['누구에게나 일어날 수 있는 일이야'],
                ['상황은 나빠질 수도 있지만, 마찬가지로 더 나아질 수도 있어'],
                ['누구든지 도움이 필요한 때가 있는 법이야'],
                ['누구든 실수를 할 수 있어. 그리고 실수로부터 배우는 것들도 있는 걸'],
                ['사람이 완벽할 수는 없어'],
              ]}
              type="light_blue"
              my
            />
          ),
        },
        {
          text: [
            '도움이 되는 생각은 같은 상황에서도 더 긍정적 결과를 만들어낼 수 있답니다. 이런 생각은 더 객관적이고 합리적이기 때문에 생각 함정에 빠지지 않아요. 그래서 상황을 더 안정적으로 받아들이게 도와주죠.',
            <br />,
            <br />,
          ],
        },
        {
          component: (
            <TextWithBold
              texts={[
                '더욱 도움이 되는 믿음을 끌어내려면 ',
                '나와 똑같은 사건을 겪는 친구에게 내가 어떻게 충고하고 싶은지',
                ' 생각해 보세요.',
              ]}
            />
          ),
        },
      ],
      link: '',
      buttonText: '당연하지만 놓쳤던 것들이네요!',
    },

    {
      id: 1,
      category: '인사이드 이야기',
      title: '예를 들어볼까요?',
      texts: [
        {
          text: [
            `재무설계사인 영호가 작성했던 감정기록을 한번 같이 살펴봐요. 우선 영호는 인사이드에서 다음과 같은 감정기록을 남겼어요.`,
          ],
        },
        {
          component: (
            <MessageBlockAndTail
              types={['gray', 'white_dotted']}
              textList={[
                ['C. 어떤 감정을 느꼈나요?'],
                ['불안했어요. 5점 만점 중에서는 4점으로 불안했어요.'],
                ['A. 어떤 상황이었나요?'],
                ['새로운 고객을 맡아서 일을 했는데, 첫 번째 의뢰 이후 더이상 일을 주지 않아요.'],
                ['B. 그때 어떻게 생각했나요?'],
                [
                  '내가 일을 잘하지 못했나 봐요. 정말 형편없죠. 첫 번째 의뢰 이후에 일이 끊어지다니. 이대로 일이 하나도 안 들어오면 공과금도 못 낼 텐데. 여자친구와 가족들은 날 무능한 사람이라고 생각하곘죠? 혼자 길거리에 나앉게 생겼네요.',
                ],
              ]}
              my
            />
          ),
        },
        {
          text: '불안한 마음에 영호는 감정기록을 남겼어요. 그리고 시간이 조금 흐른 뒤 인사이드에서 다시 한번 당시의 생각들을 되짚어봤죠.',
        },
        {
          component: (
            <MessageBlockAndTail
              types={['gray', 'white_dotted']}
              textList={[
                ['D. 생각 함정이 있진 않았나요?'],
                [
                  '안 좋은 일 한 번만으로도 현재 상황을',
                  '일반화',
                  '해서 생각한 것 같아요. 그리고 일을 한 번 못 따냈다고 길거리에 나앉겠다고 생각한 건 너무 ',
                  '최악의 시나리오',
                  '만 떠올린 것 같기도 해요. 다른 사정이 있을지도 모르는데 그저 내 실력이 안 좋아서 일을 안 맡긴다고 ',
                  '추측',
                  '한 것 같기도 해요. 와, 정말 내가 이렇게까지 생각했다고요?',
                ],
                ['D. 도움이 되는 생각은 없을까요?'],
                [
                  '누구나 성장하려면 시간이 필요한 법이에요. 그리고 단 한 번만으로 사람을 평가할 순 없어요. 일이 늘 잘 될 수만은 없죠. 앞으로도 일이 안 들어올 수도 있겠지만, 또 다르게 생각하면 앞으로 더 나아지는 가능성도 충분히 많은 걸요. 직접 이유를 듣기 전에 사람의 마음은 알 수 없는 일이기도 하고요. 이렇게 보니 내 일이 마음에 안 들었다거나 이대로 영영 일을 못 하게 될 거라는 생각은 저에게 도움 될 게 하나도 없었네요.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '정말 많은 변화가 있네요!',
    },
    {
      image: wb14_3,
      title: '성숙한 사고를 할 수 있을 거예요!',
      texts: [
        {
          text: [
            '이번 시간에는 생각 함정을 기반으로 우리들의 생각들을 되짚어보는 방법에 대해 알아보았어요. 앞서 소개드린 믿음을 반박하는 과정, 즉, 회고 작업을 계속 하다 보면 보다 성숙한 사고를 할 수 있을 거예요.',
            <br />,
            <br />,
            '다음 시간에는 ABCDE 중 E에 대해 공부하고 ABCDE 모델 공부를 마무리할 거예요. 조금 쉬었다가 다시 시작하도록 해요. 저도 잠시 명상을 하고 올까봐요. 그러면 이따 만나요!',
          ],
        },
      ],
      buttonText: '곧 만나요!',
    },
  ],
  [
    {
      title: 'E로 생각 바꾸기',
      fullImage: wb12_1,
      height: 215,
      texts: [
        {
          text: [
            '이제 ABCDE 모델의 마지막 단계인 E에 대해 배울 거랍니다. ABCDE에서 E는 효과(Effect) 혹은 변화를 뜻해요. 나의 생각(ABC)에 대해 회고(D)를 진행한 후, 더 좋은 방향으로 생각할 수 있도록 정리(E)하는 것이죠. 자, 그럼 시작해 볼까요?',
          ],
        },
      ],
      buttonText: '드디어 마지막 단계네요!',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: 'Effect - 변화 (효과)',
      texts: [
        {
          text: [
            `효과 단계에서는 회고(D) 과정을 통해 떠올렸던 유익한 생각이 어떻게 나의 감정, 행동, 신체적 반응에 영향을 미치는지 생각해 보는 거예요. 다시 말해서 C를 새롭게 E로 바꾸는 것이죠. 도식으로 살펴볼까요?`,
          ],
        },
        {
          component: (
            <div className={`my-24px rounded-2xl mb-24px flex align-center bg-[#F3F5F8]`}>
              <img className="w-[335px] mx-auto" src={wb12_2} alt="empty" />
            </div>
          ),
        },
        {
          text: '우리는 위와 같은 인지 도식에서 CAB의 순서로 감정기록을 진행했어요. 즉 어떤 감정을 느꼈을 때(C), 당시에 무슨 상황이 있었는지 기록하고(A), 그때 무슨 생각을 했는지 적었죠(B). ',
        },
        {
          component: (
            <div className={`my-24px rounded-2xl mb-24px flex align-center bg-[#F3F5F8]`}>
              <img className="w-[335px] mx-auto" src={wb12_6} alt="empty" />
            </div>
          ),
        },
        {
          text: [
            '그리곤 회고(D)를 통해 이전의 불합리한 믿음을 새로운 믿음으로 대체했어요. 그리고 새로운 믿음은 우리에게 더 긍정적인 감정과 행동 반응을 제공해주게 되죠(E).',
            <br />,
            <br />,
            '우리들의 마음을 들여다보고 다시 되짚어보는 ABCDE 모델, 이제 잘 이해가 되나요?',
          ],
        },
      ],
      buttonText: '정말 체계적인 모델이었네요!',
      isBottom: true,
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '어떤 변화가 있을까요?',
      texts: [
        {
          text: '회고를 통해 우리들의 마음에는 어떤 변화가 있을까요? 회고의 내용이 반응에 어떻게 영향을 미치는지 확인하려면 스스로에게 다음과 같은 질문들을 던져보세요.',
        },
        {
          component: (
            <MessageBlock
              type="gray"
              textList={[
                ['새로운 관점을 통해 불러올 수 있는 긍정적인 변화로는 어떤 게 있을까요?'],
                ['그러한 관점을 완전히 수용한다면 해당 상황에서 무엇이 바뀔 수 있을까요?'],
                ['다음에는 어떻게 행동할 것 같아요?'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '내 감정과 행동도 바뀔 것 같아요',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '이전 수업의 예시를 이용해 볼게요',
      texts: [
        {
          text: [
            `재무설계사인 영호가 작성했던 감정기록을 한번 같이 살펴봐요. 우선 영호는 인사이드에서 다음과 같은 감정기록을 남겼어요.`,
          ],
        },
        {
          component: (
            <MessageBlockAndTail
              types={['gray', 'white_dotted']}
              textList={[
                ['C. 어떤 감정을 느꼈나요?'],
                ['불안했어요. 5점 만점 중에서는 4점으로 불안했어요.'],
                ['A. 어떤 상황이었나요?'],
                ['새로운 고객을 맡아서 일을 했는데, 첫 번째 의뢰 이후 더이상 일을 주지 않아요.'],
                ['B. 그때 어떻게 생각했나요?'],
                [
                  '내가 일을 잘하지 못했나 봐요. 정말 형편없죠. 첫 번째 의뢰 이후에 일이 끊어지다니. 이대로 일이 하나도 안 들어오면 공과금도 못 낼 텐데. 여자친구와 가족들은 날 무능한 사람이라고 생각하곘죠? 혼자 길거리에 나앉게 생겼네요.',
                ],
              ]}
              my
            />
          ),
        },
        {
          text: '불안한 마음에 영호는 감정기록을 남겼어요. 그리고 시간이 조금 흐른 뒤 인사이드에서 다시 한번 당시의 생각들을 되짚어봤죠.',
        },
        {
          component: (
            <MessageBlockAndTail
              types={['gray', 'white_dotted']}
              textList={[
                ['D. 생각 함정이 있진 않았나요?'],
                [
                  '안 좋은 일 한 번만으로도 현재 상황을',
                  '일반화',
                  '해서 생각한 것 같아요. 그리고 일을 한 번 못 따냈다고 길거리에 나앉겠다고 생각한 건 너무 ',
                  '최악의 시나리오',
                  '만 떠올린 것 같기도 해요. 다른 사정이 있을지도 모르는데 그저 내 실력이 안 좋아서 일을 안 맡긴다고 ',
                  '추측',
                  '한 것 같기도 해요. 와, 정말 내가 이렇게까지 생각했다고요?',
                ],
                ['D. 도움이 되는 생각은 없을까요?'],
                [
                  '누구나 성장하려면 시간이 필요한 법이에요. 그리고 단 한 번만으로 사람을 평가할 순 없어요. 일이 늘 잘 될 수만은 없죠. 앞으로도 일이 안 들어올 수도 있겠지만, 또 다르게 생각하면 앞으로 더 나아지는 가능성도 충분히 많은 걸요. 직접 이유를 듣기 전에 사람의 마음은 알 수 없는 일이기도 하고요. 이렇게 보니 내 일이 마음에 안 들었다거나 이대로 영영 일을 못 하게 될 거라는 생각은 저에게 도움 될 게 하나도 없었네요.',
                ],
                ['E. 그럼 다시 한번 생각해 볼까요?'],
                [
                  '새로운 고객한테 꾸준히 일을 받고 싶은 마음이 컸던 것 같아요. 내 입장이 되어 보면 누구든 덜컥 겁이 나고 실망스럽겠지만 고객이 일을 추가로 맡기지 않는 이유를 아직 제대로 모르는걸요. 나 스스로 실력이 없다고 결론짓기 전에 고객한테 연락해서 피드백을 부탁해야겠어요. 만약 피드백이 안 좋다면 또 개선할 부분을 알아낸 것이니까 괜찮아요. 이렇게 생각하니 이젠 불안하지 않아요.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '내 감정과 행동도 바뀔 것 같아요',
    },
    {
      id: 1,
      title: '마지막 정리를 해 볼까요?',
      texts: [
        {
          text: [`ABCDE 모델을 통해 마음을 들여다보는 일은 다음과 같은 순서(CABDE)로 진행된답니다.`],
        },
        {
          component: (
            <MessageBlockAndTail
              types={['gray', 'white_dotted']}
              textList={[
                ['C. 결과'],
                ['나에게 영향을 주었던 감정, 가령 울적함, 우울함, 분노, 언짢은 감정 등을 기록해 보세요.'],
                ['A. 사건'],
                [
                  '당시에 어떠한 상황이었는지 기록해 보세요. 이때 주의할 점은 상황에 대한 판단이나 평가는 배제하고 사실에 중점을 두면서 최대한 객관적으로 어떤 일이 발생했는지 적는 거예요.',
                ],
                ['B. 믿음 (자동적 사고)'],
                [
                  '그 사건이 있을 때 어떤 생각이 떠올랐는지, 그 사건이 나에게 어떠한 의미로 다가왔는지 적어보세요. 감정을 느꼈던 순간에 바로 적어보는 게 나의 자동적 사고를 더 명확하게 기록할 수 있답니다.',
                ],
                ['D. 회고 (믿음 논박)'],
                [
                  '조금 시간이 지난 후, 내가 했던 생각에 생각 함정이 포함되어 있는지 살펴보세요. 그리고 그보다 더 도움이 되는 생각을 할 순 없는지 떠올려보세요.',
                ],
                ['E. 변화 (효과)'],
                ['새롭게 생각했을 때 나의 감정과 행동, 신체에는 어떠한 변화가 있는지 적어보세요.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      link: '',
      buttonText: 'CABDE 순서로 진행하면 되는군요!',
    },
    {
      title: '회고 노트를 잘 활용해 보도록 해요',
      image: wb12_3,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                'CABDE의 순서로 체계적으로 감정을 기록하고 회고하는 과정이 쉬운 일은 아닐 거예요. 하지만 제가 드렸던 감정 노트와 회고 노트를 잘 활용해 보면 조금 더 쉽고 편하게 내 감정을 돌아볼 수 있어요.',
                '\n',
                '지난 번에 이야기했듯이 회고는 당일에 할 수는 없어요. 기록을 할 당시에는 감정에 휩싸여서 객관적인 회고를 할 수 없을 때가 많거든요. ',
                '회고',
                '는 ',
                '감정 기록을 하고 난 이틀 뒤부터 가능',
                '하답니다.',
                '\n',
                '이제 모든 내용을 배웠어요. 앞으로는 꾸준히 감정 노트와 회고 노트를 이용해서 내 감정을 기록하고 되짚어보는 거예요. ',
                '혹시나 궁금한 부분이 있다면 인사이드의 코치나 상담사 선생님에게 여쭤보는 것도 좋아요.',
                ' 어쩌면 저보다 더 자세하고 따뜻한 이야기를 해 줄 수도 있죠. 저는 더 자세한 이야기를 나누기에는 너무 바쁘거든요.',
                '\n',
                '저는 이제 조금 자러가야겠어요. 피로가 몰려오네요. 자, 그러면 고생 많았어요. 우리는 또 언젠가 다른 곳에서 만나요. 즐거운 시간이었어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '감사해요!',
    },
  ],
  [
    {
      texts: [
        {
          component: (
            <div className={`rounded-2xl mb-30px flex align-center bg-[#7655FA]`}>
              <img className="w-[335px] mx-auto" src={wb13_1} alt="empty" />
            </div>
          ),
        },
        {
          component: (
            <p
              className="font-bold text-[24px] leading-[30px] mb-[15px] text-[#26282C]"
              style={{ wordBreak: 'keep-all' }}
            >
              감정을 만드는 생각을 찾아
            </p>
          ),
        },
        {
          text: '이번 코스에서 살펴본 것처럼 감정은 내가 능동적으로 느끼는 게 아니랍니다. 나의 생각, 어쩌면 나의 무의식에서 기원했을 자동적 사고가 내 감정을 좌우하고 있죠. 그러한 자동적 사고가 있다는 것을 인지했다는 것만으로도 큰 변화가 있을 수 있답니다.',
        },
      ],
      buttonText: '신기하고 유익했어요!',
      link: '',
    },
    {
      title: '감정 노트가 도움될 거예요',
      image: wb13_2,
      bg: '#96ccff',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '닥터 스마일이 선물했던 감정 노트, 잘 사용하고 계신가요? ABCDE모델 중 ABC에 집중해서 C-A-B 순서로 감정을 기록했죠. 즉 ',
                '강렬한 감정을 느꼈을 때 감정 노트를 열어 감정을 기록',
                '하고(C), ',
                '당시에 어떠한 상황이 있었는지',
                '(A), 그리고 ',
                '그 상황에서 어떻게 생각했었는지',
                '(B) 적어보는 거예요.',
                '\n',
                '이와 같은 방식으로 감정을 기록하는 방법은 나의 자동적 사고, 더 나아가 내 마음을 이루고 있는 기본 가정들을 파악하는 데에 도움을 줄 수 있답니다. 실제 연구를 통해 그 효과가 입증된 방법이기도 해요.',
              ]}
            />
          ),
        },
        {
          text: [
            <br />,
            ` 이와 같은 방식으로 감정을 기록하는 방법은 나의 자동적 사고, 더 나아가 내 마음을 이루고 있는 기본 가정들을 파악하는 데에 도움을 줄 수 있답니다. 실제 연구를 통해 그 효과가 입증된 방법이기도 해요.`,
          ],
        },
      ],
      buttonText: '열심히 할 거예요!',
      link: '',
    },
    {
      title: '회고 노트도 꾸준히 사용해야 해요',
      image: wb13_3,
      bg: '#aabaff',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                'ABCDE 모델에서 ABC가 끝이 아니었죠? 핵심은 CAB 순서로 작성한 나의 ',
                '감정 기록을 회고',
                '하고(D), ',
                '다시 한번 생각을 교정',
                '해 보는 작업이었어요(E). ',
                '\n',
                '이 과정을 위해 닥터 스마일이 제공해드린 회고 노트 잘 받으셨죠? ',
                '회고',
                '는 ',
                '감정기록을 하고 난 이틀 뒤부터 가능',
                '하답니다. 감정을 작성했을 당시에는 그 감정에 휩싸여서 객관적으로 회고를 진행할 수 없기 때문이에요. 감정을 기록하는 것 만큼이나 감정을 되돌아보는 작업도 중요하니 회고도 꾸준히 해야 해요!',
              ]}
            />
          ),
        },
      ],
      buttonText: '회고 노트도 열심히 써볼게요',
      link: '',
    },
    {
      title: '그런데 생각은 어디서 기원하는 걸까요?',
      image: wb13_4,
      bg: '#FFC670',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '감정을 만드는 생각 찾기에서는 나의 자동적 사고를 살펴봤었죠. 누군가는 일반화 생각 함정에 자주 빠지기도 하고, 또 다른 누군가는 과도한 책임감 생각 함정에 자주 빠진답니다. 그런데 왜 사람마다 이렇게 자주 하는 생각 오류들이 있는 걸까요? ',
                '내 생각의 근원은 어디에서 비롯되고 있는 것일까요? ',
              ]}
            />
          ),
        },
      ],
      buttonText: '생각해 보니 그렇네요. 왜 그럴까요?',
      link: '',
    },
    {
      title: '다른 코스에서 알아봐요',
      image: wb13_5,
      bg: '#7655FA',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '사실 내 마음을 되짚어보는 과정을 단 하나의 코스만으로 완료할 수는 없답니다. 감정을 만드는 생각 찾기 코스는 ',
                '생각의 발자취 따라가기',
                ' 코스와 ',
                '마음의 근원을 찾아서',
                ' 코스로 이어져요. 두 코스 모두 조금 더 깊은 내면을 살펴보는 코스랍니다. 두 코스를 마무리 하면 ',
                '내가 자주 빠지는 생각 함정을 파악하고, 그 생각들이 어디서 비롯되는지 이해',
                '할 수 있을 거예요. 온전한 내 마음을 들여다보는 과정이죠.',
                '\n',
                '자, 그러면 또 다른 코스에서 만나도록 해요. 감정 기록을 꾸준히 사용하는 것, 잊지 말고요!',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요!',
      link: '',
    },
  ],
];

export default course1Data;
