import 'moment/locale/ko';
import React from 'react';
import { anxyToolData } from '../../../data/anxy/home/anxyToolData';

import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { BlackActionButton, WhiteActionButton } from '../../../stories/button/ActionButton';

export default function CardWithTail({
  title,
  text,
  buttonText,
  buttonColor,
  tailMoveAmount,
  isTailBottom,
  hideButton,
}) {
  const goToTrialExample = useNavigateWithParams(
    `../trialExample/${anxyToolData.find((item) => item.title === title).url}/0`,
  );

  const heart = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.30916 3.53745C-0.436388 5.25405 -0.436388 8.03721 1.30916 9.75381L2.60738 11.0305L2.60418 11.0336L6.82292 15.1824C7.98989 16.33 9.86132 16.3305 11.0289 15.1835L15.253 11.0336L15.2498 11.0305L16.548 9.75381C18.2935 8.03721 18.2935 5.25405 16.548 3.53745C14.8024 1.82085 11.9723 1.82085 10.2268 3.53745L8.92857 4.81414L7.63036 3.53745C5.88481 1.82085 3.05471 1.82085 1.30916 3.53745Z"
        fill="url(#paint0_linear_2518_56359)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2518_56359"
          x1="8.92857"
          y1="2.25"
          x2="8.92857"
          y2="17.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DC3535" />
          <stop offset="1" stopColor="#C61C1C" />
        </linearGradient>
      </defs>
    </svg>
  );

  return (
    <div className="flex flex-col items-center ">
      {!isTailBottom && (
        <div className={`z-[40] `} style={{ transform: `translate(${tailMoveAmount}px,1px)` }}>
          <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.4024 1.5C5.5571 -0.500004 8.44385 -0.5 9.59855 1.5L13.9287 9L0.0722656 9L4.4024 1.5Z"
              fill="white"
            />
          </svg>
        </div>
      )}
      <div
        className="w-[calc(100vw-60px)] max-w-[315px] bg-white px-[20px] pt-[24px] pb-[20px] rounded-[20px]
    drop-shadow-[0_5px_20px_rgba(0,0,0,0.20)] "
      >
        <div className="flex flex-col gap-[15px]">
          <div className="flex gap-[30px] ">
            <div className="flex-1 flex flex-col gap-[6px] justify-between">
              <div className=" text-[18px] leading-[23px] font-semibold ">{title}</div>
              <div className="text-[15px] leading-[21px] text-[rgba(0,0,0,0.6)]">{text}</div>
            </div>
          </div>
          {!hideButton &&
            (buttonColor === 'white' ? (
              <WhiteActionButton text={buttonText} action={() => {}} medium />
            ) : (
              <BlackActionButton text={buttonText} action={goToTrialExample} medium />
            ))}
        </div>
      </div>
      {isTailBottom && (
        <div className={`z-[40] `} style={{ transform: `translate(${tailMoveAmount}px,-1px)` }}>
          <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.33013 7.5C5.48483 9.5 8.37158 9.5 9.52628 7.5L13.8564 9.53674e-07L0 9.53674e-07L4.33013 7.5Z"
              fill="white"
            />
          </svg>
        </div>
      )}
    </div>
  );
}
