import React, { useState, useCallback, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { hasTouch } from 'detect-touch';

export default function SkipButton(props) {
  const { type, action, disable, somny } = props;
  const [buttonDown, setButtonDown] = useState(false);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  function mouseUp(e) {
    setButtonDown(false);
  }

  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  }, []);
  const doAction = useCallback(
    useDebounce(() => {
      if (!disable) {
        console.log('skip');
        action();
      }
    }, 300),
  );

  return (
    <div
      className={`w-[50px] h-[50px] rounded-[50px] ${
        disable
          ? 'text-[#26282C] opacity-70'
          : buttonDown
          ? 'bg-[rgba(225,231,255,0.15)] opacity-60'
          : 'bg-[rgba(225,231,255,0)] '
      } flex items-center justify-center text-[15px] leading-[21px] font-bold text-[#A4A6B0] relative `}
      onMouseDown={(e) => {
        if (!hasTouch) {
          setButtonDown(true);
        }
      }}
      onClick={(e) => {
        if (!hasTouch) {
          doAction();
        }
      }}
      onTouchStart={(e) => {
        if (!buttonDown) {
          // console.log('touchstart: ');
          setTouchStartY(e.changedTouches[0].clientY);
          setTouchStartX(e.changedTouches[0].clientX);

          setButtonDown(true);
        }
      }}
      onTouchEnd={(e) => {
        setButtonDown(false);
        if (
          document
            .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
            .includes(e.currentTarget) &&
          Math.abs(e.changedTouches[0].clientY - touchStartY) < 10 &&
          Math.abs(e.changedTouches[0].clientX - touchStartX) < 10
        ) {
          // console.log('touchend: ', e.changedTouches[0].clientY);
          doAction();
        }
      }}
    >
      {type === 'forward' ? (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity={somny ? '0.8' : '0.7'}>
            <path
              d="M25.0001 9.69898C16.4235 9.69898 9.47071 16.6517 9.47071 25.2284C9.47071 33.8051 16.4235 40.7578 25.0001 40.7578C33.5768 40.7578 40.5295 33.8051 40.5295 25.2284C40.5295 19.8908 37.8367 15.1821 33.7354 12.3869"
              stroke={somny ? '#FFFFFF' : '#26282C'}
              strokeWidth="1.8"
              strokeLinecap="round"
            />
            <path
              d="M26.8916 9.17902L27.2828 8.36846C27.5323 8.48888 27.7125 8.71754 27.7712 8.98834C27.8299 9.25914 27.7606 9.54188 27.5833 9.75483L26.8916 9.17902ZM22.9913 8.29619C22.5437 8.08017 22.3559 7.54215 22.5719 7.09449C22.788 6.64683 23.326 6.45905 23.7736 6.67508L22.9913 8.29619ZM24.9339 12.9373C24.6159 13.3194 24.0484 13.3712 23.6664 13.0532C23.2844 12.7352 23.2325 12.1677 23.5505 11.7857L24.9339 12.9373ZM26.5005 9.98957L22.9913 8.29619L23.7736 6.67508L27.2828 8.36846L26.5005 9.98957ZM23.5505 11.7857L26.1999 8.6032L27.5833 9.75483L24.9339 12.9373L23.5505 11.7857Z"
              fill={somny ? '#FFFFFF' : '#26282C'}
            />
            <path
              d="M19.7501 31H21.94V20.4312H19.7428L17.0108 22.3281V24.291L19.7062 22.46H19.7501V31ZM28.0707 31.1831C30.4877 31.1831 32.1576 29.6816 32.1576 27.4697V27.4551C32.1576 25.4629 30.7074 24.02 28.7006 24.02C27.6312 24.02 26.7597 24.4521 26.3202 25.1626H26.2763L26.5326 22.189H31.5204V20.4312H24.7748L24.2914 26.6348H26.2177C26.3422 26.415 26.5106 26.21 26.723 26.0488C27.0819 25.7632 27.5434 25.6021 28.0854 25.6021C29.2279 25.6021 30.0409 26.3857 30.0482 27.499V27.5137C30.0482 28.6416 29.2353 29.4326 28.078 29.4326C27.06 29.4326 26.3202 28.8174 26.1664 28.019L26.1518 27.9678H24.1083L24.1156 28.0776C24.2475 29.8428 25.7709 31.1831 28.0707 31.1831Z"
              fill={somny ? '#FFFFFF' : '#26282C'}
            />
          </g>
        </svg>
      ) : (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity={somny ? '0.8' : '0.7'}>
            <path
              d="M25.0001 9.69898C33.5768 9.69898 40.5295 16.6517 40.5295 25.2284C40.5295 33.8051 33.5768 40.7578 25.0001 40.7578C16.4235 40.7578 9.4707 33.8051 9.4707 25.2284C9.4707 19.8908 12.1636 15.1821 16.2648 12.3869"
              stroke={somny ? '#FFFFFF' : '#26282C'}
              strokeWidth="1.8"
              strokeLinecap="round"
            />
            <path
              d="M23.1086 9.17902L22.7175 8.36846C22.4679 8.48888 22.2878 8.71754 22.2291 8.98834C22.1704 9.25914 22.2397 9.54188 22.417 9.75483L23.1086 9.17902ZM27.0089 8.29619C27.4566 8.08017 27.6443 7.54215 27.4283 7.09449C27.2123 6.64683 26.6743 6.45905 26.2266 6.67508L27.0089 8.29619ZM25.0663 12.9373C25.3843 13.3194 25.9518 13.3712 26.3338 13.0532C26.7158 12.7352 26.7677 12.1677 26.4497 11.7857L25.0663 12.9373ZM23.4998 9.98957L27.0089 8.29619L26.2266 6.67508L22.7175 8.36846L23.4998 9.98957ZM26.4497 11.7857L23.8003 8.6032L22.417 9.75483L25.0663 12.9373L26.4497 11.7857Z"
              fill={somny ? '#FFFFFF' : '#26282C'}
            />
            <path
              d="M19.7501 31H21.94V20.4312H19.7428L17.0108 22.3281V24.291L19.7062 22.46H19.7501V31ZM28.0707 31.1831C30.4877 31.1831 32.1576 29.6816 32.1576 27.4697V27.4551C32.1576 25.4629 30.7074 24.02 28.7006 24.02C27.6312 24.02 26.7597 24.4521 26.3202 25.1626H26.2763L26.5326 22.189H31.5204V20.4312H24.7748L24.2914 26.6348H26.2177C26.3422 26.415 26.5106 26.21 26.723 26.0488C27.0819 25.7632 27.5434 25.6021 28.0854 25.6021C29.2279 25.6021 30.0409 26.3857 30.0482 27.499V27.5137C30.0482 28.6416 29.2353 29.4326 28.078 29.4326C27.06 29.4326 26.3202 28.8174 26.1664 28.019L26.1518 27.9678H24.1083L24.1156 28.0776C24.2475 29.8428 25.7709 31.1831 28.0707 31.1831Z"
              fill={somny ? '#FFFFFF' : '#26282C'}
            />
          </g>
        </svg>
      )}
    </div>
  );
}
