import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import axios from '../utils/axiosAnxy';
import webviewToast from '../development/webviewToast';
import useNavigateWithParams from './useNavigateWithParams';
import { useSetRecoilState } from 'recoil';
import sessionAtom from '../recoil/anxy/session/atom';
import useMixpanelEventAnxy from './useMixpanelEventAnxy';
import { getSessionData } from '../utils/anxySessionLogic';

export default function usePostSessionDone({ id }) {
  const { sessionId } = useParams();
  const [searchParams] = useSearchParams();

  const [isLifetimeFirstComplete, setLifetimeFirstComplete] = useState();

  const goToSessionDone = useNavigateWithParams(`/sessionDone?isLifetimeFirstComplete=${isLifetimeFirstComplete}`);

  const setSessionState = useSetRecoilState(sessionAtom);

  const sessionData = getSessionData(sessionId);
  const setMixPanel = useMixpanelEventAnxy({
    eventName: '세션 진행 완료',
    params: {
      세션: sessionData ? sessionData.eventProperty : '',
    },
  });

  useEffect(() => {
    if (isLifetimeFirstComplete !== undefined) {
      goToSessionDone();
    }
  }, [isLifetimeFirstComplete]);

  const sectionDataId = sessionId ? sessionId : id;

  const postSessionDone = useCallback(async () => {
    try {
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          `/anxy/chapter/section/complete`,
          JSON.stringify({
            sectionId: sectionDataId,
          }),
        )
        .then((response) => {
          setMixPanel();
          let sessionName = '';
          sessionName = getSessionData(sectionDataId).eventProperty;
          webviewToast('session name: ' + sessionName);
          console.log(`isFirstComplete: ${response.data.result.isFirstComplete}`, 'sessionId: ' + sessionId);

          if (response.data.result.isFirstComplete && sessionId === 'session_aa') {
            setLifetimeFirstComplete(true);
          } else {
            setLifetimeFirstComplete(false);
          }

          // 세션 끝내면 카드 이동을 위해 currentSession(보고 있던 마지막 세션) 삭제
          setSessionState((state) => ({ ...state, currentSession: undefined }));
        });
    } catch (error) {
      goToSessionDone();
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return postSessionDone;
}
