import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectButton } from '../../../stories/button/SelectButton';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { useContext } from 'react';
import { CoachingContext } from '../../../coachingContext';

export default function SleepingRuleCard({ title, text, isMarketing }) {
  const { id, page } = useParams();
  const [clicked, setClicked] = useState(false);
  const [sleepingSurvey, setSleepingSurvey] = useSessionStorage('sleepingSurvey');
  const currentPage = isMarketing ? parseInt(id) - 6 : parseInt(page) - 6;
  console.log('current is: ', isMarketing, id, currentPage, sleepingSurvey);

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  const goNextInMarketing = useNavigateWithParams(`/sleepChallengers/${parseInt(id) + 1}`);

  const context = useContext(CoachingContext);
  useEffect(() => {
    setClicked(false);
  }, []);

  console.log(sleepingSurvey);
  function isClicked(option) {
    if (option !== '') {
      console.log('이미클릭함');
      select(option);
      console.log('option', option);
    }
  }
  console.log(sleepingSurvey);

  function select(option) {
    setClicked(true);
    if (sleepingSurvey.ruleList.find((element) => element.ruleId === currentPage)) {
      console.log('current is: find');
      const updated = { ...sleepingSurvey };
      updated.ruleList = [
        ...sleepingSurvey.ruleList.filter((element) => element.ruleId !== currentPage),
        { ruleId: currentPage, isDone: option.isDone },
      ];
      setSleepingSurvey(updated);
    } else {
      console.log('current is: not find');

      const updated = { ...sleepingSurvey };
      updated.ruleList = [...updated.ruleList, { ruleId: currentPage, isDone: option.isDone }];
      setSleepingSurvey(updated);
    }

    if (currentPage < 16) {
      if (isMarketing) {
        setTimeout(() => {
          goNextInMarketing();
        }, 300);
      } else {
        setTimeout(() => {
          goNext();
        }, 300);
      }
    }
  }

  useEffect(() => {
    console.log(sleepingSurvey);
    if (clicked) {
      console.log('clicked');
    }
  }, [sleepingSurvey]);

  const options = [
    { isDone: true, text: '네' },
    { isDone: false, text: '아니요' },
  ];

  return (
    <div className={`w-full px-20px h-[calc(100vh-17px)] overflow-hidden relative`}>
      <div
        className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C]  mt-[50px] "
        style={{ wordBreak: 'keep-all' }}
      >
        {title}
      </div>

      <div className="w-[calc(100%-40px)] absolute top-[50%] translate-y-[calc(-50%+20px)]">
        {options.map((option, index) => (
          <SelectButton
            selected={
              sleepingSurvey.ruleList.find((element) => element.ruleId === currentPage) &&
              sleepingSurvey.ruleList.find((element) => element.ruleId === currentPage).isDone === option.isDone
            }
            option={option}
            text={option.text}
            isClicked={isClicked} //버튼 컴포넌트에서 알려줌
            disable={false} // 버튼 컴포넌트로 알려줌
          />
        ))}
      </div>
    </div>
  );
}
