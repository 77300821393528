import React, { useState } from 'react';
import { body1 } from '../../resources/course-5';

export default function Script(props) {
  const { duration } = props;
  const [scrollTop, setScrollTop] = useState(0);

  return (
    <div className={`w-screen `}>
      <div
        className={`w-full bg-[#FFFFFF] px-[20px] pb-[60px] text-[15px] leading-[18px] 
         overflow-scroll scrollbar-hide `}
        onScroll={(e) => {
          setScrollTop(e.target.scrollTop);
        }}
        style={{
          borderRadius: '20px 20px 0px 0px',
          boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.05)',

          overscrollBehavior: 'none',
        }}
      >
        <div className="w-full h-[60px] sticky top-0 z-30">
          <div className="w-full h-[60px] bg-white pt-[12px]" />
          {scrollTop > 0 && (
            <div
              className="w-full h-[20px]  "
              style={{ background: `linear-gradient(to bottom, white, rgba(255,255,255,0))` }}
            />
          )}
        </div>

        <p className="text-[#26282C] text-[24px] leading-[30px] font-semibold mb-[6px] text-center ">긴장 풀기</p>
        <div className="mb-[40px] flex items-center justify-center pr-[17px]">
          <div className="mr-[5px]">
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="7.5" cy="7" r="6.4" stroke="#4E4F53" strokeWidth="1.2" />
              <path d="M7.5 3.89062V7.00174L9.36667 9.33522" stroke="#4E4F53" strokeWidth="1.2" />
            </svg>
          </div>
          <div className="text-[#4E4F53] opacity-[0.8] text-[14px] leading-[20px] font-bold  ">{duration}</div>
        </div>
        <div className="text-[17px] leading-[28px] text-[#4E4F53] ">
          {`안녕하세요. 오늘도 잘 찾아오셨습니다. 조금씩 수면이 개선되는 느낌이 드는지 모르겠네요. 별다른 진척이 없는 것 같더라도 아직 코스의 절반도 지나지 않았으니 조금만 더 참고 진행해보시길 바랍니다. 분명 많은 도움이 있을거예요. 
          \n\n${body1}`}
        </div>
      </div>
    </div>
  );
}
