import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import webviewToast from '../development/webviewToast';
import modalAtom from '../recoil/common/modal/atom';
import notificationSettingAtom from '../recoil/common/notification/settingAtom';
import { requestAppState } from '../utils/webview';
import useFetchAnxy from './useFetchAnxy';
import useMixpanelEventAnxy from './useMixpanelEventAnxy';

export default function useSetNotification(timeList, action, isSession, isSetting) {
  const [notificationState, setNotificationState] = useRecoilState(notificationSettingAtom);

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '알림 설정 완료',
    params: {
      타입: isSession ? '세션' : '걱정기록',
    },
  });

  const requestBody = { ...notificationState };

  console.log(requestBody);
  const [saveNotification, setSaveNotification] = useFetchAnxy({
    url: '/anxy/v2/notification/setting/save',
    requestBody: requestBody,
  });

  const [isAlarmSet, setIsAlarmSet] = useState(false);
  const setModalState = useSetRecoilState(modalAtom);

  useEffect(() => {
    if (saveNotification) {
      if (!isSetting) {
        setMixPanel();
      }
      action();
    }
  }, [saveNotification]);

  const getAlarmPermission = () => {
    const eventFromApp = async (event) => {
      setIsAlarmSet(true);
      webviewToast('alarm message: ' + JSON.stringify(event.detail));
      // 권한이 없을 때
      if (!isAlarmSet) {
        if (!event.detail.isEnabled) {
          webviewToast('권한 없음');
          setModalState({ isModalVisible: true, modalType: 'ALLOW_NOTIFICATION' });
        } else {
          webviewToast('권한 있음');
          // 권한 있을 때
          if (timeList) {
            setSaveNotification();
          }
        }
      }
    };

    window.addEventListener('notificationPermission', eventFromApp);

    return () => {
      window.removeEventListener('notificationPermission', eventFromApp);
    };
  };

  const fetchData = useCallback(() => {
    requestAppState('notification');
    getAlarmPermission();
    if (window.location.hostname === 'localhost') {
      setSaveNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeList]);

  return fetchData;
}
