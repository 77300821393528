import React, { useState, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import coachInfo from '../../data/coachInfo';
import AccordianItem from './AccordianItem';

export default function AccordianContent(props) {
  const [index, setIndex] = useState(1);
  const { id, page } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    switch (parseInt(id)) {
      case 8:
        setIndex(2);
        break;
      case 10:
        setIndex(3);
        break;
      case 58:
        setIndex(4);
        break;
      case 60:
        setIndex(5);
        break;
      case 98:
        setIndex(6);
        break;
      default:
        break;
    }
  }, []);

  const content = coachInfo[index - 1];

  return (
    <div className="mt-40px">
      {content.map((item, index) => (
        <AccordianItem item={item} />
      ))}
    </div>
  );
}
