/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import landing1 from '../../image/somny/landing1.png';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../recoil/common/header/atom';
import { TopTitleBottomActionSomny } from '../../stories/page/TopTitleBottomActionSomny';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';
import useLoadImage from '../../hooks/useLoadImage';
import { somnyImageList } from '../../data/somnyImageList';

export default function SomnyLanding() {
  const setMixPanel = useMixpanelWeb({
    eventName: '1. 시작',
    projectName: 'somny',
  });

  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    const body = document.body;
    body.style.position = 'fixed';
    body.style.overflow = 'hidden';
    setMixPanel();
  }, []);

  const goPreview = useNavigateWithParams(`../somnyPreview/0`);
  const preloadImage = useLoadImage(somnyImageList);

  useEffect(() => {
    document.title = 'Somny, 오늘 밤을 위한 수면 관리';
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE, headerTitle: '' }));
    preloadImage();
  }, []);

  const MainContent = () => (
    <div className="h-full w-full text-center text-white flex flex-col justify-center items-center py-[30px]">
      <div className="pb-[50px] max-w-[375px]">
        <div className="px-[16%]">
          <img src={landing1} alt="emtpy" className="object-contain w-full h-full" />
        </div>
      </div>
      <div className="px-[24px]">
        <p className="text-[30px] leading-[36px] font-bold">{`잠 못 드는 밤은 그만, \n 이젠 somny와 잠들어요`}</p>
        <p className="text-[17px] leading-[23px] opacity-[0.8] pt-[10px]">오늘 밤을 위한 수면 관리, somny</p>
      </div>
    </div>
  );
  return (
    <div
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <TopTitleBottomActionSomny
        content={<MainContent />}
        buttonText={'somny 알아보기'}
        buttonState={'ACTIVE'}
        action={() => {
          goPreview();
        }}
        isSomny
        noHeader
      />
    </div>
  );
}
