import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { hasTouch } from 'detect-touch';
import { useContext } from 'react';
import { CoachingContext } from '../../../coachingContext';
// import { useNavigateWithParam } from '../../../hooks/useWorkbookNavigate';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';

export default function QuizOXButton({ textList, answer }) {
  const { id, page } = useParams();
  const [clickedId, setClickedId] = useState();
  const [isPressed, setIsPressed] = useState(false);
  const [select, setSelect] = useState('');
  const [myAnswer, setMyAnswer] = useState('');
  const context = useContext(CoachingContext);
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  function mouseUp(e) {
    setIsPressed(false);
    setMyAnswer(select);
  }

  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  useEffect(() => {
    if (!hasTouch) {
      if (myAnswer === answer) {
        goNext();
      } else {
        if (context.showWorkbookToast === false) {
          context.setShowWorkbookToast(true);
        }
      }
    }
  }, [myAnswer]);

  return (
    <div className="font-normal flex text-white" style={{ columnGap: '10px' }}>
      {textList.map((text, index) => (
        <motion.div
          animate={{ scale: isPressed && clickedId === index ? 0.95 : 1 }}
          transition={{ duration: 0.2 }}
          className={`text-left h-[120px] flex justify-center items-center w-full rounded-[12px] text-[17px] leading-[26px] text-center text-[#80838B]
          ${myAnswer === text && text === answer && 'text-white bg-[#F5F6FD]'}}

          ${myAnswer === text && text !== answer && 'text-[#E32940] bg-[#E32940]'}}
          `}
          style={{
            border:
              myAnswer === text && text !== answer
                ? '1px solid #E32940'
                : myAnswer === text && text === answer
                ? '1px solid #3953D9'
                : '1px solid #D1D5DC',

            background: myAnswer === text && text === answer && 'linear-gradient(272.73deg, #5513F0 0%, #2C4BEC 100%)',
          }}
          onMouseDown={(e) => {
            if (!hasTouch) {
              setClickedId(index);
              setIsPressed(true);
              setSelect(e.currentTarget.textContent);
            }
          }}
          onTouchStart={(e) => {
            setClickedId(index);
            setIsPressed(true);
          }}
          onTouchEnd={(e) => {
            setIsPressed(false);
            console.log(e.currentTarget);

            if (
              document
                .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
                .includes(e.currentTarget)
            ) {
              setMyAnswer(index === 0 ? 'O' : 'X');
              if (index === 0 ? answer === 'O' : answer === 'X') {
                goNext();
                context.setShowWorkbookToast(false);
              } else {
                if (context.showWorkbookToast === false) {
                  context.setShowWorkbookToast(true);
                }
              }
            }
          }}
        >
          {text === 'O' ? (
            <div className="w-[40px] h-[40px]">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle
                  cx="20"
                  cy="20"
                  r="17.5"
                  stroke={`${
                    myAnswer === text && text !== answer
                      ? '#EF3E3E'
                      : myAnswer === text && text === answer
                      ? '#FFFFFF'
                      : '#80838B'
                  } `}
                  stroke-width="5"
                />
              </svg>
            </div>
          ) : (
            <div>
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M45 15L15 45"
                  stroke={`${
                    myAnswer === text && text !== answer
                      ? '#EF3E3E'
                      : myAnswer === text && text === answer
                      ? '#FFFFFF'
                      : '#80838B'
                  } `}
                  stroke-width="5"
                  stroke-linecap="square"
                />
                <path
                  d="M45 45L15 15"
                  stroke={`${
                    myAnswer === text && text !== answer
                      ? '#EF3E3E'
                      : myAnswer === text && text === answer
                      ? '#FFFFFF'
                      : '#80838B'
                  } `}
                  stroke-width="5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          )}
        </motion.div>
      ))}
    </div>
  );
}
