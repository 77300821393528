/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../../stories/page/BottomActionAnxy';
import { useParams, useSearchParams } from 'react-router-dom';
import anxyTypeData from '../../../data/anxyTypeData';
import { useSetRecoilState } from 'recoil';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import useMixpanelWeb from '../../../hooks/useMixpanelWeb';

export default function TypeResult(props) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = parseInt(searchParams.get('type'));
  const typeData = anxyTypeData.find((element) => element.type === type);
  const goNext = useNavigateWithParams(`/anxyWebTest/typeInfo${parseInt(id.substring(8)) + 1}`);
  const goAboutCourse = useNavigateWithParams(`/anxyWebAboutCourse/0`);

  const setMixpanel = useMixpanelWeb({
    eventName: `(AT) 검사 결과 ${parseInt(id.substring(8)) + 1}`,
    projectName: 'anxy',
  });

  const initializeSelfCheckAndGoIntro = () => {
    console.log('sd');
    setSelfCheckState({ qcList: [], selfCheckAnswer: [], selfCheckResult: [] });
    goAboutCourse();
  };

  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const setHeaderState = useSetRecoilState(headerAtom);
  const setSelfCheckState = useSetRecoilState(selfCheckAtom);

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
    setMixpanel();
  }, []);

  const titleClassName = 'text-[24px] leading-[30px] font-bold';
  const textClassName = 'text-[17px] leading-[28px] font-light';

  return (
    <BottomActionAnxy
      content={
        id === 'typeInfo1' ? (
          <div className="pt-[12px] px-[20px] flex flex-col gap-[24px] text-[#26282C]">
            <p className={titleClassName}>{typeData.name}는</p>
            <p className={textClassName}>{typeData.explanation}</p>
          </div>
        ) : (
          <div className="flex-1 pt-[12px]  text-[#26282C] relative">
            <div className=" flex flex-col gap-[24px] px-[20px]  z-[30] relative">
              <p className={titleClassName}>어떻게 관리하나요?</p>
              <p className={`${textClassName}`}>{typeData.solution}</p>
            </div>
            <div className="absolute bottom-[-74px] w-full z-[0] ">
              <div className="relative w-full">
                <div className="absolute left-[50%] translate-x-[-50%] bottom-0">
                  <svg width="375" height="358" viewBox="0 0 375 358" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="187.5" cy="226.2" rx="226.5" ry="226.2" fill="white" />
                    <ellipse cx="148.021" cy="132.76" rx="15.0127" ry="23.7702" fill="#181818" />
                    <ellipse cx="225.586" cy="132.76" rx="15.0127" ry="23.7702" fill="#181818" />
                    <path
                      d="M187.028 100.585L198.774 164.462C198.824 164.734 198.788 165.015 198.669 165.265L186.923 190.112C186.447 191.12 185.013 191.12 184.537 190.112L172.791 165.265C172.673 165.015 172.636 164.734 172.686 164.462L184.432 100.585C184.697 99.143 186.763 99.143 187.028 100.585Z"
                      fill="#CB6932"
                    />
                    <path
                      d="M123 104.41C155.01 77.3223 218.611 79.1132 249.656 104.41"
                      stroke="#181818"
                      strokeWidth="6.6"
                      strokeLinecap="round"
                    />
                    <path
                      d="M217.38 194.863C210.365 208.936 199.52 215.983 187.35 215.983C175.18 215.983 164.335 208.936 157.32 194.863"
                      stroke="#803F3F"
                      strokeWidth="6.6"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )
      }
      bgColor={id !== 'typeInfo2' ? '#ffffff' : '#F3F5F8'}
      buttonText={id !== 'typeInfo2' ? '다음' : 'Anxy 앱 알아보기'}
      buttonState={'ACTIVE'}
      noGradient={id === 'typeInfo2'}
      action={() => {
        if (id === 'typeInfo2') {
          initializeSelfCheckAndGoIntro();
        } else {
          goNext();
        }
      }}
      fixedRoot
    />
  );
}
