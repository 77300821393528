import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { hasTouch } from 'detect-touch';
import { motion } from 'framer-motion';

export function SelectButton(props) {
  const { selected, option, text, isClicked, disable, anxy, insideBlack } = props;
  const [buttonDown, setButtonDown] = useState(false);

  function mouseUp(e) {
    setButtonDown(false);
  }
  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });
  return (
    <motion.div animate={{ scale: buttonDown && !disable ? 0.95 : 1 }} transition={{ duration: 0.2 }}>
      <div
        className={`w-full
               px-[20px] mb-[10px] flex items-center
              ${insideBlack ? 'py-[11px] rounded-[16px] font-bold' : 'py-[13px] rounded-[12px] ring-[1px] ring-inset'}
            ${
              selected
                ? `font-semibold text-[#3953D9] ${anxy ? 'bg-[#FFEBE2]' : 'bg-[#F5F6FD]'} ${
                    insideBlack ? 'text-[#FF6F6F] bg-[#000000] justify-center' : 'ring-[#3953D9]'
                  }`
                : `text-[#26282C] ${anxy ? 'bg-[#FFFFFF]' : ''} ${
                    insideBlack ? 'text-white justify-center' : 'ring-[#D1D5DC] font-normal'
                  }`
            }`}
        style={{
          border:
            insideBlack && selected
              ? '2px solid #FF6F6F'
              : insideBlack && !selected
              ? '2px solid rgba(255, 255, 255, 0.3)'
              : '',
        }}
        onMouseDown={(e) => {
          if (!hasTouch) {
            setButtonDown(true);
          }
        }}
        onClick={(e) => {
          console.log('action ');
          if (!hasTouch && !disable) {
            isClicked(option);
          }
        }}
        onTouchStart={(e) => {
          if (!buttonDown && !disable) {
            setButtonDown(true);
          }
        }}
        onTouchEnd={(e) => {
          setButtonDown(false);
          if (
            document
              .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
              .includes(e.currentTarget) &&
            !disable
          ) {
            isClicked(option);
          }
        }}
      >
        <div className="select-none leading-[28px] text-[17px]  ">{text}</div>
      </div>
    </motion.div>
  );
}
// SelectButton.propTypes = {
//   selected:PropTypes.bool,
//    option:undefined,
//    text:PropTypes.string,
//    isClicked:PropTypes.func,
//    disable:PropTypes.bool
// };
// SelectButton.defaultProps = {
//   state: 'ACTIVE',
//   activeColor: '#3953D9',
//   inactiveColor: '#E1E4EB',
//   textColor: '#FFFFFF',
//   text: '다음',
//   action: undefined,
//   small: false,
// };
