/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import { useSetRecoilState, useRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../recoil/common/header/atom';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import selfCheckAtom from '../../recoil/common/selfCheck/atom';
import { useSearchParams } from 'react-router-dom';
import CircularProgressingAnxy from '../../stories/page/CircularProgressingAnxy';

export default function SelfCheckAnalysis(props) {
  const { types } = props;
  const [searchParams] = useSearchParams();
  const GAD = searchParams.get('GAD-7') === 'true';
  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const [selfCheckState, setSelfCheckState] = useRecoilState(selfCheckAtom);

  const type = getMaxScoreId();
  const goResult = useNavigateWithParams(`/anxyTest/result${!GAD ? `?type=${type}` : ''}`, true);

  const [anxyTypeSubmitSubmitResult, anxyTypeSubmit] = useFetchAnxy({
    url: `/anxy/anxiety-type/submit`,
    requestBody: JSON.stringify({ anxietyTypeId: type }),
    bg: '#FFFFFF',
  });
  const [selfCheckSubmitResult, selfCheckSubmit] = useFetchAnxy({
    url: `/anxy/self-check/submit`,
    requestBody: JSON.stringify({ selfCheckId: 2, selfCheckQcIdList: selfCheckState.selfCheckAnswer }),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (selfCheckSubmitResult) {
      setSelfCheckState((state) => ({
        ...state,
        selfCheckResult: {
          ...selfCheckSubmitResult.result,
          resultHistoryList: selfCheckSubmitResult.result.resultHistoryList.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          }),
        },
      }));
    }
  }, [selfCheckSubmitResult]);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_NONE, headerTitle: '' });
    document.title = `anxy 자가검사 로딩`;
    setProgressBarState({ isProgressBarVisible: false, maxCompleted: 0, completed: 0 });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
  }, []);

  useEffect(() => {
    if (!GAD) {
      if (type) {
        anxyTypeSubmit();
      }
    } else {
      selfCheckSubmit();
    }
  }, [type]);

  function getMaxScoreId() {
    const sortedList = types
      .map((type) => getEachTypeScoreWithId(type.id))
      .sort(function (a, b) {
        return b.score - a.score || a.id - b.id;
      });

    return sortedList[0].id;
  }

  function getEachType(id) {
    return selfCheckState.selfCheckAnswer.filter((element) =>
      types.find((type) => type.id === id).qcIdList.includes(element.selfCheckQuestionId),
    );
  }
  function getEachTypeScoreWithId(id) {
    return { id: id, score: getEachTypeScore(id) };
  }
  function getEachTypeScore(id) {
    return arraySum(getEachType(id).map((element) => element.score));
  }

  function arraySum(arr) {
    return arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
  }

  const isDone = () => {
    goResult();
  };

  return <CircularProgressingAnxy steps={['답변 분석 중', '테스트 결과 구성 중']} isDone={isDone} color={'#EF670B'} />;
}
