import React, { useState, useEffect, useRef } from 'react';
import 'moment/locale/ko';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import notificationAtom from '../../recoil/somny/notification/atom';
import sleepRecordAtom from '../../recoil/somny/sleepRecord/atom';

const getTimeDiff = (startTime, endTime) => {
  return moment.duration(moment(endTime).diff(startTime));
};

export default function TimePickerInMinute({
  propsName,
  startTime,
  endTime,
  selectedTime,
  changeSelectedTime,
  prevTime,
}) {
  const repeatNum = 50;
  const [minuteIdx, setMinuteIdx] = useState(0);
  const numberHeight = 63;

  const minutePickerRef = useRef();
  const [sleepingRecord, setSleepingRecord] = useRecoilState(sleepRecordAtom);

  // 몇 분 차이남?
  const timeDiff = getTimeDiff(startTime, endTime);
  const minuteDiffDivByTen = timeDiff.asMinutes() / 10;
  const wholeStickNumber = minuteDiffDivByTen + 1;

  const timeArray = Array.from({ length: wholeStickNumber }, (v, i) => i * 10);
  timeArray.push('');
  timeArray.push('');

  useEffect(() => {
    if (minutePickerRef.current) {
      minutePickerRef.current.scrollTop = numberHeight * (minuteIdx - 1);
    }
  }, []);

  const isoFormat = 'YYYY-MM-DDTHH:mm:ss';

  useEffect(() => {
    const changedMinute = timeArray[minuteIdx - 1];
    const newTime = moment(startTime).add({ minutes: changedMinute }).format(isoFormat);
    changeSelectedTime(newTime);

    if (changedMinute) {
      setSleepingRecord((state) => {
        const updated = { ...state };
        updated[propsName] = changedMinute;
        return updated;
      });
    }
  }, [minuteIdx]);

  return (
    <div className="px-[20px]">
      <div
        className={`text-white w-full h-[189px] text-center flex justify-center gap-[32px] relative mx-auto`}
        height={repeatNum * numberHeight}
      >
        <div className="rounded-[12px] absolute top-[0px] w-full px-[20px] h-[63px] bg-[#E1E7FF] opacity-[0.2] z-[10] pointer-events-none" />
        <div className=" absolute top-[63px] w-screen px-[20px] h-[63px] bg-[#11113c] opacity-[0.9] z-[10] pointer-events-none" />
        <div className=" absolute top-[126px]  w-screen px-[20px] h-[63px] bg-[#11113c] opacity-[0.9] z-[10] pointer-events-none" />{' '}
        <div
          className="w-full h-full overflow-auto snap-y snap-proximity relative ml-[1px] scrollbar-hide"
          ref={minutePickerRef}
          onScroll={(e) => {
            setMinuteIdx(parseInt((e.target.scrollTop + numberHeight / 2) / numberHeight) + 1);
          }}
        >
          {timeArray.map((minute, index) => (
            <div
              key={`min${index}`}
              style={{}}
              className="mx-auto flex justify-center items-center h-[63px] text-[30px] leading-[40px] font-bold py-[5px] snap-center snap-always	"
            >
              {minute !== '' &&
                `${
                  minute / 60 >= 1 && minute % 60 !== 0
                    ? `${parseInt(minute / 60)}시간 ${minute % 60}분`
                    : minute / 60 >= 1
                    ? `${parseInt(minute / 60)}시간`
                    : `${minute}분`
                }`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
