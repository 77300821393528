import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import webviewToast from '../../development/webviewToast';
import modalAtom from '../../recoil/somny/modal/atom';
import { requestAppState } from '../../utils/webview';
import useFetchAnxy from '../useFetchAnxy';
import useMixpanelEventSomny from '../useMixpanelEventSomny';

export default function usePostNotification(timeList, action) {
  const [saveNotification, setSaveNotification] = useFetchAnxy({
    url: '/somny/notification/enable',
  });

  const setMixPanel = useMixpanelEventSomny({
    eventName: '알림 설정 완료',
  });

  const [isAlarmSet, setIsAlarmSet] = useState(false);
  const setModalState = useSetRecoilState(modalAtom);

  useEffect(() => {
    if (saveNotification) {
      webviewToast('알림 받을래');
      if (action) {
        setMixPanel();
        action();
      }
    }
  }, [saveNotification]);

  const getAlarmPermission = () => {
    const eventFromApp = async (event) => {
      setIsAlarmSet(true);
      webviewToast('alarm message: ' + JSON.stringify(event.detail));
      // 권한이 없을 때
      if (!isAlarmSet) {
        if (!event.detail.isEnabled) {
          webviewToast('권한 없음');
          setModalState({ isModalVisible: true, modalType: 'ALLOW_NOTIFICATION' });
        } else {
          webviewToast('권한 있음');
          // 권한 있을 때
          if (timeList) {
            setSaveNotification();
          }
        }
      }
    };

    window.addEventListener('notificationPermission', eventFromApp);

    return () => {
      window.removeEventListener('notificationPermission', eventFromApp);
    };
  };

  const fetchData = useCallback(() => {
    requestAppState('notification');
    getAlarmPermission();
    if (window.location.hostname === 'localhost') {
      setSaveNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeList]);

  return fetchData;
}
