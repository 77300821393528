import { useEffect } from 'react';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import ContainerByDeviceAnxy from '../../../components/elements/ContainerByDeviceAnxy';
import { NotificationCard } from '../../../components/somny/time/NotificationCard';
import webviewToast from '../../../development/webviewToast';
import useCheckNotificationState from '../../../hooks/useCheckNotificationState';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import notificationAtom from '../../../recoil/somny/notification/atom';
import { goToSetting } from '../../../utils/webview';

export function SomnyNotificationSetting() {
  const [notificationState, setNotificationState] = useRecoilState(notificationAtom);
  const [selected, setSelected] = useState();
  const [saveNotification, setSaveNotification] = useFetchAnxy({
    url: '/somny/routine-time/save',
    requestBody: notificationState,
  });

  const [enableNotification, setEnableNotification] = useFetchAnxy({
    url: '/somny/notification/enable',
  });

  const { isNotificationAllow } = useCheckNotificationState();

  //   const isNotificationAllow = true;

  useEffect(() => {
    if (saveNotification) {
      webviewToast('알림 설정: ' + JSON.stringify(notificationState));
    }
  }, [saveNotification]);

  useEffect(() => {
    if (isNotificationAllow) {
      setEnableNotification();
    }
  }, [isNotificationAllow]);

  return (
    <ContainerByDeviceAnxy bgColor="#050C26">
      <div className="px-[20px] pt-[24px] flex flex-col gap-[12px]">
        {!isNotificationAllow && (
          <div className="relative rounded-[20px] bg-[#E1E7FF] p-[20px] flex justify-between items-center">
            <p className="text-[17px] leading-[23px] text-[#050C26] font-bold">먼저 설정에서 알림을 허용해주세요</p>
            <div
              className="flex items-center justify-center right-[0px] absolute w-[60px] h-[60px]"
              onClick={() => goToSetting()}
            >
              <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.5 12.5L7.5 7.5L2.5 2.5"
                  stroke="#26282C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        )}
        <div
          className="flex flex-col gap-[12px]"
          tabIndex="1"
          onBlur={() => {
            if (isNotificationAllow) {
              setSaveNotification();
              setSelected();
            }
          }}
          style={{
            opacity: isNotificationAllow ? 1 : 0.6,
          }}
        >
          <NotificationCard
            id={1}
            propsName="sleepTime"
            staticMinute={notificationState.sleepTime.split(':')[1].toString()}
            isSelected={selected === 1}
            setSelected={setSelected}
            title="자기 전 동화 듣기"
            isLock={!isNotificationAllow}
          />
          <NotificationCard
            id={2}
            propsName="wakeUpTime"
            isSelected={selected === 2}
            setSelected={setSelected}
            title="기상 후 수면 기록"
            isLock={!isNotificationAllow}
          />
        </div>
      </div>
    </ContainerByDeviceAnxy>
  );
}
