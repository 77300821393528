import course0 from '../image/course0.png';
import course1 from '../image/course1.png';
import course2 from '../image/course2.png';
import course3 from '../image/course3.png';
import course4 from '../image/course4.png';
import course5 from '../image/course5.png';
import course101 from '../image/course101.png';
import course102 from '../image/course102.png';
import course103 from '../image/course103.png';
import course104 from '../image/course104.png';
import course105 from '../image/course105.png';
import course106 from '../image/course106.png';
import course107 from '../image/course107.png';
import course108 from '../image/course108.png';
import course109 from '../image/course109.png';
import course110 from '../image/course110.png';
import course111 from '../image/course111.png';
import course112 from '../image/course112.png';
import course113 from '../image/course113.png';

const courseData = [
  //course
  { courseId: 'course-0', image: course0, bg: '#1355FF' }, // 중요한 건 내 마음
  { courseId: 'course-1', image: course1, bg: '#7655FA' }, // 감정을 만드는 생각 찾기
  { courseId: 'course-2', image: course2, bg: '#4A3CED' }, // 생각의 발자취 따라가기
  { courseId: 'course-3', image: course3, bg: '#463995' }, // 내 감정 돌아보기
  { courseId: 'course-4', image: course4, bg: '#FF5A13' }, // 부정적인 감정 다루기
  { courseId: 'course-5', image: course5, bg: '#182D58' }, // 불면증, 잃어버린 밤을 찾아서
  { courseId: 'course-101', image: course101, bg: '#1B438E' }, // 우울을 이해하고 극복하기
  { courseId: 'course-102', image: course102, bg: '#FCB809' }, // 음식과 나
  { courseId: 'course-103', image: course103, bg: '#0F81A5' }, // 일상 생활 속 불안함 물리치기
  { courseId: 'course-104', image: course104, bg: '#4D41D7' }, // 공황장애 조절하기
  { courseId: 'course-105', image: course105, bg: '#2F3CB1' }, // 사람들 사이에서 불안 극복하기
  { courseId: 'course-106', image: course106, bg: '#3EB2B2' }, // 만성 통증을 마음으로 다스리기
  // { courseId: 'course-107', image: course107, bg: '#415376' }, // 더 깊게 숙면하기
  { courseId: 'course-108', image: course108, bg: '#363947' }, // 운동과 정신건강의 상관관계
  { courseId: 'course-109', image: course109, bg: '#3687D2' }, // 마음챙김의 세상으로
  { courseId: 'course-110', image: course110, bg: '#51AAC6' }, // 자존감, 나를 받아들이는 법
  { courseId: 'course-111', image: course111, bg: '#7289C6' }, // 스트레스에서 벗어나기
  { courseId: 'course-112', image: course112, bg: '#6554CA' }, // 반복되는 생각 멈추기
  { courseId: 'course-113', image: course113, bg: '#F07F4F' }, // 내 감정 이해하기
];

export default courseData;
