import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import { motion } from 'framer-motion';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import { PlaceholderInput } from '../PlaceholderInput';
import { SkipButton } from '../../elements/SkipButton';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import useFetch from '../../../hooks/useFetch';

function WorrySingleInput({ num, sum, title, placeholder, isSkip, isFirstPage }) {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);

  const [loaded, setLoaded] = useState(false);
  const [stopFocus, setStopFocus] = useState(false);
  const [forceBlur, setForceBlur] = useState(false);
  const [titleHeight, setTitleHeight] = useState(0);
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [buttonText, setButtonText] = useState('다음');
  const android = /(android)/i.test(navigator.userAgent);
  const isAndroid = android && isFirstPage ? true : false;

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  const [worrySolutionInfo, getWorrySolutionInfo] = useFetch({
    url: '/sleep/worry-solution/load',
  });

  const [text, setText] = useState('');
  const titleRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (context.workbookAnswer.length !== 0) {
      if (num === 0) {
        setText(context.workbookAnswer[0]);
      } else {
        setText(context.workbookAnswer[1]);
      }
    }
  }, [context]);

  console.log(text);

  useEffect(() => {
    if (worrySolutionInfo) {
      if (worrySolutionInfo.result) {
        const result = worrySolutionInfo.result;
        const updated = [...context.workbookAnswer];
        updated[1] = result.worry;
        updated[0] = result.solution;
        context.setWorkbookAnswer(updated);
      }
    }
  }, [worrySolutionInfo]);

  console.log(context.workbookAnswer);

  const setCursorOnFirst = () => {
    if (inputRef.current) {
      const end = inputRef.current.value.length;
      inputRef.current.setSelectionRange(end, end);
      inputRef.current.focus();
    }
  };

  const handleResize = useCallback(() => {
    console.log('resize', window.visualViewport.height, window.innerHeight);
    // window.scrollTo(0, 0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    if (window.visualViewport.height < viewportSize) {
      setKeyboardOpen(true);
    } else {
      setKeyboardOpen(false);
    }
    // webviewToast('viewport: ' + window.visualViewport.height + ' ' + window.innerHeight);

    setViewportSize(window.visualViewport.height);
  }, [viewportSize]);

  useEffect(() => {
    setLoaded(true);
    setButtonState('INACTIVE');
    if (isSkip) {
      setButtonText('건너뛰기');
    }
    setKeyboardOpen(true);
    setCursorOnFirst();
    if (context.workbookAnswer.length === 0) {
      getWorrySolutionInfo();
    }

    if (titleRef.current) {
      setTitleHeight(titleRef.current.clientHeight);
      console.log(titleRef.current.clientHeight);
    }
    window.visualViewport.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goToNext = () => {
    setStopFocus(true);
    if (buttonText !== '건너뛰기') {
      let updated = [];
      if (context.workbookAnswer) {
        // 배열 초기화
        updated = context.workbookAnswer;
      } else {
        updated = Array.from({ length: sum }, () => '');
      }
      updated[num] = text;
      context.setWorkbookAnswer(updated);
    }
    setTimeout(() => {
      goNext();
    }, 400);
  };

  useEffect(() => {
    if (text === undefined || text === '') {
      setButtonState('INACTIVE');
      if (isSkip) {
        setButtonText('건너뛰기');
      }
    } else {
      setButtonState('ACTIVE');
      setButtonText('다음');
    }
  }, [text]);

  function updateValue(value) {
    setText(value);
    const updated = [...context.workbookAnswer];
    if (num === 0) {
      updated[0] = value;
    } else {
      updated[1] = value;
    }
    context.setWorkbookAnswer(updated);
  }
  console.log('workbook', context.workbookAnswer);

  const inputRef = useRef();

  return (
    <motion.div
      ref={containerRef}
      animate={{
        height: `${viewportSize - 17}px`,
      }}
      style={{
        width: '100%',
        height: '100%',
      }}
      transition={{ ease: 'circOut', duration: loaded ? 0.15 : 0 }}
      className={`select-none flex flex-col justify-between`}
    >
      <div className="h-full w-full px-[20px] justify-between pt-[50px]" style={{ overflowY: 'scroll' }}>
        <div
          ref={titleRef}
          className="font-bold text-[24px] leading-[28.8px] text-[#26282C]"
          style={{ wordBreak: 'keep-all' }}
        >
          {title}
        </div>

        <PlaceholderInput
          placeholder={placeholder}
          value={text}
          maxLength={'10'}
          stopFocus={stopFocus}
          forceBlur={forceBlur}
          autofocus={true}
          updateValue={updateValue}
          buttonState={buttonState}
          num={num}
          sum={sum}
          isAndroid={isAndroid}
          viewportSize={viewportSize}
          titleHeight={titleHeight}
          keyboardOpen={isKeyboardOpen}
          containerRef={containerRef}
        />
      </div>
      <div className="w-full relative bottom-0 ">
        <div
          className="w-full h-[42px] bg-black absolute top-[-40px]"
          style={{
            background: 'linear-gradient(to top, #FFFFFF, rgba(255,255,255,0))',
          }}
        />
        <div className="px-[20px] bg-white ">
          {isSkip ? (
            <SkipButton state={buttonState} text={buttonText} action={goToNext} />
          ) : (
            <SecondaryActionButton state={buttonState} text={buttonText} action={goToNext} />
          )}
        </div>
        <div
          className="w-full bg-white"
          style={{
            pointerEvents: 'none',
            height: !isKeyboardOpen && context.biggerMargin ? `34px` : `20px`,
          }}
        ></div>
      </div>
    </motion.div>
  );
}
export default WorrySingleInput;
