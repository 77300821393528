/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../stories/page/BottomActionAnxy';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from '../../recoil/common/header/atom';
import anxyWebAboutCourse0 from '../../image/anxy/anxyWeb/anxyWebAboutCourse0.png';
import anxyWebPreview0 from '../../image/anxy/anxyWeb/anxyWebPreview0.png';
import anxyWebPreview2 from '../../image/anxy/anxyWeb/anxyWebPreview2.png';
import anxyWebPreview3 from '../../image/anxy/anxyWeb/anxyWebPreview3.png';
import useMixpanelEventAnxy from '../../hooks/useMixpanelEventAnxy';

function Guide({ title, subtitle, noGradient, content, buttonText, action }) {
  const [searchParams] = useSearchParams();
  const at = searchParams.get('at');
  return (
    <BottomActionAnxy
      content={
        <div className="h-full flex flex-col px-[20px]">
          <div className="pt-[12px] text-[24px] leading-[30px] font-bold">{title}</div>
          {subtitle && <div className="pt-[12px] text-[17px] leading-[28px] font-light">{subtitle}</div>}
          <div className={`flex-1  relative`}>{content}</div>
        </div>
      }
      noGradient={noGradient}
      buttonText={buttonText}
      action={action}
      bgColor={'#ffffff'}
      fixedRoot={at === undefined}
    />
  );
}

export default function AnxyWebPreview(props) {
  let { id } = useParams();
  id = parseInt(id);
  const [searchParams] = useSearchParams();
  const at = searchParams.get('at');
  const setHeaderState = useSetRecoilState(headerAtom);

  const goNext = useNavigateWithParams(`/anxyWebPreview/${id + 1}`);
  const goAboutCourse = useNavigateWithParams(`/anxyWebAboutCourse/1`);

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '코스 설명',
  });

  useEffect(() => {
    if (id <= 1) {
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_NONE,
        progressBar: undefined,
      }));
      setMixPanel();
    } else {
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_BACK,
        progressBar: { maxCompleted: 17, completed: id - 1 },
      }));
    }
    const root = document.getElementById('root');
    if (!at) {
      root.style.position = 'fixed';
      root.style.overflow = 'hidden';
    }
  }, []);

  function CenterImage({ image }) {
    return (
      <img
        src={image}
        alt={'empty'}
        className="w-full h-full max-w-[375px] py-[20px] object-contain absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]"
      />
    );
  }

  return id === 0 ? (
    <Guide
      title={'Anxy는 불안의 3요소를 공략하며 불안을 다스리는 앱이에요'}
      noGradient
      content={
        <img
          src={anxyWebPreview0}
          alt={'empty'}
          className="w-full h-full absolute bottom-0 left-[50%] translate-x-[-50%] max-w-[375px] px-[20px] pt-[40px] object-contain object-bottom"
        />
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 1 ? (
    <Guide
      title={'불안에는 세 가지 요소가 있어요'}
      subtitle={
        '호흡이 가빠진다면 ‘신체’, 무언가 나쁜 일이 일어날 것처럼 여겨진다면 ‘생각’, 불안함에 자꾸 무언가를 재차 확인한다면 ‘행동’이라고 할 수 있죠.'
      }
      noGradient
      content={<CenterImage image={anxyWebAboutCourse0} />}
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 2 ? (
    <Guide
      title={'서로 맞물리며 불안함을 더욱 키워요'}
      subtitle={'이 중 한 가지 때문에 불안한 게 아니에요. 3요소가 서로 영향을 주며 불안함을 점점 가중시켜요.'}
      noGradient
      content={<CenterImage image={anxyWebPreview2} />}
      buttonText={'다음'}
      action={goNext}
    />
  ) : (
    <Guide
      title={'순환고리를 끊는 게 핵심이랍니다'}
      subtitle={'각 요소를 하나씩 체계적으로 공략해 순환고리가 만들어지지 않도록 할 거예요.'}
      noGradient
      content={<CenterImage image={anxyWebPreview3} />}
      buttonText={'다음'}
      action={goAboutCourse}
    />
  );
}
