/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

export default function LockText(props) {
  const { text } = props;

  return (
    <div className=" flex items-center text-[15px] leading-[21px] text-[#80838B] ">
      <div className="mr-[7px]">
        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.38999 6.61019H8.47473V4.23728C8.47473 2.83317 7.33647 1.69491 5.93236 1.69491C4.52825 1.69491 3.38999 2.83317 3.38999 4.23728V6.61019ZM1.69508 6.65743C1.45481 6.68883 1.26287 6.74112 1.09202 6.82817C0.715695 7.01992 0.409734 7.32588 0.217987 7.70221C0 8.13003 0 8.69008 0 9.81019V11.8C0 12.9201 0 13.4802 0.217987 13.908C0.409734 14.2843 0.715695 14.5903 1.09202 14.782C1.51984 15 2.0799 15 3.2 15H8.66438C9.78449 15 10.3445 15 10.7724 14.782C11.1487 14.5903 11.4546 14.2843 11.6464 13.908C11.8644 13.4802 11.8644 12.9201 11.8644 11.8V9.81019C11.8644 8.69008 11.8644 8.13003 11.6464 7.70221C11.4546 7.32588 11.1487 7.01992 10.7724 6.82817C10.6016 6.74116 10.4098 6.68888 10.1696 6.65747V4.23728C10.1696 1.8971 8.27254 0 5.93236 0C3.59217 0 1.69508 1.8971 1.69508 4.23728V6.65743Z"
            fill="#26282C"
          />
        </svg>
      </div>
      <div className="whitespace-nowrap">{text}</div>
    </div>
  );
}
