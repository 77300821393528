import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import betterMeSessionList from '../../../data/betterMe/betterMeSessionList';
import useNavigateSession from '../../../hooks/betterme/useNavigateSession';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import deviceAtom from '../../../recoil/common/device';
import somnyAtom from '../../../recoil/somny/atom';
import { BlackRoundActionButton } from '../../../stories/button/RoundActionButton';

export default function SessionTemplate({ content, action }) {
  const { page } = useParams();
  const { image, bgColor, timeout } = content;
  const isEnd = betterMeSessionList.length - 1 === parseInt(page);
  const deviceState = useRecoilValue(deviceAtom);
  const [somnyState, setSomnyState] = useRecoilState(somnyAtom);

  const goNext = useNavigateWithParams(`/betterMeSession/${parseInt(page) + 1}`);
  const goHome = useNavigateWithParams(`/betterMeHome`);

  const handleEnd = () => {
    if (isEnd) {
      const nowDate = new Date().toISOString();
      if (typeof somnyState.completedAt === 'undefined') {
        setSomnyState((state) => ({ ...state, completedAt: nowDate }));
      }
      goHome();
    } else {
      goNext();
    }
  };

  return (
    <div
      className="w-screen h-screen"
      style={{
        backgroundColor: bgColor,
      }}
    >
      {image && (
        <img className="w-full h-full object-cover min-h-[710px] max-w-[420px] m-auto" src={image} alt="empty" />
      )}
      <div
        className="w-full absolute z-[50] px-[20px]"
        style={{
          bottom: deviceState.hasHomeIndicator ? '34px' : '20px',
        }}
      >
        <BlackRoundActionButton state={'ACTIVE'} text={isEnd ? '마치기' : '다음'} action={handleEnd} />
      </div>
    </div>
  );
}
