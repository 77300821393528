/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoomyQA from './LoomyQA';
import LoomyEnd from './LoomyEnd';
import LoomyResultLoading from './LoomyResultLoading';
import LoomyResult from './LoomyResult';
import LoomyAnalysis from './LoomyAnalysis';
import headerAtom from '../../../recoil/common/header/atom';
import { useSetRecoilState } from 'recoil';

export default function LoomyTest() {
  let { id } = useParams();

  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerColor: '#26282C' }));
  }, []);

  const choices = [
    { selfCheckChoiceId: 1, score: 0, choice: '전혀' },
    { selfCheckChoiceId: 2, score: 1, choice: '며칠간' },
    { selfCheckChoiceId: 3, score: 2, choice: '7일 이상' },
    { selfCheckChoiceId: 4, score: 3, choice: '거의 매일' },
  ];

  const types = [
    { id: 1, name: '행동', qcIdList: [4, 10, 13, 15, 25] },
    { id: 2, name: '생각', qcIdList: [1, 7, 17, 20, 24] },
    { id: 3, name: '감정', qcIdList: [3, 5, 11, 16, 23] },
    { id: 4, name: '신체', qcIdList: [2, 6, 9, 18, 21] },
    { id: 5, name: '상황', qcIdList: [8, 12, 14, 19, 22] },
  ];

  const typeData = [
    {
      id: 1,
      name: '행동',
      image: '🛌',
      title: '활동의 감소',
      subtitle: '줄어든 행동 반경이 우울에 영향을 미치고 있어요',
      reason: {
        title: '이전에 비해 즐거운 활동이 줄었어요',
        text: '기분이 저하되면 사람들은 대체로 즐거움을 주는 활동을 먼저 그만두는 경향이 있어요. 에너지 부족을 일종의 위기로 여기고 취미생활이나 여가와 같은 일들을 줄여 나가거든요.\n\n즐거운 활동과 스트레스 활동을 병행하다, 즐거운 일이 줄어들면 스트레스 요인만 남게 돼요. 그렇게 스트레스만 남은 생활이 지속되면 우울해질 수밖에 없어요.',
      },
      keypoint: {
        title: '행동이 기분을 결정해요',
        text: '대부분의 사람들은 기분이 나아지고 의욕이 생기면 일을 시작할 수 있다고 생각해요. 하지만 일을 시작해야 기분이 나아지고 의욕이 생긴답니다. 우리가 행동을 먼저 해야하는 이유예요.',
      },
      solution: {
        title: '무활동 상태에서 벗어나는 방법',
        text: '무활동을 활동으로 돌려야 해요. 무활동을 끊는 것은 우울감에서 벗어나는 핵심일 뿐만 아니라 풍요로운 삶을 향유하는 지름길이 돼요. Loomy와 함께 새로운 일상을 경험해봐요.',
      },
    },
    {
      id: 2,
      name: '생각',
      image: '🚨',
      title: '부정적 생각',
      subtitle: '부정적인 생각 방식이 우울에 영향을 미치고 있어요',
      reason: {
        title: '세 종류의 부정적 생각이 우울을 만들어내고 있어요',
        text: '나 자신 ‘나는 부족해’, ‘나는 별로야’, ‘나는 매력적이지 않아’와 같이 좌절이나 실패 상황에서 스스로를 부적절하고 무능하다고 생각하며, 이를 영구적인 것으로 치부해요.\n\n나의 세계 ‘사람들은 실수에 냉정해’, ‘세상은 무서운 곳이야’, ‘사람들은 내 결점을 들춰 내’와 같은 생각들이예요. 사람들은 생각보다 비판적이지 않음에도 내가 그렇게 생각하고 있어요.\n\n미래 은연 중에 ‘앞으로도 발전이 없을 거야’, ‘아무런 희망이 없어’, ‘상황은 늘 똑같을 거야’와 같이 미래를 단정하는 생각들을 해요. 미래는 누구도 알 수 없고, 스스로 바꿀 수 있는데 말이죠.',
      },
      keypoint: {
        title: '이유 모를 우울감을 느낀 적 있나요?',
        text: '나조차도 인지하지 못할 정도로 부정적인 생각이 빠르게 지나가고 감정만 덜렁 남았기 때문이예요. 무의식적으로 스쳐가는 생각은 하루에 오만가지나 된답니다.',
      },
      solution: {
        title: '부정적인 생각을 바꾸는 방법',
        text: '무의식적인 생각은 알아내기 쉽지 않아요. 하지만 우울한 감정을 만들어내는 원인이니 꼭 포착해내야 한답니다. Loomy가 보다 쉬운 방법을 알려드릴게요. 생각 패턴을 바꾸는 연습도 함께 해요.',
      },
    },
    {
      id: 3,
      name: '감정',
      image: '🚥',
      title: '감정 회피',
      subtitle: '감정 회피가 우울에 영향을 미치고 있어요',
      reason: {
        title: '불쾌함을 느끼기 꺼려하고 있어요',
        text: '본능적으로 부정적인 감정을 회피하고 있어요. 계속해서 감정을 회피하면 그 순간은 불쾌한 느낌을 느끼지 않겠지만 계속 마음 안에 남아 부작용을 일으키게 된답니다. 일시적인 불쾌감이 지속적인 고통과 괴로움으로 변하거든요.\n\n이런 불쾌감을 알아차리지 못하면 직간접적으로 나의 태도와 판단에 영향을 미치고, 이는 우울감으로 굳어지죠.',
      },
      keypoint: {
        title: '기분 대신 감정을 살펴 볼거예요',
        text: '기분은 복합적이기 때문에 왜 이런 기분인지 파악하기 어려워요. 그에 비해 감정은 특정한 사건 때문에 발생했기 때문에 비교적 명확하답니다. 대신 빠르게 사라지기 때문에 제때 기록하는 것이 중요해요.',
      },
      solution: {
        title: '감정 회피에서 벗어나는 방법',
        text: '순간 생겨난 감정을 불편하더라도 마주해야 해요. 불쾌한 상황마다 가볍게 적어 기록을 쌓아주세요. 그리고 내 감정 기저에 있는 공통적인 원인을 찾기 위해 적절한 질문을 던질 거예요. 감정을 스스로 다루고 싶다면 Loomy를 만나보세요.',
      },
    },
    {
      id: 4,
      name: '신체',
      image: '☁',
      title: '낮은 신체 에너지',
      subtitle: '낮은 신체 에너지가 우울에 영향을 미치고 있어요',
      reason: {
        title: '계속되는 스트레스에 신체가 절전 모드에 돌입했어요',
        text: '스트레스 상황에서 우리 몸은 스트레스에 대응하기 위해 긴장 상태에 돌입합니다. 그러나 계속해서 스트레스 상황에 노출되면 장기전에 들어가기 위해 에너지 절전 모드로 전환시켜 버리죠.\n\n에너지 절전모드 상태에서는 소화기능이 떨어져 자주 체하거나 속이 더부룩해요. 뇌에선 정보처리속도가 떨어져 집중력이 떨어지고 생각이 느려지죠. 수면의 질이 좋지 않고, 팔다리에 납덩이가 들어있는 것처럼 몸이 무겁기도 합니다.',
      },
      keypoint: {
        title: '의지부족이 아니예요',
        text: '의지가 부족한 게 아니라 실제로 신체의 에너지가 부족한 거예요. 에너지가 부족할 때는 활동량이 계속해서 감소하고, 하고 싶은 일은 물론 해야 하는 일도 하지 못하게 돼요. 할 일을 못해서 자책하게 되면 더욱 우울해지고, 우울의 악순환에 쉽게 빠지게 됩니다.',
      },
      solution: {
        title: '절전 모드에서 벗어나는 방법',
        text: '우리 몸은 감정과 긴밀히 상호작용하면서 순간순간 우리의 상태를 알려줘요. 하지만 바쁜 일상을 사는 우리는 몸의 신호를 캐치해내기 어렵죠. 신호를 무시하는 경우도 생기구요. Loomy가 몸의 신호를 알아채고 절전 모드에서 벗어날 수 있도록 도울게요.',
      },
    },
    {
      id: 5,
      name: '상황',
      image: '🌊',
      title: '바람 잘 날 없는 생활',
      subtitle: '크고작은 상황이 우울에 영향을 미치고 있어요',
      reason: {
        title: '스트레스 받는 상황이 지속되고 있네요',
        text: '우울함은 누구에게나 찾아오는 마음의 감기라고 하죠. 일시적인, 혹은 오래 지속되고 있는 외부 상황 때문에 우울한 듯 보여요.\n\n살면서 스트레스를 한번도 받지 않는 사람은 없어요. 하지만 이를 어떻게 다루는지가 그 사람의 기분과 인생을 결정하죠. 우리는 방치하지 말기로 해요.',
      },
      keypoint: {
        title: '통제할 수 있는 것에 집중해요',
        text: '내가 통제할 수 없는 것은 그대로 두고, 내가 통제할 수 있는 것에 집중해요. 가장 가까운 예로, 상황은 내가 변화시키기 어려워도 그 상황을 받아들이는 나는 변화시킬 수 있지요.',
      },
      solution: {
        title: '스트레스 상황 슬기롭게 대처하기',
        text: '생활 속 스트레스 상황을 점검하고, 내가 특히 어떤 카테고리에 취약한지 발견할 거예요. 내 기준 크고 작은 문제를 분류하고 이에 대처하는 힘도 길러요. 작심삼일이 되지 않도록 Loomy가 도울게요.',
      },
    },
  ];
  const qcList = [
    {
      selfCheckQuestionId: 1,
      question: '스스로가 실패자처럼 느껴진다.',
    },
    {
      selfCheckQuestionId: 2,
      question: '몸이 여기저기 이유 없이 아프거나 힘이 없다.',
    },
    {
      selfCheckQuestionId: 3,
      question: '죄책감이 든다.',
    },
    {
      selfCheckQuestionId: 4,
      question: '일을 시작하기가 어렵다.',
    },
    {
      selfCheckQuestionId: 5,
      question: '작은 일에도 짜증이 나거나 쉽게 좌절한다.',
    },
    {
      selfCheckQuestionId: 6,
      question: '쉽게 잠이 오지 않거나 잠에 들더라도 자주 깬다.',
    },
    {
      selfCheckQuestionId: 7,
      question: '자살이나 스스로를 해치는 생각을 한다.',
    },
    {
      selfCheckQuestionId: 8,
      question: '감당할 수 없을 같은 상황에 처해 있다.',
    },
    {
      selfCheckQuestionId: 9,
      question: '집중이 되지 않고 멍한 느낌이 든다.',
    },
    {
      selfCheckQuestionId: 10,
      question: '해야 할 일이 있는데도 미루게 된다.',
    },
    {
      selfCheckQuestionId: 11,
      question: '이유 없이 눈물이 난다.',
    },
    {
      selfCheckQuestionId: 12,
      question: '내가 처한 상황에서 빠져나올 수 없다.',
    },
    {
      selfCheckQuestionId: 13,
      question: '사람을 만나는 일을 회피했다.',
    },
    {
      selfCheckQuestionId: 14,
      question: '가족이나 친구, 또는 연인 관계에서 스트레스가 심하다.',
    },
    {
      selfCheckQuestionId: 15,
      question: '아무 것도 하지 않은 채 생각에 잠겨 있는 시간이 많다.',
    },
    {
      selfCheckQuestionId: 16,
      question: '슬프고 우울하다.',
    },
    {
      selfCheckQuestionId: 17,
      question: '현재 상태가 나아지지 않을 것이라 생각한다.',
    },
    {
      selfCheckQuestionId: 18,
      question: '소화가 잘 되지 않아 체하거나 메스꺼움을 느낀다.',
    },
    {
      selfCheckQuestionId: 19,
      question: '최근 너무 많은 일들을 처리하느라 소진되었다.',
    },
    {
      selfCheckQuestionId: 20,
      question: '다른 사람과 나를 비교하며 스스로를 깎아내린다.',
    },
    {
      selfCheckQuestionId: 21,
      question: '식욕이 없거나 이유 없이 체중이 빠진다.',
    },
    {
      selfCheckQuestionId: 22,
      question: '원치 않는 일을 하고 있다.',
    },
    {
      selfCheckQuestionId: 23,
      question: '아무 것에도 흥미나 관심이 가지 않고 무엇을 해도 즐겁지 않다.',
    },
    {
      selfCheckQuestionId: 24,
      question: '실수한 일을 곱씹는다.',
    },
    {
      selfCheckQuestionId: 25,
      question: '도전적인 일이 부담스러워 계획을 포기한다.',
    },
  ];

  return (
    <div className={`w-screen h-full`}>
      {parseInt(id) > 0 && !isNaN(parseInt(id)) && <LoomyQA qcList={qcList} choices={choices} />}
      {id === 'end' && <LoomyEnd />}
      {id === 'loading' && <LoomyResultLoading types={types} />}
      {id === 'result' && <LoomyResult typeData={typeData} />}
      {id.includes('analysis') && <LoomyAnalysis typeData={typeData} />}
    </div>
  );
}
