/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import EndProcessing from '../../components/selfCheck/EndProcessing';
import { AuthContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import webviewToast from '../../development/webviewToast';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function OwlAnalysis(props) {
  const navigate = useNavigate();
  const { types } = props;
  const context = useContext(AuthContext);

  const setMixpanel = useMixpanelWeb({ eventName: '랜딩페이지 도달', projectName: 'somny' });

  useEffect(() => {
    document.title = `somny 로딩`;
    console.log(context.qcAnswerList);
    context.setShowProgressingbar(false);
    context.setMaxCompleted(0);
    context.setCompleted(0);
  }, []);

  function getMaxScoreId() {
    const sortedList = types
      .map((type) => getEachTypeScoreWithId(type.id))
      .sort(function (a, b) {
        return b.score - a.score || a.id - b.id;
      });
    console.log(sortedList);
    return sortedList[0].id;
  }

  function getEachType(id) {
    return context.qcAnswerList.filter((element) => types.find((type) => type.id === id).qcIdList.includes(element.id));
  }
  function getEachTypeScoreWithId(id) {
    return { id: id, score: getEachTypeScore(id) };
  }
  function getEachTypeScore(id) {
    return arraySum(getEachType(id).map((element) => element.score));
  }

  function arraySum(arr) {
    return arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
  }

  function isDone() {
    setMixpanel();
    const maxScoreId = getMaxScoreId();
    webviewToast(maxScoreId);
    if (maxScoreId === 1) {
      window.location.href = 'https://orwellhealth.io/somny-sleephabit';
    } else if (maxScoreId === 2) {
      window.location.href = 'https://orwellhealth.io/somny-night-activity';
    } else if (maxScoreId === 3) {
      window.location.href = 'https://orwellhealth.io/somny-emotion';
    } else if (maxScoreId === 4) {
      window.location.href = 'https://orwellhealth.io/somny-day-activity';
    }
  }

  return (
    <div
      className={`w-screen`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <EndProcessing steps={['검진 답변 분석 중', '결과지 구성 중']} isDone={isDone} />
    </div>
  );
}
