import { useLocation } from 'react-router-dom';
import * as AppSchemes from '../utils/appSchemes';
import useIsMountedRef from '../hooks/useIsMountedRef';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function CompanyAuth() {
  const location = useLocation();
  const isMountedRef = useIsMountedRef();
  const params = new URLSearchParams(location.search);
  const authId = params.get('auth_id');

  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = /(android)/i.test(navigator.userAgent);
  const isiOSMobile = /(iPhone|iPod|iPad)/i.test(navigator.platform);
  const isiPad = ua.indexOf('macintosh') > -1 && navigator.maxTouchPoints > 2;

  const isAOSMobile = isAndroid;
  const isIOSMobile = isiOSMobile || isiPad;

  useEffect(() => {
    if (isMountedRef.current) {
      if (isAOSMobile || isIOSMobile) {
        window.location.href = AppSchemes.BASE + 'main/companyAuth?auth_id=' + authId;
      } else {
        window.confirm('모바일에서 진행해주세요');
      }
    }
  }, [authId, isAOSMobile, isIOSMobile, isMountedRef, location]);

  return (
    <>
      <Helmet>
        <title>기업 회원 인증</title>
      </Helmet>
      <div className="w-screen py-5 h-100px bg-grey-500" />
    </>
  );
}

export default CompanyAuth;
