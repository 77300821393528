import session1_0 from '../../data/anxy/videos/session1_0.mp4';
import session1_1 from '../../data/anxy/videos/session1_1.mp4';
import type1_1 from '../../data/anxy/videos/intro/type1_1.mp4';
import type1_2 from '../../data/anxy/videos/intro/type1_2.mp4';
import type2_1 from '../../data/anxy/videos/intro/type2_1.mp4';
import type2_2 from '../../data/anxy/videos/intro/type2_2.mp4';
import type3_1 from '../../data/anxy/videos/intro/type3_1.mp4';
import type3_2 from '../../data/anxy/videos/intro/type3_2.mp4';
import type4_1 from '../../data/anxy/videos/intro/type4_1.mp4';
import type4_2 from '../../data/anxy/videos/intro/type4_2.mp4';
import type5_1 from '../../data/anxy/videos/intro/type5_1.mp4';
import type5_2 from '../../data/anxy/videos/intro/type5_2.mp4';
import type6_1 from '../../data/anxy/videos/intro/type6_1.mp4';
import type6_2 from '../../data/anxy/videos/intro/type6_2.mp4';
import 완벽추구자_원인 from '../../data/anxy/videos/intro/완벽추구자_원인.mp4';
import 재앙적사상가_원인 from '../../data/anxy/videos/intro/재앙적사상가_원인.mp4';
import 부담감내자_원인 from '../../data/anxy/videos/intro/부담감내자_원인.mp4';
import 감정회피자_원인 from '../../data/anxy/videos/intro/감정회피자_원인.mp4';
import 자기의심가_원인 from '../../data/anxy/videos/intro/자기의심가_원인.mp4';
import 불확실성공포자_원인 from '../../data/anxy/videos/intro/불확실성공포자_원인.mp4';
import 불확실성공포자_극복방향 from '../../data/anxy/videos/intro/불확실성공포자_극복방향.mp4';
import 완벽추구자_극복방향 from '../../data/anxy/videos/intro/완벽추구자_극복방향.mp4';
import 재앙적사상가_극복방향 from '../../data/anxy/videos/intro/재앙적사상가_극복방향.mp4';
import 부담감내자_극복방향 from '../../data/anxy/videos/intro/부담감내자_극복방향.mp4';
import 감정회피자_극복방향 from '../../data/anxy/videos/intro/감정회피자_극복방향.mp4';
import 자기의심가_극복방향 from '../../data/anxy/videos/intro/자기의심가_극복방향.mp4';

import type1 from '../../image/anxy/intro/type1.png';
import type2 from '../../image/anxy/intro/type2.png';
import type3 from '../../image/anxy/intro/type3.png';
import type4 from '../../image/anxy/intro/type4.png';
import type5 from '../../image/anxy/intro/type5.png';
import type6 from '../../image/anxy/intro/type6.png';

import green_phone from '../../image/anxy/intro/green_bottom_phone.png';
import orange_phone from '../../image/anxy/intro/orange_bottom_phone.png';
import yellow_phone from '../../image/anxy/intro/yellow_bottom_phone.png';

import phone1_1_0 from '../../image/anxy/intro/type1/phone1_1_0.png';
import phone1_2_0 from '../../image/anxy/intro/type2/phone1_2_0.png';
import phone1_3_0 from '../../image/anxy/intro/type3/phone1_3_0.png';
import phone1_4_0 from '../../image/anxy/intro/type4/phone1_4_0.png';
import phone1_5_0 from '../../image/anxy/intro/type5/phone1_5_0.png';
import phone1_6_0 from '../../image/anxy/intro/type6/phone1_6_0.png';

import phone2_1_0 from '../../image/anxy/intro/type1/phone2_1_0.png';
import phone2_1_1 from '../../image/anxy/intro/type1/phone2_1_1.png';
import phone2_1_2 from '../../image/anxy/intro/type1/phone2_1_2.png';
import phone2_1_3 from '../../image/anxy/intro/type1/phone2_1_3.png';
import phone2_1_4 from '../../image/anxy/intro/type1/phone2_1_4.png';
import phone2_1_5 from '../../image/anxy/intro/type1/phone2_1_5.png';
import phone2_1_6 from '../../image/anxy/intro/type1/phone2_1_6.png';
import phone2_1_7 from '../../image/anxy/intro/type1/phone2_1_7.png';

import end1 from '../../image/anxy/intro/type1/end1.png';
import end2 from '../../image/anxy/intro/type1/end2.png';
import end3 from '../../image/anxy/intro/type1/end3.png';

import trial2_1_5 from '../../image/anxy/intro/type1/trial2_1_5.png';
import trial2_1_6 from '../../image/anxy/intro/type1/trial2_1_6.png';

import lottie1_1 from '../../image/anxy/intro/type1/lottie1_1.png';
import lottie1_2 from '../../image/anxy/intro/type1/lottie1_2.png';
import lottie2_1 from '../../image/anxy/intro/type2/lottie2_1.png';
import lottie2_2 from '../../image/anxy/intro/type2/lottie2_2.png';
import lottie3_1 from '../../image/anxy/intro/type3/lottie3_1.png';
import lottie3_2 from '../../image/anxy/intro/type3/lottie3_2.png';
import lottie4_1 from '../../image/anxy/intro/type4/lottie4_1.png';
import lottie4_2 from '../../image/anxy/intro/type4/lottie4_2.png';
import lottie5_1 from '../../image/anxy/intro/type5/lottie5_1.png';
import lottie5_2 from '../../image/anxy/intro/type5/lottie5_2.png';
import lottie6_1 from '../../image/anxy/intro/type6/lottie6_1.png';
import lottie6_2 from '../../image/anxy/intro/type6/lottie6_2.png';

import trial4_1 from '../../image/anxy/intro/trial4_1.png';
import trial4_2 from '../../image/anxy/intro/trial4_2.png';

import trial1_0_1 from '../../image/anxy/intro/trial1_0_1.png';
import trial2_1 from '../../image/anxy/intro/trial2_1.png';

import trial3_1 from '../../image/anxy/intro/trial3_1.png';

import phone3_common_2 from '../../image/anxy/intro/phone3_common_2.png';

import phone3_1_0 from '../../image/anxy/intro/type1/phone3_1_0.png';
import phone3_1_1 from '../../image/anxy/intro/type1/phone3_1_1.png';
import phone3_1_3 from '../../image/anxy/intro/type1/phone3_1_3.png';
import phone3_1_4 from '../../image/anxy/intro/type1/phone3_1_4.png';
import phone3_1_5 from '../../image/anxy/intro/type1/phone3_1_5.png';
import phone3_1_6 from '../../image/anxy/intro/type1/phone3_1_6.png';

import phone2_2_0 from '../../image/anxy/intro/type2/phone2_2_0.png';
import phone2_2_1 from '../../image/anxy/intro/type2/phone2_2_1.png';
import phone2_2_2 from '../../image/anxy/intro/type2/phone2_2_2.png';
import phone2_2_3 from '../../image/anxy/intro/type2/phone2_2_3.png';
import phone2_2_4 from '../../image/anxy/intro/type2/phone2_2_4.png';
import phone2_2_5 from '../../image/anxy/intro/type2/phone2_2_5.png';
import phone2_2_6 from '../../image/anxy/intro/type2/phone2_2_6.png';
import phone2_2_7 from '../../image/anxy/intro/type2/phone2_2_7.png';
import phone2_2_8 from '../../image/anxy/intro/type2/phone2_2_8.png';
import phone2_2_9 from '../../image/anxy/intro/type2/phone2_2_9.png';

import trial2_2_1 from '../../image/anxy/intro/type2/trial2_2_1.png';
import trial2_2_2 from '../../image/anxy/intro/type2/trial2_2_2.png';

import phone3_2_0 from '../../image/anxy/intro/type2/phone3_2_0.png';
import phone3_2_1 from '../../image/anxy/intro/type2/phone3_2_1.png';
import phone3_2_3 from '../../image/anxy/intro/type2/phone3_2_3.png';
import phone3_2_4 from '../../image/anxy/intro/type2/phone3_2_4.png';
import phone3_2_5 from '../../image/anxy/intro/type2/phone3_2_5.png';
import phone3_2_6 from '../../image/anxy/intro/type2/phone3_2_6.png';

import phone2_3_0 from '../../image/anxy/intro/type3/phone2_3_0.png';
import phone2_3_1 from '../../image/anxy/intro/type3/phone2_3_1.png';
import phone2_3_2 from '../../image/anxy/intro/type3/phone2_3_2.png';
import phone2_3_3 from '../../image/anxy/intro/type3/phone2_3_3.png';
import phone2_3_4 from '../../image/anxy/intro/type3/phone2_3_4.png';
import phone2_3_5 from '../../image/anxy/intro/type3/phone2_3_5.png';
import phone2_3_6 from '../../image/anxy/intro/type3/phone2_3_6.png';
import phone2_3_7 from '../../image/anxy/intro/type3/phone2_3_7.png';
import phone2_3_8 from '../../image/anxy/intro/type3/phone2_3_8.png';
import phone2_3_9 from '../../image/anxy/intro/type3/phone2_3_9.png';
import trial2_3_5 from '../../image/anxy/intro/type3/trial2_3_5.png';
import trial2_3_6 from '../../image/anxy/intro/type3/trial2_3_6.png';
import trial3_3_0 from '../../image/anxy/intro/type3/trial3_3_0.png';

import phone3_3_0 from '../../image/anxy/intro/type3/phone3_3_0.png';
import phone3_3_1 from '../../image/anxy/intro/type3/phone3_3_1.png';
import phone3_3_3 from '../../image/anxy/intro/type3/phone3_3_3.png';
import phone3_3_4 from '../../image/anxy/intro/type3/phone3_3_4.png';
import phone3_3_5 from '../../image/anxy/intro/type3/phone3_3_5.png';
import phone3_3_6 from '../../image/anxy/intro/type3/phone3_3_6.png';

import phone2_4_0 from '../../image/anxy/intro/type4/phone2_4_0.png';
import phone2_4_1 from '../../image/anxy/intro/type4/phone2_4_1.png';
import phone2_4_2 from '../../image/anxy/intro/type4/phone2_4_2.png';
import phone2_4_3 from '../../image/anxy/intro/type4/phone2_4_3.png';
import phone2_4_4 from '../../image/anxy/intro/type4/phone2_4_4.png';
import phone2_4_5 from '../../image/anxy/intro/type4/phone2_4_5.png';
import phone2_4_6 from '../../image/anxy/intro/type4/phone2_4_6.png';
import phone2_4_7 from '../../image/anxy/intro/type4/phone2_4_7.png';

import trial2_4_1 from '../../image/anxy/intro/type4/trial2_4_1.png';
import trial2_4_2 from '../../image/anxy/intro/type4/trial2_4_2.png';
import trial2_4_5 from '../../image/anxy/intro/type4/trial2_4_5.png';
import trial2_4_6 from '../../image/anxy/intro/type4/trial2_4_6.png';
import trial3_4_0 from '../../image/anxy/intro/type4/trial3_4_0.png';

import phone3_4_0 from '../../image/anxy/intro/type4/phone3_4_0.png';
import phone3_4_1 from '../../image/anxy/intro/type4/phone3_4_1.png';
import phone3_4_3 from '../../image/anxy/intro/type4/phone3_4_3.png';
import phone3_4_4 from '../../image/anxy/intro/type4/phone3_4_4.png';
import phone3_4_5 from '../../image/anxy/intro/type4/phone3_4_5.png';
import phone3_4_6 from '../../image/anxy/intro/type4/phone3_4_6.png';

import phone2_5_0 from '../../image/anxy/intro/type5/phone2_5_0.png';
import phone2_5_1 from '../../image/anxy/intro/type5/phone2_5_1.png';
import phone2_5_2 from '../../image/anxy/intro/type5/phone2_5_2.png';
import phone2_5_3 from '../../image/anxy/intro/type5/phone2_5_3.png';
import phone2_5_4 from '../../image/anxy/intro/type5/phone2_5_4.png';
import phone2_5_5 from '../../image/anxy/intro/type5/phone2_5_5.png';
import phone2_5_6 from '../../image/anxy/intro/type5/phone2_5_6.png';
import phone2_5_7 from '../../image/anxy/intro/type5/phone2_5_7.png';
import trial2_5_5 from '../../image/anxy/intro/type5/trial2_5_5.png';
import trial2_5_6 from '../../image/anxy/intro/type5/trial2_5_6.png';
import trial3_5_0 from '../../image/anxy/intro/type5/trial3_5_0.png';

import phone3_5_0 from '../../image/anxy/intro/type5/phone3_5_0.png';
import phone3_5_1 from '../../image/anxy/intro/type5/phone3_5_1.png';
import phone3_5_3 from '../../image/anxy/intro/type5/phone3_5_3.png';
import phone3_5_4 from '../../image/anxy/intro/type5/phone3_5_4.png';
import phone3_5_5 from '../../image/anxy/intro/type5/phone3_5_5.png';
import phone3_5_6 from '../../image/anxy/intro/type5/phone3_5_6.png';

import phone2_6_0 from '../../image/anxy/intro/type6/phone2_6_0.png';
import phone2_6_1 from '../../image/anxy/intro/type6/phone2_6_1.png';
import phone2_6_2 from '../../image/anxy/intro/type6/phone2_6_2.png';
import phone2_6_3 from '../../image/anxy/intro/type6/phone2_6_3.png';
import phone2_6_4 from '../../image/anxy/intro/type6/phone2_6_4.png';
import phone2_6_5 from '../../image/anxy/intro/type6/phone2_6_5.png';
import phone2_6_6 from '../../image/anxy/intro/type6/phone2_6_6.png';
import phone2_6_7 from '../../image/anxy/intro/type6/phone2_6_7.png';
import trial2_6_5 from '../../image/anxy/intro/type6/trial2_6_5.png';
import trial2_6_6 from '../../image/anxy/intro/type6/trial2_6_6.png';
import trial3_6_0 from '../../image/anxy/intro/type6/trial3_6_0.png';

import phone3_6_0 from '../../image/anxy/intro/type6/phone3_6_0.png';
import phone3_6_1 from '../../image/anxy/intro/type6/phone3_6_1.png';
import phone3_6_3 from '../../image/anxy/intro/type6/phone3_6_3.png';
import phone3_6_4 from '../../image/anxy/intro/type6/phone3_6_4.png';
import phone3_6_5 from '../../image/anxy/intro/type6/phone3_6_5.png';
import phone3_6_6 from '../../image/anxy/intro/type6/phone3_6_6.png';

import trial_finish_1 from '../../image/anxy/intro/trial_finish_1.png';
import trial_finish_2 from '../../image/anxy/intro/trial_finish_2.png';

import { BodyLottie } from '../../stories/animation/BodyLottie';
import { DoneLottieInPhone } from '../../components/anxy/session/DoneLottieInPhone';
import { TimerLottie } from '../../stories/animation/TimerLottie';
import VideoPlayerByStatus from '../../components/anxy/common/VideoPlayerByStatus';
import IntroMonster from '../../components/anxy/session/IntroMonster';
import Intro0 from '../../pages/anxy/intro3/Intro0';
import Intro1 from '../../pages/anxy/intro3/Intro1';
import IntroLottieTemplate from '../../pages/anxy/intro/IntroLottieTemplate';

const commonFlow1 = [
  {
    replaceComponent: <VideoPlayerByStatus type trialIndex={0} isFirst isTrial />,
  },
  {
    replaceComponent: <Intro0 />,
  },
  {
    replaceComponent: <VideoPlayerByStatus type trialIndex={1} isTrial />,
  },
  {
    replaceComponent: <Intro1 />,
  },
];

const commonFlow2 = [
  {
    image: trial3_1,
    title: ['먼저 신체 파트에서는\n불안감을 느낄 때마다', '몸을 이완하여\n불안감을 즉각 낮추는', '훈련을 할 거예요'],
    imageCss: 'w-[120px] mx-auto',
  },
];

const commonFlow3 = [
  {
    imageLottie: <BodyLottie />,
    isFirst: true,
    timeout: 17 * 1000,
  },
  {
    image: end1,
    focusType: 'button',
    imageLottie: <DoneLottieInPhone />,
  },
  {
    title: [
      '이렇게 연습을 계속하다보면\n점점 이완에 걸리는 시간이 짧아지며',
      "결국엔 단번에 몸을 이완시키는\n'반사 이완'까지도 익힐 수 있게 돼요",
    ],
    imageLottie: <TimerLottie />,
    timeout: 25 * 1000,
  },
];

const commonFlow4 = [
  {
    image: trial4_2,
    title: ['두 번째로 생각 파트를 \n 어떻게 공략할 건지 알아봐요'],
    imageCss: 'w-[360px]',
  },
];

const commonFlow5 = [
  {
    title: ['이렇게 꾸준히\n반복해나가다보면', `불안한 상황에 익숙해지고\n통제감을 갖게 될 거예요`],
    component: <IntroMonster isBefore />,
  },
  {
    image: trial_finish_1,
    title: [`자, 3주간의 여정을 떠날\n모든 준비를 마쳤어요`],
    imageCss: 'max-w-auto w-[375px] mx-0',
    fixedTitleMarginBottom: true,
  },
  {
    image: trial_finish_2,
    title: ['3주 뒤에 우리는\n다음과 같이 변화한 자신을', '마주할 수 있을 거예요'],
    fixedTitleMarginBottom: true,
    slideUp: true,
  },
  { image: trial_finish_2, title: [`그럼 다음 세션에서 만나요`], fixedTitleMarginBottom: true },
];

const anxySessionByType = [
  {
    type: 1,
    eventProperty: 'Intro-1',
    title: '작은 가능성에 매달리지 않는 법',
    subtitle: '항상 걱정이 많고 긴장해 있는 불확실성 공포자는 어떻게 불안을 다스릴 수 있을까요?',
    image: type1,
    videoList: [불확실성공포자_원인, 불확실성공포자_극복방향],
    trialVideoList: [type1_1, type1_2],
    breath: [
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_1_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
    ],
    trap: [
      {
        image: green_phone,
        bottomImage: phone2_1_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '현실적인 가능성을\n판단하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_1_1, isFirst: true, focusType: 'button' }, //불안한 생각
      { image: phone2_1_2, focusType: 'possibility' }, //현실적으로 -
      { image: phone2_1_3, focusType: 'button' }, //현실적으로 -
      { image: phone2_1_4, focusType: 'input-1' }, //다른 모습으로 전개될 -
      { image: phone2_1_5, focusType: 'input-2' }, //다른 모습으로 전개될 -
      { image: phone2_1_6, focusType: 'input-3_2' }, //다른 모습으로 전개될 -
      { image: phone2_1_7, focusType: 'button' },
      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_1_5,
        title: [`이렇게 걱정에 대한\n가능성을 현실적으로 따져보며`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_1_6, title: [`합리적으로 사고하는\n힘을 기를 수 있어요`] },
    ],
    action: [
      {
        title: ['일어날 가능성이 적은 일에\n완벽히 대비하지 않아도', '괜찮다는 것을 경험하는\n훈련을 할 거예요'],
        image: orange_phone,
        bottomImage: phone3_1_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_1_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_1_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_1_4, focusType: 'input_progressbar-3_3' },
      { image: phone3_1_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_1_6, timeout: 2000 },

      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        title: ['이렇게 꾸준히\n반복해나가다보면', `불안한 상황에 익숙해지고\n통제감을 갖게 될 거예요`],
        component: <IntroMonster isBefore />,
      },
    ],
    trialList: [
      ...commonFlow1,
      ...commonFlow2,
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_1_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,

      ...commonFlow4,
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['불확실성 공포자는\n미래에 일어날 일을', '과대평가하며\n불안해하는데요']}
            image={lottie1_1}
            imageCss="px-[53px]"
          />
        ),
      },
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['현실적으로 \n 발생할 확률이 적은 일도', '일어날 것처럼 생각하며 \n 두려워하는 것이죠']}
            lottieImage={lottie1_2}
            imageCss="pl-[54px] pr-[53px] translate-y-[calc(-50%-40px)]"
          />
        ),
      },
      {
        image: green_phone,
        bottomImage: phone2_1_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '현실적인 가능성을\n판단하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_1_1, isFirst: true, focusType: 'button' }, //불안한 생각
      { image: phone2_1_2, focusType: 'possibility' }, //현실적으로 -
      { image: phone2_1_3, focusType: 'button' }, //현실적으로 -
      { image: phone2_1_4, focusType: 'input-1' }, //다른 모습으로 전개될 -
      { image: phone2_1_5, focusType: 'input-2' }, //다른 모습으로 전개될 -
      { image: phone2_1_6, focusType: 'input-3_2' }, //다른 모습으로 전개될 -
      { image: phone2_1_7, focusType: 'button' },
      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_1_5,
        title: [`이렇게 걱정에 대한\n가능성을 현실적으로 따져보며`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_1_6, title: [`합리적으로 사고하는\n힘을 기를 수 있어요`] },

      {
        title: '마지막으로 행동 파트에서는',
        image: trial2_1,
        imageCss: 'w-[120px] mx-auto',
      },
      {
        title: ['일어날 가능성이 적은 일에\n완벽히 대비하지 않아도', '괜찮다는 것을 경험하는\n훈련을 할 거예요'],
        image: orange_phone,
        bottomImage: phone3_1_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_1_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_1_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_1_4, focusType: 'input_progressbar-3_3' },
      { image: phone3_1_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_1_6, timeout: 2000 },

      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      ...commonFlow5,
    ],
  },
  {
    type: 2,
    eventProperty: 'Intro-2',
    title: '"조금만 더"로부터 벗어나는 법',
    subtitle: '항상 스스로 만족하지 못하고 지쳐버리는 완벽 추구자는 어떻게 불안을 다스릴 수 있을까요?',
    image: type2,
    videoList: [완벽추구자_원인, 완벽추구자_극복방향],
    trialVideoList: [type2_1, type2_2],
    breath: [
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_2_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
    ],
    trap: [
      {
        image: green_phone,
        bottomImage: phone2_2_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '현실적인 대처 방안을\n마련하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_2_1, isFirst: true, focusType: 'button' }, //불안한 생각
      { image: phone2_2_2, focusType: 'input-1' }, //어떤 부정적인-
      { image: phone2_2_3, focusType: 'input-2' }, //어떤 부정적인-
      { image: phone2_2_4, focusType: 'input-3_1' }, //어떤 부정적인-
      { image: phone2_2_5, focusType: 'button' }, //어떤 부정적인-
      { image: phone2_2_6, focusType: 'input-1' }, //일이 파국으로-
      { image: phone2_2_7, focusType: 'input-2' }, //일이 파국으로-
      { image: phone2_2_8, focusType: 'input-3_2' }, //일이 파국으로-
      { image: phone2_2_9, focusType: 'button' },
      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_2_1,
        title: [`이렇게 걱정에 대한\n대처 방안까지 마련하며`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_2_2, title: [`현실적으로 상황을 판단하는\n힘을 기를 수 있어요`] },
    ],
    action: [
      {
        title: ['완벽하지 않아도\n나쁜 일이 일어나지 않는다는 것을', '경험하는 훈련을 할 거예요'],
        image: orange_phone,
        bottomImage: phone3_2_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_2_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_2_3, focusType: 'input_progressbar-2_1' },
      { image: phone3_2_4, focusType: 'input_progressbar-3_1' },
      { image: phone3_2_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_2_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        title: ['이렇게 꾸준히\n반복해나가다보면', `불안한 상황에 익숙해지고\n통제감을 갖게 될 거예요`],
        component: <IntroMonster isBefore />,
      },
    ],
    trialList: [
      ...commonFlow1,
      ...commonFlow2,
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_2_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
      ...commonFlow4,
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['완벽 추구자는\n일을 완벽하게 해내지 못하면', '부정적인 일이 일어날 거라 여기며\n불안해하는데요']}
            image={lottie2_1}
            isBottom
            imageCss="p-0"
          />
        ),
      },
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['무언가 실수를 하면\n사람들이 자신을', '형편없게 본다고 생각하며\n두려워하는 것이죠']}
            lottieImage={lottie2_2}
          />
        ),
      },
      {
        image: green_phone,
        bottomImage: phone2_2_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '현실적인 대처 방안을\n마련하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_2_1, isFirst: true, focusType: 'button' }, //불안한 생각
      { image: phone2_2_2, focusType: 'input-1' }, //어떤 부정적인-
      { image: phone2_2_3, focusType: 'input-2' }, //어떤 부정적인-
      { image: phone2_2_4, focusType: 'input-3_1' }, //어떤 부정적인-
      { image: phone2_2_5, focusType: 'button' }, //어떤 부정적인-
      { image: phone2_2_6, focusType: 'input-1' }, //일이 파국으로-
      { image: phone2_2_7, focusType: 'input-2' }, //일이 파국으로-
      { image: phone2_2_8, focusType: 'input-3_2' }, //일이 파국으로-
      { image: phone2_2_9, focusType: 'button' },
      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_2_1,
        title: [`이렇게 걱정에 대한\n대처 방안까지 마련하며`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_2_2, title: [`현실적으로 상황을 판단하는\n힘을 기를 수 있어요`] },

      {
        title: '마지막으로 행동 파트에서는',
        image: trial2_1,
        imageCss: 'w-[120px] mx-auto',
      },
      {
        title: ['완벽하지 않아도\n나쁜 일이 일어나지 않는다는 것을', '경험하는 훈련을 할 거예요'],
        image: orange_phone,
        bottomImage: phone3_2_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_2_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_2_3, focusType: 'input_progressbar-2_1' },
      { image: phone3_2_4, focusType: 'input_progressbar-3_1' },
      { image: phone3_2_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_2_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      ...commonFlow5,
    ],
  },
  {
    type: 3,
    eventProperty: 'Intro-3',
    title: '작은 일에도 크게 반응하지 않는 법',
    subtitle: '항상 최악을 떠올리며 걱정하는 재앙적 사상가는 어떻게 불안을 다스릴 수 있을까요?',
    image: type3,
    videoList: [재앙적사상가_원인, 재앙적사상가_극복방향],
    trialVideoList: [type3_1, type3_2],
    breath: [
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_3_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
    ],
    trap: [
      {
        image: green_phone,
        bottomImage: phone2_3_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '현실적인 대처 방안을\n마련하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_3_1, isFirst: true, focusType: 'button' },
      { image: phone2_3_2, focusType: 'input-1' },
      { image: phone2_3_3, focusType: 'input-2' },
      { image: phone2_3_4, focusType: 'input-3_2' },
      { image: phone2_3_5, focusType: 'button' },
      { image: phone2_3_6, focusType: 'input-1' },
      { image: phone2_3_7, focusType: 'input-2' },
      { image: phone2_3_8, focusType: 'input-3_1' },
      { image: phone2_3_9, focusType: 'button' },
      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_2_1,
        title: [`이렇게 걱정에 대한\n대처 방안까지 마련하며`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_2_2, title: [`현실적으로 상황을 판단하는\n힘을 기를 수 있어요`] },
    ],
    action: [
      {
        title: ['설사 일이 파국으로 치닫는다 해도\n충분히 조치할 수 있다는 것을', '경험하는 훈련을 할 거예요'],
        image: orange_phone,
        bottomImage: phone3_3_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_3_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_3_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_3_4, focusType: 'input_progressbar-3_3' },
      { image: phone3_3_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_3_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        title: ['이렇게 꾸준히\n반복해나가다보면', `불안한 상황에 익숙해지고\n통제감을 갖게 될 거예요`],
        component: <IntroMonster isBefore />,
      },
    ],
    trialList: [
      ...commonFlow1,
      ...commonFlow2,
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_3_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
      ...commonFlow4,
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['재앙적 사상가는\n미래에 대한 다양한 가능성 중', '최악의 상황에만 집중하며\n불안해하는데요']}
            image={lottie3_1}
            imageCss="px-[11px]"
          />
        ),
      },
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['다양한 모습으로\n전개될 수 있는 일임에도', '최악의 경우만 생각하며\n두려워하는 것이죠']}
            lottieImage={lottie3_2}
            imageCss="pl-[17px] pr-[6px]"
          />
        ),
      },
      {
        image: green_phone,
        bottomImage: phone2_3_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '현실적인 대처 방안을\n마련하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_3_1, isFirst: true, focusType: 'button' },
      { image: phone2_3_2, focusType: 'input-1' },
      { image: phone2_3_3, focusType: 'input-2' },
      { image: phone2_3_4, focusType: 'input-3_2' },
      { image: phone2_3_5, focusType: 'button' },
      { image: phone2_3_6, focusType: 'input-1' },
      { image: phone2_3_7, focusType: 'input-2' },
      { image: phone2_3_8, focusType: 'input-3_1' },
      { image: phone2_3_9, focusType: 'button' },
      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_2_1,
        title: [`이렇게 걱정에 대한\n대처 방안까지 마련하며`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_2_2, title: [`현실적으로 상황을 판단하는\n힘을 기를 수 있어요`] },
      {
        title: '마지막으로 행동 파트에서는',
        image: trial2_1,
        imageCss: 'w-[120px] mx-auto',
      },
      {
        title: ['설사 일이 파국으로 치닫는다 해도\n충분히 조치할 수 있다는 것을', '경험하는 훈련을 할 거예요'],
        image: orange_phone,
        bottomImage: phone3_3_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_3_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_3_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_3_4, focusType: 'input_progressbar-3_3' },
      { image: phone3_3_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_3_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      ...commonFlow5,
    ],
  },
  {
    type: 4,
    eventProperty: 'Intro-4',
    title: '"내가 해야만 해"로부터 벗어나는 법',
    subtitle: '항상 모든 것을 견디다 결국엔 무너져버리는 부담 감내자는 어떻게 불안을 다스릴 수 있을까요?',
    image: type4,
    videoList: [부담감내자_원인, 부담감내자_극복방향],
    trialVideoList: [type4_1, type4_2],
    breath: [
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_4_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
    ],
    trap: [
      {
        image: green_phone,
        bottomImage: phone2_4_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '자신이 가진 왜곡된 믿음을\n찾는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_4_1, isFirst: true, focusType: 'button' },
      { image: phone2_4_2, focusType: 'input_arrow-1_1' },
      { image: phone2_4_3, focusType: 'input_arrow-2_1' },
      { image: phone2_4_4, focusType: 'input_arrow-3' },

      { image: phone2_4_5, focusType: 'button' },
      { image: phone2_4_6, focusType: 'input-1' },
      { image: phone2_4_7, focusType: 'button' },

      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_4_1,
        title: [`이렇게 찾은 왜곡된 믿음을 \n 합리적인 믿음으로`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_4_2, title: [`변화시키는 훈련을 하며\n건강한 사고를 가질 수 있어요`] },
    ],
    action: [
      {
        title: [
          '모든 일을 꼭 내가 다 하지 않고\n남에게 분담해도',
          '나쁜 일이 발생하지 않는다는 것을\n경험하는 훈련을 할 거예요',
        ],
        image: orange_phone,
        bottomImage: phone3_4_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_4_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_4_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_4_4, focusType: 'input_progressbar-3_3' },
      { image: phone3_4_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_4_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        title: ['이렇게 꾸준히\n반복해나가다보면', `불안한 상황에 익숙해지고\n통제감을 갖게 될 거예요`],
        component: <IntroMonster isBefore />,
      },
    ],
    trialList: [
      ...commonFlow1,
      ...commonFlow2,
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_4_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
      ...commonFlow4,

      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['부담 감내자는 ”내가 해야만 해”라는', '강박적 부담을 갖으며 불안해하는데요']}
            image={lottie4_1}
            isBottom
          />
        ),
      },
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={[
              '아무도 그러라고 하지 않았는데도\n자신이 해야만 한다는 생각에',
              '사로잡혀 스스로 불안감을\n떠안게 되는 것이죠',
            ]}
            lottieImage={lottie4_2}
            imageCss="px-[10px]"
          />
        ),
      },
      {
        image: green_phone,
        bottomImage: phone2_4_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '자신이 가진 왜곡된 믿음을\n찾는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_4_1, isFirst: true, focusType: 'button' },
      { image: phone2_4_2, focusType: 'input_arrow-1_1' },
      { image: phone2_4_3, focusType: 'input_arrow-2_1' },
      { image: phone2_4_4, focusType: 'input_arrow-3' },

      { image: phone2_4_5, focusType: 'button' },
      { image: phone2_4_6, focusType: 'input-1' },
      { image: phone2_4_7, focusType: 'button' },

      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_4_1,
        title: [`이렇게 찾은 왜곡된 믿음을 \n 합리적인 믿음으로`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_4_2, title: [`변화시키는 훈련을 하며\n건강한 사고를 가질 수 있어요`] },
      {
        title: '마지막으로 행동 파트에서는',
        image: trial2_1,
        imageCss: 'w-[120px] mx-auto',
      },
      {
        title: [
          '모든 일을 꼭 내가 다 하지 않고\n남에게 분담해도',
          '나쁜 일이 발생하지 않는다는 것을\n경험하는 훈련을 할 거예요',
        ],
        image: orange_phone,
        bottomImage: phone3_4_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_4_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_4_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_4_4, focusType: 'input_progressbar-3_3' },
      { image: phone3_4_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_4_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      ...commonFlow5,
    ],
  },
  {
    type: 5,
    eventProperty: 'Intro-5',
    title: '내 감정을 마주하고 이해하는 법',
    subtitle: '항상 감정을 외면하고 쌓아두며 자신을 괴롭히는 감정 회피자는 어떻게 불안을 다스릴 수 있을까요?',
    image: type5,
    videoList: [감정회피자_원인, 감정회피자_극복방향],
    trialVideoList: [type5_1, type5_2],
    breath: [
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_5_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
    ],
    trap: [
      {
        image: green_phone,
        bottomImage: phone2_5_0,
        title: ['부정적인 감정을 마주해도\n충분히 감당할 수 있다는 것을', '경험하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_5_1, isFirst: true, focusType: 'button' },
      { image: phone2_5_2, focusType: 'input_arrow-1_2' },
      { image: phone2_5_3, focusType: 'input_arrow-2_2' },
      { image: phone2_5_4, focusType: 'input_arrow-3' },

      { image: phone2_5_5, focusType: 'button' },
      { image: phone2_5_6, focusType: 'input-1' },
      { image: phone2_5_7, focusType: 'button' },

      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_4_1,
        title: [`이렇게 찾은 왜곡된 믿음을\n합리적인 믿음으로`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_4_2, title: [`변화시키는 훈련을 하며\n건강한 사고를 가질 수 있어요`] },
    ],
    action: [
      {
        title: ['부정적인 감정을 마주해도\n충분히 감당할 수 있다는 것을', '경험하는 훈련을 할 거예요'],
        image: orange_phone,
        bottomImage: phone3_5_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_5_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_5_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_5_4, focusType: 'input_progressbar-3_2' },
      { image: phone3_5_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_5_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        title: ['이렇게 꾸준히\n반복해나가다보면', `불안한 상황에 익숙해지고\n통제감을 갖게 될 거예요`],
        component: <IntroMonster isBefore />,
      },
    ],
    trialList: [
      ...commonFlow1,
      ...commonFlow2,
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_5_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
      ...commonFlow4,

      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['감정 회피자는\n부정적인 감정을', '객관적으로 마주하지 않으며\n불안해하는데요']}
            image={lottie5_1}
            isBottom
          />
        ),
      },
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['부정적인 감정을 마주하며\n감당해야 할 부담을 두려워하며', '감정 자체를\n외면해 버리는 것이죠']}
            lottieImage={lottie5_2}
            isBottom
          />
        ),
      },
      {
        image: green_phone,
        bottomImage: phone2_5_0,
        title: ['부정적인 감정을 마주해도\n충분히 감당할 수 있다는 것을', '경험하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_5_1, isFirst: true, focusType: 'button' },
      { image: phone2_5_2, focusType: 'input_arrow-1_2' },
      { image: phone2_5_3, focusType: 'input_arrow-2_2' },
      { image: phone2_5_4, focusType: 'input_arrow-3' },

      { image: phone2_5_5, focusType: 'button' },
      { image: phone2_5_6, focusType: 'input-1' },
      { image: phone2_5_7, focusType: 'button' },

      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_4_1,
        title: [`이렇게 찾은 왜곡된 믿음을\n합리적인 믿음으로`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_4_2, title: [`변화시키는 훈련을 하며\n건강한 사고를 가질 수 있어요`] },
      {
        title: '마지막으로 행동 파트에서는',
        image: trial2_1,
        imageCss: 'w-[120px] mx-auto',
      },
      {
        title: ['부정적인 감정을 마주해도\n충분히 감당할 수 있다는 것을', '경험하는 훈련을 할 거예요'],
        image: orange_phone,
        bottomImage: phone3_5_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_5_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_5_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_5_4, focusType: 'input_progressbar-3_2' },
      { image: phone3_5_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_5_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      ...commonFlow5,
    ],
  },

  {
    type: 6,
    eventProperty: 'Intro-6',
    title: '나 자신에게 확신을 갖는 법',
    subtitle: '항상 자신을 믿지 못하고 갈팡질팡하는 자기 의심가는 어떻게 불안을 다스릴 수 있을까요?',
    image: type6,
    videoList: [자기의심가_원인, 자기의심가_극복방향],
    trialVideoList: [type6_1, type6_2],
    breath: [
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_6_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
    ],
    trap: [
      {
        image: green_phone,
        bottomImage: phone2_6_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '현실적인 가능성을\n판단하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_6_1, isFirst: true, focusType: 'button' },
      { image: phone2_6_2, focusType: 'possibility' },
      { image: phone2_6_3, focusType: 'button' },
      { image: phone2_6_4, focusType: 'input-1' },
      { image: phone2_6_5, focusType: 'input-2' },
      { image: phone2_6_6, focusType: 'input-3_2' },
      { image: phone2_6_7, focusType: 'button' },
      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_1_5,
        title: [`이렇게 걱정에 대한\n가능성을 현실적으로 따져보며`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_1_6, title: [`합리적으로 사고하는\n힘을 기를 수 있어요`] },
    ],
    action: [
      {
        title: [
          '내 의견을 말하고\n내 생각대로 실천한다해도',
          '나쁜 일이 일어나지 않는다는 것을 \n 경험하는 훈련을 할 거예요',
        ],
        image: orange_phone,
        bottomImage: phone3_6_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_6_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_6_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_6_4, focusType: 'input_progressbar-3_3' },
      { image: phone3_6_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_6_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        title: ['이렇게 꾸준히\n반복해나가다보면', `불안한 상황에 익숙해지고\n통제감을 갖게 될 거예요`],
        component: <IntroMonster isBefore />,
      },
    ],
    trialList: [
      ...commonFlow1,
      ...commonFlow2,
      {
        title: ['머리부터 발 끝까지\n온몸 구석구석을', '긴장시켰다가 이완할 거예요', '연습 삼아 조금만 해볼까요?'],
        isBottom: true,
        image: yellow_phone,
        bottomImage: phone1_6_0,
        bottomCss: 'h-[calc(83%)]',
      },
      ...commonFlow3,
      ...commonFlow4,
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={['자기 의심가는\n”내 선택이 틀리면 어쩌지?”', '라고 생각하며\n불안해하는데요']}
            image={lottie6_1}
            isBottom
            imageCss="pl-[11px] pr-[1px] "
          />
        ),
      },
      {
        replaceComponent: (
          <IntroLottieTemplate
            title={[
              '심지어는 내 선택으로\n다른 사람에게까지 해를',
              '끼칠지도 모른다는 생각에\n선택하기를 두려워하는 것이죠',
            ]}
            lottieImage={lottie6_2}
            imageCss="px-[73px]"
          />
        ),
      },
      {
        image: green_phone,
        bottomImage: phone2_6_0,
        title: ['이런 불합리한 사고를\n스스로 알아차리고', '현실적인 가능성을\n판단하는 훈련을 할 거예요'],
        isBottom: true,
        bottomCss: 'h-[calc(59.8%)]',
      },
      { image: phone2_6_1, isFirst: true, focusType: 'button' },
      { image: phone2_6_2, focusType: 'possibility' },
      { image: phone2_6_3, focusType: 'button' },
      { image: phone2_6_4, focusType: 'input-1' },
      { image: phone2_6_5, focusType: 'input-2' },
      { image: phone2_6_6, focusType: 'input-3_2' },
      { image: phone2_6_7, focusType: 'button' },
      { image: end2, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      {
        image: trial2_1_5,
        title: [`이렇게 걱정에 대한\n가능성을 현실적으로 따져보며`],
        imageCss: 'max-w-[375px] p-0',
      },
      { image: trial2_1_6, title: [`합리적으로 사고하는\n힘을 기를 수 있어요`] },
      {
        title: '마지막으로 행동 파트에서는',
        image: trial2_1,
        imageCss: 'w-[120px] mx-auto',
      },
      {
        title: [
          '내 의견을 말하고\n내 생각대로 실천한다해도',
          '나쁜 일이 일어나지 않는다는 것을 \n 경험하는 훈련을 할 거예요',
        ],
        image: orange_phone,
        bottomImage: phone3_6_0,
        bottomCss: 'h-[calc(83%)]',
        isBottom: true,
      },
      { image: phone3_6_1, isFirst: true, focusType: 'button' },
      { image: phone3_common_2, focusType: 'input_progressbar-1' },
      { image: phone3_6_3, focusType: 'input_progressbar-2_2' },
      { image: phone3_6_4, focusType: 'input_progressbar-3_3' },
      { image: phone3_6_5, focusType: 'behavior_progressbar-1' },
      { image: phone3_6_6, timeout: 2000 },
      { image: end3, focusType: 'button', imageLottie: <DoneLottieInPhone /> },
      ...commonFlow5,
    ],
  },
];
export default anxySessionByType;
