import React from 'react';

const TitleTextList = ({ texts }) => {
  return (
    <div className="">
      {texts.map((text, index) => (
        <div className={`${index !== texts.length - 1 && 'mb-[25px]'}`}>
          <p className="text-[17px] leading-[23px] font-bold mb-[6px]">{text.title}</p>
          <p className="text-[14px] leading-[20px]">{text.info}</p>
        </div>
      ))}
    </div>
  );
};

export default TitleTextList;
