import { useContext } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { CoachingContext } from '../../coachingContext';
import { PressedEffect } from '../../stories/button/PressedEffect';
import { showWebView } from '../../utils/webview';

const SelfCheckItem = ({ selfCheckResult }) => {
  const context = useContext(CoachingContext);

  const goToSelfCheck = () => {
    showWebView({
      url: `selfCheck?at=${context.authToken}&app_version=${context.appVersion}&scroll_id=${selfCheckResult.selfCheckId}`,
      backgroundColor: '#F3F5F8',
    });
  };

  return (
    <PressedEffect
      element={
        <div className={` bg-[#F3F5F8] rounded-[20px] flex justify-between h-[92px] items-center  px-[20px]`}>
          {true ? (
            <>
              <div className="flex text-[15px] leading-[21px]">
                <span className="font-bold text-[16px] leading-[22px]">{`${selfCheckResult.selfCheckName} `}</span>
                <span
                  className="font-bold text-[16px] leading-[22px] ml-[4px] text-[#2C4BEC]"
                  style={{ color: selfCheckResult.isAbnormal ? '#EF3E3E' : '#2C4BEC' }}
                >
                  {selfCheckResult.diagnosisSummary}
                </span>
              </div>
              <div className="w-[40px] h-[40px]">
                <CircularProgressbar
                  styles={buildStyles({
                    rotation: -0.375,
                    pathColor: selfCheckResult.isAbnormal ? '#EF3E3E' : '#2C4BEC',
                    trailColor: 'rgba(0,0,0,0.05)',
                    strokeLinecap: 'round',
                  })}
                  strokeWidth={12}
                  circleRatio={0.75}
                  value={selfCheckResult.scorePercentage}
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col text-[15px] leading-[21px]">
                <p className="font-bold text-[16px] leading-[22px]">{selfCheckResult.title}</p>
                <p className="text-[#6B6D76] text-[15px] leading-[21px]">
                  지금까지 <span className="text-[#2C4BEC] font-bold">{selfCheckResult.num}명이</span> 검사
                </p>
              </div>
              <div
                className="text-[15px] leading-[18px] rounded-[10px] text-white h-[38px] w-[76px] flex items-center justify-center"
                style={{ background: '#2C4BEC', border: '1px solid rgba(0, 0, 0, 0.02)' }}
              >
                검사하기
              </div>
            </>
          )}
        </div>
      }
      action={goToSelfCheck}
    />
  );
};

export default SelfCheckItem;
