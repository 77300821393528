import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { TextArea } from '../../stories/text/TextArea';
import { Title } from '../../stories/text/Title';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { keyboardOpen } from '../../utils/webview';
import { CoachingContext } from '../../coachingContext';
import useFetch from '../../hooks/useFetch';

export default function CoachingLeave1() {
  const context = useContext(CoachingContext);
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);
  const [windowSize, setWindowSize] = useState(window.innerHeight);
  const [loaded, setLoaded] = useState(false);
  const [stopFocus, setStopFocus] = useState(false);
  const [forceBlur, setForceBlur] = useState(false);
  const [leaveReasonText, setLeaveReasonText] = useState('');
  const goNextPage = useNavigateWithParams('/coachingLeave/2');

  const [submitLeaveResult, submitLeave] = useFetch({
    url: `/coaching/leave-review/submit`,
    requestBody: JSON.stringify({
      leaveReasonChoiceList: context.leaveReasonChoiceList,
      leaveReasonText: leaveReasonText,
    }),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    setLoaded(true);
    keyboardOpen();

    window.visualViewport.addEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    console.log('resize', window.visualViewport.height, window.clientHeight);
    setViewportSize(window.visualViewport.height);
    setWindowSize(window.innerHeight);
    if (window.android) {
      if (window.innerHeight < windowSize) {
        setKeyboardOpen(true);
      } else {
        setKeyboardOpen(false);
      }
    } else {
      if (window.visualViewport.height < viewportSize) {
        setKeyboardOpen(true);
      } else {
        setKeyboardOpen(false);
      }
    }
  };

  useEffect(() => {
    console.log('stopfocus change: ', stopFocus);
    if (stopFocus) {
      setTimeout(() => {
        setForceBlur(true);
        console.log(
          JSON.stringify({
            leaveReasonChoiceList: context.leaveReasonChoiceList,
            leaveReasonText: leaveReasonText,
          }),
        );
        submitLeave();
        setButtonState('LOADING');
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopFocus]);

  useEffect(() => {
    if (submitLeaveResult) {
      goNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitLeaveResult]);

  function updateValue(value) {
    if (value && value.length > 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
    console.log(value);
    setLeaveReasonText(value);
  }

  function goNext() {
    setStopFocus(true);
  }

  return (
    <motion.div
      animate={{
        height: window.android ? `${windowSize}px` : `${viewportSize}px`,
        paddingBottom: !isKeyboardOpen && context.biggerMargin ? `34px` : `20px`,
      }}
      transition={{ ease: 'circOut', duration: loaded ? 0.15 : 0 }}
      className={`px-[20px] pt-[7px] flex flex-col justify-between`}
    >
      <div className="flex-1 flex flex-col">
        <div className="mb-[40px]">
          <Title title={'떠나는 이유를 알려주세요'} />
        </div>
        <div className="flex-1  pb-[40px]">
          <TextArea
            placeholder={'더 나은 인사이드가 되도록 노력할게요'}
            value={context.leaveReason}
            stopFocus={stopFocus}
            forceBlur={forceBlur}
            autofocus={true}
            updateValue={updateValue}
          />
        </div>
      </div>
      <PrimaryActionButton state={buttonState} text={'다음'} action={goNext} />
    </motion.div>
  );
}
