import activity0 from '../image/activity0.png';
import activity0_1 from '../image/activity0_1.png';
import activity0_2 from '../image/activity0_2.png';
import activity0_3 from '../image/activity0_3.png';
import activity0_5 from '../image/activity0_5.png';
import activity0_6 from '../image/activity0_6.png';
import activity0_7 from '../image/activity0_7.png';

import activity1 from '../image/activity1.png';
import activity1_1 from '../image/activity1_1.png';
import activity1_2 from '../image/activity1_2.png';
import activity1_3 from '../image/activity1_3.png';
import activity1_4 from '../image/activity1_4.png';
import activity1_5 from '../image/activity1_5.png';
import activity1_6 from '../image/activity1_6.png';
import activity1_7 from '../image/activity1_7.png';
import activity1_9 from '../image/activity1_9.png';
import activity1_10 from '../image/activity1_10.png';
import activity1_11 from '../image/activity1_11.png';
import activity1_12 from '../image/activity1_12.png';
import activity1_13 from '../image/activity1_13.png';
import activity1_14 from '../image/activity1_14.png';

import activity2 from '../image/activity2.png';
import activity2_1 from '../image/activity2_1.png';
import activity2_2 from '../image/activity2_2.png';
import activity2_3 from '../image/activity2_3.png';
import activity2_4 from '../image/activity2_4.png';
import activity2_5 from '../image/activity2_5.png';
import activity2_6 from '../image/activity2_6.png';

import activity3 from '../image/activity3.png';
import activity3_1 from '../image/activity3_1.png';
import activity3_2 from '../image/activity3_2.png';
import activity3_3 from '../image/activity3_3.png';
import activity3_4 from '../image/activity3_4.png';
import activity3_5 from '../image/activity3_5.png';
import activity3_6 from '../image/activity3_6.png';
import activity3_7 from '../image/activity3_7.png';
import activity3_8 from '../image/activity3_8.png';
import activity3_9 from '../image/activity3_9.png';
import activity3_10 from '../image/activity3_10.png';

import activity4 from '../image/activity4.png';
import activity4_1 from '../image/activity4_1.png';
import activity4_2 from '../image/activity4_2.png';
import activity4_4 from '../image/activity4_4.png';
import activity4_5 from '../image/activity4_5.png';
import activity4_6 from '../image/activity4_6.png';
import activity4_7 from '../image/activity4_7.png';
import activity4_8 from '../image/activity4_8.png';
import activity4_9 from '../image/activity4_9.png';
import activity4_10 from '../image/activity4_10.png';

import activity5 from '../image/activity5.png';
import activity5_1 from '../image/activity5_1.png';
import activity5_2 from '../image/activity5_2.png';
import activity5_3 from '../image/activity5_3.png';
import activity5_4 from '../image/activity5_4.png';
import activity5_5 from '../image/activity5_5.png';
import activity5_6 from '../image/activity5_6.png';
import activity5_7 from '../image/activity5_7.png';
import activity5_report from '../image/activity5_report.png';
import activity5_10 from '../image/activity5_10.png';
import activity5_11 from '../image/activity5_11.png';
import activity5_13 from '../image/activity5_13.png';
import activity5_14 from '../image/activity5_14.png';
import activity5_16 from '../image/activity5_16.png';
import activity5_18 from '../image/activity5_18.png';
import activity5_19 from '../image/activity5_19.png';
import activity5_21 from '../image/activity5_21.png';
import activity5_solution from '../image/activity5_solution.png';
import activity5_24 from '../image/activity5_24.png';
import activity5_26 from '../image/activity5_26.png';
import activity5_28 from '../image/activity5_28.png';
import activity5_30 from '../image/activity5_30.png';
import activity5_relaxation1 from '../image/activity5_relaxation1.png';
import activity5_relaxation2 from '../image/activity5_relaxation2.png';
import activity5_relaxation3 from '../image/activity5_relaxation3.png';
import activity5_imagination from '../image/activity5_imagination.png';
import activity5_subtraction from '../image/activity5_subtraction.png';
import activity5_44 from '../image/activity5_44.png';
import activity5_47 from '../image/activity5_47.png';
import activity5_50 from '../image/activity5_50.png';
import activity5_53 from '../image/activity5_53.png';
import activity5_56 from '../image/activity5_56.png';
import activity5_60 from '../image/activity5_60.png';
// import activity5_62 from '../image/activity5_62.png';
// import activity5_65 from '../image/activity5_65.png';
// import activity5_68 from '../image/activity5_68.png';
// import activity5_71 from '../image/activity5_71.png';
// import activity5_73 from '../image/activity5_73.png';
// import activity5_76 from '../image/activity5_76.png';
// import activity5_78 from '../image/activity5_78.png';
// import activity5_80 from '../image/activity5_80.png';

const activityData = [
  {
    courseId: 0, // 중요한 건 내 마음
    image: activity0,
    backgroundColor: '#1355FF',
    activityList: [
      {
        //인사이드에 오신 걸 환영해요!
        activityId: 'course-0_1',
        backgroundColor: '#0D41CA',
        image: activity0_1,
      },
      {
        //안녕하세요, 닥터 스마일입니다
        activityId: 'course-0_2',
        backgroundColor: '#FFC045',
        image: activity0_2,
      },
      {
        //감정 노트: 감정과 생각 기록하기
        activityId: 'course-0_3',
        backgroundColor: '#7C4FFF',
        image: activity0_3,
      },
      {
        //감정 노트
        activityId: 'course-0_4',
        toolId: 'tool-2',
      },
      {
        //앨리스의 생각에 대하여
        activityId: 'course-0_5',
        backgroundColor: '#EF7257',
        image: activity0_5,
      },
      {
        //회고 노트: 생각 되짚어 보기 1
        activityId: 'course-0_6',
        backgroundColor: '#1F6890',
        image: activity0_6,
      },
      {
        //회고 노트: 생각 되짚어 보기 2
        activityId: 'course-0_7',
        backgroundColor: '#21593E',
        image: activity0_7,
      },
      {
        //회고 노트
        activityId: 'course-0_8',
        toolId: 'tool-8',
      },
    ],
  },
  {
    courseId: 1,
    image: activity1,
    backgroundColor: '#7655FA',
    activityList: [
      {
        //intro
        activityId: 'course-1_1',
        backgroundColor: '#7655FA',
        image: activity1_1,
      },
      {
        //생각에 집중하기
        activityId: 'course-1_2',
        backgroundColor: '#FFCA43',
        image: activity1_2,
      },
      {
        //자동적 사고
        activityId: 'course-1_3',
        backgroundColor: '#7655FA',
        image: activity1_3,
      },
      {
        //자동적사고 인지하기
        activityId: 'course-1_4',
        backgroundColor: '#5566FA',
        image: activity1_4,
      },
      {
        //ABC로 감정 파악하기
        activityId: 'course-1_5',
        backgroundColor: '#6C55FA',
        image: activity1_5,
      },
      {
        //상황, 생각, 그리고 결과
        activityId: 'course-1_6',
        backgroundColor: '#FFB443',
        image: activity1_6,
      },
      {
        //내 마음 살펴보기
        activityId: 'course-1_7',
        backgroundColor: '#FFB443',
        image: activity1_7,
      },
      {
        //감정노트
        activityId: 'course-1_8',
        toolId: 'tool-2',
      },
      {
        //생각 함정1
        activityId: 'course-1_9',
        backgroundColor: '#4B72FB',
        image: activity1_9,
      },
      {
        //생각함정2
        activityId: 'course-1_10',
        backgroundColor: '#3F576C',
        image: activity1_10,
      },
      {
        //생각함정3
        activityId: 'course-1_11',
        backgroundColor: '#4BA7FB',
        image: activity1_11,
      },
      {
        //생각함정4
        activityId: 'course-1_12',
        backgroundColor: '#FB804B',
        image: activity1_12,
      },
      {
        //D로 생각 점검하기
        activityId: 'course-1_13',
        backgroundColor: '#FBB54B',
        image: activity1_13,
      },
      {
        //E로 생각 바꾸기
        activityId: 'course-1_14',
        backgroundColor: '#7655FA',
        image: activity1_14,
      },
      {
        //회고 노트
        activityId: 'course-1_15',
        toolId: 'tool-8',
      },
      {
        //Outro
        activityId: 'course-1_16',
        backgroundColor: '#7655FA',
        image: activity1_1,
      },
    ],
  },
  {
    courseId: 2,
    image: activity2,
    backgroundColor: '#4A3CED',
    activityList: [
      {
        //intro
        activityId: 'course-2_1',
        backgroundColor: '#7655FA',
        image: activity2_1,
      },
      {
        //내 마음을 이루는 기본 가정
        activityId: 'course-2_2',
        backgroundColor: '#4B91FB',
        image: activity2_2,
      },
      {
        //내 마음의 근원
        activityId: 'course-2_3',
        backgroundColor: '#8E4BFB',
        image: activity2_3,
      },
      {
        //기본 가정을 통한 예상
        activityId: 'course-2_4',
        backgroundColor: '#4B7CFB',
        image: activity2_4,
      },
      {
        //삶의 규칙
        activityId: 'course-2_5',
        backgroundColor: '#FB8A4B',
        image: activity2_5,
      },
      {
        //기본 가정과 규칙 조정하기
        activityId: 'course-2_6',
        backgroundColor: '#FBBF4B',
        image: activity2_6,
      },
      {
        //규칙수첩
        activityId: 'course-2_7',
        toolId: 'tool-9',
      },
      {
        //Outro
        activityId: 'course-2_8',
        backgroundColor: '#7655FA',
        image: activity2_1,
      },
    ],
  },
  {
    courseId: 3,
    image: activity3,
    backgroundColor: '#463995',
    activityList: [
      {
        //intro
        activityId: 'course-3_1',
        backgroundColor: '#463995',
        image: activity3_1,
      },
      {
        //우리를 돕는 감정
        activityId: 'course-3_2',
        backgroundColor: '#23416F',
        image: activity3_2,
      },
      {
        //감정에 이름붙이기
        activityId: 'course-3_3',
        backgroundColor: '#1E47B0',
        image: activity3_3,
      },
      {
        //감정이 보내는 몸의 신호
        activityId: 'course-3_4',
        backgroundColor: '#6656C9',
        image: activity3_4,
      },
      {
        //감정 알아차리기(1)-기쁨
        activityId: 'course-3_5',
        backgroundColor: '#FFC657',
        image: activity3_5,
      },
      {
        //감정 알아차리기(2)-슬픔
        activityId: 'course-3_6',
        backgroundColor: '#213459',
        image: activity3_6,
      },
      {
        //감정 알아차리기(3)-불쾌함
        activityId: 'course-3_7',
        backgroundColor: '#1C603B',
        image: activity3_7,
      },
      {
        //감정 알아차리기(4)-화남
        activityId: 'course-3_8',
        backgroundColor: '#D44C2E',
        image: activity3_8,
      },
      {
        //감정 알아차리기(5)-두려움
        activityId: 'course-3_9',
        backgroundColor: '#270A78',
        image: activity3_9,
      },
      {
        //흐르는 감정의 물결
        activityId: 'course-3_10',
        backgroundColor: '#644BFB',
        image: activity3_10,
      },
      {
        //내 감정 돌아보기
        activityId: 'course-3_11',
        backgroundColor: '#463995',
        image: activity3_1,
      },
    ],
  },
  {
    courseId: 4,
    image: activity4,
    backgroundColor: '#FF5A13',
    activityList: [
      {
        //intro : 부정적인 감정 다기
        activityId: 'course-4_1',
        backgroundColor: '#EF3E3E',
        image: activity4_1,
      },
      {
        //불난 감정의 소화기 '심호흡
        activityId: 'course-4_2',
        backgroundColor: '#26282C',
        image: activity4_2,
      },
      {
        //호흡 타이머
        activityId: 'course-4_3',
        toolId: 'tool-10',
      },
      {
        //슬픔과 우울증
        activityId: 'course-4_4',
        backgroundColor: '#575DEF',
        image: activity4_4,
      },
      {
        //마음의 근육 기르기
        activityId: 'course-4_5',
        backgroundColor: '#441F90',
        image: activity4_5,
      },
      {
        //죄책감을 부르는 생각 함정
        activityId: 'course-4_6',
        backgroundColor: '#1884B1',
        image: activity4_6,
      },
      {
        //죄책김 벗어던지기
        activityId: 'course-4_7',
        backgroundColor: '#24968F',
        image: activity4_7,
      },
      {
        //양날의 검, 분노
        activityId: 'course-4_8',
        backgroundColor: '#D44C2E',
        image: activity4_8,
      },
      {
        //분노를 부르는 생각 함정
        activityId: 'course-4_9',
        backgroundColor: '#9D1313',
        image: activity4_9,
      },
      {
        //분노에서 자유로워지기
        activityId: 'course-4_10',
        backgroundColor: '#FF9F0E',
        image: activity4_10,
      },
      {
        //outro
        activityId: 'course-4_11',
        backgroundColor: '#EF3E3E',
        image: activity4_1,
      },
    ],
  },
  {
    courseId: 5,
    image: activity5,
    backgroundColor: '#182D58',
    activityList: [
      {
        //1
        activityId: 'course-5_1',
        image: activity5_1,
      },
      {
        activityId: 'course-5_2',
        image: activity5_2,
      },
      {
        activityId: 'course-5_3',
        image: activity5_3,
      },
      {
        activityId: 'course-5_4',
        image: activity5_4,
      },
      {
        //2
        activityId: 'course-5_5',
        image: activity5_5,
      },
      {
        activityId: 'course-5_6',
        image: activity5_6,
      },
      {
        activityId: 'course-5_7',
        image: activity5_7,
      },
      {
        activityId: 'course-5_8',
        image: activity5_report,
      },
      {
        //3
        activityId: 'course-5_9',
        image: activity5_report,
      },
      {
        activityId: 'course-5_10',
        image: activity5_10,
      },
      {
        activityId: 'course-5_11',
        image: activity5_11,
      },
      {
        //4
        activityId: 'course-5_12',
        image: activity5_report,
      },
      {
        activityId: 'course-5_13',
        image: activity5_13,
      },
      {
        activityId: 'course-5_14',
        image: activity5_14,
      },
      {
        //5
        activityId: 'course-5_15',
        image: activity5_report,
      },
      {
        activityId: 'course-5_16',
        image: activity5_16,
      },
      {
        //6
        activityId: 'course-5_17',
        image: activity5_report,
      },
      {
        activityId: 'course-5_18',
        image: activity5_18,
      },
      {
        activityId: 'course-5_19',
        image: activity5_19,
      },
      {
        //7
        activityId: 'course-5_20',
        image: activity5_report,
      },
      {
        activityId: 'course-5_21',
        image: activity5_2,
      },
      {
        activityId: 'course-5_22',
        image: activity5_solution,
      },
      {
        //8
        activityId: 'course-5_23',
        image: activity5_report,
      },
      {
        activityId: 'course-5_24',
        image: activity5_24,
      },
      {
        //9
        activityId: 'course-5_25',
        image: activity5_report,
      },
      {
        activityId: 'course-5_26',
        image: activity5_26,
      },
      {
        //10
        activityId: 'course-5_27',
        image: activity5_report,
      },
      {
        activityId: 'course-5_28',
        image: activity5_28,
      },
      {
        //11
        activityId: 'course-5_29',
        image: activity5_report,
      },
      {
        activityId: 'course-5_30',
        image: activity5_30,
      },
      {
        //12
        activityId: 'course-5_31',
        image: activity5_report,
      },
      {
        activityId: 'course-5_32',
        image: activity5_relaxation1,
      },
      {
        activityId: 'course-5_33',
        image: activity5_relaxation1,
      },
      {
        //13
        activityId: 'course-5_34',
        image: activity5_report,
      },
      {
        activityId: 'course-5_35',
        image: activity5_relaxation2,
      },
      {
        activityId: 'course-5_36',
        image: activity5_imagination,
      },
      {
        activityId: 'course-5_37',
        image: activity5_subtraction,
      },
      {
        //14
        activityId: 'course-5_38',
        image: activity5_report,
      },
      {
        activityId: 'course-5_39',
        image: activity5_2,
      },
      {
        activityId: 'course-5_40',
        image: activity5_relaxation3,
      },
      {
        activityId: 'course-5_41',
        image: activity5_relaxation3,
      },
      {
        activityId: 'course-5_42',
        image: activity5_solution,
      },
      {
        activityId: 'course-5_43',
        image: activity5_21,
      },
      {
        //15
        activityId: 'course-5_44',
        image: activity5_report,
      },
      {
        activityId: 'course-5_45',
        image: activity5_44,
      },
      {
        activityId: 'course-5_46',
        image: activity5_imagination,
      },
      {
        //16
        activityId: 'course-5_47',
        image: activity5_report,
      },
      {
        activityId: 'course-5_48',
        image: activity5_47,
      },
      {
        activityId: 'course-5_49',
        image: activity5_subtraction,
      },
      {
        //17
        activityId: 'course-5_50',
        image: activity5_report,
      },
      {
        activityId: 'course-5_51',
        image: activity5_50,
      },
      {
        activityId: 'course-5_52',
        image: activity5_relaxation3,
      },
      {
        //18
        activityId: 'course-5_53',
        image: activity5_report,
      },
      {
        activityId: 'course-5_54',
        image: activity5_53,
      },
      {
        activityId: 'course-5_55',
        image: activity5_relaxation1,
      },
      {
        //19
        activityId: 'course-5_56',
        image: activity5_report,
      },
      {
        activityId: 'course-5_57',
        image: activity5_56,
      },
      {
        activityId: 'course-5_58',
        image: activity5_56,
      },
      {
        activityId: 'course-5_59',
        image: activity5_relaxation3,
      },
      {
        //20
        activityId: 'course-5_60',
        image: activity5_report,
      },
      {
        activityId: 'course-5_61',
        image: activity5_60,
      },
      {
        activityId: 'course-5_62',
        image: activity5_imagination,
      },
      {
        //21
        activityId: 'course-5_63',
        image: activity5_report,
      },
      {
        activityId: 'course-5_64',
        image: activity5_2,
      },
      {
        activityId: 'course-5_65',
        image: activity5_relaxation3,
      },
      {
        //22
        activityId: 'course-5_66',
        image: activity5_report,
      },

      {
        activityId: 'course-5_67',
        image: activity5_subtraction,
      },
      {
        activityId: 'course-5_68',
        image: activity5_56,
      },
      {
        //23
        activityId: 'course-5_69',
        image: activity5_report,
      },

      {
        activityId: 'course-5_70',
        image: activity5_relaxation3,
      },
      {
        //24
        activityId: 'course-5_71',
        image: activity5_report,
      },

      {
        //25
        activityId: 'course-5_72',
        image: activity5_report,
      },
      {
        activityId: 'course-5_73',
        image: activity5_relaxation3,
      },
      {
        activityId: 'course-5_74',
        image: activity5_56,
      },
      {
        //26
        activityId: 'course-5_75',
        image: activity5_report,
      },
      {
        //27
        activityId: 'course-5_76',
        image: activity5_report,
      },
      {
        //28
        activityId: 'course-5_77',
        image: activity5_report,
      },
      {
        activityId: 'course-5_78',
        image: activity5_2,
      },
      {
        activityId: 'course-5_79',
        image: activity5_56,
      },
      {
        activityId: 'course-5_80',
        image: activity5_21,
      },
      {
        activityId: 'course-5_81',
        image: activity5_1,
      },
    ],
  },
];

export default activityData;
