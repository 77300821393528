import React, { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
import MessageBlockWithBold from '../../stories/workbook/MessageBlockWithBold';

function UserAnswerBox({ num, sum, title, placeholder }) {
  const context = useContext(CoachingContext);
  return (
    <div className="">
      {context.workbookAnswer.length !== 0 && (
        <MessageBlockWithBold
          textList={[
            {
              bold: '사건: ',
              text: [context.workbookAnswer[0]],
              type: 'black',
            },
            {
              bold: '믿음: ',
              text: [context.workbookAnswer[1]],
              type: 'white_dotted',
              tail: true,
            },
            {
              bold: '결과: ',
              text: [context.workbookAnswer[2]],
              type: 'light_blue',
            },
          ]}
          my
        />
      )}
    </div>
  );
}

export default UserAnswerBox;
