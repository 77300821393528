import SurveyInBedStart from '../../../components/workbook/course5/SurveyInBedStart';
import SurveyInSleepStart from '../../../components/workbook/course5/SurveyInSleepStart';
import SurveyInSleepEnd from '../../../components/workbook/course5/SurveySleepEnd';
import SurveyInBedEnd from '../../../components/workbook/course5/SurveyInBedEnd';
import SurveyInStruggle from '../../../components/workbook/course5/SurveyInStruggle';
import SurveyInNap from '../../../components/workbook/course5/SurveyInNap';
import SurveyRuleCard from '../../../components/workbook/course5/SurveyRuleCard';
import ChallengersReport from './challengersReport';
import SurveyDone from '../../../components/workbook/course5/SurveyDone';

const challengersData = [
  <SurveyInBedStart type="inBedStart" isMarketing />,
  <SurveyInSleepStart type="sleepStart" isMarketing />,
  <SurveyInSleepEnd type="sleepEnd" isMarketing />,
  <SurveyInBedEnd type="inBedEnd" isMarketing />,
  <SurveyInStruggle type="struggleTime" isMarketing />,
  <SurveyInNap type="napTime" isMarketing />,
  <SurveyRuleCard title="졸릴 때만 침대에 누웠나요?" isMarketing />,
  <SurveyRuleCard title="누웠지만 15분이 지나도 졸리지 않을때에는 잠자리에서 일어났나요?" isMarketing />,
  <SurveyRuleCard title="침실에서 잠자는 것 이외의 활동은 하지 않았나요?" isMarketing />,
  <SurveyDone isMarketing />,
  <ChallengersReport />,
];

export default challengersData;
