import React, { useState, useEffect, useRef } from 'react';
import { changeHeader, getRefresh } from '../../utils/webview';
import DotLoader from 'react-spinners/DotLoader';
import 'react-toastify/dist/ReactToastify.css';
import { scrollEvent } from '../../utils/webview';
import CourseCard from '../../components/course/CourseCard';
import courseData from '../../data/courseData';
import InprogressCourse from '../../components/course/InprogressCourse';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import useFetch from '../../hooks/useFetch';
import useShowWebviewWithParams from '../../hooks/useShowWebviewWithParams';
import ContainerByDevice from '../../components/elements/ContainerByDevice';
import Test from '../../development/Test';

function Course() {
  const [inProgressCourse, setInProgressCourse] = useState({});
  const [recommendedCourse, setRecommendedCourse] = useState({});
  const [releasedCourseList, setReleasedCourseList] = useState([]);
  const [comingSoonCourseList, setComingSoonCourseList] = useState([]);

  const [recommendedCourseId, setRecommendedCourseId] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const titleRef = useRef();

  const [course, getCourseList] = useFetch({
    url: '/course/v2/list',
    bg: '#F3F5F8',
  });

  //코스리스트 받아오기
  useEffect(() => {
    if (course) {
      setInProgressCourse({ ...course.result.inProgress });
      setRecommendedCourse({ ...course.result.recommended });
      if (course.result.recommended) {
        setRecommendedCourseId(course.result.recommended.courseId);
      }
      setReleasedCourseList(course.result.released);
      setComingSoonCourseList(course.result.comingSoon);
      setIsLoaded(true);
      if (
        titleRef.current &&
        (scrollTop > titleRef.current.offsetHeight || window.scrollY > titleRef.current.offsetHeight)
      ) {
        setShowHeader(true);
        changeHeader({ backgroundColor: '#FFFFFF', text: '코스' });
      } else {
        setShowHeader(false);
        changeHeader({ backgroundColor: '#D5DBFB', text: '' });
      }
    }
  }, [course]);

  useEffect(() => {
    document.title = '코스';
    getCourseList();
    getRefresh(getCourseList);
    changeHeader({ backgroundColor: '#FFFFFF', text: '' });
    window.document.body.style.backgroundColor = '#D5DBFB';

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goRecommendedCourseDetail = useShowWebviewWithParams(`course/${recommendedCourseId.split('-')[1]}`, '#FFFFFF');
  const goUserInfo = useShowWebviewWithParams('userInfo', '#FFFFFF');
  const goOnboarding = useShowWebviewWithParams('assignCounselor/begin/first', '#FFFFFF');

  useEffect(() => {
    if (isLoaded) {
      if (showHeader) {
        console.log('헤더 텍스트 노출');
        changeHeader({ backgroundColor: '#FFFFFF', text: '코스' });
        window.document.body.style.backgroundColor = '#F3F5F8';
      } else {
        console.log('헤더 텍스트 비노출');
        changeHeader({ backgroundColor: '#D5DBFB', text: '' });
        window.document.body.style.backgroundColor = '#D5DBFB';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHeader]);

  const handleScroll = (scrollTop) => {
    scrollEvent(scrollTop);
    setScrollTop(scrollTop);
    if (scrollTop > titleRef.current.offsetHeight) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  };

  return (
    <ContainerByDevice handleScroll={handleScroll} scrollEvent>
      {isLoaded ? (
        <div>
          <div className="w-full  bg-[#D5DBFB] ">
            <div
              className="font-bold text-[24px] leading-[28.8px] text-[#26282C] pt-[12px]  px-[20px] max-w-[428px] mx-auto"
              ref={titleRef}
            >
              코스
            </div>
            <Test
              element={
                <div className="px-[20px] py-[5px] bg-[#D5DBFB] flex gap-[50px] max-w-[428px] mx-auto ">
                  <div className="" onClick={goUserInfo}>
                    내담자 알아보기
                  </div>
                  <div className="" onClick={goOnboarding}>
                    온보딩 테스트
                  </div>
                </div>
              }
            />
            {inProgressCourse.courseId && (
              <div className="px-[20px] pt-[30px] pb-[40px] max-w-[428px] mx-auto bg-[#D5DBFB]">
                <p className={`text-[#26282C] text-[20px] leading-[26px] font-bold  mb-[15px]`}>진행 중</p>
                <div
                  className={`w-full grid grid-cols-2 gap-[9px] items-center `}
                  key={`inprogress${inProgressCourse.courseId}`}
                >
                  <CourseCard
                    courseId={inProgressCourse.courseId}
                    courseName={inProgressCourse.name}
                    courseResource={courseData.find((item) => item.courseId === inProgressCourse.courseId)}
                  />
                  <div className="pl-[11px] mt-[15px] w-full">
                    <InprogressCourse
                      percentageOfProgress={inProgressCourse.percentageOfProgress}
                      totalDays={inProgressCourse.totalDays}
                      currentDay={inProgressCourse.currentDay}
                    />
                  </div>
                </div>
              </div>
            )}
            {recommendedCourse.courseId && (
              <div className="px-[20px] pt-[30px] pb-[40px] max-w-[428px] mx-auto bg-[#D5DBFB]">
                <p className={`text-[#26282C] text-[20px] leading-[26px] font-bold  mb-[15px]`}>추천 코스</p>
                <div
                  className={`w-full grid grid-cols-2 gap-[9px] items-center `}
                  key={`recommended${recommendedCourse.courseId}`}
                >
                  <CourseCard
                    courseId={recommendedCourse.courseId}
                    courseName={recommendedCourse.name}
                    courseResource={courseData.find((item) => item.courseId === recommendedCourse.courseId)}
                  />
                  <div className="pl-[11px]  w-full h-full flex flex-col justify-between pt-[5px]">
                    <p className="inline text-[14px] leading-[20px] text-[#4E4F53] ">{recommendedCourse.description}</p>
                    <PrimaryActionButton
                      state={'ACTIVE'}
                      text={'알아보기'}
                      action={() => {
                        goRecommendedCourseDetail();
                      }}
                      small={true}
                    />
                  </div>
                </div>
              </div>
            )}{' '}
          </div>
          <div className="bg-[#F3F5F8] ">
            <div className="">
              {releasedCourseList.length > 0 &&
                releasedCourseList.map((released, index) => (
                  <div key={`released${index}`} className="px-[20px] pt-[30px] pb-[40px]">
                    <p
                      className={`text-[#26282C] text-[20px] leading-[26px] font-bold  mb-[15px] max-w-[428px] mx-auto`}
                    >
                      {released.category}
                    </p>
                    <div className="w-full grid grid-cols-2 gap-[9px]">
                      {released.courseList.length > 0 &&
                        released.courseList.map((result, index) => (
                          <CourseCard
                            key={`notdone${index}`}
                            courseId={result.courseId}
                            courseName={result.name}
                            courseResource={courseData.find((item) => item.courseId === result.courseId)}
                          />
                        ))}{' '}
                    </div>
                    {/* <div className="w-screen flex  overflow-x-scroll scrollbar-hide ">
                      <div
                        style={{
                          width: window.innerWidth > 428 ? (window.innerWidth - 428) / 20 : 20,
                          minWidth: window.innerWidth > 428 ? (window.innerWidth - 428) / 2 + 20 : 20,
                        }}
                      />
                      {released.courseList.map((course, index) => (
                        <div
                          key={`released2${index}`}
                          className={`w-[calc((100%-49px)/2)] max-w-[190px] shrink-0 
                      ${index !== released.courseList.length - 1 ? 'mr-[9px]' : ''}`}
                        >
                          <CourseCard
                            courseId={course.courseId}
                            courseName={course.name}
                            isDone={course.isDone}
                            courseResource={courseData.find((item) => item.courseId === course.courseId)}
                          />
                        </div>
                      ))}
                      <div
                        style={{
                          width: window.innerWidth > 428 ? (window.innerWidth - 428) / 2 + 20 : 20,
                          minWidth: window.innerWidth > 428 ? (window.innerWidth - 428) / 2 + 20 : 20,
                        }}
                      />
                    </div> */}
                  </div>
                ))}
            </div>
          </div>

          <div className="w-full px-[20px] pt-[30px] pb-[40px] max-w-[428px] mx-auto bg-[#F3F5F8]">
            <p className={`text-[#26282C] text-[20px] leading-[26px] font-bold  mb-[15px]`}>출시 예정</p>
            <div className="w-full grid grid-cols-2 gap-[9px]">
              {comingSoonCourseList.length > 0 &&
                comingSoonCourseList.map(
                  (result, index) =>
                    courseData.find((item) => item.courseId === result.courseId) && (
                      <CourseCard
                        key={`notdone${index}`}
                        courseId={result.courseId}
                        courseName={result.name}
                        courseResource={courseData.find((item) => item.courseId === result.courseId)}
                        isComingSoon
                      />
                    ),
                )}{' '}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center bg-white">
          <DotLoader color="#3953D9" loading={true} size={80} />
        </div>
      )}
    </ContainerByDevice>
  );
}

export default Course;
