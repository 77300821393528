import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { DoneLottie } from '../../../stories/animation/DoneLottie';
import { motion } from 'framer-motion';

export function DoneLottieInPhone(props) {
  return (
    <motion.div
      className="w-[calc(100%+200px)] absolute top-[calc(50%-230px)] z-[50] left-[50%] translate-x-[-50%] "
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      viewport={{ once: true }}
    >
      <DoneLottie />
    </motion.div>
  );
}
