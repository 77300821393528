import { useEffect, useRef } from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import './carousel.css';
import { createRef } from 'react';
import SessionCard from './SessionCard';
import { useRecoilState } from 'recoil';
import somnyAtom from '../../recoil/somny/atom';

export default function SessionCarousel({ sessionList, isLoomy, isBetty }) {
  SwiperCore.use([Navigation, Pagination, Autoplay]);

  const carouselRef = createRef();
  const scrollRef = useRef();

  const [somnyState, setSomnyState] = useRecoilState(somnyAtom);
  const cardWidth = window.innerWidth - 48 >= 327 ? 327 : window.innerWidth - 48;

  useEffect(() => {
    if (somnyState.completedAt) {
      if (somnyState.isFirst) {
        setTimeout(() => {
          const x = cardWidth + 10;
          scrollRef.current.scrollTo({
            left: x,
            behavior: 'smooth',
          });
          setSomnyState((state) => ({ ...state, isFirst: false }));
        }, 900);
      } else {
        if (scrollRef.current) {
          scrollRef.current.scrollLeft = cardWidth + 10;
        }
      }
    }
  }, []);

  return (
    <div ref={carouselRef}>
      <div className={`relative w-screen`}>
        <div ref={scrollRef} className="w-full flex overflow-auto snap-x snap-mandatory scrollbar-hide">
          <div className="px-[24px] flex gap-[10px]">
            {sessionList.map((item, index) => (
              <div
                key={`carousel${index}`}
                className="shrink-0 snap-center snap-always"
                style={{
                  display: 'inline-block',
                  width: cardWidth,
                }}
              >
                <SessionCard index={index} sessionData={sessionList} item={item} isLoomy={isLoomy} isBetty={isBetty} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
