import lake from '../../image/anxy/tool/lake.png';
import forest from '../../image/anxy/tool/forest.png';
import desert from '../../image/anxy/tool/desert.png';
import hill from '../../image/anxy/tool/hill.png';

export const meditationThemeData = [
  {
    id: 1,
    title: '고요한 아침 호수',
    image: lake,
    isNotReady: false,
  },
  {
    id: 2,
    title: '푸른 숲 속',
    image: forest,
    isNotReady: true,
  },
  {
    id: 3,
    title: '따뜻한 사막',
    image: desert,
    isNotReady: true,
  },
  {
    id: 4,
    title: '눈 덮인 언덕',
    image: hill,
    isNotReady: true,
  },
];
