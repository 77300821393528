/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import Progressbar from 'react-js-progressbar';
import { convertMinutesToStringTime } from './calculateTime';

export default function SleepEfficiencyGraphXYZ(props) {
  const { efficiency, inBedTime, sleepTime } = props;
  const flexRef = useRef();
  return (
    <div className="w-full  rounded-[16px] bg-white relative overflow-hidden ">
      <div className="px-[20px] pt-[24px] pb-[30px]">
        <div className={`w-full flex flex-wrap gap-[5px] mb-[20px] whitespace-nowrap`} ref={flexRef}>
          <div className="flex-1 flex gap-[10px] ">
            <div className=" flex items-center ">
              <div className="w-[10px] h-[10px] rounded-[10px] bg-[#D8DCFF] mr-[4px]" />
              <div> 침대에 머문 시간</div>
            </div>
            <div className="font-bold">{convertMinutesToStringTime(inBedTime)}</div>
          </div>
          <div className="flex-1 flex gap-[10px] ">
            <div className="flex items-center">
              <div className="w-[10px] h-[10px] rounded-[10px] bg-[#2C4BEC] mr-[4px]" />
              <div> 잠잔 시간</div>
            </div>
            <div className="font-bold">{convertMinutesToStringTime(sleepTime)}</div>
          </div>
        </div>
        <div className="w-[180px] h-[180px] mx-auto mb-[20px] relative" id="selfCheckEnd">
          <Progressbar
            input={efficiency}
            pathWidth={14}
            pathColor={'#2C4BEC'} // use an array for gradient color.
            trailWidth={14}
            trailColor="#D8DCFF" // use a string for solid color.
            textStyle={{ fontSize: '0px' }} // middle text style
            pathLinecap={'round'}
            pathShadow={'none'}
            animation={{
              duration: 0,
              delay: 0,
              ease: 'easeInSine',
            }}
          ></Progressbar>
          <div className="absolute top-[50%] translate-y-[calc(-50%)] left-[50%] translate-x-[-50%]  z-40 text-center">
            <div className="text-[14px] leading-[20px] text-[#A4A6B0] mb-[4px]">{'지난 7일 기준'}</div>
            <div
              className={`whitespace-nowrap  text-[30px] leading-[36px] ${
                !isNaN(efficiency) ? 'text-[#26282C]' : 'text-[#D1D5DC]'
              } font-bold `}
            >
              {!isNaN(efficiency) ? `${efficiency}%` : '처방예정'}
            </div>
          </div>
        </div>
        <div className="w-fit text-[15px] leading-[21px] text-[#80838B] mx-auto ">
          {!isNaN(efficiency)
            ? efficiency >= 85
              ? '잘 자고 있어요.'
              : '수면 효율이 낮은 편이예요.'
            : '수면 효율을 계산할 수 없어요'}
        </div>
        <div className="w-full h-[1px] bg-[#F3F5F8] my-[20px]" />
        <div
          className={` w-full text-[15px] leading-[24px] text-[#26282C] font-medium ${
            isNaN(efficiency) && 'text-center'
          }`}
        >
          {!isNaN(efficiency)
            ? efficiency >= 85
              ? '불면증에 가장 높은 치료 효과를 보이는 불면증 인지행동치료에 따르면, 수면 효율이 85% 이상일 때 잘 자고 있는 상태라고 해요.'
              : '불면증에 가장 높은 치료 효과를 보이는 불면증 인지행동치료에 따르면, 수면 효율이 85% 이상일 때 잘 자고 있는 상태라고 해요. 나의 수면 효율을 높이기 위해서는 몇시에 침대에 들어가야 할까요?'
            : '침대에 머문 시간을 정확히 입력해주세요.'}
        </div>
      </div>
    </div>
  );
}
