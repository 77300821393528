import lake from '../../../image/anxy/tool/lake.png';
import forest from '../../../image/anxy/tool/forest.png';
import desert from '../../../image/anxy/tool/desert.png';
import hill from '../../../image/anxy/tool/hill.png';
import anxyWithWorri from '../../../image/anxy/tool/anxyWithWorri.png';
import info1 from '../../../image/anxy/tool/info1.png';
import info2 from '../../../image/anxy/tool/info2.png';
import info3 from '../../../image/anxy/tool/info3.png';
import meditateEnd from '../../../image/anxy/tool/meditateEnd.png';

export const meditationImageList = [lake, forest, desert, hill, anxyWithWorri, info1, info2, info3, meditateEnd];
