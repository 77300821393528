import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hasTouch } from 'detect-touch';

export function SkipButton(props) {
  const { state, activeColor, inactiveColor, textColor, text, action, small } = props;
  const [buttonDown, setButtonDown] = useState(false);
  function mouseUp(e) {
    setButtonDown(false);
  }
  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });
  return (
    <button
      style={{
        backgroundColor: state !== 'INACTIVE' ? '#2C4BEC' : '#EFF2FF',
        color: state !== 'INACTIVE' ? '#FFFFFF' : '#3953D9',
        border: state === 'INACTIVE' && '1px solid #EAEDFA',
      }}
      className={`${'h-[54px] rounded-[16px] text-[17px] leading-[26px]'} relative  w-full font-semibold
    `}
      onMouseDown={(e) => {
        if (!hasTouch) {
          setButtonDown(true);
        }
      }}
      onClick={(e) => {
        if (!hasTouch) {
          action();
        }
      }}
      onTouchStart={(e) => {
        setButtonDown(true);
      }}
      onTouchEnd={(e) => {
        setButtonDown(false);
        if (
          document.elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY).includes(e.currentTarget)
        ) {
          action();
        }
      }}
    >
      <p className="mb-[1px]">{text}</p>
    </button>
  );
}
