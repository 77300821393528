import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { PressedEffect } from '../../stories/button/PressedEffect';
import courseData from '../../data/courseData';

export default function RecommendedCourseCard(props) {
  const { course, hideButton } = props;
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appId = searchParams.get('app_id');
  const navigate = useNavigate();

  function goCourseDetail() {
    console.log(course.courseId.split('-')[1], course.courseId.slice(-1));
    navigate(`/course/${course.courseId.split('-')[1]}?at=${authToken}&app_id=${appId}&navigation=back`);
  }

  return (
    <PressedEffect
      element={
        <div
          className=" max-w-[295px] h-full px-[24px]  rounded-[20px] flex flex-col items-centerflex flex-col justify-end"
          style={{ backgroundColor: courseData.find((element) => element.courseId === course.courseId).bg }}
        >
          <div className="w-full flex-1 max-h-[150px]  relative">
            <img
              src={courseData.find((element) => element.courseId === course.courseId).image}
              alt={'empty'}
              className="absolute h-[calc(100%-30px)] mx-auto top-[30px] left-[50%] translate-x-[-50%]"
            />
          </div>
          <div className=" py-[30px] flex-initial">
            <div className="  text-center mb-[24px]">
              <p className="font-bold text-[20px] leading-[26px] text-white mb-[20px]">{course.name}</p>
              <p
                className={`font-normal text-[15px] text-[#E1E4EB] leading-[21px] text-ellipsis ${
                  hideButton ? 'line-clamp-6' : 'line-clamp-4'
                }`}
              >
                {course.description}
              </p>
            </div>
            {!hideButton && (
              <div className=" text-[17px] leading-[26px] font-semibold text-white flex items-center justify-center ">
                <p className="mr-[5.5px]">알아보기</p>
                <p>
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 10L5.5 5.5L1 1"
                      stroke="white"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </div>
            )}
          </div>
        </div>
      }
      action={goCourseDetail}
    />
  );
}
