import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import deviceAtom from '../../recoil/common/device';
import { getScrollEvent } from '../../utils/webview';
import AnxyNotch from '../anxy/AnxyNotch';

export default function ContainerByDeviceAnxy({
  css,
  children,
  handleScroll,
  scrollto,
  initialScrollTo,
  bgColor,
  noHeader,
  appNotch,
  transparentHeader,
  scrollBehaviour,
}) {
  const { isIOS } = useRecoilValue(deviceAtom);
  const scrollRef = useRef();

  const onScroll = (e) => {
    const scrollTop = e.target.scrollTop ? e.target.scrollTop : window.scrollY;

    if (handleScroll) {
      handleScroll(scrollTop);
    }
  };

  useEffect(() => {
    if (isIOS) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollTop + scrollto,
        behavior: scrollBehaviour || 'smooth',
      });
    } else {
      window.scrollTo({
        top: window.scrollY + scrollto,
        behavior: scrollBehaviour || 'smooth',
      });
    }
  }, [scrollto]);

  useEffect(() => {
    if (initialScrollTo !== undefined) {
      setTimeout(() => {
        if (isIOS) {
          scrollRef.current.scrollTo({
            top: initialScrollTo,
          });
        } else {
          window.scrollTo({
            top: initialScrollTo,
          });
        }
      }, 100);
    }
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
    getScrollEvent(scrollRef);
    window.addEventListener('scroll', onScroll);
    return () => {
      if (window !== null) {
        window.removeEventListener('scroll', onScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`w-screen overflow-x-hidden min-h-screen ${!appNotch && 'pt-safe'} ${
        isIOS && ` h-screen overflow-auto `
      }  ${css} `}
      style={{ backgroundColor: bgColor || '#ffffff' }}
      onScroll={(e) => {
        onScroll(e);
      }}
      ref={scrollRef}
    >
      <AnxyNotch
        notchColor={transparentHeader ? '#000000' : bgColor || '#ffffff'}
        noHeader={noHeader}
        appNotch={appNotch}
      />
      <div className={` w-full  ${!noHeader && 'pt-[44px]'} h-full flex-1 flex flex-col`}>{children}</div>
    </div>
  );
}
