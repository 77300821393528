import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import { marginWithHomeIndicator } from 'recoil/common/device';
import headerAtom, { BUTTON_NONE } from 'recoil/common/header/atom';
import { PATH } from 'routes/paths';
import { CheckLottie } from 'stories/animation/CheckLottie';
import { AnxyActionButton } from 'stories/button/ActionButton';
import { FullPageAnxy } from 'stories/page/FullPageAnxy';
import congrats_particle from 'image/congrats_particle.json';
import Lottie from 'components/elements/Lottie';
import homeTypeAtom from 'recoil/anxy/home/atom';
import { impactFeedback } from 'utils/webview';
import webviewToast from 'development/webviewToast';

export default function SessionDone() {
  const [searchParams] = useSearchParams();
  const isLifetimeFirstComplete = searchParams.get('isLifetimeFirstComplete') === 'true';
  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const setHeaderState = useSetRecoilState(headerAtom);
  const setHomeType = useSetRecoilState(homeTypeAtom);

  const goToHome = useNavigateWithParams(PATH.anxy.anxyHome);
  const goAlarm = useNavigateWithParams('/anxyNotification/0?isSession=true');

  const handleNavigate = () => {
    setHomeType('anxy');
    if (isLifetimeFirstComplete) {
      goAlarm();
    } else {
      goToHome();
    }
  };

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE, headerColor: '#26282C' }));
    impactFeedback('success');
    webviewToast(`isLifetimeFirstComplete: ${isLifetimeFirstComplete}`);
  }, []);

  return (
    <FullPageAnxy
      content={
        <div className={`${marginClassName} m-auto flex flex-col justify-between items-center h-full relative`}>
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[50] w-full pointer-events-none">
            <Lottie lottieData={congrats_particle} autoplay />
          </div>
          <div className="h-full flex flex-col justify-center items-center">
            <CheckLottie green containerSize="72px" autoplay />
            <p className="text-[24px] leading-[30px] font-bold text-[#26282C] pt-[30px]">세션을 마쳤어요</p>
          </div>
          <div className="w-full px-[20px]">
            <AnxyActionButton text="확인" buttonState="ACTIVE" round action={handleNavigate} />
          </div>
        </div>
      }
    />
  );
}
