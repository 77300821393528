/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useSessionStorage from '../../hooks/useSessionStorage';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import SelectButton from './SelectButton';
import { motion } from 'framer-motion';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import { useState } from 'react';
import { useEffect } from 'react';
import { objectDataList } from '../../data/inside/objectDataList';

export default function SelectMultiInQA(props) {
  const { action, selfCheckState, setSelfCheckState } = props;

  const [buttonState, setButtonState] = useState('INACTIVE');
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  useEffect(() => {
    if (selfCheckState.objective) {
      setButtonState('ACTIVE');
    }
  }, [selfCheckState]);

  function isClicked(option) {
    setSelfCheckState((state) => ({ ...state, objective: option }));
  }

  console.log(selfCheckState);

  return (
    <div
      className={`relative text-center flex flex-col items-center justify-between`}
      style={{
        height: 'calc(var(--vh,1vh) * 100 - 61px)',
        minHeight: 'calc(var(--vh.1vh) * 100 - 61px)',
      }}
    >
      <div className="py-[20px] h-full flex flex-col justify-center">
        <p className="text-[24px] leading-[30px] font-bold">어떤 모습으로 변화하고 싶나요?</p>
        <p className="text-[17px] leading-[23px] text-[#4E4F53] pt-[12px] ">가장 원하는 목표 한 가지만 선택해주세요.</p>
        <div className="flex flex-col items-center mt-[40px]">
          {objectDataList.map((option, index) => (
            <div className="pb-[16px]">
              <SelectButton
                key={`selectButton${index}`}
                selected={selfCheckState.objective && selfCheckState.objective.id === option.id}
                option={option}
                text={option.text}
                isClicked={isClicked}
                isWhite
              />
            </div>
          ))}
        </div>
      </div>
      <div className={`px-[20px] w-full ${marginClassName}`}>
        <motion.div
          initial={{ opacity: selfCheckState.objective ? 1 : 0, y: selfCheckState.objective ? 0 : 88 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: 'easeInOut' }}
        >
          <PrimaryActionButton state={buttonState} text={'다음'} action={action} />
        </motion.div>
      </div>
    </div>
  );
}
