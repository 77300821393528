import { useCallback } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import axios from '../utils/axios2';
import webviewToast from '../development/webviewToast';
import getCouseName from '../components/workbook/data/getCourseName';
import { close } from '../utils/webview';
import mixpanel from 'mixpanel-browser';

export default function usePostWorkbookDone(action) {
  const { id, page } = useParams();
  const [searchParams] = useSearchParams();
  const isFirst = searchParams.get('isFirst') === 'true' || false;

  const postWorkbookDone = useCallback(async () => {
    try {
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          `/activity/complete`,
          JSON.stringify({
            activityKey: searchParams.get('activity_key'),
          }),
        )
        .then((response) => {
          let courseName = getCouseName(id);
          webviewToast(`workbook_complete ${courseName}`);
          mixpanel.track('workbook_complete', {
            course_name: courseName,
            first_done: isFirst,
          });

          if (action) {
            action();
          } else {
            close({ refresh: true });
          }
        });
    } catch (error) {
      webviewToast('error' + error);
      if (action) {
        action();
      } else {
        close({ refresh: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return postWorkbookDone;
}
