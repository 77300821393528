import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useRequestPayment from 'hooks/useRequestPayment';
import { updateNotch } from 'utils/webview';
import { Title24, Text17 } from 'stories/text/AnxyText';
import hexOpacity from 'hex-opacity';
import { RoundBottomAction } from 'stories/page/RoundBottomAction';

export default function PaymentConfirmFailTemplate({ fetchInvoiceDetailUrl, successUrlPath, failUrlPath, isSomny }) {
  const { state } = useLocation();
  const [appNotch, setAppNotch] = useState(false);

  const [invoiceData, requestPayment] = useRequestPayment({
    fetchInvoiceDetailUrl: fetchInvoiceDetailUrl,
    successUrlPath: successUrlPath,
    failUrlPath: failUrlPath,
  });

  useEffect(() => {
    if (!appNotch) {
      updateNotch({ isFullScreen: true });
    } else {
      updateNotch({ isFullScreen: false });

      setTimeout(() => {
        if (invoiceData) {
          requestPayment();
          setTimeout(() => {
            setAppNotch(false);
            updateNotch({ isFullScreen: true });
          }, 1500);
        }
      }, 500);
    }
  }, [appNotch]);

  return (
    <RoundBottomAction
      content={
        <div className="h-full flex flex-col gap-[30px] items-center justify-center text-center text-[12px] leading-[18px]">
          <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40.5" cy="40" r="40" fill={isSomny ? '#79AEFF' : '#EF670B'} />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M40 18.5C41.3807 18.5 42.5 19.6193 42.5 21V46C42.5 47.3807 41.3807 48.5 40 48.5C38.6193 48.5 37.5 47.3807 37.5 46V21C37.5 19.6193 38.6193 18.5 40 18.5Z"
              fill={isSomny ? '#050C26' : 'white'}
            />
            <circle cx="40" cy="56" r="3" fill={isSomny ? '#050C26' : 'white'} />
          </svg>

          <div className="flex flex-col gap-[8px] px-[20px]">
            <Title24 title={'결제에 실패했어요'} color={isSomny && '#ffffff'} />
            {state && state.failureMessage && (
              <Text17
                text={(state && state.failureMessage) || '에러메세지'}
                color={isSomny ? hexOpacity.create('#ffffff', 0.4) : '#6B6D76'}
              />
            )}
          </div>
        </div>
      }
      buttonText={'다시 시도하기'}
      buttonState={!appNotch ? 'ACTIVE' : 'LOADING'}
      action={() => {
        setAppNotch(true);
      }}
      appNotch={appNotch}
      noGradient
      bgColor={isSomny ? '#050C26' : '#ffffff'}
      isSomny={isSomny}
      isAnxy={!isSomny}
    />
  );
}
