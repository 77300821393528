import { useContext, useEffect, useState } from 'react';
import { CoachingContext } from '../../../coachingContext';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import axios from '../../../utils/axios2';
import webviewToast from '../../../development/webviewToast';
import EmotionSummaryAccordianItem from './EmotionSummaryAccordianItem';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import { TextWithBold } from '../TextWithBold';
import { useGetWorkbookParams } from '../../../hooks/useGetWorkbookParams';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';

const EmotionSummary = ({ emotion }) => {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);
  const [data, setData] = useState([]);
  const [isButtonFloat, setIsButtonFloat] = useState(false);
  const scrollRef = useRef();
  const accordianRef = useRef();
  const [height, setHeight] = useState(370);

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  const [authToken, appId, activityKey, isFirst] = useGetWorkbookParams();

  const setButtonFloat = () => {
    if (scrollRef.current) {
      const calc = context.biggerMargin ? 14 : 0;
      // console.log('height', scrollRef.current.scrollHeight, window.innerHeight - 67);
      if (scrollRef.current.scrollHeight > window.innerHeight - 67 - calc) {
        setIsButtonFloat(false);
      } else {
        setIsButtonFloat(true);
      }
    }
  };

  const resizeScrollHeight = (scrollHeight) => {
    setHeight((height) => height + parseInt(scrollHeight));
    console.log('changed height', height, parseInt(scrollHeight));
    console.log('height', height, window.innerHeight - 391);

    const calc = context.biggerMargin ? 14 : 0;

    if (height + parseInt(scrollHeight) > window.innerHeight - 391 - calc) {
      setIsButtonFloat(false);
    } else {
      setIsButtonFloat(true);
    }

    // setButtonFloat();
  };

  useEffect(() => {
    setButtonFloat();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios(3, authToken, appId).get('/emotion-recognition/load');
      const { result } = response.data;

      const dataSortByEmotion = [
        {
          title: '기쁨',
          situationList: result.situationByWordListOfPleasure,
          descriptionList: result.situationAndDescriptionOfPleasure,
          questionList: ['최근에 언제 기뻤나요?', '어떻게 기뻤나요?'],
        },
        {
          title: '슬픔',
          situationList: result.situationByWordListOfSadness,
          descriptionList: result.situationAndDescriptionOfSadness,
          questionList: ['최근에 언제 슬펐나요?', '어떻게 슬펐나요?'],
        },
        {
          title: '불쾌함',
          situationList: result.situationByWordListOfDiscomfort,
          descriptionList: result.situationAndDescriptionOfDiscomfort,
          questionList: ['최근에 언제 불쾌했나요?', '어떻게 불쾌했나요?'],
        },
        {
          title: '화남',
          situationList: result.situationByWordListOfAnger,
          descriptionList: result.situationAndDescriptionOfAnger,
          questionList: ['최근에 언제 화났나요?', '어떻게 화났나요?'],
        },
        {
          title: '두려움',
          situationList: result.situationByWordListOfFear,
          descriptionList: result.situationAndDescriptionOfFear,
          questionList: ['최근에 언제 두려웠나요?', '어떻게 두려웠나요?'],
        },
      ];

      console.log(dataSortByEmotion[0].descriptionList);
      setData(dataSortByEmotion);
    } catch (error) {
      console.log(error);
      webviewToast('error' + error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const goNextPage = () => {
    context.setCurrent(context.current + 1);
    goNext();
  };

  return (
    <div className="mt-40px px-[20px] relative h-[calc(100vh-57px)] overflow-auto scrollbar-hide">
      <div ref={scrollRef}>
        <p className="font-bold text-[24px] leading-[30px]  mb-[15px] text-[#26282C]" style={{ wordBreak: 'keep-all' }}>
          여러분의 감정
        </p>
        <TextWithBold
          texts={[
            '여러분이 이번 코스를 진행하면서 표현해본 감정과 신체 반응들을 모아보았어요. 하나씩 살펴보며 되돌아보는 시간을 가져볼게요.',
            <br />,
            <br />,
            '“내가 이때는 이런 감정을 느꼈었구나.” ',
          ]}
        />
        <div className="min-h-[370px] mt-[40px]" ref={accordianRef}>
          {data.length !== 0 && (
            <div>
              {data.map((item, index) => (
                <EmotionSummaryAccordianItem item={item} resizeScrollHeight={resizeScrollHeight} />
              ))}
            </div>
          )}
        </div>
        <div
          className={`${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'} rounded-[16px] mt-[40px]
      ${isButtonFloat ? 'absolute bottom-0 w-[calc(100%-40px)]' : 'static'}
      `}
        >
          <SecondaryActionButton state="ACTIVE" text={'다음'} action={goNextPage} />
        </div>
      </div>
    </div>
  );
};

export default EmotionSummary;
