/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { CheckLottie } from '../../stories/animation/CheckLottie';
import Progressbar from 'react-js-progressbar';
import { AuthContext } from '../../context';

function EndProcessing(props) {
  const { title, steps, isDone } = props;
  const [loaded, setLoaded] = useState(Array.from({ length: steps.length }, (v, idx) => false));
  const [percentage, setPercentage] = useState(0);
  const context = useContext(AuthContext);

  const duration = 1050;
  const percentageList = [11, 35, 53, 85, 91, 100];

  function eachStepDone(i) {
    setTimeout(() => {
      setPercentage(percentageList[i]);
      setLoaded(Array.from({ length: steps.length }, (v, idx) => (idx <= i - 1 ? true : false)));
      if (i === steps.length - 1) {
        setTimeout(() => {
          setLoaded(Array.from({ length: steps.length }, (v, idx) => true));
          finish();
        }, duration);
      }
    }, 500 + i * duration);
  }

  useEffect(() => {
    for (var i = 0; i < steps.length; i++) {
      eachStepDone(i);
    }
  }, []);

  function finish() {
    setTimeout(() => {
      context.setShowProgressingbar(false);
      isDone();
    }, 1000);
  }

  return (
    <div className={`h-full px-[20px] pt-[7px] pb-[54px] flex flex-col justify-between`}>
      <div className="font-bold text-[24px] leading-[28.8px] text-[#26282C]" style={{ wordBreak: 'keep-all' }}>
        {title}
      </div>
      <div className="mx-auto  w-[240px] h-[240px]  ">
        <Progressbar
          input={percentage}
          pathWidth={29}
          pathColor={'#3953D9'} // use an array for gradient color.
          trailWidth={29}
          trailColor="#F3F5F8" // use a string for solid color.
          textStyle={{ fill: '#26282C', fontSize: '60px', fontWeight: '700' }} // middle text style
          pathLinecap={'none'}
          pathShadow={'none'}
          animation={{
            duration: 1000,
            delay: 0,
            ease: 'easeInSine',
          }}
        ></Progressbar>
      </div>
      <div className="w-full   ">
        {steps.map((text, index) => (
          <div className="flex justify-between items-center">
            <div
              key={index}
              className={` text-[17px] leading-[20.4px] mb-[8px] ${
                loaded[index] ? 'text-[#26282C]' : 'text-[#A4A6B0]'
              }`}
            >
              {text}
            </div>
            {!loaded[index] && (
              <div className="pr-[4px]">
                <div className=" animate-spin">
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.3 4C7.6866 4 8.00622 4.31575 7.93891 4.69644C7.852 5.18794 7.6735 5.66092 7.41056 6.08999C7.02512 6.71898 6.47325 7.22912 5.81596 7.56403C5.15868 7.89893 4.42158 8.04555 3.68616 7.98767C2.95075 7.92979 2.24566 7.66967 1.64886 7.23607C1.05206 6.80247 0.586784 6.21227 0.304482 5.53073C0.0221804 4.8492 -0.0661534 4.10287 0.0492466 3.37426C0.164647 2.64566 0.479285 1.96315 0.958376 1.40221C1.2852 1.01955 1.67987 0.703622 2.12045 0.469091C2.46171 0.287432 2.86078 0.493836 2.98024 0.861514C3.09971 1.22919 2.89042 1.61708 2.56777 1.83004C2.36546 1.96357 2.18186 2.12537 2.02294 2.31144C1.71154 2.67605 1.50702 3.11968 1.43201 3.59327C1.357 4.06687 1.41442 4.55198 1.59791 4.99498C1.78141 5.43798 2.08384 5.8216 2.47176 6.10344C2.85968 6.38529 3.31799 6.55436 3.79601 6.59198C4.27403 6.62961 4.75314 6.5343 5.18038 6.31662C5.60761 6.09893 5.96633 5.76734 6.21686 5.3585C6.34472 5.14986 6.44186 4.92525 6.50633 4.69158C6.60917 4.31891 6.9134 4 7.3 4Z"
                      fill="#3953D9"
                    />
                  </svg>
                </div>
              </div>
            )}
            {loaded[index] && <CheckLottie containerSize={'18px'} gradient={false} />}
          </div>
        ))}
      </div>
    </div>
  );
}

export default EndProcessing;
