/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../stories/page/BottomActionAnxy';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import userAtom from '../../recoil/anxy/user/atom';
import Wori from '../../components/anxy/anxy12ndA/Wori';
import walkthrough1 from '../../image/anxy/12B/walkthrough1.png';
import walkthrough2 from '../../image/anxy/12B/walkthrough2.png';
import Lottie from '../../components/elements/Lottie';
import anxy_big_lottie from '../../image/anxy/12A/anxy_big_lottie.json';
import CountUp from 'react-countup';
import anxyWebCoreExp6 from '../../image/anxy/anxyWeb/anxyWebCoreExp6.png';
import anxyWebCoreExp7 from '../../image/anxy/anxyWeb/anxyWebCoreExp7.png';
import anxyWebCoreExp8 from '../../image/anxy/anxyWeb/anxyWebCoreExp8.png';
import anxyWebCoreExp9 from '../../image/anxy/anxyWeb/anxyWebCoreExp9.png';
import anxyWebCoreExp10 from '../../image/anxy/anxyWeb/anxyWebCoreExp10.png';
import { motion } from 'framer-motion';
import useMixpanelEventAnxy from '../../hooks/useMixpanelEventAnxy';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import anxyColorPalette from '../../data/anxy/contents/anxyColorPalette';
import anxySessionData from '../../data/anxy/anxySessionData';
import { Caption15, SubTitle18 } from '../../stories/text/AnxyText';
import useSessionStorage from '../../hooks/useSessionStorage';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { impactFeedback } from '../../utils/webview';

function Guide({ aboveTitleContent, title, noGradient, content, buttonText, action, bgColor, paddingX }) {
  const [searchParams] = useSearchParams();
  const at = searchParams.get('at');

  return (
    <BottomActionAnxy
      content={
        <div className="flex-1 h-full flex flex-col">
          {aboveTitleContent}
          <div className="pt-[12px] text-[24px] leading-[30px] font-bold px-[20px]">{title}</div>
          <div className={`w-full h-full flex-1 relative flex ${paddingX && 'px-[20px]'} `}> {content}</div>
        </div>
      }
      noGradient={noGradient}
      buttonText={buttonText}
      action={action}
      bgColor={bgColor || '#ffffff'}
      fixedRoot={at === undefined}
    />
  );
}

export default function AnxyWebCoreExp(props) {
  let { id } = useParams();
  id = parseInt(id);
  const setHeaderState = useSetRecoilState(headerAtom);
  const [userState, setUserState] = useRecoilState(userAtom);

  const [searchParams] = useSearchParams();
  const at = searchParams.get('at');
  const uiType = searchParams.get('uiType');

  const [sessionList, setSessionList] = useSessionStorage('sessionList', undefined);

  const setMixpanel = useMixpanelEventAnxy({
    eventName: id === 0 ? '핵심 경험 설명' : '효과 설명',
  });

  const [checkpointSaveData, checkpointSave] = useFetchAnxy({
    url: `anxy/v2/onboarding/checkpoint/save`,
    requestBody: { checkpointId: id === 11 ? 3 : id === 12 ? 4 : '' },
    bg: '#FFFFFF',
  });

  const [homeDetail, getHomeDetail] = useFetchAnxy({
    url: `/anxy/chapter/detail`,
    bg: '#FFFFFF',
  });

  const woriContainerRef = useRef();
  const [score, setScore] = useState(userState.anxietyScore !== undefined ? userState.anxietyScore : 50);
  const goNext = useNavigateWithParams(`/anxyWebCoreExp/${id + 1}`);
  const goSubmitInfo = useNavigateWithParams(`/submitInfo/0?type=anxy`);
  const goSession1 = useNavigateWithParams(`/anxy/session_aa/0`);
  const goOnboarding = useNavigateWithParams(`/anxyOnboarding/0?uiType=${uiType}`);

  useEffect(() => {
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_BACK,
      progressBar: { maxCompleted: 17, completed: id + 4 },
    }));
    if (id === 0 || id === 12) {
      setMixpanel();
    }

    if (id === 13) {
      getHomeDetail();
    }
  }, []);

  useDidMountEffect(() => {
    impactFeedback('selection');
  }, [score]);

  useEffect(() => {
    if (homeDetail) {
      const flattenedList = homeDetail.result.chapterList.map((element) => element.sectionList).flat();
      const changedList = [...flattenedList];
      setSessionList(changedList);
    }
  }, [homeDetail]);

  useEffect(() => {
    setUserState((state) => ({
      ...state,
      anxietyScore: score,
    }));
  }, [score]);

  return id === 0 ? (
    <Guide
      title={'우리는 Wori와 함께 불안 관리 여정을 떠날 거예요'}
      noGradient
      content={
        <img
          src={walkthrough1}
          alt={'empty'}
          className="absolute left-[50%] translate-x-[-50%] bottom-0 translate-y-[74px] w-[580px] min-w-[580px] object-contain w-full h-full"
        />
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 1 ? (
    <Guide
      title={'인사해요, 당신의 Wori에요'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%] origin-bottom"
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Wori score={0} duration={0} hand />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col opacity-0 ">
            <p className="text-[50px] font-bold text-center mb-[20px]">{userState.anxietyScore}</p>

            <div className="w-full h-[10px] rounded-[50px] bg-[#FDF0E7]">
              <div className=" h-full  rounded-[50px] bg-anxyOrange" style={{ width: `${userState.anxietyScore}%` }} />
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 2 ? (
    <Guide
      title={'Wori는 불안하면 몸집이 커져요'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%] origin-bottom"
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Wori initialScore={0} score={100} duration={2} delay={0.2} />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col ">
            {/* <p className="text-[50px] font-bold text-center mb-[20px]">{score}</p> */}
            <CountUp
              end={100}
              start={0}
              duration={2}
              delay={0.2}
              className="text-[50px] font-bold text-center mb-[20px]"
            />
            <div className="w-full h-[10px] rounded-[50px] bg-[#FDF0E7]">
              <motion.div
                className=" h-full  rounded-[50px] bg-anxyOrange"
                initial={{ width: `0%` }}
                animate={{ width: '100%' }}
                transition={{ duration: 2, delay: 0.2 }}
                // style={{ width: `${score}%` }}
              />
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 3 ? (
    <Guide
      title={'지금 얼마나 불안한가요?'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%]  origin-bottom "
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Wori score={score} duration={0} />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col ">
            <p className="text-[50px] font-bold text-center">{score}</p>
            <div className="flex px-[4px] w-full justify-between text-[15px] leading-[21px] text-[#6B6D76] mb-[8px]">
              <p>매우 평온</p>
              <p>매우 불안</p>
            </div>
            <div
              className="w-full h-[24px] rounded-[50px]  px-[22px]"
              style={{
                background: `linear-gradient(90deg, rgba(239, 103, 11) 50%, rgba(253, 240, 231) 50%)`,
              }}
            >
              <div
                className="w-full h-full  relative"
                style={{
                  background: `linear-gradient(90deg, rgba(239, 103, 11) ${score}%, rgba(253, 240, 231) ${score}%)`,
                }}
              >
                <div
                  className=" w-[44px] h-[44px] rounded-[50%] bg-white ring-[1px] ring-inset ring-[#F3F5F8] absolute top-[50%] translate-x-[-50%] translate-y-[-50%]
                flex items-center justify-center"
                  style={{ left: `${score}%`, boxShadow: '0px 6px 8px -2px rgba(0, 0, 0, 0.2)' }}
                  onTouchMove={(e) => {
                    setScore(
                      Math.round(
                        Math.max(
                          0,
                          Math.min(
                            100,
                            parseInt(((e.changedTouches[0].clientX - 20) / (window.innerWidth - 40)) * 100),
                          ),
                        ) / 10,
                      ) * 10,
                    );
                  }}
                >
                  <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="9" width="3" height="18" rx="1.5" fill="#D1D5DC" />
                    <rect width="3" height="18" rx="1.5" fill="#D1D5DC" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 4 ? (
    <Guide
      title={'무서워 할 필요 없어요'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%] w-[350px] h-[412px] origin-bottom"
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Lottie lottieData={anxy_big_lottie} autoplay />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col ">
            <p className="text-[50px] font-bold text-center mb-[20px]">{score}</p>

            <div className="w-full h-[10px] rounded-[50px] bg-[#FDF0E7]">
              <div className=" h-full  rounded-[50px] bg-anxyOrange" style={{ width: `${score}%` }}></div>
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 5 ? (
    <Guide
      title={'이제 우리는 불안을 다스릴 거니깐요'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%] origin-bottom"
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Wori initialScore={100} score={0} duration={2} delay={0.2} downArrow />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col ">
            <CountUp
              end={0}
              start={score}
              duration={2}
              delay={0.2}
              className="text-[50px] font-bold text-center mb-[20px]"
            />
            <div className="w-full h-[10px] rounded-[50px] bg-[#FDF0E7]">
              <motion.div
                className=" h-full  rounded-[50px] bg-anxyOrange"
                initial={{ width: `${score}%` }}
                animate={{ width: '0%' }}
                transition={{ duration: 2, delay: 0.2 }}
              />
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 6 ? (
    <Guide
      title={'앞으로 불안할 때마다 꾸준히 기록을 해나갈 거예요'}
      noGradient
      content={
        <img
          src={anxyWebCoreExp6}
          alt={'empty'}
          className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] w-full h-full  object-contain"
        />
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 7 ? (
    <Guide
      title={'그러면 우리가 언제 어떻게 불안해 하는지 파악이 가능하죠'}
      noGradient
      content={
        <img
          src={anxyWebCoreExp7}
          alt={'empty'}
          className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] w-full h-full p-[20px] object-contain "
        />
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 8 ? (
    <Guide
      aboveTitleContent={
        <div className="flex flex-row gap-[8px] px-[20px] pt-[12px]">
          {['신체', '생각', '행동'].map((each, index) => (
            <div
              key={`category1${index}`}
              className={`px-[8px] py-[2px] rounded-[4px] text-white ${
                index === 0 ? 'bg-[#26282C]' : 'bg-[rgba(38,40,44,0.2)] '
              } `}
            >
              {each}
            </div>
          ))}
        </div>
      }
      title={'첫째로 점진적 근육 이완법으로 긴장된 몸을 이완할 거예요'}
      noGradient
      content={
        <img
          src={anxyWebCoreExp8}
          alt={'empty'}
          className="absolute left-[50%] translate-x-[-50%] bottom-0 w-full h-full px-[20px] pt-[20px] object-contain object-bottom "
        />
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 9 ? (
    <Guide
      aboveTitleContent={
        <div className="flex flex-row gap-[8px] px-[20px] pt-[12px]">
          {['신체', '생각', '행동'].map((each, index) => (
            <div
              key={`category2${index}`}
              className={`px-[8px] py-[2px] rounded-[4px] text-white ${
                index === 1 ? 'bg-[#26282C]' : 'bg-[rgba(38,40,44,0.2)] '
              } `}
            >
              {each}
            </div>
          ))}
        </div>
      }
      title={'둘째로 인지 재구성을 통해 불안을 유발하는 생각을 떨쳐낼 거예요'}
      noGradient
      content={
        <img
          src={anxyWebCoreExp9}
          alt={'empty'}
          className="absolute left-[50%] translate-x-[-50%] bottom-0 w-full h-full px-[20px] pt-[20px] object-contain object-bottom "
        />
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 10 ? (
    <Guide
      aboveTitleContent={
        <div className="flex flex-row gap-[8px] px-[20px] pt-[12px]">
          {['신체', '생각', '행동'].map((each, index) => (
            <div
              key={`category3${index}`}
              className={`px-[8px] py-[2px] rounded-[4px] text-white ${
                index === 2 ? 'bg-[#26282C]' : 'bg-[rgba(38,40,44,0.2)] '
              } `}
            >
              {each}
            </div>
          ))}
        </div>
      }
      title={'셋째로 노출 요법을 통해 불안을 마주하고 내구력을 높일 거예요'}
      noGradient
      content={
        <img
          src={anxyWebCoreExp10}
          alt={'empty'}
          className="absolute left-[50%] translate-x-[-50%] bottom-0 w-full h-full px-[20px] pt-[20px] object-contain object-bottom "
        />
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : id === 11 ? (
    <Guide
      title={'함께하다 보면 어느새 불안감으로부터 자유로워질 거랍니다'}
      noGradient
      content={
        <img
          src={walkthrough2}
          alt={'empty'}
          className="absolute left-[50%] translate-x-[-50%] bottom-0 translate-y-[74px] w-[580px] min-w-[580px] object-contain w-full h-full"
        />
      }
      buttonText={'다음'}
      action={() => {
        checkpointSave();
        goNext();
      }}
    />
  ) : id === 12 ? (
    <Guide
      title={'효과가 입증된 연구 결과를 바탕으로 만들어졌으니까요'}
      content={
        <div className="px-[20px]">
          <div className="flex flex-col gap-[40px] my-[50px] ">
            {[
              { percentage: 71, effect: '불안 증상의 임상적 개선' },
              { percentage: 57, effect: '전반적인 기분 개선' },
              { percentage: 47, effect: '불면증 개선' },
            ].map((each, index) => (
              <div className="flex flex-col gap-[8px] items-center " key={`effect${index}`}>
                <div className="flex text-[50px] text-anxyOrange font-bold">
                  <p>{each.percentage}%</p>
                </div>
                <div className="text-[17px] leading-[23px] text-[#6B6D76]">{each.effect}</div>
              </div>
            ))}
          </div>
          <div className="border-t-[1px] border-[#E1E4EB] pt-[20px] text-[14px] leading-[20px] text-[rgba(0,0,0,0.4)]">
            {
              'Carl, J. R., Miller, C. B., Henry, A. L., Davis, M. L., Stott, R., Smits, J. A., … & Espie, C. A. (2020). Efficacy of digital cognitive behavioral therapy for moderate-to-severe symptoms of generalized anxiety disorder: A randomized controlled trial. Depression and Anxiety, 37(12), 1168-1178.'
            }
          </div>
        </div>
      }
      buttonText={'다음'}
      action={() => {
        if (at) {
          checkpointSave();
          goOnboarding();
          // goNext();
        } else {
          goSubmitInfo();
        }
      }}
    />
  ) : (
    <Guide
      title={'그럼 첫 번째 세션으로 시작해볼게요'}
      content={
        <div className="self-center w-full mx-auto max-w-[250px] w-fit h-fit rounded-[20px] relative overflow-hidden">
          <img
            src={anxySessionData.find((element) => element.sectionId === 'session_aa').image_big}
            alt={'empty'}
            className="w-full "
          />
          <div className="absolute w-full bottom-0 p-[24px] bg-anxyBlack flex flex-col items-center justify-center gap-[8px] text-center ">
            <SubTitle18
              subtitle={anxySessionData.find((element) => element.sectionId === 'session_aa').title}
              color={'#ffffff'}
            />
            <div className="flex items-center gap-[4px]">
              <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.6"
                  d="M8.5 4.63397C9.16667 5.01887 9.16667 5.98113 8.5 6.36603L1.75 10.2631C1.08333 10.648 0.25 10.1669 0.25 9.39711L0.25 1.60289C0.25 0.833085 1.08333 0.35196 1.75 0.73686L8.5 4.63397Z"
                  fill="white"
                />
              </svg>
              <Caption15 caption={'1분'} color={'rgba(255,255,255,0.6)'} />
            </div>
          </div>
        </div>
      }
      paddingX
      noGradient
      bgColor={anxyColorPalette.oat}
      buttonText={'다음'}
      action={() => {
        setHeaderState((state) => ({
          ...state,
          progressBar: undefined,
        }));
        goSession1();
      }}
    />
  );
}
