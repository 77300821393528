import React from 'react';
import { useRecoilValue } from 'recoil';
import deviceAtom from '../../recoil/common/device';

export default function AnxyNotch(props) {
  const { notchColor, noHeader, appNotch } = props;
  const deviceState = useRecoilValue(deviceAtom);

  return (
    <div
      className={`fixed top-0 left-0 w-screen z-[100] pointer-events-none`}
      style={{
        height: appNotch ? 0 : noHeader ? deviceState.notchSize : deviceState.notchSize + 44,
        backgroundColor: notchColor,
      }}
    />
    // <div className={`fixed top-0 left-0 w-screen z-[50]`} style={{ height: '44px', backgroundColor: '#FFFFFF' }} />
  );
}
