import { useState } from 'react';
import { useCallback, useEffect } from 'react';
import selfCheckData from '../../data/selfCheckData';
import { useGetWorkbookParams } from '../../hooks/useGetWorkbookParams';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { showWebView } from '../../utils/webview';
import CurrentExamOnCoaching from './CurrentExamOnCoaching';

export const OnboardingOngoing = ({ onboardingCase }) => {
  const { selfCheckId, title, allSelfCheckIdList } = onboardingCase;
  const recommendCheckItem = selfCheckData.filter((item) => item.selfCheckId === selfCheckId)[0];
  const [authToken, appId] = useGetWorkbookParams();
  const [selfCheckList, setSelfCheckList] = useState([]);

  useEffect(() => {
    setSelfCheckList((prev) => {
      const mapList = [];
      allSelfCheckIdList.forEach((orderedData) => {
        selfCheckData.forEach((selfCheckData) => {
          if (selfCheckData.selfCheckId === orderedData) {
            mapList.push({ selfCheckName: selfCheckData.selfCheckName });
            console.log(selfCheckData.selfCheckName);
          }
        });
      });
      return mapList;
    });
  }, [allSelfCheckIdList]);

  const goToOnboarding = useCallback(() => {
    showWebView({ url: `startOnboarding?at=${authToken}&app_id=${appId}&refresh=true` });
  }, [authToken, appId]);

  return (
    <div className="">
      <div>
        <div className="w-full relative bg-white rounded-[20px] overflow-hidden">
          <div className="w-full h-full px-[20px] pt-[30px] pb-[20px] flex flex-col items-between">
            <p className=" w-full px-[4px] text-[#26282C] font-bold leading-[28px] text-[22px]  ">{title}</p>
            <div
              className="h-[193px] pl-[13.6px] pr-[14.4px] pt-[20px] pb-[40px] px-[15px] rounded-[15px] bg-[#F3F5F8] my-[25px] text-[#26282C] text-[15px] leading-[21px] font-bold"
              style={{
                border: '0.8px solid rgba(0,0,0,0.1)',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
              }}
            >
              <div className="h-full text-[15px] leading-[21px] flex flex-col justify-between">
                <div className="flex justify-center">
                  <span className="">
                    📋{' '}
                    <span className="text-[#2C4BEC]">{`${allSelfCheckIdList.length - selfCheckId + 1}개의 검사`}</span>
                    {`(약 ${
                      Math.round((allSelfCheckIdList.length - selfCheckId + 1) * 0.4) === 0
                        ? 1
                        : Math.round((allSelfCheckIdList.length - selfCheckId + 1) * 0.4)
                    }분 소요)`}
                  </span>
                </div>
                <CurrentExamOnCoaching id={selfCheckId} selfCheckList={selfCheckList} />
              </div>
            </div>
            <div className="">
              <PrimaryActionButton state="ACTIVE" text={'검사 계속하기'} action={goToOnboarding} medium />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingOngoing;
