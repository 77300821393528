import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingLottie } from '../../../stories/animation/LoadingLottie';
import PaymentConfirmTemplate from 'pages/PaymentConfirmTemplate';

export default function SomnyPaymentSuccess() {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('at');
  const appId = searchParams.get('app_id');

  return (
    <PaymentConfirmTemplate
      confirmUrl={`/somny/payment/confirm`}
      sucessUrlPath={`/somnyHome?at=${accessToken}&app_id=${appId}`}
      failUrlPath={`/somnyPayment-confirm-fail?at=${accessToken}&app_id=${appId}`}
      loadingComponent={<LoadingLottie containerSize="50px" />}
      bgColor={'#060727'}
    />
  );
}
