/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import SleepEfficiencyGraph from '../../components/sleepingReport/SleepEfficiencyGraphXYZ';
import SleepSolution from '../../components/sleepingReport/SleepSolution';
import { convertMinutesToISO } from '../../components/sleepingReport/calculateTime';
import useSessionStorage from '../../hooks/useSessionStorage';
import { TextWithBold } from '../../components/workbook/TextWithBold';
import { BottomAction } from '../../stories/page/BottomAction';
import xyzChart from '../../image/xyzchart.png';
import 수면처방1 from '../../image/수면처방1.png';
import 수면처방2 from '../../image/수면처방2.png';

export default function SleepingReportXYZ(props) {
  const [wakeupTime] = useSessionStorage('wakeUpTime');
  const [sleepingData] = useSessionStorage('sleepingData');

  console.log(wakeupTime, sleepingData);
  const inBedTime = sleepingData.inBedMinutes;
  const sleepTime = sleepingData.sleepMinutes;
  const wakeUpTimeMin = wakeupTime.hour * 60 + wakeupTime.minute;
  const efficiency = parseInt((sleepTime / inBedTime) * 100);
  const goToBedTime =
    inBedTime === 0
      ? NaN
      : efficiency >= 85
      ? convertMinutesToISO(wakeUpTimeMin - sleepTime)
      : convertMinutesToISO(wakeUpTimeMin - Math.max(sleepTime, 300));
  useEffect(() => {
    const root = document.getElementById('root');
    root.style.position = 'relative';
    root.style.overflow = 'auto';

    if (inBedTime === 0 || wakeupTime === undefined) {
      window.location.href = `/sleepingSurvey/1`;
    }
  }, []);
  const Element = (
    <div className={`w-full h-full overflow-auto scrollbar-hide  `}>
      <div className={`pt-[30px] pb-[40px] px-[20px]  bg-[#F3F5F8]`}>
        <div className="pb-[40px]">
          <p className="text-[20px] leading-[26px] text-[#26282C] font-bold mb-[10px]">나의 수면 효율</p>
          <div className="flex flex-col gap-[10px] text-[12px] leading-[18px]">
            <SleepEfficiencyGraph efficiency={efficiency} inBedTime={inBedTime} sleepTime={sleepTime} />
          </div>
        </div>
        <div>
          <p className="text-[20px] leading-[26px] text-[#26282C] font-bold mb-[10px]">나에게 맞는 수면 시간</p>
          <div className="flex flex-col gap-[10px] mb-[10px]">
            <SleepSolution id={1} time={goToBedTime} />
            <SleepSolution id={2} time={convertMinutesToISO(wakeUpTimeMin)} />
          </div>
          {efficiency < 85 && (
            <div className=" rounded-[16px] px-[10px] pt-[10px] text-[13px] font-semibold">
              {
                '❗️ 예상치 못한 시간일 수 있어요. 수면 인지행동치료(CBT)의 수면 제한 요법에 따라 침대에 머무는 시간을 제한해서 수면 패턴을 다시 설정할 거예요.'
              }
            </div>
          )}
          <p className="font-bold text-[20px] leading-[26px] mt-[40px] mb-[10px]">Sleep Doctor의 수면 처방 🩺</p>
          <div className="bg-white rounded-[16px] p-[20px] text-[15px] leading-[24px]  mb-[30px]">
            {inBedTime > 0 ? (
              <TextWithBold
                fontSize="text-[15px]"
                fontWeight="font-normal"
                lineHeight="leading-[24px]"
                texts={[
                  `Sleep Doctor가 인지행동치료의 `,
                  `수면 제한 요법`,
                  `에 따라 침대에 들어가는 최적의 시간을 처방해드렸어요. 수면 제한 요법이란, 수면 효율을 높이기 위해 수면 시간을 제한하는 것이랍니다. 

                    수면효율이 낮은 경우, 처음에 제안드리는 시각이 너무  늦다고 생각하실 수 있어요. 수면 제한 요법에서 가장 어려운 부분이랍니다. 하지만 꾸준하게 기록하면 주차별로 다른 처방을 드릴거에요. 처방된 시간을 잘 지키신다면 수면시간을 20분씩 늘려갈 거랍니다. `,
                  `연구 결과에 따르면 처방 시간을 잘 지킨 사람의 88%는 주에 1시간 40분을 더 잘 수 있었어요.\n\n`,
                  `푹 주무시고 싶나요? Sleep Doctor가 가능하게 해드립니다. 하루에 5분만 Sleep Doctor의 수면 기록에 투자하세요.\n\n`,
                  `매일매일 수면을 기록하고 처방된 시간에 잠드는 것만 지켜주세요.`,
                  ` 8주 뒤에는 눕자마자 잠들 수 있게 해드립니다.`,
                ]}
              />
            ) : (
              '수면 기록을 정확히 해주세요.'
            )}
          </div>
          <div className="mb-[30px]">
            <p className="text-[20px] leading-[26px] text-[#26282C] font-bold mb-[10px] mt-[40px]">
              주차별로 수면 시간을 늘려가요
            </p>
            <img src={xyzChart} className="w-full h-full" alt="empty" />
          </div>
          <p className="font-bold text-[20px] mb-[10px] whitespace-nowrap">8주 뒤엔 눕자마자 잠들게 해드립니다 💤</p>
          <div className="bg-white rounded-[16px] p-[20px] text-[15px] leading-[24px]  mb-[40px]">
            <TextWithBold
              fontSize="text-[15px]"
              fontWeight="font-normal"
              lineHeight="leading-[24px]"
              texts={[
                `Sleep Doctor의 불면증 치료 프로그램은 서울 의대 출신 의사들과 심리전문가로 구성된 연구진들이 만들었어요. 과학에 근거하고 임상으로 증명된 `,
                `수면 기록과 처방으로 이루어진 치료 방법`,
                `입니다.\n
                  미국내과학회(ACP)에서는 만성화된 `,
                `불면증에 대해 인지행동치료를 가장 먼저 적용할 것을 강력하게 권고`,
                `할만큼, 불면증에 가장 효과적인 치료는 '인지행동치료' 에 기반한다고 해요.`,
              ]}
            />
          </div>
        </div>
      </div>
      <div
        className="bg-black py-[20px] text-[17px] leading-[24px] text-white"
        style={{
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
        }}
      >
        <div className=" px-[15px]  max-w-[335px] mx-auto">
          <p className="font-bold text-[26px] leading-[26px] mt-[30px] mb-[50px]">Sleep Doctor</p>
          <p className="font-bold text-[22px] leading-[26px] mt-[30px] mb-[50px]">
            눕자마자 잠드는 경험, <br />
            8주 뒤에 경험해 보세요
          </p>
          <div className="mb-[50px]">
            <div className="mb-[50px] flex justify-center" style={{ gap: '20px' }}>
              <p className="font-bold mb-[20px] w-[100px] flex items-center text-[20px] leading-[26px]">
                5분 안에 <br />
                끝나는 <br />
                수면 기록
              </p>
              <img src={수면처방1} alt="" className="w-[180px]" />
            </div>
            <p className="flex max-w-[315px] mx-auto text-[16px] leading-[22px]">{`침대에 오래 머무른다고\n더 오래 잘 수 있는 건 아니에요.\n\n뒤척이는 시간을 파악해서 수면 시간을 늘릴 수 있게 도와드릴게요.\n\n매일매일 수면 시간만 기록해주세요.`}</p>
          </div>
          <div className="mb-[30px] text-right mt-[50px]">
            <div className="mb-[50px] flex justify-center" style={{ gap: '20px' }}>
              <img src={수면처방2} alt="" className="w-[180px]" />
              <p className="font-bold mb-[20px] w-[100px] flex items-center text-[20px] leading-[26px] text-right">
                7일 주기로 <br />
                제공되는 <br />
                수면 처방
              </p>
            </div>
            <p className="flex justify-end max-w-[315px] mx-auto text-[16px] leading-[22px]">{`이번 주 기록을 바탕으로\n다음 주 수면 시간 가이드를 드려요.\n\n연구 결과에 따르면 수면 처방을 잘 지킨\n88%의 사람들이\n한 주에 1시간 40분을 더 잘 수 있었어요.`}</p>
          </div>
        </div>
        <div className="w-full px-[30px] bg-[#F7F7F7] text-center mx-auto py-[50px] text-black">
          <div className="mb-[30px] mb-[10px]">
            <p className="text-[18px] font-bold">인지행동치료</p>
            <p className="text-[#888888] mt-[5px] font-medium">{`임상적으로 증명된 수면 인지행동치료(CBT) 기법에 근거한 프로그램`}</p>
          </div>
          <div className="mb-[30px] mb-[10px]">
            <p className="text-[18px] font-bold">서울의대 출신 의사 및 심리전문가</p>
            <p className="text-[#888888] mt-[5px] font-medium">{`서울의대 출신 의사 및 심리전문가들이 연구한 불면증 치료 프로그램`}</p>
          </div>
          <div className="mb-[10px]">
            <p className="text-[18px] font-bold">비약물적치료</p>
            <p className="text-[#888888] mt-[5px] font-medium">{`만성화된 불면증에 효과없는 수면제와 달리, 내성 걱정 없이 안전한 치료 방법`}</p>
          </div>
        </div>
        <p className="text-center text-[22px] leading-[26px] font-bold mt-[60px] mb-[30px]">{`8주간 진행되는 \n불면증 치료 프로그램을 \n9,800원에 이용해보세요.`}</p>
      </div>
    </div>
  );
  return (
    <BottomAction
      content={Element}
      buttonText={'Sleep Doctor 출시 알림 받기 🔔'}
      buttonState={'ACTIVE'}
      action={() => {
        window.open('https://ftwl7crbb3s.typeform.com/to/QwTVbiRJ', '_blank');
      }}
      bgColor={'#F3F5F8'}
    />
  );
}
