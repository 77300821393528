import React from 'react';
import selfCheckData from '../../data/selfCheckData';
import { getSelfCheckState } from './selfCheckLogic';

export default function HistoryGraph(props) {
  const { selfCheckId, resultHistoryList, normalColor } = props;
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === parseInt(selfCheckId));

  return (
    <div className="w-full pr-[20px]">
      <div className=" h-[137px] py-[10px] border-[#A4A6B0] border-b-[1px] text-[12px] leading-[18px]">
        <div className="h-full relative text-[#A4A6B0]">
          <div className="w-[10px]  absolute top-[-8px] right-[-20px] ">{selfCheckInfo.maxScore}</div>
          <div className="w-[10px]  absolute bottom-[-8px] right-[-20px] ">{selfCheckInfo.minScore}</div>
          {/* <div
            className="w-[10px]  absolute right-[-20px] text-[#EF3E3E] translate-y-[8px]"
            style={{
              bottom: `${
                (selfCheckData.find((element) => element.selfCheckName === examName).abnormalScore /
                  selfCheckData.find((element) => element.selfCheckName === examName).maxScore) *
                100
              }%`,
            }}
          >
            H
          </div> */}
          {/* <div
            className="w-full h-[1px] bg-[#E1E4EB] absolute "
            style={{
              bottom: `${
                (selfCheckData.find((element) => element.selfCheckName === examName).abnormalScore /
                  selfCheckData.find((element) => element.selfCheckName === examName).maxScore) *
                100
              }%`,
            }}
          /> */}

          <div className="w-full h-full absolute   translate-y-[6px]">
            <div className="flex w-full h-full">
              {resultHistoryList.map((history, index) => (
                <div
                  key={`score${index}`}
                  className="h-full  relative"
                  style={{ width: `${100 / resultHistoryList.length}%` }}
                >
                  <div
                    className={`z-20 w-[14px] h-[14px] rounded-[12px] absolute left-[50%] translate-x-[-50%]  border-[2px] border-white
                 `}
                    style={{
                      bottom: `${(history.score / selfCheckInfo.maxScore) * 100}%`,
                      backgroundColor:
                        index === resultHistoryList.length - 1
                          ? resultHistoryList[index].isAbnormal
                            ? '#EF3E3E'
                            : normalColor || '#2C4BEC'
                          : '#D1D5DC',
                    }}
                  />
                  <div
                    className={`text-[#80838B] absolute left-[50%] translate-x-[-50%] bottom-[-48px] text-center whitespace-nowrap
                    ${index === resultHistoryList.length - 1 ? 'font-bold' : 'font-normal'}`}
                  >
                    <p className={`${index === resultHistoryList.length - 1 ? 'text-[#26282C]' : ''}`}>
                      {resultHistoryList[index].date
                        .split('-')
                        .slice(1)
                        .map((each, index) => parseInt(each))
                        .join('.')}
                    </p>
                    <p
                      style={{
                        color:
                          index === resultHistoryList.length - 1
                            ? resultHistoryList[index].isAbnormal
                              ? '#EF3E3E'
                              : normalColor || '#2C4BEC'
                            : '#D1D5DC',
                      }}
                    >
                      {selfCheckInfo.result[getSelfCheckState(parseInt(selfCheckId), history.score)].label}
                    </p>
                  </div>

                  {index < resultHistoryList.length - 1 && (
                    <div className="z-10 w-full h-full absolute left-[50%] translate-y-[-6px]">
                      {!(
                        (history.score === 0 && resultHistoryList[index + 1].score === 0) ||
                        (history.score === selfCheckInfo.maxScore &&
                          resultHistoryList[index + 1].score === selfCheckInfo.maxScore)
                      ) ? (
                        <svg style={{ width: '100%', height: '100%' }} xmlns="http://www.w3.org/2000/svg">
                          <line
                            x1="0"
                            y1={`${100 - (history.score / selfCheckInfo.maxScore) * 100}%`}
                            x2="100%"
                            y2={`${100 - (resultHistoryList[index + 1].score / selfCheckInfo.maxScore) * 100}%`}
                            stroke="#D1D5DC"
                            strokeWidth="2px"
                          />
                        </svg>
                      ) : (
                        <div
                          className={`w-full h-[2px] bg-[#D1D5DC] absolute  
                                ${
                                  history.score === 0 && resultHistoryList[index + 1].score === 0
                                    ? 'bottom-0 translate-y-[1px]'
                                    : 'top-0 translate-y-[-1px]'
                                }`}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
