const courseNameList = [
  '감정을 만드는 생각 찾기',
  '생각의 발자취 따라가기',
  '내 감정 돌아보기',
  '중요한 건 내 마음',
  '부정적인 감정 다스리기',
  '불면증, 잃어버린 밤을 찾아서',
];

export default function getCouseName(id) {
  let courseName = '';
  if (id > 47) {
    courseName = courseNameList[5];
  } else if (id > 37) {
    courseName = courseNameList[4];
  } else if (id > 31) {
    courseName = courseNameList[3];
  } else if (id > 20) {
    courseName = courseNameList[2];
  } else if (id > 13) {
    courseName = courseNameList[1];
  } else {
    courseName = courseNameList[0];
  }
  return courseName;
}
