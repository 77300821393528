import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const ComingSoonMentoringCard = ({ item }) => {
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    if (isPressed) {
      setTimeout(() => {
        setIsPressed(false);
      }, 1400);
    }
  }, [isPressed]);

  return (
    <motion.div onClick={() => setIsPressed(true)}>
      <div className="relative h-[162px] rounded-[16px] overflow-hidden mb-[15px]">
        <motion.div
          className="rounded-[16px] w-full h-full absolute top-0 bg-black opacity-[0] z-[30] "
          animate={{ opacity: isPressed ? 0.5 : 0 }}
          transition={{ duration: 0.2 }}
        />
        <motion.img
          alt="empty"
          src={item.image}
          className="w-full h-full object-cover mb-[15px]"
          animate={{ filter: isPressed ? 'blur(5px)' : 'blur(0px)' }}
          transition={{ duration: 0.2 }}
        />
        <motion.p
          className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[15px] leading-[21px] text-white opacity-0 z-[50]"
          animate={{ opacity: isPressed ? 1 : 0 }}
          style={{ whiteSpace: 'nowrap' }}
          transition={{ duration: 0.2 }}
        >
          오픈 예정이에요
        </motion.p>
      </div>
      <div className="mx-[4px]">
        <p className="mb-[4px] font-bold text-[17px] leading-[23px] text-[#26282C]">{item.title}</p>
        <p className="text-[15px] leading-[21px] text-[#80838B]">{item.subtitle}</p>
      </div>
    </motion.div>
  );
};

export default ComingSoonMentoringCard;
