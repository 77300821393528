import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { hasTouch } from 'detect-touch';
import { PressedEffect } from './PressedEffect';
import { Text17 } from '../text/AnxyText';
import anxyColorPalette from '../../data/anxy/contents/anxyColorPalette';

export function SelectButtonDevelop(props) {
  const { selected, option, text, isClicked, disable, activeColor, inactiveColor } = props;
  const [buttonDown, setButtonDown] = useState(false);

  function mouseUp(e) {
    setButtonDown(false);
  }
  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });
  return (
    <div className="w-fit">
      <PressedEffect
        element={
          <div
            className={`w-fit h-[54px] px-[20px]  flex items-center  rounded-[12px]`}
            style={{
              backgroundColor: selected ? `${activeColor}0A` : '',
              border: selected ? `1.2px solid ${activeColor}` : `1px solid ${inactiveColor}1A`,
              color: selected ? activeColor : `${inactiveColor}1A`,
            }}
            onMouseDown={(e) => {
              if (!hasTouch) {
                setButtonDown(true);
              }
            }}
            onClick={(e) => {
              console.log('action ');
              if (!hasTouch && !disable) {
                isClicked(option);
              }
            }}
            onTouchStart={(e) => {
              if (!buttonDown && !disable) {
                setButtonDown(true);
              }
            }}
            onTouchEnd={(e) => {
              setButtonDown(false);
              if (
                document
                  .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
                  .includes(e.currentTarget) &&
                !disable
              ) {
                isClicked(option);
              }
            }}
          >
            <Text17
              text={text}
              color={selected ? activeColor : anxyColorPalette.black}
              className={`${selected ? 'font-bold opacity-[1]' : 'font-semibold opacity-[0.6]'}`}
            />
          </div>
        }
        action={() => {}}
      />
    </div>
  );
}
