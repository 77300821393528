import React, { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from '../../../recoil/common/header/atom';
import useSetNotification from '../../../hooks/useSetNotification';
import { useParams, useSearchParams } from 'react-router-dom';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import notificationAtom from '../../../recoil/common/notification/atom';
import CongratsWithCheckLottie from './CongratsWithCheckLottie';
import ChangeNumberWithButton from './ChangeNumberWithButton';
import NotificationSettingCardList from './NotificationSettingCardList';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import notificationSettingAtom from '../../../recoil/common/notification/settingAtom';
import { impactFeedback } from '../../../utils/webview';

const getSortedArray = (array) => {
  array = new Set(array);
  array = [...array];
  array.sort(function (a, b) {
    const aHour = parseInt(a.split(':')[0]);
    const aMin = parseInt(a.split(':')[1]);

    const bHour = parseInt(b.split(':')[0]);
    const bMin = parseInt(b.split(':')[1]);

    console.log(aHour, bHour);
    if (aHour < 4 && bHour < 4) {
      return aHour * 60 + aMin - (bHour * 60 + bMin);
    } else if (aHour < 4) {
      return 1;
    } else if (bHour < 4) {
      return -1;
    } else {
      return aHour * 60 + aMin - (bHour * 60 + bMin);
    }
  });
  return array;
};

export default function AlarmTemplate() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const isSession = searchParams.get('isSession') === 'true';
  const [sessionList, setSessionList] = useState(['19:00']);
  const [worryNoteList, setWorrynoteList] = useState([]);

  const [loadNotification, setLoadNotification] = useFetchAnxy({
    url: '/anxy/v2/notification/setting/load',
  });

  const [notificationState, setNotificationState] = useRecoilState(notificationSettingAtom);
  const resetState = useResetRecoilState(notificationSettingAtom);

  const timeList = {
    session: {
      timeList: getSortedArray(notificationState.session.timeList),
    },
    worryNote: {
      timeList: getSortedArray(notificationState.worryNote.timeList),
    },
  };

  console.log('time', timeList);

  const goNext = useNavigateWithParams(`/anxyNotification/${parseInt(id) + 1}`);
  const goHome = useNavigateWithParams(`/anxyHome`);

  const setHeaderState = useSetRecoilState(headerAtom);
  const setNotification = useSetNotification(timeList, goHome, isSession);

  useEffect(() => {
    if (loadNotification && loadNotification.result) {
      setNotificationState((state) => ({
        ...state,
        session: {
          timeList: getSortedArray(loadNotification.result.session.timeList),
        },
        worryNote: {
          timeList: getSortedArray(loadNotification.result.worryNote.timeList),
        },
      }));
    }
  }, [loadNotification]);

  useEffect(() => {
    setSessionList(getSortedArray(notificationState.session.timeList));
    setWorrynoteList(getSortedArray(notificationState.worryNote.timeList));
  }, [notificationState]);

  const worryContents = [
    <CongratsWithCheckLottie title="첫 번째 걱정을 기록했어요" action={goNext} />,
    <ChangeNumberWithButton
      timeList={worryNoteList}
      state={notificationState}
      setState={setNotificationState}
      number={notificationState.worryNoteNumber}
      propsName="worryNoteNumber"
      title="하루에 몇 번 기록할까요?"
      subtitle="앞으로 걱정기록을 토대로 나는 어떤 상황에서 어떻게 불안한지 패턴을 찾아낼 거랍니다."
      action={goNext}
    />,
    <NotificationSettingCardList
      timeList={worryNoteList}
      state={notificationState}
      setState={setNotificationState}
      cardNumber={notificationState.worryNoteNumber}
      action={setNotification}
      linkAction={goHome}
    />,
  ];

  const sessionContents = [
    <ChangeNumberWithButton
      timeList={sessionList}
      number={notificationState.sessionNumber}
      state={notificationState}
      setState={setNotificationState}
      propsName="sessionNumber"
      title="하루에 몇 개의 세션을 들을까요?"
      subtitle="앞으로 불안 공략에 대한 탄탄한 이론을 배워나갈 거예요."
      isSession
      action={goNext}
    />,
    <NotificationSettingCardList
      timeList={sessionList}
      state={notificationState}
      setState={setNotificationState}
      cardNumber={notificationState.sessionNumber}
      action={setNotification}
      linkAction={goHome}
      isSession
    />,
  ];

  const currentData = isSession ? sessionContents : worryContents;

  useEffect(() => {
    if (parseInt(id) === 0) {
      resetState();
      if (!isSession) {
        impactFeedback('success');
      }
    }

    if (parseInt(id) === currentData.length - 1) {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#26282C', headerTitle: '' }));
    } else {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
    }

    if ((isSession && parseInt(id) === 0) || (!isSession && parseInt(id) === 1)) {
      setLoadNotification();
    }
  }, [id]);

  return <>{isSession ? sessionContents[parseInt(id)] : worryContents[parseInt(id)]}</>;
}
