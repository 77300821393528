import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import { MarketingActionButton } from '../../../stories/button/ActionButton';
import 'moment/locale/ko';
import useSessionStorage from '../../../hooks/useSessionStorage';
import TimePicker from '../../../components/workbook/course5/TimePicker';

export default function SelectWakeUp({ type }) {
  const { id, page } = useParams();

  const defaultTime = {
    hour: 7,
    minute: 0,
  };

  const defaultData = {
    sleepMinutes: 0,
    inBedMinutes: 0,
  };

  const [wakeupTime, setWakeUpTime] = useSessionStorage('wakeUpTime', defaultTime);
  const [sleepingData, setSleepingData] = useSessionStorage('sleepingData', defaultData);

  const [hour, setHour] = useState(wakeupTime.hour);
  const [minute, setMinute] = useState(wakeupTime.minute);

  const context = useContext(CoachingContext);
  const navigate = useNavigate();

  const goNext = () => {
    navigate(`/sleepingSurvey/${parseInt(id) + 1}`);
  };

  useEffect(() => {
    context.setShowProgressingbar(true);
    setSleepingData(defaultData);
  }, []);

  const getHour = (hour) => {
    setHour(hour);
    setWakeUpTime({ ...wakeupTime, hour: parseInt(hour) });
  };

  const getMinute = (minute) => {
    setMinute(minute);
    setWakeUpTime({ ...wakeupTime, minute: parseInt(minute) });
  };

  console.log('get', wakeupTime);

  const divs = document.getElementsByClassName('slick-list');
  for (var i = 0; i < divs.length; i++) {
    divs[i].style.height = '200px';
  }

  const WakeUpIcon = (
    <div className="flex items-center h-[25px]">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.8735 2.50781H13.1235V6.25781H11.8735V2.50781ZM16.2484 12.5078C16.2484 14.5789 14.5695 16.2578 12.4984 16.2578C10.4274 16.2578 8.74843 14.5789 8.74843 12.5078C8.74843 10.4367 10.4274 8.75781 12.4984 8.75781C14.5695 8.75781 16.2484 10.4367 16.2484 12.5078ZM5.87056 4.98556L4.98667 5.86944L7.63832 8.52109L8.52221 7.63721L5.87056 4.98556ZM22.4997 13.1328H18.7497V11.8828H22.4997V13.1328ZM20.0103 5.88944L19.1265 5.00556L16.4748 7.65721L17.3587 8.54109L20.0103 5.88944ZM11.8735 18.7544H13.1235V22.5044H11.8735V18.7544ZM17.3594 16.4836L16.4755 17.3675L19.1271 20.0192L20.011 19.1353L17.3594 16.4836ZM6.24805 11.8828V13.1328H2.49805V11.8828H6.24805ZM8.52148 17.3772L7.63759 16.4933L4.98594 19.145L5.86983 20.0288L8.52148 17.3772Z"
          fill="#FFC700"
        />
      </svg>

      <p className="ml-[5px] text-[15px] leading-[21px]">잠에서 깨는 시각</p>
    </div>
  );

  const typeInfo = [
    {
      type: 'wakeup',
      title: '앞으로 몇시에 일어날까요',
      subtitle: '너무 늦은 시간은 피하고, 평소에 깨어나는 시각으로 정해주세요.',
      icon: WakeUpIcon,
      api: '/sleep/expected-wake-up-time/submit',
      alarmType: 'sleep_record',
    },
  ];

  const info = typeInfo.find((item) => item.type === type);

  const hours = Array.from({ length: 24 }, (v, i) => `${i / 10 < 1 ? `0${i}` : i}`);
  return (
    <div className={`w-full px-20px h-full pb-[20px]`}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div>
          <div
            className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C]  mt-[40px] "
            style={{ wordBreak: 'keep-all' }}
          >
            수면 효율 계산기
          </div>
          <p className="text-[16px] leading-[28px] text-[#4E4F53] mt-[24px] mb-[20px] font-light">
            내가 얼마나 못 자고 있는지, 어떻게 하면 효율적으로 잘 수 있는지 궁금하지 않나요?
          </p>
          <p className="text-[16px] leading-[28px] text-[#4E4F53] mt-[24px] mb-[20px] font-light">
            불면증에 효과적인 인지행동치료 이론에 따라 <br />
            수면 효율과 침대에 들어갈 시간을 계산해 드릴게요.
          </p>
        </div>
        <div className="flex items-center justify-between rounded-[12px] p-[20px] text-white bg-[#fa9112]">
          {info.icon}
          <div className="text-[16px] leading-[22px] font-bold ">{`${hour}:${minute === 0 ? '00' : minute}`}</div>
        </div>
        <div>
          <TimePicker hour={hour} minute={minute} getHour={getHour} getMinute={getMinute} />
          <div className={`w-full rounded-[16px] mt-[20px] pb-[20px]`}>
            <MarketingActionButton state={'ACTIVE'} text={'다음'} action={goNext} />
          </div>
        </div>
      </div>
    </div>
  );
}
