import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { CoachingContext } from '../../../coachingContext';
import useFetch from '../../../hooks/useFetch';
import useSessionStorage from '../../../hooks/useSessionStorage';

const defaultValue = {
  hour: 23,
  minute: 0,
};
export default function GoToBedCard() {
  const context = useContext(CoachingContext);
  const [time, setTime] = useSessionStorage('goToBedTime', defaultValue);

  const [sleepingReportData, getSleepingData] = useFetch({
    url: `/sleep/report/detail`,
    bg: '#FFFFFF',
  });

  console.log(time);

  useEffect(() => {
    if (sleepingReportData) {
      if (sleepingReportData.result.prescription) {
        console.log('있어');
        const inBedTime = moment(sleepingReportData.result.prescription.inBedTime, 'H:m');
        const updated = { ...time };
        time.hour = inBedTime.hours();
        time.minute = inBedTime.minutes();
        setTime(updated);
      }
    }
  }, [sleepingReportData]);

  useEffect(() => {
    getSleepingData();
    setTime(defaultValue);
  }, []);

  return (
    <div className={`flex flex-col items-center justify-center h-[127px] my-[40px]`}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.04297 15.5594C4.04297 14.8966 4.58023 14.3594 5.24297 14.3594H24.756C25.4188 14.3594 25.956 14.8966 25.956 15.5594V20.4898H25.9552V22.8441H23.6074V20.4898H6.39079V22.8441H4.04297V20.4898V18.1484V15.5594Z"
          fill="#98A3E0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.40693 7.20312C6.41282 7.20312 5.60693 8.00901 5.60693 9.00313V13.464H24.3895V9.00313C24.3895 8.00901 23.5837 7.20312 22.5895 7.20312H7.40693ZM10.9313 10.2969C9.93724 10.2969 9.13135 11.1028 9.13135 12.0969V13.4273H20.8705V12.0969C20.8705 11.1028 20.0646 10.2969 19.0705 10.2969H10.9313Z"
          fill="#98A3E0"
        />
      </svg>
      <p className="text-[30px] leading-[36px] font-bold mt-[20px]">{`${time.hour}:${
        time.minute === 0 ? '00' : time.minute
      }`}</p>
    </div>
  );
}
