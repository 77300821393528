import React from 'react';
import { PrimaryActionButton } from '../button/ActionButton';
import { Title } from '../../stories/text/Title';
import LinkButton from '../button/LinkButton';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import { useRecoilValue } from 'recoil';

export function TopTitleBottomActionWithDefault(props) {
  const {
    title,
    subtitle,
    content,
    buttonText,
    defaultButtonText,
    buttonState,
    action,
    defaultAction,
    topColor,
    bgColor,
    isGradient,
  } = props;

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return (
    <div className={`h-screen flex flex-col ${marginClassName} `}>
      <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col`}>
        <div className="pt-[12px] px-[20px] " style={{ backgroundColor: topColor }}>
          <Title title={title} />
          {subtitle && <p className="mt-[8px] text-[17px] leading-[23px] text-[#6B6D76]">{subtitle}</p>}
        </div>
        <div className="flex-1">{content}</div>
      </div>
      <div className={`flex-none w-full relative `}>
        <div
          className="w-full h-[40px] absolute top-[-40px]"
          style={{ background: `linear-gradient(to top, ${bgColor ? bgColor : 'white'}, rgba(255,255,255,0))` }}
        />
        <div className="px-[20px] flex flex-col items-center relative">
          {isGradient && (
            <div
              className="w-full h-[40px] absolute top-[-40px] z-[50]"
              style={{ background: `linear-gradient(to top, ${bgColor ? bgColor : 'white'}, rgba(255,255,255,0))` }}
            />
          )}
          <PrimaryActionButton state={buttonState} text={buttonText} action={action} />
          <LinkButton text={defaultButtonText} action={defaultAction} />
        </div>
      </div>
    </div>
  );
}
