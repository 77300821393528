import defaultState from '../../data/inside/defaultState';
import { objectDataBlack } from '../../data/inside/objectDataBlack';
import useSessionStorage from '../../hooks/useSessionStorage';

const ObjectiveWithText = ({ index, name, title, placeholder, isEntered, isAndroid, autoFocus }) => {
  const [insideState, setInsideState] = useSessionStorage('insideState', defaultState);
  return (
    <div className="text-white px-[20px] py-[40px] flex flex-col gap-[28px]">
      <div
        className="w-full p-[24px] rounded-[16px]"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      >
        <p className="text-white text-[18px] leading-[21.6px] font-semibold mb-[8px]">{'나의 목표'}</p>
        <div>
          {insideState.answerIdList &&
            insideState.answerIdList.map((data) => (
              <div className="flex items-start opacity-[0.5] text-[18px] leading-[21.6px] mb-[3px]">
                <span className="px-[8px]">•</span>
                <div>{data.text}</div>
              </div>
            ))}
        </div>
      </div>

      {insideState.answerIdList &&
        objectDataBlack
          .find((item) => item.text === insideState.answerIdList[0].text)
          .planStepList.map((item, index) => (
            <div
              className="w-full pt-[28px] pb-[22px] rounded-[16px] px-[24px]"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }}
            >
              <p className="font-bold text-[18px] leading-[21.9px]">
                <span className="font-bold text-[#FF6E6E] mr-[13px]">{index + 1}단계</span>
                {item.title}
              </p>
              <p className="text-white opacity-[0.5] pt-[15px]">{item.subtitle}</p>
              <div className="mt-[16px] max-w-[400px] flex justify-center m-auto opacity-[0.8]">
                <img className="w-full h-full" src={item.image} alt="empty" />
              </div>
            </div>
          ))}
    </div>
  );
};

export default ObjectiveWithText;
