/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import SelectTemplate from '../../components/onboarding/SelectTemplate';
import MultipleSelectTemplate from '../../components/onboarding/MultipleSelectTemplate';
import onboarding1Q from '../../data/onboarding1Q';
import { changeHeader } from '../../utils/webview';
import { AuthContext } from '../../context';

function Onboarding1() {
  const context = useContext(AuthContext);
  let { id } = useParams();

  useEffect(() => {
    if (parseInt(id) === 0) {
      document.title = '기업회원 직종 선택';
    } else if (parseInt(id) === 1) {
      document.title = '기업회원 직책 선택';
    } else if (parseInt(id) === 2) {
      document.title = '일반회원 직업 선택';
    } else if (parseInt(id) === 3) {
      document.title = '상담 경험 여부 선택';
    } else if (parseInt(id) === 4) {
      document.title = '진료 경험 여부 선택';
    } else if (parseInt(id) === 5) {
      document.title = '진단 종류 선택';
    }
    context.setShowProgressingbar(false);

    changeHeader({ buttonType: 'back' });
  }, []);

  return (
    <div className={`w-screen h-screen overflow-hidden  `}>
      {onboarding1Q[parseInt(id)].type === 'select' && (
        <SelectTemplate
          id={onboarding1Q[parseInt(id)].id}
          category={'onboarding1'}
          question={onboarding1Q[parseInt(id)].question}
          options={onboarding1Q[parseInt(id)].options}
        />
      )}
      {onboarding1Q[parseInt(id)].type === 'multipleselect' && (
        <MultipleSelectTemplate
          id={onboarding1Q[parseInt(id)].id}
          category={'onboarding1'}
          question={onboarding1Q[parseInt(id)].question}
          options={onboarding1Q[parseInt(id)].options}
        />
      )}
    </div>
  );
}

export default Onboarding1;
