/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import { BottomAction } from '../../stories/page/BottomAction';
import { changeHeader } from '../../utils/webview';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import selfCheckData from '../../data/selfCheckData';
import { CheckLottie } from '../../stories/animation/CheckLottie';

export default function SelfCheckAnalysis(props) {
  let { selfCheckId } = useParams();
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === parseInt(selfCheckId));
  const context = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const first = searchParams.get('first') === 'true';

  useEffect(() => {
    if (first) {
      document.title = `마음 스캔 종료`;
    } else {
      document.title = `${selfCheckInfo.selfCheckName} 검사 종료`;
    }
    changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'none' });
    context.setShowProgressingbar(false);
  }, []);

  const Element = (
    <div className="w-full h-full flex flex-col items-center justify-center text-center ">
      <div className="mb-[30px]">
        <CheckLottie containerSize={'77px'} />
      </div>
      <p className=" font-bold text-[24px] leading-[30px] text-[#26282C]  mb-[7px] ">{'검사를 마쳤어요'}</p>
      <p className="  font-normal text-[17px] leading-[23px] text-[#6B6D76] whiteSpace-pre-line  ">
        {'결과를 보러 갈까요?'}
      </p>
    </div>
  );

  const goNext = useNavigateWithParams(`/mindScan/${selfCheckId}/analysis`);

  return (
    <div className={`w-screen h-screen bg-white`}>
      <BottomAction content={Element} buttonText={'결과 보기'} buttonState={'ACTIVE'} action={goNext} />
    </div>
  );
}
