import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

export default function useMixpanelWeb(request) {
  const params = request.params ? request.params : {};
  const projectName = request.projectName ? request.projectName : '';

  const fetchData = useCallback(async () => {
    try {
      if (projectName) {
        mixpanel[projectName].track(request.eventName, params);
      } else {
        mixpanel.track(request.eventName, params);
      }
    } catch (error) {
      console.error('error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  return fetchData;
}
