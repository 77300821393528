import React from 'react';
import { getDay } from '../../sleepingReport/calculateTime';
import { PressedEffect } from '../../../stories/button/PressedEffect';

export default function ThoughtNoteModule(props) {
  const { title, subTitle, date, onClick } = props;

  return (
    <PressedEffect
      element={
        <div className="w-full bg-white rounded-[20px] mx-auto py-[21px] px-[20px] ">
          <div className="w-full flex items-center justify-between gap-[15px]">
            <div className="w-[50px] h-[50px]  shrink-0 text-center flex flex-col justify-center">
              <p className="text-[17px] leaeding-[23px] text-[#26282C] font-bold mb-[5px]">
                {parseInt(date.substring(5, 7))}/{parseInt(date.substring(8, 10))}
              </p>
              <p className="text-[12px] leaeding-[18px] text-[#A4A6B0]  ">{getDay(date)}요일</p>
            </div>
            <div className=" min-w-0 shrink h-[50px]  whitespace-nowrap ">
              <div>
                <p className="text-[17px] leading-[23px] font-bold text-[#26282C] text-ellipsis overflow-hidden mb-[4px]">
                  {title}
                </p>
                <p className="text-[14px] leading-[20px] text-[#6B6D76] text-ellipsis overflow-hidden">{subTitle}</p>
              </div>
            </div>

            <div className="w-[24px] h-[24px] flex items-center justify-center shrink-0">
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.5 11L6.5 6L1.5 1"
                  stroke="#80838B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      }
      action={onClick}
    />
  );
}
