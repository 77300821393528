/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
// import PropTypes from 'prop-types';

export function SituationInput(props) {
  const { placeholder, value, updateValue } = props;

  const [isFocused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const inputRef = useRef();

  useEffect(() => {
    updateValue(inputValue);
  }, [inputValue]);

  return (
    <div>
      <div
        className={`w-full ring-[1px] rounded-[12px]  bg-[#FAFBFD]  ${
          isFocused ? 'ring-[#26282C]' : 'ring-[#D1D5DC]'
        } flex justify-between `}
      >
        <input
          enterkeyhint="done"
          className={`w-full rounded-[12px] pl-[20px] py-[13px] font-regular text-[17px] leading-[26px] bg-[#FAFBFD] placeholder:text-[#A4A6B0] 
      text-black  caret-[#3953D9] outline-none mb-[2px]`}
          placeholder={placeholder}
          value={inputValue}
          maxLength={1000}
          ref={inputRef}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onFocus={(e) => {
            setFocused(true);
          }}
          onBlur={(e) => {
            setFocused(false);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              if (inputRef.current) {
                inputRef.current.blur();
              }
            }
          }}
        ></input>
      </div>
    </div>
  );
}
