import book1 from '../../image/somny/sleepTale/book1.png';
import book2 from '../../image/somny/sleepTale/book2.png';
import book3 from '../../image/somny/sleepTale/book3.png';
import lottie1 from '../../stories/animation/somny/somny_dreaming1.json';
import lottie2 from '../../stories/animation/somny/somny_dreaming2.json';
import lottie3 from '../../stories/animation/somny/somny_dreaming3.json';

export const somnyFairyTaleData = [
  {
    seq: 1,
    taleId: 'fairytale_aa',
    title: '잔잔한 강',
    audio: 'https://media.priv-inside.im/somny/calm_river.wav',
    image: book1,
    lottie: lottie1,
    dreamImageList: ['🏞', '🌿', '🦢'],
  },
  {
    seq: 2,
    taleId: 'fairytale_ab',
    title: '집으로 가는 길',
    audio: 'https://media.priv-inside.im/somny/way_back_home.wav',
    image: book2,
    lottie: lottie2,
    dreamImageList: ['🛤', '🍂', '🏠'],
  },
  {
    seq: 3,
    taleId: 'fairytale_ac',
    title: '숲 산책',
    audio: 'https://media.priv-inside.im/somny/forest_walk.wav',
    image: book3,
    lottie: lottie3,
    dreamImageList: ['⛰', '🌲', '🌳'],
  },
];
