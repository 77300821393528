import toolData from '../../data/toolData';
import moment from 'moment';
import 'moment/locale/ko';
import { PressedEffect } from '../../stories/button/PressedEffect';
import { goEmotionalRecordHistory, goToScreen } from '../../utils/webview';
import { useEffect, useState } from 'react';

const EmotionHistoryCard = ({ historyList }) => {
  console.log(historyList);
  const weeklyDateList = [];
  console.log(weeklyDateList);

  for (let i = 0; i < 7; i++) {
    const fullDate = moment().add(-i, 'days').format('YYYY-MM-DD');
    const historyItem = historyList.find((historyItem) => historyItem.date === fullDate);
    weeklyDateList[6 - i] = {
      day: 6 - i === 6 ? '오늘' : moment().add(-i, 'days').format('dd'),
      date: moment().add(-i, 'days').format('DD'),
      fullDate: fullDate,
      emotionId: historyItem && historyItem.emotionId,
      isRetrospectDone: historyItem && historyItem.isRetrospectDone,
    };
  }

  const mapEmotionHistory = () => {
    weeklyDateList.forEach((weeklyItem) => {
      historyList.forEach((historyItem) => {
        if (weeklyItem.fullDate === historyItem.date) {
          weeklyItem.emotionId = historyItem.emotionId;
          weeklyItem.isRetrospectDone = historyItem.isRetrospectDone;
        }
      });
    });
  };

  useEffect(() => {
    moment.locale('ko');
    mapEmotionHistory();
  }, []);
  console.log(weeklyDateList);

  // console.log(historyList.find((item) => item.date === moment.format('YYYY-MM-DD').toString()));

  // 기쁨, 슬픔, 불쾌함, 두려움, 화남
  const emotionColor = [
    { id: 1, color: '#FFD233' },
    { id: 2, color: '#36458E' },
    { id: 3, color: '#1A8F4F' },
    { id: 4, color: '#6D22E8' },
    { id: 5, color: '#D44C2E' },
  ];
  console.log(weeklyDateList);

  const goHistory = () => {
    goToScreen({
      screenName: 'history',
    });
  };

  return (
    <>
      <PressedEffect
        element={
          <div className="w-full rounded-[20px] bg-[#F3F5F8] my-[10px] py-[24px] min-h-[157px]">
            <p className="font-bold mb-[22px] px-[20px]">{moment().format('YYYY년 M월')}</p>
            <div className="flex px-[8px]">
              {weeklyDateList.map((item) => (
                <div className="w-full text-[15px] leading-[18px] h-[65px] flex flex-col items-center justify-between">
                  <p
                    className={`h-[34px] flex items-center
               ${item.day === '오늘' ? 'font-bold text-[#26282C]' : 'text-[#A4A6B0]'}
              `}
                  >
                    {item.day}
                  </p>
                  <p className="flex items-end">
                    <div
                      className={`w-[31px] h-[31px] rounded-[100%] flex items-center justify-center`}
                      style={{
                        border:
                          item.emotionId &&
                          item.isRetrospectDone &&
                          `1px solid ${emotionColor.find((resource) => resource.id === item.emotionId).color}`,
                        background:
                          item.emotionId &&
                          !item.isRetrospectDone &&
                          emotionColor.find((resource) => resource.id === item.emotionId).color,
                        color: item.emotionId && item.emotionId !== 1 && !item.isRetrospectDone && '#FFFFFF',
                      }}
                    >
                      {item.date}
                    </div>
                  </p>
                </div>
              ))}
            </div>
          </div>
        }
        action={goHistory}
      />
    </>
  );
};

export default EmotionHistoryCard;
