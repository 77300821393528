import { Swiper, SwiperSlide } from 'swiper/react'; // basic
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css'; //basic
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SwiperContent from './SwiperContent';
import '../tutorial/SwiperView.css';

export default function SwiperView(props) {
  const { steps, category } = props;
  SwiperCore.use([Navigation, Pagination]);
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      scrollbar={{ draggable: true }}
      navigation
      pagination={{ clickable: false }}
      breakpoints={{
        768: {
          slidesPerView: 2,
        },
      }}
      // on={

      // }
    >
      {steps.map((step, index) => (
        <SwiperSlide key={index}>
          <SwiperContent step={step} category={category} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
