/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { AnxyActionButton } from '../../stories/button/ActionButton';
import somny1 from '../../image/somny/somny1.png';
import somny2 from '../../image/somny/somny2.png';
import somny3 from '../../image/somny/somny3.png';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function Somny2Result3(props) {
  const setMixpanel = useMixpanelWeb({ eventName: '[3차] 검사결과 - 관리방법', projectName: 'somny' });
  const goNext = useNavigateWithParams('/somnyTest/result4');

  useEffect(() => {
    document.title = `somny 결과`;
    setMixpanel();
  }, []);

  return (
    <div className={`w-screen h-full min-h-screen overflow-h-scroll px-[20px] pt-[24px] flex flex-col`}>
      <p className={'flex-none text-[24px] leading-[30px] font-bold'}>어떻게 관리하나요?</p>

      <div className="pt-[24px] flex-1">
        <div className="flex flex-col gap-[40px]">
          <p className={'text-[17px] text-[#26282C] font-light'}>
            {
              '수면 문제는 크게 세 가지가 중요해요. 최적의 수면 효율을 달성할 수 있는 시간에 자고 일어나기, 잘못된 수면 습관 바로잡기, 침실에서 안락함을 충분히 느끼기.\n\n아래와 같은 활동들이 도움될 거예요'
            }
          </p>
          {[
            {
              title: '최적의 시간에 자고 일어나기',
              text: '수면 효율을 높일 수 있는 시간에 자고 일어나기 (Behavioral Intervention)',
              image: somny1,
            },
            {
              title: '잘못된 수면 습관 바로잡기',
              text: '수면을 방해하는 잘못된 습관들을 교정하고 수면 위생 관리하기 (Sleep hygiene Management)',
              image: somny2,
            },
            {
              title: '침실에서 안락함 느끼기',
              text: '침실에서 불안감을 유발하는 생각들을 바로잡고, 침실의 환경을 따뜻하게 만들고, 마음이 편안해지는 수면 동화 듣기 (Cognitive and Emotional Intervention)',
              image: somny3,
            },
          ].map((each, index) => (
            <div className="flex flex-col gap-[15px]">
              <div className="flex flex-col gap-[10px] text-[17px]">
                <p className="leading-[24px] font-bold">{each.title}</p>
                <p className="leading-[28px] font-light">{each.text}</p>
              </div>
              <div className="w-full py-[30px] rounded-[16px] bg-[#D1D5DC]">
                <img src={each.image} alt={'empty'} className={'w-[209px] mx-auto'} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex-none w-full pb-[20px] pt-[30px]">
        <AnxyActionButton state={'ACTIVE'} text={'다음'} action={goNext} />
      </div>
    </div>
  );
}
