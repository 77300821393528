import React, { useEffect, useContext, useCallback } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CoachingContext } from '../../coachingContext';
import { changeHeader } from '../../utils/webview';
import axios from '../../utils/axios2';
import webviewToast from '../../development/webviewToast';
import useDebounce from '../../hooks/useDebounce';
import WorkbookTemplate from './WorkbookTemplate';
import { useGetWorkbookParams } from '../../hooks/useGetWorkbookParams';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import usePostWorkbookDone from '../../hooks/usePostWorkbookDone';
import moment from 'moment';
import useSessionStorage from '../../hooks/useSessionStorage';

export default function WorkbookContent({ content }) {
  const { id, page } = useParams();
  // 기존 레이아웃과 다른 형식일 때 replaceComponent를 사용합니다.(ex. 감정기록 템플릿)
  // 버튼 눌렀을 때 api 호출 등의 액션이 필요하다면: submit
  const { replaceComponent, submit, height, progressHide, showHeader } = content;

  const context = useContext(CoachingContext);

  const [searchParams] = useSearchParams();
  const navigation = searchParams.get('navigation') || 'none';

  const nowTime = moment(new Date());
  const defaultAlarmTime = {
    hour: nowTime.format('H'),
    minute: Math.floor(nowTime.format('mm') * 0.1) * 10,
  };

  const [authToken, appId, activityKey, isFirst] = useGetWorkbookParams();

  const [alarmTime, setAlarmTime] = useSessionStorage('alarmTime', defaultAlarmTime);

  const html = document.getElementsByTagName('html')[0];
  const navigate = useNavigate();

  const goReport = useCallback(
    () =>
      setTimeout(() => {
        navigate(`/sleepingReport${window.location.search}`);
      }, 200),
    [],
  );

  const postWorkbookDone = usePostWorkbookDone();
  const postWorkbookDoneWithGoReport = usePostWorkbookDone(goReport);

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  useEffect(() => {
    window.scrollTo(0, 0);
    html.style.backgroundColor = '#FFFFFF';
    document.body.style.backgroundColor = '#FFFFFF';
    if (replaceComponent) {
      // 퀴즈, 정답 템플릿인 경우 배경색 변경을 위해 인자를 변경해줍니다.
      if (replaceComponent.type.name === 'QuizTemplate' || replaceComponent.type.name === 'AnswerTemplate') {
        context.setIsQuizTemplate(true);
      }
    } else {
      context.setIsQuizTemplate(false);
      changeHeader({ backgroundColor: '#FFFFFF', color: '#000000' });
    }

    if (progressHide) {
      changeHeader({ buttonType: 'none' });
      if (showHeader) {
        changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'close' });
      }
      context.setShowProgressingbar(false);
    } else if (navigation === 'back') {
      changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'back' });
      context.setShowProgressingbar(false);
    } else {
      if (parseInt(page) === 1) {
        changeHeader({ buttonType: 'close' });
      } else {
        changeHeader({ buttonType: 'back' });
      }
      context.setShowProgressingbar(true);
    }
  }, [page]);

  // 더블 클릭을 방지하기 위함
  const postOnce = useCallback(
    useDebounce(() => postWorkbookDone(), 300),
    [],
  );

  // 코스3 감정 관련 API
  const getDescriptionData = () => {
    const descriptionData = {
      situation: context.workbookAnswer[0],
      description: context.workbookAnswer[1],
    };
    return descriptionData;
  };

  const getSituationByEmotion = (emotion) => {
    return context.workbookAnswer.filter((item) => item.emotion === emotion)[0].situationList;
  };

  const submitEmotionSituation = async (index) => {
    try {
      const situationData = {
        situationByWordListOfAnger: getSituationByEmotion('화남'),
        situationByWordListOfDiscomfort: getSituationByEmotion('불쾌함'),
        situationByWordListOfFear: getSituationByEmotion('두려움'),
        situationByWordListOfPleasure: getSituationByEmotion('기쁨'),
        situationByWordListOfSadness: getSituationByEmotion('슬픔'),
      };

      await axios(3, authToken, appId)
        .post(`/emotion-recognition/situation-by-word/submit`, JSON.stringify(situationData))
        .then((response) => {
          webviewToast('success');
          postOnce();
        });
    } catch (error) {
      webviewToast('error' + error);
    }
  };

  const submitEmotionDescription = async (jsonData) => {
    try {
      await axios(3, authToken, appId)
        .post(`/emotion-recognition/situation-and-description/submit`, jsonData)
        .then((response) => {
          webviewToast('success');
          postOnce();
        });
    } catch (error) {
      webviewToast('error' + error);
    }
  };

  // 새 규칙 API
  const submitNewRule = async () => {
    try {
      await axios(3, authToken, appId)
        .post(
          '/footsteps-of-thought/new-rule/submit',
          JSON.stringify({
            newRule: context.workbookAnswer[0],
          }),
        )
        .then((response) => {
          webviewToast('success');
          postOnce();
        });
    } catch (error) {
      webviewToast('error' + error);
    }
  };

  // 감정 기록 피드백 API
  const submitEmotionFeedback = async () => {
    try {
      if (context.workbookAnswer.length !== 0) {
        await axios(3, authToken, appId)
          .post(
            '/emotion-note/feedback/submit',
            JSON.stringify({
              feedback: context.workbookAnswer[0],
            }),
          )
          .then((response) => {
            webviewToast('success');
            postOnce();
          });
      } else {
        postOnce();
      }
    } catch (error) {
      webviewToast('error' + error);
    }
  };

  const postTimeApi = async (api, hour, minute) => {
    try {
      const mockDate = new Date();
      mockDate.setHours(0);
      mockDate.setMinutes(0);
      const alarmDate = moment(mockDate).add({ hours: alarmTime.hour, minutes: alarmTime.minute }).format('HH:mm');
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          api,
          JSON.stringify({
            type: 'ready_in_bed',
            time: alarmDate,
          }),
        )
        .then((response) => {
          postWorkbookDoneWithGoReport();
        });
    } catch (error) {
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const submitWakeUpTime = async () => {
    context.setShowProgressingbar(false);
    postTimeApi('/sleep/expected-wake-up-time/submit');
  };

  const submitSleepingWorry = async () => {
    try {
      await axios(3, authToken, appId)
        .post('/sleep/ease-worry/save', JSON.stringify(context.workbookAnswer))
        .then((response) => {
          webviewToast('success');
          postOnce();
        });
    } catch (error) {
      webviewToast('error' + error);
    }
  };

  const submitWorrySolution = async () => {
    try {
      await axios(3, authToken, appId)
        .post(
          '/sleep/worry-solution/save',
          JSON.stringify({
            solution: context.workbookAnswer[0],
            worry: context.workbookAnswer[1],
          }),
        )
        .then((response) => {
          webviewToast('success');
          postOnce();
        });
    } catch (error) {
      webviewToast('error' + error);
    }
  };

  // 감정 기록 피드백 API
  const submitCourseFeedback = async () => {
    try {
      if (context.workbookAnswer.length !== 0) {
        await axios(3, authToken, appId)
          .post(
            '/course/feedback/submit',
            JSON.stringify({
              courseId: 'course-5',
              feedback: context.workbookAnswer[0],
            }),
          )
          .then((response) => {
            webviewToast('success');
            postOnce();
          });
      } else {
        postOnce();
      }
    } catch (error) {
      webviewToast('error' + error);
    }
  };

  const goNextPage = () => {
    if (context.progressSum === parseInt(page)) {
      console.log(submit);
      switch (submit) {
        case 'emotionSituation': {
          submitEmotionSituation();
          break;
        }
        case 'Pleasure': {
          submitEmotionDescription(JSON.stringify({ situationAndDescriptionOfPleasure: getDescriptionData() }));
          break;
        }
        case 'Sadness': {
          submitEmotionDescription(JSON.stringify({ situationAndDescriptionOfSadness: getDescriptionData() }));
          break;
        }
        case 'Anger': {
          submitEmotionDescription(JSON.stringify({ situationAndDescriptionOfAnger: getDescriptionData() }));
          break;
        }
        case 'Discomfort': {
          submitEmotionDescription(JSON.stringify({ situationAndDescriptionOfDiscomfort: getDescriptionData() }));
          break;
        }
        case 'Fear': {
          submitEmotionDescription(JSON.stringify({ situationAndDescriptionOfFear: getDescriptionData() }));
          break;
        }
        case 'NewRule': {
          submitNewRule();
          break;
        }
        case 'EmotionFeedback': {
          submitEmotionFeedback();
          break;
        }
        case 'Skip': {
          goNext();
          break;
        }
        case 'sleepPrescription': {
          submitWakeUpTime();
          break;
        }
        case 'sleepingWorry': {
          submitSleepingWorry();
          break;
        }
        case 'worrySolution': {
          submitWorrySolution();
          break;
        }
        case 'courseFeedback': {
          submitCourseFeedback();
          break;
        }
        case 'None': {
          break;
        }
        default: {
          postOnce();
          break;
        }
      }
    } else {
      goNext();
      context.setCurrent(context.current + 1);
    }
  };

  return (
    <div>
      {!replaceComponent ? (
        <WorkbookTemplate content={content} action={goNextPage} />
      ) : (
        <div className="w-full" style={{ height: height ? `${height}px` : '100%' }}>
          {replaceComponent}
        </div>
      )}
    </div>
  );
}
