/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { changeHeader } from '../../utils/webview';
import UserLeave0 from './UserLeave0';
import UserLeave1 from './UserLeave1';
import UserLeave2 from './UserLeave2';

export default function UserLeave() {
  let { id } = useParams();

  useEffect(() => {
    if (parseInt(id) === 0) {
      changeHeader({ buttonType: 'close' });
    } else if (parseInt(id) === 1) {
      changeHeader({ buttonType: 'back' });
    } else if (parseInt(id) === 2) {
      changeHeader({ buttonType: 'none' });
    }
  }, []);

  return (
    <div className={`w-screen h-screen overflow-hidden  `}>
      {parseInt(id) === 0 && <UserLeave0 />}
      {parseInt(id) === 1 && <UserLeave1 />}
      {parseInt(id) === 2 && <UserLeave2 />}
    </div>
  );
}
