/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import landingEnd from '../../image/somny/landingEnd.png';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import { TopTitleBottomActionSomny } from '../../stories/page/TopTitleBottomActionSomny';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function SomnyComingSoon() {
  const setMixPanel = useMixpanelWeb({
    eventName: '6-11. 출시 알림',
    projectName: 'somny',
  });

  const setMixPanel2 = useMixpanelWeb({
    eventName: '6-12. 타입폼 이동',
    projectName: 'somny',
  });

  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    const body = document.body;
    body.style.position = 'fixed';
    body.style.overflow = 'hidden';
    setMixPanel();
    document.title = 'Somny, 오늘 밤을 위한 수면 관리';
  }, []);

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerTitle: '' }));
  }, []);

  const MainContent = () => (
    <div className="h-full w-full text-center text-white flex flex-col justify-center items-center py-[0px]">
      <div className="p-[20px] max-w-[375px]">
        <div>
          <img src={landingEnd} alt="emtpy" className="object-contain w-full h-full" />
        </div>
      </div>
    </div>
  );
  return (
    <div
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <TopTitleBottomActionSomny
        content={<MainContent />}
        buttonText={'somny 출시 알림 받기'}
        buttonState={'ACTIVE'}
        action={() => {
          setMixPanel2();
          window.location.href = 'https://ftwl7crbb3s.typeform.com/to/l7LxTnQR';
        }}
        isSomny
      />
    </div>
  );
}
