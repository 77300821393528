/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SelfCheckTemplate from '../../../components/selfCheck/SelfCheckTemplate';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import Test from '../../../development/Test';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import deviceAtom from '../../../recoil/common/device';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import { PATH } from '../../../routes/paths';

export default function BetterMeQA(props) {
  const { choices, qcList } = props;

  const setHeaderState = useSetRecoilState(headerAtom);
  const deviceState = useRecoilValue(deviceAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const [selfCheckState, setSelfCheckState] = useRecoilState(selfCheckAtom);

  let { id } = useParams();
  id = parseInt(id);

  const [answer, setAnswer] = useState('');
  const [disable, setDisable] = useState(false);

  const goNextQuestion = useNavigateWithParams(`/betterMeTest/${parseInt(id) + 1}`);
  const goEndTest = useNavigateWithParams(`/betterMeTest/end`);

  useEffect(() => {
    if (
      selfCheckState.selfCheckAnswer.find(
        (element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId,
      )
    ) {
      const selected = selfCheckState.selfCheckAnswer.find(
        (element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId,
      );
      console.log(selected.selfCheckChoiceId);
      setAnswer(selected.selfCheckChoiceId);
    }
  }, []);

  function select(option) {
    setAnswer(option.selfCheckChoiceId);
    var arr1 = selfCheckState.selfCheckAnswer.slice();

    if (arr1.find((element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId)) {
      arr1 = arr1.filter((element) => element.selfCheckQuestionId !== qcList[id - 1].selfCheckQuestionId);
    }
    if (option.score) {
      arr1.push({
        selfCheckQuestionId: qcList[id - 1].selfCheckQuestionId,
        selfCheckChoiceId: option.selfCheckChoiceId,
        score: option.score,
      });
    } else {
      arr1.push({
        selfCheckQuestionId: qcList[id - 1].selfCheckQuestionId,
        selfCheckChoiceId: option.selfCheckChoiceId,
      });
    }

    console.log('answers: ', arr1);
    setSelfCheckState((state) => ({ ...state, selfCheckAnswer: arr1 }));

    setTimeout(() => {
      if (parseInt(id) < qcList.length) {
        // console.log('다음 문항으로');
        goNextQuestion();
      } else if (parseInt(id) === qcList.length) {
        goEndTest();
      }
    }, 400);
  }

  function isClicked(option) {
    if (option !== '') {
      setDisable(true);
      select(option);
    }
  }

  useEffect(() => {
    document.title = `BetterMe ${parseInt(id)}`;
    setProgressBarState({ isProgressBarVisible: true, maxCompleted: qcList.length, completed: parseInt(id) });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerTitle: '' }));
  }, []);

  const goToHome = useNavigateWithParams(PATH.betterMe.betterMeHome);

  return (
    <div className={`w-screen bg-white `} style={{ height: window.innerHeight - (deviceState.notchSize + 44 + 17) }}>
      <Test
        element={
          <div
            className="absolute right-[10px] py-[10px] px-[10px] z-[50]"
            onClick={() => {
              goToHome();
              setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
            }}
          >
            홈으로
          </div>
        }
      />
      <SelfCheckTemplate
        question={qcList[id - 1].question}
        optionList={qcList[id - 1].choices}
        answer={answer}
        isClicked={isClicked}
        disable={disable}
        anxy
      />
    </div>
  );
}
