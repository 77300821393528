import { useRecoilValue } from 'recoil';
import { somnyKnitData } from '../../../data/somny/somnyKnitData';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import knittedBall from '../../../image/somny/home/knitted_ball.png';
import knittedEnd from '../../../image/somny/home/knitted_end.png';
import statusAtom from '../../../recoil/somny/status/atom';
import { CheckLottie } from '../../../stories/animation/CheckLottie';
import { UnlockLottie } from '../../../stories/animation/UnlockLottie';

export function KnitItem(props) {
  const { index, knitId, isLock, isComplete, isLast, isFirstLock } = props;
  const statusState = useRecoilValue(statusAtom);
  const goToPayment = useNavigateWithParams(`/somnyPayment`);
  const goToSession = useNavigateWithParams(`/somny/${knitId}/0`);

  const defaultHeight = 264;
  const sectionHeight = 101.5;
  const ballSize = 43;
  const ballPadding = 20;

  const h = 112;
  const currentData = somnyKnitData.find((item) => item.knitId === knitId);

  function Path({ type, ratio }) {
    const pathWidth = 128;
    const pathHeight = 108;

    const path = {
      lb: 'M128.5 3H107.5C49.7862 3 3 49.7862 3 107.5V107.5',
      br: 'M0 3H21C78.7138 3 125.5 49.7862 125.5 107.5V107.5',
      rb: 'M128.5 104.5H107.5C49.7862 104.5 3 57.7138 3 0V0',
      bl: 'M0 104.5H21C78.7138 104.5 125.5 57.7138 125.5 0V0',
    };
    return (
      <div className="w-fit relative">
        <div>
          <svg
            width={pathWidth}
            height={pathHeight}
            viewBox={`0 0 ${pathWidth} ${pathHeight}`}
            direction="rtl"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d={path[type]} stroke={`${type === 'br' || type === 'bl' ? '#308497' : '#308497'}`} strokeWidth="5" />
          </svg>

          <div className="absolute top-0">
            <svg
              width={pathWidth}
              height={pathHeight}
              viewBox={`0 0 ${pathWidth} ${pathHeight}`}
              direction="rtl"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={path[type]}
                stroke={`${type === 'br' || type === 'bl' ? '#308497' : '#308497'}`}
                strokeWidth="5"
                strokeDasharray={`${
                  type === 'br' || type === 'bl' ? Math.max((1 - ratio) * 160, 0) : ratio * 160
                }, 160`}
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  const handleNavigate = () => {
    if (!isLock) {
      if (statusState.isPaymentRequired) {
        goToPayment();
      } else {
        goToSession();
      }
    }
  };

  const isNotReadyIndex = 3;

  return (
    <div>
      {index % 2 === 0 ? (
        <div>
          <div
            className="w-full relative z-[50] flex items-center"
            style={{
              top: index === 0 ? -43 : index * h + index * 4 - 30,
            }}
            onClick={handleNavigate}
          >
            {isLock ? (
              <img src={knittedBall} alt="empty" className="w-[86px] h-[86px]" />
            ) : (
              <div className="w-[86px] h-[86px] flex items-center justify-center">
                <img src={currentData.image} alt="empty" className="w-[60px] h-[60px]" />
              </div>
            )}
            <div className="text-white pl-[4px]">
              {isFirstLock && index < isNotReadyIndex && (
                <p className="text-[#BED7FF] text-[15px] leading-[21px] pb-[4px]">{`1번 더 기록하면 줄게요`}</p>
              )}
              {isFirstLock && index >= isNotReadyIndex && (
                <p className="text-[#BED7FF] text-[15px] leading-[21px] pb-[4px]">{`준비 중이에요`}</p>
              )}
              <div className="flex items-center">
                {isLock && <UnlockLottie white containerSize="24px" isStop />}
                {isComplete && (
                  <div className="pr-[6px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12.0002" cy="12" r="12" fill="#79AEFF" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.5388 8.47802C17.8271 8.77545 17.8197 9.25027 17.5222 9.53855L11.3317 15.5386C11.0409 15.8205 10.5787 15.8205 10.2878 15.5386L6.47826 11.8462C6.18083 11.558 6.17341 11.0831 6.46169 10.7857C6.74998 10.4883 7.22479 10.4809 7.52222 10.7691L10.8098 13.9555L16.4783 8.46145C16.7757 8.17317 17.2505 8.18059 17.5388 8.47802Z"
                        fill="#121B40"
                      />
                    </svg>
                  </div>
                )}
                <p className="text-[20px] leading-[26px] font-bold">{currentData.title}</p>
              </div>
            </div>
          </div>
          <div
            className={`absolute`}
            style={{
              left: ballSize,
              top: defaultHeight + sectionHeight * (index * 2) + ballPadding,
            }}
          >
            <Path type="rb" />
          </div>
          {isLast ? (
            <div
              className="absolute left-[50%] translate-x-[calc(-50%+23px)] "
              style={{
                transform: 'scaleX(-1)',
                top: defaultHeight + sectionHeight * (index * 2 + 1) + parseInt(ballSize / 2),
              }}
            >
              <img src={knittedEnd} alt="empty" className="w-[93.9px] translate-x-[23px] translate-y-[-1px]" />
            </div>
          ) : (
            <div>
              <div
                className={`absolute `}
                style={{
                  right: ballSize,
                  top: defaultHeight + sectionHeight * (index * 2 + 1) + ballPadding - 0.1,
                }}
              >
                <Path type="br" />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div
            className="w-full relative z-[50] flex items-center justify-end"
            style={{
              top: index * h + index * 5 - 30,
            }}
            onClick={handleNavigate}
          >
            <div className="text-white pl-[4px] flex flex-col items-end pr-[8px]">
              {isFirstLock && index < isNotReadyIndex && (
                <p className="text-[#BED7FF] text-[15px] leading-[21px] pb-[4px]">{`1번 더 기록하면 줄게요`}</p>
              )}
              {isFirstLock && index >= isNotReadyIndex && (
                <p className="text-[#BED7FF] text-[15px] leading-[21px] pb-[4px]">{`준비 중이에요`}</p>
              )}
              <div className="flex items-center">
                {isLock && <UnlockLottie white containerSize="24px" isStop />}
                {isComplete && (
                  <div className="pr-[6px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12.0002" cy="12" r="12" fill="#79AEFF" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.5388 8.47802C17.8271 8.77545 17.8197 9.25027 17.5222 9.53855L11.3317 15.5386C11.0409 15.8205 10.5787 15.8205 10.2878 15.5386L6.47826 11.8462C6.18083 11.558 6.17341 11.0831 6.46169 10.7857C6.74998 10.4883 7.22479 10.4809 7.52222 10.7691L10.8098 13.9555L16.4783 8.46145C16.7757 8.17317 17.2505 8.18059 17.5388 8.47802Z"
                        fill="#121B40"
                      />
                    </svg>
                  </div>
                )}
                <p className="text-[20px] leading-[26px] font-bold">{currentData.title}</p>
              </div>
            </div>
            {isLock ? (
              <img src={knittedBall} alt="empty" className="w-[86px] h-[86px]" />
            ) : (
              <div className="w-[86px] h-[86px] flex items-center justify-center">
                <img src={currentData.image} alt="empty" className="w-[60px] h-[60px]" />
              </div>
            )}
          </div>
          <div
            className={`absolute`}
            style={{
              right: ballSize,
              top: defaultHeight + sectionHeight * (index * 2) + parseInt(ballSize / 2),
            }}
          >
            <Path type="bl" />
          </div>
          {isLast ? (
            <div
              className="absolute left-[50%] translate-x-[calc(-50%-23px)]"
              style={{
                top: defaultHeight + sectionHeight * (index * 2 + 1) + parseInt(ballSize / 2),
              }}
            >
              <img src={knittedEnd} alt="empty" className="w-[93.9px]" />
            </div>
          ) : (
            <div>
              <div
                className={`absolute`}
                style={{
                  left: ballSize,
                  top: defaultHeight + sectionHeight * (index * 2 + 1) + parseInt(ballSize / 2),
                }}
              >
                <Path type="lb" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
