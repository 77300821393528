import React from 'react';
import co7 from '../../image/co7.png';
import Clock from 'react-live-clock';
import 'moment/locale/ko';
import { motion } from 'framer-motion';
function CompleteWithCountdown({ animateList, isCompleted }) {
  const calculateTime = (endTime, nowTime) => {
    const leftHour = parseInt((endTime - nowTime) / 60 / 60);
    const leftMinute = parseInt(((endTime - nowTime) / 60) % 60);
    const leftSecond = parseInt((endTime - nowTime) % 60);

    return `${leftHour}시간 ${leftMinute}분 ${leftSecond}초`;
  };
  return (
    <div>
      {animateList.length === 0 && <div className="w-full h-[10px]" />}
      <motion.div
        className="top-[10px] w-full left-0px bg-white shadow-counselorcard rounded-[20px] mx-auto mb-0px"
        animate={{ opacity: 1 }}
        style={{
          position: isCompleted && animateList.length !== 0 ? 'absolute' : 'static',
          opacity: isCompleted && animateList.length !== 0 ? 0 : 1,
        }}
        transition={{ ease: 'easeInOut', duration: 0.3, delay: 1 }}
      >
        <div className={`w-full px-[20px] py-[30px] border-[#F3F5F8]`}>
          <div className="flex flex-col items-center">
            <div className="w-[44px] h-[44px]">
              <img className="object-contain h-full w-full" src={co7} alt="empty" />
            </div>
            <p className={`text-[14px] leading-[20px]  text-[#6B6D76] mt-[16px]`}>오늘 활동을 모두 마쳤어요</p>
            <p className={`font-bold text-[20px] leading-[26px]  text-[#26282C] mt-[5px]`}>내일 활동이 열리기까지</p>
            <p className={`font-bold text-[24px] leading-[30px]  text-[#2C4BEC] mt-[5px]`}>
              <Clock
                format={'HH:mm:s'}
                ticking={true}
                timezone={'Asia/Seoul'}
                filter={(e) => {
                  const nowHour = parseInt(e.split(':')[0]);
                  const nowMinute = parseInt(e.split(':')[1]);
                  const nowSecond = parseInt(e.split(':')[2]);
                  const endTime = 24 * 60 * 60;

                  const nowTimeWithSec = nowHour * 60 * 60 + nowMinute * 60 + nowSecond;

                  return calculateTime(endTime, nowTimeWithSec);
                }}
              />
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default CompleteWithCountdown;
