import landing0 from '../image/somny/onboarding/landing0.png';
import landing1 from '../image/somny/onboarding/landing1.png';
import landing2 from '../image/somny/onboarding/landing2.png';
import landing3 from '../image/somny/onboarding/landing3.png';
import landing4 from '../image/somny/onboarding/landing4.png';
import landing5 from '../image/somny/onboarding/landing5.png';
import landing6 from '../image/somny/onboarding/landing6.png';
import landing7 from '../image/somny/onboarding/landing7.png';
import landing_knit from '../image/somny/onboarding/landing_knit.png';
import landing9 from '../image/somny/landing9.png';
import landingEffect from '../image/somny/onboarding/landing_effect.png';
import somny11 from '../image/somny/somny11.png';
import somny12 from '../image/somny/somny12.png';
import final from '../image/somny/final.png';

export const somnyImageList = [
  landing0,
  somny11,
  landing1,
  landing2,
  landing3,
  landing4,
  landing_knit,
  landing5,
  landing6,
  landing7,
  landingEffect,
  somny12,
  landing9,
  final,
];
