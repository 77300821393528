import MessageBlockWithTail from './MessageBlockWithTail';

export const MessageBlockWithTailCross = ({ types, texts, opposite, my, margin, fit }) => {
  return (
    <div className={`w-full overflow-hidden ${my ? 'my-40px' : margin ? margin : ''}`}>
      {texts.map((item, index) => (
        <div className={`${index !== texts.length - 1 ? (index % 2 === 0 || fit ? 'mb-[10px]' : 'mb-[24px]') : ''}`}>
          {index % 2 === (opposite ? 1 : 0) ? (
            <MessageBlockWithTail textList={[item]} type={opposite ? types[1] : types[0]} cross />
          ) : (
            <MessageBlockWithTail textList={[item]} type={opposite ? types[0] : types[1]} opposite cross />
          )}
        </div>
      ))}
    </div>
  );
};
