import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';
import { CoachingContext } from '../../../coachingContext';

export default function SurveyAnalysis({ title }) {
  const { id, page } = useParams();

  const navigate = useNavigate();
  const context = useContext(CoachingContext);

  useEffect(() => {
    context.setShowProgressingbar(false);
    setTimeout(() => {
      navigate(`/sleepingReportxyz`);
    }, 2000);
  }, []);

  return (
    <div
      className="w-full h-[calc(100vh-57px)] flex flex-col items-center justify-center text-center "
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <DotLoader color="#3953D9" loading={true} size={80} />
      <p className=" font-bold text-[22px] leading-[35px] text-[#26282C]  mt-[40px] ">
        나에게 맞는 <br />
        수면 시간을 계산하고 있어요.
      </p>
    </div>
  );
}
