import { BUILD_TARGET } from 'config';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import useFetchAnxy from './useFetchAnxy';
import { useEffect } from 'react';

export default function useRequestPayment({ fetchInvoiceDetailUrl, successUrlPath, failUrlPath }) {
  const isDev = BUILD_TARGET === 'dev';
  const clientKey = isDev ? 'test_ck_ADpexMgkW36NRYqomPM3GbR5ozO0' : 'live_ck_MGjLJoQ1aVZKWg4eJZJrw6KYe2RN';

  const [invoiceData, getInvoiceData] = useFetchAnxy({
    url: fetchInvoiceDetailUrl,
    bg: '#FFFFFF',
  });

  useEffect(() => {
    getInvoiceData();
  }, []);

  async function requestPayment() {
    const tossPayments = await loadTossPayments(clientKey);
    if (invoiceData) {
      tossPayments.requestPayment('카드', {
        amount: invoiceData.result.payAmount,
        orderId: invoiceData.result.orderId,
        orderName: invoiceData.result.orderName,
        customerName: invoiceData.result.customerName,
        successUrl: isDev ? `https://dev-m.inside.im/${successUrlPath}` : `https://m.inside.im/${successUrlPath}`,
        failUrl: isDev ? `https://dev-m.inside.im/${failUrlPath}` : `https://m.inside.im/${failUrlPath}`,
        appScheme: 'anxy-dev://',
      });
    }
  }

  return [invoiceData, requestPayment];
}
