import React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

export function ProgressingBar(props) {
  const { maxCompleted, completed, color, baseColor, height, animateNone, initCompleted, duration, maskColor } = props;
  const heightWithDefault = height || 5;
  return (
    <div className="relative">
      <div
        className="absolute left-0 "
        style={{
          width: heightWithDefault / 2,
          height: heightWithDefault,
          background: `radial-gradient(circle at 100% 50%, transparent 0,transparent ${
            heightWithDefault / 2
          }px,${maskColor} ${heightWithDefault / 2}px)`,
        }}
      />

      <ProgressBar
        completed={completed}
        maxCompleted={maxCompleted}
        bgColor={color}
        baseBgColor={baseColor ? baseColor : '#F3F5F8'}
        height={`${heightWithDefault}px`}
        borderRadius={'60px'}
        isLabelVisible={false}
        animateOnRender={animateNone ? false : true}
        initCompletedOnAnimation={initCompleted ? initCompleted : 0}
        transitionDuration={duration ? duration : '0.4s'}
      />
    </div>
  );
}
