export const DottedmessageWithBold = ({ bold, texts, margin }) => {
  return (
    <div className={`${margin ? margin : 'my-[40px]'} mx-[25px]`}>
      <div
        className="text-[17px] leading-[22px] rounded-[16px] w-full py-[14px] px-[20px] text-[#000000]"
        style={{ border: '1px dashed #A4A6B0' }}
      >
        {bold && <p className="font-bold mb-[6px]">{bold}</p>}
        {texts.map((each, index) => (
          <p className={`flex ${index !== texts.length - 1 && 'mb-[6px]'}`}>
            <div className="flex items-start">
              <span className="mr-[8px]">•</span>
              <span>
                {each.map((text, index) => (
                  <span>
                    {index % 2 === 0 ? (
                      <span>{text}</span>
                    ) : (
                      <span className={`bg-[#FFEB3564] pb-[1.5px] `}>{text}</span>
                    )}
                  </span>
                ))}
              </span>
            </div>
          </p>
        ))}
      </div>
    </div>
  );
};
