/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../context';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { BlackRoundActionButton } from '../../stories/button/RoundActionButton';
import betterme from '../../image/betterme.png';

export default function BetterMeStart(props) {
  const { state } = props;
  const context = useContext(AuthContext);
  const goNext = useNavigateWithParams(`/betterme-score/${state}/1`);

  useEffect(() => {
    document.title = `betterme start`;
    context.setShowProgressingbar(false);
    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';
  }, []);

  return (
    <div
      className="w-full h-screen bg-[#FFA601] overflow-x-hidden  px-[20px] flex flex-col justify-center"
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <div className="flex flex-col items-center mb-[100px] text-center">
        <img src={betterme} alt={'empty'} className="w-[200px]" />
        <p className=" text-[40px] leading-[48px] font-bold text-black mb-[30px]">
          {state === 'diet' ? '나는 다이어트 강박을 가지고 있을까?' : '나는 식이장애를 가지고 있을까?'}
        </p>
        <p className=" text-[20px] leading-[24px] font-bold text-black opacity-[0.4] mb-[30px]">
          {state === 'diet'
            ? '2분만에 나의 다이어트 강박 유무를 알아보고 더 건강하게 내 몸을 대하는 방법을 배워보세요!'
            : '2분만에 나의 식이장애 유무를 알아보고 더 건강하게 내 몸을 대하는 방법을 배워보세요!'}
        </p>
      </div>

      <div className=" mx-auto">
        <BlackRoundActionButton state={'ACTIVE'} text={'검사 시작하기'} action={goNext} />
      </div>
    </div>
  );
}
