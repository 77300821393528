import React from 'react';

export default function UserProfileImage({ profileImageUrl, nickname, containerSize }) {
  return profileImageUrl && profileImageUrl !== '' ? (
    <div
      className="rounded-[50%] overflow-hidden"
      style={{ border: '1px inset rgba(0, 0, 0, 0.05)', width: containerSize, height: containerSize }}
    >
      <img alt="empty" src={profileImageUrl} className="w-full h-full object-cover" />
    </div>
  ) : (
    <div
      className="bg-[#FAFBFD]  rounded-[100%] flex justify-center items-center"
      style={{ border: '1px inset rgba(0, 0, 0, 0.05)', width: containerSize, height: containerSize }}
    >
      <p className="font-medium text-[30px] leading-[36px] text-[#D6D8DC] mt-[1px]">{nickname.slice(0, 1)}</p>
    </div>
  );
}
