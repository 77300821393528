import { PressedEffect } from '../../stories/button/PressedEffect';
import { goToScreen } from '../../utils/webview';
import { PrimaryActionButton } from '../../stories/button/ActionButton';

export default function AssignedCounselorCard({ counselor }) {
  const goCounselor = (id) => {
    goToScreen({
      screenName: 'counselorDetail',
      counselorId: id,
    });
  };

  const goCounselorReservation = (id, name, appointmentId) => {
    goToScreen({
      screenName: 'reservation',
      counselorId: id,
      counselorName: name,
      appointmentId: appointmentId,
    });
  };

  return (
    <div className="w-full h-fit">
      <PressedEffect
        element={
          <div
            className={`w-full max-w-[380px]  mx-auto bg-white rounded-[20px] overflow-hidden pt-[30px] pb-[20px] px-[20px]`}
            style={{
              boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.22)',
            }}
          >
            <div className="flex flex-col items-center justify-between ">
              <p className={`text-[22px] leading-[28px] font-bold text-[#26282C] mb-[24px] text-center`}>
                전담 상담사와
                <br />첫 상담을 예약하세요
              </p>
              <div
                className="flex flex-col mx-auto w-full bg-[#F3F5F8] w-[120px] h-[120px] rounded-[100%] overflow-hidden relative"
                style={{
                  border: '1px inset rgba(0, 0, 0, 0.04)',
                }}
              >
                <div
                  className="w-full h-full bg-black opacity-[0.1] absolute"
                  style={{
                    borderRadius: '16px 16px 0 0',
                  }}
                />
                <img src={counselor.profileImageUrl} alt={'empty'} className="w-full" />
              </div>
              <div className="mt-[15px] text-[20px] leading-[26px] font-bold text-[#26282C] line-clamp-2 ">
                {counselor.name} 상담사
              </div>
            </div>

            <div className="w-full max-h-[120px]  bg-[#F3F5F8] rounded-[8px] px-[15px] py-[20px] my-[24px] border-[1px] border-[rgba(0,0,0,0.05)]">
              {counselor.careerList.slice(0, 3).map((career, index) => (
                <div className="w-full mb-[4px] flex items-start" key={`career${index}`}>
                  <div className="w-[3px] h-[3px] rounded-[3px] bg-[#80838B] mr-[5px] mt-[8px] shrink-0" />
                  <p className="text-[15px] leading-[21px] text-[#6B6D76] whitespace-nowrap overflow-hidden text-ellipsis">
                    {career}
                  </p>
                </div>
              ))}
            </div>

            <PrimaryActionButton
              state={'ACTIVE'}
              text={'예약하기'}
              action={() => {
                goCounselorReservation(counselor.counselorId, counselor.name, counselor.previousAppointmentId);
              }}
            />
          </div>
        }
        action={() => goCounselor(counselor.counselorId)}
      />
    </div>
  );
}
