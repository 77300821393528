/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useNavigateWithParams from '../useNavigateWithParams';
import { useSetRecoilState } from 'recoil';
import useFetchAnxy from '../useFetchAnxy';
import userAtom from '../../recoil/anxy/user/atom';

export default function useGetHeart(props) {
  const setUserState = useSetRecoilState(userAtom);
  const [goHeartURL, setGoHeartURL] = useState();

  const goHeartGuide = useNavigateWithParams(`/guide/heart/0`, true);
  const goHeartPage = useNavigateWithParams(`/heart`, true);

  const [userHeartData, getUserHeart] = useFetchAnxy({
    url: `anxy/user/heart`,
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (userHeartData) {
      const userHeart = userHeartData.result;
      setUserState((state) => ({
        ...state,
        heart: {
          currentHeart: userHeart.currentHeart,
          totalHeart: userHeart.totalHeart,
          lastUseAt: userHeart.lastUseAt,
        },
      }));
      if (userHeart.isFirst) {
        console.log('go heart guide');
        setGoHeartURL(`/guide/heart/0`);
      } else {
        console.log('go heart ');
        setGoHeartURL(`/heart`);
      }
    }
  }, [userHeartData]);

  useEffect(() => {
    getUserHeart();
  }, []);

  return goHeartURL;
}
