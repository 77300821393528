import workbookMessageColorData from '../../data/workbookMessageColorData';

export default function MessageBlockWithQuote({ type, textList, normal, margin, my }) {
  const colorInfo = workbookMessageColorData.find((element) => element.type === type);
  console.log(textList);
  return (
    <div className={`${margin ? margin : my ? 'my-[40px]' : ''} `}>
      {textList.map((text, index) => (
        <div
          key={`MessageBlockWithQuote${index}`}
          className={`flex justify-center items-start rounded-[16px] w-full   py-[20px] ${
            index !== textList.length - 1 ? 'mb-[10px]' : ''
          } `}
          style={{ backgroundColor: colorInfo.backgroundColor, border: colorInfo.border }}
        >
          <img src={colorInfo.quoteL} className="w-[10.8px] h-[10.6px] mt-[2.92px]" alt="empty" />
          <p
            className={`text-center w-[190px]  ${
              normal ? 'font-normal' : 'font-semibold'
            }  text-[20px] leading-[24px] text-[#26282C] mx-[16.27px]`}
          >
            {text}
          </p>
          <img src={colorInfo.quoteR} className="w-[10.8px] h-[10.6px] mt-[2.92px]" alt="empty" />
        </div>
      ))}
    </div>
  );
}
