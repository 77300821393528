import 'moment/locale/ko';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Lottie from '../../elements/Lottie';
import anxy_hand_lottie from '../../../image/anxy/12A/anxy_hand_lottie.json';
import { WorryBubble } from '../../../stories/animation/WorryBubble';
import toolModalAtom from '../../../recoil/anxy/session/toolModalAtom';
import sessionAtom from '../../../recoil/anxy/session/atom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import userAtom from '../../../recoil/anxy/user/atom';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';

export default function Wori({ initialScore, score, duration, delay, hand, downArrow, bubble, growl, hasArm }) {
  const [score2, setScore2] = useState(initialScore !== undefined ? initialScore : score);
  const [duration2, setDuration2] = useState(initialScore !== undefined ? 0 : duration);
  const [showDownArrow, setShowDownArrow] = useState(false);

  const setSessionState = useSetRecoilState(sessionAtom);
  const setModalState = useSetRecoilState(toolModalAtom);
  const { worryNoteHistory } = useRecoilValue(userAtom);

  const goWorryNote = useNavigateWithParams('/worryNote');

  useEffect(() => {
    if (initialScore !== undefined) {
      setTimeout(() => {
        setDuration2(duration);
        setScore2(score);
        setTimeout(() => {
          setShowDownArrow(true);
        }, duration * 1000);
      }, delay * 1000);
    }
  }, []);

  useEffect(() => {
    if (initialScore === undefined) {
      setScore2(score);
    }
  }, [score]);

  const head = (
    <svg width="211" height="113" viewBox="0 0 211 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.0268 1.18127C92.6132 -0.393758 94.841 -0.393758 95.4275 1.18127L98.0617 8.25596C100.519 8.08593 102.999 7.9995 105.5 7.9995C108.797 7.9995 112.059 8.14977 115.28 8.44383L117.984 1.18127C118.57 -0.393758 120.798 -0.393758 121.384 1.18127L124.558 9.70538C173.727 18.6191 211 61.4069 211 112.999H0C0 60.5108 38.5789 17.1353 89.0143 9.27169L92.0268 1.18127Z"
        fill="#171222"
      />
      <ellipse cx="97.4265" cy="69.3684" rx="3.64327" ry="6.2727" fill="#FFC700" />
      <ellipse cx="114.98" cy="69.3684" rx="3.97447" ry="6.2727" fill="#FFC700" />
      <path d="M92.459 85.5449H120.28" stroke="#B43737" strokeWidth="1.89" strokeLinecap="round" />
    </svg>
  );

  const body = (
    <svg width="211" height="85" viewBox="0 0 211 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M211 0.000573942C211 29.7622 198.597 56.6363 178.662 75.767C159.694 93.9693 133.908 78.6197 105.5 78.6197C77.0915 78.6197 51.3056 93.9693 32.3381 75.767C12.4032 56.6362 0 29.7622 0 0.000497648C78 0.000436613 158.5 -0.000638126 211 0.000573942Z"
        fill="#171222"
      />
    </svg>
  );

  const pants = (
    <svg width="140" height="20" viewBox="0 0 140 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0H140V20C120.596 20 104.851 20 72 20V18.7895C72 17.5644 71.5 16.5 70 16.5C68.5 16.5 68 17.5644 68 18.7895V20C45.3017 20 22.5547 20 0 20V0Z"
        fill="#171222"
      />
    </svg>
  );

  const leg = (
    <svg width="211" height="104" viewBox="0 0 211 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse opacity="0.4" cx="105.5" cy="79" rx="105.5" ry="25" fill="#A4A6B0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.197 75.4529V0H36.4439V47C36.4439 53.5241 35.0004 59.9678 32.2162 65.8722L29.7954 71.006C28.4378 73.885 29.1211 77.015 31.0162 79.0681C35.7856 85.7413 50.0076 90.5833 66.8107 90.5833C86.5231 90.5833 102.683 83.9195 104.197 75.4529ZM180.633 65.8722C177.849 59.9678 176.405 53.5241 176.405 47V0H108.65V75.9179C110.661 84.1622 126.588 90.5833 145.938 90.5833C162.578 90.5833 176.686 85.8353 181.591 79.2627C183.687 77.308 184.494 74.0613 183.072 71.0442L180.633 65.8722Z"
        fill="#171222"
      />
    </svg>
  );

  const arm = (
    <svg width="305" height="139" viewBox="0 0 305 139" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0008 3.33721e-10C38.8079 -7.05716e-05 50.0008 11.1928 50.0009 24.9999C50.001 45.641 50.2516 63.5774 54.4021 76.4571C56.3106 82.3795 58.4507 85.1504 59.9958 86.4507C61.1351 87.4096 63.5656 89 69.7657 89H235.711C241.736 89 244.045 87.4483 245.12 86.534C246.621 85.2574 248.747 82.491 250.645 76.5352C254.761 63.6152 255.001 45.6396 255.001 25C255.001 11.1929 266.194 3.33721e-10 280.001 3.33721e-10C293.808 3.33721e-10 305.001 11.1929 305.001 25C305.001 25.5838 305.001 26.1758 305.001 26.7757C305.009 45.0423 305.019 70.5788 298.285 91.7148C294.646 103.134 288.468 115.305 277.512 124.622C266.131 134.302 251.831 139 235.711 139H69.7657C53.5973 139 39.2481 134.34 27.8 124.706C16.7576 115.412 10.5026 103.246 6.81205 91.7929C-0.0166435 70.6021 -0.00665858 45.0051 0.000469179 26.7347C0.000698061 26.1488 0.000923128 25.5705 0.000919314 25.0001C0.000848742 11.193 11.1937 7.05722e-05 25.0008 3.33721e-10Z"
        fill="#171222"
      />
    </svg>
  );

  const openWorryNoteModal = () => {
    if (worryNoteHistory && worryNoteHistory.length > 0) {
      setSessionState((state) => ({ ...state, currentTool: 'tool_ca' }));
      setTimeout(() => {
        setModalState((state) => ({ ...state, isModalVisible: true }));
      }, 0);
    } else {
      goWorryNote();
    }
  };

  return (
    <div className="flex flex-col items-center ">
      <div className={`${growl && 'origin-bottom animate-[growl_1s_ease-in-out]'}`}>
        <motion.div
          initial={{ transform: `translateY(${(40 * (100 - score2)) / 100}px)` }}
          animate={{ transform: `translateY(${(40 * (100 - score2)) / 100}px)` }}
          transition={{ duration: duration2 }}
        >
          <div className="translate-y-[3px] relative">
            {bubble && (
              <div className="absolute top-[-30px] left-[130px] z-[50]" onClick={openWorryNoteModal}>
                <WorryBubble />
              </div>
            )}
            {hand && (
              <div className="absolute top-[-20px] right-[160px] w-[120px] h-[160px] ">
                <Lottie lottieData={anxy_hand_lottie} autoplay loop />
              </div>
            )}
            <motion.div
              initial={{ transform: 'scale(0) translate(-50%,100px)' }}
              animate={{ transform: `scale(${hasArm ? 1 : 0}) translate(-50%,0px)` }}
              transition={{ duration: 0.2 }}
              className="absolute left-[50%] origin-bottom-left"
            >
              {arm}
            </motion.div>
            {downArrow && (
              <motion.div
                animate={{ opacity: showDownArrow ? 1 : 0 }}
                className="absolute left-[50%] translate-x-[-50%] top-[-110px] opacity-0"
              >
                <svg width="57" height="97" viewBox="0 0 57 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.8499 0C14.193 0 12.8499 1.34315 12.8499 3V62.75H2.00389C0.287088 62.75 -0.631826 64.7707 0.496619 66.0646L26.8424 96.2718C27.6394 97.1857 29.0599 97.1857 29.8569 96.2718L56.2027 66.0646C57.3311 64.7708 56.4122 62.75 54.6954 62.75H43.8499V3C43.8499 1.34315 42.5067 0 40.8499 0H15.8499Z"
                    fill="url(#paint0_linear_2837_67235)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2837_67235"
                      x1="31.5773"
                      y1="96.9572"
                      x2="29.9432"
                      y2="-0.0346061"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#C7CFDB" />
                      <stop offset="1" stopColor="#C7CFDB" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </motion.div>
            )}
            {head}
            <motion.div
              className="absolute w-[41px] h-[15px] bg-[#171222] left-[50%] translate-x-[-50%] "
              initial={{ bottom: `${54 - 9 * (score2 / 100)}px` }}
              animate={{ bottom: `${54 - 9 * (score2 / 100)}px` }}
              transition={{ duration: duration2 }}
            />
          </div>
          {/* 몸통 */}
          <motion.div
            className=" w-[211px] bg-[#171222] translate-y-[2px]"
            initial={{ height: `${(74 * score2) / 100}px` }}
            animate={{ height: `${(74 * score2) / 100}px` }}
            transition={{ duration: duration2 }}
          />
          {body}
        </motion.div>
      </div>
      {/* 다리 */}
      <div className="relative w-full">
        {/* 바지 */}
        <motion.div
          initial={{ transform: `translate(calc(-50% + 1px),${-20 + (40 * (100 - score2)) / 100}px)` }}
          animate={{ transform: `translate(calc(-50% + 1px),${-20 + (40 * (100 - score2)) / 100}px)` }}
          transition={{ duration: duration2 }}
          className="absolute left-[50%] top-0  "
        >
          {pants}
        </motion.div>
        {leg}
      </div>
    </div>
  );
}
