/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import betterMeSessionList from '../../../data/betterMe/betterMeSessionList';
import backgroundColorAtom from '../../../recoil/common/backgroundColor/atom';
import SessionTemplate from './SessionTemplate';
import sessionAtom from '../../../recoil/betterme/session/atom';

export default function BetterMeSession() {
  const { page } = useParams();

  const [prevBackgroundColor, setPrevBackgroundColor] = useRecoilState(backgroundColorAtom);

  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const [headerState, setHeaderState] = useRecoilState(headerAtom);

  const content = betterMeSessionList[parseInt(page)];
  const backgroundColor = content.backgroundColor ? content.backgroundColor : prevBackgroundColor;

  useEffect(() => {
    document.title = 'BetterMe Session';
    setPrevBackgroundColor(backgroundColor);
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#26282C', headerTitle: '' }));
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
  }, []);

  return <SessionTemplate content={content} />;
}
