import { useCallback } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import axios from '../../utils/axiosAnxy';
import webviewToast from '../../development/webviewToast';
import { somnyFairyTaleData } from '../../data/somny/somnyFairyTaleData';
import useMixpanelEventSomny from '../useMixpanelEventSomny';
import useNavigateWithParams from '../useNavigateWithParams';
import sleepRecordAtom from '../../recoil/somny/sleepRecord/atom';
import { useRecoilState } from 'recoil';
import statusAtom from '../../recoil/somny/status/atom';
import somnyHomeAtom from '../../recoil/somny/home/atom';

export default function usePostRecordDone(action) {
  const [searchParams] = useSearchParams();

  const [statusState, setStatusState] = useRecoilState(statusAtom);
  const [homeState, setHomeState] = useRecoilState(somnyHomeAtom);

  const [sleepingRecord, setSleepingRecord] = useRecoilState(sleepRecordAtom);
  const goRecordDone = useNavigateWithParams('/somnyRecordDone');

  const setMixPanel = useMixpanelEventSomny({
    eventName: '기록 완료',
  });

  const handleEnd = () => {
    setMixPanel();
    if (!statusState.isCompleteTodaySleepRecord) {
      setHomeState((state) => ({ ...state, somnyWakeUp: true }));
    }
    if (action) {
      action();
    } else {
      goRecordDone();
    }
  };

  const postRecordDone = async () => {
    try {
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(`/somny/sleep-record/submit`, sleepingRecord)
        .then((response) => {
          console.log('완료', sleepingRecord);
          webviewToast('수면 기록 완료');
          handleEnd();
        });
    } catch (error) {
      handleEnd();
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return postRecordDone;
}
