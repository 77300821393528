import thumbnail_session_1 from '../../image/anxy/12B/thumbnail_session_1.png';
import thumbnail_session_aa_big from '../../image/anxy/12B/thumbnail_session_aa_big.png';
import thumbnail_session_2 from '../../image/anxy/12B/thumbnail_session_2.png';
import thumbnail_session_3 from '../../image/anxy/12B/thumbnail_session_3.png';
import thumbnail_session_4 from '../../image/anxy/12B/thumbnail_session_4.png';
import thumbnail_session_5 from '../../image/anxy/12B/thumbnail_session_5.png';
import thumbnail_session_6 from '../../image/anxy/12B/thumbnail_session_6.png';
import thumbnail_session_7 from '../../image/anxy/12B/thumbnail_session_7.png';

import thumbnail_exercise_meditate from '../../image/anxy/12B/thumbnail_exercise_meditate.png';
import thumbnail_exercise_record from '../../image/anxy/12B/thumbnail_exercise_record.png';
import thumbnail_exercise_relax from '../../image/anxy/12B/thumbnail_exercise_relax.png';
import thumbnail_exercise_trap from '../../image/anxy/12B/thumbnail_exercise_trap.png';
import thumbnail_exercise_face from '../../image/anxy/12B/thumbnail_exercise_face.png';

const anxySessionData = [
  {
    sectionId: 'session_aa',
    type: 'session',
    title: '불안은 꼭 나쁘기만 한 걸까요?',
    image: thumbnail_session_1,
    image_big: thumbnail_session_aa_big,
    eventProperty: '1',
    playTime: 1,
    checkPoint: ['1. 불안 이해'],
  },
  {
    sectionId: 'box_breath_acquire',
    type: 'box',
    title: '심호흡하기',
    eventProperty: '심호흡하기 획득',
  },

  {
    sectionId: 'box_worrynote_upgrade',
    type: 'box',
    title: '걱정기록',
    eventProperty: '걱정기록 업그레이드',
  },
  {
    sectionId: 'box_meditate_aa',
    type: 'box',
    title: '명상하기',
    eventProperty: '명상하기 획득',
  },
  {
    sectionId: 'exercise_meditate_aa',
    type: 'exercise',
    title: '명상하기',
    image: thumbnail_exercise_meditate,
    eventProperty: '명상하기',
  },
  {
    sectionId: 'session_ab',
    type: 'session',
    title: '나는 무엇 때문에 불안한 걸까요?',
    image: thumbnail_session_2,
    eventProperty: '2',
    playTime: 1,
    checkPoint: [
      '1. 불안 문제',
      '2. 불안 문제 적기',
      '3. 외부, 내부 요인',
      '4. 외부, 내부 요인 적기',
      '5. 목표 가이드',
      '6. 목표 적기',
    ],
  },
  {
    sectionId: 'session_ac',
    type: 'session',
    title: '불안할 때 내 몸이 보이는 반응',
    image: thumbnail_session_3,
    eventProperty: '3',
    playTime: 1,
    checkPoint: [
      '1. 불안 3요소, 걱정 일기 설명',
      '2. 외부, 내부 요인 기록',
      '3. 불안 점수 기록',
      '4. 신체 반응 기록',
      '5. 생각 기록',
      '6. 행동 기록',
      '7. 걱정 일기 기록 종합',
      '8. 걱정 일기 마무리',
    ],
  },

  {
    sectionId: 'session_ad',
    type: 'session',
    title: '불안할 때 내가 하는 생각',
    image: thumbnail_session_4,
    eventProperty: '4',
    playTime: 1,
  },
  {
    sectionId: 'session_ae',
    type: 'session',
    title: '불안할 때 내가 하는 행동',
    image: thumbnail_session_5,
    eventProperty: '5',
    playTime: 1,
  },
  {
    sectionId: 'exercise_meditate_ab',
    type: 'box',
    title: '명상하기',
    image: thumbnail_exercise_meditate,
    eventProperty: '명상하기',
  },
  {
    sectionId: 'session_af',
    type: 'session',
    title: '꼬리에 꼬리를 무는 불안의 순환고리',
    image: thumbnail_session_6,
    eventProperty: '6',
    playTime: 1,
  },
  {
    sectionId: 'session_kc',
    type: 'session',
    title: '내 마음, 지금 여기로 가져와요',
    image: thumbnail_session_7,
    eventProperty: '7',
    playTime: 1,
    checkPoint: ['1. 마음 챙김 설명', '2. 명상'],
  },
  {
    sectionId: 'box_record_aa',
    type: 'box',
    title: '불안 기록하기',
    eventProperty: '불안 기록하기',
  },
  {
    sectionId: 'exercise_record_aa',
    type: 'exercise',
    title: '불안 기록하기',
    image: thumbnail_exercise_record,
    eventProperty: '불안 기록하기',
  },
  {
    sectionId: 'session_ag',
    type: 'session',
    title: '어떻게 불안한 몸을 달랠 수 있을까요?',
    eventProperty: '6',
    playTime: 1,
  },
  {
    sectionId: 'box_relax_aa',
    type: 'box',
    title: '온몸 이완하기',
    eventProperty: '6',
  },
  {
    sectionId: 'session_ah',
    type: 'exercise',
    title: '한 방에 내 몸을 이완할 수 있다고요?',
    eventProperty: '7',
    playTime: 1,
  },
  {
    sectionId: 'session_ai',
    type: 'exercise',
    title: '내 생각에 함정이 있다니',
    eventProperty: '8',
    playTime: 1,
  },
  {
    sectionId: 'session_aj',
    type: 'exercise',
    title: '뭔가 일어날 것처럼 과하게 여기는 생각',
    eventProperty: '9',
    playTime: 1,
  },
  {
    sectionId: 'box_trap_aa',
    type: 'box',
    title: '생각함정 찾기',
    eventProperty: '10',
  },
  {
    sectionId: 'session_ak',
    type: 'session',
    title: '일이 파국으로 치닫을 거라 여기는 생각',
    eventProperty: '10',
    playTime: 1,
  },
  {
    sectionId: 'session_al',
    type: 'session',
    title: '“~해야 해”라는 당위적인 생각',
    eventProperty: '11',
    playTime: 1,
  },
  {
    sectionId: 'session_am',
    type: 'session',
    title: '내 생각의 뿌리를 찾아서',
    eventProperty: '12',
    playTime: 1,
  },
  {
    sectionId: 'session_an',
    type: 'session',
    title: '불안함에 맞설 수 있는 힘을 주세요',
    eventProperty: '13',
    playTime: 1,
  },
  {
    sectionId: 'session_ao',
    type: 'session',
    title: '나를 계속 불안하게 만드는 생각은 뭘까?',
    eventProperty: '14',
    playTime: 1,
  },
  {
    sectionId: 'session_ap',
    type: 'session',
    title: '내 머리 속의 불안을 마주하기',
    eventProperty: '15',
    playTime: 1,
  },
  {
    sectionId: 'box_face_aa',
    type: 'box',
    title: '불안 마주하기',
    eventProperty: '15',
  },
  {
    sectionId: 'session_aq',
    type: 'session',
    title: '불안할 때는 이렇게 행동하자!',
    eventProperty: '16',
    playTime: 1,
  },
  {
    sectionId: 'session_ar',
    type: 'session',
    title: '계획하고 실천하며 불안 깨부수기',
    eventProperty: '17',
    playTime: 1,
  },
  {
    sectionId: 'session_as',
    type: 'session',
    title: '불안으로부터 자유로워지기',
    eventProperty: '18',
    playTime: 1,
  },
];

export default anxySessionData;
