/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import backgroundColorAtom from '../../../recoil/common/backgroundColor/atom';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import webviewToast from '../../../development/webviewToast';
import anxyColorPalette from '../../../data/anxy/contents/anxyColorPalette';
import anxySessionByType from '../../../data/anxy/anxySessionByType';
import userAtom from '../../../recoil/anxy/user/atom';
import TrialTemplate from './TrialTemplate';
import useMixpanelEventAnxy from '../../../hooks/useMixpanelEventAnxy';

export default function TrialExample() {
  const { id, page } = useParams();
  const userState = useRecoilValue(userAtom);

  const [searchParams] = useSearchParams();
  const testType = searchParams.get('type');
  const anxietyTypeId = testType ? parseInt(testType) : userState.anxietyTypeId;

  const [prevBackgroundColor, setPrevBackgroundColor] = useRecoilState(backgroundColorAtom);

  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const [headerState, setHeaderState] = useRecoilState(headerAtom);

  const setMixPanel1 = useMixpanelEventAnxy({
    eventName: '온몸 이완하기 체험',
  });
  const setMixPanel2 = useMixpanelEventAnxy({
    eventName: '생각 함정 찾기 체험',
  });
  const setMixPanel3 = useMixpanelEventAnxy({
    eventName: '불안 마주하기 체험',
  });

  const dataByType = anxySessionByType.find((data) => data.type === anxietyTypeId);

  let currentData = [];
  if (id === 'breath') {
    currentData = dataByType.breath;
  } else if (id === 'trap') {
    currentData = dataByType.trap;
  } else if (id === 'action') {
    currentData = dataByType.action;
  }

  const isExampleEnd = currentData.length === parseInt(page) + 1;

  const loadImageById = () => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(loadImg);
          }, 100);

        loadImg.onerror = (err) => reject(err);
      });
    };

    const imageList = currentData.filter((item) => item.image !== undefined).map((item) => item.image);

    Promise.all(imageList.map((image) => loadImage(image)))
      .then(() => {
        // webviewToast('이미지 로드');
        // setImageLoaded(true);
      })
      .catch((err) => webviewToast('이미지 로드 실패'));
  };

  useEffect(() => {
    if (page === '0') {
      loadImageById();
      if (id === 'breath') {
        setMixPanel1();
      } else if (id === 'trap') {
        setMixPanel2();
      } else if (id === 'action') {
        setMixPanel3();
      }
    }
  }, [id, page]);

  useEffect(() => {
    document.title = 'Anxy Session';
    setPrevBackgroundColor(anxyColorPalette.black);
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#FFFFFF', headerTitle: '' }));
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
  }, []);

  return currentData.replaceComponent ? (
    currentData.replaceComponent
  ) : (
    <TrialTemplate data={currentData[parseInt(page)]} isExampleEnd={isExampleEnd} isExample />
  );
}
