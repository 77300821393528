import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Content from '../../components/feed/Content';
import contents from '../../data/contents';
import { changeHeader } from '../../utils/webview';

function Detail() {
  let { id } = useParams();
  console.log('id: ', id);
  const [searchParams] = useSearchParams();

  const navigation = searchParams.get('navigation');

  useEffect(() => {
    if (navigation === 'close') {
      changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'close' });
    } else {
      changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'back' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`w-screen `}>
      <Content
        key={contents[contents.length - 1 - id].id}
        image={contents[contents.length - 1 - id].image}
        category={contents[contents.length - 1 - id].category}
        title={contents[contents.length - 1 - id].title}
        subtitle={contents[contents.length - 1 - id].subtitle}
        texts={contents[contents.length - 1 - id].texts}
        link={contents[contents.length - 1 - id].link}
      />
    </div>
  );
}

export default Detail;
