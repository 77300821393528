/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context';
import { BottomAction } from '../../stories/page/BottomAction';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useFetch from '../../hooks/useFetch';
import firstMindCheckUpStart from '../../image/firstMindCheckUpStart.png';
import webviewToast from '../../development/webviewToast';

export default function FirstMindScanStart(props) {
  const context = useContext(AuthContext);
  const [surveyQuestion, getSurveyQuestion] = useFetch({
    url: `/self-check/v2/qc/list`,
    bg: '#FFFFFF',
  });
  const titleRef = useRef();
  const imageRef = useRef();
  // const windowHeight =
  //   navigator.userAgent.indexOf('KAKAOTALK') > -1 ? window.innerHeight * 0.9 : window.innerHeight * 1.1;
  const windowHeight = window.innerHeight;

  useEffect(() => {
    window.document.title = '마음 스캔';
    webviewToast(navigator.userAgent.indexOf('KAKAOTALK') > -1);
  }, []);

  useEffect(() => {
    if (surveyQuestion) {
      context.setSelfcheckQuestion(surveyQuestion.result);
    }
  }, [surveyQuestion]);

  useEffect(() => {
    getSurveyQuestion();
    context.setShowProgressingbar(false);
    if (titleRef.current && imageRef.current) {
      console.log(
        titleRef.current.offsetHeight + (imageRef.current.offsetWidth * 1524) / 1125,
        windowHeight,
        `${
          titleRef.current.offsetHeight + (imageRef.current.offsetWidth * 1524) / 1125 > windowHeight
        } ${windowHeight}`,
      );
    }
  }, []);

  const goNext = useNavigateWithParams(`/firstMindScan/0/0`);

  const Element = (
    <div className="w-full h-full overflow-x-hidden overflow-y-auto scrollbar-hide  flex flex-col ">
      <div className="flex flex-col items-center px-[20px] pt-[56px] text-center z-[10]" ref={titleRef}>
        <p className="bg-[#26282C] rounded-[20px] px-[15px] py-[4px] text-[15px] leading-[21px] font-bold text-white mb-[15px]">
          총 76문항
        </p>
        <p className=" text-[24px] leading-[30px] font-bold text-[#26282C] mb-[10px]">인사이드 마음 스캔</p>
        <p className=" text-[17px] leading-[23px] font-normal text-[#6B6D76] ">
          해외 유수의 대학, 병원, 연구기관에서 개발한 신뢰도 높은 검사들로 구성되었어요.
        </p>
      </div>
      <div
        className="absolute bottom-0 w-full flex flex-col justify-center "
        ref={imageRef}
        style={{
          transform: `translate(0,${
            titleRef.current &&
            imageRef.current &&
            titleRef.current.offsetHeight + (imageRef.current.offsetWidth * 1524) / 1125 > windowHeight
              ? titleRef.current.offsetHeight + (imageRef.current.offsetWidth * 1524) / 1125 - windowHeight
              : 0
          }px`,
        }}
      >
        <img src={firstMindCheckUpStart} alt="empty" />
      </div>
    </div>
  );

  return (
    <div className="relative w-screen h-screen ">
      <BottomAction
        content={Element}
        buttonText={'시작하기'}
        buttonState={'ACTIVE'}
        action={goNext}
        secondary
        noGradient
      />
    </div>
  );
}
