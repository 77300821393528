/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { useParams, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import { FullPageAnxy } from '../../stories/page/FullPageAnxy';
import { SubTitle18 } from '../../stories/text/AnxyText';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import hexOpacity from 'hex-opacity';
import { motion } from 'framer-motion';
import somnySessionPageData from '../../data/somny/somnySessionPageData';
import { Text17, Title17 } from '../../stories/text/SomnyText';
import Lottie from '../elements/Lottie';
import usePostKnitDone from '../../hooks/somny/usePostKnitDone';
import useMixpanelEventSomny from '../../hooks/useMixpanelEventSomny';

export default function SomnySessionTemplate(props) {
  let { knitId, page } = useParams();
  page = parseInt(page);

  const navigate = useNavigate();

  const setMixPanel = useMixpanelEventSomny({
    eventName: '뜨개 진입',
  });

  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const currentSession = somnySessionPageData.find((element) => element.knitId === knitId);
  const currentPage = currentSession.contents[page];
  const setHeaderState = useSetRecoilState(headerAtom);

  const goBack = () => {
    navigate(-1);
  };
  const goNext = useNavigateWithParams(`/somny/${knitId}/${page + 1}`);
  const postKnitDone = usePostKnitDone();

  useEffect(() => {
    if (page === 0) {
      setMixPanel();
    }
  }, [page]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_BACK,
      headerColor: '#FFFFFF',
      progressBar: {
        maxCompleted: currentSession.contents.length,
        completed: page + 1,
        color: '#FFFFFF',
        baseColor: '#17172f',
      },
    }));
  }, []);

  const BottomImage = ({ image, css, lottie }) => (
    <div
      className={` w-full pointer-events-none h-full absolute bottom-0 left-[50%] translate-x-[-50%] max-w-[375px] mx-auto flex justify-center items-end pt-[20px] ${css}`}
    >
      <img src={image} alt="empty" className="object-contain" />
      {lottie && (
        <div className="absolute top-[186px] left-[50%] translate-x-[-50%]">
          <Lottie lottieData={lottie} loop autoplay width="220px" height="220px" />
        </div>
      )}
    </div>
  );

  return (
    <FullPageAnxy
      content={
        <div className={`w-full h-full ${marginClassName}`}>
          {currentPage.isCover && (
            <img
              src={require(`../../image/somny/session/${knitId}/${page}.png`)}
              alt={'empty'}
              className="fixed w-screen h-screen object-contain object-bottom "
            />
          )}

          <div className="w-full  h-full">
            <div className="px-[20px] relative top-[60px]">
              <Text17 text={currentPage.title} />
            </div>
            {!currentPage.isCover && currentSession.contents.length - 1 !== page && (
              <BottomImage
                image={require(`../../image/somny/session/${knitId}/${page}.png`)}
                css={currentPage.imageCss}
              />
            )}
            {!currentPage.isCover && currentSession.contents.length - 1 === page && (
              <BottomImage
                image={require(`../../image/somny/session/session_end.png`)}
                css="pl-[34px] pr-[60px] max-h-[400px]"
              />
            )}
            <div className="w-full h-full relative px-[20px]">
              <motion.div
                initial={{ opacity: 0, pointerEvents: page === 0 ? 'none' : 'auto' }}
                animate={{ opacity: page === 0 ? 1 : 0, pointerEvents: page === 0 ? 'auto' : 'auto' }}
                transition={{ delay: 1, duration: 0.2 }}
                className={`z-[50] absolute h-full right-0 w-[50%] rounded-[24px] backdrop-blur-[40px] flex flex-col gap-[16px] items-center justify-center px-[40px] opacity-0`}
                style={{ backgroundColor: hexOpacity.create('#F1EEEB', 0.2) }}
                onClick={() => {
                  if (page === currentSession.contents.length - 1) {
                    setHeaderState((state) => ({
                      ...state,
                      progressBar: { maxCompleted: undefined },
                    }));
                    postKnitDone();
                  } else {
                    goNext();
                  }
                }}
              >
                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 0.999999L9 8.5L1 16"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <SubTitle18
                  subtitle={'다음으로 넘어가려면 여기를 눌러주세요'}
                  color={'#ffffff'}
                  className="text-center"
                />
              </motion.div>

              <div
                className={`absolute h-full left-0 w-[50%] rounded-[24px] backdrop-blur-[40px] flex flex-col gap-[16px] items-center justify-center px-[40px] opacity-0
                ${page === 0 && 'pointer-events-none'}`}
                style={{ backgroundColor: hexOpacity.create('#F1EEEB', 0.2) }}
                onClick={goBack}
              />
            </div>
          </div>
        </div>
      }
      bgColor={currentPage.backgroundColor || currentSession.backgroundColor}
    />
  );
}
