import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { AuthContext } from '../../context';
import { ProgressingBarThin } from '../../stories/else/ProgressingBarThin';
import { CheckLottie } from '../../stories/animation/CheckLottie';

export default function CurrentExamOnCoaching(props) {
  const { id, selfCheckList } = props;

  const context = useContext(AuthContext);
  const [progressbarAnimation, setProgressbarAnimation] = useState(false);
  const [focusNext, setFocusNext] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (parseInt(id) === 1) {
      setFocusNext(true);
    } else {
      setTimeout(() => {
        setProgressbarAnimation(true);
        setTimeout(() => {
          setFocusNext(true);
        }, 600);
      }, 400);
    }
  }, [id]);

  return (
    <div className="w-full flex justify-between">
      {selfCheckList.map((step, index) => (
        <div key={index} className={` flex  items-center relative mb-[20px]`}>
          <div className="flex flex-col">
            <div className="flex items-center">
              <div className="flex flex-col items-center relative">
                <div
                  className={`w-[20px] h-[20px] rounded-[28px] overflow-hidden bg-white ring-[1px] ring-inset ${
                    index <= id - 1 && focusNext ? 'ring-[#373EED] text-[#2C4BEC]' : 'ring-[#D1D5DC] text-[#A4A6B0]'
                  } flex justify-center items-center  z-10 shrink-0 transition-all`}
                >
                  {index < id - 2 && (
                    <div className="relative w-[20px] h-[20px] bg-qna flex justify-center items-center absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.35529 2.88453C1.05786 2.59624 0.583044 2.60366 0.294762 2.9011C0.00648063 3.19853 0.0138997 3.67335 0.311333 3.96163L1.35529 2.88453ZM4.00792 6.5L3.48594 7.03855C3.77682 7.32048 4.23901 7.32048 4.5299 7.03855L4.00792 6.5ZM9.68863 2.03855C9.98606 1.75027 9.99348 1.27545 9.7052 0.97802C9.41692 0.680587 8.9421 0.673168 8.64467 0.961449L9.68863 2.03855ZM0.311333 3.96163L3.48594 7.03855L4.5299 5.96145L1.35529 2.88453L0.311333 3.96163ZM4.5299 7.03855L9.68863 2.03855L8.64467 0.961449L3.48594 5.96145L4.5299 7.03855Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  )}
                  {index === id - 2 && (
                    <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                      <CheckLottie containerSize={'22px'} gradient />
                    </div>
                  )}
                  {index >= id - 2 && <p className="text-[12px] leading-[18px] font-normal">{index + 1}</p>}
                </div>
                {index % 2 === 0 ? (
                  <p
                    className={`absolute left-[50%] translate-x-[-50%] top-[24px] text-[10px] leading-[22px]  text-[#26282C] font-bold ${
                      index <= id - 1 ? 'text-[#2C4BEC]' : 'text-[#80838B] font-normal'
                    } `}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {step.selfCheckName}
                  </p>
                ) : (
                  <p
                    className={`absolute left-[50%] translate-x-[-50%] top-[-24px] text-[10px] leading-[22px]  text-[#26282C] font-bold 
                    ${index <= id - 1 ? 'text-[#2C4BEC]' : 'text-[#80838B] font-normal'}
                    `}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {step.selfCheckName}
                  </p>
                )}
              </div>
              {index !== selfCheckList.length - 1 && (
                <div
                  style={{
                    width: (windowWidth - 120 - 20 * selfCheckList.length) / (selfCheckList.length - 1) + 1.8,
                  }}
                >
                  <div className="w-full">
                    <ProgressingBarThin
                      completed={index <= id - 2 ? (index < id - 2 ? 1 : progressbarAnimation ? 1 : '') : 0}
                      color={'#2C4BEC'}
                      height={'1px'}
                      animateOnRender={index === id - 2}
                      duration={'0.6s'}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
