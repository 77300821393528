/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../context';
import { BottomAction } from '../../stories/page/BottomAction';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { CheckLottie } from '../../stories/animation/CheckLottie';

export default function Somny2End(props) {
  const context = useContext(AuthContext);

  useEffect(() => {
    document.title = `somny 검사 종료`;
    context.setShowProgressingbar(false);
  }, []);

  const Element = (
    <div className="w-full h-full flex flex-col items-center justify-center text-center ">
      <div className="mb-[30px]">
        <CheckLottie containerSize={'77px'} gradient />
      </div>
      <p className=" font-bold text-[24px] leading-[30px] text-[#26282C]  mb-[7px] ">{'검사를 마쳤어요'}</p>
      <p className="  font-normal text-[17px] leading-[23px] text-[#6B6D76] whiteSpace-pre-line  ">
        {'결과를 보러 갈까요?'}
      </p>
    </div>
  );

  const goNext = useNavigateWithParams(`/somnyTest/analysis`);

  return (
    <div className={`w-screen h-screen`}>
      <BottomAction content={Element} buttonText={'결과 보기'} buttonState={'ACTIVE'} action={goNext} />
    </div>
  );
}
