import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { hasTouch } from 'detect-touch';
import { motion } from 'framer-motion';

export function SelectButtonCircle(props) {
  const { selected, option, text, isClicked, disable, anxy, loomy } = props;
  const [buttonDown, setButtonDown] = useState(false);

  function mouseUp(e) {
    setButtonDown(false);
  }

  useEffect(() => {
    // console.log(hasTouch);
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  return (
    <div
      className={`flex flex-col items-center w-[47px] relative`}
      onMouseDown={(e) => {
        if (!hasTouch) {
          setButtonDown(true);
        }
      }}
      onClick={(e) => {
        if (!hasTouch && !disable) {
          isClicked(option);
        }
      }}
      onTouchStart={(e) => {
        if (!buttonDown && !disable) {
          setButtonDown(true);
        }
      }}
      onTouchEnd={(e) => {
        setButtonDown(false);
        if (
          document
            .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
            .includes(e.currentTarget) &&
          !disable
        ) {
          isClicked(option);
        }
      }}
    >
      <motion.div animate={{ scale: buttonDown && !disable ? 0.95 : 1 }} transition={{ duration: 0.2 }}>
        <div
          className={`w-[47px] h-[47px] rounded-[47px] 
                mb-[10px] flex items-center flex justify-center items-center
                ring-[1px] ring-inset ring-[#00000005]
            ${
              selected
                ? `font-bold text-[#2C4BEC] ${anxy ? 'bg-[#FFEBE2]' : loomy ? 'bg-[#D9D8B7]' : 'bg-[#EFF2FF]'}`
                : `font-normal text-[#4E4F53] ${loomy ? 'bg-[#D9D8B7]' : 'bg-[#F3F5F8]'}`
            }`}
        >
          <div
            className={`w-[25px] h-[25px] rounded-[25px] ${
              loomy ? 'bg-[rgba(17,56,59,0.3)]' : 'bg-[#E1E4EB]'
            } pointer-events-none`}
          >
            <div
              className={`w-full h-full rounded-[25px] ${
                anxy ? 'bg-[#EF670B]' : loomy ? 'bg-[#11383B]' : 'bg-[#2C4BEC]'
              } ${selected ? 'scale-100' : 'scale-0'} transition-all`}
            />
          </div>
        </div>
      </motion.div>
      <div className="select-none text-[15px] text-[#4E4F53] leading-[21px]  whitespace-nowrap ">{text}</div>
    </div>
  );
}
