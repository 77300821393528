import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { changeHeader } from '../../utils/webview';
import SwiperView from './SwiperView';

export default function CouponItem(props) {
  const { category, steps } = props;
  const navigate = useNavigate();
  const [isButtonDown, setButtonDown] = useState(false);
  const [searchParams] = useSearchParams();
  const isBiggerMargin = searchParams.get('isBiggerMargin') === 'true' || false;
  console.log(category);

  useEffect(() => {
    changeHeader({ backgroundColor: '#FFFFFF', text: '상담권 이용안내', buttonType: 'back' });
  }, []);

  return (
    <div
      className={`
        h-full w-screen  flex flex-col justify-between overflow-auto scrollbar-hide`}
    >
      <div className="h-full">
        {category ? (
          <div className={`${isBiggerMargin ? 'h-[calc(100%-88px)]' : 'h-[calc(100%-74px)]'}  relative`}>
            {category && (
              <div className="flex items-center justify-center h-[44px] text-[17px] leading-[26px] font-semibold">
                {category}
              </div>
            )}
            <SwiperView key={`${steps}`} steps={steps} category={category} />
          </div>
        ) : (
          <div className={`w-full h-full overflow-auto scrollbar-hide relative`}>
            <SwiperView key={`${steps}`} steps={steps} category={category} />
          </div>
        )}

        {category && (
          <div className={`${isBiggerMargin ? 'h-[88px]' : 'h-[74px]'} w-full rounded-[16px] px-20px`}>
            <div
              className={`flex items-center justify-center h-[54px] w-full rounded-[16px]  text-[17px] font-semibold text-white
              ${isButtonDown ? 'bg-[#98A3E0]' : 'bg-[#3953D9]'}
              `}
              onTouchStart={(e) => {
                console.log('mousedown');
                setButtonDown(true);
              }}
              onTouchEnd={(e) => {
                setButtonDown(false);
              }}
              onClick={() => navigate(-1)}
            >
              <p className="mb-[2px]">확인</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
