/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../../stories/page/BottomActionAnxy';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../../recoil/common/header/atom';
import userAtom from '../../../recoil/anxy/user/atom';
import { getDurationSecond, getHHMMSSFromSecond } from '../../../logic/method';
import useInterval from '../../../hooks/useInterval';
import useMixpanelEventAnxy from '../../../hooks/useMixpanelEventAnxy';

export default function Heart(props) {
  const goHome = useNavigateWithParams(`/anxyHome`);
  const [timeLeftToCharge, setTimeLeftToCharge] = useState(0);
  // const chargeTime = '2022-11-11T00:00:00';
  const setHeaderState = useSetRecoilState(headerAtom);
  const userState = useRecoilValue(userAtom);
  const userHeart = userState.heart;

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '하트',
  });

  useEffect(() => {
    if (userHeart) {
      if (userHeart.lastUseAt) {
        const date = new Date(userHeart.lastUseAt);
        date.setDate(date.getDate() + 1);
        let offset = date.getTimezoneOffset() * 60000; //ms단위라 60000곱해줌
        let datekr = new Date(date.getTime() - offset).toISOString().substring(0, 19);
        setTimeLeftToCharge(getDurationSecond(new Date(), new Date(datekr)));
      } else {
        setTimeLeftToCharge(0);
      }
    }
  }, []);

  useInterval(
    () => {
      setTimeLeftToCharge((timeLeftToCharge) => timeLeftToCharge - 1);
    },
    timeLeftToCharge > 0 ? 1000 : null,
  );

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE, headerTitle: '' }));
    setMixPanel();
  }, []);

  return (
    <BottomActionAnxy
      content={
        <div className="h-full flex flex-col justify-between px-[20px] text-[#26282C] pb-[20px]">
          <div className="flex-1 flex flex-col items-center justify-center gap-[20px] text-center ">
            <p className="text-[40px] font-bold">체력</p>
            <div className="relative">
              <svg width="153" height="127" viewBox="0 0 153 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.2169 11.0721C-3.73897 25.8348 -3.73897 49.77 11.2169 64.5327L22.34 75.5122L22.3126 75.5393L72.2597 124.842C74.5955 127.147 78.3505 127.148 80.6875 124.844L130.687 75.5393L130.66 75.5122L141.783 64.5327C156.739 49.77 156.739 25.8348 141.783 11.0721C126.827 -3.69069 102.579 -3.69069 87.6231 11.0721L76.5 22.0516L65.3769 11.0721C50.421 -3.69069 26.1728 -3.69069 11.2169 11.0721Z"
                  fill="url(#paint0_linear_2453_77901)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2453_77901"
                    x1="76.5"
                    y1="0"
                    x2="76.5"
                    y2="129"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#DC3535" />
                    <stop offset="1" stopColor="#C61C1C" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="absolute left-[50%] translate-x-[-50%] top-[20px] text-[50px] text-white">
                <span className="font-bold drop-shadow-[0_2px_4px_rgba(0,0,0,0.25)]">{userHeart.currentHeart}</span>
                <span className="font-thin">/{userHeart.totalHeart}</span>
              </div>
            </div>
          </div>
          <div className="p-[20px] bg-white rounded-[16px] font-bold text-center">
            <p className=" texxt-[17px] leading-[23px] mb-[6px]">
              {timeLeftToCharge > 0 ? '체력 +1 회복까지 남은 시간' : '회복할 체력이 없어요'}
            </p>
            <p className={`text-[24px] leading-[30px] mb-[15px] ${timeLeftToCharge === 0 && 'opacity-[0.4]'}`}>
              {getHHMMSSFromSecond(timeLeftToCharge)}
            </p>
            <div className="w-full h-[14px] rounded-[10px] bg-[#E1E4EB]">
              <div
                className="w-[50%] h-full rounded-[10px] bg-[#398047]"
                style={{ width: `${(timeLeftToCharge / (24 * 60 * 60)) * 100}%` }}
              />
            </div>
          </div>
        </div>
      }
      noHeader
      noGradient
      buttonText={'확인'}
      action={goHome}
      bgColor={'#F3F5F8'}
    />
  );
}
