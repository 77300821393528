/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { CheckLottie } from '../animation/CheckLottie';
import { close } from '../../utils/webview';
import { BottomAction } from './BottomAction';
import { BottomActionAnxy } from './BottomActionAnxy';

export function DonePage(props) {
  const { title, subtitle, gradient, white, actionButton, buttonText, action, anxy } = props;

  useEffect(() => {
    if (!actionButton) {
      setTimeout(() => {
        console.log('닫아');
        close({ refresh: true });
      }, 1200);
    }
  }, []);

  const Content = (
    <div className="pb-[30px]">
      <div className="mb-[30px] flex justify-center">
        <CheckLottie containerSize={'77px'} gradient={gradient} white={white} green={anxy} />
      </div>
      <div className=" text-[#26282C] text-[24px] leading-[30px] mt-[24px]  font-bold">{title}</div>
      {subtitle && subtitle.length > 0 && (
        <div className=" text-[#80838B] text-[17px] leading-[23px] mt-[8px]">{subtitle}</div>
      )}
    </div>
  );

  const Element = (
    <div
      className={`${
        actionButton ? 'w-screen h-full' : 'w-screen h-screen'
      } overflow-hidden  px-[20px]  flex flex-col justify-center items-center text-center  `}
    >
      {Content}
    </div>
  );

  return actionButton ? (
    anxy ? (
      <BottomActionAnxy content={Element} buttonText={buttonText} buttonState={'ACTIVE'} action={action} />
    ) : (
      <BottomAction content={Element} buttonText={buttonText} buttonState={'ACTIVE'} action={action} />
    )
  ) : (
    <div
      className={`w-screen h-screen overflow-hidden  px-[20px]  flex flex-col justify-center items-center text-center  `}
    >
      {Content}
    </div>
  );
}
