import { motion } from 'framer-motion';
import { PressedEffect } from '../../../stories/button/PressedEffect';
import { useState } from 'react';
import { checkTimeByHour } from '../../../logic/method';
import TimePicker from '../../../stories/time/TimePicker';
import { useRef } from 'react';
import { useEffect } from 'react';
import webviewToast from '../../../development/webviewToast';

export function NotificationSettingCard({ ref, state, setState, time, isSelected, setSelected, type, notDelete }) {
  const [hour, setHour] = useState(time.split(':')[0]);
  const [minute, setMinute] = useState(time.split(':')[1]);

  const getHour = (value) => {
    setHour(value);
  };

  const getMinute = (minute) => {
    setMinute(minute);
  };

  const hanldeDelete = () => {
    let array = [...state[type].timeList];
    array.splice(
      array.findIndex((item) => item === time),
      1,
    );
    setSelected();

    setState((state) => {
      const updated = { ...state };
      updated[type] = {
        timeList: array,
      };
      return updated;
    });
  };

  const handleUpdate = () => {
    const nowTime = `${hour}:${minute}`;
    const prevTime = `${time.split(':')[0]}:${time.split(':')[1]}`;

    let array = [...state[type].timeList];
    array.splice(
      array.findIndex((item) => item === prevTime),
      1,
      nowTime,
    );

    setState((state) => {
      const updated = { ...state };
      updated[type] = {
        timeList: array,
      };
      console.log('up', type, updated);
      return updated;
    });
  };

  const cardRef = useRef();

  useEffect(() => {
    if (cardRef && cardRef.current) {
      if (isSelected) {
        cardRef.current.focus();
      }
    }
  }, []);

  return (
    <PressedEffect
      element={
        <div
          ref={cardRef}
          className="flex flex-col  bg-white rounded-[20px] pb-[20px] px-[24px] outline-none "
          tabIndex={2}
          onBlur={() => {
            webviewToast('updated');
            handleUpdate();
          }}
        >
          <motion.div
            className="w-full flex justify-between items-center text-[#26282C]"
            style={{
              paddingTop: isSelected && !notDelete ? '24px' : '32px',
              paddingBottom: isSelected && !notDelete ? '16px' : '12px',
            }}
          >
            <div
              className="text-[18px] leading-[23px] font-semibold"
              style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }}
            >
              {checkTimeByHour(hour)}
            </div>
            <motion.div className="">
              {isSelected ? (
                <TimePicker hour={hour} minute={minute} getHour={getHour} getMinute={getMinute} />
              ) : (
                <p className="text-[30px] leading-[36px] font-bold ">{`${hour}:${minute}`}</p>
              )}
            </motion.div>
          </motion.div>
          {isSelected && !notDelete && (
            <div className="w-full" onClick={hanldeDelete}>
              <div className="w-full bg-black opacity-[0.1] h-[1px] mb-[16px]" />
              <p className="text-[#EF670B] text-[17px] leading-[24px] font-semibold text-center">제거하기</p>
            </div>
          )}
        </div>
      }
      action={() => setSelected(time)}
      disable={isSelected}
    />
  );
}
//
