import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export function WorryBubble(props) {
  const { containerSize } = props;
  const likecontainer = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('./worri_bubble.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <div>
      <div
        style={{ width: containerSize ? containerSize : '120px', height: containerSize ? containerSize : '90px' }}
        ref={likecontainer}
      ></div>
    </div>
  );
}
