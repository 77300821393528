import { useEffect, useRef, useState } from 'react';
import { useModalDrag } from '../../../hooks/useModalDrag';
import 'moment/locale/ko';
import moment from 'moment';
import webviewToast from '../../../development/webviewToast';

const getTimeDiff = (startTime, endTime) => {
  return moment.duration(moment(endTime).diff(startTime));
};

export default function SlideTimePicker({ isStart, startTime, endTime, selectedTime, changeSelectedTime, prevTime }) {
  const [prevPosition, setPrevPosition] = useState(0);
  // 현재와 끝 시간 차이 구해서 막대 개수 구하기
  const timeDiff = getTimeDiff(startTime, endTime);
  const minuteDiffDivByTen = timeDiff.asMinutes() / 10;
  const wholeStickNumber = minuteDiffDivByTen + 1;

  const timeArray = Array.from({ length: wholeStickNumber }, (v, i) => 0);

  const startPosition = 1;
  // 마지막 스크롤
  const endPosition = startPosition + 22 * wholeStickNumber - 22;
  const currentPosition = endPosition - ((endTime - 0) / 10) * 22;
  // 12시간 전

  const { iconRef } = useModalDrag();
  const scrollRef = useRef();

  const goToSelectedPosition = (startTime, selectedTime) => {
    const timeDiff = getTimeDiff(startTime, selectedTime);
    const minuteDiffDivByTen = timeDiff.asMinutes() / 10;
    scrollRef.current.scrollLeft = minuteDiffDivByTen * 22 + 1;
  };

  useEffect(() => {
    if (scrollRef.current) {
      // prev가 있다면 start와의 시간 계산해서 위치 변경해줌
      if (prevTime) {
        goToSelectedPosition(startTime, prevTime);
      } else {
        if (isStart) {
          goToSelectedPosition(startTime, endTime);
        }
      }
    }
  }, [currentPosition]);

  const isoFormat = 'YYYY-MM-DDTHH:mm:ss';

  return (
    <div className="relative h-[99px]">
      <div className="absolute top-[0] left-[50%] translate-x-[-50%] w-[4px] rounded-[16px] h-[99px] bg-[#2C4BEC]" />
      <div className="relative">
        <div
          ref={scrollRef}
          className="w-full flex overflow-x-scroll scrollbar-hide snap-mandatory snap-x items-center relative"
          style={{ scrollBehavior: 'smooth' }}
          onScroll={(e) => {
            const scrollX = e.target.scrollLeft;
            const endScroll = (wholeStickNumber - 1) * 22 + 1;

            if (scrollX > 0) {
              if (scrollX <= endScroll) {
                let changedPositionLength = parseInt(scrollX / 22);
                setPrevPosition(changedPositionLength);
                const changedMinute = changedPositionLength * 10;
                console.log(changedPositionLength);
                if (changedMinute % 10 === 0 && prevPosition !== changedPositionLength) {
                  const newTime = moment(startTime).add({ minutes: changedMinute }).format(isoFormat);
                  console.log(newTime);
                  changeSelectedTime(newTime);
                }
              }
            }
          }}
        >
          <div className={`relative flex`}>
            <div className="" style={{ width: window.innerWidth / 2 }} />
            <div className="flex relative ">
              {timeArray.map((item, index) => (
                <div className="relative left-0 flex items-center opacity-[0.3]">
                  {item === 1 ? (
                    <div className={`snap-center w-[2px] h-[45px] bg-[#3A3C40] mr-[20px] rounded-[50px]`} />
                  ) : (
                    <div
                      className={`snap-center w-[2px] h-[19px] bg-[#3A3C40] mr-[20px] rounded-[50px]
                    ${index === timeArray.length - 1 ? 'mr-[0]' : 'mr-[20px]'}
                    `}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="" style={{ width: window.innerWidth / 2, height: '99px' }} />
          </div>
        </div>
      </div>
    </div>
  );
}
