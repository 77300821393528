import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import WorryNoteTextArea from './WorryNoteTextArea';
import { AnxyActionButton } from '../../../stories/button/ActionButton';
import { Title } from '../../../stories/text/Title';
import { useRecoilValue } from 'recoil';
import deviceAtom from '../../../recoil/common/device';
import worryNoteAtom from '../../../recoil/anxy/worryNote/atom';
import useNavigateSession from '../../../hooks/useNavigateSession';
import backgroundColorAtom from '../../../recoil/common/backgroundColor/atom';
import { getSessionContents } from '../../../utils/anxySessionLogic';
import WorryNoteStaticInput from './WorryNoteStaticInput';
import InputWithAddButton from './InputWithAddButton';
import webviewToast from '../../../development/webviewToast';

export default function InputListTemplate(props) {
  const { sectionId, page } = useParams();
  const scrollRef = useRef();
  const titleRef = useRef();
  const inputRef = useRef();
  const { title, isStatic, addInputList, textareaList, necessaryParams, isFirst } = props;
  const [buttonState, setButtonState] = useState('INACTIVE');

  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);
  const [forceBlur, setForceBlur] = useState(false);

  const [isKeyboardOpen, setKeyboardOpen] = useState(false);

  const deviceState = useRecoilValue(deviceAtom);
  const worryNoteState = useRecoilValue(worryNoteAtom);
  const backgroundColor = useRecoilValue(backgroundColorAtom);

  const [scrollY, setScrollY] = useState(0);

  const isHome = getSessionContents(sectionId).length === parseInt(page);

  const [additionalHeight, setAdditionalHeight] = useState(0);

  useEffect(() => {
    if (deviceState.isIOS) {
      // if (isKeyboardOpen) {
      //   setAdditionalHeight(
      //     titleRef.current.offsetHeight + inputRef.current.offsetHeight + scrollY < scrollRef.current.offsetHeight
      //       ? scrollRef.current.offsetHeight -
      //           (titleRef.current.offsetHeight + inputRef.current.offsetHeight) +
      //           1 +
      //           scrollY
      //       : scrollY,
      //   );
      // } else {
      //   setAdditionalHeight(0);
      // }
      setAdditionalHeight(0);
    }
  }, [isKeyboardOpen]);

  useEffect(() => {
    setTimeout(() => {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollTop + additionalHeight,
      });
    }, 100);
  }, [additionalHeight]);

  const goToNextPage = useNavigateSession({ isHome: isHome });

  const handleAction = () => {
    setTimeout(() => {
      goToNextPage();
    }, 400);
  };

  useEffect(() => {
    if (forceBlur) {
      handleAction();
    }
  }, [forceBlur]);

  useEffect(() => {
    let isFilled = true;
    const necessaryParamList = necessaryParams
      ? necessaryParams
      : addInputList
      ? addInputList.map((item) => item.name)
      : textareaList.map((item) => item.name);
    necessaryParamList.forEach((item) => {
      const content = worryNoteState[item];
      if (!textareaList) {
        if (content.length === content.filter((item) => item === '').length) {
          isFilled = false;
        }
      }
      if (content === '' || typeof content === 'undefined' || content === null) {
        isFilled = false;
      }
    });
    if (isFilled) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [worryNoteState]);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  const handleResize = () => {
    setViewportSize(window.visualViewport.height);
    if (window.visualViewport.height < viewportSize) {
      setKeyboardOpen(true);
    } else {
      setKeyboardOpen(false);
    }
  };
  useEffect(() => {
    // keyboardOpen();
    document.body.style.backgroundColor = backgroundColor;
    window.addEventListener('scroll', handleScroll);
    window.visualViewport.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.visualViewport.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`w-screen h-screen overflow-hidden px-[20px]`}
      style={{
        backgroundColor: backgroundColor,
        paddingTop: deviceState.notchSize + 44,
        paddingBottom: deviceState.hasHomeIndicator ? `34px` : `20px`,
      }}
    >
      <div
        className="w-full h-[calc(100%-54px)] overflow-auto scrollbar-hide pb-[40px]"
        ref={scrollRef}
        onScroll={(e) => {
          console.log(e.target.scrollTop);
        }}
      >
        <motion.div
          className=" w-full "
          animate={{
            height: deviceState.isIOS && isKeyboardOpen ? additionalHeight : 0,
          }}
          transition={{ duration: 0 }}
        />
        <div className="pb-[40px] pt-[7px]" ref={titleRef}>
          <Title title={title} color={'#FFFFFF'} />
        </div>
        <div className="flex flex-col gap-[40px]" ref={inputRef}>
          {isStatic && <WorryNoteStaticInput title="나의 불안 문제" name="problem" />}
          {addInputList &&
            addInputList.map((item, index) => (
              <InputWithAddButton
                key={`inputWithAdd${index}`}
                index={index}
                title={item.title}
                placeholder={item.placeholder}
                name={item.name}
                autofocus={true}
                backgroundColor={backgroundColor}
              />
            ))}

          {textareaList &&
            textareaList.map((item, index) => (
              <WorryNoteTextArea
                key={`textArea${index}`}
                title={item.title}
                placeholder={item.placeholder}
                name={item.name}
                autofocus={index === 0}
              />
            ))}
        </div>
      </div>
      <div className=" w-full relative h-fit" style={{ backgroundColor: backgroundColor }}>
        <div
          className="w-full absolute top-[-30px] h-[30px] translate-y-[1px]"
          style={{
            background: `linear-gradient(0deg, ${backgroundColor} 0%, rgba(57, 128, 71, 0) 100%)`,
          }}
        />
        <AnxyActionButton state={buttonState} text={isHome ? '마치기' : '다음'} action={() => setForceBlur(true)} />
      </div>
    </div>
  );
}
