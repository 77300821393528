import moment from 'moment';
import 'moment/locale/ko';
import ToolCard from './ToolCard';
import EmotionHistoryCard from './EmotionHistoryCard';

const ToolCardItem = ({ toolList, isStarted, historyList }) => {
  const weeklyDateList = [];

  moment.locale('ko');

  for (let i = 0; i < 7; i++) {
    weeklyDateList[6 - i] = {
      day: 6 - i === 6 ? '오늘' : moment().add(-i, 'days').format('dd'),
      date: moment().add(-i, 'days').format('DD'),
    };
  }
  console.log(weeklyDateList);

  return (
    <div className="w-screen h-[553px] bg-white text-[#26282C] flex flex-col px-[20px] pt-[25px] text-[16px] leading-[22px]">
      <p className="mb-[13px] text-[20px] font-semibold leading-6 ">마음 도구</p>
      <div className="w-full flex" style={{ columnGap: '10px' }}>
        {toolList.slice(0, 2).map((toolData) => (
          <ToolCard data={toolData} isStarted={isStarted} />
        ))}
      </div>
      <div className="w-full">
        <EmotionHistoryCard historyList={historyList} />
      </div>
      <div className="w-full flex" style={{ columnGap: '10px' }}>
        {toolList.slice(2, 4).map((toolData) => (
          <ToolCard data={toolData} isStarted={isStarted} />
        ))}
      </div>
    </div>
  );
};

export default ToolCardItem;
