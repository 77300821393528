/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InputTemplate from '../../components/onboarding/InputTemplate';
import CoachMatching2 from '../../components/onboarding/CoachMatching2';
import CoachMatching3 from '../../components/onboarding/CoachMatching3';
import SelectTemplate from '../../components/onboarding/SelectTemplate';
import { changeHeader } from '../../utils/webview';

export default function CoachMatching() {
  let { id } = useParams();

  useEffect(() => {
    if (parseInt(id) === 0) {
      document.title = '코칭 스타일 선택';
    } else if (parseInt(id) === 1) {
      document.title = '닉네임 입력';
    } else if (parseInt(id) === 2) {
      document.title = '코치 찾는 중';
    }
  }, []);

  useEffect(() => {
    window.document.body.style.backgroundColor = 'white';

    if (parseInt(id) === 2) {
      changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'none' });
    } else {
      changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'back' });
    }
  }, []);

  return (
    <div className={`w-screen h-screen overflow-auto scrollbar-hide `}>
      {parseInt(id) === 0 && (
        <SelectTemplate
          id={parseInt(id)}
          category={'coachMatching'}
          question={'어떤 코칭을 원하나요?'}
          options={['공감과 응원', '조언과 지시', '이론과 정보']}
        />
      )}
      {parseInt(id) === 1 && <InputTemplate question={['코치가 당신을 어떻게 부르면 좋을까요?']} />}
      {parseInt(id) === 2 && <CoachMatching2 />}
      {parseInt(id) === 3 && <CoachMatching3 />}
    </div>
  );
}
