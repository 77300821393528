import React from 'react';

export default function DetectDeviceLegacy({ channel }) {
  const varUA = navigator.userAgent.toLowerCase();
  const isiOS = /(Mac|iPhone|iPod)/i.test(navigator.platform);
  const isiPad = varUA.indexOf('macintosh') > -1 && navigator.maxTouchPoints > 2;
  if (isiOS || isiPad) {
    if (channel === 'inside') {
      window.location.href = 'https://apps.apple.com/kr/app/%EC%9D%B8%EC%82%AC%EC%9D%B4%EB%93%9C-inside/id1596901525';
    } else {
      window.location.href = `https://apps.apple.com/app/apple-store/id1596901525?pt=123689644&ct=${channel}&mt=8`;
    }
  } else {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.orwellhealth.inside';
  }
  return <></>;
}
