import { atom } from 'recoil';

const notificationAtom = atom({
  key: 'somny_notification',
  default: {
    sleepTime: '23:00',
    wakeUpTime: '07:00',
  },
});

export default notificationAtom;
