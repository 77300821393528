/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useShowWebviewWithParams from '../../hooks/useShowWebviewWithParams';
import sleepReport from '../../image/sleepReport.png';
import { PressedEffect } from '../../stories/button/PressedEffect';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function SleepReportModule(props) {
  // const goSleepingReport = useShowWebviewWithParams('sleepingReport', '#303038');
  const goSleepingReport = useNavigateWithParams(`/sleepingReport?navigation=back`);
  return (
    <PressedEffect
      element={
        <div className=" w-full p-[20px] bg-[#F3F5F8] rounded-[16px] flex justify-between items-center">
          <div>
            <div className="text-[17px] leading-[23px] font-bold text-[#26282C] mb-[4px]">수면 리포트</div>
            <div className="text-[15px] leading-[21px] text-[#4E4F53] ">4주간의 수면 패턴을 분석해요</div>
          </div>
          <div className="w-[60px] h-[60px] rounded-[12px] bg-black overflow-hidden shrink-0">
            <img src={sleepReport} alt={'empty'} />
          </div>
        </div>
      }
      action={goSleepingReport}
    />
  );
}
