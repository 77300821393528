/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { RoundBottomAction } from '../../stories/page/RoundBottomAction';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';
import loomyWebWalkthrough0 from '../../image/loomy/web/loomyWebWalkthrough0.png';
import loomyWalkthrough1 from '../../image/loomy/loomyWalkthrough1.png';
import loomyWalkthrough2 from '../../image/loomy/loomyWalkthrough2.png';
import loomyWalkthrough3 from '../../image/loomy/loomyWalkthrough3.png';
import loomyWalkthrough4 from '../../image/loomy/loomyWalkthrough4.png';
import loomyWalkthrough5 from '../../image/loomy/loomyWalkthrough5.png';
import loomyWalkthrough6 from '../../image/loomy/loomyWalkthrough6.png';
import loomyWalkthrough7 from '../../image/loomy/loomyWalkthrough7.png';
import loomyWalkthrough8 from '../../image/loomy/loomyWalkthrough8.png';
import loomyWalkthrough9 from '../../image/loomy/loomyWalkthrough9.png';
import loomyWalkthrough10 from '../../image/loomy/loomyWalkthrough10.png';
import loomyWalkthrough11 from '../../image/loomy/loomyWalkthrough11.png';
import loomyWalkthrough12 from '../../image/loomy/loomyWalkthrough12.png';
import loomy from '../../image/loomy/loomy.png';

function Template({ title, subtitle, noGradient, image, content, buttonText, action, css, fixedRoot }) {
  return (
    <RoundBottomAction
      content={
        <div className="h-full flex flex-col">
          <div className="flex flex-col gap-[12px] px-[20px] text-[#11383B]">
            <div className="pt-[12px] text-[24px] leading-[30px] font-bold">{title}</div>
            {subtitle && <div className="text-[17px] leading-[24px]">{subtitle}</div>}
          </div>
          <div className={`px-[20px] flex-1 flex flex-col relative items-center justify-center  object-contain`}>
            {image && <img src={image} alt={'empty'} className={` ${css}`} />}
            {content && content}
          </div>
        </div>
      }
      noGradient={noGradient}
      buttonText={buttonText}
      action={action}
      bgColor={'#F3F0E7'}
      fixedRoot={fixedRoot}
      isLoomy
    />
  );
}

export default function LoomyWalkthrough(props) {
  let { id } = useParams();
  id = parseInt(id);
  const [searchParams] = useSearchParams();
  const app = searchParams.get('app') === 'true';
  const goNext = useNavigateWithParams(`/loomyWalkthrough/${id + 1}`);
  const goSubmitInfo = useNavigateWithParams(`/submitInfo/0?type=loomy&L1=true`);
  const goHome = useNavigateWithParams(`/loomyHome`);
  const setHeaderState = useSetRecoilState(headerAtom);

  const bottomImageCss = app
    ? 'absolute bottom-[-35px] left-[50%] translate-x-[-50%] w-full h-full px-[20px] max-w-[375px] object-contain object-bottom'
    : 'absolute bottom-[-35px] max-w-[230px] left-[50%] translate-x-[-50%]';

  const walkthroughData = [
    {
      title: '모든 내용은 우울 및 무기력 인지행동치료에 기반했어요',
      image: loomyWebWalkthrough0,
      css: 'mt-[40px] ',
      mixpanel: '(L1) 코스',
    },
    {
      title: 'Loomy는 날씨를 연구하는 기상학자예요',
      image: loomyWalkthrough1,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 1',
    },
    {
      title: 'Loomy가 사는 마을은 날씨가 좋지 않죠 ',
      image: loomyWalkthrough2,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 2',
    },
    {
      title: 'Loomy와 함께 마음 날씨를 측정해보세요',
      image: loomyWalkthrough3,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 3',
    },
    {
      title: '그러면 날씨를 우울하게 만드는 네 가지 요인을 알아낼 수 있답니다',
      image: loomyWalkthrough4,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 4',
    },
    {
      title: 'Loomy가 마음 날씨 좋게 만드는 이론 지식을 알려줄 거예요',
      image: loomyWalkthrough5,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 5',
    },
    {
      title: '매일 실천할 수 있는 도구도 줄 거랍니다',
      image: loomyWalkthrough6,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 6',
    },
    {
      title: '[행동] 좋아하는 활동을 하며 나를 무기력하게 하는 안개를 걷어내요',
      image: loomyWalkthrough10,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 7',
    },
    {
      title: '[생각] 부정적인 생각 번개를 바로 잡아요',
      image: loomyWalkthrough8,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 8',
    },
    {
      title: '[감정] 바람의 세기를 관찰하며 내 감정을 다루는 방법을 배워요',
      image: loomyWalkthrough9,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 9',
    },

    {
      title: '[상황] 스트레스 상황에서 나를 보호하는 우산을 준비해요',
      image: loomyWalkthrough11,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 10',
    },
    {
      title: '함께하다보면, 어느새 내 마음의 날씨가 좋아질 거랍니다',
      image: loomyWalkthrough12,
      css: bottomImageCss,
      mixpanel: '(L1) 핵심 경험 11',
    },
    {
      title: '효과가 입증된 연구 결과를 바탕으로 만들어졌으니까요',
      content: (
        <div className="flex-1 flex flex-col  pb-[24px] items-center ">
          <div className="flex-1 flex flex-col gap-[40px] justify-center items-center">
            {[
              { percentage: 71, effect: '우울 증상의 임상적 개선' },
              { percentage: 57, effect: '전반적인 기분 개선' },
              { percentage: 47, effect: '불면증 개선' },
            ].map((each, index) => (
              <div className="flex flex-col gap-[8px] items-center " key={`effect${index}`}>
                <div className="flex text-[50px] text-[#11383B] font-bold">
                  <p>{each.percentage}%</p>
                </div>
                <div className="text-[17px] leading-[23px] text-[#6B6D76]">{each.effect}</div>
              </div>
            ))}
          </div>
          <div className="flex-none text-[13px] opacity-[0.6] ">Loomy 베타 테스트 결과</div>
        </div>
      ),
      mixpanel: '(L1) 효과',
    },
    {
      id: 12,
      title: '그럼 이제 Loomy를 만나보세요',
      image: loomy,
      css: 'max-w-[230px] ',
      mixpanel: '(L) 핵심 경험 10',
    },
  ];
  const currentWalkthroughData = walkthroughData[id];

  const setMixpanel = useMixpanelWeb({
    eventName: `${currentWalkthroughData.mixpanel}`,
    projectName: 'loomy',
  });

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
    if (currentWalkthroughData.mixpanel) setMixpanel();
  }, []);

  return (
    <Template
      title={currentWalkthroughData.title}
      subtitle={currentWalkthroughData.subtitle}
      noGradient={id !== 0}
      css={currentWalkthroughData.css}
      image={currentWalkthroughData.image}
      content={currentWalkthroughData.content}
      buttonText={app ? (id < 13 ? '다음' : 'loomy 시작하기') : '다음'}
      action={app ? (id < 13 ? goNext : goHome) : id < 12 ? goNext : goSubmitInfo}
      fixedRoot={!app}
    />
  );
}
