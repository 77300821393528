import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { replaceColor } from 'lottie-colorify';
import CheckSolid from './check_solid.json';

export function CheckLottie(props) {
  const { containerSize, gradient, white, green, orange, greenInvert, gray, yellow, somny, isStop } = props;
  const likecontainer = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: isStop ? false : true,
      animationData: gradient
        ? require('./check_gradient.json')
        : white
        ? require('./check_white.json')
        : green
        ? require('./check_green.json')
        : greenInvert
        ? require('./check_green_invert.json')
        : orange
        ? require('./check_orange.json')
        : gray
        ? replaceColor('#2C4BEC', '#A4A6B0', CheckSolid)
        : yellow
        ? replaceColor('#2C4BEC', '#FFE03C', CheckSolid)
        : somny
        ? replaceColor('#FFFFFF', '#121B40', replaceColor('#2C4BEC', '#79AEFF', CheckSolid))
        : require('./check_solid.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <div>
      <div style={{ width: containerSize, height: containerSize }} ref={likecontainer}></div>
    </div>
  );
}
