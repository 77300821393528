import { useEffect, useRef } from 'react';
import { createRef } from 'react';
import { useRecoilState } from 'recoil';
import somnyAtom from '../../../recoil/somny/atom';
import { somnyFairyTaleData } from '../../../data/somny/somnyFairyTaleData';
import emptyBook from '../../../image/somny/sleepTale/empty_book.png';

import { TaleItem } from './TaleItem';
import statusAtom from '../../../recoil/somny/status/atom';

export default function TaleCarousel({ taleList }) {
  const carouselRef = createRef();
  const scrollRef = useRef();

  const [statusState, setStatusState] = useRecoilState(statusAtom);
  const taleId = statusState.taleId ? statusState.taleId : 'fairytale_aa';
  console.log(taleId);
  const openTaleIndex = somnyFairyTaleData.findIndex((item) => item.taleId === taleId);
  console.log(openTaleIndex);
  const [somnyState, setSomnyState] = useRecoilState(somnyAtom);
  const cardWidth = window.innerWidth - 48 >= 177 ? 177 : window.innerWidth - 48;
  const emptyData = Array(10).fill({ image: emptyBook, isLock: true });

  useEffect(() => {
    if (somnyState.completedAt) {
      if (somnyState.isFirst) {
        setTimeout(() => {
          const x = cardWidth + 10;
          scrollRef.current.scrollTo({
            left: x,
            behavior: 'smooth',
          });
          setSomnyState((state) => ({ ...state, isFirst: false }));
        }, 900);
      } else {
        if (scrollRef.current) {
          scrollRef.current.scrollLeft = cardWidth + 10;
        }
      }
    }
  }, []);

  return (
    <div ref={carouselRef}>
      <div className={`relative w-screen`}>
        <div ref={scrollRef} className="w-full flex overflow-auto snap-x snap-mandatory scrollbar-hide">
          <div
            className="flex gap-[40px]"
            style={{
              paddingLeft: (window.innerWidth - cardWidth) / 2,
              paddingRight: (window.innerWidth - cardWidth) / 2,
            }}
          >
            {taleList.concat(emptyData).map((item, index) => (
              <div
                key={`carousel${index}`}
                className="shrink-0 snap-center snap-always"
                style={{
                  display: 'inline-block',
                  width: cardWidth,
                }}
              >
                <TaleItem
                  taleId={item.sleepFairyTaleId}
                  title={item.title}
                  isLock={item.isLock}
                  isFirstLock={
                    taleList.filter((item) => item.isLock).length === 0
                      ? index === 3
                      : taleList.filter((item) => item.isLock)[0] === item
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
