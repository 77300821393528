/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { RoundBottomAction } from '../../../stories/page/RoundBottomAction';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import headerAtom, { BUTTON_NONE } from '../../../recoil/common/header/atom';
import useMixpanelWeb from '../../../hooks/useMixpanelWeb';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import loomyPreview0 from '../../../image/loomy/loomyPreview0.png';

export default function LoomyResult(props) {
  const { typeData } = props;

  const { selfCheckResult } = useRecoilValue(selfCheckAtom);
  const type = selfCheckResult[0].id;
  const currentTypeData = typeData.find((element) => element.id === type);
  const goNext = useNavigateWithParams(`/loomyTest/analysis1`);

  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const setHeaderState = useSetRecoilState(headerAtom);

  const setMixpanel = useMixpanelWeb({ eventName: '(LT) 검사 결과 1', projectName: 'loomy' });

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
    setMixpanel();
    console.log(selfCheckResult);
  }, []);

  return (
    <RoundBottomAction
      content={
        <div className="w-full h-full flex flex-col items-center justify-center text-center text-[#11383B]">
          <div className="mb-[60px]">
            <p className=" text-[24px] leading-[30px] opacity-[0.6] mb-[8px] ">{'내 우울의 원인은'}</p>
            <p className="  font-bold text-[40px]  whiteSpace-pre-line  ">{currentTypeData.title}</p>
          </div>
          <div className={`mb-[30px] max-w-[375px] mx-auto relative `}>
            <img src={loomyPreview0} alt={'empty'} className="" />
            {selfCheckResult.map((result, index) => (
              <div
                className={`absolute ${
                  result.id === 1
                    ? 'left-0 top-[25%]'
                    : result.id === 2
                    ? 'bottom-0 left-[13%]'
                    : result.id === 3
                    ? 'bottom-0 right-[13%]'
                    : result.id === 4
                    ? 'top-0 left-[50%] translate-x-[-50%]'
                    : 'right-0 top-[25%]'
                }  w-[34.5%] h-[34.5%] max-w-[126px] max-h-[126px] rounded-[50%] bg-[rgba(0,0,0,0.5)] bg-[#D9D8B7]  font-bold flex flex-col gap-[5px] items-center justify-center`}
              >
                <p className="text-[24px] text-[#11383B] ">
                  {typeData.find((element) => element.id === result.id).name}
                </p>
                <p className="text-[20px] text-[#485146]">
                  {result.score}
                  <span className="text-[#899886]">/15</span>
                </p>
              </div>
            ))}
            <div className="">
              <div
                className={`absolute top-[47%] left-[50%] translate-y-[-50%] translate-x-[-50%]  origin-[50%_85%] 
                ${
                  type === 1
                    ? 'rotate-[-72deg]'
                    : type === 2
                    ? 'rotate-[-144deg]'
                    : type === 3
                    ? 'rotate-[144deg]'
                    : type === 4
                    ? 'rotate-0'
                    : 'rotate-[72deg]'
                }`}
              >
                <svg width="16" height="62" viewBox="0 0 16 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.75318 0.861859C8.5929 -0.287288 6.93263 -0.287285 6.77235 0.861862L0.0479562 49.0734C-0.134692 50.3829 0.208786 51.7115 1.00324 52.7684L6.96341 60.6975C7.36344 61.2297 8.16209 61.2297 8.56212 60.6975L14.5223 52.7684C15.3167 51.7115 15.6602 50.3829 15.4776 49.0734L8.75318 0.861859ZM7.76274 53.761C9.97188 53.761 11.7627 51.9702 11.7627 49.761C11.7627 47.5519 9.97188 45.761 7.76274 45.761C5.55361 45.761 3.76274 47.5519 3.76274 49.761C3.76274 51.9702 5.55361 53.761 7.76274 53.761Z"
                    fill="#11383B"
                  />
                </svg>
              </div>
            </div>
          </div>
          <p className=" text-[13px] leading-[20px] text-[#777777] text-center ">
            {'※ 15점 만점으로, 점수가 높을 수록 좋지 않아요'}
          </p>
        </div>
      }
      buttonText={'자세히 보기'}
      buttonBgColor={'#11383B'}
      buttonTextColor={'#ffffff'}
      action={goNext}
      bgColor="#F3F0E7"
      noGradient
      fixedRoot
    />
  );
}
