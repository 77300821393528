const effectText1 = '나의 노력이 많이 필요해요';
const effectText2 = '나쁘지 않은 효과를 볼 수 있어요';
const effectText3 = '만족스러운 효과를 볼 수 있어요';
const effectText4 = '삶의 변화가 느껴질 거예요';

export const resultByScore = [
  {
    score: 0,
    effectText: effectText1,
    counselingNumber: 15,
    expectedMonth: 5,
  },
  {
    score: 10,
    effectText: effectText1,
    counselingNumber: 15,
    expectedMonth: 5,
  },
  {
    score: 20,
    effectText: effectText1,
    counselingNumber: 15,
    expectedMonth: 5,
  },
  {
    score: 30,
    effectText: effectText1,
    counselingNumber: 12,
    expectedMonth: 4,
  },
  {
    score: 40,
    effectText: effectText1,
    counselingNumber: 12,
    expectedMonth: 4,
  },
  {
    score: 50,
    effectText: effectText2,
    counselingNumber: 9,
    expectedMonth: 3,
  },
  {
    score: 60,
    effectText: effectText2,
    counselingNumber: 9,
    expectedMonth: 3,
  },
  {
    score: 70,
    effectText: effectText3,
    counselingNumber: 6,
    expectedMonth: 2,
  },
  {
    score: 80,
    effectText: effectText3,
    counselingNumber: 6,
    expectedMonth: 2,
  },
  {
    score: 90,
    effectText: effectText4,
    counselingNumber: 3,
    expectedMonth: 1,
  },
  {
    score: 100,
    effectText: effectText4,
    counselingNumber: 3,
    expectedMonth: 1,
  },
];
