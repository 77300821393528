import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function EmotionSummaryAccordianItem({ item, resizeScrollHeight }) {
  const { id } = useParams();
  const [isSeeMore, setSeeMore] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const detailRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  }, []);

  function seeAnswer() {
    setRotate((rotate) => rotate + 1);
    setSeeMore((isSeeMore) => !isSeeMore);
    if (isSeeMore) {
      resizeScrollHeight(-detailRef.current.offsetHeight);
    } else {
      resizeScrollHeight(detailRef.current.offsetHeight);
    }
  }

  return (
    <div className={`bg-[#F3F5F8] rounded-[20px] w-full mx-auto mb-10px ${loaded ? 'opacity-[1]' : 'opacity-0'}`}>
      <motion.div
        className={`overflow-hidden `}
        animate={{ height: isSeeMore && detailRef.current ? `${66 + detailRef.current.offsetHeight}px` : '66px' }}
        transition={{ duration: loaded ? 0.2 : 0 }}
      >
        <div className={`w-full h-full bg-[#F3F5F8] rounded-[16px] pt-[20px] pb-[20px] px-[24px] `} onClick={seeAnswer}>
          <div className=" flex justify-between">
            <p className="text-[17px] leading-[26px] text-black font-semibold inline ">{item.title}</p>
            <div className="flex items-center">
              <motion.div
                className="inline-block my-auto pr-[1px] "
                animate={{ rotate: 180 * rotate }}
                transition={{ duration: loaded ? 0.2 : 0 }}
              >
                <p>
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 1.5L6 6.5L11 1.5"
                      stroke="#26282C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </motion.div>
            </div>
          </div>
          {
            <motion.div
              className={`pointer-events-none `}
              animate={{ opacity: isSeeMore ? 1 : 0 }}
              transition={{ duration: loaded ? 0.2 : 0 }}
            >
              <div ref={detailRef} className="pt-[20px] pb-[4px]">
                <div className="w-full">
                  {item.situationList &&
                    item.situationList.map((text, index) => (
                      <div className="text-[#26282C]" style={{ marginBottom: '10px' }}>
                        <div className="bg-[#E1E4EB] rounded-[12px] p-[20px]">
                          <div className="flex items-center">
                            <div className="pb-[1px] px-[6px] font-semibold w-[33px] bg-[#4E4F53] rounded-[4px] text-white text-[12px] leading-[18px]  ">
                              상황
                            </div>
                            <p className="font-semibold ml-[10px] text-[17px] leading-[23px] ">{text.situation}</p>
                          </div>
                          <div className="flex items-center mt-[5px]">
                            <div className="bg-[#2C4BEC] pb-[1px] px-[6px] font-semibold w-[33px] rounded-[4px] text-white text-[12px] leading-[18px]  ">
                              감정
                            </div>
                            <p className="font-semibold ml-[10px] text-[17px] leading-[23px]  mb-[1px]">
                              {text.word || '다시 입력해 주세요'} 감정을 느껴요
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  {item.descriptionList && item.questionList ? (
                    <div className="text-[#26282C]" style={{ marginBottom: '10px' }}>
                      <div
                        className={`text-[17px] leading-[26px] bg-white text-black flex rounded-[16px] px-[20px] pt-[13px] pb-[15px] mb-[10px]`}
                        style={{ border: '1px dashed #A4A6B0' }}
                      >
                        <div>
                          <p className="font-bold mb-[2px]">{item.questionList[0]}</p>
                          <span className="">{`${item.descriptionList.situation || '다시 입력해 주세요'}`}</span>
                        </div>
                      </div>
                      <div
                        className={`text-[17px] leading-[26px] bg-white text-black flex rounded-[16px] px-[20px] pt-[13px] pb-[15px] mb-[10px]`}
                        style={{ border: '1px dashed #A4A6B0' }}
                      >
                        <div>
                          <p className="font-bold mb-[2px]">{item.questionList[1]}</p>
                          <span className="">{`${item.descriptionList.description || '다시 입력해 주세요'} `}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-[#26282C]" style={{ marginBottom: '10px' }}>
                      <div
                        className={`text-[17px] leading-[26px] bg-white text-black flex rounded-[16px] px-[20px] pt-[13px] pb-[15px] mb-[10px]`}
                        style={{ border: '1px dashed #A4A6B0' }}
                      >
                        <div>
                          <p className="font-bold mb-[2px]">최근에 언제 기뻤나요?</p>
                          <span className="">미입력</span>
                        </div>
                      </div>
                      <div
                        className={`text-[17px] leading-[26px] bg-white text-black flex rounded-[16px] px-[20px] pt-[13px] pb-[15px] mb-[10px]`}
                        style={{ border: '1px dashed #A4A6B0' }}
                      >
                        <div>
                          <p className="font-bold mb-[2px]">어떻게 기뻤나요?</p>
                          <span className="">미입력</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          }
        </div>
      </motion.div>
    </div>
  );
}
