export const sessionMockData = [
  { sessionId: 'ac', seq: 1, isLock: false, isNotReady: false },
  { sessionId: 'bd', seq: 2, isLock: true, isNotReady: false },
  { sessionId: 'pk', seq: 3, isLock: true, isNotReady: false },
  { sessionId: 'dk', seq: 4, isLock: true, isNotReady: true },
  { sessionId: 'lm', seq: 5, isLock: true, isNotReady: true },
  { sessionId: 'sd', seq: 6, isLock: true, isNotReady: true },
  { sessionId: 'tl', seq: 7, isLock: true, isNotReady: true },
  { sessionId: 'ps', seq: 8, isLock: true, isNotReady: true },
];

// session1에서 쓰는 user 응답 포맷
export const responseForm = {
  problem: '',
  externalFactorList: [''],
  internalFactorList: [''],
  objective: '',
  score: 0,
  bodyReactionList: [''],
  thoughtList: [''],
  behaviorList: [''],
};
