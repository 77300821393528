import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ic_provider from '../../../image/ic_provider.png';
import ic_policy from '../../../image/ic_policy.png';
import ic_account from '../../../image/ic_account.png';
import ic_appversion from '../../../image/ic_appversion.png';
import { showModal } from '../../../utils/webview';
import ContainerByDeviceAnxy from '../../../components/elements/ContainerByDeviceAnxy';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useSetRecoilState, useRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import sessionAtom from '../../../recoil/betterme/session/atom';
import myPageAtom from '../../../recoil/betterme/myPage/atom';

function Menu(props) {
  const { id, icon, text, appVersion } = props;

  const goMenu = useNavigateWithParams(`/betterMeMyPage/${id}${id === 5 ? `?app_version=${appVersion}` : ''}`);
  return (
    <div
      className="w-full flex items-center gap-[7px] py-[15px]"
      onClick={() => {
        if (id === 1) {
          showModal({ url: 'https://pf.kakao.com/_Hsyjxj', title: 'KakaoTalk', type: 'web' });
        } else {
          goMenu();
        }
      }}
    >
      <div className="shrink-0 w-[30px]">
        <img src={icon} alt={'empty'} />
      </div>
      <div className="flex-1 text-[17px] leading-[23px] font-bold text-[#26282C]">{text}</div>
      <div className="shrink-0 flex gap-[7px] items-center">
        {id === 5 && <div className="text-[17px] leading-[23px] text-[#4E4F53] font-semibold">{appVersion}</div>}
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.5 12L7.5 7L2.5 2"
            stroke="#26282C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
export default function BetterMeMyPageHome() {
  const setHeaderState = useSetRecoilState(headerAtom);
  const setSessionState = useSetRecoilState(sessionAtom);
  const [myPageState, setMyPageState] = useRecoilState(myPageAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_BACK, headerTitle: '', headerColor: '#26282C' });
    setSessionState((state) => ({ ...state, currentSession: undefined }));
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuMap = [
    { id: 3, icon: ic_policy, text: '이용약관' },
    { id: 4, icon: ic_provider, text: '사업자 정보' },
  ];

  return (
    <ContainerByDeviceAnxy>
      <div className="w-full overflow-x-hidden h-full px-[20px] py-[20px]">
        {menuMap.map((menu, index) => (
          <Menu
            id={menu.id}
            icon={menu.icon}
            text={menu.text}
            key={`menu${index}`}
            appVersion={myPageState.userProfile && myPageState.userProfile.appVersion}
          />
        ))}
      </div>
    </ContainerByDeviceAnxy>
  );
}
