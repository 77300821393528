export const habitData = [
  {
    content: (
      <div className="pb-[100px]">
        <p
          className="text-[60px] leading-[66px] font-bold text-[#FFFFFF4D]"
          style={{
            wordBreak: 'keep-all',
          }}
        >
          Have <span className="text-[#FF6B6B]">trouble</span> falling asleep? Wake up in the{' '}
          <span className="text-[#3CD0FF]">middle</span> of the night? Feel{' '}
          <span className="text-[#FFE03C]">groggy</span> and <span className="text-[#51FFE0]">drained</span> in the
          morning? Have <span className="text-[#FF6B6B]">low energy</span> during the day?
        </p>
      </div>
    ),
    buttonText: 'Feeling so',
  },
  {
    content: (
      <div className="h-full flex items-end pb-[114px] text-white text-[40px] leading-[48px] font-bold">
        Getting good sleep is crucial for your mental and physical health.
      </div>
    ),
    buttonText: 'Obviously',
  },
  {
    content: (
      <div className="h-full flex items-end pb-[54px] text-white text-[40px] leading-[48px] font-bold">
        Sleep better in days by following research-backed sleep habits.
      </div>
    ),
    buttonText: 'Science?',
  },
  {
    content: (
      <div className="h-full flex items-end pb-[102px] text-white text-[40px] leading-[48px] font-bold">
        Trust the science, build your habits, and sleep better in 7 days.
      </div>
    ),
    buttonText: (
      <svg width="58" height="23" viewBox="0 0 58 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.126 1.556V17H0.364V1.556H4.126ZM7.05028 1.556H10.9003L8.43628 7.452H5.90628L7.05028 1.556ZM16.2123 0.72V17H12.4503V0.72H16.2123ZM22.0405 0.72V17H18.2785V0.72H22.0405ZM34.9509 13.81V17H33.0369C31.6729 17 30.6096 16.67 29.8469 16.01C29.0843 15.3353 28.7029 14.2427 28.7029 12.732V7.848H27.2069V4.724H28.7029V1.732H32.4649V4.724H34.9289V7.848H32.4649V12.776C32.4649 13.1427 32.5529 13.4067 32.7289 13.568C32.9049 13.7293 33.1983 13.81 33.6089 13.81H34.9509ZM40.1484 6.77C40.5884 6.09533 41.1384 5.56733 41.7984 5.186C42.4584 4.79 43.1918 4.592 43.9984 4.592V8.574H42.9644C42.0258 8.574 41.3218 8.77933 40.8524 9.19C40.3831 9.586 40.1484 10.29 40.1484 11.302V17H36.3864V4.724H40.1484V6.77ZM57.6766 4.724L49.9766 22.83H45.9286L48.7446 16.582L43.7506 4.724H47.9526L50.7906 12.402L53.6066 4.724H57.6766Z"
          fill="#131635"
        />
      </svg>
    ),
  },
  {
    content: (
      <div className="h-full flex justify-center items-center">
        <p className="text-[22px] leading-[26.4px] font-bold text-white opacity-[0.6]">Creating personal space..</p>
      </div>
    ),
  },
];
