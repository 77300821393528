import React, { useEffect } from 'react';
import useLoadImage from '../../../hooks/useLoadImage';
import useNavigateSession from '../../../hooks/useNavigateSession';
import anxyMusic from '../../../image/anxy/anxyMusic.png';
import AudioPlayer from '../tool/AudioPlayer';

export function MeditateInSession({ backgroundColor }) {
  const hanldEnd = useNavigateSession({ isHome: true, isVideo: true });
  const loadImage = useLoadImage([anxyMusic]);
  useEffect(() => {
    loadImage();
  }, []);

  return (
    <div
      className="w-full flex flex-col items-center justify-end h-screen"
      style={{
        background: backgroundColor,
      }}
    >
      <div className="absolute top-0">
        <img className="w-full h-full object-cover min-h-[710px] max-w-[420px] m-auto" src={anxyMusic} alt="empty" />
      </div>
      <div
        className="h-[230px] w-full absolute bottom-0 z-[50] bg-black"
        style={{
          background: 'linear-gradient(180deg, rgba(243, 245, 248, 0) 0%, #F3F5F8 32.01%)',
        }}
      />
      <div className="w-full mx-auto z-[50]">
        <AudioPlayer audio={'https://media.priv-inside.im/anxy/session2.wav'} notSkip action={hanldEnd} />
      </div>
    </div>
  );
}
