import React, { useEffect, useRef, useState } from 'react';
import { AnxyLoadingLottie } from 'stories/animation/AnxyLoadingLottie';
import { useRecoilState, useRecoilValue } from 'recoil';
import SectionCard from 'pages/anxy/anxy12ndA/SectionCard';
import sessionAtom from 'recoil/anxy/session/atom';
import deviceAtom from 'recoil/common/device';
import PageToast from 'components/elements/PageToast';

export default function SessionCardList({
  chapterList,
  sessionList,
  setScrollTo,
  isPaymentRequired,
  isTestClosed,
  isInReview,
}) {
  const [sessionState, setSessionState] = useRecoilState(sessionAtom);
  const deviceState = useRecoilValue(deviceAtom);

  const [showPageToast, setShowPageToast] = useState(false);
  const [toastType, setToastType] = useState();
  const sectionRefs = useRef([]);
  const cardHeight = 150;

  useEffect(() => {
    if (sessionState.scrollToSession) {
      const sectionId = sessionState.scrollToSession;
      const index = sessionList.findIndex((element) => element.sectionId === sectionId);
      const y = index === 0 ? 0 : sectionRefs.current[index].getBoundingClientRect().y - 16;
      const scrollMove = y + deviceState.notchSize + parseInt(cardHeight / 2) - window.innerHeight / 2;
      console.log('scrolltoSession', sectionId);
      if (sectionId === 'session_af') {
        console.log('scrollto', scrollMove + cardHeight);
        setScrollTo(scrollMove + cardHeight);
      } else {
        console.log('scrollto2', scrollMove + cardHeight);
        setScrollTo(scrollMove);
      }
    }
  }, [sessionState]);

  return chapterList && sessionList ? (
    <div className="relative w-full bg-[#F3F5F8]">
      <PageToast
        text={toastType === 'upgrade' ? '이미 업그레이드했어요' : '이미 획득한 도구예요'}
        showPageToast={showPageToast}
        setShowPageToast={setShowPageToast}
      />
      {chapterList.map(({ title, sectionList }, index) => (
        <div key={`chapter${index}`}>
          <div className="text-[24px] leading-[29px] text-black font-bold px-[24px]">{title}</div>

          <div className="relative w-full pt-[20px] pb-[100px]">
            {sectionList.map((each, index2) => (
              <div
                key={each.sectionId}
                ref={(element) => {
                  sectionRefs.current[sessionList.findIndex((element) => element.sectionId === each.sectionId)] =
                    element;
                }}
              >
                <SectionCard
                  index={index2}
                  sectionId={each.sectionId}
                  type={each.type}
                  isLock={each.isLock}
                  isCompleted={each.completedAt ? true : false}
                  isFirst={each.isFirst}
                  isBetweenExercise={sectionList[index2 + 1] && sectionList[index2 + 1].type === 'box'}
                  isChapterEnd={index2 === sectionList.length - 1}
                  pageLastViewed={each.pageLastViewed}
                  isFirstUnlock={each.isFirstUnlock}
                  isFirstComplete={each.isFirstComplete}
                  isPaymentRequired={isPaymentRequired}
                  isTestClosed={isTestClosed}
                  isInReview={isInReview}
                  isNotReady={each.isNotReady}
                  setShowPageToast={setShowPageToast}
                  setToastType={setToastType}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <AnxyLoadingLottie />
    </div>
  );
}
