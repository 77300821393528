/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import { BottomActionAnxy } from 'stories/page/BottomActionAnxy';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from 'recoil/common/header/atom';
import selfCheckAtom from 'recoil/common/selfCheck/atom';
import userAtom from 'recoil/anxy/user/atom';
import useMixpanelEventAnxy from 'hooks/useMixpanelEventAnxy';
import anxyTypeData from 'data/anxyTypeData';
import Wori from 'components/anxy/anxy12ndA/Wori';
import CircularProgressingAnxy from 'stories/page/CircularProgressingAnxy';
import walkthrough1 from 'image/anxy/12B/walkthrough1.png';
import walkthrough2 from 'image/anxy/12B/walkthrough2.png';
import Lottie from 'components/elements/Lottie';
import anxy_big_lottie from 'image/anxy/12A/anxy_big_lottie.json';
import useFetchAnxy from 'hooks/useFetchAnxy';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import TextAreaTemplate from 'stories/page/TextAreaTemplate';

function Guide({ title, noGradient, content, buttonText, action, justifyEnd }) {
  return (
    <BottomActionAnxy
      content={
        <div className="h-full flex flex-col">
          <div className="pt-[12px] text-[24px] leading-[30px] font-bold px-[20px]">{title}</div>
          <div className={`flex-1 flex flex-col ${justifyEnd ? 'justify-end' : 'justify-center'}`}> {content}</div>
        </div>
      }
      noGradient={noGradient}
      buttonText={buttonText}
      action={action}
      bgColor={'#ffffff'}
    />
  );
}

export default function WalkthroughA(props) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = parseInt(searchParams.get('type'));
  const fromBubble = searchParams.get('fromBubble') === 'true';

  const setHeaderState = useSetRecoilState(headerAtom);
  const setSelfCheckState = useSetRecoilState(selfCheckAtom);
  const setUserState = useSetRecoilState(userAtom);
  const userState = useRecoilValue(userAtom);

  const woriContainerRef = useRef();

  const [score, setScore] = useState(userState.anxietyScore !== undefined ? userState.anxietyScore : 50);
  const goNext = useNavigateWithParams(`/walkthroughA/${parseInt(id) + 1}`);
  const goHome = useNavigateWithParams(`/anxyHomeA`);

  function updateValue(value) {
    setUserState((state) => ({ ...state, anxietyNote: value }));
  }

  const initializeSelfCheckAndGoHome = () => {
    setUserState((state) => ({ ...state, anxietyNote: undefined, anxietyScore: undefined }));
    setSelfCheckState({ qcList: [], selfCheckAnswer: [], selfCheckResult: [] });
    goHome();
  };

  const [saveAnxietyResultResult, saveAnxietyRecord] = useFetchAnxy({
    url: 'anxy/anxiety-record/save',
    requestBody: JSON.stringify({
      note: userState.anxietyNote,
      score: userState.anxietyScore,
    }),
  });

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '불안 타입 검사 결과',
    params: {
      체크포인트: '어떻게 관리하나요?',
    },
  });

  useEffect(() => {
    if (parseInt(id) < 9) {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
    } else {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
    }

    setUserState((state) => ({ ...state, anxietyTypeId: type }));

    if (id === 'typeInfo2') {
      setMixPanel();
    }

    if (userState.score !== undefined && userState.anxietyScore === undefined) {
      setScore(userState.score);
    }
  }, []);

  useEffect(() => {
    setUserState((state) => ({
      ...state,
      anxietyScore: score,
    }));
  }, [score]);

  return parseInt(id) === 0 ? (
    <Guide
      title={'지금부터 3주 동안 Anxy와 불안 관리 여정을 떠나요'}
      noGradient
      justifyEnd
      content={
        <div className="absolute left-[50%] translate-x-[-50%] w-[619px] min-w-[619px] h-fit translate-y-[114px]">
          <img src={walkthrough1} alt={'empty'} />
        </div>
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 1 ? (
    <Guide
      title={'과학과 임상 연구에 기반해 만들어진 코스랍니다'}
      content={
        <div className="px-[20px]">
          <div className="flex flex-col gap-[40px] my-[50px] ">
            {[
              { percentage: 71, effect: '불안 증상의 임상적 개선' },
              { percentage: 57, effect: '전반적인 기분 개선' },
              { percentage: 47, effect: '불면증 개선' },
            ].map((each, index) => (
              <div className="flex flex-col gap-[8px] items-center " key={`effect${index}`}>
                <div className="flex text-[50px] text-[#EF670B] font-bold">
                  <p>{each.percentage}%</p>
                </div>
                <div className="text-[17px] leading-[23px] text-[#6B6D76]">{each.effect}</div>
              </div>
            ))}
          </div>
          <div className="border-t-[1px] border-[#E1E4EB] pt-[20px] text-[14px] leading-[20px] text-[rgba(0,0,0,0.4)]">
            {
              'Carl, J. R., Miller, C. B., Henry, A. L., Davis, M. L., Stott, R., Smits, J. A., … & Espie, C. A. (2020). Efficacy of digital cognitive behavioral therapy for moderate-to-severe symptoms of generalized anxiety disorder: A randomized controlled trial. Depression and Anxiety, 37(12), 1168-1178.'
            }
          </div>
        </div>
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 2 ? (
    <Guide
      title={'인사해요, 당신의 Wori에요'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%] origin-bottom"
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Wori score={0} duration={0} hand />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col opacity-0 ">
            <p className="text-[50px] font-bold text-center mb-[20px]">{userState.anxietyScore}</p>

            <div className="w-full h-[10px] rounded-[50px] bg-[#FDF0E7]">
              <div className=" h-full  rounded-[50px] bg-[#EF670B]" style={{ width: `${userState.anxietyScore}%` }} />
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 3 ? (
    <Guide
      title={'Wori는 불안하면 몸집이 커져요'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%] origin-bottom"
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Wori initialScore={0} score={100} duration={2} delay={0.2} />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col ">
            {/* <p className="text-[50px] font-bold text-center mb-[20px]">{score}</p> */}
            <CountUp
              end={100}
              start={0}
              duration={2}
              delay={0.2}
              className="text-[50px] font-bold text-center mb-[20px]"
            />
            <div className="w-full h-[10px] rounded-[50px] bg-[#FDF0E7]">
              <motion.div
                className=" h-full  rounded-[50px] bg-[#EF670B]"
                initial={{ width: `0%` }}
                animate={{ width: '100%' }}
                transition={{ duration: 2, delay: 0.2 }}
                // style={{ width: `${score}%` }}
              />
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 4 ? (
    <Guide
      title={'지금 얼마나 불안한가요?'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%]  origin-bottom "
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Wori score={score} duration={0} />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col ">
            <p className="text-[50px] font-bold text-center">{score}</p>
            <div className="flex px-[4px] w-full justify-between text-[15px] leading-[21px] text-[#6B6D76] mb-[8px]">
              <p>매우 평온</p>
              <p>매우 불안</p>
            </div>
            <div
              className="w-full h-[24px] rounded-[50px]  px-[22px]"
              style={{
                background: `linear-gradient(90deg, rgba(239, 103, 11) 50%, rgba(253, 240, 231) 50%)`,
              }}
            >
              <div
                className="w-full h-full  relative"
                style={{
                  background: `linear-gradient(90deg, rgba(239, 103, 11) ${score}%, rgba(253, 240, 231) ${score}%)`,
                }}
              >
                <div
                  className=" w-[44px] h-[44px] rounded-[50%] bg-white ring-[1px] ring-inset ring-[#F3F5F8] absolute top-[50%] translate-x-[-50%] translate-y-[-50%]
                flex items-center justify-center"
                  style={{ left: `${score}%`, boxShadow: '0px 6px 8px -2px rgba(0, 0, 0, 0.2)' }}
                  onTouchMove={(e) => {
                    setScore(
                      Math.round(
                        Math.max(
                          0,
                          Math.min(
                            100,
                            parseInt(((e.changedTouches[0].clientX - 20) / (window.innerWidth - 40)) * 100),
                          ),
                        ) / 10,
                      ) * 10,
                    );
                  }}
                >
                  <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="9" width="3" height="18" rx="1.5" fill="#D1D5DC" />
                    <rect width="3" height="18" rx="1.5" fill="#D1D5DC" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 5 ? (
    <TextAreaTemplate
      bgColor={'#ffffff'}
      title={'무엇 때문에불안했나요?'}
      titleColor={'#26282C'}
      textareaBgColor={'#ffffff'}
      caretColor={'#EF670B'}
      placeholder={'걱정하거나 불안했던 경험을 적어주세요'}
      value={userState.anxietyNote}
      updateValue={updateValue}
      noBorder
      noPadding
      action={() => {
        saveAnxietyRecord();
        if (fromBubble) {
          initializeSelfCheckAndGoHome();
        } else {
          goNext();
        }
      }}
    />
  ) : parseInt(id) === 6 ? (
    <Guide
      title={'무서워 할 필요 없어요'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%] w-[350px] h-[412px] origin-bottom"
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Lottie lottieData={anxy_big_lottie} autoplay />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col ">
            <p className="text-[50px] font-bold text-center mb-[20px]">{score}</p>

            <div className="w-full h-[10px] rounded-[50px] bg-[#FDF0E7]">
              <div className=" h-full  rounded-[50px] bg-[#EF670B]" style={{ width: `${score}%` }}></div>
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 7 ? (
    <Guide
      title={'이제 우리는 불안을 다스릴 거니깐요'}
      content={
        <div className="w-full h-full pt-[20px] pb-[50px] flex flex-col gap-[20px] ">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%] origin-bottom"
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Wori initialScore={100} score={0} duration={2} delay={0.2} downArrow />
            </div>
          </div>
          <div className="w-full px-[20px] flex flex-col ">
            {/* <p className="text-[50px] font-bold text-center mb-[20px]">{score}</p> */}
            <CountUp
              end={0}
              start={score}
              duration={2}
              delay={0.2}
              className="text-[50px] font-bold text-center mb-[20px]"
            />
            <div className="w-full h-[10px] rounded-[50px] bg-[#FDF0E7]">
              <motion.div
                className=" h-full  rounded-[50px] bg-[#EF670B]"
                initial={{ width: `${score}%` }}
                animate={{ width: '0%' }}
                transition={{ duration: 2, delay: 0.2 }}
                // style={{ width: `${score}%` }}
              />
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 8 ? (
    <Guide
      title={'오늘부터 불안감으로부터 자유로워져볼까요?'}
      noGradient
      justifyEnd
      content={
        <div className="absolute left-[50%] translate-x-[-50%] w-[619px] min-w-[619px]  h-fit translate-y-[114px]">
          <img src={walkthrough2} alt={'empty'} />
        </div>
      }
      buttonText={'시작하기'}
      action={goNext}
    />
  ) : (
    <CircularProgressingAnxy
      steps={[`${anxyTypeData.find((data) => data.type === type).name}를 위한 코스 생성 중`]}
      isDone={initializeSelfCheckAndGoHome}
      color={'#EF670B'}
    />
  );
}
