import { useContext, useEffect, useState } from 'react';
import { CoachingContext } from '../../../coachingContext';
import { useGetWorkbookParams } from '../../../hooks/useGetWorkbookParams';
import axios from '../../../utils/axios2';

const UserSituationEmotionBox = ({ emotion }) => {
  const context = useContext(CoachingContext);
  const [data, setData] = useState([]);
  const [authToken, appId, activityKey, isFirst] = useGetWorkbookParams();

  const emotionData = [
    { emotion: '기쁨', apiName: 'situationByWordListOfPleasure' },
    { emotion: '슬픔', apiName: 'situationByWordListOfSadness' },
    { emotion: '불쾌함', apiName: 'situationByWordListOfDiscomfort' },
    { emotion: '화남', apiName: 'situationByWordListOfAnger' },
    { emotion: '두려움', apiName: 'situationByWordListOfFear' },
  ];

  const getUserData = async () => {
    try {
      const response = await axios(3, authToken, appId).get('/emotion-recognition/load');
      const apiName = emotionData.filter((item) => item.emotion === emotion)[0].apiName;
      console.log(apiName);
      setData(response.data.result[apiName]);
    } catch (error) {
      console.log(error);
      //   webviewToast('error' + error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="my-[40px] w-full ">
      {data.map((text, index) => (
        <div className="mx-[25px] text-[#26282C]" style={{ marginBottom: '10px' }}>
          <div className="bg-[#EFF2FF] rounded-[12px] p-[20px]">
            <div className="flex items-center">
              <div className="pb-[1px] px-[6px] font-semibold w-[33px] bg-[#4E4F53] rounded-[4px] text-white text-[12px] leading-[18px]  ">
                상황
              </div>
              <p className="font-semibold ml-[10px] text-[17px] leading-[23px] ">{text.situation}</p>
            </div>
            <div className="flex items-center mt-[5px]">
              <div className="bg-[#2C4BEC] pb-[1px] px-[6px] font-semibold w-[33px] rounded-[4px] text-white text-[12px] leading-[18px]  ">
                감정
              </div>
              <p className="font-semibold ml-[10px] text-[17px] leading-[23px]  mb-[1px]">{text.word} 감정을 느껴요</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserSituationEmotionBox;
