/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import { changeHeader, close, getRefresh } from '../../utils/webview';
import { BottomAction } from '../../stories/page/BottomAction';
import TestResultChart from '../../components/selfCheck/TestResultChart';
import HistoryGraph from '../../components/selfCheck/HistoryGraph';
import selfCheckData from '../../data/selfCheckData';
import SelfCheckCard from '../../components/selfCheck/SelfCheckCard';
import { getSelfCheckState } from '../../components/selfCheck/selfCheckLogic';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { AccordianItem } from '../../stories/text/AccordianItem';
import SelfCheckResultCourseCard from '../../components/course/SelfCheckResultCourseCard';
import SelfCheckResultCounselorCard from '../../components/counselor/SelfCheckResultCounselorCard';
import SelfCheckResultCoachCard from '../../components/selfCheck/SelfCheckResultCoachCard';
import SelfCheckResultDoctorCard from '../../components/selfCheck/SelfCheckResultDoctorCard';
import useFetch from '../../hooks/useFetch';
import ContainerByDevice from '../../components/elements/ContainerByDevice';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import mixpanel from 'mixpanel-browser';
import { BUILD_TARGET } from '../../config';
import benefit1 from '../../image/benefit1.png';
import benefit2 from '../../image/benefit2.png';
import up_arrow from '../../image/up_arrow.png';
import down_arrow from '../../image/down_arrow.png';
import inside_logo from '../../image/inside_logo.png';
import { BlackRoundActionButton } from '../../stories/button/RoundActionButton';
import Footer from '../../components/homepage/Footer';

export default function SelfCheckResult() {
  const context = useContext(AuthContext);
  const isDev = BUILD_TARGET === 'dev';
  let { selfCheckId } = useParams();
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === parseInt(selfCheckId));

  const [searchParams] = useSearchParams();
  const navigation = searchParams.get('navigation') || 'none';
  const xyz = searchParams.get('xyz') === 'true';
  const sc = searchParams.get('sc') === 'true';
  const selfCheckScrollRef = useRef();
  const [lastLabel, setLastLabel] = useState(0);
  const [currentLabel, setCurrentLabel] = useState(0);

  const [change, setChange] = useState(0);
  const [latestResult, getLatestResult] = useFetch({
    url: `/self-check/v2/latest-result/detail`,
    params: { self_check_id: selfCheckInfo.selfCheckId },
    bg: '#FFFFFF',
  });

  useEffect(() => {
    // if (xyz) {
    //   console.log('결과페이지 들어옴');
    //   if (!isDev) {
    //     mixpanel.track('[카카오톡 가설 검증] 결과페이지 들어옴');
    //   }
    // }
    if (sc) {
      const root = document.getElementById('root');
      root.style.position = 'relative';
      root.style.overflow = 'auto';
    }
  }, []);

  useEffect(() => {
    if (latestResult) {
      // getAllResult();
      console.log(latestResult.result);
      context.setSelfcheckResult(latestResult.result);

      console.log('결과:', latestResult.result.latestResultSummaryList);
      const submitted = latestResult.result.latestResultSummaryList.map((each) => each.selfCheckId);

      const todayNotSubmitted = latestResult.result.latestResultSummaryList
        .filter(
          (element) =>
            parseInt(Math.abs(new Date() - new Date(element.date)) / (1000 * 3600 * 24)) > 0 &&
            element.selfCheckId !== selfCheckInfo.selfCheckId,
        )
        .sort(function (a, b) {
          return new Date(b.date) - new Date(a.date) || b.scorePercentage - a.scorePercentage;
        });
      console.log('검사 내역 있음', todayNotSubmitted);

      const abnormal = todayNotSubmitted.filter((element) => element.isAbnormal);
      const normal = todayNotSubmitted.filter((element) => !element.isAbnormal);
      const noResult = selfCheckData.filter((element) => !submitted.includes(element.selfCheckId));

      console.log('이상 있음', abnormal);
      console.log('이상 없음', normal);
      console.log('검사 내역 없음', noResult);

      context.setAvailableSelfCheck(abnormal.concat(normal).concat(noResult));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestResult]);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('selfCheckName', selfCheckInfo.selfCheckName, context.availableSelfCheck);
    document.title = `${selfCheckInfo.selfCheckName} 자가검사 결과`;

    changeHeader({
      backgroundColor: '#1A2960',
      text: `${selfCheckInfo.selfCheckName} 검사 결과`,
      color: '#FFFFFF',
      buttonType: navigation,
    });
    if (searchParams.get('at')) {
      getRefresh(getLatestResult);
    }

    selfCheckScrollRef.current.scrollTop = context.selfCheckScrollPosition;
    context.setShowProgressingbar(false);
    setCurrentLabel(getSelfCheckState(parseInt(selfCheckId), context.selfcheckResult.score));

    if (context.selfcheckResult.resultHistoryList) {
      context.selfcheckResult.resultHistoryList.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });

      if (context.selfcheckResult.resultHistoryList.length >= 2) {
        setLastLabel(
          getSelfCheckState(
            parseInt(selfCheckId),
            context.selfcheckResult.resultHistoryList[context.selfcheckResult.resultHistoryList.length - 2].score,
          ),
        );
      }
    }

    // setAvailableSelfCheck(context.availableSelfCheck.slice().sort(() => Math.random() - 0.5));
  }, []);

  useEffect(() => {
    if (lastLabel < currentLabel) {
      setChange(-1);
    } else if (lastLabel > currentLabel) {
      setChange(1);
    }
  }, [lastLabel, currentLabel]);

  const Element = (
    <div
      className="w-full h-full overflow-x-hidden overflow-y-auto scrollbar-hide "
      ref={selfCheckScrollRef}
      onScroll={(e) => {
        context.setSelfCheckScrollPosition(e.target.scrollTop);

        if (e.target.scrollTop >= 10) {
          console.log('헤더 흰색');
          changeHeader({
            backgroundColor: '#FFFFFF',
            text: `${selfCheckInfo.selfCheckName} 검사 결과`,
            color: '#000000',
          });
          if (context.availableSelfCheck.length > 0) {
            selfCheckScrollRef.current.style.backgroundColor = '#FFFFFF';
          } else {
            selfCheckScrollRef.current.style.backgroundColor = '#F3F5F8';
          }
        } else {
          console.log('헤더 남색');
          changeHeader({
            backgroundColor: '#1A2960',
            text: `${selfCheckInfo.selfCheckName} 검사 결과`,
            color: '#FFFFFF',
          });
          if (!xyz) {
            selfCheckScrollRef.current.style.backgroundColor = '#1A2960';
          }
        }
      }}
    >
      <div className="w-full relative">
        <div className="w-full h-[54px] pt-[10px] bg-[#1A2960] flex items-center justify-center text-[17px] leading-[26px] text-white font-semibold">
          {selfCheckInfo.selfCheckName} 검사 결과
        </div>
        <img src={selfCheckInfo.image_result} alt={'empty'} />
        <p
          className="w-full px-[20px] text-center absolute bottom-[29px] text-[#D1D5DC] text-[14px] leading-[20px]  "
          style={{ wordBreak: 'keep-all' }}
        >
          {selfCheckInfo.source}
        </p>
      </div>

      <div className="w-full  bg-white  px-[20px] ">
        <div className="w-full pt-[30px] pb-[40px]">
          <p className=" font-bold text-[24px] leading-[30px] text-[#26282C]  mb-[20px] ">
            {context.selfcheckResult.diagnosisTitle}
          </p>
          <div className="w-full pt-[28px] pb-[20px] rounded-[12px] bg-[#F3F5F8] flex flex-col items-center">
            <div className="w-[42px] h-[42px] mb-[15px]">
              <img src={selfCheckInfo.result[currentLabel].emoji} alt={'empty'} />
            </div>
            <p className="  font-normal text-[15px] leading-[21px] text-[#4E4F53]   ">
              {context.selfcheckResult.diagnosisSubTitle}
            </p>
          </div>
          <div className="mt-[50px]">
            <TestResultChart
              examName={selfCheckInfo.selfCheckName}
              isAbnormal={context.selfcheckResult.isAbnormal}
              score={context.selfcheckResult.score}
            />
          </div>
        </div>
        <div className="w-full h-[1px] bg-[#F3F5F8]" />
        <div className="w-full  pt-[30px] pb-[40px]">
          {context.selfcheckResult.resultHistoryList && context.selfcheckResult.resultHistoryList.length >= 2 && (
            <div>
              <p className=" font-bold text-[20px] leading-[26px] text-[#26282C]  mb-[6px] ">
                {change === -1 && '지난 검사보다 나빠졌어요'}
                {change === 0 && '지난 검사와 유사해요'}
                {change === 1 && '지난 검사보다 좋아졌어요'}
              </p>
              <p className="  font-normal text-[15px] leading-[21px] text-[#6B6D76]   ">
                {change === 0 && `${selfCheckInfo.result[currentLabel].label} 단계가 유지되고 있어요`}

                {change !== 0 &&
                  `${selfCheckInfo.result[lastLabel].label}에서 ${selfCheckInfo.result[currentLabel].label} 단계로 변화했어요.`}
              </p>
              <div className="mt-[20px] mb-[74px]">
                <HistoryGraph selfCheckId={selfCheckId} resultHistoryList={context.selfcheckResult.resultHistoryList} />
              </div>
            </div>
          )}
          {!xyz && (
            <div className="w-full ">
              {context.selfcheckResult.analysisList && (
                <div className="mb-[30px]">
                  {context.selfcheckResult.analysisList.map((analysis, index) => (
                    <div
                      key={`analysis${index}`}
                      className={`${index !== context.selfcheckResult.analysisList.length - 1 ? 'mb-[30px]' : ''}`}
                    >
                      <p className="text-[18px] leading-[24px] font-bold text-[#26282C] mb-[10px]">{analysis.title}</p>
                      <p className="text-[17px] leading-[23px] font-normal text-[#4E4F53]">{analysis.body}</p>
                    </div>
                  ))}
                </div>
              )}
              <AccordianItem
                bgColor={'#FFFFFF'}
                borderColor={'#E1E4EB'}
                title={selfCheckInfo.description.title}
                content={
                  <div className="pt-[24px]">
                    <p className="text-[16px] leading-[24px] text-[#4E4F53] ">{selfCheckInfo.description.text}</p>
                    <div className="w-full h-[1px] bg-[#F3F5F8] my-[24px] " />
                    <p className="text-[18px] leading-[24px] text-[#3A3C40] font-bold mb-[10px]">
                      {selfCheckInfo.symptom.title}
                    </p>
                    <div className="text-[16px] leading-[24px] text-[#4E4F53]">
                      {selfCheckInfo.symptom.text.map((each, index) => (
                        <div className="flex " key={`a${index}`}>
                          <span className="mr-[3px]">･</span>
                          <span>{each}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </div>

      <div className="w-full bg-[#F3F5F8] px-[20px] pt-[40px] pb-[20px]">
        <p className="text-[22px] leading-[28px] text-[#26282C] font-bold mb-[40px] ">🔍 어떻게 관리해야 할까요?</p>

        <div className="pb-[40px]">
          <p className="text-[18px] leading-[24px] text-[#26282C] font-bold mb-[10px] ">
            나에게 적합한 방법으로 관리해요
          </p>
          <div className="w-full bg-white rounded-[16px] px-[20px] py-[24px]">
            {selfCheckInfo.selfTip.map((each, index) => (
              <div key={`selfTip${index}`} className={`flex items-center ${index !== 2 ? 'mb-[25px]' : ''}`}>
                <div className="w-[55px] h-[55px] rounded-[8px] bg-[#EFF2FF] mr-[15px] p-[5px]">
                  <img src={each.image} alt={'empty'} />
                </div>
                <div className="">
                  <p className="text-[17px] leading-[23px] text-[#3A3C40] font-bold mb-[3px]">{each.title}</p>
                  <p className="text-[14px] leading-[20px]  text-[#6B6D76]">{each.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full bg-white px-[20px] pt-[40px] pb-[60px] ">
        <div className="mb-[70px]">
          <p className="text-[18px] leading-[24px] text-[#2C4BEC] font-bold mb-[6px] ">인사이드 x 스프링캠프</p>
          <p className="text-[24px] leading-[30px] text-[#26282C] font-bold mb-[30px] ">대표님을 위해 준비했어요</p>
          <div className="flex flex-col gap-[10px] ">
            {[
              { text: '50만원 상당의 상담 5회기 지원', image: benefit1 },
              { text: '5회기 이후의 상담 비용 할인', image: benefit2 },
            ].map((benefit, index) => (
              <div key={`benefit${index}`} className="bg-[#F3F5F8] rounded-[12px] p-[20px] flex flex-col items-center ">
                <p className="text-[16px] leading-[22px] text-[#A4A6B0] font-bold mb-[5px] ">혜택 {index + 1}</p>
                <p className="text-[22px] leading-[28px] text-[#26282C] font-bold mb-[10px] ">{benefit.text}</p>
                <div className="h-[123px]">
                  <img src={benefit.image} alt={'empty'} className="h-full" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="text-[18px] leading-[24px] text-[#2C4BEC] font-bold mb-[6px] ">심리상담,</p>
          <p className="text-[24px] leading-[30px] text-[#26282C] font-bold mb-[30px] ">마인드셋을 바꾸는 50분 투자</p>
          <div className="flex flex-col gap-[10px] ">
            {[
              {
                text: ['스트레스를 잘 관리해 마음이 20% 더 단단해질 수 있다면,', ' 그 값은 얼마일까요?'],
                positive: true,
              },
              {
                text: [
                  '마음의 여유를 찾아 팀원들을 30% 만큼 더 이해하고 시너지를 낼 수 있다면,',
                  ' 그 값은 얼마일까요?',
                ],
                positive: true,
              },
              {
                text: [
                  '스트레스를 방치해 번아웃이나 우울증이 생긴다면,',
                  ' 나와 회사가 감당해야 하는 손실의 값은 얼마일까요?',
                ],
                positive: false,
              },
              {
                text: [
                  '과중된 스트레스로 내 스스로가 약해지고 무너진다면,',
                  ' 동료들과 가족이 겪어야 하는 손실의 값은 얼마일까요?',
                ],
                positive: false,
              },
            ].map((element, index) => (
              <div
                key={`positive${index}`}
                className={`${
                  element.positive ? 'bg-[#EFF2FF]' : 'bg-[#FFEAEA]'
                } rounded-[12px] p-[20px] text-[#26282C] flex  items-center gap-[10px]`}
              >
                <div className="w-[45px] h-[45px] shrink-0">
                  <img src={element.positive ? up_arrow : down_arrow} alt={'empty'} />
                </div>
                <div>
                  <span className="font-bold">{element.text[0]}</span>
                  <span>{element.text[1]}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full bg-[#F3F5F8] px-[20px] pt-[50px] pb-[40px] flex flex-col items-center">
        <div className="w-[88px] mb-[16px]">
          <img src={inside_logo} alt={'empty'} />
        </div>
        <div className="text-[24px] leading-[30px] text-[#26282C] font-bold mb-[20px] text-center">
          심리상담 프로그램을 통해
          <br /> 그 가치를 경험해보세요
        </div>
        <BlackRoundActionButton
          state={'ACTIVE'}
          text={'인사이드와 함께하기'}
          width={'199px'}
          action={() => {
            window.open('https://inside.oopy.io/springcampfamily', '_blank');
          }}
        />
      </div>
      <Footer />
    </div>
  );

  const goSelfCheckHome = useNavigateWithParams(`/selfCheck`);

  return (
    <ContainerByDevice css="w-screen">
      {navigation === 'back' || sc ? (
        Element
      ) : (
        <BottomAction
          content={Element}
          buttonText={'확인'}
          buttonState={'ACTIVE'}
          action={() => {
            if (searchParams.get('hide_dashboard') === 'true') {
              close({ refresh: true });
            } else {
              goSelfCheckHome();
            }
          }}
        />
      )}
    </ContainerByDevice>
  );
}
