import session1_1 from '../../../data/anxy/videos/session1_1.mp4';
import session1_2 from '../../../data/anxy/videos/session1_2.mp4';
import session1_4 from '../../../data/anxy/videos/session1_4.mp4';

import session1_0 from '../../../data/anxy/videos/session1_0.mp4';
import session2_0 from '../../../data/anxy/videos/session2_0.mp4';
import session3_0 from '../../../data/anxy/videos/session3_0.mp4';
import session4_0 from '../../../data/anxy/videos/session4_0.mp4';

import colorPalette from './anxyColorPalette';
import AnxyInputListTemplate from '../../../components/anxy/worryNote/InputListTemplate';
import VideoPlayer from '../../../components/anxy/common/VideoPlayer';
import DragEmotionFace from '../../../components/anxy/worryNote/DragEmotionFace';
import { MeditateInSession } from '../../../components/anxy/session/MeditateInSession';

import cover1 from '../../../image/anxy/session/cover1.png';
import cover2 from '../../../image/anxy/session/cover2.png';
import cover3 from '../../../image/anxy/session/cover3.png';
import cover4 from '../../../image/anxy/session/cover4.png';
import InputTemplate from '../../../components/anxy/worryNote/InputTemplate';
import WorryNoteResult from '../../../components/anxy/worryNote/WorryNoteResult';

const template = (component, backgroundColor) => {
  return {
    template: component,
    backgroundColor: backgroundColor,
  };
};

const anxyContents = [
  {
    sectionId: 'session_kc',
    contents: [
      template(<VideoPlayer video={session1_0} thumbnail={cover1} isMeditation />, colorPalette.yellow),
      template(<MeditateInSession backgroundColor={colorPalette.green} />, colorPalette.green),
    ],
  },
  {
    sectionId: 'session_aa',
    contents: [template(<VideoPlayer video={session2_0} thumbnail={cover2} />, colorPalette.green)],
  },
  {
    sectionId: 'session_ab',
    contents: [
      template(<VideoPlayer video={session3_0} thumbnail={cover3} />, colorPalette.orange),
      template(
        <InputTemplate
          title="나의 불안 문제를 적어보세요"
          placeholder="평소 어떤 불안 문제를 겪고 있나요? 혹은 최근에 겪은 불안 문제가 있나요?"
          name="problem"
        />,
        colorPalette.orange,
      ),
      template(<VideoPlayer video={session1_1} />, colorPalette.orange),
      template(
        <AnxyInputListTemplate
          title="여러분의 불안을 만드는 요인은 무엇인가요?"
          isStatic
          addInputList={[
            {
              name: 'externalFactorList',
              title: '외부 요인',
              placeholder: '장소, 상황, 대상',
            },
            {
              name: 'internalFactorList',
              title: '내부 요인',
              placeholder: '생각, 이미지, 신체 반응',
            },
          ]}
        />,
        colorPalette.purple,
      ),
      template(<VideoPlayer video={session1_2} />, colorPalette.green),
      template(
        <AnxyInputListTemplate
          title="Anxy와 함께 이루고 싶은 목표는 무엇인가요?"
          isStatic
          textareaList={[
            {
              name: 'objective',
              title: '나의 목표',
              placeholder: '구체적으로 측정 가능한 목표 적기',
            },
          ]}
          isHome
        />,
        colorPalette.green,
      ),
    ],
  },
  {
    sectionId: 'session_ac',
    contents: [
      template(<VideoPlayer video={session4_0} isFirst thumbnail={cover4} />, colorPalette.green),
      template(
        <AnxyInputListTemplate
          title="무엇 때문에 불안했나요?"
          addInputList={[
            {
              name: 'externalFactorList',
              title: '외부 요인',
              placeholder: '장소, 상황, 대상',
            },
            {
              name: 'internalFactorList',
              title: '내부 요인',
              placeholder: '생각, 이미지, 신체 반응',
            },
          ]}
          isFirstPage
        />,
        colorPalette.green,
      ),
      template(<DragEmotionFace title="얼만큼 불안했나요?" />, colorPalette.green),
      template(
        <AnxyInputListTemplate
          title="몸의 반응은 어땠나요?"
          addInputList={[
            {
              name: 'bodyReactionList',
              title: '신체',
              placeholder: '호흡, 심박, 근육 긴장 등',
            },
          ]}
          isFirstPage
        />,
        colorPalette.green,
      ),
      template(
        <AnxyInputListTemplate
          title="어떤 생각이 떠올랐나요?"
          addInputList={[
            {
              name: 'thoughtList',
              title: '생각',
              placeholder: '상상, 예측, 이미지 등',
            },
          ]}
        />,
        colorPalette.green,
      ),
      template(
        <AnxyInputListTemplate
          title="어떻게 행동했나요?"
          addInputList={[
            {
              name: 'behaviorList',
              title: '행동',
              placeholder: '불안해서 한 행동',
            },
          ]}
        />,
        colorPalette.green,
      ),
      template(<WorryNoteResult title="어떻게 행동했나요?" />, colorPalette.green),
      template(<VideoPlayer video={session1_4} isHome />, colorPalette.green),
    ],
  },
];

export default anxyContents;
