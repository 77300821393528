import 'moment/locale/ko';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Lottie from '../../elements/Lottie';
import anxy_hand_lottie from '../../../image/anxy/12A/anxy_hand_lottie.json';
import { WorryBubble } from '../../../stories/animation/WorryBubble';
import toolModalAtom from '../../../recoil/anxy/session/toolModalAtom';
import sessionAtom from '../../../recoil/anxy/session/atom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import userAtom from '../../../recoil/anxy/user/atom';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';

export default function Wori({ initialScore, score, duration, delay, hand, downArrow, bubble, growl }) {
  const [score2, setScore2] = useState(initialScore !== undefined ? initialScore : score);
  const [duration2, setDuration2] = useState(initialScore !== undefined ? 0 : duration);
  const [showDownArrow, setShowDownArrow] = useState(false);

  const setSessionState = useSetRecoilState(sessionAtom);
  const setModalState = useSetRecoilState(toolModalAtom);
  const { worryNoteHistory } = useRecoilValue(userAtom);

  const goWorryNote = useNavigateWithParams('/worryNote');

  useEffect(() => {
    if (initialScore !== undefined) {
      setTimeout(() => {
        setDuration2(duration);
        setScore2(score);
        setTimeout(() => {
          setShowDownArrow(true);
        }, duration * 1000);
      }, delay * 1000);
    }
  }, []);

  useEffect(() => {
    if (initialScore === undefined) {
      setScore2(score);
    }
  }, [score]);

  const head = (
    <svg width="211" height="113" viewBox="0 0 211 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.0268 1.18127C92.6132 -0.393758 94.841 -0.393758 95.4275 1.18127L98.0617 8.25596C100.519 8.08593 102.999 7.9995 105.5 7.9995C108.797 7.9995 112.059 8.14977 115.28 8.44383L117.984 1.18127C118.57 -0.393758 120.798 -0.393758 121.384 1.18127L124.558 9.70538C173.727 18.6191 211 61.4069 211 112.999H0C0 60.5108 38.5789 17.1353 89.0143 9.27169L92.0268 1.18127Z"
        fill="#171222"
      />
      <ellipse cx="97.4265" cy="69.3684" rx="3.64327" ry="6.2727" fill="#FFC700" />
      <ellipse cx="114.98" cy="69.3684" rx="3.97447" ry="6.2727" fill="#FFC700" />
      <path d="M92.459 85.5449H120.28" stroke="#B43737" strokeWidth="1.89" strokeLinecap="round" />
    </svg>
  );

  const pants = (
    <svg width="211" height="87" viewBox="0 0 211 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.662 75.767C198.597 56.6363 211 29.7622 211 0.000573942C175.181 -0.000253017 126.328 -1.54812e-05 74.1274 0.00023833C49.8181 0.000356528 24.7828 0.000478256 0 0.000497648C0 29.7622 12.4032 56.6362 32.3381 75.767C33.6739 77.0489 35.0434 78.1643 36.4453 79.1305V87H104.198V85.7895C104.198 84.5644 105.195 83.5713 106.424 83.5713C107.653 83.5713 108.649 84.5644 108.649 85.7895V87H176.404V77.753C177.167 77.1404 177.92 76.4794 178.662 75.767Z"
        fill="#171222"
      />
    </svg>
  );

  const leg = (
    <svg width="211" height="104" viewBox="0 0 211 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse opacity="0.4" cx="105.5" cy="79" rx="105.5" ry="25" fill="#A4A6B0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.197 75.4529V0H36.4439V47C36.4439 53.5241 35.0004 59.9678 32.2162 65.8722L29.7954 71.006C28.4378 73.885 29.1211 77.015 31.0162 79.0681C35.7856 85.7413 50.0076 90.5833 66.8107 90.5833C86.5231 90.5833 102.683 83.9195 104.197 75.4529ZM180.633 65.8722C177.849 59.9678 176.405 53.5241 176.405 47V0H108.65V75.9179C110.661 84.1622 126.588 90.5833 145.938 90.5833C162.578 90.5833 176.686 85.8353 181.591 79.2627C183.687 77.308 184.494 74.0613 183.072 71.0442L180.633 65.8722Z"
        fill="#171222"
      />
    </svg>
  );

  const openWorryNoteModal = () => {
    if (worryNoteHistory && worryNoteHistory.length > 0) {
      setSessionState((state) => ({ ...state, currentTool: 'tool_ca' }));
      setTimeout(() => {
        setModalState((state) => ({ ...state, isModalVisible: true }));
      }, 0);
    } else {
      goWorryNote();
    }
  };

  return (
    <div className="flex flex-col items-center ">
      <div className={`${growl && 'origin-bottom animate-[growl_1s_ease-in-out]'}`}>
        <motion.div
          initial={{ transform: `translateY(${(45 * (100 - score2)) / 100}px)` }}
          animate={{ transform: `translateY(${(45 * (100 - score2)) / 100}px)` }}
          transition={{ duration: duration2 }}
        >
          <div className="translate-y-[3px] relative">
            {bubble && (
              <div className="absolute top-[-30px] left-[130px] z-[50]" onClick={openWorryNoteModal}>
                <WorryBubble />
              </div>
            )}
            {hand && (
              <div className="absolute top-[-20px] right-[160px] w-[120px] h-[160px] ">
                <Lottie lottieData={anxy_hand_lottie} autoplay loop />
              </div>
            )}
            {downArrow && (
              <motion.div
                animate={{ opacity: showDownArrow ? 1 : 0 }}
                className="absolute left-[50%] translate-x-[-50%] top-[-110px] opacity-0"
              >
                <svg width="57" height="97" viewBox="0 0 57 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.8499 0C14.193 0 12.8499 1.34315 12.8499 3V62.75H2.00389C0.287088 62.75 -0.631826 64.7707 0.496619 66.0646L26.8424 96.2718C27.6394 97.1857 29.0599 97.1857 29.8569 96.2718L56.2027 66.0646C57.3311 64.7708 56.4122 62.75 54.6954 62.75H43.8499V3C43.8499 1.34315 42.5067 0 40.8499 0H15.8499Z"
                    fill="url(#paint0_linear_2837_67235)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2837_67235"
                      x1="31.5773"
                      y1="96.9572"
                      x2="29.9432"
                      y2="-0.0346061"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#C7CFDB" />
                      <stop offset="1" stopColor="#C7CFDB" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </motion.div>
            )}
            {head}
            <motion.div
              className="absolute w-[41px] h-[15px] bg-[#171222] left-[50%] translate-x-[-50%] "
              initial={{ bottom: `${54 - 9 * (score2 / 100)}px` }}
              animate={{ bottom: `${54 - 9 * (score2 / 100)}px` }}
              transition={{ duration: duration2 }}
            />
          </div>
          {/* 몸통 */}
          <motion.div
            className=" w-[211px] bg-[#171222] translate-y-[2px]"
            initial={{ height: `${(74 * score2) / 100}px` }}
            animate={{ height: `${(74 * score2) / 100}px` }}
            transition={{ duration: duration2 }}
          />
          {/* 바지 */}
          <div className="translate-y-[1px]">{pants}</div>
        </motion.div>
      </div>
      {/* 다리 */}
      {leg}
    </div>
  );
}
