import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AnxyActionButton } from '../../../stories/button/ActionButton';
import { TextArea } from '../../../stories/text/TextArea';
import { Title } from '../../../stories/text/Title';
import { keyboardOpen } from '../../../utils/webview';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import deviceAtom from '../../../recoil/common/device';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import stopInputFocusAtom from '../../../recoil/common/stopInputFocus/atom';
import { useNavigate } from 'react-router-dom';
import modalAtom, { MODAL_LEAVE } from '../../../recoil/somny/modal/atom';
import myPageAtom from '../../../recoil/somny/myPage/atom';

export default function SomnyUserLeave() {
  const navigate = useNavigate();
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);
  const [windowSize, setWindowSize] = useState(window.innerHeight);
  const [loaded, setLoaded] = useState(false);
  const [stopFocus, setStopFocus] = useState(false);
  const [forceBlur, setForceBlur] = useState(false);

  const deviceState = useRecoilValue(deviceAtom);
  const setModalState = useSetRecoilState(modalAtom);
  const setHeaderState = useSetRecoilState(headerAtom);
  const [myPageState, setMyPageState] = useRecoilState(myPageAtom);
  const [stopInputFocusState, setStopInputFocusState] = useRecoilState(stopInputFocusAtom);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_BACK, headerTitle: '' });
    setLoaded(true);
    keyboardOpen();

    window.visualViewport.addEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setViewportSize(window.visualViewport.height);
    setWindowSize(window.innerHeight);
    if (window.android) {
      if (window.innerHeight < windowSize) {
        setKeyboardOpen(true);
      } else {
        setKeyboardOpen(false);
      }
    } else {
      if (window.visualViewport.height < viewportSize) {
        setKeyboardOpen(true);
      } else {
        setKeyboardOpen(false);
      }
    }
  };

  useEffect(() => {
    if (stopFocus || stopInputFocusState) {
      setTimeout(() => {
        setForceBlur(true);
        if (stopFocus) {
          setModalState({ isModalVisible: true, modalType: MODAL_LEAVE });
          setStopFocus(false);
        } else if (stopInputFocusState) {
          navigate(-1);
          setStopInputFocusState(false);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopFocus, stopInputFocusState]);

  function updateValue(value) {
    if (value.length > 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
    setMyPageState((state) => ({ ...state, leaveReason: value }));
  }

  function goNext() {
    setStopFocus(true);
  }

  return (
    <motion.div
      animate={{
        height: window.android ? `${windowSize}px` : `${viewportSize}px`,
        paddingBottom: !isKeyboardOpen && deviceState.hasHomeIndicator ? `34px` : `20px`,
      }}
      transition={{ ease: 'circOut', duration: loaded ? 0.15 : 0 }}
      className={` w-screen `}
    >
      <div
        className="h-full px-[20px]  flex flex-col justify-between"
        style={{ paddingTop: deviceState.notchSize + 44 }}
      >
        <div className="flex-1 flex flex-col pt-[7px]">
          <div className="mb-[40px]">
            <Title title={'떠나는 이유를 알려주세요'} />
          </div>
          <div className="flex-1  pb-[40px]">
            <div className="h-[calc(100%-52px)] mb-[40px]">
              <TextArea
                placeholder={'더 나은 Somny를 만드는데 참고할게요'}
                value={myPageState.leaveReason}
                forceBlur={forceBlur}
                autofocus={true}
                updateValue={updateValue}
              />
            </div>
            <AnxyActionButton state={buttonState} text={'다음'} action={goNext} />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
