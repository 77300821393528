import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { FullPageAnxy } from '../../../stories/page/FullPageAnxy';
import { motion } from 'framer-motion';
import anxyColorPalette from '../../../data/anxy/contents/anxyColorPalette';
import { FocusingLottie } from '../../../stories/animation/FocusingLottie';
import { 생각, 행동, 신체 } from './textSvg.js';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { TextFadeTransition, getTitleDuration } from '../../../stories/transition/TextFadeTransition';
import userAtom from '../../../recoil/anxy/user/atom';
import { useRecoilValue } from 'recoil';

function CircleModule({
  id,
  backgroundColor,
  isFocused,
  setCurrentFocusId,
  isNext,
  showFocusingLottie,
  setShowFocusingLottie,
  isInitialized,
}) {
  return (
    <div className="w-fit h-fit relative">
      <motion.div
        initial={{ transform: 'scale(1)' }}
        animate={{
          transform: !isInitialized ? (isFocused ? 'scale(1.083)' : 'scale(0.833)') : 'scale(1)',
          opacity: isFocused || isInitialized ? 1 : 0.2,
        }}
        transition={{ duration: 0.5 }}
        className="w-[120px] h-[120px] rounded-[50%] flex items-center justify-center"
        style={{ backgroundColor: backgroundColor }}
      >
        {id === 0 ? 생각 : id === 1 ? 행동 : 신체}
      </motion.div>
      {isNext && showFocusingLottie && (
        <div
          className="absolute bottom-0 right-0"
          onClick={() => {
            if (showFocusingLottie && isNext) {
              setCurrentFocusId((currentFocusId) => currentFocusId + 1);
              setShowFocusingLottie(false);
            }
          }}
        >
          <FocusingLottie />
        </div>
      )}
    </div>
  );
}

function Tail({ currentFocusId }) {
  return (
    <div className="w-full h-[13px] relative">
      <div
        className={`absolute left-[50%] top-0  `}
        style={{
          transform:
            currentFocusId % 3 === 0
              ? `translate(calc(-50% - ${(Math.min(350, window.innerWidth) - 40) / 2 - 60}px),1px)`
              : currentFocusId % 3 === 1
              ? `translate(calc(-50% + ${(Math.min(350, window.innerWidth) - 40) / 2 - 60}px),1px)`
              : `translate(-50%,-1px) scaleY(-1)`,
        }}
      >
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.06218 1.5C7.21688 -0.499998 10.1036 -0.5 11.2583 1.5L17.3205 12H0L6.06218 1.5Z" fill="#131416" />
        </svg>
      </div>
    </div>
  );
}

export default function Intro1() {
  const { id, page } = useParams();
  const [currentTitleId, setCurrentTitleId] = useState(1);
  const [currentFocusId, setCurrentFocusId] = useState(0);
  const [nextFocusId, setNextFocusId] = useState();
  const [showFocusingLottie, setShowFocusingLottie] = useState(false);
  const [showText, setShowText] = useState(true);
  const userState = useRecoilValue(userAtom);

  const containerRef = useRef();
  const circlesRef = useRef();
  const textRef = useRef();
  const [translateY, setTranslateY] = useState(0);

  const [searchParams] = useSearchParams();
  const testType = searchParams.get('type');
  const type = testType ? parseInt(testType) : userState.anxietyTypeId;

  const location = useLocation();
  const pathname = location.pathname.split('/')[1];
  const goNext = useNavigateWithParams(`../${pathname}/${parseInt(id) + 1}/1`);

  useEffect(() => {
    if (containerRef.current && circlesRef.current && textRef.current) {
      console.log(textRef.current.offsetHeight, currentFocusId);
      const areaHeight = (containerRef.current.offsetHeight - circlesRef.current.offsetHeight) / 2;
      if (areaHeight < textRef.current.offsetHeight) {
        console.log('넘음');
      } else {
        console.log('안넘음', currentFocusId % 3 === 2);
        setTranslateY(areaHeight - textRef.current.offsetHeight - 10);
      }
    }
  }, [containerRef, circlesRef, textRef, currentFocusId]);

  const loopData = [
    {
      type: 1,
      loop: [
        '지금은 괜찮아 보이지만\n이따 퇴근 길에는 비가 올지도 몰라',
        '가방에 우산을 챙겨 출근함',
        '그럼에도 안심하지 못해\n주의가 산만하고 일에 집중하지 못함',
        '(비 생각을 계속 하다 결국..)\n이따 큰 비가 올 것 같아\n챙겨온 작은 우산으로는 안전하게 집에 가지 못할거야',
        '결국 업무 도중에 회사를 나와\n편의점에 들려 우비를 구입함',
      ],
    },
    {
      type: 2,
      loop: [
        '거의 마무리 지었지만\n조금만 더 수정하면 더 완벽해질 것 같아',
        '보고서의 구조도 바꿔보고\n내용을 다시 써보기도 함',
        '마감 시간이 점점 임박하며 심장이 뛰고 호흡이 빨라짐\n조마조마해서 집중이 안됨',
        '왜 이것밖에 안되지?\n이건 아니야 이렇게 과제를 제출할 순 없어',
        '괜찮았던 부분까지 더 크게 수정하며\n일이 점점 커짐',
      ],
    },
    {
      type: 3,
      loop: [
        '왜 내 메시지를 읽지 않지?\n어제 모임에서 나한테 기분 상한 일이라도 있나?',
        '어제 모임에서 있었던 일을\n하나하나 되짚으며 기록함',
        '하나하나 떠올릴수록 불안해져\n소화가 안 되고 안절부절못함',
        '내 농담 때문인가봐\n단단히 나한테 화가 난 게 분명해\n이제 나와 친구로 지내는 걸 원치 않을거야',
        '친구에게 보낼 메시지를 썼다 지웠다를 반복하며 어쩔 줄을 몰라함',
      ],
    },
    {
      type: 4,
      loop: [
        '이 일을 남에게 부탁하면\n일을 남에게 떠넘기는 이기적인 사람이라고 여길거야',
        '대부분의 일을 자신이 맡아서 하겠다고 함',
        '주위를 계속 두리번거리며 시선 처리가 불안함',
        '이 일을 어떻게 다 하지?\n내가 한다고 한 일인데 다 하기엔 시간이 부족한 걸',
        '밤을 새며 야근을 함',
      ],
    },
    {
      type: 5,
      loop: [
        '속상하지만 괜찮아\n뭐 어떡해 그냥 속으로 삭히는 수밖에',
        '앞으로 마음 상하는 일을\n원천 차단하기 위해 퇴사를 고민함',
        '막연한 불안감에 안절부절못하고\n손톱을 물어뜯음',
        '가만히 있으니 생각만 많아지고\n어떻게 해야할지 모르겠어',
        '부정적인 감정을 잊기 위해\n주말 내내 누워서 핸드폰만 봄',
      ],
    },
    {
      type: 6,
      loop: [
        '내 의견은 틀릴지도 몰라\n그러니 얘기해서는 안돼',
        '회의에서 아무 말도 않고 가만히 있음',
        '다리를 떨고 펜을 만지작거림',
        '나한테 물어보는 건 아니겠지?\n제발 나에겐 의견을 묻지 않았으면 좋겠어',
        '급한 전화가 온 척\n회의실을 도망치듯 나감',
      ],
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setNextFocusId(1);
      setShowFocusingLottie(true);
    }, 3000);
  }, []);

  useEffect(() => {
    if (!showFocusingLottie) {
      setTimeout(() => {
        if (nextFocusId !== undefined) {
          setNextFocusId((nextFocusId) => nextFocusId + 1);
        }
        setShowFocusingLottie(true);
      }, 3000);
    }
  }, [showFocusingLottie]);

  const titles = [
    { id: 1, title: '이때 불안의 순환고리는\n다음과 같이 동작합니다' },
    { id: 2, title: '어떤가요?\n비슷하게 불안을 느낀 경험이 있나요?' },
    { id: 3, title: '우리는 불안의 3요소를\n하나씩 공략할 건데요' },
    { id: 4, title: '오늘은 간략하게 살펴만 볼게요' },
  ];

  useEffect(() => {
    if (currentTitleId === 2 || currentTitleId === 3) {
      setTimeout(() => {
        setCurrentTitleId((currentTitleId) => currentTitleId + 1);
      }, getTitleDuration(titles[currentTitleId - 1].title));
    }
    if (currentTitleId === 4) {
      setTimeout(() => {
        goNext();
      }, getTitleDuration(titles[currentTitleId - 1].title));
    }
  }, [currentTitleId]);

  return (
    <FullPageAnxy
      content={
        <div className={`w-screen h-full flex flex-col pt-[11px] relative`}>
          <TextFadeTransition textList={titles} currentTextId={currentTitleId} />

          <div className="flex-1 flex flex-col justify-center " ref={containerRef}>
            <div className="w-full h-fit  px-[20px]">
              {showText && (
                <div
                  className={`w-[calc(100%-40px)] absolute ${
                    currentFocusId % 3 === 2 ? 'top-[63px]' : 'bottom-[0px]'
                  } z-[10] pointer-events-none`}
                  style={{
                    transform:
                      currentFocusId % 3 === 2 ? `translate(0,${translateY}px)` : `translate(0,-${translateY}px)`,
                  }}
                  ref={textRef}
                >
                  {currentFocusId % 3 !== 2 && <Tail currentFocusId={currentFocusId} />}
                  <div className="p-[24px] bg-[rgba(0,0,0,0.5)] rounded-[16px] text-white text-center">
                    {loopData.find((element) => element.type === type).loop[currentFocusId]}
                  </div>
                  {currentFocusId % 3 === 2 && <Tail currentFocusId={currentFocusId} />}
                </div>
              )}
              <div
                className={`w-full max-w-[310px] max-h-[300px] mx-auto   relative`}
                ref={circlesRef}
                style={{ height: window.innerWidth * (30 / 31) }}
              >
                {[
                  { id: 0, name: '생각', backgroundColor: '#398047', className: 'absolute bottom-[0px] left-[0px] ' },
                  { id: 1, name: '행동', backgroundColor: '#EF670B', className: 'absolute bottom-[0px] right-[0px] ' },
                  {
                    id: 2,
                    name: '신체',
                    backgroundColor: '#F8CF46',
                    className: 'absolute top-[0px] left-[50%] translate-x-[-50%]',
                  },
                ].map((each, index) => (
                  <div className={`${each.className}`} key={`불안순환고리${index}`}>
                    <CircleModule
                      id={each.id}
                      backgroundColor={each.backgroundColor}
                      isFocused={currentFocusId % 3 === each.id}
                      setCurrentFocusId={setCurrentFocusId}
                      isNext={nextFocusId <= 4 && nextFocusId % 3 === each.id}
                      showFocusingLottie={showFocusingLottie}
                      setShowFocusingLottie={setShowFocusingLottie}
                      isInitialized={nextFocusId === 6}
                    />
                  </div>
                ))}
                {nextFocusId === 5 && showFocusingLottie && (
                  <div
                    className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[calc(-50% - 10px)]"
                    onClick={() => {
                      setShowText(false);
                      setNextFocusId((nextFocusId) => nextFocusId + 1);
                      setCurrentTitleId(2);
                    }}
                  >
                    <FocusingLottie />
                  </div>
                )}
                {nextFocusId === 6 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[calc(-50% - 10px)]"
                  >
                    <p className="text-[17px] leading-[23px] font-bold text-white text-center">
                      불안의
                      <br />
                      순환고리
                    </p>
                  </motion.div>
                )}

                {/* 아래 화살표 */}
                <div className="absolute bottom-[10px] left-[50%] translate-x-[-50%]">
                  <svg width="56" height="18" viewBox="0 0 56 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.1"
                      d="M55.3944 2.1183C55.3944 1.28987 54.7228 0.618298 53.8944 0.618296L40.3944 0.618297C39.566 0.618297 38.8944 1.28987 38.8944 2.1183C38.8944 2.94673 39.566 3.6183 40.3944 3.6183L52.3944 3.6183L52.3944 15.6183C52.3944 16.4467 53.066 17.1183 53.8944 17.1183C54.7228 17.1183 55.3944 16.4467 55.3944 15.6183L55.3944 2.1183ZM1.56849 0.618296C0.740066 0.618298 0.0684928 1.28987 0.0684918 2.1183L0.0684923 15.6183C0.0684923 16.4467 0.740065 17.1183 1.56849 17.1183C2.39692 17.1183 3.06849 16.4467 3.06849 15.6183L3.06849 3.6183L15.0685 3.6183C15.8969 3.6183 16.5685 2.94673 16.5685 2.1183C16.5685 1.28987 15.8969 0.618297 15.0685 0.618297L1.56849 0.618296ZM52.8337 1.05764C38.9701 14.9212 16.4928 14.9212 2.62915 1.05764L0.507832 3.17896C15.543 18.2141 39.9199 18.2141 54.9551 3.17896L52.8337 1.05764Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {/* 왼쪽 화살표 */}
                <div className="absolute top-[95px] left-[50%] translate-x-[calc(-50%-95px)]">
                  <svg width="38" height="58" viewBox="0 0 38 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.1"
                      d="M13.4091 57.0239C14.1637 57.3659 15.0526 57.0315 15.3946 56.277L20.9679 43.9811C21.3099 43.2265 20.9754 42.3376 20.2209 41.9956C19.4664 41.6536 18.5775 41.988 18.2354 42.7426L13.2814 53.6722L2.35175 48.7182C1.59722 48.3762 0.708297 48.7106 0.366293 49.4652C0.0242891 50.2197 0.358713 51.1086 1.11325 51.4506L13.4091 57.0239ZM36.9966 8.61822C37.3386 7.86368 37.0041 6.97476 36.2496 6.63276L23.9537 1.05948C23.1992 0.717476 22.3103 1.0519 21.9683 1.80644C21.6263 2.56097 21.9607 3.44989 22.7152 3.7919L33.6449 8.74592L28.6909 19.6756C28.3489 20.4301 28.6833 21.319 29.4378 21.6611C30.1924 22.0031 31.0813 21.6686 31.4233 20.9141L36.9966 8.61822ZM15.4323 55.1295C8.52864 36.7791 17.8081 16.3066 36.1585 9.4029L35.1022 6.59503C15.201 14.0821 5.13737 36.2847 12.6244 56.1859L15.4323 55.1295Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {/* 오른쪽 화살표 */}
                <div className="absolute top-[95px] left-[50%] translate-x-[calc(-50%+95px)]">
                  <svg width="38" height="58" viewBox="0 0 38 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.1"
                      d="M24.6397 57.0239C23.8852 57.3659 22.9962 57.0315 22.6542 56.277L17.081 43.9811C16.739 43.2265 17.0734 42.3376 17.8279 41.9956C18.5825 41.6536 19.4714 41.988 19.8134 42.7426L24.7674 53.6722L35.6971 48.7182C36.4516 48.3762 37.3405 48.7106 37.6825 49.4652C38.0245 50.2197 37.6901 51.1086 36.9356 51.4506L24.6397 57.0239ZM1.05227 8.61822C0.710261 7.86368 1.04468 6.97476 1.79922 6.63276L14.0951 1.05948C14.8496 0.717476 15.7386 1.0519 16.0806 1.80644C16.4226 2.56097 16.0881 3.44989 15.3336 3.7919L4.40394 8.74592L9.35796 19.6756C9.69996 20.4301 9.36554 21.319 8.611 21.6611C7.85647 22.0031 6.96754 21.6686 6.62554 20.9141L1.05227 8.61822ZM22.6165 55.1295C29.5202 36.7791 20.2407 16.3066 1.8903 9.4029L2.94665 6.59503C22.8478 14.0821 32.9115 36.2847 25.4244 56.1859L22.6165 55.1295Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      bgColor={anxyColorPalette.black}
      transparentHeader
    />
  );
}
