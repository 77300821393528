import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { hasTouch } from 'detect-touch';
import { motion } from 'framer-motion';

export function SelectCheckButton(props) {
  const { selected, option, text, isClicked, disable } = props;
  const [buttonDown, setButtonDown] = useState(false);

  function mouseUp(e) {
    setButtonDown(false);
  }

  useEffect(() => {
    // console.log(hasTouch);
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  useEffect(() => {
    if (disable) {
      setButtonDown(false);
    } else {
      setButtonDown(true);
    }
  }, [disable]);

  return (
    <div className={`flex flex-col items-center relative`}>
      <motion.div animate={{ scale: selected ? 1 : 0.95 }} transition={{ duration: 0.2 }}>
        <div
          className={`w-[24px] h-[24px] rounded-[24px]`}
          onMouseDown={(e) => {
            console.log('mousedown before touch');

            if (!hasTouch) {
              console.log('mousedown');
              setButtonDown(true);
            }
          }}
          onClick={(e) => {
            console.log('action ');
            if (!hasTouch && !disable) {
              isClicked(option);
            }
          }}
          onTouchStart={(e) => {
            if (!buttonDown && !disable) {
              setButtonDown(true);
            }
          }}
          onTouchEnd={(e) => {
            setButtonDown(false);
            if (
              document
                .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
                .includes(e.currentTarget) &&
              !disable
            ) {
              isClicked(option);
            }
          }}
        >
          <div className={`w-[25px] h-[25px] rounded-[25px] pointer-events-none`}>
            {selected ? (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="url(#paint0_radial_1165_19912)" />
                <path
                  d="M7.52198 10.7691C7.22455 10.4809 6.74973 10.4883 6.46145 10.7857C6.17317 11.0831 6.18059 11.558 6.47802 11.8462L7.52198 10.7691ZM10.8095 15L10.2875 15.5386C10.5784 15.8205 11.0406 15.8205 11.3315 15.5386L10.8095 15ZM17.522 9.53855C17.8194 9.25027 17.8268 8.77545 17.5386 8.47802C17.2503 8.18059 16.7755 8.17317 16.478 8.46145L17.522 9.53855ZM6.47802 11.8462L10.2875 15.5386L11.3315 14.4614L7.52198 10.7691L6.47802 11.8462ZM11.3315 15.5386L17.522 9.53855L16.478 8.46145L10.2875 14.4614L11.3315 15.5386Z"
                  fill="white"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial_1165_19912"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(23.9598 16.3234) rotate(-160.031) scale(25.4924 281.516)"
                  >
                    <stop stopColor="#5513F0" />
                    <stop offset="1" stopColor="#2C4BEC" />
                  </radialGradient>
                </defs>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#D1D5DC" />
                <path
                  d="M7.52198 10.7691C7.22455 10.4809 6.74973 10.4883 6.46145 10.7857C6.17317 11.0831 6.18059 11.558 6.47802 11.8462L7.52198 10.7691ZM10.8095 15L10.2875 15.5386C10.5784 15.8205 11.0406 15.8205 11.3315 15.5386L10.8095 15ZM17.522 9.53855C17.8194 9.25027 17.8268 8.77545 17.5386 8.47802C17.2503 8.18059 16.7755 8.17317 16.478 8.46145L17.522 9.53855ZM6.47802 11.8462L10.2875 15.5386L11.3315 14.4614L7.52198 10.7691L6.47802 11.8462ZM11.3315 15.5386L17.522 9.53855L16.478 8.46145L10.2875 14.4614L11.3315 15.5386Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
