import React, { useEffect, useState } from 'react';
import ContainerByDeviceAnxy from '../../components/elements/ContainerByDeviceAnxy';
import { useSearchParams } from 'react-router-dom';
import Test from '../../development/Test';
import { updateNotch } from '../../utils/webview';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_MORE } from '../../recoil/common/header/atom';
import { LoadingLottie } from '../../stories/animation/LoadingLottie';
import SessionCarousel from '../../components/somny/SessionCarousel';
import loomySessionData from '../../data/loomy/loomySessionData';

export default function LoomyHome() {
  const [sessionList, setSessionList] = useState(loomySessionData);

  // 데이터 로드 여부
  const [isLoaded, setIsLoaded] = useState(false);

  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');

  const setHeaderState = useSetRecoilState(headerAtom);

  const initializeState = () => {
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_MORE,
      headerTitle: '',
      headerColor: '#26282C',
    }));
  };

  useEffect(() => {
    // header, layout settings
    document.body.style.backgroundColor = '#FFFFFF';

    updateNotch({ isFullScreen: true });

    initializeState();

    setTimeout(() => {
      setIsLoaded(true);
    }, 400);
  }, []);

  const TokenTest = () => {
    return (
      <Test
        element={
          <div className="absolute left-[20px] top-[-30px] text-[10px] z-[50]">
            <textarea
              id="textArea"
              onClick={() => {
                const content = document.getElementById('textArea');
                content.select();
                document.execCommand('copy');
              }}
              defaultValue={authToken}
            />
          </div>
        }
      />
    );
  };

  return (
    <ContainerByDeviceAnxy noHeader css={'flex flex-col'} bgColor="#F3F0E7">
      <TokenTest />
      <div
        className="flex-1 flex flex-col"
        style={{
          opacity: isLoaded ? 1 : 0,
        }}
      >
        <div className={`flex-1 flex flex-col justify-center py-[50px]`}>
          <SessionCarousel sessionList={sessionList} isLoomy />
        </div>
      </div>
      {!isLoaded && (
        <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
          <LoadingLottie containerSize="50px" />
        </div>
      )}
    </ContainerByDeviceAnxy>
  );
}
