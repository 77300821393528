import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CoachingContext } from '../../coachingContext';
// import { motion } from 'framer-motion';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { SecondaryActionButton } from '../../stories/button/ActionButton';
import { showWebView, close } from '../../utils/webview';
import { useModalDrag } from '../../hooks/useModalDrag';

export default function ActivityModal(props) {
  const { courseStart } = props;
  const context = useContext(CoachingContext);
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appId = searchParams.get('app_id');

  const closeModal = () => {
    context.setShowActivityModal(false);
  };

  const goToOnboarding = () => {
    showWebView({ url: `startOnboarding?at=${authToken}&app_id=${appId}&refresh=true` });
    context.setShowActivityModal(false);
  };
  const data = [
    {
      type: 'PROGRAM_NOT_STARTED',
      title: '먼저 프로그램을 세팅해주세요',
      info: '몇 가지 설정으로 맞춤 프로그램을 시작하세요.',
      buttonText: '세팅하기',
      action: () => goToOnboarding(),
    },
    {
      type: 'PRECEDING',
      title: '필수 선행 코스가 있어요',
      info: "'감정을 만드는 생각 찾기' 먼저 진행해주세요",
      buttonText: '확인',
      action: () => close({ refresh: true }),
    },
    {
      type: 'START_NEW_COURSE',
      title: '새 코스를 시작할까요?',
      info: '지금 하고 있는 코스는 중단돼요. 동시에 여러 코스를 함께 진행할 수 없어요.',
      twoButton: true,
      buttonText: '시작하기',
      cancel: () => closeModal(),
      action: () => {
        courseStart();
        closeModal();
      },
    },
  ];

  const dataPerType = data.find((item) => item.type === context.activityModalType);
  const { sheet, showModal } = useModalDrag(context.showActivityModal);

  useEffect(() => {
    context.setShowActivityModal(showModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <div
      className={`w-screen sticky opacity-100  bottom-[0px] z-[30]`}
      style={{
        transform: `${context.showActivityModal ? 'translateY(0px)' : 'translateY(250px)'}`,
        transition: `all ${context.showActivityModal ? 0.3 : 0.2}s ease-in-out`,
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <div
        ref={sheet}
        className={`w-full bg-[#FFFFFF] px-[20px] pt-[28px] text-[15px] leading-[18px] text-center 
        ${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'}`}
        style={{
          borderRadius: '20px 20px 0px 0px',
          boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.05)',
        }}
      >
        <p className="text-[#26282C] text-[20px] leading-[24px] font-semibold">{dataPerType.title}</p>
        <p className="text-[#26282C] text-[17px] leading-[26px] text-[#80838B] mt-[8px] mb-[24px]">
          {dataPerType.info}
        </p>
        {!dataPerType.twoButton && (
          <PrimaryActionButton state="ACTIVE" text={dataPerType.buttonText} action={dataPerType.action} />
        )}
        {dataPerType.twoButton && (
          <div className="flex justify-between">
            <div className="w-[calc((100%-5px)/2)]">
              <SecondaryActionButton state="ACTIVE" text={'취소'} action={dataPerType.cancel} />
            </div>
            <div className="w-[calc((100%-5px)/2)]">
              <PrimaryActionButton state="ACTIVE" text={dataPerType.buttonText} action={dataPerType.action} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
