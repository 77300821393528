import React, { useState, useEffect } from 'react';
import { hasTouch } from 'detect-touch';

export default function LinkButton(props) {
  const { text, action, noLine, isSomny } = props;
  const [buttonDown, setButtonDown] = useState(false);

  function mouseUp(e) {
    setButtonDown(false);
  }
  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });
  return (
    <div
      className={`w-fit  py-[8px] text-[15px] leading-[21px] ${
        !noLine
          ? 'underline underline-offset-2 decoration-1 mt-[12px] text-[#80838B]'
          : isSomny
          ? 'mt-[4px] text-white opacity-[0.8]'
          : 'mt-[4px] text-[#26282C] opacity-[0.8]'
      }  
    ${buttonDown && 'opacity-[0.5]'}`}
      onMouseDown={(e) => {
        if (!hasTouch) {
          setButtonDown(true);
        }
      }}
      onClick={(e) => {
        action();
      }}
      onTouchStart={(e) => {
        e.stopPropagation();
        setButtonDown(true);
      }}
      onTouchEnd={(e) => {
        setButtonDown(false);
        if (
          document.elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY).includes(e.currentTarget)
        ) {
          action();
        }
      }}
    >
      {text}
    </div>
  );
}
