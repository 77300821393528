/* eslint-disable default-case */
import React, { useReducer, createContext } from 'react';

const initialState = {
  authToken: '',
  appId: '',
  appVersion: 0,
  todoList: [],
  biggerMargin: false,

  showModal: false,
  modalType: 'NOT_STARTED',
  activityModalType: 'PROGRAM_NOT_STARTED',
  showActivityModal: false,
  showCounselingModal: false,

  activityKey: '',
  courseId: 1,

  current: 0,
  progressSum: 0,
  showProgressingbar: false,
  firstWorkbook: false,
  ruleData: {},
  isQuizTemplate: false,
  showWorkbookToast: false,
  workbookAnswer: [],
  selectedPage: [],
  sleepingSurvey: {
    inBed: {
      endDateTime: '',
      startDateTime: '',
    },
    napTimeMinutes: 30,
    sleep: {
      endDateTime: '',
      startDateTime: '',
    },
    struggleTimeMinutes: 70,
  },

  recommendedCourse: [],
  userFeedback: {},
  showWebviewLayer: {},
  isFirstWebviewLayer: true,

  feedScrollPosition: 0,
  counselorSearchWizardChoice: [],
  leaveReasonChoiceList: [],
  acquisitionChannel: [],
};

const CoachingContext = createContext({
  ...initialState,
});

function coachingReducer(state, action) {
  switch (action.type) {
    case 'SETTODOLIST':
      return {
        ...state,
        todoList: action.payload,
      };
    case 'SETCURRENT':
      return {
        ...state,
        current: action.payload,
      };
    case 'SETSHOWPROGRESSINGBAR':
      return {
        ...state,
        showProgressingbar: action.payload,
      };
    case 'SETAUTHTOKEN':
      return {
        ...state,
        authToken: action.payload,
      };
    case 'SETPROGRESSSUM':
      return {
        ...state,
        progressSum: action.payload,
      };
    case 'SETBIGGERMARGIN':
      return {
        ...state,
        biggerMargin: action.payload,
      };
    case 'SETAPPVERSION':
      return {
        ...state,
        appVersion: action.payload,
      };
    case 'SETFEEDSCROLLPOSITION':
      return {
        ...state,
        feedScrollPosition: action.payload,
      };
    case 'SETSHOWMODAL':
      return {
        ...state,
        showModal: action.payload,
      };
    case 'SETMODALTYPE':
      return {
        ...state,
        modalType: action.payload,
      };
    case 'SETACTIVITYKEY':
      return {
        ...state,
        activityKey: action.payload,
      };

    case 'SETWORKBOOKANSWER':
      return {
        ...state,
        workbookAnswer: action.payload,
      };
    case 'SETAPPID':
      return {
        ...state,
        appId: action.payload,
      };
    case 'SETFIRSTWORKBOOK':
      return {
        ...state,
        firstWorkbook: action.payload,
      };
    case 'SETSHOWACTIVITYMODAL':
      return {
        ...state,
        showActivityModal: action.payload,
      };
    case 'SETSHOWCOUNSELINGMODAL':
      return {
        ...state,
        showCounselingModal: action.payload,
      };
    case 'SETACTIVITYMODALTYPE':
      return {
        ...state,
        activityModalType: action.payload,
      };
    case 'SETRULEDATA':
      return {
        ...state,
        ruleData: action.payload,
      };
    case 'SETCOURSEID':
      return {
        ...state,
        courseId: action.payload,
      };
    case 'SETRECOMMENDEDCOURSE':
      return {
        ...state,
        recommendedCourse: action.payload,
      };
    case 'SETISFIRSTWEBVIEWLAYER':
      return {
        ...state,
        isFirstWebviewLayer: action.payload,
      };
    case 'SETISQUIZTEMPLATE':
      return {
        ...state,
        isQuizTemplate: action.payload,
      };
    case 'SETSHOWWORKBOOKTOAST':
      return {
        ...state,
        showWorkbookToast: action.payload,
      };
    case 'SET_COUNSELOR_SEARCH_WIZARD_CHOICE':
      return {
        ...state,
        counselorSearchWizardChoice: action.payload,
      };
    case 'SETSELECTEDPAGE':
      return {
        ...state,
        selectedPage: action.payload,
      };
    case 'SETSLEEPINGSURVEY':
      return {
        ...state,
        sleepingSurvey: action.payload,
      };

    case 'SET_LEAVE_REASON_CHOICE_LIST':
      return {
        ...state,
        leaveReasonChoiceList: action.payload,
      };
    case 'SET_ACQUISITION_CHANNEL':
      return {
        ...state,
        acquisitionChannel: action.payload,
      };
  }
}

function CoachingProvider(props) {
  const [state, dispatch] = useReducer(coachingReducer, initialState);

  function setTodoList(todoList) {
    dispatch({
      type: 'SETTODOLIST',
      payload: todoList,
    });
  }

  function setCurrent(current) {
    dispatch({
      type: 'SETCURRENT',
      payload: current,
    });
  }

  function setShowProgressingbar(showProgressingbar) {
    dispatch({
      type: 'SETSHOWPROGRESSINGBAR',
      payload: showProgressingbar,
    });
  }

  function setAuthToken(authToken) {
    dispatch({
      type: 'SETAUTHTOKEN',
      payload: authToken,
    });
  }

  function setProgressSum(progressSum) {
    dispatch({
      type: 'SETPROGRESSSUM',
      payload: progressSum,
    });
  }

  function setBiggerMargin(biggerMargin) {
    dispatch({
      type: 'SETBIGGERMARGIN',
      payload: biggerMargin,
    });
  }

  function setAppVersion(appVersion) {
    dispatch({
      type: 'SETAPPVERSION',
      payload: appVersion,
    });
  }

  function setFeedScrollPosition(feedScrollPosition) {
    dispatch({
      type: 'SETFEEDSCROLLPOSITION',
      payload: feedScrollPosition,
    });
  }

  function setShowModal(showModal) {
    dispatch({
      type: 'SETSHOWMODAL',
      payload: showModal,
    });
  }

  function setModalType(modalType) {
    dispatch({
      type: 'SETMODALTYPE',
      payload: modalType,
    });
  }

  function setActivityKey(activityKey) {
    dispatch({
      type: 'SETACTIVITYKEY',
      payload: activityKey,
    });
  }

  function setWorkbookAnswer(workbookAnswer) {
    dispatch({
      type: 'SETWORKBOOKANSWER',
      payload: workbookAnswer,
    });
  }

  function setAppId(appId) {
    dispatch({
      type: 'SETAPPID',
      payload: appId,
    });
  }

  function setFirstWorkbook(firstWorkbook) {
    dispatch({
      type: 'SETFIRSTWORKBOOK',
      payload: firstWorkbook,
    });
  }
  function setShowActivityModal(showActivityModal) {
    dispatch({
      type: 'SETSHOWACTIVITYMODAL',
      payload: showActivityModal,
    });
  }
  function setShowCounselingModal(showCounselingModal) {
    dispatch({
      type: 'SETSHOWCOUNSELINGMODAL',
      payload: showCounselingModal,
    });
  }
  function setActivityModalType(activityModalType) {
    dispatch({
      type: 'SETACTIVITYMODALTYPE',
      payload: activityModalType,
    });
  }
  function setRuleData(ruleData) {
    dispatch({
      type: 'SETRULEDATA',
      payload: ruleData,
    });
  }

  function setCourseId(courseId) {
    dispatch({
      type: 'SETCOURSEID',
      payload: courseId,
    });
  }
  function setRecommendedCourse(recommendedCourse) {
    dispatch({
      type: 'SETRECOMMENDEDCOURSE',
      payload: recommendedCourse,
    });
  }
  function setIsFirstWebviewLayer(isFirstWebviewLayer) {
    dispatch({
      type: 'SETISFIRSTWEBVIEWLAYER',
      payload: isFirstWebviewLayer,
    });
  }
  function setIsQuizTemplate(isQuizTemplate) {
    dispatch({
      type: 'SETISQUIZTEMPLATE',
      payload: isQuizTemplate,
    });
  }
  function setShowWorkbookToast(showWorkbookToast) {
    dispatch({
      type: 'SETSHOWWORKBOOKTOAST',
      payload: showWorkbookToast,
    });
  }

  function setCounselorSearchWizardChoice(counselorSearchWizardChoice) {
    dispatch({
      type: 'SET_COUNSELOR_SEARCH_WIZARD_CHOICE',
      payload: counselorSearchWizardChoice,
    });
  }

  function setSelectedPage(selectedPage) {
    dispatch({
      type: 'SETSELECTEDPAGE',
      payload: selectedPage,
    });
  }

  function setSleepingSurvey(sleepingSurvey) {
    dispatch({
      type: 'SETSLEEPINGSURVEY',
      payload: sleepingSurvey,
    });
  }
  function setLeaveReasonChoiceList(leaveReasonChoiceList) {
    dispatch({
      type: 'SET_LEAVE_REASON_CHOICE_LIST',
      payload: leaveReasonChoiceList,
    });
  }
  function setAcquisitionChannel(acquisitionChannel) {
    dispatch({
      type: 'SET_ACQUISITION_CHANNEL',
      payload: acquisitionChannel,
    });
  }

  return (
    <CoachingContext.Provider
      value={{
        ...state,
        setTodoList,
        setCurrent,
        setShowProgressingbar,
        setAuthToken,
        setProgressSum,
        setBiggerMargin,
        setAppVersion,
        setFeedScrollPosition,
        setShowModal,
        setModalType,
        setActivityKey,
        setWorkbookAnswer,
        setAppId,
        setFirstWorkbook,
        setShowActivityModal,
        setShowCounselingModal,
        setActivityModalType,
        setRuleData,
        setCourseId,
        setRecommendedCourse,
        setIsFirstWebviewLayer,
        setIsQuizTemplate,
        setShowWorkbookToast,
        setCounselorSearchWizardChoice,
        setSelectedPage,
        setSleepingSurvey,
        setLeaveReasonChoiceList,
        setAcquisitionChannel,
      }}
      {...props}
    />
  );
}

export { CoachingContext, CoachingProvider };
