import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import { SelectButton } from '../../../stories/button/SelectButton';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import webviewToast from '../../../development/webviewToast';
import CustomToast from '../../../development/CustomToast';
import { SelectButtonCircle } from '../../../stories/button/SelectButtonCircle';

export default function SleepingSurveyCircle({ isFirstPage, title, text }) {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);
  const [disable, setDisable] = useState(false);
  const [clicked, setClicked] = useState(false);

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  const goFirstPage = useNavigateWithParams(`/workbook/${parseInt(id)}/${1}`);

  useEffect(() => {
    setClicked(false);
  }, []);

  function isClicked(option) {
    if (option !== '') {
      console.log('이미클릭함');
      setDisable(true);
      select(option);
    }
  }

  function select(option) {
    setClicked(true);
    if (context.workbookAnswer.find((element) => element.id === parseInt(page))) {
      context.setWorkbookAnswer([
        ...context.workbookAnswer.filter((element) => element.id !== parseInt(page)),
        { id: parseInt(page), value: option.value },
      ]);
    } else {
      if (!isFirstPage && context.workbookAnswer.length === 0) {
        goFirstPage();
        webviewToast('context 날라감');
        return;
      }
      context.setWorkbookAnswer([...context.workbookAnswer, { id: parseInt(page), value: option.value }]);
    }

    if (parseInt(page) < 16) {
      setTimeout(() => {
        goNext();
      }, 400);
    }
  }

  const setSelectPageAndGoNext = (id, pageIndex) => {
    const updated = context.selectedPage;
    if (context.selectedPage.length !== 0) {
      updated[pageIndex] = id;
    } else {
      updated.push(id);
    }
    context.setSelectedPage(updated);
    goNext();
  };

  useEffect(() => {
    console.log(context.workbookAnswer);
    if (clicked) {
      if (context.workbookAnswer.length >= 13 && parseInt(page) === 16) {
        console.log(context.workbookAnswer);
        setTimeout(() => {
          let A = false;
          let B = false;
          if (
            (context.workbookAnswer.find((element) => element.id === 4).value ||
              context.workbookAnswer.find((element) => element.id === 5).value ||
              context.workbookAnswer.find((element) => element.id === 6).value) &&
            context.workbookAnswer.find((element) => element.id === 7).value &&
            context.workbookAnswer.find((element) => element.id === 8).value &&
            context.workbookAnswer.find((element) => element.id === 9).value &&
            context.workbookAnswer.find((element) => element.id === 10).value
          ) {
            A = true;
          }
          if (
            !context.workbookAnswer.find((element) => element.id === 11).value &&
            !context.workbookAnswer.find((element) => element.id === 12).value &&
            !context.workbookAnswer.find((element) => element.id === 13).value &&
            !context.workbookAnswer.find((element) => element.id === 14).value &&
            !context.workbookAnswer.find((element) => element.id === 15).value &&
            !context.workbookAnswer.find((element) => element.id === 16).value
          ) {
            B = true;
          }
          if (A && !B) {
            console.log('A !B');
            setSelectPageAndGoNext(0, 0);
          } else if (A && B) {
            console.log('A B');
            setSelectPageAndGoNext(1, 0);
          } else if (!A) {
            console.log('!A');
            setSelectPageAndGoNext(2, 0);
          }
        }, 400);
      } else if (parseInt(page) === 18) {
        //원래 20 말고 18이 맞음
        setTimeout(() => {
          if (context.workbookAnswer.find((element) => element.id === 18).value) {
            //원래 20 말고 18이 맞음
            setSelectPageAndGoNext(0, 1);
          } else {
            setSelectPageAndGoNext(1, 1);
          }
        }, 400);
      }
    }
  }, [context.workbookAnswer]);

  const options = [
    { value: true, text: '네' },
    { value: false, text: '아니요' },
  ];

  return text ? (
    <div className={`w-full px-20px h-[calc(100vh-17px)] `}>
      <div className="w-full relative">
        <div
          className="w-full px-[20px]  font-bold text-[24px] leading-[30px]  absolute bottom-[40px] text-center left-[50%] translate-x-[-50%]"
          style={{ wordBreak: 'keep-all' }}
        >
          {title}
        </div>
      </div>
      <div className="text-[17px] leading-[28px] font-light text-[#4E4F53] pt-[40px] px-[4px]">{text}</div>
      <div className="w-[calc(100%-80px)] flex justify-between min-w-[305px] mx-auto shrink-0">
        {options.map((option, index) => (
          <SelectButtonCircle
            selected={
              context.workbookAnswer.find((element) => element.id === parseInt(page)) &&
              context.workbookAnswer.find((element) => element.id === parseInt(page)).value === option.value
            }
            option={option}
            text={option.text}
            isClicked={isClicked} //버튼 컴포넌트에서 알려줌
            disable={disable} // 버튼 컴포넌트로 알려줌
          />
        ))}
      </div>
    </div>
  ) : (
    <div className={`w-full px-20px h-[calc(100vh-17px)] relative flex flex-col`}>
      <div className="w-full h-full relative flex flex-col justify-center">
        <div
          className="mb-[40px] w-full px-[20px]  font-bold text-[24px] leading-[30px] text-center"
          style={{ wordBreak: 'keep-all' }}
        >
          {title}
        </div>
        <div className="w-[calc(100%-80px)] flex justify-center min-w-[305px] mx-auto shrink-0" style={{ gap: '40px' }}>
          {options.map((option, index) => (
            <SelectButtonCircle
              selected={
                context.workbookAnswer.find((element) => element.id === parseInt(page)) &&
                context.workbookAnswer.find((element) => element.id === parseInt(page)).value === option.value
              }
              option={option}
              text={option.text}
              isClicked={isClicked} //버튼 컴포넌트에서 알려줌
              disable={disable} // 버튼 컴포넌트로 알려줌
            />
          ))}
        </div>
      </div>
    </div>
  );
}
