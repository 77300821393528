// import './App.css';
import Router from './routes';
import mixpanel from 'mixpanel-browser';
import { BUILD_TARGET } from './config';
import { RecoilRoot } from 'recoil';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

function App() {
  const isDev = BUILD_TARGET === 'dev';
  const projectId = isDev ? '25b42d29b7a969a3fdaf6033c5548c5f' : '02acb9c807360f7c5387d8816184ab8d';
  const projectId_somny = isDev ? '14e8215c61c05a3b04ea722d64965b16' : 'f81900df2882a68a27fbde82553d6b69';
  const projectId_loomy = isDev ? '1bab83cc1b3caa67150f5b3d04638705' : '4a864cf15239a4f3691979e7067e07a0';
  const projectId_anxy = isDev ? '1ef0fd1faa965eb583b3e61e9d65160f' : '8239381470d70b5c60504ed32271a5fd';

  if (window.location.hostname !== 'localhost' && isNaN(parseInt(window.location.hostname.split('.')[0]))) {
    Sentry.init({
      dsn: 'https://1702eca4f7824c23bb179d41a1a3ff93@o4504052882407424.ingest.sentry.io/4504052888043520',
      environment: isDev ? 'dev' : 'main',
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0,
    });
  }

  mixpanel.init(projectId, { debug: true });
  mixpanel.init(projectId_somny, {}, 'somny');
  mixpanel.init(projectId_loomy, {}, 'loomy');
  mixpanel.init(projectId_anxy, {}, 'anxy');

  mixpanel.set_config({
    // HTTP method for tracking requests
    api_method: 'POST',

    // transport for sending requests ('XHR' or 'sendBeacon')
    // NB: sendBeacon should only be used for scenarios such as
    // page unload where a &quot;best-effort&quot; attempt to send is
    // acceptable; the sendBeacon API does not support callbacks
    // or any way to know the result of the request. Mixpanel
    // tracking via sendBeacon will not support any event-
    // batching or retry mechanisms.
    api_transport: 'XHR',

    // request-batching/queueing/retry
    batch_requests: false,

    // maximum number of events/updates to send in a single
    // network request
    batch_size: 50,

    // milliseconds to wait between sending batch requests
    batch_flush_interval_ms: 5000,

    // milliseconds to wait for network responses to batch requests
    // before they are considered timed-out and retried
    batch_request_timeout_ms: 90000,

    // override value for cookie domain, only useful for ensuring
    // correct cross-subdomain cookies on unusual domains like
    // subdomain.mainsite.avocat.fr; NB this cannot be used to
    // set cookies on a different domain than the current origin
    cookie_domain: '',

    // super properties cookie expiration (in days)
    cookie_expiration: 365,

    // if true, cookie will be set with SameSite=None; Secure
    // this is only useful in special situations, like embedded
    // 3rd-party iframes that set up a Mixpanel instance
    cross_site_cookie: false,

    // super properties span subdomains
    cross_subdomain_cookie: true,

    // debug mode
    debug: false,

    // if this is true, the mixpanel cookie or localStorage entry
    // will be deleted, and no user persistence will take place
    disable_persistence: false,

    // if this is true, Mixpanel will automatically determine
    // City, Region and Country data using the IP address of
    //the client
    ip: true,

    // opt users out of tracking by this Mixpanel instance by default
    opt_out_tracking_by_default: false,

    // opt users out of browser data storage by this Mixpanel instance by default
    opt_out_persistence_by_default: false,

    // persistence mechanism used by opt-in/opt-out methods - cookie
    // or localStorage - falls back to cookie if localStorage is unavailable
    opt_out_tracking_persistence_type: 'localStorage',

    // customize the name of cookie/localStorage set by opt-in/opt-out methods
    opt_out_tracking_cookie_prefix: null,

    // type of persistent store for super properties (cookie/
    // localStorage) if set to 'localStorage', any existing
    // mixpanel cookie value with the same persistence_name
    // will be transferred to localStorage and deleted
    persistence: 'cookie',

    // name for super properties persistent store
    persistence_name: '',

    // names of properties/superproperties which should never
    // be sent with track() calls
    property_blacklist: [],

    // if this is true, mixpanel cookies will be marked as
    // secure, meaning they will only be transmitted over https
    secure_cookie: false,

    // the amount of time track_links will
    // wait for Mixpanel's servers to respond
    track_links_timeout: 300,

    // if you set upgrade to be true, the library will check for
    // a cookie from our old js library and import super
    // properties from it, then the old cookie is deleted
    // The upgrade config option only works in the initialization,
    // so make sure you set it when you create the library.
    upgrade: false,

    // extra HTTP request headers to set for each API request, in
    // the format {'Header-Name': value}
    xhr_headers: {},

    // whether to ignore or respect the web browser's Do Not Track setting
    ignore_dnt: false,
  });
  // mixpanel.track('Sign up');

  return (
    <RecoilRoot>
      <Router />
    </RecoilRoot>
  );
}

export default App;
