import React, { useContext } from 'react';
import { AuthContext } from '../../context';
import { ProgressingBar } from '../../stories/else/ProgressingBar';

function OnboardingProgressingBar() {
  const context = useContext(AuthContext);

  return (
    <div className={` ${context.showProgressingbar ? 'w-full px-[20px] pt-[12px] visible' : 'h-0 invisible'}`}>
      <ProgressingBar
        maxCompleted={isNaN(context.maxCompleted) ? 0 : context.maxCompleted}
        completed={isNaN(context.completed) ? 0 : context.completed}
        color={'#3953D9'}
      />
    </div>
  );
}

export default OnboardingProgressingBar;
