import React, { useEffect, useState } from 'react';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import anxySessionData from 'data/anxy/anxySessionData';
import modalAtom, { MODAL_VIEWED } from 'recoil/common/modal/atom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import sessionAtom from 'recoil/anxy/session/atom';
import { PressedEffect } from 'stories/button/PressedEffect';
import defaultImage from 'image/anxy/12A/default_image.png';
import { UnlockLottie } from 'stories/animation/UnlockLottie';
import { CheckLottie } from 'stories/animation/CheckLottie';
import { motion } from 'framer-motion';
import usePostToolGain from 'hooks/usePostToolGain';

export default function SectionCard({
  index,
  sectionId,
  type,
  isLock,
  isCompleted,
  isFirst,
  isBetweenExercise,
  isChapterEnd,
  pageLastViewed,
  isFirstUnlock,
  isFirstComplete,
  isPaymentRequired,
  isTestClosed,
  isInReview,
  isNotReady,
  setShowPageToast,
  setToastType,
}) {
  const currentData = anxySessionData.find((element) =>
    element.type !== 'box' ? element.sectionId === sectionId : sectionId.includes(element.sectionId),
  );
  const [showUnlock, setShowUnlock] = useState(false);
  const [sessionState, setSessionState] = useRecoilState(sessionAtom);
  const setModalState = useSetRecoilState(modalAtom);

  const sectionCardHeight = 126;
  const exerciseCardHeight = 86;
  const lineHeight = isBetweenExercise || type === 'box' ? exerciseCardHeight : sectionCardHeight;

  useEffect(() => {
    if (isFirstUnlock) {
      setTimeout(() => {
        setShowUnlock(true);
      }, 2200);
    }
  }, [isFirstUnlock]);

  const goToFirstSession = useNavigateWithParams(`/anxy/${sectionId}/0`);
  const goToPayment = useNavigateWithParams('../payment');
  const gainTool = usePostToolGain({ sectionId: sectionId });

  const completedAtBeforePayment =
    sessionState.sessionList &&
    sessionState.sessionList.length !== 0 &&
    sessionState.sessionList.find((item) => item.sectionId === 'box_meditate_aa').completedAt;

  const goToComingSoon = useNavigateWithParams(`../anxyComingSoon?date=${completedAtBeforePayment}`);
  const goToNotReady = useNavigateWithParams(`../notReady`);

  const goToSession = () => {
    if (pageLastViewed && !JSON.parse(pageLastViewed).isDone && JSON.parse(pageLastViewed).page) {
      // modal한테 클릭 session 알려주기 위함
      setSessionState((state) => ({
        ...state,
        currentSession: { sectionId: sectionId, pageLastViewed: parseInt(JSON.parse(pageLastViewed).page) },
      }));
      setModalState({ isModalVisible: true, modalType: MODAL_VIEWED });
    } else {
      goToFirstSession();
    }
  };

  const handleNavigate = () => {
    if (sectionId === 'session_kc' && isPaymentRequired) {
      setSessionState((state) => ({ ...state, scrollToSession: sectionId }));
      goToPayment();
    } else if (isInReview) {
      setSessionState((state) => ({ ...state, scrollToSession: sectionId }));
      goToComingSoon();
    } else {
      goToSession();
    }
  };

  const CardSection = () => (
    <div
      className={`w-full ${type !== 'exercise' && 'bg-white'} px-[20px] py-[20px] rounded-[20px]  ring-inset ${
        !isLock && !isCompleted ? 'ring-[2px] ring-[#A4A6B0]' : 'ring-[1px] ring-[rgba(0,0,0,0.08)]'
      }`}
    >
      <div className="flex gap-[20px]">
        <div className="flex-1 flex flex-col justify-between">
          <div className={`text-[18px] leading-[23px] font-semibold ${type === 'box' && 'py-[4px]'}`}>
            {currentData
              ? currentData.type === 'box'
                ? `${currentData.title} ${currentData.title === '걱정기록' ? '업그레이드' : '획득'}`
                : currentData.title
              : ' '}
          </div>
          <div className="flex gap-[4px] items-center">
            <div>
              {type === 'session' && (
                <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    opacity="0.6"
                    d="M8.5 4.63397C9.16667 5.01887 9.16667 5.98113 8.5 6.36603L1.75 10.2631C1.08333 10.648 0.25 10.1669 0.25 9.39711L0.25 1.60289C0.25 0.833085 1.08333 0.35196 1.75 0.73686L8.5 4.63397Z"
                    fill="black"
                  />
                </svg>
              )}
            </div>
            {currentData && currentData.playTime && (
              <div className="text-[15px] leading-[21px] text-[rgba(0,0,0,0.6)]">{`${currentData.playTime}분`}</div>
            )}
          </div>
        </div>
        {type === 'session' && (
          <div className="shrink-0 w-[83px] h-[110px] rounded-[8px] ">
            <img src={currentData && currentData.image ? currentData.image : defaultImage} alt={'empty'} />
          </div>
        )}
      </div>
    </div>
  );

  const handleAction = () => {
    if (isPaymentRequired) {
      goToPayment();
    } else {
      if (type === 'session') {
        if (isNotReady) {
          setSessionState((state) => ({ ...state, scrollToSession: sectionId }));
          goToNotReady();
        } else {
          handleNavigate();
        }
      } else {
        if (isFirst) {
          gainTool();
        } else {
          setShowPageToast(true);
          if (sectionId.includes('upgrade')) {
            setToastType('upgrade');
          } else {
            setToastType('gain');
          }
        }
      }
    }
  };

  return (
    <div className="flex flex-col pb-[16px] pr-[20px]">
      <div className="w-full flex items-center">
        <div className="relative">
          {!isChapterEnd && (
            <div>
              <div
                className={`absolute ${
                  isCompleted && !isFirstComplete ? 'w-[2px] bg-[#A4A6B0]' : 'w-[1px] bg-black opacity-[0.08]'
                } top-[32px] left-[31px] rounded-[18px]`}
                style={{
                  height: `${lineHeight}px`,
                }}
              />
              {isFirstComplete && (
                <motion.div
                  className={`absolute w-[2px] h-[0] bg-[#A4A6B0] top-[32px] left-[31px] rounded-[18px]`}
                  animate={{ height: `${lineHeight}px` }}
                  transition={{ duration: 1, delay: 1, ease: 'easeInOut' }}
                />
              )}
            </div>
          )}
          <div className="w-[24px] h-[24px] ml-[20px] mr-[10px] relative ">
            {!isLock && !isCompleted && (
              <motion.div
                key={isFirstUnlock}
                initial={{ opacity: isFirstUnlock ? 0 : 1 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: 3.4 }}
                className="absolute top-0 left-0 w-full h-full"
              >
                <svg width="24" height="24" viewBox="  0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="11" stroke="#A4A6B0" strokeWidth="2" />
                </svg>
              </motion.div>
            )}
            {(type === 'session' || type === 'box') && isLock && (
              <div className="absolute top-0 left-0 w-full h-full z-[50]">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.3">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.38999 10.6102H14.4747V8.23728C14.4747 6.83317 13.3365 5.69491 11.9324 5.69491C10.5282 5.69491 9.38999 6.83317 9.38999 8.23728V10.6102ZM7.69508 10.6574C7.45481 10.6888 7.26287 10.7411 7.09202 10.8282C6.71569 11.0199 6.40973 11.3259 6.21799 11.7022C6 12.13 6 12.6901 6 13.8102V15.8C6 16.9201 6 17.4802 6.21799 17.908C6.40973 18.2843 6.71569 18.5903 7.09202 18.782C7.51984 19 8.0799 19 9.2 19H14.6644C15.7845 19 16.3445 19 16.7724 18.782C17.1487 18.5903 17.4546 18.2843 17.6464 17.908C17.8644 17.4802 17.8644 16.9201 17.8644 15.8V13.8102C17.8644 12.6901 17.8644 12.13 17.6464 11.7022C17.4546 11.3259 17.1487 11.0199 16.7724 10.8282C16.6016 10.7412 16.4098 10.6889 16.1696 10.6575V8.23728C16.1696 5.8971 14.2725 4 11.9324 4C9.59217 4 7.69508 5.8971 7.69508 8.23728V10.6574Z"
                      fill="#26282C"
                    />
                  </g>
                </svg>
              </div>
            )}
            {(type === 'session' || type === 'box') && isFirstUnlock && (
              <div className="absolute top-0 left-0 w-full h-full z-[50]">
                <motion.div animate={{ opacity: showUnlock ? 0 : 1 }} transition={{}} className="z-[50]">
                  <UnlockLottie isStop={true} containerSize="24px" gray />
                </motion.div>
              </div>
            )}
            {(type === 'session' || type === 'box') && isFirstUnlock && showUnlock && (
              <div className="absolute top-0 left-0 w-full h-full z-[50]">
                <motion.div animate={{ opacity: 0 }} transition={{ delay: 0.8 }} className="z-[50]">
                  <UnlockLottie isStop={!showUnlock} containerSize="24px" gray />
                </motion.div>
              </div>
            )}
            {(isCompleted || isFirstComplete) && (
              <div className="absolute top-0 left-0 w-full h-full">
                {isFirstComplete ? (
                  <CheckLottie containerSize="24px" gray />
                ) : (
                  <svg
                    className="absolute"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#A4A6B0" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.5386 8.47802C17.8268 8.77545 17.8194 9.25027 17.522 9.53855L11.3315 15.5386C11.0406 15.8205 10.5784 15.8205 10.2875 15.5386L6.47802 11.8462C6.18059 11.558 6.17317 11.0831 6.46145 10.7857C6.74973 10.4883 7.22455 10.4809 7.52198 10.7691L10.8095 13.9555L16.478 8.46145C16.7755 8.17317 17.2503 8.18059 17.5386 8.47802Z"
                      fill="white"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
        </div>
        <PressedEffect element={<CardSection />} disable={isLock} action={handleAction} />
      </div>
    </div>
  );
}
