/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from '../../recoil/common/header/atom';
import { BottomAction } from '../../stories/page/BottomAction';
import Progressbar from 'react-js-progressbar';
import './result.css';
import { resultByScore } from '../../data/inside/resultByScore';
import { objectDataList } from '../../data/inside/objectDataList';
import useMixpanelEvent from '../../hooks/useMixpanelEvent';
import { AccordianItem } from '../../stories/text/AccordianItem';
import { useRef } from 'react';

export default function Result({ selfCheckState }) {
  const score = getScore();
  const infoRef = useRef();
  const dataByScore = resultByScore.find((item) => item.score === score);
  console.log('self', selfCheckState);

  const setMixPanel = useMixpanelEvent({
    eventName: 'white_3_result',
    params: {
      점수: score,
      목표: selfCheckState.objective.text,
    },
  });

  function getScore() {
    return arraySum(selfCheckState && selfCheckState.selfCheckAnswer.map((element) => element.score));
  }

  function arraySum(arr) {
    return arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
  }

  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
    setMixPanel();
  }, []);

  const Title = ({ title }) => {
    return <p className="text-[24px] leading-[30px] font-bold">{title}</p>;
  };

  const WhiteCard = ({ content }) => {
    return <p className="bg-white rounded-[16px] w-full p-[20px] mt-[12px]">{content}</p>;
  };

  const Section = ({ title, subtitle, content, replaceContent, noMargin }) => {
    return (
      <div className={`flex flex-col ${!noMargin && 'mb-[60px]'}`}>
        <Title title={title} />
        {subtitle && <p className="mt-[8px] text-[#A4A6B0] text-[15px] leading-[18px]">{subtitle}</p>}
        {content && <WhiteCard content={content} />}
        {replaceContent && replaceContent}
      </div>
    );
  };

  const ProgressBarWithInfo = () => {
    return (
      <div className="relative">
        <div className="flex-1 flex flex-col items-center justify-center">
          <div className="w-[254px] h-[254px]  relative" id="result">
            <Progressbar
              input={score}
              pathWidth={14}
              pathColor={'#2C4BEC'} // use an array for gradient color.
              trailWidth={24}
              trailColor="#E1E7FF" // use a string for solid color.
              textStyle={{ fill: '#26282C', fontSize: '50px', fontWeight: '700' }} // middle text style
              pathShadow={'none'}
              pathLinecap="round"
              customText={score === 0 ? '0' : score}
              animation={{ duration: 0 }}
            ></Progressbar>
            <div className="absolute top-[50%] translate-y-[calc(-50%+28px)] left-[50%] translate-x-[-50%] text-[17px] leading-[23px] text-[#6B6D76] font-normal">
              {'100점 만점'}
            </div>
          </div>
          <div className="mt-[8px] bg-[#F3F5F8] w-full rounded-[12px] p-[20px] text-center">
            <p className="text-[18px] leading-[21.6px] font-bold">{`${dataByScore.effectText}`}</p>
          </div>
        </div>
      </div>
    );
  };

  const ExpectedPlan = () => {
    return (
      <div className="flex items-center pt-[4px] pb-[10px]">
        <div className="flex flex-col items-center flex-1">
          <span className="text-[#4E4F53] text-[15px] leading-[18px]">
            <span className="text-[50px] leading-[60px] font-bold mr-[2px] text-black">
              {dataByScore.counselingNumber}
            </span>
            회
          </span>
          <p className="mt-[4px] text-[15px] leading-[18px] text-[#4E4F53]">필요한 상담</p>
        </div>
        <div className="bg-[#E1E4EB] h-[70px] w-[1px]" />
        <div className="flex flex-col items-center flex-1">
          <span className="text-[#4E4F53] text-[15px] leading-[18px]">
            <span className="text-[50px] leading-[60px] font-bold mr-[2px] text-black">
              {dataByScore.expectedMonth}
            </span>
            개월
          </span>
          <p className="mt-[4px] text-[15px] leading-[18px] text-[#4E4F53]">필요한 기간</p>
        </div>
      </div>
    );
  };

  const ObjectiveText = () => {
    return (
      <p
        className="py-[12px] text-center text-[18px] leading-[23px] text-[#26282C] font-semibold"
        style={{ wordBreak: 'keep-all' }}
      >{`${selfCheckState.objective.text}`}</p>
    );
  };

  const PlanStepCard = ({ dataList }) => {
    return (
      <div className="flex flex-col gap-[20px]">
        {dataList.map((data, index) => (
          <WhiteCard
            content={
              <div className="p-[4px] text-[15px] leading-[21px]">
                <p className="text-[#2C4BEC]">{`${index + 1}단계`}</p>
                <p className="text-[18px] leading-[23px] font-semibold pt-[8px] pb-[16px]">{data.title}</p>
                <p className="text-black opacity-[0.6]">{data.subtitle}</p>
                <div className="mt-[16px] max-w-[400px] flex justify-center m-auto">
                  <img className="w-full h-full" src={data.image} alt="empty" />
                </div>
              </div>
            }
          />
        ))}
      </div>
    );
  };

  const ExpectedDateLine = () => {
    return (
      <div className={`pt-[20px] ${score <= 60 ? 'pb-[30px]' : 'pb-[50px]'} flex items-center justify-center`}>
        <div className="w-full h-[1px] bg-[#D1D5DC]" />
        <div className="flex items-center px-[8px] shrink-0">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.5"
              d="M6.79688 3.51562C6.79688 3.12598 7.11035 2.8125 7.5 2.8125C7.88965 2.8125 8.20312 3.12598 8.20312 3.51562V7.125L10.7021 8.78906C11.0244 9.00586 11.1123 9.44238 10.8721 9.76465C10.6816 10.0869 10.2451 10.1748 9.92285 9.93457L7.11035 8.05957C6.91406 7.9541 6.79688 7.73438 6.79688 7.47363V3.51562V3.51562ZM7.5 0C11.6426 0 15 3.35742 15 7.5C15 11.6426 11.6426 15 7.5 15C3.35742 15 0 11.6426 0 7.5C0 3.35742 3.35742 0 7.5 0ZM1.40625 7.5C1.40625 10.8662 4.13379 13.5938 7.5 13.5938C10.8662 13.5938 13.5938 10.8662 13.5938 7.5C13.5938 4.13379 10.8662 1.40625 7.5 1.40625C4.13379 1.40625 1.40625 4.13379 1.40625 7.5Z"
              fill="black"
            />
          </svg>
          <span className="ml-[5px] text-[17px] leading-[20.29px] opacity-[0.5]">{`3단계 달성까지 ${dataByScore.expectedMonth}개월 예상`}</span>
        </div>
        <div className="w-full h-[1px] bg-[#D1D5DC]" />
      </div>
    );
  };

  const QAInfo = () => {
    return (
      <div
        ref={infoRef}
        className="pb-[50px]"
        onClick={() => {
          if (infoRef.current) {
            infoRef.current.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }
        }}
      >
        <AccordianItem
          title="상담 효과를 높이고 싶다면?"
          content={
            <div className="pt-[20px] pb-[30px]">
              <div className="text-[17px] leading-[31px] text-[#4E4F53]">
                {`심리상담의 효과를 높이려면 아래 여건을 충족한 상태로 상담을 받는 게 좋아요. \n\n`}
                <span className="font-bold">첫째,</span>
                {` 제한된 시간에 목적을 이루려면 변화하고자 하는 마음과 동기가 강해야 해요. \n\n`}
                <span className="font-bold">둘째,</span> {`불편하고 싫은 마음도 솔직하게 드러내야 해요. \n\n`}
                <span className="font-bold">셋째,</span>
                {` 심리상담으로 모든 것이 좋아질거라는 환상이나, 달콤한 위로만을 받고 싶은 것도 좋지 않아요. \n\n`}
                <span className="font-bold">넷째,</span>
                {` 내 생각과 감정 등을 말로 표현하기 힘들다면 개인 상담보다 집단 상담을 추천해요.`}
              </div>
            </div>
          }
          bgColor="#FFFFFF"
        />
      </div>
    );
  };

  return (
    <BottomAction
      content={
        <div className="w-screen h-full px-[20px] pt-[20px]">
          <p className="text-[21px] leading-[30px] font-bold text-center pb-[40px]">
            {`심리상담 효과를 결정하는 \n`}
            <span className="text-[#2C4BEC]">내담자 요인 4가지</span>를 분석했어요.
          </p>
          <Section title="상담 효과" content={<ProgressBarWithInfo />} />
          <Section
            title="목표 달성까지 예상"
            subtitle="개인의 상태에 따라 실제와 차이가 있을 수 있습니다."
            content={<ExpectedPlan />}
          />
          <Section title="나의 목표" content={<ObjectiveText />} />
          <Section
            title="이렇게 변화해요"
            replaceContent={
              <PlanStepCard
                dataList={objectDataList.find((item) => item.id === selfCheckState.objective.id).planStepList}
              />
            }
            noMargin
          />
          <ExpectedDateLine />
          <div>
            <QAInfo />
            <p className="text-center text-[19px] leading-[27px] font-bold text-[#3F3F3F] pb-[50px]">{`인사이드에서 상위 0.05% 자격의 \n 심리 전문가를 만나세요`}</p>
          </div>
        </div>
      }
      buttonText={'전문가 만나기'}
      buttonState={'ACTIVE'}
      action={() => {
        window.open('https://inside.im/loading', '_blank');
      }}
      bgColor="#F3F5F8"
      isHeader
    />
  );
}
