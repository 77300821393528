import React, { useState, useEffect, useContext } from 'react';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import { AuthContext } from '../../context';
import useFetch from '../../hooks/useFetch';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function UserLeave1(props) {
  const context = useContext(AuthContext);
  const [buttonState, setButtonState] = useState('ACTIVE');
  const leaveData = JSON.stringify({ reason: context.leaveReason });
  const [value, submitLeave] = useFetch({
    url: `/user/leave`,
    requestBody: leaveData,
    bg: '#FFFFFF',
  });
  const goNextPage = useNavigateWithParams('/userLeave/2');

  useEffect(() => {
    if (value) {
      console.log('탈퇴');
      setButtonState('DONE');
      goNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function userLeave() {
    setButtonState('LOADING');
    submitLeave();
  }

  const Element = (
    <div
      className={`h-full  pt-[15px] px-[20px] text-[17px] leading-[23px] trackinf-[-0.3px] text-[#4E4F53] whitespace-pre-line`}
    >
      {
        '상담 내역은 영구적으로 삭제되어 복구할 수 없어요. 나중에 인사이드를 다시 사용하려면 처음부터 시작해야 해요. \n\n 인사이드는 회원의 모든 개인 정보를 개인을 식별할 수 없게끔 암호화하여 관리해요. \n\n 그럼에도 불구하고 탈퇴를 원하시면 아래 [탈퇴하기] 버튼을 눌러주세요.'
      }
    </div>
  );

  return (
    <div className={`h-full `}>
      <TopTitleBottomAction
        title={'정말로 탈퇴하시겠어요?'}
        content={Element}
        buttonText={'탈퇴하기'}
        buttonState={buttonState}
        action={userLeave}
      />
    </div>
  );
}
