/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../../stories/page/BottomActionAnxy';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../../recoil/common/header/atom';
import heart_guide1 from '../../../image/anxy/12B/heart_guide1.png';
import heart_guide2 from '../../../image/anxy/12B/heart_guide2.png';
import level_guide1_lottie from '../../../image/anxy/12B/level_guide1_lottie.json';
import level_guide2_lottie from '../../../image/anxy/12B/level_guide2_lottie.json';
import Lottie from '../../../components/elements/Lottie';
import useFetchAnxy from '../../../hooks/useFetchAnxy';

function Guide1({ title, noGradient, content, buttonText, action, justifyEnd }) {
  const imageRef = useRef();
  return (
    <BottomActionAnxy
      content={
        <div className="h-full flex flex-col">
          <div
            className="pt-[12px] text-[24px] leading-[30px] font-bold px-[20px] "
            style={{
              wordBreak: 'keep-all',
            }}
          >
            {title.map((each, index) => (
              <span key={each} className={`${index % 2 === 1 ? 'text-[#EF670B]' : 'text-black'}`}>
                {each}
              </span>
            ))}
          </div>
          <div className={`w-full flex-1 `} ref={imageRef}>
            <div
              style={{ width: '100%', height: imageRef.current && imageRef.current.offsetHeight }}
              className={`flex flex-col items-center relative ${justifyEnd ? 'justify-end' : 'justify-center'}`}
            >
              {content}
            </div>
          </div>
        </div>
      }
      noGradient={noGradient}
      buttonText={buttonText}
      action={action}
      bgColor={'#F3F5F8'}
    />
  );
}

const guideData = [
  {
    page: 'level',
    guides: [
      {
        title: ['', '레벨', '은 미션을 완수하면 올릴 수 있어요'],
        lottie: level_guide1_lottie,
        css: 'w-full px-[40px]',
        buttonText: '다음',
      },
      {
        title: ['레벨이 올라갈수록 ', '최대 체력', '이 많아져요'],
        lottie: level_guide2_lottie,
        css: 'mx-[20px]',
        buttonText: 'Anxy 레벨 확인하기',
      },
    ],
  },
  {
    page: 'heart',
    guides: [
      {
        title: ['여정을 떠날 때마다 Anxy는 ', '체력❤️', '을 소진해요'],
        image: heart_guide1,
        css: 'absolute w-full px-[50px] py-[30px] h-full  object-contain',
        buttonText: '다음',
      },
      {
        title: ['소진된 체력은 일정 시간이 지나면 ', '회복', '돼요'],
        image: heart_guide2,
        css: 'absolute top-0 w-full px-[20px] h-[calc(100%+88px)] translate-y-[0px] object-contain ',
        buttonText: 'Anxy 체력 확인하기',
      },
    ],
  },
];

export default function Guide(props) {
  const { page, id } = useParams();
  const currentData = guideData.find((element) => element.page === page).guides;

  const goNext = useNavigateWithParams(`/guide/${page}/${parseInt(id) + 1}`);
  const goPage = useNavigateWithParams(`/${page}`);

  const setHeaderState = useSetRecoilState(headerAtom);
  const [viewHeartGuideData, viewHeartGuide] = useFetchAnxy({
    url: `anxy/user/level-heart/viewed/save`,
    requestBody: JSON.stringify({ isHeartViewed: true }),
    bg: '#FFFFFF',
  });
  const [viewLevelGuideData, viewLevelGuide] = useFetchAnxy({
    url: `anxy/user/level-heart/viewed/save`,
    requestBody: JSON.stringify({ isLevelViewed: true }),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (viewHeartGuideData || viewLevelGuideData) {
      goNext();
    }
  }, [viewHeartGuideData, viewLevelGuideData]);

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
  }, []);

  return (
    <Guide1
      title={currentData[parseInt(id)].title}
      css
      content={
        currentData[parseInt(id)].image ? (
          <img src={currentData[parseInt(id)].image} alt={'empty'} className={currentData[parseInt(id)].css} />
        ) : (
          <Lottie lottieData={currentData[parseInt(id)].lottie} autoplay />
        )
      }
      justifyEnd={page === 'heart' && parseInt(id) === 1}
      noGradient
      buttonText={currentData[parseInt(id)].buttonText}
      action={() => {
        if (parseInt(id) === currentData.length - 1) {
          goPage();
        } else {
          if (page === 'level') {
            viewLevelGuide();
          } else if (page === 'heart') {
            viewHeartGuide();
          }
        }
      }}
    />
  );
}
