import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../coachingContext';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import card1 from '../../image/course5/card1.png';
import card2 from '../../image/course5/card2.png';
import card3 from '../../image/course5/card3.png';
import cardImage1 from '../../image/course5/cardImage1.png';
import cardImage2 from '../../image/course5/cardImage2.png';
import cardImage3 from '../../image/course5/cardImage3.png';

export default function ShareCard() {
  const { id } = useParams();
  const context = useContext(CoachingContext);

  const [vh, setVh] = useState(window.innerHeight);

  const cardInfo = [
    { id: 1, icon: card1, image: cardImage1, bg: '#101A4F' },
    { id: 2, icon: card2, image: cardImage2, bg: '#40527A' },
    { id: 3, icon: card3, image: cardImage3, bg: '#142647' },
  ];

  const goToApp = () => {
    window.location.href = 'https://insideapp.page.link/82AY';
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <div className={`bg-[#2B3550] w-full overflow-hidden px-[20px]`} style={{ height: vh }}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="h-[420px]">
            <img
              className="w-full h-full object-contain"
              src={cardInfo.find((item) => item.id === parseInt(id)).image}
              alt="empty"
            />
          </div>
          <p className="text-[15px] leading-[21px] text-[#D1D5DC] mt-[20px]">잃어버린 밤을 찾아서 @inside</p>
        </div>
        <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px] mt-[40px]`}>
          <PrimaryActionButton state="ACTIVE" text={'함께 코스 진행하기'} action={goToApp} />
        </div>
      </div>
    </div>
  );
}
