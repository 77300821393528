import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import somnyHomeAtom, { TYPE_REPORT } from '../../../recoil/somny/home/atom';
import { CheckLottie } from '../../../stories/animation/CheckLottie';
import { TopTitleBottomActionSomny } from '../../../stories/page/TopTitleBottomActionSomny';

export function SleepRecordDone() {
  const goHome = useNavigateWithParams('/somnyHome');
  const setHomeType = useSetRecoilState(somnyHomeAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  const handleEnd = () => {
    setHomeType((state) => ({ ...state, type: TYPE_REPORT }));
    goHome();
  };

  useEffect(() => {
    setProgressBarState({ isProgressBarVisible: false });
  }, []);

  return (
    <TopTitleBottomActionSomny
      content={
        <div className="h-full flex flex-col items-center justify-center">
          <CheckLottie containerSize="77px" somny />
          <p className="text-[24px] leading-[29px] font-bold text-white pt-[30px]">기록을 마쳤어요</p>
          <p className="text-[17px] leading-[23px] text-white opacity-[0.5] pt-[8px]">
            저도 이제 안심하고 잠들 수 있겠네요
          </p>
        </div>
      }
      action={handleEnd}
      buttonText="확인"
      buttonState="ACTIVE"
      bgColor="#121B40"
    />
  );
}
