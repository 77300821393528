/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import backgroundColorAtom from '../../../recoil/common/backgroundColor/atom';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import webviewToast from '../../../development/webviewToast';
import { AnxyLoadingLottie } from '../../../stories/animation/AnxyLoadingLottie';
import anxyColorPalette from '../../../data/anxy/contents/anxyColorPalette';
import anxySessionByType from '../../../data/anxy/anxySessionByType';
import userAtom from '../../../recoil/anxy/user/atom';
import TrialTemplate from './TrialTemplate';
import { data } from 'autoprefixer';
import useSaveViewedPage from '../../../hooks/useSaveViewedPage';
import useMixpanelEventAnxy from '../../../hooks/useMixpanelEventAnxy';
import anxyTypeData from '../../../data/anxyTypeData';

export default function Trial() {
  const { id, page } = useParams();
  const userState = useRecoilValue(userAtom);

  const [searchParams] = useSearchParams();
  const testType = searchParams.get('type');
  const anxietyTypeId = testType ? parseInt(testType) : userState.anxietyTypeId;

  const [prevBackgroundColor, setPrevBackgroundColor] = useRecoilState(backgroundColorAtom);

  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const [headerState, setHeaderState] = useRecoilState(headerAtom);
  const [imageLoaded, setImageLoaded] = useState(false);

  const saveViewedPage = useSaveViewedPage({ noneAction: true });

  const checkPointList = ['불안 파트', '신체 파트', '생각 파트', '행동 파트'];
  const checkPointName = checkPointList[parseInt(id)];
  const setMixPanel = useMixpanelEventAnxy({
    eventName: '세션 진행 중',
    params: {
      세션: 'Intro',
      체크포인트: checkPointName,
    },
  });

  const imageByType = anxySessionByType.find((data) => data.type === anxietyTypeId).trialList;
  const loadImageById = () => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(loadImg);
          }, 100);

        loadImg.onerror = (err) => reject(err);
      });
    };

    const imageList = imageByType.filter((item) => item.image !== undefined).map((item) => item.image);

    console.log(imageByType, imageList);
    Promise.all(imageList.map((image) => loadImage(image)))
      .then(() => {
        webviewToast('이미지 로드');
        setImageLoaded(true);
      })
      .catch((err) => webviewToast('이미지 로드 실패'));
  };

  useEffect(() => {
    setImageLoaded(false);
    // loadImageById();
  }, [id]);

  useEffect(() => {
    document.title = 'Anxy Session';
    setPrevBackgroundColor(anxyColorPalette.black);
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#FFFFFF', headerTitle: '' }));
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    loadImageById();
  }, []);

  useEffect(() => {
    if ((dataByType.replaceComponent && !dataByType.replaceComponent.props.video) || !data.replaceComponent) {
      saveViewedPage();
    }
    if (parseInt(page) === 1) {
      webviewToast('mixpanel: ' + checkPointName);
      setMixPanel();
    }
  }, [page]);

  const dataByType = anxySessionByType.find((data) => data.type === anxietyTypeId);
  const curretData = dataByType.trialList[parseInt(page) - 1];
  const trialList = dataByType.trialList;
  const isSessionEnd = trialList.length === parseInt(page);

  console.log(isSessionEnd);

  return curretData.replaceComponent ? (
    curretData.replaceComponent
  ) : (
    <TrialTemplate data={curretData} isSessionEnd={isSessionEnd} />
  );
}
