import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import axios from '../utils/axios2';
import gift from '../image/gift.png';
import checkin_star from '../image/checkin_star.png';
import 'moment/locale/ko';
import { motion } from 'framer-motion';
import lottie from 'lottie-web';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { CoachingContext } from '../coachingContext';

import { changeHeader, close, showWebView, getRefresh } from '../utils/webview';
import webviewToast from '../development/webviewToast';
import { PrimaryActionButton } from '../stories/button/ActionButton';

function CheckIn() {
  const [isLoaded, setIsLoaded] = useState(false);

  const [result, setResult] = useState('');
  const [isRotate, setIsRotate] = useState(false);
  const [prevDay, setPrevDay] = useState(0);
  const [isNewVersion, setIsNewVersion] = useState(0);

  const navigate = useNavigate();

  const context = useContext(CoachingContext);
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appId = searchParams.get('app_id');
  // const isChanged = searchParams.get('at').split('?')[1];
  const isChanged = searchParams.get('isChanged') === 'true' || false;
  const isFirst = searchParams.get('isFirst') === 'true' || false;
  const appVersion = searchParams.get('app_version') || 0;

  const likecontainer = useRef();
  const container = useRef();

  useEffect(() => {
    if (isLoaded) {
      const instance = lottie.loadAnimation({
        container: likecontainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: require('../image/homelottie.json'),
      });
      return () => instance.destroy();
    }
  }, [isLoaded]);

  const goToBoarding = () => {
    showWebView({ url: `startOnboarding?at=${authToken}&refresh=true` });
  };

  useEffect(() => {
    getRefresh(getCheckInDetail);

    setTimeout(() => {
      setIsRotate(true);
    }, 500);

    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'none' });

    context.authToken === '' && context.setAuthToken(authToken);
    context.appId === '' && context.setAppId(appId);

    if (appVersion) {
      let version = appVersion.replace(/\D/g, '');
      if (parseInt(version) >= 132) {
        setIsNewVersion(true);
      }
    }
  }, []);

  const getCheckInDetail = useCallback(async () => {
    try {
      if (!context.isHomeLoaded) {
        const response = await axios(3, authToken, appId).get(`/check-in/detail`);
        const { result } = response.data;
        setResult(() => result);
        if (result.daysInARow !== 1) {
          setPrevDay(() => result.daysInARow - 1);
        }
        setIsLoaded(true);
        console.log(result);
      }
    } catch (error) {
      console.error('result', error);
    }
  }, []);

  useEffect(() => {
    console.log('getCheckInDetail');
    getCheckInDetail();
  }, [getCheckInDetail]);

  const spring = {
    type: 'spring',
    duration: 1.4,
    delay: 1.2,
  };

  const variants2 = {
    rotate: {
      rotateY: [0, 60, 120, 180, 240, 255, 270, 285, 300, 315, 330, 345, 350, 355, 360],
      // opacity: isClosing ? 0 : 1,
      transition: spring,
    },
    visible: {
      opacity: 1,
    },
  };

  let isTodayIndex = 0;
  result &&
    result.dayOfWeekStatusList.forEach((item, index) => {
      if (item.dayOfWeekText === '오늘') {
        isTodayIndex = index;
      }
    });

  const goToHome = () => {
    if (isFirst) {
      webviewToast('marketingpage open');
      navigate(`/marketing?at=${authToken}&app_id=${appId}`);
    } else {
      close({ refresh: true });
    }
  };

  const goToCouponPage = () => {
    showWebView({ url: `myCoupon?at=${authToken}` });
  };

  const left = 45 * isTodayIndex + -1;

  console.log(result.status);

  useEffect(() => {
    setTimeout(() => {
      const instance = lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: require('../image/toolopen.json'),
      });
      return () => instance.destroy();
    }, 400);
  }, []);

  return (
    <div
      className={`relative h-screen overflow-auto w-screen bg-[#EFF2FF]  text-[#26282C] px-20px flex flex-col justify-between
      ${isLoaded ? 'opacity-1' : 'opacity-0'}`}
    >
      {result.daysInARow === 7 && isChanged && (
        <div className="w-full absolute top-[70px]  left-[50%] translate-x-[-50%]">
          <motion.div className="w-full h-full" ref={container} />
        </div>
      )}
      <div className="my-auto">
        {isChanged && result.daysInARow !== 0 ? (
          <div className="relative w-[49px] h-[95px] mx-auto overflow-hidden">
            <motion.div
              className="relative top-0"
              animate={{
                top: -95,
              }}
              transition={{ duration: 0.5, delay: 0.4, ease: 'easeInOut' }}
            >
              <p className="text-[80px] leading-[95px] font-semibold text-center">{prevDay}</p>
              <p className="text-[80px] leading-[95px] font-semibold text-center">{result.daysInARow}</p>
            </motion.div>
          </div>
        ) : (
          <p className="text-[80px] leading-[95px] font-semibold text-center">{result.daysInARow}</p>
        )}

        <p className="text-[24px] leading-[29px] font-bold text-center mt-[6px] mb-[30px]">
          {result.status === 'complete' ? '축하합니다! 연속 달성 완료' : '연속 달성일'}
        </p>
        <div className="flex justify-center relative ">
          {result.length !== 0 &&
            result.dayOfWeekStatusList.map((item, index) => (
              <div className={`relative flex flex-col justify-center items-center ml-[${index !== 0 ? 15 : 0}px]`}>
                <p
                  className={`text-[17px] leading-[26px] whitespace-nowrap ${
                    item.isCheckedIn || item.dayOfWeekText === '오늘'
                      ? 'text-[#3953D9] font-semibold'
                      : 'text-[#A4A6B0]'
                  } mb-[7px]
                  ${!item.isCheckedIn && index < isTodayIndex && 'text-[#98A3E0]'}
                  ${item.dayOfWeekText === '오늘' && !item.isCheckedIn && result.closingSoonText && 'text-[#FF6D2E]'}
                  `}
                >
                  {item.dayOfWeekText}
                </p>
                {index < isTodayIndex && !item.isCheckedIn ? (
                  <div
                    className={`w-[30px] h-[30px] bg-[#98A3E0] flex justify-center items-center rounded-[100%]
          `}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M12.6567 1.34277L1.34303 12.6565" stroke="white" strokeWidth="2" strokeLinecap="round" />
                      <path d="M12.6567 12.6572L1.34303 1.34352" stroke="white" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                  </div>
                ) : (
                  <div
                    className={`relative w-[30px] h-[30px]
                    ${
                      item.isCheckedIn && !isChanged ? 'bg-qna' : 'bg-white'
                    } flex justify-center items-center rounded-[100%]
                    ${index < isTodayIndex && item.isCheckedIn && 'bg-qna'}
                    ${item.dayOfWeekText === '오늘' && !item.isCheckedIn && !isChanged && 'border-2 border-[#3953D9]'}
                    ${
                      item.dayOfWeekText === '오늘' &&
                      !item.isCheckedIn &&
                      result.closingSoonText &&
                      'border-solid border-2 border-[#FF6D2E]'
                    }
          `}
                  >
                    {/* <div className="w-[30px] h-[30px] absolute rounded-[100%] bg-black" />
                    <div className="w-[32px] h-[32px] absolute  z-[50]" ref={likecontainer} /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="">
                      <path
                        d="M2.44597 3.41655C2.0494 3.03217 1.41631 3.04206 1.03193 3.43864C0.647557 3.83522 0.657449 4.46831 1.05403 4.85268L2.44597 3.41655ZM6.5119 8.75L5.81593 9.46807C6.20377 9.84398 6.82003 9.84398 7.20788 9.46807L6.5119 8.75ZM14.946 1.96807C15.3426 1.58369 15.3524 0.950605 14.9681 0.554027C14.5837 0.157449 13.9506 0.147557 13.554 0.531932L14.946 1.96807ZM1.05403 4.85268L5.81593 9.46807L7.20788 8.03193L2.44597 3.41655L1.05403 4.85268ZM7.20788 9.46807L14.946 1.96807L13.554 0.531932L5.81593 8.03193L7.20788 9.46807Z"
                        fill={item.isCheckedIn ? 'white' : `#D1D5DC`}
                      />
                    </svg>
                  </div>
                )}
              </div>
            ))}
          {isChanged && result.daysInARow !== 0 && (
            <div className="w-[300px] h-[30px] absolute left-[50%] top-[32px] translate-x-[-50%]">
              <div style={{ position: 'absolute', left: left }}>
                <div className="w-[32px] h-[32px]  z-[50]" ref={likecontainer} />
              </div>
            </div>
          )}
        </div>
        {result.closingSoonText && (
          <motion.div
            className="w-[0] h-[39px] flex justify-center items-center bg-[#FF6D2E] rounded-[50px] mt-[30px] mx-auto"
            animate={{ width: '100%' }}
            transition={{ duration: 0.5 }}
          >
            <motion.div className="flex opacity-0" animate={{ opacity: 1 }} transition={{ duration: 0.3, delay: 0.2 }}>
              <div className="w-[18px] h-[18px] bg-white rounded-[100%] flex justify-center items-center mr-[6px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="3" height="12" viewBox="0 0 3 12" fill="none">
                  <path
                    d="M1.90967 7.61426H0.432129L0.166504 0.765625H2.19189L1.90967 7.61426ZM2.37451 9.87207C2.37451 10.2041 2.2583 10.4863 2.02588 10.7188C1.79346 10.9512 1.51123 11.0674 1.1792 11.0674C0.847168 11.0674 0.564941 10.9512 0.33252 10.7188C0.111165 10.4863 0.000488281 10.2041 0.000488281 9.87207C0.000488281 9.54004 0.116699 9.26335 0.349121 9.04199C0.581543 8.80957 0.858236 8.69336 1.1792 8.69336C1.51123 8.69336 1.79346 8.80957 2.02588 9.04199C2.2583 9.26335 2.37451 9.54004 2.37451 9.87207Z"
                    fill="#FF6D2E"
                  />
                </svg>
              </div>
              <p className="whitespace-nowrap text-[15px] leading-[18px] text-[#FFFFFF] font-semibold">
                {result.closingSoonText}
              </p>
            </motion.div>
          </motion.div>
        )}
      </div>

      {result.status === 'complete' && (
        <motion.div className={`${context.biggerMargin ? `pb-[34px]` : `pb-[20px]`}`}>
          {isChanged && result.daysInARow !== 0 ? (
            <motion.div
              className="opacity-0 flex flex-col justify-center items-center bg-[#3A3C40] rounded-[20px] h-[162px]"
              animate={{ y: -20, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.9, ease: 'easeInOut' }}
            >
              <motion.img
                className="h-[35px] w-[35px]"
                src={checkin_star}
                alt="empty"
                variants={variants2}
                animate="rotate"
              />
              <div>
                <p className="font-semibold text-[20px] leading-[24px] text-white mt-[17px] mb-[6px]">
                  상담 할인권이 발급되었어요
                </p>
                <div className="flex items-center justify-center">
                  <p className="text-[15px] leading-[18px] text-[#A4A6B0] mr-[4.5px]" onClick={goToCouponPage}>
                    나의 상담권 보기
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                    <path
                      d="M1.5 9L5.5 5L1.5 1"
                      stroke="#A4A6B0"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </motion.div>
          ) : (
            <div className="flex flex-col justify-center items-center bg-[#3A3C40] rounded-[20px] h-[162px]">
              <img className="h-[35px] w-[35px]" src={checkin_star} alt="empty" />
              <div>
                <p className="font-semibold text-[20px] leading-[24px] text-white mt-[17px] mb-[6px]">
                  상담 할인권이 발급되었어요
                </p>
                <div className="flex items-center justify-center">
                  <p className="text-[15px] leading-[18px] text-[#A4A6B0] mr-[4.5px]" onClick={goToCouponPage}>
                    나의 상담권 보기
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                    <path
                      d="M1.5 9L5.5 5L1.5 1"
                      stroke="#A4A6B0"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
          <div className={`${isChanged ? 'mt-[4px]' : 'mt-[24px]'} ${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'}`}>
            <PrimaryActionButton state={'ACTIVE'} text={'확인'} action={goToHome} />
          </div>
        </motion.div>
      )}
      {result.status === 'progress' && (
        <div>
          <div className="flex items-center bg-white rounded-[20px] h-[93px]">
            <img className="h-[46px] w-[46px] mr-[12px] ml-[27px]" src={gift} alt="empty" />
            <div>
              <p className="text-[15px] leading-[18px] text-[#6B6D76]">{`앞으로 ${
                7 - result.daysInARow
              }일간 연속 달성하면`}</p>
              <p className="text-[17px] leading-[26px] font-bold">심리상담 3만원 할인권</p>
            </div>
          </div>
          <div className={`mt-[24px] ${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'}`}>
            <PrimaryActionButton state={'ACTIVE'} text={'확인'} action={goToHome} />
          </div>
        </div>
      )}

      {result.status === 'program_not_started_yet' && (
        <div>
          <div className="flex flex-col justify-center items-center bg-[#3A3C40] rounded-[20px] h-[162px]">
            <img className="h-[35px] w-[35px]" src={checkin_star} alt="empty" />
            <div>
              <p className="font-semibold text-[20px] leading-[24px] text-white mt-[17px] mb-[6px]">
                7일마다 제공되는 리워드
              </p>
              <div className="flex items-center justify-center">
                <p className="text-[15px] leading-[18px] text-[#A4A6B0] mr-[4.5px]" onClick={goToBoarding}>
                  프로그램 시작하기
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path
                    d="M1.5 9L5.5 5L1.5 1"
                    stroke="#A4A6B0"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className={`mt-[24px] ${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'}`}>
            <PrimaryActionButton state={'ACTIVE'} text={'확인'} action={goToHome} />
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckIn;
