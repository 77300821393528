import homeForest from '../../image/somny/home/home_forest.png';
import homeSomny from '../../image/somny/home/home_somny.png';
import knitting1 from '../../image/somny/home/knitting_1.png';

import forestRecord from '../../image/somny/home/forest_record.png';
import forestDream from '../../image/somny/home/forest_dream.png';
import forestTale from '../../image/somny/home/forest_tale.png';

import iconDream from '../../image/somny/home/icon_dream.png';
import iconTale from '../../image/somny/home/icon_tale.png';
import iconRecord from '../../image/somny/home/icon_record.png';
import dreamMessage from '../../image/somny/home/dream_message.png';

import knit1 from '../../image/somny/knit/knit1.png';
import knit2 from '../../image/somny/knit/knit2.png';
import knit3 from '../../image/somny/knit/knit3.png';
import knit4 from '../../image/somny/knit/knit4.png';

export const somnyHomeImageList = [
  homeForest,
  homeSomny,
  knitting1,
  forestRecord,
  forestDream,
  forestTale,
  iconDream,
  iconTale,
  iconRecord,
  dreamMessage,
  knit1,
  knit2,
  knit3,
  knit4,
];
