/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../recoil/common/header/atom';
import CircularProgressingAnxy from '../../stories/page/CircularProgressingAnxy';

export default function AnxyLoading(props) {
  const setHeaderState = useSetRecoilState(headerAtom);
  const goHome = useNavigateWithParams(`/anxyHome`, true);

  useEffect(() => {
    document.title = `anxy 로딩`;
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
  }, []);

  const isDone = () => {
    goHome();
  };

  return <CircularProgressingAnxy steps={['Anxy 불러오는 중']} isDone={isDone} color={'#EF670B'} />;
}
