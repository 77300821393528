import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';

const UnlockLottie = ({ isWhite }) => {
  const likecontainer = useRef();
  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: isWhite ? require('../../image/unlocked_white.json') : require('../../image/unlocked.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <Wrapper>
      <Lottie ref={likecontainer}></Lottie>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const Lottie = styled.div`
  width: 24px;
  height: 24px;
  z-index: 1000;
`;

export default UnlockLottie;
