/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export default function Springcampfamily(props) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/selfCheck/3/0?sc=true');
  }, []);

  return (
    <div className="w-full h-screen  overflow-x-hidden overflow-y-auto scrollbar-hide pt-[20px] flex flex-col justify-center"></div>
  );
}
