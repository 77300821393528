import { useContext } from 'react';
import { CoachingContext } from '../../../coachingContext';
import useSessionStorage from '../../../hooks/useSessionStorage';

const SleepingDiseaseSummary = () => {
  const context = useContext(CoachingContext);
  const [sleepingSurvey, setSleepingSurvey] = useSessionStorage('sleepingSurvey', []);

  const disaseList = [
    { id: 3, name: '하지불안증후군' },
    { id: 5, name: '폐쇄성수면무호흡' },
    { id: 7, name: '사건수면' },
    { id: 9, name: '기면병' },
  ];
  console.log(context.workbookAnswer);
  const possibleList = disaseList.filter((t) => context.workbookAnswer.find((item) => item.id === t.id && item.value));

  const notPossibleList = disaseList.filter((t) =>
    context.workbookAnswer.find((item) => item.id === t.id && item.value === false),
  );
  console.log(possibleList);
  return (
    <div className="w-full py-[30px]">
      <div className="rounded-[12px] bg-[#F3F5F8] mx-[23px] p-20px mb-[10px]">
        <div className="mb-[6px] text-center rounded-[4px] bg-[#4E4F53] w-[155px] text-[12px] leading-[18px] h-[19px] text-white font-bold">
          <p className="pt-[0.5px]">나에게 해당할 가능성이 있는 것</p>
        </div>
        <div className="text-[17px] leading-[23px] font-semibold">
          {possibleList.length === 0 ? (
            <div className="flex items-center">
              <div className="w-[3px] h-[3px] bg-[#26282C] rounded-[100%]" />
              <p className="ml-[3px]">없음</p>
            </div>
          ) : (
            possibleList.map((data) => (
              <div className="flex items-center">
                <div className="w-[3px] h-[3px] bg-[#26282C] rounded-[100%]" />
                <p className="ml-[3px]">{data.name}</p>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="rounded-[12px] bg-[#F3F5F8] mx-[23px] p-20px mb-[10px]">
        <div className="mb-[6px] text-center rounded-[4px] bg-[#4E4F53] w-[155px] text-[12px] leading-[18px] h-[19px] text-white font-bold">
          <p className="pt-[0.5px]">나에게 해당할 가능성이 낮은 것</p>
        </div>
        <div className="text-[#26282C] text-[17px] leading-[23px] font-semibold">
          {context.workbookAnswer.length === 0 ? (
            <div>
              {disaseList.map((item, index) => (
                <div className="flex items-center">
                  <div className="w-[3px] h-[3px] bg-[#26282C] rounded-[100%]" />
                  <p className={`ml-[3px] ${disaseList.length - 1 !== index && 'mb-[6px]'}`}>{item.name}</p>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {notPossibleList.length === 0 ? (
                <div>
                  <div className="flex items-center">
                    <div className="w-[3px] h-[3px] bg-[#26282C] rounded-[100%]" />
                    <p className={`ml-[3px]`}>없음</p>
                  </div>
                </div>
              ) : (
                notPossibleList.map((data) => (
                  <div className="flex items-center">
                    <div className="w-[3px] h-[3px] bg-[#26282C] rounded-[100%]" />
                    <p className="ml-[3px]">{data.name}</p>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SleepingDiseaseSummary;
