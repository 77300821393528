export const TextWithBold = ({ texts, mx, fontColor, fontSize, fontWeight, lineHeight }) => {
  console.log(texts);
  return (
    <div className={`${mx && 'mx-0'}`}>
      {texts.map((text, index) => (
        <span
          className={`${fontSize ? fontSize : 'text-[17px]'} ${fontWeight ? fontWeight : 'font-light'} ${
            lineHeight ? lineHeight : 'leading-[28px]'
          } ${fontColor ? fontColor : 'text-[#3A3C40]'}`}
        >
          {text === '\n' && <br />}
          {index % 2 === 0 ? (
            <span>{text}</span>
          ) : (
            <span className={`bg-[#FFEB3564] pb-[0.9px] pt-[0.6px] `}>{text}</span>
          )}
        </span>
      ))}
    </div>
  );
};
