/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import SelfCheckTemplate from '../../components/selfCheck/SelfCheckTemplate';
import { AuthContext } from '../../context';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function Somny2QA(props) {
  const { choices, choices_type, qcList } = props;
  let { id } = useParams();
  id = parseInt(id);
  const context = useContext(AuthContext);

  const [answer, setAnswer] = useState();
  const [disable, setDisable] = useState(false);

  const goNextQuestion = useNavigateWithParams(`/somnyTest/${parseInt(id) + 1}`);
  const goEndTest = useNavigateWithParams(`/somnyTest/end`);

  const setMixpanel = useMixpanelWeb({ eventName: '5-2-2. 5지선다 검사 시작', projectName: 'somny' });
  const setMixpanel2 = useMixpanelWeb({ eventName: '5-2-3. 2중택일 검사 시작', projectName: 'somny' });

  useEffect(() => {
    if (parseInt(id) === 1) {
      // setMixpanel();
    } else if (parseInt(id) - 1 === qcList.findIndex((element) => element.type)) {
      // setMixpanel2();
    }
  }, []);

  useEffect(() => {
    if (context.qcAnswerList.find((element) => element.id === qcList[id - 1].id)) {
      console.log('체크햇음', context.qcAnswerList);
      const selected = context.qcAnswerList.find((element) => element.id === qcList[id - 1].id);
      setAnswer(selected.selfCheckChoiceId);
    }
  }, []);

  function select(option) {
    // console.log('answers: ', context.qcAnswerList);
    setAnswer(option.selfCheckChoiceId);
    var arr1 = context.qcAnswerList.slice();

    if (arr1.find((element) => element.id === qcList[id - 1].id)) {
      arr1 = arr1.filter((element) => element.id !== qcList[id - 1].id);
    }
    if (qcList[id - 1].type) {
      arr1.push({
        id: qcList[id - 1].id,
        selfCheckChoiceId: option.selfCheckChoiceId,
        type: qcList[id - 1].type,
      });
    } else {
      arr1.push({
        id: qcList[id - 1].id,
        selfCheckChoiceId: option.selfCheckChoiceId,
        score: option.score,
      });
    }

    context.setQcAnswerList(arr1);

    setTimeout(() => {
      if (parseInt(id) < qcList.length) {
        console.log('다음 문항으로');
        goNextQuestion();
      } else if (parseInt(id) === qcList.length) {
        goEndTest();
      }
    }, 400);
  }

  function isClicked(option) {
    if (option !== '') {
      setDisable(true);
      select(option);
    }
  }

  useEffect(() => {
    document.title = `somny ${parseInt(id)}`;
    context.setMaxCompleted(qcList.length);
    context.setCompleted(parseInt(id));
    context.setShowProgressingbar(true);
  }, []);

  return (
    <div className={`w-screen h-[calc(100vh-17px)] bg-white `}>
      <SelfCheckTemplate
        question={qcList[id - 1].question}
        optionList={qcList[id - 1].type ? choices_type : choices}
        answer={answer}
        isClicked={isClicked}
        disable={disable}
        list
      />
    </div>
  );
}
