import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import webviewToast from 'development/webviewToast';
import { CheckLottie } from 'stories/animation/CheckLottie';
import { impactFeedback, keepScreenEvent } from 'utils/webview';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from 'recoil/common/header/atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { BlackRoundActionButton } from 'stories/button/RoundActionButton';
import deviceAtom from 'recoil/common/device';
import Test from 'development/Test';
import { FullPageAnxy } from 'stories/page/FullPageAnxy';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import { marginWithHomeIndicator } from 'recoil/common/device';

export default function AnxyBreathTool({ action }) {
  const [selectMinute, setSelectMinute] = useState(1);
  const [vw, setVw] = useState(window.innerWidth);
  const [isLoad, setIsLoad] = useState(false);

  const [breathState, setBreathState] = useState('init');
  const [breathText, setBreathText] = useState('들이쉬세요');
  const [breathStart, setBreathStart] = useState(false);
  const [breathEnd, setBreathEnd] = useState(false);

  const [countDownStart, setCountDownStart] = useState(false);
  const [countDownNumber, setCountDownNumber] = useState(3);
  const [minutes, setMinutes] = useState(selectMinute);
  const [seconds, setSeconds] = useState(0);
  const setHeaderState = useSetRecoilState(headerAtom);
  const deviceState = useRecoilValue(deviceAtom);

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  // 타이머
  const time = useRef(minutes * 60 + seconds);
  const timer = useRef(null);

  // 호흡 state (들숨, 기다리기, 날숨)
  const isInit = breathState === 'init';
  const isIn = breathState === 'in';
  const isOut = breathState === 'out';
  const isHold = breathText === '잠시 멈추고';
  const minuteList = [1, 2, 3, 4, 5];

  // 타이머 3초
  useEffect(() => {
    if (countDownStart) {
      setTimeout(() => {
        setCountDownNumber(2);
        setTimeout(() => {
          setCountDownNumber(1);
          setTimeout(() => {
            // 카운트다운 3초 뒤 호흡 시작
            keepScreenEvent({ isKeepScreenOn: true });
            setBreathStart(true);
            setBreathState('in');
          }, 1000);
        }, 1000);
      }, 1300);
    }
  }, [countDownStart]);

  // 타이머 설정하는 함수
  const setTimer = () => {
    timer.current = setInterval(() => {
      time.current -= 1;
      setMinutes(parseInt(time.current / 60));
      setSeconds(parseInt(time.current % 60));
    }, 1000);
    return () => clearInterval(timer.current);
  };

  const setFeedback = () => {
    impactFeedback('medium');
  };

  useEffect(() => {
    if (countDownNumber === 1) {
      time.current = 60 * selectMinute;
      setTimer();
    }
  }, [countDownNumber, selectMinute]);

  useEffect(() => {
    if (selectMinute !== minutes) {
      setMinutes(selectMinute);
      if (!countDownStart) {
        setFeedback();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectMinute]);

  // 타이머 종료
  useEffect(() => {
    if (time.current === 0) {
      keepScreenEvent({ isKeepScreenOn: false });
      // 호흡 state 초기화
      setBreathStart(false);
      setBreathEnd(true);
      clearInterval(timer.current);
      // 카운트다운 초기화
      setCountDownStart(false);
      setCountDownNumber(3);
    }
  }, [seconds]);

  const handleResize = () => {
    setVw(window.innerWidth);
  };

  useEffect(() => {
    document.title = '호흡 타이머';
    window.scrollTo(0, 0);

    setTimeout(() => {
      setIsLoad(true);
    }, 800);

    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#26282C', headerTitle: '' }));

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAction = () => {
    // 호흡 끝일 때의 동작
    if (breathEnd) {
      webviewToast('end');
      action();
    } else {
      setCountDownStart(true);
    }
  };

  useEffect(() => {
    if (breathEnd) {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE, headerColor: '#26282C', headerTitle: '' }));
      // setTimeout(() => {
      //   action();
      // }, 200);
    }
  }, [breathEnd]);

  // 들숨 4초 멈추기 2초 날숨 4초
  useEffect(() => {
    if (breathState === 'in' && breathStart && !breathEnd) {
      setBreathText('들이쉬세요');
      setFeedback();
      setTimeout(() => {
        // 1초
        setFeedback();
        setTimeout(() => {
          // 2초
          setFeedback();
          setTimeout(() => {
            // 3초
            setFeedback();
            setBreathText('잠시 멈추고');
            setTimeout(() => {
              setBreathText('내쉬세요');
              setBreathState('out');
            }, 2000);
          }, 1000);
        }, 1000);
      }, 1000);
    }

    if (breathState === 'out' && breathStart && !breathEnd) {
      setBreathText('내쉬세요');
      setFeedback();
      setTimeout(() => {
        // 1초
        setFeedback();
        setTimeout(() => {
          // 2초
          setFeedback();
          setTimeout(() => {
            // 3초
            setFeedback();
            setTimeout(() => {
              setBreathState('in');
            }, 2000);
          }, 1000);
        }, 1000);
      }, 1000);
    }
  }, [breathState, breathStart, breathEnd]);

  return (
    <FullPageAnxy
      content={
        <div
          className={`w-screen h-full  flex flex-col justify-between text-[50px] leading-[59.67px] font-bold relative ${marginClassName}`}
        >
          <div className="flex-1 h-full flex flex-col justify-center items-center ">
            <Test
              element={
                <div
                  className=" fixed top-[60px] right-[20px] p-[10px] bg-black rounded-[5px] text-[15px] leading-[15px] text-white z-[50]"
                  onClick={action}
                >
                  SKIP
                </div>
              }
            />
            {countDownStart && !breathEnd && (
              <motion.div
                className={`opacity-0 w-[100px] text-[#26282C] absolute top-[12px] flex justify-center items-center`}
                animate={{ y: countDownNumber === 1 ? 10 : 0, opacity: countDownNumber === 1 ? 0 : 1 }}
                transition={{
                  duration: 0.2,
                  ease: 'easeInOut',
                  delay: countDownNumber === 1 ? 1 : countDownStart ? 0.3 : 1,
                }}
              >
                {countDownNumber}
              </motion.div>
            )}
            <motion.div
              className={`opacity-0 w-[100px] text-[#26282C] absolute top-[20px] flex justify-center items-center text-[24px] leading-[30px]`}
              style={{ whiteSpace: 'nowrap' }}
              animate={{ opacity: countDownNumber === 1 ? 1 : 0 }}
              transition={{
                duration: 0.2,
                ease: 'easeInOut',
                delay: countDownNumber === 1 ? 1.1 : breathEnd ? 0.1 : 1,
              }}
            >
              {breathText}
            </motion.div>
            {isInit ? (
              <motion.div
                key={isLoad}
                className={`rounded-[100%] ${
                  countDownStart ? 'w-[100px] h-[100px]' : isLoad ? 'w-[100px] h-[100px]' : 'w-[0px] h-[0px] opacity-0'
                }`}
                initial={{
                  opacity: 1,
                  backgroundColor: '#FFFFF00',
                  border: '2px solid #EBAE86',
                }}
                animate={{
                  width: breathEnd ? '0px' : isLoad && !countDownStart ? ['100px', '90px', '100px'] : '100px',
                  height: breathEnd ? '0px' : isLoad && !countDownStart ? ['100px', '90px', '100px'] : '100px',
                }}
                transition={{
                  repeat: isLoad && !countDownStart && Infinity,
                  duration: countDownStart ? 0 : isLoad ? 1.4 : 0.5,
                  ease: (!isLoad || breathEnd) && 'easeInOut',
                }}
              />
            ) : (
              <motion.div
                className={`rounded-[100%]`}
                initial={{
                  width: breathEnd ? '0px' : isOut ? '255px' : '100px',
                  height: breathEnd ? '0px' : isOut ? '255px' : '100px',
                  backgroundColor: isInit ? '#FFFFFF00' : isIn || (isHold && isOut) ? '#FFFFFF00' : '#EF670B',
                  border: isInit
                    ? '1px solid #EBAE86'
                    : isIn || (isHold && isOut)
                    ? '1px solid #EBAE86'
                    : '1px solid #EF670B',
                }}
                animate={{
                  opacity: breathEnd ? 0 : 1,
                  height: breathEnd ? '0px' : isIn ? '255px' : '100px',
                  width: breathEnd ? '0px' : isIn ? '255px' : '100px',
                  backgroundColor: isInit ? '#FFFFFF00' : isIn || (isHold && isIn) ? '#EF670B' : '#FFFFFF00',
                  border: isInit
                    ? '1px solid #EBAE86'
                    : isIn || (isHold && isIn)
                    ? '1px solid #EF670B'
                    : '1px solid #EBAE86',
                }}
                transition={{
                  duration: breathEnd ? 0.5 : isIn ? 4 : isOut ? 4 : 0.7,
                  ease: [0.38, 0.11, 0.02, 0.99],
                }}
              />
            )}
            {breathEnd && (
              <motion.div
                className={`w-[242px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[calc(-50%-54px)] flex flex-col items-center text-[#26282C] opacity-0`}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, ease: 'easeInOut', delay: 0.5 }}
              >
                <CheckLottie containerSize="72px" green />
                <p className="text-[24px] leading-[30px] mt-[26px]">심호흡을 마쳤어요</p>
              </motion.div>
            )}
            <motion.div
              className={`opacity-0 text-[20px] leading-[26px] w-[100px] h-[41px] text-[#26282C] absolute ${
                deviceState.hasHomeIndicator ? 'bottom-[54px]' : 'bottom-[40px]'
              } flex justify-center items-center`}
              animate={{ opacity: countDownStart ? 1 : 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut', delay: breathEnd ? 0.1 : 0.3 }}
            >
              {`${minutes}:${seconds.toString().length === 1 ? `0${seconds}` : seconds}`}
            </motion.div>
          </div>
          <motion.div
            key={breathEnd}
            className={`flex flex-col relative opacity-0 `}
            animate={{
              opacity: countDownStart ? 0 : 1,
              height: countDownStart ? 0 : '',
            }}
            transition={{
              duration: countDownStart ? 0.3 : 0.5,
              ease: 'easeInOut',
              delay: breathEnd ? 0.5 : 0,
            }}
          >
            {!breathEnd && (
              <div className=" mb-[40px] text-[#26282C] font-bold relative ">
                <div
                  className="w-full overflow-x-scroll snap-x text-[#26282C] scrollbar-hide"
                  style={{ scrollBehavior: 'smooth' }}
                  onTouchMove={(e) => {}}
                  onScroll={(e) => {
                    if (e.target.scrollLeft > 65) {
                      setSelectMinute(parseInt(e.target.scrollLeft / 65) + 1);
                    } else {
                      setSelectMinute(1);
                    }
                    console.log(e.target.scrollLeft);
                  }}
                >
                  <div
                    className={`h-[60px] text-center flex relative left-[50%] translate-x-[-37.5px]`}
                    style={{ width: (vw - 75) / 2 + 375 }}
                  >
                    <div className="relative w-full left-[50%] translate-x-[-50%]">
                      {minuteList.map((item, index) => (
                        <div
                          key={`${item}${index}`}
                          className={`snap-center w-[75px] text-center shrink-0 ${
                            selectMinute - 1 === index
                              ? 'opacity-1'
                              : selectMinute - 2 === index || selectMinute === index
                              ? 'opacity-[0.5]'
                              : 'opacity-[0.3]'
                          } `}
                          style={{ display: 'table-cell' }}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <p className="text-[20px] leading-[26px] relative left-[50%] translate-x-[-9px] text-wthie">분</p>
              </div>
            )}

            <div className={`px-[20px] w-full`}>
              <BlackRoundActionButton state="ACTIVE" text={breathEnd ? '다음' : '시작하기'} action={handleAction} />
            </div>
          </motion.div>
        </div>
      }
      bgColor={anxyColorPalette.oat}
    />
  );
}
