import mixpanel, { track } from 'mixpanel-browser';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from '../utils/axios2';

export default function useMixpanelEventSomny(request) {
  const [searchParams] = useSearchParams();

  const fetchData = useCallback(async () => {
    try {
      await axios(1, searchParams.get('at'), searchParams.get('app_id'), request.bg, true).post(
        '/somny/track/event/send',
        {
          eventName: request.eventName,
          propsMap: request.params,
        },
      );
    } catch (error) {
      console.error('result', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, request.eventName]);

  return fetchData;
}
