import trap1 from '../image/course5/trap1.png';
import trap2 from '../image/course5/trap2.png';
import trap3 from '../image/course5/trap3.png';
import trap4 from '../image/course5/trap4.png';
import trap5 from '../image/course5/trap5.png';
import trap6 from '../image/course5/trap6.png';
import trap7 from '../image/course5/trap7.png';

const thoughtNoteData = [
  {
    id: 1,
    text: '흑백논리',
    image: trap7,
    subtitle: '극단적으로 모 아니면 도라는 논리',
    isChecked: false,
  },
  {
    id: 2,
    text: '일반화',
    image: trap1,
    subtitle: '한 가지 사건으로 전체를 평가',
    isChecked: false,
  },
  {
    id: 3,
    text: '좋은 일 회피하기',
    isChecked: false,
  },
  {
    id: 4,
    text: '좁은 시야',
    image: trap6,
    subtitle: '긍정적인 면 대신 부정적인 면에만 집중',
    isChecked: false,
  },
  {
    id: 5,
    text: '추측',
    isChecked: false,
  },
  {
    id: 6,
    text: '성급한 결론',
    image: trap5,
    subtitle: '적절한 근거 없이 상황을 해석',
    isChecked: false,
  },
  {
    id: 7,
    text: '감정으로 판단하기',
    isChecked: false,
  },
  {
    id: 8,
    text: '당위 부여',
    image: trap4,
    subtitle: '모든것이 내 믿음과 일치하기를 바람',
    isChecked: false,
  },
  {
    id: 9,
    text: '꼬리표 붙이기',
    isChecked: false,
  },
  {
    id: 10,
    text: '과도한 책임감',
    isChecked: false,
  },
  {
    id: 11,
    text: '최악의 시나리오 떠올리기',
    image: trap3,
    subtitle: '벌어지지 않은 가장 나쁜 상황을 상상함',
    isChecked: false,
  },
  {
    id: 12,
    text: '경험과 다른 결론',
    image: trap2,
    subtitle: '나의 경험과 다른 방향으로 생각',
    isChecked: false,
  },
];

export default thoughtNoteData;
