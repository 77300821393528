/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import userAtom from '../../../recoil/anxy/user/atom';
import headerAtom, { BUTTON_NONE } from '../../../recoil/common/header/atom';
import { AnxyLoadingLottie } from '../../../stories/animation/AnxyLoadingLottie';

export default function Bridge(props) {
  const { page } = useParams();
  const setHeaderState = useSetRecoilState(headerAtom);
  const setUserState = useSetRecoilState(userAtom);

  const [userLevelData, getUserLevel] = useFetchAnxy({
    url: `anxy/user/level`,
    bg: '#FFFFFF',
  });
  const [userHeartData, getUserHeart] = useFetchAnxy({
    url: `anxy/user/heart`,
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (userLevelData) {
      const userLevel = userLevelData.result;
      setUserState((state) => ({
        ...state,
        level: {
          level: userLevel.level,
          currentExperience: userLevel.currentExperience,
          requiredExperience: userLevel.requiredExperience,
          heartBenefit: userLevel.heartBenefit,
        },
      }));
      if (userLevel.isFirst) {
        console.log('go level guide');
        goLevelGuide();
      } else {
        console.log('go level ');
        goLevel();
      }
    }
  }, [userLevelData]);

  useEffect(() => {
    if (userHeartData) {
      const userHeart = userHeartData.result;
      setUserState((state) => ({
        ...state,
        heart: {
          currentHeart: userHeart.currentHeart,
          totalHeart: userHeart.totalHeart,
          lastUseAt: userHeart.lastUseAt,
        },
      }));
      if (userHeart.isFirst) {
        console.log('go heart guide');
        goHeartGuide();
      } else {
        console.log('go heart ');
        goHeart();
      }
    }
  }, [userHeartData]);

  const goLevelGuide = useNavigateWithParams(`/guide/level/0`, true);
  const goHeartGuide = useNavigateWithParams(`/guide/heart/0`, true);
  const goLevel = useNavigateWithParams(`/level`, true);
  const goHeart = useNavigateWithParams(`/heart`, true);

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
    setTimeout(() => {
      if (page === 'level') {
        getUserLevel();
      } else if (page === 'heart') {
        console.log('d');
        getUserHeart();
      }
    }, 1000);
  }, []);

  return (
    <div className="w-screen h-screen bg-[#F3F5F8] flex justify-center items-center">
      <AnxyLoadingLottie />
    </div>
  );
}
