/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export default function Lottie(props) {
  const { lottieData, loop, autoplay, width, height } = props;
  const lottieContainer = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: loop || false,
      autoplay: autoplay || false,
      animationData: lottieData,
    });
    return () => instance.destroy();
  }, []);

  return <div style={{ width: width, height: height }} ref={lottieContainer} />;
}
