/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import EndProcessing from '../../components/onboarding/EndProcessing';
import { AuthContext } from '../../context';
import { changeHeader } from '../../utils/webview';
import useFetch from '../../hooks/useFetch';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

function EndTest() {
  const context = useContext(AuthContext);
  const [done, setDone] = useState(false);

  useEffect(() => {
    document.title = '검사 종료';

    changeHeader({ buttonType: 'none' });
    context.setShowProgressingbar(false);
  }, []);

  const selfCheckData = JSON.stringify({
    selfCheckQcSubmitList: context.selfcheckAnswer,
  });

  const [selfCheckSubmitResult, selfCheckSubmit] = useFetch({
    url: `/onboarding/self-check/submit`,
    requestBody: selfCheckData,
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (selfCheckSubmitResult) {
      getSelfCheckResult();
    }
  }, [selfCheckSubmitResult]);

  const [selfCheckResult, getSelfCheckResult] = useFetch({
    url: `/onboarding/self-check/result/detail`,
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (selfCheckResult) {
      console.log('자가검사 결과', selfCheckResult.result.selfCheckResultList);
      context.setSelfcheckResult(selfCheckResult.result.selfCheckResultList);
    }
  }, [selfCheckResult]);

  useEffect(() => {
    selfCheckSubmit();
  }, []);

  const goResult = useNavigateWithParams(`/result`);

  function isDone() {
    setDone(true);
  }
  useEffect(() => {
    if (done) {
      goResult();
    }
  }, [done]);

  return (
    <div className={`w-screen h-screen`}>
      <EndProcessing
        title={'검사 결과를 분석하여 프로그램을 구성 중이에요'}
        steps={[
          '분석 환경 세팅 중...',
          '개인정보 암호화 처리 중...',
          '검진 답변 분석 중...',
          '개인화 목표 설정 중...',
          '추천 코스 세팅 중...',
          '맞춤 프로그램 구성 중...',
        ]}
        isDone={isDone}
      />
    </div>
  );
}

export default EndTest;
