import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../../recoil/common/device';
import homeIndicatorSize from '../../../recoil/common/device/homeIndicatorSize';
import tool_box from '../../../image/anxy/12B/tool_box.png';
import { motion } from 'framer-motion';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useLocation } from 'react-router-dom';
import toolModalAtom from '../../../recoil/anxy/session/toolModalAtom';
import useMixpanelEventAnxy from '../../../hooks/useMixpanelEventAnxy';
import { anxyToolData } from '../../../data/anxy/home/anxyToolData';
import { getTimeDifference } from '../../../logic/method';
import { PressedEffect } from '../../../stories/button/PressedEffect';
import { useModalDrag } from '../../../hooks/useModalDrag';

export default function AnxyModalWithDefault(props) {
  const { toolList } = props;
  const openedToolList = toolList.filter((element) => !element.isLock);
  const location = useLocation();
  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const homeIndicatorHeight = useRecoilValue(homeIndicatorSize);
  const defaultHeight = 28 + 26 + 24 + homeIndicatorHeight;
  const [modalState, setModalState] = useRecoilState(toolModalAtom);
  const [modalHeight, setModalHeight] = useState();

  const { sheet, showModal, setShowModal } = useModalDrag(modalState.isModalVisible);
  const goManagementTool = useNavigateWithParams('/managementTool');
  const goToMeditation = useNavigateWithParams(`/meditationTool/0?useHeart=false&isSession=false`);

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '불안 관리 도구',
  });

  useEffect(() => {
    setModalState((state) => ({ ...state, isModalVisible: false }));
  }, [location]);

  useEffect(() => {
    setModalState((state) => ({ ...state, isModalVisible: showModal }));
    if (showModal) {
      setMixPanel();
    }
  }, [showModal]);

  useEffect(() => {
    if (sheet.current && !modalHeight) {
      setModalHeight(sheet.current.offsetHeight);
    }
  }, [sheet]);

  return (
    <div className="relative">
      {modalState.isModalVisible && (
        <div
          className={`bg-black opacity-[0.0] fixed top-[0] w-screen h-screen  z-[60]`}
          onClick={() => {
            setShowModal(false);
            setModalState((state) => ({ ...state, isModalVisible: false }));
          }}
        />
      )}
      <div className="w-screen fixed bottom-[0px] bg-white z-[80]" style={{ height: homeIndicatorHeight }} />
      <motion.div
        className={`w-screen fixed  opacity-100  z-[70]`}
        style={{
          bottom: modalState.isModalVisible ? 0 : `${-(modalHeight - defaultHeight)}px`,
          transition: `all 0.2s ease-in-out`,
          WebkitOverflowScrolling: 'touch',
        }}
      >
        <div
          ref={sheet}
          className={`w-full bg-[#FFFFFF] px-[20px] pt-[28px] text-[15px] leading-[18px]  relative ${marginClassName}`}
          style={{
            borderRadius: '20px 20px 0px 0px',
            boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div className="absolute w-[45px] h-[5px] rounded-[10px] bg-[#D6D8DC] top-[7px] left-[50%] translate-x-[-50%]" />
          <div className="pb-[36px]">
            <p className="text-[#26282C] text-[20px] leading-[26px] font-semibold mb-[24px]">{'나의 불안 관리 도구'}</p>

            <div className="flex flex-col items-center justify-center">
              {openedToolList.length === 0 ? (
                <div className="flex flex-col items-center justify-center ">
                  <div className="w-[140px] h-[94px] mb-[20px]">
                    <img src={tool_box} alt={'empty'} />
                  </div>
                  <p className="text-[24px] leading-[30px] font-bold text-[#26282C] mb-[8px]">
                    아직 획득한 도구가 없어요
                  </p>
                </div>
              ) : (
                <div
                  className={`w-full overflow-x-auto transition-all ${
                    showModal ? 'opacity-100' : 'opacity-100 duration-[1000]'
                  } mb-[16px]`}
                >
                  {openedToolList.map((tool, index) => (
                    <div className="w-fit h-fit" key={`openedToolList${index}`}>
                      <PressedEffect
                        key={`openTool${index}`}
                        element={
                          <div className="w-[165px] h-[165px] rounded-[18px] bg-[#F3F5F8]  flex flex-col gap-[5px] justify-center items-center">
                            <svg
                              width="60"
                              height="60"
                              viewBox="0 0 60 60"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M30.2321 43H32C34.2091 43 36 41.2091 36 39V39C36 36.7909 34.2091 35 32 35H17"
                                stroke="#757575"
                                strokeWidth="3"
                                strokeLinecap="round"
                              />
                              <path
                                d="M13 30H42.5C44.9853 30 47 27.9853 47 25.5V24.8839C47 22.7389 45.2611 21 43.1161 21V21C40.971 21 39.2321 22.7389 39.2321 24.8839V25"
                                stroke="#757575"
                                strokeWidth="3"
                                strokeLinecap="round"
                              />
                              <path
                                d="M17 25H31.5C33.9853 25 36 22.9853 36 20.5V19.8839C36 17.7389 34.2611 16 32.1161 16V16C29.971 16 28.2321 17.7389 28.2321 19.8839V20"
                                stroke="#757575"
                                strokeWidth="3"
                                strokeLinecap="round"
                              />
                            </svg>
                            <div className="flex flex-col text-center gap-[5px]">
                              <p className="text-[18px] leading-[23px] font-semibold text-[#26282C]">
                                {anxyToolData.find((element) => element.toolId === tool.toolId).title}
                              </p>
                              <p className="text-[15px] leading-[21px] text-[#80838B]">
                                {tool.completedAt ? `${getTimeDifference(tool.completedAt)}` : '시작하기'}
                              </p>
                            </div>
                          </div>
                        }
                        action={() => {
                          if (tool.toolId === 'tool_aa') goToMeditation();
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className="flex items-center gap-[5px] " onClick={goManagementTool}>
                <p className="text-[17px] leading-[23px]  text-[#6B6D76]">불안 관리 도구에는 무엇이 있나요?</p>

                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.5 11L6.5 6L1.5 1"
                    stroke="#80838B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
