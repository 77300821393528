/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import useFetchAnxy from '../useFetchAnxy';
import userAtom from '../../recoil/anxy/user/atom';

export default function useGetLevel(props) {
  const setUserState = useSetRecoilState(userAtom);
  const [goLevelURL, setGoLevelURL] = useState();

  const [userLevelData, getUserLevel] = useFetchAnxy({
    url: `anxy/user/level`,
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (userLevelData) {
      const userLevel = userLevelData.result;
      setUserState((state) => ({
        ...state,
        level: {
          level: userLevel.level,
          currentExperience: userLevel.currentExperience,
          requiredExperience: userLevel.requiredExperience,
          heartBenefit: userLevel.heartBenefit,
        },
      }));
      if (userLevel.isFirst) {
        console.log('go level guide');
        setGoLevelURL(`/guide/level/0`);
      } else {
        console.log('go level ');
        setGoLevelURL(`/level`);
      }
    }
  }, [userLevelData]);

  useEffect(() => {
    getUserLevel();
  }, []);

  return goLevelURL;
}
