/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import { BottomAction } from '../../stories/page/BottomAction';
import selfCheckData from '../../data/selfCheckData';
import { changeHeader } from '../../utils/webview';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useFetch from '../../hooks/useFetch';

export default function SelfCheckStart(props) {
  let { selfCheckId } = useParams();
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === parseInt(selfCheckId));
  const context = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const navigation = searchParams.get('navigation') || 'close';
  const [selfCheckQuestion, getSelfCheckQuestion] = useFetch({
    url: `/self-check/v2/qc/detail`,
    params: { self_check_id: parseInt(selfCheckId) },
    bg: '#EFF2FF',
  });

  useEffect(() => {
    if (selfCheckQuestion) {
      console.log(selfCheckQuestion.result);
      context.setSelfcheckQuestion(selfCheckQuestion.result);
    }
  }, [selfCheckQuestion]);

  useEffect(() => {
    document.title = `${selfCheckInfo.selfCheckName} 검사 시작`;

    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: navigation });

    getSelfCheckQuestion();
    context.setShowProgressingbar(false);
    context.setSelfcheckAnswer([]);
    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';
  }, []);

  const goNext = useNavigateWithParams(`/mindScan/${selfCheckId}/1`);

  const Element = (
    <div className="w-full h-full bg-[#EFF2FF] overflow-x-hidden overflow-y-auto scrollbar-hide pt-[85px] flex flex-col ">
      <div className="flex flex-col items-center">
        <p className="bg-[#26282C] rounded-[20px] px-[15px] py-[4px] text-[15px] leading-[21px] font-bold text-white mb-[15px]">
          {`${selfCheckInfo.examName} (${selfCheckInfo.numberOfQuestion}문항)`}
        </p>
        <p className=" text-[24px] leading-[30px] font-bold text-[#26282C] mb-[10px]">{selfCheckInfo.selfCheckName}</p>
        <p className=" text-[17px] leading-[23px] font-normal text-[#6B6D76] ">{selfCheckInfo.guide}</p>
      </div>
      <div className="flex-1  pt-[30px] flex flex-col justify-center">
        <img src={selfCheckInfo.image} alt="empty" className=" w-full translate-y-[-30px]" />
      </div>
    </div>
  );
  return (
    <BottomAction
      content={Element}
      buttonText={'시작하기'}
      buttonState={'ACTIVE'}
      action={goNext}
      bgColor={'#EFF2FF'}
    />
  );
}
