/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import DonutChart from 'react-donut-chart';
import emoji_unstable from '../../image/emoji_unstable.png';
import emoji_tired from '../../image/emoji_tired.png';
import emoji_soso from '../../image/emoji_soso.png';
import emoji_good from '../../image/emoji_good.png';
import { AnxyActionButton } from '../../stories/button/ActionButton';
import { arraySum } from '../../logic/method';

export default function OwlResult(props) {
  const { types } = props;
  const [searchParams] = useSearchParams();
  const score = searchParams.get('score');
  const type = searchParams
    .get('type')
    .split(',')
    .map((element) => parseInt(element));

  useEffect(() => {
    document.title = `somny 결과`;
    const root = document.getElementById('root');
    root.style.position = 'relative';
    root.style.overflow = 'auto';
  }, []);

  const title1 = '불면증 때문에 제법 고생하고 계신 것 같아요';
  const title2 = '뒤척이는 밤을 보낼 때도 있겠군요';
  const title3 = '잠자리가 편안한 상태인 것 같네요';
  const text1 =
    '누워도 쉽게 잠들지 못하고, 자다가 잠에서 깨는 날도 많나요? 고생이 많겠어요. 불면증의 원인은 다양하답니다. 스트레스나 환경의 변화로 인해 근심거리가 생겨 잠을 뒤척일 수도 있어요. 잘못된 생활습관으로 인해 생활리듬이 바뀌어버렸을 수도 있고요. 하지만 별다른 원인이 없고 잘 수 있는 적절한 시간과 기회가 주어지는데도 불구하고 수면에 문제가 생겼나요? 그렇다면 그건 불면증이라고 이야기할 수 있답니다. 불면증이 생기면 자고 일어나도 개운하지 않고 하루종일 피곤하죠. 그리고 잠자리에 눕게 되면 ‘이렇게 피곤한데 오늘은 잘 자야해’와 같은 생각을 하며 걱정을 하고, 결국엔 또 걱정 때문에 잠을 뒤척이며 악순환에 빠져요. 불면증이 장기화될 가능성이 있다면 약물 사용하기도 하지만, 일반적인 경우에는 약물 없이도 개선이 가능하답니다. 조금만  노력하면 지금보다 더 푹 자고 개운하게 일어날 수 있을 거예요.';
  const text2 =
    '아침에 일어나면 개운하지 않나요? 밤에 종종 잠에 못 드는 날도 있나요? 아마 가벼운 불면증이 있는 것 같아요. 불면증은 여러 요인으로 인해 발생할 수 있지만, 가벼운 형태의 불면증은 주로 수면 습관과 관련된 경우가 많답니다. 하지만 그렇게 심각한 정도는 아니에요. 조금만 노력하면 지금보다 더 푹 자고 개운하게 일어날 수 있답니다. ';
  const text3 =
    '불면증에 해당할 가능성은 낮아보여요. 혹시나 검사 결과와 무관하게 자고 일어나도 개운하지 않고 피곤하다면, 수면을 방해하는 다른 요인이 있진 않은지 살펴봐야 해요. 불면증은 아니더라도 잘못된 수면 습관 때문에 수면이 방해 받고 있을 수 있으니까요. 잘못된 수면 습관만 교정하여도 지금보다 더 푹 자고 개운하게 일어날 수 있답니다.';
  const resultData = [
    {
      image: emoji_unstable,
      title: '이런, 불면증이\n심각해요',
      firstAnalysis: { title: title1, text: text1 },
    },
    {
      image: emoji_tired,
      title: '중간 정도의 불면증을\n가지고 있어요',
      firstAnalysis: { title: title1, text: text1 },
    },
    {
      image: emoji_soso,
      title: '가벼운 불면증이\n있는 것 같아요',
      firstAnalysis: { title: title2, text: text2 },
    },
    {
      image: emoji_good,
      title: '잠은 잘 주무시고 계시네요',
      firstAnalysis: { title: title3, text: text3 },
    },
  ];

  function getStep(score) {
    return score >= 22 ? 0 : score >= 15 ? 1 : score >= 8 ? 2 : 3;
  }

  const step = getStep(score);

  const donutColor = ['#2973E3', '#2C29E3', '#232798', '#101364'];

  return (
    <div className={`w-screen h-full overflow-h-scroll px-[20px] pt-[24px]`}>
      <div className="w-full h-[300px] bg-[#EFF2FF] rounded-[20px] mb-[60px] flex flex-col gap-[30px] justify-center items-center text-center">
        <img src={resultData[step].image} alt={'empty'} className="w-[80px] h-[80px]" />
        <p className="text-[24px] leading-[30px] font-bold">{resultData[step].title}</p>
      </div>
      <div className="flex flex-col gap-[25px] mb-[60px] ">
        <p className="text-[17px] leading-[23px] font-bold text-[#26282C]">나에게서 발견된 수면 문제</p>
        <div className="flex gap-[20px] items-center ">
          <div className="flex-1 relative">
            <div className="absolute w-[120px] h-[120px] flex justify-center items-center text-[17px] leading-[23px] font-bold">
              {arraySum(type)}개
            </div>
            <DonutChart
              className="rotate-[-90deg]"
              width={120}
              height={120}
              colors={donutColor}
              strokeColor={'#00000000'}
              innerRadius={0.55}
              outerRadius={0.9}
              legend={false}
              interactive={false}
              formatValues={''}
              emptyOffset={0}
              data={[
                {
                  label: '',
                  value: (type[0] / arraySum(type)) * 100,
                  isEmpty: type[0] === 0,
                },
                {
                  label: '',
                  value: (type[1] / arraySum(type)) * 100,
                  isEmpty: type[1] === 0,
                },
                {
                  label: '',
                  value: (type[2] / arraySum(type)) * 100,
                  isEmpty: type[2] === 0,
                },
                {
                  label: '',
                  value: (type[3] / arraySum(type)) * 100,
                  isEmpty: type[3] === 0,
                },
              ]}
            />
          </div>

          <div className="flex flex-col gap-[8px] ">
            {types.map((each, index) => (
              <div
                className="flex justify-between items-center gap-[8px] text-[14px] leading-[20px]"
                key={`type${index}`}
              >
                <div className="flex gap-[8px] items-center ">
                  <div
                    className={`w-[15px] h-[15px] rounded-[50%] `}
                    style={{
                      backgroundColor:
                        index === 0
                          ? donutColor[0]
                          : index === 1
                          ? donutColor[1]
                          : index === 2
                          ? donutColor[2]
                          : donutColor[3],
                    }}
                  />
                  <p className=" ">{each}</p>
                </div>
                <p className="font-bold">{type[index]}개</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[60px] pb-[110px]">
        {[
          resultData[step].firstAnalysis,
          {
            title: '수면은 관리할 수 있어요',
            text: '잠이 들면 아무것도 할 수 없어요. 하지만 그렇다고 수면을 관리할 수 없는 건 아니랍니다. 우리가 낮시간에 하는 활동들, 저녁 시간에 하는 활동들, 잠자리에서 하는 행동들, 그리고 수면 자체에 대해 가지고 있는 생각들이 수면의 질을 결정지어요. 이러한 것들이 잘 관리되지 않으면 수면의 질이 낮아지고, 그게 더욱 심해지면 불면증이 발생한답니다. 다행히 의학과 심리학에서 많은 연구 끝에 수면을 관리할 수 있는 요소들을 발견했어요. 그러한 요소들만 잘 관리하면 불면증도 잘 극복할 수 있고, 수면의 질 또한 높일 수 있답니다.',
          },
          {
            title: 'somny와 함께라면 오늘 밤도 푹 잠들 거예요',
            text: 'somny는 불면증을 극복하고 수면의 질을 높일 수 있는 다양한 방법들을 제공해요. 규칙적인 수면 관리부터 잠자리에서 들을 수 있는 수면 동화까지 다양한 도구들을 제공한답니다. somny만 잘 따라가면 이 세상 누구보다 더 포근하고 따뜻하게 잠들 수 있을 거예요. ',
          },
        ].map((each, index) => (
          <div className="flex flex-col gap-[10px] text-[17px]">
            <p className="leading-[24px] font-bold">{each.title}</p>
            <p className="leading-[28px] font-light">{each.text}</p>
          </div>
        ))}
      </div>
      <div className="border-t-[1px] border-[#E1E4EB] pt-[20px] text-[14px] leading-[20px] text-[rgba(0,0,0,0.4)]">
        {
          '본 검사는 Orwell Health, Inc에서 연구개발한 것으로 의사의 검수를 거쳤지만 본 검사 결과가 불면증을 진단해 주는 것은 아닙니다. 불면증에 대한 진단은 정신건강의학과 전문의와 상의하시길 바랍니다.'
        }
      </div>

      <div className="w-full pb-[20px] pt-[30px]">
        <AnxyActionButton
          state={'ACTIVE'}
          text={'somny 알아보기'}
          action={() => {
            window.location.href = 'https://orwellhealth.io/somny';
          }}
        />
      </div>
    </div>
  );
}
