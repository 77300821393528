/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import InsideCenterTitle from '../../components/inside/InsideCenterTitle';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { PATH } from '../../routes/paths';
import white1 from '../../image/inside/white1.png';
import white2 from '../../image/inside/white2.png';
import white3 from '../../image/inside/white3.png';
import { TopTitleBottomActionInside } from '../../stories/page/TopTitleBottomActionInside';
import { useEffect } from 'react';
import webviewToast from '../../development/webviewToast';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import useSessionStorage from '../../hooks/useSessionStorage';
import defaultState from '../../data/inside/defaultState';
import useMixpanelEvent from '../../hooks/useMixpanelEvent';
import { useRecoilState, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import progressBarAtom from '../../recoil/common/progressBar/atom';

export default function InsideWhitePreview({ resultList }) {
  const { id } = useParams();

  const setProgressBarState = useSetRecoilState(progressBarAtom);

  const setMixPanel = useMixpanelEvent({
    eventName: 'white_2_start',
  });

  const [isError, setIsError] = useState(true);
  const [buttonText, setButtonText] = useState('다음');
  const [buttonState, setButtonState] = useState('ACTIVE');

  const [insideState, setInsideState] = useSessionStorage('insideState', defaultState);
  const [headerState, setHeaderState] = useRecoilState(headerAtom);

  const filteredAnswerIdList = insideState.answerIdList.map((item) => item.id);
  let filteredState = { ...insideState };
  for (let key of Object.keys(insideState)) {
    if (filteredState[key] === undefined) {
      filteredState[key] = false;
    }
  }

  const [submit, setSubmit] = useFetchAnxy({
    url: '/black/survey/submit',
    requestBody: { ...insideState, answerIdList: filteredAnswerIdList },
  });

  useEffect(() => {
    if (id === '3') {
      setMixPanel();
    }
  }, [id]);

  useEffect(() => {
    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';
  }, []);

  const goNext = useNavigateWithParams(`${PATH.marketing.insideWhiteDefault}/preview/${parseInt(id) + 1}`);
  const goQA = useNavigateWithParams(`${PATH.marketing.insideWhiteDefault}/0`);

  const ImageWithTitle = ({ image, css, title, subtitle }) => {
    return (
      <div className="h-full flex flex-col justify-center items-center px-[20px] py-[20px] py-[40px]">
        <div className={`${css ? css : 'w-full max-w-[375px]'} relative`}>
          <img alt="empty" src={image} className={`${css ? css : 'w-full max-w-[375px]'} object-contain `} />
          <p className="w-full left-[50%] translate-x-[-50%] text-center absolute top-[32px] z-[50] text-white font-regular text-[20px] leading-[24px] ">
            {subtitle}
          </p>
        </div>
        <p className="text-[24px] leading-[30px] font-semibold mt-[32px]">{title}</p>
      </div>
    );
  };

  const contentList = [
    {
      content: (
        <div className="h-full flex items-center justify-center">
          <InsideCenterTitle title={`인사이드로 \n 당신의 잠재력을 깨우세요`} />
        </div>
      ),
    },
    {
      content: (
        <ImageWithTitle
          image={white1}
          title="누구는 좋다하고"
          subtitle={`두 달간의 상담, \n 나를 위한 가장 가치있는 투자였어요.`}
        />
      ),
    },
    {
      content: (
        <ImageWithTitle
          image={white2}
          title="누구는 별로라하고"
          subtitle={`친구에게 고민 털어놓는 것과 \n 뭐가 다르죠?`}
        />
      ),
    },
    {
      title: '상담으로 얼만큼의 효과를 볼 수 있는지 알아볼게요',
      content: (
        <div className="flex flex-col justify-center items-center h-full">
          <img src={white3} alt="empty" className="w-[86px]" />
          <p className="text-[#4E4F53] text-[17px] leading-[23px] pt-[24px] pb-[12px]">나의 예상 상담 효과</p>
          <p className="text-[50px] leading-[60px] font-bold">??점</p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (submit) {
      webviewToast('success');
    }
  }, [submit]);

  const handleSubmit = () => {
    setSubmit();
    setButtonState('LOADING');
    setTimeout(() => {
      setButtonState('ACTIVE');
    }, 2000);
  };

  useEffect(() => {
    if (parseInt(id) === 12) {
      setButtonState('INACTIVE');
    }
    setProgressBarState({
      isProgressBarVisible: false,
    });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#26282C', headerTitle: '' }));
  }, [id]);

  useEffect(() => {
    if (parseInt(id) === 12) {
      if (insideState.mobileNumber && !isError) {
        setButtonState('ACTIVE');
      } else {
        setButtonState('INACTIVE');
      }
      setButtonText('제출하기');
    }
  }, [isError, insideState.mobileNumber]);

  useEffect(() => {
    if (parseInt(id) === 5) {
      if (insideState.answerIdList.length === 0) {
        setButtonState('INACTIVE');
      } else {
        setButtonState('ACTIVE');
      }
    }
  }, [insideState]);
  return (
    <div
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
        // minHeight: '-webkit-fill-available',
      }}
    >
      <TopTitleBottomActionInside
        title={contentList[parseInt(id)].title && contentList[parseInt(id)].title}
        subtitle={contentList[parseInt(id)].subtitle && contentList[parseInt(id)].subtitle}
        content={contentList[parseInt(id)].content}
        buttonText={buttonText}
        buttonState={buttonState}
        action={() => {
          if (id === '3') {
            goQA();
          } else {
            goNext();
          }
        }}
      />
    </div>
  );
}
