/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { hasHomeIndicator, changeHeader } from '../../utils/webview';
import { CoachingContext } from '../../coachingContext';
import { PressedEffect } from '../../stories/button/PressedEffect';
import SkipButton from '../../components/audioPlayer/SkipButton';
import webviewToast from '../../development/webviewToast';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { BUILD_TARGET } from '../../config';
import Script from '../../components/audioPlayer/Script2';
import day14_2_3 from '../../image/course5/day14_2_3.png';

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [ended, setEnded] = useState(false);
  const [backwardDisable, setBackwardDisable] = useState(false);
  const [forwardDisable, setForwardDisable] = useState(false);

  const { id, page } = useParams();
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  const toggle = () => setPlaying((playing) => !playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    console.log(currentTime, duration);
    if (duration === 0) {
      setBackwardDisable(true);
      setForwardDisable(true);
    } else {
      if (currentTime === 0) {
        setBackwardDisable(true);
      } else {
        setBackwardDisable(false);
      }
      if (duration > 0 && currentTime === duration) {
        console.log('다음');
        setForwardDisable(true);
        if (currentTime === duration) {
          setEnded(true);
        }
      } else {
        setForwardDisable(false);
      }
    }
  }, [currentTime, duration]);

  function backward() {
    audio.currentTime -= 15;
    setCurrentTime(parseInt(Math.max(audio.currentTime, 0)));
    if (!playing) {
      audio.play();
      audio.pause();
    }
  }

  function forward() {
    audio.currentTime += 15;
    if (audio.currentTime >= duration) {
      setEnded(true);
    }
    setCurrentTime(parseInt(Math.min(audio.currentTime, audio.duration)));
    if (!playing) {
      audio.play();
      audio.pause();
    }
  }

  useEffect(() => {
    if (ended) {
      setForwardDisable(true);

      goNext();
    }
  }, [ended]);

  useEffect(() => {
    if (audio.duration) {
      setDuration(parseInt(audio.duration));
    }

    audio.addEventListener('durationchange', () => {
      setDuration(parseInt(audio.duration));
    });
    audio.addEventListener('timeupdate', () => {
      setCurrentTime(parseInt(audio.currentTime));
    });
    audio.addEventListener('ended', () => {
      setCurrentTime(parseInt(audio.duration));
      setPlaying(false);
      webviewToast('다음 페이지로');
      setEnded(true);
    });
    return () => {
      audio.removeEventListener('durationchange', () => {
        setDuration(parseInt(audio.duration));
      });
      audio.removeEventListener('timeupdate', () => setCurrentTime(parseInt(audio.currentTime)));
      audio.removeEventListener('ended', () => {
        setCurrentTime(parseInt(audio.duration));
        setPlaying(false);
        webviewToast('다음 페이지로');
        setEnded(true);
      });
    };
  }, []);

  return [playing, toggle, backward, forward, currentTime, duration, backwardDisable, forwardDisable];
};

export default function AudioPlayer() {
  const context = useContext(CoachingContext);
  const scrollRef = useRef();
  const imageContainerRef = useRef();
  const [playing, toggle, backward, forward, currentTime, duration, backwardDisable, forwardDisable] = useAudio(
    // 'https://media.priv-inside.im/sample/over_the_horizon.mp3',
    'https://media.priv-inside.im/course-5/1.mp3',
  );
  const isDev = BUILD_TARGET === 'dev';
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (duration !== 0) {
      setLoaded(true);
    }
  }, [duration]);
  function getTimeStamp(seconds) {
    return `${String(parseInt(seconds / 60)).padStart(2, '0')}:${String(seconds % 60).padStart(2, '0')}`;
  }

  useEffect(() => {
    hasHomeIndicator(context.setBiggerMargin);
    changeHeader({ backgroundColor: '#FFFFFF', buttonType: 'close', color: '#000000' });
  }, []);

  return (
    <div className={`w-screen h-[calc(100vh-17px)] overflow-auto scrollbar-hide snap-mandatory snap-y`} ref={scrollRef}>
      <div className=" snap-start w-screen h-[calc(100vh-17px)] px-[30px] pt-[35px] pb-[50px] flex flex-col items-center justify-between relative">
        <div className="w-full flex-1  " ref={imageContainerRef}>
          <div
            className="mx-auto rounded-[16px] overflow-hidden"
            style={{
              width:
                imageContainerRef.current &&
                Math.min(imageContainerRef.current.offsetHeight, imageContainerRef.current.offsetWidth),
            }}
          >
            <img src={day14_2_3} alt={'empty'} />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-[24px] leading-[30px] font-bold text-[#26282C] mb-[7px] mt-[20px]">긴장 풀기</p>
          <div className="flex items-center gap-[8px] text-[15px] leading-[21px] font-bold mb-[25px] ">
            <p className=" text-[#80838B]"> {getTimeStamp(currentTime)}</p>
            <div className="w-[1px] h-[16px] bg-[#D9D9D9]" />
            <p className="text-[#D1D5DC]">{getTimeStamp(duration)}</p>
          </div>

          <div className="flex items-center gap-[30px] text-[15px] leading-[21px] font-bold mb-[50px]">
            <SkipButton type={'backward'} action={backward} disable={backwardDisable} />
            <div className="w-[54px] h-[54px] ">
              <PressedEffect
                element={
                  <div
                    className={`w-full h-full rounded-[50%]  bg-[#26282C] flex items-center justify-center relative ${
                      !loaded && 'bg-[rgba(0,0,0,0.05)]'
                    }`}
                  >
                    {!loaded ? (
                      <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center">
                        <div className={`flex items-center animate-spin`}>
                          <svg
                            width="23"
                            height="22"
                            viewBox="0 0 23 22"
                            fill="none"
                            stroke={'#FFFFFF'}
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.5 11C21.5 12.8442 20.99 14.6525 20.0264 16.225C19.0628 17.7974 17.6831 19.0728 16.0399 19.9101C14.3967 20.7473 12.5539 21.1139 10.7154 20.9692C8.87687 20.8245 7.11415 20.1742 5.62215 19.0902C4.13014 18.0062 2.96696 16.5307 2.2612 14.8268C1.55545 13.123 1.33462 11.2572 1.62312 9.43566C1.91162 7.61414 2.69821 5.90788 3.89594 4.50552C5.09367 3.10317 6.65587 2.05934 8.40983 1.48944"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      </div>
                    ) : playing ? (
                      <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="4" height="16" rx="1" fill="white" />
                        <rect x="8" width="4" height="16" rx="1" fill="white" />
                      </svg>
                    ) : (
                      <div className="ml-[3px]">
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.5 7.13397C14.1667 7.51887 14.1667 8.48113 13.5 8.86603L1.5 15.7942C0.833334 16.1791 0 15.698 0 14.9282L0 1.0718C0 0.301997 0.833333 -0.179129 1.5 0.205771L13.5 7.13397Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    )}
                    <div
                      className="w-full h-full  absolute"
                      onClick={() => {
                        if (loaded) {
                          console.log('click');
                          toggle();
                        }
                      }}
                    />
                  </div>
                }
              />
            </div>

            <SkipButton type={'forward'} action={forward} disable={forwardDisable} />
          </div>
          <div className="flex items-center text-[15px] leading-[21px] text-[#4E4F53]">
            <p
              className="mr-[6px]"
              onClick={() => {
                console.log('jv');
                scrollRef.current.scrollTo({
                  top: window.innerHeight - 17,
                  behavior: 'smooth',
                });
              }}
            >
              스크립트 보기
            </p>
            <div>
              <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 5.75L5.5 1.25L10 5.75"
                  stroke="#4E4F53"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="snap-start">
        <Script />
      </div>
    </div>
  );
}
