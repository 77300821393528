import { selector } from 'recoil';
import deviceAtom from './atom';

const marginWithHomeIndicator = selector({
  key: 'biggerMargin',
  get: ({ get }) => {
    const hasHomeIndicator = get(deviceAtom).hasHomeIndicator;
    return hasHomeIndicator ? 'pb-[34px]' : 'pb-[20px]';
  },
});

export default marginWithHomeIndicator;
