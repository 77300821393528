import 'moment/locale/ko';
import React, { useEffect } from 'react';
import useMixpanelEventAnxy from '../../../hooks/useMixpanelEventAnxy';
import { useRecoilValue } from 'recoil';
import { AnxyActionButton } from '../../../stories/button/ActionButton';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import userAtom from '../../../recoil/anxy/user/atom';
import { getTimeDifference } from '../../../logic/method';
import { SubTitle18, Caption15 } from '../../../stories/text/AnxyText';

export default function WorryRecordListInModal() {
  // 데이터 로드 여부
  const { worryNoteHistory } = useRecoilValue(userAtom);
  const latestHistory = worryNoteHistory[0];
  const setMixPanel = useMixpanelEventAnxy({
    eventName: '불안 기록하기 상세',
  });

  const goToWorryNote = useNavigateWithParams(`/worryNote`);

  useEffect(() => {
    setMixPanel();
  }, []);

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col gap-[12px]">
        <div className="flex justify-between items-center gap-[10px] w-full rounded-[16px] bg-anxyOat p-[24px]">
          <div className="flex-1 flex flex-col gap-[8px]">
            <SubTitle18 subtitle={latestHistory.reason} className="w-full line-clamp-2 overflow-hidden text-ellipsis" />
            <Caption15 caption={getTimeDifference(latestHistory.submittedAt)} />
          </div>
          <p className="text-[30px] leading-[36px] font-bold">{latestHistory.score}</p>
        </div>
      </div>
      <div className="pt-[20px]">
        <AnxyActionButton text="새로운 불안 기록하기" buttonState="ACTIVE" action={goToWorryNote} />
      </div>
    </div>
  );
}
