import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from '../utils/axios2';

export default function useMixpanelEvent(request) {
  const [searchParams] = useSearchParams();
  let params = request.params ? request.params : {};

  const fetchData = useCallback(async () => {
    try {
      const response = await axios(1, searchParams.get('at'), searchParams.get('app_id'), request.bg, true).get(
        '/app/track/detail',
      );

      params.app_id = searchParams.get('app_id');
      if (response.data.code === 0) {
        const data = response.data;
        if (data.result && data.result.trackId) {
          params.distinct_id = data.result.trackId;
        }
        mixpanel.track(request.eventName, params);
        console.log('DATA', request, data);
      } else {
        mixpanel.track(request.eventName, params);
        console.log(response.data);
      }
    } catch (error) {
      console.error('result', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, request.eventName, params]);

  return fetchData;
}
