import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import sleepingReportSurveyData from '../../../data/sleepingReportSurveyData';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import sleepRecordAtom from '../../../recoil/somny/sleepRecord/atom';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import { DateTime } from 'luxon';
import TimePointerRecord from './TimePointerRecord';
import usePostRecordDone from '../../../hooks/somny/usePostRecordDone';
import { getDiffTime, getNowDateInIOSFormat } from '../../../logic/method';
import 'moment/locale/ko';
import moment from 'moment';
import useMixpanelEventSomny from '../../../hooks/useMixpanelEventSomny';

// start: 48시간 전, end: 현재 시각(분은 내림), selected: 이전 기록

const getTimeDiff = (startTime, endTime) => {
  return moment.duration(moment(endTime).diff(startTime));
};

export default function SleepRecordTemplate() {
  const { id } = useParams();

  const [sleepingRecord, setSleepingRecord] = useRecoilState(sleepRecordAtom);
  const resetRecord = useResetRecoilState(sleepRecordAtom);

  const setMixPanel = useMixpanelEventSomny({
    eventName: '기록 진입',
  });

  const now = new Date();
  const nowDate = DateTime.now()
    .set({ minute: Math.floor(now.getMinutes() / 10) * 10 })
    .toISO();

  const currentData = sleepingReportSurveyData[parseInt(id)];
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [prevTime, setPrevTime] = useState();

  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  const initializeState = () => {
    setProgressBarState({
      isProgressBarVisible: true,
      maxCompleted: 6,
      completed: parseInt(id) + 1,
      paddingColor: '#06062d',
      color: '#79AEFF',
      baseColor: '#F3F5F84d',
    });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#FFFFFF' }));
  };

  useEffect(() => {
    if (parseInt(id) === 0) {
      setMixPanel();
    }
  }, [id]);

  useEffect(() => {
    initializeState();
    if (currentData.type === 'inBedStart') {
      resetRecord();
      // 30시간 전 ~ 현재
      const nHoursBefore = DateTime.fromISO(nowDate).minus({ hours: 30 }).toISO();
      setStartTime(nHoursBefore);
      setSelectedTime(nowDate);
    } else if (currentData.type === 'sleepStart') {
      // inBed Start ~ 현재
      setStartTime(sleepingRecord.inBed.startDateTime);
      setSelectedTime(sleepingRecord.inBed.startDateTime);
    } else if (currentData.type === 'sleepEnd') {
      // sleep Start ~ 현재
      setStartTime(sleepingRecord.sleep.startDateTime);
      setSelectedTime(sleepingRecord.sleep.startDateTime);
    } else if (currentData.type === 'inBedEnd') {
      // sleep End ~ 현재
      setStartTime(sleepingRecord.sleep.endDateTime);
      setSelectedTime(sleepingRecord.sleep.endDateTime);
    } else if (currentData.type === 'struggleTimeMinutes') {
      setStartTime(sleepingRecord.sleep.startDateTime);
      setSelectedTime(sleepingRecord.sleep.startDateTime);
    } else if (currentData.type === 'napTimeMinutes') {
      const nHoursBefore = DateTime.fromISO(nowDate).minus({ hours: 24 }).toISO();

      setStartTime(nHoursBefore);
      setSelectedTime(nHoursBefore);
    }

    if (currentData.type === 'struggleTimeMinutes') {
      setEndTime(sleepingRecord.sleep.endDateTime);
    } else {
      setEndTime(nowDate);
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (sleepingRecord.sleep.startDateTime !== '') {
      setPrevTime(sleepingRecord.sleep.startDateTime);
    }
  }, [sleepingRecord]);

  const goNext = useNavigateWithParams(`/somnyRecord/${parseInt(id) + 1}`);
  const postRecordDone = usePostRecordDone();

  useEffect(() => {
    console.log(getNowDateInIOSFormat());
    if (!sleepingRecord.submittedAt) {
      setSleepingRecord((state) => ({ ...state, submittedAt: getNowDateInIOSFormat() }));
    }
  }, [sleepingRecord]);

  const handleSubmit = () => {
    const updated = { ...sleepingRecord };

    if (currentData.type === 'inBedStart') {
      updated.inBed = { ...updated.inBed, startDateTime: selectedTime };
    } else if (currentData.type === 'sleepStart') {
      updated.sleep = { ...updated.sleep, startDateTime: selectedTime };
    } else if (currentData.type === 'sleepEnd') {
      updated.sleep = { ...updated.sleep, endDateTime: selectedTime };
    } else if (currentData.type === 'inBedEnd') {
      updated.inBed = { ...updated.inBed, endDateTime: selectedTime };
    }

    setSleepingRecord(updated);

    setTimeout(() => {
      if (parseInt(id) === sleepingReportSurveyData.length - 1) {
        postRecordDone();
      } else {
        goNext();
      }
    }, 400);
  };

  console.log(sleepingRecord);
  return (
    <TimePointerRecord
      currentData={currentData}
      startTime={startTime}
      endTime={endTime}
      selectedTime={selectedTime}
      setSelectedTime={setSelectedTime}
      handleSubmit={handleSubmit}
      isAreaPicker={currentData.isAreaPicker}
    />
  );
}
