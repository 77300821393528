import React, { useContext, useRef } from 'react';

import { useParams } from 'react-router-dom';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { CoachingContext } from '../../../coachingContext';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import TextWithGradientCircle from './TextWithGradientCircle';
import { Swiper, SwiperSlide } from 'swiper/react'; // basic
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css'; //basic
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Image } from '../Image';
import emagine1 from '../../../image/course5/emagine1.png';
import emagine2 from '../../../image/course5/emagine2.png';
import emagine3 from '../../../image/course5/emagine3.png';

export default function EmagineCard() {
  const context = useContext(CoachingContext);
  const { id, page } = useParams();
  SwiperCore.use([Navigation, Pagination]);

  const steps = [
    { image: emagine1, backgroundColor: '#E3EAF5' },
    { image: emagine2, backgroundColor: '#E3F5EF' },
    { image: emagine3, backgroundColor: '#FFF0E3' },
  ];
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  return (
    <div className="w-screen h-[calc(100vh-17px)] relative">
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="flex flex-col relative">
          <div className="mt-[50px] h-[273px]">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              scrollbar={{ draggable: true }}
              navigation
              pagination
              breakpoints={{
                768: {
                  slidesPerView: 3,
                },
              }}
              // on={

              // }
            >
              {steps.map((step, index) => (
                <SwiperSlide key={index}>
                  <div className="w-full h-[240px]  rounded-[10px] overflow-hidden px-[20px] mb-[20px]">
                    <Image image={step.image} bg={step.backgroundColor} height={215} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="px-[20px]">
            <p
              className="font-bold text-[24px] leading-[30px]  mb-[15px] text-[#26282C]"
              style={{ wordBreak: 'keep-all' }}
            >
              상상하기를 해볼까요?
            </p>
            <span className={`text-[17px] leading-[28px] text-[#3A3C40]`}>
              <span className={`font-light`}>아래 절차대로 떠올려 보고 이를 반복해 봅니다.</span>
              <TextWithGradientCircle
                texts={[
                  { title: '내가 상상할 수 있는 과일 떠올리기' },
                  { title: '색깔, 질감 등을 선명하게 구체화 하기' },
                  { title: '색을 바꿔보기' },
                ]}
                margin="mt-[40px]"
              />
            </span>
          </div>
        </div>
        <div
          className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px] px-[20px] mt-[40px]`}
        >
          <SecondaryActionButton state="ACTIVE" text={'다음'} action={goNext} />
        </div>
      </div>
    </div>
  );
}
