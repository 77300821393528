const ExpressEmotionInfo = () => {
  return (
    <div
      className={`my-[40px] text-[17px] leading-[24px] relative text-black flex rounded-[16px] mx-[25px] px-[20px] pt-[13px] pb-[15px]
      
      `}
      style={{ border: '1px dashed #A4A6B0' }}
    >
      <div>
        <div className="ml-[3px]">
          <span className="font-bold ">1. </span>
          <span className="font-bold">감정에 이름 붙이기</span>
        </div>
        <div>
          <span className="font-bold ">2. </span>
          <span className="font-bold ">신체반응 알아차리기</span>
        </div>
      </div>
    </div>
  );
};

export default ExpressEmotionInfo;
