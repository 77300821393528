import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { CheckLottie } from '../../../stories/animation/CheckLottie';
import { TopTitleBottomActionSomny } from '../../../stories/page/TopTitleBottomActionSomny';

export function SomnySessionDone() {
  const goHome = useNavigateWithParams('/somnyHome');
  return (
    <TopTitleBottomActionSomny
      content={
        <div className="h-full flex flex-col items-center justify-center">
          <CheckLottie containerSize="77px" somny />
          <p className="text-[24px] leading-[29px] font-bold text-white pt-[30px]">뜨개를 확인했어요</p>
        </div>
      }
      action={goHome}
      buttonText="확인"
      buttonState="ACTIVE"
    />
  );
}
