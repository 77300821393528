import somnyCard1 from '../../image/somny/somnyCard1.png';
import blueMessage from '../../image/somny/somny_blue_message.png';

export function SomnyAlarmCard() {
  const MessageCard = ({ title, highlight, subtitle }) => (
    <div className="relative">
      <div className="bg-[#222455] rounded-[20px] w-full relative px-[24px] py-[20px]">
        <div className="text-white">
          <p className="text-white text-[15px] leading-[21px] opacity-[0.6]">{title}</p>
          <p className="text-[20px] leading-[26px] font-bold pt-[4px]">
            <span className="text-[#79AEFF]">{highlight}</span>
            {subtitle}
          </p>
        </div>
      </div>
      <svg
        className="relative left-[50%] translate-x-[-50%]"
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5858 12.6041C11.4259 14.5761 8.57415 14.5761 7.4142 12.6041L-8.30536e-07 1.57169e-06L20 -1.76765e-07L12.5858 12.6041Z"
          fill="#222455"
        />
      </svg>
    </div>
  );

  return (
    <div className="w-screen pt-[20px] px-[20px] mx-auto flex flex-col justify-end gap-[12px] h-full relative">
      <div className="pb-[268px] flex flex-col gap-[10px]">
        <MessageCard title="잠에 들 때" highlight="오늘 밤" subtitle=" 들을 동화를 가져왔어요" />
        <MessageCard title="일어났을 때" highlight="지난 밤" subtitle="은 어땠나요?" />
      </div>
      <div className="absolute bottom-0 left-0 px-[94px] w-full">
        <div className="w-full h-full">
          <img src={somnyCard1} alt="empty" className="w-[160px] mx-auto" />
        </div>
      </div>
    </div>
  );
}
