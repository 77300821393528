import 'moment/locale/ko';
import React, { useEffect, useState } from 'react';
import { getRefresh, goToScreen, scrollEvent, showWebView } from '../../utils/webview';
import ContainerByDevice from '../../components/elements/ContainerByDevice';
import CounselingCard from '../../components/home/CounselingCard';
import { LoadingLottie } from '../../stories/animation/LoadingLottie';
import useFetch from '../../hooks/useFetch';
import moment from 'moment';
import ReservationCard from '../../components/home/ReservationCard';
import Test from '../../development/Test';
import { useSearchParams } from 'react-router-dom';
import AssignedCounselorCard from '../../components/counselor/AssignedCounselorCard';
import UserProfileImage from '../../components/home/UserProfileImage';
import useMixpanelEvent from '../../hooks/useMixpanelEvent';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import { useRef } from 'react';
import CounselorYoutube from '../../components/counselor/CounselorYoutube';

function Home() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [homeData, getHomeData] = useFetch({
    url: '/view/home/detail',
    bg: '#EFF3F8',
  });

  const setMixPanel = useMixpanelEvent({
    eventName: '홈',
  });

  const [counselingHistoryList, setCounselingHistoryList] = useState([]);
  const [userProfileImageUrl, setUserProfileImageUrl] = useState('');
  const [counselorAssigned, setCounselorAssigned] = useState('');
  const [showReservation, setShowReservation] = useState(false);

  const [playing, setPlaying] = useState(false);

  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');

  const isoFormat = 'YYYY-MM-DDTHH:mm:ss';
  const videoRef = useRef(null);

  const getTimeDiff = (newestTime) => {
    const nowDate = moment(new Date()).format(isoFormat);
    console.log('now', nowDate, 'diff', newestTime);
    const timeDiff = moment.duration(moment(newestTime).diff(nowDate));
    return timeDiff.asSeconds();
  };

  useEffect(() => {
    if (homeData) {
      setIsLoaded(true);
      if (homeData.result.userProfileImageUrl) {
        setUserProfileImageUrl(homeData.result.userProfileImageUrl);
      }
      if (homeData.result.counselingHistoryList) {
        const historyList = homeData.result.counselingHistoryList;
        if (historyList.length !== 0) {
          const waitingOrConfirmList = historyList.filter(
            (item) => item.counselingStatus.id === 0 || item.counselingStatus.id === 1,
          );
          const waitingList = historyList.filter((item) => item.counselingStatus.id === 0);
          const confirmList = historyList.filter((item) => item.counselingStatus.id === 1);
          if (waitingOrConfirmList && waitingOrConfirmList.length !== 0) {
            if (waitingList && waitingList.length !== 0) {
              setShowReservation(false);
            } else {
              if (confirmList && confirmList.length !== 0) {
                const firstConfirmItem = confirmList[0];
                const afterCounselingTime = moment(firstConfirmItem.counselingStartDateTime)
                  .add({ minutes: 50 })
                  .format(isoFormat);
                // 확정된 예약이 과거면 보여주지 않음
                if (getTimeDiff(afterCounselingTime) > 0) {
                  setShowReservation(false);
                } else {
                  setShowReservation(true);
                }
              } else {
                setShowReservation(false);
              }
            }
          } else {
            // 예약, 대기건이 없는 경우 보여줌
            setShowReservation(true);
          }
        }
        setCounselingHistoryList(homeData.result.counselingHistoryList);
      }
      if (homeData.result.counselorAssigned) {
        setCounselorAssigned(homeData.result.counselorAssigned);
      }
      console.log(homeData);
    }
  }, [homeData]);

  const refreshVideo = () => {
    // videoRef.current.currentTim
    // setPlaying(true);
  };

  const refreshAction = () => {
    getHomeData();
    refreshVideo();
  };

  const handleScroll = (scrollTop) => {
    scrollEvent(scrollTop);
  };

  useEffect(() => {
    document.title = '홈';
    window.document.body.style.backgroundColor = '#EFF3F8';
    getRefresh(refreshAction);
    getHomeData();
    setMixPanel();
  }, []);

  const goMyPage = () => {
    goToScreen({
      screenName: 'myPage',
    });
  };

  const Profile = () => {
    return (
      <div className="font-bold flex flex-col items-center pt-[20px]">
        <UserProfileImage
          profileImageUrl={userProfileImageUrl}
          nickname={homeData.result.userNickname}
          containerSize={'60px'}
        />
        <p className="text-[20px] leading-[26px] mt-[12px] mb-[8px]">{`${homeData.result.userNickname}님, 안녕하세요`}</p>
        <div
          className="flex pl-[15px] items-center bg-[#26282C] w-[63px] h-[32px] rounded-[100px] text-white"
          onClick={goMyPage}
        >
          <span className="text-[14px] leading-[20px]">MY</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.91665 14.1667L12.0833 10L7.91665 5.83335"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   console.log(videoRef.current);
  // }, [videoRef]);
  // console.log(videoRef.current);

  return isLoaded ? (
    <ContainerByDevice css={`bg-[#EFF3F8]`} handleScroll={handleScroll}>
      <div className={`w-full h-full min-h-screen px-[20px] flex flex-col items-center pt-safe pb-safe relative`}>
        <Profile />
        <div>
          {counselingHistoryList &&
            counselingHistoryList.length === 0 &&
            counselorAssigned !== '' &&
            counselorAssigned.interviewVideoId && (
              <CounselorYoutube videoId={counselorAssigned.interviewVideoId} isHome />
            )}
        </div>
        {counselingHistoryList && counselingHistoryList.length === 0 && counselorAssigned !== '' && (
          <div className="w-full flex-1 flex flex-col justify-center py-[30px] ">
            <AssignedCounselorCard counselor={homeData.result.counselorAssigned} />
          </div>
        )}
        {counselingHistoryList && counselingHistoryList.length !== 0 && counselorAssigned !== '' && (
          <div className="pt-[30px] w-full h-full pb-[20px]">
            {showReservation && (
              <div>
                <ReservationCard
                  data={counselorAssigned}
                  allData={counselingHistoryList}
                  index={counselingHistoryList.length + 1}
                />
              </div>
            )}
            {counselingHistoryList.map((data, index) => (
              <div className={`${counselingHistoryList.length - 1 !== index && 'mb-[10px]'}`}>
                <CounselingCard
                  data={data}
                  allData={counselingHistoryList}
                  index={counselingHistoryList.length - index}
                />
              </div>
            ))}
          </div>
        )}
        <Test
          element={
            <div className="absolute left-[20px] top-[40px] text-[10px]">
              <textarea
                id="textArea"
                onClick={() => {
                  const content = document.getElementById('textArea');
                  content.select();
                  document.execCommand('copy');
                }}
                defaultValue={authToken}
              />
            </div>
          }
        />
      </div>
    </ContainerByDevice>
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <LoadingLottie containerSize="72px" />
    </div>
  );
}

export default Home;
