/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import counselorSearchWizardQ from '../../data/counselorSearchWizardQ';
import webviewToast from '../../development/webviewToast';
import SingleSelectTemplate from '../../components/counselorSearchWizard/SingleSelectTemplate';
import MultipleSelectTemplate from '../../components/counselorSearchWizard/MultipleSelectTemplate';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import Calender from './Calender';
import { motion } from 'framer-motion';
import { CoachingContext } from '../../coachingContext';
import { AuthContext } from '../../context';
import { impactFeedback } from '../../utils/webview';
import { changeHeader } from '../../utils/webview';

export default function Search(props) {
  // const context = useContext(CoachingContext);
  const context = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const { job } = props;
  const [current, setCurrent] = useState(0);
  const [showCalender, setShowCalender] = useState(false);
  const [stopGoPrevious, setStopGoPrevious] = useState(false);
  const [goNext, setGoNext] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [disable, setDisable] = useState(false);

  const scrollRef = useRef();

  const goAnalysis = useNavigateWithParams('/counselorSearchWizard/analysis');
  const goScore = useNavigateWithParams('/counselorSearchWizard/score');
  useEffect(() => {
    scrollRef.current.scrollTop = window.innerHeight;
    changeHeader({ backgroundColor: '#2C4BEC', text: '', color: '#FFFFFF', buttonType: 'back' });
  }, []);

  useEffect(() => {
    if (goBack) {
      setGoBack(false);
    }
  }, [goBack]);

  useEffect(() => {
    console.log('disable', disable);
    if (disable) {
      setTimeout(() => {
        setDisable(false);
      }, 1300);
    }
  }, [disable]);

  useEffect(() => {
    console.log('current', current);
    // if (current === order.length) {
    //   if (
    //     context.counselorSearchWizardChoice.find((element) => element.id === 'crc-7').answer.text === '네, 받고 싶어요'
    //   ) {
    //     console.log('캘린더로가');
    //     setTimeout(() => {
    //       setShowCalender(true);
    //     }, 1200);
    //   } else {
    //     console.log('분석으로가');
    //     setTimeout(() => {
    //       goAnalysis();
    //     }, 1200);
    //   }
    // }
    if (current === order.length) {
      if (context.counselorSearchWizardChoice.find((element) => element.id === 'crc-6')) {
        console.log('분석으로가');
        setTimeout(() => {
          if (searchParams.get('test') === 'true') {
            goScore();
          } else {
            goAnalysis();
          }
        }, 1200);
      }
    }
  }, [current]);

  // const [order, setOrder] = useState(['crc-1', 'crc-2', 'crc-3', 'crc-4', 'crc-5', 'crc-6', 'crc-7']);

  // const [questions, setQuestions] = useState([
  //   counselorSearchWizardQ.find((element) => element.id === 'crc-1').category[job],
  //   counselorSearchWizardQ.find((element) => element.id === 'crc-2').category['가족'],
  //   counselorSearchWizardQ.find((element) => element.id === 'crc-3'),
  //   counselorSearchWizardQ.find((element) => element.id === 'crc-4'),
  //   counselorSearchWizardQ.find((element) => element.id === 'crc-5'),
  //   counselorSearchWizardQ.find((element) => element.id === 'crc-6'),
  //   counselorSearchWizardQ.find((element) => element.id === 'crc-7'),
  // ]);
  const [order, setOrder] = useState(['crc-1', 'crc-2', 'crc-3', 'crc-4', 'crc-5', 'crc-6']);

  const [questions, setQuestions] = useState([
    counselorSearchWizardQ.find((element) => element.id === 'crc-1').category[job],
    counselorSearchWizardQ.find((element) => element.id === 'crc-2').category['가족'],
    counselorSearchWizardQ.find((element) => element.id === 'crc-3'),
    counselorSearchWizardQ.find((element) => element.id === 'crc-4'),
    counselorSearchWizardQ.find((element) => element.id === 'crc-5'),
    counselorSearchWizardQ.find((element) => element.id === 'crc-6'),
  ]);

  useEffect(() => {
    if (current === 0) {
      changeHeader({ backgroundColor: '#2C4BEC', text: '', color: '#FFFFFF', buttonType: 'back' });
      setTimeout(() => {
        // setOrder(['crc-1', 'crc-2', 'crc-3', 'crc-4', 'crc-5', 'crc-6', 'crc-7']);
        // setQuestions([
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-1').category[job],
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-2').category['가족'],
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-3'),
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-4'),
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-5'),
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-6'),
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-7'),
        // ]);
        setOrder(['crc-1', 'crc-2', 'crc-3', 'crc-4', 'crc-5', 'crc-6']);
        setQuestions([
          counselorSearchWizardQ.find((element) => element.id === 'crc-1').category[job],
          counselorSearchWizardQ.find((element) => element.id === 'crc-2').category['가족'],
          counselorSearchWizardQ.find((element) => element.id === 'crc-3'),
          counselorSearchWizardQ.find((element) => element.id === 'crc-4'),
          counselorSearchWizardQ.find((element) => element.id === 'crc-5'),
          counselorSearchWizardQ.find((element) => element.id === 'crc-6'),
        ]);
      }, 500);
    } else {
      changeHeader({ backgroundColor: '#2C4BEC', buttonType: 'none' });
    }
  }, [current]);

  useEffect(() => {
    console.log(context.counselorSearchWizardChoice, current);

    if (context.counselorSearchWizardChoice.find((element) => element.id === 'crc-1')) {
      if (
        ['가족', '연인', '직장', '학교'].includes(
          context.counselorSearchWizardChoice.find((element) => element.id === 'crc-1').answer.text,
        )
      ) {
        // setOrder(['crc-1', 'crc-2', 'crc-3', 'crc-4', 'crc-5', 'crc-6', 'crc-7']);
        // setQuestions([
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-1').category[job],
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-2').category[
        //     context.counselorSearchWizardChoice.find((element) => element.id === 'crc-1').answer.text
        //   ],
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-3'),
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-4'),
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-5'),
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-6'),
        //   counselorSearchWizardQ.find((element) => element.id === 'crc-7'),
        // ]);
        setOrder(['crc-1', 'crc-2', 'crc-3', 'crc-4', 'crc-5', 'crc-6']);
        setQuestions([
          counselorSearchWizardQ.find((element) => element.id === 'crc-1').category[job],
          counselorSearchWizardQ.find((element) => element.id === 'crc-2').category[
            context.counselorSearchWizardChoice.find((element) => element.id === 'crc-1').answer.text
          ],
          counselorSearchWizardQ.find((element) => element.id === 'crc-3'),
          counselorSearchWizardQ.find((element) => element.id === 'crc-4'),
          counselorSearchWizardQ.find((element) => element.id === 'crc-5'),
          counselorSearchWizardQ.find((element) => element.id === 'crc-6'),
        ]);
      } else {
        console.log('skip');
        setOrder((order) => order.filter((element) => element !== 'crc-2'));
        setQuestions((questions) => questions.filter((element) => element.id !== 'crc-2'));
      }
    }
  }, [context.counselorSearchWizardChoice]);

  return (
    <div className={`w-screen h-screen bg-[#2C4BEC] overflow-hidden text-white font-bold relative `}>
      <div
        className="w-full h-screen overflow-auto scrollbar-hide absolute top-0 z-[100] "
        ref={scrollRef}
        onClick={(e) => {
          if (!disable) {
            scrollRef.current.scrollTop = window.innerHeight;

            const optionSelect = document
              .elementsFromPoint(e.clientX, e.clientY)
              .find((element) => element.id === 'option');
            const multipleSelect = document
              .elementsFromPoint(e.clientX, e.clientY)
              .find((element) => element.id === 'goNext');
            const dateSelect = document
              .elementsFromPoint(e.clientX, e.clientY)
              .find((element) => element.id.includes('date'));
            const goAnalysisSelect = document
              .elementsFromPoint(e.clientX, e.clientY)
              .find((element) => element.id === 'goAnalysis');

            if (optionSelect) {
              impactFeedback('light');
              if (questions[current].type === 'select') {
                setDisable(true);
                context.setCounselorSearchWizardChoice([
                  ...context.counselorSearchWizardChoice,
                  {
                    id: order[current],
                    answer: {
                      id: questions[current].options.find((element) => element.text === optionSelect.innerText).id,
                      text: optionSelect.innerText,
                    },
                  },
                ]);

                setTimeout(
                  () => {
                    setCurrent((current) => current + 1);
                  },
                  current === 0 ? 100 : 0,
                );
              } else {
                console.log(
                  'multiselect',
                  optionSelect.innerText,
                  context.counselorSearchWizardChoice,
                  context.counselorSearchWizardChoice.find((element) => element.id === order[current]),
                );
                if (
                  context.counselorSearchWizardChoice.find(
                    (element) => element.id === order[current] && element.answer.text === optionSelect.innerText.trim(),
                  )
                ) {
                  console.log('이미있음');
                  context.setCounselorSearchWizardChoice([
                    ...context.counselorSearchWizardChoice.filter(
                      (element) =>
                        !(element.id === order[current] && element.answer.text === optionSelect.innerText.trim()),
                    ),
                  ]);
                } else {
                  context.setCounselorSearchWizardChoice([
                    ...context.counselorSearchWizardChoice,
                    {
                      id: order[current],
                      answer: {
                        id: questions[current].options.find((element) => element.text === optionSelect.innerText.trim())
                          .id,
                        text: optionSelect.innerText.trim(),
                      },
                    },
                  ]);
                }
              }
            } else if (
              multipleSelect &&
              context.counselorSearchWizardChoice.filter((element) => element.id === order[current]).length > 0
            ) {
              setDisable(true);
              impactFeedback('light');
              setCurrent((current) => current + 1);
            } else if (dateSelect) {
              impactFeedback('light');
              console.log(dateSelect.id.slice(4));

              if (context.counselorSearchWizardChoice.find((element) => element.id === 'date') === undefined) {
                console.log('날짜 첫 선택');
                context.setCounselorSearchWizardChoice([
                  ...context.counselorSearchWizardChoice,
                  { id: 'date', answer: [dateSelect.id.slice(4)] },
                ]);
              } else {
                if (
                  context.counselorSearchWizardChoice
                    .find((element) => element.id === 'date')
                    .answer.includes(dateSelect.id.slice(4))
                ) {
                  context.setCounselorSearchWizardChoice([
                    ...context.counselorSearchWizardChoice.filter((element) => element.id !== 'date'),
                    {
                      id: 'date',
                      answer: context.counselorSearchWizardChoice
                        .find((element) => element.id === 'date')
                        .answer.filter((answer) => answer !== dateSelect.id.slice(4)),
                    },
                  ]);
                } else {
                  context.counselorSearchWizardChoice
                    .find((element) => element.id === 'date')
                    .answer.push(dateSelect.id.slice(4));
                  console.log(
                    '추가',
                    context.counselorSearchWizardChoice.find((element) => element.id === 'date').answer,
                  );
                  context.setCounselorSearchWizardChoice([
                    ...context.counselorSearchWizardChoice.filter((element) => element.id !== 'date'),
                    {
                      id: 'date',
                      answer: context.counselorSearchWizardChoice.find((element) => element.id === 'date').answer,
                    },
                  ]);
                }
              }
            } else if (goAnalysisSelect) {
              if (
                context.counselorSearchWizardChoice.find((element) => element.id === 'date') &&
                context.counselorSearchWizardChoice.find((element) => element.id === 'date').answer.length > 0
              ) {
                setDisable(true);
                impactFeedback('light');
                console.log('분석으로 가');
                setGoNext(true);
                setTimeout(() => {
                  goAnalysis();
                }, 1000);
              }
            }
          }
        }}
        onTouchStart={(e) => {
          setStopGoPrevious(false);
        }}
        onScroll={(e) => {
          // console.log(window.innerHeight - e.target.scrollTop, window.innerHeight / 5);
          if (!disable) {
            if (window.innerHeight - e.target.scrollTop > window.innerHeight / 5) {
              setCurrent((current) => {
                if (current > 0 && !stopGoPrevious) {
                  console.log(
                    '전 질문으로',
                    context.counselorSearchWizardChoice.filter((element) => element.id !== order[current - 1]),
                  );
                  webviewToast('전 질문으로');
                  setGoBack(true);
                  scrollRef.current.scrollTop = window.innerHeight;
                  setShowCalender(false);
                  setStopGoPrevious(true);
                  context.setCounselorSearchWizardChoice(
                    context.counselorSearchWizardChoice.filter(
                      (element) => element.id !== order[current - 1] && element.id !== order[current],
                    ),
                  );

                  return current - 1;
                }
                return current;
              });
            }
          }
        }}
      >
        <div className={`w-full h-[200%]  opacity-30`}></div>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 1, ease: [0.4, 0, 0.2, 1] }}
        className={`w-full h-full pl-[20px] pr-[15px] opacity-0 `}
      >
        {current <= order.length &&
          questions.map((question, index) => (
            <div key={`question${index}`}>
              {question.type === 'select' ? (
                <SingleSelectTemplate
                  current={current}
                  question={question}
                  selected={context.counselorSearchWizardChoice}
                  order={order}
                  goBack={goBack}
                />
              ) : (
                <MultipleSelectTemplate
                  current={current}
                  question={question}
                  selected={context.counselorSearchWizardChoice}
                  order={order}
                  goBack={goBack}
                />
              )}
            </div>
          ))}

        <motion.div animate={{ opacity: showCalender ? 1 : 0 }} transititon={{}}>
          <Calender
            showCalender={showCalender}
            selectedDate={
              context.counselorSearchWizardChoice.find((element) => element.id === 'date')
                ? context.counselorSearchWizardChoice
                    .find((element) => element.id === 'date')
                    .answer.map((element) => new Date(element).getDate())
                : []
            }
            goNext={goNext}
          />
        </motion.div>
      </motion.div>
    </div>
  );
}
