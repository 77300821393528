/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import { useParams, useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import headerAtom, { BUTTON_CLOSE } from 'recoil/common/header/atom';
import { FullPageAnxy } from 'stories/page/FullPageAnxy';
import anxySessionPageData from 'data/anxy/anxySessionPageData';
import { SubTitle18 } from 'stories/text/AnxyText';
import { marginWithHomeIndicator } from 'recoil/common/device';
import hexOpacity from 'hex-opacity';
import { motion } from 'framer-motion';
import usePostSessionDone from 'hooks/usePostSessionDone';
import useSaveViewedPage from 'hooks/useSaveViewedPage';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import AnxySession from '../AnxySession';
import useMixpanelEventAnxy from 'hooks/useMixpanelEventAnxy';
import anxySessionData from 'data/anxy/anxySessionData';
import sessionAtom from 'recoil/anxy/session/atom';
import useLoadImage from 'hooks/useLoadImage';

export default function SessionTemplate(props) {
  let { sessionId, page } = useParams();
  page = parseInt(page);

  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);

  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const currentSessionName = anxySessionData.find((element) => element.sectionId === sessionId).eventProperty;
  const currentSession = anxySessionPageData.find((element) => element.sessionId === sessionId);
  const currentPage = currentSession.contents && currentSession.contents[page];

  const saveViewedPage = useSaveViewedPage({
    isHome: currentSession.contents && page < currentSession.contents.length - 1 ? false : true,
    noneAction: true,
  });

  const setMixPanel_start = useMixpanelEventAnxy({
    eventName: '세션 진행 시작',
    params: {
      세션: currentSessionName,
    },
  });

  const setHeaderState = useSetRecoilState(headerAtom);
  const [sessionState, setSessionState] = useRecoilState(sessionAtom);

  const goBack = () => {
    navigate(-1);
  };
  const goNext = useNavigateWithParams(`/anxy/${sessionId}/${page + 1}`);
  const postDoneAndGoToHome = usePostSessionDone({ sessionId });

  const sessionImageList = Array.from({ length: currentSession.contents && currentSession.contents.length }, (v, i) =>
    require(`../../../image/anxy/session/${sessionId}/${sessionId}_${i}.png`),
  );

  const preloadImage = useLoadImage(sessionImageList);

  useEffect(() => {
    setSessionState((state) => ({ ...state, scrollToSession: sessionId }));
    if (currentSession.contents) {
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_CLOSE,
        progressBar: { maxCompleted: currentSession.contents.length, completed: page + 1 },
      }));
      if (page === 0) {
        setMixPanel_start();
      }
      if (!sessionState.currentSessionImageList) {
        console.log('____ image load');
        preloadImage();
        setSessionState((state) => ({ ...state, currentSessionImageList: sessionImageList }));
      }
    }
  }, []);

  useEffect(() => {
    if (disable) {
      setDisable(false);
    }
  }, [disable]);

  return sessionId !== 'session_kc' ? (
    <FullPageAnxy
      content={
        <div className={`w-full h-full ${marginClassName}  `}>
          <img
            src={
              sessionState.currentSessionImageList !== undefined
                ? sessionState.currentSessionImageList[page]
                : sessionImageList[page]
            }
            alt={'empty'}
            className="fixed w-screen h-screen object-contain object-bottom "
          />
          <div className="w-full  h-full pt-[12px] px-[20px] pt-[44px] z-[10] relative">
            <SubTitle18
              subtitle={currentPage.title}
              color={
                currentSession.backgroundColor !== anxyColorPalette.yellow
                  ? anxyColorPalette.white
                  : anxyColorPalette.black
              }
              className="text-center w-[240px] mx-auto pt-[12px] "
            />
            <div className="w-full h-full relative">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: page === 0 ? 1 : 0 }}
                transition={{ delay: 3, duration: 0.2 }}
                className={`absolute h-full right-0 w-[50%] rounded-[24px] backdrop-blur-[40px] flex flex-col gap-[16px] items-center justify-center px-[40px] opacity-0`}
                style={{ backgroundColor: hexOpacity.create('#F1EEEB', 0.2) }}
                onClick={() => {
                  if (!disable) {
                    saveViewedPage();
                    if (page === currentSession.contents.length - 1) {
                      setHeaderState((state) => ({
                        ...state,
                        progressBar: { maxCompleted: undefined },
                      }));
                      postDoneAndGoToHome();
                      setSessionState((state) => ({ ...state, currentSessionImageList: undefined }));
                    } else {
                      goNext();
                    }
                    setDisable(true);
                  }
                }}
              >
                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 0.999999L9 8.5L1 16"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <SubTitle18
                  subtitle={'다음으로 넘어가려면 여기를 눌러주세요'}
                  color={'#ffffff'}
                  className="text-center"
                />
              </motion.div>

              <div
                className={`absolute h-full left-0 w-[50%] rounded-[24px] backdrop-blur-[40px] flex flex-col gap-[16px] items-center justify-center px-[40px] opacity-0
                ${page === 0 && 'pointer-events-none'}`}
                style={{ backgroundColor: hexOpacity.create('#F1EEEB', 0.2) }}
                onClick={goBack}
              />
            </div>
          </div>
        </div>
      }
      noHeader
      bgColor={currentPage.backgroundColor || currentSession.backgroundColor}
    />
  ) : (
    <AnxySession />
  );
}
