import React from 'react';
import { useRecoilValue } from 'recoil';
import headerAtom from '../../recoil/common/header/atom';
import Header from '../../stories/else/Header';

export default function AnxyHeader() {
  const headerState = useRecoilValue(headerAtom);

  return (
    <Header
      buttonType={headerState.headerButtonType || 'none'}
      title={headerState.headerTitle}
      color={headerState.headerColor}
      bgColor={headerState.headerBgColor}
      maxCompleted={headerState.progressBar && headerState.progressBar.maxCompleted}
      completed={headerState.progressBar && headerState.progressBar.completed}
      progressBarColor={headerState.progressBar && headerState.progressBar.color}
      progressBarBaseColor={headerState.progressBar && headerState.progressBar.baseColor}
    />
  );
}
