import React, { useEffect } from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from 'recoil/common/header/atom';
import modalAtom, { MODAL_LEAVE } from 'recoil/common/modal/atom';
import myPageAtom from 'recoil/anxy/myPage/atom';
import TextAreaTemplate from 'stories/page/TextAreaTemplate';

export default function UserLeaveAnxy() {
  const setModalState = useSetRecoilState(modalAtom);
  const setHeaderState = useSetRecoilState(headerAtom);
  const [myPageState, setMyPageState] = useRecoilState(myPageAtom);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_BACK, headerTitle: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function updateValue(value) {
    setMyPageState((state) => ({ ...state, leaveReason: value }));
  }

  function action() {
    setModalState({ isModalVisible: true, modalType: MODAL_LEAVE });
  }

  return (
    <TextAreaTemplate
      bgColor={'#ffffff'}
      title={'떠나는 이유를 알려주세요'}
      textareaBgColor={'#FAFBFD'}
      placeholder={'더 나은 anxy를 만드는데 참고할게요'}
      value={myPageState.leaveReason}
      updateValue={updateValue}
      action={action}
    />
  );
}
