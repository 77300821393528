/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';

import { BottomActionAnxy } from '../../../stories/page/BottomActionAnxy';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from '../../../recoil/common/header/atom';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import userAtom from '../../../recoil/anxy/user/atom';
import useMixpanelEventAnxy from '../../../hooks/useMixpanelEventAnxy';
import anxyTypeData from '../../../data/anxyTypeData';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import CircularProgressingAnxy from '../../../stories/page/CircularProgressingAnxy';
import walkthrough1 from '../../../image/anxy/12B/walkthrough1.png';
import walkthrough_box from '../../../image/anxy/12B/walkthrough_box.png';

function Guide({ title, noGradient, content, buttonText, action, justifyEnd }) {
  return (
    <BottomActionAnxy
      content={
        <div className="h-full flex flex-col">
          <div className="pt-[12px] text-[24px] leading-[30px] font-bold px-[20px]">{title}</div>
          <div className={`flex-1 flex flex-col ${justifyEnd ? 'justify-end' : 'justify-center'}`}> {content}</div>
        </div>
      }
      noGradient={noGradient}
      buttonText={buttonText}
      action={action}
      bgColor={'#ffffff'}
    />
  );
}

export default function WalkthroughB(props) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = parseInt(searchParams.get('type'));

  const goNext = useNavigateWithParams(`/walkthroughB/${parseInt(id) + 1}`);
  const goHome = useNavigateWithParams(`/anxyHome`);

  const initializeSelfCheckAndGoHome = () => {
    setSelfCheckState({ qcList: [], selfCheckAnswer: [], selfCheckResult: [] });
    goHome();
  };

  const setHeaderState = useSetRecoilState(headerAtom);
  const setSelfCheckState = useSetRecoilState(selfCheckAtom);
  const setUserState = useSetRecoilState(userAtom);

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '불안 타입 검사 결과',
    params: {
      체크포인트: '어떻게 관리하나요?',
    },
  });

  useEffect(() => {
    if (parseInt(id) < 4) {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
    } else {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
    }

    setUserState((state) => ({ ...state, anxietyTypeId: type }));

    if (id === 'typeInfo2') {
      setMixPanel();
    }
  }, []);

  return parseInt(id) === 0 ? (
    <Guide
      title={'지금부터 3주 동안 Anxy와 불안 관리 여정을 떠나요'}
      noGradient
      justifyEnd
      content={
        <div className="absolute left-[50%] translate-x-[-50%] w-[619px] min-w-[619px] h-fit translate-y-[114px]">
          <img src={walkthrough1} alt={'empty'} className="" />
        </div>
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 1 ? (
    <Guide
      title={'과학과 임상 연구에 기반해 만들어진 코스랍니다'}
      content={
        <div className="px-[20px]">
          <div className="flex flex-col gap-[40px] my-[50px] ">
            {[
              { percentage: 71, effect: '불안 증상의 임상적 개선' },
              { percentage: 57, effect: '전반적인 기분 개선' },
              { percentage: 47, effect: '불면증 개선' },
            ].map((each, index) => (
              <motion.div
                // initial={{ opacity: 0 }}
                // animate={{ opacity: 1 }}
                // transition={{ duration: 0.5, delay: 0.2 + index * 0.7, ease: [0.65, 0, 0.35, 1] }}
                // onAnimationComplete={() => {
                //   // console.log(index);
                //   if (index === 2) {
                //     // console.log('done');
                //   }
                // }}
                className="flex flex-col gap-[8px] items-center "
                key={`effect${index}`}
              >
                <div className="flex text-[50px] text-[#EF670B] font-bold">
                  {/* <CountUp end={each.percentage} delay={0.2 + index * 0.7} duration={0.5} /> */}
                  <p>{each.percentage}%</p>
                </div>
                <div className="text-[17px] leading-[23px] text-[#6B6D76]">{each.effect}</div>
              </motion.div>
            ))}
          </div>
          <div className="border-t-[1px] border-[#E1E4EB] pt-[20px] text-[14px] leading-[20px] text-[rgba(0,0,0,0.4)]">
            {
              'Carl, J. R., Miller, C. B., Henry, A. L., Davis, M. L., Stott, R., Smits, J. A., … & Espie, C. A. (2020). Efficacy of digital cognitive behavioral therapy for moderate-to-severe symptoms of generalized anxiety disorder: A randomized controlled trial. Depression and Anxiety, 37(12), 1168-1178.'
            }
          </div>
        </div>
      }
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 2 ? (
    <Guide
      title={'Session으로 배우고 Exercise로 연습해요'}
      content={
        <div className="w-full px-[20px] h-fit ">
          {[
            ['Session', '동영상 학습'],
            ['Exercise', '연습 활동'],
          ].map((each, index) => (
            <div className="flex flex-col items-center">
              <div className="w-full border-[2px] border-[#EF670B] rounded-[60px] py-[25px]  font-bold">
                <div className="flex flex-col gap-[5px] items-center justify-center">
                  <p className="text-[17px] leading-[23px] text-[#A4A6B0]">{each[0]}</p>
                  <p className="text-[24px] leading-[30px] text-[#4E4F53]">{each[1]}</p>
                </div>
              </div>
              {index === 0 && (
                <div className="my-[20px]">
                  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.92736 12.4888C9.53198 13.0866 8.65453 13.0866 8.25916 12.4888L1.02612 1.55162C0.586472 0.886823 1.0632 1.49697e-06 1.86022 1.42729e-06L16.3263 1.62626e-07C17.1233 9.29486e-08 17.6 0.886821 17.1604 1.55161L9.92736 12.4888Z"
                      fill="#4E4F53"
                    />
                  </svg>
                </div>
              )}
            </div>
          ))}
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : parseInt(id) === 3 ? (
    <Guide
      title={'숨겨져 있는 다섯 가지 불안관리 도구를 획득하세요'}
      content={
        <div className="w-[375px] absolute left-[50%] translate-x-[-50%]">
          <img src={walkthrough_box} alt={'empty'} />
        </div>
      }
      noGradient
      buttonText={'다음'}
      action={goNext}
    />
  ) : (
    <CircularProgressingAnxy
      steps={[`${anxyTypeData.find((data) => data.type === type).name}를 위한 코스 생성 중`]}
      isDone={initializeSelfCheckAndGoHome}
      color={'#EF670B'}
    />
  );
}
