/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export default function Calender(props) {
  const { showCalender, selectedDate, goNext } = props;
  const [current, setCurrent] = useState([]);
  const [next, setNext] = useState([]);
  const today = new Date();
  const month = today.getMonth();

  useEffect(() => {
    // console.log(today.getMonth() + 1);
    // console.log(today.getDay()); //0:일 1:월 2:화 3:수 4:목 5:금 6:토

    const currentMonth = [];
    const nextMonth = [];
    for (let i = 0; i < 28; i++) {
      var result = new Date();
      result.setDate(today.getDate() + i);
      if (result.getMonth() > month) {
        nextMonth.push(new Date(result));
      } else {
        currentMonth.push(new Date(result));
      }
    }

    setCurrent(currentMonth);
    setNext(nextMonth);
  }, []);

  const dateTemplate = (date) => (
    <div
      className="h-[50px] w-full   p-[5px] flex justify-center "
      style={{ gridColumnStart: date.getDay() + 1 }}
      key={`date${date}`}
    >
      <div className="h-full min-w-[31px] w-full  flex items-center relative " id={`date${date}`}>
        <div
          className={`absolute top-[50%] left-[50%]  translate-y-[-50%] translate-x-[-50%] w-[31px] h-[31px] rounded-[31px]  flex items-center justify-center  ${
            selectedDate.includes(date.getDate()) ? 'bg-white text-[#2C4BEC] font-bold' : ''
          } transition-all duration-200`}
        >
          {date.getDate()}
        </div>
      </div>
    </div>
  );

  const monthTemplate = (dates) =>
    dates.length > 0 && (
      <div>
        <div className="h-[34px] grid grid-cols-7 items-center justify-center place-items-center ">
          <div className="font-bold  col-start-5  ">{dates[0].getMonth() + 1}월</div>
        </div>
        <div className="w-full grid grid-cols-7  justify-center justify-items-center place-items-center ">
          {dates.map((date, index) => dateTemplate(date))}
        </div>
      </div>
    );

  return (
    <motion.div
      // animate={{
      //   opacity: goNext ? 0 : 1,
      //   y: goNext ? -(window.innerHeight - 74) : 0,
      // }}
      // transition={{
      //   delay: 0.1,
      //   duration: 0.7,
      //   ease: 'easeInOut',
      // }}
      style={{
        opacity: goNext ? 0 : 1,
        y: goNext ? -(window.innerHeight - 74) : 0,
        transitionProperty: 'all',
        transitionDelay: '0.1s',
        transitionDuration: '0.7s',
        transitionTimingFunction: [0.4, 0, 0.2, 1],
      }}
      className={`w-[calc(100%-40px)] fixed bottom-[49px]  bg-[#2C4BEC]  text-white ${
        showCalender ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >
      {window.innerHeight > 667 && (
        <motion.div
          animate={{ opacity: showCalender ? 1 : 0 }}
          transition={{ duration: 0.5 }}
          className=" left-[20px] text-[24px] leading-[30px] font-bold mb-[30px]"
        >
          {'원하는 날을 모두 선택해주세요'}
        </motion.div>
      )}

      <motion.div
        // initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: showCalender ? 1 : 0, y: showCalender ? 0 : 10 }}
        transition={{ delay: 0.5, duration: 0.2, ease: 'easeInOut' }}
      >
        <div
          className={`w-full max-w-[380px] mx-auto px-[10px] py-[15px] text-[15px] leading-[18px]  bg-[rgba(0,0,0,0.2)] rounded-[16px] mb-[30px] font-normal`}
        >
          <div className="h-[34px]  grid grid-cols-7 items-center justify-center justify-items-center text-[#E1E4EB] ">
            {['일', '월', '화', '수', '목', '금', '토'].map((day, index) => (
              <div key={`day${index}`}>{day}</div>
            ))}
          </div>
          <div className={`w-full`}>
            {monthTemplate(current)}
            {monthTemplate(next)}
          </div>
        </div>
        <motion.div
          id={`goAnalysis`}
          animate={{
            opacity: selectedDate.length > 0 ? 1 : 0.3,
          }}
          transition={{ delay: 0, duration: 0.2, ease: 'easeInOut' }}
          className={`w-[54px] h-[54px] rounded-[54px] mx-auto bg-white  flex items-center justify-center opacity-[0.3]`}
        >
          <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.75171 17.3087C7.75171 17.861 8.19942 18.3087 8.75171 18.3087C9.30399 18.3087 9.75171 17.861 9.75171 17.3087L7.75171 17.3087ZM9.75171 1C9.75171 0.447715 9.30399 2.41411e-08 8.75171 0C8.19942 -2.41411e-08 7.75171 0.447715 7.75171 1L9.75171 1ZM7.75171 1L7.75171 17.3087L9.75171 17.3087L9.75171 1L7.75171 1Z"
              fill="#2C4BEC"
            />
            <path
              d="M16 11.7517L8.75168 19L1.50336 11.7517"
              stroke="#2C4BEC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </motion.div>{' '}
      </motion.div>
    </motion.div>
  );
}
