import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SecondaryActionButton } from '../../stories/button/ActionButton';
import { EmotionSelectButton } from './EmotionSelectButton';
import emotionButtonData from '../../data/emotionData';
import { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
// import { useNavigateWithParam } from '../../hooks/useWorkbookNavigate';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

const EmotionSelectBox = ({ index, emotion, extraText }) => {
  const { id, page } = useParams();
  const [answer, setAnswer] = useState([]);
  const [buttonState, setButtonState] = useState('INACTIVE');
  const context = useContext(CoachingContext);
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  useEffect(() => {
    // 배열 초기화
    if (context.workbookAnswer.length === 0) {
      const updated = [
        {
          emotion: '기쁨',
          situationList: [],
        },
        {
          emotion: '슬픔',
          situationList: [],
        },
        {
          emotion: '불쾌함',
          situationList: [],
        },
        {
          emotion: '화남',
          situationList: [],
        },
        {
          emotion: '두려움',
          situationList: [],
        },
      ];
      context.setWorkbookAnswer(updated);
    } else {
      const emotionList = context.workbookAnswer
        .filter((item) => item.emotion === emotion)[0]
        .situationList.filter((item) => item.word);
      const selectedEmotionList = [];
      emotionList.forEach((item) => {
        selectedEmotionList.push(item.word);
      });
      setAnswer(() => selectedEmotionList);
    }
  }, [context]);

  useEffect(() => {
    if (answer.length > 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [answer]);

  function select(option) {
    const selected = option;
    console.log(answer, selected);
    if (answer.includes(selected)) {
      let filteredArray = answer.filter((item) => item !== selected);
      setAnswer(filteredArray);
    } else {
      if (answer.length < 3) {
        setAnswer((answer) => [...answer, selected]);
      }
    }
  }

  function isClicked(option) {
    console.log(option);
    if (option !== '') {
      console.log('이미클릭함');
      select(option);
    }
  }

  const goNextPage = () => {
    const mapEmotion = [
      {
        word: answer[0],
        situation: '',
      },
      {
        word: answer[1],
        situation: '',
      },
      {
        word: answer[2],
        situation: '',
      },
    ];
    const updated = context.workbookAnswer.map((item) =>
      item.emotion === emotion ? { ...item, situationList: mapEmotion } : item,
    );
    console.log(updated);
    context.setWorkbookAnswer(updated);
    goNext();
  };

  const buttonText = emotionButtonData.filter((item) => item.emotion === emotion)[0].buttonList;
  return (
    <div className="w-full">
      <div className="mb-[23px]">
        <div
          className="w-full flex justify-center gap-[6px] mt-[40px] flex-wrap"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            justifyContent: 'center',
          }}
        >
          {buttonText.map((option, index) => (
            <div sytle={{}}>
              <EmotionSelectButton
                key={index}
                selected={answer.includes(option)}
                option={option}
                isClicked={isClicked}
                text={option}
                normalCheck={true}
              />
            </div>
          ))}
        </div>
        {extraText && <p className="mt-[40px] text-[17px] leading-[28px] text-[#3A3C40] font-light">{extraText}</p>}
      </div>
      <div className={`w-full rounded-[16px] relative ${context.biggerMargin ? 'bottom-[-6px]' : 'bottom-[-20px]'}`}>
        <SecondaryActionButton state={buttonState} text="다음" action={goNextPage} />
      </div>
    </div>
  );
};

export default EmotionSelectBox;
