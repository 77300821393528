import React, { useState, useEffect, useCallback, useContext } from 'react';
import TodoItem from '../../components/coaching/TodoItem';
import co3 from '../../image/co3.png';
import co4 from '../../image/co4.png';
import co6 from '../../image/co6.png';
import coStar from '../../image/coStar.png';
import TestItem from '../../components/coaching/TestItem';
import 'moment/locale/ko';
import { motion } from 'framer-motion';
// import ProgressingBar from '../components/onboarding/OnboardingProgressingBar';
import {
  changeHeader,
  scrollEvent,
  goToTab,
  getScrollEvent,
  getAlarmPermission,
  requestAppState,
} from '../../utils/webview';
import { useRef } from 'react';
import ContentsCarousel from './ContentsCarousel';
import { showWebView } from '../../utils/webview';
import { CoachingContext } from '../../coachingContext';
import RecommendItem from './RecommendItem';
import CompleteWithCountdown from './CompleteWithCountdown';
import { useSearchParams } from 'react-router-dom';
import ProgramNotStarted from './ProgramNotStarted';
import OnboardingOngoing from './OnboardingOngoing';
import OnboardingNotStarted from './OnboardingNotStarted';
import SelfCheckCarousel from './SelfCheckCarousel';
import ToolCardItem from './ToolCardItem';
import Test from '../../development/Test';

function CoachingInfo({ result, authToken, appId, todoList, toolList }) {
  const {
    checkIn,
    isBannerOn,
    title,
    subTitle,
    isOnboardingNotStartedYet,
    isCourseRecommendShown,
    onboardingCase,
    historyList,
    selfCheckResultList,
  } = result;
  const [isSoon, setIsSoon] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const isDoneList = (todoList && todoList.filter((item) => item.isDone)) || [];
  const isCompleted = todoList && todoList.length === isDoneList.length && todoList.length !== 0;

  const activityList = todoList && todoList.filter((item) => item.activity);
  const doneActivityList = todoList && todoList.filter((item) => item.activity && item.isDone);
  const isActivityDone = isCourseRecommendShown;
  const animateList = (todoList && todoList.filter((item) => !item.isDone || item.isFirstCallAfterDone)) || [];

  const context = useContext(CoachingContext);
  const divRef = useRef();

  const [searchParams] = useSearchParams();
  const programNotStartedYet = searchParams.get('program_not_started');

  const isClosingText = 'closing_soon';
  // 모든 액티비티를 완료했을 때
  const completeFixedHeight = isActivityDone
    ? `${
        102 * (todoList.length - isDoneList.length) + (isCourseRecommendShown ? 102 : 0) === 0
          ? 102
          : 102 * (todoList.length - isDoneList.length) + (isCourseRecommendShown ? 102 : 0)
      }px`
    : isCompleted
    ? '102px'
    : '96px';

  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;

  const iOSMobile = /(iPhone|iPod|iPad)/i.test(navigator.platform);

  useEffect(() => {
    if (isSafari || iOSMobile) {
      setIsIOS(true);
    }

    getScrollEvent(divRef);
    getAlarmPermission();
  }, []);

  const goToCheckIn = useCallback(() => {
    showWebView({ url: `checkin?at=${authToken}`, backgroundColor: '#EFF2FF' });
  }, [authToken]);

  const goToFeed = () => {
    showWebView({ url: `feed` });
    changeHeader({ backgroundColor: 'FFFFFF', text: '스토리' });
  };

  const spring = {
    type: 'spring',
    duration: 0.88,
    tension: 401,
    friction: 59,
  };

  const variants2 = {
    visible: {
      opacity: 1,
      background: '#1B35BA',
    },
    hide: {
      opacity: 0,
    },
    soon: {
      background: '#FF6D2E',
      transition: { duration: 0.3 },
    },
    soonVisible: {
      opacity: 1,
      transition: { duration: 0.3 },
    },
    rotate: {
      opacity: 1,
      rotateY: 180,
      transition: spring,
    },
  };

  const handleIsSoon = useCallback(() => {
    const time = isDoneList.length === 0 ? 580 : 1400;
    const timer = setTimeout(() => {
      setIsSoon(true);
      clearTimeout(timer);
    }, time);
  }, []);

  useEffect(() => {
    if (result) {
      // 프로그램 시작 후
      if (result.checkIn.status !== 'not_yet') {
        console.log(result.todoProgramList);
        if (result.checkIn.status === isClosingText || result.checkIn.isClosingSoon) {
          handleIsSoon();
        } else {
          setIsSoon(false);
        }
      } else {
        // 프로그램 시작 전
        setIsSoon(false);
      }
    }
  }, [result]);

  useEffect(() => {
    if (context.showModal) {
      scrollEvent(1);
    }
  }, [context.showModal]);

  const handleScroll = (e) => {
    if (!context.showModal) {
      if (isIOS) {
        scrollEvent(e.target.scrollTop);
      } else {
        scrollEvent(window.scrollY);
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      if (window !== null) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div
      className={`${
        isIOS
          ? 'w-screen h-full overflow-auto scrollbar-hide bg-half text-[#FFFFFF] pt-safe pb-safe'
          : 'w-screen h-full overflow-auto scrollbar-hide bg-[#3953D9] text-[#FFFFFF]'
      }`}
      style={{
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      }}
      ref={divRef}
      onScroll={(e) => {
        handleScroll(e);
      }}
    >
      <div className={`pt-[25px] pb-[40px] px-20px relative bg-[#3953D9]`}>
        <p className="text-normal text-[17px] leading-[26px]">{subTitle}</p>
        <p className={`text-[24px] mt-[5px] font-bold leading-[29px] ${!todoList ? 'mb-[30px]' : 'mb-[20px]'}`}>
          {title}
        </p>
        <div className={`absolute top-[49px] right-[20px] flex justify-between items-end`}>
          <motion.div
            className={`w-[82px] relative flex justify-between items-center rounded-[50px] ${
              isSoon ? 'bg-[#FF6D2E]' : 'bg-[#1B35BA]'
            } px-[15px] h-[36px]`}
            animate={isSoon ? 'soon' : 'visible'}
            variants={variants2}
            onClick={goToCheckIn}
          >
            <motion.div
              className="absolute left-[15px] h-[20px] w-[20px] bg-white rounded-[100%] flex justify-center items-center opacity-0"
              animate={isSoon ? 'soonVisible' : 'hide'}
              variants={variants2}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="12" viewBox="0 0 4 12" fill="none">
                <path
                  d="M3.01047 7.79319H1.36876L1.07362 0.183594H3.32406L3.01047 7.79319ZM3.52696 10.3019C3.52696 10.6708 3.39784 10.9844 3.13959 11.2426C2.88135 11.5009 2.56776 11.63 2.19884 11.63C1.82992 11.63 1.51633 11.5009 1.25808 11.2426C1.01213 10.9844 0.88916 10.6708 0.88916 10.3019C0.88916 9.93294 1.01828 9.62551 1.27653 9.37956C1.53478 9.12131 1.84221 8.99219 2.19884 8.99219C2.56776 8.99219 2.88135 9.12131 3.13959 9.37956C3.39784 9.62551 3.52696 9.93294 3.52696 10.3019Z"
                  fill="#FF6D2E"
                />
              </svg>
            </motion.div>
            {isSoon ? (
              <motion.img
                className={`h-20px opacity-0`}
                src={coStar}
                alt="empty"
                animate={isSoon ? 'hide' : 'rotate'}
                variants={variants2}
              />
            ) : (
              <motion.img className={`h-20px`} src={coStar} alt="empty" animate={'rotate'} variants={variants2} />
            )}
            <div>
              <p className="inline font-bold text-[20px] leading-[24px] ml-4px">{checkIn.currentCount}</p>
              <p className="inline font-light text-[20px] leading-[24px]">{`/${checkIn.totalCount || 7}`}</p>
            </div>
          </motion.div>
        </div>
        {isBannerOn && (
          <motion.div
            className="h-[0]"
            animate={{ height: todoList && isCompleted ? '80px' : '70px' }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          />
        )}
        {isBannerOn && (
          <div className="absolute top-[115px] left-0 w-full px-[20px]">
            <motion.div
              className={`opacity-0 mb-[10px] flex items-center justify-between bg-[#EFF2FF] rounded-[20px] h-[70px] pl-20px pr-[17.5px]`}
              animate={{ y: [-10, 0], opacity: 1 }}
              transition={{ duration: 0.4, delay: 0.7, ease: 'easeInOut' }}
              onClick={() => goToTab({ tabName: 'counselor' })}
            >
              <div className="flex">
                <div className="bg-qna rounded-[50px] w-[54px] h-[23px] text-[13px] leading-[16px] font-semibold mr-10px">
                  <p className="pt-[3px] px-10px">리워드</p>
                </div>
                <p className="inline font-bold text-[18px] leading-[22px] text-[#26282C]">
                  상담
                  <p className="inline font-bold text-[18px] leading-[22px] text-[#3953D9]"> 3만원 </p>
                  할인권
                </p>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                <path d="M1 9L5 5L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </motion.div>
          </div>
        )}
        {isOnboardingNotStartedYet &&
          onboardingCase &&
          onboardingCase.onboardingCaseId === 1 &&
          programNotStartedYet !== 'planetStyle' && <OnboardingNotStarted onboardingCase={onboardingCase} />}
        {isOnboardingNotStartedYet &&
          onboardingCase &&
          onboardingCase.onboardingCaseId === 2 &&
          programNotStartedYet !== 'planetStyle' && <OnboardingOngoing onboardingCase={onboardingCase} />}
        {isOnboardingNotStartedYet &&
          onboardingCase &&
          onboardingCase.onboardingCaseId === 3 &&
          programNotStartedYet !== 'planetStyle' && <ProgramNotStarted onboardingCase={onboardingCase} />}
        <motion.div
          className={``}
          style={{
            position: 'relative',
            minHeight: animateList.length !== 0 && (isCompleted || isActivityDone) ? completeFixedHeight : 'auto',
          }}
          animate={{
            height: isCompleted && animateList.length !== 0 && !isCourseRecommendShown ? '216px' : 'auto',
          }}
          transition={{ ease: 'easeInOut', duration: 0.3, delay: 1 }}
        >
          {isCompleted && !isCourseRecommendShown && (
            <CompleteWithCountdown animateList={animateList} isCompleted={isCompleted} />
          )}
          {todoList &&
            todoList
              .filter((item) => !item.isDone || item.isFirstCallAfterDone)
              .map((item, index) => (
                <div className="relative">
                  <TodoItem key={item.title} item={item} index={index} />
                </div>
              ))}
          {!isOnboardingNotStartedYet && isCourseRecommendShown && (
            <RecommendItem activityList={activityList} isActivityDone={isActivityDone} />
          )}
        </motion.div>
      </div>
      <div className="w-full bg-white h-[15px]" />
      {selfCheckResultList.length !== 0 && (
        <div className="w-full bg-white">
          <SelfCheckCarousel selfCheckResultList={selfCheckResultList} />
        </div>
      )}
      <ToolCardItem toolList={toolList} isStarted={!isOnboardingNotStartedYet} historyList={historyList} />

      {/* <div className="text-[#000000] bg-[#FFFFFF] pt-30px pb-20px ">
        <p className="mb-[13px] text-[20px] font-semibold leading-6 px-[20px]">마음 도구</p>
        <div
          className="flex overflow-x-auto scroll-smooth scrollbar-hide mt-[20px]"
          style={{ scrollBehavior: 'smooth' }}
        >
          {toolList.map((item, index) => (
            <div className="relative">
              <ToolItem
                key={item.toolId}
                id={index}
                sum={toolList.length}
                toolId={item.toolId}
                name={item.name}
                isLock={item.isLock}
                resource={toolData.find((resource) => item.toolId === resource.toolId)}
                webToAppEvent={item.webToAppEvent}
                isStarted={todoList}
                isFirstUnlocked={item.isFirstUnlocked}
              />
            </div>
          ))}
        </div>
      </div> */}
      {/* <div className="text-[#000000] bg-[#FFFFFF] pt-30px pb-20px px-20px">
        <p className="mb-[13px] text-[20px] font-semibold leading-6">더 많은 활동</p>
        {testItems.map((item) => (
          <TestItem key={item.title} image={item.image} title={item.title} text={item.text} />
        ))}
      </div> */}
      <div className="text-[#000000] bg-white py-40px overflow-hidden">
        <div className="flex items-center justify-between mr-[2px] mb-[13px] px-[20px] ">
          <p className="text-[20px] font-semibold leading-6 ">스토리</p>
          <div
            className="flex justify-center items-center w-[35px] h-[35px] translate-x-[12px]
            "
            onClick={goToFeed}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" fill="none">
              <path
                d="M1 14.5L7 8L0.999999 1.5"
                stroke="#80838B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <ContentsCarousel isScroll={isScroll} />
        <Test
          element={
            <textarea
              id="textArea"
              onClick={() => {
                const content = document.getElementById('textArea');
                content.select();
                document.execCommand('copy');
              }}
              defaultValue={authToken}
            />
          }
        />
      </div>
    </div>
  );
}

export default CoachingInfo;
