/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import headerAtom from '../../../recoil/common/header/atom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import BetterMeStart from './BetterMeStart';
import BetterMeQA from './BetterMeQA';
import BetterMeEnd from './BetterMeEnd';
import BetterMeAnalysis from './BetterMeAnalysis';
import BetterMeResult from './BetterMeResult';

export default function BetterMeTest() {
  let { id } = useParams();
  const [selfCheckState, setSelfCheckState] = useRecoilState(selfCheckAtom);
  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerColor: '#26282C' }));
    if (id === '0') {
      setSelfCheckState((state) => ({ ...state, qcList: qcList }));
    }
  }, []);

  const choices = [
    { selfCheckChoiceId: 1, score: 0.1, choice: '전혀' },
    { selfCheckChoiceId: 2, score: 0.2, choice: '거의 아님' },
    { selfCheckChoiceId: 3, score: 0.3, choice: '가끔' },
    { selfCheckChoiceId: 4, score: 1, choice: '자주' },
    { selfCheckChoiceId: 5, score: 2, choice: '매우 자주' },
    { selfCheckChoiceId: 6, score: 3, choice: '항상' },
  ];

  const types = [
    { id: 1, name: '스파르타 다이어터', qcIdList: [1, 7, 13, 19, 25] },
    { id: 2, name: '완벽주의자', qcIdList: [2, 8, 14, 20, 26] },
    { id: 3, name: '자기 팩폭러', qcIdList: [3, 9, 15, 21, 27] },
    { id: 4, name: '지적인 이성주의자', qcIdList: [4, 10, 16, 22, 28] },
    { id: 5, name: '스마일 천사', qcIdList: [5, 11, 17, 23, 29] },
    { id: 6, name: '돌보는 자아', qcIdList: [6, 12, 18, 24, 30] },
  ];
  const qcList = [
    {
      selfCheckQuestionId: 1,
      question: '살이 찌는 것이 두렵다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 2,
      question: '배가 고파도 식사를 하지 않는다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 3,
      question: '나는 음식에 집착하고 있다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 4,
      question: '억제할 수 없이 폭식을 한 적이 있다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 5,
      question: '음식을 작은 조각으로 나누어 먹는다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 6,
      question: '내가 먹고 있는 음식의 영양분과 열량을 알고 먹는다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 7,
      question: '빵이나 감자 같은 탄수화물이 많은 음식은 특히 피한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 8,
      question: '내가 음식을 많이 먹으면 다른 사람들이 좋아하는 것 같다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 9,
      question: '먹고 난 다음 토한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 10,
      question: '먹고 난 다음 심한 죄책감을 느낀다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 11,
      question: '좀 더 날씬해져야겠다는 생각을 떨쳐 버릴 수가 없다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 12,
      question: '운동을 할 때 운동으로 인해 없어질 열량에 대해 계산하거나 생각한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 13,
      question: '남들이 내가 너무 말랐다고 생각한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 14,
      question: '내가 살이 쪘다는 생각을 떨쳐 버릴 수가 없다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 15,
      question: '식사시간이 다른 사람보다 더 길다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 16,
      question: '설탕이 든 음식은 피한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 17,
      question: '체중조절을 위해 다이어트용 음식을 먹는다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 18,
      question: '음식이 나의 인생을 지배한다는 생각이 든다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 19,
      question: '음식에 대한 자신의 조절능력을 과시한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 20,
      question: '다른 사람들이 나에게 음식을 먹도록 강요하는 것 같이 느껴진다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 21,
      question: '음식에 대해 많은 시간과 에너지를 투자한다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 22,
      question: '단 음식을 먹고 나면 마음이 편치 않다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 23,
      question: '체중을 줄이기 위해 운동이나 다른 것을 하고 있다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 24,
      question: '위가 비어 있는 느낌이 좋다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 25,
      question: '새로운 기름진 음식 먹는 것을 즐긴다.',
      choices: choices,
    },
    {
      selfCheckQuestionId: 26,
      question: '식사 후에 토하고 싶은 충동을 느낀다.',
      choices: choices,
    },
  ];

  return (
    <div className={`w-screen h-full`}>
      {parseInt(id) === 0 && <BetterMeStart />}
      {parseInt(id) > 0 && !isNaN(parseInt(id)) && <BetterMeQA qcList={selfCheckState.qcList} />}
      {id === 'end' && <BetterMeEnd />}
      {id === 'analysis' && <BetterMeAnalysis types={types} />}
      {id === 'result' && <BetterMeResult />}
    </div>
  );
}
