/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import { useSetRecoilState, useRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import EndProcessing from '../../../components/selfCheck/EndProcessing';
import webviewToast from '../../../development/webviewToast';

export default function BetterMeAnalysis(props) {
  const { types } = props;
  const [done, setDone] = useState(false);
  const [type, setType] = useState();

  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const [selfCheckState, setSelfCheckState] = useRecoilState(selfCheckAtom);

  const goResult = useNavigateWithParams(`/betterMeTest/result?type=${type}`, true);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_NONE, headerTitle: '' });
    document.title = `betterMe 자가검사 로딩`;
    setProgressBarState({ isProgressBarVisible: false, maxCompleted: 0, completed: 0 });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
  }, []);

  useEffect(() => {
    if (type) {
      webviewToast('type is' + type);
      goResult();
    }
  }, [type]);

  function getScore() {
    return arraySum(selfCheckState.selfCheckAnswer.map((element) => element.score));
  }

  function arraySum(arr) {
    return arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
  }

  const isDone = () => {
    setDone(true);
  };

  useEffect(() => {
    if (done) {
      const score = getScore();
      if (score >= 27) {
        // 젤 심함
        setType(1);
      } else if (score >= 22) {
        setType(2);
      } else if (score >= 18) {
        setType(3);
      } else {
        setType(4);
      }
    }
  }, [done]);

  return (
    <div className={`w-screen h-screen`}>
      <EndProcessing steps={['답변 분석 중', '테스트 결과 구성 중']} isDone={isDone} color="#EF670B" />
    </div>
  );
}
