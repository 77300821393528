/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { getConvertedDay } from './calculateTime';
import { motion } from 'framer-motion';
import sleepRule1 from '../../image/sleepRule1.png';
import sleepRule2 from '../../image/sleepRule2.png';
import sleepRule3 from '../../image/sleepRule3.png';
import sleepRule4 from '../../image/sleepRule4.png';
import sleepRule5 from '../../image/sleepRule5.png';
import sleepRule6 from '../../image/sleepRule6.png';
import sleepRule7 from '../../image/sleepRule7.png';
import sleepRule8 from '../../image/sleepRule8.png';
import sleepRule9 from '../../image/sleepRule9.png';
import sleepRule10 from '../../image/sleepRule10.png';

export default function SleepRule(props) {
  const { id, today, data, open, isChallenge } = props;

  const [isSeeMore, setSeeMore] = useState(open);
  const [rotate, setRotate] = useState(open ? 1 : 0);
  const [detailRefLoaded, setDetailRefLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const ruleData = [
    {
      id: 1,
      title: '졸릴 때만 침대에 들어가기',
      text: '눈꺼풀이 무거워지고, 눈이 감겨 하품이 나오는 순간에만 침대에 들어가요.',
      image: sleepRule1,
    },
    {
      id: 2,
      title: '15분 이상 잠이 안오면 나오기',
      text: '침실을 빠져나와 TV, 휴대폰을 제외한 독서, 음악 감상 등 자극적이지 않은 활동을 해요.',
      image: sleepRule2,
    },
    {
      id: 3,
      title: '침실에서 잠만 자기',
      text: '침실에서 책을 읽거나 휴대폰 등을 사용해선 안되며, 오로지 잠을 자는데에만 집중해요.',
      image: sleepRule3,
    },
    {
      id: 4,
      title: '카페인 조절하기',
      text: '오전 시간에만 아메리카노 한 잔 이하로 마셔요.',
      image: sleepRule4,
    },
    {
      id: 5,
      title: '알코올 조절하기',
      text: '마시지 않는 것을 권장하나, 피할 수 없는 경우 한 잔 이하로만 마셔요.',
      image: sleepRule5,
    },
    {
      id: 6,
      title: '취침 2시간 전엔 금연하기',
      text: '잠자기 2시간 전에는 흡연을 피해요.',
      image: sleepRule6,
    },
    {
      id: 7,
      title: '최소 20분간 운동하기',
      text: '잠자기 2시간 전을 제외하고, 숨이 차고 심장박동이 빨라지는 운동을 최소 20분간 해요.',
      image: sleepRule7,
    },
    {
      id: 8,
      title: '자기 전 과식하지 않기',
      text: '공복감이 느껴질 때 자극적이지 않은 가벼운 간식을 먹되, 너무 과식하지 않아요.',
      image: sleepRule8,
    },
    {
      id: 9,
      title: '잠들기 좋은 환경 만들기',
      text: '침실을 시원하고 어둡고, 조용하게 유지해요.',
      image: sleepRule9,
    },
    {
      id: 10,
      title: '시계와 스마트폰 멀리하기',
      text: '침실에 있는 시계는 치우고, 스마트폰은 침실 밖이나 손이 닿지 않는 곳에 둬요.',
      image: sleepRule10,
    },
  ];

  const rule = ruleData.find((element) => element.id === id);
  const weeklyData = data.slice(-7);

  const startDate = new Date(weeklyData.length > 0 ? weeklyData[0].date : today);
  const initialLength = weeklyData.length;
  for (let i = 0; i < 7 - initialLength; i++) {
    weeklyData.unshift({ date: new Date(startDate.setDate(startDate.getDate() - 1)) });
  }
  const detailRef = useRef();

  useEffect(() => {
    // console.log(detailRef.current);
    if (detailRef.current) {
      // console.log('로드됨');
      setDetailRefLoaded(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  }, [detailRefLoaded]);

  function seeMore() {
    setRotate((rotate) => rotate + 1);
    setSeeMore((isSeeMore) => !isSeeMore);
  }

  return (
    <div
      className={`overflow-hidden rounded-[16px] w-full bg-white transition-all`}
      style={{
        WebkitTransitionDuration: loaded ? '0.2s' : '0s',
        transitionDuration: loaded ? '0.2s' : '0s',
        height:
          isChallenge && detailRef.current
            ? `${30 + detailRef.current.offsetHeight}px`
            : isSeeMore && detailRef.current
            ? `${90 + detailRef.current.offsetHeight}px`
            : '90px',
      }}
    >
      <div className={`w-full h-full rounded-[16px]  p-[15px]`}>
        {!isChallenge && (
          <div className="pr-[5px] flex items-center" onClick={seeMore}>
            <div className="w-[60px] h-[60px] rounded-[8px]  mr-[15px] overflow-hidden">
              <img src={rule.image} alt={'empty'} />
            </div>
            <p className="flex-1 pr-[5px] text-[17px] leading-[26px] text-[#3A3C40] font-semibold inline whitespace-nowrap text-ellipsis overflow-hidden">
              {rule.title}
            </p>
            <motion.div
              className="flex-none  flex items-center "
              animate={{ rotate: 180 * rotate }}
              transition={{ duration: loaded ? 0.2 : 0 }}
            >
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 1.5L6 6.5L11 1.5"
                  stroke="#26282C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </motion.div>
          </div>
        )}

        <motion.div
          className={`pointer-events-none `}
          animate={{ opacity: isSeeMore ? 1 : 0 }}
          transition={{ duration: loaded ? 0.2 : 0 }}
        >
          <div className={`${isChallenge ? '' : 'pt-[15px]'}`} ref={detailRef}>
            {!isChallenge && <div className="h-[1px] w-full bg-[#F3F5F8]" />}
            <div className="w-full px-[5px] pt-[18px] pb-[20px] flex justify-between">
              {weeklyData.map((each, index) => (
                <div key={`weeklyData${index}`}>
                  <div
                    className={`${isChallenge ? 'w-[21px] h-[21px]' : 'w-[24px] h-[24px]'}  rounded-[24px] ${
                      isChallenge || each.isDone
                        ? 'bg-qna'
                        : each.isDone !== undefined
                        ? 'bg-[#EF3E3E]'
                        : 'bg-[#E1E4EB]'
                    } flex items-center justify-center`}
                  >
                    {isChallenge || each.isDone ? (
                      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.52198 2.76914C1.22455 2.48086 0.749731 2.48828 0.461449 2.78571C0.173168 3.08315 0.180587 3.55796 0.47802 3.84624L1.52198 2.76914ZM4.80952 7L4.28754 7.53855C4.57843 7.82048 5.04062 7.82048 5.3315 7.53855L4.80952 7ZM11.522 1.53855C11.8194 1.25027 11.8268 0.775453 11.5386 0.47802C11.2503 0.180587 10.7755 0.173168 10.478 0.461449L11.522 1.53855ZM0.47802 3.84624L4.28754 7.53855L5.3315 6.46145L1.52198 2.76914L0.47802 3.84624ZM5.3315 7.53855L11.522 1.53855L10.478 0.461449L4.28754 6.46145L5.3315 7.53855Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.96967 7.96967C0.676777 8.26256 0.676777 8.73744 0.96967 9.03033C1.26256 9.32322 1.73744 9.32322 2.03033 9.03033L0.96967 7.96967ZM9.03033 2.03033C9.32322 1.73744 9.32322 1.26256 9.03033 0.96967C8.73744 0.676777 8.26256 0.676777 7.96967 0.96967L9.03033 2.03033ZM2.03033 9.03033L9.03033 2.03033L7.96967 0.96967L0.96967 7.96967L2.03033 9.03033Z"
                          fill="white"
                        />
                        <path
                          d="M9.03033 7.96967C9.32322 8.26256 9.32322 8.73744 9.03033 9.03033C8.73744 9.32322 8.26256 9.32322 7.96967 9.03033L9.03033 7.96967ZM0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L0.96967 2.03033ZM7.96967 9.03033L0.96967 2.03033L2.03033 0.96967L9.03033 7.96967L7.96967 9.03033Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </div>
                  <div
                    className={`pt-[8px]  text-center text-[12px] leading-[18px] ${
                      each.date === weeklyData[weeklyData.length - 1].date
                        ? 'font-bold text-[#26282C]'
                        : 'font-normal text-[#80838B] '
                    }`}
                  >
                    {`${new Date(each.date).getMonth() + 1}.${new Date(each.date).getDate()}`}

                    <br />
                    {getConvertedDay(each.date, ['어제'])}
                  </div>
                </div>
              ))}
            </div>
            {!isChallenge && (
              <div className="p-[15px] bg-[#FAFBFD] rounded-[8px] text-[15px] leading-[21px] text-[#4E4F53]">
                {rule.text}
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
}
