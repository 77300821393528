import React from 'react';
import { PrimaryActionButton } from '../../stories/button/ActionButton';

export function HabitIntro(props) {
  const { content, buttonText, buttonState, action, bgColor } = props;

  return (
    <div
      className={`flex flex-col`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
        backgroundColor: '#131635',
      }}
    >
      <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col justify-between`}>
        <div className="flex-1 px-[20px] pt-[40px] ">{content}</div>
        {buttonText && (
          <div className="px-[20px] flex items-center pb-[60px]" onClick={action}>
            <div
              className="w-fit bg-white rounded-[100px] py-[16px] h-[57px] px-[40px] flex items-center font-bold text-[22px] leading-[33px]
            text-[#131635]
            "
            >
              {buttonText}
            </div>
          </div>
        )}
      </div>
      <div className={`flex-none w-full relative `}>
        <div
          className="w-full h-[40px] absolute top-[-40px]"
          style={{ background: `linear-gradient(to top, ${bgColor ? bgColor : 'white'}, rgba(255,255,255,0))` }}
        />
      </div>
    </div>
  );
}
