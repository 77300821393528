import { useState, useEffect } from 'react';

export default function useSessionStorage(key, initialState) {
  // console.log(window.localStorage.getItem(key));
  const [state, setState] = useState(() =>
    window.localStorage.getItem(key) && window.localStorage.getItem(key) !== 'undefined'
      ? JSON.parse(window.localStorage.getItem(key))
      : initialState,
  );

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
