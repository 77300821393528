import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWithParams from './useNavigateWithParams';
import useFetchAnxy from './useFetchAnxy';

export default function useSaveViewedPage({ isHome, currentTime, noneAction }) {
  const { sessionId, page } = useParams();

  const goToNext = useNavigateWithParams(`../anxy/${sessionId}/${parseInt(page) + 1}`);

  const viewedInfo = {
    sectionId: sessionId,
    sectionPageId: JSON.stringify({
      page: page,
      currentTime: currentTime,
      isDone: false,
    }),
  };

  const doneInfo = {
    sectionId: sessionId,
    sectionPageId: JSON.stringify({
      page: page,
      isDone: true,
    }),
  };

  const [saveViewedPage, setSaveViewedPage] = useFetchAnxy({
    url: '/anxy/chapter/section/page-viewed/save',
    requestBody: viewedInfo,
  });

  const [saveViewedPageDone, setSaveViewedPageDone] = useFetchAnxy({
    url: '/anxy/chapter/section/page-viewed/save',
    requestBody: doneInfo,
  });

  useEffect(() => {
    if (saveViewedPage) {
      handleDoneAction();
    }
  }, [saveViewedPage]);

  const handleDoneAction = () => {
    if (!noneAction) {
      if (isHome) {
        setSaveViewedPageDone();
      } else {
        goToNext();
      }
    }
  };

  const SaveViewedPage = () => {
    if (isHome) {
      console.log('goHOme');
      setSaveViewedPageDone();
    } else {
      setSaveViewedPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return SaveViewedPage;
}
