/* eslint-disable default-case */
import React, { useReducer, createContext } from 'react';

const initialState = {
  //온보딩, 자가검사
  biggerMargin: false,
  showProgressingbar: false,
  maxCompleted: 0,
  completed: 0,

  //온보딩
  nickname: '',
  selfCheckOrder: [8, 4, 7, 9, 6, 3, 1, 2, 5],
  selfcheckQuestion: [],
  selfcheckAnswer: [],
  selfcheckResult: [],
  recommendedCourse: [],
  goal: [],
  coachingStyle: '',
  // presurvey: {
  //   work: '',
  //   position: '일반사원',
  //   job: '',
  //   hasCounselingExperience: false,
  //   hasMedicalTreatmentExperience: false,
  //   subjectOfTreatmentList: [],
  // },
  presurvey: {
    // job: '',
    // lover: '',
    // child: '',
    // mindfulnessActivityList: [],
    // hasCounselingExperience: false,
    // hasMedicalTreatmentExperience: false,
    // subjectOfTreatmentList: [],
  },
  onboardingRefresh: false,
  showOnboardingModal: false,
  completeStepNumber: 0,
  canCloseOnboarding: true,
  counselorSearchWizardChoice: [],
  counselor: {},

  //회원탈퇴
  leaveReason: '',

  //자가검사
  selfCheckScrollPosition: 0,
  selfCheckHomeScrollPosition: 0,
  availableSelfCheck: [],
  qcAnswerList: [],
};

const AuthContext = createContext({
  ...initialState,
});

function authReducer(state, action) {
  switch (action.type) {
    case 'SET_BIGGER_MARGIN':
      return {
        ...state,
        biggerMargin: action.payload,
      };
    case 'SET_SHOW_PROGRESSINGBAR':
      return {
        ...state,
        showProgressingbar: action.payload,
      };
    case 'SET_MAX_COMPLETED':
      return {
        ...state,
        maxCompleted: action.payload,
      };
    case 'SET_COMPLETED':
      return {
        ...state,
        completed: action.payload,
      };

    case 'SET_NICKNAME':
      return {
        ...state,
        nickname: action.payload,
      };

    case 'SET_SELFCHECK_QUESTION':
      return {
        ...state,
        selfcheckQuestion: action.payload,
      };
    case 'SET_SELFCHECK_ANSWER':
      return {
        ...state,
        selfcheckAnswer: action.payload,
      };
    case 'SET_SELFCHECK_RESULT':
      return {
        ...state,
        selfcheckResult: action.payload,
      };

    case 'SET_RECOMMENDED_COURSE':
      return {
        ...state,
        recommendedCourse: action.payload,
      };
    case 'SET_GOAL':
      return {
        ...state,
        goal: action.payload,
      };
    case 'SET_COACHING_STYLE':
      return {
        ...state,
        coachingStyle: action.payload,
      };
    case 'SET_PRESURVEY':
      return {
        ...state,
        presurvey: action.payload,
      };
    case 'SET_ONBOARDING_REFRESH':
      return {
        ...state,
        onboardingRefresh: action.payload,
      };
    case 'SET_SHOW_ONBOARDING_MODAL':
      return {
        ...state,
        showOnboardingModal: action.payload,
      };
    case 'SET_COMPLETE_STEP_NUMBER':
      return {
        ...state,
        completeStepNumber: action.payload,
      };
    case 'SET_CAN_CLOSE_ONBOARDING':
      return {
        ...state,
        canCloseOnboarding: action.payload,
      };

    case 'SET_LEAVE_REASON':
      return {
        ...state,
        leaveReason: action.payload,
      };

    case 'SET_SELFCHECK_SCROLL_POSITION':
      return {
        ...state,
        selfCheckScrollPosition: action.payload,
      };
    case 'SET_SELFCHECK_HOME_SCROLL_POSITION':
      return {
        ...state,
        selfCheckHomeScrollPosition: action.payload,
      };
    case 'SET_AVAILABLE_SELFCHECK':
      return {
        ...state,
        availableSelfCheck: action.payload,
      };
    case 'SET_QC_ANSWER_LIST':
      return {
        ...state,
        qcAnswerList: action.payload,
      };
    case 'SET_COUNSELOR_SEARCH_WIZARD_CHOICE':
      return {
        ...state,
        counselorSearchWizardChoice: action.payload,
      };
    case 'SET_COUNSELOR':
      return {
        ...state,
        counselor: action.payload,
      };
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  function setBiggerMargin(biggerMargin) {
    dispatch({
      type: 'SET_BIGGER_MARGIN',
      payload: biggerMargin,
    });
  }
  function setShowProgressingbar(showProgressingbar) {
    dispatch({
      type: 'SET_SHOW_PROGRESSINGBAR',
      payload: showProgressingbar,
    });
  }
  function setMaxCompleted(maxCompleted) {
    dispatch({
      type: 'SET_MAX_COMPLETED',
      payload: maxCompleted,
    });
  }
  function setCompleted(completed) {
    dispatch({
      type: 'SET_COMPLETED',
      payload: completed,
    });
  }

  function setNickname(nickname) {
    dispatch({
      type: 'SET_NICKNAME',
      payload: nickname,
    });
  }

  function setSelfcheckQuestion(selfcheckQuestion) {
    dispatch({
      type: 'SET_SELFCHECK_QUESTION',
      payload: selfcheckQuestion,
    });
  }
  function setSelfcheckAnswer(selfcheckAnswer) {
    dispatch({
      type: 'SET_SELFCHECK_ANSWER',
      payload: selfcheckAnswer,
    });
  }
  function setSelfcheckResult(selfcheckResult) {
    dispatch({
      type: 'SET_SELFCHECK_RESULT',
      payload: selfcheckResult,
    });
  }

  function setRecommendedCourse(recommendedCourse) {
    dispatch({
      type: 'SET_RECOMMENDED_COURSE',
      payload: recommendedCourse,
    });
  }
  function setGoal(goal) {
    dispatch({
      type: 'SET_GOAL',
      payload: goal,
    });
  }
  function setCoachingStyle(coachingStyle) {
    dispatch({
      type: 'SET_COACHING_STYLE',
      payload: coachingStyle,
    });
  }

  function setPresurvey(presurvey) {
    dispatch({
      type: 'SET_PRESURVEY',
      payload: presurvey,
    });
  }

  function setOnboardingRefresh(onboardingRefresh) {
    dispatch({
      type: 'SET_ONBOARDING_REFRESH',
      payload: onboardingRefresh,
    });
  }
  function setShowOnboardingModal(showOnboardingModal) {
    dispatch({
      type: 'SET_SHOW_ONBOARDING_MODAL',
      payload: showOnboardingModal,
    });
  }
  function setCompleteStepNumber(completeStepNumber) {
    dispatch({
      type: 'SET_COMPLETE_STEP_NUMBER',
      payload: completeStepNumber,
    });
  }
  function setCanCloseOnboarding(canCloseOnboarding) {
    dispatch({
      type: 'SET_CAN_CLOSE_ONBOARDING',
      payload: canCloseOnboarding,
    });
  }
  function setLeaveReason(leaveReason) {
    dispatch({
      type: 'SET_LEAVE_REASON',
      payload: leaveReason,
    });
  }

  function setSelfCheckScrollPosition(selfCheckScrollPosition) {
    dispatch({
      type: 'SET_SELFCHECK_SCROLL_POSITION',
      payload: selfCheckScrollPosition,
    });
  }
  function setSelfCheckHomeScrollPosition(selfCheckHomeScrollPosition) {
    dispatch({
      type: 'SET_SELFCHECK_HOME_SCROLL_POSITION',
      payload: selfCheckHomeScrollPosition,
    });
  }
  function setAvailableSelfCheck(availableSelfCheck) {
    dispatch({
      type: 'SET_AVAILABLE_SELFCHECK',
      payload: availableSelfCheck,
    });
  }
  function setQcAnswerList(qcAnswerList) {
    dispatch({
      type: 'SET_QC_ANSWER_LIST',
      payload: qcAnswerList,
    });
  }
  function setCounselorSearchWizardChoice(counselorSearchWizardChoice) {
    dispatch({
      type: 'SET_COUNSELOR_SEARCH_WIZARD_CHOICE',
      payload: counselorSearchWizardChoice,
    });
  }
  function setCounselor(counselor) {
    dispatch({
      type: 'SET_COUNSELOR',
      payload: counselor,
    });
  }
  return (
    <AuthContext.Provider
      value={{
        ...state,
        setBiggerMargin,
        setShowProgressingbar,
        setMaxCompleted,
        setCompleted,

        setNickname,
        setSelfcheckQuestion,
        setSelfcheckAnswer,
        setSelfcheckResult,
        setRecommendedCourse,
        setGoal,
        setCoachingStyle,
        setPresurvey,
        setOnboardingRefresh,
        setShowOnboardingModal,
        setCompleteStepNumber,
        setCanCloseOnboarding,

        setLeaveReason,

        setSelfCheckScrollPosition,
        setSelfCheckHomeScrollPosition,
        setAvailableSelfCheck,
        setQcAnswerList,
        setCounselorSearchWizardChoice,
        setCounselor,
      }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
