import { useCallback } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import axios from '../../utils/axiosAnxy';
import webviewToast from '../../development/webviewToast';
import { somnyFairyTaleData } from '../../data/somny/somnyFairyTaleData';
import useMixpanelEventSomny from '../useMixpanelEventSomny';
import useNavigateWithParams from '../useNavigateWithParams';
import { getNowDateInIOSFormat } from '../../logic/method';
import { useRecoilState } from 'recoil';
import somnyHomeAtom from '../../recoil/somny/home/atom';
import statusAtom from '../../recoil/somny/status/atom';

export default function usePostTaleDone(action) {
  const { taleId } = useParams();
  const [searchParams] = useSearchParams();

  const [statusState, setStatusState] = useRecoilState(statusAtom);
  const [homeState, setHomeState] = useRecoilState(somnyHomeAtom);
  const goToHome = useNavigateWithParams('/somnyHome');

  const currentData = somnyFairyTaleData.find((item) => item.taleId === taleId);
  const setMixPanel = useMixpanelEventSomny({
    eventName: '동화 완료',
  });

  const handleEnd = () => {
    setMixPanel();
    if (!statusState.isCompleteTodaySleepFairyTale) {
      setHomeState((state) => ({ ...state, somnyWakeUp: true }));
    }
    if (action) {
      action();
    } else {
      goToHome();
    }
  };

  const postSessionDone = useCallback(async () => {
    try {
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          `/somny/sleep-fairy-tale/complete`,
          JSON.stringify({
            sleepFairyTaleId: taleId,
            completedAt: getNowDateInIOSFormat(),
          }),
        )
        .then((response) => {
          webviewToast('tale name: ' + currentData.title);
          handleEnd();
        });
    } catch (error) {
      handleEnd();
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return postSessionDone;
}
