/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Begin from './Begin';
import Search from './Search';
import Analysis from './Analysis';
import useFetch from '../../hooks/useFetch';
import Analysis2 from './Analysis2';
import Analysis3 from './Analysis3';
import Score from './Score';
import AssignCounselorDone from './AssignCounselorDone';
import { AuthContext } from '../../context';
import { changeHeader } from '../../utils/webview';
import webviewToast from '../../development/webviewToast';

export default function CounselorSearchWizard() {
  const context = useContext(AuthContext);
  let { state } = useParams();
  const [searchParams] = useSearchParams();
  const [job, setJob] = useState(null);

  const [useInfo, getUserInfo] = useFetch({
    url: `/user/info/detail`,
    bg: '#FFFFFF',
  });
  const [counselingSurveySubmitResult, counselingSurveySubmit] = useFetch({
    url: `/counseling/survey/submit`,
    requestBody: JSON.stringify({
      job: context.presurvey.job,
      lover: context.presurvey.lover,
      child: context.presurvey.child,
      mindfulnessActivityList: context.presurvey.mindfulnessActivityList,
      hasCounselingExperience: context.presurvey.hasCounselingExperience === '네, 있어요' ? true : false,
      hasMedicalTreatmentExperience: context.presurvey.hasMedicalTreatmentExperience === '네, 있어요' ? true : false,
      subjectOfTreatmentList: context.presurvey.subjectOfTreatmentList,
    }),
    bg: '#FFFFFF',
  });
  useEffect(() => {
    if (counselingSurveySubmitResult.code === 0) {
      getUserInfo();
    }
  }, [counselingSurveySubmitResult]);

  useEffect(() => {
    // if (!state.includes('analysis')) {
    //   console.log(state);
    //   changeHeader({ backgroundColor: '#2C4BEC', text: '', color: '#FFFFFF', buttonType: 'close' });
    // } else {
    //   console.log('x 버튼 지워');
    //   changeHeader({ buttonType: 'none' });
    // }

    if (state === 'begin') {
      document.title = '상담사 찾기 시작';
    } else if (state === 'search') {
      document.title = '상담사 찾기 본체';
    } else if (state === 'analysis') {
      document.title = '상담사 찾기 키워드';
    } else if (state === 'analysis2') {
      document.title = '상담사 찾기 로딩';
    } else if (state === 'analysis3') {
      document.title = '상담사 찾기 완료';
    } else if (state === 'assign') {
      document.title = '상담사 배정';
    }
  }, [state]);

  useEffect(() => {
    // webviewToast('counselorSearchWizard 들어옴');
    if (state === 'search') {
      console.log(context.presurvey);
      if (context.presurvey.lover) {
        counselingSurveySubmit();
      } else {
        if (searchParams.get('test') === 'true') {
          setJob('기본');
        } else {
          getUserInfo();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (useInfo) {
      if (useInfo.result.job) {
        console.log(useInfo.result);
        setJob(useInfo.result.job);
      } else {
        console.log('직업 안내려옴');
        setJob('기본');
      }
    }
  }, [useInfo]);

  return (
    <div className={`w-screen h-screen  bg-[#2C4BEC] `}>
      {state === 'begin' && <Begin />}
      {state === 'search' && job && <Search job={job} />}
      {state === 'analysis' && <Analysis />}
      {state === 'analysis2' && <Analysis2 />}
      {state === 'analysis3' && <Analysis3 />}
      {state === 'score' && <Score />}
      {state === 'done' && <AssignCounselorDone counselor={context.counselor} />}
    </div>
  );
}
