import { useState, useEffect, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'; // basic
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css'; //basic
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SwiperContent from './SwiperContent';
import inside from '../../image/inside.png';
import questionmark from '../../image/questionmark.png';
import checkbox from '../../image/checkbox.png';
import mh21 from '../../image/mh21.png';
import mh20 from '../../image/mh20.png';
import mh19 from '../../image/mh19.png';
import mh18 from '../../image/mh18.png';
import mh17 from '../../image/mh17.png';
// import '../tutorial/SwiperView.css';

export default function ContentsCarousel({ isScroll }) {
  // const steps = ['1', '2', '3', '4', '5'];
  const [isMove, setIsMove] = useState(false);

  const steps = [
    {
      id: 23,
      title: '네가 밉지만 가지 마. 제발 내 곁에 계속 머물러줘.',
      subtitle: '경계성 인격장애를 아시나요?',
      category: '건강한 마음을 위한 지침서',
      image: mh21,
    },

    {
      id: 22,
      title: '나, 올해도 가을 타나 봐....',
      subtitle: '계절성 정동장애, 들어보셨어요?',
      category: '우울증과 조울증의 모든 것',
      image: mh20,
    },
    {
      id: 21,
      title: '정신과 약은 중독되기 쉽나요?',
      subtitle: '정신과 약에 대한 오해와 진실 2',
      category: '정신과 약 백과사전',
      image: mh19,
    },
    {
      id: 20,
      title: '정신과 약은 평생 먹어야 하나요?',
      subtitle: '정신과 약에 대한 오해와 진실 1',
      category: '정신과 바로 알기',
      image: mh18,
    },
    {
      id: 19,
      title: '불면증은 수면제로 치료되지 않아요!',
      subtitle: '잠 잘 자는 방법 6가지',
      category: '불면증 극복 프로젝트',
      image: mh17,
    },
  ];

  useEffect(() => {
    // if (isMove) {
    //   document.body.style.overflow = 'hidden';
    // } else {
    //   document.body.style.overflow = 'unset';
    // }
  }, [isMove]);

  SwiperCore.use([Navigation, Pagination, Autoplay]);
  return (
    <Swiper
      loop
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      spaceBetween={5}
      slidesPerView={1}
      allowTouchMove={true}
      noSwiping={true}
      onTouchStart={() => {
        setIsMove(true);
      }}
      onTouchEnd={() => {
        setIsMove(false);
      }}
      breakpoints={{
        768: {
          slidesPerView: 3,
        },
      }}
    >
      {steps.map((step, index) => (
        <SwiperSlide key={index}>
          <SwiperContent step={step} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
