import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from '../utils/axiosAnxy';
import webviewToast from '../development/webviewToast';
import useNavigateWithParams from './useNavigateWithParams';
import { useSetRecoilState } from 'recoil';
import sessionAtom from '../recoil/anxy/session/atom';
import useMixpanelEventAnxy from './useMixpanelEventAnxy';
import { anxyToolData } from '../data/anxy/home/anxyToolData';

export default function usePostToolDone({ toolId, isNotGoHome }) {
  const [searchParams] = useSearchParams();

  const goToHome = useNavigateWithParams(`/anxyHome`);

  const setSessionState = useSetRecoilState(sessionAtom);

  const toolData = anxyToolData.find((item) => item.toolId === toolId);
  const setMixPanel = useMixpanelEventAnxy({
    eventName: '툴 진행 완료',
    params: {
      툴: toolData ? toolData.title : '',
    },
  });

  const handleDoneAction = () => {
    if (!isNotGoHome) {
      goToHome();
    }
  };

  const postSessionDone = useCallback(async () => {
    try {
      setMixPanel();
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          `/anxy/tool/complete`,
          JSON.stringify({
            toolId: toolId,
          }),
        )
        .then((response) => {
          let sessionName = '';
          sessionName = toolData && toolData.title;
          webviewToast('tool name: ' + sessionName);

          handleDoneAction();

          // 세션 끝내면 카드 이동을 위해 currentSession(보고 있던 마지막 세션) 삭제
          setSessionState((state) => ({ ...state, currentSession: undefined }));
        });
    } catch (error) {
      handleDoneAction();
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return postSessionDone;
}
