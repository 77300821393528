import { motion } from 'framer-motion';
import { PressedEffect } from '../../../stories/button/PressedEffect';
import { useRecoilState } from 'recoil';
import notificationAtom from '../../../recoil/somny/notification/atom';
import { SomnyTimePicker } from './SomnyTimePicker';

export function NotificationCard({ id, propsName, isSelected, setSelected, title, isLock }) {
  const [notificationState, setNotificationState] = useRecoilState(notificationAtom);

  return (
    <PressedEffect
      element={
        <motion.div className="w-full flex justify-between items-center bg-[#222455] rounded-[20px] py-[32px] px-[24px] text-[#26282C]">
          <div
            className="text-[18px] leading-[23px] text-white font-semibold"
            style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }}
          >
            {title}
          </div>
          <motion.div className="">
            {isSelected ? (
              <SomnyTimePicker
                state={notificationState}
                setState={setNotificationState}
                propsName={propsName}
                hour={notificationState[propsName].split(':')[0]}
                minute={notificationState[propsName].split(':')[1]}
                bgColor="#222455"
              />
            ) : (
              <p className="text-[30px] leading-[36px] font-bold text-white">{`${
                notificationState[propsName].split(':')[0]
              }:${notificationState[propsName].split(':')[1]}`}</p>
            )}
          </motion.div>
        </motion.div>
      }
      action={() => setSelected(id)}
      disable={isSelected || isLock}
    />
  );
}
