import { useNavigate, useSearchParams } from 'react-router-dom';

export default function useNavigateWithParams(url, replace) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const navigateWithParams = () => {
    var prefix = url.includes('?');
    let params = '';
    for (var [key, value] of searchParams.entries()) {
      // if (key !== 'type') {
      if (!(url.includes(`?${key}=`) || url.includes(`&${key}=`))) {
        if (prefix) {
          params += `&${key}=${value}`;
        } else {
          params += `?${key}=${value}`;
          prefix = true;
        }
      }
      // }
    }
    if (replace) {
      navigate(`${url}${params}`, { replace: true });
    } else {
      navigate(`${url}${params}`);
    }
  };

  return navigateWithParams;
}
