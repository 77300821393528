import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectButton } from '../../../stories/button/SelectButton';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import useSessionStorage from '../../../hooks/useSessionStorage';
import useFetch from '../../../hooks/useFetch';
export default function SurveyCas({ title, text, buttonText, isFirstPage }) {
  const { id, page } = useParams();
  const [clicked, setClicked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sleepingSurvey, setSleepingSurvey] = useSessionStorage('sleepingSurvey');
  const [casList, setCasList] = useSessionStorage('casList');
  const [isCasFirst, setIsCasFirst] = useSessionStorage('isCasFirst');

  const content = casList.length === 0 ? [] : casList[page - 10];
  const currentPage = content.length === 0 ? 0 : content.id;
  const type = content.length === 0 ? '' : content.type;
  console.log('casList', casList);

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  const goSecond = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 2}`);
  const goThird = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 3}`);

  const [hygieneInfo, getHygieneInfo] = useFetch({
    url: '/sleep/hygiene/cas/load',
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (hygieneInfo) {
      console.log('load');
      setIsLoaded(true);
    }
  }, [hygieneInfo]);

  useEffect(() => {
    setClicked(false);
    if (casList) {
      const casListByKey = Object.keys(casList);
      if (casListByKey.length === 0) {
        goThird();
      }
    }
  }, []);

  console.log(sleepingSurvey);
  function isClicked(option) {
    if (option !== '') {
      console.log('이미클릭함');
      // setDisable(true);
      select(option);
    }
  }

  function isClickedCas(option) {
    console.log('cas', option);
    if (option !== '') {
      console.log('이미클릭함');
      // setDisable(true);
      selectCas(option);
    }
  }
  console.log(sleepingSurvey);

  function select(option) {
    setClicked(true);
    if (sleepingSurvey.ruleList.find((element) => element.ruleId === currentPage)) {
      console.log('find');

      const updated = { ...sleepingSurvey };

      updated.ruleList = [
        ...sleepingSurvey.ruleList.filter((element) => element.ruleId !== currentPage),
        { ruleId: currentPage, isDone: option.isDone },
      ];

      if (sleepingSurvey.casList.find((element) => element.type === type)) {
        updated.casList = [...sleepingSurvey.casList.filter((element) => element.type !== type)];
        console.log('delete', updated);
      }

      setSleepingSurvey(updated);
    } else {
      console.log('not find');

      const updated = { ...sleepingSurvey };
      updated.ruleList = [...updated.ruleList, { ruleId: currentPage, isDone: option.isDone }];

      if (sleepingSurvey.casList.find((element) => element.type === type)) {
        updated.casList = [...sleepingSurvey.casList.filter((element) => element.type !== type)];
        console.log('delete', updated);
      }

      setSleepingSurvey(updated);
    }

    setTimeout(() => {
      console.log(page - 10, casList.length - 1);
      if (isCasFirst) {
        goNext();
      } else {
        if (page - 10 === casList.length - 1) {
          switch (4 - casList.length) {
            case 3:
              goThird();
              break;
            case 2:
              goSecond();
              break;
            case 1:
              goNext();
              break;
            default:
              break;
          }
        } else {
          goNext();
        }
      }
    }, 400);
  }

  function selectCas(option) {
    setClicked(true);
    console.log(sleepingSurvey.casList);
    if (sleepingSurvey.casList.find((element) => element.type === type)) {
      console.log('find', type);
      const updated = { ...sleepingSurvey };

      updated.casList = [
        ...sleepingSurvey.casList.filter((element) => element.type !== type),
        { type: type, notDoing: option.notDoing },
      ];

      if (sleepingSurvey.ruleList.find((element) => element.ruleId === currentPage)) {
        updated.ruleList = [...sleepingSurvey.ruleList.filter((element) => element.ruleId !== currentPage)];
        console.log('delete', updated);
      }

      setSleepingSurvey(updated);
    } else {
      console.log('not find');

      const updated = { ...sleepingSurvey };
      updated.casList = [...updated.casList, { type: type, notDoing: option.notDoing }];

      if (sleepingSurvey.ruleList.find((element) => element.ruleId === currentPage)) {
        updated.ruleList = [...sleepingSurvey.ruleList.filter((element) => element.ruleId !== currentPage)];
        console.log('delete', updated);
      }

      setSleepingSurvey(updated);
    }

    setTimeout(() => {
      goNext();
    }, 400);
  }

  useEffect(() => {
    console.log(sleepingSurvey);
    if (clicked) {
      console.log('clicked');
    }
  }, [sleepingSurvey]);

  const options = [
    { isDone: true, text: '네' },
    { isDone: false, text: '아니요' },
  ];

  console.log('isfirst', content, isCasFirst);

  const optionsWithType = [{ notDoing: true, text: content.length === 0 ? '' : content.buttonText }];
  return (
    <div
      className={`w-full px-20px h-[calc(100vh-17px)] overflow-hidden relative ${
        sleepingSurvey.isLoaded || !isFirstPage ? 'opacity-1' : 'opacity-0'
      } `}
    >
      <div
        className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C]  pt-[50px] "
        style={{ wordBreak: 'keep-all' }}
      >
        {casList.length !== 0 && casList[page - 10].title}
      </div>
      <div className="text-[17px] leading-[28px] font-light text-[#4E4F53] pt-[40px] px-[4px]">
        {casList.length !== 0 && casList[page - 10].text}
      </div>
      <div className="w-[calc(100%-40px)] absolute top-[50%] translate-y-[calc(-50%+20px)]">
        {options.map((option, index) => (
          <SelectButton
            selected={
              sleepingSurvey.ruleList.find((element) => element.ruleId === currentPage) &&
              sleepingSurvey.ruleList.find((element) => element.ruleId === currentPage).isDone === option.isDone
            }
            option={option}
            text={option.text}
            isClicked={isClicked} //버튼 컴포넌트에서 알려줌
            disable={false} // 버튼 컴포넌트로 알려줌
          />
        ))}
        {isCasFirst &&
          optionsWithType.map((option, index) => (
            <SelectButton
              selected={
                sleepingSurvey.casList.find((element) => element.type === type) &&
                sleepingSurvey.casList.find((element) => element.type === type).notDoing === option.notDoing
              }
              option={option}
              text={option.text}
              isClicked={isClickedCas} //버튼 컴포넌트에서 알려줌
              disable={false} // 버튼 컴포넌트로 알려줌
            />
          ))}
      </div>
    </div>
  );
}
