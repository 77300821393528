import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import 'moment/locale/ko';
import Progressbar from 'react-js-progressbar';
import useFetch from '../../../hooks/useFetch';
import { getMinutes, convertMinutesToStringTime } from '../../sleepingReport/calculateTime';
import { changeHeader } from '../../../utils/webview';

export default function SleepEfficiencyResult({ title, text }) {
  const { id, page } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [sleepTime, setSleepTime] = useState(2000);
  const [inBedTime, setInBedTime] = useState(2500);
  const [efficiency, setEfficiency] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const [sleepingData, getSleepingData] = useFetch({
    url: '/sleep/report/detail',
    bg: '#FFFFFF',
  });

  const context = useContext(CoachingContext);
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  useEffect(() => {
    if (sleepingData) {
      setIsLoaded(true);
      const result = sleepingData.result;
      setInBedTime(result.totalInBedMinutes);
      setSleepTime(result.totalSleepMinutes);
      setEfficiency(sleepingData.result.efficiency);
    }
  }, [sleepingData]);

  useEffect(() => {
    // context.setShowProgressingbar(true);
    getSleepingData();
  }, []);

  let percentageList = [];
  useEffect(() => {
    if (efficiency !== 0) {
      const per = parseInt(efficiency / 3);
      percentageList.push(per * 2 - 10 < 0 ? 5 : per * 2 - 10);
      percentageList.push(per * 2);
      percentageList.push(efficiency);
      for (var i = 0; i < percentageList.length; i++) {
        eachStepDone(i);
      }
    }
  }, [efficiency]);

  const duration = 550;

  function eachStepDone(i) {
    setTimeout(() => {
      setPercentage(percentageList[i]);
    }, 500 + i * duration);
  }

  return (
    <div className={`w-full px-20px h-[calc(100vh-17px)]`}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div>
          <div
            className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C]  mt-[50px] "
            style={{ wordBreak: 'keep-all' }}
          >
            나의 수면 효율
          </div>
          <p className="text-[17px] leading-[28px] text-[#4E4F53] mt-[24px] mb-[20px] font-light">
            {`지난 7일간 실제로 잠든 시간 ${
              sleepTime % 60 === 0
                ? `${parseInt(sleepTime / 60)}시간`
                : `${parseInt(sleepTime / 60)}시간 ${sleepTime % 60}분`
            }  / 침대에 머문 시간 ${
              inBedTime % 60 === 0
                ? `${parseInt(sleepTime / 60)}시간`
                : `${parseInt(inBedTime / 60)}시간 ${inBedTime % 60}분`
            }으로 나의 수면 효율은 ${efficiency}% 이에요.`}
          </p>
          <div className="w-full  rounded-[16px] bg-white relative overflow-hidden">
            <div className="px-[20px] pt-[40px]">
              <div className="pl-[4px] pr-[18px] flex justify-between mb-[35px]">
                <div className="flex items-center">
                  <div className="mt-[2px] w-[10px] h-[10px] bg-[#D8DCFF] rounded-[100%]" />
                  <p className="font-[14px] leading-[20px] ml-[6px]">침대에 머문 시간</p>
                </div>
                <div className="flex items-center">
                  <div className="mt-[2px] w-[10px] h-[10px] bg-[#2C4BEC] rounded-[100%]" />
                  <p className="font-[14px] leading-[20px] ml-[6px]">잠든 시간</p>
                </div>
              </div>
              <div className="w-[180px] h-[180px] mx-auto mb-[20px] relative" id="selfCheckEnd">
                <Progressbar
                  input={efficiency}
                  pathWidth={14}
                  pathColor={'#2C4BEC'} // use an array for gradient color.
                  trailWidth={14}
                  trailColor="#D8DCFF" // use a string for solid color.
                  textStyle={{ fontSize: '0px' }} // middle text style
                  pathLinecap={'round'}
                  pathShadow={'none'}
                  animation={{
                    duration: 1000,
                    delay: 0,
                    ease: 'easeInSine',
                  }}
                />
                <div className="absolute top-[50%] translate-y-[calc(-50%)] left-[50%] translate-x-[-50%]  z-40 text-center">
                  <div className="text-[14px] leading-[20px] text-[#A4A6B0] mb-[4px]">{'지난 7일 기준'}</div>
                  <div className={`whitespace-nowrap  text-[30px] leading-[36px] ${'text-[#26282C]'} font-bold `}>
                    {`${efficiency}%`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px]`}>
          <SecondaryActionButton state="ACTIVE" text={'다음'} action={goNext} />
        </div>
      </div>
    </div>
  );
}
