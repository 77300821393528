import { FullPageAnxy } from '../../../stories/page/FullPageAnxy';
import anxyColorPalette from '../../../data/anxy/contents/anxyColorPalette';
import { useLocation, useParams } from 'react-router-dom';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { marginWithHomeIndicator } from '../../../recoil/common/device';
import { useRecoilValue } from 'recoil';
import { useRef, useState } from 'react';
import { BlackRoundActionButton, SessionBlackRoundActionButton } from '../../../stories/button/RoundActionButton';
import { useEffect } from 'react';
import anxySessionByType from '../../../data/anxy/anxySessionByType';
import userAtom from '../../../recoil/anxy/user/atom';
import { AnimatePresence, motion } from 'framer-motion';
import { TextFadeTransition, getTitleDuration } from '../../../stories/transition/TextFadeTransition';
import useInterval from '../../../hooks/useInterval';
import { FocusingLottie } from '../../../stories/animation/FocusingLottie';
import { AnxiousLottie } from '../../../stories/animation/AnxiousLottie';
import webviewToast from '../../../development/webviewToast';

export default function IntroLottieTemplate(props) {
  const { id, page } = useParams();
  const [currentTitleId, setCurrentTitleId] = useState(1);
  const [allowNavigate, setAllowNavigate] = useState(false);

  const [titleHeight, setTitleHeight] = useState(120);
  const {
    title,
    image,
    lottieImage,
    bottomImage,
    bottomCss,
    imageLottie,
    imageCss,
    component,
    isBottom,
    isFirst,
    timeout,
    focusType,
  } = props;

  const userState = useRecoilValue(userAtom);

  const location = useLocation();
  const titleRef = useRef();

  const textTimer = useRef(null);

  const setTextTimer = (action) => {
    textTimer.current = setTimeout(() => {
      setAllowNavigate(true);
    }, getTitleDuration(title[currentTitleId - 1]));
    return () => clearTimeout(textTimer.current);
  };

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  const trialList = anxySessionByType.find((data) => data.type === userState.anxietyTypeId).trialList;

  const isTrialEnd = trialList[id].length === parseInt(page);
  const isSessionEnd = trialList[id].length === parseInt(page) && trialList.length === parseInt(id) + 1;

  const pathname = location.pathname.split('/')[1];

  const goNext = useNavigateWithParams(`../${pathname}/${id}/${parseInt(page) + 1}`);
  const goNextTrial = useNavigateWithParams(`../${pathname}/${parseInt(id) + 1}/${1}`);
  const goHome = useNavigateWithParams(`../anxyHome`);

  const isPhoneImage = image && image.includes('phone');
  const isEndImage = image && image.includes('end');

  const handleNavigate = () => {
    if (!isSessionEnd) {
      if (isTrialEnd) {
        goNextTrial();
      } else if (isSessionEnd) {
        goHome();
      } else {
        goNext();
      }
    }
  };

  useEffect(() => {
    // webviewToast('alo' + allowNavigate);
    if (allowNavigate) {
      handleNavigate();
      setCurrentTitleId(1);
    }
  }, [allowNavigate]);

  useEffect(() => {
    if (title && currentTitleId === title.length) {
      setTextTimer();
    }
    if (currentTitleId === 1) {
      clearTimeout(textTimer.current);
      clearInterval(lottieTimer);
    }
  }, [currentTitleId, title]);

  const handleClick = () => {
    if ((!title || typeof title === 'string' || currentTitleId === title.length) && allowNavigate) {
      setAllowNavigate(false);
      setCurrentTitleId(1);
      handleNavigate();
    }
  };

  useEffect(() => {
    console.log('allowClick', allowNavigate);
  }, [allowNavigate]);

  useEffect(() => {
    console.log('page 바뀜');
    setCurrentTitleId(1);
    setAllowNavigate(false);
    if (titleRef.current) {
      setTitleHeight(titleRef.current.offsetHeight);
    } else {
      setTitleHeight(0);
    }
  }, [page]);

  const timerId = useInterval(
    () => {
      if (title && currentTitleId < title.length) {
        setCurrentTitleId((currentTitleId) => Math.min(currentTitleId + 1, title.length));
      } else {
        clearInterval(timerId);
      }
    },
    title ? (typeof title === 'string' ? getTitleDuration(title) : getTitleDuration(title[currentTitleId - 1])) : 0,
    page,
  );

  const lottieTimer = useInterval(
    () => {
      if (timeout) {
        setAllowNavigate(true);
      } else {
        clearInterval(lottieTimer);
      }
    },
    timeout,
    page,
  );

  const focusTypeData = [
    { type: 1, css: 'right-[10%] bottom-[4.8%]' },
    { type: 2, css: 'right-[8.6%] bottom-[15.5%]' },
    { type: 3, css: 'right-[10%] top-[23.5%]' },
    { type: 4, css: 'right-[10%] top-[27%]' },
    { type: 5, css: 'right-[10%] top-[32%]' },
    { type: 6, css: 'right-[10%] top-[50.5%]' },
    { type: 7, css: 'right-[10%] top-[22%]' },
    { type: 8, css: 'right-[10%] top-[38%]' },
    { type: 9, css: 'right-[10%] top-[43%]' },
    { type: 10, css: 'right-[10%] bottom-[24%]' },
    { type: 11, css: 'right-[10%] bottom-[14.5%]' },
    { type: 12, css: 'right-[10%] top-[46.5%]' },
    { type: 13, css: 'right-[10%] bottom-[21%]' },
  ];

  return (
    <FullPageAnxy
      content={
        <motion.div className="w-screen h-full  flex flex-col justify-between relative overflow-hidden">
          {title && (
            <div ref={titleRef} className="text-white pt-[12px]">
              <TextFadeTransition
                textList={
                  typeof title === 'string'
                    ? [{ id: 1, title: title }]
                    : title.map((each, index) => ({ id: index + 1, title: each }))
                }
                currentTextId={currentTitleId}
              />
            </div>
          )}
          {lottieImage && !isBottom && (
            <div className={`absolute top-[50%] translate-y-[calc(-50%-10px)] ${imageCss}`}>
              {<img src={lottieImage} alt="empty" />}
            </div>
          )}
          {lottieImage && isBottom && <div className="absolute bottom-0">{<img src={lottieImage} alt="empty" />}</div>}
          {lottieImage && (
            <div className="absolute bottom-0 translate-y-[calc(50%-30px)] w-[calc(140%)] left-[50%] translate-x-[-50%]">
              <AnxiousLottie />
            </div>
          )}

          <div className={`w-full flex flex-col justify-center items-center h-full`}>
            {!isBottom && (image || imageLottie) && (
              <div
                className={`h-full flex flex-col justify-center items-center mx-[20px]`}
                // style={{ height: `calc(100vh - ${titleHeight + 44 + deviceState.notchSize}px)` }}
              >
                <motion.div className="relative">
                  {image && (
                    <AnimatePresence exitBeforeEnter>
                      <motion.img
                        key={image}
                        initial={{ opacity: isPhoneImage || isEndImage ? 1 : 0, bottom: isFirst && -110 }}
                        animate={{ opacity: 1, bottom: 0 }}
                        exit={{ opacity: isPhoneImage && !isEndImage ? 1 : 0 }}
                        transition={{ duration: 0.5, opacity: { duration: 0.4 }, ease: 'easeInOut' }}
                        src={image}
                        alt="empty"
                        className={`max-w-[360px] relative py-[20px] z-[50] ${imageCss}`}
                        style={{
                          width: isPhoneImage && 'calc(100vw - 40px)',
                        }}
                      />
                    </AnimatePresence>
                  )}
                  {focusType && (
                    <motion.div
                      key={`${image}${focusType}`}
                      initial={{
                        opacity: 0,
                        display: 'none',
                      }}
                      animate={{ opacity: 1, display: 'flex' }}
                      transition={{ delay: 3 }}
                      className={`py-[20px] absolute ${
                        focusTypeData.find((item) => item.type === focusType).css
                      } z-[50]`}
                      onClick={() => {
                        handleNavigate();
                      }}
                    >
                      <FocusingLottie />
                    </motion.div>
                  )}
                  {imageLottie && isFirst && (
                    <motion.div
                      className="relative h-full "
                      initial={{ opacity: 0, transform: isFirst && 'translateY(110px)' }}
                      animate={{ opacity: 1, transform: isFirst && 'translateY(0px)' }}
                      transition={{ duration: 0.7, ease: 'easeInOut' }}
                    >
                      {imageLottie}
                    </motion.div>
                  )}
                  {imageLottie && !isFirst && (
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        className="z-[50]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      >
                        {imageLottie}
                      </motion.div>
                    </AnimatePresence>
                  )}
                </motion.div>
              </div>
            )}

            {component && component}
            {isSessionEnd && (
              <div className={`${marginClassName} z-[50] w-full px-[20px]`}>
                <SessionBlackRoundActionButton state="ACTIVE" text={'마치기'} action={goHome} round />
              </div>
            )}
          </div>
          <div className="">
            {isBottom && image && (
              <div className={`relative overflow-hidden max-w-[375px] m-auto`}>
                {bottomImage && (
                  <motion.img
                    key={`bottomImage${page}`}
                    className={`${imageCss} absolute top-[11%] translate-y-[40px] left-[50%] translate-x-[-50%] z-[50] object-contain`}
                    alt="empty"
                    src={bottomImage}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7, ease: 'easeInOut' }}
                  />
                )}
                <motion.img
                  key={`image${page}`}
                  src={image}
                  alt="empty"
                  className={`${imageCss} relative z-[40] bottom-[-30px] m-auto`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: isPhoneImage ? 0.7 : 0.5, ease: 'easeInOut' }}
                />
              </div>
            )}
          </div>
        </motion.div>
      }
      bgColor={anxyColorPalette.black}
    />
  );
}
