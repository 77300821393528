const TextWithGradientCircle = ({ texts, margin }) => {
  return (
    <div className={`${margin && margin}`}>
      {texts.map((item, index) => (
        <div
          key={item.title}
          className="flex items-center w-full bg-[#F3F5F8] rounded-[16px] p-[20px]"
          style={{ marginBottom: index !== texts.length && '10px' }}
        >
          <div className="relative mr-[15px] text-white bg-gradientA rounded-[100%] w-[24px] h-[24px] flex flex-items justify-center ">
            <p className="text-[15px] relative bottom-[2px]">{index + 1}</p>
          </div>
          <div className="flex-1 flex flex-col leading-[21px]">
            <p className="mb-[3px] font-bold text-[16px] text-[#3A3C40]">{item.title}</p>
            <p className="text-[15px] text-[#6B6D76]">{item.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default TextWithGradientCircle;
