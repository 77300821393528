/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import selfCheckData from '../../data/selfCheckData';
import { changeHeader } from '../../utils/webview';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useFetch from '../../hooks/useFetch';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import inside_logo from '../../image/inside_logo.png';
import { WhiteRoundActionButton } from '../../stories/button/RoundActionButton';

export default function SelfCheckStart(props) {
  let { selfCheckId } = useParams();
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === parseInt(selfCheckId));
  const context = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const navigation = searchParams.get('navigation') || 'close';
  const xyz = searchParams.get('xyz') === 'true';
  const sc = searchParams.get('sc') === 'true';
  const [selfCheckQuestion, getSelfCheckQuestion] = useFetch({
    url: `/self-check/v2/qc/detail`,
    params: { self_check_id: parseInt(selfCheckId) },
    bg: '#EFF2FF',
  });

  useEffect(() => {
    if (selfCheckQuestion) {
      console.log(selfCheckQuestion.result);
      context.setSelfcheckQuestion(selfCheckQuestion.result);
    }
  }, [selfCheckQuestion]);

  useEffect(() => {
    document.title = `${selfCheckInfo.selfCheckName} 자가검사 시작`;
    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: navigation });
    getSelfCheckQuestion();
    context.setShowProgressingbar(false);
    context.setSelfcheckAnswer([]);

    if (sc) {
      const root = document.getElementById('root');
      root.style.position = 'fixed';
      root.style.overflow = 'hidden';
    }
  }, []);

  const goNext = useNavigateWithParams(`/selfCheck/${selfCheckId}/1`);

  const Element = (
    <div
      className="w-full h-screen bg-[#EFF2FF] overflow-x-hidden  scrollbar-hide pt-[20px] flex flex-col "
      style={{
        background: '#EFF2FF',
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <div className="w-full flex flex-col items-center px-[20px]">
        {sc ? (
          <div className="w-[88px] mb-[20px]">
            <img src={inside_logo} alt={'empty'} />
          </div>
        ) : (
          <p className="bg-[#26282C] rounded-[20px] px-[15px] py-[4px] text-[15px] leading-[21px] font-bold text-white mb-[15px]">
            {`${selfCheckInfo.examName} (${selfCheckInfo.numberOfQuestion}문항)`}
          </p>
        )}
        {sc ? (
          <div className="text-center ">
            <p className="text-[17px] leading-[23px] text-[#3A3C40] font-semibold mb-[5px]">창업자를 위한</p>
            <p className="text-[40px] leading-[48px] text-[#26282C] font-bold ">스트레스 검사</p>
            <p className="text-[17px] leading-[23px] text-[#80838B] ">(10문항)</p>
          </div>
        ) : (
          <p className={`text-[40px] leading-[48px] font-bold text-[#26282C] mb-[10px]`}>
            {selfCheckInfo.selfCheckName}
          </p>
        )}
      </div>
      <div className="flex-1  pt-[50px] pb-[20px] flex flex-col ">
        <img src={selfCheckInfo.image} alt="empty" />
        {/* {sc && (
          <p
            className="w-full px-[20px] text-center  text-[#80838B] text-[14px] leading-[20px]  "
            style={{ wordBreak: 'keep-all' }}
          >
            {selfCheckInfo.source}
          </p>
        )} */}
      </div>
      <p className="pb-[20px] text-center text-[17px] leading-[23px] font-normal text-[#6B6D76] ">
        {selfCheckInfo.guide}
      </p>
      <div className="px-[20px] pb-[20px]">
        <PrimaryActionButton state={'ACTIVE'} text={'시작하기'} action={goNext} />
      </div>
    </div>
  );
  return sc ? (
    // <BottomAction
    //   content={Element}
    //   buttonText={'시작하기'}
    //   buttonState={'ACTIVE'}
    //   action={goNext}
    //   bgColor={'#EFF2FF'}
    // />
    Element
  ) : xyz ? (
    <div
      className="w-full h-screen bg-[#111A43] overflow-x-hidden  px-[20px] flex flex-col justify-center"
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <div className="flex flex-col items-center mb-[100px] text-center">
        <p className=" text-[40px] leading-[48px] font-bold text-white mb-[50px]">
          나의 불면증 점수는
          <br />
          몇점일까?
        </p>
        <p className=" text-[20px] leading-[24px] font-bold text-[#8CAEF2]">
          2분만에 나의 불면증 점수를 알아보고
          <br />더 개운하게 숙면하는 방법을 배워보세요
        </p>
      </div>

      <div className=" mx-auto">
        <WhiteRoundActionButton state={'ACTIVE'} text={'검사 시작하기'} action={goNext} />
      </div>
    </div>
  ) : (
    <div className="w-full h-screen  overflow-x-hidden overflow-y-auto scrollbar-hide pt-[20px] flex flex-col justify-center">
      <div className="flex flex-col items-center mb-[30px]">
        <p className=" text-[30px] leading-[40px] font-bold text-[#26282C] mb-[10px]">
          내 {selfCheckInfo.selfCheckName} 점수 알아보기
        </p>
        <p className=" text-[17px] leading-[23px] font-normal text-[#6B6D76] ">{selfCheckInfo.guide}</p>
      </div>

      <div className="px-[20px]">
        <PrimaryActionButton state={'ACTIVE'} text={'시작하기'} action={goNext} />
      </div>
    </div>
  );
}
