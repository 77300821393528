/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import { BottomActionAnxy } from 'stories/page/BottomActionAnxy';
import { useSearchParams } from 'react-router-dom';
import anxyTypeData from 'data/anxyTypeData';
import { useSetRecoilState } from 'recoil';
import progressBarAtom from 'recoil/common/progressBar/atom';
import headerAtom, { BUTTON_NONE } from 'recoil/common/header/atom';

export function AnxyTypeCard(props) {
  const { type, clickable, big } = props;
  const myType = anxyTypeData.find((element) => element.type === type);

  return (
    myType && (
      <div
        className={`w-full rounded-[20px] ${big ? 'py-[70px]' : 'pt-[10px] pb-[40px]'} flex flex-col items-center`}
        style={{ backgroundColor: myType.bgColor }}
      >
        <img src={myType.image} alt={'empty'} className="w-[200px] h-[200px] mb-[20px]" />
        <p className="text-[18px] leading-[24px] text-[#6B6D76] mb-[6px]">{myType.sentence}</p>
        <div className={`flex items-center ${clickable && 'translate-x-[12px]'}`}>
          <p className="text-[24px] leading-[30px] font-bold text-[#26282C] ">{myType.name}</p>
          {clickable && (
            <div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.5 17L14.5 12L9.5 7"
                  stroke="#26282C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
    )
  );
}
export function AnxyTypeDetail(props) {
  const { type, onlySummary } = props;
  const myType = anxyTypeData.find((element) => element.type === type);
  return (
    myType && (
      <div className="flex flex-col gap-[60px]">
        {(onlySummary
          ? [{ title: '세 줄 요약', text: myType.summary, list: true }]
          : [
              { title: '결과 요약', text: myType.summary, list: true },
              { title: '타입 설명', text: myType.explanation },
              { title: '해결 방법', text: myType.solution },
            ]
        ).map((each, index) => (
          <div key={`detail${index}`}>
            <div className="text-[17px] leading-[24px] text-[#26282C] font-bold mb-[10px]">{each.title}</div>
            <div className="text-[17px] leading-[28px] text-[#26282C] font-light">
              {each.list
                ? each.text.map((each, index) => (
                    <div key={`summary${index}`} className="flex items-start">
                      <div className="w-[28px] h-[28px] relative shrink-0">
                        <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[3px] h-[3px] rounded-[50%] bg-[#26282C] shrink-0" />
                      </div>
                      <div className="">{each}</div>
                    </div>
                  ))
                : each.text}
            </div>
          </div>
        ))}
      </div>
    )
  );
}

export default function AnxyResult(props) {
  const [searchParams] = useSearchParams();
  const type = parseInt(searchParams.get('type'));
  const goNext = useNavigateWithParams(`/anxyTest/typeInfo1`);

  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
  }, []);

  return (
    <BottomActionAnxy
      content={
        <div className="w-screen h-full px-[20px] pt-[24px]   ">
          <div className="mb-[40px]">
            <AnxyTypeCard type={type} big />
          </div>
          <AnxyTypeDetail type={type} onlySummary />
        </div>
      }
      buttonText={'다음'}
      buttonState={'ACTIVE'}
      action={() => {
        goNext();
      }}
      noHeader
    />
  );
}
