import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import EndProcessing from '../../components/selfCheck/EndProcessing';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import headerAtom, { BUTTON_NONE } from '../../recoil/common/header/atom';
import progressBarAtom from '../../recoil/common/progressBar/atom';

export default function EndTest() {
  const [done, setDone] = useState(false);
  const goResult = useNavigateWithParams(`/inside-white/result`);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    if (done) {
      goResult();
    }
  }, [done]);

  useEffect(() => {
    setProgressBarState({
      isProgressBarVisible: false,
    });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
  }, []);

  const isDone = () => {
    setDone(true);
  };

  return (
    <div
      className={`w-screen h-screen bg-white`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <EndProcessing
        steps={['분석 환경 세팅 중', '개인정보 암호화 처리 중', '검진 답변 분석 중', '결과지 구성 중']}
        isDone={isDone}
      />
    </div>
  );
}
