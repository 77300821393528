/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import SelfCheckTemplate from '../../components/selfCheck/SelfCheckTemplate';
import { AuthContext } from '../../context';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function OwlQA(props) {
  const { choices, qcList } = props;
  let { id } = useParams();
  id = parseInt(id);
  const context = useContext(AuthContext);

  const setMixpanel = useMixpanelWeb({ eventName: '검사 마지막', projectName: 'somny' });

  const [answer, setAnswer] = useState();
  const [disable, setDisable] = useState(false);

  const goNextQuestion = useNavigateWithParams(`/somny/${parseInt(id) + 1}`);
  const goEndTest = useNavigateWithParams(`/somny/end`);

  useEffect(() => {
    if (context.qcAnswerList.find((element) => element.id === qcList[id - 1].id)) {
      console.log('체크햇음', context.qcAnswerList);
      const selected = context.qcAnswerList.find((element) => element.id === qcList[id - 1].id);
      console.log(selected);
      setAnswer(selected.selfCheckChoiceId);
    }
  }, []);

  function select(option) {
    // console.log('answers: ', context.qcAnswerList);
    setAnswer(option.selfCheckChoiceId);
    var arr1 = context.qcAnswerList.slice();

    if (arr1.find((element) => element.id === qcList[id - 1].id)) {
      arr1 = arr1.filter((element) => element.id !== qcList[id - 1].id);
    }
    if (qcList[id - 1].type) {
      arr1.push({
        id: qcList[id - 1].id,
        selfCheckChoiceId: option.selfCheckChoiceId,
        type: qcList[id - 1].type,
      });
    } else {
      arr1.push({
        id: qcList[id - 1].id,
        selfCheckChoiceId: option.selfCheckChoiceId,
        score: option.score,
      });
    }

    console.log(arr1);
    context.setQcAnswerList(arr1);

    setTimeout(() => {
      if (parseInt(id) < qcList.length) {
        console.log('다음 문항으로');
        goNextQuestion();
      } else if (parseInt(id) === qcList.length) {
        goEndTest();
      }
    }, 400);
  }

  function isClicked(option) {
    if (option !== '') {
      setDisable(true);
      select(option);
    }
  }

  useEffect(() => {
    document.title = `somny ${parseInt(id)}`;
    context.setMaxCompleted(qcList.length);
    context.setCompleted(parseInt(id));
    context.setShowProgressingbar(true);

    if (id === qcList.length) {
      setMixpanel();
    }
  }, []);

  return (
    <div className={`w-screen h-[calc(100vh-17px)] bg-white `}>
      <SelfCheckTemplate
        question={qcList[id - 1].question}
        optionList={choices}
        answer={answer}
        isClicked={isClicked}
        disable={disable}
        list
      />
    </div>
  );
}
