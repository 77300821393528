/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import TestResultChart from '../../components/selfCheck/TestResultChart';
import HistoryGraph from '../../components/selfCheck/HistoryGraph';
import selfCheckData from '../../data/selfCheckData';
import { getSelfCheckState } from '../../components/selfCheck/selfCheckLogic';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import headerAtom, { BUTTON_NONE } from '../../recoil/common/header/atom';
import selfCheckAtom from '../../recoil/common/selfCheck/atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { BottomActionAnxy } from '../../stories/page/BottomActionAnxy';
import { PATH } from '../../routes/paths';

export default function GADResult() {
  const selfCheckId = 2;
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === selfCheckId);

  const selfCheckState = useRecoilValue(selfCheckAtom);
  const setHeaderState = useSetRecoilState(headerAtom);

  const [lastLabel, setLastLabel] = useState(0);
  const currentLabel = getSelfCheckState(selfCheckId, selfCheckState.selfCheckResult.score);

  const [change, setChange] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_NONE,
      headerTitle: '불안 검사 결과',
      headerColor: '#26282C',
    }));

    // setCurrentLabel();

    if (selfCheckState.selfCheckResult.resultHistoryList) {
      const resultHistoryList = selfCheckState.selfCheckResult.resultHistoryList;
      if (resultHistoryList.length >= 2) {
        setLastLabel(getSelfCheckState(selfCheckId, resultHistoryList[resultHistoryList.length - 2].score));
      }
    }
  }, []);

  useEffect(() => {
    if (lastLabel < currentLabel) {
      setChange(-1);
    } else if (lastLabel > currentLabel) {
      setChange(1);
    }
  }, [lastLabel, currentLabel]);

  const goMyPage = useNavigateWithParams(PATH.anxy.introAfterTest);

  return (
    <BottomActionAnxy
      content={
        currentLabel !== null && (
          <div className="h-full overflow-x-hidden ">
            <div className="w-full  bg-white  px-[20px] ">
              <div className="w-full pt-[30px] pb-[40px]">
                <p className=" font-bold text-[24px] leading-[30px] text-[#26282C]  mb-[20px] ">
                  {selfCheckState.selfCheckResult.diagnosisTitle}
                </p>
                <div className="w-full pt-[28px] pb-[20px] rounded-[12px] bg-[#F3F5F8] flex flex-col items-center">
                  <div className="w-[42px] h-[42px] mb-[15px]">
                    <img src={selfCheckInfo.result[currentLabel].emoji} alt={'empty'} />
                  </div>
                  <p className="  font-normal text-[15px] leading-[21px] text-[#4E4F53]   ">
                    {selfCheckState.selfCheckResult.diagnosisSubTitle}
                  </p>
                </div>
                <div className="mt-[50px]">
                  <TestResultChart
                    examName={selfCheckInfo.selfCheckName}
                    isAbnormal={selfCheckState.selfCheckResult.isAbnormal}
                    score={selfCheckState.selfCheckResult.score}
                    normalColor={'#398047'}
                  />
                </div>
              </div>

              {selfCheckState.selfCheckResult.resultHistoryList &&
                selfCheckState.selfCheckResult.resultHistoryList.length >= 2 && (
                  <div className="w-full  pt-[30px]  border-t-[1px] border-[#F3F5F8]">
                    <p className=" font-bold text-[20px] leading-[26px] text-[#26282C]  mb-[6px] ">
                      {change === -1 &&
                        (!selfCheckInfo.reverse ? '지난 검사보다 나빠졌어요' : '지난 검사보다 좋아졌어요')}
                      {change === 0 && '지난 검사와 유사해요'}
                      {change === 1 &&
                        (!selfCheckInfo.reverse ? '지난 검사보다 좋아졌어요' : '지난 검사보다 나빠졌어요')}
                    </p>
                    <p className="  font-normal text-[15px] leading-[21px] text-[#6B6D76]   ">
                      {change === 0 && `${selfCheckInfo.result[currentLabel].label} 단계가 유지되고 있어요`}

                      {change !== 0 &&
                        `${selfCheckInfo.result[lastLabel].label}에서 ${selfCheckInfo.result[currentLabel].label} 단계로 변화했어요.`}
                    </p>
                    <div className="mt-[20px] mb-[74px]">
                      <HistoryGraph
                        selfCheckId={selfCheckId}
                        resultHistoryList={selfCheckState.selfCheckResult.resultHistoryList}
                        normalColor={'#398047'}
                      />
                    </div>
                  </div>
                )}
              {selfCheckState.selfCheckResult.analysisList && (
                <div className="w-full bg-white  pt-[40px] border-t-[1px] border-[#F3F5F8]">
                  <div className="flex flex-col gap-[30px]">
                    {selfCheckState.selfCheckResult.analysisList.map((analysis, index) => (
                      <div key={`analysis${index}`}>
                        <p className="text-[18px] leading-[24px] font-bold text-[#26282C] mb-[10px]">
                          {analysis.title}
                        </p>
                        <p className="text-[17px] leading-[23px] font-normal text-[#4E4F53]">{analysis.body}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      }
      buttonText={'확인'}
      buttonState={'ACTIVE'}
      action={() => {
        goMyPage();
      }}
    />
  );
}
