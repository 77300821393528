import { selector } from 'recoil';
import deviceAtom from './atom';

const homeIndicatorSize = selector({
  key: 'homeIndicatorSize',
  get: ({ get }) => {
    const hasHomeIndicator = get(deviceAtom).hasHomeIndicator;
    return hasHomeIndicator ? 34 : 20;
  },
});

export default homeIndicatorSize;
