import { useState, useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import webviewToast from '../development/webviewToast';
import worryNoteAtom from '../recoil/anxy/worryNote/atom';
import deviceAtom from '../recoil/common/device';

export const useTouchScroll = () => {
  const sheet = useRef();
  const worryNoteState = useRecoilValue(worryNoteAtom);
  const [score, setScore] = useState(worryNoteState.score);
  const [scrollX, setScrollX] = useState(0);
  const deviceState = useRecoilValue(deviceAtom);

  const headerHeight = 81;
  const buttonHeight = deviceState.hasHomeIndicator ? 88 : 74;

  // touch 영역: 헤더 ~ 버튼 사이 div
  const touchAreaHeight = ((window.innerHeight - buttonHeight - headerHeight) * 2) / 3;
  const touchAreaWidth = window.innerWidth > 375 ? 375 : window.innerWidth;

  const metrics = useRef({
    touchMove: {
      prevTouchX: 0,
      endTouchX: 0,
      prevTouchY: 0,
      endTouchY: 0,
      movingDirection: 'none',
    },
  });

  const updateState = (setState, data) => {
    setState((prev) => {
      const updated = prev + data;
      if (updated > 100) {
        return 100;
      } else if (updated < 0) {
        return 0;
      } else {
        return updated;
      }
    });
  };

  useEffect(() => {
    setScore(worryNoteState.score);
  }, [worryNoteState]);

  useEffect(() => {
    const handleTouchStart = (e) => {
      const { touchMove } = metrics.current;

      touchMove.endTouchX = e.touches[0].clientX;
      touchMove.endTouchY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      e.preventDefault();

      const { touchMove } = metrics.current;
      const currentTouch = e.touches[0];

      touchMove.prevTouchX = touchMove.endTouchX;
      touchMove.endTouchX = currentTouch.clientX;

      touchMove.prevTouchY = touchMove.endTouchY;
      touchMove.endTouchY = currentTouch.clientY;

      const diffX = touchMove.endTouchX - touchMove.prevTouchX;
      const diffY = touchMove.prevTouchY - touchMove.endTouchY;

      const updateScrollX = (diffX / touchAreaWidth) * 100;
      const updateScore = (diffY / touchAreaHeight) * 100;

      updateState(setScrollX, updateScrollX);
      updateState(setScore, updateScore);
    };

    if (sheet && sheet.current) {
      sheet.current.addEventListener('touchstart', handleTouchStart);
      sheet.current.addEventListener('touchmove', handleTouchMove);

      return () => {
        if (sheet.current !== null) {
          sheet.current.removeEventListener('touchstart', handleTouchStart);
          sheet.current.removeEventListener('touchmove', handleTouchMove);
        }
      };
    }
  }, [sheet]);

  return { sheet, score, scrollX };
};
