import 'moment/locale/ko';
import React, { useEffect, useState, useRef } from 'react';
import ContainerByDeviceAnxy from '../../../components/elements/ContainerByDeviceAnxy';
import { AnxyLoadingLottie } from '../../../stories/animation/AnxyLoadingLottie';
import { updateNotch, keepScreenEvent } from '../../../utils/webview';
import useMixpanelEventAnxy from '../../../hooks/useMixpanelEventAnxy';
import headerAtom, { BUTTON_MORE } from '../../../recoil/common/header/atom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useInterval from '../../../hooks/useInterval';
import roadmap_box from '../../../image/anxy/12B/roadmap_box.png';
import roadmap_box_disabled from '../../../image/anxy/12B/roadmap_box_disabled.png';
import roadmap_box_opened from '../../../image/anxy/12B/roadmap_box_opened.png';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import Progressbar from 'react-js-progressbar';
import start_flag_lottie from '../../../image/anxy/12B/start_flag_lottie.json';
import Lottie from '../../../components/elements/Lottie';
import SectionCard from '../../../components/anxy/anxy12ndB/SectionCard';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import TokenTest from '../../../development/TokenTest';
import anxyContents from '../../../data/anxy/contents/anxyContents';
import sessionAtom from '../../../recoil/anxy/session/atom';
import modalAtom from '../../../recoil/common/modal/atom';
import PageToast from '../../../components/elements/PageToast';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { anxyToolData } from '../../../data/anxy/home/anxyToolData';
import useGetLevel from '../../../hooks/anxy/useGetLevel';
import useGetHeart from '../../../hooks/anxy/useGetHeart';
import statusAtom from '../../../recoil/anxy/home/statusAtom';
import AnxyModalWithDefault from '../../../components/anxy/modal/AnxyModalWithDefault';

const pathWidth = 122;
const pathHeight = 99;
const sectionHeight = 635 / 7;

function Path({ type, ratio }) {
  const path = {
    lb: 'M122 8H98.625C48.5742 8 8 48.5742 8 98.625V98.625',
    br: 'M122 91H98.625C48.5742 91 8 50.4258 8 0.375008V0.375008',
    rb: 'M0 8H23.375C73.4258 8 114 48.5742 114 98.625V98.625',
    bl: 'M0 91H23.375C73.4258 91 114 50.4258 114 0.375008V0.375008',
  };
  return (
    <div className="w-fit relative">
      <div>
        <svg
          width={pathWidth}
          height={pathHeight}
          viewBox={`0 0 ${pathWidth} ${pathHeight}`}
          direction="rtl"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={path[type]} stroke={`${type === 'br' || type === 'bl' ? '#E8DAD1' : '#E4E4E5'}`} strokeWidth="15" />
        </svg>

        <div className="absolute top-0">
          <svg
            width={pathWidth}
            height={pathHeight}
            viewBox={`0 0 ${pathWidth} ${pathHeight}`}
            direction="rtl"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d={path[type]}
              stroke={`${type === 'br' || type === 'bl' ? '#E4E4E5' : '#E8DAD1'}`}
              strokeWidth="15"
              strokeDasharray={`${type === 'br' || type === 'bl' ? Math.max((1 - ratio) * 160, 0) : ratio * 160}, 160`}
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

function Section({ data, index, setSelectedSectionId, disabled, isCompleted, percentage, isFirstComplete, toolList }) {
  const { sectionId, type, isLock, isFirst } = data;
  const openedToolIdList = toolList.filter((element) => !element.isLock).map((element) => element.toolId);
  const openedExerciseSectionIdList = anxyToolData
    .filter((element) => openedToolIdList.includes(element.toolId))
    .map((element) => element.exerciseIdPrefix);

  return (
    <div
      className="w-[110px] h-[110px] rounded-[50%] bg-[#F3F5F8] border-[10px] border-[#E4E4E5] flex items-center justify-center relative z-[100]"
      onClick={(e) => {
        if (!disabled) {
          e.stopPropagation();
          setSelectedSectionId(sectionId);
        }
      }}
    >
      {!isLock && !isFirst && (
        <div className="absolute w-[110px] h-[110px]">
          <Progressbar
            input={isCompleted ? 100 : percentage}
            pathWidth={20}
            pathColor={'#EF670B'} // use an array for gradient color.
            trailWidth={20}
            trailColor="#F3E7E0" // use a string for solid color.
            textStyle={{ fill: '#26282C', fontSize: '0px', fontWeight: '700' }} // middle text style
            pathLinecap={'none'}
            pathShadow={'none'}
            animation={{
              duration: !isFirstComplete ? 0 : 1000,
              delay: 0,
              ease: 'easeInSine',
              animateOnInputChange: true,
            }}
          ></Progressbar>
        </div>
      )}

      <div
        className={`w-[84px] h-[84px] rounded-[50%] 
        ${
          type === 'box'
            ? 'bg-[#3A3C40]'
            : !disabled
            ? 'bg-white border-[1px] border-[rgba(0,0,0,0.1)]'
            : 'bg-[#D1D1D1]'
        }
        
        flex items-center justify-center`}
      >
        {type !== 'box' ? (
          <div className="flex flex-col items-center justify-center">
            <p className="text-[rgba(0,0,0,0.4)] text-[12px] leading-[18px] font-bold ">
              {`${type.substring(0, 1).toUpperCase()}${type.substring(1)}`}
            </p>
            <p
              className={`text-[#3A3C40] ${disabled ? 'opacity-40' : 'opacity-100'} ${
                type === 'exercise' && openedExerciseSectionIdList.includes(sectionId.split('_').slice(0, 2).join('_'))
                  ? 'text-[18px] '
                  : 'text-[30px] leading-[32px] '
              }font-extrabold `}
            >
              {type === 'exercise'
                ? openedExerciseSectionIdList.includes(sectionId.split('_').slice(0, 2).join('_'))
                  ? anxyToolData.find(
                      (element) => element.exerciseIdPrefix === sectionId.split('_').slice(0, 2).join('_'),
                    ).title
                  : '?'
                : index}
            </p>
          </div>
        ) : !disabled ? (
          isCompleted ? (
            <img src={roadmap_box_opened} alt={'empty'} className="w-[63px]" />
          ) : (
            <img src={roadmap_box} alt={'empty'} className="w-[63px]" />
          )
        ) : (
          <img src={roadmap_box_disabled} alt={'empty'} className="w-[63px]" />
        )}
      </div>
    </div>
  );
}

export default function AnxyRoadMap() {
  // 데이터 로드 여부

  const [modalState, setModalState] = useRecoilState(modalAtom);
  const [statusState, setStatusState] = useRecoilState(statusAtom);

  const setHeaderState = useSetRecoilState(headerAtom);
  const setSessionState = useSetRecoilState(sessionAtom);
  const [ratio, setRatio] = useState(0);

  const [selectedSectionId, setSelectedSectionId] = useState();
  const [sectionCardHeight, setSectionCardHeight] = useState(0);
  const [level, setLevel] = useState();
  const [heart, setHeart] = useState();
  const [chapterList, setChapterList] = useState();

  const [sessionList, setSessionList] = useSessionStorage('sessionList', null);
  const [toolList, setToolList] = useSessionStorage('toolList', null);
  const [pathStart, setPathStart] = useState(false);
  const [showStartLottie, setShowStartLottie] = useState(false);
  const sectionRefs = useRef([]);
  const sectionCardRef = useRef();
  const [scrollTo, setScrollTo] = useState();

  const [showPageToast, setShowPageToast] = useState(false);

  const goLevelURL = useGetLevel();
  const goHeartURL = useGetHeart();

  const goLevel = useNavigateWithParams(goLevelURL);
  const goHeart = useNavigateWithParams(goHeartURL);

  const [homeDetail, getHomeDetail] = useFetchAnxy({
    url: `/anxy/chapter/detail`,
    bg: '#FFFFFF',
  });

  const [toolListData, getToolList] = useFetchAnxy({
    url: `/anxy/tool/list`,
    bg: '#FFFFFF',
  });

  const initializeState = () => {
    if (modalState.isModalVisible) {
      setModalState((state) => ({ ...state, isModalVisible: false }));
    }
    setSessionState((state) => ({ ...state, currentSession: undefined }));
  };

  useEffect(() => {
    getHomeDetail();
    getToolList();
    initializeState();
    setTimeout(() => {
      setPathStart(true);
    }, 1100);
  }, []);

  useEffect(() => {
    if (toolListData) {
      const changedList = [...toolListData.result];
      if (toolList) {
        toolList.forEach((beforeItem) => {
          changedList.forEach((afterItem) => {
            if (beforeItem.id === afterItem.id) {
              // 방금 세션을 마친 경우 check 애니메이션 위해 isFirstComplete 설정
              if (!beforeItem.completedAt && afterItem.completedAt) {
                afterItem.isFirstComplete = true;
              }
              if (beforeItem.isLock && !afterItem.isLock) {
                afterItem.isFirstUnlocked = true;
              }
            }
          });
        });
      }
      setToolList(changedList);
      setSessionState((state) => ({ ...state, toolList: changedList }));
    }
  }, [toolListData]);

  useEffect(() => {
    if (homeDetail) {
      setLevel(homeDetail.result.level);
      setHeart(homeDetail.result.heart);
      setChapterList(homeDetail.result.chapterList);
      const sessionList = homeDetail.result.chapterList.map((element) => element.sectionList).flat();
      setSessionList(sessionList);
      setSessionState((state) => ({ ...state, sessionList }));
      setStatusState((state) => ({
        isInReview: homeDetail.result.isInReview,
        isPaymentRequired: homeDetail.result.isPaymentRequired,
        isTestClosed: homeDetail.result.isTestClosed,
      }));
      const flattenedList = homeDetail.result.chapterList.map((element) => element.sectionList).flat();

      // result는 session API로부터 받은 data
      const changedList = [...flattenedList];
      if (sessionList) {
        sessionList.forEach((beforeItem) => {
          changedList.forEach((afterItem) => {
            if (beforeItem.sectionId === afterItem.sectionId) {
              // 방금 세션을 마친 경우 check 애니메이션 위해 isFirstComplete 설정
              if (!beforeItem.completedAt && afterItem.completedAt) {
                afterItem.isFirstComplete = true;
              }
              if (beforeItem.isLock && !afterItem.isLock) {
                afterItem.isFirstUnlocked = true;
              }
            }
          });
        });
      }
      setTimeout(
        () => {
          setShowStartLottie(true);
        },
        changedList.findIndex((element) => element.isLock) >= 2 &&
          changedList[changedList.findIndex((element) => element.isLock) - 2].isFirstComplete
          ? 1600
          : 0,
      );

      setSessionList(changedList);
      setSessionState((state) => ({ ...state, sessionList: changedList }));
    }
  }, [homeDetail]);

  const animation = useInterval(
    () => {
      if (pathStart) {
        setRatio((ratio) => ratio + 0.01);
      }
    },
    ratio < 1 ? 10 : null,
  );

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '홈',
  });

  useEffect(() => {
    if (selectedSectionId) {
      setSectionCardHeight(sectionCardRef.current.offsetHeight);

      const index = sessionList.findIndex((element) => element.sectionId === selectedSectionId);

      const scrollMove =
        sectionRefs.current[index].getBoundingClientRect().y +
        110 +
        sectionCardRef.current.offsetHeight -
        (window.innerHeight - 110);

      if (scrollMove > 0) {
        setScrollTo(scrollMove + 20);
      }
    }
  }, [selectedSectionId]);

  useEffect(() => {
    // header, layout settings
    document.title = 'Anxy 홈';
    document.body.style.backgroundColor = '#FFFFFF';
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_MORE,
      headerTitle: '',
      headerColor: '#26282C',
    }));
    updateNotch({ isFullScreen: true });
    setMixPanel();
    keepScreenEvent({ isKeepScreenOn: false });
  }, []);

  const chapterColorData = [
    { title: '불안 이해하기', color: '#EF670B' },
    { title: '불안한 몸 달래기', color: '#F8CF46' },
    { title: '불안한 생각 덜기', color: '#348E46' },
    { title: '불안한 행동 맞서기', color: '#6041A1' },
  ];

  return level && heart && chapterList && sessionList ? (
    <ContainerByDeviceAnxy noHeader scrollto={scrollTo}>
      <AnxyModalWithDefault toolList={toolList} />

      <PageToast text={'Anxy의 체력이 부족해요'} showPageToast={showPageToast} setShowPageToast={setShowPageToast} />

      <div className="w-full h-fit pt-safe fixed top-0 left-0 z-[50] bg-white">
        <div className="w-[calc(100%-52px)] h-[44px]  pl-[20px] pr-[3px] flex gap-[10px] items-center  justify-between ">
          <div
            className=" flex-1 h-[32px] px-[15px] bg-white rounded-[50px] border-[1px] border-[rgba(0,0,0,0.05)] flex gap-[6px] items-center justify-center "
            onClick={goLevel}
          >
            <p className="text-[17px] leading-[23px] text-[#26282C] font-bold ">{`Lv.${level.level}`}</p>

            <div className="flex-1 flex gap-[1px] ">
              {Array.from({ length: level.requiredExperience }, () => 0).map((each, index) => (
                <div
                  key={`level${index}`}
                  className={`flex-1 h-[8px]  ${index < level.currentExperience ? 'bg-[#398047]' : 'bg-[#E1E4EB]'}
                  ${
                    index === 0 ? 'rounded-l-[10px]' : index === level.requiredExperience - 1 ? 'rounded-r-[10px]' : ''
                  }`}
                />
              ))}
            </div>
          </div>

          <div
            className=" h-[32px] px-[15px] bg-white flex gap-[2px] items-center rounded-[50px] border-[1px] border-[rgba(0,0,0,0.05)] text-[17px] "
            onClick={goHeart}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.46626 5.54494C1.51125 7.60486 1.51125 10.9446 3.46626 13.0046L4.92027 14.5366L4.91668 14.5404L9.82151 19.7084C11.004 20.9544 12.9893 20.9549 14.1725 19.7095L19.0833 14.5404L19.0797 14.5366L20.5337 13.0046C22.4888 10.9446 22.4888 7.60486 20.5337 5.54494C18.5787 3.48502 15.409 3.48502 13.454 5.54494L12 7.07696L10.546 5.54494C8.59098 3.48502 5.42128 3.48502 3.46626 5.54494Z"
                fill="url(#paint0_linear_2828_65644)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2828_65644"
                  x1="12"
                  y1="4"
                  x2="12"
                  y2="22"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#DC3535" />
                  <stop offset="1" stopColor="#C61C1C" />
                </linearGradient>
              </defs>
            </svg>

            <div className="font-bold">
              <span>{heart.currentHeart}</span>
              <span className="text-[#26282C] opacity-[0.3]">/{heart.totalHeart}</span>
            </div>
          </div>
        </div>
        <div className="w-screen h-[10px]  " />
      </div>

      <div className="relative w-full pt-[54px] pb-[118px] bg-[#F3F5F8]">
        {chapterList.map(({ title, sectionList }, index) => (
          <div
            key={`chapter${index}`}
            onClick={() => {
              // 선택 초기화
              setSelectedSectionId();
            }}
          >
            <div
              className="text-[20px] leading-[26px] text-white font-bold p-[20px]"
              style={{ backgroundColor: chapterColorData.find((element) => element.title === title).color }}
            >
              {title}
            </div>

            <div className="mx-auto w-fit  py-[130px] ">
              <div
                className="relative  w-[228px]"
                style={{
                  height: `${sectionHeight * (sectionList.length - 1)}px`,
                }}
              >
                {sectionList.map((each, index2) => (
                  <div key={each.sectionId}>
                    <div
                      className="absolute z-[20] "
                      ref={(element) => {
                        sectionRefs.current[sessionList.findIndex((element) => element.sectionId === each.sectionId)] =
                          element;
                      }}
                      style={{
                        top: sectionHeight * index2,
                        left: index2 % 4 === 1 ? 0 : (index2 % 4) % 2 === 0 ? '50%' : '',
                        right: index2 % 4 === 3 && 0,
                        transform:
                          (index2 % 4) % 2 === 0
                            ? 'translate(-50%,calc(-50% + 4px))'
                            : index2 % 4 === 1
                            ? 'translate(calc(-50% + 10px),calc(-50% + 4px))'
                            : 'translate(calc(50% - 10px),calc(-50% + 4px))',
                      }}
                    >
                      <Section
                        data={each}
                        index={
                          sessionList
                            .filter((element) => element.type === 'session')
                            .findIndex((element) => element.sectionId === each.sectionId) + 1
                        }
                        setSelectedSectionId={setSelectedSectionId}
                        disabled={index > 0}
                        isCompleted={each.completedAt || false}
                        percentage={
                          each.pageLastViewed
                            ? anxyContents[index2]
                              ? JSON.parse(each.pageLastViewed).currentTime && anxyContents[index2].length === 1
                                ? (parseInt(JSON.parse(each.pageLastViewed).currentTime) / 120) * 100
                                : ((parseInt(JSON.parse(each.pageLastViewed).page) || 0) /
                                    anxyContents[index2].length) *
                                  100
                              : 0
                            : 0
                        }
                        isFirstComplete={
                          sessionList.find((element) => element.sectionId === each.sectionId).isFirstComplete
                        }
                        toolList={toolList}
                      />
                    </div>
                    {index2 < sectionList.length - 1 && (
                      <div
                        className={`absolute ${index2 % 4 <= 1 ? 'left-[0px]' : 'right-[0px]'}  top-0 `}
                        style={{ top: sectionHeight * index2 }}
                      >
                        <Path
                          type={index2 % 4 === 0 ? 'lb' : index2 % 4 === 1 ? 'br' : index2 % 4 === 2 ? 'rb' : 'bl'}
                          ratio={
                            index === 0
                              ? sessionList.find((element) => element.sectionId === each.sectionId).isFirstComplete
                                ? ratio
                                : each.completedAt
                                ? 1
                                : 0
                              : 0
                          }
                        />
                      </div>
                    )}
                    {
                      // sessionList.findLast((element) => !element.isLock).id === each.id ||
                      index === 0 &&
                        sessionList.findIndex((element) => element.isLock) === index2 + 1 &&
                        showStartLottie && (
                          <div
                            className="absolute z-[20] "
                            style={{
                              top: sectionHeight * index2 - 110,
                              left: '50%',
                              transform:
                                (index2 % 4) % 2 === 0
                                  ? 'translate(-50%,0px)'
                                  : index2 % 4 === 1
                                  ? 'translate(calc(-50% - 102px),0px)'
                                  : 'translate(calc(-50% + 102px),0px)',
                            }}
                          >
                            <Lottie lottieData={start_flag_lottie} autoplay loop width={63} height={60} />
                          </div>
                        )
                    }
                    {selectedSectionId === each.sectionId && (
                      <div
                        className={`absolute z-[30]`}
                        style={{
                          top:
                            sessionList.findIndex((element) => element.sectionId === each.sectionId) -
                              sessionList.length >=
                            -2
                              ? sectionHeight * index2 - 55 - (sectionCardRef.current ? sectionCardHeight : 0)
                              : sectionHeight * index2 + 65,
                          left: index2 % 4 === 1 ? 0 : (index2 % 4) % 2 === 0 ? '50%' : '',
                          right: index2 % 4 === 3 && 0,
                          transform:
                            (index2 % 4) % 2 === 0
                              ? 'translate(-50%,0px)'
                              : index2 % 4 === 1
                              ? 'translate(calc(-50% + 114px),0px)'
                              : 'translate(calc(50% - 114px),0px)',
                        }}
                        ref={sectionCardRef}
                      >
                        <SectionCard
                          index={index2}
                          sectionId={each.sectionId}
                          isTailLeft={index2 % 4 === 1}
                          isTailRight={index2 % 4 === 3}
                          type={each.type}
                          isLock={each.isLock}
                          isCompleted={each.completedAt ? true : false}
                          isFirst={each.isFirst}
                          isTailBottom={
                            sessionList.findIndex((element) => element.sectionId === each.sectionId) -
                              sessionList.length >=
                            -2
                          }
                          pageLastViewed={each.pageLastViewed}
                          isPaymentRequired={statusState.isPaymentRequired}
                          isTestClosed={statusState.isTestClosed}
                          isInReview={statusState.isInReview}
                          isNotReady={each.isNotReady}
                          canViewSession={heart.currentHeart >= 1}
                          setShowPageToast={setShowPageToast}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <TokenTest />
      </div>
    </ContainerByDeviceAnxy>
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <AnxyLoadingLottie />
    </div>
  );
}
