import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { changeHeader } from '../../../utils/webview';
import { SelectButtonEmojiCircle } from '../../../stories/button/SelectButtonEmojiCircle';
import { CoachingContext } from '../../../coachingContext';
import { motion } from 'framer-motion';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import emoji_bad from '../../../image/emoji_bad.png';
import emoji_great from '../../../image/emoji_great.png';
import useFetch from '../../../hooks/useFetch';

export default function ObjectiveFeedback() {
  const [answer, setAnswer] = useState('');
  const [disable, setDisable] = useState(false);
  const context = useContext(CoachingContext);
  const { id, page } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const [objective, getObjective] = useFetch({
    url: '/sleep/objective/load',
    bg: '#F3F5F8',
  });

  useEffect(() => {
    if (objective) {
      console.log(objective);
      setIsLoaded(true);
    }
  }, [objective]);

  useEffect(() => {
    document.title = '피드백';
    getObjective();
  }, []);

  function isClicked(option) {
    if (option !== '') {
      setDisable(true);
      select(option);
    }
  }

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  function select(option) {
    console.log('answers: ', option.id);
    setAnswer(option.id);
    if (parseInt(option.id) === 1) {
      context.workbookAnswer = { isDoingWell: false };
      setTimeout(() => {
        goNext();
      }, 300);
    } else {
      context.workbookAnswer = { isDoingWell: true };
      setTimeout(() => {
        goNext();
      }, 300);
    }
  }

  const data = [
    { id: 1, label: '잘 못하고 있어요', emoji: emoji_bad },
    { id: 2, label: '잘 하고 있어요', emoji: emoji_great },
  ];

  return (
    <div className={`w-screen h-screen bg-[#EFF2FF]`}>
      {isLoaded && (
        <div className={`w-screen h-screen flex flex-col items-center justify-center bg-[#EFF2FF]`}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'linear', delay: parseInt(id) === 0 ? 0.2 : 0 }}
            exit={{ opacity: 0 }}
            className="w-full translate-y-[-40px]"
          >
            <div className="text-center mb-[10px] text-[15px] leading-[21px] text-[#2C4BEC]">{`${objective.result.nickname}님의 수면 목표`}</div>
            <div
              className="w-full px-[20px]  font-bold text-[24px] leading-[28.8px]  text-center mb-[20px] "
              style={{ wordBreak: 'keep-all' }}
            >
              {objective.result.objective}
            </div>

            <div className={`w-[200px] flex justify-between  mx-auto shrink-0`}>
              {data.map((option, index) => (
                <SelectButtonEmojiCircle
                  key={`option${index}`}
                  selected={answer === option.id}
                  option={option}
                  text={option.label}
                  isClicked={isClicked} //버튼 컴포넌트에서 알려줌
                  emoji={option.emoji}
                />
              ))}
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}
