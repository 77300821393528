import { atom } from 'recoil';

const statusAtom = atom({
  key: 'status',
  default: {
    isInReview: false,
    isPaymentRequired: true,
    isTestClosed: false,
  },
});

export default statusAtom;
