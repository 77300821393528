import axios from 'axios';
import { BUILD_TARGET } from '../config';
import webviewToast from '../development/webviewToast';

const HOST_API = process.env.REACT_APP_API_SERVER_HOST_ANXY;
const API_KEY = process.env.REACT_APP_API_KEY;

const isDev = BUILD_TARGET === 'dev';

const axiosInstance = (tryCount, token, appId, bg, isOnce) => {
  const instance = axios.create({
    baseURL: HOST_API,
    headers: {
      'X-Api-Key': API_KEY,
      'X-Auth-Token': token || '',
      'content-type': 'application/json',
      'X-App-Id': appId || 'w:123',
    },
  });

  const onFulfilled = (response) => response;

  const retry = (errConfig) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(instance.get(errConfig.url));
      }, 1000);
    });
  };

  const onRejected = (error) => {
    if (error.config) {
      tryCount--;
      if (!isOnce) {
        if (tryCount) {
          webviewToast('retry');
          return retry(error.config);
        } else {
          console.log(error);
          webviewToast('error: ' + error.response.data || error.response.data.debugMessage);

          if (!isDev) {
            window.location.href = `/anxyError?bg=${bg}`;
          }
        }
      }
    }
    return Promise.reject(error);
  };

  instance.interceptors.response.use(onFulfilled, onRejected);

  return instance;
};

export default axiosInstance;
