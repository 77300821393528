import React, { useEffect } from 'react';
import { AnxyActionButton } from '../button/ActionButton';
import { motion } from 'framer-motion';
import AnxyNotch from '../../components/anxy/AnxyNotch';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import { BottomActionAnxyWithScroll } from './BottomActionAnxyWithScroll';

export function BottomActionAnxy(props) {
  const { content, buttonText, buttonState, action, bgColor, noGradient, fadeIn, noHeader, fixedRoot, appNotch } =
    props;
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
  }, []);

  return noGradient ? (
    <div
      className={`h-screen ${!appNotch && 'pt-safe'}  `}
      style={{
        background: bgColor ? bgColor : '#FFFFFF',
        height: fixedRoot ? 'calc(var(--vh,1vh) * 100)' : '100vh',
        minHeight: fixedRoot ? 'calc(var(--vh.1vh) * 100)' : '100vh',
      }}
    >
      <AnxyNotch notchColor={bgColor || '#ffffff'} noHeader={noHeader} appNotch={appNotch} />
      <div className={`h-full ${!noHeader && 'pt-[44px]'} flex flex-col `}>
        <div className="flex-1 flex flex-col overflow-hidden">{content}</div>

        <div className={`flex-none w-full relative `}>
          <div className={`px-[20px] ${marginClassName} `}>
            <motion.div
              initial={{ opacity: fadeIn ? 0 : 1 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.2, ease: 'easeInOut' }}
            >
              <AnxyActionButton
                state={buttonState || 'ACTIVE'}
                text={buttonText}
                action={action}
                bgColor={bgColor}
                round
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <BottomActionAnxyWithScroll
      content={content}
      buttonText={buttonText}
      buttonState={buttonState}
      action={action}
      bgColor={bgColor}
      noHeader={noHeader}
      fixedRoot={fixedRoot}
      round
    />
  );
}
