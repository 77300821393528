import React, { useState, useEffect, useRef, useContext } from 'react';
import { changeHeader, close } from '../../utils/webview';

import RecommendedCourseCard from '../../components/course/RecommendedCourseCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './RecommendedCourse.css';
import { CoachingContext } from '../../coachingContext';
import emoji_great from '../../image/emoji_great.png';

export default function RecommendedCourse() {
  const context = useContext(CoachingContext);
  const wrapperRef = useRef();
  const contentRef = useRef();

  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    document.title = '추천 코스';
    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'none' });
  }, []);

  useEffect(() => {
    if (wrapperRef.current) {
      console.log(wrapperRef.current.offsetHeight);
      setWrapperHeight(wrapperRef.current.offsetHeight);
    }
    if (contentRef.current) {
      console.log(contentRef.current.offsetHeight);
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [wrapperRef, contentRef]);

  const settings = {
    infinite: false,
    lazyLoad: false,
    // speed: 300,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    variableWidth: true,
    dots: true,
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: 'rba(255,255,255,0)',
          bottom:
            wrapperRef.current && contentRef.current
              ? `${-Math.max((wrapperRef.current.offsetHeight - contentRef.current.offsetHeight) / 2 + 38, 30)}px`
              : '',
          fontSize: '0px',
        }}
      >
        <ul style={{ margin: '-1px 0px' }}> {dots} </ul>
      </div>
    ),
  };

  function closeWithRefresh() {
    console.log('close');
    close({ refresh: true });
  }
  const example = [
    { courseId: 'course-0', name: 'fa', description: 's' },
    { courseId: 'course-0', name: 'fa', description: 's' },
    { courseId: 'course-0', name: 'fa', description: 's' },
  ];
  return (
    <div
      className={`w-screen h-screen bg-[#EFF2FF] overflow-hidden flex flex-col
    `}
    >
      <div className={`w-full flex-1 relative`} ref={wrapperRef}>
        <div
          ref={contentRef}
          style={{
            marginTop:
              wrapperRef.current && contentRef.current ? Math.max(`${(wrapperHeight - contentHeight) / 2}`, '0') : '',
          }}
          className="max-h-[463px] h-full flex flex-col   "
        >
          <div className="px-[20px] text-[24px] leading-[30px]  font-bold mb-[20px] flex justify-center items-center ">
            <p className="mr-[3px]">이어서 하면 좋은 코스예요 </p>
            <img src={emoji_great} alt="empty" className="h-[28px]" />
          </div>

          {example.length > 1 && (
            <div className="w-full h-full  flex flex-col">
              <div className={`w-full flex-1  `} id="recommendedCourseSwiper">
                <Slider {...settings}>
                  {example.map((course, index) => (
                    <div
                      key={`courseSlide${index}`}
                      style={{ width: window.innerWidth - 80 > 295 ? 295 : window.innerWidth - 80 }}
                      className="h-full "
                    >
                      <RecommendedCourseCard course={course} key={`recommendedCourse${index}`} />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className={`w-full h-[30px] bg-black opacity-0 `} />
            </div>
          )}
          {example.length === 1 && (
            <div className="w-full h-full  flex flex-col">
              <div className={`w-full flex-1  `}>
                <div
                  style={{ width: window.innerWidth - 80 > 295 ? 295 : window.innerWidth - 80 }}
                  className="h-full mx-auto"
                >
                  <RecommendedCourseCard course={example[0]} />
                </div>
              </div>
              <div className={`w-full h-[30px] bg-black opacity-0 `} />
            </div>
          )}
        </div>
      </div>
      <div className={`w-full shrink-0 ${example.length === 1 ? 'h-0' : 'h-[38px]'} bg-black opacity-0`} />
      <div className={`px-20px ${context.biggerMargin ? 'h-[68px]' : 'h-[54px]'} w-full z-[50]`}>
        <p
          className="h-[34px]  text-[15px] leading-[18px] text-[#80838B] text-center py-[7px] underline decoration-[1px] underline-offset-1"
          onClick={closeWithRefresh}
        >
          다음에 알아보기
        </p>
      </div>
    </div>
  );
}
