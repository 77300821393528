import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from '../utils/axiosReal';

export default function useFetch(request) {
  const [value, setValue] = useState('');
  const [searchParams] = useSearchParams();
  const params = request.params || {};

  const fetchData = useCallback(async () => {
    try {
      const response = !request.requestBody
        ? await axios(1, searchParams.get('at'), searchParams.get('app_id'), request.bg).get(request.url, {
            params: params,
          })
        : await axios(1, searchParams.get('at'), searchParams.get('app_id'), request.bg).post(
            request.url,
            request.requestBody,
            {
              params: params,
            },
          );

      if (response.data.code === 0) {
        const data = response.data;
        console.log('DATA', request, data);
        setValue(data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.error('result', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, request.params, request.requestBody, request.url, params]);

  useEffect(() => {
    for (var [key, value] of searchParams.entries()) {
      if (
        key !== 'at' &&
        key !== 'app_id' &&
        // key !== 'user_id' &&
        key !== 'refresh' &&
        key !== 'navigation' &&
        !Object.keys(params).includes(key)
      )
        params[key] = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, params]);

  return [value, fetchData];
}
