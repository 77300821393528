import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CoachingContext } from '../../coachingContext';
import { PressedEffect } from '../../stories/button/PressedEffect';
import { goToScreen } from '../../utils/webview';

const RecommendedCounselorCard = ({ recommendList, isScrolling }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    if (recommendList) {
      console.log('recommend', recommendList);
      setIsLoaded(true);
    }
  }, [recommendList]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 600) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const goCounselor = (id) => {
    goToScreen({
      screenName: 'counselorDetail',
      counselorId: id,
    });
  };

  const xScroll = useRef();
  const context = useContext(CoachingContext);

  return (
    <div className={`${isLoaded ? 'opacity-1' : 'opacity-0'} mb-[60px]`}>
      <>
        {!isSmall && recommendList && recommendList.counselorList.length !== 0 && (
          <p className="px-[20px] text-[20px] leading-[26px] font-bold mt-[20px]">{`내게 맞는 상담사`}</p>
        )}
        {recommendList && recommendList.counselorList.length !== 0 && (
          <div className="mb-[9px]">
            <div
              ref={xScroll}
              className="w-full overflow-x-scroll scrollbar-hide"
              style={{ WebkitOverflowScrolling: 'touch' }}
              onTouchStart={(e) => isScrolling(true)}
              onTouchMove={(e) => isScrolling(true)}
              onTouchEnd={(e) => isScrolling(false)}
              onTouchCancel={(e) => isScrolling(false)}
              // onClick={() => context.setShowCounselingModal(true)}
            >
              <div
                className="flex items-center h-[40px] relative pl-[20px] pr-[10px]"
                style={{ width: 'fit-content', blockSize: 'fit-content', whiteSpace: 'nowrap' }}
              >
                {recommendList.keywordList.map((keyword, index) => (
                  <span className="text-[20px] leading-[26px] text-[#A4A6B0] mr-[10px]">{`#${keyword}`}</span>
                ))}
                <div
                  onClick={() => {
                    context.setShowCounselingModal(true);
                  }}
                >
                  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_277_5318)">
                      <circle cx="19" cy="18" r="15" fill="white" />
                      <circle cx="19" cy="18" r="14.5" stroke="#D6D8DC" />
                    </g>
                    <path
                      d="M25.2145 20.0489L23.1081 16.809C23.0216 16.676 23.1171 16.5 23.2758 16.5H27.4882C27.6469 16.5 27.7423 16.6759 27.6559 16.809L25.5499 20.0489C25.471 20.1702 25.2934 20.1703 25.2145 20.0489Z"
                      fill="#636569"
                    />
                    <path
                      d="M25.5073 18C25.5073 14.4101 22.5971 11.5 19.0073 11.5C17.2572 11.5 15.6686 12.1917 14.5 13.3166"
                      stroke="#636569"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M12.786 15.9511L14.8924 19.191C14.9789 19.324 14.8834 19.5 14.7247 19.5L10.5123 19.5C10.3536 19.5 10.2582 19.3241 10.3446 19.191L12.4506 15.9511C12.5295 15.8298 12.7071 15.8297 12.786 15.9511Z"
                      fill="#636569"
                    />
                    <path
                      d="M12.4932 18C12.4932 21.5899 15.4034 24.5 18.9932 24.5C20.7433 24.5 22.3319 23.8083 23.5005 22.6834"
                      stroke="#636569"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <defs>
                      <filter
                        id="filter0_d_277_5318"
                        x="0"
                        y="0"
                        width="38"
                        height="38"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_277_5318" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_277_5318" result="shape" />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="max-w-[380px] px-[20px] mx-auto">
          {recommendList &&
            recommendList.counselorList.map((counselor, index) => (
              <PressedEffect
                element={
                  <div
                    className={`w-full max-w-[380px] mx-auto bg-white rounded-[20px] overflow-hidden ${
                      index !== 2 && 'mb-[20px]'
                    }`}
                    style={{
                      boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.22)',
                    }}
                  >
                    <div className="flex flex-col items-center justify-between mt-[40px]">
                      <div className="flex flex-col mx-auto w-full bg-[#F3F5F8] w-[140px] h-[140px] rounded-[100%] overflow-hidden relative">
                        <div
                          className="w-full h-full bg-black opacity-[0.1] absolute"
                          style={{
                            borderRadius: '16px 16px 0 0',
                          }}
                        />
                        <img
                          src={counselor.profileImageUrl}
                          // src={counselorList.length !== 0 && counselorList[0].profileImageUrl}
                          alt={'empty'}
                          className="w-full"
                        />
                      </div>
                      <div className="mt-[15px] mb-[7px] text-[20px] leading-[26px] font-bold text-[#26282C] line-clamp-2 ">
                        {counselor.name} 상담사
                      </div>
                      <div className={`shrink-0 flex items-center text-[15px] leading-[18px] pl-[5px]`}>
                        <div className=" mr-[4px]">
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="7" cy="7" r="6.25" stroke="#3953D9" strokeWidth="1.5" />
                            <path
                              d="M5.1333 8.40039C5.559 8.96713 6.23676 9.33372 7.00014 9.33372C7.76352 9.33372 8.44128 8.96713 8.86698 8.40039"
                              stroke="#3953D9"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                            <circle cx="5.13353" cy="5.60033" r="0.933333" fill="#3953D9" />
                            <circle cx="8.86644" cy="5.60033" r="0.933333" fill="#3953D9" />
                          </svg>
                        </div>
                        <div className=" font-bold text-[#2C4BEC] ">만족도 {counselor.satisfactionRatio}%</div>
                      </div>
                    </div>
                    <div className="py-[20px] px-[20px]">
                      <div className="w-full bg-[#F3F5F8] rounded-[8px] p-[15px]">
                        {counselor.careerList.map((career) => (
                          <div className=" mb-[4px] flex items-start">
                            <div className="w-[3px] h-[3px] rounded-[3px] bg-[#80838B] mr-[5px] mt-[8px] shrink-0" />
                            <p className="text-[15px] leading-[21px] text-[#6B6D76] ">{career}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                }
                action={() => goCounselor(counselor.counselorId)}
              />
            ))}
        </div>
      </>
    </div>
  );
};

export default RecommendedCounselorCard;
