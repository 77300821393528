import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { PressedEffect } from '../../stories/button/PressedEffect';

export function HabitShare(props) {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const [isClicked, setIsClicked] = useState(false);

  const Title = ({ title }) => <p className="text-white text-[40px] leading-[48px] font-bold pb-[12px]">{title}</p>;

  useEffect(() => {
    if (isClicked) {
      setTimeout(() => {
        setIsClicked(false);
      }, 2000);
    }
  }, [isClicked]);

  const dataList = [
    {
      title: 'Personal link',
      subtitle: 'Copy this link and paste it somewhere to revisit this app and keep your progress safe.',
      link: `habit.im?id=${id}`,
      action: () => {
        window.navigator.clipboard.writeText(`https://dev-m.inside.im/habit?id=${id}`).then(() => {
          // 복사가 완료되면 호출된다.
          if (!isClicked) {
            setIsClicked(true);
          }
        });
      },
    },
    {
      title: 'Link for share',
      subtitle: 'If you want to share this app with your friends, use this link.',
      link: `habit.im`,
      action: () => {
        window.navigator.clipboard.writeText('https://dev-m.inside.im/habit').then(() => {
          // 복사가 완료되면 호출된다.
          if (!isClicked) {
            setIsClicked(true);
          }
        });
      },
    },
  ];

  useEffect(() => {
    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';
  }, []);

  return (
    <div
      className={`flex flex-col relative font-poppins`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
        backgroundColor: '#131635',
      }}
    >
      <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col justify-between`}>
        <div className="flex-1">
          <div className="px-[20px] flex flex-col gap-[60px] pt-[20px] pb-[40px]">
            {dataList.map((item) => (
              <div>
                <Title title={item.title} />
                <p className="text-[18px] leading-[21.6px] text-white opacity-[0.6] pb-[20px]">{item.subtitle}</p>
                <PressedEffect
                  element={
                    <div
                      className="w-full px-[31px] py-[32px] rounded-[16px]"
                      style={{
                        background: 'rgba(255, 255, 255, 0.05)',
                      }}
                    >
                      <p className="text-[20px] leading-[22px] font-bold text-white text-center">{item.link}</p>
                    </div>
                  }
                  action={item.action}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        tabIndex="1"
        className="absolute bottom-[-100px] w-full pb-[20px] z-[50] bg-[#131635]"
        animate={{ opacity: 1, y: isClicked ? -100 : 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className={`w-full relative flex justify-center `}>
          <div className="py-[16px] flex justify-between items-center w-[calc(100%-40px)] bg-[#FFE03C] rounded-[16px] px-[20px] text-[15px] leading-[18px] font-bold text-[#131635]">
            <p>Copied to clipboard</p>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
