import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import { useSetRecoilState } from 'recoil';
import Intro0 from './Intro0';
import Intro1 from './Intro1';
import Intro2 from './Intro2';
import FadeTransition from '../../FadeTransition';

export default function Intro() {
  const { id } = useParams();
  const location = useLocation();
  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_BACK,
      headerTitle: '',
      headerColor: '#ffffff',
    }));
  }, []);

  return (
    <FadeTransition>
      <div key={location.pathname}>
        {parseInt(id) === 0 ? <Intro0 /> : parseInt(id) === 1 ? <Intro1 /> : <Intro2 />}
      </div>
    </FadeTransition>
  );
}
