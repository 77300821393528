import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PaymentConfirmFailTemplate from 'pages/PaymentConfirmFailTemplate';

export default function SomnyPaymentConfirmFail() {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('at');
  const appId = searchParams.get('app_id');

  return (
    <PaymentConfirmFailTemplate
      fetchInvoiceDetailUrl={`/somny/payment/invoice/detail`}
      successUrlPath={`somnyPayment-success?at=${accessToken}&app_id=${appId}`}
      failUrlPath={`somnyHome?at=${accessToken}&app_id=${appId}`}
      isSomny
    />
  );
}
