const FullImage = ({ image, height, margin, noMargin }) => {
  return (
    <div
      className={`${
        margin ? margin : noMargin ? 'my-0' : 'my-[30px]'
      } w-full relative overflow-hidden rounded-2xl flex align-center`}
      style={{ minHeight: height ? height : '190px' }}
    >
      <div className="w-full min-w-[335px]  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
        <img className="w-full h-full object-fit" src={image} alt="empty" />
      </div>
    </div>
  );
};

export default FullImage;
