import React, { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
import MessageBlock from '../../stories/workbook/MessageBlock';

function UserEmotionRecord({ emotion }) {
  const context = useContext(CoachingContext);
  console.log('워크', context.workbookAnswer[1]);

  const defaultDataByEmotion = [
    { emotion: '기쁨', defaultData: ['최근에 언제 기분이 좋았나요?', '어떻게 기뻤나요?'] },
    { emotion: '슬픔', defaultData: ['최근에 언제 기분이 슬펐나요?', '어떻게 슬펐나요?'] },
    { emotion: '불쾌함', defaultData: ['최근에 언제 기분이 불쾌했나요?', '어떻게 불쾌했나요?'] },
    { emotion: '화남', defaultData: ['최근에 언제 화가났나요?', '어떻게 화가 났나요?'] },
    { emotion: '두려움', defaultData: ['최근에 언제 두려웠나요?', '어떻게 두려웠나요?'] },
  ];

  const info = defaultDataByEmotion.filter((item) => item.emotion === emotion)[0];
  return (
    <div className="min-h-[176px]">
      {context.workbookAnswer && (
        <div className="my-40px">
          <MessageBlock
            textList={[{ title: info.defaultData[0], text: [context.workbookAnswer[0]] }]}
            type="gray"
            margin="mb-[10px]"
          />
          <MessageBlock
            textList={[{ title: info.defaultData[1], text: [context.workbookAnswer[1]] }]}
            type="white_dotted"
          />
        </div>
      )}
    </div>
  );
}

export default UserEmotionRecord;
