/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context';

import Somny2Start from './Somny2Start';
import Somny2QA from './Somny2QA';
import Somny2End from './Somny2End';
import Somny2Analysis from './Somny2Analysis';
import Somny2Result from './Somny2Result';
import Somny2Result2 from './Somny2Result2';
import Somny2Result3 from './Somny2Result3';
import Somny2Result4 from './Somny2Result4';

export default function Somny2() {
  let { id } = useParams();
  const context = useContext(AuthContext);

  const choices = [
    { selfCheckChoiceId: 1, score: 0, choice: '전혀 아님' },
    { selfCheckChoiceId: 2, score: 1, choice: '아님' },
    { selfCheckChoiceId: 3, score: 2, choice: '보통' },
    { selfCheckChoiceId: 4, score: 3, choice: '그럼' },
    { selfCheckChoiceId: 5, score: 4, choice: '자주 그럼' },
  ];

  const choices_type = [
    { selfCheckChoiceId: 6, choice: '아님' },
    { selfCheckChoiceId: 7, choice: '그럼' },
  ];

  const types = ['낮 시간 활동 문제', '저녁 시간 활동 문제', '수면 습관 문제', '정서적 문제'];

  const qcList = [
    {
      id: 1,
      question: '누웠는데도 잠들기 어려워요',
    },
    {
      id: 2,
      question: '잠자다 자꾸 깨요',
    },
    {
      id: 3,
      question: '아침에 너무 일찍 깨어 다시 잠들기 어려워요',
    },
    {
      id: 4,
      question: '현재의 수면 패턴에 만족해요',
    },
    {
      id: 5,
      question: '불면증이 일상을 방해하고 있어요 (주간 피로, 업무와 가사 능력, 집중력, 기억력, 기분 등)',
    },
    {
      id: 6,
      question: '내 삶이 불면증으로 손상되었다는 것을 다른 사람이 알아차릴 수 있어요',
    },
    {
      id: 7,
      question: '현재 불면증을 걱정하고 있어요',
    },
    {
      id: 8,
      type: types[0],
      question: '종종 낮잠을 잔다',
    },
    {
      id: 9,
      type: types[0],
      question: '낮에도 주로 어두운 곳에서 생활한다',
    },
    {
      id: 10,
      type: types[0],
      question: '커피를 즐겨 마신다',
    },
    {
      id: 11,
      type: types[1],
      question: '잠들기 2시간 전에 운동할 때가 많다',
    },
    {
      id: 12,
      type: types[1],
      question: '자기 전에 과식할 때가 있다',
    },
    {
      id: 13,
      type: types[1],
      question: '잠들기 직전까지 일어나 공부를 한다',
    },
    {
      id: 14,
      type: types[2],
      question: '잠이 안 와도 침대에 누워있는다',
    },
    {
      id: 15,
      type: types[2],
      question: '졸리지 않아도 자러 갈 때가 많다',
    },
    {
      id: 16,
      type: types[2],
      question: '침대에서 휴대폰을 보거나 업무를 할 때가 있다',
    },
    {
      id: 17,
      type: types[3],
      question: '근심과 걱정 때문에 뒤척일 때가 있다',
    },
    {
      id: 18,
      type: types[3],
      question: '못 잘까봐 불안해서 뒤척일 때가 있다',
    },
    {
      id: 19,
      type: types[3],
      question: '밤이 되면 혼자가 된 것 같은 기분이 든다',
    },
  ];

  return (
    <div className={`w-screen   `}>
      {parseInt(id) === 0 && <Somny2Start />}
      {parseInt(id) > 0 && !isNaN(parseInt(id)) && (
        <Somny2QA choices={choices} choices_type={choices_type} qcList={qcList} />
      )}
      {id === 'end' && <Somny2End />}
      {id === 'analysis' && <Somny2Analysis types={types} />}
      {id === 'result' && <Somny2Result types={types} />}
      {id === 'result2' && <Somny2Result2 types={types} />}
      {id === 'result3' && <Somny2Result3 />}
      {id === 'result4' && <Somny2Result4 />}
    </div>
  );
}
