import React, { useEffect, useState } from 'react';

import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin, hasGrantedAllScopesGoogle } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import google_logo from '../../image/google_logo.png';
import axios from '../../utils/axiosGoogle';
import qs from 'qs';
import webviewToast from '../../development/webviewToast';
import { login } from '../../utils/webview';
import { AnxyActionButton } from '../../stories/button/ActionButton';

export default function GoogleButton() {
  const clientId = '250716209381-5n63flftdcg30bkkbucrn9dmp79tevag.apps.googleusercontent.com'; //dev
  const secret_clientId = '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';
  const navigate = useNavigate();

  // const getCredential = (codeResponse) => {
  //   console.log(codeResponse.credential, jwt_decode(codeResponse.credential));
  //   navigate(`/#id_token=${codeResponse.credential}`);
  // };
  // const getCredentialCustom = (codeResponse) => {
  //   console.log('ksb');
  //   console.log('----', codeResponse);
  //   // getIdToken(codeResponse.code, clientId, secret_clientId);
  // };

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     getCredentialCustom(codeResponse);
  //   },
  //   onError: (errorResponse) => {
  //     webviewToast('error');
  //     console.log(errorResponse);
  //   },
  //   flow: 'auth-code',
  //   ux_mode: 'redirect',
  //   redirect_uri: window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://dev-m.inside.im',
  // });

  // const getIdToken = async (code, clientId, secret) => {
  //   try {
  //     const response = await axios.post(
  //       `/token`,
  //       qs.stringify({
  //         code: code,
  //         client_id: clientId,
  //         client_secret: secret,
  //         redirect_uri: window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://dev-m.inside.im',
  //         grant_type: 'authorization_code',
  //       }),
  //     );
  //     if (response) {
  //       console.log(jwt_decode(response.data.id_token));
  //       navigate(`/#id_token=${response.data.id_token}&state=google`);
  //     }
  //   } catch (error) {
  //     console.error('result', error.debugMessage);
  //   }
  // };

  return (
    <div className="w-full">
      {/* <div className="mb-[10px]">
        <GoogleLogin
          onSuccess={(codeResponse) => {
            getCredential(codeResponse);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          text={'Google 계정으로 계속하기'}
        />
      </div> */}
      {/* <div
        className="w-full py-[14px] px-[20px] bg-white rounded-[16px] flex justify-center gap-[10px] ring-[1px] ring-inset ring-[rgba(0,0,0,0.1)]"
        onClick={() => {
          login({ type: 'google' });
        }}
      >
        <img src={google_logo} alt={'empty'} className="w-[24px]" />
        <p className="text-[17px] leading-[26px] font-semibold text-[#3A3C40]"> Google 로그인</p>
      </div> */}

      <AnxyActionButton
        state={'ACTIVE'}
        activeColor={'#ffffff'}
        textColor={'#3A3C40'}
        borderColor={'rgba(0,0,0,0.1'}
        text={
          <div className="flex justify-center gap-[10px]">
            <img src={google_logo} alt={'empty'} className="w-[24px] " />
            <p> Google 로그인</p>
          </div>
        }
        action={() => {
          login({ type: 'google' });
        }}
      />
    </div>
  );
}
