import React, { useState, useEffect } from 'react';
import TutorialList from '../components/tutorial/TutorialList';

function Tutorial() {
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = '상담 이용 안내';
  }, []);
  return (
    <div className={`w-screen py-5 `}>
      <TutorialList />
    </div>
  );
}

export default Tutorial;
