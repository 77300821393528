import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import CounselorCard from '../components/counselor/CounselorCard';
import axios from '../utils/axios';
import axios2 from '../utils/axios2';
import { changeHeader } from '../utils/webview';
import { showModal, scrollEvent, getRefresh, getScrollEvent } from '../utils/webview';
import { BUILD_TARGET } from '../config';
import RandomCounselorCard from '../components/counselor/RandomCounselorCard';
import RecommendedCounselorCard from '../components/counselor/RecommendedCounselorCard';
import { useSearchParams } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';
import CounselingModal from '../components/elements/CounselingModal';
import { CoachingContext } from '../coachingContext';
import { motion } from 'framer-motion';
import InsideOriginalCard from '../components/counselor/InsideOriginalCard';
import SelectCounselorBySpecialty from '../components/counselor/SelectCounselorBySpecialty';
import useDetectIOS from '../hooks/useDetectIOS';
import ContainerByDevice from '../components/elements/ContainerByDevice';

export default function Counseling() {
  const [counselorList, setCounselorList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [previousScrollTop, setPreviousScrollTop] = useState(0);
  const [showHeader, setShowHeader] = useState(false);
  const [isRefresh, setRefresh] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isIOS] = useDetectIOS(false);

  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appId = searchParams.get('app_id');

  const tokenList = [
    '741a3a5b003d24ea49d259ab2ec96324',
    '432fc95dc7e26b42f5cb10a92a2e510c8ee273ec25c4a29612617f79ef4f8aeb',
    'a335f8cb9d6568ad412375bd188cf9c22d689e626e3740e78461de1d08ba26d1',
    'b71ee3eda101be7009df3e67f7861b588615d197d9d4e4baf7214256ea6bcd6a',
  ];

  const isTestUser = tokenList.includes(authToken);

  const titleRef = useRef();
  const buttonRef = useRef();
  const scrollRef = useRef();
  const interviewRef = useRef();
  const contentRef = useRef();

  const isDev = BUILD_TARGET === 'dev';

  const [randomCounselor, setRandomCounselor] = useState([]);
  const [recommendList, setRecommendList] = useState([]);

  const getRandomCounselor = async (index) => {
    try {
      await axios2(3, authToken, appId, '#F3F5F8')
        .get('/counselor/random-profile/list')
        .then((response) => {
          const data = response.data;
          setIsLoaded(true);
          setRandomCounselor(data.result);
        });
    } catch (error) {}
  };

  const getRecommendList = async (index) => {
    try {
      await axios2(3, authToken, appId, '#F3F5F8')
        .get('/counselor/recommendation/list')
        .then((response) => {
          const data = response.data;
          console.log('reco', data.result);
          if (data.result.counselorList.length !== 0) {
            setIsLoaded(true);
            setRecommendList(data.result);
          } else {
            getRandomCounselor();
          }
        });
    } catch (error) {}
  };

  const isScrolling = (boolean) => {
    if (!isIOS) {
      if (boolean) {
        scrollEvent(1);
      } else {
        scrollEvent(window.scrollY);
      }
    }
  };

  function refresh() {
    console.log('refresh', counselorList);
    if (showHeader) {
      changeHeader({ backgroundColor: '#FFFFFF', text: '상담' });
    } else {
      changeHeader({ backgroundColor: '#F3F5F8', text: '' });
    }
    setCounselorList([]);
    setRandomCounselor([]);
    setRecommendList([]);
    getRecommendList();
    setPageNum(0);
    setPageLoaded(false);
    setPreviousScrollTop(0);
    setRefresh(true);
    setIsLoaded(false);
  }

  useEffect(() => {
    console.log('pageNum 바뀜', pageNum);
    if (isRefresh && pageNum === 0) {
      console.log('REFRESH----------------------------------------');
      setRefresh(false);
      getCounselor(pageNum);
    }
  }, [isRefresh, pageNum]);

  useEffect(() => {
    getRecommendList();
    getRefresh(refresh);
    getScrollEvent(scrollRef);
  }, []);

  function showInstruction() {
    console.log('show tutorial');
    showModal({
      url: `${isDev ? `https://dev-m.inside.im/tutorial` : `https://m.inside.im/tutorial`}`,
      title: '이용안내',
      type: 'web',
    });
  }

  const getCounselor = async (pageNum) => {
    try {
      console.log('데이터 불러와 pageNum: ', pageNum);
      setDataLoaded(false);
      const response = await axios.get(`/counselor/v2/list?page_number=${pageNum}&size=${isDev ? 20 : 50}`);

      if (response.data.code === 0) {
        console.log('response: ', response, response.data.result.counselorList.length, pageNum);
        let result = response.data.result.counselorList;
        for (let i = 0; i < result.length; i++) {
          let parsed_interview = [];
          const temp = result[i].contents.body.split('\n');

          for (let j = 0; j < temp.length; j++) {
            parsed_interview.push(temp[j]);
            if (j < temp.length - 1) {
              parsed_interview.push(<br />);
            }
          }
          result[i].contents.body = parsed_interview;
        }
        console.log('original', counselorList, pageNum);
        const newCounselorList = counselorList.slice().concat(result);
        console.log('new', newCounselorList, pageNum);

        setCounselorList(newCounselorList);
        setDataLoaded(true);
        console.log('PAGENUM', pageNum);
        setPageNum((pageNum) => pageNum + 1);
      }
    } catch (error) {
      console.error('result', error);
    }
  };

  useEffect(() => {
    document.title = '상담';

    console.log('getCounselor');
    getCounselor(pageNum);
  }, []);

  const context = useContext(CoachingContext);

  useEffect(() => {
    if (context.showCounselingModal) {
      if (showHeader) {
        changeHeader({ backgroundColor: '#00000080', text: '상담', buttonType: 'none' });
      } else {
        changeHeader({ backgroundColor: '#00000080', text: '', buttonType: 'none' });
      }
    } else {
      if (showHeader) {
        changeHeader({ backgroundColor: '#FFFFFF', text: '상담' });
      } else {
        changeHeader({ backgroundColor: '#F3F5F8', text: '' });
      }
    }
  }, [context.showCounselingModal, showHeader]);

  const handleScroll = (e) => {
    if (!context.showCounselingModal) {
      if (isIOS) {
        scrollEvent(e.target.scrollTop);
      } else {
        scrollEvent(window.scrollY);
        if (titleRef.current) {
          if (window.scrollY > titleRef.current.offsetHeight) {
            changeHeader({ backgroundColor: '#FFFFFF', text: '상담', buttonType: 'none' });
            setShowHeader(true);
          } else {
            changeHeader({ backgroundColor: '#F3F5F8', text: '', buttonType: 'none' });
            setShowHeader(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      if (window !== null) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <ContainerByDevice css="relative">
      <CounselingModal />
      {context.showCounselingModal && (
        <div
          className={`bg-black  ${
            context.showCounselingModal ? 'opacity-[0.5]' : 'opacity-0'
          } absolute top-0 w-full h-full  z-[20]`}
          onClick={() => context.setShowCounselingModal(false)}
        />
      )}
      <>
        {isLoaded ? (
          <div
            className="w-screen h-full  bg-[#F3F5F8] overflow-auto scrollbar-hide"
            ref={scrollRef}
            onScroll={(e) => {
              if (isIOS) {
                console.log('scrollevent: ', counselorList, pageNum);
                if (e.target.scrollTop > titleRef.current.offsetHeight) {
                  console.log('상담');
                  changeHeader({ backgroundColor: '#FFFFFF', text: '상담' });
                  setShowHeader(true);
                  console.log('스크롤', contentRef.current.offsetHeight, buttonRef.current.offsetHeight);
                } else {
                  console.log('흰');
                  changeHeader({ backgroundColor: '#F3F5F8', text: '' });
                  setShowHeader(false);
                }

                if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - 1000 && !isRefresh) {
                  if (!pageLoaded) {
                    console.log('load more', counselorList, pageNum);
                    getCounselor(pageNum);
                    setPageLoaded(true);
                  }
                } else {
                  setPageLoaded(false);
                }
                handleScroll(e);

                setPreviousScrollTop(e.target.scrollTop);
              }
            }}
          >
            <div
              className="bg-[#F3F5F8]  pt-[12px] pl-[20px] pr-[15px] pb-[10px] flex items-center justify-between"
              ref={titleRef}
            >
              <p className="font-bold text-[24px] leading-[28.8px] text-[#26282C]">상담</p>
              <p className="font-normal text-[15px] leading-[18px] text-[#4E4F53] p-[5px]" onClick={showInstruction}>
                이용안내
              </p>
            </div>
            <div ref={interviewRef} className={`pb-[20px] ${isLoaded ? 'opacity-1' : 'opacity-0'}`}>
              <InsideOriginalCard />
              <SelectCounselorBySpecialty />
              <div ref={contentRef}>
                {isLoaded && recommendList.length !== 0 && (
                  <RecommendedCounselorCard recommendList={recommendList} isScrolling={isScrolling} />
                )}

                {isLoaded && recommendList.length === 0 && (
                  <RandomCounselorCard randomCounselor={randomCounselor} recommendList={recommendList} />
                )}
              </div>
              <p className="px-[20px] text-[20px] leading-[26px] font-bold mb-[15px]">선생님 인터뷰</p>
              {counselorList.length > 0 &&
                counselorList.map((counselor, index) => (
                  <div key={index} className="px-[20px] mb-[20px]">
                    <CounselorCard
                      id={counselor.counselorId}
                      title={counselor.contents.title}
                      interview={counselor.contents.body}
                      image={counselor.profileImageUrl}
                      introduce={counselor.introduce}
                      name={counselor.name}
                      dataLoaded={dataLoaded}
                    />
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="w-screen h-screen flex justify-center items-center bg-[#F3F5F8]">
            <DotLoader color="#3953D9" loading={true} size={80} />
          </div>
        )}
      </>
    </ContainerByDevice>
  );
}
