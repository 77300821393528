export default function SwiperContent(props) {
  const { step, category } = props;
  return (
    <div className="pb-20px px-20px">
      <p className={`text-[17px] font-semibold ${category ? 'mt-32px' : 'mt-20px'} mb-4px  leading-[26px]`}>
        {step[0]}
      </p>
      <div className="text-gray-500 text-content leading-[24px] mb-20px  ">{step[1]}</div>
      <div className="w-full rounded-2xl bg-[#E1E4EB] mx-auto mb-5">
        <img className="object-contain mx-auto " src={step[2]} alt="empty" />
      </div>
    </div>
  );
}
