import React from 'react';
import TutorialItem from './TutorialItem';
import A1 from '../../image/1-1.png';
import A2 from '../../image/1-2.png';
import A3 from '../../image/1-3.png';
import B1 from '../../image/2-1.png';
import B2 from '../../image/2-2.png';
import B3 from '../../image/2-3.png';
import B4 from '../../image/2-4.png';
import B5 from '../../image/2-5.png';
import C1 from '../../image/3-1.png';
import C2 from '../../image/3-2.png';
import C3 from '../../image/3-3.png';
import D1 from '../../image/4-1.png';
import D2 from '../../image/4-2.png';
import D3 from '../../image/4-3.png';

export default function TutorialList(props) {
  return (
    <div className="w-screen mb-20px select-none">
      <p className="text-questionTitle font-bold ml-20px mt-30px mb-20px">인사이드 상담 알아보기 </p>
      <TutorialItem
        category={'선생님 찾기'}
        steps={[
          ['STEP 1', '스크롤하며 둘러보거나 상세 탐색 메뉴를 이용하세요.', A1],
          ['STEP 2', '필터를 사용하면 분야별 선생님을 쉽게 찾을 수 있어요.', A2],
          ['STEP 3', '선생님을 찾으셨다면 상세화면에서 예약을 진행하세요.', A3],
        ]}
      />

      <TutorialItem
        category={'상담 예약하기'}
        steps={[
          ['STEP 1', '달력에서 나에게 맞는 시간을 선택하세요.', B1],
          ['STEP 2 ', '전문 분야를 참고해서 상담 주제를 선택하세요.', B2],
          ['STEP 3', '선생님이 참고할 수 있도록 고민을 자세히 적어주세요.', B3],
          ['STEP 4', '예약 정보를 확인하고 결제를 진행해 주세요.', B4],
          ['STEP 5', '선생님이 예약을 확정해야 예약일에 상담이 진행돼요.', B5],
        ]}
      />
      <TutorialItem
        category={'상담받기'}
        steps={[
          ['STEP 1', '마이페이지 > 상담 내역에서 상담 시간을 확인하세요.', C1],
          ['STEP 2', '상담실은 예약시간 1시간 전에 생성돼요.', C2],
          ['STEP 3', '선생님과의 상담은 약 50분간 진행됩니다.', C3],
        ]}
      />
      <TutorialItem
        category={'상담 후기 남기기'}
        steps={[
          ['STEP 1', '상담을 마치면 바로 후기를 작성할 수 있어요.', D1],
          ['STEP 2', '상담이 어땠는지 느낀대로 자유롭게 선택하세요.', D2],
          ['STEP 3', '바로 작성하지 못했다면 나중에 후기를 남기세요.', D3],
        ]}
      />
    </div>
  );
}
