import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime, {
  thresholds: [
    { l: 's', r: 59, d: 'second' },
    { l: 'ss', r: 4, d: 'minute' },

    { l: 'm', r: 9, d: 'minute' },
    { l: 'mm', r: 29, d: 'minute' },
    { l: 'mmm', r: 59, d: 'minute' },

    { l: 'h', r: 1, d: 'hour' },
    { l: 'hh', r: 23, d: 'hour' },

    { l: 'd', r: 1 },
    { l: 'dd', r: 6, d: 'day' },

    { l: 'w', r: 1 },
    { l: 'ww', r: 4, d: 'week' },

    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y' },
    { l: 'yy', d: 'year' },
  ],
  rounding: Math.floor,
});

dayjs.extend(updateLocale);
dayjs.locale('ko');

dayjs.updateLocale('ko', {
  relativeTime: {
    future: '%s',
    past: '%s 전',
    s: '방금',
    ss: '방금',

    m: '5분',
    mm: '10분',
    mmm: '30분',

    h: '%d시간',
    hh: '%d시간',

    d: '%d일',
    dd: '%d일',

    w: '%d주',
    ww: '%d주',

    MM: '%d개월',
    y: '%d년',
    yy: '%d년',
  },
});

export function convertNumberToStringTime(hour) {
  if (hour - Math.floor(hour) === 0) {
    if (hour === 0) {
      return '0분';
    }
    return `${hour}시간`;
  } else {
    const minute = Math.round((hour - Math.floor(hour)) * 60);
    if (Math.floor(hour) === 0) {
      return `${minute}분`;
    }
    return `${Math.floor(hour)}시간 ${minute}분`;
  }
}

export function convertMinutesToStringTime(minutes) {
  if (minutes === 0) {
    return '0분';
  }
  if (minutes % 60 === 0) {
    return `${minutes / 60}시간`;
  } else {
    if (parseInt(minutes / 60) === 0) {
      return `${minutes}분`;
    }
    return `${parseInt(minutes / 60)}시간 ${minutes - parseInt(minutes / 60) * 60}분`;
  }
}

export function convertMinutesToISO(minutes) {
  let convertedMin = minutes;
  if (minutes < 0) {
    convertedMin = 24 * 60 + minutes;
  }
  if (convertedMin === 0) {
    return '00:00';
  }
  if (convertedMin % 60 === 0) {
    return `${convertedMin / 60}:00`;
  } else {
    if (parseInt(convertedMin / 60) === 0) {
      return `00:${convertedMin}`;
    }
    return `${String(parseInt(convertedMin / 60)).padStart(2, '0')}:${String(
      convertedMin - parseInt(convertedMin / 60) * 60,
    ).padStart(2, '0')}`;
  }
}

export function getTimePassedNumber(start, end) {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const hour = (endTime - startTime) / (1000 * 60 * 60);
  return hour;
}

export function getTimePassedString(start, end) {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const hour = (endTime - startTime) / (1000 * 60 * 60);

  return convertNumberToStringTime(hour);
}

export function getDateString(date) {
  const offset = new Date().getTimezoneOffset() * 60000;
  const today = new Date(Date.now() - offset).toISOString().substring(0, 10);
  if ((new Date(today) - new Date(date)) / (1000 * 3600 * 24) === 1) {
    return '어제';
  } else if ((new Date(today) - new Date(date)) / (1000 * 3600 * 24) === 0) {
    return '오늘';
  } else {
    return `${parseInt(date.split('-').slice(1, 2))}월 ${parseInt(date.split('-').slice(2))}일 ${getDay(date)}요일`;
  }
}

export function getDay(date) {
  const days = ['일', '월', '화', '수', '목', '금', '토'];
  return days[new Date(date).getDay()];
}

export function getConvertedDay(date, stringList) {
  const offset = new Date().getTimezoneOffset() * 60000;
  const today = new Date(Date.now() - offset).toISOString().substring(0, 10);
  // const today = new Date().toISOString().substring(0, 10);
  // console.log(new Date(Date.now() - offset).toISOString(), (new Date(today) - new Date(date)) / (1000 * 3600 * 24));
  if ((new Date(today) - new Date(date)) / (1000 * 3600 * 24) === 1 && stringList && stringList.includes('어제')) {
    return '어제';
  } else if (
    (new Date(today) - new Date(date)) / (1000 * 3600 * 24) === 0 &&
    stringList &&
    stringList.includes('오늘')
  ) {
    return '오늘';
  }
  return getDay(date);
}

export function getMinutes(dateString) {
  const date = new Date(dateString);
  // console.log(date.getHours(), date.getMinutes());
  return date.getHours() * 60 + date.getMinutes();
}

export function arraySum(arr) {
  return arr.reduce(function add(sum, currValue) {
    return sum + currValue;
  }, 0);
}

export function getDurationSecond(start, end) {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const second = (endTime - startTime) / 1000;
  return parseInt(second);
}

export function getHHMMSSFromSecond(seconds) {
  const hour = parseInt(seconds / (60 * 60));
  const minute = parseInt((seconds - hour * 60 * 60) / 60);
  const second = seconds - (hour * 60 * 60 + minute * 60);

  return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`;
}

export function getTimeDifference(completedAt) {
  // console.log('완료 시간', completedAt);
  return dayjs(completedAt).fromNow();
}

export function getSerializedDaysArray(startDate, n) {
  const dayList = [...Array(n).keys()].map((index) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + index);
    const dataByDefaultFormat = DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd');
    const dataLLFormat = DateTime.fromISO(date.toISOString()).toFormat('LLL dd').split(' ');
    return {
      date: dataByDefaultFormat,
      formatList: dataLLFormat,
    };
  });
  return dayList;
}

export function getHabitFormattedDate(dateList) {
  const arr = [];
  dateList.forEach((item) => {
    console.log(item);
    const dataByDefaultFormat = DateTime.fromISO(new Date(item.date).toISOString()).toFormat('yyyy-MM-dd');
    const dataLLFormat = DateTime.fromISO(new Date(item.date).toISOString()).toFormat('LLL dd').split(' ');
    arr.push({
      date: dataByDefaultFormat,
      formatList: dataLLFormat,
    });
  });
  return arr;
}

export function getCountdown(startDate, leftDays, format) {
  const start = DateTime.fromISO(new Date(startDate).toISOString());
  const endDate = start.plus({ day: leftDays });
  const nowDate = DateTime.fromISO(new Date().toISOString());
  const diff = endDate.diff(nowDate).toFormat(format);
  return diff;
}

export function getTimeZone(time) {
  return DateTime.fromISO(time.toISOString()).toFormat('z');
}

export function changeISOToFormatString(date, format) {
  return DateTime.fromISO(date).toFormat(format);
}

export function getDiffTime(startDate, endDate) {
  const start = DateTime.fromISO(new Date(startDate).toISOString());
  const end = DateTime.fromISO(new Date(endDate).toISOString());
  const diff = end.diff(start);
  return diff;
}

export function checkIsNight() {
  const now = DateTime.fromISO(new Date().toISOString());
  const hour = now.hour;
  const minute = now.minute;
  if ((hour >= 20 && hour <= 24) || (hour >= 0 && hour <= 5) || (hour === 5 && minute <= 59)) {
    return true;
  } else {
    return false;
  }
}

export function getNowDateInIOSFormat() {
  return DateTime.now().toISO().split('.')[0];
}

export const checkTimeByHour = (hours) => {
  const hour = parseInt(hours);
  if (hour >= 4 && hour < 12) {
    return '오전';
  } else if (hour >= 12 && hour < 18) {
    return '오후';
  } else if (hour >= 18 && hour < 22) {
    return '저녁';
  } else {
    return '밤';
  }
};

export const addDays = (date, days) => {
  return DateTime.fromISO(date).plus({ day: days });
};
