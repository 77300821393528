import { atom } from 'recoil';

const sessionAtom = atom({
  key: 'betterme_session',
  default: {
    currentSession: undefined,
    openSessions: [0],
    sessionList: [],
  },
});

export default sessionAtom;
