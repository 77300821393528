import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { hasTouch } from 'detect-touch';
import { useContext } from 'react';
import { CoachingContext } from '../../../coachingContext';
// import { useNavigateWithParam } from '../../../hooks/useWorkbookNavigate';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';

export default function ChooseRoute({ textList }) {
  const { id, page } = useParams();
  const [clickedId, setClickedId] = useState();
  const [isPressed, setIsPressed] = useState(false);
  const [select, setSelect] = useState('');
  const [myAnswer, setMyAnswer] = useState('');
  const context = useContext(CoachingContext);
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  function mouseUp(e) {
    setIsPressed(false);
    setMyAnswer(select);
  }

  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  const setSelectPageAndGoNext = (id, pageIndex) => {
    const updated = context.selectedPage;
    if (context.selectedPage.length !== 0) {
      updated[pageIndex] = id;
    } else {
      updated.push(id);
    }
    context.setSelectedPage(updated);
    goNext();
  };

  return (
    <div className="mt-40px font-normal">
      {textList.map((text, index) => (
        <motion.div
          animate={{ scale: isPressed && clickedId === index ? 0.95 : 1 }}
          transition={{ duration: 0.2 }}
          className={`text-left px-20px py-12px w-full rounded-[12px] text-[17px] leading-[26px] mb-10px text-[#26282C]
          ${myAnswer === text && 'font-semibold text-[#3953D9] bg-[#F5F6FD]'}}
          `}
          style={{
            border: myAnswer === text ? '1px solid #3953D9' : '1px solid #D1D5DC',
          }}
          onMouseDown={(e) => {
            if (!hasTouch) {
              setClickedId(index);
              setIsPressed(true);
              setSelect(e.currentTarget.textContent);
            }
          }}
          onTouchStart={(e) => {
            setClickedId(index);
            setIsPressed(true);
          }}
          onTouchEnd={(e) => {
            setIsPressed(false);

            if (
              document
                .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
                .includes(e.currentTarget)
            ) {
              setMyAnswer(e.currentTarget.textContent);

              if (index === 0) {
                console.log(index);
                setSelectPageAndGoNext(0, 0);
              } else {
                setSelectPageAndGoNext(1, 0);
              }
            }
          }}
        >
          <p className="mb-[2px]">{text}</p>
        </motion.div>
      ))}
    </div>
  );
}
