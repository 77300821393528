/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import { changeHeader } from '../../utils/webview';

import SelfCheckStartedCard from '../../components/selfCheck/SelfCheckStartedCard';
import SelfCheckNotStartedCardA from '../../components/selfCheck/SelfCheckNotStartedCardA';
import SelfCheckNotStartedCardB from '../../components/selfCheck/SelfCheckNotStartedCardB';
import selfCheckData from '../../data/selfCheckData';
import useFetch from '../../hooks/useFetch';
import HealthyCard from '../../components/selfCheck/HealthyCard';
import AbnormalCard from '../../components/selfCheck/AbnormalCard';
import useDetectIOS from '../../hooks/useDetectIOS';
import ContainerByDevice from '../../components/elements/ContainerByDevice';

export default function SelfCheck() {
  const context = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const selfCheckOrder = searchParams.get('self_check_order');
  const selfCheckInitialType = searchParams.get('self_check_initial_type');
  const appVersion = searchParams.get('app_version') || 0;
  const scrollId = searchParams.get('scroll_id');

  console.log('scroll id: ', scrollId);
  console.log(context.selfCheckHomeScrollPosition);

  const [showButton, setShowButton] = useState(false);
  const [showNumber, setShowNumber] = useState(3);

  const [notAbnormal, setNotAbnormal] = useState([]);
  const [abnormal, setAbnormal] = useState([]);
  const [notStarted, setNotStarted] = useState([]);
  const [isIOS] = useDetectIOS();

  const selfCheckHomeScrollRef = useRef();
  const resultRef = useRef();

  const [loaded, setLoaded] = useState(false);

  const [selfCheckHomeDetail, getSelfCheckHomeDetail] = useFetch({
    url: `/self-check/v2/latest-result/list`,
    bg: '#F3F5F8',
  });

  const [selfCheckStat, getSelfCheckStat] = useFetch({
    url: `/self-check/v2/stat/detail`,
    bg: '#F3F5F8',
  });
  const [stat, setStat] = useState([]);

  useEffect(() => {
    context.setSelfCheckScrollPosition(0);
    if (selfCheckInitialType === 'stat') {
      getSelfCheckStat();
    }
  }, []);

  useEffect(() => {
    if (selfCheckStat) {
      console.log(selfCheckStat.result);
      setStat(selfCheckStat.result);
    }
  }, [selfCheckStat]);

  useEffect(() => {
    if (selfCheckHomeDetail) {
      selfCheckHomeDetail.result.sort(function (a, b) {
        return a.scorePercentage - b.scorePercentage;
      });

      setShowNumber(Math.min(3, selfCheckHomeDetail.result.filter((element) => !element.isAbnormal).length));
      setShowButton(selfCheckHomeDetail.result.filter((element) => !element.isAbnormal).length > 3);
      setNotAbnormal(selfCheckHomeDetail.result.filter((element) => !element.isAbnormal));
      setAbnormal(selfCheckHomeDetail.result.filter((element) => element.isAbnormal).reverse());

      if (selfCheckOrder) {
        const notStartedList = [];
        for (let selfCheckId of selfCheckOrder.split('')) {
          if (
            selfCheckHomeDetail.result.find((started) => started.selfCheckId === parseInt(selfCheckId)) === undefined
          ) {
            notStartedList.push(selfCheckData.find((element) => element.selfCheckId === parseInt(selfCheckId)));
          }
        }
        setNotStarted(notStartedList);
      } else {
        setNotStarted(
          selfCheckData.filter(
            (element) =>
              selfCheckHomeDetail.result.find((started) => started.selfCheckId === element.selfCheckId) === undefined,
          ),
        );
      }
      setLoaded(true);
    }
  }, [selfCheckHomeDetail]);

  useEffect(() => {
    window.document.title = '자가검사 홈';
    getSelfCheckHomeDetail();

    if (parseInt(appVersion) < 170) {
      changeHeader({ backgroundColor: '#F3F5F8', text: '자가검사', color: '#000000', buttonType: 'close' });
    } else {
      changeHeader({ backgroundColor: '#F3F5F8', text: '자가검사', color: '#000000', buttonType: 'closeWithRefresh' });
    }

    window.document.body.style.backgroundColor = '#F3F5F8';
  }, []);

  useEffect(() => {
    if (abnormal.length !== 0 && notAbnormal.length !== 0) {
      if (scrollId) {
        if (context.selfCheckHomeScrollPosition !== 0) {
          selfCheckHomeScrollRef.current.scrollTop = context.selfCheckHomeScrollPosition;
        } else {
          setTimeout(() => {
            scrollToResult(parseInt(scrollId));
          }, 200);
        }
      }
    }
  }, [abnormal, notAbnormal]);

  useEffect(() => {
    if (loaded) {
      if (isIOS) {
        selfCheckHomeScrollRef.current.scrollTop = context.selfCheckHomeScrollPosition;
      } else {
        window.scrollTo({ top: context.selfCheckHomeScrollPosition });
      }
    }
  }, [loaded]);

  function scrollToResult(selfCheckId) {
    const combined = abnormal.concat(notAbnormal);
    console.log(abnormal, notAbnormal);
    const index = combined.findIndex((element) => element.selfCheckId === selfCheckId);
    console.log('scrollto', selfCheckId, index);
    console.log(selfCheckHomeScrollRef.current);
    if (isIOS) {
      selfCheckHomeScrollRef.current.scrollTo({
        top: resultRef.current.offsetTop + resultRef.current.offsetHeight + 370 * index - 10,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: resultRef.current.offsetTop + resultRef.current.offsetHeight + 370 * index - 10,
        behavior: 'smooth',
      });
    }
  }

  const handleScroll = (e) => {
    if (isIOS) {
      context.setSelfCheckHomeScrollPosition(e.target.scrollTop);
    } else {
      if (window.scrollY !== 0) {
        context.setSelfCheckHomeScrollPosition(window.scrollY);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      if (window !== null) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <ContainerByDevice css={`w-screen`}>
      <div
        className={`w-full ${isIOS && 'h-full overflow-auto scrollbar-hide '}  bg-[#F3F5F8] px-[20px] pt-[20px]`}
        ref={selfCheckHomeScrollRef}
        onScroll={(e) => {
          handleScroll(e);
        }}
      >
        {notAbnormal.length > 0 && (
          <div className=" pb-[60px] ">
            <p className="text-[20px] leading-[26px] text-[#26282C] font-bold  pb-[20px]">👍 나의 마음건강 태그</p>
            {notAbnormal.slice(0, showNumber).map((selfCheckInfo, index) => (
              <div
                key={index}
                className={`${index !== notAbnormal.slice(0, showNumber).length - 1 ? 'mb-[10px]' : ''}`}
              >
                <HealthyCard selfCheckInfo={selfCheckInfo} scrollToResult={scrollToResult} />
              </div>
            ))}
            {showButton && (
              <div className="w-full relative mt-[20px]">
                <div className="absolute w-full h-[1px] bg-[#E1E4EB] top-[50%]" />
                <div
                  className="relative mx-auto w-[30px] h-[30px] rounded-[30px] bg-black flex  justify-center pt-[11px]"
                  onClick={() => {
                    setShowNumber(selfCheckData.length);
                    setShowButton(false);
                  }}
                >
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 1.5L6 6.5L11 1.5"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        )}

        {abnormal.length > 0 && (
          <div className="pb-[40px]">
            <p className="text-[20px] leading-[26px] text-[#26282C] font-bold  pb-[20px]">
              🚨 지속적인 관리가 필요해요
            </p>
            {abnormal.map((selfCheckInfo, index) => (
              <div key={index} className={`${index !== abnormal.length - 1 ? 'mb-[10px]' : ''}`}>
                <AbnormalCard selfCheckInfo={selfCheckInfo} scrollToResult={scrollToResult} />
              </div>
            ))}
          </div>
        )}

        {(notAbnormal.length > 0 || abnormal.length > 0) && (
          <div className="pb-[60px]">
            <p className="text-[20px] leading-[26px] text-[#26282C] font-bold  pb-[20px]" ref={resultRef}>
              검사 내역
            </p>
            {abnormal.map((selfCheckInfo, index) => (
              <div key={index} className={`mb-[10px]`}>
                <SelfCheckStartedCard selfCheckInfo={selfCheckInfo} />
              </div>
            ))}
            {notAbnormal.map((selfCheckInfo, index) => (
              <div key={index} className={` mb-[10px] ${index !== notAbnormal.length - 1 ? 'mb-[10px]' : ''}`}>
                <SelfCheckStartedCard selfCheckInfo={selfCheckInfo} />
              </div>
            ))}
          </div>
        )}

        {notStarted.length > 0 && (
          <div className="pb-[40px]">
            <p className="text-[20px] leading-[26px] text-[#26282C] font-bold  pb-[20px]">아직 받지 않은 검사</p>

            {notStarted.map((selfCheckInfo, index) => (
              <div key={`card${index}`} className={`${index !== notStarted.length - 1 ? 'mb-[10px]' : ''}`}>
                {selfCheckInitialType === 'stat' ? (
                  <SelfCheckNotStartedCardB
                    selfCheckId={selfCheckInfo.selfCheckId}
                    stat={stat.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId)}
                  />
                ) : (
                  <SelfCheckNotStartedCardA selfCheckId={selfCheckInfo.selfCheckId} />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </ContainerByDevice>
  );
}
