import GradientBox from './GradientBox';

const GradientEmotionBox = () => {
  const gradientList = [
    {
      bg: 'linear-gradient(270deg, #FDAF18 0%, rgba(253, 175, 24, 0) 100%)',
      texts: ['평안', '기쁨', '황홀'],
    },
    {
      bg: 'linear-gradient(270deg, #003F9A 0%, rgba(243, 245, 248, 0) 100%)',
      texts: ['수심', '슬픔', '비통'],
    },
    { bg: 'linear-gradient(270deg, #1C603B 0%, rgba(28, 96, 59, 0) 100%)', texts: ['지루함', '불쾌함', '증오'] },
    {
      bg: 'linear-gradient(270deg, #D44C2E 0%, rgba(212, 76, 46, 0) 100%)',
      texts: ['짜증', '화남', '격노'],
    },
    {
      bg: 'linear-gradient(270deg, #003F9A 0%, #270A78 0.01%, rgba(39, 10, 120, 0) 100%)',
      texts: ['불안', '두려움', '공포'],
    },
  ];
  return (
    <div className="text-black mx-[20px] mt-[40px] min-h-[290px]">
      {gradientList.map((item) => (
        <GradientBox item={item} />
      ))}
    </div>
  );
};

export default GradientEmotionBox;
