import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

export default function AccordianItemWithoutBorder(props) {
  const { title, content, fontColor } = props;
  const [isSeeMore, setSeeMore] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const detailRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  }, []);

  function seeAnswer() {
    setRotate((rotate) => rotate + 1);
    setSeeMore((isSeeMore) => !isSeeMore);
  }

  return (
    <motion.div
      className={`overflow-hidden  w-full mx-auto  duration-200 `}
      style={{
        height: isSeeMore && detailRef.current ? `${60 + detailRef.current.offsetHeight}px` : '60px',
      }}
    >
      <div className={`w-full h-full rounded-[16px] pt-[17px]   `} onClick={seeAnswer}>
        <div className=" flex justify-between items-center">
          <p
            className="text-[17px] leading-[26px] font-semibold inline "
            style={{
              color: fontColor ? fontColor : '#3A3C40',
            }}
          >
            {title}
          </p>

          <motion.div
            className="inline-block  pr-[1px]  flex items-center"
            animate={{ rotate: 180 * rotate }}
            transition={{ duration: loaded ? 0.2 : 0 }}
          >
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1.5L6 6.5L11 1.5"
                stroke={fontColor ? fontColor : '#26282C'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </motion.div>
        </div>

        <motion.div
          className={`pointer-events-none `}
          animate={{ opacity: isSeeMore ? 1 : 0 }}
          transition={{ duration: loaded ? 0.2 : 0 }}
        >
          <div ref={detailRef}>{content}</div>
        </motion.div>
      </div>
    </motion.div>
  );
}
