import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import { login } from '../utils/webview';
import axios from '../utils/axiosGoogle';
import qs from 'qs';
const clientId = '250716209381-5n63flftdcg30bkkbucrn9dmp79tevag.apps.googleusercontent.com'; //dev
const secret_clientId = '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';
export default function AfterLogin() {
  const navigate = useNavigate();

  const parsedSearch = new URLSearchParams(
    window.location.search.substring(1), // skip the first char (#)
  );
  const parsedHash = new URLSearchParams(
    window.location.hash.substring(1), // skip the first char (#)
  );
  console.log('SEARCH: ', parsedSearch.get('code')); //google
  console.log('HASH: ', parsedHash.get('id_token')); //apple

  const [idToken, setIdToken] = useState();
  const state = parsedSearch.get('code') ? 'google' : 'apple';

  const getIdToken = async (code, clientId, secret) => {
    try {
      const response = await axios.post(
        `/token`,
        qs.stringify({
          code: code,
          client_id: clientId,
          client_secret: secret,
          redirect_uri: window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://dev-m.inside.im',
          grant_type: 'authorization_code',
        }),
      );
      if (response) {
        console.log(response.data.id_token);
        setIdToken(response.data.id_token);
      }
    } catch (error) {
      console.error('result', error.debugMessage);
    }
  };

  const [verifyData, verifyLogin] = useFetch({
    url: `/anxy/user/login`,
    requestBody: JSON.stringify({ idToken: idToken, type: state }),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (verifyData) {
      console.log(verifyData.result);
      login({ accessToken: verifyData.result.accessToken });
      setTimeout(() => {
        navigate(`/anxyTest/0?at=${verifyData.result.accessToken}`);
      }, 3000);
    }
  }, [verifyData]);

  useEffect(() => {
    if (idToken) {
      verifyLogin();
    }
  }, [idToken]);

  useEffect(() => {
    console.log('LoggedIn', state);
    if (parsedSearch.get('code')) {
      //google
      const code = parsedSearch.get('code');
      getIdToken(code, clientId, secret_clientId);
    } else {
      //apple
      setIdToken(parsedHash.get('id_token'));
    }
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col gap-[10px] items-center justify-center">
      LoggedIn!
      <div>{verifyData && verifyData.result.accessToken}</div>
    </div>
  );
}
