import { atom } from 'recoil';

const myPageAtom = atom({
  key: 'betterme_myPage',
  default: {
    leaveReason: '',
    userProfile: undefined,
  },
});

export default myPageAtom;
