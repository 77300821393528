import { useEffect, useState } from 'react';
import TimePickerSomny from '../../../stories/time/TimePickerSomny';

export function SomnyTimePicker({ staticHour, staticMinute, state, setState, propsName, bgColor }) {
  const [hour, setHour] = useState(state[propsName] ? state[propsName].split(':')[0] : staticHour);
  const [minute, setMinute] = useState(state[propsName] ? state[propsName].split(':')[1] : staticMinute);

  useEffect(() => {
    console.log(hour, minute);
    setState((prev) => {
      const updated = { ...prev };
      updated[propsName] = `${hour}:${minute}`;
      return updated;
    });

    // const prevTime = state.sleepTime;
    // const prevHour = prevTime.split(':')[0];
    // const prevMin = prevTime.split(':')[1];
    // if(prevHour === hour && prevMin< minute){

    // }
  }, [hour, minute]);

  return (
    <div className="h-full flex items-end px-[20px] pb-[10px]">
      <TimePickerSomny hour={hour} minute={minute} getHour={setHour} getMinute={setMinute} bgColor={bgColor} />
    </div>
  );
}
