import React, { useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { CoachingContext } from '../../../coachingContext';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import TextWithGradientCircle from './TextWithGradientCircle';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css'; //basic
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './numberCard.css';
import Subtraction from '../../../pages/workbook/Subtraction';

export default function CountNumberCard() {
  const context = useContext(CoachingContext);
  const { id, page } = useParams();
  SwiperCore.use([Navigation, Pagination]);

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  return (
    <div className="w-screen px-[20px] h-[calc(100vh-17px)] relative text-black">
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="h-full flex items-center  my-[50px]">
          <Subtraction />
        </div>
        <div>
          <p
            className="font-bold text-[24px] leading-[30px]  mb-[15px] text-[#26282C]"
            style={{ wordBreak: 'keep-all' }}
          >
            숫자빼기를 해볼까요?
          </p>
          <span className={`text-[17px] leading-[28px] text-[#3A3C40]`}>
            <span className={`font-light`}>아래 절차대로 떠올려 보고 이를 반복해 봅니다.</span>
            <TextWithGradientCircle
              texts={[{ title: '1,000부터 7을 빼기' }, { title: '틀려도 괜찮으니 과정에 집중하기' }]}
              margin="mt-[40px]"
            />
          </span>
          <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px] mt-[40px]`}>
            <SecondaryActionButton state="ACTIVE" text={'다음'} action={goNext} />
          </div>
        </div>
      </div>
    </div>
  );
}
