import emoji_bad from '../image/emoji_bad.png';
import emoji_great from '../image/emoji_great.png';
import emoji_difficult from '../image/emoji_difficult.png';
import emoji_good from '../image/emoji_good.png';
import emoji_easy from '../image/emoji_easy.png';

const feedbackQ = [
  //feedback
  {
    id: 0,
    question: '오늘 활동은 어땠나요?',
    options: [
      { emoji: emoji_bad, label: '도움이 안됐어요', id: 0 },
      { emoji: emoji_great, label: '도움이 됐어요', id: 1 },
    ],
  },
  {
    id: 1,
    question: '난이도는 어땠나요?',
    options: [
      { emoji: emoji_difficult, label: '어려움', id: 0 },
      { emoji: emoji_good, label: '적당함', id: 1 },
      { emoji: emoji_easy, label: '쉬움', id: 2 },
    ],
  },
];

export default feedbackQ;
