import { atom } from 'recoil';

export const BUTTON_NONE = 'none';
export const BUTTON_BACK = 'back';
export const BUTTON_MORE = 'more';
export const BUTTON_CLOSE = 'close';

const headerAtom = atom({
  key: 'header',
  default: {
    headerButtonType: BUTTON_NONE,
    headerTitle: '',
    headerColor: '',
    headerBgColor: '',
  },
});

export default headerAtom;
