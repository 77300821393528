import React, { useState, useEffect, useRef } from 'react';
import ReadMore from './ReadMore';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TagManager from 'react-gtm-module';
import { goToScreen } from '../../utils/webview';
import mixpanel from 'mixpanel-browser';

export default function CounselorCard(props) {
  const { id, title, interview, image, introduce, name, dataLoaded } = props;
  const [textLoaded, setIsTextLoaded] = useState(false);

  const interviewRef = useRef();

  const tagManagerArgs = {
    dataLayer: {
      event: '선생님 상세로 이동',
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function counselorClick() {
    console.log('counselor click', id);
    mixpanel.track('선생님 상세로 이동', { counselorId: id });
    TagManager.dataLayer(tagManagerArgs);
    goToScreen({
      screenName: 'counselorDetail',
      counselorId: id,
    });
  }

  function getReadMoreLoadedState(ifLoaded) {
    setIsTextLoaded(ifLoaded);
  }

  return (
    <div className="w-full  select-none">
      <SkeletonTheme baseColor="#F3F5F8" highlightColor="#F3F5F8">
        <div className="bg-white shadow-counselorcard rounded-[20px] max-w-[380px] mx-auto ">
          <div
            className={`w-full ${
              textLoaded && dataLoaded ? 'pt-[30px]' : 'pt-[26px]'
            } px-24px border-b-[1px] border-[#F3F5F8]`}
          >
            <p
              className={`font-bold text-[20px] leading-[28px] text-[#26282C] ${
                textLoaded && dataLoaded ? 'mb-10px' : 'mb-[10px]'
              }`}
              style={{ wordBreak: 'keep-all' }}
            >
              {!(textLoaded && dataLoaded) && <Skeleton count={1} height={'28px'} width={200} />}
              {textLoaded && dataLoaded && title}
            </p>
            <div
              ref={interviewRef}
              className={` mb-20px min-h-[270px]
           
            relative text-[16px] text-[#4E4F53] leading-[27px]  font-light`}
            >
              {!(textLoaded && dataLoaded) && <Skeleton count={9} height={'18px'} />}
              {!(textLoaded && dataLoaded) && <Skeleton count={1} height={'18px'} width={100} />}
              {
                <div className={`${textLoaded && dataLoaded ? 'opacity-100' : 'opacity-0 absolute top-0'}`}>
                  <ReadMore
                    text={interview}
                    numberOfLines={10}
                    lineHeight={1.7}
                    ellipses="..."
                    readMoreLabel="계속 읽기"
                    showLessLabel="show less"
                    showLessButton={false}
                    getReadMoreLoadedState={getReadMoreLoadedState}
                  />
                  {/* {interview} */}
                </div>
              }
            </div>
          </div>

          <div className={`py-20px pl-24px pr-[13px] w-full `} onClick={counselorClick}>
            <div className="w-full flex align-center justify-between ">
              <div className="flex items-center min-w-0  ">
                <div className="w-[50px] h-[50px] rounded-[50px] bg-[#F3F5F8]  overflow-hidden shrink-0 ">
                  {textLoaded && dataLoaded && <img className="object-cover w-full h-hull " src={image} alt="empty" />}
                </div>
                <div className="ml-[9px] min-w-0 shrink h-[50px] overflow-hidden">
                  {!(textLoaded && dataLoaded) && <Skeleton count={1} height={'18px'} />}
                  {!(textLoaded && dataLoaded) && <Skeleton count={1} height={'18px'} width={100} />}
                  <div className={`${textLoaded && dataLoaded ? 'opacity-100' : 'opacity-0   '}`}>
                    <p className="text-[15px] leading-[23px] text-[#4E4F53] whitespace-nowrap text-ellipsis overflow-hidden">
                      {introduce}
                    </p>
                    <p className="text-[16px] leading-[23px] font-semibold text-[#26282C]">{name} 상담사</p>
                  </div>
                </div>
              </div>
              {textLoaded && dataLoaded && (
                <button className="my-auto p-2  flex-none ">
                  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 14.5L7 8L0.999999 1.5"
                      stroke="#80838B"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
}
