import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ic_alarm from 'image/ic_alarm.png';
import ic_kakaotalk from 'image/ic_kakaotalk.png';
import ic_refund from 'image/ic_refund.png';
import ic_account from 'image/ic_account.png';
import ic_policy from 'image/ic_policy.png';
import ic_provider from 'image/ic_provider.png';
import ic_appversion from 'image/ic_appversion.png';
import { AnxyTypeCard } from '../../anxyTest/AnxyResult';
import { showModal } from 'utils/webview';
import { PressedEffect } from 'stories/button/PressedEffect';
import ContainerByDeviceAnxy from 'components/elements/ContainerByDeviceAnxy';
import useFetchAnxy from 'hooks/useFetchAnxy';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import Test from 'development/Test';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_BACK } from 'recoil/common/header/atom';
import sessionAtom from 'recoil/anxy/session/atom';
import myPageAtom from 'recoil/anxy/myPage/atom';
import progressBarAtom from 'recoil/common/progressBar/atom';
import modalAtom from 'recoil/common/modal/atom';
import statusAtom from 'recoil/anxy/home/statusAtom';

function Menu(props) {
  const { id, icon, text, appVersion } = props;

  const goMenu = useNavigateWithParams(`/myPage/${id}${id === 5 ? `?app_version=${appVersion}` : ''}`);
  return (
    <div
      className="w-full flex items-center gap-[7px] py-[15px]"
      onClick={() => {
        if (id === 1 || id === 6) {
          showModal({ url: 'https://pf.kakao.com/_Hsyjxj', title: 'KakaoTalk', type: 'web' });
        } else {
          goMenu();
        }
      }}
    >
      <div className="shrink-0 w-[30px]">
        <img src={icon} alt={'empty'} />
      </div>
      <div className="flex-1 text-[17px] leading-[23px] font-bold text-[#26282C]">{text}</div>
      <div className="shrink-0 flex gap-[7px] items-center">
        {id === 5 && <div className="text-[17px] leading-[23px] text-[#4E4F53] font-semibold">{appVersion}</div>}
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.5 12L7.5 7L2.5 2"
            stroke="#26282C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
export default function MyPageHome() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [userProfile, getUserProfile] = useFetchAnxy({
    url: `/anxy/user/profile/load`,
    bg: '#FFFFFF',
  });
  const setHeaderState = useSetRecoilState(headerAtom);
  const setSessionState = useSetRecoilState(sessionAtom);
  const [myPageState, setMyPageState] = useRecoilState(myPageAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const setModalState = useSetRecoilState(modalAtom);
  const { isPaymentRequired } = useRecoilValue(statusAtom);

  useEffect(() => {
    if (myPageState.userProfile === undefined) {
      getUserProfile();
    }
    setHeaderState({ headerButtonType: BUTTON_BACK, headerTitle: '', headerColor: '#26282C' });
    setSessionState((state) => ({ ...state, currentSession: undefined }));
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userProfile) {
      setMyPageState((state) => ({ ...state, userProfile: userProfile.result }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const menuMap = [
    { id: 0, icon: ic_alarm, text: '알림 설정' },
    { id: 1, icon: ic_kakaotalk, text: '문의하기' },
    // { id: 6, icon: ic_refund, text: '환불 요청' },
    { id: 2, icon: ic_account, text: '계정 관리' },
    { id: 3, icon: ic_policy, text: '이용약관' },
    { id: 4, icon: ic_provider, text: '사업자 정보' },
    { id: 5, icon: ic_appversion, text: '앱 버전' },
  ];

  const goPayment = useNavigateWithParams('/payment');
  const goWorryNote = useNavigateWithParams('/worryNote');
  const goNotification = useNavigateWithParams('/anxyNotification/0?isSession=false');
  const goSessionNotification = useNavigateWithParams('/anxyNotification/0?isSession=true');
  const goBreath = useNavigateWithParams('/breath');
  const goPaymentConfirmFail = useNavigateWithParams('/payment-confirm-fail');

  return (
    <ContainerByDeviceAnxy>
      <div className="w-full overflow-x-hidden h-full px-[20px] py-[20px]">
        <Test
          element={
            <div className="flex gap-[10px] flex-wrap">
              <div className="w-fit p-[5px] border-[1px] rounded-[5px] border-[#6B6D76]" onClick={goPayment}>
                결제 테스트
              </div>
              <div className="w-fit p-[5px] border-[1px] rounded-[5px] border-[#6B6D76]" onClick={goPaymentConfirmFail}>
                결제 실패 테스트
              </div>
              <div className="w-fit p-[5px] border-[1px] rounded-[5px] border-[#6B6D76]" onClick={goNotification}>
                걱정 노트 알림
              </div>
              <div
                className="w-fit p-[5px] border-[1px] rounded-[5px] border-[#6B6D76]"
                onClick={goSessionNotification}
              >
                세션 알림
              </div>
              <div className="w-fit p-[5px] border-[1px] rounded-[5px] border-[#6B6D76]" onClick={goBreath}>
                호흡 툴
              </div>
            </div>
          }
        />
        {myPageState.userProfile && (
          <div className="mb-[40px]">
            <PressedEffect
              element={<AnxyTypeCard type={myPageState.userProfile.anxietyTypeId} clickable />}
              action={() => {
                navigate(`/myPage/type${myPageState.userProfile.anxietyTypeId}`);
              }}
            />
          </div>
        )}
        {(isPaymentRequired ? menuMap.filter((element) => element.id !== 6) : menuMap).map((menu, index) => (
          <Menu
            id={menu.id}
            icon={menu.icon}
            text={menu.text}
            key={`menu${index}`}
            appVersion={myPageState.userProfile && myPageState.userProfile.appVersion}
          />
        ))}
        <Test
          element={
            <div className=" text-[10px]">
              <textarea
                id="anxyat"
                onClick={() => {
                  const content = document.getElementById('anxyat');
                  content.select();
                  document.execCommand('copy');
                }}
                defaultValue={searchParams.get('at')}
              />
            </div>
          }
        />
      </div>
    </ContainerByDeviceAnxy>
  );
}
