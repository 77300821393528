import axios from 'axios';

const HOST_API = 'https://oauth2.googleapis.com';

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'Content-Type': `application/x-www-form-urlencoded`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
);

export default axiosInstance;
