import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import SlideTimePicker from './SlideTimePicker';
import sleepingReportSurveyData from '../../../data/sleepingReportSurveyData';
import moment from 'moment';
import 'moment/locale/ko';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { impactFeedback } from '../../../utils/webview';
import defaultValue from '../data/sleepingSurveyData';

const isoFormat = 'YYYY-MM-DDTHH:mm:ss';

// start: 48시간 전, end: 현재 시각(분은 내림), selected: 이전 기록

export default function SurveyInSleepStart({ type, isMarketing }) {
  const { id, page } = useParams();

  const [sleepingSurvey, setSleepingSurvey] = useSessionStorage('sleepingSurvey', defaultValue);
  const context = useContext(CoachingContext);
  const nowDate = new Date();
  const nowMinuteByFloor = Math.floor(nowDate.getMinutes() / 10) * 10;

  const nowTime = moment(nowDate).set({ minutes: nowMinuteByFloor }).format(isoFormat);
  const [dayType, setDayType] = useState();

  const [startTime, setStartTime] = useState(sleepingSurvey.inBed.startDateTime);
  const [endTime, setEndTime] = useState(nowTime);
  const [selectedTime, setSelectedTime] = useState(sleepingSurvey.inBed.startDateTime);
  const [prevTime, setPrevTime] = useState(sleepingSurvey.sleep.startDateTime);

  useEffect(() => {
    const is24hourAfter = moment.duration(moment(nowTime).diff(sleepingSurvey.inBed.startDateTime)).asHours() > 24;
    const limitTime = moment(sleepingSurvey.inBed.startDateTime).add({ hours: 24 }).format(isoFormat);
    if (is24hourAfter) {
      setEndTime(limitTime);
    }
  }, []);

  useEffect(() => {
    const nowFormat = moment(nowDate).format('YYYY-MM-DD');
    const selectedFormat = moment(selectedTime).format('YYYY-MM-DD');
    const timeDiff = moment.duration(moment(nowFormat).diff(selectedFormat));
    const dayDiff = Math.ceil(timeDiff.asDays());
    console.log(dayDiff, nowFormat);
    switch (dayDiff) {
      case 0:
        setDayType('오늘');
        break;
      case 1:
        setDayType('어제');
        break;
      case 2:
        setDayType('그저께');
        break;
      default:
        setDayType('오늘');
        break;
    }
  }, [selectedTime]);

  useEffect(() => {
    if (sleepingSurvey.sleep.startDateTime !== '') {
      console.log('survey', sleepingSurvey.sleep.startDateTime);
      setPrevTime(sleepingSurvey.sleep.startDateTime);
    }
  }, [sleepingSurvey]);

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  const goNextInMarketing = useNavigateWithParams(`/sleepChallengers/${parseInt(id) + 1}`);

  const reportData = sleepingReportSurveyData.find((item) => item.type === type);

  const sendFeedback = () => {
    impactFeedback('selection');
  };

  const changeSelectedTime = (changedTime) => {
    console.log('시간 바뀜 ', changedTime);
    setSelectedTime(() => changedTime);
  };

  useEffect(() => {
    sendFeedback();
  }, [selectedTime]);

  const submit = () => {
    const updated = { ...sleepingSurvey };
    updated.sleep = { ...updated.sleep, startDateTime: selectedTime };
    setSleepingSurvey(updated);

    setTimeout(() => {
      if (isMarketing) {
        goNextInMarketing();
      } else {
        goNext();
      }
    }, 400);
  };

  console.log('sleeping survey: ', sleepingSurvey);

  return (
    <div className={`w-full ${isMarketing ? 'h-full' : 'h-[calc(100vh-17px)]'} overflow-hidden`}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="px-[20px] ">
          <div
            className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C]  mt-[50px] "
            style={{ wordBreak: 'keep-all' }}
          >
            {reportData.title}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-[110px]">
          <div className="w-[30px]">
            <img className="" src={reportData.icon} alt="empty" />
          </div>
          <p className="mt-[10px] font-bold text-[40px] leading-[48px] whitespace-nowrap">
            {`${dayType} ${moment(selectedTime).format('HH')}:${moment(selectedTime).format('mm')}`}
          </p>
        </div>
        <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px]`}>
          <div className="relative w-screen">
            <SlideTimePicker
              startTime={startTime}
              endTime={endTime}
              selectedTime={selectedTime}
              changeSelectedTime={changeSelectedTime}
              prevTime={prevTime}
            />
          </div>
          <div className={`w-full px-[20px] mt-[50px] ${isMarketing ? 'pb-[20px]' : ''}`}>
            <SecondaryActionButton state="ACTIVE" text={'다음'} action={submit} />
          </div>
        </div>
      </div>
    </div>
  );
}
