/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import EndProcessing from '../../components/selfCheck/EndProcessing';
import { AuthContext } from '../../context';
import webviewToast from '../../development/webviewToast';

export default function BetterMeAnalysis(props) {
  const { types } = props;
  const context = useContext(AuthContext);
  const [done, setDone] = useState(false);

  useEffect(() => {
    document.title = `betterme 자가검사 로딩`;
    console.log(context.qcAnswerList);
    context.setShowProgressingbar(false);
    context.setMaxCompleted(0);
    context.setCompleted(0);
  }, []);

  function getMaxScoreId() {
    const sortedList = types
      .map((type) => getEachTypeScoreWithId(type.id))
      .sort(function (a, b) {
        return b.score - a.score || a.id - b.id;
      });
    console.log(sortedList);
    return sortedList[0].id;
  }

  function getEachType(id) {
    return context.qcAnswerList.filter((element) => types.find((type) => type.id === id).qcIdList.includes(element.id));
  }
  function getEachTypeScoreWithId(id) {
    return { id: id, score: getEachTypeScore(id) };
  }
  function getEachTypeScore(id) {
    return arraySum(getEachType(id).map((element) => element.score));
  }

  function arraySum(arr) {
    return arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
  }

  function isDone() {
    setDone(true);
  }

  useEffect(() => {
    if (done) {
      const maxScoreId = getMaxScoreId();
      webviewToast(maxScoreId);
      // if (maxScoreId === 1) {
      //   window.location.href = 'https://inside.im/betterme/love-seeker';
      // } else if (maxScoreId === 2) {
      //   window.location.href = 'https://inside.im/betterme/complex-ap';
      // } else if (maxScoreId === 3) {
      //   window.location.href = 'https://inside.im/betterme/guilty-diver';
      // } else if (maxScoreId === 4) {
      //   window.location.href = 'https://inside.im/betterme/ideal-me';
      // } else if (maxScoreId === 5) {
      //   window.location.href = 'https://inside.im/betterme/chew-spit';
      // } else if (maxScoreId === 6) {
      //   window.location.href = 'https://inside.im/betterme/vd-watcher';
      // }
      if (maxScoreId === 1) {
        window.location.href = 'https://inside.im/betterme/sparta-diater';
      } else if (maxScoreId === 2) {
        window.location.href = 'https://inside.im/betterme/perfection';
      } else if (maxScoreId === 3) {
        window.location.href = 'https://inside.im/betterme/self-blamer';
      } else if (maxScoreId === 4) {
        window.location.href = 'https://inside.im/betterme/rationalist';
      } else if (maxScoreId === 5) {
        window.location.href = 'https://inside.im/betterme/smile-angel';
      } else if (maxScoreId === 6) {
        window.location.href = 'https://inside.im/betterme/toomuch-consideration';
      }
    }
  }, [done]);

  return (
    <div
      className={`w-screen h-screen`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <EndProcessing steps={['검진 답변 분석 중', '결과지 구성 중']} isDone={isDone} />
    </div>
  );
}
