import axios from 'axios';

const HOST_API = process.env.REACT_APP_API_SERVER_HOST;
const API_KEY = process.env.REACT_APP_API_KEY;

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'X-Api-Key': API_KEY,
    'Content-Type': `application/json`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
);

export default axiosInstance;
