import { useRecoilState } from 'recoil';
import worryNoteAtom from '../../../recoil/anxy/worryNote/atom';
import { TextArea } from '../../../stories/text/TextArea';

const WorryNoteTextArea = ({ title, placeholder, name, autofocus }) => {
  const [worryNoteState, setWorryNoteState] = useRecoilState(worryNoteAtom);

  const setValue = (value) => {
    setWorryNoteState((state) => {
      const updated = { ...state };
      updated[name] = value;
      return updated;
    });
  };

  return (
    <div className=" text-white">
      <p className="text-[17px] leading-[23px] font-bold mb-[13px]">{title}</p>
      <div className="h-full">
        <TextArea
          placeholder={placeholder}
          value={worryNoteState[name]}
          updateValue={setValue}
          height={120}
          forceBlur={true}
          autofocus={autofocus}
          noBorder
        />
      </div>
    </div>
  );
};

export default WorryNoteTextArea;
