/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import webviewToast from '../../../development/webviewToast';

export function AnxyInput(props) {
  const { onKeyUp, setFocus, index, placeholder, value, autofocus, updateValue, inside } = props;
  const inputRef = useRef();
  const [isFocused, setFocused] = useState(autofocus);
  const [inputValue, setInputValue] = useState(value);
  const [isFirst, setFirst] = useState(true);

  useEffect(() => {
    setFocus(isFocused);
  }, [isFocused]);

  useEffect(() => {
    updateValue(inputValue, index);
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (!isFocused && value === '' && index !== 0) {
      console.log(inputValue, index);
      updateValue(inputValue, index, true);
    }
  }, [isFocused]);

  return (
    <div className={`w-full rounded-[12px]  bg-[#FAFBFD] flex justify-between mb-[10px]`} ref={inputRef}>
      <input
        className={`w-full rounded-[12px] px-[20px] py-[13px] font-regular text-[17px] leading-[26px] bg-[#FAFBFD] placeholder:text-[#A4A6B0] 
       text-black ${inside ? 'caret-[#222222]' : 'caret-[#3953D9]'}  outline-none mb-[2px]`}
        placeholder={placeholder}
        value={inputValue}
        onKeyUp={onKeyUp}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onClick={(e) => {
          // e.stopPropagation();
          // e.preventDefault();
          e.target.focus({ preventScroll: true });
        }}
        onFocus={(e) => {
          // e.preventScroll();
          setFocused(true);
        }}
        onBlur={(e) => {
          if (isFirst) {
            e.target.focus({ preventScroll: true });
            setFirst(false);
          }
          if (inside && index !== 0 && value === '') {
            updateValue('', index, true);
          }
          setFocused(false);
        }}
        autoFocus={autofocus}
      />
    </div>
  );
}
