import React, { useEffect, useContext, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import sleepingSurveyData from '../../../data/sleepingSurveyData';

function SleepingSurvey() {
  let { id } = useParams();
  const content = sleepingSurveyData;
  const context = useContext(CoachingContext);

  const contentRef = useRef();

  console.log(content[id - 1]);

  useEffect(() => {
    context.setProgressSum(3);
    context.setCurrent(id);
  }, [id]);

  useEffect(() => {
    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';

    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  return (
    <div
      ref={contentRef}
      className={`w-full h-[calc(100vh-17px)] relative`}
      style={{
        height: 1 !== parseInt(id) ? 'calc(var(--vh,1vh) * 100)' : window.innerHeight,
        minHeight: 1 !== parseInt(id) ? 'calc(var(--vh.1vh) * 100)' : window.innerHeight,
      }}
    >
      {content[id - 1]}
    </div>
  );
}

export default SleepingSurvey;
