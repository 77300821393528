import React from 'react';
import Lottie from '../../elements/Lottie';
import worriWalking from '../../../stories/animation/worri_walking.json';
import useInterval from '../../../hooks/useInterval';
import anxyWithWorri from '../../../image/anxy/tool/anxyWithWorri.png';

export function FirstPage({ goNext }) {
  const timerId = useInterval(
    () => {
      goNext();
      clearInterval(timerId);
    },
    3700,
    [],
  );

  return (
    <div className="w-full flex flex-col justify-end h-full">
      <div
        className="flex justify-center absolute bottom-[160px] w-full z-[10] m-auto"
        style={{
          height: 'calc(100vh - 320px)',
          background: 'linear-gradient(180deg, #F3F5F800 25%, #B8BDC6 77.81%, #F3F5F800 92.85%)',
        }}
      >
        <div className="absolute top-[50%] translate-y-[-50%]">
          <Lottie lottieData={worriWalking} autoplay />
        </div>
      </div>
      <div
        className="absolute bottom-0 w-full h-[280px] z-[0]"
        style={{
          background: 'linear-gradient(180deg, rgba(243, 245, 248, 0) 0%, #F3F5F8 59.23%)',
        }}
      />
    </div>
  );
}
