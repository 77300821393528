import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AnxyActionButton } from '../../stories/button/ActionButton';
import { useModalDrag } from '../../hooks/useModalDrag';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import { goToSetting, logout, userLeave } from '../../utils/webview';
import { useRecoilState, useRecoilValue } from 'recoil';
import LinkButton from '../../stories/button/LinkButton';
import myPageAtom from '../../recoil/somny/myPage/atom';
import BottomSheetModal from '../../stories/modal/BottomSheetModal';
import modalAtom, { MODAL_NOTIFICATION } from '../../recoil/somny/modal/atom';
import deviceAtom from '../../recoil/common/device';
import allow_notification_ios from '../../image/common/allow_notification_ios.png';
import allow_notification_android from '../../image/common/allow_notification_android.png';
import { RoundActionButton, SomnyActionButton } from '../../stories/button/RoundActionButton';

export default function SomnyModal(props) {
  const [searchParams] = useSearchParams();
  const appId = searchParams.get('app_id');
  const myPageState = useRecoilValue(myPageAtom);
  const [modalState, setModalState] = useRecoilState(modalAtom);
  const { isIOS } = useRecoilValue(deviceAtom);

  const navigate = useNavigate();

  const [logoutResponse, callLogoutApi] = useFetchAnxy({
    url: `/somny/user/logout`,
    bg: '#FFFFFF',
  });

  const [userLeaveResponse, callUserLeaveApi] = useFetchAnxy({
    url: `/somny/user/leave`,
    requestBody: JSON.stringify({ reason: myPageState.leaveReason }),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (userLeaveResponse) {
      userLeave();
      setModalState((state) => ({ ...state, isModalVisible: false }));
      navigate(`/somnyLogin?app_id=${appId}`, { replace: true });
    }
  }, [userLeaveResponse]);

  useEffect(() => {
    if (logoutResponse.code === 0) {
      logout();
      setModalState((state) => ({ ...state, isModalVisible: false }));

      navigate(`/somnyLogin?app_id=${appId}`, { replace: true });
    }
  }, [logoutResponse]);

  const data = [
    {
      type: 'LOGOUT',
      title: '정말 로그아웃할까요?',
      buttonText: '로그아웃하기',
      action: () => {
        callLogoutApi();
      },
    },
    {
      type: 'LEAVE',
      title: '정말 탈퇴할까요?',
      info: 'Somny 이용/결제 내역은 영구적으로 삭제되어 복구할 수 없어요. 나중에 Somny를 다시 사용하려면 처음부터 시작해야 해요.',
      buttonText: '탈퇴하기',
      red: true,
      action: () => {
        callUserLeaveApi();
      },
    },
    {
      type: 'ALLOW_NOTIFICATION',
      title: '알림을 허용해주세요',
      info: `설정 > ${!isIOS ? '애플리케이션 > ' : ''}Somny > 알림에서 알림을 허용해주세요`,
      buttonText: '설정으로 이동하기',
      content: (
        <div
          className="p-[40px] rounded-[16px]"
          style={{
            background: 'rgba(0, 0, 0, 0.3)',
          }}
        >
          <img
            src={isIOS ? allow_notification_ios : allow_notification_android}
            alt={'empty'}
            className="w-full max-w-[255px] mx-auto"
          />
        </div>
      ),
      action: () => {
        goToSetting();
        // goViewedSession();
      },
    },
  ];

  const dataPerType = data.find((item) => item.type === modalState.modalType);
  const { sheet, showModal } = useModalDrag(modalState.isModalVisible);

  useEffect(() => {
    setModalState((state) => ({ ...state, isModalVisible: showModal }));
  }, [showModal]);

  return (
    <BottomSheetModal
      modalState={modalState}
      setModalState={setModalState}
      content={
        <div className={`z-[50]`}>
          {dataPerType && (
            <div
              className={`h-full w-full text-[15px] leading-[18px] text-center relative ${
                dataPerType.type === MODAL_NOTIFICATION && 'bg-[#2F3164]'
              }`}
            >
              <div className="flex flex-col gap-[24px]">
                <div className="flex flex-col gap-[6px]">
                  <p
                    className="text-[#26282C] text-[20px] leading-[24px] font-semibold"
                    style={{
                      color: dataPerType.type === MODAL_NOTIFICATION && '#FFFFFF',
                    }}
                  >
                    {dataPerType.title}
                  </p>
                  {dataPerType.info && (
                    <p
                      className="text-[17px] leading-[26px] text-[#6B6D76]"
                      style={{
                        color: dataPerType.type === MODAL_NOTIFICATION && '#FFFFFF',
                        opacity: dataPerType.type === MODAL_NOTIFICATION ? 0.8 : 1,
                      }}
                    >
                      {dataPerType.info}
                    </p>
                  )}
                </div>
                {dataPerType.content}
              </div>
              <div className="pt-[30px] flex flex-col items-center">
                {dataPerType.type === 'ALLOW_NOTIFICATION' ? (
                  <SomnyActionButton
                    state="ACTIVE"
                    text={dataPerType.buttonText}
                    action={dataPerType.action}
                    activeColor={dataPerType.red && '#EF3E3E'}
                  />
                ) : (
                  <AnxyActionButton
                    state="ACTIVE"
                    text={dataPerType.buttonText}
                    action={dataPerType.action}
                    activeColor={dataPerType.red && '#EF3E3E'}
                  />
                )}

                {dataPerType.linkText && (
                  <LinkButton state="ACTIVE" text={dataPerType.linkText} action={dataPerType.linkAction} />
                )}
              </div>
            </div>
          )}
        </div>
      }
      bgColor={dataPerType && dataPerType.type === MODAL_NOTIFICATION && '#2F3164'}
    />
  );
}
