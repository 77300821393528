import React, { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';

const MyRuleBox = () => {
  const [rule, setRule] = useState('');

  const [userData, getUserData] = useFetch({
    url: `/footsteps-of-thought/load`,
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (userData) {
      console.log(userData.result);
      setRule(userData.result.rule);
    }
  }, [userData]);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-[#F3F5F8] w-full rounded-[12px] py-[20px] text-center">
      <p className="text-[#80838B] font-semibold">나의 규칙</p>
      <p className="mt-[5px]">{rule}</p>
    </div>
  );
};

export default MyRuleBox;
