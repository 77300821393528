/* eslint-disable react-hooks/exhaustive-deps */
import 'moment/locale/ko';
import React, { useEffect, useState } from 'react';
import { getRefresh, goToScreen, scrollEvent } from '../../utils/webview';
import ContainerByDevice from '../../components/elements/ContainerByDevice';
import CounselingCard from '../../components/home/CounselingCard';
import { LoadingLottie } from '../../stories/animation/LoadingLottie';
import useFetch from '../../hooks/useFetch';
import moment from 'moment';
import UserProfileList from '../../components/home/UserProfileList';
import UserProfileImage from '../../components/home/UserProfileImage';
import { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
import webviewToast from '../../development/webviewToast';

function CounselorHome() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [homeData, getHomeData] = useFetch({
    url: '/view/home-for-counselor/detail',
    bg: '#EFF3F8',
  });

  const [counselingHistoryList, setCounselingHistoryList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectDropoutUser, setSelectDropoutUser] = useState(false);

  const [counselorProfileImageUrl, setCounselorProfileImageUrl] = useState('');

  const [scrollTop, setScrollTop] = useState(0);
  const [xScrollStop, setXScrollStop] = useState(false);

  function getXScrollStop() {
    setXScrollStop(true);
  }

  useEffect(() => {
    if (xScrollStop) {
      scrollEvent(scrollTop);
      setXScrollStop(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xScrollStop]);

  useEffect(() => {
    document.title = '상담사 홈';
    window.document.body.style.backgroundColor = '#EFF3F8';
    getRefresh(refreshAction);
    getHomeData();
  }, []);

  const getSelectedUser = (selectedUser, isDropoutUser) => {
    setSelectedUser(selectedUser);
    setSelectDropoutUser(isDropoutUser);
  };
  const getTimeDiff = (timeA, timeB) => {
    const timeDiff = moment.duration(moment(timeA).diff(timeB));
    return timeDiff.asSeconds();
  };

  const getCurrentTime = (currentCounseling, userInfo) => {
    const currentInfo =
      currentCounseling && currentCounseling.length !== 0
        ? currentCounseling[currentCounseling.length - 1]
        : userInfo.history[0];
    return currentInfo.counselingStartDateTime;
  };

  const getWaitOrConfirmedList = (list) => {
    return list.filter((item) => item.counselingStatus.id === 1 || item.counselingStatus.id === 0);
  };

  const isLeaveUser = (user) => {
    return user.isLeaveUser || (user.userNickname && user.userNickname.includes('탈퇴'));
  };

  // 이부분 리팩토링 필요
  function groupByKey(array, key) {
    let userListById = [];

    const sortedList = array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
    }, {});

    Object.entries(sortedList).forEach((list) => {
      const currentCounseling = getWaitOrConfirmedList(list[1]);
      const userInfo =
        currentCounseling && currentCounseling.length !== 0
          ? currentCounseling[currentCounseling.length - 1]
          : list[1][0];

      console.log('user', list, userInfo);

      // 예약 대기 or 확정인지 확인 필요
      const currentWaiting = list[1].filter((item) => item.counselingStatus.id === 0);
      const isWait = currentWaiting && currentWaiting.length !== 0;

      userListById.push({
        userId: list[0],
        userNickname: userInfo.userNickname,
        userProfileImageUrl: userInfo.userProfileImageUrl,
        history: list[1],
        notify: !userInfo.isLeaveUser && (isWait || userInfo.decimalDay === 0),
        isLeaveUser: userInfo.isLeaveUser,
      });

      // notify인 경우 앞으로 땡겨줘야함
      userListById.sort(function (a, b) {
        // notify에서는 지금과 가까운 순서대로 정렬해야 함
        return a.notify === b.notify ? 0 : a.notify ? -1 : 1;
      });

      userListById.sort(function (a, b) {
        if (a.notify && b.notify) {
          const currentCounselingA = getWaitOrConfirmedList(a.history);
          const currentCounselingB = getWaitOrConfirmedList(b.history);

          const prevDate = getCurrentTime(currentCounselingA, a);
          const afterDate = getCurrentTime(currentCounselingB, b);

          return getTimeDiff(prevDate, afterDate);
        } else if (a.notify || b.notify === false) {
          // 최근 카드 날짜 기준 내림차순
          const prevDate = a.history[0].counselingStartDateTime;
          const afterDate = b.history[0].counselingStartDateTime;

          //현재 시간과 얼마나 차이가 나는지 비교해야함
          const isoFormat = 'YYYY-MM-DDTHH:mm:ss';
          const nowDate = moment(new Date()).format(isoFormat);

          // if (getTimeDiff(prevDate, nowDate) > getTimeDiff(afterDate, nowDate)) {
          //   return -1;
          // } else {
          //   return 1;
          // }

          // console.log(prevDate, afterDate, getTimeDiff(prevDate, nowDate), getTimeDiff(afterDate, nowDate));
          return getTimeDiff(prevDate, afterDate) * -1;
        } else {
          return 0;
        }
      });

      userListById.sort(function (a, b) {
        // 탈퇴한 사람이면
        return isLeaveUser(a) === isLeaveUser(b) ? 0 : isLeaveUser(a) ? 1 : -1;
      });

      userListById.forEach((item) => {
        console.log(
          '유저:',
          item.userNickname,
          ' 최근 상담:',
          item.history[0].counselingStartDateTime,
          ' 상담 상태:',
          item.history[0].counselingStatus.id === 0
            ? '대기'
            : item.history[0].counselingStatus.id === 1
            ? '확정'
            : '기타',
        );
      });
    });

    console.log('sorted', userListById);

    return userListById;
  }

  useEffect(() => {
    if (homeData) {
      setIsLoaded(true);
      if (homeData.result.counselorProfileImageUrl) {
        setCounselorProfileImageUrl(homeData.result.counselorProfileImageUrl);
      }
      if (homeData.result.counselingHistoryList) {
        setCounselingHistoryList(homeData.result.counselingHistoryList);
        if (homeData.result.counselingHistoryList.length !== 0) {
          const userListByKey = groupByKey(homeData.result.counselingHistoryList, 'userId');
          setUserList(userListByKey);
          if (!selectedUser) {
            setSelectedUser(userListByKey[0].userId);
          } else {
            const prev = selectedUser;
            setSelectedUser(prev);
          }
        }
      }
      console.log('api 호출함', homeData);
    }
  }, [homeData]);

  const refreshAction = () => {
    getHomeData();
  };

  const handleScroll = (scrollTop) => {
    setScrollTop(scrollTop);
    scrollEvent(scrollTop);
  };

  const goMyPage = () => {
    goToScreen({
      screenName: 'myPage',
    });
  };

  function NoCounselingGuide() {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="w-[50px] h-[50px] flex items-center justify-center">
          <svg width="44" height="36" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22.0002 0.669434C33.9316 0.669434 43.6002 7.47729 43.6002 15.8655C43.6002 21.4374 39.3419 26.2799 33.0059 28.9342V34.2224C33.0059 35.3571 31.6482 35.9852 30.7636 35.2355L25.6002 30.8388C24.4276 30.9806 23.2345 31.0616 22.0002 31.0616C10.0688 31.0616 0.400217 24.2538 0.400217 15.8655C0.400217 7.47729 10.0688 0.669434 22.0002 0.669434Z"
              fill="#2C4BEC"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.4361 10.9133C14.2903 11.1995 14.2903 11.5742 14.2903 12.3237V19.513C14.2903 20.2624 14.2903 20.6372 14.4361 20.9234C14.5644 21.1752 14.7691 21.3799 15.0209 21.5082C15.3072 21.6541 15.6819 21.6541 16.4313 21.6541H23.6207C24.3701 21.6541 24.7448 21.6541 25.0311 21.5082C25.2829 21.3799 25.4876 21.1752 25.6159 20.9234C25.7617 20.6372 25.7617 20.2624 25.7617 19.513V12.3237C25.7617 11.5742 25.7617 11.1995 25.6159 10.9133C25.4876 10.6615 25.2829 10.4568 25.0311 10.3285C24.7448 10.1826 24.3701 10.1826 23.6207 10.1826H16.4313C15.6819 10.1826 15.3072 10.1826 15.0209 10.3285C14.7691 10.4568 14.5644 10.6615 14.4361 10.9133ZM31.0392 11.9853V19.8516C31.0392 20.4278 31.0392 20.7158 30.9213 20.8627C30.8189 20.9903 30.6636 21.0641 30.5 21.0629C30.3117 21.0615 30.0884 20.8795 29.6418 20.5156L26.9094 18.2892V13.5477L29.6418 11.3214C30.0884 10.9575 30.3117 10.7755 30.5 10.7741C30.6636 10.7729 30.8189 10.8467 30.9213 10.9743C31.0392 11.1211 31.0392 11.4092 31.0392 11.9853Z"
              fill="white"
            />
          </svg>
        </div>
        <p className="mt-[30px] text-[24px] leading-[30px] font-bold">예정된 상담이 없어요</p>
      </div>
    );
  }

  function Profile() {
    return (
      <div className="font-bold flex flex-col items-center pt-[20px]">
        <UserProfileImage
          profileImageUrl={counselorProfileImageUrl}
          nickname={homeData.result.counselorName}
          containerSize={'60px'}
        />
        <p className="text-[20px] leading-[26px] mt-[12px] mb-[8px]">{`${homeData.result.counselorName} 상담사님, 안녕하세요`}</p>
        <div
          className="flex pl-[15px] items-center bg-[#26282C] w-[63px] h-[32px] rounded-[100px] text-white"
          onClick={goMyPage}
        >
          <span className="text-[14px] leading-[20px]">MY</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.91665 14.1667L12.0833 10L7.91665 5.83335"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    );
  }

  const context = useContext(CoachingContext);

  return isLoaded ? (
    <ContainerByDevice css={`bg-[#EFF3F8]`} handleScroll={handleScroll}>
      <div className="w-screen h-full">
        <div
          className={`w-full ${
            counselingHistoryList && counselingHistoryList.length === 0 && 'h-screen'
          } h-full flex flex-col items-center pt-safe pb-safe relative`}
        >
          <Profile />
          <div className={`w-full h-full ${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'}`}>
            {counselingHistoryList && counselingHistoryList.length === 0 && (
              <div className="h-full">
                <NoCounselingGuide />
              </div>
            )}
            {counselingHistoryList && counselingHistoryList.length !== 0 && selectedUser && (
              <div className="mt-[50px]">
                <UserProfileList
                  userList={userList}
                  getSelectedUser={getSelectedUser}
                  getXScrollStop={getXScrollStop}
                />
                <div className={`px-[20px] ${selectDropoutUser ? 'opacity-[0.5]' : 'opacity-1'}`}>
                  {userList
                    .find((item) => item.userId === selectedUser)
                    .history.map((data, index) => (
                      <div className={`${'mb-[10px]'}`}>
                        <CounselingCard
                          data={data}
                          allData={userList.find((item) => item.userId === selectedUser).history}
                          index={userList.find((item) => item.userId === selectedUser).history.length - index}
                          isCounselor
                          getRefreshAction={() => refreshAction()}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ContainerByDevice>
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <LoadingLottie containerSize="72px" />
    </div>
  );
}

export default CounselorHome;
