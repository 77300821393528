export const body1 =
  '방해받지 않는 장소에서 앉거나 눕는 등 편한 자세를 취하세요.\n' +
  '오직 당신의 몸에만 주의를 집중하세요. 마음이 산만해지는 것이 느껴지면 다시 지금 작업하려는 근육군으로 주의를 되돌려 오세요.\n' +
  '복식호흡으로 숨을 길게 들이마시고, 몇 초간 숨을 참았다가 천천히 내뱉으세요. 숨을 들이마시면서 배가 솟아오르고 폐가 공기로 채워지는 것을 느껴 보세요. 숨을 내뱉으면서 몸 안의 긴장이 해소되고 몸 밖으로 빠져나가는 이미지를 그려 보세요. 숨을 들이쉬고 내쉬기를 계속하세요. 몸이 이완되는 것을 느끼세요. 이 과정을 하면서 호흡은 계속 유지하세요.\n' +
  '자, 이제 근육군 하나씩 시작해 봅시다. 눈썹을 위로 있는 힘껏 치켜 올리면서 앞이마 근육을 긴장시켜 보세요. 5초간 유지해 주세요. 그리고 일순간 힘을 빼면서 긴장이 사라지는 것을 느껴 보세요.\n' +
  '이번에는 웃는 표정을 지으며 입과 볼 주위 근육을 긴장시켜 보세요. 5초간 유지하시고 힘을 빼세요. 얼굴이 부드러워지는 것을 느껴 보세요.\n' +
  '이번에는 눈꺼풀을 세게 감으며 쥐어짜듯이 눈 근육을 강하게 수축시키세요. 5초간 유지한 후 힘을 빼세요.\n' +
  '천장을 보듯이 목을 뒤로 부드럽게 젖혀 보세요. 5초간 유지한 후 힘을 빼면서 긴장이 사라지는 것을 느껴 보세요. 다음으로 목을 아래로 내려뜨리면서 머리의 무게를 느껴 보세요. 숨을 들이쉬고 내쉬기를 충분히 반복하세요. 모든 스트레스를 내보내면서 숨을 들이쉬고 내쉬세요.\n' +
  '이번에는 너무 무리하지는 말고 두 주먹을 꽉 쥐면서 긴장시키고, 5초간 이 자세를 유지한 후 힘을 빼세요.\n' +
  '이번에는 이두근 차례입니다. 힘을 줄 때의 긴장을 느껴 보세요. 근육이 긴장되는 것을 마음에 그려 볼 수도 있습니다. 5초간 유지한 후 힘을 빼면서 팔뚝이 느슨해지는 것을 느껴 보세요. 숨을 계속 들이쉬고 내쉬세요.\n' +
  '이번에는 삼두근 차례입니다. 팔꿈치를 굽히지 말고 팔을 쭉 펴세요. 5초간 유지한 후 힘을 빼세요.\n' +
  '이번에는 어깨가 귀에 닿는다는 느낌으로 어깨를 움츠려 보세요. 5초간 유지한 후 재빨리 힘을 빼세요.\n' +
  '이번에는 어깨 날갯죽지가 서로 닿을 것처럼 어깨를 뒤로 젖히면서 등을 펴세요. 5초간 유지한 후 힘을 빼세요.\n' +
  '숨을 충분히 들이쉬면서 가슴을 팽창시키고, 5초간 유지한 후 숨을 내뱉으면서 긴장이 빠져나가는 것을 느껴 보세요.\n' +
  '이번에는 공기를 흡입하면서 배에 있는 근육을 긴장시켜 보세요. 5초간 유지한 후 힘을 빼세요.\n' +
  '이번에는 부드럽게 허리 부위를 활모양으로 만들어 보세요. 5초간 유지한 후 힘을 빼세요.\n' +
  '이제 상반신에서 긴장과 스트레스가 빠져나가 나긋나긋해진 것을 충분히 느껴 보세요. 5초간 유지한 후 힘을 빼세요.\n' +
  '엉덩이를 긴장시킵니다. 5초간 유지한 후 힘을 빼시고, 엉덩이가 풀어지는 것을 마음속에 그려 보세요.\n' +
  '무릎을 모으면서 넓적다리를 긴장시킵니다. 5초간 유지한 후 힘을 뺍니다.\n' +
  '이번에는 발에 힘을 주며, 발가락을 몸 쪽으로 당기면서 종아리의 긴장을 느껴 봅니다. 5초간 유지한 후 힘을 뺍니다. 다리가 풀리는 것을 느껴 봅니다.\n' +
  '발을 긴장시키면서 발가락을 아래쪽으로 구부립니다. 5초간 유지한 후 힘을 뺍니다.\n' +
  '이제 이완의 물결이 천천히 머리에서부터 발끝까지 온몸으로 퍼져 가는 것을 마음속에 그려 봅니다. 완전히 늘어진 몸의 무게감을 느껴 봅니다. 숨을 들이쉬고 내쉬기를 반복합니다.';
