import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import TokenTest from 'development/TokenTest';
import Wori from 'components/anxy/anxy12ndA/Wori2';
import { ProgressingBar } from 'stories/else/ProgressingBar';
import { marginWithHomeIndicator } from 'recoil/common/device';
import { useState } from 'react';
import animateAtom from 'recoil/anxy/home/animateAtom';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import CountUp from 'react-countup';
import { AnxyActionButton } from 'stories/button/ActionButton';
import { PressedEffect } from 'stories/button/PressedEffect';
import userAtom from 'recoil/anxy/user/atom';
import { motion } from 'framer-motion';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import { Text17 } from 'stories/text/AnxyText';
import useFetchAnxy from 'hooks/useFetchAnxy';
import statusAtom from 'recoil/anxy/home/statusAtom';

export default function WorryWithNote({ previousScore, score, setGoAnalysis, setPageAnimation }) {
  const woriContainerRef = useRef();
  const [animate, setAnimate] = useState(true);

  const [animateState, setAnimateState] = useRecoilState(animateAtom);
  const { worryNoteHistory } = useRecoilValue(userAtom);
  const setUserState = useSetRecoilState(userAtom);
  const { isPaymentRequired } = useRecoilValue(statusAtom);

  const [isGrowl, setGrowl] = useState(false);
  const [growlByClick, setGrowlByClick] = useState(false);
  const [showNewAnalysisIndicator, setNewAnalysisIndicator] = useState(false);

  const [worryNoteMetaInfo, getWorryNoteMetaInfo] = useFetchAnxy({
    url: 'anxy/v2/worry-note/meta-info/load',
  });

  useEffect(() => {
    getWorryNoteMetaInfo();
  }, []);

  useEffect(() => {
    if (isGrowl) {
      setTimeout(() => {
        setPageAnimation(true);
      }, 0);
      setTimeout(() => {
        setGrowl(false);
        setUserState((state) => ({
          ...state,
          previousScore: undefined,
        }));

        if (
          !growlByClick &&
          worryNoteMetaInfo.result.perfectionStep > 1 &&
          worryNoteHistory[0].reason !== '심호흡하기'
        ) {
          setTimeout(() => {
            setNewAnalysisIndicator(true);
          }, 200);
        }
      }, 1000);
    }
  }, [isGrowl]);

  useEffect(() => {
    if (previousScore !== undefined) {
      setTimeout(() => {
        setGrowlByClick(false);
        setGrowl(true);
      }, 1000);
    }
  }, [previousScore]);

  const goWorryNote = useNavigateWithParams(`/worryNote`);
  const goPayment = useNavigateWithParams(`/payment`);
  const goWorryNoteHistory = useNavigateWithParams(`/worryNoteHistory`);
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  useEffect(() => {
    if (animateState === 1) {
      setAnimate(false);
    }
  }, [animateState, animate]);

  return (
    <div className={`snap-always snap-start h-full pt-[54px] px-[20px] flex flex-col ${marginClassName}`}>
      <div className="text-[24px] leading-[29px] text-black font-bold px-[4px]">지금 나의 불안</div>
      <div className="absolute top-[10px] left-[20px]">
        <TokenTest />
      </div>
      <div className="flex-1 flex flex-col pb-[72px]">
        <div className="flex-1 flex flex-col pb-[56px]">
          <div className="flex-1 relative" ref={woriContainerRef}>
            <div
              className="absolute bottom-0 left-[50%] translate-x-[-50%] origin-bottom "
              onClick={() => {
                setGrowlByClick(true);
                setGrowl(true);
              }}
              style={{
                transform: `translateX(-50%) scale(${
                  Math.min(woriContainerRef.current && woriContainerRef.current.offsetHeight, 412) / 412
                })`,
              }}
            >
              <Wori
                initialScore={previousScore === undefined ? score : previousScore}
                score={score}
                duration={1}
                delay={0.2}
                growl={isGrowl}
                hasArm={isGrowl}
              />
            </div>
            <motion.div
              initial={{ opacity: 0, transform: 'translate(-50%,30px)' }}
              animate={{
                opacity: showNewAnalysisIndicator ? 1 : 0,
                transform: showNewAnalysisIndicator ? 'translate(-50%,-44px)' : 'translate(-50%,30px)',
              }}
              className="absolute bottom-0 left-[50%]  w-fit px-[16px] py-[8px] rounded-[40px] flex items-center gap-[4px]"
              style={{ backgroundColor: anxyColorPalette.orange }}
              onClick={() => {
                setGoAnalysis(true);
                setTimeout(() => {
                  setNewAnalysisIndicator(false);
                }, 200);
              }}
            >
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.5 7.5L7.5 12.5L12.5 7.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line x1="7.5" y1="11.5" x2="7.5" y2="1.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
              </svg>
              <Text17 text={'새로운 분석'} color={anxyColorPalette.white} className="font-bold" />
            </motion.div>
          </div>
          <div className=" pt-[18px] pb-[12px] relative">
            <p className="absolute z-[10] top-[23px] left-[50%] translate-x-[-50%] text-[15px] leading-[18px] font-bold text-white font-semibold">
              <CountUp
                end={score}
                start={previousScore === undefined ? score : previousScore}
                duration={1}
                delay={0.2}
              />
              <span className="opacity-[0.5]">/100</span>
            </p>
            <ProgressingBar
              maxCompleted={100}
              completed={score}
              height={30}
              color="#EF670B"
              baseColor="#3A3C40"
              duration={'1s'}
              delay={'0.2s'}
              initCompleted={previousScore === undefined ? score : previousScore}
              // animateNone
              maskColor={'#F3F5F8'}
            />
          </div>
          <div className="flex gap-[8px]">
            <div className="flex-1">
              <AnxyActionButton
                state={'ACTIVE'}
                text={
                  <div className="flex items-center justify-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="11" y="5" width="2" height="14" rx="1" fill="white" />
                      <rect x="19" y="11" width="2" height="14" rx="1" transform="rotate(90 19 11)" fill="white" />
                    </svg>
                    <p>기록하기</p>
                  </div>
                }
                action={() => {
                  if (isPaymentRequired) {
                    goPayment();
                  } else {
                    goWorryNote();
                  }
                }}
              />
            </div>
            {worryNoteHistory && worryNoteHistory.length > 0 && (
              <div className="w-[54px]">
                <PressedEffect
                  element={
                    <div
                      className="shrink-0 w-[54px] h-[54px] rounded-[50%] bg-white flex items-center justify-center"
                      onClick={() => {
                        goWorryNoteHistory();
                      }}
                    >
                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14 27C6.8203 27 1 21.1797 1 14C1 6.8203 6.8203 1 14 1C21.1797 1 27 6.8203 27 14C27 17.493 25.6224 20.6642 23.3809 23"
                          stroke="#26282C"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                        />
                        <path
                          d="M23 19V23H27"
                          stroke="#26282C"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.5 7V14.5H19.5"
                          stroke="#26282C"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
