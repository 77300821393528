import knit1 from '../../image/somny/knit/knit1.png';
import knit2 from '../../image/somny/knit/knit2.png';
import knit3 from '../../image/somny/knit/knit3.png';

export const somnyKnitData = [
  {
    knitId: 'knit_aa',
    number: '첫',
    title: '수면의 세계',
    image: knit1,
  },
  {
    knitId: 'knit_ab',
    number: '두',
    title: '수면 효율',
    image: knit2,
  },
  {
    knitId: 'knit_ac',
    number: '세',
    title: '좋은 수면이란',
    image: knit3,
  },
  {
    knitId: 'knit_ad',
    number: '네',
    title: '생체 시계',
  },
  { knitId: 'knit_ae', number: '다섯', title: '항상성' },
  { knitId: 'knit_af', number: '여섯', title: '불면증의 시작' },
  { knitId: 'knit_ag', number: '일곱', title: '불면증 벗어나기 1' },
  { knitId: 'knit_ah', number: '여덟', title: '불면증 벗어나기 2' },
  { knitId: 'knit_ai', number: '아홉', title: '수면 미신' },
  { knitId: 'knit_aj', number: '열', title: '낮 시간의 활동' },
  { knitId: 'knit_ak', number: '열한', title: '잠 자기 전 시간' },
  { knitId: 'knit_al', number: '열두', title: '잠자리 환경' },
  { knitId: 'knit_ts', number: '열두', title: '긴장 풀기' },
  { knitId: 'knit_tu', number: '열두', title: '불면증의 뇌' },
  { knitId: 'knit_tk', number: '열두', title: '생각 바로잡기' },
];
