import { atom } from 'recoil';

const userAtom = atom({
  key: 'user',
  default: {
    anxietyTypeId: 1,
    anxietyScore: 50,
  },
});

export default userAtom;
