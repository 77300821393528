import { atom } from 'recoil';

const somnyAtom = atom({
  key: 'somny_home',
  default: {
    completedAt: undefined,
    isFirst: true,
  },
});

export default somnyAtom;
