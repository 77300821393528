import { atom } from 'recoil';

const progressBarAtom = atom({
  key: 'progressBar',
  default: {
    isProgressBarVisible: false,
    maxCompleted: 0,
    completed: 0,
  },
});

export default progressBarAtom;
