import { BUILD_TARGET } from '../config';
import webviewToast from '../development/webviewToast';

const isDev = BUILD_TARGET === 'dev';

export const login = (message) => {
  webviewToast(`login`);
  if (window.android) {
    window.android.login(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.login.postMessage(JSON.stringify(message));
  }
};

export const updateNotch = (message) => {
  // webviewToast(`updateNotch ${message.isFullScreen}`);
  // if (window.android) {
  //   window.android.updateNotch(JSON.stringify(message));
  // } else
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.updateNotch) {
    window.webkit.messageHandlers.updateNotch.postMessage(JSON.stringify(message));
  }
};

export const goToScreen = (message) => {
  console.log(JSON.stringify(message));
  webviewToast(`goToScreen:${message.screenName}`);
  if (window.android) {
    window.android.goToScreen(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.goToScreen.postMessage(JSON.stringify(message));
  }
};

export const goToTab = (message) => {
  webviewToast(`goToTab:${message.tabName}`);
  if (window.android) {
    window.android.goToTab(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.goToTab.postMessage(JSON.stringify(message));
  }
};

export const goToHome = () => {
  webviewToast(`goToHome`);
  if (window.android && window.android.goToHome) {
    window.android.goToHome();
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.goToHome.postMessage('');
  }
};

export const goToSetting = () => {
  webviewToast(`goToSetting`);
  if (window.android && window.android.goToSetting) {
    window.android.goToSetting();
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.goToSetting.postMessage('');
  }
};

export const showWebView = (rawMessage) => {
  const message = {
    url: !rawMessage.url.includes('https://')
      ? isDev
        ? `https://dev-m.inside.im/${rawMessage.url}`
        : `https://m.inside.im/${rawMessage.url}`
      : rawMessage.url,
    backgroundColor: rawMessage.backgroundColor,
    title: rawMessage.title,
    isExternal: rawMessage.isExternal,
  };
  webviewToast(`showWebView:${message.url}`);
  console.log(message);
  if (window.android) {
    window.android.showWebView(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.showWebView.postMessage(JSON.stringify(message));
  }
};

export const showModal = (message) => {
  webviewToast(`showModal:${message.url}`);
  if (window.android) {
    window.android.showModal(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.showModal.postMessage(JSON.stringify(message));
  }
};

export const close = (message) => {
  //default: {refresh:false}
  webviewToast(`close:${message.refresh}`);
  console.log('close', 'refresh', message ? message.refresh : false);

  if (window.android) {
    window.android.close(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.close.postMessage(JSON.stringify(message));
  }
};

export const changeHeader = (message) => {
  if (window.android && window.android.changeHeader) {
    window.android.changeHeader(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    if (window.webkit.messageHandlers.changeHeader) {
      window.webkit.messageHandlers.changeHeader.postMessage(JSON.stringify(message));
    }
  }
};

export const impactFeedback = (type) => {
  const message = { type: type };
  if (window.android && window.android.impactFeedback) {
    window.android.impactFeedback(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.impactFeedback) {
    window.webkit.messageHandlers.impactFeedback.postMessage(JSON.stringify(message));
  }
};

export const scrollEvent = (y) => {
  const message = { scrollY: y };
  if (window.android) {
    window.android.scrollEvent(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.scrollEvent.postMessage(JSON.stringify(message));
  }
};

export const firebaseEvent = (name) => {
  const message = { name: name };
  if (window.android) {
    // Call Android interface
    webviewToast('android success');
    window.android.firebaseEvent(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    webviewToast('ios success');
    if (window.webkit.messageHandlers.firebaseEvent) {
      window.webkit.messageHandlers.firebaseEvent.postMessage(JSON.stringify(message));
    }
  }
};

export const logout = () => {
  console.log('logout');
  if (window.android) {
    window.android.logout();
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.logout.postMessage('');
  }
};

export const userLeave = () => {
  console.log('userLeave');
  if (window.android) {
    window.android.userLeave();
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.userLeave.postMessage('');
  }
};

export const keyboardOpen = () => {
  if (window.android && window.android.keyboardOpen) {
    window.android.keyboardOpen();
  }
};

export const requestAppState = (type) => {
  const message = { type: type };
  if (window.android && window.android.requestAppState) {
    window.android.requestAppState(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.requestAppState.postMessage(JSON.stringify(message));
  }
};

export const keepScreenEvent = (message) => {
  // webviewToast(`keepScreenEvent:${message.isKeepScreenOn}`);
  if (window.android && window.android.keepScreenEvent) {
    window.android.keepScreenEvent(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.keepScreenEvent) {
    window.webkit.messageHandlers.keepScreenEvent.postMessage(JSON.stringify(message));
  }
};

export const historyBack = (message) => {
  // webviewToast(`historyBack:${message.allow}`);
  if (window.android && window.android.historyBack) {
    window.android.historyBack(JSON.stringify(message));
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.historyBack) {
    window.webkit.messageHandlers.historyBack.postMessage(JSON.stringify(message));
  }
};

export const allowBack = (message) => {
  // webviewToast(`allowBack:${message.allow}`);
  if (window.android && window.android.allowBack) {
    window.android.allowBack(JSON.stringify(message));
  }
};

//앱->웹
export const hasHomeIndicator = (setBiggerMargin) => {
  const eventFromApp = async (event) => {
    setBiggerMargin(true);
  };
  window.addEventListener('hasHomeIndicator', eventFromApp);
  return () => {
    window.removeEventListener('hasHomeIndicator', eventFromApp);
  };
};

export const getNotchSize = (setDeviceState) => {
  const eventFromApp = async (event) => {
    // webviewToast(`getNotchSize: ${event.detail.size}`);
    setDeviceState((state) => ({ ...state, notchSize: event.detail.size }));
  };
  window.addEventListener('getNotchSize', eventFromApp);
  return () => {
    window.removeEventListener('getNotchSize', eventFromApp);
  };
};

export const getRefresh = (action) => {
  const eventFromApp = async (event) => {
    webviewToast('refresh');
    action();
  };
  window.addEventListener('refresh', eventFromApp);
  return () => {
    window.removeEventListener('refresh', eventFromApp);
  };
};

export const getScrollEvent = (scrollRef) => {
  const eventFromApp = async (event) => {
    if (scrollRef.current) {
      webviewToast('getScrollEvent');
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  window.addEventListener('scrollToTop', eventFromApp);
  return () => {
    window.removeEventListener('scrollToTop', eventFromApp);
  };
};

export const headerTapped = (action) => {
  const eventFromApp = async (event) => {
    webviewToast('headerTapped');
    action();
  };
  window.addEventListener('headerTapped', eventFromApp);
  return () => {
    window.removeEventListener('headerTapped', eventFromApp);
  };
};

export const getAlarmPermission = () => {
  const eventFromApp = async (event) => {
    if (!event.detail.isEnabled) {
      showModal({ type: 'alarm' });
    }
  };
  window.addEventListener('alarmPermission', eventFromApp);
  return () => {
    window.removeEventListener('alarmPermission', eventFromApp);
  };
};
