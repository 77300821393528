/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import WorryScore from '../../worryNote/WorryScore';
import AnxyBreathTool from './AnxyBreathTool';
import breathToolAtom from 'recoil/anxy/tool/atom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useFetchAnxy from 'hooks/useFetchAnxy';
import userAtom from 'recoil/anxy/user/atom';
import { CompletePage } from 'stories/page/CompletePage';
import usePostToolDone from 'hooks/usePostToolDone';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from 'recoil/common/header/atom';
import useMixpanelEventAnxy from 'hooks/useMixpanelEventAnxy';

export default function Breath(props) {
  let { id } = useParams();
  id = parseInt(id);

  const [breathToolState, setBreathToolState] = useRecoilState(breathToolAtom);
  const setHeaderState = useSetRecoilState(headerAtom);
  const setUserState = useSetRecoilState(userAtom);

  const goNext = useNavigateWithParams(`/breath/${id + 1}`);
  const goHome = useNavigateWithParams('/anxyHome');
  const postToolDone = usePostToolDone({ toolId: 'tool_bb', isNotGoHome: true });

  const [isInitialDone, setInitialDone] = useState(false);
  const [isFinalDone, setFinalDone] = useState(false);

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '툴 진행 시작',
    params: {
      툴: '심호흡하기',
    },
  });

  const [saveInitialWorryRecordResult, saveInitialWorryRecord] = useFetchAnxy({
    url: 'anxy/v2/worry-note/submit',
    requestBody: {
      score: breathToolState.initialScore,
      reason: '심호흡하기 전',
    },
  });

  const [saveFinalWorryRecordResult, saveFinalWorryRecord] = useFetchAnxy({
    url: 'anxy/v2/worry-note/submit',
    requestBody: {
      score: breathToolState.finalScore,
      reason: '심호흡하기',
    },
  });

  useEffect(() => {
    if (id === 0) {
      setMixPanel();
    }
    if (id < 2) {
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_BACK,
        headerColor: anxyColorPalette.black,
      }));
    } else {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
    }
  }, []);

  useEffect(() => {
    if (isInitialDone) {
      saveInitialWorryRecord();
    }
  }, [isInitialDone]);

  useEffect(() => {
    if (isFinalDone) {
      saveFinalWorryRecord();
    }
  }, [isFinalDone]);

  useEffect(() => {
    if (saveFinalWorryRecordResult) {
      setUserState((state) => ({
        ...state,
        previousScore: breathToolState.initialScore,
      }));
      goHome();
      setBreathToolState({ initialScore: 50, finalScore: 50 });
    }
  }, [saveFinalWorryRecordResult]);

  return id === 0 ? (
    <WorryScore
      title={'심호흡하기 전, 불안감을 체크해볼게요. 지금 얼마나 불안한가요?'}
      action={(initialScore) => {
        setBreathToolState((state) => ({ ...state, initialScore: initialScore }));
        setInitialDone(true);
        goNext();
      }}
      buttonText={'심호흡 시작하기'}
      bgColor={anxyColorPalette.oat}
      initialScore={breathToolState.initialScore}
    />
  ) : id === 1 ? (
    <AnxyBreathTool
      action={() => {
        postToolDone();
        goNext();
      }}
    />
  ) : id === 2 ? (
    <WorryScore
      title={'심호흡을 마친 뒤, 지금은 얼마나 불안한가요?'}
      action={(finalScore) => {
        setBreathToolState((state) => ({ ...state, finalScore: finalScore }));
        setFinalDone(true);
      }}
      buttonText={'확인'}
      bgColor={anxyColorPalette.oat}
      initialScore={breathToolState.initialScore}
      isInitialMax
    />
  ) : (
    <CompletePage title={'심호흡을 마쳤어요'} buttonText={'마치기'} action={() => {}} />
  );
}
