import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ic_alarm from '../../../image/somny/myPage/ic_alarm.png';
import ic_refund from '../../../image/somny/myPage/ic_refund.png';
import ic_account from '../../../image/somny/myPage/ic_account.png';
import ic_provider from '../../../image/somny/myPage/ic_provider.png';
import ic_policy from '../../../image/somny/myPage/ic_policy.png';
import ic_appversion from '../../../image/somny/myPage/ic_appversion.png';
import { showModal } from '../../../utils/webview';
import ContainerByDeviceAnxy from '../../../components/elements/ContainerByDeviceAnxy';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import Test from '../../../development/Test';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import sessionAtom from '../../../recoil/betterme/session/atom';
import myPageAtom from '../../../recoil/betterme/myPage/atom';
import { BetterMeTypeCard } from '../../../components/betterme/BetterMeTypeCard';
import notificationAtom from '../../../recoil/somny/notification/atom';
import { PressedEffect } from '../../../stories/button/PressedEffect';
import statusAtom from 'recoil/somny/status/atom';

function Menu(props) {
  const { id, icon, text, appVersion } = props;

  const goMenu = useNavigateWithParams(`/somnyMyPage/${id}${id === 5 ? `?app_version=${appVersion}` : ''}`);
  return (
    <div
      className="w-full flex items-center gap-[7px] py-[15px]"
      onClick={() => {
        if (id === 1) {
          showModal({ url: 'https://pf.kakao.com/_UxajBxj', title: 'KakaoTalk', type: 'web' });
        } else {
          goMenu();
        }
      }}
    >
      <div className="shrink-0 w-[30px]">
        <img src={icon} alt={'empty'} />
      </div>
      <div className="flex-1 text-[17px] leading-[23px] font-bold text-white">{text}</div>
      <div className="shrink-0 flex gap-[7px] items-center">
        {id === 5 && <div className="text-[17px] leading-[23px] text-[#80838B] font-semibold">{appVersion}</div>}
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.5 12L7.5 7L2.5 2" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </div>
  );
}
export default function SomnyMyPageHome() {
  const [searchParams] = useSearchParams();
  const [userNotification, getUserNotification] = useFetchAnxy({
    url: `/somny/routine-time/load`,
  });
  const navigate = useNavigate();
  const setHeaderState = useSetRecoilState(headerAtom);
  const setSessionState = useSetRecoilState(sessionAtom);
  const [myPageState, setMyPageState] = useRecoilState(myPageAtom);
  const [notificationState, setNotificationState] = useRecoilState(notificationAtom);

  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const { isPaymentRequired } = useRecoilValue(statusAtom);

  const isInReview = window.location.pathname.toLowerCase() === '/somnymypageinreview';
  useEffect(() => {
    if (userNotification) {
      if (userNotification.result) {
        setNotificationState(userNotification.result);
      }
    }
  }, [userNotification]);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_BACK, headerTitle: '', headerColor: '#FFFFFF' });
    setSessionState((state) => ({ ...state, currentSession: undefined }));
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    if (!isInReview) {
      getUserNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuMap = [
    { id: 0, icon: ic_alarm, text: '알림 설정' },
    // { id: 1, icon: ic_refund, text: '환불 요청' },
    { id: 2, icon: ic_account, text: '계정 관리' },
    { id: 3, icon: ic_policy, text: '이용약관' },
    { id: 4, icon: ic_provider, text: '사업자 정보' },
    { id: 5, icon: ic_appversion, text: '앱 버전' },
  ];

  const goToPayment = useNavigateWithParams('/somnyPayment');
  const goToTaleHome = useNavigateWithParams('/taleHome');

  const currentMenu = isInReview ? menuMap.filter((item) => item.id !== 2 && item.id !== 0) : menuMap;
  console.log(isInReview, currentMenu);
  return (
    <ContainerByDeviceAnxy bgColor="#050C26">
      <div className="w-full overflow-x-hidden h-full px-[20px] py-[20px]">
        {(isPaymentRequired ? currentMenu.filter((element) => element.id !== 1) : currentMenu).map((menu, index) => (
          <Menu
            id={menu.id}
            icon={menu.icon}
            text={menu.text}
            key={`menu${index}`}
            appVersion={myPageState.userProfile && myPageState.userProfile.appVersion}
          />
        ))}
        <Test
          element={
            <div className=" text-[10px]">
              <textarea
                id="anxyat"
                onClick={() => {
                  const content = document.getElementById('anxyat');
                  content.select();
                  document.execCommand('copy');
                }}
                defaultValue={searchParams.get('at')}
              />
            </div>
          }
        />
        <Test
          element={
            <>
              <PressedEffect
                element={
                  <div className="p-[5px] mt-[10px]  z-[80] border-1 rounded-[20px] text-white text-bold text-center ">
                    결제 페이지로
                  </div>
                }
                action={goToPayment}
              />
              <PressedEffect
                element={
                  <div className="p-[5px] mt-[10px]  z-[80] border-1 rounded-[20px] text-white text-bold text-center ">
                    동화 책장으로
                  </div>
                }
                action={goToTaleHome}
              />
            </>
          }
        />
      </div>
    </ContainerByDeviceAnxy>
  );
}
