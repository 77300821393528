/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
export function RegexInput(props) {
  const { type, value, forceBlur, autofocus, updateValue, updateError } = props;

  const [isFocused, setFocused] = useState(autofocus);
  const [inputValue, setInputValue] = useState(value || '');
  const [isError, setIsError] = useState(false);
  const [isBlur, setIsBlur] = useState(false);

  const dataFormat = [
    {
      type: 'phone',
      placeholder: '휴대폰 번호 (숫자만 입력해주세요)',
      errorMessage: '올바른 번호를 입력해주세요',
      regExp: /^010(?:\d{4})\d{4}$/,
    },
  ];

  const currentData = dataFormat.find((item) => item.type === type);

  const inputRef = useRef();

  useEffect(() => {
    if (updateError) {
      updateError(isError);
    }
  }, [isError]);

  useEffect(() => {
    updateValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (forceBlur) {
      inputRef.current.blur();
    }
  }, [forceBlur]);

  const checkValidate = (e) => {
    if (currentData) {
      if (currentData.regExp.test(e.target.value)) {
        setIsError(false);
      } else {
        setIsError(true);
      }
    }
  };

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,11}$/;
    const data = e.target.value;
    if (regex.test(data)) {
      checkValidate(e);
      setInputValue(data);
      if (data && data.length === 11) {
        setIsBlur(true);
      }
    }
  };

  const handleBlur = (e) => {
    if (!isBlur) {
      setIsBlur(true);
    }
    checkValidate(e);
  };

  return (
    <div>
      <div className={`w-full rounded-[12px]  bg-[#FAFBFD] flex justify-between mb-[10px]`}>
        <input
          type="text"
          pattern="\d*"
          className={`w-full rounded-[12px] px-[20px] py-[12.5px] font-regular text-[17px] leading-[26px] bg-[#FAFBFD] placeholder:text-[#A4A6B0] 
       text-black caret-[#A4A6B0] outline-none ring-[1px] ring-inset ring-[#D1D5DC]`}
          placeholder={currentData.placeholder}
          value={inputValue}
          ref={inputRef}
          onClick={(e) => {
            e.target.focus();
            setFocused(true);
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus={autofocus}
        />
      </div>
      {isError && isBlur && (
        <div className="text-[#E32940] text-[15px] leading-[18px] mt-[10px] px-[4px]">{currentData.errorMessage}</div>
      )}
    </div>
  );
}
