import React, { useEffect, useContext, useRef, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import useDebounce from '../../../hooks/useDebounce';
import { CoachingContext } from '../../../coachingContext';
import trophy from '../../../image/trophy.png';
import textCorrect from '../../../image/text_correct.png';
import lottie from 'lottie-web';
import { motion } from 'framer-motion';
import { changeHeader } from '../../../utils/webview';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import usePostWorkbookDone from '../../../hooks/usePostWorkbookDone';

export default function AnswerTemplate({ title, buttonText, answerInfo, isFinalPage }) {
  const { id, page } = useParams();
  const container = useRef();
  const contentRef = useRef();

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  const postWorkbookDone = usePostWorkbookDone();
  const context = useContext(CoachingContext);

  const buttonRef = useRef();
  const scrollRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    context.setIsQuizTemplate(true);
    changeHeader({ backgroundColor: '#26282C', color: '#FFFFFF' });
  }, []);

  const postOnce = useCallback(
    useDebounce(() => postWorkbookDone(), 300),
    [],
  );

  const goNextPage = () => {
    if (isFinalPage) {
      postOnce();
    } else {
      goNext();
    }
  };

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('../../../image/toolopen.json'),
    });

    return () => instance.destroy();
  }, []);

  return (
    <div className={`w-full h-[calc(100vh-17px)] overflow-hidden relative`}>
      <div className="relative flex flex-col justify-between h-full">
        <div
          ref={scrollRef}
          className="w-full h-full bg-halfGray flex flex-col items-between overflow-auto scrollbar-hide"
        >
          <div className=" w-[calc(200%)] absolute top-[-17px]  left-[50%] translate-x-[-50%] pointer-events-none z-[40]">
            <div className="h-full" ref={container} />
          </div>
          <div className="bg-[#26282C] w-full h-full  relative flex flex-col justify-between items-center text-white">
            <div className="flex items-center my-auto">
              <div className={`mt-[50px] mb-[40px] text-white flex flex-col items-center justify-center`}>
                <motion.div
                  className="w-[144.8px] h-[78.6px] relative top-[10px] "
                  initial={{ y: 30, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, ease: 'easeInOut' }}
                  viewport={{ once: true }}
                  ref={contentRef}
                >
                  <img src={textCorrect} alt="empty" />
                </motion.div>
                <motion.div className="z-[30] h-[120px] w-[120px] flex justify-center items-center">
                  <motion.img
                    src={trophy}
                    alt="empty"
                    className="w-0 h-0"
                    animate={{ height: '120px', width: '120px', overflow: 'hidden' }}
                    transition={{ type: 'spring', tension: 311, friction: 25, duration: 0.8 }}
                  />
                </motion.div>
                <p className="mx-[20px] text-white text-[24px] leading-[28.8px] font-bold mt-[20px] text-center">
                  {title}
                </p>
              </div>
            </div>

            {answerInfo ? (
              <div
                ref={buttonRef}
                className={`bg-white w-full pt-[30px] ${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} px-[20px] `}
                style={{
                  borderRadius: '20px 20px 0px 0px',
                  WebkitOverflowScrolling: 'touch',
                }}
              >
                <div className="mb-[40px] text-[17px] leading-[28px] text-[#26282C] mx-[0]">{answerInfo}</div>
                <SecondaryActionButton state="ACTIVE" text={isFinalPage ? '마치기' : '다음'} action={goNextPage} />
              </div>
            ) : (
              <div ref={buttonRef} className={`w-full ${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} px-[20px]`}>
                <SecondaryActionButton state="ACTIVE" text={isFinalPage ? '마치기' : '다음'} action={goNextPage} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
