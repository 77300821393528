import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useFetchAnxy from './useFetchAnxy';
import worryNoteAtom from '../recoil/anxy/worryNote/atom';
import { responseForm } from '../data/anxy/home/mockDataList';
import { useLocation, useParams } from 'react-router-dom';
import useNavigateWithParams from './useNavigateWithParams';
import useSaveViewedPage from './useSaveViewedPage';
import usePostSessionDone from './usePostSessionDone';

export default function useNavigateSession({ isHome, isVideo }) {
  const worryNoteState = useRecoilValue(worryNoteAtom);
  const { sessionId, page } = useParams();
  const location = useLocation();
  const goToNext = useNavigateWithParams(`../${location.pathname.split('/')[1]}/${sessionId}/${parseInt(page) + 1}`);
  const saveViewedPage = useSaveViewedPage({ isHome: true });
  const postSessionDone = usePostSessionDone({ sessionId });

  const [saveRecord, setSaveRecord] = useFetchAnxy({
    url: '/anxy/record/save',
    requestBody: worryNoteState,
  });

  const handleNavigate = () => {
    if (isHome) {
      console.log('gohome');
      saveViewedPage();
      postSessionDone();
    } else {
      goToNext();
    }
  };
  useEffect(() => {
    if (saveRecord) {
      handleNavigate();
    }
  }, [saveRecord]);

  const navigateSession = useCallback(async () => {
    if (worryNoteAtom !== responseForm) {
      console.log(isVideo);
      if (!isVideo) {
        console.log('save');
        setSaveRecord();
      } else {
        console.log('navigate');
        handleNavigate();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worryNoteState]);

  return navigateSession;
}
