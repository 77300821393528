import React, { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
import MessageBlockWithTail from '../../stories/workbook/MessageBlockWithTail';

function UserAnswerGrayMessage({ index }) {
  const context = useContext(CoachingContext);
  return (
    <div>
      {context.workbookAnswer && (
        <div className="my-40px">
          <MessageBlockWithTail textList={[[context.workbookAnswer[index]]]} type="gray" />
          <p className="my-[24px]">저라면 이런 생각이 들었을 것 같아요</p>
          <MessageBlockWithTail
            textList={
              index === 0
                ? [['어라, 비가 오네? 오늘 운전하면 조금 위험할 것 같은데']]
                : [['정말 위험하다. 오늘은 운전하지 말고 지하철을 타고 가야겠군']]
            }
            type="white"
          />
        </div>
      )}
    </div>
  );
}

export default UserAnswerGrayMessage;
