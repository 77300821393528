import React, { useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context';
import { changeHeader, close, goToTab } from '../../utils/webview';
import finish_onboarding from '../../image/finish_onboarding.png';
import lottie from 'lottie-web';
import { motion } from 'framer-motion';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import webviewToast from '../../development/webviewToast';
import useFetch from '../../hooks/useFetch';

export default function FinishOnboarding() {
  const container = useRef();
  const context = useContext(AuthContext);

  useEffect(() => {
    document.title = '온보딩 종료';

    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'none' });
    window.document.body.style.backgroundColor = '#EFF2FF';

    if (context.onboardingRefresh) {
      webviewToast('closeWithRefresh');
    } else {
      webviewToast('goHomeTab');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [programStartResult, programStart] = useFetch({
    url: `/onboarding/program/start`,
    bg: '#EFF2FF',
  });

  useEffect(() => {
    if (programStartResult) {
      if (context.onboardingRefresh) {
        close({ refresh: true });
      } else {
        goToTab({ tabName: 'home' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programStartResult]);

  useEffect(() => {
    setTimeout(() => {
      const instance = lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: require('../../image/finish_onboarding2.json'),
      });
      return () => instance.destroy();
    }, 300);
  }, []);

  function goNext() {
    programStart();
  }
  const Element = (
    <div className="w-full h-full relative ">
      <motion.div
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className="h-full min-h-full max-w-[370px] mx-auto  px-[20px] pb-[80px] "
      >
        <div className="w-full h-[10%] " />
        <img src={finish_onboarding} alt={'empty'} className="w-full  object-contain" />
        <div className="w-full h-[10%] " />
      </motion.div>

      <div className="absolute top-[-70px] left-0 bottom-0 right-0 overflow-hidden pointer-events-none ">
        <div className="relative h-full w-full flex flex-col justify-center items-center">
          <div className="w-full h-full ">
            <div ref={container} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`w-screen h-screen bg-[#EFF2FF] overflow-hidden `}>
      <TopTitleBottomAction
        title={['세팅을 모두 마쳤어요! \n 오늘 활동을 시작해보세요']}
        content={Element}
        buttonText={'완료'}
        buttonState={'ACTIVE'}
        action={goNext}
        bgColor={'#EFF2FF'}
      />
    </div>
  );
}
