import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import defaultState from '../../data/inside/defaultState';
import useSessionStorage from '../../hooks/useSessionStorage';
import { SelectButton } from '../../stories/button/SelectButton';

export function TopTitleAnswerList(props) {
  const { state, id } = useParams();
  const { point, content, topColor, webHeader, optionList, propsName, action } = props;

  const [insideState, setInsideState] = useSessionStorage('insideState', defaultState);

  function isClicked(option) {
    setInsideState((state) => {
      const updated = { ...state };
      updated[propsName] = option.value;
      return updated;
    });
    setTimeout(() => {
      action();
    }, 400);
  }

  return (
    <div
      className={`flex flex-col pb-[20px]`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col`}>
        <div className="pt-[12px] px-[20px] " style={{ backgroundColor: topColor }}>
          <div className="mt-[80px] text-[50px] leading-[60px] flex flex-col font-semibold">{point}</div>
        </div>
        <div className="flex-1">{content}</div>
      </div>
      <div className={`flex-none w-full relative `}>
        <div
          className="w-full h-[40px] absolute top-[-40px]"
          style={{ background: `linear-gradient(to top, black, rgba(0,0,0,0))` }}
        />
        <div className="px-[20px]">
          {optionList.map((option, index) => (
            <SelectButton
              key={index}
              selected={insideState[propsName] === option.value}
              option={option}
              text={option.text}
              isClicked={isClicked}
              insideBlack
            />
          ))}
        </div>
      </div>
    </div>
  );
}
