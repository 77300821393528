import React, { useEffect, useContext, useRef, useState } from 'react';
import { CoachingContext } from '../../../coachingContext';
import QuizSelectButton from './QuizSelectButton';
import QuizOXButton from './QuizOXButton';
import { changeHeader } from '../../../utils/webview';
import Toast from '../../elements/Toast';

export default function QuizTemplate({ index, buttonList, answer, question, contents, image, isOX }) {
  const context = useContext(CoachingContext);

  const buttonRef = useRef();
  const scrollRef = useRef();
  const questionRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    context.setIsQuizTemplate(true);
    context.setShowWorkbookToast(false);
    setTimeout(() => {
      changeHeader({ backgroundColor: '#26282C', color: '#FFFFFF' });
    }, 100);
  }, []);

  return (
    <div className="w-full h-[calc(100vh-17px)] overflow-hidden relative">
      <div
        ref={scrollRef}
        className="relative flex flex-col justify-between h-full overflow-auto scrollbar-hide bg-halfGray"
      >
        <div className="w-full h-full bg-[#26282C] flex items-center">
          <div className="relative my-auto mt-[50px] mb-[40px] flex flex-1 flex-col justify-center items-center mx-[20px] text-white">
            <p className="text-[#2C4BEC] font-bold text-[20px] leading-[26px] tracking-[-0.3px]">{`Q${index || ''}`}</p>
            <p
              ref={questionRef}
              className="text-white text-[24px] leading-[34px] font-bold mt-[15px] text-center mx-[15px]"
            >
              {question}
            </p>
            {image && (
              <div className="mt-[30px]">
                <img src={image} alt="empty" />
              </div>
            )}
            {contents && (
              <div
                className="w-full rounded-[16px] p-[24px] mt-[30px] text-[17px] leading-[28px]"
                style={{ background: 'rgba(0,0,0,0.2)' }}
              >
                {contents.map((each) => (
                  <span>
                    <span>{each.text}</span>
                    <div className="">{each.component && each.component}</div>
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="w-full bg-[#26282C] relative">
          <div className="absolute top-[0px] left-[0px]">
            <Toast text="한 번 더 생각해보세요." />
          </div>
          <div
            ref={buttonRef}
            className={`relative bg-white w-full pt-[30px] ${
              context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'
            } px-[20px]`}
            style={{
              borderRadius: '20px 20px 0px 0px',
            }}
          >
            {isOX ? (
              <QuizOXButton textList={['O', 'X']} answer={answer} />
            ) : (
              <QuizSelectButton textList={buttonList} answer={answer} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
