/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import CircularProgressingAnxy from '../../../stories/page/CircularProgressingAnxy';

export default function AnxyWebResultLoading(props) {
  const { types } = props;
  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const selfCheckState = useRecoilValue(selfCheckAtom);

  const type = getMaxScoreId();
  const goResult = useNavigateWithParams(`/anxyWebTest/result?type=${type}`, true);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_NONE, headerTitle: '' });
    setProgressBarState({ isProgressBarVisible: false, maxCompleted: 0, completed: 0 });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
  }, []);

  function getMaxScoreId() {
    const sortedList = types
      .map((type) => getEachTypeScoreWithId(type.id))
      .sort(function (a, b) {
        return b.score - a.score || a.id - b.id;
      });

    return sortedList[0].id;
  }

  function getEachType(id) {
    return selfCheckState.selfCheckAnswer.filter((element) =>
      types.find((type) => type.id === id).qcIdList.includes(element.selfCheckQuestionId),
    );
  }
  function getEachTypeScoreWithId(id) {
    return { id: id, score: getEachTypeScore(id) };
  }
  function getEachTypeScore(id) {
    return arraySum(getEachType(id).map((element) => element.score));
  }

  function arraySum(arr) {
    return arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
  }

  const isDone = () => {
    goResult();
  };

  return (
    <CircularProgressingAnxy
      steps={['답변 분석 중', '테스트 결과 구성 중']}
      isDone={isDone}
      color={'#EF670B'}
      disclaimer={
        '본 검사는 Orwell Health, Inc에서 연구개발한 것으로 의사의 검수를 거쳤지만 본 검사 결과가 불안장애를 진단해 주는 것은 아닙니다. 불안장애에 대한 진단은 정신건강의학과 전문의와 상의하시길 바랍니다.'
      }
    />
  );
}
