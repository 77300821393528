const GradientBox = ({ item }) => {
  return (
    <div className="flex w-full h-[50px] rounded-[30px] mb-[10px]" style={{ background: item.bg }}>
      <div className="w-full flex justify-between items-center px-[20px] mb-[2px] relative text-center">
        <span className="w-[60px]">{item.texts[0]}</span>
        <span className="font-bold w-[60px]">{item.texts[1]}</span>
        <span className="w-[60px]">{item.texts[2]}</span>
      </div>
    </div>
  );
};

export default GradientBox;
