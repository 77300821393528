import { useContext, useEffect } from 'react';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { SelectCheckButton } from '../../../stories/button/SelectCheckButton';
import { CoachingContext } from '../../../coachingContext';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useParams } from 'react-router-dom';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import MessageBlockWithTail from '../../../stories/workbook/MessageBlockWithTail';
import { useState } from 'react';
import thoughtNoteData from '../../../data/thoughtNoteData';

const defaultValue = {
  situation: '',
  thought: '',
  thoughtTrapIdList: [],
  newThought: '',
};

const SelectTrapOfThought = () => {
  const [trapInfo, setTrapInfo] = useSessionStorage('trapInfo', defaultValue);
  const { id, page } = useParams();
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [disable, setDisable] = useState(false);

  const isClicked = (option) => {
    console.log('trapInfo: ', option, trapInfo.thoughtTrapIdList, trapInfo.thoughtTrapIdList.includes(option.id));
    const updated = { ...trapInfo };
    if (!trapInfo.thoughtTrapIdList.includes(option.id)) {
      updated.thoughtTrapIdList = [
        ...trapInfo.thoughtTrapIdList.filter((element) => element.id !== option.id),
        option.id,
      ];
      console.log(updated.thoughtTrapIdList.includes(option.id));
      console.log(updated);
    } else {
      console.log('있으');
      updated.thoughtTrapIdList = [...trapInfo.thoughtTrapIdList.filter((element) => element !== option.id)];
      console.log(updated);
    }
    setTrapInfo(updated);
  };

  useEffect(() => {
    // setTrapInfo(defaultValue);
  }, []);

  useEffect(() => {
    console.log(trapInfo);
    if (trapInfo.thoughtTrapIdList.length === 0) {
      setButtonState('INACTIVE');
    } else {
      setButtonState('ACTIVE');
    }
  }, [trapInfo]);

  const context = useContext(CoachingContext);
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  return (
    <div className="w-full px-20px h-[calc(100vh-17px)] relative overflow-hidden">
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="mt-[50px]">
          <p className="font-bold text-[24px] leading-[30px] text-[#26282C]" style={{ wordBreak: 'keep-all' }}>
            혹시 어떤 생각 함정들이 보이나요?
          </p>
          <MessageBlockWithTail type="gray" textList={[[trapInfo.thought]]} my />
          {thoughtNoteData
            .filter((item) => item.subtitle)
            .map((item) => (
              <div className="w-full flex justify-between items-center py-[20px] overflow-hidden">
                <div
                  className="flex items-center w-full overflow-hidden"
                  onClick={() => {
                    isClicked(item);
                    setDisable(!disable);
                  }}
                >
                  <img alt="empty" src={item.image} className="h-[50px] w-[50px]" />
                  <div className="flex flex-col ml-[15px]">
                    <p className="mb-[4px] font-bold text-[17px] leading-[23px]">{item.text}</p>
                    <p className="text-[14px] leading-[20px] text-[#6B6D76]">{item.subtitle}</p>
                  </div>
                </div>
                <div className="ml-[10px]">
                  <SelectCheckButton
                    selected={trapInfo.thoughtTrapIdList.includes(item.id)}
                    option={item}
                    isClicked={isClicked}
                    disable={disable}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px] mt-[40px]`}>
          <SecondaryActionButton state={buttonState} text="다음" action={goNext} />
        </div>
      </div>
    </div>
  );
};

export default SelectTrapOfThought;
