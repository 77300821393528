import React from 'react';
// import PropTypes from 'prop-types';

export function RectWithCenterImageKeepSize(props) {
  const { image, background, height } = props;

  return (
    <div
      className={`rounded-[16px]  flex relative overflow-hidden w-full`}
      style={{ backgroundColor: background, height: height }}
    >
      <div style={{}} className="w-full h-full  ">
        <img src={image} className=" h-full object-cover mx-auto" alt="empty" />
      </div>
    </div>
  );
}
