import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context';
import { MultipleSelectButton } from '../../stories/button/MultipleSelectButton';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { changeHeader } from '../../utils/webview';

export default function MultipleSelectTemplate(props) {
  const { id, question, options } = props;
  const [buttonState, setButtonState] = useState('INACTIVE');

  const context = useContext(AuthContext);

  var step = [];
  if (id === 6) {
    if (context.presurvey.subjectOfTreatmentList) {
      step = context.presurvey.subjectOfTreatmentList;
    }
  }

  const [answer, setAnswer] = useState(step);
  const goWizard = useNavigateWithParams(`/counselorSearchWizard/search`);

  function goNextPage() {
    console.log('answer: ', answer, id);
    if (id === 6) {
      changeHeader({ backgroundColor: '#2C4BEC', text: '', color: '#FFFFFF', buttonType: 'back' });
      context.presurvey['subjectOfTreatmentList'] = answer;
      setTimeout(() => {
        goWizard();
      }, 100);
    }
  }

  function select(option) {
    const selected = option;
    if (answer.includes(selected)) {
      let filteredArray = answer.filter((item) => item !== selected);
      setAnswer(filteredArray);
    } else {
      setAnswer((answer) => [...answer, selected]);
    }
  }

  function isClicked(option) {
    if (option !== '') {
      console.log('이미클릭함');
      select(option);
    }
  }
  useEffect(() => {
    if (answer.length !== 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [answer]);

  const Element = (
    <div className={`h-full  pt-[43px] pb-[30px] px-[20px] `}>
      {options.map((option, index) => (
        <MultipleSelectButton
          key={index}
          selected={answer.includes(option)}
          option={option}
          text={option}
          isClicked={isClicked}
        />
      ))}
    </div>
  );

  return (
    <div className={`h-full `}>
      <TopTitleBottomAction
        title={question}
        content={Element}
        buttonText={'다음'}
        buttonState={buttonState}
        action={goNextPage}
      />
    </div>
  );
}
