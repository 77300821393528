/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import SelectTemplate from '../../components/onboarding/SelectTemplate2';
import MultipleSelectTemplate from '../../components/onboarding/MultipleSelectTemplate2';
import MultipleSelectWithDefaultTemplate from '../../components/onboarding/MultipleSelectWithDefaultTemplate';
import newOnboardingQ from '../../data/newOnboardingQ';
import { changeHeader } from '../../utils/webview';
import { AuthContext } from '../../context';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import onboardingBegin1 from '../../image/onboardingBegin1.png';
import onboardingBegin2 from '../../image/onboardingBegin2.png';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useFetch from '../../hooks/useFetch';
import useMixpanelEvent from '../../hooks/useMixpanelEvent';

export default function AssignCounselor() {
  const context = useContext(AuthContext);
  let { stage, id } = useParams();
  const [searchParams] = useSearchParams();
  const headerBack = searchParams.get('navigation') === 'back';
  const [company, setCompany] = useState(false);

  const [useInfo, getUserInfo] = useFetch({
    url: `/user/info/detail`,
    bg: '#FFFFFF',
  });

  const setMixPanel = useMixpanelEvent({
    eventName: '상담사 배정 안내',
  });

  useEffect(() => {
    if (stage === 'begin' && id === 'security') {
      getUserInfo();
      setMixPanel();
    }
  }, []);

  useEffect(() => {
    if (useInfo) {
      if (useInfo.result.company) {
        console.log('기업회원');
        context.presurvey['job'] = '직장인';
        setCompany(true);
      }
    }
  }, [useInfo]);

  useEffect(() => {
    if (parseInt(id) === 0) {
      document.title = '일반 회원 직업 선택';
    } else if (parseInt(id) === 1) {
      document.title = '연인 유무 선택';
    } else if (parseInt(id) === 2) {
      document.title = '자녀 유무 선택';
    } else if (parseInt(id) === 3) {
      document.title = '마음 건강 활동 선택';
    } else if (parseInt(id) === 4) {
      document.title = '상담 경험 여부 선택';
    } else if (parseInt(id) === 5) {
      document.title = '진료 경험 여부 선택';
    } else if (parseInt(id) === 6) {
      document.title = '진단 종류 선택';
    }
    context.setShowProgressingbar(false);
    if (stage === 'begin' && id !== 'security' && !headerBack) {
      changeHeader({ backgroundColor: '#FFFFFF', color: '#000000', buttonType: 'none' });
    } else {
      changeHeader({ backgroundColor: '#FFFFFF', color: '#000000', buttonType: 'back' });
    }

    context.setCounselorSearchWizardChoice([]);
  }, []);

  const goNext = useNavigateWithParams(`/assignCounselor/begin/security`);
  const goSurvey = useNavigateWithParams(company ? `/assignCounselor/survey/1` : `/assignCounselor/survey/0`);

  return (
    <div className={`w-screen h-screen overflow-hidden  `}>
      {stage === 'begin' &&
        (id === 'first' ? (
          <TopTitleBottomAction
            title={'전담 상담사를 배정해드릴게요'}
            subtitle={'몇 가지 질문을 드릴 거예요. 답변 내용을 분석해서 마음관리를 도와 줄 전담 상담사를 찾아드릴게요.'}
            content={
              <div className="h-full flex items-center justify-center ">
                <img src={onboardingBegin1} alt={'empty'} className="my-[30px]" />
              </div>
            }
            buttonText={'다음'}
            buttonState={'ACTIVE'}
            action={goNext}
            topColor={'#FFFFFF'}
          />
        ) : id === 'again' ? (
          <TopTitleBottomAction
            title={'기업회원 전담 상담사를 새로 배정해드릴게요'}
            subtitle={
              '기업회원에게는 기업 전문 상담사를 배정해드려요. 몇 가지 질문을 드릴 거예요. 답변 내용을 분석해서 마음관리를 도와 줄 전담 상담사를 찾아드릴게요.'
            }
            content={
              <div className="h-full flex items-center justify-center ">
                <img src={onboardingBegin1} alt={'empty'} className="my-[30px]" />
              </div>
            }
            buttonText={'다음'}
            buttonState={'ACTIVE'}
            action={goNext}
            topColor={'#FFFFFF'}
          />
        ) : (
          <TopTitleBottomAction
            title={'안심하고 답해주세요'}
            subtitle={'인사이드 회원의 중요 정보는 개인을 식별할 수 없게끔 암호화되어 관리돼요.'}
            content={
              <div className="h-full flex items-center justify-center ">
                <img src={onboardingBegin2} alt={'empty'} className="my-[30px]" />
              </div>
            }
            buttonText={'다음'}
            buttonState={'ACTIVE'}
            action={goSurvey}
            topColor={'#FFFFFF'}
          />
        ))}
      {stage === 'survey' && (
        <>
          {newOnboardingQ[parseInt(id)].type === 'select' && (
            <SelectTemplate
              id={newOnboardingQ[parseInt(id)].id}
              question={newOnboardingQ[parseInt(id)].question}
              options={newOnboardingQ[parseInt(id)].options}
            />
          )}
          {newOnboardingQ[parseInt(id)].type === 'multiple_select' && (
            <MultipleSelectTemplate
              id={newOnboardingQ[parseInt(id)].id}
              question={newOnboardingQ[parseInt(id)].question}
              options={newOnboardingQ[parseInt(id)].options}
            />
          )}
          {newOnboardingQ[parseInt(id)].type === 'multiple_select_with_default' && (
            <MultipleSelectWithDefaultTemplate
              id={newOnboardingQ[parseInt(id)].id}
              question={newOnboardingQ[parseInt(id)].question}
              options={newOnboardingQ[parseInt(id)].options}
            />
          )}
        </>
      )}
    </div>
  );
}
