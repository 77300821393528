/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../stories/page/BottomActionAnxy';
import { useParams, useSearchParams } from 'react-router-dom';
import anxyTypeData from '../../data/anxyTypeData';
import { useSetRecoilState } from 'recoil';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import selfCheckAtom from '../../recoil/common/selfCheck/atom';
import woriGetSmaller from '../../image/anxy/wori/woriGetSmaller.png';
import 온몸이완하기 from '../../image/anxy/온몸이완하기.png';
import 생각함정찾기 from '../../image/anxy/생각함정찾기.png';
import 불안마주하기 from '../../image/anxy/불안마주하기.png';
import curriculum from '../../image/anxy/curriculum.png';
import { PressedEffect } from '../../stories/button/PressedEffect';
import userAtom from '../../recoil/anxy/user/atom';
import useMixpanelEventAnxy from '../../hooks/useMixpanelEventAnxy';
import webviewToast from '../../development/webviewToast';

export default function TypeResult(props) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = parseInt(searchParams.get('type'));
  const uiType = searchParams.get('uiType');
  const typeData = anxyTypeData.find((element) => element.type === type);
  const goNext = useNavigateWithParams(`/anxyTest/typeInfo${parseInt(id.substring(8)) + 1}`);
  const goIntro = useNavigateWithParams(`/trial/0/1`);
  const goWalkthroughA = useNavigateWithParams(`/walkthroughA/0`);
  const goWalkthroughB = useNavigateWithParams(`/walkthroughB/0`);
  const goHome = useNavigateWithParams(`/anxyHome`);

  const initializeSelfCheckAndGoIntro = () => {
    setSelfCheckState({ qcList: [], selfCheckAnswer: [], selfCheckResult: [] });
    goIntro();
  };

  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const setHeaderState = useSetRecoilState(headerAtom);
  const setSelfCheckState = useSetRecoilState(selfCheckAtom);
  const setUserState = useSetRecoilState(userAtom);

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '불안 타입 검사 결과',
    params: {
      체크포인트:
        id === 'typeInfo2'
          ? '어떻게 관리하나요?'
          : id === 'typeInfo3'
          ? '3주간의 디지털 코스'
          : id === 'typeInfo4'
          ? '과학으로 입증된 효과'
          : '지금 시작하세요',
    },
  });

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
    setUserState((state) => ({ ...state, anxietyTypeId: type }));

    if (id !== 'typeInfo1') {
      setMixPanel();
    }
  }, []);

  const titleClassName = 'text-[24px] leading-[30px] font-bold';
  const textClassName = 'text-[17px] leading-[28px] font-light';

  return (
    <BottomActionAnxy
      content={
        id === 'typeInfo1' ? (
          <div className=" pt-[12px] px-[20px] flex flex-col gap-[24px] text-[#26282C]">
            <p className={titleClassName}>{typeData.name}는</p>
            <p className={textClassName}>{typeData.explanation}</p>
          </div>
        ) : id === 'typeInfo2' ? (
          // <div className="pt-[12px] px-[20px] flex flex-col gap-[24px] text-[#26282C]">
          //   <p className={titleClassName}>어떻게 관리하나요?</p>
          //   <div className="flex flex-col gap-[40px]">
          //     <p className={textClassName}>{typeData.solution}</p>
          //     {[
          //       {
          //         title: '온몸 이완하기',
          //         text: '불안감을 느낄 때마다 몸을 이완하며 불안감을 즉각 낮추는 점진적 근육 이완법(Progressive muscle relaxation)',
          //         image: 온몸이완하기,
          //       },
          //       {
          //         title: '생각함정 찾기',
          //         text: '불합리한 사고를 알아차리고 건강하고 이성적인 사고로 변화시키는 인지 재구성(Cognitive restructuring)',
          //         image: 생각함정찾기,
          //       },
          //       {
          //         title: '불안 마주하기',
          //         text: '불안한 기억과 상황에 스스로 노출하며 불안감을 낮추고 통제감을 갖는 노출 요법(Exposure therapy)',
          //         image: 불안마주하기,
          //       },
          //     ].map((each, index) => (
          //       <div className="flex flex-col gap-[15px]">
          //         <div className="flex flex-col gap-[10px] text-[17px]">
          //           <p className="leading-[24px] font-bold">{each.title}</p>
          //           <p className="leading-[28px] font-light">{each.text}</p>
          //         </div>
          //         <div className="w-full py-[30px] rounded-[16px] bg-[#F3F5F8]">
          //           <img src={each.image} alt={'empty'} className={'w-[209px] mx-auto'} />
          //         </div>
          //       </div>
          //     ))}
          //   </div>
          // </div>
          <div className="flex-1 h-full pt-[12px] px-[20px] flex flex-col gap-[24px] text-[#26282C]">
            <p className={titleClassName}>걱정 마세요, 지금부터 불안을 다스리는 법을 알려드릴 거니까요</p>
            <div className="flex-1 h-full relative">
              <img src={woriGetSmaller} alt={'empty'} className={'absolute w-full h-full py-[50px] object-contain'} />
            </div>
          </div>
        ) : id === 'typeInfo3' ? (
          <div className="flex flex-col gap-[30px]">
            <div className="pt-[12px] px-[20px] flex flex-col gap-[24px] text-[#26282C]">
              <p className={titleClassName}>3주간의 디지털 코스</p>
              <p
                className={textClassName}
              >{`이제부터 3주간 ${typeData.name}를 위한 불안 관리 방법을 배워보도록 해요.`}</p>
            </div>
            <img src={curriculum} alt={'empty'} />
          </div>
        ) : id === 'typeInfo4' ? (
          <div className="flex flex-col gap-[30px] px-[20px] ">
            <div className="pt-[12px] flex flex-col gap-[24px] text-[#26282C]">
              <p className={titleClassName}>과학으로 입증된 효과</p>
              <p className={textClassName}>{`Anxy는 과학과 임상 연구에 기반해 만들어졌으니 믿고 따라오셔도 돼요.`}</p>
            </div>
            <div className="flex flex-col gap-[50px]">
              <div className="flex flex-col gap-[40px] ">
                {[
                  { percentage: 71, effect: '불안 증상의 임상적 개선' },
                  { percentage: 57, effect: '전반적인 기분 개선' },
                  { percentage: 47, effect: '불면증 개선' },
                ].map((each, index) => (
                  <div className="flex flex-col gap-[8px] items-center " key={`effect${index}`}>
                    <div className="flex text-[50px] text-[#EF670B] font-bold">{each.percentage}%</div>
                    <div className="text-[17px] leading-[23px] text-[#6B6D76]">{each.effect}</div>
                  </div>
                ))}
              </div>
              <div className="border-t-[1px] border-[#E1E4EB] pt-[20px] text-[14px] leading-[20px] text-[rgba(0,0,0,0.4)]">
                Carl, J. R., Miller, C. B., Henry, A. L., Davis, M. L., Stott, R., Smits, J. A., … & Espie, C. A.
                (2020). Efficacy of digital cognitive behavioral therapy for moderate-to-severe symptoms of generalized
                anxiety disorder: A randomized controlled trial. Depression and Anxiety, 37(12), 1168-1178.
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full pt-[12px] px-[20px] text-[#26282C] flex flex-col justify-between mb-0">
            <div className="flex flex-col gap-[24px] ">
              <p className={titleClassName}>불확실성 공포자를 위한 불안 관리, 지금 시작하세요</p>
              <p
                className={textClassName}
              >{`앞으로 영상을 통해 불안을 다스리는 방법에 대해 알려드리고 같이 연습해 볼 거예요. 오늘부터 들어보세요.`}</p>
            </div>
            <div>
              <PressedEffect
                element={
                  <div className="w-full rounded-[20px] bg-[#F3F5F8] p-[24px] flex gap-[15px] ">
                    <div className="w-[80px] h-[80px] rounded-[12px] overflow-hidden shrink-0">
                      <img src={typeData.introThumbnail} alt={'empty'} />
                    </div>
                    <div className="flex flex-col gap-[3px] ">
                      <p className="text-[20px] leading-[26px] font-bold">{typeData.introTitle}</p>
                      <div className="flex items-center gap-[5px]">
                        <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.5 4.63397C9.16667 5.01887 9.16667 5.98113 8.5 6.36603L1.75 10.2631C1.08333 10.648 0.25 10.1669 0.25 9.39711L0.25 1.60289C0.25 0.833085 1.08333 0.35196 1.75 0.73686L8.5 4.63397Z"
                            fill="#4E4F53"
                          />
                        </svg>
                        <p className="text-[15px] leading-[21px]">{typeData.introLength}</p>
                      </div>
                    </div>
                  </div>
                }
                action={initializeSelfCheckAndGoIntro}
              />
            </div>
          </div>
        )
      }
      noGradient={id === 'typeInfo2'}
      buttonText={id !== 'typeInfo2' ? '다음' : '시작하기'}
      buttonState={'ACTIVE'}
      action={() => {
        if (id === 'typeInfo5') {
          initializeSelfCheckAndGoIntro();
        } else if (id === 'typeInfo2') {
          goHome();
        } else {
          goNext();
        }
      }}
    />
  );
}
