import React from 'react';

export function Title(props) {
  const { title, color } = props;

  return (
    <div>
      <div
        className="font-bold text-[24px] leading-[30px] text-[#26282C] whitespace-pre-line"
        style={{ wordBreak: 'keep-all', color: color }}
      >
        {title}
      </div>
    </div>
  );
}
