import { somnyColorPalette } from './somnyColorPalette';

const somnySessionPageData = [
  {
    knitId: 'knit_aa',
    title: '수면의 세계',
    backgroundColor: somnyColorPalette.primary,
    contents: [
      { isCover: true },
      {
        title:
          '안녕하세요, 반가워요. 저를 찾아오시다니 수면에 대해 고민이 많으셨나봐요. 아마 뒤척이는 밤을 보내고 계시겠죠? 잘 찾아오셨어요.',
      },
      {
        title:
          '오늘부터 4주 동안 뜨개를 전달해드리려고 해요. 제가 밤새 만든 뜨개랍니다. 뜨개에는 수면에 대한 많은 이야기와 활동들이 담겨있어요.',
        imageCss: 'px-[26px] pb-[30px]',
      },
      {
        title:
          '처음에는 우리가 잠드는 원리와 이유에 대해 알아볼 거예요. 이를 이해하고 나면 어떻게 자고 일어나야 할지 감이 올 거예요.',
        imageCss: 'px-[50px] pb-[125px]',
      },
      {
        title:
          '다음에는 불면증에 대해 알아볼 거예요. 불면증을 검사해보고, 불면증에 해당할 가능성이 있다면 여기서 빠져나오기 위한 방법을 알아볼게요.',
        imageCss: 'pl-[117px] pr-[114px] pb-[109.4px]',
      },
      {
        title:
          '이후에는 수면 습관에 대해 알아볼 거예요. 낮 시간, 잠자기 전 시간, 그리고 잠자리 환경에서의 잘못된 수면 습관을 바로잡을 거랍니다.',
        imageCss: 'px-[20px] pb-[54px]',
      },
      {
        title:
          '마지막으로는 불면증에 빠진 사람들에게 나타나는 공통된 생각 패턴을 알아보고, 이러한 생각에서 벗어날 수 있는 방법을 배워볼 거예요.',
        imageCss: 'px-[40px] pb-[51px]',
      },
      {
        title:
          '거창한 이야기들을 많이 했지만, 지레 겁먹을 필요는 없답니다. 제가 찬찬히 알려드릴게요. 그러면 첫인사는 이쯤하고 우리는 오늘밤에 다시 만날까요? 제가 동화를 한 편 가지고 갈 테니 기대해도 좋아요. \n\n 그러면 좋은 하루 보내세요.',
        imageCss: 'pl-[34px] pr-[60px]',
      },
    ],
  },
  {
    knitId: 'knit_ab',
    title: '수면 효율',
    backgroundColor: somnyColorPalette.primary,
    contents: [
      { isCover: true },
      {
        title:
          "오늘은 수면 효율에 대해 알아볼 거랍니다. 수면 효율은 말 그대로 ‘잠자리에 누워서 얼마나 효과적으로 잠들었는지'를 나타내요.",
        imageCss: 'pl-[110px] pr-[71.71px] pb-[202px]',
      },
      {
        title:
          '수면 효율은 아래와 같이 계산돼요. 가령 지난밤 침대에 총 10시간을 머물렀는데 그중에서 6시간만 잤다면 수면 효율은 60%겠죠?',
        imageCss: 'px-[56px] pb-[220px]',
      },
      {
        title:
          '수면 효율이 높으면 잠자리에 눕자마자 바로 잠이 든답니다. 반대로 수면 효율이 낮으면 잠자리에 누워 뒤척이는 시간이 많다는 뜻이에요.',
        imageCss: 'px-[38px] pb-[148px]',
      },
      {
        title:
          '수면 습관이 좋지 않거나 불면증이 있는 사람들은 수면 효율이 낮아요. 그 결과 수면의 질은 떨어지고 자고 일어나도 개운하지 않답니다.',
        imageCss: 'px-[0]',
      },
      {
        title:
          '수면 효율은 약 85~90% 정도를 유지하는 게 좋다고 알려져 있어요. 우리는 앞으로 수면 효율은 높이는 다양한 활동을 진행할 거랍니다.',
        imageCss: 'px-[90px] pb-[114px]',
      },
      {
        title:
          '하지만 그 전에 현재 수면 효율이 어느 정도인지 알아야겠죠? 수면 효율을 알기 위해서는 약 일주일 정도 수면을 기록해 보아야 해요.',
        imageCss: 'px-[20px] pb-[124px]',
      },
      {
        title:
          '벌써 두 번 수면 기록을 진행하셨죠? 앞으로 다섯 번만 더 기록하면 제가 수면에 대해 더 많은 정보를 알려드릴 테니 꾸준히 기록해보아요.',
        imageCss: 'px-[35px] pb-[234px]',
      },
      {
        title: '그럼 오늘은 여기까지 할까요? 이따 저녁에 또 새로운 동화를 가지고 갈게요. 오늘도 좋은 하루 보내세요!',
        imageCss: 'pl-[34px] pr-[60px]',
      },
    ],
  },
  {
    knitId: 'knit_ac',
    title: '좋은 수면이란',
    backgroundColor: somnyColorPalette.primary,
    contents: [
      { isCover: true },
      {
        title:
          "오늘은 좋은 수면이란 무엇인지 알아볼 거예요. 아마 더 잘 자고 싶은 마음이 클 텐데, 어느 정도 자야 ‘잘 잤다'고 이야기할 수 있을까요?",
        imageCss: 'pb-[116px]',
      },
      {
        title:
          "사실 ‘좋은 수면’이라는 건 없답니다. 오히려 많은 사람들이 ‘좋은 수면'에 집착하다가 초조함을 느끼고, 결국 불면증에 빠지곤 해요.",
        imageCss: 'pb-[208.45px] px-[63px]',
      },
      {
        title:
          '수면은 사람마다, 시기마다 달라요. 누군가에게는 적당한 수면이 나에게는 불충분한 수면일 수도 있어요. 몇 가지 예를 들어볼게요.',
        imageCss: 'pl-[70px] pr-[72px] pb-[226px]',
      },
      {
        title:
          '수면 시간은 사람마다 달라요. 평균적으로 7~8시간의 수면이 필요하지만, 누군가에겐 4시간 또는 10시간의 수면이 적당할 수도 있어요.',
        imageCss: 'px-[67px] pb-[224px]',
      },
      {
        title:
          '잠에 드는 시간도 달라요. 평균적으로는 저녁 11시에 잠들어 아침 7시 정도에 일어나지만 이 또한 사람마다 차이가 있어요.',
        imageCss: 'px-[94px] pb-[154.5px]',
      },
      {
        title:
          '그러니 “최소한 얼마는 자야 한다”, “최소한 언제는 자야 한다”라는 생각은 접어두시길 바라요. 오히려 우리를 초조하게 할 뿐이랍니다.',
        imageCss: 'px-[48px] pb-[234px]',
      },
      {
        title:
          '수면은 저축이 되기도 해요. 마치 예금과 같죠. 오늘 놓친 잠은 앞으로도 영영 놓치는 게 아니에요. 다음에 조금 더 많이 자면 그만이랍니다.',
        imageCss: 'px-[45px] pb-[224px]',
      },
      {
        title:
          '한편 수면 부족의 영향은 생각보다 크지 않아요. 연구에 따르면 하룻밤을 뒤척여도 다음날 일상 생활에 치명적인 영향이 있진 않았답니다.',
        imageCss: 'px-[42px] pb-[60px]',
      },
      {
        title:
          '그러니 지금 조금 못 자고 있다고 해서 조급해 할 필요가 없어요. 적당한 수면은 사람마다 다르고, 상황에 따라 다른 것이니까요.',
        imageCss: 'pl-[54px] pr-[51px] pb-[198px]',
      },
      {
        title:
          '앞으로 제가 더 깊게 자고 불면증에서 벗어나는 방법을 알려드릴 테니 여유를 가지고 천천히 따라오세요. 제가 잃어버린 밤을 되찾아드릴게요.',
        imageCss: 'pl-[91px] pr-[82px] pb-[204px]',
      },
      {
        title:
          '오늘은 여기서 마무리하기로 해요. 저는 이만 자야겠어요. 제가 꿈 속에서 새로운 동화를 들고 찾아갈게요. 오늘도 좋은 하루 보내세요!',
        imageCss: 'pl-[34px] pr-[60px]',
      },
    ],
  },
];

export default somnySessionPageData;
