import React, { useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context';
import { changeHeader } from '../../utils/webview';
import GoalSwiper from '../../components/onboarding/GoalSwiper';
// import * as _ from 'lodash';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useFetch from '../../hooks/useFetch';

export default function Goal() {
  const context = useContext(AuthContext);
  const contentRef = useRef();
  const chartRef = useRef();
  const scrollRef = useRef();

  useEffect(() => {
    document.title = '목표 제시';

    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'back' });
  }, []);

  // const [recommendedCourse, getRecommendedCourse] = useFetch({
  //   url: `/onboarding/course-recommendation/detail`,
  //   bg: '#EFF2FF',
  // });

  const [recommendedCourse, getRecommendedCourse] = useFetch({
    url: `course/recommendation/list`,
    bg: '#EFF2FF',
  });

  useEffect(() => {
    if (recommendedCourse) {
      console.log('코스 추천 결과', recommendedCourse.result);
      // let temp = _.cloneDeep(recommendedCourse.result);
      // temp['candidateCourseList'] = temp.candidateCourseList
      //   .filter((element) => element.courseId === temp.pickedCourse.courseId)
      //   .concat(temp.candidateCourseList.filter((element) => element.courseId !== temp.pickedCourse.courseId));
      // temp.candidateCourseList.map((each, index) => (temp.candidateCourseList[index]['isComingSoon'] = true));
      // console.log('isComingSoon', temp);
      // context.setRecommendedCourse(temp);
      context.setRecommendedCourse(recommendedCourse.result.courseList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedCourse]);

  useEffect(() => {
    console.log('goal: ', context.goal);
    getRecommendedCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goRecommendedCourse = useNavigateWithParams(`/courseRecommendation`);
  function goNext() {
    console.log('다음');
    goRecommendedCourse();
  }

  const Element = (
    <div className="h-full  " ref={contentRef}>
      <div className="w-full h-full relative">
        <div
          className="w-full  absolute   left-[50%] translate-x-[-50%] text-center"
          ref={chartRef}
          style={{
            top:
              contentRef.current && chartRef.current
                ? Math.max(`${(contentRef.current.offsetHeight - chartRef.current.offsetHeight) / 2}`, '30')
                : '',
          }}
        >
          <GoalSwiper steps={context.goal} />
        </div>
      </div>
    </div>
  );

  return (
    <div className={`w-screen h-screen bg-[#EFF2FF] overflow-hidden  relative `} ref={scrollRef}>
      <TopTitleBottomAction
        title={['검사 결과와 성향을 분석하여 설정한 목표예요']}
        content={Element}
        buttonText={'다음'}
        buttonState={'ACTIVE'}
        action={goNext}
        bgColor={'#EFF2FF'}
      />
    </div>
  );
}
