export function convertNumberToStringTime(hour) {
  if (hour - Math.floor(hour) === 0) {
    if (hour === 0) {
      return '0분';
    }
    return `${hour}시간`;
  } else {
    const minute = Math.round((hour - Math.floor(hour)) * 60);
    if (Math.floor(hour) === 0) {
      return `${minute}분`;
    }
    return `${Math.floor(hour)}시간 ${minute}분`;
  }
}

export function convertMinutesToStringTime(minutes) {
  if (minutes === 0) {
    return '0분';
  }
  if (minutes % 60 === 0) {
    return `${minutes / 60}시간`;
  } else {
    if (parseInt(minutes / 60) === 0) {
      return `${minutes}분`;
    }
    return `${parseInt(minutes / 60)}시간 ${minutes - parseInt(minutes / 60) * 60}분`;
  }
}

export function convertMinutesToISO(minutes) {
  let convertedMin = minutes;
  if (minutes < 0) {
    convertedMin = 24 * 60 + minutes;
  }
  if (convertedMin === 0) {
    return '00:00';
  }
  if (convertedMin % 60 === 0) {
    return `${convertedMin / 60}:00`;
  } else {
    if (parseInt(convertedMin / 60) === 0) {
      return `00:${convertedMin}`;
    }
    return `${String(parseInt(convertedMin / 60)).padStart(2, '0')}:${String(
      convertedMin - parseInt(convertedMin / 60) * 60,
    ).padStart(2, '0')}`;
  }
}

export function getTimePassedNumber(start, end) {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const hour = (endTime - startTime) / (1000 * 60 * 60);
  return hour;
}

export function getTimePassedString(start, end) {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const hour = (endTime - startTime) / (1000 * 60 * 60);

  return convertNumberToStringTime(hour);
}

export function getDay(date) {
  const days = ['일', '월', '화', '수', '목', '금', '토'];
  return days[new Date(date).getDay()];
}

export function getConvertedDay(date, stringList) {
  const offset = new Date().getTimezoneOffset() * 60000;
  const today = new Date(Date.now() - offset).toISOString().substring(0, 10);
  // const today = new Date().toISOString().substring(0, 10);
  // console.log(new Date(Date.now() - offset).toISOString(), (new Date(today) - new Date(date)) / (1000 * 3600 * 24));
  if ((new Date(today) - new Date(date)) / (1000 * 3600 * 24) === 1 && stringList && stringList.includes('어제')) {
    return '어제';
  } else if (
    (new Date(today) - new Date(date)) / (1000 * 3600 * 24) === 0 &&
    stringList &&
    stringList.includes('오늘')
  ) {
    return '오늘';
  }
  return getDay(date);
}

export function getMinutes(dateString) {
  const date = new Date(dateString);
  // console.log(date.getHours(), date.getMinutes());
  return date.getHours() * 60 + date.getMinutes();
}

export function getLastDate(date) {
  const temp = new Date(date);
  temp.setDate(temp.getDate() - 1);

  return `${temp.getFullYear()}-${String(temp.getMonth() + 1).padStart(2, '0')}-${String(temp.getDate()).padStart(
    2,
    '0',
  )}`;
}
