import { useContext, useEffect, useRef, useState } from 'react';
import 'moment/locale/ko';
import moment from 'moment';
import { CoachingContext } from '../../../coachingContext';
import webviewToast from '../../../development/webviewToast';

const getTimeDiff = (startTime, endTime) => {
  return moment.duration(moment(endTime).diff(startTime));
};

export default function SlideTimePickerWithArea({ startTime, endTime, selectedTime, changeSelectedTime, prevTime }) {
  const [prevPosition, setPrevPosition] = useState(0);
  const [prevScroll, setPrevScroll] = useState(0);
  const [colorWidth, setColorWidth] = useState(0);
  // const [wholeStickNumber, setWholeSticknumber] = useState();
  const coloredArea = useRef();
  const transformX = useRef(0);

  // 현재와 끝 시간 차이 구해서 막대 개수 구하기
  const timeDiff = getTimeDiff(startTime, endTime);
  console.log(timeDiff);
  const minuteDiffDivByTen = timeDiff.asMinutes() / 10;
  const wholeStickNumber = minuteDiffDivByTen + 1;

  const timeArray = Array.from({ length: wholeStickNumber }, (v, i) => 0);

  const scrollRef = useRef();

  const isoFormat = 'YYYY-MM-DDTHH:mm:ss';

  useEffect(() => {
    transformX.current = 0;
  }, []);

  useEffect(() => {
    if (prevTime) {
      console.log('prev is', prevTime);
      scrollRef.current.scrollLeft = 22 * (prevTime / 10);
      coloredArea.current.style.width = 22 * (prevTime / 10);
    }
  }, [prevTime]);

  return (
    <div className="relative h-[99px]">
      {colorWidth < 4 && (
        <div className="absolute top-[0] left-[50%] translate-x-[-50%] w-[4px] rounded-[16px] h-[99px] bg-[#2C4BEC]" />
      )}
      <div className="relative w-full overflow-hidden">
        <div
          ref={scrollRef}
          className="w-full flex overflow-x-scroll scrollbar-hide snap-mandatory snap-x items-center relative"
          style={{ scrollBehavior: 'smooth' }}
          onScroll={(e) => {
            const scrollX = e.target.scrollLeft;
            const endScroll = (wholeStickNumber - 1) * 22 + 1;
            console.log(endScroll, scrollX);
            if (scrollX >= 0) {
              if (scrollX <= endScroll) {
                const changedPositionLength = parseInt(scrollX / 22);
                setPrevPosition(changedPositionLength);
                const changedMinute = changedPositionLength * 10;
                if (scrollX >= 3) {
                  coloredArea.current.style.width = `${scrollX}px`;
                } else {
                  coloredArea.current.style.width = `0px`;
                }
                // coloredArea.current.style.setProperty('transition', 'transform 0.2s ease-in-out');
                console.log(changedPositionLength);
                if (changedMinute % 10 === 0 && prevPosition !== changedPositionLength) {
                  const newTime = moment(startTime).add({ minutes: changedMinute }).format(isoFormat);
                  changeSelectedTime(newTime);
                }
              }
            }
          }}
        >
          <div className={`relative flex`}>
            <div className="" style={{ width: window.innerWidth / 2 }} />
            <div className="flex relative" ref={scrollRef}>
              <div
                ref={coloredArea}
                className="absolute bg-gradientA left-0 h-full w-0"
                style={{ borderRadius: '10px 0px 0 10px' }}
              />
              {timeArray.map((item, index) => (
                <div className="relative left-0 flex items-center opacity-[0.3]">
                  {item === 1 ? (
                    <div className={`snap-center w-[2px] h-[45px] bg-[#3A3C40] mr-[20px] rounded-[50px]`} />
                  ) : (
                    <div
                      className={`snap-center w-[2px] h-[19px] bg-[#3A3C40] mr-[20px] rounded-[50px]
                    ${index === timeArray.length - 1 ? 'mr-[0]' : 'mr-[20px]'}
                    `}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="" style={{ width: window.innerWidth / 2, height: '99px' }} />
          </div>
        </div>
      </div>
    </div>
  );
}
