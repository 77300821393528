import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MarketingActionButton } from '../../../stories/button/ActionButton';
import sleepingReportSurveyData from '../../../data/sleepingReportSurveyData';
import moment from 'moment';
import 'moment/locale/ko';
import useSessionStorage from '../../../hooks/useSessionStorage';
import SlideTimePickerWithArea from '../../../components/workbook/course5/SlideTimePickerWithArea';
import { CoachingContext } from '../../../coachingContext';

const defaultValue = {
  sleepMinutes: 0,
  inBedMinutes: 0,
};

const isoFormat = 'YYYY-MM-DDTHH:mm:ss';

const getTimeDiff = (startTime, endTime) => {
  return moment.duration(moment(endTime).diff(startTime));
};

// start: 48시간 전, end: 현재 시각(분은 내림), selected: 이전 기록

export default function SelectInBed({ type }) {
  const { id, page } = useParams();

  const [sleepingData, setSleepingData] = useSessionStorage('sleepingData', defaultValue);

  const nowTime = moment(new Date()).format(isoFormat);
  console.log(nowTime);
  const afterTime = moment(nowTime).add({ hours: 24 }).format(isoFormat);
  const [startTime, setStartTime] = useState(nowTime);
  const [endTime, setEndTime] = useState(afterTime);
  const [selectedTime, setSelectedTime] = useState(nowTime);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [prevTime, setPrevTime] = useState(0);

  const navigate = useNavigate();
  const context = useContext(CoachingContext);

  useEffect(() => {
    context.setShowProgressingbar(true);
  }, []);

  useEffect(() => {
    if (sleepingData.inBedMinutes !== 0) {
      const updateTime = moment(startTime).add({ minutes: prevTime }).format(isoFormat);
      console.log('prev', startTime, updateTime);
      setSelectedTime(updateTime);
      setSelectedMinute(sleepingData.inBedMinutes);
      setPrevTime(sleepingData.inBedMinutes);
    }
  }, [sleepingData]);

  const reportData = sleepingReportSurveyData.find((item) => item.type === type);

  const changeSelectedTime = (changedTime) => {
    console.log('시간 바뀜 ', changedTime);
    setSelectedTime(() => changedTime);

    const minDiff = getTimeDiff(startTime, changedTime);
    const minuteDiffDivByTen = minDiff.asMinutes();
    setSelectedMinute(() => minuteDiffDivByTen);
  };

  const submit = () => {
    const updated = { ...sleepingData };
    updated.inBedMinutes = selectedMinute;
    console.log('updated:', updated);
    setSleepingData(updated);

    setTimeout(() => {
      navigate(`/sleepingSurvey/${parseInt(id) + 1}`);
    }, 400);
  };

  return (
    <div className={`w-full h-full pb-[20px] overflow-hidden`}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="px-[20px] mt-[40px]">
          <div
            className="flex-none  text-[14px] leading-[20px] text-[#80838B] text-center"
            style={{ wordBreak: 'keep-all' }}
          >
            지난 7일 동안의 밤을 떠올려 보세요. <br />
          </div>
          <div
            className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C]  mt-[10px] text-center"
            style={{ wordBreak: 'keep-all' }}
          >
            평균적으로 침대에 <br />
            얼마나 누워있었나요?
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-[110px]">
          <div className="w-[30px]">
            {' '}
            <img className="" src={reportData.icon} alt="empty" />
          </div>
          <div
            className="flex text-[14px] leading-[20px] text-[#80838B]  my-[8px] text-center"
            style={{ wordBreak: 'keep-all' }}
          >
            실제로 잔 시간과 <br />
            뒤척인 시간을 포함한 시간이에요.
          </div>
          <p className="font-bold text-[35px] leading-[48px]">{`${
            selectedMinute / 60 >= 1 && selectedMinute % 60 !== 0
              ? `${parseInt(selectedMinute / 60)}시간 ${selectedMinute % 60}분`
              : selectedMinute / 60 >= 1
              ? `${parseInt(selectedMinute / 60)}시간`
              : `${selectedMinute}분`
          }`}</p>
        </div>
        <div className={`pb-[20px] w-full rounded-[16px]`}>
          <div className="relative w-screen">
            <SlideTimePickerWithArea
              startTime={startTime}
              endTime={endTime}
              selectedTime={selectedTime}
              changeSelectedTime={changeSelectedTime}
              prevTime={prevTime}
            />
          </div>
          <div className="w-full px-[20px] mt-[50px]">
            <MarketingActionButton state="ACTIVE" text={'다음'} action={submit} />
          </div>
        </div>
      </div>
    </div>
  );
}
