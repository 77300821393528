import original1 from '../../image/original_1.png';
import original2 from '../../image/original_2.png';
import original3 from '../../image/original_3.png';
import original4 from '../../image/original_4.png';
import original5 from '../../image/original_5.png';
import { showModal, showWebView } from '../../utils/webview';
import ComingSoonMentoringCard from './ComingSoonMentoringCard';

const InsideOriginalCard = ({ recommendList, isScrolling }) => {
  const openCardData = [
    {
      title: '엄마도 마음 쉴 곳이 필요해',
      subtitle: '워킹맘을 위한 4주 심리상담 프로그램',
      image: original1,
      url: 'https://inside.oopy.io/featured/workingmom',
    },
    {
      title: '삶이 회사 중심으로 돌아가고 있다면',
      subtitle: '직장인 전문 상담사와 함께하는 번아웃 극복 프로그램',
      image: original5,
      url: 'https://inside.oopy.io/featured/burnout',
    },
  ];

  const comingSoonCardData = [
    {
      title: '가끔씩 내가 쓸모없는 사람처럼 느껴져요',
      subtitle: '자존감을 되찾는 4주간의 여정',
      image: original2,
    },
    {
      title: '상사/동료와의 지속되는 갈등으로 힘들다면',
      subtitle: '직장 내 인간관계 솔루션',
      image: original3,
    },
    {
      title: '우울을 멈추는 방법을 모르겠을 때',
      subtitle: '우울증 전문가와 함께하는 다회기 심리상담',
      image: original4,
    },
  ];

  const showPage = (item) => {
    showWebView({ url: item.url, backgroundColor: '#FFFFFF', title: item.title, isExternal: true });
  };

  return (
    <div className={`px-[20px]`}>
      <p className="text-[20px] leading-[26px] font-bold mb-[15px] mt-[20px]">인사이드 오리지널</p>
      {openCardData.map((item) => (
        <div className="mb-[30px]" onClick={() => showPage(item)}>
          <div
            className={`w-full relative overflow-hidden rounded-[10px] flex align-center mb-[15px]`}
            style={{ minHeight: '162px' }}
          >
            <div className="w-full min-w-[335px]  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
              <img className="w-full h-full object-fit" src={item.image} alt="empty" />
            </div>
          </div>
          <div className="mx-[4px]">
            <p className="mb-[4px] font-bold text-[17px] leading-[23px] text-[#26282C]">{item.title}</p>
            <p className="text-[15px] leading-[21px] text-[#80838B]">{item.subtitle}</p>
          </div>
        </div>
      ))}
      <p className="text-[17px] leading-[23px] font-bold mb-[10px] text-[#4E4F53]">오픈 예정</p>
      <div
        className="mb-[60px] flex"
        style={{ columnGap: '11px', rowGap: '30px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}
      >
        {comingSoonCardData.map((item) => (
          <ComingSoonMentoringCard item={item} />
        ))}
      </div>
    </div>
  );
};

export default InsideOriginalCard;
