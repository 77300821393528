import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { BlackActionButton, WhiteActionButton } from '../../../stories/button/ActionButton';
import anxySessionData from '../../../data/anxy/anxySessionData';
import thumbnail_exercise_unknown from '../../../image/anxy/12B/thumbnail_exercise_unknown.png';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import modalAtom, { MODAL_VIEWED } from '../../../recoil/common/modal/atom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import webviewToast from '../../../development/webviewToast';
import sessionAtom from '../../../recoil/anxy/session/atom';
import toolModalAtom from '../../../recoil/anxy/session/toolModalAtom';
import usePostToolGain from '../../../hooks/usePostToolGain';

export default function SectionCard({
  index,
  sectionId,
  type,
  isLock,
  isCompleted,
  isFirst,
  isTailLeft,
  isTailRight,
  isTailBottom,
  pageLastViewed,
  isPaymentRequired,
  isTestClosed,
  isInReview,
  isNotReady,
  canViewSession,
  setShowPageToast,
}) {
  const currentData = anxySessionData.find((element) => element.sectionId === sectionId);
  const [sessionState, setSessionState] = useRecoilState(sessionAtom);
  const setModalState = useSetRecoilState(modalAtom);
  const setToolModalState = useSetRecoilState(toolModalAtom);

  const [sessionOpen, setSessionOpen] = useFetchAnxy({
    url: '/anxy/chapter/section/open',
    requestBody: {
      sectionId: sectionId,
    },
  });

  const heart = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.24632 3.53745C-0.415441 5.25405 -0.415441 8.03721 1.24632 9.75381L2.48223 11.0305L2.47917 11.0336L7.0607 15.7664C7.84638 16.578 9.14785 16.5783 9.93395 15.7671L14.5208 11.0336L14.5178 11.0305L15.7537 9.75381C17.4154 8.03721 17.4154 5.25405 15.7537 3.53745C14.0919 1.82085 11.3977 1.82085 9.7359 3.53745L8.5 4.81414L7.2641 3.53745C5.60234 1.82085 2.90809 1.82085 1.24632 3.53745Z"
        fill="url(#paint0_linear_2518_56359)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2518_56359"
          x1="8.5"
          y1="2.25"
          x2="8.5"
          y2="17.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DC3535" />
          <stop offset="1" stopColor="#C61C1C" />
        </linearGradient>
      </defs>
    </svg>
  );

  const goToFirstSession = useNavigateWithParams(`/anxy/${sectionId}/1`);
  const goToPayment = useNavigateWithParams('../payment');
  const gainTool = usePostToolGain({ sectionId: sectionId });
  const goToMeditation = useNavigateWithParams(`/meditationTool/0?useHeart=${isFirst}&isSession=true`);

  const completedAtBeforePayment =
    sessionState.sessionList &&
    sessionState.sessionList.length !== 0 &&
    sessionState.sessionList.find((item) => item.sectionId === 'box_meditate_aa').completedAt;
  const goToComingSoon = useNavigateWithParams(`../anxyComingSoon?date=${completedAtBeforePayment}`);
  const goToNotReady = useNavigateWithParams(`../notReady`);

  useEffect(() => {
    if (sessionOpen) {
      if (type === 'exercise' && sectionId.includes('exercise_meditate')) {
        goToMeditation();
      } else {
        goToFirstSession();
      }
    }
  }, [sessionOpen]);

  const goToSession = () => {
    if (isNotReady) {
      goToNotReady();
    } else if (isFirst) {
      if (canViewSession) {
        setSessionOpen();
      } else {
        setShowPageToast(true);
      }
    } else {
      if (pageLastViewed && !JSON.parse(pageLastViewed).isDone && JSON.parse(pageLastViewed).page) {
        setModalState({ isModalVisible: true, modalType: MODAL_VIEWED });
      } else {
        goToFirstSession();
      }
    }
  };

  const blackButtonAction = () => {
    // modal한테 클릭 session 알려주기 위함
    setSessionState((state) => ({ ...state, currentSession: sectionId }));
    if (type === 'box') {
      gainTool();
    } else if (type === 'exercise' && sectionId.includes('exercise_meditate')) {
      if (isFirst) {
        setSessionOpen();
      } else {
        goToMeditation();
      }
    } else {
      if (isTestClosed || sectionId === 'session_kc' || !isPaymentRequired) {
        goToSession();
      } else {
        if (isInReview) {
          goToComingSoon();
        } else {
          goToPayment();
        }
      }
    }
  };

  return (
    <div className="flex flex-col items-center">
      {!isTailBottom && (
        <div
          className={`z-[40] translate-y-[1px] ${isTailLeft && 'translate-x-[-112px]'} ${
            isTailRight && 'translate-x-[112px]'
          }`}
        >
          <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.4024 1.5C5.5571 -0.500004 8.44385 -0.5 9.59855 1.5L13.9287 9L0.0722656 9L4.4024 1.5Z"
              fill="white"
            />
          </svg>
        </div>
      )}
      <div
        className="w-[calc(100vw-60px)] max-w-[315px] bg-white px-[20px] pt-[24px] pb-[20px] rounded-[20px]
    drop-shadow-[0_5px_20px_rgba(0,0,0,0.20)] "
      >
        <div className="flex gap-[30px] mb-[15px] ">
          <div className="flex-1 flex flex-col justify-between">
            <div className=" text-[18px] leading-[23px] font-semibold ">
              {type === 'box'
                ? !isLock && isCompleted
                  ? `'${currentData.title}' 도구를 획득했어요`
                  : '불안 관리 도구가 숨겨져 있어요'
                : type === 'exercise' && isLock
                ? '어떤 연습 활동을 하게 될까요?'
                : currentData.title}
            </div>
            <div className="flex gap-[4px] items-center">
              <div>
                {type === 'session' && !isLock && !isCompleted && (
                  <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.6"
                      d="M8.5 4.63397C9.16667 5.01887 9.16667 5.98113 8.5 6.36603L1.75 10.2631C1.08333 10.648 0.25 10.1669 0.25 9.39711L0.25 1.60289C0.25 0.833085 1.08333 0.35196 1.75 0.73686L8.5 4.63397Z"
                      fill="black"
                    />
                  </svg>
                )}
                {type === 'session' && isLock && (
                  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.43374 5.40083C3.59154 5.39981 3.76296 5.39981 3.95 5.39981H6.94264C7.12978 5.39981 7.30129 5.39981 7.45916 5.40083V3.52126C7.45916 2.40967 6.55804 1.50855 5.44645 1.50855C4.33486 1.50855 3.43374 2.40967 3.43374 3.52126V5.40083ZM2.09194 5.51895C2.0025 5.54553 1.92006 5.57804 1.84202 5.6178C1.46569 5.80955 1.15973 6.11551 0.967987 6.49183C0.75 6.91966 0.75 7.47971 0.75 8.59981V8.84175C0.75 9.96185 0.75 10.5219 0.967987 10.9497C1.15973 11.3261 1.46569 11.632 1.84202 11.8238C2.26984 12.0417 2.82989 12.0417 3.95 12.0417H6.94264C8.06274 12.0417 8.62279 12.0417 9.05062 11.8238C9.42694 11.632 9.7329 11.3261 9.92465 10.9497C10.1426 10.5219 10.1426 9.96185 10.1426 8.84175V8.59981C10.1426 7.47971 10.1426 6.91966 9.92465 6.49183C9.7329 6.11551 9.42694 5.80955 9.05062 5.6178C8.97265 5.57808 8.8903 5.54559 8.80096 5.51902V3.52126C8.80096 1.66861 7.2991 0.166748 5.44645 0.166748C3.5938 0.166748 2.09194 1.66861 2.09194 3.52126V5.51895Z"
                      fill="rgba(0,0,0,0.6)"
                    />
                  </svg>
                )}
                {isCompleted && (
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3 5.66667L6.5 10L12 2"
                      stroke="#666666"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
              <div className="text-[15px] leading-[21px] text-[rgba(0,0,0,0.6)]">
                {isCompleted
                  ? `${type === 'session' ? '학습' : type === 'exercise' ? '활동' : '획득'} 완료`
                  : type === 'session'
                  ? isLock
                    ? '잠김'
                    : `${currentData.playTime}분`
                  : type === 'box'
                  ? isLock
                    ? 'Session 1 완료 후 획득 가능'
                    : !isCompleted
                    ? '첫 번째 불안 관리 도구를 획득하세요.'
                    : ''
                  : ''}
              </div>
            </div>
          </div>
          {type === 'session' && (
            <div className="shrink-0 w-[83px] h-[110px] rounded-[8px] ">
              <img src={currentData.image} alt={'empty'} />
            </div>
          )}
          {type === 'exercise' && (
            <div className="shrink-0 w-[72px] h-[72px] rounded-[50%] ">
              <img src={!isLock ? currentData.image : thumbnail_exercise_unknown} alt={'empty'} />
            </div>
          )}
          {type === 'box' && <div className="shrink-0 w-[0px] h-[50px]  bg-black opacity-[0.5]"></div>}
        </div>
        {(type === 'exercise' && isLock) || (type === 'box' && !(!isLock && !isCompleted)) ? (
          <WhiteActionButton
            text={'나의 불안 관리 도구 보기'}
            action={() => {
              setToolModalState((state) => ({ ...state, isModalVisible: true }));
            }}
            medium
          />
        ) : (
          <BlackActionButton
            text={
              type === 'session' ? (
                isCompleted ? (
                  '다시 보기'
                ) : isFirst || isLock ? (
                  <div className="flex gap-[10px] items-center justify-center ">
                    <p>보기</p>
                    <div className="flex gap-[2px] items-center ">
                      {heart}
                      <p>1</p>
                    </div>
                  </div>
                ) : (
                  '보기'
                )
              ) : type === 'exercise' ? (
                isCompleted ? (
                  '한 번 더 하기'
                ) : isFirst ? (
                  <div className="flex gap-[10px] items-center justify-center ">
                    <p>시작하기</p>
                    <div className="flex gap-[5px] items-center ">
                      {heart}
                      <p>1</p>
                    </div>
                  </div>
                ) : (
                  '시작하기'
                )
              ) : (
                '획득하기'
              )
            }
            state={type === 'session' && isLock ? 'INACTIVE' : 'ACTIVE'}
            action={blackButtonAction}
            medium
          />
        )}
      </div>
      {isTailBottom && (
        <div
          className={`z-[40] translate-y-[-1px] ${isTailLeft && 'translate-x-[-112px]'} ${
            isTailRight && 'translate-x-[112px]'
          }`}
        >
          <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.33013 7.5C5.48483 9.5 8.37158 9.5 9.52628 7.5L13.8564 9.53674e-07L0 9.53674e-07L4.33013 7.5Z"
              fill="white"
            />
          </svg>
        </div>
      )}
    </div>
  );
}
