/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import SelfCheckTemplate from '../../components/selfCheck/SelfCheckTemplate';

import { AuthContext } from '../../context';
import { changeHeader } from '../../utils/webview';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function SelfCheckQA(props) {
  const { selfCheckQuestion } = props;
  let { selfCheckId, state } = useParams();

  const context = useContext(AuthContext);

  const [answer, setAnswer] = useState('');
  const [disable, setDisable] = useState(false);

  const goNextQuestion = useNavigateWithParams(`/mindScan/${selfCheckId}/${parseInt(state) + 1}`);
  const goEndTest = useNavigateWithParams(`/mindScan/${selfCheckId}/end`);

  useEffect(() => {
    changeHeader({ backgroundColor: '#FFFFFF', text: '' });
    window.document.body.style.backgroundColor = '#FFFFFF';

    if (
      context.selfcheckAnswer.find((element) => element.selfCheckId === selfCheckQuestion.selfCheckId) &&
      context.selfcheckAnswer.find((element) => element.selfCheckId === selfCheckQuestion.selfCheckId)
        .selfCheckQcIdList[state - 1]
    ) {
      //이미 체크한거 있음
      const selected = context.selfcheckAnswer.find((element) => element.selfCheckId === selfCheckQuestion.selfCheckId)
        .selfCheckQcIdList[state - 1];

      setAnswer(selected.selfCheckChoiceId);
    }
  }, []);

  function select(option) {
    setAnswer(option.selfCheckChoiceId);
    if (
      context.selfcheckAnswer.find((element) => element.selfCheckId === selfCheckQuestion.selfCheckId) === undefined
    ) {
      // 새로운 검사
      var arr1 = context.selfcheckAnswer.slice();
      arr1.push({
        selfCheckId: selfCheckQuestion.selfCheckId,
        selfCheckQcIdList: [
          {
            selfCheckChoiceId: option.selfCheckChoiceId,
            selfCheckQuestionId: selfCheckQuestion.qcList[state - 1].selfCheckQuestionId,
          },
        ],
      });

      context.setSelfcheckAnswer(arr1);
    } else {
      //리스트에만 추가
      var arr = context.selfcheckAnswer
        .find((element) => element.selfCheckId === selfCheckQuestion.selfCheckId)
        .selfCheckQcIdList.slice();
      arr[state - 1] = {
        selfCheckChoiceId: option.selfCheckChoiceId,
        selfCheckQuestionId: selfCheckQuestion.qcList[state - 1].selfCheckQuestionId,
      };
      context.selfcheckAnswer.find(
        (element) => element.selfCheckId === selfCheckQuestion.selfCheckId,
      ).selfCheckQcIdList = arr;
    }

    setTimeout(() => {
      //다음 문항으로
      if (parseInt(state) < selfCheckQuestion.qcList.length) {
        goNextQuestion();
      } else if (parseInt(state) === selfCheckQuestion.qcList.length) {
        goEndTest();
      }
    }, 400);
  }

  function isClicked(option) {
    if (option !== '') {
      setDisable(true);
      select(option);
    }
  }

  useEffect(() => {
    document.title = `${selfCheckQuestion.selfCheckName} 검사 ${parseInt(state)}`;
    changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'back' });

    context.setMaxCompleted(selfCheckQuestion.qcList.length);
    context.setCompleted(parseInt(state));
    context.setShowProgressingbar(true);
  }, []);

  return (
    <div className={`w-screen h-[calc(100vh-17px)] bg-white `}>
      <SelfCheckTemplate
        question={selfCheckQuestion.qcList[state - 1].question}
        optionList={selfCheckQuestion.qcList[state - 1].choices}
        answer={answer}
        isClicked={isClicked}
        disable={disable}
      />
    </div>
  );
}
