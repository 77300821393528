import { useParams } from 'react-router-dom';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
import WorkbookTemplate from './WorkbookTemplate';

export default function PageBySelected({ info, index }) {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);
  const selectedPage = context.selectedPage[index ? index : 0];

  console.log('selected: ', context.selectedPage);

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  return <WorkbookTemplate content={info[selectedPage]} action={goNext} />;
}
