import React from 'react';
import { goToTab } from '../../utils/webview';
import { PressedEffect } from '../../stories/button/PressedEffect';
import recommendIcon from '../../image/recommend_icon.png';
import { motion } from 'framer-motion';
import webviewToast from '../../development/webviewToast';

export default function RecommendItem({ activityList, isActivityDone }) {
  const animateList = (activityList && activityList.filter((item) => item.isFirstCallAfterDone)) || [];
  const goLink = () => {
    webviewToast('코스탭 가라');
    goToTab({ tabName: 'course' });
  };
  return (
    <PressedEffect
      element={
        <div className={`w-full relative`}>
          {isActivityDone && animateList.length === 0 && <div className="w-full h-[10px]" />}
          <motion.div
            className={`w-full top-[10px] left-0 shadow-counselorcard rounded-[20px] mx-auto bg-white`}
            animate={{ opacity: 1 }}
            style={{
              position: isActivityDone && animateList.length !== 0 ? 'absolute' : 'static',
              opacity: isActivityDone && animateList.length !== 0 ? 0 : 1,
            }}
            transition={{ ease: 'easeInOut', duration: 0.3, delay: 1.2 }}
          >
            <div className={`w-full px-[20px] pt-[24px] pb-[24px] border-[#F3F5F8]`}>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <div className="w-[44px] h-[44px] mr-[5px]">
                    <img src={recommendIcon} alt="empty" className="w-full h-full" />
                  </div>
                  <p className={`font-bold text-[18px] leading-[22px] text-[#26282C] mb-[2px] `}>
                    추천 코스를 시작하세요
                  </p>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="#26282C"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      }
      action={goLink}
    />
  );
}
