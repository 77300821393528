import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import deviceAtom from '../../recoil/common/device';
import { useRecoilValue } from 'recoil';

export default function PageToast(props) {
  const { text, showPageToast, setShowPageToast } = props;
  const { notchSize } = useRecoilValue(deviceAtom);

  useEffect(() => {
    if (showPageToast) {
      setTimeout(() => {
        setShowPageToast(false);
      }, 3000);
    }
  }, [showPageToast]);

  return (
    <motion.div
      className={`w-screen fixed  px-[20px] z-[100] pointer-events-none opacity-0 `}
      style={{ top: notchSize }}
      animate={{ y: showPageToast ? `10px` : '0px', opacity: showPageToast ? 1 : 0 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      <div
        className="w-full rounded-[12px] py-[12px] px-[15px] text-[15px] leading-[18px] text-center text-white "
        style={{
          background: 'rgba(38,40,44,0.75)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        }}
      >
        {text}
      </div>
    </motion.div>
  );
}
