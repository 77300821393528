import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';
import { CoachingContext } from '../../../coachingContext';
import webviewToast from '../../../development/webviewToast';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { changeHeader } from '../../../utils/webview';

export default function SleepResultAnalysis({ title }) {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}${window.location.search}`);

  useEffect(() => {
    setTimeout(() => {
      goNext();
    }, 1500);
  }, []);

  return (
    <div className="w-full h-[calc(100vh-57px)] flex flex-col items-center justify-center text-center ">
      <DotLoader color="#3953D9" loading={true} size={80} />
      <p className=" font-bold text-[24px] leading-[30px] text-[#26282C]  mt-[40px] ">{title}</p>
    </div>
  );
}
