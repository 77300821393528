import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context';
import { ProgressingBarThin } from '../../stories/else/ProgressingBarThin';
import { CheckLottie } from '../../stories/animation/CheckLottie';

export default function CurrentExam2(props) {
  const { id } = props;

  const context = useContext(AuthContext);
  const [progressbarAnimation, setProgressbarAnimation] = useState(false);
  const [focusNext, setFocusNext] = useState(false);
  const [lineCheck, setLineCheck] = useState(false);

  useEffect(() => {
    if (id === 0) {
      setFocusNext(true);
    } else {
      setTimeout(() => {
        setProgressbarAnimation(true);
        setTimeout(() => {
          setLineCheck(true);
        }, 600);
        setTimeout(() => {
          setFocusNext(true);
        }, 600);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full ">
      {context.selfcheckQuestion.map((step, index) => (
        <div
          key={index}
          className={`flex  items-center relative ${index !== context.selfcheckQuestion.length - 1 ? 'mb-[40px]' : ''}`}
        >
          <div className="flex flex-col ">
            <div
              className={`w-[28px] h-[28px] rounded-[28px] overflow-hidden bg-white ring-[1px] ring-inset ${
                index === id && focusNext
                  ? 'ring-[0px] bg-qna text-white'
                  : index < id
                  ? ' ring-[#2C4BEC] text-[#A4A6B0] '
                  : ' ring-[#D1D5DC] text-[#A4A6B0]'
              } flex justify-center items-center  z-10 shrink-0 mr-[10px] relative transition-all`}
            >
              {index < id && (
                <div className="bg-white flex justify-center items-center absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.68848 3.65376C1.39105 3.36548 0.916235 3.37289 0.627953 3.67033C0.339672 3.96776 0.347091 4.44258 0.644524 4.73086L1.68848 3.65376ZM5.61095 8.5L5.08897 9.03855C5.37985 9.32048 5.84205 9.32048 6.13293 9.03855L5.61095 8.5ZM13.3552 2.03855C13.6526 1.75027 13.66 1.27545 13.3717 0.97802C13.0834 0.680587 12.6086 0.673168 12.3112 0.961449L13.3552 2.03855ZM0.644524 4.73086L5.08897 9.03855L6.13293 7.96145L1.68848 3.65376L0.644524 4.73086ZM6.13293 9.03855L13.3552 2.03855L12.3112 0.961449L5.08897 7.96145L6.13293 9.03855Z"
                      fill="#2C4BEC"
                    />
                  </svg>
                </div>
              )}
              {index === id - 1 && (
                <div
                  className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] ${
                    !lineCheck ? 'opacity-100' : 'opacity-0'
                  } transition-all`}
                >
                  <CheckLottie containerSize={'29px'} gradient />
                </div>
              )}
              {index >= id && <p className="text-[15px] leading-[21px] font-normal">{index + 1}</p>}
            </div>
            {index !== context.selfcheckQuestion.length - 1 && (
              <div className="w-[28px]  ">
                <div
                  className={`origin-left rotate-90  translate-y-[-1px] translate-x-[14px] z-0
                ${index <= id ? 'w-[52px]' : 'w-[45px]'}
                `}
                >
                  <ProgressingBarThin
                    completed={index <= id - 1 ? (index < id - 1 ? 1 : progressbarAnimation ? 1 : '') : 0}
                    color={'#2C4BEC'}
                    height={'1.2px'}
                    animateOnRender={index === id - 1}
                    duration={'0.6s'}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full">
            <div className="w-full flex justify-between mb-[3px]">
              <div className="flex items-center">
                <p className="text-[17px] leading-[23px]  text-[#26282C] font-bold mr-[3px] ">{step.selfCheckName}</p>
                <p className="text-[14px] leading-[20px]  text-[#80838B] font-normal ">{step.examName}</p>
              </div>
              <div
                className="w-[45px] h-[20px] rounded-[12px] bg-[#F3F5F8] text-[12px] leading-[18px] text-[#4E4F53]
            flex items-center justify-center"
              >
                {context.selfcheckQuestion[index].qcList.length}문항
              </div>
            </div>
            {index === id && (
              <p className="text-[15px] leading-[21px]">
                <span className="font-bold text-[#2C4BEC]">{context.selfcheckQuestion[index].guide.slice(0, -15)}</span>
                <span className="font-normal text-[#6B6D76]">{context.selfcheckQuestion[index].guide.slice(-16)}</span>
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
