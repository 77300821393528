import wb0_4_2 from '../image/wb0_4_2.png';
import wb4_1_1 from '../image/wb4_1_1.png';
import wb4_1_2 from '../image/wb4_1_2.png';
import wb4_1_3 from '../image/wb4_1_3.png';
import wb4_1_4 from '../image/wb4_1_4.png';
import wb4_1_5 from '../image/wb4_1_5.png';
import wb4_2_1 from '../image/wb4_2_1.png';
import wb4_2_2 from '../image/wb4_2_2.png';
import wb4_2_3 from '../image/wb4_2_3.png';
import wb4_2_4 from '../image/wb4_2_4.png';
import wb4_2_5 from '../image/wb4_2_5.png';
import wb4_2_6 from '../image/wb4_2_6.png';
import wb4_2_7 from '../image/wb4_2_7.png';
import wb4_2_8 from '../image/wb4_2_8.png';
import wb4_2_9 from '../image/wb4_2_9.png';
import wb4_2_10 from '../image/wb4_2_10.png';
import wb4_3_1 from '../image/wb4_3_1.png';
import wb4_3_2 from '../image/wb4_3_2.png';
import wb4_3_3 from '../image/wb4_3_3.png';
import wb4_3_4 from '../image/wb4_3_4.png';
import wb4_3_5 from '../image/wb4_3_5.png';
import wb4_3_6 from '../image/wb4_3_6.png';
import wb4_4_1 from '../image/wb4_4_1.png';
import wb4_4_2 from '../image/wb4_4_2.png';
import wb4_4_3 from '../image/wb4_4_3.png';
import wb4_4_4 from '../image/wb4_4_4.png';
import wb4_4_5 from '../image/wb4_4_5.png';
import wb4_4_6 from '../image/wb4_4_6.png';
import wb4_4_7 from '../image/wb4_4_7.png';
import wb4_5_1 from '../image/wb4_5_1.png';
import wb4_5_2 from '../image/wb4_5_2.png';
import wb4_5_3 from '../image/wb4_5_3.png';
import wb4_5_4 from '../image/wb4_5_4.png';
import wb4_6_1 from '../image/wb4_6_1.png';
import wb4_6_2 from '../image/wb4_6_2.png';
import wb4_6_3 from '../image/wb4_6_3.png';
import wb4_6_4 from '../image/wb4_6_4.png';
import wb4_6_5 from '../image/wb4_6_5.png';
import wb4_6_6 from '../image/wb4_6_6.png';
import wb4_6_7 from '../image/wb4_6_7.png';
import wb4_7_1 from '../image/wb4_7_1.png';
import wb4_7_2 from '../image/wb4_7_2.png';
import wb4_7_3 from '../image/wb4_7_3.png';
import wb4_7_4 from '../image/wb4_7_4.png';
import wb4_7_5 from '../image/wb4_7_5.png';
import wb4_7_6 from '../image/wb4_7_6.png';
import wb4_7_7 from '../image/wb4_7_7.png';
import wb4_7_8 from '../image/wb4_7_8.png';
import wb4_8_1 from '../image/wb4_8_1.png';
import wb4_8_2 from '../image/wb4_8_2.png';
import wb4_8_3 from '../image/wb4_8_3.png';
import wb4_8_4 from '../image/wb4_8_4.png';
import wb4_9_1 from '../image/wb4_9_1.png';
import wb4_9_2 from '../image/wb4_9_2.png';
import wb4_9_3 from '../image/wb4_9_3.png';
import wb4_9_4 from '../image/wb4_9_4.png';
import wb4_9_5 from '../image/wb4_9_5.png';
import wb4_9_6 from '../image/wb4_9_6.png';
import wb4_9_7 from '../image/wb4_9_7.png';
import wb4_10_1 from '../image/wb4_10_1.png';
import wb4_10_2 from '../image/wb4_10_2.png';
import wb4_10_3 from '../image/wb4_10_3.png';
import { TextWithBold } from '../components/workbook/TextWithBold';
import TextWithDot from '../components/workbook/TextWithDot';
import ChooseRoute from '../components/workbook/course3/ChooseRoute';
import NavigateSelectButton from '../components/workbook/NavigateSelectButton';
import QuizTemplate from '../components/workbook/quiz/QuizTemplate';
import AnswerTemplate from '../components/workbook/quiz/AnswerTemplate';
import { DottedmessageWithBold } from '../components/workbook/DottedMessageWithBold';
import SelfCheckResult from '../components/workbook/course4/SelfCheckResult';
import PageBySelected from '../components/workbook/PageBySelected';
import MessageBlock from '../stories/workbook/MessageBlock';
import MessageBlockWithQuote from '../stories/workbook/MessageBlockWithQuote';
import MessageBlockWithTail from '../stories/workbook/MessageBlockWithTail';
import { MessageBlockWithTailCross } from '../stories/workbook/MessageBlockWithTailCross';

const course4Data = [
  [
    {
      title: '부정적인 감정 다루기',
      subtitle: '여러분을 가장 힘들게 하는 감정은 무엇인가요?',
      fullImage: wb4_1_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '누군가에게 상처를 줬다는 죄책감, 불확실한 미래에 대한 불안감, 소중한 사람을 상실한 슬픔. 우리는 살아가면서 수많은 감정들을 경험하고 이를 통해 성숙합니다. 하지만 ',
                '때로는 혼자서 감당하기 어려울 만큼 깊고 큰 감정의 웅덩이에 빠지는 때가 있어요.',
                ' 그러한 고민으로 마음의 위안과 해결 방법을 얻고자 인사이드를 찾아오신 분들도 많을 테죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '어떤 고민이 있으신가요?',
      image: wb4_1_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '한 번 쯤은 나를 힘들게 하는 부정적인 감정에서 벗어나고 싶다고 생각한 적이 있을 거예요. 따뜻한 말로 위로받고 싶었을 수도 있고, 현실적인 해결책이 필요한 분들도 있을 겁니다.',
                '\n',
                '어느 쪽이든, 잘 찾아오셨습니다. 이번 코스에서는 여러분을 힘들게 했던 감정들을 다스리는 방법에 대해 알려드릴 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '부정적인 감정을 다룰 거예요',
      image: wb4_1_3,
      bg: '#FEEEE7',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '생각 함정에 빠지면 감정은 더 어두운 웅덩이로 가라앉게 됩니다. 이번 코스에서는 그러한 감정 중 특히 헤어나오기 어려운 감정인 ',
                '슬픔과 죄책감, 그리고 분노',
                '에 대한 내용을 다룰 거예요.',
              ]}
            />
          ),
        },
        {
          component: (
            <DottedmessageWithBold
              texts={[
                ['나는 ', '슬픔', '에서 도무지 헤어나올 수가 없어'],
                ['나는 나쁜 사람이야. ', '죄책감', '은 나를 우울하고 불안하며 수치스럽게 만들어'],
                ['작은 일로 ', '화', '가 나는 나를 통제할 수 없어'],
              ]}
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '어떤 ',
                '왜곡된 생각이 이 감정을 증폭시키고 있는지',
                ' 함께 살펴보고 ',
                '부정적인 감정의 악순환의 고리를 끊는 방법',
                '을 알려드릴게요. ',
              ]}
            />
          ),
        },
      ],
      buttonText: '이해했어요!',
    },
    {
      title: '호흡 타이머를 드릴 거예요',
      subtitle: '여러분도 이런 경험 있지 않으신가요?',
      image: wb4_1_4,
      bg: '#FEE9E3',
      texts: [
        {
          component: (
            <MessageBlockWithTail textList={[['저는 화가 나면 저도 모르게 소리를 질러요.']]} type={'gray'} my />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '생각 함정에 대해 생각하기도 전에 감정이 너무 강렬해져 버린 경우,',
                ' 우리 뇌에서 이성적인 사고를 담당하는 부위의 스위치가 꺼져버립니다. 그렇게 우리의 ',
                '의지와 상관없이 충동적으로 행동해 버리게 되죠.',
                ' 이때 우리가 ',
                '후회할 만한 행동을 예방할 수 있는 방법',
                '이 있습니다. 바로 ',
                '호흡하기',
                '예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '코스를 시작해 봅시다',
      image: wb4_1_5,
      bg: '#FEEEE7',
      texts: [
        {
          component: (
            <div className="text-black my-[40px] w-full bg-[#F3F5F8] rounded-[16px] py-[20px] px-[12px]">
              <span className="mx-[8px] font-bold">이번 코스에서는</span>
              <div className="mt-[6px]">
                <TextWithDot
                  texts={[
                    '슬픔, 죄책감, 분노를 유발하는 생각 함정에 대해 알아볼 거예요.',
                    '감정에 이끌려 충동적으로 행동하지 않을 수 있는 방법인 호흡하기를 배울 거예요.',
                  ]}
                  margin="m-0"
                />
              </div>
            </div>
          ),
        },
        {
          text: '인사이드에서는 의학과 심리학에 기반한 인지행동치료 이론에 근거하여 감정과 생각을 다룰 수 있는 감정 노트와 회고 노트를 제공해드리고 있어요. 하지만 생각을 되돌아보거나 기록을 하는 것 자체가 낯선 분들도 계실 겁니다. 이번 코스에는 닥터 스마일의 진료실을 찾아갔던 다른 사람들의 노트를 함께 보면서 어떻게 하면 노트를 더 잘 활용할 수 있을지도 함께 배워 볼게요. 생각과 감정을 기록함으로써 우리 내면의 잠재력을 발견해 봅시다.',
        },
      ],
      buttonText: '어렵지 않네요!',
    },
    {
      title: '당위 부여',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '당위부여',
                ' 생각함정은 ',
                '나 자신, 다른 사람, 혹은 이 세상이 어떤 모습으로 어떻게 행동해야 하는지 명확히 이해하고 전부 다 내 믿음과 일치하기를 바라는 생각 함정을 뜻해요.',
                ' 예를 들어볼게요.',
                '\n',
                '앤디는 팀의 리더예요. 총 다섯 명의 팀원이 그를 따르고 있죠. 앤디는 항상 열심히 일해요. 팀원보다 더 일찍 출근하고, 더 늦게 퇴근하죠. 앤디는 다음과 같이 생각하거든요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['리더는 항상 모범을 보여야 해. 팀원들에게 안일한 모습을 보여선 안 돼.']]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '그리고 다섯 달 뒤, 회사에서 승승장구 하던 앤디는 결국 퇴사를 하고 말았답니다. 사유는 번아웃으로 인해 유발된 우울증이었죠.',
        },
      ],
      buttonText: '어떤 규칙을 부여하는 거네요',
    },
    {
      title: '꼬리표 붙이기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '꼬리표 붙이기 생각 함정은 나 자신 혹은 다른 사람에게 실제로는 존재하지 않는 특성을 영구적으로 부여하는 것을 뜻해요. 예를 들어볼까요?',
                '\n',
                '토마스는 어릴 적 친구들로부터 ‘미스터 루저’라고 놀림받은 기억이 있어요. 다소 왜소한 체격과 내성적인 성격 때문이었죠. 그의 뛰어난 글쓰기 능력은 전혀 생각하지 않은 별명이었어요. 토마스는 아직까지도 무슨 일을 하려고 할 때 이런 생각이 들 때가 있어요.',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithTail textList={[['나 같은 루저가 뭘 잘하겠어....']]} type={'white_dotted'} my />,
        },
        {
          text: '전국 글쓰기 대회에서 수상한 지 한 달도 안 된 시점이었죠.',
        },
      ],
      buttonText: '이해했어요!',
    },
    {
      title: '최악의 시나리오 떠올리기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '최악의 시나리오 떠올리기 생각 함정은 그리 중요하지 않은 사건에서 가장 나쁜 상황을 상상하는 것을 뜻해요. 예를 들어볼게요.',
                '\n',
                '수지에게는 중학생의 딸이 있어요. 오늘은 딸의 생일날이죠. 딸은 친구의 집에서 생일 잔치를 하고 온다고 했죠. 그런데 저녁 10시가 되어도 딸이 아직 귀가 하지 않는 거예요. 수지는 딸에게 전화를 걸엇고, 딸은 전화를 받지 않았어요. 수지는 생각했죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['무슨 일이 생긴 게 틀림없어. 경찰에 신고를 해야 하나? 이러다가 영영 못 돌아오면 어떻게 하지?'],
              ]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '그 시간 수지는 집 앞에서 친구의 고민을 들어주고 있었죠. 휴대폰에는 20통의 부재중 전화 기록이 찍혀있었답니다.',
        },
      ],
      buttonText: '최악의 경우에 집착하는 거군요',
    },
    {
      title: '과도한 책임감',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '과도한 책임감',
                ' 생각 함정은 ',
                '나와 관련 없는 일도 나와 관련된 일로 생각하면서 과도한 책임감을 느끼는 것을 뜻해요.',
                ' 예를 들어볼게요.',
                '\n',
                '올리비아는 아들의 중요한 시험날에 집에서 기도를 하기로 마음 먹었어요. 그리고 시험 당일, 집에서 기도를 하던 올리비아는 깜빡 잠에 들었답니다. 그런데 눈을 떠보니 시험 시간이 끝나있는 거예요. 불안한 마음으로 있던 올리비아는 시험을 잘 보지 못했다는 아들의 연락을 듣고는 다음과 같이 생각했죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail textList={[['내가 잠들지만 않았어도...']]} type={'white_dotted'} margin="mt-[40px]" />
          ),
        },
      ],
      buttonText: '그렇군요!',
    },
    {
      title: '자, 여기까지예요.',
      texts: [
        {
          text: [
            '이렇게 12가지의 생각 함정에 대해 다 배워보았어요. 내용 자체는 생각보다 별로 어렵지 않죠? 물론 이것보다 더 깊은 내용도 있어요. 하지만 여기서는 다루지 않을 거예요. 너무 깊은 내용을 한번에 다루면 다시 저를 찾아오지 않더라고요. 저는 사람들이 계속 저를 찾아왔으면 좋겠어요. 고양이와 있는 것도 좋지만... 저는 이야기하는 게 좋거든요.',
            <br />,
            <br />,
            '자, 그러면 이번에도 제가 퀴즈를 몇 개만 내볼게요. 시작해볼까요? ',
          ],
        },
      ],
      buttonText: '네, 준비됐어요!',
    },
    {
      title: '고생 많았어요. 잘 배우시네요!',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이제 기본적인 생각 함정들을 배웠으니 실제 내 마음이 함정에 빠지진 않았는지 살펴봐야겠죠? 그래서 제가 ',
                '회고 노트',
                '를 선물해드릴 거예요. 지난번에 제가 선물해드렸던 감정 노트 기억하시나요? 나의 감정을 기록하고, 그때 상황을 적고, 그 상황에서 어떻게 생각했는지 적어보는 거였죠. 이제 그 기록을 바탕으로 회고를 진행할 거예요.',
                '\n',
                '그런데 앨리스의 사례에서 보았듯이 감정을 기록한 직후에는 회고를 진행하기가 쉽지 않아요. 이미 감정에 휩싸여 있는 상태이기 때문이죠. 그래서 ',
                '회고는 감정을 기록하고 난 이틀 뒤부터 진행',
                '할 수 있답니다. 감정 노트에 기록을 하고, ',
                '이틀 뒤에 회고 노트를 열어보면 회고를 할 수 있는 카드가 있을 거예요.',
                ' 우선은 어떠한 생각 함정이 있는지 살펴보고, 다르게 생각할 순 없었는지 가볍게 적어보도록 해요. 보다 자세한 원리와 작성 방법은 다른 코스에서 설명해드릴 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '회고 노트, 기대돼요!',
    },
    {
      title: '자, 그럼 이제 다음에 만나요',
      texts: [
        {
          text: [
            '지금까지의 수업은 정말 기본적인 내용이었답니다. 이제 이 내용을 바탕으로 개별 코스에서 다양한 주제에 대해 이야기해 볼 거예요. 어떠한 주제를 먼저 하든 상관없어요. 지금 나에게 필요한 코스를 선택하면 된답니다. 특정 코스를 시작하고자 하면 다른 코스를 먼저 들어야 하는 경우가 있을 순 있어요. 그런 순서만 잘 따르면 큰 문제는 없을 거예요. 제가 어떤 코스들을 먼저 다뤄야 하는지 알려드릴 거니 너무 걱정하지 말고요.',
            <br />,
            <br />,
            '자, 그럼 저는 이제 쉬러 가야겠어요. 고양이가 많이 외로워할 것 같기도 해요. 고양이 옆에서 책을 좀 읽다가 잠들까봐요. 그러면 우리는 다음에 뵙도록 하죠. 너무 늦게 찾아오진 말아요. 저도 이야기할 상대가 필요하답니다.',
            <br />,
            <br />,
            '그럼 수고했어요, 이틀 간 즐거운 대화였어요.',
          ],
        },
      ],
      buttonText: '수고했어요!',
    },
  ],
  [
    {
      title: "불난 감정의 소화기 '심호흡'",
      fullImage: wb4_2_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '어서 오세요, 또 찾아오셨군요. 반가워요. 다시 만날 수 있어서 좋군요. 그래요, 아마 부정적인 감정 때문에 찾아오셨을 테죠? 잘 찾아오셨습니다. 사실 우리 감정 중에 부정적인 감정을 다루는 게 가장 어렵죠. 저도 진료를 본 지 제법 오래 됐는데 감정에 휩싸인 분들을 만날 때면 참 쉽지가 않은 것 같아요. 그래도 할 수 있는 것들이 있답니다. 앞으로 4일 동안 부정적인 감정들에 대해 배워볼게요. 차근히 해보도록 하죠.',
                '\n',
                '오늘은 ',
                '강렬한 감정이 갑자기 찾아왔을 때 어떻게 해야 할지',
                '에 관해 이야기해 볼 거예요. 먼저 한 가지 질문을 드려 볼게요. 심한 불안이나 갑작스러운 공포감을 느낄 때 혹은 몹시 화가 났을 때와 같이 감정이 강렬하거나 격해지면, 여러분은 주로 어떻게 하시나요?',
              ]}
            />
          ),
        },
        {
          component: (
            <ChooseRoute textList={['일단 감정이 이끄는 대로 행동해요.', '잠시 혼자서 진정할 시간을 가져요.']} />
          ),
        },
      ],
    },
    {
      replaceComponent: (
        <PageBySelected
          info={[
            {
              title: '감정이 이끄는 대로 행동하시는군요',
              image: wb4_2_2,
              texts: [
                {
                  component: (
                    <TextWithBold
                      texts={[
                        '감정에 따라 행동해야 할 때도 있어요. 예를 들면, 뜨거운 주전자를 만지고 재빨리 손을 떼는 반응은 ',
                        '신체적인 위험으로부터 보호하기 위해 우리 몸이 가진 자동 반사회로가 발동',
                        '한 겁니다.',
                        '\n',
                        '운전을 하던 중 사람이 차도로 뛰어든 상황에서 재빨리 브레이크를 밟아 차를 멈춰 세우는 것 또한 ',
                        '공포심을 느낀 우리 몸이 빠르게 판단하여 행동한 경우',
                        '죠.',
                        '\n',
                        '우리 모두에게는 신체적인 위협으로부터 우리 몸을 보호하기 위한 신경계의 자동 회로가 짜져 있어요. 반대로, ',
                        '감정이 이끄는 대로 행동해버리면 좋지 않을 때도 있습니다.',
                      ]}
                    />
                  ),
                },
              ],
              buttonText: ' ',
            },
            {
              title: '잠시 진정할 시간을 가지시는군요',
              image: wb4_2_2,
              texts: [
                {
                  component: (
                    <TextWithBold
                      texts={[
                        '이성적인 판단이 필요한 경우라면, 감정이 이끄는 대로 충동적인 행동을 하지 않고 잠시 진정할 시간을 가지는 것은 정말 좋은 방법이에요.',
                        '\n',
                        '그런데 때로는 ',
                        '감정에 따라 행동해야 할 때',
                        '도 있죠. 예를 들면, 뜨거운 주전자를 만지고 재빨리 손을 떼는 반응은 ',
                        '신체적인 위험으로부터 보호하기 위해 우리 몸이 가진 자동 반사회로가 발동',
                        '한 겁니다.',
                        '\n',
                        '운전을 하던 중 사람이 뛰어든 상황에서 재빨리 브레이크를 밟아 차를 멈춰 세우는 것 또한 ',
                        '공포심을 느낀 우리 몸이 빠르게 판단하여 행동한 경우',
                        '죠. ',
                      ]}
                    />
                  ),
                },
              ],
              buttonText: ' ',
            },
          ]}
        />
      ),
    },
    {
      title: '이런 경험 있지 않으신가요?',
      image: wb4_2_3,
      bg: '#6CA6DC',
      texts: [
        {
          component: (
            <MessageBlockWithTail
              textList={[['저는 화가 나면 소리를 지르게 돼요'], ['긴장하면 아무 말이나 막 하게 돼요']]}
              type={'gray'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '감정이 격렬해졌을 때 자동적인 행동이 튀어나오는 경우인데요. 우리에게 ',
                '불편한 자극이 들어왔을 때, 마음 회로에 습관적으로 굳어진 행동이 재빠르게 튀어나와 버립니다.',
                '\n',
                <span />,
                '이렇게 순간의 감정을 되돌리기 위해 충동적으로 한 행동은 장기적으로 우리에게 이롭지 못한다는 것을 여러분도 경험해봐서 아실 거예요. 이럴 때 우리는 ',
                '나의 감정에 경보등이 켜졌다는 것을 알아채고 잠시 멈추어야 해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '우리 뇌를 한번 살펴볼게요',
      image: wb4_2_4,
      bg: '#2A58A1',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리 뇌는 ',
                '생각을 하는 부위',
                '와 ',
                '감정을 느끼는 부위',
                '가 나뉘어 있습니다. 평소에는 감각 자극이 들어오면 감정 부위에서 감각을 정리해 생각 부위로 보내게 됩니다. 그리고 생각 부위에서 다시 한번 이성적으로 판단한 뒤 우리에게 행동하도록 지시를 내리게 되죠.',
                '\n',
                '그런데 이 감정 부위에 불편한 자극이 들어오면 어떻게 될까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '잠시 멈추고 쉬어야 할 때',
      image: wb4_2_5,
      bg: '#2A58A1',
      texts: [
        {
          text: '감정 부위에 정서적 혹은 육체적으로 편안하지 않은 자극이 들어오게 되면, 감정 부위는 즉시 경보음을 울립니다.',
        },
        {
          component: <MessageBlockWithTail textList={[['지금 안전하지 않아!']]} type={'white_dotted'} my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '그리고 나선 ',
                '생각 부위로 가는 길이 차단',
                '되어 버리죠. ',
                '따라서 강렬한 감정을 느꼈을 때는 이성적인 판단이 어러워지고 충동 조절 능력이 떨어집니다.',
                ' 그렇게 우리의 의지와 상관없이 원치 않는 행동이 툭 튀어나와 버리는 거죠.',
                '\n',
                '따라서 감정에 이끌려 우리에게 이롭지 않은 행동을 하는 것을 막기 위해서는 ',
                '잠시 멈춤으로써 차단된 길이 다시 회복될 때까지 기다리는 것이 필요합니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '그렇군요!',
    },
    {
      title: '90초만 멈추어 보세요',
      image: wb4_2_6,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리 뇌는 생각하고, 감정을 느끼고, 생리적으로 반응합니다. ',
                '강렬한 감정을 느끼면 뇌의 사고 회로가 차단됨과 동시에 신체적인 변화',
                '가 일어나는데요. 이는 ',
                '뇌에서 방출되는 호르몬에 의해 발생',
                '합니다. 호르몬은 혈류를 돌아다니며 전신에 영향을 미치고 그 결과 심장이 빨리 뛰고 숨이 가빠지며 얼굴이 화끈 달아오르는 등의 신체적 변화가 나타납니다. 그와 동시에 우리 뇌의 사고 회로 또한 차단되는 거예요.',
                '\n',
                <span />,
                '흥미로운 사실은 이 호르몬이 혈류에서 빠져나가는 시간이 90초라는 점이에요.',
                ' 따라서 우리에게 필요한 건 ',
                '90초 동안 잠시 멈추는 겁니다.',
                ' 그 사이 달아오른 얼굴은 진정되고 차단되었던 사고 회로도 다시 열려 이성적인 판단력이 돌아오게 됩니다.',
                '\n',
                '그렇다면, 감정의 경보음이 울렸다는 것을 가장 잘 알아차릴 수 있는 방법은 무엇일까요?',
              ]}
            />
          ),
        },
        {
          component: (
            <NavigateSelectButton
              textList={[
                '심장이 두근거리는지 느껴봐요',
                '호흡이 변하는지 느껴봐요',
                '온 몸의 체온이 변하는지 느껴봐요',
                '신체 부위에서 특정 감각이 느껴지는지 봐요',
              ]}
            />
          ),
        },
      ],
    },
    {
      title: '맞아요 신체 반응이 시작이예요',
      subtitle: "그 중에 가장 좋은 것은 ‘호흡'입니다.",
      image: wb4_2_7,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '혹시 기분에 따라 호흡이 어떻게 변하는지 유심히 관찰해본 적이 있나요? 편안하고 행복한 상태에서 깊고 느린 숨을 쉬지만, 부정적인 감정을 느낄 때는 호흡이 얕고 빨라져 심하면 숨쉬기 어려워질 때도 있었을 거예요. 우리는 평생 호흡과 함께하지만, 그 사실을 항상 잊고 삽니다.',
                '\n',
                '이러한 ',
                '호흡은 우리의 감정을 알아차릴 수 있는 가장 좋은 방법',
                '이에요. 따라서 감정이 요동칠 때 가장 먼저 해볼 수 있는 것이 바로 ',
                "'나의 호흡 관찰해보기'",
                '입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '회고 노트, 기대돼요!',
    },
    {
      title: '그저 내가 숨쉬는 것을 바라보세요.',
      image: wb4_2_8,
      texts: [
        {
          text: [
            '처음부터 나의 호흡을 통제하고 조절하려고 할 필요는 없습니다. 우선 호흡이 불편하다는 것을 알아차리기만 하여도 현재 나의 감정 상태에 비상등이 들어왔다는 것을 깨달을 수 있어요. 그리고 몸의 감각을 느끼고 감정에 이름을 붙여보세요.',
          ],
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['지금 호흡이 가쁘고 식은땀이 나네. 불안하고 걱정이 돼.']]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          component: <TextWithBold texts={['우리의 ', '감정은 일단 알아차리면 한결 진정되기 마련', '입니다.']} />,
        },
      ],
      buttonText: '수고했어요!',
    },
    {
      title: '깊게 심호흡하기',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이렇게 호흡 상태를 알아차렸다면, 심호흡을 하는 겁니다. 호흡할 때 중요한 것은 ',
                '횡격막이 움직일 수 있는 복식호흡',
                '을 하는 거예요. 횡격막에는 부교감신경이 있어요. 부교감신경은 신경은 우리 몸을 더 편안한 상태로 만들죠. ',
                '부교감신경이 자극되면 우리 몸은 실제로 이완되고 긴장이 풀려 편안한 상태로 변할 수 있어요.',
                ' 호흡하는 방법은 다음과 같습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['한 손을 배에 올려요'],
                ['4초 간 아랫배가 올라오도록 숨을 들이쉽니다'],
                ['2초 간 숨을 멈춥니다'],
                ['4초 간 아랫배가 쏙 들어가도록 숨을 내쉽니다'],
              ]}
              type={'gray'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '실제 정신의학에서는 바이오피드백이라는 형태로 복식호흡을 통한 신체 이완을 진행하고 있답니다. 단순히 숨을 쉬는 게 아니라 부교감신경을 자극해 나의 몸을 이완시키는 과정이랍니다. ',
                '나의 몸을 내가 조절하는 것',
                '이지요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '수고했어요!',
    },
    {
      title: '90초면 충분하답니다',
      image: wb4_2_9,
      bg: '#FEE9E3',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '발표나 시험 직전에 긴장되고 떨릴 때, 슬프고 두려운 감정에 숨이 잘 쉬어지지 않을 때, 화가 끝까지 치밀어 올랐을 때, 우리는 이성적으로 판단하고 행동하기 어렵습니다. 이때 언제든 ',
                '호흡하기로 급한 불을 꺼보세요. 90초면 충분합니다.',
                ' 이렇게 잠시 멈춤으로써 여러분 자신에게 선택할 기회를 주는 겁니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <div className="mt-[40px]">
              <MessageBlock textList={[['감정에 따라 충동적으로 행동할지']]} type={'black'} margin="mb-[10px]" />
              <MessageBlock textList={[['나의 감정을 내가 원하는 방식으로 표현할지']]} type={'light_blue'} />
            </div>
          ),
        },
      ],
      buttonText: '수고했어요!',
      customButtonText: '다음',
      submit: 'Skip',
    },
    {
      title: '불붙은 감정에 소화기를 뿌려보세요',
      image: wb4_2_10,
      bg: '#2E399A',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '감정에 불이 붙지 않도록 예방을 하는 것은 내 ',
                '생각을 되돌아보는 것',
                '이고, 이미 불이 붙어버린 감정에 소화기를 뿌리는 것은 ',
                '호흡하기',
                '라고 할 수 있어요.',
                '\n',
                '감정이 강렬해졌을 때 말고도 평소에 언제든 호흡 타이머에 들어와 나의 호흡에 집중해 보세요. ',
                '하루 10분의 심호흡이 주는 안정 효과는 반나절 이상 지속됩니다.',
                ' 하루를 시작하며 아침에 한 번, 하루를 정리하며 자기 전에 한 번, 이렇게만 호흡해 보아도 몸이 많이 편안해질 거예요.',
                '\n',
                '물론 이렇게 말만 해서는 호흡하는 게 쉽지 않은 걸 잘 알고 있어요. 제가 만난 다른 분들도 ‘호흡을 하라’고 하여도 정작 그걸 실천하는 건 쉽지 않다고 하더라고요. 그래서 ',
                '호흡 타이머',
                '를 선물해 드릴 거예요. 강렬한 감정에 휩싸였을 때, 아침에 일어났을 때, 취침 전에, 꾸준히 이용해 보세요.',
                '\n',
                '오늘은 여기까지 하기로 해요. 내일은 슬픔이라는 감정에 대해 다룰 거랍니다. 오늘은 제가 선물해 드리는 호흡도구를 이용해보기로 해요. 저는 이제 조금 눈을 붙여야겠어요. 조금 졸리네요. 그럼 우린 내일 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '수고했어요!',
      customButtonText: '마치기',
    },
  ],
  [
    {
      title: '슬픔의 우물 밖으로',
      fullImage: wb4_3_1,
      subtitle: '어서오세요, 환영해요.',
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '어제와 오늘은 어떤 하루를 보내셨나요? 저는 조금은 좋지 않은 하루를 보냈답니다. 좋지 않은 일들이 겹쳐서 일어나서 조금 짜증이 났었죠. 그래도 밥을 먹고 잠시 멈춰서 눈을 감고 호흡을 했더니 조금은 차분히 가라앉는 느낌이네요. 그런 감정들을 다스리지 않고 지금 이 수업을 시작했다면 온통 짜증 섞인 말투로 이야기를 했겠죠? 그러지 않아서 다행이에요. 소중한 분에게 짜증을 낼 순 없지요.',
                '\n',
                '어제 받은 호흡 타이머는 사용해보셨나요? 일주일만 사용해 보시면 평소에도 한층 이완되고 편안한 느낌이 드실 거예요. 부정적인 감정에 휩싸이지 않더라도 꾸준히 사용하면 많은 효과가 있을 거예요.',
                '\n',
                '오늘은 ',
                '우리를 힘들게 하는 슬픔',
                '에 대해서 함께 보도록 할게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '건강한 슬픔과 우울증은 달라요',
      image: wb4_3_2,
      bg: '#283F6D',
      texts: [
        {
          text: '사랑하는 사람을 잃거나, 건강에 큰 문제가 생긴 경우, 혹은 간절히 원하던 일이 좌절되었을 때와 같이 현실적으로 벌어진 일에 의해 우리는 슬픔을 느낄 수밖에 없습니다. ',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '현실적인 문제로 인해 슬픔을 느끼는 것이 당연하다면, 이런 문제로 우울증에 걸릴 수도 있는 것 아닌가요?',
                ],
              ]}
              type={'black'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '맞아요. 실제로 갑작스러운 환경변화나 사고, 재난 등 커다란 사건을 경험했을 때 불안과 우울과 같은 감정 변화를 겪는 것은 자연스러운 일입니다. 하지만 이러한 감정의 유효기간은 ',
                '1~2개월',
                '입니다. 감정 변화를 유발한 문제 사건이 해결되었는데도 강렬한 슬픔이 그 이상 지속되면서 여러분의 일상생활까지 망가뜨린다면 ',
                '우울증',
                '을 의심해볼 수 있어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '우울증은요',
      image: wb4_3_3,
      bg: '#152543',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '실제 우울증 환자분들은 이렇게 표현하기도 해요. “끝이 보이지 않는 어두운 터널 속에 갇힌 느낌이야. 이 끝없는 우울감을 벗어날 수 있을까?” 하고 말입니다. 그런데 슬픔이 아닌 다른 ',
                '신체적인 증상이나 일상생활의 어려움으로 병원에 왔다가 우울증을 발견',
                '하게 되는 경우도 많아요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['예전에는 좋아하던 취미도 있었는데, 지금은 뭘 해도 재미가 없고 의욕이 나질 않아요'],
                ['하루 종일 피곤하고 활력이 없어요'],
                ['도저히 잠을 제대로 잘 수가 없어요'],
              ]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '하지만 우리는 일상이 너무 바쁜 나머지 피곤하고 지치는 것을 당연하다고 여기고 대수롭지 않게 넘기곤 하죠. 이럴 때 우리는 “잠깐!”을 외치고, 내 마음이 힘든 건 아닌지 생각해 보셔야 해요.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '우울증의 진단은 다음과 같아요',
      texts: [
        {
          text: 'A. 적어도 2주 동안 다음 중 하나가 있어야 합니다.',
        },
        {
          component: (
            <DottedmessageWithBold texts={[['거의 하루 종일 우울해요'], ['거의 매일 어떤 일을 해도 재미가 없어요']]} />
          ),
        },
        {
          text: 'B. 또한 다음 중 4가지 이상이 있어야 합니다.',
        },
        {
          component: (
            <DottedmessageWithBold
              texts={[
                ['식욕이 변했어요', <span />, '(1개월 동안 체중이 5% 이상 변화)'],
                ['불면증이 있어요', <span />, '(혹은 잠을 너무 많이 자요)'],
                ['불안하고 가만히 못 있겠어요'],
                ['쉽게 피곤하고 힘이 없어요'],
                ['삶의 의미를 모르겠어요. 스스로가 무가치한 느낌과 죄책감이 들어요'],
                ['기억력이나 집중력이 떨어져요'],
                ['죽고 싶다는 생각이 들어요. 계획을 세워 봤어요.'],
              ]}
            />
          ),
        },
        {
          text: '여러분은 어떠한 상태인지 지난 검사 결과를 한번 살펴볼까요?',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '여러분의 검사결과예요',
      texts: [
        {
          component: <SelfCheckResult examName="우울" />,
        },
        {
          text: '가장 최근의 우울감 검사는 위와 같네요.',
        },
        {
          component: (
            <MessageBlock
              textList={[['우울감은 아픈 만큼 우리를 성숙하게 하는 데 꼭 필요한 감정입니다. ']]}
              type={'gray'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '누구나 우울감을 겪을 순 있답니다. 하지만 만약 현재의 ',
                '우울감이 중간 정도 이상',
                '이면서, ',
                '일상생활에 지장이 있는 분',
                '이라면 인사이드 내의 ',
                '심리상담사 및 의사 선생님의 도움',
                '을 받는 것이 좋을 거예요. ',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '생각을 되돌아보는 것의 힘',
      fullImage: wb4_3_5,
      height: 190,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '감정 노트',
                '와 ',
                '회고 노트',
                ' 또한 중요한 도구가 될 수 있습니다. 감정과 생각을 기록하다 보면 감정의 구렁텅이에 빠지지 않도록 브레이크를 걸 수 있거든요. ',
                '가벼운 우울증',
                '의 경우 생각, 감정, 행동이 어떻게 서로 영향을 미치는지 살펴보고 그 과정에서 나타나는 불합리한 생각들을 교정하는 ',
                '인지행동치료',
                '가 효과적이랍니다. 여러분이 제 진료실에 처음 오셨을 때 가볍게 이야기했던 기억이 나네요.',
                '\n',
                '실제로 가벼운 우울증을 앓고 계신 분들의 경우, ',
                '감정과 생각의 연결 관계를 설명한 책을 읽은 것만으로도 치료적 효과가 있었다는 연구 결과',
                '도 있답니다. 인지행동치료를 통해 내 생각과 감정을 이해함으로써 감정 기복에 잘 대처하고, 불합리했던 삶의 규칙이나 가치관을 건강하고 성숙하게 변화시켰기 때문이에요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '그렇군요!',
    },
    {
      title: '마음의 근육을 길러보아요',
      image: wb4_3_6,
      bg: '#4852AC',
      texts: [
        {
          text: '전 세계 4명 중 1명은 일생 중 우울증을 겪으며, 모든 사람은 살아가면서 우울감을 경험합니다. ',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['우리는 과연 슬픔으로 이끄는 비극적인 사건을 막을 수 있을까요? ']]}
              type={'gray'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '아닙니다. 우리가 할 수 있는 일은 슬픈 일을 막는 것이 아닌, ',
                '슬픔을 딛고 건강하게 회복할 수 있도록 하는 마음의 근육을 기르는 것',
                '입니다. 그래서 여러분과 함께 인사이드의 다양한 도구들을 활용하여 나의 슬픔을 다스리는 법을 함께 알아볼 거예요. ',
                '\n',
                '잠시 쉬었다가 조금 더 이야기해 볼게요. 호흡도구를 한 번 사용하고 오시는 것도 좋을 거 같아요. 처음엔 이렇게 제가 자주 권해야 많이 실천하시더라고요. 그래도 다들 제가 하자고 하면 잘 따라오시니까 다행이에요. 그러면 호흡을 한 번 하고 나서 다시 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '마음의 근육 기르기',
      fullImage: wb4_4_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '자, 그럼 다시 시작해 볼게요. 제가 드렸던 감정 노트는 잘 쓰고 계시나요? 아직 어떻게 사용해야 하는지 잘 몰라 많이 적지 않으셨을지도 모르겠네요. 무엇보다 잘 써야 한다는 부담감을 내려놓아 보세요. 언제든 나의 감정을 털어놓을 수 있는 창구 정도로 생각해 보면 감정 노트가 한결 친근해지실 거예요. 감정에 대해 기록한다는 사실 자체가 중요하답니다. 너무 부담가지지 말아요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '감정 노트와 회고 노트가 중요해요',
      image: wb4_4_2,
      bg: '#B6D0E3',
      texts: [
        {
          text: '마음의 근육을 기르는 방법 중 가장 핵심적인 것은 감정 노트와 회고 노트를 꾸준히 사용하는 것이랍니다.',
        },
        {
          component: <MessageBlockWithTail textList={[['노트를 어떻게 쓰면 된다는 건가요?']]} type={'black'} my />,
        },
        {
          text: '여전히 많이 어렵나요? 저의 진료실에 오셨던 분 중 감정 노트를 통해 보다 단단한 마음의 근육을 갖게 된 한 분의 이야기를 들려드릴게요.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '3년 전에 오셨던 룬님의 이야기에요',
      image: wb4_4_3,
      bg: '#BACDD8',
      texts: [
        {
          text: '룬님은 동생이 교통사고로 한순간에 세상을 떠난 후부터 극심한 죄책감에 시달려 잠을 잘 수 없다며 저에게 오셨어요. 1년 넘게 괴로워하던 룬님은 삶을 마감할 생각을 할 정도로 심한 우울증을 겪고 있었습니다. 룬님을 괴롭게 하던 생각은 다음과 같았어요.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '그날 아침에 동생이랑 싸웠어요. 동생이 말도 안 하고 제 옷을 입고 나가서 제가 엄청 화를 냈거든요. 아직도 너무 후회되고 미안해요. 동생에게 좋지 않은 마지막 기억을 준 건 다 제 책임이에요. ',
                ],
              ]}
              type={'black'}
              margin="mt-[40px]"
              opposite
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '룬님과의 대화를 살펴볼게요',
      texts: [
        {
          text: '그렇게 룬님은 1년 동안 불면증에 시달려 직장을 휴직하고 저에게 찾아왔었죠. 그리고 우리는 이런 대화를 나눴어요. ',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['gray', 'black']}
              texts={[
                ['그 당시에 대해 더 기억이 나는 것이 있나요?'],
                [
                  '제가 화를 내서 동생이 밥도 안 먹고 먼저 나가버렸어요. 그리고 사고가 났어요. 제가 쓸데없이 화만 내지 않았더라면 평소처럼 10분만 더 늦게 출근해서 사고가 나지 않았을 텐데. 이게 다 저 때문이에요.',
                ],
                ['룬님은 그날 아침에 동생에게 사고가 날 것을 미리 알고 계셨나요?'],
                ['아니요. 당연히 몰랐죠.'],
                ['맞아요. 룬님이 그날 아침에 동생에게 화를 냈다고 해서 사고가 났던 게 아니에요. '],
              ]}
              my
            />
          ),
        },
        {
          text: '룬님과 대화를 나누는 동안, 저는 룬님이 어떤 생각 함정에 빠져있는지 찾아보았습니다. 여러분도 퀴즈를 통해 한번 맞춰보세요.',
        },
      ],
      buttonText: ' ',
      customButtonText: '퀴즈 풀기',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="룬님은 어떤 생각 함정에 빠졌을까요?"
          contents={[
            {
              component: (
                <div>
                  <p>그 당시에 대해 더 기억 나는 것이 있나요?</p>
                  <MessageBlockWithTail
                    textList={[
                      [
                        '제가 화를 내서 동생이 밥도 안 먹고 먼저 나가버렸어요. 그리고 사고가 났어요. 제가 쓸데없이 화만 내지 않았더라면 평소처럼 10분만 더 늦게 출근해서 사고가 나지 않았을 텐데. 이게 다 저 때문이에요.',
                      ],
                    ]}
                    type={'white'}
                    margin="mt-[30px]"
                    isQuiz
                  />
                </div>
              ),
            },
          ]}
          buttonList={['흑백논리', '좋은 일 회피하기', '과도한 책임감', '성급한 결론']}
          answer="과도한 책임감"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="과도한 책임감에 빠진거예요"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '과연 미래를 미리 알 수 있는 사람이 있을까요?',
                '\n',
                '아니요, 없습니다. 그날 사고가 난 것은 룬님이 통제할 수 없었던 사건이었고, 동생이 죽은 것은 룬님의 탓이 아니었습니다. 그러나 룬님은 실제로 관계가 없는 동생의 죽음을 자신이 화를 낸 탓으로 돌리며 과도한 책임감을 느끼고 있었어요.',
                '\n',
                '이러한 생각 함정은 끊임없이 우리의 감정을 상하게 해 다시 부정적인 생각을 몰고 오게 됩니다.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      title: '룬님은 생각을 바꿔보기로 했어요',
      fullImage: wb4_3_5,
      height: 190,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이러한 대화를 나눈 후 룬님은 오히려 자신이 책임질 일은 죄책감과 불면증에서 비롯된 우울증을 이겨내고 행복한 인생을 사는 것이라는 것을 깨달았어요.',
                '\n',
                '저는 룬님에게 감정 노트를 선물해 드렸어요. 감정과 생각을 기록하며 룬님은 빠르게 회복하였고 다시 회사에 나가 이전과 같은 일상생활로 돌아갈 수 있었어요. 그리고 보다 더 평화롭고 행복한 삶을 위해 저와 몇 차례 더 만나며 생각을 되돌아보는 연습을 했습니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '그렇군요!',
    },
    {
      title: '룬님의 감정기록이에요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '때마침, 지난주에 카페에 갔다가 우연히 룬님을 마주쳤는데요. 제가 인사이드에서 진료실을 열었다는 이야기를 듣고는 자신과 같이 슬픔의 우물 안에서 힘들어하는 사람들을 위해 감정 노트와 회고 노트를 보여줄 수 있도록 빌려주셨어요.',
                '\n',
                '룬님의 기록들을 한번 살펴보도록 할게요.',
              ]}
            />
          ),
        },
        {
          component: (
            <div
              className={`border-b-1 w-full relative overflow-hidden rounded-2xl mt-[30px] flex justify-center align-center`}
              style={{ backgroundColor: '#E1E4EB' }}
            >
              <div className={`max-w-[300px] mx-[17px] overflow-hidden mt-[0.5px]`}>
                <img className="w-full h-full object-contain" src={wb4_4_6} alt="empty" />
              </div>
            </div>
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '생각 함정에 빠지지 않은 생각',
      bg: '#FFE5A0',
      texts: [
        {
          text: '감정 기록을 지속하던 룬님은 평소와 같이 회고 노트를 작성하려고 했어요. ',
        },
        {
          component: (
            <div
              className={`my-[30px] border-b-1 w-full relative overflow-hidden rounded-2xl mt-[30px] flex justify-center align-center`}
              style={{ backgroundColor: '#E1E4EB' }}
            >
              <div className={`max-w-[300px] mx-[17px] overflow-hidden mt-[0.5px]`}>
                <img className="w-full h-full object-contain" src={wb4_4_7} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          text: '그런데 생각 함정을 선택하려는 순간 룬님은 깨달았습니다.',
        },
        {
          component: <MessageBlock textList={[['선택할 생각 함정이 없구나!']]} type={'gray'} my />,
        },
        {
          text: '룬님은 처음으로 현실을 있는 그대로 바라보고 생각했다는 사실을 깨달았어요. 그리곤 동생이 자신 때문에 죽었다는 왜곡된 생각으로 인한 죄책감으로부터 벗어나 이런 생각을 하게 되었죠.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '동생은 나 때문에 죽은 것이 아니었어. 동생도 내가 행복하게 살아가길 바랄거야. 내 책임이 아닌 일에 죄책감을 느끼는 것은 멈추자. 나의 삶을 꿋꿋이 살아가는 것이 내가 할 수 있는 일이야.',
                ],
              ]}
              type={'gray'}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '여러분의 생각 함정을 찾아보세요',
      image: wb4_4_4,
      bg: '#33415C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이별이나 죽음으로부터 오는 슬픔, 과거를 그리워하는 마음 모두 소중한 무언가를 상실했다는 데서 오는 고통이에요. 하지만 모든 감정이 그렇듯 슬픔 또한 다가오면 지나가는 것이 자연스러운 일이죠. ',
                '이런 슬픔이 사라지지 않고 나를 끝없는 구렁텅이로 몰아넣고 있다면 아마 여러분은 생각 함정에 빠져 감정에 매몰되어 있었을지도 모릅니다.',
                '\n',
                <span />,
                '감정 노트와 회고 노트를 꾸준히 사용하면 ',
                '1~2주 안에 기분이 달라진 것을 느끼고, 3개월 이상 지나면 마음의 근육은 더욱 단단해질 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '이럴 때는 전문가를 만나보세요',
      image: wb4_4_5,
      bg: '#2C4BEC',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '혼자 하는 것이 어렵다면 인사이드의 코치나 심리상담사 선생님의 도움을 받아봐도 좋아요.',
                <br />,
                '세상에 만병통치약은 없듯이 때로는 병원에 가서 약물치료나 의사의 진료가 필요할 때도 있어요. ',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['죽음에 대한 생각이 들 때가 있다'],
                ['일상생활에 지장이 있다'],
                ['다른 신체적 증상으로 고통받는다'],
              ]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이렇게 ',
                '혼자서 감당하기 어려운 증상이 있다면, 언제든 병원이나 인사이드 내의 정신 건강 전문가 선생님들의 도움을 받는 것이 좋습니다.',
                ' 우울증은 정말로 마음이 아픈 것입니다. 다리가 부러지면 정형외과에 가서 수술받듯이, ',
                '마음이 아픈 것 또한 치료받으면 회복이 되는 질환',
                '입니다. 정신력이 약한 것이라며 부끄러워할 것이 아니에요. 그러니 위와 같은 증상이 있다면 꼭 전문가를 찾아가보시길 바랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '자, 여기서 마무리 해볼까요?',
      texts: [
        {
          text: [
            '다음 시간에는 죄책감에 대해 알아보도록 할 거예요. 저는 음악을 한 곡 듣고 나머지 하루를 보내려고 해요. Audrey Hepburn의 <Moon River>라는 노래를 들을 거랍니다. 마음이 차분해지는 곡이에요. 가끔 사람들이 왜 자꾸 노래를 알려주냐고 할 때가 있답니다. 혹시 모르죠. 언젠가 이 노래를 듣고 제가 생각나서 또 저를 찾아오실지요.',
            <br />,
            <br />,
            '자, 그럼 우린 내일 만나요.',
          ],
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '죄책감을 부르는 생각 함정',
      subtitle: '오셨어요? 반가워요.',
      fullImage: wb4_5_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘 하루는 어떻게 보내고 계신가요? 저는 아침부터 고양이가 탁자 위를 건너가다가 커피잔을 쏟아버렸어요. 커피잔을 높은 탁자에 놓아둘 걸 그랬어요. 식어버린 커피였기에 망정이지, 만약 뜨거운 커피였더라면 커피에 젖은 고양이가 큰 화상을 입을 뻔 했어요.',
                '\n',
                '만약에 커피가 뜨거워 고양이가 다쳤더라면, 여러분은 어떤 생각이 들었을 것 같나요?',
              ]}
            />
          ),
        },
        {
          component: (
            <NavigateSelectButton
              textList={[
                '전부 나 때문이야! 난 정말 할 줄 아는 게 아무것도 없어!',
                '빨리 동물 병원부터 가야겠다. 앞으로 커피잔을 높은 곳에 올려두어야겠어.',
              ]}
            />
          ),
        },
      ],
    },
    {
      title: '저는 다음과 같이 느꼈을 것 같아요',
      image: wb4_5_2,
      bg: '#E3ECF9',
      texts: [
        {
          text: '만약에 뜨거운 커피였더라면 저도 잠시 죄책감을 느꼈을 것 같아요. ',
        },
        {
          component: <MessageBlockWithTail textList={[['나 때문에 고양이가 다쳤어...']]} type={'white_dotted'} my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '그리고 재빨리 죄책감에서 빠져나와 상황을 수습했을 거예요.',
                '\n',
                '이렇듯 우리는 누군가에게 실수로 피해를 주거나, 나쁜 행동을 했다고 생각할 때 ',
                '죄책감',
                '을 느낄 때가 있어요. 그런데 이 죄책감에 너무 깊게 빠져버리면 문제가 됩니다. 이 경우 실수에 대한 교훈을 얻고 발전하는 것이 아니라 오히려 죄책감에 매몰되어 시간을 헛되이 보내게 되어버립니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '후회와 죄책감은 다른 거예요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '실수나 잘못에 대해 자연스럽게 생겨나는 ',
                '후회',
                '는 ',
                "'행동'",
                '에 대한 것인 반면, ',
                '죄책감',
                '은 ',
                "'자신'",
                '을 향해 있습니다. 죄책감을 느낄 때 우리는 흔히 이런 생각을 하죠.',
              ]}
            />
          ),
        },

        {
          text: '룬님은 동생이 교통사고로 한순간에 세상을 떠난 후부터 극심한 죄책감에 시달려 잠을 잘 수 없다며 저에게 오셨어요. 1년 넘게 괴로워하던 룬님은 삶을 마감할 생각을 할 정도로 심한 우울증을 겪고 있었습니다. 룬님을 괴롭게 하던 생각은 다음과 같았어요.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['나는 해서는 안 되는 일을 저질렀어'], ['그런 나쁜 행동을 한 나는 나쁜 사람이야']]}
              type={'black'}
              my
            />
          ),
        },
        {
          text: '이렇게 피어난 죄책감은 꼬리에 꼬리를 물고 부정적인 감정과 생각들을 몰고 옵니다.',
        },
        {
          component: (
            <div className="my-[40px]">
              <MessageBlockWithTail
                textList={[['나는 해서는 안 되는 일을 저질렀어']]}
                type={'black'}
                margin="mb-[10px]"
              />
              <MessageBlock textList={[['우울하고 비참하다']]} type={'white_dotted'} margin="mb-[24px]" />
              <MessageBlockWithTail
                textList={[['누군가 내가 한 짓을 알게 되면 나를 비웃겠지?']]}
                type={'black'}
                margin="mb-[10px]"
              />
              <MessageBlock textList={[['수치스럽고 부끄럽다']]} type={'white_dotted'} margin="mb-[24px]" />

              <MessageBlockWithTail textList={[['이런 나는 벌을 받게 될 거야']]} type={'black'} margin="mb-[10px]" />
              <MessageBlock textList={[['불안하고 두렵다']]} type={'white_dotted'} />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우리를 고통스럽게 하는 비정상적인 죄책감에서 자유로워지려면, 그것이 ',
                '건전하고 적절한 후회',
                '인지, ',
                '비정상적으로 왜곡된 죄의식',
                '인지 구분할 줄 알아야 해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '죄책감이 심할 때 질문해보세요',
      texts: [
        {
          text: '죄책감이 우울함과 불안감을 몰고 와서 너무 오랜 기간 나를 괴롭게 한다면, 스스로에게 다음의 질문을 해보세요.',
        },
        {
          component: (
            <div className="my-[40px] mx-[25px] px-[12px] rounded-[16px]" style={{ border: '1px dashed #A4A6B0' }}>
              <TextWithDot
                texts={[
                  '나의 실수나 잘못이 내 고의일까? 내가 실수 없이 완벽한 사람이어야 한다고 생각하고 있지는 않은가?',
                  '이런 행동으로 스스로를 나쁜 사람이라고 여기고 있지는 않은가?',
                  '실수를 통해 배우고 해결책을 고민하고 있는가? 아니면 과거를 후회하는 일에 과도하게 붙들려 있지는 않은가?',
                ]}
                margin="my-[20px]"
              />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이런 질문들을 통해, 죄책감을 유발하는 왜곡되고 뒤틀린 생각을 알아볼 수 있어요. 그리고 제가 드린 ',
                '회고 노트에 생각을 적다 보면, 우리가 어떤 생각 함정에 빠졌는지 객관적으로 파악할 수 있게 됩니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '흔히 빠지는 생각 함정',
      image: wb4_5_3,
      bg: '#33415C',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이전의 세 가지 질문으로 내가 과도한 죄책감으로 고통받고 있다는 것을 알게 되셨다면, 원인이 되는 생각 함정을 찾아볼 차례입니다. 죄책감에 빠지게 하는 생각 함정에 대해 알려드릴게요. 혼자서 회고 노트를 작성할 때 도움이 될 겁니다.',
                '\n',
                '제가 1년 전에 만났던 중학생 케이의 이야기입니다. ',
                '과도한 죄책감으로 불안과 우울을 느끼고 있던 케이가 어떻게 생각 함정을 극복했는지',
                ' 함께 봅시다. ',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '중학생 케이의 이야기예요',
      image: wb4_5_4,
      bg: '#E3ECF9',
      texts: [
        {
          text: '중학생 케이는 중요한 시험을 한 주 앞두고 있었습니다. 가장 큰 관심사는 다이어트였고요. 어느 날 밤, 공부하던 케이는 집중이 안 돼 아이스크림을 한입 먹었습니다.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['아이스크림을 먹다니, 이번 다이어트는 망했어. 나는 돼지야']]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이런 생각이 스친 케이는 갑자기 속이 끓어오르며 화가 나 아이스크림을 한 통 다 비워버렸어요.',
                '\n',
                '인사이드에서 여기까지 오신 분이라면 생각 함정이 무엇인지 알고 계실 거예요. 케이는 어떤 생각 함정에 빠졌는지 한번 맞혀보세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
      customButtonText: '퀴즈 풀기',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="케이는 어떤 생각 함정에
                  빠졌던 걸까요?"
          contents={[
            {
              component: (
                <div>
                  <p>강조된 문장의 생각 함정을 찾아보세요</p>
                  <MessageBlockWithTail
                    textList={[
                      [
                        '살을 빼려면 ',
                        '아이스크림을 절대로 먹으면 안 되는데 먹어버렸어. 내가 세운 계획은 완벽하게 지켜내야했는데,',
                        ' 이런 계획도 못 지키다니, 나는 멍청하고 나쁜 돼지야',
                      ],
                    ]}
                    type={'white'}
                    margin="mt-[30px]"
                    isQuiz
                  />
                </div>
              ),
            },
          ]}
          buttonList={['일반화', '당위 부여', '꼬리표 붙이기', '감정으로 판단하기']}
          answer="당위 부여"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="꼭 해야만 한다고 생각하고 있어요"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '맞아요. 케이는 자신은 살을 빼려면 절대로 아이스크림을 먹으면 안 돼라며 ',
                '당위 부여',
                '를 하고 있어요. ‘해야 한다’식 사고를 가지게 되면, 자신만의 규칙을 어기게 됐을 때 강한 죄책감이 유발되어 불안함과 초조감을 느끼게 됩니다. 불안해진 케이는 자기도 모르게 아이스크림을 한 통 다 비워버렸어요.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="케이는 어떤 생각 함정에
                  빠졌던 걸까요?"
          contents={[
            {
              component: (
                <div>
                  <p>강조된 문장의 생각 함정을 찾아보세요</p>
                  <MessageBlockWithTail
                    textList={[
                      [
                        '살을 빼려면 아이스크림을 절대로 먹으면 안 되는데 먹어버렸어. 내가 세운 계획은 완벽하게 지켜내야했는데, ',
                        '이런 계획도 못 지키다니, 나는 멍청하고 나쁜 돼지야',
                      ],
                    ]}
                    type={'white'}
                    margin="mt-[30px]"
                    isQuiz
                  />
                </div>
              ),
            },
          ]}
          buttonList={['좋은 일 회피하기', '좁은 시야', '꼬리표 붙이기', '당위 부여']}
          answer="꼬리표 붙이기"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="부정적인 꼬리표를 붙였어요"
          buttonText="이해했어요!"
          answerInfo={
            <div>
              <TextWithBold
                texts={[
                  '그렇습니다. 케이는 ',
                  '고작 아이스크림을 먹은 것일 뿐인데, 자신에게 멍청이면서 나쁜 돼지라고 부정적인 꼬리표를 붙여버렸어요.',
                  ' 자신이 죄책감을 느꼈을 때 감정적으로 자신을 ‘나쁜 사람’이라고 낙인을 찍어 버리는 경우, 과연 좋은 점이 있을까요? ',
                  '\n',
                  '전혀 없습니다. 이렇게 자신에게 낙인을 찍어버리는 행동은 스스로를 비판하고 마음 상하게 할 뿐입니다. 그리고 ',
                  '단 하나의 행위로 자아 전체를 낙인 찍어버리는 것은 굉장히 비합리적입니다.',
                  ' 예를 들어 여러분이 밤에 잠을 자고 일어나 이런 생각을 했다고 해봅시다.',
                ]}
                mx
              />
              <MessageBlockWithTail textList={[['잠만 자는 게으름뱅이 같으니라고']]} type={'white_dotted'} my />
              <TextWithBold
                texts={[
                  '얼마나 말도 안 되고 고통스러운 생각인가요? 이렇게 꼬리표를 붙임으로써 비난의 화살을 스스로에게로 돌리게 되면 부정적인 감정을 감당하는 데 에너지를 빼앗겨 버리는 거예요.',
                ]}
                mx
              />
            </div>
          }
        />
      ),
    },
    {
      title: '저와 함께 노트를 써보기로 했어요',
      image: wb0_4_2,
      bg: '#96CDFF',
      height: 190,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '저는 케이에게 방법을 하나를 알려주었어요. ',
                '반드시 해야만 한다는 기준을 지키지 못했을 때, 그로 인해 자신에게 나쁜 꼬리표를 붙이며 비난하게 될 때',
                ' 노트를 열어 생각을 그대로 적어보도록 했어요. 그리고 함께 생각을 교정해보기로 했습니다.',
                '\n',
                '그렇게 일주일 간 기록을 했던 케이는 진료실에 와 이런 이야기를 했어요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '정말 노트에 기록하니 좋네요. 그런데 한 가지 고민이 더 있어요. 주변에서 일어나는 나쁜 일이 다 제 탓 같다는 거예요. ',
                ],
              ]}
              type={'black'}
              my
            />
          ),
        },
        {
          text: '그래서 저는 케이가 빠진 다른 생각 함정이 무엇인지 함께 찾아보았어요. 다음 시간에는 케이의 노트를 살펴보며 케이가 어떠한 생각 함정에 빠졌고, 왜 그러한 함정에 빠지게 되었는지 살펴볼 거예요. 그러면 잠시만 쉬었다 다시 시작할까요?',
        },
      ],
      buttonText: '그렇군요!',
    },
  ],
  [
    {
      title: '죄책감 벗어던지기',
      subtitle: '이야기를 이어 나가볼게요',
      fullImage: wb4_6_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '방금 전 들려드렸던 케이 이야기를 마저 할게요. 케이가 아이스크림을 먹고 죄책감을 느꼈을 때 빠졌던 생각 함정, 기억나시나요? 당위 부여와 꼬리표 붙이기였죠. 케이의 생각에 그러한 생각함정이 있다는 걸 발견한 저는 케이에게 일주일 간 생각 노트와 회고 노트를 사용하도록 했어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '케이의 감정기록이에요',
      texts: [
        {
          text: '일주일이 지난 후 함께 기록들을 살펴보았죠. 여러분도 노트를 읽어보고 케이가 어떤 생각 함정에 빠졌는지 함께 찾아보세요.',
        },
        {
          component: (
            <div
              className={`border-b-1 w-full relative overflow-hidden rounded-2xl mt-[30px] flex justify-center align-center`}
              style={{ backgroundColor: '#E1E4EB' }}
            >
              <div className={`max-w-[300px] mx-[17px] overflow-hidden mt-[0.5px]`}>
                <img className="w-full h-full object-contain" src={wb4_6_6} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          component: (
            <div
              className={`border-b-1 w-full relative overflow-hidden rounded-2xl mt-[30px] flex justify-center align-center`}
              style={{ backgroundColor: '#E1E4EB' }}
            >
              <div className={`max-w-[300px] mx-[17px] overflow-hidden mt-[0.5px]`}>
                <img className="w-full h-full object-contain" src={wb4_6_7} alt="empty" />
              </div>
            </div>
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="케이는 어떤 생각 함정에
                  빠졌던 걸까요?"
          contents={[
            {
              component: (
                <div>
                  <MessageBlockWithTail
                    textList={[
                      [
                        '내가 도와줬더라면 친구가 시험을 망쳐서 슬퍼하지 않았을지도 몰라. 이게 다 나 때문이야. 난 정말 이기적이야.',
                      ],
                    ]}
                    type={'white'}
                    isQuiz
                  />
                  <MessageBlockWithTail
                    textList={[
                      [
                        '추운데 고생하고 계시는데 내가 매정하게 지나쳐서 상처받지는 않으셨을까? 내가 전단지를 받았어야 조금이라도 빨리 끝내고 집에 가셨을 텐데...',
                      ],
                    ]}
                    type={'white'}
                    isQuiz
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
          buttonList={['과도한 책임감', '흑백논리', '꼬리표 붙이기', '좁은 시야']}
          answer="과도한 책임감"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="과도한 책임감을 느끼고 있어요"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '맞습니다. 케이는 자신의 잘못이 아닌 일에 과도한 책임감을 느꼈어요. 친구가 시험을 망친 것은 케이가 돕지 않아서가 아닙니다. 전단지를 받지 않았을 때도 추운데 오래 서 계실 할머니를 안타까워할 수는 있지만, 그로 인해 죄책감까지 느끼는 것은 과합니다.',
                '\n',
                <span />,
                '자신의 책임이 아닌데도 어떤 일이 자신이 부족해서 일어났다며 스스로를 탓하며 빠지게 되는 생각 함정',
                '을 ',
                '과도한 책임감',
                '이라고 하죠. 이 함정은 ',
                '우리를 죄의식으로 몰고 가는 가장 흔한 생각 함정',
                '이에요.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      title: '케이가 갖고 있던 규칙',
      image: wb4_6_3,
      bg: '#2C4BEC',
      texts: [
        {
          text: '케이와 대화를 더 나누어보니 케이의 마음속에는 이런 규칙이 있었어요',
        },
        {
          component: <MessageBlock type={'white_dotted'} textList={[['나는 항상 착해야 하고, 남을 도와야만 해']]} my />,
        },
        {
          text: '어렸을 때부터 부족함 없이 자란 외동딸 케이는 부모님의 기대를 한 몸에 받고 있었어요. 사실 케이는 말괄량이에 장난기 많은 중학생 소녀였지만, 언제나 성숙하고 의젓한 모범생의 모습을 바라는 부모님의 바람에 부응해야 한다는 압박감이 있었습니다. ',
        },
        {
          component: (
            <MessageBlock
              type={'gray'}
              textList={[['케이야, 의젓하게 행동해야지'], ['케이는 항상 주변 사람들을 잘 챙겨. 정말 어른스럽구나.']]}
              my
            />
          ),
        },
        {
          text: '그래서 케이는 남을 도와야 한다는 책임감이 점점 무거워져 자신과 관련 없는 일까지도 자신의 책임으로 여기며 스스로를 탓하게 되었던 겁니다.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '죄책감을 벗어던지는 방법',
      image: wb4_6_4,
      bg: '#24968F',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '케이가 죄책감을 극복할 수 있었던 방법은 정말 간단하답니다. 바로 ',
                '계속해서 떠오르는 부정적인 생각에 말대꾸하기',
                '입니다. 간단하지만 핵심은 꾸준히 해야 한다는 거예요.',
                '\n',
                '가령 ',
                '“해야 한다”라는 당위 부여에 대해서는 “한다면 좋을 거야”라고 생각',
                '하며 말대꾸할 수 있죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              type={'gray'}
              textList={[['그렇게 한다면 좋을 거야. 하지만, 반드시 해야만 하는 건 아니야.']]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '과도한 책임을 느낄 때에는 ',
                '내가 책임질 부분과 상대방이 책임질 부분을 구분할 필요',
                '가 있어요. 그리고 다음과 같이 되물어볼 수 있죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail type={'gray'} textList={[['정말 내 책임은 얼마나 될까?']]} margin={'mt-[40px]'} />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '저마다의 규칙이 있을 거예요',
      image: wb4_6_3,
      bg: '#2C4BEC',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '누구나 경험에 의해 저마다 마음속에 규칙이 생겨납니다. 규칙은 우리를 올바른 길로 인도해주기도 하지만, 때로는 너무나도 엄격한 심판관이 되어 누구도 지킬 수 없는 비현실적인 잣대를 들이밀기도 합니다. 이러한 규칙은 ',
                '우리의 경험을 통해 굳어진 생각이므로 쉽게 변하기 어려울 수 있어요.',
                ' 또한 규칙은 ',
                '마음속 깊은 곳에 숨어있기 때문에 찾아내기도 쉽지 않죠.',
                '\n',
                <span />,
                '감정 기록을 지속하다 보면 내가 언제 주로 불편한 감정을 느끼는지 알게 됩니다. 그 안에서 나의 엄격했던 규칙을 찾아 규칙 카드에 적어 보세요. 하지만 마음속 깊이 굳어진 규칙을 찾고 그 틀에서 벗어나기 위해서는 ',
                '전문가의 도움',
                '이 필요할지도 몰라요. 인사이드에는 이러한 과정들을 도와줄 전문가 선생님들이 있으니 그들을 만나보는 것도 좋은 선택입니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '반복적으로 내 규칙을 반박해요',
      image: wb4_6_5,
      bg: '#29458C',
      texts: [
        {
          text: '늘 배운 죄책감으로 인해 마음고생하고 계신다면, 감정 노트와 회고 노트를 활용하여 오늘 배운 것과 같은 생각 함정에 빠져있진 않은지 살펴보세요. 그리고 규칙 카드에 적은 나의 엄격했던 규칙에 대해 다음과 같이 말대꾸해 보세요.',
        },
        {
          component: (
            <MessageBlockWithTail
              type={'gray'}
              textList={[['그렇게 한다면 좋을 거야. 하지만, 반드시 해야만 하는 건 아니야.']]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '명심하세요. 나를 바꿀 수 있는 건 나 자신이랍니다. 나를 갉아먹고 있는 부정적인 규칙에 대해서는 반복해서 의식적으로 대꾸할 필요가 있답니다. 그렇게 하다 보면 많은 것들이 바뀔 수 있을 거예요. 우리 모두 그러한 마음의 잠재력이 있는 사람들이니까요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '오늘도 고생 많았어요',
      texts: [
        {
          text: [
            '항상 감정에 대해 이야기하다 보면 저도 지칠 때가 많답니다. 그렇지만 저는 마음에 대한 이야기를 나누는 게 좋아요. 알고 보면 우리 모두 다 비슷하게 약하고, 또 비슷하게 강인한 면들이 있거든요. 그 다양한 모습들을 함께 나눌 수 있다는 건 참 감사한 일이에요.',
            <br />,
            <br />,

            '다음 시간에는 분노에 대해 배워볼게요. 저는 이제 고양이와 잠시 누워서 휴식을 취해야겠어요. 어쩌면 고양이가 제 무릎 위에서 잠들지도 모르죠. 자, 그러면 우리는 내일 만나요.',
          ],
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '양날의 검, 분노',
      subtitle: '반가워요',
      fullImage: wb4_7_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '어서오세요, 매일 이렇게 꾸준히 찾아오시니 참 반갑네요. 요즘은 찾아오는 사람들이 많아 정신이 없지만, 진료실을 연 초반에는 내일은 찾아오는 사람이 없으면 어쩌나 마음 졸였던 적도 있답니다. 진료실에 혼자 남아있는 건 쓸쓸한 일이거든요. 별 이야기를 다 하네요. 아무튼 반갑다는 이야기였어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '오늘 있었던 일이에요',
      texts: [
        {
          text: '점심시간에 글을 쓰기 위해 진료실에서 음악을 틀고 조용히 혼자만의 시간을 가지려고 했어요. 그런데 갑자기 밖에서 우당탕탕 소리가 들렸죠. 밖으로 나가보니 아이들이 뛰어놀고 있었습니다. ',
        },
        {
          component: <MessageBlock type={'gray'} textList={[['아이고, 귀여운 아이들. 신나게 뛰어놀고 있구나.']]} my />,
        },
        {
          text: '조금 소란스러웠지만 그래도 저는 아이들의 웃음소리를 흐뭇하게 들으며 글을 쓰는 것에 집중할 수 있었어요.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '지난주에도 비슷한 일이 있었어요',
      image: wb4_7_2,
      texts: [
        {
          text: '그런데 생각해보니 지난주에도 비슷한 일이 있었어요. 그때는 분명 이렇게 생각했습니다.',
        },
        {
          component: (
            <MessageBlockWithTail textList={[['어휴 시끄러워라. 하나도 집중이 안 되네!']]} type={'black'} my />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '결국 당시에는 화가 나서 결국 일을 하지 못하고 바깥으로 나가 산책을 했었죠. 여기에서 제가 하고자 했던 말은, ',
                '감정은 생각에서 나온다',
                '는 것이었어요. 결국 ',
                '감정은 사건 그 자체가 아닌 우리가 사건을 해석하는 방법에 따라 생겨납니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '이런 생각이 들지도 모르겠네요',
      image: wb4_7_3,
      texts: [
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '정말 화를 낼 만한 상황도 있지 않나요? 불공평한 일이 얼마나 많은데, 그런 일을 어떻게 그저 참아 넘기란 말인가요?',
                ],
              ]}
              type={'black'}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '맞아요. 세상에는 부조리하거나 상식 밖의 일들이 매일 일어납니다. 하지만 그런 일을 겪었을 때 무작정 화가 나는 것을 참고 견디란 말이 아니에요. 중요한 것은 ',
                '분노가 나를 조종하도록 두지 말고, 내가 분노의 주인이 되어 언제 얼마만큼의 분노를 표출할지 결정하자',
                '는 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '의사 고든의 이야기예요',
      image: wb4_7_4,
      bg: '#FFEFD0',
      texts: [
        {
          text: '고든은 훌륭한 정형외과 의사였어요. 평소에는 누구보다도 온화하고 유쾌한 사람으로 주변 사람들과 원만한 관계를 맺고 있었습니다. 그런 고든이 어느 날 저에게 찾아왔어요.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '아무에게도 말하지 않았던 건데, 사실 전 화가 많습니다. 정말 잘 참고 있다가도 한순간에 터져버리는 게 문제인 것 같아요.',
                ],
              ]}
              type={'black'}
              opposite
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '화를 안내는 사람이 있을까요?',
      texts: [
        {
          text: '경직된 얼굴로 말하는 고든을 차분히 다독이며 계속 대화를 이어갔어요.',
        },
        {
          component: (
            <div className="mt-[40px]">
              <MessageBlockWithTail
                textList={[
                  ['그렇군요. 고민이 많았을 거 같네요. 그런데 오늘 진료실을 찾게 된 특별한 계기가 있었던 건가요?'],
                ]}
                type={'gray'}
                cross
                margin="mb-[10px]"
              />
              <MessageBlockWithTail
                textList={[
                  [
                    '맞아요. 얼마 전 수술 중에 수술 가위가 잘 들지 않았거든요. 순간적으로 짜증이 나서 동료들에게 소리를 버럭 질렀어요. 평소에는 큰 문제가 발생해 화가 나더라도 잘 참아 넘기는데, 굉장히 사소한 문제였는데 저도 모르게 분노가 폭발했습니다.',
                  ],
                ]}
                type={'black'}
                opposite
                cross
                margin="mb-[24px]"
              />
              <MessageBlockWithTail
                textList={[['화를 크게 낸 일이 있었군요. 그 후에 어떤 생각이 들었나요?']]}
                type={'gray'}
                cross
                margin="mb-[10px]"
              />
              <MessageBlockWithTail
                textList={[
                  [
                    '정말 부끄럽고 수치스러웠어요. 제 분을 못 이겨 분노를 터뜨린 거잖아요 며칠 동안 동료들 얼굴을 보는 게 민망했습니다.',
                  ],
                ]}
                type={'black'}
                opposite
                cross
              />
            </div>
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '분노는 양날의 검입니다',
      fullImage: wb4_7_6,
      height: 190,
      texts: [
        {
          text: '사실 분노는 타인뿐만 아니라 나에게도 해를 끼칠 수 있답니다. 누군가 고의적으로 나에게 피해를 준 상황이더라도 충동적으로 분노를 터뜨리며 보복을 하는 행위는 궁극적으로 자신에게 모멸감과 부끄러움을 주게 되죠. ',
        },
        {
          component: <MessageBlockWithTail textList={[['그러면 절대로 화를 내서는 안 되겠네요?']]} type={'black'} my />,
        },
        {
          text: '그렇다고 해서 분노가 있어서는 안 되는 감정이라거나 완전히 쓸모없다는 것이 아닙니다. 분노를 적절히 표출해야 할 때도 있어요.',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '분노를 적절히 표출한 경우예요',
      image: wb4_7_7,
      bg: '#F3F5FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '유명한 축구선수 아레스의 이야기예요. 그는 실력이 뛰어나지만, 경기 중에 일어난 작은 몸싸움에 분노를 참지 못해 여러 차례 퇴장을 당하는 문제가 있었습니다. 아레스는 분노를 통제하지 못하는 것이 팀의 성적에 안 좋은 영향을 미치고 있다는 것을 뼈저리게 깨닫고 변화가 필요하다고 생각했습니다.',
                '\n',
                '하루는 이러한 아레스의 약점을 노린 상대편 선수가 고의로 경기 중에 그에게 시비를 걸었습니다. 아레스는 순간적으로 분노가 치밀었지만 이렇게 생각했습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['잠깐, 분노가 차오르지만 예전처럼 충동적으로 행동하지 않을거야']]}
              type={'black'}
              my
            />
          ),
        },
        {
          text: '아레스는 예전과 같이 몸싸움으로 맞대응하는 대신 다른 방법을 선택했습니다. 분노를 승부욕으로 승화시켜 그날 팀의 승리를 이끌었어요. ',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '분노를 활용하는 거예요',
      image: wb4_7_8,
      bg: '#FEFBE1',
      texts: [
        { text: '만약 아이가 찻길로 뛰어드는 위험한 행동을 했을 때 부모는 즉각 저지하며 소리칠 거예요.' },
        {
          component: <MessageBlockWithTail textList={[['위험하니까 찻길로 뛰어들면 안 돼!']]} type={'black'} my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이때 차분하고 다정한 말투로 이야기했다면 아이에게 그러한 행동이 위험하다는 게 전달되지 않았을 겁니다. 이렇게 분노를 적절히 표출해야 할 때도 있어요. 하지만 갑자기 치밀어 오른 화를 내 마음대로 조절하는 게 누구에게도 쉬운 일은 아닐 거예요.',
                '\n',
                '걱정하지 마세요. 여러분에게는 두 가지 전략이 있습니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              type={'white_dotted'}
              textList={[
                ['(1) 생각 함정에서 빠져나와 불필요한 내면의 화를 잠재우는 방법'],
                ['분노가 끓어올랐을 때 이성을 되찾는 방법 : 잠시 멈추고, 호흡하기'],
              ]}
              my
            />
          ),
        },
        {
          text: '이제 나의 화를 조절함으로써 원하는 것을 얻어내기 위한 방법을 알려드릴 거예요. 잠시 쉬었다가 와도 좋아요. 얼마 전에 선물해드렸던 호흡도구를 한 번 사용하고 오는 것도 좋을 것 같아요. 그러면 이따 만나요.',
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '분노를 부르는 생각 함정',
      subtitle: '우리는 언제 분노를 느낄까요?',
      fullImage: wb4_8_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '사람마다 다양한 상황에서 화가 납니다. 날씨가 더워 짜증이 나는 것부터, 일이 예상처럼 잘 굴러가지 않을 때나 누군가 고의적으로 나에게 피해를 줬을 때와 같이, 우리는 다양한 상황에서 분노를 느끼고 살아갑니다.',
                '\n',
                '여러분은 주로 어떤 상황에서 화가 나는지 선택해 보세요.',
              ]}
            />
          ),
        },
        {
          component: (
            <NavigateSelectButton
              textList={[
                '누군가에게 무시당했을 때',
                '불공평하거나 부당한 일을 당했을 때',
                '내 마음대로 일이 잘되지 않을 때',
              ]}
            />
          ),
        },
      ],
    },
    {
      title: '저도 그럴 때 화가 나기도 해요',
      subtitle: '그런데 다시 한번 생각해 볼까요?',
      image: wb4_8_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '반드시 화가 나야만 하는 상황이 있을까요? 우리는 주로 부당한 일을 당했을 때 화가 납니다. 하지만 ',
                '사람들은 모두 자신의 입장에서 생각했을 때 공정한 행동을 하기 때문',
                '에, 나의 입장에서 상대방의 행동이 마음에 들지 않는다고 해서 그것을 온전히 부당한 행위라고 하기는 어렵습니다. 나중에 감정이 진정되고 그때의 생각을 회고하면 ',
                '실제로 나의 입장에서 보았을 때 부당한 것이었던 경우',
                '가 많죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '결국 분노 또한 생각이 만들어내요',
      image: wb4_8_3,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '결국 돌고 돌아서 다시 핵심으로 돌아왔어요. ',
                '감정은 생각이 만들어낸다',
                '는 것이에요. ',
                '\n',
                '우리에게 분노를 불러일으키는 가장 흔한 생각 함정을 소개해드릴게요. 저의 진료실에 왔던 30대 가정주부 베스의 감정 노트를 보며 그녀는 어떤 생각 함정에 빠졌던 것인지 함께 보겠습니다. 여러분은 이미 생각 함정에 대해 알고 있으실 테니 퀴즈를 한번 풀어보세요',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="베스는 어떤 생각 함정에 빠졌던 걸까요?"
          buttonList={['좁은 시야', '추측', '꼬리표 붙이기', '감정으로 판단하기']}
          contents={[
            {
              component: (
                <div>
                  <p>
                    일요일 아침, 베스는 남편에게 함께 장을 보러 가자고 제안했습니다. 베스의 남편은 그 날 오전에 있을
                    야구 중계를 손꼽아 기다리던 중이었어요. 그래서 거절을 했습니다.
                  </p>
                  <MessageBlockWithTail
                    textList={[
                      [
                        '화가 난다. 남편은 너무 이기적이야. 자기가 하고 싶은 것만 하고, ',
                        '분명히 날 사랑하지 않는 게 확실해.',
                      ],
                    ]}
                    type={'white'}
                    isQuiz
                    margin="my-[30px]"
                  />
                  <p>
                    남편이 자신을 사랑하지 않는다고 생각하니 더욱 화가 난 베스는 남편에게 소리를 지르고 집을
                    나가버렸어요.
                  </p>
                </div>
              ),
            },
          ]}
          answer="추측"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="근거 없이 제멋대로 해석했어요"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '베스는 남편이 자신의 제안을 거절했다는 것만으로 자신을 사랑하지 않는다고 멋대로 해석하고서는 분노와 좌절감을 이기지 못하고 화를 터뜨렸어요. 특별한 ',
                "근거 없이 상황을 해석해 부정적인 생각을 유발하는 '추측'",
                '이라는 생각 함정에 빠져버렸습니다.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="베스는 어떤 생각 함정에
                  빠졌던 걸까요?"
          buttonList={['성급한 결론', '추측', '꼬리표 붙이기', '좋은 일 회피하기']}
          contents={[
            {
              component: (
                <div>
                  <p>그날 이후로 베스는 남편을 계속 “이기적인 인간"으로 생각하기 시작했어요.</p>
                  <MessageBlockWithTail
                    textList={[['남편은 이기적인 인간이야. ']]}
                    type={'white'}
                    margin="my-[30px]"
                    isQuiz
                  />
                  <p>이렇게 생각하니 남편이 무슨 말을 해도 베스에게는 전부 부정적으로 들리기 시작했습니다.</p>
                </div>
              ),
            },
          ]}
          answer="꼬리표 붙이기"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="나쁜 꼬리표를 붙여버렸어요"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '베스가 남편에게 “이기적인 인간"이라는 꼬리표를 붙인 뒤부터는 남편의 안 좋은 점만 보이기 시작했어요. 자기 일에 열정적이고 의욕적인 남편의 모습이 너무나 좋았던 베스였는데, 이때부터는 남편이 일을 하느라 집에 늦게 들어오는 모습만 보이기 시작했습니다. 그렇게 공격적인 태도로 돌변한 베스의 모습에 남편 또한 기분이 상해 점차 둘의 사이는 멀어지기 시작했어요.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="베스는 어떤 생각 함정에
                  빠졌던 걸까요?"
          buttonList={['최악의 시나리오 떠올리기', '과도한 책임감', '일반화', '좁은 시야']}
          contents={[
            {
              component: (
                <div>
                  <p>
                    이제 베스의 눈에는 남편의 성실하고 추진력있는 모습은 전혀 보이지 않고, 집에 늦게 오는 것만
                    보였습니다.
                  </p>

                  <MessageBlockWithTail
                    textList={[['이거 봐. 맨날 늦게 들어오잖아. 어느 하나 마음에 드는 점이 없어.']]}
                    type={'white'}
                    margin="mt-[30px]"
                    isQuiz
                  />
                </div>
              ),
            },
          ]}
          answer="좁은 시야"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="부정적인 면만 보고 있어요"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '사실 남편은 최근에 회사에 중요한 프로젝트를 맡게 되어 야근이 잦을 거라는 이야기를 했었습니다. 일을 하느라 집에 늦게 오는 것은 어쩔 수 없는 일인데도, 베스는 남편이 집에 늦게 온다는 사실만 보였습니다. ',
                '좁은 시야',
                '라는 생각 함정에 빠진거예요. 사실 남편은 프로젝트를 성공적으로 마치고 베스와의 근사한 저녁 식사를 할 준비를 하고 있었거든요.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      title: '베스의 마음 속에 있던 규칙',
      image: wb4_6_3,
      bg: '#2C4BEC',
      texts: [
        {
          text: '결국 겉잡을 수 없이 분노가 커진 베스는 저를 찾아왔습니다. 베스와의 대화에서 알게 된 베스의 마음 속의 규칙은 이것이었어요.',
        },
        {
          component: (
            <div className="my-[40px] px-[23px]">
              <MessageBlockWithQuote
                type={'light_blue'}
                textList={['나는 사랑받아야만 행복할 수 있어']}
                margin="mb-[10px]"
              />
              <MessageBlockWithQuote type={'light_blue'} textList={['사랑한다는 것은 언제나 함께 한다는 거야']} />
            </div>
          ),
        },
        {
          text: "완벽한 사람은 없기 때문에 우리의 비현실적인 '해야 한다'식 사고에 따른 기대감은 언제나 좌절될 수밖에 없습니다. 결국 베스는 저와 함께 노트를 기록하며 마음 속에 굳게 자리잡은 이 규칙을 다시 쓰게 되었고, 왜곡된 분노를 잠재울 수 있었어요.",
        },
      ],
      buttonText: ' ',
    },
    {
      title: '베스에게 알려준 방법',
      image: wb4_8_4,
      bg: '#B6D0E3',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '좋아요. 그럼 베스의 노트를 함께 보면서 그녀가 어떻게 분노를 불러일으키는 왜곡된 생각과 마음속에 굳어진 규칙을 다시 쓰게 됐는지 함께 배워 봅시다.',
                '\n',
                '마지막 시간이 될 거예요. 진료실 서가에서 베스의 노트를 가져와야 하니 잠시만 앉아 계시겠어요? 금방 올게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '분노에서 자유로워지기',
      subtitle: '베스의 노트를 가져왔어요',
      fullImage: wb4_9_1,
      height: 253,
      texts: [
        {
          text: '우선 베스에게 감정 노트를 선물해주기 전에 베스가 원하는 것이 무엇인지 물어보았어요.',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['black', 'gray']}
              texts={[
                [
                  '저는 남편과 지금처럼 헐뜯고 서로를 상처입히는 관계가 아닌 예전처럼 서로를 보듬고 행복했던 관계로 돌아가고 싶어요.',
                ],
                ['좋아요. 지금의 상황에서 베스가 할 수 있는 일이 무엇인지 함께 찾아 봅시다.'],
              ]}
              margin="mt-[40px]"
              opposite
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '감정의 장단점',
      subtitle: '우선 분노의 장단점을 분석해 보았어요',
      fullImage: wb4_9_2,
      height: 190,
      texts: [
        {
          text: '베스와 저는 생각을 전환하는 상담 기법 중 하나인 장단점 분석해보기를 적용하여 화를 내는 것의 장단점을 분석해 보기로 했어요. 장단점을 적을 때는 다음의 것을 고려해 적어 보면 좋습니다.',
        },
        {
          component: (
            <DottedmessageWithBold
              texts={[
                ['단기적인 결과와 장기적인 결과 모두를 고려해 본다.'],
                ['변화하고 싶은 나의 규칙이나 특정 행동에 대해 적용해 볼 수 있다.'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '베스가 작성한 분노의 장단점 비교 ',
      texts: [
        {
          component: (
            <div className="my-[40px]">
              <div
                className={`font-normal text-[17px] leading-[28px] relative text-black flex rounded-[16px] mx-[25px] px-[12px] pt-[13px] pb-[15px]`}
                style={{ border: '1px dashed #A4A6B0' }}
              >
                <div>
                  <p className="font-bold mx-[8px]">장점</p>
                  <TextWithDot
                    texts={[
                      '속이 시원하다.',
                      '내가 화가 났다는 것을 알릴 수 있다.',
                      '내가 쉽게 볼 만 한 상대가 아니란 것을 알릴 수 있다.',
                      '남편도 나와 똑같이 상처 받게 하면 태도가 변할 것 같다.',
                    ]}
                    margin="ml-0 mt-[6px]"
                  />
                </div>
              </div>
              <div
                className={`font-normal text-[17px] leading-[28px] relative text-black flex rounded-[16px] mx-[25px] px-[12px] pt-[13px] pb-[15px] mt-[10px]`}
                style={{ border: '1px dashed #A4A6B0' }}
              >
                <div>
                  <p className="font-bold mx-[8px]">단점</p>
                  <TextWithDot
                    texts={[
                      '잠깐 속이 시원할지는 몰라도 결국 남편과 사이가 더 멀어질 것이다.',
                      '화를 내고 나면 부끄럽고 후회가 된다.',
                      '남편도 나에게 화를 낼 것이다.',
                      '그러다 보면 점점 더 짜증이 많아지고 내 기분은 더 안 좋아질 것이다. ',
                    ]}
                    margin="ml-0 mt-[6px]"
                  />
                </div>
              </div>
            </div>
          ),
        },
        {
          text: '이렇게 분노의 장단점을 써본 베스는 어떤 생각을 했을까요?',
        },
        {
          component: (
            <NavigateSelectButton
              textList={[
                '속 시원하게 계속 화를 내겠어요',
                '무작정 화내는 것보다 더 좋은 방법이 있다면 그것을 선택할게요',
              ]}
            />
          ),
        },
      ],
    },
    {
      title: '베스는 변하기로 결심했어요',
      image: wb4_9_3,
      texts: [
        {
          text: '베스는 그러한 행위가  자신에게 결과적으로 좋지 않다고 결론을 내렸습니다. 그리고 변화를 결심했어요. 베스에게 저는 두 가지 방법을 적용해보도록 알려주었습니다.',
        },
        {
          component: (
            <div className="my-[40px]">
              <div
                className={`font-normal text-[17px] leading-[28px] relative text-black flex rounded-[16px] mx-[25px] px-[12px] pt-[13px] pb-[15px]`}
                style={{ border: '1px dashed #A4A6B0' }}
              >
                <div>
                  <p className="font-bold mx-[8px]">(1) 노트를 활용하는 방법</p>
                  <TextWithDot
                    texts={[
                      '화가 날 때 감정 노트에 격양된 생각, 화가 난 정도와 느낌을 기록하기',
                      '이후 회고 노트에서 화가 났을 때의 생각 함정을 찾아 이성적인 생각으로 바꿔보기',
                      '감정 변화를 객관적으로 비교해보기',
                    ]}
                    margin="ml-0 mt-[6px]"
                  />
                </div>
              </div>
              <div
                className={`mt-[15px] font-normal text-[17px] leading-[28px] relative text-black flex rounded-[16px] mx-[25px] px-[12px] pt-[13px] pb-[15px]`}
                style={{ border: '1px dashed #A4A6B0' }}
              >
                <div>
                  <p className="font-bold mx-[8px]">(2) 호흡하기</p>
                  <TextWithDot
                    texts={['분노가 치밀어 올라 충동적인 행동이 나오기 전에, 90초 잠시 멈추고 호흡해보기']}
                    margin="ml-0 mt-[6px]"
                  />
                </div>
              </div>
            </div>
          ),
        },
        {
          text: '베스는 변화할 수 있을지 의심이 들었지만, 일단 화가 날 때마다 노트에 적어보고, 호흡하기 방법을 잘 적용해보기로 했어요. 그럼 베스의 노트의 일부분을 함께 볼까요?',
        },
      ],
      buttonText: ' ',
    },
    {
      title: '베스의 감정기록이에요',
      texts: [
        {
          component: (
            <div
              className={`border-b-1 w-full relative overflow-hidden rounded-2xl mt-[30px] flex justify-center align-center`}
              style={{ backgroundColor: '#E1E4EB' }}
            >
              <div className={`max-w-[300px] mx-[17px] overflow-hidden mt-[0.5px]`}>
                <img className="w-full h-full object-contain" src={wb4_9_7} alt="empty" />
              </div>
            </div>
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '규칙을 다시 쓰기',
      image: wb4_6_3,
      bg: '#2C4BEC',
      texts: [
        {
          text: '이 과정을 2주간 반복한 베스는 다시 저의 진료실을 찾아왔어요. 그리고 자신이 세웠던 비합리적인 규칙 대신 새로운 규칙을 세웠습니다.',
        },
        {
          component: (
            <div className="mt-[40px] px-[23px]">
              <MessageBlockWithQuote type={'gray'} textList={['나는 사랑받아야만 행복할 수 있어']} margin="mb-[10px]" />
              <MessageBlockWithQuote
                type={'gray'}
                textList={['사랑한다는 것은 언제나 함께 한다는 거야']}
                margin="mb-[10px]"
              />
              <MessageBlockWithQuote
                type={'light_blue'}
                textList={['사랑받으면 행복하겠지만, 남편의 사랑으로 나의 가치가 결정되는 건 아니야.']}
                margin="mb-[10px]"
              />
              <MessageBlockWithQuote
                type={'light_blue'}
                textList={[
                  '사랑하는 사람과 함께 항상 함께 할 수 있다면 좋겠지만, 항상 함께 한다는 것은 불가능해. 함께 하지 않는다고 남편이 나를 미워하는 건 아니야',
                ]}
              />
            </div>
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '분노가 강렬해질 때는 감정 노트를 살펴보세요',
      image: wb4_9_4,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이전의 감정 노트를 되돌아보면, “내가 이때는 왜 그렇게 과잉 반응했었지?” 혹은 “왜 이렇게까지 감정이 상했었을까?”라고 생각하며 ',
                '제 3자의 입장에서 객관적으로 상황을 볼 수 있게 됩니다.',
                ' 마치 영화를 보는 것처럼 말이에요. 그렇게 우리의 분노를 객관적으로 이해하고 왜곡된 생각을 찾아낼 수 있을 거예요.',
                '\n',
                '그런데 만약 내면의 생각 함정을 되돌아보기도 전에 불이 붙어버린 감정에는 어떻게 해야 할까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '90초만 심호흡해 보세요',
      image: wb4_9_5,
      bg: '#FEE9E3',
      texts: [
        {
          component: (
            <MessageBlock
              type={'black'}
              textList={[
                ['홧김에 먹어버렸어'],
                ['홧김에 자리를 박차고 나와버렸어'],
                ['홧김에 쇼핑으로 스트레스를 풀었어'],
              ]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '우리는 홧김에 무엇을 했다는 말을 많이 합니다. 이는 화가 났을 때 우리가 자제력을 잃는다는 사실을 떠올리면 자연스러운 말이에요. 하지만 ',
                '화가 났을 때 했던 후회할 만한 말이나 행동은 실제로 내가 원하는 것이 아닙니다.',
                ' 우리의 마음 회로에서 분노가 작동했을 때, ',
                '이 극단적인 한순간만 잠시 멈추어보는 게 중요합니다.',
                ' 분노에 휘둘려 후회할만한 실수를 저지르지 말고, 내가 선택하여 분노를 얼마나 표출할지 결정해 보는 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '감정에 주도적으로 대응해야 해요',
      image: wb4_9_6,
      bg: '#86A1E8',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '물론 내 감정을 이해하고 이에 주도적으로 대응하는 건 쉬운 일이 아닙니다. 특히 분노와 같은 감정이 발생했을 때에는 순간적으로 감정에 휩싸이기 쉽기 때문에 더욱이 이성적으로 대응하기 어렵답니다. 그러니 화가 치밀어 오를 때면 ',
                '호흡 타이머를 이용해 잠시 멈춰보는 거예요.',
                ' 90초만 지나고 나서 반응해도 늦지 않답니다. 그리곤 찬찬히 ',
                '감정들을 기록해 보세요.',
                ' 시간이 지나 ',
                '기록들을 살펴보면 화가 났던 순간에는 알지 못했던 생각 함정들을 발견',
                '할 수 있을 거랍니다.',
                '\n',
                '분노에 대한 이야기는 여기까지 할게요. 오늘도 참 고생 많았어요. 제가 알려준 이야기들 잊지 말고요. 궁금한 게 있다면 언제든 질문해도 좋아요. 아마 인사이드 코치나 상담사가 많은 도움이 될 거예요. 그러면 다음에 또 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: ' ',
    },
  ],
  [
    {
      title: '부정적인 감정 다루기를 마치며',
      fullImage: wb4_10_1,
      height: 253,
      texts: [
        {
          text: [
            '여기까지 왔다면, 여러분은 감정 노트와 회고 노트를 전보다 더 잘 활용해 감정과 생각을 되돌아보는 기반을 마련한 겁니다. 부정적인 감정이 느껴질 때면 언제든 감정 노트를 꺼내 적어 보세요.',
            <br />,
            <br />,
            '노트 기록을 지속하다보면 나의 생각과 행동 패턴들이 보이기 시작할 거예요. 이를 통해 나를 힘들게 하던 압도적인 감정의 원인을 파악하고, 내가 정말 원하는 것이 무엇인지 깨달을 수 있어요. 따라서 감정을 내가 원하는 것을 얻을 수 있는 좋은 신호로 사용할 수 있게 됩니다. ',
          ],
        },
      ],
      buttonText: ' ',
    },
    {
      title: '이번 코스를 요약해드릴게요',
      image: wb4_10_2,
      bg: '#B6D0E3',
      texts: [
        {
          component: (
            <div className="w-full rounded-[16px] bg-blue bg-[#F3F5F8] p-[20px] mt-[40px]">
              <p className={`flex`}>
                <span className="mr-[8px]">•</span>
                <span>감정 노트와 회고 노트를 기록하며 부정적인 감정에 숨겨진 생각 함정을 찾아 보세요.</span>
              </p>
              <p className={`flex`}>
                <span className="mr-[8px]">•</span>
                <span>
                  나를 옭아맸던 엄격한 규칙에 이렇게 말대꾸해 보세요. <br />
                  <div>
                    <span className="bg-[#FFEB3564] pb-[1.5px]">“그렇게 한다면 좋겠지만 꼭 해야만 하는 건 아니야"</span>
                  </div>
                </span>
              </p>
              <p className={`flex`}>
                <span className="mr-[8px]">•</span>
                <span>압도적인 감정이 몰려 왔을 때는 90초만 깊게 호흡해 보세요.</span>
              </p>
              <p className={`flex`}>
                <span className="mr-[8px]">•</span>
                <span>
                  누군가의 도움이 필요하다면, 망설이지 말고 인사이드의 코치나 심리 전문가 선생님들께 도움을 요청해
                  보세요.
                </span>
              </p>
            </div>
          ),
        },
      ],
      buttonText: ' ',
    },
    {
      title: '누구나 비슷하답니다',
      image: wb4_10_3,
      bg: '#EFAD4B',
      texts: [
        {
          text: [
            '슬픔, 죄책감, 분노에 휩싸여 힘든 나날들을 보내고 계신가요? 모두 아닌 척은 해도 사실 모두가 비슷한 생각과 감정들을 느끼고 지낸답니다. 저 또한 마찬가지죠. 제가 잠시 연수를 떠났을 때 제가 키우던 고양이가 몸이 안 좋아졌던 적이 있어요. 자칫하면 영영 이별할 뻔 했었답니다. 당시엔 얼마나 큰 죄책감에 빠졌는지 몰라요. 그러니 혼자라고 생각하지 말고 부정적인 감정을 잘 다스리려고 노력해 보았으면 좋겠어요. 또 힘들면 저를 찾아오셔도 된답니다.',
          ],
        },
      ],
      buttonText: ' ',
    },
    {
      title: '이만 여기서 인사할까요?',
      texts: [
        {
          text: [
            '벌써 끝이라 아쉬운 마음이에요. 며칠 이야기한 것 같지도 않은데 말이에요. 그래도 어쩌겠어요. 모든 만남이 항상 지속될 수는 없으니까요. 분명 또 제가 도와드릴 일들이 있으리라 생각해요. 물론 모든 근심과 걱정이 사라져서 저를 만나지 않는 게 가장 좋겠지만요. 그렇게 말하니 갑자기 무언가 쓸쓸한 걸요. 근심과 걱정이 사라져도 가끔씩은 찾아오셔요.',
            <br />,
            <br />,
            '자, 그럼 우리는 또 다음에 만나요. 덕분에 즐거운 나날들이었어요.',
          ],
        },
      ],
      buttonText: ' ',
    },
  ],
];

export default course4Data;
