import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export function getTitleDuration(title) {
  // console.log(title, Math.max(title.length * 100, 2000));
  return Math.max(title.length * 100, 2000);
}

export function TextFadeTransition({ textList, currentTextId, height, fontSize, lineHeight, fontColor, page }) {
  return (
    <AnimatePresence exitBeforeEnter>
      {textList.map(
        (each, index) =>
          each.id === currentTextId && (
            <motion.div
              key={`${each.id}${page}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="font-bold  text-center"
              style={{
                height: height || '52px',
                fontSize: fontSize || '20px',
                lineHeight: lineHeight || '26px',
                color: fontColor || '#ffffff',
              }}
            >
              {each.title}
            </motion.div>
          ),
      )}
    </AnimatePresence>
  );
}
