import React, { useContext } from 'react';
import { AuthContext } from '../../context';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import CurrentExam from './CurrentExam';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function MiddleTemplate2(props) {
  const { id } = props;

  const context = useContext(AuthContext);

  const startTest = useNavigateWithParams(`/onboarding2/${id}/1`);
  function goNext() {
    startTest();
  }
  const Element = (
    <div className="h-full  flex flex-col ">
      <div className={`mt-[15px] px-[20px] font-normal text-[17px] leading-[23px] text-[#4E4F53] `}>
        종합검진 결과는 모든 검사를 마친 뒤 제공돼요.
      </div>
      <div className="flex-1  flex flex-col justify-center pt-[30px] pb-[40px] px-[20px]">
        <div className="w-full translate-y-[5px]">
          <CurrentExam id={id} />
        </div>
      </div>
    </div>
  );
  return (
    <div className={`h-full `}>
      <TopTitleBottomAction
        title={`${context.selfcheckQuestion[id].selfCheckName} 검사를 진행해요`}
        content={Element}
        buttonText={'시작하기'}
        buttonState={'ACTIVE'}
        action={goNext}
        topColor={'#FFFFFF'}
      />
    </div>
  );
}
