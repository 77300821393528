import SelectWakeUp from '../pages/marketing/sleepDoctor/SelectWakeUp';
import SelectSleeping from '../pages/marketing/sleepDoctor/SelectSleeping';
import SelectInBed from '../pages/marketing/sleepDoctor/SelectInBed';
import SurveyAnalysis from '../pages/marketing/sleepDoctor/SurveyAnalysis';

const sleepingSurveyData = [
  <SelectWakeUp type="wakeup" />,
  <SelectInBed type="inBedStart" />,
  <SelectSleeping type="sleepStart" />,
  <SurveyAnalysis title="회원님께 맞는 시간을 분석하고 있어요." />,
];

export default sleepingSurveyData;
