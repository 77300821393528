/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TopTitleBottomActionInside } from '../../stories/page/TopTitleBottomActionInside';
import { useEffect } from 'react';
import headerAtom, { BUTTON_NONE } from '../../recoil/common/header/atom';
import { useSetRecoilState } from 'recoil';
import ClipLoader from 'react-spinners/ClipLoader';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function Submit({ resultList }) {
  const { id } = useParams();
  const [isLoad, setIsLoad] = useState(false);

  const setMixPanel = useMixpanelWeb({
    eventName: '9_번호 제출',
  });

  const setHeaderState = useSetRecoilState(headerAtom);

  const root = document.getElementById('root');
  root.style.position = 'fixed';
  root.style.overflow = 'hidden';

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE, headerColor: '#FFFFFF', headerTitle: '' }));
  }, [id]);

  useEffect(() => {
    setMixPanel();
    setTimeout(() => {
      setIsLoad(true);
    }, 1500);
  }, []);

  return (
    <div
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      {isLoad ? (
        <TopTitleBottomActionInside
          title={'접속이 많아 순서대로 처리하고 있어요'}
          subtitle={'조금만 기다려주세요. 순서대로 문자를 보내드리고 있습니다.'}
          buttonText={'닫기'}
          buttonState={'ACTIVE'}
          bgColor="#000000"
          action={() => {
            window.close();
          }}
          insideBlack
        />
      ) : (
        <div className="h-full flex justify-center items-center">
          <ClipLoader color="#FFFFFF80" loading={true} size={30} />
        </div>
      )}
    </div>
  );
}
