import workbookMessageColorData from '../../data/workbookMessageColorData';
import PropTypes from 'prop-types';

export default function MessageBlock({ textList, titlemb, type, my, margin }) {
  const colorInfo = workbookMessageColorData.find((element) => element.type === type);
  return (
    <div className={`${my ? 'my-[40px]' : margin ? margin : ''} `}>
      {textList.map((texts, index) => (
        <div
          key={`messageBlock${index}`}
          className={` text-[17px] leading-[24px] flex items-center rounded-[16px] mx-[25px] px-[20px] pt-[12px] pb-[14px] ${
            index !== textList.length - 1 ? 'mb-[10px]' : ''
          }`}
          style={{ backgroundColor: colorInfo.backgroundColor, color: colorInfo.color, border: colorInfo.border }}
        >
          <div>
            {texts.title && <div className={`font-bold ${titlemb ? titlemb : 'mb-[10px]'}`}>{texts.title}</div>}
            {texts.text
              ? texts.text.map((text, index) =>
                  index % 2 === 0 ? (
                    <span key={`messageBlockText${index}`}>{text}</span>
                  ) : (
                    <span key={`messageBlockText${index}`} className={`bg-[#FFEB3564]`}>
                      {text}
                    </span>
                  ),
                )
              : texts.map((text, index) =>
                  index % 2 === 0 ? (
                    <span key={`messageBlockText${index}`}>{text}</span>
                  ) : (
                    <span key={`messageBlockText${index}`} className={`bg-[#FFEB3564] `}>
                      {text}
                    </span>
                  ),
                )}
          </div>
        </div>
      ))}
    </div>
  );
}

MessageBlock.propTypes = {
  type: PropTypes.oneOf(['black', 'gray', 'white', 'light_blue', 'white_dotted', 'blue_border']),
};
