import { useState } from 'react';
import { useRecoilState } from 'recoil';
import webviewToast from '../../../development/webviewToast';
import worryNoteAtom from '../../../recoil/anxy/worryNote/atom';
import { AnxyInput } from '../common/AnxyInput';
import { TextArea } from '../../../stories/text/TextArea';

const InputWithAddButton = ({ index, name, title, placeholder, isEntered, autofocus, backgroundColor }) => {
  const [worryNoteState, setWorryNoteState] = useRecoilState(worryNoteAtom);
  const [focus, setFocus] = useState(false);

  const addList = () => {
    setWorryNoteState((state) => {
      const updated = { ...state };
      const newArray = [...updated[name]];
      newArray.push('');
      updated[name] = newArray;
      return updated;
    });
  };

  const updateList = (value, index, isDelete) => {
    setWorryNoteState((state) => {
      const updated = { ...state };
      const newArray = [...updated[name]];
      if (isDelete) {
        newArray.splice(index, 1);
      } else {
        newArray[index] = value;
      }
      updated[name] = newArray;
      return updated;
    });
  };

  return (
    <div className="text-white">
      <p className="text-[17px] leading-[23px] font-bold mb-[13px]">{title}</p>
      {worryNoteState[name].length !== 0 &&
        worryNoteState[name].map((item, index) => (
          <AnxyInput
            key={`input${index}`}
            index={index}
            placeholder={placeholder}
            value={item}
            updateValue={updateList}
            forceBlur={true}
            autofocus={autofocus}
            isEntered={isEntered}
            setFocus={setFocus}
          />
        ))}

      <div className="w-full flex justify-center">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={addList}
        >
          <circle cx="12.5" cy="12" r="12" fill="#26282C" />
          <path d="M7.0459 12.0001H17.955" stroke="white" strokeWidth="2" strokeLinecap="round" />
          <path d="M12.501 6.54559L12.501 17.4547" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div>
    </div>
  );
};

export default InputWithAddButton;
