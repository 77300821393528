import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AnxyActionButton } from '../../../stories/button/ActionButton';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { BUILD_TARGET } from '../../../config';
import SomnyPaymentMain from './SomnyPaymentMain';
import ContainerByDeviceAnxy from '../../../components/elements/ContainerByDeviceAnxy';
import effective from '../../../image/somny/payment/effective.png';
import partner from '../../../image/somny/payment/partner.png';
import snowTitle from '../../../image/somny/payment/snowTitle.png';
import main5 from '../../../image/somny/payment/main5.png';

import { updateNotch } from '../../../utils/webview';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import { motion } from 'framer-motion';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import deviceAtom from '../../../recoil/common/device';
import headerAtom, { BUTTON_BACK, BUTTON_CLOSE } from '../../../recoil/common/header/atom';
import { SomnyActionButton } from '../../../stories/button/RoundActionButton';
import useMixpanelEventSomny from '../../../hooks/useMixpanelEventSomny';
import AccordianItemWithoutBorder from '../../../stories/text/AccordianItemWithoutBorder';
import useRequestPayment from 'hooks/useRequestPayment';
import SomnyPaymentTrialSection from './SomnyPaymentTrialSection';

function Title(props) {
  const { text, white, css } = props;
  return (
    <p className={`text-[30px] leading-[36px] font-bold ${white ? 'text-white' : 'text-[#26282C]'} ${css}`}>{text}</p>
  );
}

const Container = forwardRef((props, ref) => {
  const { children, bgColor, css } = props;

  return (
    <div
      ref={ref}
      className={`relative z-[10] w-full pt-[60px] pb-[40px] px-[20px] flex flex-col justify-center justify-center  text-center ${css}`}
      style={{ backgroundColor: bgColor }}
    >
      {children}
    </div>
  );
});
export default function SomnyPayment() {
  const containerRefs = useRef([]);
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('at');
  const appId = searchParams.get('app_id');
  const isDev = BUILD_TARGET === 'dev';
  const [appNotch, setAppNotch] = useState(false);

  const [showButton, setShowButton] = useState(false);

  const [invoiceData, requestPayment] = useRequestPayment({
    fetchInvoiceDetailUrl: `/somny/payment/invoice/detail`,
    successUrlPath: `somnyPayment-success?at=${accessToken}&app_id=${appId}`,
    failUrlPath: `somnyHome?at=${accessToken}&app_id=${appId}`,
  });

  const setMixPanel = useMixpanelEventSomny({
    eventName: '결제 안내',
  });

  const deviceState = useRecoilValue(deviceAtom);
  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    setHeaderState({ headerButtonType: BUTTON_CLOSE, headerColor: '#FFFFFF', headerTitle: '' });
    setMixPanel();

    // return clearInterval(keepFullScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return updateNotch({ isFullScreen: true });
  }, []);

  useEffect(() => {
    if (!appNotch) {
      updateNotch({ isFullScreen: true });
    } else {
      updateNotch({ isFullScreen: false });

      setTimeout(() => {
        if (invoiceData) {
          requestPayment();
          setTimeout(() => {
            setAppNotch(false);
            updateNotch({ isFullScreen: true });
          }, 1500);
        }
      }, 500);
    }
  }, [appNotch]);

  return (
    <ContainerByDeviceAnxy bgColor="#050C26" initialScrollTo={0} noHeader appNotch={appNotch}>
      <motion.div className={'w-full fixed bottom-0   z-[20]'}>
        <div
          className="w-full h-[40px]"
          style={{ background: 'linear-gradient(to top, #121B40, rgba(255,255,255,0))' }}
        />
        <div className={`px-[20px] bg-white ${deviceState.hasHomeIndicator ? 'pb-[34px]' : 'pb-[20px]'} `}>
          <AnxyActionButton
            state={!appNotch ? 'ACTIVE' : 'LOADING'}
            text={'결제하기'}
            action={() => {
              setAppNotch(true);
            }}
          />
        </div>
      </motion.div>
      {/* <SomnyPaymentTrialSection /> */}
      <div
        ref={(element) => {
          containerRefs.current[0] = element;
        }}
      >
        <SomnyPaymentMain
          requestPayment={requestPayment}
          setAppNotch={setAppNotch}
          appNotch={appNotch}
          payAmount={invoiceData && invoiceData.result.payAmount}
        />
      </div>

      <div
        className="w-full flex flex-col relative"
        style={{
          background: 'linear-gradient(180deg, #35388E 0%, #050C26 55.21%)',
        }}
      >
        <div className="w-full h-[241px] mx-auto absolute top-0 left-[50%] translate-x-[-50%] flex justify-center">
          <img src={snowTitle} alt="empty" className="" />
        </div>
        <div className="text-white text-center pt-[60px] pb-[40px]">
          <p className="text-[30px] leading-[36px] pb-[8px] font-bold z-[90]">수면 파트너 Somny</p>
          <p className="text-[17px] leading-[23px] opacity-[0.6]">
            {'밤 사이 내 곁을 지켜주는 든든한 파트너예요 \n 내가 잘 잠드는지 관리하고 케어해줄 거예요'}
          </p>
        </div>
        <div className="max-w-[375px] mx-auto pb-[100px]">
          <img src={partner} alt="empty" className="w-[250px]" />
        </div>
      </div>
      <Container
        css="pt-[60px] pb-[108px]"
        bgColor={'#121B40'}
        ref={(element) => {
          containerRefs.current[2] = element;
        }}
      >
        <Title white text={'입증된 효과'} />
        <p className="text-[17px] leading-[23px] opacity-[0.6] pt-[8px] text-white">
          과학에 근거한 개선 프로그램으로 포근한 밤을 되찾으세요{' '}
        </p>
        <div className="flex flex-col items-center gap-[50px] pt-[50px] pb-[50px] px-[20px]">
          <img src={effective} alt="empty" className="w-[335px]" />
        </div>
        <div className="max-w-[375px] mx-auto">
          <img src={main5} alt="empty" className="w-[355px]" />
        </div>
        <div className="pb-[10px]">
          <div className="w-full h-[1px] bg-white opacity-[0.1] mt-[50px]" />
          <AccordianItemWithoutBorder
            title={'취소 및 환불 규정'}
            fontColor="#FFFFFF"
            content={
              <div className="flex flex-col gap-[8px] pt-[25px] ">
                {[
                  'Somny 수면 관리 프로그램은 4주 분량의 교육 프로그램과 매일 진행할 수 있는 기록, 수면 동화로 구성되어있어요.',
                  '결제 후에는 교육 프로그램과 수면 기록, 수면 동화를 모두 진행할 수 있어요. 교육 프로그램과 수면 동화는 수면 기록의 진도에 따라 점차 이용할 수 있게 돼요.',
                  '결제 후 유료 기능을 한 번이라도 진행하게 되면 서비스에 대한 환불은 불가능해요. 단, 아무 기능도 이용하지 않았을 시에는 고객센터의 확인을 통해 환불해 드릴 수 있어요.',
                  '서버 문제, 개발 오류 등 회사의 사정으로 인해 일주일을  초과하는 기간 동안 서비스가 원활하게 전개되지 않는 경우에는, 문제 발생 21일 전부터 문제 발생 시점까지 진행된 결제 건에 대해 모두 환불해드려요.',
                ].map((each, index) => (
                  <div className="flex gap-[9px]" key={`취소및환불규정${index}`}>
                    <div className="w-[3px] h-[3px] rounded-[50%] bg-[#6B6D76] shrink-0 mt-[10px]" />
                    <div className="text-[15px] leading-[21px] text-[#6B6D76] text-left">{each}</div>
                  </div>
                ))}
              </div>
            }
          />
        </div>
      </Container>
      <div className="fixed bottom-0 w-full px-[20px]" style={{}}></div>
      <div className="w-full fixed bottom-0 z-[70]">
        <div
          className={`flex-none w-full relative bg-[#121B40]`}
          style={{
            height: deviceState.hasHomeIndicator ? 88 : 74,
          }}
        >
          <div
            className="w-full h-[30px] absolute top-[-28px]"
            style={{
              background: `linear-gradient(to top, #121B40, rgba(18,26,64,0))`,
            }}
          />
          <div className="px-[20px]">
            <SomnyActionButton
              state={!appNotch ? 'ACTIVE' : 'LOADING'}
              text={'결제하기'}
              action={() => {
                // requestPayment();
                setAppNotch(true);
              }}
            />
          </div>
        </div>
      </div>
    </ContainerByDeviceAnxy>
  );
}
