import React, { useEffect, useContext, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import challengersData from './challengersData';

function ChallengersSurvey() {
  let { id } = useParams();
  const content = challengersData;
  const context = useContext(CoachingContext);
  const contentRef = useRef();

  console.log(content[id - 1]);

  useEffect(() => {
    context.setCurrent(id);
  }, [id]);

  useEffect(() => {
    context.setProgressSum(10);
    if (parseInt(id) < 10) {
      context.setShowProgressingbar(true);
    }

    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';

    document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  return (
    <div
      ref={contentRef}
      className={`w-full h-[calc(100vh-17px)] relative`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      {content[id - 1]}
    </div>
  );
}

export default ChallengersSurvey;
