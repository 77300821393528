/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useNavigateWithParams from '../hooks/useNavigateWithParams';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from '../recoil/common/header/atom';
import useMixpanelWeb from '../hooks/useMixpanelWeb';
import { SubmitPhoneNumber } from '../stories/page/SubmitPhoneNumber';
import { RoundBottomAction } from '../stories/page/RoundBottomAction';
import { CheckLottie } from '../stories/animation/CheckLottie';
import { FullPageAnxy } from '../stories/page/FullPageAnxy';

export default function SubmitInfo() {
  let { id } = useParams();
  id = parseInt(id);

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const anxyWithoutTest = searchParams.get('a') === 'true';

  const L1 = searchParams.get('L1') === 'true';
  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    const body = document.body;
    body.style.position = 'fixed';
    body.style.overflow = 'hidden';
  }, []);

  const goNext = useNavigateWithParams(`../submitInfo/${parseInt(id) + 1}`);

  const dataByType = [
    {
      type: 'anxy',
      textColor: '#26282C',
      highlightColor: '#EF670B',
      bgColor: '#FFFFFF',
      eventNameList: [`(${!anxyWithoutTest ? 'AT' : 'A'}) 가격`, `(${!anxyWithoutTest ? 'AT' : 'A'}) 전화번호 기입`],
    },
    {
      type: 'somny',
      textColor: '#FFFFFF',
      bgColor: '#121B40',
      highlightColor: '#79AEFF',
      eventNameList: ['(S) 가격', '(S) 전화번호 기입'],
    },
    {
      type: 'loomy',
      textColor: '#11383B',
      bgColor: '#F3F0E7',
      eventNameList: [`(${L1 ? 'L1' : 'L'}) 가격`, `(${L1 ? 'L1' : 'L'}) 전화번호 기입`],
    },
  ];

  const currentData = dataByType.find((item) => type === item.type);

  const setMixPanel = useMixpanelWeb({
    eventName: currentData.eventNameList[parseInt(id)],
    projectName: currentData.type,
  });

  const contentListAnxy = [
    {
      title: '지금 Anxy를 만나보세요',
      content: (
        <div className="flex-1  flex flex-col gap-[12px] justify-center items-center text-black">
          <p className="text-[17px] leading-[22px] opacity-[0.6]">4주 간의 불안 코스</p>
          <p
            className="text-[50px] leading-[59.67px] font-bold"
            style={{ color: currentData.highlightColor ? currentData.highlightColor : currentData.textColor }}
          >
            39,800원
          </p>
          <p className="text-[17px] leading-[22px] opacity-[0.6]">1회 결제 / 무제한 이용</p>
        </div>
      ),
      buttonText: '앱 다운로드',
    },
    {
      replaceComponent: (
        <SubmitPhoneNumber isAnxy action={goNext} mixpanelEvent={`(${!anxyWithoutTest ? 'AT' : 'A'}) 신청 완료`} />
      ),
    },
    {
      replaceComponent: (
        <FullPageAnxy
          content={
            <div className="text-[#26282C] w-full h-full flex flex-col items-center justify-center text-center ">
              <div className="mb-[30px]">
                <CheckLottie containerSize="77px" green />
              </div>
              <p className=" font-bold text-[24px] leading-[30px]  mb-[7px] ">{'알림을 신청했어요'}</p>
              <p className="text-[#80838B] font-normal text-[17px] leading-[24px] whiteSpace-pre-line  ">
                {'우리 곧 다시 만나요.'}
              </p>
            </div>
          }
          bgColor={currentData.bgColor}
          fixedRoot
        />
      ),
    },
  ];

  const contentListSomny = [
    {
      title: '지금 Somny를 만나보세요',
      content: (
        <div className="flex-1  flex flex-col gap-[12px] justify-center items-center text-white">
          <p className="text-[17px] leading-[22px]">4주 간의 수면 코스</p>
          <p
            className="text-[50px] leading-[59.67px] font-bold"
            style={{ color: currentData.highlightColor ? currentData.highlightColor : currentData.textColor }}
          >
            39,800원
          </p>
          <p className="text-[17px] leading-[22px]">1회 결제 / 무제한 이용</p>
        </div>
      ),
      buttonText: 'somny 시작하기',
    },
    {
      replaceComponent: <SubmitPhoneNumber isSomny action={goNext} mixpanelEvent="(S) 신청 완료" />,
    },
    {
      replaceComponent: (
        <FullPageAnxy
          content={
            <div className="text-white w-full h-full flex flex-col items-center justify-center text-center ">
              <div className="mb-[30px]">
                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="36" cy="36" r="36" fill="#E1E7FF" />
                  <path
                    d="M22.3919 32.4869C21.5988 31.7182 20.3326 31.738 19.5639 32.5311C18.7951 33.3243 18.8149 34.5905 19.6081 35.3592L22.3919 32.4869ZM32.4286 45L31.0366 46.4361C31.8123 47.188 33.0448 47.188 33.8205 46.4361L32.4286 45ZM52.3919 28.4361C53.1851 27.6674 53.2049 26.4012 52.4361 25.6081C51.6674 24.8149 50.4012 24.7951 49.6081 25.5639L52.3919 28.4361ZM19.6081 35.3592L31.0366 46.4361L33.8205 43.5639L22.3919 32.4869L19.6081 35.3592ZM33.8205 46.4361L52.3919 28.4361L49.6081 25.5639L31.0366 43.5639L33.8205 46.4361Z"
                    fill="#121B40"
                  />
                </svg>
              </div>
              <p className=" font-bold text-[24px] leading-[30px]  mb-[7px] ">{'알림을 신청했어요'}</p>
              <p className="opacity-[0.9] font-normal text-[17px] leading-[24px] whiteSpace-pre-line  ">
                {'우리 곧 다시 만나요.'}
              </p>
            </div>
          }
          bgColor={currentData.bgColor}
          fixedRoot
        />
      ),
    },
  ];

  const contentListLoomy = [
    {
      title: '지금 Loomy와 함께 우울한 마음 날씨를 맑게 만들어봐요',
      content: (
        <div className="flex-1  flex flex-col gap-[12px] justify-center items-center text-black">
          <p className="text-[17px] leading-[22px] opacity-[0.6]">4주 간의 우울 코스</p>
          <p
            className="text-[50px] leading-[59.67px] font-bold"
            style={{ color: currentData.highlightColor ? currentData.highlightColor : currentData.textColor }}
          >
            39,800원
          </p>
          <p className="text-[17px] leading-[22px] opacity-[0.6]">1회 결제 / 무제한 이용</p>
        </div>
      ),
      buttonText: '앱 다운로드',
    },
    {
      replaceComponent: <SubmitPhoneNumber isLoomy action={goNext} mixpanelEvent={`(${L1 ? 'L1' : 'L'}) 신청 완료`} />,
    },
    {
      replaceComponent: (
        <FullPageAnxy
          content={
            <div
              className="w-full h-full flex flex-col items-center justify-center text-center"
              style={{
                color: currentData.textColor,
              }}
            >
              <div className="mb-[30px]">
                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="36" cy="36" r="36" fill="#D9D8B7" />
                  <path
                    d="M22.3919 32.4869C21.5988 31.7182 20.3326 31.738 19.5639 32.5311C18.7951 33.3243 18.8149 34.5905 19.6081 35.3592L22.3919 32.4869ZM32.4286 45L31.0366 46.4361C31.8123 47.188 33.0448 47.188 33.8205 46.4361L32.4286 45ZM52.3919 28.4361C53.1851 27.6674 53.2049 26.4012 52.4361 25.6081C51.6674 24.8149 50.4012 24.7951 49.6081 25.5639L52.3919 28.4361ZM19.6081 35.3592L31.0366 46.4361L33.8205 43.5639L22.3919 32.4869L19.6081 35.3592ZM33.8205 46.4361L52.3919 28.4361L49.6081 25.5639L31.0366 43.5639L33.8205 46.4361Z"
                    fill="#11383B"
                  />
                </svg>
              </div>
              <p className=" font-bold text-[24px] leading-[30px]  mb-[7px] ">{'알림을 신청했어요'}</p>
              <p className="opacity-[0.9] font-normal text-[17px] leading-[24px] whiteSpace-pre-line  ">
                {'우리 곧 다시 만나요.'}
              </p>
            </div>
          }
          bgColor={currentData.bgColor}
          fixedRoot
        />
      ),
    },
  ];

  const contentList = type === 'anxy' ? contentListAnxy : type === 'somny' ? contentListSomny : contentListLoomy;

  useEffect(() => {
    if (contentList.length - 1 === id) {
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_NONE,
        headerColor: currentData.textColor,
        headerTitle: '',
      }));
    } else {
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_BACK,
        headerColor: currentData.textColor,
        headerTitle: '',
      }));
      setMixPanel();
    }
  }, [id]);

  const handleAction = () => {
    if (contentList.length - 1 === id) {
      if (window.android) {
        window.location.href = 'kakaotalk://inappbrowser/close';
        window.location.href = 'instagram://inappbrowser/close';
      } else {
        window.location.href = 'kakaoweb://closeBrowser';
        window.location.href = 'instagram://closeBrowser';
        window.location.href = 'instagramweb://closeBrowser';
        window.location.href = 'instaweb://closeBrowser';
      }
      // window.close();
    } else {
      goNext();
    }
  };

  return (
    <div
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      {contentList[id].replaceComponent ? (
        contentList[id].replaceComponent
      ) : (
        <RoundBottomAction
          content={
            <div className="h-full flex flex-col">
              <div className={`text-[${currentData.textColor}] px-[20px] pt-[12px]`}>
                <p className={`text-[24px] leading-[30px] font-bold`}>{contentList[id].title}</p>
                {contentList[id].subtitle && (
                  <p className="font-light opacity-[0.8] text-[17px] leading-[28px] pt-[24px]">
                    {contentList[id].subtitle}
                  </p>
                )}
              </div>
              <div className="flex-1 flex flex-col">{contentList[id].content}</div>
            </div>
          }
          noGradient
          buttonText={contentList[id].buttonText}
          buttonState={'ACTIVE'}
          action={handleAction}
          bgColor={currentData.bgColor}
          fixedRoot
          isAnxy={type === 'anxy'}
          isSomny={type === 'somny'}
          isLoomy={type === 'loomy'}
        />
      )}
    </div>
  );
}
