import wb15_1 from '../image/wb15_1.png';
import wb15_2 from '../image/wb15_2.png';
import wb15_3 from '../image/wb15_3.png';
import wb15_4 from '../image/wb15_4.png';
import wb16_1 from '../image/wb16_1.png';
import wb16_2 from '../image/wb16_2.png';
import wb16_3 from '../image/wb16_3.png';
import wb16_4 from '../image/wb16_4.png';
import wb16_5 from '../image/wb16_5.png';
import wb16_6 from '../image/wb16_6.png';
import wb17_1 from '../image/wb17_1.png';
import wb17_2 from '../image/wb17_2.png';
import wb17_3 from '../image/wb17_3.png';
import wb17_4 from '../image/wb17_4.png';
import wb17_5 from '../image/wb17_5.png';
import wb17_6 from '../image/wb17_6.png';
import wb17_7 from '../image/wb17_7.png';
import wb18_1 from '../image/wb18_1.png';
import wb18_2 from '../image/wb18_2.png';
import wb18_3 from '../image/wb18_3.png';
import wb18_4 from '../image/wb18_4.png';
import wb19_1 from '../image/wb19_1.png';
import wb19_2 from '../image/wb19_2.png';
import wb19_3 from '../image/wb19_3.png';
import wb19_4 from '../image/wb19_4.png';
import wb19_5 from '../image/wb19_5.png';
import wb19_6 from '../image/wb19_6.png';
import wb19_7 from '../image/wb19_7.png';
import wb20_1 from '../image/wb20_1.png';
import wb20_2 from '../image/wb20_2.png';
import wb20_3 from '../image/wb20_3.png';
import wb20_4 from '../image/wb20_4.png';
import wb20_5 from '../image/wb20_5.png';
import wb20_6 from '../image/wb20_6.png';
import wb20_7 from '../image/wb20_7.png';
import wb20_8 from '../image/wb20_8.png';
import wb21_1 from '../image/wb21_1.png';
import wb21_2 from '../image/wb21_2.png';
import wb21_3 from '../image/wb21_3.png';
import wb21_4 from '../image/wb21_4.png';
import EmotionalInput from '../components/workbook/EmotionalInput';
import { TextWithBold } from '../components/workbook/TextWithBold';
import BasicAssumptionBox from '../components/workbook/BasicAssumptionBox';
import BasicAssumptionLoadBox from '../components/workbook/BasicAssumptionLoadBox';
import AnticipationBox from '../components/workbook/AnticipationBox';
import RuleBox from '../components/workbook/RuleBox';
import MyRuleBox from '../components/workbook/MyRuleBox';
import NewRuleBox from '../components/workbook/NewRuleBox';
import MessageBlock from '../stories/workbook/MessageBlock';
import MessageBlockWithQuote from '../stories/workbook/MessageBlockWithQuote';
import MessageBlockWithTail from '../stories/workbook/MessageBlockWithTail';
import MessageBlockWithBold from '../stories/workbook/MessageBlockWithBold';
import MessageBlockAndTail from '../stories/workbook/MessageBlockAndTail';

const course2Data = [
  [
    {
      title: '생각의 발자취 따라가기',
      fullImage: wb15_1,
      height: 273,
      subtitle: '벌써 두 번째 코스에 도달하셨네요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '생각의 발자취 따라가기 코스는 ',
                '나의 자동적 사고가 어디에서 비롯됐는지 알고 그로부터 내 감정을 더 잘 이해하고 대처할 수 있도록',
                ' 하기 위해 만들어졌어요. 나 자신에 대한 더 깊은 이해가 필요한 분들, 자동적인 부정적 생각들을 더 효과적으로 차단하고 싶은 분들에게 많은 도움이 될 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '기대돼요!',
      link: '',
    },
    {
      title: '섬세한 접근이 필요해요',
      image: wb15_2,
      bg: '#4B87FB',
      texts: [
        {
          text: [
            `이번 코스에서는 조금 더 과거로 들어가서 이야기를 나눠볼 거예요. 그 과정에서 떠올리기 싫었던 예전 기억들이 떠올라 힘들다거나, 여러 감정이 느껴져 혼란스러울 수도 있어요. 그러니 본인의 속도에 맞게 진행하는 게 가장 좋답니다. 언제라도 벅차거나 힘들면 멈추어도 돼요. `,
          ],
        },
      ],
      buttonText: '섬세하게 접근할게요',
      link: '',
    },
    {
      title: '저희도 단계적으로 접근할 거예요',
      image: wb15_3,
      bg: '#FEB246',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '깊은 내면은 들여다보는 작업은 간단한 일이 아니랍니다. 신경써야 할 부분도 많고, 조심스럽게 접근해야 하는 부분들도 있죠. 그래서 저희도 단계적으로 접근할 거예요. 이번 코스에서는 .',
                '내 마음을 이루는 기본 가정,',
                ' 그리고 ',
                '그로부터 기인한 예상과 규칙',
                '에 대해 가볍게 살펴볼 거예요. 더 깊고 자세한 내용은 ‘마음의 근원을 찾아서’ 코스에서 다룰 예정이에요. 그러니 우선은 이번 코스를 먼저 잘 마무리하도록 해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '과학에 기반한 과정을 진행하는 거네요',
      link: '',
    },
    {
      id: 3,
      category: '인사이드 이야기',
      title: '하지만 성실한 참여가 필요해요',
      image: wb15_4,
      bg: '#3C2DE3',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이번 코스는 내용이 조금 더 어려울 수도 있답니다. 혼자서 이해하기 힘든 부분이 있다면 인사이드 코치에게 질문해보세요 :) 이해가 되지 않는 부분은 반복해서 읽어보고 이해해보려고 노력하셔야 한답니다. 다시 한번, 인사이드 프로그램은 전문가들에 의해 정교하게 설계된 정신건강 프로그램이에요. 그러니 ',
                '단순한 읽을거리를 찾아본다는 마음가짐보다는 공부하고 배우고 익힌다는 마음으로 참여',
                '하셔야 해요! 그럼, 이제 시작해볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '준비됐어요!',
    },
  ],
  [
    {
      id: 0,
      category: '인사이드 이야기',
      title: '내 마음을 이루는 ‘기본 가정’',
      fullImage: wb16_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '지난 수업에서 배웠던 ABCDE, 잘 기억하고 계신가요? ABCDE 모델을 염두에 두고 감정기록을 어느 정도 진행해 보셨다면, 그리고 기록들을 회고해 보았다면 ',
                '믿음 혹은 자동적 사고가 대부분 반복되거나 동일한 주제를 중심으로 발생',
                '하는 경향이 있다는 점을 발견했을 거예요.',
                <br />,
                <br />,
                <span />,
                '상황을 그러한 방식으로 평가하도록 유발한 요인은 무엇이고 자동적 사고는 어디에서 비롯됐을까요? ',
                '자동적 사고가 발생한 시작점',
                '을 이해하면 생각과 그에 수반한 감정과 행동을 개선하는 데 도움이 될 거예요.',
                <br />,
                <br />,
                <span />,
                '지금까지의 수업에서는 특정한 사건을 중심으로 발생하는 자동적 사고와 믿음을 되돌아봤어요. 이러한 작업들을 반복하다 보면 ',
                '내 마음을 이루는 ‘기본 가정’',
                '을 발견할 수 있어요. 기본 가정은 내 마음속의 근본적인 신념이랍니다. 이러한 신념으로부터 나의 자동적 사고가 발생하는 것이죠. 결국 상황을 어떻게 평가하는지는 궁극적으로 ',
                '내 마음속 깊게 자리잡은 신념인 ‘기본 가정’과 연관이 있답니다.',
                '과 연관이 있답니다.',
                <br />,
                <br />,
                <span />,
                '이번 수업에서는 그러한 기본 가정이 무엇이고 어떻게 발생하는지 살펴보면서, 우리들의 내면을 더 깊게 살펴볼 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '더 깊은 내면이라니 흥미롭네요!',
      link: '',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '맞아요. 하지만 조심스럽기도 해요.',
      image: wb16_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '별로 어렵지 않게 들릴지도 모르지만, 자신의 기본 가정을 확인하다 보면 가슴 아픈 기억과 감정을 상기하면서 기력이 많이 소모될 수 있어요. 기본 가정은 ',
                '마음속 가장 깊숙한 곳에 자리하는 경향',
                '이 있거든요.',
                <br />,
                <br />,
                <span />,
                '이번 장을 학습하면서 스스로 마음을 잘 가다듬길 바라요. 기본 가정을 한번에 전부 찾아내려고 너무 무리하기보다는 ',
                '본인에게 적당한 속도로 가볍게 진행',
                '하세요. ',
                '기본 가정을 들여다보려는 마음을 먹는 일 자체만으로도 좋은 시작',
                '이라는 사실을 꼭 기억해 두시고요.',
                <br />,
                <br />,
                <span />,
                '기본 가정을 찾는 여정 속에서 떠오르는 생각들을 생각을 적어 보시길 바라요. 생각을 적어 두면 단순히 머릿속에 떠올리고 끝내는 것보다 효과적으로 처리할 수 있거든요. ',
                <br />,
                <br />,
                <span />,
                '혹시 어느 순간 감정이 북받쳐 오르면 잠시 멈추고 휴식을 취하세요. 마음이 차분해졌을 때 돌아오면 돼요. ',
                '힘들다면 심리상담사 혹은 정신건강의학과 의사와 같이 정신 건강 전문가의 도움',
                '을 받아보는 것도 좋습니다. 특히 ',
                '어릴 적에 상처가 되었던 트라우마',
                '가 있다면 ',
                '정신 건강 전문가와 함께 진행',
                '하시는 걸 추천드릴게요. 현재 인사이드에는 뛰어난 정신 건강 전문가분들이 함께 하고 있답니다. 필요하다면 언제든지 선생님들께 도움을 요청하셔도 돼요',
              ]}
            />
          ),
        },
      ],
      buttonText: '알겠어요. 조금 더 세심하게 참여할게요.',
      link: '',
    },
    {
      id: 2,
      category: '인사이드 이야기',
      title: '기본 가정은 근본적인 믿음이에요',
      image: wb16_3,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '기본 가정은 ',
                '자신을 보는 방식과 사회에서의 자신의 위치, 세계 전체에 대한 인식의 많은 부분을 결정짓는 근본적인 믿음',
                '이에요. 누구든지 어릴 적부터 기본 가정을 형성하는데, 그중 유익한 추측도 있지만 별로 도움이 되지 않는 추측도 있죠. 예를 들어볼까요?',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithQuote textList={['나는 소중한 사람이야']} type="light_blue" my />,
        },
        {
          text: '이런 가정은 유익한 반면',
        },
        {
          component: <MessageBlockWithQuote textList={['나는 사랑스럽지 않아']} type="gray" my />,
        },

        {
          text: '이런 가정은 도움이 되지 않겠죠.',
        },
      ],
      buttonText: '되게 근본적인 생각들이네요',
      link: '',
    },
    {
      id: 3,
      image: wb16_4,
      title: '기본 가정에 따라 자동적 사고가 달라지기도 해요.',
      texts: [
        {
          text: [`동일한 상황에서 두 가지 상반된 기본 가정이 어떻게 자동적 사고로 이어지는지 한번 살펴보도록 해요.`],
        },
        {
          component: <MessageBlockWithQuote textList={['나는 소중한 사람이야']} type="light_blue" my />,
        },
        {
          text: `라고 생각하는 사람은 연인과의 이별을 마주했을 때 다음과 같은 자동적 사고를 떠올릴 거예요.`,
        },
        {
          component: (
            <MessageBlockWithTail
              type="white_dotted"
              textList={[['우린 너무 달랐고 서로 안 맞았던 거야. 더는 서로 행복하게 해 줄 수는 없는 거지.']]}
              my
            />
          ),
        },
        {
          text: '반면,',
        },
        {
          component: <MessageBlockWithQuote textList={['나는 사랑스럽지 않아']} type="gray" my />,
        },

        {
          text: '라고 생각하는 사람은 동일한 상황에서도 다음과 같이 생각할지도 몰라요.',
        },
        {
          component: (
            <MessageBlockWithTail
              type="white_dotted"
              textList={[['나는 연애만 하면 늘 실패해. 난 평생 혼자일 거야..']]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이렇게 보았을 때, ',
                '믿음이나 자동적 사고는 꽤 구체적인 내용',
                '인 반면, ',
                '기본 가정은 조금 더 광범위하고 일반적인 생각',
                '이라는 것을 알 수 있어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '내 생각의 뿌리와 같은 거네요',
      link: '',
    },
    {
      id: 3,
      image: wb16_5,
      title: '많은 것들을 결정한답니다',
      texts: [
        {
          text: [`기본 가정은 세상을 바라보는 관점뿐만 아니라 다음과 같은 내용들을 결정하기도 해요.`],
        },
        {
          component: (
            <MessageBlock
              textList={[['주변 세상을 어떻게 인식하고 평가할지'], ['어떤 결정을 내릴지'], ['어떻게 행동할지']]}
              type="gray"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '기본 가정이 유익한지, 혹은 방해가 되는지 여부는 대부분 이전 경험에 달려 있어요. 기본 가정은 ',
                '대개 어릴 때 형성',
                '되므로 마음속 깊이 뿌리박혀 있기 쉽거든요. ',
                '상황과는 별개로 수많은 생각과 행동에 영향',
                '을 미치죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: '내 자아를 구성하는 생각들 같아요',
      link: '',
    },

    {
      id: 5,
      category: '인사이드 이야기',
      title: '조금 더 예시를 살펴볼까요?',
      texts: [
        {
          text: '다음과 같은 기본 가정들이 있을 거예요.',
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['매력적이고 지적이거나 부자일 때, 혹은 창의적일 때만 행복할 수 있어'],
                ['뭐든 다 잘될 거야'],
                ['다른 사람들은 믿을 만해'],
                ['내가 실수하면 사람들은 날 싫어할 거야'],
                ['사람을 믿으면 나만 상처받을 거야'],
                ['다른 사람들이 나보다 나아'],
                ['많은 사람들이 나의 행동을 예의주시하고 있을 거야'],
                ['나는 소중한 존재야'],
                ['실패하면 쓸모없는 인간이 되겠지'],
                ['난 부족해'],
              ]}
              type="gray"
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '긍정적인 것도 있고, 부정적인 것도 있네요',
    },
    {
      id: 3,
      image: wb16_6,
      title: '기본 가정도 바꿀 수 있답니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '근원적인 마음이라는 기본 가정의 특성상 우리는 이를 ',
                '별다른 검토 없이 진실하고 올바른 생각으로 받아들이기 쉬워요.',
                ` 이런 까닭에 방해만 되는 기본 가정이더라도 바꾸는 게 쉽지는 않죠.`,
                <br />,
                <br />,
                <span />,
                '하지만 ',
                '당연히 변화도 가능',
                '해요. 핵심은 ',
                '기본 가정을 파악하고 어떤 영향을 미치는지 이해한 뒤 기본 가정이 발생할 때 의문을 제기',
                '하는 거예요. 대개 오랜 기간에 걸쳐 진행해야 하는 과정이고 혼자 애쓰는 것보다 ',
                '심리치료를 받으면서 시도하는 게 효과가 더 좋을 수 있어요.',
                ' 앞서 소개해드렸듯 인사이드에는 그러한 일들을 도와줄 전문 심리학자 선생님들이 많이 있답니다.',
                <br />,
                <br />,
                <span />,
                '이번 시간에는 기본 가정의 기본 개념에 대해 배워보았어요. 오늘은 속도를 조금 조절하기 위해 여기에서 끝내려고 해요. 마음을 들여다보는 과정도 여유를 두고 진행해야 하거든요 :) 다음 시간에는 기본 가정이 어디서 시작되었는지 살펴보는 시간을 가질 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '점점 마음을 들여다보고 있네요!',
      link: '',
    },
  ],
  [
    {
      id: 0,
      category: '인사이드 이야기',
      title: '내 마음의 근원',
      fullImage: wb17_1,
      height: 189,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '지난 시간에 배웠던 내용들을 기억하시나요? 자동적 사고를 만드는 ‘기본 가정’에 대해 배웠죠. 오늘은 그러한 기본 가정이 어디에서 비롯되었는지 살펴볼 계획이에요.',
                <br />,
                <br />,
                <span />,
                '그 전에 기본 가정과 자동적 사고의 관계에 대해 한 번만 더 복습해 보도록 해요!',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요. 준비됐어요!',
      link: '',
    },
    {
      title: '기본 가정을 토대로 한 자동적 사고',
      image: wb17_2,
      texts: [
        {
          text: '자주 봤던 인지 도식 기억나시나요? 사실은 위 인지도식을 조금 더 자세히 살펴보면 다음과 같아요.',
        },
        {
          component: (
            <div
              className={`w-full h-[190px] relative overflow-hidden rounded-2xl my-[30px] flex align-center`}
              style={{ backgroundColor: '#F3F5F8' }}
            >
              <div className="w-[335px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
                <img className="w-full h-full object-fit" src={wb17_3} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '어떠한 사건이 발생했을 때 평소에 내가 ',
                '무의식 중에 가지고 있었던 기본 가정이 활성화',
                '돼요. 그리고 그러한 ',
                '기본 가정을 토대로 자동적 사고가 발생',
                '하죠. 이후 ',
                '자동적 사고에 따라 감정과 행동, 신체적 반응 등이 정해진답니다.',
                <br />,
                <br />,
                '즉 나의 감정과 행동을 좌지우지하는 건 자동적 사고였지만, 그 ',
                '자동적 사고는 내 마음의 근원, 즉 기본 가정에서 출발하는 것',
                '이죠.',
                <br />,
                <br />,
                <span />,
                '이제 우리가 왜 기본 가정을 공부해야 하는지 아시겠나요? 기본 가정을 잘 파악하면 나의 자동적 사고를 더 효율적이고 근원적으로 교정할 수 있기 때문이에요!',
              ]}
            />
          ),
        },
      ],
      buttonText: '쉽지만은 않을 것 같아요',
      link: '',
    },
    {
      title: '맞아요. 사실 더 깊은 내용도 있어요.',
      image: wb17_4,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '사실 내 마음의 근원은 ',
                '조금 더 세밀한 구성 요소들로 이루어져있어요.',
                ' 핵심 신념이라고도 할 수 있는 ‘기본 가정’ 외에도 중간 신념, 보상 전략 등 다양한 요소들이 있죠. 하지만 ',
                '이번 코스에서는 내 마음의 근원을 ‘기본 가정’의 시선에서만 살펴볼 거예요.',
                ' 한번에 너무 많은 작업들을 하다 보면 혼란스럽고 지칠 수도 있거든요.',
                <br />,
                <br />,
                <span />,
                '이번 코스를 잘 끝낸다면 한층 더 깊게 들어가서 내 마음의 근원을 들여다볼 수 있는 코스를 소개해드릴게요. 그땐 정말 전문 심리학자 선생님의 도움이 필요할지도 모른답니다. 필요하다면 잘 맞는 선생님도 추천해드릴 거예요.',
                <br />,
                <br />,
                <span />,
                '하지만 우선은 이번 코스를 통해 내 마음에 대한 기본적인 이해를 잘 하도록 해요 :) 그럼 기본 가정이 어디에서 비롯되는지 살펴볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요. 기본 가정 먼저! 준비됐어요.',
      link: '',
    },
    {
      id: 3,
      image: wb17_5,
      title: '대부분 아동청소년기에 발생해요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '기본 가정은 ',
                '아동기와 청소년기에 발생',
                '하는데, 보통 ',
                '당시 혹은 그 이전에 내 삶에 중요한 역할을 했던 사람과 함께한 경험',
                '에서 비롯돼요. 아마도 이런 사람들이겠죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['자주 보거나 가까운 사람'],
                ['함께 강렬한 경험(좋은 경험이든, 싫은 경험이든)을 했던 사람'],
                ['지금의 나를 있게 한 사람'],
              ]}
              type="gray"
              my
            />
          ),
        },

        {
          component: (
            <TextWithBold
              texts={[
                '대부분의 경우 ',
                '부모님과 조부모님 등 날 길러 준 사람',
                '을 제일 먼저 떠올릴 거예요. 이후 형제자매나 친구, 동급생, 선생님, 조언자, 혹은 젊은 시절 만난 파트너와 함께하며 성격 형성에 영향을 미치는 학습을 경험하게 되죠. 앞으로 우리 사람에서 중요한 역할을 했던 사람들을 ',
                '중요 인물',
                '들이라고 부를게요.',
                <br />,
                <br />,
                <span />,
                '나의 중요 인물들에 대해 한번 생각해 볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요!',
      link: '',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={8}
          title="어린 시절 나에게 큰 영향을 준 사람들의 이름을 적어 보세요."
          placeholder="여럿이어도 괜찮아요."
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      image: wb17_6,
      title: '중요 인물들과의 경험을 통해 나의 믿음이 형성된답니다.',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '아동기와 청소년기를 통틀어, 다른 사람과 어울리며 좋은 경험 및 나쁜 경험을 모두 해 보셨을 텐데요, 이러한 상호 작용을 통해 믿음이 형성되고 강화되어요. ',
                '중요 인물들과 함께 한 경험',
                '이라면 더 강력한 믿음, 즉 ',
                '기본 가설이 형성',
                '되곤 하죠. ',
                <br />,
                <br />,
                <span />,
                '특히 믿음과 태도는 ',
                '본보기가 되는 사람이 보여주는 모습으로부터 영향',
                '을 받아요.  예를 들어 아픈 내색을 감추지 못하면 약하게 보이는 거라고 믿는 양육자 아래서 자란 아이는 그러한 말을 반복해 들으면서 그와 똑같은 믿음 혹은 태도를 가지게 될 수 있어요. 혹은 친구 대다수가 여자는 수학에 약하지만 독해와 언어 면에서는 뛰어나다고 믿는다면, 나 역시 그렇게 생각하게 될지도 몰라요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '중요 인물로부터 영향을 받는 거군요',
    },
    {
      title: '이제 우리들의 경험들을 떠올려볼 거예요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '다음 질문들은 나의 마음을 들여다보기 위함이지 ',
                '나에게 중요한 영향을 미친 사람들과의 관계를 틀어지게 만들기 위함은 아니랍니다.',
                ' 어떤 관계도 긍정적인 측면이 있고, 또 부정적인 측면이 있어요. 그러니 차분한 마음으로 이전 경험들을 회상해 보세요.',
                <br />,
                <br />,
                <span />,
                '이러한 과정 때문에 ',
                '괴로운 옛 기억이 떠올라 화가 나거나 우울해질 수도 있어요.',
                ' 만약 그렇다면 잠시 감정을 마주하고 앉아 ',
                '감정이 쌓였다가 지나가도록 기다리세요.',
                ' 머리를 식히고 나서 다른 날에 다시 강의로 돌아오면 돼요. 그리고 다시 한번 소개해드리지만, 인사이드에는 이러한 과정들을 더 효과적으로 이끌어주고 해석해 줄 ',
                '전문 심리학자 선생님들',
                '이 계시답니다. 힘들다면 전문가의 도움을 받아보세요!',
                <br />,
                <br />,
                <span />,
                '자, 그럼 시작해 볼까요? 답변할 수 있는 질문들만 답하셔도 돼요!',
              ]}
            />
          ),
        },
      ],
      buttonText: '알겠어요. 여유를 가지고 진행할게요.',
      link: '',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={8}
          title="어떤 규칙과 가치를 배웠나요?"
          placeholder="중요 인물들에게 영향받은 내용을 적어 보세요."
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={2}
          sum={8}
          title="자주 했던 말이나 행동이 있나요?"
          placeholder="중요 인물들에게 영향받은 내용을 적어 보세요."
          buttonText="다음"
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={3}
          sum={8}
          title="문제나 도전에 직면했을 때 당신에게 어떻게 대했나요?"
          placeholder="중요 인물들에게 영향받은 내용을 적어 보세요."
          buttonText="다음"
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={4}
          sum={8}
          title="문제를 스스로 해결하도록 방치됐거나 감정적으로 무시당한 경험이 있나요?"
          placeholder="중요 인물들에게 영향받은 내용을 적어 보세요."
          buttonText="다음"
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={5}
          sum={8}
          title="지나치게 당신을 보호하려 했나요?"
          placeholder="중요 인물들에게 영향받은 내용을 적어 보세요."
          buttonText="다음"
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={6}
          sum={8}
          title="그 외 강렬한 경험들이 있을까요?"
          placeholder="중요 인물들에게 영향받은 내용을 적어 보세요."
          buttonText="다음"
        />
      ),
    },
    {
      title: '잠시 예시를 하나 볼까요?',
      texts: [
        {
          text: [
            '은지의 엄마는 아이 넷을 집에서 돌보느라 눈코 뜰 새 없이 바빴어요. 엄마의 손길과 관심이 필요했던 은지는 엄마가 자기와 함께 많이 놀아 주길 바랐지만 엄마는 은지와 함께 놀아 줄 여유가 없었기 때문에 나중에 놀자고 미루곤 했죠.',
            <br />,
            <br />,
            '은지의 아빠는 일이 많았던 터라 은지가 잠자리에 들기 직전이나 직후에 집에 도착하는 경우가 잦았어요. 일찍 퇴근하는 날에는 소파에 앉아 TV를 봤죠. 은지는 서운했지만 부모님의 감정에 충분히 공감했고 피곤하실 부모님을 굳이 보채지 않았어요. 시간이 지나면서 부모님과 함께 시간을 보내고 싶은 마음은 여전히 굴뚝같았지만 부모님께 놀아 달라고 부탁하는 일은 줄게 됐죠.',
            <br />,
            <br />,
            '은지는 원하는 걸 참는 법을 배웠고, 덕분에 부모님께 독립심이 강하다는 칭찬을 받곤 했어요.',
            <br />,
            <br />,
            '성인이 된 은지는 상대에게 너무 많은 관심을 요구하면 사랑받기 힘들다는 믿음을 갖게 됐죠.',
          ],
        },
      ],
      buttonText: '어릴 적 영향이 되게 컸네요!',
      link: '',
    },
    {
      title: '은지의 이야기를 정리해 볼까요?',
      texts: [
        {
          component: (
            <MessageBlockAndTail
              textList={[
                ['은지의 중요 인물은 누구인가요?'],
                ['엄마와 아빠예요'],
                ['은지는 중요 인물들과 어떤 경험을 했었죠?'],
                [
                  '엄마와 아빠가 너무 바빠서 어릴 적에 많이 놀아주지 못했어요. 엄마에게 놀자고 했을 때 나중에 놀자고 하기도 했고, 아빠는 보통 늦게 집에 왔고 일찍 퇴근하는 날에는 소파에 앉아서 TV를 봤어요. 하지만 두 분 다 너무 바쁘긴 했죠. 그래서 은지는 굳이 부모님을 보채진 않았아요.',
                ],
                ['그래서 은지는 어떤 생각, 특히 어떤 ‘기본 가정’을 가지게 되었나요?'],
                ['상대에게 너무 많은 관심을 요구하면 사랑받기 힘들다는 믿음을 갖게 되었어요.'],
              ]}
              types={['gray', 'white_dotted']}
              my
            />
          ),
        },
        {
          text: [
            '은지가 갖게 된 기본 가정은 긍정적으로 작용할 수도 있고, 부정적으로 작용할 수도 있어요. 하지만 중요한 사실은 분명 은지가 아동청소년기 시절 중요 인물들과 경험했던 것들이 은지의 마음을 형성하고 있다는 점이에요. 기본 가정말이에요.',
            <br />,
            <br />,
            '이번에는 우리도 중요 인물들과의 경험들을 통해 어떠한 생각을 가지게 되었는지 생각해 볼까요?',
          ],
        },
      ],
      buttonText: '어릴 적 경험이 그렇게나 중요했군요',
      link: '',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={7}
          sum={8}
          title="경험들이 나에게 어떤 영향을 미쳤나요? 그리고 어떤 기본 가정이 형성되었을까요?"
          placeholder="중요 인물들로부터 영향받은 내용을 적어 보세요."
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      title: '잘했어요. 이제 시작이랍니다!',
      texts: [
        {
          text: [
            '당장 확실히 답하기 힘들더라도 괜찮아요. 일단 여러 아이디어를 종합해 그럴듯한 기본 가정을 작성해 보는 게 중요하답니다. 어떤 사항을 나에게 적용할 수 있을지 생각해 보는 것만으로도 순조롭게 시작할 수 있어요.',
            <br />,
            <br />,
            '계속해서 어린 시절 경험을 되돌아보고 이런 경험이 기본 가정에 어떤 방식으로 영향을 미쳤는지 생각하다 보면 기억이 새록새록 떠오르고 더 많은 깨달음을 얻게 될 수 있어요.',
            <br />,
            <br />,
            '혹여나 그 과정에서 다음과 같은 기본 가정들이 있는지 살펴보세요. 나에게 방해가 되는 기본 가정들로는 다음과 같은 것들이 있답니다.',
          ],
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['매력적이고 지적이거나 부자일 때, 혹은 창의적일 때만 행복할 수 있어'],
                ['내가 실수하면 사람들이 날 안 좋게 생각하겠지'],
                ['사람을 믿으면 나만 상처받을 거야'],
                ['나는 사랑스럽지 않아'],
                ['실패하면 쓸모없는 인간이 되겠지'],
                ['내가 일을 잘 해내야 사람들이 날 존중해 줄 거야'],
                ['노력하는 일을 잘해 내는 사람만 존중받을 수 있어'],
                ['내 의견에 동의하지 않는 사람은 날 싫어하는 거야'],
                ['손해 보지 않도록 조심해야지'],
                ['나는 쓸모없는 인간이야'],
                ['절대 실수하면 안 돼'],
                ['내게 벌어진 일은 모두 내 책임이지'],
                ['항상 완벽해야 해'],
                ['다른 사람들의 행복은 나에게 달려 있어'],
                ['사람들을 실망시킬 순 없어'],
                ['주변 사람들보다 뛰어나야 해'],
              ]}
              type="black"
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '부정적인 생각들이 정말 많네요',
      link: '',
    },
    {
      id: 3,
      image: wb17_7,
      title: '어렵지만 조금씩 시도해 보세요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '어릴 적 경험을 되돌아보고 어떤 기본 가정이 있는지 알아봤으니, 이제 다음 순서로 넘어가도록 해요. 앞서 이야기했듯이 ',
                '기본 가정에 대한 더 깊은 이야기',
                '는 ',
                '다른 코스에서 별도로 다룰 예정',
                '이랍니다.',
                <br />,
                <br />,
                <span />,
                '다음 시간에는 기본 가정이 우리들의 ',
                '‘예상’',
                '에 어떠한 영향을 미치는지, 그리고 그러한 것들이 어떻게 ',
                '‘삶의 규칙’',
                '을 형성하는지에 대해 알아볼 거예요. 결국 가정과 예상들이 모여 우리들의 행동에 영향을 미친답니다.',
                <br />,
                <br />,
                <span />,
                '마지막으로 오늘 내가 작성하였던 내용들을 살펴볼까요? 내용을 바꾸고 싶거나 다시 보고 싶을 때는 언제든 다시 ‘내 마음의 근원’ 워크북을 찾아오시면 돼요 :)',
              ]}
            />
          ),
        },
      ],
      buttonText: '제가 어떻게 썼는지 보여주세요!',
      link: '',
    },
    {
      replaceComponent: (
        <BasicAssumptionBox title="어린 시절을 토대로 내 마음을 형성한 기본 가정이에요" buttonText={'마치기'} />
      ),
    },
  ],
  [
    {
      id: 0,
      category: '인사이드 이야기',
      title: '기본 가정을 통한 예상',
      fullImage: wb18_1,
      height: 189,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '특정 상황에서 어떻게 느끼고 행동할지는 우리들의 생각에 좌우된답니다. 그리고 앞서 배운 것처럼 그러한 생각은 자동적으로 이루어지는 경우가 많아요. 그리고 기본 가정이 어떻게 형성되고 그것이 자동적 사고와 어떤 연관이 있는지 가볍게 살펴보았답니다.',
                <br />,
                <br />,
                <span />,
                '기본 가정과 자동적 사고의 관계에 대해 조금 더 살펴볼까요? 흔히 ',
                '기본 가정과 자동적 사고 사이에는 ‘예상‘',
                '이라는 것이 있어요. ',
                '나 자신이나 다른 사람 혹은 미래에 대한 기대',
                '를 뜻하죠.',
                <br />,
                <br />,
                <span />,
                '이번 장에서는 이런 예상을 들여다보면서 예상이 무엇이고 어떻게 발생하며 태도와 행동에 어떤 영향을 미칠 수 있는지 살펴보도록 해요!',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요. 준비됐어요!',
      link: '',
    },
    {
      title: '예상은 기본 가정에서 출발해요',
      image: wb18_2,
      texts: [
        {
          text: '예상은 나 자신이나 타인 혹은 이 세상에 적용될 수 있죠. 예를 들어 볼게요. 다음과 같은 기본 가정이 있으면,',
        },
        {
          component: <MessageBlock textList={[['난 사랑스럽지 않아.']]} type="black" my />,
        },

        {
          text: '위와 같은 기본 가정이 있으면,',
        },
        {
          component: (
            <MessageBlock textList={[['사람들은 날 좋아하거나 인정해 주지 않을 거야.']]} type="white_dotted" my />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이렇게 예상하기 쉬울 거예요. 이처럼 예상은 ',
                '기본 가정을 조금 더 현실의 상황에 적용시킨 생각',
                '이랍니다. 이런 예상은 어딜 가든 날 따라다니기 때문에 새로운 사람을 만나면 말 한마디 나눠 보기도 전에 상대방이 날 좋아하거나 인정해 주지는 않을 거라고 확신하게 되죠.',
                <br />,
                <br />,
                <span />,
                '몇 가지 더 예시를 살펴볼게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '기본 가정을 토대로 예상을 하는 거군요!',
    },
    {
      title: '한번 살펴볼까요?',
      texts: [
        {
          text: '어떠한 기본을 가정하고 있느냐에 따라 특정 상황에서 기대하는 바, 즉 예상이 달라진답니다. 몇 가지 더 살펴볼게요.',
        },
        {
          component: (
            <MessageBlockWithBold
              textList={[
                {
                  bold: '기본 가정',
                  text: [': 난 쓸모없는 인간이야'],
                  type: 'black',
                },
                {
                  bold: '예상',
                  text: [': 당연히 시험에 떨어지겠지'],
                  type: 'white_dotted',
                  tail: true,
                },
                { bold: '기본 가정', text: [': 절대 실수하면 안 돼'], type: 'black' },
                { bold: '예상', text: [': 내가 실수하면 부모님이 날 미워하시겠지'], type: 'white_dotted' },
                { bold: '기본 가정', text: [': 예뻐야 행복할 수 있어'], type: 'black' },
                { bold: '예상', text: [': 여기서 한 5kg 빼면 행복할 텐데'], type: 'white_dotted' },
                {
                  bold: '기본 가정',
                  text: [': 내가 조심하지 않으면 사람들에게 안 좋은 일이 생길 거야.'],
                  type: 'black',
                },
                { bold: '예상', text: [': 내 의견에 동의하지 않는 사람은 날 싫어하는 거야'], type: 'white_dotted' },
              ]}
              margin="mt-[40px]"
              evenMargin
            />
          ),
        },
      ],
      buttonText: '예상이 조금 더 구체적인 생각이네요',
      link: '',
    },
    {
      replaceComponent: (
        <BasicAssumptionLoadBox
          title={'여러분은 어떤 예상을 하시나요?'}
          text={'이제 여러분의 차례예요. 이전 장에서 작성했던 기본 가정을 한번 살펴볼게요.'}
          buttonText={'다음'}
          addText="자, 그럼 이제 이러한 기본 가정에서 발생할 수 있는 예상에는 어떠한 것들이 있는지 알아볼까요?"
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={8}
          title="기본 가정으로부터 비롯된 ‘다른 사람에 대한 예상’은 무엇이 있나요?"
          placeholder="편하게 작성해 보세요"
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={1}
          sum={8}
          title="기본 가정으로부터 비롯된 ‘나 자신에 대한 예상’은 무엇이 있나요?"
          placeholder="편하게 작성해 보세요"
          buttonText="다음"
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={2}
          sum={8}
          title="기본 가정으로부터 비롯된 ‘미래에 대한 예상’은 무엇이 있나요?"
          placeholder="편하게 작성해 보세요"
          buttonText="다음"
        />
      ),
    },
    {
      image: wb18_3,
      title: '좋아요. 조금 더 이야기해 볼게요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '우리들은 기본 가정에서 비롯된 예상을 바탕으로 사람이나 상황에 대해 특정한 방식으로 예상하죠.  이러한 예상은 되풀이되는 결과를 낳을 수도 있는데요, 종종 우리가 예상하는 방식 자체가 실제 그 예상이 실현되게 할 수 있답니다. 즉, 우리들은 ',
                '자기실현적 예언을 형성',
                '할 수 있어요.',
                <br />,
                <br />,
                <span />,
                '예를 들어볼게요. 여러분이 다음과 같은 예상을 가지고 있다고 생각해 봐요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['사람들은 나에게 불친절하고 내가 없는 자리에서 내 험담을 할 거야']]}
              type="white_dotted"
              my
            />
          ),
        },

        {
          component: (
            <TextWithBold
              texts={[
                '이렇게 예상하다 보니 주변 사람들이 퉁명스럽거나 따로 속닥거리는 기색이 조금이라도 있으면 잔뜩 긴장하고 촉각이 곤두서겠죠. 귓속말로 속삭이고 함께 웃거나 손짓하는 모습을 보면 날 헐뜯는 건 아닌지 의심하게 될지도 몰라요. 이러한 나의 생각은 내가 ',
                '예상했던 상황이 실제 발생한다고 여기게 되는 증거',
                '가 되는 거예요. 결국 나는 자연히 더더욱 긴장하며 불안해질 테고, 이런 상황을 눈치챈 사람들은 함께 있을 때 안절부절못하는 내 모습에 저마다 반응하겠죠. 그렇게 예상은 현실이 된답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '정말 생각하는대로 이뤄지는 거네요',
    },
    {
      title: '예시를 하나 들어볼게요',
      texts: [
        {
          text: '어떻게 예상이 현실이 되는지 살펴보도록 해요. 수진이는 다음과 같은 기본 가정이 있어요.',
        },
        {
          component: <MessageBlock textList={[['난 쓸모없는 인간이야']]} type="black" my />,
        },
        {
          text: '그리고 운전면허시험을 앞둔 지금, 수진이는 다음과 같이 예상하죠.',
        },
        {
          component: <MessageBlockWithTail textList={[['나는 시험에 통과하지 못할 거야']]} type="white_dotted" my />,
        },
        {
          text: '시험장에 도착한 수진이는 그러한 예상 때문에 몹시 초조함을 느끼죠. 그리고 나머지 시험을 보기도 전에 패배감에 빠지고 말아요. 결국 감독관과 차에 올라탔을 때 방향을 틀었다가 옆 차에 너무 가까이 붙는 바람에 주차선을 침범해서 다른 차를 하마터면 긁을 뻔했어요. 패배감에 빠진 수진이는 결국 시험관에게 다음과 같이 말해요.',
        },
        {
          component: <MessageBlockWithTail textList={[['시험 포기할게요']]} type="white_dotted" my />,
        },
        {
          text: '어떻게 예상이 현실이 되는지 살펴보도록 해요. 수진이는 다음과 같은 기본 가정이 있어요.',
        },
        {
          text: '그렇게 수진이의 예상은 현실이 되었답니다.',
        },
      ],
      buttonText: '기본 가정과 예상이 많은 걸 좌지우지하네요',
      link: '',
    },
    {
      image: wb18_4,
      title: '잘 이해가 되었나요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘은 ',
                '기본 가정이 나 자신과 세상에 대한 예상과 함께 발생',
                '할 수 있으며, 결국 ',
                '이러한 예상이 태도와 행동에 영향을 미칠 수 있다는 점',
                '을 학습해 봤어요. 다음 시간에는 기본 가정이 삶의 규칙과 관계를 맺는 방식을 살펴보도록 할게요. 사람들은 예측 가능한 세상에서 이해하고 살아가기 위해 어떤 규칙을 생성하는 경향이 있답니다.',
                <br />,
                <br />,
                <span />,
                '참, 마무리하면서 내가 작성한 ‘예상’을 다시 살펴볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요, 다시 한번 보고 싶어요',
    },
    {
      replaceComponent: (
        <AnticipationBox
          title="나의 기본 가정과 예상이에요"
          subtitle="이제 여러분의 차례예요. 이전 장에서 작성했던 기본 가정을 한번 살펴볼게요."
          buttonText={'마치기'}
        />
      ),
    },
  ],
  [
    {
      id: 0,
      category: '인사이드 이야기',
      title: '삶의 규칙',
      fullImage: wb19_1,
      height: 199,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '오늘은 삶의 규칙에 대해 살펴볼 거랍니다. 많은 사람들이 삶의 규칙을 가지고 있어요. 우리 주변에서도 많이 볼 수 있죠? 하지만 ',
                '어떤 규칙들을 조금 더 근본적인 나의 내면에서부터 비롯',
                '된답니다. 오늘은 기본 가정과 예상에서 비롯되는 삶의 규칙을 살펴보도록 해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '재밌을 것 같아요!',
    },
    {
      id: 1,
      category: '인사이드 이야기',
      title: '규칙은 우리 삶에 영향을 줘요',
      image: wb19_2,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '기본 가정과 예상은 삶의 규칙을 형성하고, 형성된 규칙은 일상생활에 영향',
                '을 줘요. 흔히 우리가 스스로 부과한 규칙들은 ‘~하지 않아야 한다’는 식의 금지 사항으로 작용해요. 그리고 특정한 상황에서 어떻게 행동해야 하는지 안내하죠. 일반적으로 규칙은 유익하기도 해요. 예를 들어볼까요?',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithQuote textList={['비가 올 것 같은 날에는 우산 챙기기']} type="light_blue" my />,
        },

        {
          text: '혹은',
        },
        {
          component: <MessageBlockWithQuote textList={['하루에 두 번 양치하기']} type="light_blue" my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이처럼 명확한 규칙들은 우리 삶을 편리하게 하죠. 하지만 ',
                '어떤 규칙은 필요 이상으로 삶을 힘들게 만들고 더 큰 행복을 얻는 데에 방해',
                '가 되기도 한답니다. 가령',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithQuote
              textList={['다른 사람 없이 집 밖으로 나가지 않기']}
              type="gray"
              margin="mt-[40px] mb-[10px]"
            />
          ),
        },
        {
          component: (
            <MessageBlockWithQuote
              textList={['소란을 만들지 않으려면 항상 내가 먼저 양보하기']}
              type="gray"
              margin="mb-[40px]"
            />
          ),
        },

        {
          text: '이런 예시들이 있을 거예요. 이런 규칙들을 따르기엔 몸과 마음이 몹시 힘들죠. 삶의 질이 망가질 수도 있어요. 이번 수업에서는 문제를 유발하는 삶의 규칙이 어떤 모습인지 자세히 알아보고 내게 이런 삶의 규칙이 있는지, 만약 그렇다면 삶에 어떤 영향을 미치고 있는지 점검해 보도록 해요. 이후에는 삶을 저해하는 규칙을 바꾸는 데 도움이 될 만한 단계별 전략을 알려 드릴게요. ',
        },
      ],
      buttonText: '제게도 그런 규칙이 있겠죠? 기대돼요!',
      link: '',
    },
    {
      id: 2,
      category: '인사이드 이야기',
      title: '해로운 삶의 규칙은 무엇일까요?',
      image: wb19_3,
      texts: [
        {
          text: '사람들은 종종 부정적인 기본 가정과 예상으로 인해 발생한 문제를 해결하려고 규칙을 만들곤 해요. 예를 들어, 다음과 같은 기본 가정을 가지고 있다면,',
        },
        {
          component: <MessageBlock textList={[['난 사랑스럽지 않아']]} type="black" my />,
        },
        {
          text: '이런 예상이 따라올 테고,',
        },
        {
          component: <MessageBlockWithTail textList={[['아무도 날 사랑하지 않겠지']]} type="white_dotted" my />,
        },
        {
          text: '다음과 같은 규칙을 세울 수 있어요.',
        },
        {
          component: <MessageBlockWithQuote textList={['사랑받으려면 내 본모습을 숨기고 살아야지']} type="gray" my />,
        },

        {
          text: '다른 예시를 하나 더 살펴보죠. 만약 기본 가정이 다음과 같다면,',
        },
        {
          component: <MessageBlock textList={[['나는 다른 사람보다 못해']]} type="black" my />,
        },

        {
          text: '이런 예상을 하게 될 테고,',
        },
        {
          component: (
            <MessageBlockWithTail textList={[['얼마 뒤 있을 발표를 망치고 말 거야']]} type="white_dotted" my />
          ),
        },
        {
          text: '그에 따른 삶의 규칙은 다음과 같을 거예요.',
        },
        {
          component: (
            <MessageBlockWithQuote
              textList={['중간이라도 가려면 늘 남보다 두 배는 더 노력해야 해']}
              type="gray"
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '되게 불편한 규칙들이 생겼네요',
      link: '',
    },
    {
      id: 3,
      image: wb19_4,
      title: '규칙은 우리를 억압하기도 해요.',
      texts: [
        {
          text: [
            `불편하고 과도한 규칙 때문에 결국 실제 내 모습과 다르게 행동하게 돼요. 그렇게 사는 게 삶을 이어 가는 유일한 방법이라고 생각하게 되거든요.`,
            <br />,
            <br />,
          ],
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이처럼 문제를 일으키는 규칙을 인지하는 방법은 어렵지 않아요. “이런 행동은 절대 해서는 안 돼”라는 식으로 ',
                '어떤 방식으로 행동하면 안 된다고 스스로 제약',
                '을 두거나, “이건 무조건 해야지”라며 ',
                '무언가 꼭 해야 한다고 다그치는 경우',
                '에 집중해 보는 거예요. 예를 들어볼게요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithQuote
              textList={[
                '난 항상 강인해야 돼',
                '나 말고 다른 사람이 괜찮은지부터 확인해야지',
                '절대 살찌면 안 돼',
                '약점을 보일 순 없어',
                '언제나 뭐든지 완벽하게 해낼 거야',
                '누구도 믿어서는 안 돼',
              ]}
              type="gray"
              my
            />
          ),
        },

        {
          text: [
            '이런 삶의 규칙은 매우 가혹하고 엄격하죠. 그래서 우리들의 삶을 제한할 수 있어요. 그러한 규칙을 계속 따른다면 나에게 유익한 게 뭔지 혹은 어떤 상황을 마주했을 때 내게 필요한 게 뭔지 알아차리지 못할 수도 있죠. 그저 규칙을 따르는 데에만 급급해서 말이에요.',
            <br />,
            <br />,
            '열이 펄펄 날 정도로 지독한 감기에 걸렸다고 생각해 볼까요? 이때 우리들의 삶의 규칙이',
          ],
        },
        {
          component: <MessageBlockWithQuote textList={['난 언제나 강인해야 해']} type="gray" my />,
        },
        {
          text: '이와 같다면, 휴식을 취하지 않으려고 할지도 몰라요. 그 결과 결국 빨리 회복할 수 없게 되죠.',
        },
      ],
      buttonText: '너무 엄격해서 나를 잊게 되는 거 같아요',
      link: '',
    },
    {
      title: '규칙이 어디서 비롯되는지 기억하시나요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '과도하게 엄격한 규칙은 예상으로부터, 그리고 예상은 부정적인 기본 가정에서 비롯',
                '됐어요. 부정적인 기본 가정은',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['자존감을 갉아먹고'],
                ['삶의 다양한 측면을 부정적으로 바라보게 하며'],
                ['힘들고 불쾌한 감정을 유발하고'],
                ['악순환을 가져오죠'],
              ]}
              type="black"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '그래서 결국 내가 필요한 걸 등한시하거나 부정적인 경험을 불러는 행동을 취하게 된답니다.',
                <br />,
                <br />,
                <span />,
                '반면 ',
                '부정적인 기본 가정을 찾아내고 변화하고자 애쓰면 삶의 질이 크게 달라진다는 것',
                '을 알 수 있어요. 물론 노력을 기울여야 하고 시간이 꽤 걸리는 과정이죠. 하지만 노력할 만한 가치가 충분히 있어요. ',
                '혼자 해내기 어렵다면 정신건강 전문가의 도움을 받아보는 것도 좋은 선택',
                '이랍니다. 인사이드의 많은 전문가 선생님들께서 여러분을 기다리고 있으니까요 :)',
                <br />,
                <br />,
                <span />,
                '우선은 우리도 규칙을 써보는 시간을 가져보도록 할게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '복습 좋아요!',
      link: '',
    },

    {
      replaceComponent: (
        <BasicAssumptionLoadBox
          title="여러분에겐 어떤 규칙이 있나요?"
          subtitle="나의 규칙을 찾아볼게요. 이전 장에서 작성했던 기본 가정과 예상을 한번 살펴볼게요."
        />
      ),
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={1}
          title="다른 사람, 나 자신, 미래에 대한 예상을 고려하여 설정한 나의 규칙이 있나요?"
          placeholder="편하게 작성해 보세요"
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      id: 3,
      image: wb19_5,
      title: '이제 정리해 볼게요!',
      texts: [
        {
          text: '어떤 상황이 발견했을 때 내가 가지고 있는 기본 가정이 활성화되어 자동적 사고를 유발하고, 결국 그에 따라 나의 감정과 행동이 결정된다는 이야기였어요. 이번 수업을 통해서는 기본 가정과 자동적 사고 사이에 몇 단계의 사고 과정들이 있다는 사실을 배웠어요. 이를 도식으로 표현해 보면 다음과 같을 거예요.',
        },
        {
          component: (
            <div
              className={`my-[40px] w-full h-[190px] relative overflow-hidden rounded-2xl mb-[30px] flex align-center`}
              style={{ backgroundColor: '#F3F5F8' }}
            >
              <div className="w-[335px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
                <img className="w-full h-full object-fit" src={wb19_6} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '즉 내 마음의 깊은 내면에는 기본 가정이 있어요. 기본 가정에 따라 우리는 예상을 하고, 그로부터 규칙을 만들어요. 여기서 ',
                '어떠한 사건이 발생했을 때 우리들의 기본 가정이 활성화',
                '돼요. 그리고 ',
                '기본 가정과 규칙에 따라 자동적 사고가 발생',
                '하고, ',
                '그로부터 감정과 행동이 결정되죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: '내용이 조금 어려운 것 같기도 해요',
      link: '',
    },
    {
      image: wb19_7,
      title: '괜찮아요 :) 어려운 내용이랍니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '조금 어렵나요? 깊은 내면에 대해서는 별도의 코스에서 조금 더 자세히 공부해 볼 계획이니 지금 완벽히 이해하지 못했다고 하더라도 너무 걱정하지 마세요. 우선은 ',
                '어떤 사건이 있을 때 우리는 자동적 사고에 따라 생각하고 그에 따라 우리들의 감정과 행동이 결정된다',
                '는 사실만 잘 이해하면 돼요. ',
                '자동적 사고가 발생하는 이면에는 기본 가정이 작동하고 있다',
                '는 사실까지 이해하면 더 좋고요 :)',
                <br />,
                <br />,
                <span />,
                '그러한 기본 가정을 현실의 상황에 적용시킨 생각이 ',
                '예상',
                ', 그걸 규칙화한 게 ',
                '규칙',
                '이라고 생각하시면 돼요. 헷갈린다면 그냥 우선은 기본 가정으로 퉁쳐서 생각해도 괜찮답니다 :)',
                <br />,
                <br />,
                <span />,
                '오늘은 기본 가정에서 출발한 예상이 어떻게 우리들의 삶의 규칙을 형성하는지 살펴보았어요. 마지막 수업인 다음 시간에는 삶의 규칙을 바꾸는 전략들에 대해 알려드릴게요. ',
                <br />,
                <br />,
                <span />,
                '마무리하며 아까 작성하였던 나의 규칙들을 한번 살펴볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요, 다시 보고 싶어요',
      link: '',
    },
    {
      replaceComponent: <RuleBox buttonText={'마치기'} />,
    },
  ],
  [
    {
      id: 0,
      category: '인사이드 이야기',
      title: '기본 가정과 규칙 조정하기',
      fullImage: wb20_1,
      height: 191,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이제 마지막 수업이에요 :) 지난 수업에서는 기본 가정에서 출발한 삶의 규칙이 있다는 사실을 배웠어요. 긍정적인 규칙과 부정적인 규칙의 다양한 예시를 살펴보기도 했어요. ',
                '실은 어떤 규칙이 무조건 긍정적인 것도, 무조건 부정적인 것도 아니랍니다.',
                <br />,
                <br />,
                '한번 살펴볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요',
    },
    {
      title: '상황에 따라 달라진답니다',
      image: wb20_2,
      texts: [
        {
          text: '어떤 경우에는 내가 만든 규칙이 유익할 때도 있지만 다른 상황에서는 쓸모없을지도 몰라요. 예를 들어 다음과 같은 규칙을 세웠다고 가정해볼게요.',
        },
        {
          component: <MessageBlockWithQuote textList={['다른 사람 앞에서 절대 울지 않아야 해']} type="light_blue" my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이런 규칙은 깐깐한 피드백을 받는 업무 환경에서라면 도움이 될 거예요. 강인한 모습으로 좋은 평가를 받도록 도와줄 수도 있으니까요.',
                <br />,
                <br />,
                <span />,
                '하지만 가깝게 지내던 사람의 장례식장에 간다면 울지 않겠다고 다짐했던 규칙은 도움이 되지 않을 거예요. 오히려 나를 이상한 사람으로 만들어버릴 수도 있죠.',
                <br />,
                <br />,
                <span />,
                '따라서 ',
                '같은 규칙이라도 조금 더 유연하게 적용될 필요가 있답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '유연하게 적용하는 게 좋겠네요',
    },
    {
      title: '가정이나 규칙은 바꿀 수 있어요',
      image: wb20_3,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '기본 가정이나 삶의 규칙을 바꾸려면 우선 이를 인식해야겠죠. 하지만 그 과정이 생각보다 쉽지는 않답니다. 내가 가지고 있는 기본 가정이나 삶의 규칙을 찾고 이를 더 자세히 만나보는 일은 ',
                '‘마음의 근원을 찾아서’ 코스',
                '에서 조금 더 자세히 다룰 거예요. 오늘은 간단하게 가정이나 규칙을 들여다볼 수 있는 방법을 살펴보도록 해요.',
                <br />,
                <br />,
                <span />,
                '기본 추측과 삶의 규칙을 살펴보려면 일단 ',
                '내가 원하는 방식과 다르게 행동하거나 느꼈던 상황',
                '을 자세히 들여다보세요. 잠시 멈추고 차분히 되돌아보는 거예요. 당시의 자동적 사고와 느꼈던 감정을 떠올려보면서요. 이러한 작업들을 하다 보면 부정적인 기본 가장이나 문제를 유발하는 삶의 규칙을 찾을 수 있답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '쉽진 않은 것 같아요',
      link: '',
    },
    {
      id: 3,
      image: wb20_4,
      title: '감정 노트를 활용해 볼 수도 있어요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                'ABCDE 모형을 기반으로 만들어진 감정 노트를 활용하는 것도 좋은 방법이랍니다. 감정 노트 기록들을 살펴보면서 ',
                '자동적 사고와 연관되어 있을지 모르는 기본 가정과 삶의 규칙',
                '을 찾아보는 거예요. 각 가정과 규칙을 다시 살펴보면서 도움이 되는지 혹은 방해가 되는지 스스로 질문해 보세요. 아래와 같은 질문에 답해 보면 도움이 될 거예요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlock
              textList={[
                ['목표를 성취하는 데 도움이 되는 가정이나 규칙인가요?'],
                ['욕구를 충족하는 데에 도움이 되는 가정이나 규칙인가요?'],
                ['나 자신을 보살피고 배려하도록 돕는 가정이나 규칙인가요?'],
                ['가정이나 규칙으로 인해 불편하지는 않나요?'],
                ['가정이나 규칙이 너무 엄격하진 않나요?'],
                ['비슷한 상황에 처한 다른 사람을 위해서도 그러한 가정과 규칙을 세우시겠어요?'],
                ['다른 사람에게도 적용이 되는 가정과 규칙일까요?'],
                ['그러한 가정과 규칙에서 생각 함정에 빠지진 않았나요?'],
              ]}
              type="gray"
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 ',
                '기본 가정이나 삶의 규칙이 유익한지 혹은 방해가 되는지 결정하는 건 다름 아닌 나 자신',
                '이라는 점을 기억해 두세요. 다른 누구도 날 위해 이런 결정을 대신 내려 줄 순 없어요. 하지만 정신 건강 전문가는 결정을 내리는 과정에 도움을 주고 기본 가정과 삶의 규칙을 여러 방면에서 살펴보도록 도울 순 있답니다. 더 깊은 이야기를 위해서는 인사이드 상담사 선생님들을 만나보세요 :)',
              ]}
            />
          ),
        },
      ],
      buttonText: '선생님도 한번 만나볼게요!',
      link: '',
    },
    {
      title: '이제 규칙을 바꿔볼까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '삶의 규칙을 떠올려 보고 어떤 게 유익한지 혹은 유익하지 않은지 살펴봤으니 이제 어디서부터 시작하면 될까요? 도움이 되지 않는 규칙을 바꾸려고 노력해야겠죠! 시간이 흐르면서 구식이 된 법을 바꿀 수 있듯이 규칙도 마찬가지랍니다. ',
                <br />,
                <br />,
                <span />,

                '나만의 규칙은 ',
                '내가 내린 선택',
                '이므로 ',
                '이제는 유익하지 않다는 생각이 들면 언제든지 바꿀 수 있다는 장점',
                '이 있어요. 해로운 삶의 규칙을 몇 가지 살펴보고 어떻게 바뀔 수 있을지 알아볼까요?',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithQuote textList={['난 항상 강인해야 해']} type="gray" my />,
        },
        {
          text: '위와 같은 규칙은 다음과 같이 바꿀 수 있어요.',
        },
        {
          component: (
            <MessageBlockWithQuote textList={['다른 사람 앞에서 약한 모습 보일 수 없어']} type="light_blue" my />
          ),
        },
        {
          text: '그리고 다음과 같은 규칙은',
        },
        {
          component: <MessageBlockWithQuote textList={['절대 살찌면 안 돼']} type="gray" my />,
        },
        {
          text: '다음과 같이 바꿀 수 있어요.',
        },
        {
          component: (
            <MessageBlockWithQuote textList={['몸무게 따위로 내 가치를 평가할 순 없지']} type="light_blue" my />
          ),
        },
        {
          text: '비슷하게 다음과 같은 규칙은',
        },
        {
          component: <MessageBlockWithQuote textList={['항상 다른 사람을 먼저 배려해야 해']} type="gray" my />,
        },
        {
          text: '다음과 같이 바꿀 수 있어요.',
        },
        {
          component: <MessageBlockWithQuote textList={['다른 사람만큼 나 자신도 중요해']} type="light_blue" my />,
        },
        {
          component: (
            <TextWithBold
              texts={[
                '이외에도 정말 많은 규칙들이 있을 수 있겠죠. 핵심은 이전에 형성했던 기본 가정과 삶의 규칙을 나에게 더 유익하도록 내가 노력해서 바꿀 수 있다는 점이에요. 물론 쉽진 않죠. 하지만 ',
                '규칙을 바꿔나가는 과정에서 너무 어색하게 느껴지거나 나답지 않다고 느껴지더라도 너무 성급하게 생각하진 말아요.',
                ' 이제껏 예전 규칙을 지키며 살아왔잖아요. ',
                '새로운 규칙에 익숙해지려면 시간이 걸리기 마련이에요. ',
              ]}
            />
          ),
        },
      ],
      buttonText: '천천히 노력해 볼게요!',
    },
    {
      id: 3,
      image: wb20_5,
      title: '조금 더 여유를 가지기로 해요',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '충분히 시간을 들여서 바꾸고 싶은 규칙을 자세히 작성하고 더 유익한 규칙을 대안으로 세워 보세요. ',
                '새로운 규칙이 자리를 잡으려면 적응 과정과 시간, 연습이 필요하다는 점',
                '을 기억해 두시고요. 사실 첫 번째 시도에서 완전히 변화된 모습을 볼 수 없는 건 지극히 정상이에요. 이런 과정은 반복 연습이 필요하거든요. 하지만 시간이 지나면서 눈에 띄는 변화가 나타날 거예요. 또한 기본 가정과 규칙이 해롭다는 사실을 인지하고 있더라도 ',
                '이를 바꾸는 게 왠지 꺼림칙하게 느껴지는 시기가 있다는 점',
                '도 알아 두세요. 도움이 되지 않더라도 오랫동안 익숙해져 있기 때문에 그런 기분이 드는 것이랍니다.',
                <br />,
                <br />,
                '자, 그럼 이제 규칙을 바꾸는 연습을 해 볼까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '여유를 가지고 해 볼게요',
      link: '',
    },
    {
      title: '나의 규칙은 다음과 같았어요',
      texts: [
        {
          component: <MyRuleBox />,
        },
      ],
      buttonText: '다음',
    },
    {
      title: '한번 생각해 볼까요?',
      texts: [
        {
          component: (
            <MessageBlock
              textList={[
                ['목표를 성취하는 데 도움이 되는 가정이나 규칙인가요?'],
                ['욕구를 충족하는 데에 도움이 되는 가정이나 규칙인가요?'],
                ['나 자신을 보살피고 배려하도록 돕는 가정이나 규칙인가요?'],
                ['가정이나 규칙으로 인해 불편하지는 않나요?'],
                ['가정이나 규칙이 너무 엄격하진 않나요?'],
                ['비슷한 상황에 처한 다른 사람을 위해서도 그러한 가정과 규칙을 세우시겠어요?'],
                ['다른 사람에게도 적용이 되는 가정과 규칙일까요?'],
                ['그러한 가정과 규칙에서 생각 함정에 빠지진 않았나요?'],
              ]}
              type="gray"
            />
          ),
        },
      ],
      buttonText: '다음',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={1}
          title="기존의 규칙보다 더 도움이 되는 
          새로운 규칙을 적어보세요"
          placeholder="편하게 작성해 보세요"
          buttonText="다음"
          isFirstPage={true}
        />
      ),
    },
    {
      title: '한번 비교해 볼까요?',
      texts: [
        {
          component: (
            <div>
              <NewRuleBox />
              <div className="mt-[20px]">
                <MyRuleBox />
              </div>
            </div>
          ),
        },
      ],
      buttonText: '다음',
    },
    {
      title: '이제 규칙을 마음에 새겨봐요',
      image: wb20_6,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '새로운 규칙을 만들었으니 이제 새로운 규칙이 기존의 규칙을 대체할 수 있도록 해야겠죠? 방법은 정말 간단해요. 하루에 몇 번씩 ',
                '알람을 설정해서 알람이 울리면 하던 일을 멈추고 새로운 기본 가정과 삶의 규칙을 소리내어 읽어보는 거예요.',
                ' 처음에는 ‘이게 무얼 하는 건가’ 하는 생각이 들 수 있어요. 하지만 일단 시도해 보는 거예요.',
                <br />,
                <br />,
                <span />,

                '어떤 사람들은 하루에도 몇 번씩 마주치는 물건을 기준으로 삼고 여기에 새로운 기본 가정이나 삶의 규칙을 연관 지으면 도움이 된다고 해요. 예를 들어 열쇠고리나 장신구 같은 물건 말이에요. 기준 물건을 보거나 만질 때마다 새로운 가정 혹은 삶의 규칙을 떠올리는 방식이죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: '소리내어 읽어보도록 할게요',
    },
    {
      title: '소리내서 읽어보세요',
      texts: [
        {
          component: <NewRuleBox full />,
        },
      ],
      buttonText: '읽어봤어요!',
    },
    {
      title: '점차 효과가 나타날 거예요',
      image: wb20_7,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '물론 처음에는 어색하고 낯설게 느껴질 수 있어요. 하지만 ',
                '시간이 지나면서 새로운 기본 가정과 삶의 규칙을 얘기하는 게 더 자연스럽게 느껴지기 시작',
                '할 거예요. 의식적으로 새로운 기본 가정을 떠올리고 스스로 선택한 삶의 규칙을 지키면서 생활하다 보면 서서히 긍정적인 경험이 쌓이게 된답니다. 긍정적인 경험을 더 자주 할수록 새로운 가정과 삶의 규칙을 내 것으로 만들 수 있을 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '조금씩 바꿀 수 있을 것 같아요',
    },
    {
      title: '열심히 해보기로 약속해요',
      image: wb20_8,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '더 유익한 기본 가정을 세워 나가는 과정이 간단하게 보일 수 있지만 생각과 사고 패턴을 바꾸는 게 그리 만만한 일은 아니랍니다. 시간과 많은 노력이 들죠. 하지만 그만큼 가치 있는 일이에요. 그러니 열심히 해보기로 약속해요. 많은 것들을 바꿀 수 있을 거예요.',
                <br />,
                <br />,
                <span />,
                '규칙 바꾸기를 더 효과적으로 진행할 수 있도록 ',
                '생각을 다듬는 ‘규칙 카드’ 도구를 제공해드릴 거예요.',
                ' 기존 규칙을 살펴본 후 새로운 규칙을 세우면 ',
                '원하는 시간에 알람',
                '을 보내드릴 거예요. 그러면 그때 인사이드에 들어와서 ',
                '새로운 규칙을 소리내어 읽어보면 된답니다.',
                ' 오늘부터 바로 시작해 보기로 해요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요. 도구 보여주세요!',
      submit: 'NewRule',
    },
  ],
  [
    {
      title: '생각의 발자취를 따라가며',
      fullImage: wb15_1,
      height: 253,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이번 코스에서는 기본 가정, 예상, 그리고 삶의 규칙에 대해 살펴보았답니다. 다시 한번 복습해 보면, 자동적 사고는 우리들의 기본 가정에서 출발했었죠. 기본 가정은 우리들가 살아가며 어떠한 예상을 하도록 만들고, 우리는 그러한 예상을 바탕으로 삶의 규칙을 만든답니다. 그리고 우리는 기본 가정과 삶의 규칙을 바탕으로 삶을 바라보죠. ',
                '자동적 사고',
                '는 바로 그러한 ',
                '가정과 규칙에서부터 비롯',
                '된답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '가정과 규칙, 기억나요!',
    },
    {
      id: 1,
      image: wb21_1,
      bg: '#4B91FB',
      title: '가정과 규칙은 중요하답니다',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '기본 가정과 규칙을 찾아보는 과정은 매우 중요한 일이랍니다. ',
                '내 생각이 어디서부터 시작되는지 알 수 있고, 그것을 통해서 나의 감정, 특히 나를 불편하게 하는 감정들이 어디서 비롯되는지 이해할 수 있기 때문',
                '이에요. 지피지기 백전백승이라는 말 들어보셨나요? 감정 또한 마찬가지랍니다. 어디서 찾아오는지 모르는 감정은 대처하기가 어려워요. 하지만 감정이 발생하는 이유를 잘 알고 있다면 조금 더 현명하게 대처할 수 있죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: '나를 더 잘 이해할 수 있는 거군요',
      link: '',
    },
    {
      image: wb21_2,
      bg: '#FB8A4B',
      category: '인사이드 이야기',
      title: ['새로운 가정과 규칙을 만드는 것도 중요해요'],
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '내 생각을 만드는 기본 가정과 규칙을 찾았다고 하더라도 그러한 생각들을 고칠 수 있도록 노력해야겠죠. 조금 더 정확하게는 ',
                '부정적인 가정과 규칙들을 나에게 더 좋은 영향을 주는 가정과 규칙으로 대체',
                '하는 거예요. 나만의 긍정적인 자동적 사고를 만들기 위해서요!',
              ]}
            />
          ),
        },
      ],
      buttonText: '노력할 수 있는 것들이 있다니 좋아요',
      link: '',
    },
    {
      category: '인사이드 이야기',
      image: wb21_3,
      bg: '#FFF3D3',
      title: ['규칙 카드를 활용해 보세요'],
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '규칙 카드를 활용하면 더 쉬울 거예요. ',
                '규칙 카드',
                '에서 나의 ',
                '기본 가정과 규칙들을 확인하고 이를 더 건설적인 생각들로 바꿔보세요.',
                ' 그리고 하루에 몇 번씩 새로운 가정과 규칙들을 소리내어 읽어보는 거예요. 정말 간단한 일이지만 생각처럼 쉽진 않을 거랍니다. 그래도 생각 다듬기 도구를 활용하면 더 효과적으로 연습을 할 수 있을 거예요. 저희가 도와드릴게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '열심히 이용해 볼게요',
      link: '',
    },
    {
      image: wb21_4,
      bg: '#FBBF4B',
      title: ['벌써 많은 것들을 배웠죠?'],
      texts: [
        {
          text: [
            'ABCDE 모델에 기반한 감정노트와 회고노트부터 생각 다듬기까지, 정말 많은 것들을 배웠어요. 이제 당분간 저희가 제공해드린 도구들을 활용하여 내 마음을 살펴보도록 해요. 그리고 그러한 과정에 어느 정도 익숙해졌을 때, 우리 함께 다음 코스로 넘어가보도록 해요. 모르는 게 있다면 언제든지 코치에게 문의주시고요 :)',
            <br />,
            <br />,

            '그러면 다음 코스에서 만나요.',
          ],
        },
      ],
      buttonText: '이번에도 많이 배웠어요',
    },
  ],
];

export default course2Data;
