/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { MultipleSelectButton } from '../../stories/button/MultipleSelectButton';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import { CoachingContext } from '../../coachingContext';
import { changeHeader } from '../../utils/webview';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function CoachingLeave0(props) {
  const context = useContext(CoachingContext);
  const [buttonState, setButtonState] = useState('INACTIVE');
  const options = [
    '문제가 해결되었어요',
    '코칭으로는 나아질 수 없어요',
    '코치 선생님이 별로였어요',
    '비용이 부담돼요',
    '기타',
  ];

  const [answer, setAnswer] = useState([]);

  const goNext = useNavigateWithParams(`/coachingLeave/1`);

  useEffect(() => {
    changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'none' });
  }, []);

  function select(option) {
    const selected = option;
    if (answer.includes(selected)) {
      let filteredArray = answer.filter((item) => item !== selected);
      setAnswer(filteredArray);
    } else {
      setAnswer((answer) => [...answer, selected]);
    }
  }

  function isClicked(option) {
    if (option !== '') {
      console.log('이미클릭함');
      select(option);
    }
  }

  function submit() {
    console.log(answer);
    context.setLeaveReasonChoiceList(answer);
    goNext();
  }

  useEffect(() => {
    if (answer.length !== 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [answer]);

  const Element = (
    <div className={`h-full  pt-[43px] pb-[30px] px-[20px] `}>
      {options.map((option, index) => (
        <MultipleSelectButton
          key={index}
          selected={answer.includes(option)}
          option={option}
          text={option}
          isClicked={isClicked}
        />
      ))}
    </div>
  );

  return (
    <div className={`w-screen h-screen bg-white overflow-hidden `}>
      <div className={`h-full `}>
        <TopTitleBottomAction
          title={'코칭을 그만둔 이유는 무엇인가요?'}
          content={Element}
          buttonText={'다음'}
          buttonState={buttonState}
          action={submit}
        />
      </div>
    </div>
  );
}
