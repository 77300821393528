import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'; // basic
import SwiperCore, { Navigation, Pagination } from 'swiper';
// import 'swiper/css'; //basic
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import './GoalSwiper.css';
import GoalSwiperContent from './GoalSwiperContent';
export default function GoalSwiper(props) {
  const { steps } = props;

  SwiperCore.use([Navigation, Pagination]);
  return (
    <div id={'goalswiper'}>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        navigation
        pagination={{ clickable: false }}
        breakpoints={{
          768: {
            slidesPerView: 3,
          },
        }}
      >
        {steps.map((step, index) => (
          <SwiperSlide key={index}>
            <GoalSwiperContent step={step} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
