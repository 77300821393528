import { useEffect, useRef, useState } from 'react';
import { checkTimeByHour } from '../../../logic/method';
import { NotificationSettingCard } from './NotificationSettingCard';

export function NotificationSettingSection({ state, setState, timeList, type }) {
  const cardRef = useRef([]);
  const divRef = useRef();
  const isLock = useRef(false);

  const [selected, setSelected] = useState();

  useEffect(() => {
    if (divRef && divRef.current) {
      divRef.current.focus();
    }
  }, []);

  const list = Array(2).fill(['13:00', '19:00', '22:00', '08:00', '13:00']).flat();

  const hanldeClick = () => {
    let array = state[type].timeList ? [...state[type].timeList] : [];
    const lastTime = array.length === 0 ? '오후' : checkTimeByHour(array[0].split(':')[0]);

    let nextTime = '';
    let currentIdx = 1;

    switch (lastTime) {
      case '오전':
        currentIdx = 0;
        break;
      case '오후':
        currentIdx = 1;
        break;
      case '저녁':
        currentIdx = 2;
        break;
      case '밤':
        currentIdx = 3;
        break;
      default:
        break;
    }

    const newArray = list.slice(currentIdx, currentIdx + 4);
    newArray.forEach((data, index) => {
      if (!array.includes(data)) {
        if (nextTime === '') {
          nextTime = data;
        }
      }
    });

    if (nextTime !== '') {
      array.push(nextTime);
      setSelected(nextTime);
    }

    setState((state) => {
      const updated = { ...state };
      updated[type] = {
        timeList: array,
      };
      return updated;
    });
  };

  const AddButton = () => (
    <div onClick={hanldeClick} className="py-[16px] bg-white rounded-[16px] font-bold text-center">
      추가하기
    </div>
  );

  return (
    <div className="flex flex-col gap-[12px] text-[17px] leading-[24px] text-[#26282C]">
      <p className="font-light">{type === 'worryNote' ? '걱정기록 알림 ' : '세션 알림'}</p>
      {timeList && (
        <div
          ref={divRef}
          className="flex flex-col gap-[12px] select-none"
          tabIndex="0"
          onFocus={() => {
            isLock.current = false;
          }}
          onBlur={() => {
            isLock.current = true;
            setTimeout(() => {
              if (isLock.current) {
                setSelected();
              }
            }, 300);
          }}
        >
          {timeList.map((item, index) => (
            <NotificationSettingCard
              key={`${type}${item}`}
              ref={(element) => {
                cardRef.current[index] = element;
              }}
              state={state}
              setState={setState}
              id={index}
              time={item}
              setSelected={setSelected}
              isSelected={selected === item}
              type={type}
            />
          ))}
        </div>
      )}
      <AddButton />
    </div>
  );
}
