/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context';
import SelfCheckTemplate from '../selfCheck/SelfCheckTemplate';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

function Onboarding2Template(props) {
  const { exam_id, qc_id } = props;

  const context = useContext(AuthContext);
  const [answer, setAnswer] = useState('');
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    console.log('current: ', context.selfcheckAnswer);
    if (
      context.selfcheckAnswer.find(
        (element) => element.selfCheckId === context.selfcheckQuestion[exam_id].selfCheckId,
      ) &&
      context.selfcheckAnswer.find((element) => element.selfCheckId === context.selfcheckQuestion[exam_id].selfCheckId)
        .selfCheckQcIdList[qc_id - 1]
    ) {
      console.log(
        '체크햇음',
        context.selfcheckAnswer,
        context.selfcheckAnswer.find(
          (element) => element.selfCheckId === context.selfcheckQuestion[exam_id].selfCheckId,
        ),
      );
      const selected = context.selfcheckAnswer.find(
        (element) => element.selfCheckId === context.selfcheckQuestion[exam_id].selfCheckId,
      ).selfCheckQcIdList[qc_id - 1];
      console.log(selected.selfCheckChoiceId);
      setAnswer(selected.selfCheckChoiceId);
    }
  }, []);
  const goEndTest = useNavigateWithParams(`/endtest`);
  const goNextTest = useNavigateWithParams(`/onboarding2/${exam_id + 1}/0`);
  const goNextQuestion = useNavigateWithParams(`/onboarding2/${exam_id}/${qc_id + 1}`);

  function select(option) {
    console.log('answers: ', context.selfcheckAnswer, context.selfcheckQuestion[exam_id].selfCheckId);
    setAnswer(option.selfCheckChoiceId);
    if (
      context.selfcheckAnswer.find(
        (element) => element.selfCheckId === context.selfcheckQuestion[exam_id].selfCheckId,
      ) === undefined
    ) {
      console.log('없어', context.selfcheckQuestion[exam_id].qcList[qc_id - 1].selfCheckQuestionId);
      var arr1 = context.selfcheckAnswer.slice();
      arr1.push({
        selfCheckId: context.selfcheckQuestion[exam_id].selfCheckId,
        selfCheckQcIdList: [
          {
            selfCheckChoiceId: option.selfCheckChoiceId,
            selfCheckQuestionId: context.selfcheckQuestion[exam_id].qcList[qc_id - 1].selfCheckQuestionId,
          },
        ],
      });

      context.setSelfcheckAnswer(arr1);
    } else {
      console.log('리스트에만 추가', context.selfcheckAnswer);
      var arr = context.selfcheckAnswer
        .find((element) => element.selfCheckId === context.selfcheckQuestion[exam_id].selfCheckId)
        .selfCheckQcIdList.slice();
      arr[qc_id - 1] = {
        selfCheckChoiceId: option.selfCheckChoiceId,
        selfCheckQuestionId: context.selfcheckQuestion[exam_id].qcList[qc_id - 1].selfCheckQuestionId,
      };
      context.selfcheckAnswer.find(
        (element) => element.selfCheckId === context.selfcheckQuestion[exam_id].selfCheckId,
      ).selfCheckQcIdList = arr;
    }

    setTimeout(() => {
      // console.log('navigate', qc_id, context.selfcheckQuestion[exam_id].qcList.length);

      if (qc_id === context.selfcheckQuestion[exam_id].qcList.length) {
        if (exam_id === context.selfcheckQuestion.length - 1) {
          // navigate(`/endtest`);
          goEndTest();
        } else {
          console.log('다음 검사로');
          // navigate(`/onboarding2/${exam_id + 1}/0`);
          goNextTest();
        }
      } else {
        console.log('다음 문항으로');
        // navigate(`/onboarding2/${exam_id}/${qc_id + 1}`);
        goNextQuestion();
      }
    }, 400);
  }

  function isClicked(option) {
    if (option !== '') {
      // console.log('이미클릭함');
      setDisable(true);
      select(option);
    }
  }

  return (
    // <div className={`w-full h-full relative ${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'}`}>
    //   <div className="w-full   h-[calc((100%-78px)/2)] relative">
    //     <div
    //       className="w-full px-[20px]  font-bold text-[24px] leading-[30px] absolute bottom-[40px] text-center left-[50%] translate-x-[-50%]"
    //       style={{ wordBreak: 'keep-all' }}
    //     >
    //       {context.selfcheckQuestion[exam_id].qcList[qc_id - 1].question}
    //     </div>
    //   </div>

    //   <div className="w-[calc(100%-80px)] flex justify-between min-w-[305px] mx-auto shrink-0">
    //     {context.selfcheckQuestion[exam_id].qcList[qc_id - 1].choices.map((option, index) => (
    //       <SelectButtonCircle
    //         selected={answer === option.selfCheckChoiceId}
    //         option={option}
    //         text={option.choice}
    //         isClicked={isClicked} //버튼 컴포넌트에서 알려줌
    //         disable={disable} // 버튼 컴포넌트로 알려줌
    //       />
    //     ))}
    //   </div>
    // </div>
    <SelfCheckTemplate
      question={context.selfcheckQuestion[exam_id].qcList[qc_id - 1].question}
      optionList={context.selfcheckQuestion[exam_id].qcList[qc_id - 1].choices}
      answer={answer}
      isClicked={isClicked}
      disable={disable}
    />
  );
}

export default Onboarding2Template;
