import React, { useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Title } from '../../stories/text/Title';
import axios from '../../utils/axios2';
import { AuthContext } from '../../context';

export default function CoachMatching2(props) {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  function goNext() {
    navigate(`/finishOnboarding`);
  }
  useEffect(() => {
    submitCoachingStyle();
    setTimeout(() => {
      goNext();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitCoachingStyle = useCallback(async () => {
    try {
      const coachingData = JSON.stringify({ preferredCoachingStyle: context.coachingStyle });
      console.log(coachingData);
      const response = await axios(3, context.authToken, context.appId).post(
        `/onboarding/coaching-style/submit`,
        coachingData,
      );
      console.log('코칭 스타일 제출', response);
    } catch (error) {
      console.error('result', error);
    }
  }, []);

  return (
    <div className={`w-screen h-full `}>
      <div className="w-full h-full px-[20px] ">
        <div className={`h-full flex flex-col`}>
          <div>
            <div className="pt-[12px] mb-[15px]">
              <Title title={['코치가 매칭되면 채팅으로 인사드릴 거예요']} />
            </div>
            <div className="text-[17px] leading-[23px]  font-normal text-[#4E4F53]">
              코치는 6월부터 차례대로 배정돼요.
            </div>
          </div>
          <div className="flex-1 relative">
            <div className=" absolute top-[30%]  left-[50%] translate-x-[-50%]  ">
              <div
                className="w-[148px] h-[148px] rounded-[148px]  
              flex items-center justify-center  relative"
              >
                <div className="w-full h-full rounded-[148px] bg-spinning animate-spin-2s" />
                <div className="absolute top-[3px] w-[142px] h-[142px] rounded-[142px] bg-white flex items-center justify-center">
                  <div className="w-[120px] h-[120px] rounded-[120px] overflow-hidden bg-[#EFF2FF] flex justify-center items-center">
                    <svg width="24" height="37" viewBox="0 0 24 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.318359 10.6582H6.93457C7.08105 7.89941 8.93652 6.11719 11.7441 6.11719C14.5273 6.11719 16.3828 7.80176 16.3828 10.1211C16.3828 12.4404 15.4307 13.6367 12.2812 15.5166C8.91211 17.4941 7.56934 19.6914 7.88672 23.5244L7.93555 24.9404H14.4053V23.6465C14.4053 21.2539 15.3086 20.0576 18.5557 18.1777C21.998 16.1514 23.7803 13.5879 23.7803 9.90137C23.7803 4.33496 19.166 0.453125 12.1836 0.453125C4.68848 0.453125 0.44043 4.65234 0.318359 10.6582ZM11.2559 36.6104C13.4775 36.6104 15.2598 34.9258 15.2598 32.7773C15.2598 30.6289 13.4775 28.9443 11.2559 28.9443C9.05859 28.9443 7.27637 30.6289 7.27637 32.7773C7.27637 34.9258 9.05859 36.6104 11.2559 36.6104Z"
                        fill="#98A3E0"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'} w-full`}>
          <PrimaryActionButton state={'ACTIVE'} text={'다음'} action={goNext} />
        </div> */}
      </div>
    </div>
  );
}
