import React, { useState, useEffect } from 'react';
import selfCheckData from '../../data/selfCheckData';
import { getSelfCheckState } from '../selfCheck/selfCheckLogic';
import { motion } from 'framer-motion';

export default function GoalSwiperContentCoaching(props) {
  const { step, recommendData } = props;
  const [goalStep, setGoalStep] = useState('');
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const selfCheckInfo = selfCheckData.find((element) => element.selfCheckName === step.selfCheckName);
    setGoalStep(selfCheckInfo.result[getSelfCheckState(selfCheckInfo.selfCheckId, parseInt(step.score * 0.45))].label);
  }, [step.selfCheckName]);

  useEffect(() => {
    setHide(false);
    setTimeout(() => {
      setHide(true);
    }, 3000);
  }, [step.selfCheckName]);

  const graphExposeRange = 54;

  return (
    <div className="px-[20px]  h-full absolute top-[20px] w-full left-0">
      <div className="mb-[10px]">
        <p className="text-[15px] leading-[21px] font-bold text-[#26282C]  text-center">{step.selfCheckName}</p>
      </div>
      <motion.div
        key={step.selfCheckName}
        className="h-[98px] pb-[10px] pt-[20px] border-b-[#E1E4EB]  border-b-[1px] relative"
        // initial={{ opacity: 0 }}
        // animate={{ opacity: hide ? 0 : 1 }}
        // exit={{ opacity: 0 }}
        // transition={{ duration: hide ? 0.5 : 0.3 }}
      >
        <div className="h-full  flex justify-center  relative">
          <div
            className=" z-0 w-[2px] absolute left-[50%] [-72px] bottom-[-10px]  overflow-hidden transition-all"
            style={{ height: (graphExposeRange * step.scorePercentage) / 100 + 10 }}
          >
            <svg
              width="2"
              height={`${(graphExposeRange * step.scorePercentage) / 100 + 10}`}
              viewBox={`0 0 2 ${(graphExposeRange * step.scorePercentage) / 100 + 10}`}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={`M 1,${(graphExposeRange * step.scorePercentage) / 100 + 10} L 1,0`}
                stroke="#D1D5DC"
                strokeDasharray="4"
              />
            </svg>
          </div>
          <div
            className="z-0 w-[2px] absolute left-[50%] translate-x-[70px] bottom-[-10px] "
            style={{ height: ((graphExposeRange * step.scorePercentage) / 100) * 0.45 + 10 }}
          >
            <svg
              width="2"
              height={`${((graphExposeRange * step.scorePercentage) / 100) * 0.45 + 10}`}
              viewBox={`0 0 2 ${((graphExposeRange * step.scorePercentage) / 100) * 0.45 + 10}`}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={`M 1,${((graphExposeRange * step.scorePercentage) / 100) * 0.45 + 10} L 1,0`}
                stroke="#2C4BEC"
                strokeDasharray="4"
              />
            </svg>
          </div>
          <div
            className={`z-20 absolute left-[50%] translate-x-[calc(-50%-71px)] `}
            style={{ bottom: (graphExposeRange * step.scorePercentage) / 100 }}
          >
            <div className="relative">
              <div className=" w-[14px] h-[14px] rounded-[10px] border-[2px] border-[#EFF2FF] bg-qna" />
              <div className="absolute top-[-19px] left-[50%] translate-x-[-50%] text-[12px] leading-[18px] font-normal text-[#3A3C40]">
                {step.score}
              </div>
            </div>
          </div>
          <div
            className="z-20 absolute left-[50%] translate-x-[calc(-50%+71px)] "
            style={{ bottom: ((graphExposeRange * step.scorePercentage) / 100) * 0.45 }}
          >
            <div className="relative">
              <div className="w-[14px] h-[14px] rounded-[10px] border-[2px] border-[#EFF2FF] bg-qna" />
              <div className="absolute top-[-25px] left-[50%] translate-x-[-50%] text-[15px] leading-[21px] font-normal text-[#3A3C40] mb-[3px] ">
                <div className="w-auto h-[20px] relative overflow-hidden">
                  <div className="w-full h-full rounded-[20px] bg-[#3A3C40] flex justify-center items-center">
                    <p className="px-[8px] text-[12px] leading-[18px] font-bold text-white">
                      {parseInt(step.score * 0.45)}
                    </p>
                  </div>
                </div>
                <div className="absolute left-[50%] translate-x-[-50%] translate-y-[-0.5px]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="4" viewBox="0 0 9 4" fill="none">
                    <path
                      d="M5.61473 3.08957C4.81435 4.07465 3.31065 4.07465 2.51027 3.08957L0 0L8.125 2.33473e-07L5.61473 3.08957Z"
                      fill="#3A3C40"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className=" absolute " style={{ bottom: ((graphExposeRange * step.scorePercentage) / 100) * 0.45 }}>
            <div className="relative " style={{ height: ((graphExposeRange * step.scorePercentage) / 100) * 0.55 }}>
              <div className="translate-y-[-9px]">
                <svg
                  width="144"
                  height={`${((graphExposeRange * step.scorePercentage) / 100) * 0.55 + 5}`}
                  viewBox={`0 0 144 ${((graphExposeRange * step.scorePercentage) / 100) * 0.55 + 5}`}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d={
                      step.score === 0
                        ? `M 0,2 L 144,2`
                        : `M 0,2 C 60 0 81 ${((graphExposeRange * step.scorePercentage) / 100) * 0.55 - 2 + 5} 144 ${
                            ((graphExposeRange * step.scorePercentage) / 100) * 0.55 - 2 + 5
                          }`
                    }
                    stroke="url(#paint0_radial_317_8762)"
                    strokeWidth="3px"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_317_8762"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(143.759 40.088) rotate(-171.957) scale(145.187 692.013)"
                    >
                      <stop stopColor="#5513F0" />
                      <stop offset="1" stopColor="#2C4BEC" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <p className="text-[14px] leading-[20px] text-center font-bold mt-[10px]">
        <span className="text-[#2C4BEC]">{goalStep}</span> 범위로 개선해요
      </p>
    </div>
  );
}
