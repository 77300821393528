/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { PATH } from '../../routes/paths';
import { useEffect } from 'react';
import { habitData } from '../../data/habit/habitData';
import { HabitResult } from './HabitResult';
import { HabitIntro } from './HabitIntro';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import webviewToast from '../../development/webviewToast';
import { getTimeZone } from '../../logic/method';

export default function Main({ resultList }) {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  //   const setMixPanel4 = useMixpanelWeb({
  //     eventName: '4_information',
  //   });

  const [userId, setUserId] = useState('');

  const [identify, getIdentify] = useFetchAnxy({
    url: `/habit-streak/identify?timezone=${getTimeZone(new Date())}`,
    bg: '#FFFFFF',
  });

  const goToIdUrl = useNavigateWithParams(`${PATH.marketing.habit}?id=${userId}`);

  const [selectedId, setSelectedId] = useState(0);

  const handleSelect = () => {
    if (selectedId < 4) {
      setSelectedId((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (userId) {
      setTimeout(() => {
        goToIdUrl();
      }, 2000);
    }
  }, [userId]);

  useEffect(() => {
    if (identify) {
      setUserId(identify.result.id);
    }
  }, [identify]);

  useEffect(() => {
    const root = document.getElementById('root');
    if (selectedId !== 0) {
      root.style.position = 'fixed';
      root.style.overflow = 'hidden';
      if (selectedId === 4) {
        getIdentify();
      }
    } else {
      root.style.position = 'relative';
      root.style.overflow = 'auto';
    }
  }, [selectedId]);

  return (
    <div
      className="z-[100] font-poppins"
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
        // minHeight: '-webkit-fill-available',
      }}
    >
      {id ? (
        <HabitResult bgColor="#131635" action={handleSelect} />
      ) : (
        <HabitIntro
          content={habitData[selectedId].content}
          buttonText={habitData[selectedId].buttonText}
          bgColor="#131635"
          action={handleSelect}
        />
      )}
    </div>
  );
}
