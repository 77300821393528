import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { CheckLottie } from '../../stories/animation/CheckLottie';
import { goToScreen, goToTab, showWebView } from '../../utils/webview';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { PressedEffect } from '../../stories/button/PressedEffect';
import { CoachingContext } from '../../coachingContext';
import activityData from '../../data/activityData';
import toolData from '../../data/toolData';
import UnlockLottie from './UnlockLottie';
import selfCheckData from '../../data/selfCheckData';
import webviewToast from '../../development/webviewToast';

export default function TodoItem({ item, index }) {
  const {
    id,
    title,
    subTitle,
    isDone,
    isLock,
    webToAppEvent,
    counseling,
    coaching,
    selfCheckId,
    isFirstUnlocked,
    activity,
    toolId,
  } = item;

  console.log(item);

  const context = useContext(CoachingContext);
  const [disappear, setDisappear] = useState(false);
  const [isLockTouched, setIsLockTouched] = useState(false);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isLockTouched) {
      setTimeout(() => {
        setIsLockTouched(false);
      }, 1200);
    }
  }, [isLockTouched]);

  const goLink = () => {
    if (isLock) {
      setIsLockTouched(true);
    }

    if (!isDone && !isLock) {
      switch (webToAppEvent.eventName) {
        case 'showWebView': {
          const message = JSON.parse(webToAppEvent.body);
          console.log('message: ', message);
          showWebView(message);
          break;
        }
        case 'goEmotionalRecordStart': {
          console.log('emo');
          goToScreen({
            screenName: 'emotionalRecord',
          });
          break;
        }

        case 'goCounselingRoom': {
          goToScreen({
            screenName: 'counselingRoom',
            ...JSON.parse(webToAppEvent.body),
          });
          break;
        }
        case 'goCounselingReview': {
          goToScreen({
            screenName: 'counselingReview',
            ...JSON.parse(webToAppEvent.body),
          });
          break;
        }
        case 'goRetrospectNote': {
          // goRetrospectNote();
          goToScreen({
            screenName: 'retrospectNote',
          });
          break;
        }
        case 'goRuleCard': {
          // goRuleCard();
          goToScreen({
            screenName: 'ruleCard',
          });
          break;
        }
        case 'goToTab': {
          const message = JSON.parse(webToAppEvent.body);
          goToTab(message);
          break;
        }
        default: {
          console.log('error');
          break;
        }
      }
    }
  };

  const variants2 = {
    visible: {
      opacity: 1,
    },
  };

  const delay = 0.4 * id;

  useEffect(() => {
    setTimeout(() => {
      setDisappear(true);
    }, 100);
  }, [isMountedRef]);

  const courseId = activity && activity.activityId && activity.activityId.split('-')[1].split('_')[0];
  const activityResource =
    activity &&
    activity.activityId &&
    activityData
      .filter((item) => item.courseId === parseInt(courseId))[0]
      .activityList.filter((item) => item.activityId === activity.activityId)[0];

  const toolResource =
    (activity && activity.toolId && toolData.filter((item) => item.toolId === activity.toolId)[0]) ||
    (toolId && toolData.filter((item) => item.toolId === toolId)[0]);

  return (
    <PressedEffect
      element={
        <div
          className={`w-full ${isDone ? 'pointer-events-none ' : ''}
      `}
        >
          <motion.div
            animate={{
              opacity: isDone && disappear ? 0 : 1,
              height: isDone && disappear ? 0 : '',
            }}
            variants={variants2}
            transition={{ ease: 'easeInOut', duration: 0.3, delay: 1 }}
          >
            <div className="w-full h-[10px]" />
            <div
              className={`shadow-counselorcard rounded-[20px] mx-auto`}
              style={{
                backgroundColor:
                  (activity && activity.toolId) || toolId
                    ? toolResource.backgroundColor
                    : selfCheckId
                    ? '#EFF2FF'
                    : '#FFFFFF',
              }}
            >
              <div className={`w-full px-[20px] h-[92px] pb-[1px] flex item-center justify-between border-[#F3F5F8]`}>
                <div className="flex items-center w-auto">
                  {!isLock && !isFirstUnlocked && (
                    <div
                      className="w-[24px] h-[24px] rounded-[100%] bg-white relative"
                      style={{ border: '1px solid #373EED' }}
                    >
                      {isDone && (
                        <div className="absolute top-[-1.5px] left-[-2px]">
                          <CheckLottie containerSize={'26px'} gradient={true} />
                        </div>
                      )}
                    </div>
                  )}
                  {isFirstUnlocked && (
                    <motion.div
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.2, delay: 1.2 }}
                      className="opacity-0 w-[24px] h-[24px] rounded-[100%] bg-white"
                      style={{ border: '1px solid #373EED' }}
                    />
                  )}
                  {isFirstUnlocked && (
                    <motion.div
                      className="absolute left-[20px] top-[44px]"
                      animate={{ opacity: 0 }}
                      transition={{ duration: 0.2, delay: 1.2 }}
                    >
                      <UnlockLottie />
                    </motion.div>
                  )}
                  {isLock && (
                    <div className="w-[24px] flex justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.38999 6.61019H8.47473V4.23728C8.47473 2.83317 7.33647 1.69491 5.93236 1.69491C4.52825 1.69491 3.38999 2.83317 3.38999 4.23728V6.61019ZM1.69508 6.65743C1.45481 6.68883 1.26287 6.74112 1.09202 6.82817C0.715695 7.01992 0.409734 7.32588 0.217987 7.70221C0 8.13003 0 8.69008 0 9.81019V11.8C0 12.9201 0 13.4802 0.217987 13.908C0.409734 14.2843 0.715695 14.5903 1.09202 14.782C1.51984 15 2.0799 15 3.2 15H8.66438C9.78449 15 10.3445 15 10.7724 14.782C11.1487 14.5903 11.4546 14.2843 11.6464 13.908C11.8644 13.4802 11.8644 12.9201 11.8644 11.8V9.81019C11.8644 8.69008 11.8644 8.13003 11.6464 7.70221C11.4546 7.32588 11.1487 7.01992 10.7724 6.82817C10.6016 6.74116 10.4098 6.68888 10.1696 6.65747V4.23728C10.1696 1.8971 8.27254 0 5.93236 0C3.59217 0 1.69508 1.8971 1.69508 4.23728V6.65743Z"
                          fill="#26282C"
                        />
                      </svg>
                    </div>
                  )}
                  <motion.div className="ml-[15px]">
                    <motion.p
                      className="absolute top-[50%] translate-y-[calc(-50%+4px)] text-[14px] leading-[20px] text-[#6B6D76]  opacity-0"
                      animate={{ opacity: isLockTouched ? 1 : 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      이전 활동을 먼저 마무리 하세요
                    </motion.p>
                    <motion.div
                      animate={{ opacity: isLockTouched ? 0 : 1 }}
                      transition={{ duration: 0.2 }}
                      style={{ width: activity && `calc(${window.innerWidth}px - 164px)` }}
                    >
                      {activity ? (
                        <p
                          className={`font-bold text-[16px] leading-[22px] text-[#26282C] `}
                          style={{
                            width: 'calc(100% - 11px)',
                            whiteSpace: 'noWrap',
                            display: 'block',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {title}
                        </p>
                      ) : (
                        <p className={`font-bold text-[16px] leading-[22px] text-[#26282C] `}>{title}</p>
                      )}
                      <p className="font-semibold mt-[3px] text-[#6B6D76] text-[14px] leading-[20px]">{subTitle}</p>
                    </motion.div>
                  </motion.div>
                </div>
                <div className="flex items-center">
                  {counseling && counseling.buttonText && (
                    <div className="flex justify-center items-center text-[#3953D9] bg-[#EFF2FF] w-[78px] h-[38px] rounded-[10px] text-[15px] leading-[18px]">
                      {counseling.buttonText}
                    </div>
                  )}
                  {counseling && counseling.counselorProfileImageUrl && (
                    <div
                      className="rounded-[100%] h-[52px] w-[52px] overflow-hidden"
                      style={{ border: '1px solid rgba(0, 0, 0, 0.04)' }}
                    >
                      <img src={counseling.counselorProfileImageUrl} alt="empty" className="object-contain" />
                    </div>
                  )}
                  {coaching && coaching.coachProfileImageUrl && (
                    <div
                      className="rounded-[100%] h-[52px] w-[52px] overflow-hidden"
                      style={{ border: '1px solid rgba(0, 0, 0, 0.04)' }}
                    >
                      <img src={coaching.coachProfileImageUrl} alt="empty" className="object-contain" />
                    </div>
                  )}
                  {activity && activity.activityId && !activity.toolId && (
                    <div className="rounded-[100%] h-[45px] w-[45px] overflow-hidden">
                      <img
                        src={activityResource.image}
                        style={{ backgroundColor: activityResource.backgroundColor }}
                        alt="empty"
                        className="object-contain"
                      />
                    </div>
                  )}
                  {activity && activity.toolId && (
                    <div className="h-[45px] w-[45px] overflow-hidden">
                      <img
                        src={toolResource.image}
                        style={{
                          backgroundColor: toolResource.backgroundColor,
                        }}
                        alt="empty"
                        className="object-contain"
                      />
                    </div>
                  )}
                  {toolId && (
                    <div className="h-[45px] w-[45px] overflow-hidden">
                      <img
                        src={toolResource.image}
                        style={{
                          backgroundColor: toolResource.backgroundColor,
                        }}
                        alt="empty"
                        className="object-contain"
                      />
                    </div>
                  )}
                  {selfCheckId && (
                    <div className="h-[50px] w-[50px] text-black relative right-[-3px] ">
                      <img
                        src={selfCheckData.filter((item) => item.selfCheckId === selfCheckId)[0].image_card}
                        alt="empty"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      }
      disable={isDone}
      action={goLink}
    />
  );
}
