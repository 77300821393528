import { useSearchParams } from 'react-router-dom';

export const useGetWorkbookParams = () => {
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appId = searchParams.get('app_id');
  const activityKey = searchParams.get('activity_key');
  const isFirst = searchParams.get('isFirst') === 'true' || false;

  return [authToken, appId, activityKey, isFirst];
};
