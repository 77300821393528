import React, { useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import WorkbookContent from '../components/workbook/WorkbookContent';
import { CoachingContext } from '../coachingContext';
import { changeHeader } from '../utils/webview';
import workbookProgressData from '../data/workbookProgressData';
import course3Data from '../data/course3Data';
import course2Data from '../data/course2Data';
import course0Data from '../data/course0Data';
import course4Data from '../data/course4Data';
import course1Data from '../data/course1Data';
import course5Data from '../data/course5Data';
import useFetch from '../hooks/useFetch';
import useSessionStorage from '../hooks/useSessionStorage';
import webviewToast from '../development/webviewToast';

function Workbook() {
  let { id, page } = useParams();
  const context = useContext(CoachingContext);

  const [hygieneInfo, getHygieneInfo] = useFetch({
    url: '/sleep/hygiene/cas/load',
    bg: '#FFFFFF',
  });

  const [casList, setCasList] = useSessionStorage('casList', []);
  const [isCasFirst, setIsCasFirst] = useSessionStorage('isCasFirst', false);

  const filterObjectByValue = (obj, predicate) =>
    Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ((res[key] = obj[key]), res), {});

  const casInfo = [
    {
      type: 'isNoDrinkCoffee',
      title: '커피는 오전 시간에만, 아메리카노 한 잔 이하로 마셨나요?',
      buttonText: '커피를 마시지 않아요',
      id: 4,
    },
    {
      type: 'isNoDrinkAlcohol',
      title: '알코올은 아예 마시지 않거나, 피할 수 없는 경우에는 한 잔 이하로만 마셨나요?',
      buttonText: '술을 마시지 않아요',
      id: 5,
    },
    { type: 'isNoSmoking', title: '취침 2시간 전부터는 금연하였나요?', buttonText: '흡연을 하지 않아요', id: 6 },
  ];

  useEffect(() => {
    if (hygieneInfo) {
      const filterByValue = filterObjectByValue(hygieneInfo.result, (value) => value === false);
      const casListByKey = Object.keys(filterByValue);

      console.log('result is', Object.keys(hygieneInfo.result).length, hygieneInfo.result);
      const filteredItem = casInfo.filter((item) => casListByKey.includes(item.type));
      console.log(Object.keys(hygieneInfo.result).length);
      if (Object.keys(hygieneInfo.result).length === 0) {
        setCasList(casInfo);
        setIsCasFirst(true);
      } else {
        if (filteredItem.length === 0) {
          setCasList(casInfo);
        } else {
          setCasList(casInfo.filter((item) => casListByKey.includes(item.type)));
        }
      }
      const notDoingLength = Object.values(hygieneInfo.result).filter((item) => item === true).length;
      context.setProgressSum(content.length - notDoingLength);
    }
  }, [hygieneInfo]);

  let content = [];

  // 수면 코스
  if (id > 47) {
    content = course5Data[id - 48];
  } else if (id > 37) {
    content = course4Data[id - 38];
  } else if (id > 31) {
    content = course0Data[id - 32];
  } else if (id > 20) {
    content = course3Data[id - 21];
  } else if (id > 13) {
    content = course2Data[id - 14];
  } else {
    // 감정을 만드는 생각 찾기
    content = course1Data[id];
  }

  useEffect(() => {
    const currentPage = parseInt(page);
    if (content.find((e) => e.isHygiene) && content.find((e) => e.isHygiene).length !== 0) {
      if (page > 11) {
        const notDoingLength = casList.length !== 0 ? 3 - casList.length : 0;
        context.setCurrent(currentPage - notDoingLength);
      } else {
        context.setCurrent(currentPage);
      }
    } else {
      context.setCurrent(currentPage);
    }
  }, [page, casList]);

  useEffect(() => {
    const item = workbookProgressData.filter((item) => item.courseId === parseInt(id))[0];
    console.log(item);
    document.title = item.name;
    if (content.find((e) => e.isHygiene) && content.find((e) => e.isHygiene).length !== 0) {
      if (casList.length === 0) {
        getHygieneInfo();
      }
    } else {
      context.setProgressSum(content.length);
    }
  }, [id]);

  useEffect(() => {
    // window.localStorage.clear();
  }, []);

  return <WorkbookContent content={content[page - 1]} />;
}

export default Workbook;
