import { atom } from 'recoil';

const breathToolAtom = atom({
  key: 'breathTool',
  default: {
    initialScore: 50,
    finalScore: 50,
  },
});

export default breathToolAtom;
