import { useRecoilState } from 'recoil';
import defaultState from '../../data/inside/defaultState';
import useSessionStorage from '../../hooks/useSessionStorage';
const ObjectiveResult = ({ index, name, title, placeholder, isEntered, isAndroid, autoFocus }) => {
  const [insideState, setInsideState] = useSessionStorage('insideState', defaultState);

  return (
    <div className="text-white px-[20px] py-[40px] flex flex-col gap-[16px]">
      <div
        className="w-full p-[24px] rounded-[16px]"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      >
        <p className="text-white text-[18px] leading-[21.6px] font-semibold mb-[8px]">{'나의 목표'}</p>
        <div>
          {insideState.answerIdList.map((data) => (
            <div className="flex items-start opacity-[0.5] text-[18px] leading-[21.6px] mb-[3px]">
              <span className="px-[8px]">•</span>
              <div>{data.text}</div>
            </div>
          ))}
        </div>
      </div>
      {insideState.worryList.length !== 0 && insideState.worryList[0] !== '' && (
        <div
          className="w-full p-[24px] rounded-[16px]"
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          }}
        >
          <p className="text-white text-[18px] leading-[21.6px] font-semibold mb-[8px]">{'내가 극복할 장애물'}</p>
          <div>
            {insideState.worryList.map((text) => (
              <div className="flex items-start opacity-[0.5] text-[18px] leading-[21.6px]">
                <span className="px-[8px]">•</span>
                <div>{text}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ObjectiveResult;
