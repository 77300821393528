import React, { useEffect } from 'react';
import AnxyNotch from '../../components/anxy/AnxyNotch';

export function FullPageAnxy(props) {
  const { content, bgColor, noHeader, transparentHeader, fixedRoot } = props;

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
  }, []);

  return (
    <div
      className="h-screen pt-safe"
      style={{
        background: bgColor ? bgColor : '#FFFFFF',
        height: fixedRoot ? 'calc(var(--vh,1vh) * 100)' : '100vh',
        minHeight: fixedRoot ? 'calc(var(--vh.1vh) * 100)' : '100vh',
      }}
    >
      <AnxyNotch notchColor={!transparentHeader && (bgColor || '#FFFFFF')} noHeader={noHeader} />
      <div className={`h-full ${!noHeader && 'pt-[44px]'} flex flex-col  `}>
        {/* <div className={`flex-1  flex flex-col`}>
          <div className="flex-1">{content}</div>
        </div> */}

        <div className={`flex-1  `}>{content}</div>
      </div>
    </div>
  );
}
