/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import { BottomActionAnxy } from 'stories/page/BottomActionAnxy';
import { useSetRecoilState, useRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from 'recoil/common/header/atom';
import userAtom from 'recoil/anxy/user/atom';
import useMixpanelEventAnxy from 'hooks/useMixpanelEventAnxy';
import Wori from 'components/anxy/anxy12ndA/Wori';
import useFetchAnxy from 'hooks/useFetchAnxy';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import { Caption15, SubTitle18 } from 'stories/text/AnxyText';
import { Input } from 'stories/text/Input';
import { SelectButtonDevelop } from 'stories/button/SelectButtonDevelop';
import { Text17 } from 'stories/text/AnxyText';
import hexOpacity from 'hex-opacity';
import { impactFeedback } from 'utils/webview';
import useDidMountEffect from 'hooks/useDidMountEffect';

export default function WorryNote(props) {
  const setHeaderState = useSetRecoilState(headerAtom);
  const [userState, setUserState] = useRecoilState(userAtom);

  const [userInput, setUserInput] = useState({ 원인: '', 신체: [[]], 생각: '', 행동: [[]] });
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [rawOptionList, getOptions] = useFetchAnxy({
    url: 'anxy/v2/worry-note/meta-info/load',
  });

  const [hidePerfectionStepData, hidePerfectionStep] = useFetchAnxy({
    url: 'anxy/v2/worry-note/perfection-step/hide',
  });

  const woriContainerRef = useRef();

  const [score, setScore] = useState(userState.anxietyScore);
  const goHome = useNavigateWithParams(`/anxyHome`);
  const goAlarm = useNavigateWithParams(`/anxyNotification/0?isSession=false`);

  const totalStep = 6;
  const [perfectionStep, setPerfectionStep] = useState();
  const [isHidePerfectionStep, setHidePerfectionStep] = useState();
  const [example, setExample] = useState();

  const setMixpanel_start = useMixpanelEventAnxy({
    eventName: '걱정기록 진입',
  });

  const setMixpanel_done = useMixpanelEventAnxy({
    eventName: '걱정기록 제출',
  });

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
    getOptions();
    setMixpanel_start();
  }, []);

  useEffect(() => {
    if (rawOptionList && rawOptionList.result) {
      setHidePerfectionStep(rawOptionList.result.isHidePerfectionStep);
      setExample([
        { title: '원인', type: 'input', placeholder: '상황을 구체적으로 적어주세요', visible: true },
        {
          title: '신체',
          type: 'select',
          visible: rawOptionList.result.perfectionStep > 1,
          option: rawOptionList.result.bodyChoiceList && rawOptionList.result.bodyChoiceList.concat([['']]),
        },
        {
          title: '생각',
          type: 'input',
          visible: rawOptionList.result.perfectionStep > 2,
          placeholder: '어떤 생각을 했는지 적어주세요',
        },
        {
          title: '행동',
          type: 'select',
          visible: rawOptionList.result.perfectionStep > 3,
          option: rawOptionList.result.behaviorChoiceList && rawOptionList.result.behaviorChoiceList.concat([['']]),
        },
      ]);
      setPerfectionStep(rawOptionList.result.perfectionStep);
      // setPerfectionStep(4);
    }
  }, [rawOptionList]);

  useEffect(() => {
    console.log(userInput);
    if (example) {
      let buttonState = 'ACTIVE';
      example
        .filter((element) => element.visible)
        .forEach((category) => {
          if (typeof userInput[category.title] === 'string' && userInput[category.title] === '') {
            buttonState = 'INACTIVE';
          } else if (Array.isArray(userInput[category.title]) && userInput[category.title].flat().length === 0) {
            buttonState = 'INACTIVE';
          }
        });
      setButtonState(buttonState);
    }
  }, [userInput]);

  useEffect(() => {
    if (example) {
      console.log(example);
      setUserInput((state) => ({
        ...state,
        신체: userInput['신체']
          .filter((element) => typeof element === 'string')
          .concat(
            example.find((element) => element.title === '신체').option && [
              example
                .find((element) => element.title === '신체')
                .option.slice(-1)
                .flat()
                .filter((element) => element !== ''),
            ],
          ),
        행동: userInput['행동']
          .filter((element) => typeof element === 'string')
          .concat(
            example.find((element) => element.title === '행동').option && [
              example
                .find((element) => element.title === '행동')
                .option.slice(-1)
                .flat()
                .filter((element) => element !== ''),
            ],
          ),
      }));
    }
  }, [example]);

  const [saveWorryRecordResult, saveWorryRecord] = useFetchAnxy({
    url: 'anxy/v2/worry-note/submit',
    requestBody: {
      score: score,
      reason: userInput['원인'],
      bodyList: userInput['신체'].flat(),
      thought: userInput['생각'],
      behaviorList: userInput['행동'].flat(),
    },
  });

  useEffect(() => {
    if (saveWorryRecordResult) {
      setUserState((state) => ({ ...state, previousScore: userState.anxietyScore }));

      setMixpanel_done();
      if (saveWorryRecordResult.result.isFirstSubmit) {
        goAlarm();
      } else {
        setTimeout(() => {
          impactFeedback('success');
          setButtonState('DONE');
        }, 200);
      }
    }
  }, [saveWorryRecordResult]);

  useEffect(() => {
    if (buttonState === 'DONE') {
      setTimeout(() => {
        goHome();
      }, 200);
    }
  }, [buttonState]);

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '불안 타입 검사 결과',
    params: {
      체크포인트: '어떻게 관리하나요?',
    },
  });

  useDidMountEffect(() => {
    console.log('score', score);
    impactFeedback('selection');
  }, [score]);

  return (
    <BottomActionAnxy
      content={
        <div className="h-full  ">
          <div className="px-[20px]">
            <div className="pt-[12px] text-[24px] leading-[30px] font-bold ">{'지금 얼마나 불안한가요?'}</div>
            <div className="w-full  pt-[30px] pb-[50px] flex flex-col gap-[20px]  ">
              <div
                className="w-full  relative"
                style={{ height: Math.min(window.innerWidth - 40, 205) * (378 / 211) }}
                ref={woriContainerRef}
              >
                <div
                  className="absolute bottom-0 left-[50%] translate-x-[-50%] origin-bottom "
                  style={{
                    transform: `translateX(-50%) scale(${Math.min(window.innerWidth - 40, 205) / 205})`,
                  }}
                >
                  <Wori score={score} duration={0} />
                </div>
              </div>
              <div className="w-full flex flex-col ">
                <p className="text-[50px] font-bold text-center">{score}</p>
                <div className="flex px-[4px] w-full justify-between text-[15px] leading-[21px] text-[#6B6D76] mb-[8px]">
                  <p>매우 평온</p>
                  <p>매우 불안</p>
                </div>
                <div
                  className="w-full h-[24px] rounded-[50px]  px-[22px]"
                  style={{
                    background: `linear-gradient(90deg, rgba(239, 103, 11) 50%, rgba(253, 240, 231) 50%)`,
                  }}
                >
                  <div
                    className="w-full h-full  relative"
                    style={{
                      background: `linear-gradient(90deg, rgba(239, 103, 11) ${score}%, rgba(253, 240, 231) ${score}%)`,
                    }}
                  >
                    <div
                      className=" w-[44px] h-[44px] rounded-[50%] bg-white ring-[1px] ring-inset ring-[#F3F5F8] absolute top-[50%] translate-x-[-50%] translate-y-[-50%]
                flex items-center justify-center"
                      style={{ left: `${score}%`, boxShadow: '0px 6px 8px -2px rgba(0, 0, 0, 0.2)' }}
                      onTouchMove={(e) => {
                        setScore(
                          Math.round(
                            Math.max(
                              0,
                              Math.min(
                                100,
                                parseInt(((e.changedTouches[0].clientX - 20) / (window.innerWidth - 40)) * 100),
                              ),
                            ) / 10,
                          ) * 10,
                        );
                      }}
                    >
                      <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="9" width="3" height="18" rx="1.5" fill="#D1D5DC" />
                        <rect width="3" height="18" rx="1.5" fill="#D1D5DC" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {example && (
            <div>
              <div className="flex flex-col gap-[40px] mb-[60px]">
                {example.slice(0, perfectionStep).map((category, categoryIndex) => (
                  <div
                    className={`flex flex-col ${category.type === 'select' ? 'gap-[7px]' : 'gap-[12px]'}`}
                    key={`${category.title}${categoryIndex}`}
                  >
                    <SubTitle18 subtitle={category.title} className="px-[20px]" />
                    {category.type === 'input' && (
                      <div className="px-[20px]">
                        <Input
                          placeholder={category.placeholder}
                          value={''}
                          updateValue={(value) => {
                            setUserInput((state) => ({ ...state, [category.title]: value }));
                          }}
                          activeColor={anxyColorPalette.orange}
                          inactiveColor={anxyColorPalette.black}
                          focusAction={() => {
                            console.log('focus');
                            impactFeedback('selection');
                          }}
                        />
                      </div>
                    )}
                    {category.type === 'select' && (
                      <div className="flex  flex-wrap px-[15px]">
                        {category.option.slice(0, -1).map((option, index) => (
                          <div className="m-[5px]">
                            <SelectButtonDevelop
                              key={`${category.title}select${index}`}
                              selected={userInput[category.title].includes(option)}
                              option={option}
                              text={option}
                              isClicked={(option) => {
                                impactFeedback('selection');
                                setUserInput((state) => ({
                                  ...state,
                                  [category.title]: !userInput[category.title].includes(option)
                                    ? [option].concat(userInput[category.title])
                                    : userInput[category.title].filter((element) => element !== option),
                                }));
                              }}
                              activeColor={anxyColorPalette.orange}
                              inactiveColor={anxyColorPalette.black}
                            />
                          </div>
                        ))}

                        {category.option
                          .slice(-1)
                          .flat()
                          .map((option, index) => (
                            <div key={`${category.title}input${index}`}>
                              <Input
                                value={option}
                                updateValue={(value) => {
                                  setExample((example) => {
                                    const newExample = example.slice();
                                    let optionList = newExample.find(
                                      (element) => element.title === category.title,
                                    ).option;
                                    optionList[optionList.length - 1][index] = value;
                                    if (value !== '') {
                                      if (optionList[optionList.length - 1].slice(-1)[0] !== '') {
                                        optionList[optionList.length - 1].push('');
                                      }
                                    } else {
                                      //+버튼 없애기
                                      if (optionList[optionList.length - 1].length > 1) {
                                        console.log('pop');
                                        optionList[optionList.length - 1].pop();
                                      }
                                    }
                                    return newExample;
                                  });
                                }}
                                activeColor={anxyColorPalette.orange}
                                inactiveColor={anxyColorPalette.black}
                                autoWidth
                                deleteIfEmpty
                                deleteAction={() => {
                                  setExample((example) => {
                                    const newExample = example.slice();
                                    let optionList = newExample.find(
                                      (element) => element.title === category.title,
                                    ).option;
                                    optionList[optionList.length - 1].push('');
                                    return newExample;
                                  });
                                }}
                                focusAction={() => {
                                  console.log('focus');
                                  impactFeedback('selection');
                                }}
                                setExample={setExample}
                                margin={5}
                              />
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {!isHidePerfectionStep && (
                <div className="w-full px-[20px]">
                  <div className="px-[20px] bg-white rounded-[16px] ">
                    <div className=" py-[20px] flex flex-col gap-[24px] ">
                      <div className="flex flex-col gap-[16px]">
                        <div className="flex w-full justify-between">
                          <SubTitle18 subtitle={'걱정 기록장 완성도'} />
                          <SubTitle18 subtitle={`${perfectionStep}단계`} color={anxyColorPalette.orange} />
                        </div>
                        <div className="flex gap-[3px]">
                          {Array.from({ length: totalStep }, () => 0).map((each, index) => (
                            <div
                              key={`perfectionStep${index}`}
                              className={`flex-1 h-[10px] bg-anxyOrange ${
                                index === 0 ? 'rounded-l-[10px]' : index === totalStep - 1 ? 'rounded-r-[10px]' : ''
                              } ${index < perfectionStep ? 'opacity-[1]' : 'opacity-[0.1]'}`}
                            />
                          ))}
                        </div>
                      </div>

                      <div className="flex gap-[8px] items-center">
                        <p className="py-[2px] px-[4px] rounded-[4px] bg-anxyBlack text-white text-[13px] font-bold">
                          TIP
                        </p>
                        <Caption15
                          caption={
                            perfectionStep === totalStep
                              ? '이제 꾸준히 기록하는 일만 남았어요'
                              : `세션 ${
                                  perfectionStep < 4 ? perfectionStep + 2 : perfectionStep === 4 ? 11 : 12
                                }를 들으면 '${
                                  perfectionStep < 4
                                    ? example[perfectionStep].title
                                    : perfectionStep === 4
                                    ? '현실적 확률'
                                    : '대안적 행동'
                                }'도 적을 수 있어요`
                          }
                        />
                      </div>
                    </div>
                    {perfectionStep === totalStep && (
                      <div
                        className="pt-[16px] pb-[20px] border-t-[1px] "
                        style={{ borderColor: hexOpacity.create(anxyColorPalette.black, 0.1) }}
                        onClick={() => {
                          console.log('더 이상 보지 않기');
                          setHidePerfectionStep(true);
                          hidePerfectionStep();
                        }}
                      >
                        <Text17
                          text={'더 이상 보지 않기'}
                          color={anxyColorPalette.orange}
                          className="font-semibold text-center"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      }
      buttonState={buttonState}
      buttonText={'기록하기'}
      action={() => {
        setButtonState('LOADING');
        saveWorryRecord();
      }}
      bgColor={anxyColorPalette.oat}
    />
  );
}
