import { atom } from 'recoil';

export const MODAL_LOGOUT = 'LOGOUT';
export const MODAL_LEAVE = 'LEAVE';
export const MODAL_VIEWED = 'VIEWED';

const modalAtom = atom({
  key: 'modal',
  default: {
    isModalVisible: false,
    modalType: '',
  },
});

export default modalAtom;
