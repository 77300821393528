/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import backgroundColorAtom from '../../recoil/common/backgroundColor/atom';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import { keepScreenEvent } from '../../utils/webview';
import useSaveViewedPage from '../../hooks/useSaveViewedPage';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import useMixpanelEventAnxy from '../../hooks/useMixpanelEventAnxy';
import { getSessionContents, getSessionData } from '../../utils/anxySessionLogic';

export default function AnxySession() {
  const { sessionId, page } = useParams();

  const [prevBackgroundColor, setPrevBackgroundColor] = useRecoilState(backgroundColorAtom);

  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const saveViewedPage = useSaveViewedPage({ noneAction: true });

  const sessionData = getSessionData(sessionId);
  console.log(getSessionContents(sessionId));
  // const checkPointName = sessionData.checkPoint ? sessionData.checkPoint[parseInt(page) - 1] : '';

  const content = getSessionContents(sessionId)[parseInt(page)];
  const backgroundColor = content.backgroundColor ? content.backgroundColor : prevBackgroundColor;

  // const setMixPanel = useMixpanelEventAnxy({
  //   eventName: '세션 진행 중',
  //   params: {
  //     세션: sessionData.eventProperty,
  //     체크포인트: checkPointName,
  //   },
  // });

  useEffect(() => {
    document.title = 'Anxy Session';
    setPrevBackgroundColor(backgroundColor);
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#FFFFFF', headerTitle: '' }));
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));

    // setMixPanel();
    if (content.template && !content.template.props.video) {
      saveViewedPage();
    }
    keepScreenEvent({ isKeepScreenOn: true });
  }, []);

  return content.template;
}
