const emotionSituationData = [
  {
    emotion: '기쁨',
    placeholderList: [
      { type: 'A', emotion: '기쁜', placeholder: 'ex) 친구를 만났어요.' },
      { type: 'B', emotion: '즐거운', placeholder: 'ex) 좋아하는 물건을 샀어요.' },
      { type: 'C', emotion: '기대되는', placeholder: 'ex) 산책을 했어요.' },
    ],
  },
  {
    emotion: '슬픔',
    placeholderList: [
      { type: 'A', emotion: '기쁜', placeholder: 'ex) 친구가 약속을 지키지 않았어요.' },
      { type: 'B', emotion: '즐거운', placeholder: 'ex) 남자친구와 헤어졌어요.' },
      { type: 'C', emotion: '기대되는', placeholder: 'ex) 늦게 일어났어요.' },
    ],
  },
  {
    emotion: '불쾌함',
    placeholderList: [
      { type: 'A', emotion: '기쁜', placeholder: 'ex) 상사가 지적했어요.' },
      { type: 'B', emotion: '즐거운', placeholder: 'ex) 집에서 쓰레기 냄새가 났어요.' },
      { type: 'C', emotion: '기대되는', placeholder: 'ex) 동료의 업무를 떠맡았어요.' },
    ],
  },
  {
    emotion: '화남',
    placeholderList: [
      { type: 'A', emotion: '기쁜', placeholder: 'ex) 승진을 축하받지 못했어요.' },
      { type: 'B', emotion: '즐거운', placeholder: 'ex) 제안서를 거절 당했어요.' },
      { type: 'C', emotion: '기대되는', placeholder: 'ex) 마음대로 내 물건을 썼어요.' },
    ],
  },
  {
    emotion: '두려움',
    placeholderList: [
      { type: 'A', emotion: '기쁜', placeholder: 'ex) 어두운 골목을 지나갔어요.' },
      { type: 'B', emotion: '즐거운', placeholder: 'ex) 발표를 준비했어요.' },
      { type: 'C', emotion: '기대되는', placeholder: 'ex) 막차 시간을 맞추지 못했어요.' },
    ],
  },
];

export default emotionSituationData;
