import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from '../utils/axios2';
import { useSearchParams } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';
import 'moment/locale/ko';
import { CoachingContext } from '../coachingContext';
import { showWebView, getRefresh, getScrollEvent } from '../utils/webview';
import useSessionStorage from '../hooks/useSessionStorage';
import CoachingInfo from '../components/coaching/CoachingInfo';
import webviewToast from '../development/webviewToast';
import NotStartedModal from '../components/elements/NotStartedModal';
import { motion } from 'framer-motion';
import ContainerByDevice from '../components/elements/ContainerByDevice';

function Coaching() {
  const [todoList, setTodoList] = useState([]);
  const [toolList, setToolList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [result, setResult] = useSessionStorage('data', []);

  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appVersion = searchParams.get('app_version') || 133;
  const appId = searchParams.get('app_id');
  const context = useContext(CoachingContext);
  const divRef = useRef();
  const params = {};

  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;

  const iOSMobile = /(iPhone|iPod|iPad)/i.test(navigator.platform);

  function refreshAction() {
    // 수면 코스에서의 data(수면 기록) clear를 위해 추가
    window.localStorage.clear();
    getHomeDetail(authToken, appId);
  }

  useEffect(() => {
    document.title = '홈';

    context.setAppVersion(appVersion);
    context.setAuthToken(authToken);
    context.setAppId(appId);
    context.setAppVersion(appVersion);
    getHomeDetail(authToken, appId, false);
    getRefresh(refreshAction);
    getScrollEvent(divRef);

    // 수면 코스에서의 data(수면 기록) clear를 위해 추가
    window.localStorage.clear();

    if (isSafari || iOSMobile) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  }, []);

  const goToCheckIn = () => {
    showWebView({ url: `checkin?at=${authToken}&isChanged=true`, backgroundColor: '#EFF2FF' });
    context.showWebviewLayer['firstCheckinToday'] = false;
  };

  const goToCheckInFirst = () => {
    showWebView({ url: `checkin?at=${authToken}&isChanged=true&isFirst=true`, backgroundColor: '#EFF2FF' });

    context.showWebviewLayer['firstCheckin'] = false;
  };

  const toolOpen = (toolId) => {
    showWebView({ url: `toolOpen/${toolId}?close=true`, backgroundColor: '#EFF2FF' });
    context.showWebviewLayer['toolOpen'] = { show: false, toolId: undefined };
  };

  const goFeedback = (activityId) => {
    webviewToast('id' + activityId);
    showWebView({
      url: `feedback/0?at=${authToken}&app_id=${appId}&activity_id=${activityId}`,
      backgroundColor: '#EFF2FF',
    });
    context.showWebviewLayer['feedback'] = false;
  };

  const courseDone = (courseId) => {
    showWebView({
      url: `courseDone?at=${authToken}&app_id=${appId}&course_id=course-${courseId}`,
      backgroundColor: '#2C4BEC',
    });
    context.showWebviewLayer['courseDone'] = false;
  };
  useEffect(() => {
    if (result.length !== 0) {
      if (result.todoProgramList) {
        const todoList = result.todoProgramList;
        // 툴 획득 카드가 있을 때
        const toolList = todoList.filter((item) => item.activity && item.activity.toolId);
        if (toolList.length !== 0) {
          const tool = toolList[0];
          const toolOpenIdSplit = tool.activity.activityId.split('_');
          const toolId = tool.activity.toolId.split('-')[1];
          const toolOpenId = `${toolOpenIdSplit[0]}_${parseInt(toolOpenIdSplit[1] - 1)}`;
          todoList.forEach((item) => {
            if (item.activity && item.activity.activityId === toolOpenId) {
              if (item.isFirstCallAfterDone) {
                webviewToast('toolOpen' + toolId);
                context.showWebviewLayer['toolOpen'] = { show: true, toolId: toolId };
              }
            }
          });
        }

        // 체크인일 때
        if (result.isFirstCallAfterFirstCheckIn) {
          context.showWebviewLayer['firstCheckin'] = true;
        } else {
          if (result.isFirstCallAfterFirstCheckInToday) {
            context.showWebviewLayer['firstCheckinToday'] = true;
          }
        }

        if (todoList && todoList.length !== 0) {
          // 코스의 마지막 활동일 때
          const finalActivity = todoList.slice(-1)[0];
          if (finalActivity.activity && finalActivity.isFirstCallAfterDone) {
            const activityId = finalActivity.activity.activityId;
            if (
              activityId === 'course-0_8' ||
              activityId === 'course-1_16' ||
              activityId === 'course-2_8' ||
              activityId === 'course-3_11'
            ) {
              context.showWebviewLayer['courseDone'] = {
                show: true,
                courseId: parseInt(activityId.split('-')[1].split('_')[0]),
              };
            }
          }

          const activityList = todoList.filter((item) => item.activity);
          // 오늘 모든 활동 완료
          const isCompleted = activityList.filter((item) => item.isDone).length === activityList.length;
          if (
            isCompleted &&
            todoList[todoList.length - 1].activity &&
            todoList[todoList.length - 1].isFirstCallAfterDone
          ) {
            context.showWebviewLayer['feedback'] = true;
          }
        }

        setTimeout(
          () => {
            context.isFirstWebviewLayer = true;
            if (context.showWebviewLayer['toolOpen'] && context.showWebviewLayer['toolOpen'].show) {
              toolOpen(context.showWebviewLayer['toolOpen'].toolId);
              context.setIsFirstWebviewLayer(false);
            } else if (context.showWebviewLayer['feedback']) {
              const finalActivityId = todoList[todoList.length - 1].activity.activityId;
              goFeedback(finalActivityId);
              context.setIsFirstWebviewLayer(false);
            } else if (context.showWebviewLayer['courseDone']) {
              const courseId = context.showWebviewLayer['courseDone'].courseId;
              courseDone(courseId);
              context.setIsFirstWebviewLayer(false);
            } else if (context.showWebviewLayer['firstCheckin']) {
              goToCheckInFirst();
              context.setIsFirstWebviewLayer(true);
            } else if (context.showWebviewLayer['firstCheckinToday']) {
              goToCheckIn();
              context.setIsFirstWebviewLayer(true);
            }
          },
          context.isFirstWebviewLayer ? 1600 : 100,
        );
      }
    }
  }, [result]);

  const getHomeDetail = async (token, appId, back) => {
    try {
      for (var [key, value] of searchParams.entries()) {
        if (key !== 'at' && key !== 'app_id' && key !== 'user_id' && key !== 'refresh') {
          params[key] = value;
        }
      }
      await axios(3, token, appId)
        .get(`/program/home/detail`, {
          params: params,
        })
        .then((response) => {
          const data = response.data.result;

          // refresh로 인해 돌아온 경우

          if (data.todoProgramList) {
            console.log(data);
            setTodoList((todo) => {
              const changedList = data.todoProgramList;
              if (todo && todo.length !== 0) {
                todo.forEach((beforeItem) => {
                  changedList.forEach((afterItem) => {
                    if (beforeItem.title === afterItem.title) {
                      if (beforeItem.isLock === true && afterItem.isLock === false) {
                        afterItem.isFirstUnlocked = true;
                      }
                    }
                  });
                });
                if (changedList.length === 0) {
                  console.log('바뀐거 업서');
                  return data.todoProgramList;
                } else {
                  console.log('바귐');
                  return changedList;
                }
              } else {
                return data.todoProgramList;
              }
            });
          } else {
            setTodoList();
          }

          // 이전 툴 리스트가 있다면 비교하고 달라졌다면 true로 설정
          setToolList((toolList) => {
            const changedList = data.toolList;
            toolList.forEach((beforeItem) => {
              changedList.forEach((afterItem) => {
                if (beforeItem.toolId === afterItem.toolId) {
                  if (beforeItem.isLock === true && afterItem.isLock === false) {
                    let updated = afterItem;
                    updated.isFirstUnlocked = true;
                  }
                }
              });
            });
            if (changedList.length === 0) {
              return data.toolList;
            }
            return changedList;
          });

          setResult(data);
          setIsLoaded(true);
        });
    } catch (error) {
      console.error('error', error);
      webviewToast('api failed: ' + error.response);
    }
  };

  return (
    <ContainerByDevice css={`relative ${isLoaded && 'bg-half'}`}>
      {isLoaded ? (
        <>
          <CoachingInfo result={result} authToken={authToken} appId={appId} todoList={todoList} toolList={toolList} />
          <NotStartedModal />
          {context.showModal && (
            <motion.div
              className={`bg-black ${
                context.showModal ? 'opacity-[0.5]' : 'opacity-0'
              } absolute top-0 w-full h-full  z-[20]`}
              onClick={() => context.setShowModal(false)}
            />
          )}
        </>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <DotLoader color="#3953D9" loading={true} size={80} />
        </div>
      )}
    </ContainerByDevice>
  );
}

export default Coaching;
