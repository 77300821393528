/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../../stories/page/BottomActionAnxy';
import { motion } from 'framer-motion';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import { betterMeColor } from '../../../data/betterMe/betterMeConstant';

export default function BetterMeStart(props) {
  const goTest = useNavigateWithParams(`/betterMeTest/1`);
  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    setHeaderState({ headerButtonType: BUTTON_NONE, headerTitle: '' });
  }, []);

  return (
    <BottomActionAnxy
      content={
        <div
          className="w-screen h-full flex flex-col justify-center relative"
          style={{
            backgroundColor: betterMeColor.yellow,
          }}
        >
          {/* <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5, ease: 'easeInOut' }}
              src={anxyTest}
              alt={'empty'}
              className="w-[420px] min-w-[420px] absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]"
            /> */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5, ease: 'easeInOut' }}
            className=" px-[20px] flex flex-col gap-[14px] items-center  text-center text-[#26282C]"
          >
            <p className=" text-[30px] leading-[36px] font-bold text-[#26282C] ">
              {`2분간의 간단한 검사로 \n 식이장애 정도를 살펴봐요`}
            </p>
            <p className=" text-[17px] leading-[23px] opacity-[0.4] ">그리고 어떻게 개선할 수 있을지 살펴봐요.</p>
          </motion.div>
        </div>
      }
      buttonText={'식이장애 검사하기'}
      buttonState={'ACTIVE'}
      action={() => {
        goTest();
      }}
      bgColor={betterMeColor.yellow}
      noGradient
      fadeIn
      noHeader
      round
    />
  );
}
