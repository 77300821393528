import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export function LoadingLottie(props) {
  const { containerSize, white } = props;
  const likecontainer = useRef();
  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: white ? require('./loading_white.json') : require('./loading.json'),
    });
    return () => instance.destroy();
  }, []);
  return (
    <div>
      <div style={{ width: containerSize, height: containerSize }} ref={likecontainer}></div>
    </div>
  );
}
