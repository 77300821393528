import React, { useEffect } from 'react';
import { useState } from 'react';
import Progressbar from 'react-js-progressbar';
import { useSearchParams } from 'react-router-dom';
import { checkListData } from '../../data/habit/checkListData';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { ProgressingBar } from '../../stories/else/ProgressingBar';
import { AnimatePresence, motion } from 'framer-motion';
import './habitProgress.css';
import {
  getHabitFormattedDate,
  getPastNDaysArray,
  getSerializedDaysArray,
  getSerializedTimeArray,
  getTimeZone,
} from '../../logic/method';
import { DateTime } from 'luxon';
import { PressedEffect } from '../../stories/button/PressedEffect';
import webviewToast from '../../development/webviewToast';
import { CheckLottie } from '../../stories/animation/CheckLottie';

export function HabitResult(props) {
  const { bgColor } = props;
  const [habitList, setHabitList] = useState();
  const [streakList, setStreakList] = useState();
  const [todayPercentage, setTodayPercentage] = useState(0);
  const [completeNumber, setCompleteNumber] = useState(0);

  const [selectedHabitId, setSelectedHabitId] = useState();
  const [showModal, setShowModal] = useState(false);

  const [selectedList, setSelectedList] = useState([]);

  const [completeList, setCompleteList] = useState([]);
  const [cancleList, setCancleList] = useState('');

  const [homeDetail, getHomeDetail] = useFetchAnxy({
    url: `/habit-streak/home/detail`,
    bg: '#FFFFFF',
  });

  const [completeHabit, setCompleteHabit] = useFetchAnxy({
    url: `/habit-streak/habit/complete`,
    requestBody: {
      habitId: selectedHabitId,
    },
  });

  const [cancleHabit, setCancleHabit] = useFetchAnxy({
    url: `/habit-streak/habit/cancel`,
    requestBody: {
      sid: cancleList,
    },
    bg: '#FFFFFF',
  });

  useEffect(() => {
    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';
    getHomeDetail();
  }, []);

  useEffect(() => {
    if (completeHabit) {
      const sid = completeHabit.result.sid;
      if (sid) {
        setCancleList(sid);
      }
    }
  }, [completeHabit]);

  useEffect(() => {
    if (homeDetail) {
      const result = homeDetail.result;

      const notCompleteHabitList = result.habitList.filter((item) => item.isDone === false);

      const allowed = notCompleteHabitList.map((item) => item.habitId);
      const filteredList = [];
      checkListData.forEach((item) => {
        let newObj = {};
        const newArray = [];

        item.checkList.forEach((checkList) => {
          if (allowed.includes(checkList.id)) {
            newArray.push(checkList);
          }
        });
        if (newArray.length !== 0) {
          newObj.title = item.title;
          newObj.checkList = newArray;
          filteredList.push(newObj);
        }
      });
      setHabitList(filteredList);
      setCompleteNumber(result.habitList.filter((item) => item.isDone === true).length);
      setStreakList(result.streakList);
      setTodayPercentage(Math.round((result.habitList.filter((item) => item.isDone === true).length / 18) * 100));
    }
  }, [homeDetail]);

  const goToShare = useNavigateWithParams(`/habit-share`);

  useEffect(() => {
    setTodayPercentage(Math.round(((completeNumber + completeList.length) / 18) * 100));
  }, [completeList]);

  useEffect(() => {
    if (selectedHabitId) {
      setCompleteHabit();
      setTimeout(() => {
        setSelectedHabitId();
        setShowModal(false);
      }, 3000);
    }
  }, [selectedHabitId]);

  const handleComplete = (title, id) => {
    setSelectedHabitId(id);
    setTimeout(() => {
      const updated = completeList.includes(id) ? completeList.filter((item) => item !== id) : [...completeList, id];
      setCompleteList(updated);
      setShowModal(true);
    }, 0);
  };

  const handleAction = (title, id) => {
    handleComplete(title, id);
  };

  useEffect(() => {
    if (cancleHabit) {
      setCancleList('');
    }
  }, [cancleHabit]);

  const handleCancle = () => {
    setCancleHabit();
    const updated = completeList.slice(0, completeList.length - 1);
    setCompleteList(updated);
    setSelectedHabitId();
  };

  const SaveProgressHeader = () => (
    <div className="bg-[#3CD0FF] w-full h-[50px] flex justify-between items-center px-[20px]">
      <p className="text-[#131635] text-[15px] leading-[18px] font-bold">How to keep your progress</p>
      <div className="w-[50px] flex justify-end" onClick={goToShare}>
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L9 8L1 15" stroke="#131635" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
  );

  const Title = ({ title }) => <p className="text-white text-[40px] leading-[48px] font-bold pb-[20px]">{title}</p>;

  const todayDate = DateTime.fromISO(new Date().toISOString()).toFormat('yyyy-MM-dd');
  const latestRecordDate =
    streakList &&
    streakList.length !== 0 &&
    DateTime.fromISO(new Date(streakList[streakList.length - 1].date).toISOString())
      .plus({ day: 1 })
      .toFormat('yyyy-MM-dd');

  const pastNDays =
    streakList && streakList.length !== 0
      ? getSerializedDaysArray(latestRecordDate, 7 - streakList.length)
      : getSerializedDaysArray(todayDate, 7);
  const dateList =
    streakList && streakList.length !== 0 ? getHabitFormattedDate(streakList).concat(pastNDays) : pastNDays;
  const todayIndex = dateList.findIndex((item) => item.date === todayDate);

  console.log(streakList && streakList.length, pastNDays);
  const StreakSection = () => (
    <div className="pb-[60px]">
      <div className="px-[20px]">
        <Title title="Streak" />
      </div>
      <div className="flex gap-[12px] overflow-x-scroll overflow-y-hidden scrollbar-hide px-[20px]">
        {dateList.map((item, index) => (
          <div key={`${item.date}`} className="flex flex-col items-center">
            <div className="w-[64px] relative">
              <Progressbar
                input={
                  index < todayIndex
                    ? streakList.find((data) => data.date === item.date).percentage
                    : index === todayIndex
                    ? todayPercentage
                    : 0
                }
                pathWidth={22}
                pathColor={index === todayIndex ? '#FFE03C' : '#FFFFFF'} // use an array for gradient color.
                trailWidth={22}
                trailColor="#FFFFFF4D" // use a string for solid color.
                pathLinecap={'none'}
                pathShadow={'none'}
                animation={{
                  duration: 0,
                  delay: 0,
                  ease: 'easeInSine',
                }}
                customText=""
              />
              <div
                className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center ${
                  todayDate === item.date ? 'text-[#FFE03C]' : 'text-white'
                }  font-bold`}
              >
                <p className="text-[10px] leading-[11px]">{item.formatList[0]}</p>
                <p className="text-[20px] leading-[22px]">{item.formatList[1]}</p>
              </div>
            </div>
            {streakList && streakList.length !== 0 && index < todayIndex && (
              <p className="text-white font-bold text-[20px] leading-[22px] pt-[8px]">{`${
                streakList.find((data) => data.date === item.date).percentage
              }%`}</p>
            )}
            {streakList && streakList.length !== 0 && index === todayIndex && (
              <p className="text-[#FFE03C] font-bold text-[20px] leading-[22px] pt-[8px]">{`${todayPercentage}%`}</p>
            )}
            {streakList && streakList.length === 0 && todayDate === item.date && (
              <p className="text-[#FFE03C] font-bold text-[20px] leading-[22px] pt-[8px]">{`${todayPercentage}%`}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const TodayProgressSection = () => (
    <div className="pb-[40px] px-[20px]">
      <div className="flex items-center justify-between">
        <Title title="Today" />
        <p className="text-[40px] leading-[48px] font-bold text-[#FFE03C] pb-[20px]">{`${todayPercentage}%`}</p>
      </div>
      <ProgressingBar
        maxCompleted={100}
        completed={todayPercentage}
        height="14px"
        color="#FFE03C"
        baseColor="#FFFFFF4D"
        animateNone
      />
    </div>
  );

  return (
    <div
      className={`flex flex-col relative w-screen`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
        backgroundColor: '#131635',
      }}
    >
      {habitList && streakList && (
        <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col justify-between`}>
          <div className="flex-1">
            <SaveProgressHeader />
            {/* <UndoMessage /> */}
            <div className="pt-[40px] pb-[60px]">
              <StreakSection />
              <TodayProgressSection />
              <div className="flex flex-col">
                {habitList.map((item) => (
                  <div>
                    {item.checkList.filter((item) => !completeList.includes(item.id)).length !== 0 && (
                      <div className="text-white px-[20px] pb-[40px]">
                        <p className="text-[22px] leading-[26.4px] font-bold opacity-[0.6] pb-[16px]">{item.title}</p>
                        <div
                          className="rounded-[16px] w-full pt-[24px] pb-[12px] px-[20px]"
                          style={{
                            background: 'rgba(255, 255, 255, 0.05)',
                          }}
                        >
                          <div className="flex flex-col justify-center">
                            {item.checkList
                              // .filter((item) => !completeList.includes(item.id))
                              .map((item, index) => (
                                <motion.div
                                  style={{
                                    scale: completeList.includes(item.id) ? 0 : '',
                                    height: completeList.includes(item.id) ? 0 : '',
                                    opacity: completeList.includes(item.id) ? 0 : 1,
                                    touchAction: completeList.includes(item.id) ? 'none' : '',
                                  }}
                                  animate={{
                                    scale: completeList.includes(item.id) ? 0 : '',
                                    height: completeList.includes(item.id) ? 0 : '',
                                    opacity: completeList.includes(item.id) ? 0 : 1,
                                    touchAction: completeList.includes(item.id) ? 'none' : '',
                                  }}
                                  transition={{ transition: 0.2, delay: completeList.includes(item.id) ? 1 : 0 }}
                                >
                                  <motion.div
                                    className={`flex justify-between items-center pb-[12px] relative`}
                                    transition={{ transition: 0.2 }}
                                  >
                                    <div className="flex items-center">
                                      <p className="text-[38px] leading-[45.35px]">{item.icon}</p>
                                      <p className="px-[12px] text-[15px] leading-[18px]">{item.text}</p>
                                    </div>
                                    <div className="w-[24px] h-[24px] ">
                                      {completeList.includes(item.id) ? (
                                        <CheckLottie containerSize="24px" yellow />
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 h-[56px] w-[56px] shrink-0 flex justify-end"
                                          onClick={() => handleAction(item.title, item.id)}
                                        >
                                          <div className="absolute top-[12px]">
                                            <div className="rounded-[100%] w-[24px] h-[24px] ring-[2px] ring-inset ring-[#FFFFFF] shrink-0 opacity-[0.3]" />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </motion.div>
                                </motion.div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <motion.div
        tabIndex="1"
        className="absolute bottom-[-100px] w-full pb-[20px] z-[50] bg-[#131635]"
        animate={{ opacity: 1, y: showModal ? -100 : 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className={`w-full relative flex justify-center `}>
          <div
            className="w-full h-[40px] absolute top-[-40px]"
            style={{ background: `linear-gradient(to top, ${bgColor ? bgColor : 'white'}, rgba(255,255,255,0))` }}
          />
          <div className="py-[12px] flex justify-between items-center w-[calc(100%-40px)] bg-[#FFE03C] rounded-[16px] px-[20px] text-[15px] leading-[18px] font-bold text-[#131635]">
            <p>Marked as complete</p>
            <div className="flex items-center">
              <div className="bg-[#131635] opacity-[0.1] rounded-[51px] h-[26px] w-[2px]" />
              <p className="pl-[19px]" onClick={() => handleCancle()}>
                Undo
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
