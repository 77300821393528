import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './test.css';
import GaugeGraph from '../stories/graph/GaugeGraph';
import { BlackRoundActionButton } from '../stories/button/RoundActionButton';
import 불안이 from '../image/anxy/불안이.png';
import { cubicBezier, Gauge } from 'gauge-chart-js';
import gradstop from 'gradstop';

function GradientSVG(props) {
  const { startColor, middleColor, endColor, idCSS, rotation } = props;

  let gradientTransform = `rotate(${rotation})`;

  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="10%" stopColor={startColor} />
          <stop offset="60%" stopColor={middleColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}

function GaugeGraph2(props) {
  const { containerSize, percentage } = props;
  const gaugeRef = useRef();
  const centralAngle = 260;
  const fromAngle = 180 + (360 - centralAngle) / 2;
  const toAngle = 540 - (360 - centralAngle) / 2;
  useEffect(() => {
    const gaugeBackground = new Gauge({
      container: gaugeRef.current,
      color: '#f5f5f5',
      lineWidth: '6.5',
      animationDuration: 0,
      fromAngle: fromAngle,
      toAngle: toAngle,
    });
    gaugeBackground.setValue(centralAngle);
    const gauge = new Gauge({
      container: gaugeRef.current,
      colors: gradstop({
        stops: centralAngle,
        colorArray: ['#398047', '#F8CF46', '#EF670B'],
      }),
      lineWidth: '6.5',
      fromAngle: fromAngle,
      toAngle: toAngle,
      animationDuration: 600,
      easing: cubicBezier(0, 0, 0.2, 1),
    });
    gauge.setValue((centralAngle * percentage) / 100);
    // setTimeout(() => {
    //   setTimeout(() => {
    //     gauge.setValue(240);
    //   }, 2000);
    // }, 2000);
  }, [percentage]);

  useEffect(() => {
    // gauge.setValue((centralAngle * percentage) / 100);
  }, [percentage]);

  return (
    <div className="">
      <svg ref={gaugeRef} width={containerSize} height={containerSize} className="mx-auto" />
    </div>
  );
}
export default function Test() {
  const [percentage, setPercentage] = useState(50);
  useEffect(() => {
    setTimeout(() => {
      setPercentage(0);
      setTimeout(() => {
        setPercentage(100);
      }, 1000);
    }, 1000);
  }, []);

  useEffect(() => {
    console.log(percentage);
  }, [percentage]);

  return (
    <div className="  pt-[20px] ">
      <div className="px-[20px] text-[24px] leading-[30px] text-black font-bold">기록하기</div>
      <div className="flex flex-col items-center justify-center pt-[20px] ">
        <div className="relative">
          <GaugeGraph
            containerSize={300}
            strokeWidth={50}
            pathBackground={'conic-gradient(from 0deg, #398047 45deg,#398047 60deg, #f8cf46, #ef670b 300deg)'}
            percentage={percentage}
            circleRatio={0.7}
          />
          <div className="absolute left-[50%] translate-x-[-50%] bottom-[70px] z-[500]">
            <img src={불안이} alt={'empty'} className="w-[127px] h-[228px]" />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center translate-y-[-60px] z-[700]">
          <p className="text-[17px] leading-[23px] font-bold text-[#6B6D76] mb-[2px]">3분전 불안</p>
          <p className="text-[40px] leading-[36px] font-bold text-black mb-[20px]">20</p>
          <BlackRoundActionButton state={'ACTIVE'} text={'걱정 일기쓰기'} action={() => {}} width={175} />
          <div className="flex items-center justify-center mt-[15px]">
            <p className="text-[15px] leading-[21px] text-[#4E4F53]">지난 기록보기</p>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.5 17L14.5 12L9.5 7"
                stroke="#80838B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <GaugeGraph2 containerSize={370} percentage={percentage} />
      {/* <CircularSector percentage={20} radius={135} strokeWidth={40} /> */}
      {/* <div className="w-[100px]" id={'anxyGauge'}>
        <GradientSVG
          startColor={'#398047'}
          middleColor={'#F8CF46'}
          endColor={'#EF670B'}
          idCSS={'gradient'}
          rotation={90}
        />
        <CircularProgressbar
          styles={buildStyles({
            rotation: -0.375,
            trailColor: 'rgba(0,0,0,0.05)',
            strokeLinecap: 'round',
          })}
          strokeWidth={12}
          circleRatio={0.75}
          value={60}
        />
      </div> */}
    </div>
  );
}
