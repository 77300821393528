import anxyColorPalette from './contents/anxyColorPalette';

const anxySessionPageData = [
  {
    sessionId: 'session_aa',
    title: '불안은 꼭 나쁘기만 한 걸까요?',
    backgroundColor: anxyColorPalette.purple,
    contents: [
      { title: '' },
      { title: '불안은 꼭 나쁜 게 아니라는 사실, 알고 계신가요?' },
      { title: '불안은 모두가 경험하는 자연스러운 감정이랍니다' },
      { title: '잠재적인 위험에 대비하기 위해 우리를 준비시키는 기능을 하죠' },
      { title: '높은 곳에 올라갔을 때 긴장해서 조심조심 걸었던 적 있으시죠?' },
      { title: '미끄러졌을 때 즉각 균형을 되찾을 수 있도록 미리 준비 태세에 들어가는 것이죠' },
      { title: '오히려 적당한 수준의 불안은 생산성을 높인다는 연구 결과도 있어요' },
      { title: '시험이 임박했을 때야 비로소 공부가 잘된 적 있으시죠?' },
      { title: '적당한 불안감이 순기능으로 작용한 거랍니다' },
      { title: '즉, 불안감이 문제가 되는 순간은 바로 불안의 대상이 실재하지 않을 때예요' },
      { title: '실재하는 위험에 대해 불안을 느낀다면 쓸모 있는 준비가 되겠지만' },
      { title: '실재하지 않는 위험에 대해 불안을 느낀다면 오히려 독이 되는 것이죠' },
      { title: '일상에서도 계속해서 실재하지 않는 위험에 대해 생각한다면' },
      { title: '우리 몸은 높은 긴장 상태를 항상 유지하게 되고' },
      { title: '결국 불안에 압도당해 정상적인 생활이 불가능해집니다' },
      { title: '불안의 긍정적인 면과 부정적인 면을 모두 이해하셨나요?' },
      { title: '불안 관리의 핵심은 바로 불안을 통제할 수 있는 수준으로 낮추는 것이에요' },
      { title: '일상에서 가장 쉽고 빠르게 불안을 낮출 수 있는 방법을 알려드릴게요' },
      { title: '바로 심호흡인데요' },
      { title: '불안으로 활성화된 교감신경을 쉽게 잠재울 수 있죠' },
      { title: '선물로 드릴테니 불안할 때 응급처치처럼 사용해주세요' },
      { title: '그럼 이제 불안으로부터 자유로워져 볼까요?' },
    ],
  },

  {
    sessionId: 'session_ab',
    title: '나는 무엇 때문에 불안한 걸까요?',
    backgroundColor: anxyColorPalette.purple,
    contents: [
      { title: '' },
      { title: '불안의 원인에 대해 알아볼게요' },
      { title: '불안의 원인은 크게 외부 요인과 내부 요인으로 나눌 수 있어요' },
      { title: '외부 요인은 불안을 유발하는 장소, 상황, 대상 등이에요' },
      { title: '예를 들면, 사람들이 많은 장소' },
      { title: '남들 앞에서 발표를 하는 상황' },
      { title: '날아드는 비둘기 등이 외부 요인에 해당돼요' },
      { title: '한편 내부 요인은 불안을 유발하는 기억, 이미지, 신체 반응 등을 말하는데요' },
      { title: '외부 요인과 달리, 우리 몸에서 발생하는 일들이지요' },
      { title: '과거 경험한 트라우마가 원인이 될 수 있고' },
      { title: '가슴이 답답하거나, 두근거리는 몸의 감각도 불안의 원인이 된답니다' },
      { title: '앞으로 걱정 기록을 꾸준히 해 나가며' },
      { title: '우리가 언제 어떻게 불안을 느끼는지 알아낼 거예요' },
      { title: '홈 화면의 Wori를 통해 걱정을 기록할 수 있어요' },
      { title: '걱정기록장에 불안의 원인을 적을 때에는' },
      { title: '오늘 배운 외부, 내부 요인을 잘 생각하며 구체적으로 적어보세요' },
      { title: '아, 매번 같은 이유로 불안하다고요?' },
      { title: '우리는 매일 반복되는 일상을 보내고 있으니 당연한 거예요' },
      { title: '원인이 같더라도 반복해서 걱정기록을 하는 것이 중요하답니다' },
      { title: '그래야 내가 주로 무엇 때문에 불안해하는지 패턴을 찾고' },
      { title: '함께 공략해 나갈 수 있거든요' },
      { title: '그럼 이번 세션은 여기서 마칠게요' },
    ],
  },
  {
    sessionId: 'session_ac',
    title: '불안할 때 내 몸이 보이는 반응',
    backgroundColor: anxyColorPalette.yellow,
    contents: [
      { title: '' },
      { title: '불안을 어떻게 다스릴 수 있을까요?' },
      { title: '불안을 구성하고 있는 요소로 작게 쪼갠다면 불안을 다스릴 수 있어요' },
      { title: '마치 커다랗고 무시무시한 괴물을 자세히 살펴보니' },
      { title: '실은 고철로 이루어진 깡통 괴물에 불과했던 것처럼 말이죠' },
      { title: '불안의 3요소는 신체, 생각, 행동이에요' },
      { title: '신체 요소부터 알아볼게요' },
      { title: '신체 요소는 불안 상황에서 몸이 보이는 반응이에요' },
      { title: '불안은 잠재적인 위험에 대비하는 자연스러운 감정이라는 것 기억하시나요?' },
      { title: '우리 몸은 외부 위협에 대응하기 위해 여러 준비를 해요' },
      { title: '심박수가 증가하고, 호흡은 빨라지고, 근육이 긴장되기도 하죠' },
      { title: '이 때문에 뇌로 가는 혈액량이 줄어들어 어지러움을 느끼기도 해요' },
      { title: '시야가 흐려지고, 얼굴이 붉어지거나 귀에서 소리가 들리기도 하죠' },
      { title: '신체 요소가 무엇인지 잘 이해하셨나요?' },
      { title: `이제 걱정 기록장에 신체 요소도 적을 수 있을 건데요` },
      { title: '내 몸은 불안할 때 어떻게 되는지 꾸준히 기록해보세요' },
      { title: '불안할 때 내 몸이 보이는 반응을 알게 되면' },
      { title: `‘온몸 이완하기'라는 근육 이완법으로 쉽게 불안을 다스릴 수 있어요` },
      { title: '불안함을 느끼는 신체 감각을 완화할 수 있는 것이죠' },
      { title: '그럼 다음 세션에서 남은 두 요소에 대해 더 자세히 알아보기로 하고' },
      { title: `이번 세션은 여기서 마칠게요` },
    ],
  },
  {
    sessionId: 'session_ad',
    title: '불안할 때 내가 하는 생각',
    backgroundColor: anxyColorPalette.green,
    contents: [
      { title: '' },
      { title: '불안의 3요소 기억하시나요?' },
      { title: '이번엔 불안의 3요소 중 생각 요소에 대해 배워볼 거예요' },
      { title: '생각 요소는 불안한 상황에서 우리의 뇌가 보이는 반응이에요' },
      { title: '보통은 앞으로 일어날 일에 대해 시뮬레이션을 하곤 하는데' },
      { title: '부정적이고 최악의 시나리오까지 발전해 우리를 괴롭히죠' },
      { title: '하지만 대부분 충분히 대처가 가능하거나' },
      { title: '실제로 일어날 가능성이 매우 적답니다' },
      { title: '이제 걱정 기록장에 생각 요소도 적을 수 있을 건데요' },
      { title: '나는 불안할 때 어떤 생각을 하는지 꾸준히 기록해보세요' },
      { title: "곧 우리는 ‘생각함정 찾기'라는 인지 재구성 기법으로" },
      { title: '나를 지배하는 불안한 생각에서 벗어날 수 있게 될 거예요' },
      { title: '그럼 다음 세션에서 행동 요소에 대해 더 자세히 알아보기로 하고' },
      { title: `이번 세션은 여기서 마칠게요` },
    ],
  },
  {
    sessionId: 'session_ae',
    title: '불안할 때 내가 하는 행동',
    backgroundColor: anxyColorPalette.orange,
    contents: [
      { title: '' },
      { title: '이제 불안의 3요소 중 행동 요소만 남았네요' },
      { title: '행동 요소는 불안할 때 나오는 행동이에요' },
      { title: '회의 중 불안한 마음에 의견을 말하지 않고 듣고만 있다거나' },
      { title: '상대의 눈치를 살피는 등의 행동이 여기에 속하죠' },
      { title: '불안할 때마다 이런 행동이 계속 반복되면 어떻게 될까요?' },
      { title: '불안에 대처할 수 있는 현명한 행동은 하지 못하고' },
      { title: '불안감에 계속 지배당하다가' },
      { title: '결국엔 그 불안이 실재한다고 믿어버리게 될 수도 있어요' },
      { title: '이제 걱정 기록장에 행동 요소도 적을 수 있을 건데요' },
      { title: '나는 불안할 때 어떤 행동을 하는지 꾸준히 기록해보세요' },
      { title: "곧 우리는 ‘불안 마주하기'라는 노출 요법으로" },
      { title: '불안한 상황에서 더 나은 행동을 할 수 있는 연습을 해나갈 거예요' },
      { title: '이렇게 불안의 3요소에 대한 공부를 모두 끝냈네요' },
      { title: '다음 세션에서는 3요소가 어떻게 상호작용하는지 한 번 살펴볼게요' },
      { title: `그럼 이번 세션은 여기서 마칠게요` },
    ],
  },
  {
    sessionId: 'session_af',
    title: '꼬리에 꼬리를 무는 불안의 순환고리',
    backgroundColor: anxyColorPalette.purple,
    contents: [
      { title: '' },
      { title: '불안의 3요소가 왜 중요한지 알려드리려고 해요' },
      { title: '3요소는 상호 작용하면서 불안을 더욱 증폭시켜요' },
      { title: '이를 불안의 순환고리라고 하는데, 예시를 한 번 들어볼게요' },
      { title: 'Anxy는 진급 시험을 준비하는 도중 동료의 질문에 대답하지 못했어요' },
      { title: '대답을 못했으니 시험을 제대로 준비하지 않았다는 생각이 들었죠' },
      { title: '‘강의에서 놓친 내용이 있었나?’하고요' },
      { title: '그러자 몸이 긴장되고, 시험 공부에 집중이 안되기 시작했어요' },
      { title: '생각 요소가 신체 요소에 영향을 주기 시작한 거예요' },
      { title: '집중이 흐려지자 일단은 상황을 회피하기 위해 술을 마시기로 해요' },
      { title: '신체 요소가 행동 요소에 영향을 준거죠' },
      { title: '다음 날 아침, Anxy는 전날의 행동 때문에 더 큰 불안감을 느끼게 됩니다' },
      { title: "‘시험에서 떨어질 거야', ‘나는 낙오자가 될거야'라고 말이죠" },
      { title: '다시 행동 요소는 생각 요소에 영향을 주며 불안감은 증폭된답니다' },
      { title: '우리는 앞으로 이런 패턴을 이해하고 순환고리를 끊는 연습을 할 거예요' },
      { title: '이제 걱정 기록이 왜 중요한지 감이 오시겠죠?' },
      { title: '그럼 오늘도 불안할 때마다 걱정을 기록하는 것을 잊지 마시고' },
      { title: `이번 세션은 여기서 마칠게요` },
    ],
  },
  {
    sessionId: 'session_kc',
    title: '내 마음, 지금 여기로 가져와요',
  },
  {
    sessionId: 'session_ag',
    title: '어떻게 불안한 몸을 달랠 수 있을까요?',
  },
  {
    sessionId: 'session_ah',
    title: '한 방에 내 몸을 이완할 수 있다고요?',
    eventProperty: '7',
    playTime: 2,
  },
  {
    sessionId: 'session_ai',
    title: '내 생각에 함정이 있다니',
  },
  {
    sessionId: 'session_aj',
    title: '뭔가 일어날 것처럼 과하게 여기는 생각',
  },
  {
    sessionId: 'session_ak',
    title: '일이 파국으로 치닫을 거라 여기는 생각',
  },
  {
    sessionId: 'session_al',
    title: '“~해야 해”라는 당위적인 생각',
  },
  {
    sessionId: 'session_am',
    title: '내 생각의 뿌리를 찾아서',
  },
  {
    sessionId: 'session_an',
    title: '불안함에 맞설 수 있는 힘을 주세요',
  },
  {
    sessionId: 'session_ao',
    title: '나를 계속 불안하게 만드는 생각은 뭘까?',
  },
  {
    sessionId: 'session_ap',
    title: '내 머리 속의 불안을 마주하기',
  },
  {
    sessionId: 'session_aq',
    title: '불안할 때는 이렇게 행동하자!',
  },
  {
    sessionId: 'session_ar',
    title: '계획하고 실천하며 불안 깨부수기',
  },
  {
    sessionId: 'session_as',
    title: '불안으로부터 자유로워지기',
  },
];

export default anxySessionPageData;
