const TextWithDotBoldInline = ({ texts, margin, number }) => {
  return (
    <div className={`${margin ? margin : 'my-[30px]'} `}>
      {texts.map((each, index) => (
        <div className="flex">
          <span className="mr-[8px]">{number ? `${index + 1}.` : '•'}</span>
          <div>
            {each.text.map((text, index) => (
              <span>
                {index % 2 === 0 ? <span>{text}</span> : <span className={`bg-[#FFEB3564] pb-[1.5px] `}>{text}</span>}
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TextWithDotBoldInline;
