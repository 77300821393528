import React, { useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { changeHeader, close } from '../../utils/webview';
import { Title } from '../../stories/text/Title';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { motion } from 'framer-motion';
import lottie from 'lottie-web';
import toolData from '../../data/toolData';
import { CoachingContext } from '../../coachingContext';

export default function ToolOpen() {
  const { id } = useParams();
  const container = useRef();
  const contentRef = useRef();

  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const isCheckIn = searchParams.get('checkin') === 'true' || false;
  const navigate = useNavigate();
  const context = useContext(CoachingContext);

  useEffect(() => {
    document.title = '도구 획득';
    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'none' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('../../image/toolopen.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <div
      className={`h-screen bg-[#EFF2FF] w-screen overflow-hidden
    `}
    >
      <div
        className={`${
          context.biggerMargin ? 'h-[calc(100%-88px)]' : 'h-[calc(100%-74px)]'
        } relative flex flex-col items-center justify-center`}
      >
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeIn' }}
          viewport={{ once: true }}
          ref={contentRef}
          className="w-full relative"
        >
          <div className=" w-[calc(100%+200px)] absolute top-[calc(50%-70px)] left-[50%] translate-x-[-50%] translate-y-[-50%] pointer-events-none z-[40]">
            <div className="h-full" ref={container} />
          </div>
          <motion.div
            className={`w-[80px] h-[80px] rounded-[80px] mb-[30px] flex items-center justify-center mx-auto`}
            style={{ background: toolData[id - 1].backgroundColor, scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', tension: 300, friction: 15, duration: 0.8, delay: 0.2 }}
          >
            <div className="h-[50px] w-[50px]">
              <img src={toolData[id - 1].image} alt={'empty'} className="w-full" />
            </div>
          </motion.div>
          <div className="pt-[7px] px-[20px] mb-[8px]" style={{ textAlign: 'center' }}>
            <Title title={[`‘${toolData[id - 1].title}’를`, <br />, '이제 홈에서 쓸 수 있어요']} />
          </div>
          <div className="text-[17px] leading-[23px] font-normal text-[#80838B] text-center">
            {`${toolData[id - 1].subtitle}`}
          </div>
        </motion.div>
      </div>
      <div className={`z-[50] px-20px ${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'} w-full rounded-[16px]`}>
        <PrimaryActionButton
          state={'ACTIVE'}
          text={'확인'}
          action={() => {
            if (isCheckIn) {
              navigate(`/checkin?at=${authToken}&isChanged=${isCheckIn}`);
            } else {
              close({ refresh: true });
            }
          }}
        />
      </div>
    </div>
  );
}
