import CounselorYoutube from './CounselorYoutube';

export default function AssignedCounselorContent({ counselor }) {
  return (
    <div className="w-full ">
      <div className={`w-full max-w-[380px]  mx-auto bg-white rounded-[20px] overflow-hidden `}>
        <div className="flex flex-col items-center justify-between ">
          <div
            className="flex flex-col mx-auto w-full bg-[#F3F5F8] w-[120px] h-[120px] rounded-[100%] overflow-hidden relative"
            style={{
              border: '1px inset rgba(0, 0, 0, 0.04)',
            }}
          >
            <div
              className="w-full h-full bg-black opacity-[0.1] absolute"
              style={{
                borderRadius: '16px 16px 0 0',
              }}
            />
            <img src={counselor.profileImageUrl} alt={'empty'} className="w-full" />
          </div>
          <div className="mt-[15px] text-[20px] leading-[26px] font-bold text-[#26282C] line-clamp-2 ">
            {counselor.name} 상담사
          </div>
        </div>

        <div className="w-full max-h-[120px]  bg-[#F3F5F8] rounded-[8px] px-[15px] py-[20px] my-[24px] border-[1px] border-[rgba(0,0,0,0.05)]">
          {counselor.careerList.slice(0, 3).map((career, index) => (
            <div className="w-full mb-[4px] flex items-start" key={`career${index}`}>
              <div className="w-[3px] h-[3px] rounded-[3px] bg-[#80838B] mr-[5px] mt-[8px] shrink-0" />
              <p className="text-[15px] leading-[21px] text-[#6B6D76] whitespace-nowrap overflow-hidden text-ellipsis">
                {career}
              </p>
            </div>
          ))}
        </div>
        {counselor.interviewVideoId && <CounselorYoutube videoId={counselor.interviewVideoId} />}
      </div>
    </div>
  );
}
