/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context';

import OwlStart from './OwlStart';
import OwlQA from './OwlQA';
import OwlEnd from './OwlEnd';
import OwlAnalysis from './OwlAnalysis';
import OwlResult from './OwlResult';

export default function Owl() {
  let { id } = useParams();
  const context = useContext(AuthContext);

  const choices = [
    { selfCheckChoiceId: 1, score: 0, choice: '전혀 아님' },
    { selfCheckChoiceId: 2, score: 1, choice: '아님' },
    { selfCheckChoiceId: 3, score: 2, choice: '보통' },
    { selfCheckChoiceId: 4, score: 3, choice: '그럼' },
    { selfCheckChoiceId: 5, score: 4, choice: '자주 그럼' },
  ];

  const types = [
    { id: 4, name: '낮 시간 활동 문제', qcIdList: [1, 5, 9, 13, 17] },
    { id: 2, name: '저녁 시간 활동 문제', qcIdList: [2, 6, 10, 14, 18] },
    { id: 1, name: '수면 습관 문제', qcIdList: [3, 7, 11, 15, 19] },
    { id: 3, name: '정서적 문제', qcIdList: [4, 8, 12, 16, 20] },
  ];

  const qcList = [
    {
      id: 1,
      question: '종종 낮잠을 잔다',
    },
    {
      id: 2,
      question: '잠들기 2시간 전에 운동할 때가 많다',
    },
    {
      id: 3,
      question: '잠이 오지 않아도 침대에 누워있는다',
    },
    {
      id: 4,
      question: '근심과 걱정 때문에 뒤척일 때가 있다',
    },
    {
      id: 5,
      question: '낮에도 주로 어두운 곳에서 생활한다',
    },
    {
      id: 6,
      question: '자기 전에 과식할 때가 있다',
    },
    {
      id: 7,
      question: '그다지 졸리지 않아도 자러 갈 때가 많다',
    },
    {
      id: 8,
      question: '못 잘까봐 불안해서 뒤척일 때가 있다',
    },
    {
      id: 9,
      question: '커피를 즐겨 마신다',
    },
    {
      id: 10,
      question: '아무리 배가 고파도 저녁 식사 이후에는 간식을 먹지 않는다',
    },
    {
      id: 11,
      question: '침대에서 잠자는 것 외에 다른 활동들을 하곤 한다',
    },
    {
      id: 12,
      question: '밤이 되면 혼자가 된 것 같은 기분이 든다',
    },
    {
      id: 13,
      question: '운동은 거의 하지 않는다',
    },
    {
      id: 14,
      question: '잘 자기 위해 술을 마실 때가 있다',
    },
    {
      id: 15,
      question: '불을 켜고 잠들 때가 많다',
    },
    {
      id: 16,
      question: '침대에 누우면 머리에 전구를 켠 것처럼 각성될 때가 있다',
    },
    {
      id: 17,
      question: '주기적으로 담배를 피운다',
    },
    {
      id: 18,
      question: '저녁 식사 이후에도 일이나 공부를 한다',
    },
    {
      id: 19,
      question: '반드시 일정한 시간은 자야 한다는 생각이 강하다',
    },
    {
      id: 20,
      question: '무기력하고 울적한 날이면 잠에 들지 못한다',
    },
  ];

  return (
    <div className={`w-screen   `}>
      {parseInt(id) === 0 && <OwlStart />}
      {parseInt(id) > 0 && !isNaN(parseInt(id)) && <OwlQA choices={choices} qcList={qcList} />}
      {id === 'end' && <OwlEnd />}
      {id === 'analysis' && <OwlAnalysis types={types} />}
      {id === 'result' && <OwlResult types={types} />}
    </div>
  );
}
