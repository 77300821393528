import tool1 from '../image/tool1.png';
import tool2 from '../image/tool2.png';
import tool3 from '../image/tool3.png';
import tool4 from '../image/tool4.png';
import tool5 from '../image/tool5.png';
import tool6 from '../image/tool6.png';
import tool7 from '../image/tool7.png';
import tool8 from '../image/tool8.png';
import tool9 from '../image/tool9.png';

const toolData = [
  {
    id: 1,
    toolId: 'tool-1',
    title: '자가 검사',
    image: tool1,
    subtitle: '매일 기록하면 긍정적인 마음을 기를 수 있어요.',
    backgroundColor: '#D9E3FF',
  },
  {
    id: 2,
    toolId: 'tool-2',
    title: '감정 노트',
    image: tool2,
    subtitle: '매일 기록하면 긍정적인 마음을 기를 수 있어요.',
    backgroundColor: '#D7EEFF',
  },
  {
    id: 3,
    toolId: 'tool-3',
    title: '감사 일기',
    image: tool3,
    subtitle: '매일 기록하면 긍정적인 마음을 기를 수 있어요.',
    backgroundColor: '#D3DAFF',
  },
  {
    id: 4,
    toolId: 'tool-4',
    title: '음식 캘린더',
    image: tool4,
    subtitle: '매일 기록하면 긍정적인 마음을 기를 수 있어요.',
    backgroundColor: '#D9E3FF',
  },
  {
    id: 5,
    toolId: 'tool-5',
    title: '숙면 스티커',
    image: tool5,
    subtitle: '매일 기록하면 긍정적인 마음을 기를 수 있어요.',
    backgroundColor: '#C9E0C3',
  },
  {
    id: 6,
    toolId: 'tool-6',
    title: '마음챙김 시계',
    image: tool6,
    subtitle: '매일 기록하면 긍정적인 마음을 기를 수 있어요.',
    backgroundColor: '#E9D7FF',
  },
  {
    id: 7,
    toolId: 'tool-7',
    title: '자존감 노트',
    image: tool7,
    subtitle: '매일 기록하면 긍정적인 마음을 기를 수 있어요.',
    backgroundColor: '#F4D3FF',
  },
  {
    id: 8,
    toolId: 'tool-8',
    title: '회고 노트',
    image: tool8,
    subtitle: '내 감정을 객관적으로 교정하는 습관을 길러요.',
    backgroundColor: '#E3E8FE',
  },
  {
    id: 9,
    toolId: 'tool-9',
    title: '규칙 카드',
    image: tool9,
    subtitle: '나에게 좋은 영향을 주는 새로운 규칙을 익혀요.',
    backgroundColor: '#FFF3D3',
  },
  {
    id: 10,
    toolId: 'tool-10',
    title: '호흡 타이머',
    image: tool6,
    subtitle: '격해진 감정을 차분하게 가라앉혀요.',
    backgroundColor: '#FEE9E3',
  },
];

export default toolData;
