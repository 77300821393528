import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

export function AccordianItem(props) {
  const { title, content, bgColor, borderColor } = props;
  const [isSeeMore, setSeeMore] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const detailRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  }, []);

  function seeAnswer() {
    setRotate((rotate) => rotate + 1);
    setSeeMore((isSeeMore) => !isSeeMore);
  }

  return (
    <motion.div
      className={`overflow-hidden rounded-[20px] w-full mx-auto  border-[1px] duration-200`}
      style={{
        backgroundColor: bgColor,
        borderColor: borderColor || bgColor,
        height: isSeeMore && detailRef.current ? `${70 + detailRef.current.offsetHeight}px` : '70px',
      }}
    >
      <div className={`w-full h-full rounded-[16px] pt-[21px]  px-[24px] `} onClick={seeAnswer}>
        <div className=" flex justify-between items-center">
          <p className="text-[17px] leading-[26px] text-[#3A3C40] font-semibold inline ">{title}</p>

          <motion.div
            className="inline-block  pr-[1px]  flex items-center"
            animate={{ rotate: 180 * rotate }}
            transition={{ duration: loaded ? 0.2 : 0 }}
          >
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1.5L6 6.5L11 1.5"
                stroke="#26282C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </motion.div>
        </div>

        <motion.div
          className={`pointer-events-none `}
          animate={{ opacity: isSeeMore ? 1 : 0 }}
          transition={{ duration: loaded ? 0.2 : 0 }}
        >
          <div ref={detailRef}>{content}</div>
        </motion.div>
      </div>
    </motion.div>
  );
}
