/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { CheckLottie } from '../../stories/animation/CheckLottie';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function Analysis3() {
  const [goNext, setGoNext] = useState(false);
  const goAssignPage = useNavigateWithParams('/counselorSearchWizard/done');

  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        setGoNext(true);
      }, 200);
    }, 1600);
  }, []);

  useEffect(() => {
    if (goNext) {
      goAssignPage();
    }
  }, [goNext]);

  return (
    <div
      className={`w-screen h-screen overflow-hidden  bg-[#2C4BEC] px-[20px] text-white flex flex-col justify-center items-center text-center  font-bold `}
    >
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="pb-[30px]"
      >
        <div className="mb-[30px] flex justify-center">
          <CheckLottie containerSize={'77px'} white />
        </div>
        <div className=" text-[24px] leading-[30px] ">{'전담 상담사가 배정됐어요'}</div>
      </motion.div>
    </div>
  );
}
