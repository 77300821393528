/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { AnxyActionButton } from '../../stories/button/ActionButton';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';
import somny_rabbit from '../../image/somny/somny_rabbit.png';

export default function Somny2Result4(props) {
  const setMixpanel = useMixpanelWeb({ eventName: '[3차] 검사결과 - somny', projectName: 'somny' });
  const setMixpanel2 = useMixpanelWeb({ eventName: '[3차] 랜딩페이지 도달', projectName: 'somny' });

  useEffect(() => {
    document.title = `somny 결과`;
    setMixpanel();
  }, []);

  return (
    <div
      className={`w-screen h-screen  pt-[24px] flex flex-col `}
      style={{ background: 'linear-gradient(180deg, #ffffff 50%, #E6ECF6 100%)' }}
    >
      <p className={'flex-none text-[24px] leading-[30px] font-bold px-[20px]'}>Somny가 도움을 줄 수 있을 거예요</p>

      <div className="pt-[24px] flex-1 w-full relative">
        <img
          src={somny_rabbit}
          alt={'empty'}
          className="w-[375px] min-w-[375px] absolute bottom-0 left-[50%] translate-x-[-50%]"
        />
      </div>

      <div className="flex-none w-full pb-[20px] px-[20px]">
        <AnxyActionButton
          state={'ACTIVE'}
          text={'somny 더 알아보기'}
          action={() => {
            setMixpanel2();
            window.location.href = 'https://orwellhealth.io/somny';
          }}
        />
      </div>
    </div>
  );
}
