import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../coachingContext';
import { motion } from 'framer-motion';
import { AnxyActionButton, SecondaryActionButton } from '../../stories/button/ActionButton';
import { PlaceholderInput } from './PlaceholderInput';
import { SkipButton } from '../elements/SkipButton';
// import { useNavigateWithParam } from '../../hooks/useWorkbookNavigate';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { useRecoilState } from 'recoil';
import worryNoteAtom from '../../recoil/anxy/worryNote/atom';

function EmotionalInput({ title, placeholder, backgroundColor, name }) {
  const { id, page } = useParams();
  const context = useContext(CoachingContext);
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);

  const [loaded, setLoaded] = useState(false);
  const [stopFocus, setStopFocus] = useState(false);
  const [forceBlur, setForceBlur] = useState(false);
  const [titleHeight, setTitleHeight] = useState(0);
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [buttonText, setButtonText] = useState('다음');
  const android = /(android)/i.test(navigator.userAgent);

  const [worryNoteState, setWorryNoteState] = useRecoilState(worryNoteAtom);

  const isFirstPage = true;
  const isAndroid = android && isFirstPage ? true : false;

  const goNext = useNavigateWithParams(`/session/${parseInt(id)}/${parseInt(page) + 1}`);

  const titleRef = useRef();
  const containerRef = useRef();

  const setCursorOnFirst = () => {
    if (inputRef.current) {
      const end = inputRef.current.value.length;
      inputRef.current.setSelectionRange(end, end);
      inputRef.current.focus();
    }
  };

  const handleResize = useCallback(() => {
    console.log('resize', window.visualViewport.height, window.innerHeight);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    if (window.visualViewport.height < viewportSize) {
      setKeyboardOpen(true);
    } else {
      setKeyboardOpen(false);
    }

    setViewportSize(window.visualViewport.height);
  }, [viewportSize]);

  useEffect(() => {
    setLoaded(true);
    setButtonState('INACTIVE');

    setKeyboardOpen(true);
    setCursorOnFirst();

    if (titleRef.current) {
      setTitleHeight(titleRef.current.clientHeight);
      console.log(titleRef.current.clientHeight);
    }
    window.visualViewport.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goToNext = () => {
    setStopFocus(true);
    setTimeout(() => {
      goNext();
    }, 400);
  };

  useEffect(() => {
    if (worryNoteState[name] !== '') {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [worryNoteState[name]]);

  function updateValue(value) {
    setWorryNoteState((state) => {
      const updated = { ...state };
      updated[name] = value;
      return updated;
    });
  }
  console.log(viewportSize);

  const inputRef = useRef();

  return (
    <motion.div
      ref={containerRef}
      animate={{
        height: `${viewportSize - 17}px`,
      }}
      style={{
        width: '100%',
        height: '100%',
      }}
      transition={{ ease: 'circOut', duration: loaded ? 0.15 : 0 }}
      className={`select-none flex flex-col justify-between`}
    >
      <div className="h-full w-full px-[20px] justify-between pt-[50px]" style={{ overflowY: 'scroll' }}>
        <div
          ref={titleRef}
          className="font-bold text-[24px] leading-[28.8px] text-[#26282C]"
          style={{ wordBreak: 'keep-all' }}
        >
          {title}
        </div>

        <PlaceholderInput
          placeholder={placeholder}
          value={worryNoteState[name]}
          maxLength={'10'}
          stopFocus={stopFocus}
          forceBlur={forceBlur}
          autofocus={true}
          updateValue={updateValue}
          buttonState={buttonState}
          isAndroid={isAndroid}
          viewportSize={viewportSize}
          titleHeight={titleHeight}
          keyboardOpen={isKeyboardOpen}
          containerRef={containerRef}
        />
      </div>
      <div className="w-full relative bottom-0 ">
        <div
          className="w-full h-[42px] bg-black absolute top-[-40px]"
          style={{
            background: 'linear-gradient(to top, #FFFFFF, rgba(255,255,255,0))',
          }}
        />
        <div className="px-[20px] bg-white ">
          <AnxyActionButton state={buttonState} text={'다음'} action={goNext} />
        </div>
        <div
          className="w-full bg-white"
          style={{
            pointerEvents: 'none',
            height: !isKeyboardOpen && context.biggerMargin ? `34px` : `20px`,
          }}
        ></div>
      </div>
    </motion.div>
  );
}
export default EmotionalInput;
