/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import { BottomAction } from '../../stories/page/BottomAction';
import TestResultChart from '../../components/selfCheck/TestResultChart';
import HistoryGraph from '../../components/selfCheck/HistoryGraph';
import selfCheckData from '../../data/selfCheckData';
import SelfCheckCard from '../../components/selfCheck/SelfCheckCard';
import { getSelfCheckState } from '../../components/selfCheck/selfCheckLogic';
import { AccordianItem } from '../../stories/text/AccordianItem';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function SelfCheckResult() {
  const context = useContext(AuthContext);
  let { selfCheckId } = useParams();
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === parseInt(selfCheckId));

  const [lastLabel, setLastLabel] = useState(0);
  const [currentLabel, setCurrentLabel] = useState(0);

  const [change, setChange] = useState(0);
  const [searchParams] = useSearchParams();
  const hideButton = searchParams.get('button') === 'false';

  useEffect(() => {
    window.document.body.style.backgroundColor = '#FFFFFF';
    const root = document.getElementById('root');
    root.style.position = 'relative';
    root.style.overflow = 'auto';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('selfCheckResult', context.selfcheckResult);
    document.title = `${selfCheckInfo.selfCheckName} 검사 결과`;

    context.setShowProgressingbar(false);
    setCurrentLabel(getSelfCheckState(parseInt(selfCheckId), context.selfcheckResult.score));

    if (context.selfcheckResult.resultHistoryList) {
      context.selfcheckResult.resultHistoryList.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });

      if (context.selfcheckResult.resultHistoryList.length >= 2) {
        setLastLabel(
          getSelfCheckState(
            parseInt(selfCheckId),
            context.selfcheckResult.resultHistoryList[context.selfcheckResult.resultHistoryList.length - 2].score,
          ),
        );
      }
    }
  }, []);

  useEffect(() => {
    if (lastLabel < currentLabel) {
      setChange(-1);
    } else if (lastLabel > currentLabel) {
      setChange(1);
    }
  }, [lastLabel, currentLabel]);

  const Element = (
    <div className="w-full h-full overflow-x-hidden  ">
      <div className="w-full relative bg-[#1A2960]">
        <div className="w-full h-[54px] pt-[10px]  flex items-center justify-center text-[17px] leading-[26px] text-white font-semibold">
          {selfCheckInfo.selfCheckName} 검사 결과
        </div>
        <img src={selfCheckInfo.image_result} alt={'empty'} />
        <p
          className="w-full px-[20px] text-center absolute bottom-[29px] text-[#D1D5DC] text-[14px] leading-[20px]  "
          style={{ wordBreak: 'keep-all' }}
        >
          {selfCheckInfo.source}
        </p>
      </div>
      <div className="w-full  bg-white  px-[20px] ">
        <div className="w-full pt-[30px] pb-[40px]">
          <p className=" font-bold text-[24px] leading-[30px] text-[#26282C]  mb-[20px] ">
            {context.selfcheckResult.diagnosisTitle}
          </p>
          <div className="w-full pt-[28px] pb-[20px] rounded-[12px] bg-[#F3F5F8] flex flex-col items-center">
            <div className="w-[42px] h-[42px] mb-[15px]">
              <img src={selfCheckInfo.result[currentLabel].emoji} alt={'empty'} />
            </div>
            <p className="  font-normal text-[15px] leading-[21px] text-[#4E4F53]   ">
              {context.selfcheckResult.diagnosisSubTitle}
            </p>
          </div>
          <div className="mt-[50px]">
            <TestResultChart
              examName={selfCheckInfo.selfCheckName}
              isAbnormal={context.selfcheckResult.isAbnormal}
              score={context.selfcheckResult.score}
            />
          </div>
        </div>
        {context.selfcheckResult.resultHistoryList && context.selfcheckResult.resultHistoryList.length >= 2 && (
          <div className="w-full  pt-[30px] pb-[40px] border-t-[1px] border-[#F3F5F8]">
            <p className=" font-bold text-[20px] leading-[26px] text-[#26282C]  mb-[6px] ">
              {change === -1 && (!selfCheckInfo.reverse ? '지난 검사보다 나빠졌어요' : '지난 검사보다 좋아졌어요')}
              {change === 0 && '지난 검사와 유사해요'}
              {change === 1 && (!selfCheckInfo.reverse ? '지난 검사보다 좋아졌어요' : '지난 검사보다 나빠졌어요')}
            </p>
            <p className="  font-normal text-[15px] leading-[21px] text-[#6B6D76]   ">
              {change === 0 && `${selfCheckInfo.result[currentLabel].label} 단계가 유지되고 있어요`}

              {change !== 0 &&
                `${selfCheckInfo.result[lastLabel].label}에서 ${selfCheckInfo.result[currentLabel].label} 단계로 변화했어요.`}
            </p>
            <div className="mt-[20px] mb-[74px]">
              <HistoryGraph selfCheckId={selfCheckId} resultHistoryList={context.selfcheckResult.resultHistoryList} />
            </div>
          </div>
        )}
        {context.selfcheckResult.analysisList && (
          <div className="w-full bg-white  py-[40px] border-t-[1px] border-[#F3F5F8]">
            <div className="mb-[30px]">
              {context.selfcheckResult.analysisList.map((analysis, index) => (
                <div
                  key={`analysis${index}`}
                  className={`${index !== context.selfcheckResult.analysisList.length - 1 ? 'mb-[30px]' : ''}`}
                >
                  <p className="text-[18px] leading-[24px] font-bold text-[#26282C] mb-[10px]">{analysis.title}</p>
                  <p className="text-[17px] leading-[23px] font-normal text-[#4E4F53]">{analysis.body}</p>
                </div>
              ))}
            </div>

            {selfCheckInfo.description && (
              <AccordianItem
                bgColor={'#FFFFFF'}
                borderColor={'#E1E4EB'}
                title={selfCheckInfo.description.title}
                content={
                  <div className="pt-[24px]">
                    <p className="text-[16px] leading-[24px] text-[#4E4F53] ">{selfCheckInfo.description.text}</p>
                    <div className="w-full h-[1px] bg-[#F3F5F8] my-[24px] " />
                    <p className="text-[18px] leading-[24px] text-[#3A3C40] font-bold mb-[10px]">
                      {selfCheckInfo.symptom.title}
                    </p>
                    <div className="text-[16px] leading-[24px] text-[#4E4F53]">
                      {selfCheckInfo.symptom.text.map((each, index) => (
                        <div className="flex " key={`a${index}`}>
                          <span className="mr-[3px]">･</span>
                          <span>{each}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              />
            )}
          </div>
        )}
      </div>
      {context.availableSelfCheck.length > 0 && (
        <div className="w-full pt-[60px] pb-[40px] border-t-[1px] border-[#F3F5F8]">
          <div>
            <p className="px-[20px]  text-[22px] leading-[28px] font-bold text-[#26282C] mb-[20px]">
              다른 검사도 이어서 받아보세요
            </p>
            <div className="w-screen flex  overflow-x-scroll scrollbar-hide ">
              <div className="min-w-[20px] " />

              {context.availableSelfCheck.map((selfCheck, index) => (
                <div
                  key={`selfCheck${index}`}
                  className={`
                      ${index !== context.availableSelfCheck.length - 1 ? 'mr-[10px]' : ''}`}
                >
                  <SelfCheckCard selfCheckInfo={selfCheck} />
                </div>
              ))}
              <div className="min-w-[20px] " />
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const goSelfCheckHome = useNavigateWithParams(`/mindScan`);
  return hideButton ? (
    Element
  ) : (
    <BottomAction
      content={Element}
      buttonText={'확인'}
      buttonState={'ACTIVE'}
      action={() => {
        goSelfCheckHome();
      }}
    />
  );
}
