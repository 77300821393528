import { atom } from 'recoil';

export const TYPE_HOME = 'home';
export const TYPE_REPORT = 'report';

const somnyHomeAtom = atom({
  key: 'somny_home_status',
  default: {
    type: TYPE_HOME,
    scrollToSession: undefined,
    somnyWakeUp: undefined,
  },
});

export default somnyHomeAtom;
