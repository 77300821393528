import Lottie from '../../../components/elements/Lottie';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { SomnyActionButton } from '../../../stories/button/RoundActionButton';
import somnySleeping from '../../../stories/animation/somny_sleeping.json';
import somnyKnitting from '../../../stories/animation/somny_knitting.json';
import somnyStars from '../../../stories/animation/somny_stars.json';

import somnyGoToBed from '../../../stories/animation/somny_gotobed.json';
import forestRecord from '../../../image/somny/home/forest_record.png';
import forestDream from '../../../image/somny/home/forest_dream.png';
import forestTale from '../../../image/somny/home/forest_tale.png';

import iconDream from '../../../image/somny/home/icon_dream.png';
import iconTale from '../../../image/somny/home/icon_tale.png';
import iconRecord from '../../../image/somny/home/icon_record.png';

import knitting1 from '../../../image/somny/home/knitting_1.png';

import { KnitItem } from '../../../components/somny/home/KnitItem';
import { somnyFairyTaleData } from '../../../data/somny/somnyFairyTaleData';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import statusAtom, {
  STATUS_DREAM,
  STATUS_KNIT_OPEN,
  STATUS_RECORD,
  STATUS_TALE,
  STATUS_TALE_DONE,
} from '../../../recoil/somny/status/atom';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import deviceAtom from '../../../recoil/common/device';
import Test from '../../../development/Test';
import { PressedEffect } from '../../../stories/button/PressedEffect';
import useInterval from '../../../hooks/useInterval';
import AnimateDreamMessage from '../../../components/somny/home/AnimateDreamMessage';
import somnyHomeAtom from '../../../recoil/somny/home/atom';
import webviewToast from '../../../development/webviewToast';
import { SomnyTimeline } from '../../../components/somny/home/SomnyTimeline';

export function HomeWIthTale({ isNight, dayList, nightList, knitList, taleId, setScrollTo }) {
  const [homeState, setHomeState] = useRecoilState(somnyHomeAtom);
  const [tempStatus, setTempState] = useState();
  const [statusState, setStatusState] = useRecoilState(statusAtom);
  const [currentSomnyStatus, setCurrentSomnyStatus] = useState(statusState.somnyStatus);
  const [changeLottie, setChangeLottie] = useState(false);

  const taleData = somnyFairyTaleData.find((item) => item.taleId === taleId);
  const prevTaleData = taleData.seq !== 1 && somnyFairyTaleData.find((item) => item.seq === taleData.seq - 1);

  const somnyContainer = useRef();

  const goToPayment = useNavigateWithParams('/somnyPayment');
  const goToTale = useNavigateWithParams(`/taleHome`);
  const goToTaleAgain = useNavigateWithParams(`/taleHome`);
  const goToRecordHome = useNavigateWithParams(`/somnyRecord/0`);
  const goToRecord = () => (statusState.isPaymentRequired ? goToPayment() : goToRecordHome());

  const handleGoToTale = () => {
    if (statusState.isCompleteTodaySleepFairyTale) {
      goToTaleAgain();
    } else {
      goToTale();
    }
  };

  const goToKnit = () => {
    const notchSize = deviceState.notchSize;
    setScrollTo(somnyContainer && somnyContainer.current && somnyContainer.current.offsetHeight - notchSize);
    setTimeout(() => {
      setScrollTo(0);
    }, 1000);
  };

  const deviceState = useRecoilValue(deviceAtom);

  const bottomTabHeight = deviceState.hasHomeIndicator ? 112 : 98;

  const timerId = useInterval(
    () => {
      if (currentSomnyStatus === STATUS_DREAM || tempStatus === STATUS_DREAM) {
        if (homeState.somnyWakeUp) {
          setHomeState((state) => ({ ...state, somnyWakeUp: undefined }));
          setChangeLottie(true);
        }
      } else {
        setChangeLottie(false);
      }
      clearInterval(timerId);
    },
    5000,
    [currentSomnyStatus, tempStatus],
  );

  useEffect(() => {
    if (changeLottie) {
      if (currentSomnyStatus !== STATUS_DREAM && tempStatus !== STATUS_DREAM) {
        setChangeLottie(false);
      }
    }
  }, [changeLottie, currentSomnyStatus, tempStatus]);

  useEffect(() => {
    setCurrentSomnyStatus(statusState.somnyStatus);
  }, [statusState]);

  useEffect(() => {
    if (homeState.scrollToSession) {
      const notchSize = deviceState.notchSize;
      setScrollTo(somnyContainer && somnyContainer.current && somnyContainer.current.offsetHeight - notchSize);
      setHomeState((state) => ({ ...state, scrollToSession: undefined }));
    }
  }, [homeState]);

  const statusData = [
    {
      status: STATUS_DREAM,
      icon: iconRecord,
      forest: forestRecord,
      gradient: 'linear-gradient(180deg, #366F8F 0%, #165054 45.83%, #060727 100%)',
    },
    {
      status: STATUS_KNIT_OPEN,
      icon: iconRecord,
      forest: forestRecord,
      gradient: 'linear-gradient(180deg, #366F8F 0%, #165054 45.83%, #060727 100%)',
    },
    {
      status: STATUS_TALE,
      icon: iconTale,
      forest: forestTale,
      gradient: 'linear-gradient(180deg, #36398F 0%, #161854 45.83%, #060727 100%)',
    },
    {
      status: STATUS_TALE_DONE,
      icon: iconTale,
      forest: forestTale,
      gradient: 'linear-gradient(180deg, #36398F 0%, #161854 45.83%, #060727 100%)',
    },
    {
      status: STATUS_RECORD,
      icon: iconRecord,
      forest: forestRecord,
      gradient: 'linear-gradient(180deg, #366F8F 0%, #165054 45.83%, #060727 100%)',
    },
  ];

  const currentStatusData = statusData.find((item) => item.status === currentSomnyStatus);

  const GoToTailMessage = () => (
    <div className="relative top-[41px] max-w-[335px] mx-auto z-[50] w-full rounded-[30px] bg-[#222455] px-[20px] pt-[24px] pb-[20px] text-center">
      {currentSomnyStatus === STATUS_TALE ? (
        <div>
          <p className="text-white text-[20px] leading-[26px] font-bold pb-[16px]">
            {`오늘 밤 수면 동화로 \n`}
            <span className="text-[#79AEFF]">
              {`‘${statusState.isCompleteTodaySleepFairyTale ? prevTaleData.title : taleData.title}’`}
            </span>
            을 가져왔어요
          </p>
          <SomnyActionButton state="ACTIVE" text="동화 듣기" small action={handleGoToTale} />
        </div>
      ) : currentSomnyStatus === STATUS_DREAM ? (
        <div>
          <p className="text-white text-[20px] leading-[26px] font-bold">
            {`새로운 동화를 채집하러 \n 꿈나라에 다녀올게요`}
          </p>
        </div>
      ) : currentSomnyStatus === STATUS_TALE_DONE ? (
        <div>
          <p className="text-white text-[20px] leading-[26px] font-bold pb-[16px]">
            {`아직 잠에 들지 못했나요? \n 동화를 한 번 더 들어봐요`}
          </p>
          <SomnyActionButton state="ACTIVE" text="동화 다시 듣기" small black action={handleGoToTale} />
        </div>
      ) : currentSomnyStatus === STATUS_KNIT_OPEN ? (
        <div>
          <p className="text-white text-[20px] leading-[26px] font-bold pb-[16px]">
            {`내 뜨개 선물을 받으면 \n 좋아하겠지...?`}
          </p>
          <SomnyActionButton state="ACTIVE" text="뜨개 확인하기" small action={goToKnit} />
        </div>
      ) : (
        <div>
          <p className="text-white text-[20px] leading-[26px] font-bold pb-[16px]">
            {`잘 잤나요? \n 지난 밤은 어땠나요?`}
          </p>
          <SomnyActionButton state="ACTIVE" text="수면 기록하기" small action={goToRecord} />
        </div>
      )}
      <svg
        className="absolute left-[50%] bottom-[-13px] translate-x-[-50%]"
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5858 12.6041C11.4259 14.5761 8.57415 14.5761 7.4142 12.6041L-8.30536e-07 1.57169e-06L20 -1.76765e-07L12.5858 12.6041Z"
          fill="#222455"
        />
      </svg>
    </div>
  );

  const TempLottie = () => (
    <div className="relative">
      {tempStatus === STATUS_DREAM ? (
        <div className="z-[40] w-[377px] h-[366px] relative left-[50%] translate-x-[-50%]">
          <Lottie
            width={377}
            height={366}
            lottieData={!homeState.somnyWakeUp ? somnySleeping : somnyGoToBed}
            autoplay
            loop={!homeState.somnyWakeUp}
          />
        </div>
      ) : tempStatus === STATUS_KNIT_OPEN ? (
        <div className="z-[40] w-[377px] h-[366px] relative left-[50%] translate-x-[-50%]">
          <Lottie width={377} height={366} lottieData={somnySleeping} autoplay loop />
        </div>
      ) : (
        <div className="z-[40] w-[377px] h-[366px] relative left-[50%] translate-x-[-50%]">
          <Lottie width={377} height={366} lottieData={somnyKnitting} loop autoplay />
        </div>
      )}
    </div>
  );

  const SleepingLottie = () => (
    <div className="z-[40] w-[377px] h-[366px] relative left-[50%] translate-x-[-50%]">
      <Lottie
        width={377}
        height={366}
        lottieData={!homeState.somnyWakeUp ? somnySleeping : somnyGoToBed}
        autoplay
        loop={!homeState.somnyWakeUp}
      />
    </div>
  );

  return (
    <div className="flex flex-col">
      <div className="h-full w-full">
        <div>
          {!currentStatusData.isNotLottie && (
            <div className="absolute top-[0] h-[199px] z-[10]">
              <Lottie lottieData={somnyStars} loop autoplay />
            </div>
          )}
          <div
            className="absolute w-full px-[20px] z-[50]"
            style={{
              top: deviceState.notchSize + 44,
            }}
          >
            <SomnyTimeline isNight={isNight} dayList={dayList} nightList={nightList} />
          </div>
          <div className="h-[170px] w-full overflow-hidden absolute">
            <div
              className="w-full h-[170px] absolute top-0"
              style={{
                background: currentStatusData.gradient,
              }}
            />
          </div>
          <div className="absolute w-full left-[50%] translate-x-[-50%] top-[22px] z-[20]">
            <img src={currentStatusData.forest} alt="empty" className="object-cover w-full h-full max-h-[657px]" />
          </div>
        </div>
        <div
          ref={somnyContainer}
          className="min-h-[578px] overflow-hidden relative z-[50] w-full left-[50%] translate-x-[-50%] flex flex-col justify-end min-h-[548px]"
          style={{
            height: `calc(${window.innerHeight}px - ${bottomTabHeight + 12}px)`,
          }}
        >
          <div className="absolute right-[20px] bottom-[100px] z-[70] text-white">
            <Test
              element={
                <div className="flex flex-col text-center">
                  <p className="font-bold mb-[10px]">상태 바꾸기</p>
                  <PressedEffect
                    element={<div className="w-fit rounded-[20px] border-1 p-[8px] mb-[10px]">동화 듣기</div>}
                    action={() => {
                      setStatusState((state) => ({ ...state, somnyStatus: STATUS_TALE }));
                      setTempState(STATUS_TALE);
                    }}
                  />
                  <PressedEffect
                    element={<div className="rounded-[20px] border-1 p-[8px] mb-[10px]">기록하기</div>}
                    action={() => {
                      setStatusState((state) => ({ ...state, somnyStatus: STATUS_RECORD }));
                      setTempState(STATUS_RECORD);
                    }}
                  />
                  <PressedEffect
                    element={<div className="rounded-[20px] border-1 p-[8px] mb-[10px]">배우기</div>}
                    action={() => {
                      setStatusState((state) => ({ ...state, somnyStatus: STATUS_DREAM }));
                      setTempState(() => STATUS_DREAM);
                    }}
                  />
                  <PressedEffect
                    element={<div className="rounded-[20px] border-1 p-[8px] mb-[10px]">뜨개 획득</div>}
                    action={() => {
                      setStatusState((state) => ({ ...state, somnyStatus: STATUS_KNIT_OPEN }));
                      setTempState(() => STATUS_KNIT_OPEN);
                    }}
                  />
                </div>
              }
            />
          </div>
          <div className="relative flex justify-center w-full px-[20px] pb-[27.18px] ">
            {(tempStatus === STATUS_DREAM || currentSomnyStatus === STATUS_DREAM) && homeState.somnyWakeUp && (
              <GoToTailMessage />
            )}
            {tempStatus !== STATUS_DREAM && currentSomnyStatus !== STATUS_DREAM && <GoToTailMessage />}
            {(tempStatus === STATUS_DREAM || currentSomnyStatus === STATUS_DREAM) && !homeState.somnyWakeUp && (
              <div className="relative top-[41px]">
                <AnimateDreamMessage taleData={taleData} />
              </div>
            )}
          </div>
          {tempStatus ? (
            <TempLottie />
          ) : (
            <div className="relative">
              {currentSomnyStatus === STATUS_DREAM ? (
                <SleepingLottie />
              ) : currentSomnyStatus === STATUS_KNIT_OPEN ? (
                <div className="z-[40] w-[377px] h-[366px] relative left-[50%] translate-x-[-50%]">
                  <Lottie width={377} height={366} lottieData={somnySleeping} autoplay loop />
                </div>
              ) : (
                <div className="z-[40] w-[377px] h-[366px] relative left-[50%] translate-x-[-50%]">
                  <Lottie width={377} height={366} lottieData={somnyKnitting} loop autoplay />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="bg-[#060727] z-[40] h-[2874px]">
        <div className="h-[3574px] overflow-hidden flex flex-col relative max-w-[335px] min-w-[320px] mx-auto">
          <div className="w-[212.76px] h-[276.15px]">
            <img src={knitting1} alt="empty" className="w-[212.76px] h-[276.15px] ml-[43px]" />
          </div>
          {knitList.map((item, index) => (
            <KnitItem
              key={item.knitId}
              index={index}
              knitId={item.knitId}
              isLock={item.isLock}
              isComplete={item.isComplete}
              isLast={index === knitList.length - 1}
              isFirstLock={knitList.filter((item) => item.isLock)[0] === item}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
