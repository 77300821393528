import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import programStart from '../../image/programStart.png';
import { CheckLottie } from '../../stories/animation/CheckLottie';
import { ProgressingBarThin } from '../../stories/else/ProgressingBarThin';
import { motion } from 'framer-motion';

export default function CoachMatching4(props) {
  const [progress1Start, setProgrss1Start] = useState(false);
  const [lottie2Start, setLottie2Start] = useState(false);
  const [progress2Start, setProgrss2Start] = useState(false);
  const [lottie3Start, setLottie3Start] = useState(false);
  const [flagStart, setflagStart] = useState(false);
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  useEffect(() => {
    setTimeout(() => {
      setProgrss1Start(true);
      setTimeout(() => {
        setLottie2Start(true);
        setTimeout(() => {
          setProgrss2Start(true);
          setTimeout(() => {
            setLottie3Start(true);
            setTimeout(() => {
              setflagStart(true);
            }, 500);
          }, 500);
        }, 500);
      }, 500);
    }, 500);
  }, []);
  function goNext() {
    // navigate(`/coachMatching/2`);
  }

  return (
    <div className={`w-screen h-full bg-[#EFF2FF]`}>
      <div className="w-full h-full px-[20px] ">
        <div className={`${context.biggerMargin ? 'h-[calc(100%-88px)]' : 'h-[calc(100%-74px)]'} flex flex-col`}>
          <div>
            <div className="pt-[12px] mb-[15px]">
              <p className="font-bold text-[28px] leading-[34px] text-[#26282C]">
                {['이제 맞춤 프로그램을', <br />, '시작하세요']}
              </p>
            </div>
            <div className="text-[17px] leading-[23px] font-normal text-[#4E4F53]">
              배정된 코스를 꾸준히 따라가면 분명 지금보다 더 나아질 거에요.
            </div>
          </div>
          <div className="w-full flex-1 relative">
            <div className=" w-full absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] ">
              <div className="w-full px-[40px] flex items-center relative">
                <motion.div
                  className="absolute bottom-[36px] right-[6px] origin-bottom"
                  animate={{ scale: flagStart ? 1 : 0 }}
                  transition={{ type: 'spring', damping: 10, stiffness: 100, duration: 0.65 }}
                >
                  <img src={programStart} alt={'empty'} className="w-[77px] h-[77px] " />
                </motion.div>
                <div className="w-[24px] h-[24px] rounded-[24px] ring-[1px] ring-inset ring-[#373EED]">
                  <CheckLottie containerSize={'24px'} gradient={true} />
                </div>
                <div className="w-[calc((100%-72px)/2)] z-0">
                  <ProgressingBarThin
                    completed={progress1Start ? 1 : 0}
                    color={'radial-gradient(99.83% 1234.86% at 99.83% 68.01%, #5513F0 0%, #2C4BEC 100%)'}
                    height={'2px'}
                    animateOnRender={true}
                    duration={'0.5s'}
                  />
                </div>
                <div className="w-[24px] h-[24px] rounded-[24px] ring-[1px] ring-inset ring-[#373EED]">
                  {lottie2Start && <CheckLottie containerSize={'24px'} gradient={true} />}
                </div>
                <div className="w-[calc((100%-72px)/2)] z-0">
                  <ProgressingBarThin
                    completed={progress2Start ? 1 : 0}
                    color={'radial-gradient(99.83% 1234.86% at 99.83% 68.01%, #5513F0 0%, #2C4BEC 100%)'}
                    height={'2px'}
                    animateOnRender={true}
                    duration={'0.5s'}
                  />
                </div>
                <div className="w-[24px] h-[24px] rounded-[24px] ring-[1px] ring-inset ring-[#373EED]">
                  {lottie3Start && <CheckLottie containerSize={'24px'} gradient={true} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'} w-full`}>
          <PrimaryActionButton state={'ACTIVE'} text={'다음'} action={goNext} />
        </div>
      </div>
    </div>
  );
}
