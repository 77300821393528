/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function Begin() {
  const goSearch = useNavigateWithParams('/counselorSearchWizard/search');

  const [goNext, setGoNext] = useState(false);
  const [firstTextOut, setFirstTextOut] = useState(false);
  const [secondTextIn, setSecondTextIn] = useState(false);
  const [secondTextOut, setSecondTextOut] = useState(false);

  useEffect(() => {
    if (goNext) {
      goSearch();
    }
  }, [goNext]);

  useEffect(() => {
    setTimeout(() => {
      setFirstTextOut(true);
      setTimeout(() => {
        setSecondTextIn(true);
        setTimeout(() => {
          setSecondTextOut(true);
          setTimeout(() => {
            setGoNext(true);
          }, 500);
        }, 1200);
      }, 500);
    }, 1200);
  }, []);

  return (
    <div
      className={`w-screen h-screen overflow-hidden  bg-[#2C4BEC] px-[20px] text-white flex flex-col justify-center items-center `}
      style={{ wordBreak: 'keep-all' }}
    >
      {!secondTextIn && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: !firstTextOut ? 1 : 0, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5, ease: 'easeInOut' }}
          className=" text-[24px] leading-[30px] font-bold  text-center  pb-[30px]"
        >
          {'지금부터 마음 속 이야기를 하나씩 꺼내볼게요'}
        </motion.div>
      )}
      {secondTextIn && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: !secondTextOut ? 1 : 0, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5, ease: 'easeInOut' }}
          className=" text-[24px] leading-[30px] font-bold  text-center  pb-[30px]"
        >
          {'떠오르는 대로 솔직하게 선택해주세요'}
        </motion.div>
      )}
    </div>
  );
}
