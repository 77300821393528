import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Main from './Main';
import webviewToast from '../../development/webviewToast';
import ExampleAndQA from './ExampleAndQA';
import Result from './Result';
import Preview from './Preview';
import Submit from './Submit';
import useSessionStorage from '../../hooks/useSessionStorage';
import defaultState from '../../data/inside/defaultState';

export default function InsideBlack() {
  const { id } = useParams();
  const [insideState, setInsideState] = useSessionStorage('insideState', defaultState);

  const root = document.getElementById('root');
  root.style.position = 'fixed';
  root.style.overflow = 'hidden';

  useEffect(() => {
    if (id === 'start') {
      setInsideState(defaultState);
    }
  }, [id]);

  return (
    <div
      className={`w-screen bg-black`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      {id === 'start' && <Main />}
      {parseInt(id) > 0 && !isNaN(parseInt(id)) && <ExampleAndQA />}
      {id === 'result' && <Result />}
      {id === 'resultList' && <Result resultList />}
      {id === 'submit' && <Submit />}
    </div>
  );
}
