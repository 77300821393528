/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { AuthContext } from '../../context';

import BetterMeStart from './BetterMeStart';
import BetterMeQA from './BetterMeQA';
import BetterMeEnd from './BetterMeEnd';
import BetterMeAnalysis from './BetterMeAnalysis';

export default function BetterMe() {
  let { id } = useParams();
  const context = useContext(AuthContext);

  const choices = [
    { score: 0, choice: '전혀 아님' },
    { score: 1, choice: '아님' },
    { score: 2, choice: '보통' },
    { score: 3, choice: '그럼' },
    { score: 4, choice: '자주 그럼' },
  ];

  const types = [
    { id: 1, name: '스파르타 다이어터', qcIdList: [1, 7, 13, 19, 25] },
    { id: 2, name: '완벽주의자', qcIdList: [2, 8, 14, 20, 26] },
    { id: 3, name: '자기 팩폭러', qcIdList: [3, 9, 15, 21, 27] },
    { id: 4, name: '지적인 이성주의자', qcIdList: [4, 10, 16, 22, 28] },
    { id: 5, name: '스마일 천사', qcIdList: [5, 11, 17, 23, 29] },
    { id: 6, name: '돌보는 자아', qcIdList: [6, 12, 18, 24, 30] },
  ];
  const qcList = [
    {
      id: 1,
      question: '한번 폭식을 시작하면 멈출 수가 없다.',
    },
    {
      id: 2,
      question: '스스로가 세운 매우 엄격한 다이어트 규칙들이 있다.',
    },
    {
      id: 3,
      question: '나는 쓸모없다는 생각이 들 때가 있다.',
    },
    {
      id: 4,
      question: '누구든 미성숙한 감정들로 어리광을 부리는 건 참을 수가 없다.',
    },
    {
      id: 5,
      question: '항상 밝고 긍정적이며 남들의 부탁을 잘 들어준다.',
    },
    {
      id: 6,
      question: '사회생활에서 주로 다 받아주고 챙겨주는 타입이다.',
    },
    {
      id: 7,
      question: '“기왕 이렇게 된 거 오늘은 마음껏 먹자”라고 생각할 때가 많다.',
    },
    {
      id: 8,
      question: '주변으로부터 학벌, 직업, 외모, 성격 등과 관련하여 칭찬을 많이 듣지만, 나는 늘 부족하다고 느껴진다.',
    },
    {
      id: 9,
      question: '누가 나를 사랑주겠냐는 생각을 하곤 한다.',
    },
    {
      id: 10,
      question: '누구든 미성숙한 감정들로 어리광을 부리는 건 참을 수가 없다.',
    },
    {
      id: 11,
      question: '남들이 원하는 모습이 되는 게 중요하다.',
    },
    {
      id: 12,
      question: '다 받아주고 챙겨주다가 집에 돌아오면 무기력과 공허함을 느낀다.',
    },
    {
      id: 13,
      question: '폭식하고 나면 운동을 해서든, 토를 해서든, 어떻게든 보상을 해야 한다.',
    },
    {
      id: 14,
      question: '세웠던 다이어트 계획이 조금이라도 틀어지면 참을 수 없다.',
    },
    {
      id: 15,
      question: '내가 무언가를 했을 때 돌아올 부정적인 반응들이 걱정된다.',
    },
    {
      id: 16,
      question: '외로움, 슬픔 등 사사로운 감정에 흔들리지 않는 편이다.',
    },
    {
      id: 17,
      question: '사람들에게 맞춰주다보면 스트레스가 쌓여 폭식을 할 때가 있다.',
    },
    {
      id: 18,
      question: '힘들어도 주변 사람들한테는 괜챦은 척 할 때가 많다.',
    },
    {
      id: 19,
      question: '정신없이 음식을 먹고 나면 죄책감이 밀려와 다이어트에 더 집중해야겠다고 다짐한다.',
    },
    {
      id: 20,
      question: '모든 게 완벽해야 마음이 놓인다.',
    },
    {
      id: 21,
      question: '조금이라도 살이 찌면 그건 내가 게으르기 때문이다.',
    },
    {
      id: 22,
      question: '내 상황을 바꾸기 위한 강도 높은 다이어트를 계획적으로 실천하는 편이다.',
    },
    {
      id: 23,
      question: '남들의 비위를 맞춰주지 않으면 모든 이들이 내 곁을 떠날 것이다.',
    },
    {
      id: 24,
      question: '피상적인 인간 관계에서 오는 공허함을 폭식으로 풀 때가 있다.',
    },
    {
      id: 25,
      question: '폭식을 할 땐 그동안 눌려왔던 부정적인 감정들이 해소된다는 기분이 든다.',
    },
    {
      id: 26,
      question: '완벽한 몸을 가지기 위해 더 과감한 다이어트를 한 적도 많다.',
    },
    {
      id: 27,
      question: '잘난 것도 없는데 마르기라도 해야 한다고 생각한다.',
    },
    {
      id: 28,
      question: '감정에 동요되고 있을 바에 러닝머신이라도 한 시간 더 뛰는 게 낫다.',
    },
    {
      id: 29,
      question: '살을 빼서 상대가 원하는 모습이 되면 더 많은 관심과 애정을 받을 수 있을 것이다.',
    },
    {
      id: 30,
      question: '의식적으로 괜찮다고 하며 부정적인 감정이 없는 듯 굴 때가 있다.',
    },
  ];

  // const types = [
  //   { id: 1, name: '애정결핍러', qcIdList: [1, 7, 13, 19, 25] },
  //   { id: 2, name: '무한 외모 컴플렉스', qcIdList: [2, 8, 14, 20, 26] },
  //   { id: 3, name: '죄책감 다이버', qcIdList: [3, 9, 15, 21, 27] },
  //   { id: 4, name: '환상 속의 그녀', qcIdList: [4, 10, 16, 22, 28] },
  //   { id: 5, name: '씹고 뱉는 자', qcIdList: [5, 11, 17, 23, 29] },
  //   { id: 6, name: '대리만족 갈구자', qcIdList: [6, 12, 18, 24, 30] },
  // ];
  // const qcList = [
  //   {
  //     id: 1,
  //     question: '살을 빼면 남들에게 남들에게 더 많은 사랑과 관심을 받을 수 있다.',
  //   },
  //   {
  //     id: 2,
  //     question: '살이 빠지고 외모가 더 예뻐지면 나의 모든 문제가 해결될 것이다.',
  //   },
  //   {
  //     id: 3,
  //     question: '아직까지도 살을 빼지 못하는 건 나의 의지가 약하기 때문이다.',
  //   },
  //   {
  //     id: 4,
  //     question: '과거에 다이어트에 멋지게 성공했던 적이 있다.',
  //   },
  //   {
  //     id: 5,
  //     question: '굶을 바에는 조금이라도 씹고 그냥 뱉어버리는 게 낫다.',
  //   },
  //   {
  //     id: 6,
  //     question: '먹방 영상을 보면서 대리만족을 느낀다.',
  //   },
  //   {
  //     id: 7,
  //     question: '살을 뺐을 때 나를 대하는 사람들의 태도가 더 부드러워졌다고 느꼈다.',
  //   },
  //   {
  //     id: 8,
  //     question: '과거 성형수술을 한 적이 있거나, 향후에 할 계획이 있다.',
  //   },
  //   {
  //     id: 9,
  //     question: '주변으로부터 "간식 하나 참지 못하면서 무얼 바라는 거야”라는 이야기를 듣곤 한다.',
  //   },
  //   {
  //     id: 10,
  //     question: '다이어트에 성공한 후에 자존감이 높아졌던 경험이 있다.',
  //   },
  //   {
  //     id: 11,
  //     question: '위가 가득 찬 느낌이 너무도 싫어서 음식을 삼키지 않고 뱉고 싶다.',
  //   },
  //   {
  //     id: 12,
  //     question: '주로 날씬한 여성이 진행하는 먹방 컨텐츠를 좋아한다.',
  //   },
  //   {
  //     id: 13,
  //     question: '대인관계 문제들은 살을 빼면 상당 부분 해결될 것이다.',
  //   },
  //   {
  //     id: 14,
  //     question: '거울을 보면 외모 중 마음에 안 드는 구석이 꼭 하나씩은 보인다.',
  //   },
  //   {
  //     id: 15,
  //     question: '간식 하나 줄이지 못 하는 본인의 모습을 볼 때면 한심하게 느껴진다.',
  //   },
  //   {
  //     id: 16,
  //     question: '독하게 살을 뺀 경험이 있는데 이번에도 못 할 건 없다.',
  //   },
  //   {
  //     id: 17,
  //     question: '음식이 배에 가득하면 마치 죄를 짓는 기분이라 삼키기 싫다.',
  //   },
  //   {
  //     id: 18,
  //     question: '음식을 많이 먹고도 살이 안 찌는 먹방 유튜버들이 부럽다.',
  //   },
  //   {
  //     id: 19,
  //     question: '상대의 마음을 얻기는 쉽지 않지만 살을 빼는 건 내가 노력한 만큼 결과가 나온다.',
  //   },
  //   {
  //     id: 20,
  //     question: '목표 체중에 가까워져도 여전히 내 체형은 마음에 들지 않는다.',
  //   },
  //   {
  //     id: 21,
  //     question: '스스로가 가치있는 사람인지 의구심이 들 때가 있다.',
  //   },
  //   {
  //     id: 22,
  //     question: '미디어 속 마른 몸매의 연예인을 롤모델로 삼고 있다.',
  //   },
  //   {
  //     id: 23,
  //     question: '정신없이 음식을 입 안에 넣었다가 그냥 뱉어버릴 때가 있다.',
  //   },
  //   {
  //     id: 24,
  //     question: '나는 못 먹는 음식을 먹방 유튜버들이 먹는 걸 보는 게 위로가 된다.',
  //   },
  //   {
  //     id: 25,
  //     question: '타인의 태도나 말에 나의 감정이 쉽게 움직이는 편이다.',
  //   },
  //   {
  //     id: 26,
  //     question: '다이어트를 하거나 성형을 한 이후 시간이 지나면 또 마음에 들지 않는 구석이 보인다.',
  //   },
  //   {
  //     id: 27,
  //     question: '먹는 걸 조절하지 못하는 건 본인의 문제라고 생각한다.',
  //   },
  //   {
  //     id: 28,
  //     question: '다시 살을 빼 과거의 몸매로 돌아간다면 삶의 많은 부분들이 개선될 것이다.',
  //   },
  //   {
  //     id: 29,
  //     question: '음식은 삼키지 않고 맛만 느껴도 충분하다고 생각한다.',
  //   },
  //   {
  //     id: 30,
  //     question: '특히 배가 고플 때면 아무 생각없이 먹방 컨텐츠를 보게 된다.',
  //   },
  // ];
  return (
    <div className={`w-screen  ${context.showProgressingbar ? 'h-[calc(100vh-17px)]' : 'h-full'}  `}>
      {parseInt(id) === 0 && <BetterMeStart />}
      {parseInt(id) > 0 && !isNaN(parseInt(id)) && <BetterMeQA choices={choices} qcList={qcList} />}
      {id === 'end' && <BetterMeEnd />}
      {id === 'analysis' && <BetterMeAnalysis types={types} />}
    </div>
  );
}
