import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import sessionAtom from 'recoil/anxy/session/atom';
import { anxyToolData } from 'data/anxy/home/anxyToolData';
import { marginWithHomeIndicator } from 'recoil/common/device';
import { useState } from 'react';
import animateAtom from 'recoil/anxy/home/animateAtom';
import { getTimeDifference } from 'logic/method';
import toolModalAtom from 'recoil/anxy/session/toolModalAtom';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import { Caption15, SubTitle18 } from 'stories/text/AnxyText';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import hexOpacity from 'hex-opacity';
import { PressedEffect } from 'stories/button/PressedEffect';
import statusAtom from 'recoil/anxy/home/statusAtom';

export default function Tool({ toolList }) {
  const [animate, setAnimate] = useState(true);

  const [animateState, setAnimateState] = useRecoilState(animateAtom);
  const [sessionState, setSessionState] = useRecoilState(sessionAtom);
  const setModalState = useSetRecoilState(toolModalAtom);
  const { isPaymentRequired } = useRecoilValue(statusAtom);

  const goToBreath = useNavigateWithParams(`/breath/0`);
  const goToMeditation = useNavigateWithParams(`/meditationTool/0`);
  const goPayment = useNavigateWithParams(`/payment`);
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  useEffect(() => {
    if (animateState === 1) {
      setAnimate(false);
    }
  }, [animateState, animate]);

  return (
    <div
      className={`snap-always translate-y-[-100px] snap-start h-full pt-[54px] pb-[100px] px-[20px] flex flex-col ${marginClassName}`}
    >
      <div className="text-[24px] leading-[29px] text-black font-bold px-[4px] pb-[24px]">불안 낮추기</div>

      {toolList && (
        <div className="w-full flex flex-col gap-[8px] h-full">
          {toolList
            .filter((element) => element.toolId !== 'tool_ca')
            .map((tool, index) => (
              <PressedEffect
                key={`tool${index}`}
                className="max-h-[96px]"
                element={
                  <div
                    key={`toolCard${index}`}
                    className="w-full h-full px-[24px] flex flex-col justify-center rounded-[20px] overflow-hidden relative "
                    style={{
                      backgroundColor: tool.isLock ? '#4A4D53' : '#255BC8',
                    }}
                    onClick={() => {
                      if (tool.isLock) {
                        setSessionState((state) => ({
                          ...state,
                          currentTool: tool.toolId,
                        }));
                        setTimeout(() => {
                          setModalState((state) => ({ ...state, isModalVisible: true }));
                        }, 0);
                      } else {
                        if (isPaymentRequired) {
                          goPayment();
                        } else {
                          if (tool.toolId === 'tool_bb') {
                            goToBreath();
                          } else if (tool.toolId === 'tool_aa') {
                            goToMeditation();
                          }
                        }
                      }
                    }}
                  >
                    <div className="flex flex-col gap-[4px]  justify-center z-[10]">
                      <SubTitle18
                        subtitle={anxyToolData.find((element) => element.toolId === tool.toolId).title}
                        color={anxyColorPalette.white}
                      />

                      <div>
                        {tool.isLock ? (
                          <svg
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.78622 8.87305H11.2148V5.86695C11.2148 4.0885 9.77571 2.64678 8.00051 2.64678C6.22531 2.64678 4.78622 4.0885 4.78622 5.86695V8.87305ZM2.64336 8.88496C2.17386 8.90444 1.85749 8.95577 1.59202 9.09103C1.21569 9.28278 0.909734 9.58874 0.717987 9.96507C0.5 10.3929 0.5 10.9529 0.5 12.073V16.2996C0.5 17.4197 0.5 17.9798 0.717987 18.4076C0.909734 18.7839 1.21569 19.0899 1.59202 19.2816C2.01984 19.4996 2.57989 19.4996 3.7 19.4996H12.3C13.4201 19.4996 13.9802 19.4996 14.408 19.2816C14.7843 19.0899 15.0903 18.7839 15.282 18.4076C15.5 17.9798 15.5 17.4197 15.5 16.2996V12.073C15.5 10.9529 15.5 10.3929 15.282 9.96507C15.0903 9.58874 14.7843 9.28278 14.408 9.09103C14.1427 8.95587 13.8266 8.90452 13.3577 8.885V5.86695C13.3577 2.90287 10.9592 0.5 8.00051 0.5C5.04184 0.5 2.64336 2.90287 2.64336 5.86695V8.88496Z"
                              fill={hexOpacity.create(anxyColorPalette.white, 0.6)}
                            />
                          </svg>
                        ) : (
                          <Caption15
                            caption={tool.completedAt ? `${getTimeDifference(tool.completedAt)}` : '시작하기'}
                            color={hexOpacity.create(anxyColorPalette.white, 0.6)}
                          />
                        )}
                      </div>
                    </div>
                    <img
                      src={anxyToolData.find((element) => element.toolId === tool.toolId).thumbnailImage}
                      alt={'empty'}
                      className="absolute right-0 top-0 w-[178px]"
                    />
                  </div>
                }
              />
            ))}
        </div>
      )}
    </div>
  );
}
