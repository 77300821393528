import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from '../utils/axiosAnxy';
import webviewToast from '../development/webviewToast';
import useNavigateWithParams from './useNavigateWithParams';
import { PATH } from '../routes/paths';
import { useSetRecoilState } from 'recoil';
import sessionAtom from '../recoil/anxy/session/atom';

export default function usePostToolGain({ sectionId }) {
  const [searchParams] = useSearchParams();
  const goToHome = useNavigateWithParams(`${PATH.anxy.anxyHomeA}`);
  const goToGainTool = useNavigateWithParams(`/gainTool/${sectionId.split('_').slice(0, -1).join('_')}`);

  const setSessionState = useSetRecoilState(sessionAtom);

  const postToolGain = useCallback(async () => {
    try {
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          `/anxy/chapter/section/complete`,
          JSON.stringify({
            sectionId: sectionId,
          }),
        )
        .then((response) => {
          goToGainTool();
          setSessionState((state) => ({ ...state, scrollToSession: sectionId }));
        });
    } catch (error) {
      goToHome();

      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return postToolGain;
}
