import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { motion } from 'framer-motion';
import useShowWebviewWithParams from '../../hooks/useShowWebviewWithParams';

const RandomCounselorCard = ({ randomCounselor, recommendList }) => {
  const [isRecommend, setIsRecommend] = useState(false);
  const [nowIndex, setNowIndex] = useState(0);
  const [isSmall, setIsSmall] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const timer = useRef(null);

  useEffect(() => {
    if (recommendList.counselorList && recommendList.counselorList.length !== 0) {
      setIsRecommend(true);
    }
  }, [recommendList]);

  const setTimer = useCallback(() => {
    timer.current = setInterval(() => {
      if (isFirst) {
        setIsFirst(false);
      }

      setNowIndex((prev) => {
        if (prev === 9) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 3500);
    return () => clearInterval(timer.current);
  }, []);

  useEffect(() => {
    setIsRecommend(false);
    clearInterval(timer.current);
    setTimer();
    setIsFirst(true);
    setNowIndex(0);
  }, [randomCounselor]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 600) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const goWizard = useShowWebviewWithParams('counselorSearchWizard/begin', '#2C4BEC');

  return (
    <div className={`px-[20px] ${isRecommend ? 'opacity-1' : 'opacity-1'} mb-[60px]`}>
      <>
        {!isSmall && <p className="text-[20px] leading-[26px] font-bold mt-[20px]">{`내게 맞는 상담사`}</p>}
        <div
          className={`${
            isSmall ? 'mb-[30px]' : 'mt-[13px] mb-[40px]'
          } mx-auto relative w-full max-w-[380px] max-h-[480px] h-full relative overflow-hidden rounded-[20px] flex align-center`}
        >
          <div
            className="max-w-[380px] rounded-[20px] absolute w-full h-full z-[10]"
            style={{ backgroundColor: '#00000080' }}
          />
          <div
            className="rounded-[20px] absolute w-full h-full z-[10]"
            style={{
              background: 'linear-gradient(359.28deg, #000000 0.39%, rgba(0, 0, 0, 0) 99.15%)',
              opacity: 0.5,
              boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.22)',
            }}
          />
          <div className="rounded-[20px] flex flex-col justify-end items-start absolute w-full h-full z-[50] px-[20px] pb-[20px]">
            <p className="text-[22px] leading-[28px] font-bold text-white mx-[10px]">내게 맞는 상담사 찾기</p>
            <p className="text-[15px] leading-[21px] text-[#E1E4EB] mx-[10px] mt-[15px] mb-[30px]">
              고민, 증상, 나이, 직업, 성향 등을 분석하여 최적의 상담사를 찾아드려요.
            </p>
            <PrimaryActionButton state="ACTIVE" text="시작하기" action={goWizard} medium />
          </div>
          <div className="w-full h-[490px] relative rounded-[20px]" style={{ backgroundColor: '#00000080' }}>
            {randomCounselor &&
              randomCounselor.map((counselor, index) => (
                <motion.img
                  className={`absolute top-[0px] left-0 ${
                    isFirst && nowIndex === index ? 'opacity-1' : 'opacity-0'
                  } w-full h-full object-cover`}
                  animate={{ opacity: nowIndex === index ? 1 : 0 }}
                  transition={{ duration: 1 }}
                  src={randomCounselor[index]}
                  alt={'empty'}
                />
              ))}
            {/* <motion.img
              ref={imageRef}
              className={`absolute top-[0px] left-0 ${isFirst ? 'opacity-1' : 'opacity-0'} w-full h-full object-cover`}
              //   key={nowIndex}
              animate={{ opacity: hide === 0 ? 1 : 0 }}
              transition={{ duration: 1 }}
              src={randomCounselor[nowIndex === 9 ? 0 : nowIndex + 1]}
              alt={'empty'}
            />
            <motion.img
              className="absolute top-0 left-0 opacity-0 w-full h-full object-cover"
              //   key={nowIndex}
              animate={{ opacity: hide === 1 ? 1 : 0 }}
              transition={{ duration: 1 }}
              src={randomCounselor[nowIndex]}
              //   src={counselorList.length !== 0 && counselorList[0].profileImageUrl}
              alt={'empty'}
            /> */}
          </div>
        </div>
      </>
    </div>
  );
};

export default RandomCounselorCard;
