import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AnxyActionButton } from '../button/ActionButton';
import { TextArea } from '../text/TextArea';
import { Title } from '../text/Title';
import { keyboardOpen } from '../../utils/webview';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import deviceAtom from '../../recoil/common/device';
import modalAtom from '../../recoil/common/modal/atom';
import stopInputFocusAtom from '../../recoil/common/stopInputFocus/atom';
import { useNavigate } from 'react-router-dom';

export default function TextAreaTemplate({
  bgColor,
  title,
  titleColor,
  textareaBgColor,
  caretColor,
  noBorder,
  placeholder,
  value,
  updateValue,
  action,
  noPadding,
}) {
  const navigate = useNavigate();
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);
  const [loaded, setLoaded] = useState(false);
  const [stopFocus, setStopFocus] = useState(false);
  const [forceBlur, setForceBlur] = useState(false);
  const [hideCaret, setHideCaret] = useState(true);

  const deviceState = useRecoilValue(deviceAtom);
  const setModalState = useSetRecoilState(modalAtom);
  const [stopInputFocusState, setStopInputFocusState] = useRecoilState(stopInputFocusAtom);

  useEffect(() => {
    setModalState({ isModalVisible: false });
    setLoaded(true);
    setTimeout(() => {
      setHideCaret(false);
    }, 300);
    keyboardOpen();

    window.visualViewport.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setViewportSize(window.visualViewport.height);
    if (window.visualViewport.height < viewportSize) {
      setKeyboardOpen(true);
    } else {
      setKeyboardOpen(false);
    }
  };

  useEffect(() => {
    if (stopFocus || stopInputFocusState) {
      setTimeout(() => {
        setForceBlur(true);
        if (stopFocus) {
          // setModalState({ isModalVisible: true, modalType: MODAL_LEAVE });
          action();
          setStopFocus(false);
        } else if (stopInputFocusState) {
          navigate(-1);
          setStopInputFocusState(false);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopFocus, stopInputFocusState]);

  useEffect(() => {
    if (value && value.length > 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [value]);

  function goNext() {
    setStopFocus(true);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [window.scrollY]);

  return (
    <motion.div
      animate={{
        height: `${viewportSize}px`,
        paddingBottom: !isKeyboardOpen && deviceState.hasHomeIndicator ? `34px` : `20px`,
      }}
      transition={{ ease: 'circOut', duration: loaded ? 0.15 : 0 }}
      className={`w-screen`}
      style={{ backgroundColor: bgColor }}
    >
      <div
        className="h-full px-[20px] flex flex-col"
        style={{
          paddingTop: deviceState.notchSize + 44,
        }}
      >
        <div className="flex-1 flex flex-col gap-[40px] pt-[7px]">
          <Title title={title} color={titleColor} />
          <div className="flex-1  pb-[40px] ">
            <div className="h-[calc(100%-50px)] mb-[40px] relative">
              {noPadding && (
                <div
                  className="w-full absolute top-0 h-[10px]"
                  style={{
                    background: `linear-gradient(to bottom, ${bgColor} 0%, rgba(255, 255, 255, 0) 100%)`,
                  }}
                />
              )}
              <TextArea
                textareaBgColor={textareaBgColor}
                noBorder={noBorder}
                placeholder={placeholder}
                value={value}
                updateValue={updateValue}
                forceBlur={forceBlur}
                hideCaret={hideCaret}
                caretColor={caretColor}
                noPadding={noPadding}
              />
              {noPadding && (
                <div
                  className="w-full absolute bottom-0 h-[10px]"
                  style={{
                    background: `linear-gradient(to top, ${bgColor} 0%, rgba(255, 255, 255, 0) 100%)`,
                  }}
                />
              )}
            </div>
            <AnxyActionButton state={buttonState} text={'다음'} action={goNext} />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
