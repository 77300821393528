/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { PATH } from '../../routes/paths';
import { BottomAction } from '../../stories/page/BottomAction';
import AnimateTitleBottomAction from '../../components/inside/AnimateTitleBottomAction';
import exampleList from '../../data/inside/exampleList';
import propsNameList from '../../data/inside/propsNameList';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function ExampleAndQA(props) {
  const { id } = useParams();

  const setMixPanel = useMixpanelWeb({
    eventName: '2_test',
  });

  useEffect(() => {
    if (parseInt(id) === 1) {
      setMixPanel();
    }
  }, [id]);

  const goNext = useNavigateWithParams(`${PATH.marketing.insideBlackDefault}/${parseInt(id) + 1}`);
  const goResult = useNavigateWithParams(`${PATH.marketing.insideBlackDefault}/result`);
  const optionList = [
    { text: '예', value: true },
    { text: '아니요', value: false },
  ];

  const index = Math.floor(parseInt(id) / 2);
  const content = exampleList[index];

  return parseInt(id) % 2 === 1 ? (
    <div className="h-screen w-full overflow-hidden">
      <div
        className="absolute top-0 overflow-hidden"
        style={{
          height: 'calc(var(--vh,1vh) * 100)',
          minHeight: 'calc(var(--vh.1vh) * 100)',
        }}
      >
        <img src={content.image} alt="empty" className="w-full object-cover" />
        <div
          className="bg-[#FF6F6F] opacity-[0.2] w-full absolute top-0 h-screen"
          style={{ mixBlendMode: 'soft-light' }}
        />
      </div>
      <BottomAction
        content={
          <div className="w-screen h-full flex flex-col justify-end items-center text-center z-[50]">
            <div className="absolute top-[50%] translate-y-[calc(-50%-68px)]">{content.number}</div>
            <p className="text-white font-semibold text-[18px] leading-[21.6px] mb-[40px] z-[50]">{content.title}</p>
          </div>
        }
        buttonText={'다음'}
        buttonState={'ACTIVE'}
        bgColor="#000000"
        action={() => {
          goNext();
        }}
        insideBlack
        noHeader
        noGradient
      />
    </div>
  ) : (
    <AnimateTitleBottomAction
      optionList={optionList}
      propsName={propsNameList[index - 1]}
      noHeader
      action={() => {
        if (id === '10') {
          goResult();
        } else {
          goNext();
        }
      }}
    />
  );
}
