import React, { useRef, useEffect, useState } from 'react';
import ContainerByDeviceAnxy from 'components/elements/ContainerByDeviceAnxy';
import { AnxyLoadingLottie } from 'stories/animation/AnxyLoadingLottie';
import { updateNotch, keepScreenEvent, impactFeedback } from 'utils/webview';
import headerAtom, { BUTTON_MORE } from 'recoil/common/header/atom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// import {
//   useFetchAnxy,
//   useMixpanelEventAnxy,
//   useSessionStorage,
//   useLoadImage,
//   useDidMountEffect,
//   useNavigateWithParams,
// } from 'hooks';
import useFetchAnxy from 'hooks/useFetchAnxy';
import useMixpanelEventAnxy from 'hooks/useMixpanelEventAnxy';
import useLoadImage from 'hooks/useLoadImage';
import useSessionStorage from 'hooks/useSessionStorage';
import useDidMountEffect from 'hooks/useDidMountEffect';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import sessionAtom from 'recoil/anxy/session/atom';
import modalAtom from 'recoil/common/modal/atom';
import { marginWithHomeIndicator } from 'recoil/common/device';
import WorryWithNote from './WorryWithNote';
import SessionCardList from './SessionCardList';

import userAtom from 'recoil/anxy/user/atom';
import homeAtom from 'recoil/anxy/home/atom';

import { anxyToolData } from 'data/anxy/home/anxyToolData';
import statusAtom from 'recoil/anxy/home/statusAtom';
import AnxyModalWithTool from 'components/anxy/modal/AnxyModalWithTool';
import deviceAtom from 'recoil/common/device';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import { AnxyIcon, WorryIcon } from 'image/anxy/icon/icon';
import WorryNoteStatistics from '../worryNote/WorryNoteStatistics';
import hexOpacity from 'hex-opacity';
import Tool from './Tool';

import { Caption15 } from 'stories/text/AnxyText';

import { motion } from 'framer-motion';

export default function AnxyHomeA() {
  // 데이터 로드 여부
  const worriScrollRef = useRef();
  const [modalState, setModalState] = useRecoilState(modalAtom);
  const setHeaderState = useSetRecoilState(headerAtom);
  const [userState, setUserState] = useRecoilState(userAtom);
  const [sessionState, setSessionState] = useRecoilState(sessionAtom);
  const [statusState, setStatusState] = useRecoilState(statusAtom);
  const [homeType, setHomeType] = useRecoilState(homeAtom);
  const { notchSize } = useRecoilValue(deviceAtom);

  const [score, setScore] = useState(userState.anxietyScore || 0);

  const [chapterList, setChapterList] = useState();
  const [sessionList, setSessionList] = useSessionStorage('sessionList', undefined);
  const [toolList, setToolList] = useSessionStorage('toolList', null);

  const [isGoAnalysis, setGoAnalysis] = useState(false);
  const [isPageAnimation, setPageAnimation] = useState(false);
  const [scrollToWori, setScrollToWori] = useState();
  const [scrollToAnxy, setScrollToAnxy] = useState();

  const [totalFreeSession, setTotalFreeSession] = useState(0);
  const [completedFreeSession, setCompletedFreeSession] = useState(0);

  useEffect(() => {
    if (isGoAnalysis) {
      setScrollToWori(3 * (window.innerHeight - (100 + notchSize)));
    }
  }, [isGoAnalysis]);

  useEffect(() => {
    if (isPageAnimation) {
      setTimeout(() => {
        setPageAnimation(false);
      }, 200);
    }
  }, [isPageAnimation]);

  const paymentSessionId = 'session_kc';
  const goPayment = useNavigateWithParams('/payment');

  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const loadImage = useLoadImage(anxyToolData.map((item) => item.toolImage));

  const setMixPanelWorri = useMixpanelEventAnxy({
    eventName: '홈',
    params: {
      타입: 'Wori',
    },
  });

  const setMixPanelAnxy = useMixpanelEventAnxy({
    eventName: '홈',
    params: {
      타입: 'Anxy',
    },
  });
  const pageTitleList = [
    '지금 나의 불안',
    '불안 낮추기',
    '불안 원인',
    '신체 반응',
    '생각 반응',
    '행동 반응',
    '시간대별 불안 점수',
    '요일별 불안 점수',
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const setMixpanelScroll = useMixpanelEventAnxy({
    eventName: 'Wori 탭 스크롤',
    params: {
      위치: pageTitleList[currentPage],
    },
  });

  useDidMountEffect(() => {
    setMixpanelScroll();
  }, [currentPage]);

  useEffect(() => {
    // header, layout settings
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_MORE,
      headerTitle: '',
      headerColor: anxyColorPalette.black,
      progressBar: undefined,
    }));
    updateNotch({ isFullScreen: true });
    initializeState();
    keepScreenEvent({ isKeepScreenOn: false });
    loadImage();

    return () => {
      setUserState((state) => ({
        ...state,
        previousScore: undefined,
      }));
    };
  }, []);

  useEffect(() => {
    getHomeDetail();
    if (homeType === 'worri') {
      getToolList();
      getWorryNoteHistory();
      getWorryNoteStatistics();
      setMixPanelWorri();
      if (worriScrollRef.current) {
        setTimeout(() => {
          worriScrollRef.current.scrollTo({
            top: scrollToWori,
            behavior: 'auto',
          });
        }, 100);
      }
    } else {
      setMixPanelAnxy();
    }
  }, [homeType]);

  useEffect(() => {
    if (worriScrollRef.current) {
      worriScrollRef.current.scrollTo({
        top: scrollToWori,
        behavior: 'smooth',
      });
    }
  }, [scrollToWori, homeType]);

  useDidMountEffect(() => {
    impactFeedback('selection');
  }, [homeType]);

  const [homeDetail, getHomeDetail] = useFetchAnxy({
    url: `/anxy/chapter/detail`,
    bg: '#FFFFFF',
  });

  const [worryNoteHistoryData, getWorryNoteHistory] = useFetchAnxy({
    url: '/anxy/v2/worry-note/list',
  });

  const [toolListData, getToolList] = useFetchAnxy({
    url: `/anxy/tool/list`,
    bg: '#FFFFFF',
  });

  const [worryNoteStatisticsData, getWorryNoteStatistics] = useFetchAnxy({
    url: '/anxy/v2/worry-note/statistics/detail',
  });

  useEffect(() => {
    if (toolListData) {
      const changedList = toolListData.result.slice();

      if (toolList) {
        toolList.forEach((beforeItem) => {
          changedList.forEach((afterItem) => {
            if (beforeItem.id === afterItem.id) {
              // 방금 세션을 마친 경우 check 애니메이션 위해 isFirstComplete 설정
              if (beforeItem.completedAt === false && afterItem.completedAt) {
                afterItem.isFirstComplete = true;
                // Object.defineProperty(afterItem, 'isFirstComplete', {
                //   writable: true,
                //   configurable: true,
                //   enumerable: true,
                //   value: true,
                // });
              }
              if (beforeItem.isLock && afterItem.isLock === false) {
                afterItem.isFirstUnlocked = true;
                // Object.defineProperty(afterItem, 'isFirstUnlocked', {
                //   writable: true,
                //   configurable: true,
                //   enumerable: true,
                //   value: true,
                // });
              }
            }
          });
        });
      }
      setToolList(changedList);
      setSessionState((state) => ({ ...state, toolList: changedList }));
    }
  }, [toolListData]);

  const initializeState = () => {
    if (modalState.isModalVisible) {
      setModalState((state) => ({ ...state, isModalVisible: false }));
    }
    setSessionState((state) => ({ ...state, currentSession: undefined, currentSessionImageList: undefined }));
  };

  useEffect(() => {
    if (worryNoteHistoryData) {
      let sortedworryNoteHistoryData = worryNoteHistoryData.result.slice().sort(function (a, b) {
        return new Date(b.submittedAt) - new Date(a.submittedAt);
      });
      sortedworryNoteHistoryData = sortedworryNoteHistoryData.filter(
        (element, index) =>
          (element.reason !== '심호흡하기' && element.reason !== '심호흡하기 전') ||
          (element.reason === '심호흡하기' &&
            sortedworryNoteHistoryData[index + 1] &&
            sortedworryNoteHistoryData[index + 1].reason === '심호흡하기 전') ||
          (element.reason === '심호흡하기 전' &&
            sortedworryNoteHistoryData[index - 1] &&
            sortedworryNoteHistoryData[index - 1].reason === '심호흡하기'),
      );
      console.log(sortedworryNoteHistoryData);
      setUserState((state) => ({
        ...state,
        worryNoteHistory: sortedworryNoteHistoryData,
      }));
    }
  }, [worryNoteHistoryData]);

  useEffect(() => {
    if (homeDetail) {
      const result = homeDetail.result;

      setChapterList(result.chapterList);

      const flattenedList = result.chapterList.map((element) => element.sectionList).flat();

      // result는 session API로부터 받은 data
      const changedList = [...flattenedList];
      if (sessionList) {
        sessionList.forEach((beforeItem) => {
          changedList.forEach((afterItem) => {
            if (beforeItem.sectionId === afterItem.sectionId) {
              if (beforeItem.isLock === true && afterItem.isLock === false) {
                afterItem.isFirstUnlock = true;
              }
              if (!beforeItem.completedAt && afterItem.completedAt) {
                afterItem.isFirstComplete = true;
              }
            }
          });
        });
      }
      setSessionList(changedList);
      setTotalFreeSession(
        changedList
          .slice(
            0,
            changedList.findIndex((element) => element.sectionId === paymentSessionId),
          )
          .filter((element) => element.type === 'session').length,
      );
      setCompletedFreeSession(
        changedList
          .slice(
            0,
            changedList.findIndex((element) => element.sectionId === paymentSessionId),
          )
          .filter((element) => element.type === 'session' && element.completedAt).length,
      );

      setStatusState((state) => ({
        isInReview: homeDetail.result.isInReview,
        isPaymentRequired: homeDetail.result.isPaymentRequired,
        isTestClosed: homeDetail.result.isTestClosed,
      }));

      setScore(result.score || 0);
      setUserState((state) => ({ ...state, anxietyScore: result.score || 0 }));
    }
  }, [homeDetail]);

  const SwitchPageButton = () => {
    return (
      <div
        className={`fixed w-full bottom-0 flex justify-center ${marginClassName}`}
        style={{ background: `linear-gradient(to top, #F3F5F8, ${hexOpacity.create('#F3F5F8', 0)}` }}
      >
        <div className="w-fit">
          <div
            className={`bg-white flex justify-between items-center gap-[16px] mx-auto h-[72px] rounded-[20px] p-[8px]`}
            style={{
              boxShadow: '0px 12px 32px -4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div
              className="w-[56px] h-[56px] rounded-[16px] flex items-center justify-center"
              style={{ backgroundColor: hexOpacity.create(anxyColorPalette.orange, homeType === 'worri' ? 0.1 : 0) }}
              onClick={() => setHomeType('worri')}
            >
              <WorryIcon selected={homeType === 'worri'} />
            </div>
            <div
              className="w-[56px] h-[56px] rounded-[16px] flex items-center justify-center"
              style={{ backgroundColor: hexOpacity.create(anxyColorPalette.orange, homeType === 'anxy' ? 0.1 : 0) }}
              onClick={() => setHomeType('anxy')}
            >
              <AnxyIcon selected={homeType === 'anxy'} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleScroll_worri = (scrollTop) => {
    if (scrollTop % (window.innerHeight - (100 + notchSize)) === 0) {
      // console.log('햅틱', scrollTop);
      impactFeedback('selection');
      const currentIdx = scrollTop / (window.innerHeight - (100 + notchSize));
      setCurrentPage(currentIdx);
      setScrollToWori(scrollTop);
    }
  };

  return chapterList && sessionList ? (
    <ContainerByDeviceAnxy
      noHeader
      scrollto={homeType === 'anxy' && scrollToAnxy}
      css={'flex flex-col  '}
      bgColor="#F3F5F8"
      scrollBehaviour="auto"
    >
      <AnxyModalWithTool />

      {homeType === 'worri' ? (
        <motion.div
          animate={{ y: isPageAnimation ? -50 : 0 }}
          transition={{
            type: 'spring',
            // duration: 0.8,
            // bounce: 0.6,
            damping: 5,
            mass: 0.75,
            stiffness: 400,
          }}
          className={`overflow-scroll scrollbar-hide snap-y snap-mandatory `}
          ref={worriScrollRef}
          style={{ height: window.innerHeight - notchSize }}
          onScroll={(e) => {
            handleScroll_worri(e.target.scrollTop);
          }}
        >
          <WorryWithNote
            previousScore={userState.previousScore}
            score={score}
            toolList={toolList}
            setGoAnalysis={setGoAnalysis}
            setPageAnimation={setPageAnimation}
            worryNoteStatisticsData={worryNoteStatisticsData}
          />

          <Tool toolList={toolList} />

          <WorryNoteStatistics worryNoteStatisticsData={worryNoteStatisticsData} />
        </motion.div>
      ) : (
        <div className={`pt-[44px]`}>
          {/* {statusState.isPaymentRequired && (
            <div
              className="w-full py-[12px] px-[20px] flex justify-between mb-[24px]"
              style={{ backgroundColor: anxyColorPalette.black }}
              onClick={() => {
                setSessionState((state) => ({ ...state, scrollToSession: undefined }));
                goPayment();
              }}
            >
              <div className="flex items-center">
                {completedFreeSession < totalFreeSession ? (
                  <div>
                    <Caption15
                      caption={`무료 세션 ${totalFreeSession}개 중 `}
                      color={anxyColorPalette.white}
                      className=" inline"
                    />
                    <Caption15
                      caption={`${completedFreeSession}`}
                      color={anxyColorPalette.orange}
                      className=" inline"
                    />
                    <Caption15 caption={`개를 들었어요`} color={anxyColorPalette.white} className="inline" />
                  </div>
                ) : (
                  <Caption15
                    caption={`무료 세션 ${totalFreeSession}개를 모두 들었어요 `}
                    color={anxyColorPalette.white}
                    className=" inline"
                  />
                )}
              </div>
              <div>
                {completedFreeSession < totalFreeSession ? (
                  <Caption15 caption={`더 알아보기`} color={hexOpacity.create(anxyColorPalette.white, 0.6)} />
                ) : (
                  <Caption15 caption={`결제하기`} color={anxyColorPalette.orange} />
                )}
              </div>
            </div>
          )} */}
          <SessionCardList
            chapterList={chapterList}
            sessionList={sessionList}
            setScrollTo={setScrollToAnxy}
            isPaymentRequired={statusState.isPaymentRequired}
            isTestClosed={statusState.isTestClosed}
            isInReview={statusState.isInReview}
          />
        </div>
      )}
      <SwitchPageButton />
    </ContainerByDeviceAnxy>
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <AnxyLoadingLottie />
    </div>
  );
}
