const answerIdList = [
  {
    // title: '대인관계',
    textList: [
      { id: 'h_j', text: '내 의견을 명확하게 표현하고 싶어요.' },
      { id: 'h_i', text: '나의 감정을 잘 컨트롤 하고 싶어요.' },
      { id: 'l_b', text: '의사 결정을 현명하게 하고 싶어요.' },
      { id: 'w_s', text: '어려운 상황을 회피하지 않고 도전하고 싶어요.' },
      { id: 'f_a', text: '제대로 휴식하는 방법을 알고 싶어요.' },
      // { id: 'h_t', text: '불편한 감정을 건강하게 표현하고 싶어요.' },
      // { id: 'h_s', text: '상대방의 말에 개의치 않는 태도를 갖고 싶어요.' },
      // { id: 'h_q', text: '인간관계의 기술을 배우고 싶어요.' },
      // { id: 'h_a', text: '친절한 사람이 되고 싶어요.' },
    ],
  },
  // {
  //   title: '리더십',
  //   textList: [
  //     // { id: 'l_b', text: '의사 결정을 현명하게 하고 싶어요.' },
  //     // { id: 'l_s', text: '갈등 상황을 관리하고 해결하는 법을 알고 싶어요.' },
  //     // { id: 'l_d', text: '업무 부담감을 자신감으로 바꾸고 싶어요.' },
  //     // { id: 'l_k', text: '팀원들을 잘 이끌고 싶어요.' },
  //   ],
  // },
  // {
  //   title: '업무',
  //   textList: [
  //     { id: 'w_s', text: '어려운 상황을 회피하지 않고 도전하고 싶어요.' },
  //     { id: 'w_b', text: '일을 시작하고 마무리 짓는 것을 잘하고 싶어요.' },
  //     // { id: 'w_l', text: '어떤 일을 꾸준하게 하고 싶어요.' },
  //     // { id: 'w_p', text: '업무 실수를 줄이고 싶어요.' },
  //     { id: 'w_k', text: '성공적으로 이직/독립 하고 싶어요.' },
  //     // { id: 'w_i', text: '적극적인 사람이 되고 싶어요.' },
  //   ],
  // },

  // {
  //   title: '가치관/일상',
  //   textList: [
  //     { id: 'v_s', text: '삶의 목적을 찾고 싶어요.' },
  //     { id: 'v_n', text: '내가 원하는 게 무엇인지 알고 싶어요.' },
  //     { id: 'f_a', text: '제대로 휴식하는 방법을 알고 싶어요.' },
  //   ],
  // },

  // {
  //   title: '가치관',
  //   textList: [
  //     { id: 'v_s', text: '삶의 목적을 찾고 싶어요.' },
  //     { id: 'v_d', text: '지금 하는 일에 확신을 갖고 싶어요.' },
  //     { id: 'v_n', text: '내가 원하는 게 무엇인지 알고 싶어요.' },
  //   ],
  // },
  // {
  //   title: '일상',
  //   textList: [
  //     { id: 'f_a', text: '제대로 휴식하는 방법을 알고 싶어요.' },
  //     { id: 'f_k', text: '지금보다 에너지 넘치게 살고 싶어요.' },
  //     { id: 'f_d', text: '긍정 마인드를 장착하고 싶어요.' },
  //     { id: 'f_b', text: '부모 등 가정 내 역할도 잘 해내고 싶어요.' },
  //   ],
  // },
];

export default answerIdList;
