import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from '../../../utils/axios2';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { CheckLottie } from '../../../stories/animation/CheckLottie';
import webviewToast from '../../../development/webviewToast';
import usePostWorkbookDone from '../../../hooks/usePostWorkbookDone';
import { useContext } from 'react';
import { CoachingContext } from '../../../coachingContext';

export default function FeedbackDone(props) {
  const [searchParams] = useSearchParams();
  const context = useContext(CoachingContext);

  const postWorkbookDone = usePostWorkbookDone();

  const postFeedbackSubmit = useCallback(async () => {
    try {
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(`/sleep/objective/feedback/submit`, context.workbookAnswer)
        .then((response) => {
          webviewToast('feedback success');
          setTimeout(() => {
            postWorkbookDone();
          }, 500);
        });
    } catch (error) {
      webviewToast('feedback error' + error);
      postWorkbookDone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    postFeedbackSubmit();
  }, []);

  return (
    <div className="bg-[#EFF2FF] w-full h-screen bg-[#EFF2FF] flex flex-col items-center justify-center text-center ">
      <div className="">
        <CheckLottie containerSize={'77px'} gradient />
      </div>
      <p className=" font-bold text-[24px] leading-[30px] text-[#26282C]  mt-[30px] ">{'평가를 마쳤어요'}</p>
    </div>
  );
}
