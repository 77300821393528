import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

export default function FadeTransition(props) {
  const { children } = props;
  const location = useLocation();

  return (
    <div className="bg-[#26282C]">
      {/* fadeInOut */}
      {/* <AnimatePresence exitBeforeEnter>
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="h-full "
        >
          {children}
        </motion.div>
      </AnimatePresence> */}

      <AnimatePresence initial={false}>
        <motion.div
          key={location.pathname}
          initial={{ x: window.innerWidth, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -window.innerWidth, opacity: 0 }}
          transition={{ duration: 1.5 }}
          className="h-full "
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
