import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CoachingContext } from '../../../coachingContext';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import card1 from '../../../image/course5/card1.png';
import card2 from '../../../image/course5/card2.png';
import card3 from '../../../image/course5/card3.png';
import cardImage1 from '../../../image/course5/cardImage1.png';
import cardImage2 from '../../../image/course5/cardImage2.png';
import cardImage3 from '../../../image/course5/cardImage3.png';
import usePostWorkbookDone from '../../../hooks/usePostWorkbookDone';
import webviewToast from '../../../development/webviewToast';
import { BUILD_TARGET } from '../../../config';
import axios from '../../../utils/axios2';
import { showModal } from '../../../utils/webview';

export default function CardSharePage() {
  const [selectedCardId, setSelectedCardId] = useState(1);
  const [searchParams] = useSearchParams();

  const context = useContext(CoachingContext);

  const cardInfo = [
    { id: 1, icon: card1, image: cardImage1, bg: '#101A4F' },
    { id: 2, icon: card2, image: cardImage2, bg: '#40527A' },
    { id: 3, icon: card3, image: cardImage3, bg: '#142647' },
  ];

  const isDev = BUILD_TARGET === 'dev';

  const postWorkbookDone = usePostWorkbookDone();

  const postObjective = async () => {
    try {
      console.log(context.workbookAnswer);
      if (context.workbookAnswer.length !== 0) {
        await axios(3, searchParams.get('at'), searchParams.get('app_id'))
          .post(
            `/sleep/objective/submit`,
            JSON.stringify({
              objective: context.workbookAnswer[0],
            }),
          )
          .then((response) => {
            webviewToast(`success`);
            postWorkbookDone();
          });
      }
    } catch (error) {
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const shareUrl = `${
    isDev ? `https://dev-m.inside.im/shareCard/${selectedCardId}` : `https://m.inside.im/shareCard/${selectedCardId}`
  }`;

  const goShare = () => {
    webviewToast('show share');
    showModal({ url: shareUrl, title: '인사이드 수면 코스 공유하기', type: 'share' });
  };
  return (
    <div className={`w-full  h-[calc(100vh-17px)] overflow-hidden px-[20px]`}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="">
          <div
            className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C]  mt-[50px] mb-[15px]"
            style={{ wordBreak: 'keep-all' }}
          >
            긍정적인 에너지를 얻으세요
          </div>
          <p className="text-[17px] leading-[28px] text-[#3A3C40] font-light">
            가볍게 공유할 수 있는 카드를 준비했어요. 주변 사람들의 따뜻한 지지와 함께 하세요.
          </p>
          <div className="flex flex-col items-center mt-[30px]">
            <div className="w-full grid grid-cols-3" style={{ gap: '5.5px' }}>
              {cardInfo.map((item, index) => (
                <div
                  className={`w-full relative overflow-hidden h-[49px]`}
                  style={{
                    borderRadius: '8px',
                    border: index === selectedCardId - 1 ? '2px solid transparent' : `2px solid ${item.bg}`,
                    background: index !== selectedCardId - 1 && item.bg,
                    backgroundImage:
                      index === selectedCardId - 1 && 'linear-gradient(272.73deg, #5513F0 0%, #2C4BEC 100%)',
                  }}
                  onClick={() => setSelectedCardId(index + 1)}
                >
                  <div className="w-full h-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
                    <img className="w-full h-full object-cover" src={item.icon} alt="empty" />
                  </div>
                </div>
              ))}
            </div>
            <div className="my-[20px] px-[10px]">
              <img className="h-[420px]" src={cardInfo.find((item) => item.id === selectedCardId).image} alt="empty" />
            </div>

            <div
              className="w-[130px] bg-[#2C4BEC] rounded-[10px] h-[38px] flex justify-center items-center px-[12px] py-[10px]"
              onClick={goShare}
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.0004 3L7.28613 10.7143"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.42857 3H5C3.89543 3 3 3.89543 3 5V13C3 14.1046 3.89543 15 5 15H13C14.1046 15 15 14.1046 15 13V11.5714"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.7139 3H14.9996V7.28571"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="ml-[6px] text-white" style={{ whiteSpace: 'nowrap' }}>
                카드 공유하기
              </p>
            </div>
          </div>
        </div>
        <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px] mt-[40px]`}>
          <SecondaryActionButton state="ACTIVE" text={'마치기'} action={postObjective} />
        </div>
      </div>
    </div>
  );
}
