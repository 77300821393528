export const Image = ({ image, bg, height, my, padding }) => {
  console.log(bg);
  return (
    <div
      className={`w-full relative overflow-hidden rounded-2xl ${my ? 'my-[30px]' : 'mb-[30px]'} flex align-center`}
      style={{ backgroundColor: bg ? bg : '#F3F5F8', height: height ? height : '190px' }}
    >
      <div
        className={`w-[335px] h-[190px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ${
          padding ? padding : ''
        }`}
        style={{ height: height ? height : '190px' }}
      >
        <img className="w-full h-full object-fit" src={image} alt="empty" />
      </div>
    </div>
  );
};
