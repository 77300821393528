import React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

export function ProgressingBarThin(props) {
  const { completed, color, height, animateOnRender, duration } = props;
  return (
    <ProgressBar
      completed={completed}
      maxCompleted={1}
      bgColor={color}
      baseBgColor={'#E1E4EB'}
      height={height}
      borderRadius={'60px'}
      isLabelVisible={false}
      animateOnRender={animateOnRender}
      initCompletedOnAnimation={0}
      transitionDuration={duration}
    />
  );
}
