export const PATH = {
  home: {
    feed: '/feed',
    tutorial: '/tutorial',
    content: '/content/:id',
    counselor: '/counselors',
    companyAuth: '/companyAuth',
    startOnboarding: '/startOnboarding',
    loading: '/loading',
    assignCounselor: '/assignCounselor/:stage/:id',
    onboardingPreview: '/onboardingPreview/:id',
    onboarding1: '/onboarding1/:id',
    onboarding2: '/onboarding2/:exam_id/:qc_id',
    endtest: '/endtest',
    result: '/result',
    goal: '/goal',
    courseRecommendation: '/courseRecommendation',
    coachMatching: '/coachMatching/:id',
    finishOnboarding: '/finishOnboarding',
    marketing: '/marketing',
    marketingReason: '/marketingReason',
    coaching: '/coaching',
    workbook: '/workbook/:id/:page',
    checkIn: '/checkin',
    couponInfo: '/couponInfo',
    rewardInfo: '/rewardInfo',
    myCoupon: '/myCoupon',
    activity: '/course/:id',
    toolOpen: '/toolOpen/:id',
    course: '/course',
    courseDone: '/courseDone',
    recommendedCourse: '/recommendedCourse',
    feedback: '/feedback/:id',
    error: '/error',

    comingSoon: '/comingSoon',
    userLeave: '/userLeave/:id',
    selfCheckHome: '/selfCheck',
    selfCheck: '/selfCheck/:selfCheckId/:state',
    breath: '/breathTool',
    counselorSearchWizard: '/counselorSearchWizard/:state',
    counselorAssign: '/counselorAssign',
    counseling: '/counseling2',
    test: '/test/:id',
    sleepingReport: '/sleepingReport',
    sleepingReportxyz: '/sleepingReportxyz',
    shareCard: '/shareCard/:id',
    audio: '/audio',
    audioScript: '/audioScript',
    subtraction: '/subtraction',
    coachingLeave: '/coachingLeave/:id',
    timePickerTest: '/timePickerTest',

    bettermeType: '/betterme/:id',
    bettermeScore: '/betterme-score/:state/:id',
    home: '/home',
    userProfileList: '/userProfileList',
    userInfo: '/userInfo',
    counselorHome: '/counselorHome',
    userProfile: '/userProfile',
    springcampfamily: '/springcampfamily',
    stress: '/stress',
    mindScanLoadSid: '/mindScan',
    mindScanHome: '/mindScanHome',
    mindScan: '/mindScan/:selfCheckId/:state',
    firstMindScanStart: '/firstMindScan',
    firstMindScan: '/firstMindScan/:id/:state',
    somnyTest: '/somnyTest2/:id',
    somnyTest2: '/somnyTest/:id',
    somnyLanding: '/somnyLanding',
    somnyPreview: '/somnyPreview/:id',
    somnyComingSoon: '/somnyComingSoon',
    youtubeExample: '/youtubeExample',
  },
  marketing: {
    sleepSurvey: '/sleepingSurvey/:id',
    sleepChallengers: '/sleepChallengers/:id',
    sleepChallengersReport: '/sleepChallengersReport',
    insideBlack: '/inside-black/:id',
    insideBlackPreview: '/inside-black/preview/:id',
    insideBlackDefault: '/inside-black',
    insideWhite: '/inside-white/:id',
    insideWhitePreview: '/inside-white/preview/:id',
    insideWhiteDefault: '/inside-white',
    habit: '/habit',
    habitShare: '/habit-share',
    submitInfo: '/submitInfo/:id',
  },
  anxy: {
    login: '/login',
    afterLogin: '/',
    anxyOnboarding: '/anxyOnboarding/:id',
    anxyTest: '/anxyTest/:id',
    anxyLoading: '/anxyLoading',
    anxyRoadMap: '/anxyRoadMap',
    walkthoughA: '/walkthroughA/:id',
    walkthoughB: '/walkthroughB/:id',
    level: '/level',
    heart: '/heart',
    guide: '/guide/:page/:id',
    bridge: '/bridge/:page',
    managementTool: '/managementTool',
    anxySession: '/anxy/:sessionId/:page',
    gainTool: '/gainTool/:id',

    anxyWebPreview: '/anxyWebPreview/:id',
    anxyWebTest: '/anxyWebTest/:id',
    anxyWebAboutCourse: '/anxyWebAboutCourse/:id',
    anxyWebCoreExp: '/anxyWebCoreExp/:id',

    myPageHome: '/myPage',
    myPage: '/myPage/:id',
    userLeaveAnxy: '/userLeaveAnxy',

    payment: '/payment',
    paymentSuccess: '/payment-success',
    paymentConfirmFail: '/payment-confirm-fail',
    anxyError: '/anxyError',

    anxyHome: '/anxyHome',
    worryNote: '/worryNote',
    worryNoteHistory: '/worryNoteHistory',
    worryNoteHistoryDetail: '/worryNoteHistory/:worryNoteId',
    introAfterTest: '/session/0/5',
    intro: '/intro/:id',
    example: '/example/:id',
    trial: '/trial/:id/:page',
    trialExample: '/trialExample/:id/:page',
    sessionCardList: '/sessionCardList',
    meditationTool: '/meditationTool/:id',
    comingSoon: '/anxyComingSoon',
    sessionDone: '/sessionDone',
    notReady: '/notReady',
    notification: '/anxyNotification/:id',
    breathTool: '/breath/:id',
  },

  betterMe: {
    login: '/betterMeLogin',
    afterLogin: '/',
    bettyInReview: '/bettyInReview',
    betterMeTest: '/betterMeTest/:id',
    betterMeHome: '/betterMeHome',
    betterMeSession: '/betterMeSession/:page',

    myPageHome: '/betterMeMyPage',
    myPage: '/betterMeMyPage/:id',
    userLeave: '/betterMeUserLeave',

    payment: '/payment',
    paymentSuccess: '/payment-success',
    anxyError: '/anxyError',

    worryNote: '/worryNote',
    introAfterTest: '/session/0/5',
    intro: '/intro/:id',
  },

  loomy: {
    preview: '/loomyPreview/:id',
    test: 'loomyTest/:id',
    walkthrough: '/loomyWalkthrough/:id',
    comingSoon: '/loomyComingSoon',
    login: '/loomyLogin',
    welcome: '/loomyWelcome',

    myPageHome: '/loomyMyPage',
    myPage: '/loomyMyPage/:id',
    userLeave: '/loomyMeUserLeave',
    home: '/loomyHome',
    session: '/loomySession/:id',

    webPreview: '/loomyWebPreview/:id',
    webWalkthrough: '/loomyWebWalkthrough/:id',

    webPreview2: '/loomyWebPreview2/:id',
    webWalkthrough2: '/loomyWebWalkthrough2/:id',
  },

  somny: {
    login: '/somnyLogin',
    begin: '/somnyBegin',
    inReview: '/somnyInReview',
    onboarding: '/somnyOnboarding/:id',
    onboardingInReview: '/somnyOnboardingInReview/:id',
    home: '/somnyHome',
    homeInReview: '/somnyHomeInReview',
    sessionInReview: '/somnySessionInReview/:page',
    session: '/somny/:knitId/:page',
    sessionDone: '/somnySessionDone',
    tale: '/somnyTale/:taleId/:id',
    sleepRecord: '/somnyRecord/:id',
    sleepRecordDone: '/somnyRecordDone',
    sleepReport: '/somnyReport',
    myPageHome: '/somnyMyPage',
    myPage: '/somnyMyPage/:id',
    myPageHomeInReview: '/somnyMyPageInReview',
    userLeave: '/somnyUserLeave',
    payment: '/somnyPayment',
    paymentSuccess: '/somnyPayment-success',
    paymentConfirmFail: '/somnyPayment-confirm-fail',
    taleHome: '/taleHome',
  },
};
