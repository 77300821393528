/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import EndProcessing from '../../components/selfCheck/EndProcessing';
import { AuthContext } from '../../context';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useFetch from '../../hooks/useFetch';
import selfCheckData from '../../data/selfCheckData';

export default function SelfCheckAnalysis() {
  let { selfCheckId } = useParams();
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === parseInt(selfCheckId));
  const context = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const first = searchParams.get('first') === 'true';

  const [done, setDone] = useState(false);
  const [selfCheckSubmitResult, selfCheckSubmit] = useFetch({
    url: `/mindscan/submit`,
    requestBody: JSON.stringify(context.selfcheckAnswer[context.selfcheckAnswer.length - 1]),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (first) {
      document.title = `마음 스캔 로딩`;
    } else {
      document.title = `${selfCheckInfo.selfCheckName} 검사 로딩`;
    }

    context.setShowProgressingbar(false);
    context.setMaxCompleted(0);
    context.setCompleted(0);
    context.setSelfCheckScrollPosition(0);
    context.setSelfCheckHomeScrollPosition(0);
    selfCheckSubmit();
  }, []);

  useEffect(() => {
    if (selfCheckSubmitResult) {
      // getSelfCheckHomeDetail();
      console.log(selfCheckSubmitResult.result);
      context.setSelfcheckResult(selfCheckSubmitResult.result);

      if (selfCheckSubmitResult.result.latestResultSummaryList) {
        const submitted = selfCheckSubmitResult.result.latestResultSummaryList.map((each) => each.selfCheckId);

        const todayNotSubmitted = selfCheckSubmitResult.result.latestResultSummaryList
          .filter(
            (element) =>
              parseInt(Math.abs(new Date() - new Date(element.date)) / (1000 * 3600 * 24)) > 0 &&
              element.selfCheckId !== selfCheckInfo.selfCheckId,
          )
          .sort(function (a, b) {
            return new Date(b.date) - new Date(a.date) || b.scorePercentage - a.scorePercentage;
          });
        console.log('검사 내역 있음', todayNotSubmitted);

        const abnormal = todayNotSubmitted.filter((element) => element.isAbnormal);
        const normal = todayNotSubmitted.filter((element) => !element.isAbnormal);
        const noResult = selfCheckData.filter((element) => !submitted.includes(element.selfCheckId));

        context.setAvailableSelfCheck(abnormal.concat(normal).concat(noResult));
      }
    }
  }, [selfCheckSubmitResult]);

  const goNext = useNavigateWithParams(`/mindScan/${selfCheckId}/result`, true);
  const goHome = useNavigateWithParams(`/mindScan`, true);
  function isDone() {
    setDone(true);
  }

  useEffect(() => {
    if (done) {
      if (first) {
        goHome();
      } else {
        goNext();
      }
    }
  }, [done]);

  return (
    <div
      className={`w-screen h-screen bg-white`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <EndProcessing
        steps={['분석 환경 세팅 중', '개인정보 암호화 처리 중', '검진 답변 분석 중', '결과지 구성 중']}
        isDone={isDone}
      />
    </div>
  );
}
