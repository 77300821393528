import React from 'react';
import selfCheckData from '../../data/selfCheckData';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { SecondaryActionButton } from '../../stories/button/ActionButton';

export default function SelfCheckNotStartedCardB(props) {
  const { selfCheckId, stat } = props;
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === selfCheckId);

  const goSelfCheck = useNavigateWithParams(`/selfCheck/${selfCheckId}/0?navigation=back`);

  return (
    <div className="w-full  bg-white rounded-[20px] px-[20px] pt-[30px] pb-[20px]">
      <p className="text-[24px] leading-[30px] text-center text-[#26282C] font-bold mb-[5px]">
        {selfCheckInfo.selfCheckName}
      </p>
      <p className="text-[15px] leading-[21px] text-center text-[#6B6D76] font-normal mb-[14px] whitespace-pre-line">
        {selfCheckInfo.cardBTitle}
      </p>
      <img
        src={selfCheckInfo.image_square}
        alt={'empty'}
        className="w-[100px] h-[100px] rounded-[100px] mx-auto mb-[15px]"
      />
      <p className="text-[18px] leading-[24px] font-bold mx-auto text-center text-[#6B6D76] mb-[30px]">
        <span className="">지금까지 </span>
        <span className="text-[#2C4BEC]">{stat.totalSubmitCount}명</span>
        <span>이 검사했어요 </span>
      </p>
      <SecondaryActionButton state="ACTIVE" text={`검사하기`} action={goSelfCheck} medium />
    </div>
  );
}
