/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { RoundBottomAction } from '../../../stories/page/RoundBottomAction';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import useMixpanelWeb from '../../../hooks/useMixpanelWeb';
import loomyWebWalkthrough0 from '../../../image/loomy/web/loomyWebWalkthrough0.png';
import loomyWebWalkthrough1 from '../../../image/loomy/web/loomyWebWalkthrough1.png';
import loomyWebWalkthrough2 from '../../../image/loomy/web/loomyWebWalkthrough2.png';
import loomyWebWalkthrough3 from '../../../image/loomy/web/loomyWebWalkthrough3.png';
import loomyWebWalkthrough4 from '../../../image/loomy/web/loomyWebWalkthrough4.png';
import loomyWebWalkthrough5 from '../../../image/loomy/web/loomyWebWalkthrough5.png';
import loomyWebWalkthrough6 from '../../../image/loomy/web/loomyWebWalkthrough6.png';
import loomyWebWalkthrough7 from '../../../image/loomy/web/loomyWebWalkthrough7.png';
import loomyWebWalkthrough8 from '../../../image/loomy/web/loomyWebWalkthrough8.png';
import loomyWebWalkthrough9 from '../../../image/loomy/web/loomyWebWalkthrough9.png';
import loomyWebWalkthrough10 from '../../../image/loomy/web/loomyWebWalkthrough10.png';
import loomy from '../../../image/loomy/loomy.png';

function Template({ label, title, subtitle, noGradient, image, content, buttonText, action, css, fixedRoot }) {
  return (
    <RoundBottomAction
      content={
        <div className="h-full flex flex-col">
          {label && (
            <div className="w-fit mx-auto py-[1px] px-[12px] bg-[#11383B] rounded-[20px] text-white text-[14px] font-bold mt-[12px]">
              {label}
            </div>
          )}
          <div className={`flex flex-col gap-[12px] px-[20px] text-[#11383B] ${label && 'text-center'}`}>
            <div className="pt-[12px] text-[24px] leading-[30px] font-bold">{title}</div>
            {subtitle && <div className="text-[17px] leading-[24px]">{subtitle}</div>}
          </div>
          <div className={`flex-1 flex flex-col relative items-center justify-center `}>
            {image && <img src={image} alt={'empty'} className={`pt-[30px] px-[20px] max-w-[375px] mx-auto ${css}`} />}
            {content && content}
          </div>
        </div>
      }
      noGradient={noGradient}
      buttonText={buttonText}
      action={action}
      bgColor={'#F3F0E7'}
      fixedRoot={fixedRoot}
      isLoomy
    />
  );
}

export default function LoomyWebWalkthrough(props) {
  let { id } = useParams();
  id = parseInt(id);

  const goNext = useNavigateWithParams(`/loomyWebWalkthrough/${id + 1}`);
  const goSubmitInfo = useNavigateWithParams(`/submitInfo/0?type=loomy`);
  const goHome = useNavigateWithParams(`/loomyHome`);
  const setHeaderState = useSetRecoilState(headerAtom);

  const bottomImageCss = 'w-[290px] absolute top-0 left-[50%] translate-x-[-50%]  object-top';
  const centerImageCss =
    'w-full h-full absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] object-contain pb-[30px]';

  const walkthroughData = [
    {
      id: 0,
      title: '모든 내용은 우울 및 무기력 인지행동치료에 기반했어요',
      image: loomyWebWalkthrough0,
      css: 'mt-[10px] w-full mx-auto',
      mixpanel: '(L) 코스',
    },
    {
      id: 1,
      title: 'Loomy는 날씨를 연구하는 기상학자예요',
      image: loomyWebWalkthrough1,
      css: centerImageCss,
      mixpanel: '(L) 핵심 경험 1',
    },
    {
      id: 2,
      title: '마음 날씨는 네 가지의 유형으로 관찰돼요',
      image: loomyWebWalkthrough2,
      css: centerImageCss,
      mixpanel: '(L) 핵심 경험 2',
    },
    {
      id: 3,
      title: '이 날씨는 나의 기분을 결정하죠',
      image: loomyWebWalkthrough3,
      css: centerImageCss,
      mixpanel: '(L) 핵심 경험 3',
    },
    {
      id: 4,
      title: '먹구름 가득한 날씨가 계속되면 우리 마음도 우울해지기 쉬워요',
      image: loomyWebWalkthrough4,
      css: centerImageCss,
      mixpanel: '(L) 핵심 경험 4',
    },
    {
      id: 5,
      title: 'Loomy는 날씨를 맑게 변화 시키기 위한 효과 있는 연구 노트를 완성했어요',
      image: loomyWebWalkthrough5,
      css: centerImageCss,
      mixpanel: '(L) 핵심 경험 5',
    },
    {
      id: 6,
      title: '앞으로 Loomy와 함께 마음 날씨를 맑게 바꾸기 위한 활동들을 할거예요',
      image: loomyWebWalkthrough6,
      css: centerImageCss,
      mixpanel: '(L) 핵심 경험 6',
    },
    {
      id: 7,
      label: '행동 안개 걷어내기',
      title: '무기력함을 극복해요',
      subtitle: '행동 활성화 기법을 통해 무기력함에 머무르게 하는 행동 안개를 걷어내는 활동을 해요',
      image: loomyWebWalkthrough7,
      css: bottomImageCss,
      mixpanel: '(L) 핵심 경험 7',
    },
    {
      id: 8,
      label: '생각 번개 포착하기',
      title: '왜곡된 생각을 바로잡아요',
      subtitle: '부정적인 생각 번개를 포착하고, 생각의 패턴을 바꾸는 연습을 통해 긍정적인 나를 만들어요',
      image: loomyWebWalkthrough8,
      css: bottomImageCss,
      mixpanel: '(L) 핵심 경험 8',
    },
    {
      id: 9,
      label: '감정 폭풍 다스리기',
      title: '감정을 다루는 방법을 배워요',
      subtitle: '내가 느끼는 감정이 무엇인지 파악하고 스스로 통제하는 방법이예요',
      image: loomyWebWalkthrough9,
      css: bottomImageCss,
      mixpanel: '(L) 핵심 경험 9',
    },
    {
      id: 10,
      label: '상황 폭우 대처하기',
      title: '스트레스를 견디는 힘을 길러요',
      subtitle: '스트레스를 폭우가 내리는 상황에서도 의연하게 대처할 수 있는 힘을 기를 거예요',
      image: loomyWebWalkthrough10,
      css: bottomImageCss,
      mixpanel: '(L) 핵심 경험 10',
    },
    {
      id: 11,
      title: '효과가 입증된 연구 결과를 바탕으로 만들어졌어요',
      content: (
        <div className="flex-1 flex flex-col  pb-[24px] items-center ">
          <div className="flex-1 flex flex-col gap-[40px] justify-center items-center">
            {[
              { percentage: 71, effect: '우울 증상의 임상적 개선' },
              { percentage: 57, effect: '전반적인 기분 개선' },
              { percentage: 47, effect: '불면증 개선' },
            ].map((each, index) => (
              <div className="flex flex-col gap-[8px] items-center " key={`effect${index}`}>
                <div className="flex text-[50px] text-[#11383B] font-bold">
                  <p>{each.percentage}%</p>
                </div>
                <div className="text-[17px] leading-[23px] text-[#6B6D76]">{each.effect}</div>
              </div>
            ))}
          </div>
          <div className="flex-none text-[13px] opacity-[0.6] ">Loomy 베타 테스트 결과</div>
        </div>
      ),
      mixpanel: '(L) 효과',
    },
    {
      id: 12,
      title: '그럼 이제 Loomy를 만나보세요',
      image: loomy,
      css: 'max-w-[230px] ',
      mixpanel: '(L) 핵심 경험 10',
    },
  ];
  const currentWalkthroughData = walkthroughData.find((element) => element.id === id);

  const setMixpanel = useMixpanelWeb({
    eventName: `${currentWalkthroughData.mixpanel}`,
    projectName: 'loomy',
  });

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
    if (currentWalkthroughData.mixpanel) setMixpanel();
  }, []);

  return (
    <Template
      label={currentWalkthroughData.label}
      title={currentWalkthroughData.title}
      subtitle={currentWalkthroughData.subtitle}
      noGradient={id !== 0}
      css={currentWalkthroughData.css}
      image={currentWalkthroughData.image}
      content={currentWalkthroughData.content}
      buttonText={'다음'}
      action={id < 11 ? goNext : goSubmitInfo}
      fixedRoot
    />
  );
}
