import { atom } from 'recoil';

const notificationAtom = atom({
  key: 'notification',
  default: {
    sessionNumber: undefined,
    sessionTimeList: undefined,

    worryNoteNumber: undefined,
    worryNoteTimeList: undefined,
  },
});

export default notificationAtom;
