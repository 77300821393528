import React, { useEffect } from 'react';
import Feed from '../pages/feed/Feed';
import Tutorial from '../pages/Tutorial';
import FeedContent from '../pages/feed/FeedContent';
import Counselor from '../pages/Counselor';
import CompanyAuth from '../pages/CompanyAuth';
import Onboarding1 from '../pages/onboarding/Onboarding1';
import Onboarding2 from '../pages/onboarding/Onboarding2';
import StartOnboarding from '../pages/onboarding/StartOnboarding';
import OnboardingPreview from '../pages/onboarding/OnboardingPreview';
import AssignCounselor from '../pages/onboarding/AssignCounselor';
import EndTest from '../pages/onboarding/EndTest';
import Result from '../pages/onboarding/Result';
import Goal from '../pages/onboarding/Goal';
import CourseRecommendation from '../pages/onboarding/CourseRecommendation';
import CoachMatching from '../pages/onboarding/CoachMatching';
import FinishOnboarding from '../pages/onboarding/FinishOnboarding';
import Marketing from '../pages/onboarding/Marketing';
import MarketingReason from '../pages/onboarding/MarketingReason';

import Activity from '../pages/activity/Activity';
import ToolOpen from '../pages/activity/ToolOpen';
import CourseDone from '../pages/course/CourseDone';
import RecommendedCourse from '../pages/course/RecommendedCourse';
import Feedback from '../pages/activity/Feedback';

import { AuthProvider } from '../context';
import { CoachingProvider } from '../coachingContext';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
// import SlideRoutes from 'react-slide-routes';
import SlideRoutes from './react-slide-routes';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { PATH } from './paths';
import WorkbookProgressingBar from '../components/workbook/WorkbookProgressingBar';
import OnboardingProgressingBar from '../components/onboarding/OnboardingProgressingBar';
import Coaching from '../pages/Coaching';
import CheckIn from '../pages/CheckIn';
import Workbook from '../pages/Workbook';
import { ToastContainer } from 'react-toastify';
import CouponInfo from '../pages/CouponInfo';
import MyCoupon from '../pages/MyCoupon';
import RewardInfo from '../pages/RewardInfo';
import Course from '../pages/course/Course';
import ErrorPage from '../pages/ErrorPage';
import DetectDeviceLegacy from '../components/legacy/DetectDeviceLegacy';
import ComingSoon from '../pages/ComingSoon';
import UserLeave from '../pages/userLeave/UserLeave';

import SelfCheck from '../pages/selfcheck/SelfCheck';
import SelfCheckHome from '../pages/selfcheck/SelfCheckHome';
import MindScan from '../pages/mindscan/SelfCheck';
import MindScanHome from '../pages/mindscan/SelfCheckHome';
import LoadSid from '../pages/mindscan/LoadSid';
import FirstMindScan from '../pages/mindscan/FirstMindScan';
import BreathTool from '../pages/course/BreathTool';
import CounselorSearchWizard from '../pages/counselorSearchWizard/CounselorSearchWizard';
import Counseling from '../pages/Counseling';
import smoothscroll from 'smoothscroll-polyfill';

import Setting from '../pages/Setting';
import SleepingReport from '../pages/workbook/SleepingReport';
import SleepingReportXYZ from '../pages/workbook/SleepingReportXYZ';
import ShareCard from '../pages/course/ShareCard';
import AudioPlayer from '../pages/workbook/AudioPlayer2';
import Script from '../components/audioPlayer/Script';
import Subtraction from '../pages/workbook/Subtraction';
import CoachingLeave from '../pages/coachingLeave/CoachingLeave';
import SleepingSurvey from '../pages/marketing/sleepDoctor/SleepingSurvey';
import TimePickerTest from '../pages/TimePickerTest';
import BetterMeType from '../pages/bettermeType/BetterMe';
import BetterMeScore from '../pages/bettermeScore/BetterMe';
import ChallengersSurvey from '../pages/marketing/sleepChallengers/ChallengersSurvey';
import Home from '../pages/home/Home';
import UserProfileList from '../components/home/UserProfileList';
import UserInfo from '../pages/home/UserInfo';
import CounselorHome from '../pages/home/CounselorHome';
import Springcampfamily from '../pages/selfcheck/SpringCampFamily';
import Owl from '../pages/somny/Owl';
import Somny2 from '../pages/somny2/Somny2';
import AnxyTest from '../pages/anxyTest/AnxyTest';
import Test from '../pages/Test';
import Login from '../pages/anxy/Login';
import AfterLogin from '../pages/AfterLogin';
import YoutubeExamplePage from '../pages/example/YoutubeExamplePage';
import MyPageHome from '../pages/anxy/mypage/MyPageHome';
import MyPage from '../pages/anxy/mypage/MyPage';

import AnxyRoadMap from '../pages/anxy/anxy12ndB/AnxyRoadMap';
import WalkthroughB from '../pages/anxy/anxy12ndB/WalkthroughB';
import Level from '../pages/anxy/anxy12ndB/Level';
import Heart from '../pages/anxy/anxy12ndB/Heart';
import Guide from '../pages/anxy/anxy12ndB/Guide';
import Bridge from '../pages/anxy/anxy12ndB/Bridge';
import ManagementTool from '../pages/anxy/anxy12ndB/ManagementTool';
import GainTool from '../pages/anxy/anxy13rd/GainTool';

import WalkthroughA from '../pages/anxy/anxy12ndA/WalkthroughA';
import AnxyModal from '../components/anxy/modal/AnxyModal';
import WorryNote from '../pages/anxy/worryNote/WorryNote';
import WorryNoteHistory from '../pages/anxy/worryNote/WorryNoteHistory';
import WorryNoteHistoryDetail from '../pages/anxy/worryNote/WorryNoteHistoryDetail';
import AnxyProgressingBar from '../components/anxy/AnxyProgressingBar';
import AnxyHeader from '../components/anxy/AnxyHeader';
import AnxyErrorPage from '../pages/anxy/AnxyErrorPage';
import UserLeaveAnxy from '../pages/anxy/mypage/UserLeaveAnxy';
import Payment from '../pages/anxy/payment/Payment';
import PaymentSuccess from '../pages/anxy/payment/PaymentSuccess';
import PaymentConfirmFail from 'pages/anxy/payment/PaymentConfirmFail';
// import './slideRoute.css';
// import AnxySession from '../pages/anxy/AnxySession';
import SessionTemplate from '../pages/anxy/session/SessionTemplate';
import Intro from '../pages/anxy/intro3/Intro';
import BetterMeLogin from '../pages/betterme/BetterMeLogin';
import BetterMeHome from '../pages/betterme/BetterMeHome';
import BetterMeSession from '../pages/betterme/session/BetterMeSession';
import BetterMeTest from '../pages/betterme/test/BetterMeTest';
import BetterMeMyPageHome from '../pages/betterme/myPage/BetterMeMyPageHome';
import BetterMeMyPage from '../pages/betterme/myPage/BetterMeMyPage';
import BetterMeUserLeave from '../pages/betterme/myPage/BetterMeUserLeave';
import Trial from '../pages/anxy/intro/Trial';

import FadeTransition from '../pages/FadeTransition';
import InsideBlack from '../pages/insideBlack/InsideBlack';
import Preview from '../pages/insideBlack/Preview';
import InsideWhite from '../pages/insideWhite/InsideWhite';
import InsideWhitePreview from '../pages/insideWhite/InsideWhitePreview';
import TrialExample from '../pages/anxy/intro/TrialExample';
import SessionCardList from '../pages/anxy/anxy12ndA/SessionCardList';
import AnxyHomeA from '../pages/anxy/anxy12ndA/AnxyHomeA';
import MeditationTool from '../pages/anxy/tool/MeditationTool';
import Main from '../pages/habit/Main';
import { HabitShare } from '../pages/habit/HabitShare';
import SomnyLanding from '../pages/somnyLanding/SomnyLanding';
import SomnyPreview from '../pages/somnyLanding/SomnyPreview';
import SomnyComingSoon from '../pages/somnyLanding/SomnyComingSoon';
import AnxyComingSoon from '../pages/anxy/anxy13rd/AnxyComingSoon';
import Example from '../pages/Example';
import SessionDone from '../pages/anxy/anxy13rd/SessionDone';
import headerAtom from '../recoil/common/header/atom';
import { useRecoilValue } from 'recoil';
import { historyBack, allowBack } from '../utils/webview';
import AnxyNotReady from '../pages/anxy/anxy13rd/AnxyNotReady';

import LoomyLogin from '../pages/loomy/LoomyLogin';
import LoomyWelcome from '../pages/loomy/LoomyWelcome';
import LoomyPreview from '../pages/loomy/LoomyPreview';
import LoomyTest from '../pages/loomy/loomyTest/LoomyTest';
import LoomyWalkthrough from '../pages/loomy/LoomyWalkthrough';
import LoomyComingSoon from '../pages/loomy/LoomyComingSoon';
import SubmitInfo from '../pages/SubmitInfo';
import LoomyMyPageHome from '../pages/loomy/mypage/LoomyMyPageHome';
import LoomyMyPage from '../pages/loomy/mypage/LoomyMyPage';
import LoomyUserLeave from '../pages/loomy/mypage/LoomyUserLeave';

import LoomyWebPreview from '../pages/loomy/loomyWebTest/LoomyWebPreview';
import LoomyWebWalkthrough from '../pages/loomy/loomyWebTest/LoomyWebWalkthrough';
import LoomyWebPreview2 from '../pages/loomy/loomyWebTest2/LoomyWebPreview2';
import LoomyWebWalkthrough2 from '../pages/loomy/loomyWebTest2/LoomyWebWalkthrough2';

import AnxyWebPreview from '../pages/anxyWeb/AnxyWebPreview';
import AnxyWebTest from '../pages/anxyWeb/anxyWebTest/AnxyWebTest';
import AnxyWebAboutCourse from '../pages/anxyWeb/AnxyWebAboutCourse';
import AnxyWebCoreExp from '../pages/anxyWeb/AnxyWebCoreExp';
import AnxyOnboarding from 'pages/anxy/onboarding/AnxyOnboarding';
import AnxyLoading from 'pages/anxyTest/AnxyLoading';

import SomnyOnboarding from '../pages/somny/SomnyOnboarding';
import SomnyBegin from '../pages/somny/SomnyBegin';
import SomnySession from '../pages/somny/SomnySession';
import LoomyHome from '../pages/loomy/LoomyHome';
import LoomySession from '../pages/loomy/LoomySession';
import SleepRecordTemplate from '../pages/somny/sleepRecord/SleepRecordTemplate';
import AlarmTemplate from '../pages/anxy/notification/NotificationTemplate';
import SomnyMyPageHome from '../pages/somny/myPage/SomnyMyPageHome';
import SomnyMyPage from '../pages/somny/myPage/SomnyMyPage';
import SomnyLogin from '../pages/somny/login/SomnyLogin';
import BetterMeModal from '../components/betterme/BetterMeModal';
import SomnyUserLeave from '../pages/somny/myPage/SomnyUserLeave';
import SomnyModal from '../components/somny/SomnyModal';
import SomnyOnboardingInReview from '../pages/somny/inReview/SomnyOnboardingInReview';
import SomnyInReview from '../pages/somny/inReview/SomnyInReview';
import SomnyHomeInReview from '../pages/somny/inReview/SomnyHomeInReview';
import SomnyHome from '../pages/somny/SomnyHome';
import SleepReport from '../pages/somny/sleepReport/SleepReport';
import SomnySessionTemplate from '../components/somny/SomnySessionTemplate';
import { SomnySessionDone } from '../pages/somny/session/SomnySessionDone';
import SleepTaleTemplate from '../pages/somny/sleepTale/SleepTaleTemplate';
import { SleepRecordDone } from '../pages/somny/sleepRecord/SleepRecordDone';
import SomnyPayment from '../pages/somny/payment/SomnyPayment';
import SomnyPaymentSuccess from '../pages/somny/payment/SomnyPaymentSuccess';
import SomnyPaymentConfirmFail from 'pages/somny/payment/SomnyPaymentConfirmFail';
import Breath from '../pages/anxy/tool/breath/Breath';
import SleepTaleHome from '../pages/somny/sleepTale/SleepTaleHome';
import BettyInReview from '../pages/betterme/BettyInReview';
import SomnySessionInReview from '../components/somny/SomnySessionInReview';

export default function Router(props) {
  const headerState = useRecoilValue(headerAtom);

  useEffect(() => {
    if (headerState.headerButtonType !== 'back') {
      historyBack({ allow: false });
      if (window.location.pathname.includes('anxyHome')) {
        allowBack({ allow: true });
      } else {
        allowBack({ allow: false });
      }
    } else {
      historyBack({ allow: true });
      allowBack({ allow: true });
    }
  }, [headerState]);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className="w-screen select-none whitespace-pre-line "
      style={{
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
        WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      }}
    >
      <ToastContainer />
      <BrowserRouter>
        <div className="tracking-[-0.3px] w-full h-full overflow-hidden">
          <Routes>
            <Route path={PATH.home.error} element={<ErrorPage />} />
            <Route path={PATH.home.tutorial} element={<Tutorial />} />
            <Route path={PATH.home.counselor} element={<Counselor />} />
            <Route path={PATH.home.companyAuth} element={<CompanyAuth />} />
            <Route path={PATH.home.loading} element={<DetectDeviceLegacy channel="inside" />} />
            <Route path={PATH.home.shareCard} element={<ShareCard />} />
            <Route path={PATH.home.test} element={<Test />} />
          </Routes>

          <CoachingProvider>
            <WorkbookProgressingBar />
            <Setting
              child={
                <div>
                  <SlideRoutes>
                    <Route path={PATH.home.counseling} element={<Counseling />} />
                    <Route path={PATH.home.feed} element={<Feed />} />
                    <Route path={PATH.home.content} element={<FeedContent />} />
                    <Route path={PATH.home.couponInfo} element={<CouponInfo />} />
                    <Route path={PATH.home.rewardInfo} element={<RewardInfo />} />
                    <Route path={PATH.home.checkIn} element={<CheckIn />} />
                    <Route path={PATH.home.marketing} element={<Marketing />} />
                    <Route path={PATH.home.marketingReason} element={<MarketingReason />} />
                    <Route path={PATH.home.coaching} element={<Coaching />} />
                    <Route path={PATH.home.home} element={<Home />} />
                    <Route path={PATH.home.workbook} element={<Workbook />} />
                    <Route path={PATH.home.myCoupon} element={<MyCoupon />} />
                    <Route path={PATH.home.comingSoon} element={<ComingSoon />} />
                    <Route path={PATH.home.courseDone} element={<CourseDone />} />
                    <Route path={PATH.home.recommendedCourse} element={<RecommendedCourse />} />
                    <Route path={PATH.home.feedback} element={<Feedback />} />
                    <Route path={PATH.home.course} element={<Course />} />
                    <Route path={PATH.home.activity} element={<Activity />} />
                    <Route path={PATH.home.toolOpen} element={<ToolOpen />} />
                    <Route path={PATH.home.breath} element={<BreathTool />} />
                    <Route path={PATH.home.sleepingReport} element={<SleepingReport />} />
                    <Route path={PATH.home.sleepingReportxyz} element={<SleepingReportXYZ />} />
                    <Route path={PATH.marketing.sleepChallengers} element={<ChallengersSurvey />} />

                    <Route path={PATH.home.audio} element={<AudioPlayer />} />
                    <Route path={PATH.home.audioScript} element={<Script />} />
                    <Route path={PATH.home.subtraction} element={<Subtraction />} />
                    <Route path={PATH.home.coachingLeave} element={<CoachingLeave />} />
                    <Route path={PATH.marketing.sleepSurvey} element={<SleepingSurvey />} />
                    <Route path={PATH.home.timePickerTest} element={<TimePickerTest />} />
                    <Route path={PATH.home.userProfileList} element={<UserProfileList />} />
                    <Route path={PATH.home.userInfo} element={<UserInfo />} />
                    <Route path={PATH.home.counselorHome} element={<CounselorHome />} />
                    <Route path={PATH.home.youtubeExample} element={<YoutubeExamplePage />} />
                  </SlideRoutes>
                </div>
              }
            />
          </CoachingProvider>

          <AuthProvider>
            <OnboardingProgressingBar />
            <Setting
              child={
                <div>
                  <SlideRoutes>
                    <Route path={PATH.home.assignCounselor} element={<AssignCounselor />} />
                    <Route path={PATH.home.counselorSearchWizard} element={<CounselorSearchWizard />} />
                    <Route path={PATH.home.onboarding1} element={<Onboarding1 />} />
                    <Route path={PATH.home.onboardingPreview} element={<OnboardingPreview />} />
                    <Route path={PATH.home.onboarding2} element={<Onboarding2 />} />
                    <Route path={PATH.home.endtest} element={<EndTest />} />
                    <Route path={PATH.home.result} element={<Result />} />
                    <Route path={PATH.home.goal} element={<Goal />} />
                    <Route path={PATH.home.courseRecommendation} element={<CourseRecommendation />} />
                    <Route path={PATH.home.coachMatching} element={<CoachMatching />} />
                    <Route path={PATH.home.finishOnboarding} element={<FinishOnboarding />} />
                    <Route path={PATH.home.userLeave} element={<UserLeave />} />
                    <Route path={PATH.home.selfCheck} element={<SelfCheck />} />
                    <Route path={PATH.home.selfCheckHome} element={<SelfCheckHome />} />
                    <Route path={PATH.home.mindScan} element={<MindScan />} />
                    <Route path={PATH.home.mindScanLoadSid} element={<LoadSid />} />
                    <Route path={PATH.home.mindScanHome} element={<MindScanHome />} />
                    <Route path={PATH.home.firstMindScanStart} element={<FirstMindScan />} />
                    <Route path={PATH.home.firstMindScan} element={<FirstMindScan />} />
                    <Route path={PATH.home.bettermeType} element={<BetterMeType />} />
                    <Route path={PATH.home.bettermeScore} element={<BetterMeScore />} />
                    <Route path={PATH.home.somnyTest} element={<Owl />} />
                    <Route path={PATH.home.somnyTest2} element={<Somny2 />} />
                  </SlideRoutes>
                  <Routes>
                    <Route path={PATH.home.startOnboarding} element={<StartOnboarding />} />
                    <Route path={PATH.home.springcampfamily} element={<Springcampfamily />} />
                    <Route path={PATH.home.stress} element={<Springcampfamily />} />
                  </Routes>
                </div>
              }
            />
          </AuthProvider>

          <Setting
            child={
              <div className="relative " id="anxy">
                <AnxyHeader />
                <AnxyProgressingBar />
                <AnxyModal />
                <BetterMeModal />
                <SomnyModal />
                <Routes>
                  <Route path={PATH.anxy.trial} element={<Trial />} />
                  <Route path={PATH.anxy.trialExample} element={<TrialExample />} />
                </Routes>

                <SlideRoutes>
                  <Route path={PATH.betterMe.login} element={<BetterMeLogin />} />
                  <Route path={PATH.betterMe.bettyInReview} element={<BettyInReview />} />
                  <Route path={PATH.betterMe.betterMeTest} element={<BetterMeTest />} />
                  <Route path={PATH.betterMe.betterMeHome} element={<BetterMeHome />} />
                  <Route path={PATH.betterMe.betterMeSession} element={<BetterMeSession />} />
                  <Route path={PATH.betterMe.myPageHome} element={<BetterMeMyPageHome />} />
                  <Route path={PATH.betterMe.myPage} element={<BetterMeMyPage />} />
                  <Route path={PATH.betterMe.userLeave} element={<BetterMeUserLeave />} />

                  <Route path={PATH.anxy.login} element={<Login />} />

                  <Route path={PATH.anxy.afterLogin} element={<AfterLogin />} />
                  <Route path={PATH.anxy.anxyTest} element={<AnxyTest />} />

                  <Route path={PATH.anxy.anxyWebPreview} element={<AnxyWebPreview />} />
                  <Route path={PATH.anxy.anxyWebTest} element={<AnxyWebTest />} />
                  <Route path={PATH.anxy.anxyWebAboutCourse} element={<AnxyWebAboutCourse />} />
                  <Route path={PATH.anxy.anxyWebCoreExp} element={<AnxyWebCoreExp />} />
                  <Route path={PATH.anxy.anxyOnboarding} element={<AnxyOnboarding />} />
                  <Route path={PATH.anxy.anxyLoading} element={<AnxyLoading />} />

                  <Route path={PATH.anxy.anxyRoadMap} element={<AnxyRoadMap />} />
                  <Route path={PATH.anxy.walkthoughA} element={<WalkthroughA />} />
                  <Route path={PATH.anxy.walkthoughB} element={<WalkthroughB />} />
                  <Route path={PATH.anxy.level} element={<Level />} />
                  <Route path={PATH.anxy.heart} element={<Heart />} />
                  <Route path={PATH.anxy.guide} element={<Guide />} />
                  <Route path={PATH.anxy.bridge} element={<Bridge />} />

                  <Route path={PATH.anxy.managementTool} element={<ManagementTool />} />
                  <Route path={PATH.anxy.gainTool} element={<GainTool />} />

                  <Route path={PATH.anxy.anxyHome} element={<AnxyHomeA />} />
                  <Route path={PATH.anxy.worryNote} element={<WorryNote />} />
                  <Route path={PATH.anxy.worryNoteHistory} element={<WorryNoteHistory />} />
                  <Route path={PATH.anxy.worryNoteHistoryDetail} element={<WorryNoteHistoryDetail />} />

                  <Route path={PATH.anxy.sessionCardList} element={<SessionCardList />} />
                  <Route path={PATH.anxy.anxySession} element={<SessionTemplate />} />
                  <Route path={PATH.anxy.meditationTool} element={<MeditationTool />} />
                  <Route path={PATH.anxy.comingSoon} element={<AnxyComingSoon />} />
                  <Route path={PATH.anxy.example} element={<Example />} />
                  <Route path={PATH.anxy.sessionDone} element={<SessionDone />} />
                  <Route path={PATH.anxy.notReady} element={<AnxyNotReady />} />
                  <Route path={PATH.anxy.notification} element={<AlarmTemplate />} />

                  <Route path={PATH.anxy.myPageHome} element={<MyPageHome />} />
                  <Route path={PATH.anxy.myPage} element={<MyPage />} />
                  <Route path={PATH.anxy.userLeaveAnxy} element={<UserLeaveAnxy />} />

                  <Route path={PATH.anxy.payment} element={<Payment />} />
                  <Route path={PATH.anxy.paymentSuccess} element={<PaymentSuccess />} />
                  <Route path={PATH.anxy.paymentConfirmFail} element={<PaymentConfirmFail />} />
                  <Route path={PATH.anxy.anxyError} element={<AnxyErrorPage />} />
                  <Route path={PATH.anxy.breathTool} element={<Breath />} />

                  <Route path={PATH.marketing.insideBlack} element={<InsideBlack />} />
                  <Route path={PATH.marketing.insideBlackPreview} element={<Preview />} />
                  <Route path={PATH.marketing.insideWhite} element={<InsideWhite />} />
                  <Route path={PATH.marketing.insideWhitePreview} element={<InsideWhitePreview />} />
                  <Route path={PATH.marketing.habit} element={<Main />} />
                  <Route path={PATH.marketing.habitShare} element={<HabitShare />} />

                  <Route path={PATH.home.somnyLanding} element={<SomnyLanding />} />
                  <Route path={PATH.home.somnyPreview} element={<SomnyPreview />} />
                  <Route path={PATH.home.somnyComingSoon} element={<SomnyComingSoon />} />
                  <Route path={PATH.marketing.submitInfo} element={<SubmitInfo />} />

                  <Route path={PATH.loomy.login} element={<LoomyLogin />} />
                  <Route path={PATH.loomy.welcome} element={<LoomyWelcome />} />
                  <Route path={PATH.loomy.preview} element={<LoomyPreview />} />
                  <Route path={PATH.loomy.test} element={<LoomyTest />} />
                  <Route path={PATH.loomy.walkthrough} element={<LoomyWalkthrough />} />
                  <Route path={PATH.loomy.comingSoon} element={<LoomyComingSoon />} />
                  <Route path={PATH.loomy.myPageHome} element={<LoomyMyPageHome />} />
                  <Route path={PATH.loomy.myPage} element={<LoomyMyPage />} />
                  <Route path={PATH.loomy.userLeave} element={<LoomyUserLeave />} />
                  <Route path={PATH.loomy.webPreview} element={<LoomyWebPreview />} />
                  <Route path={PATH.loomy.webWalkthrough} element={<LoomyWebWalkthrough />} />
                  <Route path={PATH.loomy.webPreview2} element={<LoomyWebPreview2 />} />
                  <Route path={PATH.loomy.webWalkthrough2} element={<LoomyWebWalkthrough2 />} />

                  <Route path={PATH.somny.login} element={<SomnyLogin />} />
                  <Route path={PATH.somny.onboarding} element={<SomnyOnboarding />} />
                  <Route path={PATH.somny.inReview} element={<SomnyInReview />} />
                  <Route path={PATH.somny.onboardingInReview} element={<SomnyOnboardingInReview />} />
                  <Route path={PATH.somny.homeInReview} element={<SomnyHomeInReview />} />
                  <Route path={PATH.somny.sessionInReview} element={<SomnySessionInReview />} />

                  <Route path={PATH.somny.begin} element={<SomnyBegin />} />
                  <Route path={PATH.somny.home} element={<SomnyHome />} />
                  <Route path={PATH.somny.session} element={<SomnySessionTemplate />} />
                  <Route path={PATH.somny.sessionDone} element={<SomnySessionDone />} />
                  <Route path={PATH.somny.tale} element={<SleepTaleTemplate />} />
                  <Route path={PATH.somny.taleHome} element={<SleepTaleHome />} />
                  <Route path={PATH.somny.sleepRecord} element={<SleepRecordTemplate />} />
                  <Route path={PATH.somny.sleepRecordDone} element={<SleepRecordDone />} />
                  <Route path={PATH.somny.sleepReport} element={<SleepReport />} />
                  <Route path={PATH.somny.myPageHome} element={<SomnyMyPageHome />} />
                  <Route path={PATH.somny.myPageHomeInReview} element={<SomnyMyPageHome />} />
                  <Route path={PATH.somny.myPage} element={<SomnyMyPage />} />
                  <Route path={PATH.somny.userLeave} element={<SomnyUserLeave />} />
                  <Route path={PATH.somny.payment} element={<SomnyPayment />} />
                  <Route path={PATH.somny.paymentSuccess} element={<SomnyPaymentSuccess />} />
                  <Route path={PATH.somny.paymentConfirmFail} element={<SomnyPaymentConfirmFail />} />

                  <Route path={PATH.loomy.home} element={<LoomyHome />} />
                  <Route path={PATH.loomy.session} element={<LoomySession />} />
                </SlideRoutes>
                {/* <FadeTransition> */}
                <Routes>
                  <Route path={PATH.anxy.intro} element={<Intro />} key={'intro'} />
                </Routes>
                {/* </FadeTransition> */}
              </div>
            }
          />
        </div>
      </BrowserRouter>
    </div>
  );
}
