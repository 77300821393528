import React from 'react';

export function Title24(props) {
  const { title, color, className } = props;

  return (
    <div
      style={{ wordBreak: 'keep-all', overflowWrap: 'break-word', color: color }}
      className={`font-bold text-[24px] leading-[30px] text-anxyBlack ${className}`}
    >
      {title}
    </div>
  );
}

export function SubTitle18(props) {
  const { subtitle, color, className } = props;

  return (
    <div
      style={{ wordBreak: 'keep-all', overflowWrap: 'break-word', color: color }}
      className={`font-semibold text-[18px] leading-[23px] text-anxyBlack break-all ${className}`}
    >
      {subtitle}
    </div>
  );
}

export function Text17(props) {
  const { text, color, className } = props;

  return (
    <div className={`text-[17px] leading-[24px] text-anxyBlack ${className}`} style={{ color: color }}>
      {text}
    </div>
  );
}

export function Caption15(props) {
  const { caption, color, className } = props;

  return (
    <div className={`text-[15px] leading-[21px] text-anxyBlack ${className}`} style={{ color: color }}>
      {caption}
    </div>
  );
}
