import React from 'react';
import anxyWithWorri from '../../../image/anxy/tool/anxyWithWorri.png';
import { useEffect } from 'react';
import AudioPlayer from './AudioPlayer';
import Lottie from '../../elements/Lottie';
import anxyWalking from '../../../stories/animation/anxy_walking.json';

export function MeditateWithAudio({ setButtonState, theme, setBgColor }) {
  useEffect(() => {
    if (theme) {
      if (theme.isNotReady) {
        setButtonState('INACTIVE');
      } else {
        setButtonState('ACTIVE');
      }
    }
  }, [theme]);

  useEffect(() => {
    setBgColor('#F3F5F800');
  }, []);

  return (
    <div className="w-full flex flex-col justify-end h-full">
      <div
        className="flex justify-center absolute bottom-[160px] w-full z-[10] m-auto"
        style={{
          height: 'calc(100vh - 320px)',
          background: 'linear-gradient(180deg, #F3F5F800 25%, #B8BDC6 77.81%, #F3F5F800 92.85%)',
        }}
      >
        <div className="absolute top-[50%] translate-y-[-50%] px-[50px]">
          {/* <img
            src={anxyWithWorri}
            alt="empty"
            className="w-full object-contain"
            style={{
              height: 'calc(100vh - 320px)',
            }}
          /> */}
          <Lottie lottieData={anxyWalking} autoplay loop />
        </div>
      </div>
      <div className="h-screen bg-black absolute top-0 left-[0px]">
        <img src={theme.image} alt="empty" className=" h-full object-cover" />
      </div>
      <div
        className="absolute bottom-0 w-full h-[280px] z-[0]"
        style={{
          background: 'linear-gradient(180deg, rgba(243, 245, 248, 0) 0%, #F3F5F8 59.23%)',
        }}
      />
      <div className="w-full mx-auto z-[50]">
        <AudioPlayer audio="https://media.priv-inside.im/anxy/meditation_lake.wav" />
      </div>
    </div>
  );
}
