import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import pointList from '../../data/inside/pointList';
import { TopTitleAnswerList } from './TopTitleAnswerList';

export default function AnimateTitleBottomAction(props) {
  const { state, id } = useParams();

  const { optionList, action, propsName, noHeader } = props;

  const [answer, setAnswer] = useState();

  function isClicked(option) {
    if (option !== '') {
      //   select(option);
    }
  }

  return (
    <div
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <TopTitleAnswerList
        propsName={propsName}
        point={pointList[parseInt(id) / 2 - 1]}
        buttonText={'다음'}
        buttonState={'ACTIVE'}
        optionList={optionList}
        answer={answer}
        isClicked={isClicked}
        action={action}
        noHeader={noHeader}
      />
    </div>
  );
}
