/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import meditateEnd from 'image/anxy/tool/meditateEnd.png';
import { TopTitleBottomActionAnxy } from 'stories/page/TopTitleBottomActionAnxy';
import { useEffect } from 'react';
import { SelectThemeWithButton } from 'components/anxy/tool/SelectThemeWithButton';
import { SelectReadyButton } from 'components/anxy/tool/SelectReadyButton';
import headerAtom, { BUTTON_BACK } from 'recoil/common/header/atom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { meditationThemeData } from 'data/anxy/meditationThemeData';
import { FullPageAnxy } from 'stories/page/FullPageAnxy';
import { MeditateWithAudio } from 'components/anxy/tool/MeditateWithAudio';
import useLoadImage from 'hooks/useLoadImage';
import usePostSessionDone from 'hooks/usePostSessionDone';
import { meditationImageList } from 'data/anxy/contents/meditationImageList';
import useFetchAnxy from 'hooks/useFetchAnxy';
import userAtom from 'recoil/anxy/user/atom';
import { FirstPage } from 'components/anxy/tool/FirstPage';
import useMixpanelEventAnxy from 'hooks/useMixpanelEventAnxy';
import usePostToolDone from 'hooks/usePostToolDone';
import { keepScreenEvent } from 'utils/webview';
import sessionAtom from 'recoil/anxy/session/atom';
import statusAtom from 'recoil/anxy/home/statusAtom';
import homeTypeAtom from 'recoil/anxy/home/atom';

export default function MeditationTool({ resultList }) {
  const [searchParams] = useSearchParams();
  const uiType = searchParams.get('uiType');
  const isHomeA = uiType === 'a';
  const isSession = searchParams.get('isSession') === 'true';

  const { id } = useParams();
  const [buttonText, setButtonText] = useState('다음');
  const [buttonState, setButtonState] = useState('ACTIVE');

  const [sessionState, setSessionState] = useRecoilState(sessionAtom);
  const [userState, setUserState] = useRecoilState(userAtom);
  const [headerState, setHeaderState] = useRecoilState(headerAtom);

  const [theme, setTheme] = useState(sessionState.theme ? sessionState.theme : meditationThemeData[0]);
  const [bgColor, setBgColor] = useState('#F3F5F8');

  const statusState = useRecoilValue(statusAtom);
  const setHomeType = useSetRecoilState(homeTypeAtom);

  const [score, setScore] = useState(userState.meditationScore);

  const [recordDetail, getRecordDetail] = useFetchAnxy({
    url: `/anxy/anxiety-record/load`,
    bg: '#F3F5F8',
  });

  const [saveAnxietyResultResult, saveAnxietyRecord] = useFetchAnxy({
    url: 'anxy/anxiety-record/save',
    requestBody: JSON.stringify({
      note: userState.anxietyNote,
      score: userState.meditationScore,
    }),
  });

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '툴 진행 시작',
    params: {
      툴: '호흡 산책',
    },
  });

  const imagePreload = useLoadImage(meditationThemeData.map((item) => item.image));
  const imagePreload2 = useLoadImage(meditationImageList);

  const goNext = useNavigateWithParams(`/meditationTool/${parseInt(id) + 1}`);
  const goToPayment = useNavigateWithParams(`../payment?uiType=${uiType}&theme=${theme.id}`);

  const completedAtBeforePayment =
    sessionState.sessionList &&
    sessionState.sessionList.length !== 0 &&
    sessionState.sessionList.find((item) => item.sectionId === 'box_meditate_aa').completedAt;
  const goToComingSoon = useNavigateWithParams(`../anxyComingSoon?date=${completedAtBeforePayment}&theme=${theme.id}`);

  useEffect(() => {
    setUserState((prev) => ({ ...prev, meditationScore: score }));
  }, [score]);

  useEffect(() => {
    if (recordDetail) {
      setUserState((prev) => ({ ...prev, meditationScore: recordDetail.result.score, note: recordDetail.result.note }));
    }
  }, [recordDetail]);

  useEffect(() => {
    if (id === '0') {
      setMixPanel();
      imagePreload();
      imagePreload2();
      keepScreenEvent({ isKeepScreenOn: true });
    } else if (id === '3') {
      if (isHomeA) {
        getRecordDetail();
      }
    }
  }, [id]);

  useEffect(() => {
    if (sessionState.theme) {
      setTheme(sessionState.theme);
    }
  }, [sessionState]);

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '', headerTitle: '' }));
  }, []);

  const LastPage = () => (
    <div className="w-full flex flex-col justify-end h-full">
      <div
        className="flex justify-center absolute bottom-[160px] w-full z-[10] m-auto"
        style={{
          height: 'calc(100vh - 320px)',
          background: 'linear-gradient(180deg, #F3F5F800 25%, #B8BDC6 77.81%, #F3F5F800 92.85%)',
        }}
      >
        <div className="absolute top-[50%] translate-y-[-50%] px-[13.3%] pb-[12%]">
          <img
            src={meditateEnd}
            alt="empty"
            className="w-full object-contain"
            style={{
              height: 'calc(100vh - 320px)',
            }}
          />
        </div>
      </div>
      <div
        className="absolute bottom-0 w-full h-[500px] z-[0]"
        style={{
          background: 'linear-gradient(180deg, rgba(243, 245, 248, 0) 0%, #F3F5F8 59.23%)',
        }}
      />
    </div>
  );

  const contentListA = [
    {
      buttonHide: true,
      content: (
        <div className="h-full flex items-center justify-center">
          <FirstPage goNext={goNext} />
        </div>
      ),
    },
    {
      title: 'Wori와 어디를 산책할까요?',
      content: (
        <div className="h-full flex items-center justify-center">
          <SelectThemeWithButton setButtonState={setButtonState} theme={theme} />
        </div>
      ),
    },
    {
      title: '모든 준비를 마쳤나요?',
      content: (
        <div className="h-full flex items-center justify-center">
          <SelectReadyButton setButtonState={setButtonState} setButtonText={setButtonText} />
        </div>
      ),
    },
    {
      isFullPage: true,
      content: (
        <div className="h-full">
          <MeditateWithAudio setButtonState={setButtonState} setBgColor={setBgColor} theme={theme} />
        </div>
      ),
    },
    // {
    //   title: '지금은 얼마나 불안한가요?',
    //   content: (
    //     <div className="h-full">
    //       <SetAnxietyWithWorri score={userState.meditationScore} setButtonState={setButtonState} setScore={setScore} />
    //     </div>
    //   ),
    // },
    {
      title: '산책을 마쳤어요',
      content: (
        <div className="h-full flex items-center justify-center">
          <LastPage />
        </div>
      ),
    },
  ];

  useEffect(() => {
    console.log(contentList[parseInt(id)].title);
    if (isEnd) {
      setButtonText('완료');
    }
  }, [id]);

  const postSessionDone = usePostSessionDone({ sectionId: 'exercise_meditate_aa' });
  const postToolDone = usePostToolDone({ toolId: 'tool_aa' });

  const contentList = contentListA;
  const isEnd = parseInt(id) === contentList.length - 1;

  const handleEnd = () => {
    keepScreenEvent({ isKeepScreenOn: false });

    if (isHomeA) {
      setHomeType('worri');
      if (userState.anxietyNote) {
        saveAnxietyRecord();
      } else {
        postToolDone();
      }
    } else {
      if (isSession) {
        postSessionDone();
      } else {
        postToolDone();
      }
    }
  };

  useEffect(() => {
    if (saveAnxietyResultResult) {
      postToolDone();
    }
  }, [saveAnxietyResultResult]);

  const handleGoToPayment = () => {
    if (statusState.isInReview) {
      goToComingSoon();
    } else {
      goToPayment();
    }
  };
  return (
    <>
      {contentList[parseInt(id)].isFullPage ? (
        <FullPageAnxy
          title={contentList[parseInt(id)].title && contentList[parseInt(id)].title}
          subtitle={contentList[parseInt(id)].subtitle && contentList[parseInt(id)].subtitle}
          content={contentList[parseInt(id)].content}
          isBottom={contentList[parseInt(id)].isBottom}
          buttonText={buttonText}
          buttonState={buttonState}
          bgColor={bgColor}
          action={goNext}
          insideBlack
        />
      ) : (
        <TopTitleBottomActionAnxy
          title={contentList[parseInt(id)].title && contentList[parseInt(id)].title}
          subtitle={contentList[parseInt(id)].subtitle && contentList[parseInt(id)].subtitle}
          content={contentList[parseInt(id)].content}
          isBottom={contentList[parseInt(id)].isBottom}
          buttonText={buttonText}
          buttonState={buttonState}
          bgColor={bgColor}
          action={() => {
            if (isEnd) {
              handleEnd();
            } else {
              if (theme.id !== 1) {
                handleGoToPayment();
              } else {
                console.log('goNext');
                goNext();
              }
            }
          }}
          noHeader
          noGradient
          insideBlack
          buttonHide={contentList[parseInt(id)].buttonHide}
        />
      )}
    </>
  );
}
