import { TextWithBold } from '../components/workbook/TextWithBold';

const coachInfo = [
  [
    {
      id: 0,
      title: '분노를 느꼈다면?',
      texts: [
        {
          text: [`"어? 왜 나를 무시하지?"`, <br />, <br />],
          bold: false,
        },
        {
          text: [
            `• 상황: 상사가 인사를 받아주지 않았다.`,
            <br />,
            `• 생각: 저 사람이 나를 무시하네?`,
            <br />,
            `• 감정: 분노`,
          ],
          bold: false,
        },
      ],
    },
    {
      id: 1,
      title: '우울을 느꼈다면?',
      texts: [
        {
          text: [`“나는 어디서든 사랑받지 못하는 사람이야”`, <br />, <br />],
          bold: false,
        },
        {
          text: [
            `• 상황: 상사가 인사를 받아주지 않았다.`,
            <br />,
            `• 생각: 나는 사랑받지 못해`,
            <br />,
            `• 감정: 우울`,
          ],
          bold: false,
        },
      ],
    },
    {
      id: 2,
      title: '불안을 느꼈다면?',
      texts: [
        {
          text: [`“내가 실수한 게 있나?”`, <br />, <br />],
          bold: false,
        },
        {
          text: [
            `• 상황: 상사가 인사를 받아주지 않았다.`,
            <br />,
            `• 생각: 내가 실수한 게 있는 것 같아`,
            <br />,
            `• 감정: 불안`,
          ],
          bold: false,
        },
      ],
    },
  ],
  [
    {
      title: '흑백논리',
      texts: [
        {
          text: [`극단적인 두 선택지 사이에서 타협점 없이 모 아니면 도라는 논리로 상황에 접근하는 방식이에요.`],
          bold: false,
        },
      ],
    },
    {
      title: '일반화',
      texts: [
        {
          text: [`한 가지 사건을 가지고 자신 혹은 상황 전체를 전부 평가해버리는 것이에요.`],
          bold: false,
        },
      ],
    },
    {
      title: '좋은 일 회피하기',
      texts: [
        {
          text: [`긍정적인 사건이나 상황을 무시하고 깎아내리는 경향을 뜻해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '좁은 시야',
      texts: [
        {
          text: [`긍정적인 면을 보지 못하고 부정적인 면에만 집중하는 것을 뜻해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '추측',
      texts: [
        {
          text: [`충분한 정보가 없을 때 타인이 취하는 행동의 근거를 마음대로 추정하는 것을 말해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '성급한 결론',
      texts: [
        {
          text: [`적절한 근거가 없는 상태에서 상황을 해석해버리는 것을 뜻해요.`],
          bold: false,
        },
      ],
    },
  ],
  [
    {
      id: 0,
      title: '흑백논리',
      texts: [
        {
          text: [`극단적인 두 선택지 사이에서 타협점 없이 모 아니면 도라는 논리로 상황에 접근하는 방식이에요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '일반화',
      texts: [
        {
          text: [`한 가지 사건을 가지고 자신 혹은 상황 전체를 전부 평가해버리는 것이에요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '좋은 일 회피하기',
      texts: [
        {
          text: [`긍정적인 사건이나 상황을 무시하고 깎아내리는 경향을 뜻해요.`],
          bold: false,
        },
      ],
    },
    {
      title: '경험과 다른 결론',
      texts: [
        {
          text: [`나의 경험과는 다른 방향으로 생각하는 것을 뜻해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '좁은 시야',
      texts: [
        {
          text: [`긍정적인 면을 보지 못하고 부정적인 면에만 집중하는 것을 뜻해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '추측',
      texts: [
        {
          text: [`충분한 정보가 없을 때 타인이 취하는 행동의 근거를 마음대로 추정하는 것을 말해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '성급한 결론',
      texts: [
        {
          text: [`적절한 근거가 없는 상태에서 상황을 해석해버리는 것을 뜻해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '감정으로 판단하기',
      texts: [
        {
          text: [`현재 느끼는 감정이 진리라고 믿고 현실적 근거 없이 감정에 따라 상황을 판단하는 것을 뜻해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '당위 부여',
      texts: [
        {
          text: [
            `나 자신, 다른 사람, 혹은 이 세상이 어떤 모습으로 어떻게 행동해야 하는지 명확히 이해하고 전부 다 내 믿음과 일치하기를 바라는 생각 함정을 뜻해요.`,
          ],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '꼬리표 붙이기',
      texts: [
        {
          text: [`나 자신 혹은 다른 사람에게 실제로는 존재하지 않는 특성을 영구적으로 부여하는 것을 뜻해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '과도한 책임감',
      texts: [
        {
          text: [`나와 관련 없는 일도 나와 관련된 일로 생각하면서 과도한 책임감을 느끼는 것을 뜻해요.`],
          bold: false,
        },
      ],
    },
    {
      id: 0,
      title: '최악의 시나리오 떠올리기',
      texts: [
        {
          text: [`그리 중요하지 않은 사건에서 가장 나쁜 상황을 상상하는 것을 뜻해요.`],
          bold: false,
        },
      ],
    },
  ],
  [
    {
      title: '1. 잠은 빌릴 수도 있다',
      texts: [
        {
          text: [
            `우리 몸은 한두 번 적게 잤다고 하여 화내지 않습니다. 언젠가는 조금 더 자라고만 말하죠. 즉, 오늘 못 잔 잠은 다음 번 언젠가 조금 더 보충하면 그만입니다. 그러니 잠이 안 올 때 “오늘 못 자면 어떻게 하지”라며 불안해 할 필요없답니다. 잠은 빌릴 수도 있으며, 우리 몸은 하루 불충분하게 자더라도 일상생활을 해 나가는 데에 큰 문제가 없답니다.`,
          ],
        },
      ],
    },
    {
      title: '2. 다른 시간에 자면 밤엔 덜 자게 된다',
      texts: [
        {
          text: [
            `당연한 이야기이지만 항상성의 입장에서 보면 보다 명확합니다. 가령 우리가 낮잠을 잔다면, 밤에는 더 적은 시간을 자도 된다는 의미입니다. 문제는 밤에 더 적은 시간을 자게 되면 우리는 잠자리 누워 “왜 잠이 안 오지, 이대로 못 자면 어떻게 하지”하며 불안해한다는 것이죠.

            저는 앞으로 여러분의 수면 시간과 낮잠을 제한할 거랍니다. 수면 노트 기록을 기반으로 구체적으로 언제 잠자리에 들고 일어나야 하는지 알려드릴 거예요. 그 원리에는 수면 항상성이 있다는 점을 기억하시면 많은 도움이 될 거랍니다.`,
          ],
        },
      ],
    },
  ],
  [
    {
      title: '1. 졸릴 때만 잠자리에 눕는다',
      texts: [
        {
          text: ['이제부터는 눈꺼풀이 무거워지고, 눈이 감기며, 하품이 나오는 순간에만 잠자리에 누울 거예요.'],
        },
      ],
    },
    {
      title: '2. 누웠지만 졸리지 않을 때에는 잠자리에서 일어난다',
      texts: [
        {
          text: [
            '잠자리에 눕고 나서 15분 정도가 지났음에도 잠들지 않는다면 잠자리에서 빠져나와 따뜻하고 안락한 다른 장소로 가요. 그곳에서 독서, 음악 감상 등 자극적이지 않은 활동들을 하면 된답니다. 단, TV나 휴대폰 사용은 안 돼요.',
          ],
        },
      ],
    },
    {
      title: '3. 침실에서 잠자는 것 이외의 활동은 하지 않는다',
      texts: [
        {
          text: [
            '침실은 잠을 자기 위한 공간이에요. 앞으로는 침실에서 책을 읽거나 휴대폰을 하거나 업무를 하는 것과 같은 활동은 하지 않기로 해요.',
          ],
        },
      ],
    },
  ],
  [
    {
      title: '일반화',
      textList: [
        <span />,
        '한 가지 사건을 가지고 자신 혹은 상황 전체를 평가해버리는 것',
        '을 “일반화”라고 합니다. 흔히 잠을 잘 못 자거나 노력을 했음에도 수면의 양과 질 측면에서 개선이 없어 실망감을 느낄 때 이런 생각을 할 때가 많습니다. 일이 잘 안 풀려서 발생한 감정이 이성적인 사고를 지배해버리는 것이지요.',
      ],
    },
    {
      title: '경험과 다른 결론',
      textList: [
        <span />,
        '과거의 경험에 비추어보면 그렇지 않음에도 순간적인 감정에 압도되어 그간의 경험과는 다르게 생각하는 것',
        '을 “경험과 다른 결론”이라고 합니다. 흔히 잠에 대한 걱정이 너무 커져서 그간 경험과는 달리 비이성적인 사고를 하게 되는 상황에서 발생하죠.',
      ],
    },
    {
      title: '최악의 시나리오 떠올리기',
      textList: [
        <span />,
        '수면과 관련하여 걱정할 때 가장 좋지 않은 경우만 떠올리는 것',
        '을 “최악의 시나리오 떠올리기”라고 합니다. 주로 불면증 때문에 좌절감을 느낄 때, 혹은 과도한 걱정과 불안이 마음을 휩쓸 때 발생하죠.',
      ],
    },
    {
      title: '당위 부여',
      textList: [
        '흔히 ',
        '남들과 비교를 하거나, 혹은 수면에 대한 잘못된 지식으로 인해 반드시 지켜야 하는 규칙들에 집착하는 것',
        '을 “당위 부여”라고 합니다. 흔히 ',
        '“~해야 한다”라는 형태',
        '로 나타나곤 하죠.',
      ],
    },
    {
      title: '성급한 결론',
      textList: [
        '성급한 결론은 ',
        '아직 충분한 근거가 없는 상황에서 상황을 해석해 버리는 것',
        '을 뜻합니다. 흔히 잠이 오지 않을 때 불안한 마음에 성급한 결론을 많이 내리곤 하죠.',
      ],
    },
    {
      title: '좁은 시야',
      textList: [
        '모든 일에는 긍정적인 면도 있고 부정적인 면도 있는 법입니다. 하지만 ',
        '긍정적인 면은 보지 못한 채, 부정적인 면에만 집중하여 상황을 판단하는 것',
        '을 좁은 시야라고 부릅니다. 흔히 불면으로 인해 안 좋은 일이 발생했을 때 그 일에만 집중하여 불면으로 인한 영향을 과대해석할 때 발생하죠.',
      ],
    },
    {
      title: '흑백 논리',
      textList: [
        '흑백논리는 ',
        '극단적인 두 가지 선택지 사이에서 모 아니면 도라는 논리로 상황을 접근하는 것',
        '을 뜻합니다. 수면과 관련해서는 조금 못 잤을 때 “오늘도 잘 못 잤다”라고 결론 내려버리는 것이지요. 한 번도 깨지 않고 깊은 잠을 잤을 때만 그제서야 잘 잔 것이고 그 이외의 수면은 모두 “못 잤다” 혹은 “역시나 불면의 밤이었다”라고 결론 짓는 것을 뜻합니다.',
      ],
    },
  ],
];

export default coachInfo;
