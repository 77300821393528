import React, { useEffect, useState } from 'react';
import { TopTitleBottomActionAnxy } from '../../../stories/page/TopTitleBottomActionAnxy';
import useMixpanelEventAnxy from '../../../hooks/useMixpanelEventAnxy';

export default function ChangeNumberWithButton({
  title,
  subtitle,
  state,
  setState,
  number,
  propsName,
  action,
  isSession,
}) {
  const maxNumber = isSession ? 3 : 4;
  console.log(state, number);
  const setMixPanel_start = useMixpanelEventAnxy({
    eventName: '알림 설정 안내',
    params: {
      타입: isSession ? '세션' : '걱정기록',
    },
  });

  useEffect(() => {
    setMixPanel_start();
  }, []);

  const handleLeft = () => {
    if (number > 1) {
      setState((state) => {
        const updated = { ...state };
        updated[propsName] = number - 1;
        return updated;
      });
    }
  };

  const handleRight = () => {
    if (number < maxNumber) {
      setState((state) => {
        const updated = { ...state };
        updated[propsName] = number + 1;
        return updated;
      });
    }
  };

  const RightButton = () => (
    <div
      onClick={handleRight}
      className="w-[44px] h-[44px] rounded-[100%] bg-white flex justify-center items-center"
      style={{
        opacity: isSession ? number === 3 : number === 4 ? 0.6 : 1,
        boxShadow: number !== maxNumber && '0px 6px 8px -2px rgba(0, 0, 0, 0.2)',
      }}
    >
      <svg
        className="ml-[2px]"
        width="10"
        height="17"
        viewBox="0 0 10 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M1 0.999999L9 8.5L1 16"
          stroke="#26282C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );

  const LeftButton = () => (
    <div
      onClick={handleLeft}
      className="relative left-0 w-[44px] h-[44px] rounded-[100%] bg-white flex justify-center items-center"
      style={{
        opacity: number === 1 ? 0.6 : 1,
        boxShadow: number !== 1 && '0px 6px 8px -2px rgba(0, 0, 0, 0.2)',
      }}
    >
      <svg
        className="mr-[2px]"
        width="10"
        height="17"
        viewBox="0 0 10 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M9 16L1 8.5L9 1"
          stroke="#26282C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );

  return (
    <TopTitleBottomActionAnxy
      title={title}
      subtitle={subtitle}
      content={
        <div className="px-[20px] pl-[31px] pr-[40px] h-full flex justify-center items-center ">
          <div className="w-full flex justify-between items-center relative">
            {number === 2 && (
              <div className="absolute left-[50%] translate-x-[-50%] bottom-[-44px] flex items-center justify-center bg-[#EF670B] rounded-[100px] w-[54px] h-[32px] text-white font-semibold text-[17px] leading-[24px] text-center">
                <p className="mb-[2px]">추천</p>
              </div>
            )}
            {isSession && (
              <div className="absolute left-[50%] translate-x-[-50%] bottom-[-76px] flex items-center justify-center">
                {isSession && (
                  <p className="whitespace-nowrap text-[17px] leading-[24px] text-[#26282C] font-light">
                    {`약 ${number === 1 ? 6 : number === 3 ? 2 : 3}주에 걸쳐 코스를 마칠 수 있어요`}
                  </p>
                )}
              </div>
            )}
            <LeftButton />
            <p className="text-[60px] leading-[71.6px] font-bold text-[#26282C]">{`${number}회`}</p>
            <RightButton />
          </div>
        </div>
      }
      buttonText={'다음'}
      buttonState={'ACTIVE'}
      action={action}
      bgColor="#F1EEEB"
      noGradient
    />
  );
}
