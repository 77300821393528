import { atom } from 'recoil';

export const MODAL_LOGOUT = 'LOGOUT';
export const MODAL_LEAVE = 'LEAVE';
export const MODAL_VIEWED = 'VIEWED';

const toolModalAtom = atom({
  key: 'tool_modal',
  default: {
    isModalVisible: false,
    modalType: '',
  },
});

export default toolModalAtom;
