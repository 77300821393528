import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import plane from '../image/plane.png';
import { getRefresh } from '../utils/webview';

function ErrorPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const bg = searchParams.get('bg');

  function refreshAction() {
    navigate(-1, { replace: true });
  }

  useEffect(() => {
    getRefresh(refreshAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={`${bg ? `#${bg}` : '#FFFFFF'} h-screen flex flex-col justify-center items-center`}>
        <div className="w-[375px] h-[230.63px] overflow-hidden">
          <img src={plane} alt="empty" className="w-full h-full object-contain" />
        </div>
        <p className="font-bold text-[24px] leading-[29px] mt-[1.38px]">데이터를 불러올 수 없어요</p>
        <p className="text-[#6B6D76] text-[17px] leading-[26px] mt-[8px] mb-[20px]">잠시 후 다시 시도해주세요.</p>
        <p
          className="bg-[#3953D9] text-white text-[15px] leading-[18px] rounded-[10px] py-[10px] px-[10px]"
          onClick={() => navigate(-1, { replace: true })}
        >
          다시 시도하기
        </p>
      </div>
    </div>
  );
}

export default ErrorPage;
