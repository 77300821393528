import React from 'react';
import { useNavigate } from 'react-router-dom';
import { showModal } from '../../utils/webview';

export default function Thumbnail(props) {
  const { id, image, category, title, subtitle, link } = props;
  const navigate = useNavigate();

  function goLink() {
    console.log(link);
    if (link === '') {
      navigate(`/content/${id}`);
    } else {
      showModal({ url: link, title: '심리상담, 우리 모두의 경험이 될 수 있도록' });
    }
  }

  return (
    <div
      className="w-screen select-none"
      style={{
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
        WebkitUserModify: 'none',
      }}
    >
      <div className="w-full px-20px pt-20px pb-30px">
        <div className="mb-[16px] ">
          <button className="w-full rounded-2xl active:bg-[#F3F5F8]" onClick={goLink}>
            <div
              className={`h-170px rounded-2xl  
              ${category === '인사이드 이야기' ? 'bg-[#EFF2FF]' : ''} 
              ${category === '인사이드가 만난 사람들' ? 'bg-[#EBF6FE]' : ''}
              ${category === '상담사 이야기' ? 'bg-[#F1EFFF]' : ''}
              ${
                category !== '인사이드 이야기' && category !== '인사이드가 만난 사람들' && category !== '상담사 이야기'
                  ? 'bg-[#FEF7EB]'
                  : ''
              }
              flex align-center `}
            >
              <img className=" m-auto h-140px " src={image} alt="empty" />
            </div>
          </button>
        </div>

        <div className="px-4px ">
          <button onClick={goLink} className="text-left w-full">
            <p className="text-category text-category font-semibold mb-6px ">{category}</p>
            <p className="font-semibold text-title leading-content mb-5px " style={{ wordBreak: 'keep-all' }}>
              {' '}
              {title}
            </p>
            <p className="text-content leading-content text-[#4E4F53]"> {subtitle}</p>
          </button>
        </div>
      </div>
    </div>
  );
}
