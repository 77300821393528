/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import anxySessionByType from '../../../data/anxy/anxySessionByType';
import userAtom from '../../../recoil/anxy/user/atom';
import VideoPlayer from './VideoPlayer';
import 불안관리의_필요성_정상 from '../../../data/anxy/videos/intro/_4_불안관리의_필요성_정상.mp4';
import 불안관리의_필요성_정상외 from '../../../data/anxy/videos/intro/_4_불안관리의_필요성_정상외.mp4';
import { useEffect } from 'react';
import webviewToast from '../../../development/webviewToast';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import { useSearchParams } from 'react-router-dom';

export default function VideoPlayerByStatus({ index, trialIndex, video, type, isFirst, isTrial }) {
  const userState = useRecoilValue(userAtom);
  const [searchParams] = useSearchParams();
  const testType = searchParams.get('type');

  const anxietyTypeId = testType ? parseInt(testType) : userState.anxietyTypeId;

  const [isAbnormal, setIsAbnormal] = useState(false);

  const [latestResult, getLatestResult] = useFetchAnxy({
    url: '/anxy/latest-result/list',
  });

  const contentVideo = type
    ? index
      ? anxySessionByType.find((content) => content.type === 2).videoList[index]
      : anxySessionByType.find((content) => content.type === anxietyTypeId).trialVideoList[trialIndex]
    : isAbnormal
    ? 불안관리의_필요성_정상외
    : 불안관리의_필요성_정상;

  useEffect(() => {
    if (latestResult) {
      if (latestResult.result) {
        const currentResult = latestResult.result[latestResult.result.length - 1];
        if (currentResult.score >= 10) {
          webviewToast('정상 외');
          setIsAbnormal(true);
        } else {
          webviewToast('정상');
          setIsAbnormal(false);
        }
      }
    }
  }, [latestResult]);

  useEffect(() => {
    if (type) {
      webviewToast('유저 타입별 영상: ' + anxietyTypeId);
    } else {
      getLatestResult();
    }
  }, []);
  return (
    <div>
      <VideoPlayer isFirst={isFirst} video={contentVideo} isTrial={isTrial} />
    </div>
  );
}
