import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hasTouch } from 'detect-touch';

export function RoundActionButton(props) {
  const { state, activeColor, inactiveColor, textColor, inactiveTextColor, text, action, small, width, disable } =
    props;
  const [buttonDown, setButtonDown] = useState(false);

  function mouseUp(e) {
    setButtonDown(false);
  }
  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });
  return (
    <div className={`w-full h-full bg-white rounded-[50px] `}>
      <div
        style={{
          backgroundColor:
            state !== 'INACTIVE'
              ? activeColor === '#E1E7FF' && state === 'LOADING'
                ? '#F6F8FF'
                : activeColor
              : inactiveColor,
          color: state !== 'INACTIVE' ? textColor : inactiveTextColor || '#A4A6B0',
          border: activeColor === '#E1E7FF' && '1px solid #00000005',
          width: width,
        }}
        className={`${
          width ? '' : 'px-[60px]'
        } w-full  rounded-[50px]  relative flex flex-col items-center justify-center
      ${small ? 'h-[44px] font-semibold text-[16px] leading-[22px]' : 'h-[54px] font-bold text-[17px] leading-[26px]'}
    ${
      (state === 'ACTIVE' && buttonDown) || (activeColor !== '#E1E7FF' && state === 'LOADING') || state === 'DONE'
        ? 'opacity-50'
        : 'opacity-100'
    }
    `}
        onMouseDown={(e) => {
          if (!hasTouch && !disable) {
            setButtonDown(true);
          }
        }}
        onClick={(e) => {
          if (state === 'ACTIVE') {
            action();
          }
        }}
        onTouchStart={(e) => {
          if (!disable) {
            setButtonDown(true);
          }
        }}
        onTouchEnd={(e) => {
          setButtonDown(false);
        }}
      >
        {state !== 'LOADING' && state !== 'DONE' && <p className="mb-[1px] whitespace-nowrap">{text}</p>}

        {state === 'LOADING' && (
          <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center">
            <div className={`flex items-center animate-spin`}>
              {!small && (
                <svg
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  stroke={textColor}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.5 11C21.5 12.8442 20.99 14.6525 20.0264 16.225C19.0628 17.7974 17.6831 19.0728 16.0399 19.9101C14.3967 20.7473 12.5539 21.1139 10.7154 20.9692C8.87687 20.8245 7.11415 20.1742 5.62215 19.0902C4.13014 18.0062 2.96696 16.5307 2.2612 14.8268C1.55545 13.123 1.33462 11.2572 1.62312 9.43566C1.91162 7.61414 2.69821 5.90788 3.89594 4.50552C5.09367 3.10317 6.65587 2.05934 8.40983 1.48944"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              )}
              {small && (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  stroke={textColor}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.6818 9C17.6818 10.5089 17.2645 11.9884 16.4761 13.275C15.6877 14.5615 14.5589 15.605 13.2145 16.29C11.87 16.9751 10.3623 17.275 8.85806 17.1566C7.3538 17.0382 5.91158 16.5061 4.69085 15.6192C3.47011 14.7323 2.51842 13.5251 1.94098 12.131C1.36355 10.737 1.18287 9.21041 1.41891 7.72008C1.65496 6.22975 2.29854 4.83372 3.27849 3.68633C4.25845 2.53895 5.53662 1.68491 6.97167 1.21863"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </div>
          </div>
        )}
        {state === 'DONE' && (
          <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center">
            <div className="flex items-center">
              {!small && (
                <svg width="19" height="12" viewBox="0 0 19 12" fill={textColor} xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.19597 3.97424C1.7994 3.58986 1.16631 3.59976 0.781932 3.99633C0.397557 4.39291 0.407449 5.026 0.804027 5.41038L2.19597 3.97424ZM7.59524 10.6L6.89926 11.3181C7.28711 11.694 7.90337 11.694 8.29121 11.3181L7.59524 10.6ZM18.196 1.71807C18.5926 1.33369 18.6024 0.700605 18.2181 0.304027C17.8337 -0.0925511 17.2006 -0.102443 16.804 0.281932L18.196 1.71807ZM0.804027 5.41038L6.89926 11.3181L8.29121 9.88193L2.19597 3.97424L0.804027 5.41038ZM8.29121 11.3181L18.196 1.71807L16.804 0.281932L6.89926 9.88193L8.29121 11.3181Z" />
                </svg>
              )}
            </div>{' '}
          </div>
        )}
      </div>
    </div>
  );
}
export function PrimaryRoundActionButton(props) {
  const { state, text, action, small, medium } = props;
  return (
    <RoundActionButton
      state={state}
      activeColor={'#0C6FF9'}
      inactiveColor={'#E1E4EB'}
      textColor={'#FFFFFF'}
      text={text}
      action={action}
      small={small}
      medium={medium}
    />
  );
}
export function BlackRoundActionButton(props) {
  const { state, text, action, small, medium, width, disable } = props;
  return (
    <RoundActionButton
      state={state}
      activeColor={'#26282C'}
      textColor={'#FFFFFF'}
      text={text}
      action={action}
      small={small}
      medium={medium}
      width={width}
      disable={disable}
    />
  );
}

export function SessionBlackRoundActionButton(props) {
  const { state, text, action, small, medium, width } = props;
  return (
    <RoundActionButton
      state={state}
      activeColor={'#000000'}
      textColor={'#FFFFFF'}
      text={text}
      action={action}
      small={small}
      medium={medium}
      width={width}
    />
  );
}

export function WhiteRoundActionButton(props) {
  const { state, text, action, small, medium, width } = props;
  return (
    <RoundActionButton
      state={state}
      activeColor={'#FFFFFF'}
      textColor={'#111A43'}
      text={text}
      action={action}
      small={small}
      medium={medium}
      width={width}
    />
  );
}

export function SomnyActionButton(props) {
  const { state, text, action, small, black } = props;
  return (
    <RoundActionButton
      state={state}
      activeColor={black ? '#060727' : '#E1E7FF'}
      inactiveColor={'#EEF1F5'}
      textColor={black ? '#E1E7FF' : '#121B40'}
      text={text}
      action={action}
      small={small}
    />
  );
}

export function LoomyActionButton(props) {
  const { state, text, action, small } = props;
  return (
    <RoundActionButton
      state={state}
      activeColor={'#000000'}
      inactiveColor={'#D1D5DC'}
      textColor={'#FFFFFF'}
      inactiveTextColor={'#A4A6B0'}
      text={text}
      action={action}
      small={small}
    />
  );
}

RoundActionButton.propTypes = {
  state: PropTypes.oneOf(['ACTIVE', 'INACTIVE', 'LOADING', 'DONE']),
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.func,
  small: PropTypes.bool,
};
