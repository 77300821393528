/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { MultipleSelectButton } from '../../stories/button/MultipleSelectButton';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import { close } from '../../utils/webview';
import { changeHeader } from '../../utils/webview';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useFetch from '../../hooks/useFetch';
import { CoachingContext } from '../../coachingContext';

export default function MultipleSelectTemplate(props) {
  const context = useContext(CoachingContext);
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [channelList, setChannelList] = useState([]);

  const [answer, setAnswer] = useState([]);

  useEffect(() => {
    changeHeader({ backgroundColor: '#FFFFFF', text: '', buttonType: 'none' });
    getMarketingChannels();
  }, []);

  function select(option) {
    const selected = option;
    if (answer.includes(selected)) {
      let filteredArray = answer.filter((item) => item !== selected);
      setAnswer(filteredArray);
    } else {
      setAnswer((answer) => [...answer, selected]);
    }
  }

  function isClicked(option) {
    if (option !== '') {
      console.log('이미클릭함');
      select(option);
    }
  }

  const [marketingChannels, getMarketingChannels] = useFetch({
    url: `survey/acquisition-channel/list`,
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (marketingChannels) {
      console.log('채널', marketingChannels.result.acquisitionChannelList);
      setChannelList(marketingChannels.result.acquisitionChannelList);
    }
  }, [marketingChannels]);

  const [submitResult, submitMarketingChannel] = useFetch({
    url: `/survey/acquisition-channel/submit`,
    requestBody: JSON.stringify({ acquisitionChannelList: answer }),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (submitResult) {
      close({ refresh: true });
      console.log('유입채널 제출', submitResult);
    }
  }, [submitResult]);

  const goNext = useNavigateWithParams('/marketingReason');

  function submit() {
    context.setAcquisitionChannel(answer);
    if (answer.includes('지인 추천')) {
      goNext();
    } else {
      submitMarketingChannel();
    }
  }

  useEffect(() => {
    if (answer.length !== 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [answer]);

  const Element = (
    <div className={`h-full  pt-[43px] pb-[30px] px-[20px] `}>
      {channelList.map((option, index) => (
        <MultipleSelectButton
          key={index}
          selected={answer.includes(option)}
          option={option}
          text={option}
          isClicked={isClicked}
        />
      ))}
    </div>
  );

  return (
    <div className={`w-screen h-screen bg-white overflow-hidden `}>
      <div className={`h-full `}>
        <TopTitleBottomAction
          title={'인사이드를 어떻게 알게 되었나요?'}
          content={Element}
          buttonText={'확인'}
          buttonState={buttonState}
          action={submit}
        />
      </div>
    </div>
  );
}
