import React, { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
import MessageBlockWithTail from '../../stories/workbook/MessageBlockWithTail';

function UserAnswerGrayMessage2({ index }) {
  const context = useContext(CoachingContext);

  return (
    <div className="">
      {context.workbookAnswer && (
        <div className="mt-40px mb-[30px]">
          <p className="">여러분은 두려움을 느낄 때면 아래와 같이 느끼며</p>
          <MessageBlockWithTail type="gray" textList={[[context.workbookAnswer[0]]]} my />
          <p className="my-[14px]">전력질주를 했을 때의 신체반응은 </p>
          <MessageBlockWithTail type="gray" textList={[[context.workbookAnswer[1]]]} my />
        </div>
      )}
    </div>
  );
}

export default UserAnswerGrayMessage2;
