/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import { BottomActionAnxy } from 'stories/page/BottomActionAnxy';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from 'recoil/common/header/atom';
import useFetchAnxy from 'hooks/useFetchAnxy';
import TokenTest from 'development/TokenTest';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import WorryNote from '../worryNote/WorryNote';
import progressBarAtom from 'recoil/common/progressBar/atom';
import anxyTypes from 'image/anxy/type/anxyTypes.png';
import woriHi from 'image/anxy/wori/woriHi.png';
import homeTypeAtom from 'recoil/anxy/home/atom';

export default function AnxyOnboarding(props) {
  let { id } = useParams();
  id = parseInt(id);

  const [searchParams] = useSearchParams();
  const at = searchParams.get('at');
  const uiType = searchParams.get('uiType');
  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const setHomeType = useSetRecoilState(homeTypeAtom);

  const [checkpointSaveData, checkpointSave] = useFetchAnxy({
    url: `anxy/v2/onboarding/checkpoint/save`,
    requestBody: { checkpointId: 2 },
    bg: '#FFFFFF',
  });

  const goNext = useNavigateWithParams(`/anxyOnboarding/${id + 1}`);
  const goTest = useNavigateWithParams(`/anxyTest/1`);

  useEffect(() => {
    if (id === 0) {
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_BACK,
        progressBar: { maxCompleted: 17, completed: id + 17 },
      }));
    } else {
      setHeaderState((state) => ({
        ...state,
        headerButtonType: BUTTON_BACK,
        progressBar: undefined,
      }));
    }
    setHomeType('worri');
    setProgressBarState({ isProgressBarVisible: false, maxCompleted: 0, completed: 0 });
  }, []);

  return id === 0 ? (
    <BottomActionAnxy
      content={
        <div className="w-full h-full flex flex-col ">
          {/* <div className="fixed top-[100px]">
            <TokenTest />
          </div> */}
          <div className="px-[20px] pt-[12px] text-[24px] leading-[30px] font-bold">
            {uiType === 'b' ? '그럼 먼저 나의 불안 타입을 알아볼게요' : '자, 그럼 Wori를 만나며 시작해볼게요'}
          </div>
          <div className={`flex-1 h-full relative`}>
            {uiType === 'b' ? (
              <img
                src={anxyTypes}
                alt={'empty'}
                className="absolute px-[20px] py-[20px] top-0 w-full h-full object-contain"
              />
            ) : (
              <img
                src={woriHi}
                alt={'empty'}
                className="absolute max-w-[280px] py-[20px] top-0 right-0 w-full h-full object-contain object-right"
              />
            )}
          </div>
        </div>
      }
      noGradient
      buttonText={uiType === 'b' ? '타입 검사하기' : '다음'}
      action={() => {
        setHeaderState((state) => ({
          ...state,
          progressBar: undefined,
        }));
        if (uiType === 'b') {
          goTest();
        } else {
          goNext();
        }
      }}
      bgColor={anxyColorPalette.white}
      fixedRoot={at === undefined}
    />
  ) : (
    <WorryNote />
  );
}
