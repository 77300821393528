import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import useInterval from '../../hooks/useInterval';
import { useState } from 'react';
import webviewToast from '../../development/webviewToast';
import { impactFeedback } from '../../utils/webview';

export function BodyLottie(props) {
  const { containerSize, isStop } = props;
  const [sec, setSec] = useState(0);
  const likecontainer = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: isStop ? false : true,
      animationData: require('./activity_body.json'),
    });
    return () => instance.destroy();
  }, []);

  useEffect(() => {
    if (sec >= 5 && sec <= 9) {
      impactFeedback('selection');
    }
    if (sec >= 11 && sec <= 15) {
      impactFeedback('selection');
    }
  }, [sec]);

  const timerId = useInterval(
    () => {
      if (sec > 16) {
        clearInterval(timerId);
      } else {
        setSec((prev) => prev + 1);
      }
    },
    1000,
    [],
  );

  return (
    <div>
      <div style={{ width: containerSize, height: containerSize }} ref={likecontainer}></div>
    </div>
  );
}
