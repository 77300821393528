import React, { useState, useEffect, useRef } from 'react';
import UserEmotionInputForm from './UserEmotionInputForm';
import emotionSituationData from '../../data/emotionSituationData';
import { useContext } from 'react';
import { CoachingContext } from '../../coachingContext';
import { SecondaryActionButton } from '../../stories/button/ActionButton';
import { useParams } from 'react-router-dom';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

const UserEmotionInput = ({ emotion, buttonText, bottomText }) => {
  const { id, page } = useParams();
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);

  const [answer, setAnswer] = useState([]);

  const context = useContext(CoachingContext);
  const scrollRef = useRef();
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  const emotionInfo = emotionSituationData.filter((item) => item.emotion === emotion)[0].placeholderList;
  const emotionList =
    context.workbookAnswer.length !== 0 &&
    context.workbookAnswer.filter((item) => item.emotion === emotion)[0].situationList.filter((item) => item.word);

  function updateSituation(option) {
    const selected = option;

    // answer에 단어가 없을 때
    if (answer.filter((item) => item.word === selected.word).length !== 0) {
      console.log('겹침');
      const updatedAnswer = answer.filter((item) => item.word !== selected.word);
      const updated = answer.filter((item) => item.word === selected.word)[0];
      updated.situation = selected.situation;
      setAnswer((answer) => [...updatedAnswer, updated]);
    } else {
      setAnswer((answer) => [...answer, selected]);
    }
  }

  useEffect(() => {
    console.log('answer is ', answer);

    const submitList = answer.filter((item) => item.situation !== '');
    console.log(submitList);
    if (submitList.length === emotionList.length) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [answer]);

  const goNextPage = () => {
    const updated = context.workbookAnswer.map((item) =>
      item.emotion === emotion ? { ...item, situationList: answer } : item,
    );
    console.log(updated);
    context.setWorkbookAnswer(updated);
    goNext();
  };

  useEffect(() => {
    setViewportSize(window.innerHeight);
  }, []);

  return (
    <div className={`w-full px-20px overflow-hidden relative`} style={{ height: viewportSize - 17 }}>
      <div ref={scrollRef} className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="flex justify-center mt-[50px] mb-[40px]">
          <div className="flex flex-1 flex-col relative">
            <p className="font-light text-[17px] leading-[28px] text-[#3A3C40]">
              이번엔 언제 그러한 감정을 느꼈는지 표현해 볼까요?
            </p>
            {emotionList && emotionList.length !== 0 && (
              <div className="mt-[40px] mb-[10px]">
                {emotionList.map((item, index) => (
                  <UserEmotionInputForm
                    info={emotionInfo[index]}
                    emotion={emotion}
                    word={item.word}
                    list={item}
                    updateSituation={updateSituation}
                  />
                ))}
              </div>
            )}
            {bottomText &&
              bottomText.map((text) => <p className="font-light text-[17px] leading-[28px] text-[#3A3C40]">{text}</p>)}
          </div>
        </div>
        <div className={`w-full rounded-[16px] ${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'}`}>
          <SecondaryActionButton state={buttonState} text="다음" action={goNextPage} />
        </div>
      </div>
    </div>
  );
};

export default UserEmotionInput;
