import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { CheckLottie } from '../../../stories/animation/CheckLottie';
import { TopTitleBottomActionAnxy } from '../../../stories/page/TopTitleBottomActionAnxy';

export default function CongratsWithCheckLottie({ title, action }) {
  const container = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('../../../image/congrats_particle.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <TopTitleBottomActionAnxy
      content={
        <div className="my-auto mx-auto flex flex-col items-center">
          <div className=" w-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] pointer-events-none z-[40]">
            <div className="h-full" ref={container} />
          </div>
          <CheckLottie green containerSize="77px" />
          <p className="pt-[30px] text-[#26282C] text-[24px] leading-[28.8px] font-bold">{title}</p>
        </div>
      }
      buttonText={'다음'}
      buttonState={'ACTIVE'}
      action={action}
      bgColor="#F1EEEB"
    />
  );
}
