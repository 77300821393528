import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import webviewToast from '../../../development/webviewToast';

export default function TimePicker({ hour, minute, getHour, getMinute }) {
  const nowTime = moment(new Date());

  const hours = Array.from({ length: 24 }, (v, i) => (i < 10 ? `0${i}` : `${i}`));
  const minutes = ['00', '10', '20', '30', '40', '50'];
  const repeatNum = 50;
  const repeatedHours = Array(repeatNum).fill(hours).flat();
  const repeatedMinutes = Array(repeatNum).fill(minutes).flat();
  const [hourIdx, setHourIdx] = useState(hours.length * (repeatNum / 2) + parseInt(hour));
  const [minuteIdx, setMinuteIdx] = useState(minutes.length * (repeatNum / 2) + parseInt(minute * 0.1));
  const numberHeight = 63;

  const hourPickerRef = useRef();
  const minutePickerRef = useRef();

  console.log(hour, minute);

  useEffect(() => {
    console.log(hourIdx);

    setTimeout(() => {
      if (hourPickerRef.current) {
        hourPickerRef.current.scrollTop = numberHeight * (hourIdx - 1);
      }
      if (minutePickerRef.current) {
        minutePickerRef.current.scrollTop = numberHeight * (minuteIdx - 1);
      }
    }, 0);
  }, []);

  useEffect(() => {
    getHour(hours[hourIdx % hours.length]);
  }, [hourIdx]);

  useEffect(() => {
    getMinute(minutes[minuteIdx % minutes.length]);
  }, [minuteIdx]);

  return (
    <div
      className={`w-full h-[189px] text-center flex justify-center gap-[32px] relative`}
      height={repeatNum * numberHeight}
    >
      <div className=" absolute top-0  w-full px-[20px] h-[63px] bg-white opacity-90 z-[10] pointer-events-none" />
      <div className=" absolute top-[126px]  w-full px-[20px] h-[63px] bg-white opacity-90 z-[10] pointer-events-none" />
      <div className="absolute top-[50%] translate-y-[-50%] w-full px-[20px] h-[63px] ">
        <div className="w-full h-full rounded-[12px] bg-[#F3F5F8]" />
        <p className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] font-bold text-[30px] leading-[36px] pb-[3px]">
          :
        </p>
      </div>
      <div
        className="w-full h-full overflow-auto snap-y snap-proximity relative scrollbar-hide relative "
        ref={hourPickerRef}
        onScroll={(e) => {
          setHourIdx(parseInt((e.target.scrollTop + numberHeight / 2) / numberHeight) + 1);
        }}
      >
        {repeatedHours.map((hour, index) => (
          <div
            key={`hour${index}`}
            className="flex justify-end items-center h-[63px] text-[30px] leading-[40px] font-bold py-[5px] snap-center  "
          >
            <div className="w-[40px] flex justify-center">{hour}</div>
          </div>
        ))}
      </div>
      <div
        className="w-full h-full overflow-auto snap-y snap-proximity relative ml-[1px] scrollbar-hide"
        ref={minutePickerRef}
        onScroll={(e) => {
          setMinuteIdx(parseInt((e.target.scrollTop + numberHeight / 2) / numberHeight) + 1);
        }}
      >
        {repeatedMinutes.map((min, index) => (
          <div
            key={`min${index}`}
            style={{}}
            className="flex w-[40px] justify-center items-center h-[63px] text-[30px] leading-[40px] font-bold py-[5px] snap-center "
          >
            {min}
          </div>
        ))}
      </div>
    </div>
  );
}
