import type1 from '../../image/betterme/type1.png';
import type2 from '../../image/betterme/type2.png';
import type3 from '../../image/betterme/type3.png';
import type4 from '../../image/betterme/type4.png';

const betterMeTypeData = [
  {
    type: 1,
    name: '식이장애로 인해 \n 고통받고 있어요',
    summary: [
      '미래에 무슨 일이 일어날 것만 같아 불안해요',
      '무방비 상태에서 나쁜 일을 겪을까 두려워해요',
      '앞으로 일어날 일 때문에 항상 긴장해 있어요',
    ],

    image: type4,
    bgColor: '#FCE7D8',
    explanation: `검사 결과, 식사 행동이 지나치게 불규칙하거나 음식, 체중에 대한 집착으로 자신감이나 일상의 많은 부분에 영향을 받고 있을 가능성이 높아요. 지금이라도 식사행동 패턴을 점검하고 장기적이고 건강한 방향으로 내 몸을 관리할 준비를 해야 합니다. 다이어트 강박의 악순환이 길어지면 몸과 마음이 더 힘들어질 수도 있어요.

    식이장애는 주로 체형과 외모에 대한 민감성에서 출발하여 신체적 폭식, 정서적 폭식, 그리고 스트레스성 폭식에 의해 증폭돼요. 신체적 폭식은 과도한 다이어트를 하다가 몸이 따라오지 않아 식욕이 용수철처럼 튀어오르는 것이에요. 한 마디로 배가 너무 고파서 먹게 되는 것이죠. 반면 정서적 폭식은 스트레스, 우울감 등과 같은 감정에 의해 폭식으로 그러한 감정을 보상하는 것이에요. 즉, 마음이 힘들어서 먹게 되는 것이죠. 스트레스성 폭식은 과도한 스트레스를 완화하기 위해 폭식으로 보상하는 것을 말해요. 
    
    이와 같은 폭식이 발생하고 나면 살이 찌고, 그러면 또 다시 다이어트를 열심히 해보겠다고 다짐합니다. 하지만 역시 신체적 폭식, 정서적 폭식, 스트레스성 폭식으로 인해 다이어트를 실패하게 되죠. 결국엔 다이어트의 악순환이 반복됩니다.
    
    Better Me는 식이장애에서 벗어날 수 있는 인지치료, 무리한 다이어트에서 벗어나 건강한 식습관을 가질 수 있는 행동치료, 그리고 현재 내 감정을 그대로 받아들이는 마인드풀니스 프로그램을 제공하고 있어요. 우리 사회의 과도한 체형 기준에 집착하지 않도록 도와주는 다양한 컨텐츠들도 준비되어 있답니다.`,
  },
  {
    type: 2,
    name: '중간 정도의 식이장애를 \n 가지고 있어요',
    summary: [
      '미래에 무슨 일이 일어날 것만 같아 불안해요',
      '무방비 상태에서 나쁜 일을 겪을까 두려워해요',
      '앞으로 일어날 일 때문에 항상 긴장해 있어요',
    ],

    image: type3,
    bgColor: '#FCE7D8',
    explanation: `검사 결과, 중간 정도의 식이장애 증상이 보입니다. 반복되는 자기 검열로 인해 음식, 체중에 대한 집착이 점점 커지고 있어요. 더 심한 증상으로 발전하기 전에 현재의 식사 패턴을 점검하고 내 몸에 대한 건강한 인식이 다시 자리 잡을 수 있게 해야 해요.

    식이장애는 주로 체형과 외모에 대한 민감성에서 출발하여 신체적 폭식, 정서적 폭식, 그리고 스트레스성 폭식에 의해 증폭돼요. 신체적 폭식은 과도한 다이어트를 하다가 몸이 따라오지 않아 식욕이 용수철처럼 튀어오르는 것이에요. 한 마디로 배가 너무 고파서 먹게 되는 것이죠. 반면 정서적 폭식은 스트레스, 우울감 등과 같은 감정에 의해 폭식으로 그러한 감정을 보상하는 것이에요. 즉, 마음이 힘들어서 먹게 되는 것이죠. 스트레스성 폭식은 과도한 스트레스를 완화하기 위해 폭식으로 보상하는 것을 말해요. 
    
    이와 같은 폭식이 발생하고 나면 살이 찌고, 그러면 또 다시 다이어트를 열심히 해보겠다고 다짐합니다. 하지만 역시 신체적 폭식, 정서적 폭식, 스트레스성 폭식으로 인해 다이어트를 실패하게 되죠. 결국엔 다이어트의 악순환이 반복됩니다.
    
    Better Me는 식이장애에서 벗어날 수 있는 인지치료, 무리한 다이어트에서 벗어나 건강한 식습관을 가질 수 있는 행동치료, 그리고 현재 내 감정을 그대로 받아들이는 마인드풀니스 프로그램을 제공하고 있어요. 우리 사회의 과도한 체형 기준에 집착하지 않도록 도와주는 다양한 컨텐츠들도 준비되어 있답니다.`,
  },
  {
    type: 3,
    name: '식이장애로 넘어가는 \n 경계에 서 계시네요',
    summary: [
      '미래에 무슨 일이 일어날 것만 같아 불안해요',
      '무방비 상태에서 나쁜 일을 겪을까 두려워해요',
      '앞으로 일어날 일 때문에 항상 긴장해 있어요',
    ],

    image: type2,
    bgColor: '#FCE7D8',
    explanation: `검사 결과, 음식이나 체중, 그리고 외모에 대한 민감성이 시사돼요. 식사행동에 불안정한 면이 있을 가능성도 높습니다. 이런 경우에는 식사행동 패턴을 점검해 보고 보다 장기적이고 건강한 방향으로 내 몸을 관리하기 위한 준비를 시작하는 게 중요하답니다.

    식이장애는 주로 체형과 외모에 대한 민감성에서 출발하여 신체적 폭식, 정서적 폭식, 그리고 스트레스성 폭식에 의해 증폭돼요. 신체적 폭식은 과도한 다이어트를 하다가 몸이 따라오지 않아 식욕이 용수철처럼 튀어오르는 것이에요. 한 마디로 배가 너무 고파서 먹게 되는 것이죠. 반면 정서적 폭식은 스트레스, 우울감 등과 같은 감정에 의해 폭식으로 그러한 감정을 보상하는 것이에요. 즉, 마음이 힘들어서 먹게 되는 것이죠. 스트레스성 폭식은 과도한 스트레스를 완화하기 위해 폭식으로 보상하는 것을 말해요. 
    
    이와 같은 폭식이 발생하고 나면 살이 찌고, 그러면 또 다시 다이어트를 열심히 해보겠다고 다짐합니다. 하지만 역시 신체적 폭식, 정서적 폭식, 스트레스성 폭식으로 인해 다이어트를 실패하게 되죠. 결국엔 다이어트의 악순환이 반복됩니다.
    
    Better Me는 식이장애에서 벗어날 수 있는 인지치료, 무리한 다이어트에서 벗어나 건강한 식습관을 가질 수 있는 행동치료, 그리고 현재 내 감정을 그대로 받아들이는 마인드풀니스 프로그램을 제공하고 있어요. 우리 사회의 과도한 체형 기준에 집착하지 않도록 도와주는 다양한 컨텐츠들도 준비되어 있답니다.`,
  },
  {
    type: 4,
    name: '현재의 생활 습관을 \n 꾸준히 점검해 주세요',
    summary: [
      '미래에 무슨 일이 일어날 것만 같아 불안해요',
      '무방비 상태에서 나쁜 일을 겪을까 두려워해요',
      '앞으로 일어날 일 때문에 항상 긴장해 있어요',
    ],

    image: type1,
    bgColor: '#FCE7D8',
    explanation: `검사 결과, 음식과 체형에 대한 민감성이 심하다고 할 수는 없을 것 같아요. 하지만 오랜 기간, 그리고 열정적으로 다이어트를 진행하고 있을수록 식이장애가 발생할 가능성이 높으니 방심은 금물이랍니다.

    식이장애는 주로 체형과 외모에 대한 민감성에서 출발하여 신체적 폭식, 정서적 폭식, 그리고 스트레스성 폭식에 의해 증폭돼요. 신체적 폭식은 과도한 다이어트를 하다가 몸이 따라오지 않아 식욕이 용수철처럼 튀어오르는 것이에요. 한 마디로 배가 너무 고파서 먹게 되는 것이죠. 반면 정서적 폭식은 스트레스, 우울감 등과 같은 감정에 의해 폭식으로 그러한 감정을 보상하는 것이에요. 즉, 마음이 힘들어서 먹게 되는 것이죠. 스트레스성 폭식은 과도한 스트레스를 완화하기 위해 폭식으로 보상하는 것을 말해요. 
    
    이와 같은 폭식이 발생하고 나면 살이 찌고, 그러면 또 다시 다이어트를 열심히 해보겠다고 다짐합니다. 하지만 역시 신체적 폭식, 정서적 폭식, 스트레스성 폭식으로 인해 다이어트를 실패하게 되죠. 결국엔 다이어트의 악순환이 반복됩니다.
    
    Better Me는 식이장애에서 벗어날 수 있는 인지치료, 무리한 다이어트에서 벗어나 건강한 식습관을 가질 수 있는 행동치료, 그리고 현재 내 감정을 그대로 받아들이는 마인드풀니스 프로그램을 제공하고 있어요. 우리 사회의 과도한 체형 기준에 집착하지 않도록 도와주는 다양한 컨텐츠들도 준비되어 있답니다.`,
  },
];

export default betterMeTypeData;
