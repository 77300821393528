import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import webviewToast from '../../development/webviewToast';
import { impactFeedback } from '../../utils/webview';

export function FocusingLottie(props) {
  const { containerSize } = props;
  const likecontainer = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('./focusing.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <div
      onClick={() => {
        impactFeedback('selection');
      }}
    >
      <div style={{ width: containerSize || '50px', height: containerSize || '50px' }} ref={likecontainer}></div>
    </div>
  );
}
