import React, { useEffect, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import './bottomSheetModal.css';

export default function BottomSheetModal({ content, modalState, setModalState, bgColor }) {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef();
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  useEffect(() => {
    if (modalState.isModalVisible) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [modalState.isModalVisible]);

  return (
    <div className="relative">
      {modalState.isModalVisible && (
        <div
          className={`bg-black opacity-[0.8] fixed top-[0] left-[0] w-screen h-screen  z-[200]`}
          onClick={() => {
            setModalState((state) => ({ ...state, isModalVisible: false }));
          }}
        />
      )}
      <Sheet
        ref={modalRef}
        isOpen={isOpen}
        onClose={() => {
          setModalState((state) => ({ ...state, isModalVisible: false }));
        }}
        springConfig={{ stiffness: 150, damping: 10, mass: 0.1 }}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Content>
            <div
              className={`h-full w-screen px-[20px] pt-[28px] text-[15px] leading-[18px] relative  ${marginClassName}`}
              style={{
                backgroundColor: bgColor ? bgColor : '',
                borderRadius: '20px 20px 0 0',
              }}
            >
              <div className="absolute w-[45px] h-[5px] rounded-[10px] bg-[#D6D8DC] top-[7px] left-[50%] translate-x-[-50%]" />
              {content}
            </div>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </div>
  );
}
