import React from 'react';
import { TextWithBold } from './TextWithBold';

const FinishEmotionSituation = () => {
  return (
    <div className="text-[#4E4F53] ">
      <TextWithBold
        texts={[
          '감정이 뒤죽박죽 얽혀있다면, 그 얽혀있는 감정의 덩어리를 바라보세요. 그리고 무엇이 되었든, 느낄 수 있는 감정에 하나씩 이름을 붙여보세요. 슬픔, 기쁨, 억울함, 분노. 무엇이든 좋아요.',
          '\n',
          '그리고 하루에 10분 정도만 활용하여 ',
          '감정노트',
          '에 나의 감정을 기록해 보세요. ',
          '나의 감정을 알아차리고 기록하는 것만으로도 표출되지 못하고 쌓인 감정을 털어내는 데 큰 도움이 될 것',
          '이랍니다.',
          '\n',
          '하지만 감정도 잘 알아차릴 수 있어야 더 쉽게 기록하겠죠? 다음 시간에는 감정을 쉽게 알아차릴 수 있는 단서인 ',
          '신체 감각',
          '에 대해 이야기해 볼 거예요.',
        ]}
      />
    </div>
  );
};

export default FinishEmotionSituation;
