import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Test from './Test';

export default function TokenTest() {
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  return (
    <Test
      element={
        <div className="text-[10px] text-black z-[50]">
          <textarea
            id="textArea"
            onClick={() => {
              const content = document.getElementById('textArea');
              content.select();
              document.execCommand('copy');
            }}
            defaultValue={authToken}
          />
        </div>
      }
    />
  );
}
