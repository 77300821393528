import React, { useState, useEffect, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { PressedEffect } from '../../stories/button/PressedEffect';
import selfCheckData from '../../data/selfCheckData';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export default function SelfCheckCard(props) {
  const { selfCheckInfo } = props;

  const [isSoonClicked, setIsSoonClicked] = useState(false);

  const cardRef = useRef();

  const today = new Date();
  const lastCheckDate = new Date(selfCheckInfo.date);
  var difference = Math.abs(today - lastCheckDate);
  const days = parseInt(difference / (1000 * 3600 * 24));

  const latestCheck =
    days === 0 ? '오늘' : days === 1 ? '어제' : days < 7 ? `${days}일 전` : `${parseInt(days / 7)}주 전`;

  const showOtherSelfCheck = useNavigateWithParams(`/mindScan/${selfCheckInfo.selfCheckId}/0`);

  const onClick = () => {
    console.log('showOtherSelfCheck');
    showOtherSelfCheck();
  };

  useEffect(() => {
    if (isSoonClicked) {
      setTimeout(() => {
        setIsSoonClicked(false);
      }, 1000);
    }
  }, [isSoonClicked]);

  return (
    <PressedEffect
      element={
        <div className={`w-full `}>
          <div
            ref={cardRef}
            className={`w-full  w-[150px] h-[210px]  rounded-[12px]  pt-[20px] ${
              selfCheckInfo.date ? 'pb-[20px]' : 'pb-[10px]'
            } flex flex-col justify-between
            ${selfCheckInfo.isAbnormal ? 'bg-[#FFF2ED]' : 'bg-[#EFF2FF]'}`}
          >
            <div className="w-full px-[20px] text-center">
              <p className={`text-[17px] leading-[23px] font-bold text-[#26282C]`}>{selfCheckInfo.selfCheckName}</p>

              {selfCheckInfo.date && (
                <p
                  className={`text-[15px] leading-[21px] font-normal ${
                    selfCheckInfo.isAbnormal ? 'text-[#EF3E3E]' : 'text-[#2C4BEC]'
                  }`}
                >{`${latestCheck} 검사`}</p>
              )}
            </div>
            {!selfCheckInfo.date && (
              <div className="w-full">
                <img
                  src={selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).image}
                  alt="empty"
                />
              </div>
            )}

            <div className="w-[70%] mx-auto  relative">
              {selfCheckInfo.date && (
                <CircularProgressbar
                  styles={buildStyles({
                    rotation: -0.375,
                    pathColor: selfCheckInfo.isAbnormal ? '#EF3E3E' : '#2C4BEC',
                    trailColor: 'rgba(0,0,0,0.05)',
                    strokeLinecap: 'round',
                  })}
                  strokeWidth={12}
                  circleRatio={0.75}
                  value={selfCheckInfo.scorePercentage || 0}
                />
              )}
              <div
                className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[14px] font-bold whitespace-nowrap ${
                  selfCheckInfo.isAbnormal ? 'text-[#EF3E3E]' : 'text-[#2C4BEC]'
                }`}
              >
                {selfCheckInfo.diagnosisSummary}
              </div>
            </div>
          </div>
        </div>
      }
      action={onClick}
    />
  );
}
