import trial2_5 from '../../../image/anxy/intro/trial2_5.png';
import trial2_6 from '../../../image/anxy/intro/trial2_6.png';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useEffect } from 'react';
import SubtractAnxy from '../SubtractAnxy';

export default function IntroMonster() {
  const [isBefore, setIsBefore] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsBefore(false);
    }, 2200);
  }, []);
  return (
    <motion.div key={isBefore} className="flex flex-col justify-center">
      <div className={`relative h-[332px] w-[127.26px] flex justify-center`}>
        <AnimatePresence exitBeforeEnter>
          <motion.img
            key={isBefore}
            alt="empty"
            src={isBefore ? trial2_5 : trial2_6}
            className={`absolute ${!isBefore && 'bottom-[123.71px]'}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            exit={{ opacity: 0 }}
          />
        </AnimatePresence>

        <div className="absolute bottom-[0] flex flex-col items-center">
          <motion.p
            className="font-bold text-[18px] leading-[24px] opacity-[0.7] text-white pt-[39.72px]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            exit={{ opacity: 0 }}
          >
            {isBefore ? '노출 전' : '노출 후'}
          </motion.p>
          {isBefore ? (
            <p className="font-bold text-[50px] leading-[59.67px] text-[#EF670B]">{isBefore ? 80 : 30}</p>
          ) : (
            <SubtractAnxy />
          )}
        </div>
      </div>
    </motion.div>
  );
}
