import defaultState from '../../data/inside/defaultState';
import useSessionStorage from '../../hooks/useSessionStorage';
import { AnxyInput } from '../anxy/common/AnxyInput';

const InsideInputWithAdd = ({ name }) => {
  const [insideState, setInsideState] = useSessionStorage('insideState', defaultState);

  const addList = () => {
    setInsideState((state) => ({ ...state, worryList: [...state.worryList, ''] }));
  };

  const updateList = (value, index, isDelete) => {
    setInsideState((state) => {
      const updated = { ...state };
      const newArray = [...updated[name]];
      if (isDelete) {
        newArray.splice(index, 1);
      } else {
        newArray[index] = value;
      }
      updated[name] = newArray;
      console.log(updated);
      return updated;
    });
  };

  return (
    <div className="text-white px-[20px] pt-[50px]">
      {insideState[name].length > 0 &&
        insideState[name].map((item, index) => (
          <AnxyInput
            key={`input${index}`}
            name={name}
            index={index}
            placeholder={'고민이나 어려운 점이 있나요?'}
            value={item}
            updateValue={updateList}
            forceBlur={true}
            autofocus={true}
            inside
          />
        ))}

      <div className="w-full flex justify-center">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={addList}
        >
          <circle cx="12.5" cy="12" r="12" fill="#26282C" />
          <path d="M7.0459 12.0001H17.955" stroke="white" strokeWidth="2" strokeLinecap="round" />
          <path d="M12.501 6.54559L12.501 17.4547" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div>
    </div>
  );
};

export default InsideInputWithAdd;
