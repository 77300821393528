/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import imageList from '../../data/inside/imageList';
import insideColorPalette from '../../data/inside/insideColorPalette';
import webviewToast from '../../development/webviewToast';
import useMixpanelEvent from '../../hooks/useMixpanelEvent';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { PATH } from '../../routes/paths';
import { BottomAction } from '../../stories/page/BottomAction';

export default function Main(props) {
  const setMixPanel = useMixpanelEvent({
    eventName: 'white_1_landing',
  });

  useEffect(() => {
    setMixPanel();
  }, []);

  const goNext = useNavigateWithParams(`${PATH.marketing.insideWhiteDefault}/preview/1`);

  const content = (
    <div className="flex flex-col items-center justify-center px-[20px]">
      <p
        className="text-[32px] leading-[38.4px] font-bold text-black "
        style={{ wordBreak: 'keep-all' }}
      >{`심리상담, \n 내겐 얼마나 효과적일까요?`}</p>
    </div>
  );

  const loadImageById = () => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(loadImg);
          }, 100);

        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(imageList.map((image) => loadImage(image)))
      .then(() => {})
      .catch((err) => webviewToast('이미지 로드 실패'));
  };

  useEffect(() => {
    loadImageById();
  }, []);
  return (
    <BottomAction
      content={<div className="w-screen h-full flex flex-col justify-center items-center text-center">{content}</div>}
      buttonText={'다음'}
      buttonState={'ACTIVE'}
      action={() => {
        goNext();
      }}
      noHeader
    />
  );
}
