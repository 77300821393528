import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

import 'react-toastify/dist/ReactToastify.css';
import { PressedEffect } from '../../stories/button/PressedEffect';
import useShowWebviewWithParams from '../../hooks/useShowWebviewWithParams';

function CourseCard(props) {
  const { courseId, courseName, courseResource, isComingSoon, isDone, onboarding } = props;
  const [isSoonClicked, setIsSoonClicked] = useState(false);

  const cardRef = useRef();

  const goCourseDetail = useShowWebviewWithParams(`course/${courseId.split('-')[1]}`, '#FFFFFF');

  const onClick = () => {
    if (isComingSoon) {
      setIsSoonClicked(true);
    } else {
      goCourseDetail();
    }
  };

  useEffect(() => {
    if (isSoonClicked) {
      setTimeout(() => {
        setIsSoonClicked(false);
      }, 1000);
    }
  }, [isSoonClicked]);
  return (
    <PressedEffect
      element={
        <div className={`w-full `}>
          {!onboarding ? (
            <div
              ref={cardRef}
              style={{
                backgroundColor: courseResource.bg,
              }}
              className={`w-full max-w-[190px] overflow-hidden  rounded-[12px]  relative  before:block before:pb-[138%]`}
            >
              <div className="w-full h-full absolute top-0 bottom-0  pt-[24px]  ">
                <div className="w-full px-[20px] ">
                  <p
                    className={` bottom-[2px] text-[17px] leading-[23px] font-bold 
                  ${courseName === '음식과 나' ? 'text-[#3A3C40]' : 'text-white'}
                  `}
                    style={{ wordBreak: 'keep-all' }}
                  >
                    {courseName}
                  </p>
                  <div className="flex text-white items-center">
                    <motion.p
                      className={`absolute bottom-[20px] text-[14px] leading-[20px]  text-white opacity-0
                    ${courseName === '음식과 나' ? 'text-[#3A3C40]' : 'text-white'}
                    `}
                      animate={{ opacity: isSoonClicked && isComingSoon ? 0.8 : 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      코스 준비 중이에요
                    </motion.p>
                  </div>
                </div>
                <motion.div
                  className="absolute bottom-0 w-full"
                  animate={{ opacity: isSoonClicked && isComingSoon ? 0.1 : 1 }}
                  transition={{ duration: 0.2 }}
                >
                  <img src={courseResource.image} alt="empty" />
                </motion.div>
                {isDone && (
                  <div
                    className="w-full h-[30px] bg-[rgba(0,0,0,0.5)]  absolute bottom-0 text-white text-[12px] leading-[18px] 
             text-center py-[5px]"
                  >
                    완료
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: courseResource.bg,
              }}
              className={`relative px-[20px] rounded-[16px] flex items-center h-[93px]
              `}
            >
              <div className="w-full">
                <p className={`relative bottom-[2px] text-[18px] leading-[22px] text-white font-semibold z-[10]`}>
                  {courseName}
                </p>
              </div>
              <div className="absolute top-0 right-0">
                <img className={`h-[93px] w-auto`} src={courseResource.image} alt="empty" />
              </div>
            </div>
          )}
        </div>
      }
      action={onClick}
    />
  );
}

export default CourseCard;
