/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PressedEffect } from '../../../stories/button/PressedEffect';
import SkipButton from '../../../components/audioPlayer/SkipButton';
import webviewToast from '../../../development/webviewToast';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import deviceAtom, { marginWithHomeIndicator } from '../../../recoil/common/device';
import { useRecoilValue } from 'recoil';
import Test from '../../../development/Test';

const useAudio = (url, action) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [ended, setEnded] = useState(false);
  const [backwardDisable, setBackwardDisable] = useState(false);
  const [forwardDisable, setForwardDisable] = useState(false);

  const { id } = useParams();

  const goNext = useNavigateWithParams(`/meditationTool/${parseInt(id) + 1}`);

  const toggle = () => setPlaying((playing) => !playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    if (duration === 0) {
      setBackwardDisable(true);
      setForwardDisable(true);
      setPlaying(true);
    } else {
      if (currentTime === 0) {
        setBackwardDisable(true);
      } else if (currentTime === duration) {
        console.log('끝');
        setForwardDisable(true);
        setEnded(true);
      } else {
        // webviewToast('ds');
        setBackwardDisable(false);
        setForwardDisable(false);
      }
    }
  }, [currentTime, duration]);

  function backward() {
    audio.currentTime -= 15;
    setCurrentTime(parseInt(Math.max(audio.currentTime, 0)));
  }

  function forward() {
    audio.currentTime += 15;
    if (audio.currentTime >= duration) {
      setEnded(true);
    }
    setCurrentTime(parseInt(Math.min(audio.currentTime, duration)));
  }

  useEffect(() => {
    if (ended) {
      setForwardDisable(true);
      if (action) {
        action();
      } else {
        goNext();
      }
    }
  }, [ended]);

  useEffect(() => {
    audio.addEventListener('stalled', () => {
      webviewToast('stalled');
      if (playing) {
        audio.play();
      }
    });

    audio.addEventListener('error', () => {
      webviewToast('error');
      audio.load();
    });

    audio.addEventListener('durationchange', () => {
      webviewToast('durationchange' + currentTime);
      setDuration(parseInt(audio.duration));
      if (currentTime > 0) {
        audio.currentTime = currentTime;
        setPlaying(true);
      }
    });

    audio.addEventListener('seeking', () => {
      // webviewToast('seeking');
      // audio.pause();
    });

    audio.addEventListener('seeked', () => {
      // webviewToast('seeked');
      // if (playing) {
      //   audio.play();
      // }
    });

    audio.addEventListener('timeupdate', () => {
      setCurrentTime(parseInt(audio.currentTime));
    });
    audio.addEventListener('ended', () => {
      setCurrentTime(parseInt(audio.duration));
      setPlaying(false);
      setEnded(true);
    });
    return () => {
      if (audio) {
        audio.pause();
      }
      // audio.removeEventListener('durationchange', () => {
      //   setDuration(parseInt(audio.duration));
      // });
      // audio.removeEventListener('timeupdate', () => setCurrentTime(parseInt(audio.currentTime)));
      // audio.removeEventListener('ended', () => {
      //   setCurrentTime(parseInt(audio.duration));
      //   setPlaying(false);
      //   setEnded(true);
      // });
    };
  }, []);

  return [playing, toggle, backward, forward, currentTime, duration, backwardDisable, forwardDisable];
};

export default function AudioPlayer({ audio, notSkip, action }) {
  const [playing, toggle, backward, forward, currentTime, duration, backwardDisable, forwardDisable] = useAudio(
    audio,
    action,
  );

  const { sessionId, page, id } = useParams();
  const goNext = useNavigateWithParams(`/meditationTool/${parseInt(id) + 1}`);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (duration !== 0) {
      setLoaded(true);
    }
  }, [duration]);

  const getProgress = (currentTime, duration) => {
    return Math.ceil((currentTime / duration) * 100);
  };

  useEffect(() => {}, []);
  const deviceState = useRecoilValue(deviceAtom);
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  const handleEnd = () => {
    console.log('skip', sessionId, page, id);
    if (action) {
      console.log('action');
      action();
    } else {
      goNext();
    }
  };
  return (
    <div className="w-full flex flex-col items-center px-[38px] z-[50]">
      <Test
        element={
          <div
            className="absolute z-[50] top-[5px] right-[15px] bg-[#FFFFFF80] px-[10px] py-[5px] text-center rounded-[12px]"
            style={{
              marginTop: `${deviceState.notchSize + 5}px`,
            }}
            onClick={handleEnd}
          >
            Skip
          </div>
        }
      />
      <div className="z-[40] relative w-full h-[4px] bg-black opacity-[0.3] rounded-[25px] mb-[30px]">
        <div className="flex items-center absolute top-[0px] w-[calc(100vw-73px)]">
          <div
            className="h-[4px] rounded-[25px] bg-white"
            style={{
              width: `${getProgress(currentTime, duration)}%`,
            }}
          />
          {/* <div className="w-[12px] h-[12px] bg-white rounded-[100%] translate-x-[-3px] translate-y-[-0.5px]" /> */}
        </div>
        {/* {`${String(parseInt(currentTime / 60)).padStart(2, '0')}:${String(parseInt(currentTime % 60)).padStart(
          2,
          '0',
        )}`} */}
      </div>

      <div className={`${marginClassName} flex items-center gap-[30px] text-[15px] leading-[21px] font-bold mb-[32px]`}>
        <SkipButton type={'backward'} action={backward} disable={backwardDisable} />
        <div className="w-[54px] h-[54px] ">
          <PressedEffect
            element={
              <div
                className={`w-full h-full rounded-[50%]  bg-[#26282C] flex items-center justify-center relative ${
                  !loaded && 'bg-[rgba(0,0,0,0.05)]'
                }`}
              >
                {!loaded ? (
                  <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center">
                    <div className={`flex items-center animate-spin`}>
                      <svg
                        width="23"
                        height="22"
                        viewBox="0 0 23 22"
                        fill="none"
                        stroke={'#FFFFFF'}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.5 11C21.5 12.8442 20.99 14.6525 20.0264 16.225C19.0628 17.7974 17.6831 19.0728 16.0399 19.9101C14.3967 20.7473 12.5539 21.1139 10.7154 20.9692C8.87687 20.8245 7.11415 20.1742 5.62215 19.0902C4.13014 18.0062 2.96696 16.5307 2.2612 14.8268C1.55545 13.123 1.33462 11.2572 1.62312 9.43566C1.91162 7.61414 2.69821 5.90788 3.89594 4.50552C5.09367 3.10317 6.65587 2.05934 8.40983 1.48944"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                ) : playing ? (
                  <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="4" height="16" rx="1" fill="white" />
                      <rect x="8" width="4" height="16" rx="1" fill="white" />
                    </svg>
                  </div>
                ) : (
                  <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[calc(-50%+1px)]">
                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.5 7.13397C14.1667 7.51887 14.1667 8.48113 13.5 8.86603L1.5 15.7942C0.833334 16.1791 0 15.698 0 14.9282L0 1.0718C0 0.301997 0.833333 -0.179129 1.5 0.205771L13.5 7.13397Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
                <div
                  className="w-full h-full  absolute "
                  onClick={() => {
                    if (loaded) {
                      console.log('click');
                      toggle();
                    }
                  }}
                />
              </div>
            }
            action={() => {}}
          />
        </div>

        <SkipButton type={'forward'} action={forward} disable={forwardDisable} />
      </div>
    </div>
  );
}
