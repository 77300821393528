import { Swiper, SwiperSlide } from 'swiper/react'; // basic
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';

import 'swiper/css'; //basic
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SelfCheckItem from './SelfCheckItem';
import { showWebView } from '../../utils/webview';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const SelfCheckCarousel = ({ selfCheckResultList }) => {
  const settings = {
    centerMode: true,
    centerPadding: '15px',
    slidesToShow: 1,
    infinite: false,
    swipeToSlide: true,
    touchThreshold: 400,
  };
  SwiperCore.use([Navigation, Pagination, Autoplay]);

  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appVersion = searchParams.get('app_version') || 133;
  const appId = searchParams.get('app_id');

  const carouselRef = useRef();

  useEffect(() => {}, []);

  const goToSelfCheck = () => {
    showWebView({
      url: `selfCheck?at=${authToken}&app_id=${appId}&app_version=${appVersion}`,
      backgroundColor: '#F3F5F8',
    });
  };

  console.log('self', selfCheckResultList);

  return (
    <div className="text-[#000000] bg-[#FFFFFF] pt-[25px] h-[180px]">
      <div className="flex items-center justify-between mr-[2px] mb-[13px] px-[20px] ">
        <p className="text-[20px] font-semibold leading-6 ">자가 검사</p>
        <div
          className="flex justify-center items-center w-[35px] h-[35px] translate-x-[12px]
            "
          onClick={goToSelfCheck}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" fill="none">
            <path
              d="M1 14.5L7 8L0.999999 1.5"
              stroke="#80838B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="relative w-full overflow-hidden h-full">
        {/* 38+25+250 */}
        <div
          className="w-full  "
          style={{
            WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          }}
          ref={carouselRef}
        >
          <Swiper
            centeredSlides
            slidesPerView={1.1}
            allowTouchMove={true}
            noSwiping={true}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {selfCheckResultList.map((step, index) => (
              <SwiperSlide key={index}>
                <div className={`wide:px-[15px] ${index === 0 ? 'pl-[3px]' : 'pl-[5px]'} pr-[5px]`}>
                  <SelfCheckItem selfCheckResult={step} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <Slider {...settings}>
            {selfCheckResultList.map((data, index) => (
              <div className="wide:px-[15px] pl-[5px] pr-[5px]">
                <SelfCheckItem selfCheckResult={data} />
              </div>
            ))}
          </Slider> */}
        </div>
      </div>
    </div>
  );
};

export default SelfCheckCarousel;
