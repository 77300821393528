import React from 'react';
import { useState } from 'react';
import info1 from '../../../image/somny/sleepTale/icon1.png';
import info2 from '../../../image/somny/sleepTale/icon2.png';
import info3 from '../../../image/somny/sleepTale/icon3.png';

import { useEffect } from 'react';
import { CheckLottie } from '../../../stories/animation/CheckLottie';
import { PressedEffect } from '../../../stories/button/PressedEffect';

export function SelectReadyButtonSomny({ setButtonState, isSomny }) {
  const [selectedId, setSelectedid] = useState([]);

  const data = [
    {
      id: 1,
      image: info1,
      title: '방의 온도는 적당해요',
    },
    { id: 2, image: info2, title: '조명은 모두 껐어요' },
    {
      id: 3,
      image: info3,
      title: '침실에 편안하게 누웠어요',
    },
  ];

  useEffect(() => {
    if (selectedId.length !== data.length) {
      setButtonState('INACTIVE');
    } else {
      setButtonState('ACTIVE');
    }
  }, [selectedId]);

  const handleAction = (id) => {
    console.log(selectedId);
    if (selectedId.includes(id)) {
      setSelectedid(selectedId.filter((item) => item !== id));
    } else {
      if (selectedId.length === 0) {
        setSelectedid([id]);
      } else {
        setSelectedid((prev) => [...prev, id]);
      }
    }
  };

  return (
    <div className="pl-[23px] pr-[17px] w-full flex flex-col gap-[10px]">
      {data.map((item) => (
        <PressedEffect
          element={
            <div className="text-white flex justify-between items-center bg-[#222455] rounded-[16px] px-[20px] py-[21px]">
              <div className="flex items-center">
                <img src={item.image} alt="empty" className="w-[50px] h-[50px]" />
                <p className="text-[17px] leading-[23px] font-bold px-[15px]">{item.title}</p>
              </div>
              {!selectedId.includes(item.id) ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.3" cx="12" cy="12" r="12" fill="black" />
                  <path
                    opacity="0.3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.5386 8.47802C17.8268 8.77545 17.8194 9.25027 17.522 9.53855L11.3315 15.5386C11.0406 15.8205 10.5784 15.8205 10.2875 15.5386L6.47802 11.8462C6.18059 11.558 6.17317 11.0831 6.46145 10.7857C6.74973 10.4883 7.22455 10.4809 7.52198 10.7691L10.8095 13.9555L16.478 8.46145C16.7755 8.17317 17.2503 8.18059 17.5386 8.47802Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <CheckLottie containerSize="24px" somny />
              )}
            </div>
          }
          action={() => handleAction(item.id)}
        />
      ))}
    </div>
  );
}
