import React, { useContext, useEffect, useState } from 'react';
import { CoachingContext } from '../../coachingContext';

import { PrimaryActionButton } from '../../stories/button/ActionButton';

import { useModalDrag } from '../../hooks/useModalDrag';
import useShowWebviewWithParams from '../../hooks/useShowWebviewWithParams';

export default function CounselingModal() {
  const context = useContext(CoachingContext);
  const [modalClose, setModalClose] = useState(false);

  const goCounselorWizard = useShowWebviewWithParams(`counselorSearchWizard/begin`, '#2C4BEC');
  const goToWizard = () => {
    setModalClose(true);
    context.setShowCounselingModal(false);
  };
  useEffect(() => {
    if (modalClose) {
      setTimeout(() => {
        goCounselorWizard();
        setModalClose(false);
      }, 100);
    }
  }, [modalClose]);

  const { sheet } = useModalDrag();

  return (
    <div
      className={`w-screen fixed opacity-100  bottom-[-100px] z-[50]`}
      style={{
        transform: `${context.showCounselingModal ? 'translateY(-100px)' : 'translateY(250px)'}`,
        transition: `all ${context.showCounselingModal ? 0.3 : 0.2}s ease-in-out`,
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <div
        ref={sheet}
        className={`w-full bg-[#FFFFFF] px-[20px] pt-[28px] text-[15px] leading-[18px] text-center pb-[20px]`}
        style={{
          borderRadius: '20px 20px 0px 0px',
          boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.05)',
        }}
      >
        <p className="text-[#26282C] text-[20px] leading-[24px] font-semibold">전과 다른 어려움이 있나요?</p>
        <p className="text-[#26282C] text-[17px] leading-[26px] text-[#A4A6B0] mt-[8px] mb-[24px]">
          내게 맞는 새 선생님을 찾아볼게요.
        </p>
        <PrimaryActionButton state="ACTIVE" text="다시 찾기" action={goToWizard} />
      </div>
    </div>
  );
}
