import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context';
import { MultipleSelectButton } from '../../stories/button/MultipleSelectButton';
import { TopTitleBottomActionWithDefault } from '../../stories/page/TopTitleBottomActionWithDefault';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function MultipleSelectWithDefaultTemplate(props) {
  const { id, question, options } = props;
  const [buttonState, setButtonState] = useState('INACTIVE');

  const context = useContext(AuthContext);

  var step = [];
  if (id === 3) {
    if (context.presurvey.mindfulnessActivityList) {
      step = context.presurvey.mindfulnessActivityList;
    }
  }

  const [answer, setAnswer] = useState(step);
  const goNext = useNavigateWithParams(`/assignCounselor/survey/${id + 1}`);

  function goNextPage() {
    console.log('answer: ', answer);
    if (id === 3) {
      if (answer.length > 0) {
        context.presurvey['mindfulnessActivityList'] = answer;
      }

      goNext();
    }
  }

  function select(option) {
    const selected = option;
    if (answer.includes(selected)) {
      let filteredArray = answer.filter((item) => item !== selected);
      setAnswer(filteredArray);
    } else {
      setAnswer((answer) => [...answer, selected]);
    }
  }

  function isClicked(option) {
    if (option !== '') {
      console.log('이미클릭함');
      select(option);
    }
  }
  useEffect(() => {
    if (answer.length !== 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [answer]);

  const Element = (
    <div className={`h-full  pt-[43px] pb-[30px] px-[20px] `}>
      {options.map((option, index) => (
        <MultipleSelectButton
          key={index}
          selected={answer.includes(option)}
          option={option}
          text={option}
          isClicked={isClicked}
        />
      ))}
    </div>
  );

  return (
    <div className={`h-full `}>
      <TopTitleBottomActionWithDefault
        title={question}
        content={Element}
        buttonText={'다음'}
        defaultButtonText={'딱히 없어요'}
        buttonState={buttonState}
        action={goNextPage}
        defaultAction={goNextPage}
      />
    </div>
  );
}
