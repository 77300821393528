import React, { useState, useEffect, useCallback, useContext } from 'react';
import coupon_1 from '../image/coupon_1.png';
import coupon_2 from '../image/coupon_2.png';
import coupon_3 from '../image/coupon_3.png';

import CouponItem from '../components/checkin/CouponItem';

function RewardInfo() {
  return (
    <div className="w-screen h-screen select-none ">
      <CouponItem
        category={'리워드 이용안내'}
        steps={[
          ['STEP 1', '할인권은 홈화면에서 바로 볼 수 있어요.', coupon_1],
          ['STEP 2', '원하는 선생님을 선택하고,', coupon_2],
          ['STEP 3', '결제 시점에 적용된 할인율을 확인하세요.', coupon_3],
        ]}
      />
    </div>
  );
}

export default RewardInfo;
