import { atom } from 'recoil';

const selfCheckAtom = atom({
  key: 'selfCheck',
  default: {
    qcList: [],
    selfCheckAnswer: [],
    selfCheckResult: undefined,
  },
});

export default selfCheckAtom;
