import React from 'react';
import TestResultChart from '../selfCheck/TestResultChart';

export default function TestResultDetail(props) {
  const { examName, summary, detail, isAbnormal, score } = props;
  // const example = [
  //   {
  //     text: '현재 정상적인 수준의 스트레스를 느끼고 계시네요. 임상적으로는 크게 걱정할 점이 없는 수준이에요. 최근 제법 평화로운 나날들을 보내고 계실지도 모르겠어요. 혹시 힘들고 지치는 일이 많았는데도 위와 같은 결과가 나왔나요? 그렇다면 어쩌면 남들보다 ',
  //     bold: false,
  //   },
  //   { text: '정신적 혹은 신체적으로 압박과 긴장이 심한 상황 속에서도 남들보다 더 잘 대처하는 타입', bold: true },
  //   { text: '일 수도 있답니다. 스트레스에 잘 대처하는 능력은 매우', bold: false },
  //   { text: '좋은 자산', bold: false },
  //   {
  //     text: '이랍니다. 적정 수준의 긴장과 각성은 성취욕과 목표의식을 자극해 긍정적인 결과의 원동력으로 작용하기 때문이에요. 하지만 아무리 대처를 잘하는 타입이라도 높은 수준의 압박과 긴장이 오랜 기간 이어지면 다양한 문제가 발생할 수도 있답니다. 따라서 ',
  //     bold: false,
  //   },
  //   { text: '문제가 발생하지 않았을 때 스트레스에 대해 대처하는 역량을 더 기르는 것도 좋은 방법이랍니다.', bold: true },
  // ];

  return (
    <div className={``}>
      <div className={`w-full h-full bg-white  rounded-[16px] pt-[24px] pb-[30px] px-[24px] `}>
        <p
          className={`text-[20px] leading-[26px] ${
            isAbnormal ? 'text-[#EF3E3E] ' : 'text-[#2C4BEC]'
          } font-semibold mb-[30px]`}
        >
          {examName}
        </p>
        <div className="mb-[30px]">
          <TestResultChart examName={examName} isAbnormal={isAbnormal} score={score} />
        </div>
        <p className={`text-[17px] leading-[23px]  text-[#3A3C40]  font-semibold mb-[10px]`}>{summary}</p>
        {/* <p className={`text-[15px] leading-[21px]  text-[#3A3C40] font-normal `}>{detail}</p> */}
        {/* {example.map((each) => (
          <span
            className={`text-[15px] leading-[21px]  text-[#3A3C40] font-normal `}
            style={{ boxShadow: each.bold ? 'inset 0 -21px 0 rgba(53, 64, 237, 0.2)' : '' }}
          >
            {each.text}
          </span>
        ))} */}
        {detail.split('<br>').map((paragraph, index) => (
          <span className={`text-[15px] leading-[21px]  text-[#3A3C40] font-normal `} key={`testResultDetail${index}`}>
            {paragraph}
            {index !== detail.split('<br>').length - 1 && <br />}
          </span>
        ))}
      </div>
    </div>
  );
}
