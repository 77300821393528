import React from 'react';
import { useEffect } from 'react';
import somnyChairLottie from '../../../stories/animation/somny_chair.json';
import Lottie from '../../../components/elements/Lottie';
import SleepAudioPlayer from './SleepAudioPlayer';
import { somnyFairyTaleData } from '../../../data/somny/somnyFairyTaleData';
import { useParams } from 'react-router-dom';

export function SleepTailAudio({ setButtonState, theme, setBgColor, action }) {
  const { taleId } = useParams();

  useEffect(() => {
    if (theme) {
      if (theme.isNotReady) {
        setButtonState('INACTIVE');
      } else {
        setButtonState('ACTIVE');
      }
    }
  }, [theme]);

  const currentData = somnyFairyTaleData.find((item) => item.taleId === taleId);

  useEffect(() => {
    setBgColor('#F3F5F800');
    document.title = `Somny, ${currentData.title}`;
  }, []);

  return (
    <div className="w-full flex flex-col justify-between h-full bg-[#060727]">
      <div className="relative pt-[84px] pb-[40px] w-full h-full flex justify-center items-center">
        <img src={currentData.image} alt="empty" className="w-[177px]" />
        <div className="absolute bottom-[40px]">
          <Lottie lottieData={somnyChairLottie} autoplay loop height={190} />
        </div>
      </div>
      <div className="w-full mx-auto z-[50]">
        <SleepAudioPlayer title={currentData.title} url={currentData.audio} action={action} />
      </div>
    </div>
  );
}
