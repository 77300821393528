/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { getTimePassedString, getConvertedDay, getMinutes } from '../../sleepingReport/calculateTime';

export default function SleepGraph(props) {
  const { recordByDateList, dummyData } = props;
  const scrollRef = useRef();
  const tooltipRef = useRef();
  const barRef = useRef();

  const [data, setData] = useState(recordByDateList && recordByDateList.length !== 0 ? recordByDateList : dummyData);

  const [axis, setAxis] = useState([]);
  const [total, setTotal] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(0);

  useEffect(() => {
    if (scrollRef.current) {
      console.log('스크롤 끝', scrollRef.current.scrollWidth, scrollRef.current.offsetWidth);
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth - scrollRef.current.offsetWidth;
    }
    setTimeout(() => {
      setLoaded(true);
      if (scrollRef.current) {
        const offset =
          scrollRef.current.offsetWidth / 2 +
          40 +
          barRef.current.offsetWidth * (data.length >= 7 ? 3 : data.length / 2 - 0.5) +
          tooltipRef.current.offsetWidth / 2 -
          (scrollRef.current.offsetWidth + 40);
        // console.log(offset, data.length >= 7 ? 3 : data.length / 2 - 0.5);
        if (offset > 0) {
          setTooltipPosition(-offset - 1);
        }
      }
    }, 100);
  }, []);

  const [selected, setSelected] = useState(data[data.length - 1]);

  data.map((element) => {
    element['inBedNumber'] = [
      getMinutes(element.inBed.startDateTime) <= getMinutes(element.inBed.endDateTime)
        ? getMinutes(element.inBed.startDateTime) + 24 * 60
        : getMinutes(element.inBed.startDateTime),
      getMinutes(element.inBed.startDateTime) !== getMinutes(element.inBed.endDateTime)
        ? getMinutes(element.inBed.endDateTime) + 24 * 60
        : getMinutes(element.inBed.endDateTime),
    ];
    element['sleepNumber'] = [
      getMinutes(element.sleep.startDateTime) <= getMinutes(element.sleep.endDateTime)
        ? getMinutes(element.sleep.startDateTime) + 24 * 60
        : getMinutes(element.sleep.startDateTime),
      getMinutes(element.sleep.startDateTime) !== getMinutes(element.sleep.endDateTime)
        ? getMinutes(element.sleep.endDateTime) + 24 * 60
        : getMinutes(element.sleep.endDateTime),
    ];
  });

  const startTime = Math.min.apply(
    null,
    data.map((element) => element.inBedNumber[0]),
  );
  const endTime = Math.max.apply(
    null,
    data.map((element) => element.inBedNumber[1]),
  );

  console.log(startTime, endTime);

  useEffect(() => {
    console.log(data, startTime, endTime);
    let axisTemp = [];

    axisTemp.push(parseInt(startTime / 60) * 60);
    let temp = parseInt(startTime / 60) * 60;
    while (temp < endTime) {
      temp += 4 * 60;
      axisTemp.push(temp);
    }

    setTotal(axisTemp[axisTemp.length - 1] - axisTemp[0]);
    setAxis(axisTemp.map((element) => (element / 60) % 24));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(recordByDateList);

  return (
    <div className="w-full h-full rounded-[16px] overflow-hidden z-[50]">
      <div className="w-full h-[362px]  rounded-[16px] bg-[#222455] relative overflow-hidden">
        <div
          className={`flex-none pt-[24px] z-[50] pl-[20px] mb-[10px] text-[17px] leading-[23px] text-white font-bold `}
        >
          자고 일어난 시간
        </div>

        {recordByDateList && recordByDateList.length === 0 && (
          <div className="">
            <div className="absolute top-[50%] translate-y-[calc(-50%+10px)] left-[50%] translate-x-[-50%] w-fit mx-auto z-30 ">
              <p className="text-[15px] leading-[21px] text-[#A4A6B0] whitespace-nowrap">아직 기록된 데이터가 없어요</p>
            </div>
          </div>
        )}
        <div className=" flex relative">
          {recordByDateList && recordByDateList.length === 0 && (
            <div className="left-0 top-[0px] absolute w-full h-full z-20 bg-[#222455E6]" />
          )}
          <div className="flex-1 min-w-0 h-full  relative pl-[20px]">
            <div
              className=" w-[10px] h-[168px] absolute top-[64px] right-[-1px] z-10 translate-y-[1px]"
              style={{ background: `linear-gradient(to left, #222455, #22245500` }}
            />
            <div
              className="w-[10px] h-[168px] absolute  top-[64px] left-[19px] z-10 translate-y-[1px]"
              style={{ background: `linear-gradient(to right, #222455, #22245500` }}
            />
            <div
              className="w-[10px] h-[40px] absolute top-[64px] right-[-1px] z-10 translate-y-[173px]"
              style={{ background: `linear-gradient(to left, #222455, #22245500` }}
            />
            <div
              className="w-[10px] h-[40px] absolute   top-[64px] left-[19px] z-10 translate-y-[173px]"
              style={{ background: `linear-gradient(to right, #222455, #22245500` }}
            />
            <div className="absolute top-[64px]  w-[calc(100%-20px)] h-[170px] border-y-[1px] opacity-[0.1]"></div>
            <div
              className={`w-full h-full overflow-auto scrollbar-hide  flex ${
                data.length < 7 ? 'justify-center' : ''
              }  pt-[64px]`}
              ref={scrollRef}
              onScroll={(e) => {
                // console.log(e.target.scrollLeft, scrollRef.current.scrollWidth, scrollRef.current.offsetWidth);
              }}
            >
              {data.map((each, index) => (
                <div
                  className="w-[calc(100%/7)] min-w-[calc(100%/7)]  h-full relative "
                  key={`sleepingRecord${index}`}
                  ref={barRef}
                  onClick={(e) => {
                    setSelected(each);
                    if (
                      e.target.getBoundingClientRect().left +
                        e.target.offsetWidth / 2 +
                        tooltipRef.current.offsetWidth / 2 >
                      scrollRef.current.offsetWidth + 40
                    ) {
                      console.log('오른쪽 넘어가');
                      setTooltipPosition(
                        -(
                          e.target.getBoundingClientRect().left +
                          e.target.offsetWidth / 2 +
                          tooltipRef.current.offsetWidth / 2 -
                          (scrollRef.current.offsetWidth + 40)
                        ),
                      );
                    } else if (
                      e.target.getBoundingClientRect().left +
                        e.target.offsetWidth / 2 -
                        tooltipRef.current.offsetWidth / 2 <
                      40
                    ) {
                      console.log('왼쪽 넘어가');
                      setTooltipPosition(
                        40 -
                          (e.target.getBoundingClientRect().left +
                            e.target.offsetWidth / 2 -
                            tooltipRef.current.offsetWidth / 2),
                      );
                    } else {
                      setTooltipPosition(0);
                    }
                  }}
                >
                  <div className={`w-full h-[170px] py-[10px]   ${loaded ? 'opacity-100' : 'opacity-0'}`}>
                    <div className="h-full relative  flex justify-center ">
                      {each.date === selected.date && (
                        <div
                          className={`z-10  translate-y-[-74px]  left-[50%] translate-x-[-50%] flex flex-col items-center
                       `}
                        >
                          <div
                            className="w-[180px] py-[8px] px-[10px] bg-white rounded-[6px]  text-[#3A3C40]  absolute"
                            style={{ transform: `translate(${tooltipPosition}px,0)` }}
                            ref={tooltipRef}
                          >
                            <div className="flex justify-between text-[#3A3C40]">
                              <div className="flex items-center">
                                <div className="w-[10px] h-[10px] rounded-[10px] bg-[#2C4BEC] mr-[4px]" />
                                <div> 잠잔 시간</div>
                              </div>
                              <div className="font-bold text-[#26282C]">
                                {getTimePassedString(selected.sleep.startDateTime, selected.sleep.endDateTime)}
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <div className="flex items-center">
                                <div className="w-[10px] h-[10px] rounded-[10px] bg-[#D8DCFF] mr-[4px]" />
                                <div> 침대에 머문 시간</div>
                              </div>
                              <div className="font-bold text-[#26282C]">
                                {getTimePassedString(selected.inBed.startDateTime, selected.inBed.endDateTime)}
                              </div>
                            </div>
                          </div>
                          <div className="translate-y-[-2px] absolute translate-y-[50px]">
                            <svg
                              width="10"
                              height="6"
                              viewBox="0 0 10 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.55223 5.24191C5.75185 6.227 4.24816 6.227 3.44777 5.24191L1.49166e-06 0.998499L10 0.998499L6.55223 5.24191Z"
                                fill="#FFFFFF"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                      {each.date === selected.date && (
                        <div className="w-[2px] h-[168px] rounded-[1px] translate-y-[-9px] bg-[#A4A6B0] absolute" />
                      )}
                      <div
                        className="w-[14px] rounded-[50px] bg-[#D8DCFF] absolute"
                        style={{
                          top: total !== 0 && `${((each.inBedNumber[0] - startTime) / total) * 100}%`,
                          height: total !== 0 && `${((each.inBedNumber[1] - each.inBedNumber[0]) / total) * 100}%`,
                        }}
                      />

                      <div
                        className="w-[14px] rounded-[50px] bg-[#2C4BEC] absolute"
                        style={{
                          top: total !== 0 && `${((each.sleepNumber[0] - startTime) / total) * 100}%`,
                          height: total !== 0 && `${((each.sleepNumber[1] - each.sleepNumber[0]) / total) * 100}%`,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`pt-[8px]  text-center ${
                      each.date === selected.date ? 'font-bold text-white' : 'font-normal text-[#80838B] '
                    }`}
                  >
                    {each.date
                      .split('-')
                      .slice(1)
                      .map((each, index) => parseInt(each))
                      .join('.')}
                    <br />
                    {getConvertedDay(each.date, ['어제'])}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="ml-[10px] translate-y-[63px] w-[30px] h-[168px]  flex flex-col justify-between text-[#A4A6B0]">
            {axis.map((each, index) => (
              <div key={`axis${index}`}>{each === 0 ? 24 : each}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
