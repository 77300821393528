const anxyColorPalette = {
  orange: '#EF670B',
  purple: '#462C88',
  green: '#398047',
  navy: '#251E35',
  brick: '#972700',
  yellow: '#F8CF46',
  white: '#FFFFFF',
  blue: '#0C6FF9',
  gray: '#2C2C2C',
  black: '#26282C',
  oat: '#F1EEEB',
};

export default anxyColorPalette;
