import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ProgressingBar } from '../../stories/else/ProgressingBar';

export default function InprogressCourse(props) {
  const { percentageOfProgress, totalDays, currentDay } = props;

  return (
    <div>
      <ProgressingBar
        maxCompleted={100}
        completed={percentageOfProgress}
        baseColor={'#FFFFFF'}
        color={'radial-gradient(99.83% 1234.86% at 99.83% 68.01%, #5513F0 0%, #2C4BEC 100%)'}
      />
      <div className="flex text-[#4E4F53] items-center mt-[12px]">
        <div className="flex items-center ">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
            <g opacity="0.8">
              <path
                d="M13.4 10.5C13.4 11.14 12.909 11.8557 11.8117 12.4466C10.7389 13.0243 9.21551 13.4 7.5 13.4C5.78449 13.4 4.26114 13.0243 3.18827 12.4466C2.09095 11.8557 1.6 11.14 1.6 10.5C1.6 9.85999 2.09095 9.14427 3.18827 8.55341C4.26114 7.97571 5.78449 7.6 7.5 7.6C9.21551 7.6 10.7389 7.97571 11.8117 8.55341C12.909 9.14427 13.4 9.85999 13.4 10.5Z"
                stroke="#4E4F53"
                strokeWidth="1.2"
              />
              <path d="M7.5 3V10" stroke="#4E4F53" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M12 3.5L7.5 5.66506L7.5 1.33494L12 3.5Z"
                stroke="#4E4F53"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
          <div className="flex relative bottom-[1px] items-center whitespace-nowrap">
            <p className="text-[14px] leading-[17px] opacity-[0.8] ml-[6px]">{`${totalDays}일 코스`}</p>
            {percentageOfProgress !== 0 && <div className="w-[2px] h-[2px] bg-[#2C4BEC] rounded-[100%] mx-5px" />}
            {percentageOfProgress !== 0 && percentageOfProgress !== 100 && (
              <p className="flex items-center text-[14px] leading-[17px] text-[#2C4BEC] font-semibold">{`${
                currentDay ? currentDay : 1
              }일차`}</p>
            )}
            {percentageOfProgress === 100 && (
              <p className="flex items-center text-[14px] leading-[17px] text-[#2C4BEC] font-semibold">{`완료`}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
