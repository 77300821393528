/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context';
import useFetch from '../../hooks/useFetchReal';

export default function Score() {
  const context = useContext(AuthContext);
  const [choiceIdList, setChoiceIdList] = useState(null);

  const [submitResult, submit] = useFetch({
    url: `/counselor/survey-for-assign-test/submit`,
    requestBody: JSON.stringify({ choiceIdList: choiceIdList }),
    bg: '#2C4BEC',
  });

  useEffect(() => {
    if (submitResult) {
      console.log(submitResult.result.scoreTable);
    }
  }, [submitResult]);

  useEffect(() => {
    console.log('CHOICES-----------', context.counselorSearchWizardChoice);
    const choiceIdList_temp = context.counselorSearchWizardChoice
      .map((element) => {
        if (element.id !== 'crc-7' && element.id !== 'date') {
          if (element.answer.id !== 'skip') {
            return element.answer.id;
          }
        }
        return '';
      })
      .filter((element) => element !== '');
    const hopeToCounselingDateList_temp = context.counselorSearchWizardChoice.find((element) => element.id === 'date')
      ? context.counselorSearchWizardChoice
          .find((element) => element.id === 'date')
          .answer.map(
            (date) =>
              `${new Date(date).getFullYear()}-${String(new Date(date).getMonth() + 1).padStart(2, '0')}-${String(
                new Date(date).getDate(),
              ).padStart(2, '0')}`,
          )
      : [];
    console.log(choiceIdList_temp, hopeToCounselingDateList_temp);
    setChoiceIdList(choiceIdList_temp);
  }, []);

  useEffect(() => {
    if (choiceIdList !== null) {
      console.log('호출');
      submit();
    }
  }, [choiceIdList]);

  return (
    <div className={`w-screen h-screen py-[30px] overflow-auto  bg-[#2C4BEC] px-[20px] text-white  text-center   `}>
      {submitResult && (
        <div>
          <div className="pb-[20px]">
            <p className="text-[17px] font-bold">Choice</p>
            {submitResult.result.choice}
          </div>
          <div className="pb-[20px]">
            <p className="text-[17px] font-bold">KeywordList</p>
            {submitResult.result.keywordList}
          </div>
          <div className="pb-[20px]">
            <p className="text-[17px] font-bold">ScoreTable</p>
            {submitResult.result.scoreTable}
          </div>
        </div>
      )}
    </div>
  );
}
