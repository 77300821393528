import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ActivityMain from '../../components/activity/ActivityMain';
import ActivityList from '../../components/activity/ActivityList';
import { CoachingContext } from '../../coachingContext';
import { changeHeader, showWebView, getRefresh } from '../../utils/webview';
import activityData from '../../data/activityData';
import ActivityModal from '../../components/elements/ActivityModal';

import webviewToast from '../../development/webviewToast';
import DotLoader from 'react-spinners/DotLoader';
import useFetch from '../../hooks/useFetch';
import useShowWebviewWithParams from '../../hooks/useShowWebviewWithParams';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

function Activity() {
  const { id } = useParams();
  const context = useContext(CoachingContext);
  const [searchParams] = useSearchParams();
  const navigation = searchParams.get('navigation') || 'closeWithRefresh';

  const [headerText, setHeaderText] = useState('');
  const [course, setCourse] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [activityList, setActivityList] = useState([]);
  const [courseData, setCourseData] = useState('');

  const [activityId, setActivityId] = useState('');
  const [precedingCourseId, setPrecedingCourseId] = useState('');
  const [scrollTop, setScrollTop] = useState(0);

  const [loaded, setLoaded] = useState(false);
  const [toolOpenActivity, setToolOpenActivity] = useState([]);

  const scrollRef = useRef();

  const [courseDetail, getCourseDetail] = useFetch({
    url: `/course/detail`,
    params: { course_id: 'course-' + id },
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (courseDetail) {
      const data = courseDetail.result;
      setActivityList((prevActivityList) => {
        // webviewToast(`activityList length: ${prevActivityList.length}`);
        if (prevActivityList && prevActivityList.length) {
          // refresh event
          prevActivityList.forEach((beforeItem) => {
            data.activityList.forEach((afterItem) => {
              if (beforeItem.activityId === afterItem.activityId) {
                if (beforeItem.isLock && !afterItem.isLock) {
                  // webviewToast(`isFirstUnlocked id: ${afterItem.activityId}`);
                  afterItem.isFirstUnlocked = true;
                }
              }
            });
          });
        } else {
          // first render
          setToolOpenActivity(getToolOpenActivityId(data.activityList.filter((element) => element.toolId)));
        }
        return data.activityList;
      });

      console.log(
        'getCourseDetail',
        data,
        data.activityList.filter((element) => element.toolId),
      );
      setCourse({ ...data });
      setCourseData(JSON.stringify({ courseId: data.courseId }));
      if (data.activityList.filter((element) => element.availableDay === course.currentDay).length > 0) {
        setActivityId(
          data.activityList.filter((element) => element.availableDay === course.currentDay).slice(-1)[0].activityId,
        );
      }
      if (data.precedingCourse) {
        setPrecedingCourseId(data.precedingCourse.courseId);
      }
      // 수면 코스에서의 data(수면 기록) clear를 위해 추가
      window.localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetail]);

  function getToolOpenActivityId(toolList) {
    var toolOpenActivityIdList = [];
    // eslint-disable-next-line array-callback-return
    toolList.map((tool) => {
      toolOpenActivityIdList.push(`course-${id}_${parseInt(tool.activityId.split('_')[1]) - 1}`);
    });

    return toolOpenActivityIdList;
  }

  const [courseStartResult, courseStart] = useFetch({
    url: `/course/start`,
    requestBody: courseData,
    bg: '#FFFFFF',
  });

  useEffect(() => {
    if (courseStartResult) {
      getCourseDetail(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseStartResult]);

  function clickStart() {
    if (course.isOnboardingNotStartedYet || !course.isPrecedingCourseDone) {
      context.setShowActivityModal(true);
      if (!course.isPrecedingCourseDone) {
        console.log('PRECEDING');
        context.setActivityModalType('PRECEDING');
      }
      if (course.isOnboardingNotStartedYet) {
        console.log('PROGRAM_NOT_STARTED');
        context.setActivityModalType('PROGRAM_NOT_STARTED');
      }
    } else {
      if (course.inProgressCourse && course.courseId !== course.inProgressCourse.courseId) {
        context.setShowActivityModal(true);
        context.setActivityModalType('START_NEW_COURSE');
      } else {
        console.log('course start');
        courseStart();
      }
    }
  }

  const toolOpen = (toolId) => {
    // showWebView(`toolOpen/${toolId}?close=true`, false);
    showWebView({ url: `toolOpen/${toolId}?close=true` });
    context.showWebviewLayer['toolOpen'] = { show: false, toolId: undefined };
  };

  const feedbackWebview = useShowWebviewWithParams(`feedback/0?activity_id=${activityId}`, '#EFF2FF');
  const courseDoneWebview = useShowWebviewWithParams(`courseDone?course_id=course-${id}`, '#2C4BEC');

  const goFeedback = () => {
    feedbackWebview();
    context.showWebviewLayer['feedback'] = false;
  };

  const courseDone = () => {
    courseDoneWebview();
    context.showWebviewLayer['courseDone'] = false;
  };

  useEffect(() => {
    if (Object.keys(course).length !== 0) {
      setLoaded(true);
      document.title = course.title;

      const toolOpenActivityList = course.activityList.filter((element) =>
        toolOpenActivity.includes(element.activityId),
      );

      //툴 획득
      if (toolOpenActivityList.find((element) => element.isFirstCallAfterDone)) {
        const toolId =
          course.activityList[
            course.activityList.indexOf(toolOpenActivityList.find((element) => element.isFirstCallAfterDone)) + 1
          ].toolId;
        webviewToast('toolOpen' + toolId.split('-')[1]);
        context.showWebviewLayer['toolOpen'] = { show: true, toolId: toolId.split('-')[1] };
      }

      //오늘 활동 피드백
      if (
        course.currentDay !== 0 &&
        course.activityList.filter((element) => element.availableDay === course.currentDay).slice(-1)[0]
          .isFirstCallAfterDone
      ) {
        webviewToast('showFeedback');
        context.showWebviewLayer['feedback'] = true;
      }

      //코스완료
      if (course.activityList.slice(-1)[0].isFirstCallAfterDone) {
        webviewToast('showCourseDone');
        context.showWebviewLayer['courseDone'] = true;
      }

      setTimeout(
        () => {
          if (context.showWebviewLayer['toolOpen'] && context.showWebviewLayer['toolOpen'].show) {
            toolOpen(context.showWebviewLayer['toolOpen'].toolId);
            if (context.showWebviewLayer['feedback'] || context.showWebviewLayer['courseDone']) {
              context.setIsFirstWebviewLayer(false);
            }
          } else if (context.showWebviewLayer['feedback']) {
            goFeedback();
            if (context.showWebviewLayer['courseDone']) {
              context.setIsFirstWebviewLayer(false);
            }
          } else if (context.showWebviewLayer['courseDone']) {
            courseDone();
            context.setIsFirstWebviewLayer(true);
          }
        },
        context.isFirstWebviewLayer ? 1500 : 100,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  useEffect(() => {
    document.title = '.';
    getCourseDetail();
    getRefresh(getCourseDetail);

    changeHeader({ backgroundColor: '#FFFFFF', text: headerText, buttonType: navigation, color: '#000000' });

    // 수면 코스에서의 data(수면 기록) clear를 위해 추가
    window.localStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (context.showActivityModal) {
      changeHeader({ backgroundColor: '#00000080', text: headerText });
    } else {
      changeHeader({ backgroundColor: '#FFFFFF', text: headerText });
    }
  }, [context.showActivityModal, headerText]);

  const goPrecedingCourse = useNavigateWithParams(`/course/${precedingCourseId.split('-')[1]}?navigation=back`);

  function scrollToResult(targetPosition) {
    scrollRef.current.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });
    return new Promise((resolve, reject) => {
      const failed = setTimeout(() => {
        reject();
      }, 10000);
      const scrollHandler = () => {
        if (scrollRef.current.scrollTop === targetPosition) {
          console.log('도달');
          scrollRef.current.removeEventListener('scroll', scrollHandler);
          clearTimeout(failed);
          resolve();
        }
      };
      if (scrollRef.current.scrollTop === targetPosition) {
        console.log('도달');
        clearTimeout(failed);
        resolve();
      } else {
        scrollRef.current.addEventListener('scroll', scrollHandler);
      }
    });
  }

  return (
    <div className="w-screen h-screen bg-white flex justify-center items-center">
      {loaded ? (
        <div className={`w-screen h-screen overflow-hidden scrollbar-hide `}>
          <div
            className={`w-full h-full overflow-auto scrollbar-hide`}
            ref={scrollRef}
            onScroll={(e) => {
              console.log('scroll');
              setScrollTop(e.target.scrollTop);
              if (e.target.scrollTop > 280) {
                // console.log('show header');
                setHeaderText(course.name);
              } else {
                // console.log('hide header');
                setHeaderText('');
              }
            }}
          >
            <div className="px-[20px] pt-[20px] pb-[30px] ">
              <ActivityMain
                courseData={activityData.find((item) => item.courseId === parseInt(id))}
                completed={course.activityList.filter((item) => item.isDone).length}
                maxCompleted={course.activityList.length}
                tools={course.activityList.filter((item) => item.toolId)}
                course={course}
                clickStart={clickStart}
                goPrecedingCourse={goPrecedingCourse}
              />

              {/* {!course.isPrecedingCourseDone && course.precedingCourse && (
                <div className="mt-[30px]">
                  <PrecedingCourse course={course} goPrecedingCourse={goPrecedingCourse} />
                </div>
              )} */}
            </div>
            <div className="w-full h-[8px] bg-[#FAFBFD]"></div>
            <ActivityList
              courseId={id}
              currentDay={course.currentDay}
              totalDays={course.totalDays}
              activityList={course.activityList}
              isStartable={course.isStartable}
              scrollToResult={scrollToResult}
              scrollTop={scrollTop}
            />
          </div>

          <ActivityModal courseStart={courseStart} />
          {context.showActivityModal && (
            <div
              className={`bg-black ${
                context.showActivityModal ? 'opacity-[0.5]' : 'opacity-0'
              } absolute top-0 w-full h-full overflow-auto  z-[20]`}
              onClick={() => {
                context.setShowActivityModal(false);
              }}
            />
          )}
        </div>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <DotLoader color="#3953D9" loading={true} size={80} />
        </div>
      )}
    </div>
  );
}

export default Activity;
