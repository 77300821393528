/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { RoundBottomAction } from '../../stories/page/RoundBottomAction';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import loomyComingSoon from '../../image/loomy/loomyComingSoon.png';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';
import { useSearchParams } from 'react-router-dom';

export default function LoomyComingSoon(props) {
  const setHeaderState = useSetRecoilState(headerAtom);
  const setMixpanel = useMixpanelWeb({ eventName: '앱이 곧 출시돼요', projectName: 'loomy' });

  const [searchParams] = useSearchParams();

  const second = searchParams.get('2-2') === 'true';

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));
    setMixpanel();
  }, []);

  return (
    <RoundBottomAction
      content={
        <div className="h-full flex flex-col">
          <div className="flex flex-col gap-[12px] px-[20px] text-[#11383B]">
            <div className="pt-[12px] text-[24px] leading-[30px] font-bold">{'23년 1월, 앱이 곧 출시돼요'}</div>
            <div className="text-[17px] leading-[24px]">
              {'완성도를 높이는 마무리 작업을 진행하고 있어요. 출시 알림을 받으시려면 휴대폰 번호를 입력해주세요.'}
            </div>
          </div>
          <div className={`flex-1 flex flex-col items-center justify-center `}>
            <img src={loomyComingSoon} alt={'empty'} className={`w-[200px]`} />
          </div>
        </div>
      }
      noGradient
      buttonText={'출시 알림 신청하기'}
      buttonBgColor={'#11383B'}
      buttonTextColor={'#ffffff'}
      action={() => {
        if (!second) {
          window.location.href = 'https://ftwl7crbb3s.typeform.com/to/unHr57d3';
        } else {
          window.location.href = 'https://ftwl7crbb3s.typeform.com/to/d1UJ4cZq';
        }
      }}
      bgColor={'#F3F0E7'}
      fixedRoot
    />
  );
}
