import { useState, useRef, useEffect, createRef } from 'react';

export const useModalDrag = (initialState) => {
  const sheet = createRef();
  const [showModal, setShowModal] = useState(initialState);
  const [modalHeight, setModalHeight] = useState();
  const [modalSheetY, setModalSheetY] = useState();
  const metrics = useRef({
    touchStart: {
      sheetY: 0,
      touchY: 0,
    },
    touchMove: {
      prevTouchY: 0,
      movingDirection: 'none',
    },
  });

  // Touch Event 핸들러들을 등록한다.
  useEffect(() => {
    const handleTouchStart = (e) => {
      setShowModal(true);

      const { touchStart } = metrics.current;
      if (sheet.current) {
        touchStart.sheetY = sheet.current.getBoundingClientRect().y;
        touchStart.touchY = e.touches[0].clientY;
        sheet.current.style.removeProperty('transition');
      }
    };

    const handleTouchMove = (e) => {
      // console.log('touchmove', e.touches[0].clientY, modalSheetY);
      e.preventDefault();

      const { touchStart, touchMove } = metrics.current;
      const currentTouch = e.touches[0];

      if (touchMove.prevTouchY === undefined) {
        touchMove.prevTouchY = touchStart.touchY;
      }

      if (touchMove.prevTouchY < currentTouch.clientY) {
        touchMove.movingDirection = 'down';
      }

      if (touchMove.prevTouchY > currentTouch.clientY) {
        touchMove.movingDirection = 'up';
      }

      // 터치 시작점에서부터 현재 터치 포인트까지의 변화된 y값
      const touchOffset = currentTouch.clientY - touchStart.touchY;
      let nextSheetY = touchStart.sheetY + touchOffset;

      // nextSheetY 는 MIN_Y와 modalSheetY 사이의 값으로 clamp 되어야 한다
      if (nextSheetY <= modalSheetY) {
        nextSheetY = modalSheetY;
      }
      // sheet 위치 갱신.
      if (sheet.current) {
        sheet.current.style.setProperty('transform', `translateY(${nextSheetY - modalSheetY}px)`);
      }
    };

    const handleTouchEnd = (e) => {
      // Snap Animation

      if (sheet.current) {
        const currentSheetY = sheet.current.getBoundingClientRect().y;

        if (currentSheetY > modalSheetY + modalHeight * 0.15) {
          setShowModal(false);
          console.log('모달 내려 ');
        } else {
          setShowModal(true);
          console.log('모달 내리지마 ');
        }
        sheet.current.style.setProperty('transform', 'translateY(0px)');
        sheet.current.style.setProperty('transition', 'transform 0.2s');

        // metrics 초기화.
        metrics.current = {
          touchStart: {
            sheetY: 0,
            touchY: 0,
          },
          touchMove: {
            prevTouchY: 0,
            movingDirection: 'none',
          },
        };
      }
    };

    if (sheet && sheet.current) {
      setModalHeight(sheet.current.offsetHeight);
      setModalSheetY(window.innerHeight - sheet.current.offsetHeight);

      sheet.current.addEventListener('touchstart', handleTouchStart);
      sheet.current.addEventListener('touchmove', handleTouchMove);
      sheet.current.addEventListener('touchend', handleTouchEnd);

      return () => {
        if (sheet.current !== null) {
          sheet.current.removeEventListener('touchstart', handleTouchStart);
          sheet.current.removeEventListener('touchmove', handleTouchMove);
          sheet.current.removeEventListener('touchend', handleTouchEnd);
        }
      };
    }
  }, [sheet, modalSheetY, modalHeight]);

  return { sheet, showModal };
};
