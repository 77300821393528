import anxy1 from '../image/anxy1.png';
import anxy2 from '../image/anxy2.png';
import anxy3 from '../image/anxy3.png';
import anxy4 from '../image/anxy4.png';
import anxy5 from '../image/anxy5.png';
import anxy6 from '../image/anxy6.png';

import anxyType1 from '../image/anxy/type/anxyType1.png';
import anxyType2 from '../image/anxy/type/anxyType2.png';
import anxyType3 from '../image/anxy/type/anxyType3.png';
import anxyType4 from '../image/anxy/type/anxyType4.png';
import anxyType5 from '../image/anxy/type/anxyType5.png';
import anxyType6 from '../image/anxy/type/anxyType6.png';

import introThumbnail1 from '../image/anxy/introThumbnail1.png';
import introThumbnail2 from '../image/anxy/introThumbnail2.png';
import introThumbnail3 from '../image/anxy/introThumbnail3.png';
import introThumbnail4 from '../image/anxy/introThumbnail4.png';
import introThumbnail5 from '../image/anxy/introThumbnail5.png';
import introThumbnail6 from '../image/anxy/introThumbnail6.png';

const anxyTypeData = [
  //feedback
  {
    type: 1,
    name: '불확실성 공포자',
    sentence: '걱정이 많고 긴장해 있는',
    summary: [
      '미래에 무슨 일이 일어날 것만 같아 불안해요',
      '무방비 상태에서 나쁜 일을 겪을까 두려워해요',
      '앞으로 일어날 일 때문에 항상 긴장해 있어요',
    ],

    image: anxyType1,
    bgColor: '#FCE7D8',
    explanation:
      '미래에 대해 항상 걱정하는 타입입니다. 특히 내가 충분히 준비되지 않은 상태에서 어떠한 일이 발생하는 것에 대해 매우 불안해하죠. 그래서 불확실성 공포자는 앞으로 일어날 일을 항상 예측하려고 하며 긴장해 있어요.\n\n예를 들어, 일기 예보에서 강수 확률이 낮은 걸 확인했음에도 퇴근길에 비가 올까 걱정하기도 하고, 출근길에 사고가 나 길이 막혀 지각할까 불안해하기도 하죠.\n\n하지만 안타깝게도 많은 일들이 우리의 예상 밖에 있지요. 그래서 불확실성 공포자는 항상 해결할 수 없는 걱정과 불안을 안고 살아갈 수밖에 없답니다.',
    solution:
      '불확실성 공포자는 미래에 일어날 일을 과대평가하면서 불안해해요. 현실적으로 발생할 확률이 적은 일 이지만, 일어날지도 모른다는 생각에 두려워하는 것이죠.\n\nAnxy는 불확실성 공포자를 위한 불안 관리 코스를 제공해요. 미래가 불확실하다는 점을 받아들이고 불확실한 상황 속에서도 평정을 유지할 수 있도록 Anxy가 도울게요.',
    introTitle: '작은 가능성에 매달리지 않는 법',
    introSubTitle: '항상 걱정이 많고 긴장해 있는 불확실성 공포자는 어떻게 불안을 다스릴 수 있을까요?',
    introLength: '3분',
    introThumbnail: introThumbnail1,
  },
  {
    type: 2,
    name: '완벽 추구자',
    sentence: '만족하지 못하고 지쳐있는',
    summary: [
      '높은 기준을 충족하기 위해 자신을 푸시해요',
      '무엇이든 조금 더 해서 완벽하게 끝내야 마음이 편해요',
      '일이 나의 통제를 벗어나면 불안해져요',
    ],
    image: anxyType2,
    bgColor: '#D6E2FC',
    explanation:
      '거의 모든 일에 불만족하는 타입입니다. 모든 일을 완벽하게 하려고 하지만 늘 마음처럼 잘 되지 않죠. 완벽 추구자는 무엇이든 조금 더 해야 나을 것 같다는 생각에 사로잡혀 과도한 에너지를 쓰기도 한답니다.\n\n예를 들어, 과제를 얼추 마무리했음에도 아직 부족하다는 생각에 마감 시간을 꽉 채워 계속 수정하기도 하고, 발표를 잘 해내지 못하면 사람들이 자신을 나쁘게 평가할까봐 두려워하며 떨기도 하죠.\n\n물론 가끔은 많은 일들을 완벽하게 처리하기도 하지만, 일이 본인의 통제에서 벗어나게 되는 순간 결국 큰 불안에 휩싸이게 됩니다.',
    solution:
      '완벽 추구자는 일을 완벽하게 해내지 못하면 부정적인 일이 일어날 거라 생각하며 불안해해요. 무언가 실수했을 때 남들에게 비치는 부정적인 모습과 평판을 두려워하는 것이죠.\n\nAnxy는 완벽 추구자를 위한 불안 관리 코스를 제공해요. 완벽하지 않아도 별 나쁜 일이 일어나지 않는다는 것을 경험할 수 있도록 Anxy가 도울게요.',
    introTitle: '"조금만 더"로부터 벗어나는 법',
    introSubTitle: '항상 스스로 만족하지 못하고 지쳐버리는 완벽 추구자는 어떻게 불안을 다스릴 수 있을까요?',
    introLength: '3분',
    introThumbnail: introThumbnail2,
  },
  {
    type: 3,
    name: '재앙적 사상가',
    sentence: '최악을 예상하고 걱정하는',
    summary: [
      '항상 최악을 상상하며 불안해해요',
      '별일 아닌 일이지만 자칫 크게 잘못될 것만 같아요',
      '최악의 상황을 대비하려고 애를 써요',
    ],
    image: anxyType3,
    bgColor: '#D8EADB',
    explanation:
      '항상 최악을 떠올리며 걱정하는 타입입니다. 작은 일이 발생해도 항상 큰 일이 뒤따라 발생할 것처럼 생각하죠. 재앙적 사상가는 작은 일이 발생해도 그 일이 틀어져 완전히 잘못되었을 때를 상상하며 걱정한답니다.\n\n예를 들어, 보고서에 낸 사소한 오타 때문에 회사에서 쫓겨날 것 같다고 걱정하기도 하고, 친구가 메시지를 읽지 않으면 친구가 나에게 단단히 화가 났다고 여기며 불안해하죠.\n\n재앙적 생각은 너무도 빨리 최악의 경우로까지 뻗어나가며, 이에 대해 대비하려고 애를 쓰게 됩니다. 하지만 대부분 대비할 수 없는 상황이기 때문에 대처하기 위해 노력할수록 점점 더 불안해지는 악순환을 겪게 됩니다.',
    solution:
      '재앙적 사상가는 미래에 대한 다양한 가능성 중 최악의 상황에만 집중하면서 불안해해요. 다양한 모습으로 전개될 수 있는 일임에도 특정 최악의 경우만 바라보며 두려워하는 것이죠.\n\nAnxy는 재앙적 사상가를 위한 불안 관리 코스를 제공해요. 최악의 경우가 발생할 현실적인 확률과 그 외 다양한 형태로 일이 전개될 수 있다는 것을 인지할 수 있도록 Anxy가 도울게요.',
    introTitle: '작은 일에도 크게 반응하지 않는 법',
    introSubTitle: '항상 최악을 떠올리며 걱정하는 재앙적 사상가는 어떻게 불안을 다스릴 수 있을까요?',
    introLength: '3분',
    introThumbnail: introThumbnail3,
  },
  {
    type: 4,
    name: '부담 감내자',
    sentence: '과하게 일하고 중압감에 시달리는',
    summary: [
      "'내가 할 수 있어'라며 모든 일을 떠맡아요",
      '남에게 도움을 청하거나 일을 나누는 것을 두려워해요',
      '도맡은 일의 짐을 스스로 견디며 압박을 느껴요',
    ],
    image: anxyType4,
    bgColor: '#FCE7D8',
    explanation:
      "자신에게 주어지는 압박을 전부 견뎌내는 타입입니다. '내가 다 할 수 있어'라고 이야기하며 모든 일을 떠맡게 되죠. 모든 일을 내가 다 완벽하게 해낼 수는 없지만, 부담 감내자는 남에게 도움을 청하는 것을 두려워하며 많은 일들을 스스로 해내려 애를 씁니다.\n\n예를 들어, 동료에게 일을 맡기는 것을 불안해하고 '이 일은 내가 해야 해'라며 결국 모든 일을 떠맡게 되곤 하죠. 그러다 결국에는 자신이 감당할 수 없을 만큼 불어난 일에 압박을 느끼며 '맡은 일을 잘 해내지 못할 것'이라는 생각에 다시 또 불안해지는 악순환을 겪죠.\n\n어찌어찌 도맡게 된 일을 해내더라도, 결국에는 과도한 일과 업무 때문에 번아웃, 스트레스, 공황을 겪게 되기도 합니다.",
    solution:
      "부담 감내자의 불안은 '내가 해야만 한다'는 강박적인 부담을 갖는 데에서 시작돼요. 아무도 그러라고 하지 않았음에도 자신이 해야만 한다는 사고에 사로잡혀 스스로 부담과 불안감을 떠안는 것이죠.\n\nAnxy는 부담 감내자를 위한 불안 관리 코스를 제공해요. 스스로 가진 강박적 부담을 인지하고 꼭 그러지 않아도 된다는 사실을 깨닫도록 Anxy가 도울게요.",
    introTitle: '"내가 해야만 해"로부터 벗어나는 법',
    introSubTitle: '항상 모든 것을 견디다 결국엔 무너져버리는 부담 감내자는 어떻게 불안을 다스릴 수 있을까요?',
    introLength: '3분',
    introThumbnail: introThumbnail4,
  },
  {
    type: 5,
    name: '감정 회피자',
    sentence: '자기방어적 태도로 괴로워하는',
    summary: [
      '스스로 드는 감정을 마주하기를 무서워해요',
      '자기방어적인 태도로 감정을 회피해요',
      '무언가 걱정돼 불안하지만 인정하거나 표현하지 않아요',
    ],
    image: anxyType5,
    bgColor: '#EBE3F3',
    explanation:
      '스스로 드는 감정을 외면하는 타입이에요. 자신의 감정을 마주하기를 두려워하는 것이죠. 감정 회피자는 자기방어적인 태도로 자신의 감정을 회피한답니다.\n\n예를 들어, 무언가 걱정이 되어 불안하지만 불안함을 인정하거나 표현하지 않죠. 이렇게 내면의 갈등은 점점 커지지만 마주할 용기가 없어 해결하지 못하고 괴로워하게 된답니다.\n\n얼핏 보면 감정 회피자는 속 편하게 사는 것처럼 보일 수도 있어요. 하지만 그들이 해결하지 않고 쌓아둔 감정은 내면에서 갈등과 혼란을 만들며, 결국 자신을 힘들게 만들게 됩니다.',
    solution:
      '감정 회피자의 불안은 자신의 감정을 객관적으로 마주하지 않는 데에서 시작해요. 불안한 감정을 마주하면 더 불안해진다고 생각하기 때문이죠.\n\nAnxy는 감정 회피자를 위한 불안 관리 코스를 제공해요. 반복적으로 발생하는 불안의 원인을 파악하고 이를 마주할 수 있도록 Anxy가 도울게요.',
    introTitle: '내 감정을 마주하고 이해하는 법',
    introSubTitle: '항상 감정을 외면하고 쌓아두며 자신을 괴롭히는 감정 회피자는 어떻게 불안을 다스릴 수 있을까요?',
    introLength: '3분',
    introThumbnail: introThumbnail5,
  },
  {
    type: 6,
    name: '자기 의심가',
    sentence: '추측하고 머뭇거리는',
    summary: [
      '스스로에 대해 확신이 없어요',
      '내가 틀릴지도 모른다는 생각에 실천하기 두려워요',
      '나의 선택으로 문제가 발생할 것 같아 불안해요',
    ],
    image: anxyType6,
    bgColor: '#FDF6DA',
    explanation:
      "계속해서 자신을 의심하는 타입이에요. 스스로에 대한 확신이 부족한 것이죠. 자기 의심가는 스스로에 대한 확신이 없어 어떠한 일을 하려고 할 때마다 불안감을 느낀답니다.\n\n예를 들어, 나의 계획과 생각에 확신이 없어 실천하기를 두려워하기도 하고, 내 의견이 틀릴지도 모른다는 두려움에 회의서 의견을 내기보다는 남의 의견을 따르는 것을 선택하기도 하죠.\n\n이렇게 나의 의견을 숨기고 남의 의견에만 동조하며 '나로 인한 불확실함'을 없애려는 시도가 반복되면, 수동적이고 의존적인 생활을 하며 결국엔 자존감이 낮아지게 되기도 합니다.",
    solution:
      '자기 의심가는 나의 선택으로 문제가 발생할 거라 생각하며 불안해해요. 내 생각대로 일을 진행하면 일이 잘못되고 심지어는 다른 사람에게도 해를 끼치게 될지도 모른다고 생각하기 때문이죠.\n\nAnxy는 자기 의심가를 위한 불안 관리 코스를 제공해요. 자신이 결정을 내리더라도 일이 그릇되지 않는다는 것 깨닫고 이를 스스로 실천할 수 있도록 Anxy가 도울게요.',
    introTitle: '나 자신에게 확신을 갖는 법',
    introSubTitle: '항상 자신을 믿지 못하고 갈팡질팡하는 자기 의심가는 어떻게 불안을 다스릴 수 있을까요?',
    introLength: '3분',
    introThumbnail: introThumbnail6,
  },
];

export default anxyTypeData;
