/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { BottomActionAnxy } from '../../../stories/page/BottomActionAnxy';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../../recoil/common/header/atom';
import userAtom from '../../../recoil/anxy/user/atom';
import congrats_particle from '../../../image/congrats_particle.json';
import Lottie from '../../../components/elements/Lottie';
import { motion } from 'framer-motion';
import useMixpanelEventAnxy from '../../../hooks/useMixpanelEventAnxy';

export default function Level(props) {
  const goHome = useNavigateWithParams(`/anxyHome`);

  const setHeaderState = useSetRecoilState(headerAtom);
  const setUserState = useSetRecoilState(userAtom);
  const userState = useRecoilValue(userAtom);
  const userLevel = userState.level;

  const setMixPanel = useMixpanelEventAnxy({
    eventName: '레벨',
  });

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE, headerTitle: '' }));
    setMixPanel();
  }, []);

  return (
    <BottomActionAnxy
      content={
        <div className="w-full h-full flex flex-col justify-between px-[20px] text-[#26282C] pb-[20px]">
          <div className="w-full flex-1 flex flex-col items-center justify-center gap-[10px] font-bold relative">
            {userState.isMissionComplete && (
              <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[50] w-full pointer-events-none">
                <Lottie lottieData={congrats_particle} autoplay />
              </div>
            )}
            <div className=" text-[40px]  text-center ">Level</div>
            <div className="relative">
              <div
                className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-[50px] text-white
              drop-shadow-[0_2px_4px_rgba(0,0,0,0.25)]"
              >
                {userLevel && userLevel.level}
              </div>
              <svg width="130" height="144" viewBox="0 0 130 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M54.9519 2.7735C61.1399 -0.799151 68.7639 -0.799152 74.9519 2.7735L119.904 28.7265C126.092 32.2992 129.904 38.9017 129.904 46.047L129.904 97.953C129.904 105.098 126.092 111.701 119.904 115.273L74.9519 141.226C68.7639 144.799 61.1399 144.799 54.9519 141.226L10 115.274C3.81198 111.701 -1.83158e-06 105.098 -2.14391e-06 97.953L-4.4128e-06 46.047C-4.72513e-06 38.9017 3.81197 32.2992 9.99999 28.7265L54.9519 2.7735Z"
                  fill="#F8CF46"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M57.4584 19.3864C62.1205 16.6748 67.8795 16.6748 72.5416 19.3864L106.542 39.162C111.159 41.8477 114 46.7864 114 52.1282L114 91.8718C114 97.2136 111.159 102.152 106.542 104.838L72.5416 124.614C67.8795 127.325 62.1205 127.325 57.4584 124.614L23.4584 104.838C18.8408 102.152 16 97.2136 16 91.8718L16 52.1282C16 46.7864 18.8408 41.8477 23.4584 39.162L57.4584 19.3864Z"
                  fill="#DFB116"
                />
              </svg>
            </div>
          </div>
          <div className="p-[20px] bg-white rounded-[16px] text-center">
            <p className=" texxt-[17px] leading-[23px] font-bold mb-[6px]">Level up 미션</p>
            <p className="text-[24px]  mb-[15px]">
              <span className="font-bold">연속 {userLevel && userLevel.currentExperience}일</span>
              <span className=" text-[#A4A6B0]">/{userLevel && userLevel.requiredExperience}일</span>
            </p>

            <div className="flex gap-[3px] mb-[20px]">
              {Array.from({ length: userLevel.requiredExperience }, () => 0).map((each, index) => (
                <div
                  key={`level${index}`}
                  className={`flex-1 h-[10px] ${
                    index === 0
                      ? 'rounded-l-[10px]'
                      : index === userLevel.requiredExperience - 1
                      ? 'rounded-r-[10px]'
                      : ''
                  } bg-[#E1E4EB] overflow-hidden`}
                >
                  <motion.div
                    className={`w-full h-full ${index < userLevel.currentExperience ? 'bg-[#398047]' : 'bg-[#E1E4EB]'}`}
                    initial={{ width: userState.isMissionComplete ? '0%' : '100%' }}
                    animate={{ width: '100%' }}
                    transition={{ duration: 0.7, delay: 1 }}
                  />
                </div>
              ))}
            </div>

            <div className="w-full p-[15px]  bg-[#F3F5F8] rounded-[10px] text-[15px] leading-[21px] ">
              Level {userLevel.level + 1}가 되면 <span className="font-bold">최대 체력 +{userLevel.heartBenefit}</span>
            </div>
          </div>
        </div>
      }
      noHeader
      noGradient
      buttonText={'확인'}
      action={() => {
        setUserState((state) => ({ ...state, isMissionComplete: undefined }));
        goHome();
      }}
      bgColor={'#F3F5F8'}
    />
  );
}
