import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { TextArea } from '../../stories/text/TextArea';
import { Title } from '../../stories/text/Title';
import { keyboardOpen, close } from '../../utils/webview';
import { CoachingContext } from '../../coachingContext';
import useFetch from '../../hooks/useFetch';

export default function MarketingReason() {
  const context = useContext(CoachingContext);
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [buttonState, setButtonState] = useState('INACTIVE');
  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);
  const [windowSize, setWindowSize] = useState(window.innerHeight);
  const [loaded, setLoaded] = useState(false);
  const [stopFocus, setStopFocus] = useState(false);
  const [forceBlur, setForceBlur] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    setLoaded(true);
    keyboardOpen();

    window.visualViewport.addEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    console.log('resize', window.visualViewport.height, window.clientHeight);
    setViewportSize(window.visualViewport.height);
    setWindowSize(window.innerHeight);
    if (window.android) {
      if (window.innerHeight < windowSize) {
        setKeyboardOpen(true);
      } else {
        setKeyboardOpen(false);
      }
    } else {
      if (window.visualViewport.height < viewportSize) {
        setKeyboardOpen(true);
      } else {
        setKeyboardOpen(false);
      }
    }
  };

  // useEffect(() => {
  //   console.log('stopfocus change: ', stopFocus);
  //   if (stopFocus) {
  //     setTimeout(() => {
  //       setForceBlur(true);
  //     }, 100);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stopFocus]);
  const [submitResult, submitMarketingChannel] = useFetch({
    url: `/survey/acquisition-channel/submit`,
    requestBody: JSON.stringify({ acquisitionChannelList: context.acquisitionChannel, recommendReason: value }),
    bg: '#FFFFFF',
  });

  // useEffect(() => {
  //   if (forceBlur) {
  //     submitMarketingChannel();
  //     setButtonState('LOADING');
  //   }
  // }, [forceBlur]);

  useEffect(() => {
    if (submitResult) {
      close({ refresh: true });
    }
  }, [submitResult]);

  function updateValue(value) {
    if (value.length > 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
    console.log(value);
    setValue(value);
  }

  function submit() {
    submitMarketingChannel();
    setButtonState('LOADING');
    // setStopFocus(true);
  }

  return (
    <motion.div
      animate={{
        height: window.android ? `${windowSize}px` : `${viewportSize}px`,
        paddingBottom: !isKeyboardOpen && context.biggerMargin ? `34px` : `20px`,
      }}
      transition={{ ease: 'circOut', duration: loaded ? 0.15 : 0 }}
      className={` px-[20px] pt-[7px] flex flex-col justify-between`}
    >
      <div className="flex-1 flex flex-col">
        <div className="mb-[40px]">
          <Title title={'인사이드의 어떤 점을 추천 받았나요?'} />
        </div>
        <div className="flex-1  pb-[40px]">
          <TextArea
            placeholder={'지인에게 어떤 점을 추천 받았는지 적어주세요.'}
            value={value}
            // stopFocus={stopFocus}
            // forceBlur={forceBlur}
            autofocus={true}
            updateValue={updateValue}
          />
        </div>
      </div>
      <PrimaryActionButton state={buttonState} text={'다음'} action={submit} />
    </motion.div>
  );
}
