import webviewToast from 'development/webviewToast';
import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useFetch from '../hooks/useFetch';

export default function PaymentConfirmTemplate({ confirmUrl, sucessUrlPath, failUrlPath, loadingComponent, bgColor }) {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const payAmount = searchParams.get('amount');
  const paymentKey = searchParams.get('paymentKey');
  const navigate = useNavigate();

  const [confirmPaymentResult, confirmPayment] = useFetch({
    url: confirmUrl,
    requestBody: JSON.stringify({ orderId: orderId, payAmount: payAmount, paymentKey: paymentKey }),
    bg: '#FFFFFF',
  });

  useEffect(() => {
    confirmPayment();
  }, []);

  useEffect(() => {
    if (confirmPaymentResult) {
      if (confirmPaymentResult.code === 0) {
        if (confirmPaymentResult.result.status === 'SUCCESS') {
          webviewToast('SUCCESS');
          navigate(sucessUrlPath, { replace: true });
        } else {
          webviewToast('FAIL');
          navigate(failUrlPath, {
            replace: true,
            state: { failureMessage: confirmPaymentResult.result.failureMessage },
          });
        }
      }
    }
  }, [confirmPaymentResult]);

  return (
    <div
      className="w-screen h-screen flex justify-center items-center"
      style={{ backgroundColor: bgColor || '#ffffff' }}
    >
      {loadingComponent}
    </div>
  );
}
