import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from '../../../utils/axios2';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { CheckLottie } from '../../../stories/animation/CheckLottie';
import webviewToast from '../../../development/webviewToast';
import { CoachingContext } from '../../../coachingContext';
import usePostWorkbookDone from '../../../hooks/usePostWorkbookDone';

const defaultValue = {
  inBed: {
    endDateTime: '',
    startDateTime: '',
  },
  ruleList: [],
  napTimeMinutes: 0,
  sleep: {
    endDateTime: '',
    startDateTime: '',
  },
  struggleTimeMinutes: 0,
};

export default function SurveyDone({ notHasCas, isMarketing }) {
  const { id, page } = useParams();
  const [sleepingSurvey, setSleepingSurvey] = useSessionStorage('sleepingSurvey', defaultValue);
  const [isCasFirst, setIsCasFirst] = useSessionStorage('isCasFirst', false);

  const context = useContext(CoachingContext);
  const [searchParams] = useSearchParams();

  console.log('설문 내용: ', sleepingSurvey);

  const navigate = useNavigate();

  const goReport = () => {
    context.setShowProgressingbar(false);

    setTimeout(() => {
      if (isMarketing) {
        navigate(`/sleepChallengers/${parseInt(id) + 1}`);
      } else {
        navigate(`/sleepingReport${window.location.search}`);
      }
    }, 500);
  };

  const postWorkbookDone = usePostWorkbookDone(goReport);

  const postCasSubmit = async () => {
    try {
      let values = sleepingSurvey;
      const data = {
        isNoDrinkAlcohol: false,
        isNoDrinkCoffee: false,
        isNoSmoking: false,
      };
      if (values.casList && values.casList.length !== 0) {
        values.casList.forEach((item) => {
          data[item.type] = true;
        });
        console.log('data is', data);
        const filteredData = Object.keys(data).reduce((obj, key) => {
          console.log(data[key]);
          if (data[key]) {
            obj[key] = data[key];
          }
          return obj;
        }, {});

        console.log(filteredData);
        if (Object.keys(filteredData).length !== 0) {
          await axios(3, searchParams.get('at'), searchParams.get('app_id'))
            .post('sleep/hygiene/cas/submit', JSON.stringify(data))
            .then((response) => {
              webviewToast('cas save success');
              postReportSave();
            });
        } else {
          postReportSave();
        }
      } else {
        await axios(3, searchParams.get('at'), searchParams.get('app_id'))
          .post('sleep/hygiene/cas/submit', JSON.stringify(data))
          .then((response) => {
            webviewToast('cas save success');
            postReportSave();
          });
      }
    } catch (error) {
      webviewToast('cas save error' + error);
      postReportSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const postReportSave = useCallback(async () => {
    try {
      let values = sleepingSurvey;
      delete values.casList;
      if (values.ruleList && values.ruleList.length < 3) {
        delete values.ruleList;
      }
      console.log('ruleList is', values.ruleList);
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(`/sleep/record/save`, values)
        .then((response) => {
          postWorkbookDone();
          webviewToast('report save success');
        });
    } catch (error) {
      webviewToast('report save error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    context.setShowProgressingbar(false);
    setTimeout(() => {
      if (isMarketing) {
        goReport();
      } else {
        if (notHasCas || !isCasFirst) {
          postReportSave();
        } else {
          postCasSubmit();
        }
      }
    }, 500);
  }, []);

  return (
    <div className="w-full h-[calc(100vh-17px)] flex flex-col items-center justify-center text-center ">
      <div className="">
        <CheckLottie containerSize={'77px'} gradient />
      </div>
      <p className=" font-bold text-[24px] leading-[30px] text-[#26282C]  mt-[30px] ">{'기록을 마쳤어요'}</p>
    </div>
  );
}
