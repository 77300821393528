import MessageBlock from '../../stories/workbook/MessageBlock';
import MessageBlockWithTail from '../../stories/workbook/MessageBlockWithTail';

export default function MessageBlockAndTail({ types, textList, opposite, my, margin }) {
  return (
    <div className={`${my ? 'my-40px' : margin ? margin : ''}`}>
      {textList.map((item, index) => (
        <div className={`${index !== textList.length - 1 ? (index % 2 === 0 ? 'mb-[10px]' : 'mb-[24px]') : ''}`}>
          {index % 2 === 0 ? (
            <MessageBlock textList={[item]} type={types[0]} />
          ) : (
            <MessageBlockWithTail textList={[item]} type={types[1]} opposite={opposite} />
          )}
        </div>
      ))}
    </div>
  );
}
