/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import SleepGraph from '../../../components/sleepingReport/SleepGragh3';
import NapGraph from '../../../components/sleepingReport/NapGraph';
import StruggleGraph from '../../../components/sleepingReport/StruggleGraph';
import SleepRule from '../../../components/sleepingReport/SleepRule';
import { BottomAction } from '../../../stories/page/BottomAction';
import useSessionStorage from '../../../hooks/useSessionStorage';
import moment from 'moment';

export default function ChallengersReport() {
  const context = useContext(CoachingContext);

  const [sleepingSurvey] = useSessionStorage('sleepingSurvey');

  const [searchParams] = useSearchParams();
  const navigation = searchParams.get('navigation') || 'none';

  const scrollRef = useRef();
  const contentRef = useRef();
  const [isNavigate, setIsNavigate] = useState(false);

  useEffect(() => {
    setIsNavigate(false);
    context.setShowProgressingbar(false);

    const root = document.getElementById('root');
    root.style.position = 'relative';
    root.style.overflow = 'auto';
  }, []);

  const recordByDateList = [
    {
      date: moment(new Date()).format('YYYY-MM-DD'),
      inBed: sleepingSurvey.inBed,
      sleep: sleepingSurvey.sleep,
      napTimeMinutes: sleepingSurvey.napTimeMinutes,
      struggleTimeMinutes: sleepingSurvey.struggleTimeMinutes,
      ruleList: sleepingSurvey.ruleList,
    },
  ];

  const example = [
    {
      date: '2022-07-25',
      inBed: { startDateTime: '2022-07-25T00:00', endDateTime: '2022-07-25T09:30' },
      sleep: { startDateTime: '2022-07-25T04:00', endDateTime: '2022-07-25T09:00' },
      napTimeMinutes: 300,
      struggleTimeMinutes: 180,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-26',
      inBed: { startDateTime: '2022-07-26T06:00', endDateTime: '2022-07-26T06:00' },
      sleep: { startDateTime: '2022-07-26T06:00', endDateTime: '2022-07-26T06:00' },
      napTimeMinutes: 270,
      struggleTimeMinutes: 240,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-27',
      inBed: { startDateTime: '2022-07-27T20:00', endDateTime: '2022-07-27T20:00' },
      sleep: { startDateTime: '2022-07-27T20:00', endDateTime: '2022-07-27T20:00' },
      napTimeMinutes: 150,
      struggleTimeMinutes: 0,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-28',
      inBed: { startDateTime: '2022-07-28T00:00', endDateTime: '2022-07-28T10:00' },
      sleep: { startDateTime: '2022-07-28T03:00', endDateTime: '2022-07-28T09:30' },
      napTimeMinutes: 210,
      struggleTimeMinutes: 50,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-29',
      inBed: { startDateTime: '2022-07-28T22:30', endDateTime: '2022-07-29T10:00' },
      sleep: { startDateTime: '2022-07-29T01:00', endDateTime: '2022-07-29T07:30' },
      napTimeMinutes: 0,
      struggleTimeMinutes: 40,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-30',
      inBed: { startDateTime: '2022-07-29T22:00', endDateTime: '2022-07-30T10:00' },
      sleep: { startDateTime: '2022-07-30T01:00', endDateTime: '2022-07-30T09:00' },
      napTimeMinutes: 90,
      struggleTimeMinutes: 30,
      ruleList: [
        { ruleId: 1, isDone: true },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-08-04',
      inBed: { startDateTime: '2022-08-04T00:00', endDateTime: '2022-08-04T09:30' },
      sleep: { startDateTime: '2022-08-04T01:00', endDateTime: '2022-08-04T09:00' },
      napTimeMinutes: 0,
      struggleTimeMinutes: 50,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
  ];

  return (
    <div className={`w-full h-full overflow-auto scrollbar-hide bg-[#323845] `} ref={scrollRef}>
      <div>
        <div className="py-[25px] bg-white text-center text-[#A4A6B0]">
          <p className="text-[15px] leading-[21px] mb-[10px]">챌린저님의 수면 일기</p>
          <p className={`text-[22px] leading-[30px] font-bold text-[#323845]`}>꾸준히 기록하셨군요! 💪</p>
        </div>
        <div
          className={`pt-[30px] ${
            navigation !== 'back' ? 'pb-[40px]' : context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'
          } px-[20px]  bg-[#F3F5F8]`}
          ref={contentRef}
        >
          <div>
            <div className="pb-[0px]">
              <div className="flex flex-col gap-[10px] ">
                {sleepingSurvey.ruleList.filter((element) => !element.isDone).length > 0 && (
                  <div className="pb-[50px]">
                    <p className="text-[20px] leading-[26px] text-[#26282C] font-bold mb-[20px]">🏃 챌린지 진행 상황</p>
                    <div className="flex flex-col gap-[10px] ">
                      {sleepingSurvey.ruleList
                        .filter((element) => element.ruleId === 1)
                        .map((rule, index) => (
                          <SleepRule
                            key={`rule${index}`}
                            id={rule.ruleId}
                            isChallenge
                            today={recordByDateList[recordByDateList.length - 1].date}
                            data={recordByDateList
                              .filter(
                                (each) =>
                                  each.ruleList && each.ruleList.find((element) => element.ruleId === rule.ruleId),
                              )
                              .map((element) => ({
                                date: element.date,
                                isDone: true,
                              }))}
                            open
                          />
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <p className="text-[20px] leading-[26px] text-[#26282C] font-bold mb-[20px]">📊 수면 일기</p>
            <div className="flex flex-col gap-[10px] text-[12px] leading-[18px]">
              <SleepGraph recordByDateList={recordByDateList} dummyData={example} />
              <StruggleGraph recordByDateList={recordByDateList} dummyData={example} />
              <NapGraph recordByDateList={recordByDateList} dummyData={example} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
