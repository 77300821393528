import 'moment/locale/ko';
import React, { useEffect } from 'react';
import YouTube from 'react-youtube';

function YoutubeExamplePage() {
  useEffect(() => {}, []);

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      cc_load_policy: 1,
      color: 'white',
      fs: 1,
      wmode: 'opaque',
    },
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <YouTube videoId="ptysPnnms9I" opts={opts} />
    </div>
  );
}

export default YoutubeExamplePage;
