import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';

const CheckLottie = ({ delay }) => {
  const likecontainer = useRef();
  console.log(delay);
  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('../../image/homelottie.json'),
    });
    return () => instance.destroy();
  }, []);

  return (
    <Wrapper>
      <Lottie ref={likecontainer}></Lottie>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const Lottie = styled.div`
  width: 26px;
  height: 26px;
  position: absolute;
  left: 19px;
  top: 43px;
  z-index: 1000;
`;

export default CheckLottie;
