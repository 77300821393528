import React from 'react';

import { RectWithCenterImageKeepSize } from '../../stories/image/RectWithCenterImageKeepSize';
import { ProgressingBar } from '../../stories/else/ProgressingBar';
import toolData from '../../data/toolData';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import PrecedingCourse from './PrecedingCourse';
import SleepReportModule from '../sleepingReport/SleepReportModule';

export default function ActivityMain(props) {
  const { courseData, completed, maxCompleted, tools, course, clickStart, goPrecedingCourse } = props;

  return (
    <div>
      <div className="w-full ">
        <div className="mb-[30px]">
          <RectWithCenterImageKeepSize
            image={courseData && courseData.image}
            background={(courseData && courseData.backgroundColor) || '#dddddd'}
            height={'200px'}
          />
        </div>
        <div className="px-[4px]">
          <div className="text-[24px] leading-[28.8px] font-bold text-[#26282C] mb-[6px]">{course.name}</div>
          <div
            className={`flex items-center text-[15px] leading-[18px]  ${
              completed === 0 || completed === maxCompleted ? 'mb-[20px]' : 'mb-[15px]'
            }`}
          >
            <div className="mr-[6px]">
              <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.8">
                  <path
                    d="M12.4 10C12.4 10.64 11.909 11.3557 10.8117 11.9466C9.73886 12.5243 8.21551 12.9 6.5 12.9C4.78449 12.9 3.26114 12.5243 2.18827 11.9466C1.09095 11.3557 0.6 10.64 0.6 10C0.6 9.35999 1.09095 8.64427 2.18827 8.05341C3.26114 7.47571 4.78449 7.1 6.5 7.1C8.21551 7.1 9.73886 7.47571 10.8117 8.05341C11.909 8.64427 12.4 9.35999 12.4 10Z"
                    stroke="#4E4F53"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M6.5 2.5V9.5"
                    stroke="#4E4F53"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 3L6.5 5.16506L6.5 0.834936L11 3Z"
                    stroke="#4E4F53"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </div>
            <div className=" font-normal text-[#4E4F53] ">{course.totalDays}일 코스</div>
            {completed !== 0 && <div className=" w-[2px] h-[2px] mx-[5px] bg-[#3953D9] rounded-[2px]"></div>}
            {completed !== 0 && completed !== maxCompleted && (
              <div className={`font-semibold text-[#3953D9] `}>{course.currentDay}일차</div>
            )}
            {completed === maxCompleted && <div className={`font-semibold text-[#3953D9] `}>완료</div>}
          </div>

          {course.activityList.filter((item) => !item.isLock).length > 0 && (
            <div className="mb-[30px]">
              <ProgressingBar
                maxCompleted={maxCompleted}
                completed={completed}
                color={' radial-gradient(99.83% 1234.86% at 99.83% 68.01%, #5513F0 0%, #2C4BEC 100%)'}
              />
            </div>
          )}
          <div className="text-[15px] leading-[21px] text-[#4E4F53]  font-normal mb-[24px]">
            {course.mainDescription}
          </div>
          <div className="w-full h-[1px] bg-[#F3F5F8] mb-[20px]" />
          <div className="text-[17px] leading-[26px] text-[#26282C] font-semibold mb-[5px]">
            {course.subDescriptionTitle}
          </div>
          {course.subDescriptionBody.split('<br>').map((text, index) => (
            <div
              key={`body${index}`}
              className="text-[14px] leading-[20px] text-[#80838B] font-normal mb-[4px] flex items-start"
            >
              <div className="w-[3px] h-[3px] rounded-[3px] bg-[#80838B] mr-[5px] mt-[8px]" />

              {text}
            </div>
          ))}
          {tools.length > 0 && (
            <div
              className="w-full h-[40px] bg-[#EFF2FF] rounded-[6px] text-[14px] leading-[20px] font-semibold  text-[#2C4BEC]
                       mt-[15px] flex items-center justify-center"
            >
              <span className="mr-[5px] ">🏆</span>
              <p className="mt-[1px]">
                {`획득 도구 : ${tools
                  .map((tool) => toolData.find((element) => element.toolId === tool.toolId).title)
                  .join(', ')}`}
              </p>
            </div>
          )}
          {course.isStartable && (
            <div className="mt-[30px]" onClick={clickStart}>
              <PrimaryActionButton state={'ACTIVE'} text={'시작하기'} action={() => {}} />
            </div>
          )}
          {course.courseId === 'course-5' && (
            <div className="mt-[30px]">
              <SleepReportModule />
            </div>
          )}
          {!course.isPrecedingCourseDone && course.precedingCourse && (
            <div className="mt-[30px]">
              <PrecedingCourse course={course} goPrecedingCourse={goPrecedingCourse} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
