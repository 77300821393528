import React from 'react';
import anxyWithWorri from '../../../image/anxy/tool/anxyWithWorri.png';
import { useEffect } from 'react';
import { meditationThemeData } from '../../../data/anxy/meditationThemeData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import statusAtom from '../../../recoil/anxy/home/statusAtom';
import sessionAtom from '../../../recoil/anxy/session/atom';

export function SelectThemeWithButton({ setButtonState, theme }) {
  const statusState = useRecoilValue(statusAtom);
  const setSessionState = useSetRecoilState(sessionAtom);

  const handleLeft = () => {
    if (theme.id === 1) {
      setSessionState((state) => ({ ...state, theme: meditationThemeData[3] }));
    } else {
      setSessionState((state) => ({ ...state, theme: meditationThemeData[theme.id - 2] }));
    }
  };
  const handleRight = () => {
    if (theme.id === 4) {
      setSessionState((state) => ({ ...state, theme: meditationThemeData[0] }));
    } else {
      setSessionState((state) => ({ ...state, theme: meditationThemeData[theme.id] }));
    }
  };

  const LeftArrow = () => (
    <div className="w-[50px] h-[50px] relative" onClick={handleLeft}>
      <svg
        className="absolute top-[50%] left-[50%]  translate-x-[-50%] translate-y-[-50%]"
        width="10"
        height="17"
        viewBox="0 0 10 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 16L1 8.5L9 1" stroke="#80838B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  );

  const RightArrow = () => (
    <div className="w-[50px] h-[50px] relative" onClick={handleRight}>
      <svg
        className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
        width="10"
        height="17"
        viewBox="0 0 10 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 16L9 8.5L1 1" stroke="#80838B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  );

  useEffect(() => {
    if (!statusState.isPaymentRequired) {
      if (theme.id !== 1) {
        setButtonState('INACTIVE');
      } else {
        setButtonState('ACTIVE');
      }
    } else {
      setButtonState('ACTIVE');
    }
  }, [theme]);

  return (
    <div className="w-full flex flex-col justify-end h-full ">
      <div
        className="flex justify-center absolute bottom-[160px] w-full z-[10] m-auto"
        style={{
          height: 'calc(100vh - 320px)',
          background: 'linear-gradient(180deg, #F3F5F800 25%, #B8BDC6 77.81%, #F3F5F800 92.85%)',
        }}
      >
        <div className=" min-w-[375px] absolute top-[50%] translate-y-[-50%] px-[13.3%]">
          <img
            src={anxyWithWorri}
            alt="empty"
            className="w-full  object-contain"
            style={{
              height: 'calc(100vh - 320px)',
            }}
          />
        </div>
      </div>
      <div className="h-screen absolute top-0 left-[0px]">
        <img src={theme.image} alt="empty" className=" h-full object-cover" />
      </div>
      <div
        className="absolute bottom-0 w-full h-[500px] z-[0]"
        style={{
          background: 'linear-gradient(180deg, rgba(243, 245, 248, 0) 0%, #F3F5F8 59.23%)',
        }}
      />
      <div className="relative z-[50] top-0 flex justify-between items-center w-full px-[30px] pb-[40px]">
        <LeftArrow />
        {statusState.isPaymentRequired ? (
          <div className="flex items-center">
            {theme.isNotReady && (
              <svg
                className="mr-[4px]"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.78622 10.873H15.2148V7.86695C15.2148 6.0885 13.7757 4.64678 12.0005 4.64678C10.2253 4.64678 8.78622 6.0885 8.78622 7.86695V10.873ZM6.64336 10.885C6.17386 10.9044 5.85749 10.9558 5.59202 11.091C5.21569 11.2828 4.90973 11.5887 4.71799 11.9651C4.5 12.3929 4.5 12.9529 4.5 14.073V18.2996C4.5 19.4197 4.5 19.9798 4.71799 20.4076C4.90973 20.7839 5.21569 21.0899 5.59202 21.2816C6.01984 21.4996 6.57989 21.4996 7.7 21.4996H16.3C17.4201 21.4996 17.9802 21.4996 18.408 21.2816C18.7843 21.0899 19.0903 20.7839 19.282 20.4076C19.5 19.9798 19.5 19.4197 19.5 18.2996V14.073C19.5 12.9529 19.5 12.3929 19.282 11.9651C19.0903 11.5887 18.7843 11.2828 18.408 11.091C18.1427 10.9559 17.8266 10.9045 17.3577 10.885V7.86695C17.3577 4.90287 14.9592 2.5 12.0005 2.5C9.04184 2.5 6.64336 4.90287 6.64336 7.86695V10.885Z"
                  fill="#26282C"
                />
              </svg>
            )}

            <p className="text-[22px] leading-[28px] text-[#26282C] font-bold">{theme.title}</p>
          </div>
        ) : (
          <div className="relative">
            {theme.isNotReady && (
              <div className="absolute top-[-30px] left-[50%] translate-x-[-50%] mx-auto z-[50] bg-black w-[60px] h-[22px] flex justify-center rounded-[20px] mb-[0px]">
                <p className="text-white text-[14px] leading-[20px]">준비 중</p>
              </div>
            )}
            <p className="text-[22px] leading-[28px] text-[#26282C] font-bold">{theme.title}</p>
          </div>
        )}
        <RightArrow />
      </div>
    </div>
  );
}
