import workbookMessageColorData from '../../data/workbookMessageColorData';

export default function MessageBlockWithBold({ textList, my, margin, evenMargin }) {
  return (
    <div className={`${my ? 'my-[40px]' : margin ? margin : 'mt-[40px]'} `}>
      {textList.map((texts, index) => (
        <div
          className={`${
            index !== textList.length - 1
              ? index % 2 === 0
                ? 'mb-[10px]'
                : evenMargin
                ? 'mb-[24px]'
                : 'mb-[10px]'
              : ''
          }`}
        >
          {texts.tail === undefined || !texts.tail ? (
            <div
              key={`messageBlock${index}`}
              className={` text-[17px] leading-[24px] flex items-center rounded-[16px] mx-[25px] px-[20px] pt-[12px] pb-[14px] ${
                index !== textList.length - 1 ? 'mb-[10px]' : ''
              }`}
              style={{
                backgroundColor: workbookMessageColorData.find((element) => element.type === (texts.type || 'gray'))
                  .backgroundColor,
                color: workbookMessageColorData.find((element) => element.type === (texts.type || 'gray')).color,
                border: workbookMessageColorData.find((element) => element.type === (texts.type || 'gray')).border,
              }}
            >
              <div>
                {texts.bold && <span className="font-bold">{texts.bold}</span>}
                {texts.text.map((text, index) =>
                  index % 2 === 0 ? (
                    <span key={`messageBlockText${index}`}>{text}</span>
                  ) : (
                    <span key={`messageBlockText${index}`} className={`bg-[#FFEB3564] pb-[1.5px] `}>
                      {text}
                    </span>
                  ),
                )}
              </div>
            </div>
          ) : (
            <div
              key={`messageBlockTail${index}`}
              className={`relative text-[17px] leading-[24px]  flex rounded-[16px] mx-[25px] px-[20px] pt-[12px] pb-[14px]
          ${index !== textList.length - 1 ? 'mb-[10px]' : ''}`}
              style={{
                backgroundColor: workbookMessageColorData.find(
                  (element) => element.type === (texts.type || 'white_dotted'),
                ).backgroundColor,
                color: workbookMessageColorData.find((element) => element.type === (texts.type || 'white_dotted'))
                  .color,
                border: workbookMessageColorData.find((element) => element.type === (texts.type || 'white_dotted'))
                  .border,
              }}
            >
              <div>
                {texts.bold && <span className="font-bold">{texts.bold}</span>}
                {texts.text.map((text, index) =>
                  index % 2 === 0 ? (
                    <span key={`messageBlockText${index}`}>{text}</span>
                  ) : (
                    <span key={`messageBlockText${index}`} className={`bg-[#FFEB3564] pb-[1.5px] `}>
                      {text}
                    </span>
                  ),
                )}
              </div>

              <img
                className={`h-[19px] absolute bottom-0 ${
                  ['gray'].includes(texts.type)
                    ? 'right-[-8.5px]'
                    : ['black'].includes(texts.type)
                    ? 'right-[-9px]'
                    : ['white'].includes(texts.type)
                    ? 'right-[-9.5px]'
                    : 'right-[-10px]'
                }`}
                src={workbookMessageColorData.find((element) => element.type === (texts.type || 'white_dotted')).tail}
                alt="empty"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
