import React, { useContext, useEffect } from 'react';
import selfCheckData from '../../data/selfCheckData';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { SecondaryActionButton } from '../../stories/button/ActionButton';
import HomeHistoryGraph from './HomeHistoryGraph';
import useFetch from '../../hooks/useFetch';
import { AuthContext } from '../../context';
import { getSelfCheckState } from './selfCheckLogic';

export default function SelfCheckStartedCard(props) {
  const { selfCheckInfo } = props;
  const selfCheckResource = selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId);

  const today = new Date();
  const lastCheckDate = new Date(selfCheckInfo.date);
  var difference = Math.abs(today - lastCheckDate);
  const days = parseInt(difference / (1000 * 3600 * 24));

  const latestCheck =
    days === 0 ? '오늘' : days === 1 ? '어제' : days < 7 ? `${days}일 전` : `${parseInt(days / 7)}주 전`;

  const context = useContext(AuthContext);

  const [latestResult, getLatestResult] = useFetch({
    url: `/mindscan/latest-result/detail`,
    params: { self_check_id: selfCheckInfo.selfCheckId },
    bg: '#FFFFFF',
  });

  const goLatestResult = useNavigateWithParams(`/mindScan/${selfCheckInfo.selfCheckId}/result?button=false`);

  useEffect(() => {
    if (latestResult) {
      // console.log(latestResult.result);
      context.setSelfcheckResult(latestResult.result);
      const submitted = latestResult.result.latestResultSummaryList.map((each) => each.selfCheckId);

      const todayNotSubmitted = latestResult.result.latestResultSummaryList
        .filter(
          (element) =>
            parseInt(Math.abs(new Date() - new Date(element.date)) / (1000 * 3600 * 24)) > 0 &&
            element.selfCheckId !== selfCheckInfo.selfCheckId,
        )
        .sort(function (a, b) {
          return new Date(b.date) - new Date(a.date) || b.scorePercentage - a.scorePercentage;
        });
      // console.log('검사 내역 있음', todayNotSubmitted);

      const abnormal = todayNotSubmitted.filter((element) => element.isAbnormal);
      const normal = todayNotSubmitted.filter((element) => !element.isAbnormal);
      const noResult = selfCheckData.filter((element) => !submitted.includes(element.selfCheckId));

      // console.log('이상 있음', abnormal);
      // console.log('이상 없음', normal);
      // console.log('검사 내역 없음', noResult);

      context.setAvailableSelfCheck(abnormal.concat(normal).concat(noResult));
      goLatestResult();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestResult]);

  const goSelfCheck = useNavigateWithParams(`/mindScan/${selfCheckInfo.selfCheckId}/0`);

  return (
    <div className="w-full bg-white rounded-[20px]  pb-[20px]">
      <div
        className="w-full px-[20px]  pb-[20px] pt-[24px] flex justify-between items-center"
        onClick={getLatestResult}
      >
        <div className="flex items-center ">
          <img
            src={selfCheckResource.image_square}
            alt={'empty'}
            className="w-[40px] h-[40px] rounded-[40px] mx-auto mr-[10px]"
          />
          <div>
            <p className="text-[17px] leading-[23px] font-bold ">
              <span className="text-[#26282C]">{selfCheckInfo.selfCheckName} </span>
              <span className={`${selfCheckInfo.isAbnormal ? 'text-[#EF3E3E]' : 'text-[#2C4BEC]'}`}>
                {
                  selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).result[
                    getSelfCheckState(selfCheckInfo.selfCheckId, selfCheckInfo.score)
                  ].label
                }
              </span>
            </p>
            <p className="text-[15px] leading-[21px] font-normal text-[#6B6D76]">{latestCheck} 검사</p>
          </div>
        </div>
        <div>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.5 11L6.5 6L1.5 1"
              stroke="#80838B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="px-[20px]">
        <div className="h-[204px]">
          <HomeHistoryGraph selfCheckInfo={selfCheckInfo} />
        </div>
        <SecondaryActionButton
          state={days !== 0 ? 'ACTIVE' : 'INACTIVE'}
          text={days !== 0 ? `${selfCheckInfo.selfCheckName} 검사하기` : '오늘 검사를 마쳤어요'}
          action={goSelfCheck}
          medium
        />
      </div>
    </div>
  );
}
