/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import InsideCenterTitle from '../../components/inside/InsideCenterTitle';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { PATH } from '../../routes/paths';
import preview1 from '../../image/inside/preview_1.png';
import preview2 from '../../image/inside/preview_2.png';
import preview3 from '../../image/inside/preview_3.png';
import preview4 from '../../image/inside/preview_4.png';
import preview5 from '../../image/inside/preview_5.png';
import preview6 from '../../image/inside/preview_6.png';
import preview7 from '../../image/inside/preview_7.png';
import preview8 from '../../image/inside/preview_8.png';
import preview9 from '../../image/inside/preview_9.png';
import { TopTitleBottomActionInside } from '../../stories/page/TopTitleBottomActionInside';
import { useEffect } from 'react';
import TitleWithSelectButton from '../../components/inside/TitleWithSelectButton';
import InsideInputWithAdd from '../../components/inside/InsideInputWithAdd';
import ObjectiveResult from '../../components/inside/ObjectiveResult';
import { RegexInput } from '../../stories/text/RegexInput';
import webviewToast from '../../development/webviewToast';
import useFetchAnxy from '../../hooks/useFetchAnxy';
import answerIdList from '../../data/inside/answerIdList';
import useSessionStorage from '../../hooks/useSessionStorage';
import defaultState from '../../data/inside/defaultState';
import ObjectiveWithText from '../../components/inside/ObjectWithText';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';

export default function Preview({ resultList }) {
  const { id } = useParams();
  const setMixPanel4 = useMixpanelWeb({
    eventName: '4_information',
  });

  const setMixPanel5 = useMixpanelWeb({
    eventName: '5_information',
  });

  const setMixPanel6 = useMixpanelWeb({
    eventName: '6_cost',
  });
  const setMixPanel7 = useMixpanelWeb({
    eventName: '7_mentalpower',
  });

  const setMixPanel8 = useMixpanelWeb({
    eventName: '8_submit',
  });

  const [isError, setIsError] = useState(true);
  const [buttonText, setButtonText] = useState('다음');
  const [buttonState, setButtonState] = useState('ACTIVE');

  const [insideState, setInsideState] = useSessionStorage('insideState', defaultState);

  const filteredAnswerIdList = insideState.answerIdList.map((item) => item.id);
  let filteredState = { ...insideState };
  for (let key of Object.keys(insideState)) {
    if (filteredState[key] === undefined) {
      filteredState[key] = false;
    }
  }

  const [submit, setSubmit] = useFetchAnxy({
    url: '/black/survey/submit',
    requestBody: { ...insideState, answerIdList: filteredAnswerIdList },
  });

  useEffect(() => {
    if (id === '0') {
      setMixPanel4();
    } else if (id === '7') {
      setMixPanel5();
    } else if (id === '6') {
      setMixPanel6();
    } else if (id === '12') {
      setMixPanel7();
    } else if (id === '15') {
      setMixPanel8();
    }
  }, [id]);

  useEffect(() => {
    const root = document.getElementById('root');
    root.style.position = 'fixed';
    root.style.overflow = 'hidden';
  }, []);

  const goNext = useNavigateWithParams(`${PATH.marketing.insideBlackDefault}/resultList`);
  const goPreview = useNavigateWithParams(`${PATH.marketing.insideBlackDefault}/preview/${parseInt(id) + 1}`);
  const goSubmit = useNavigateWithParams(`${PATH.marketing.insideBlackDefault}/submit`);

  const updateValue = (value) => {
    setInsideState((state) => ({ ...state, mobileNumber: value }));
  };

  const Image = ({ image, css }) => {
    return (
      <div className="h-full flex justify-center items-center py-[20px] py-[40px]">
        <img alt="empty" src={image} className={`${css ? css : 'w-[225px]'} object-contain `} />
      </div>
    );
  };

  const contentList = [
    {
      content: (
        <div className="h-full flex items-center justify-center">
          <InsideCenterTitle isFirst />
        </div>
      ),
    },
    {
      content: (
        <div className="h-full flex items-center justify-center">
          <InsideCenterTitle />
        </div>
      ),
    },
    {
      title: `인사이드는 잠재력을 깨우는 \n 퍼스널 코칭을 제공합니다`,
      subtitle: '코치는 심리학 석박사 이상, 5년 이상의 수련과 경력을 갖춘 소수 정예 심리전문가예요.',
      content: <Image image={preview1} />,
    },
    {
      title: `스스로를 뛰어넘기 위한 목표를 세우고`,
      content: <Image image={preview2} />,
    },
    {
      title: '1회 50분 코치와 만납니다',
      subtitle: '목표 달성을 위한 해결책을 모색하고, 꼭 필요한 연습과 시행착오를 잘 겪어낼 수 있도록 과제를 줄거예요.',
      content: <Image image={preview5} css="w-[179px]" />,
    },
    {
      title: `성공의 동력이 되는 멘탈력을 측정하고 관리합니다`,
      content: <Image image={preview3} />,
    },
    {
      title: '인생의 성공을 원한다면 나를 위해 투자하세요',
      subtitle: '소속 회사나 기관에서 지원한다면 무료로 이용할 수 있어요.',
      content: <Image image={preview9} css="w-full max-w-[375px] px-[20px]" />,
      isBottom: true,
    },
    {
      title: `먼저 목표를 설정할게요`,
      content: <Image image={preview4} css="w-full max-w-[375px] px-[20px]" />,
    },
    {
      title: '어떤 모습으로 변화하고 싶나요?',
      subtitle: '가장 원하는 목표 한 가지만 선택해주세요. 실제 코칭에서는 30개 이상의 목표가 있어요.',
      content: <TitleWithSelectButton data={answerIdList} setButtonState={setButtonState} />,
    },
    {
      title: '목표를 막는 장애물이 있나요?',
      subtitle: '코치와 함께 이겨내봐요. 잘 모르겠다면 비워두셔도 됩니다.',
      content: <InsideInputWithAdd name="worryList" placeholder="고민이나 어려운점이 있나요?" />,
    },
    {
      title: '코치와 함께 달성할 내 목표예요',
      content: (
        <div className="flex flex-col h-full justify-end w-full">
          <ObjectiveResult name="worryList" placeholder="고민이나 어려운점이 있나요?" />
        </div>
      ),
    },
    {
      title: '이런 변화가 생겨요',
      content: (
        <div className="flex flex-col h-full justify-end w-full">
          <ObjectiveWithText name="worryList" placeholder="고민이나 어려운점이 있나요?" />
        </div>
      ),
    },
    {
      title: '이제 멘탈력을 측정할게요',
      content: <Image image={preview6} css="w-full max-w-[375px] px-[20px]" />,
    },
    {
      title: '5가지 멘탈력을 측정하는 30문항으로 구성되어 있어요',
      content: <Image image={preview8} css="w-full max-w-[375px] px-[20px]" />,
    },
    {
      title: '검사 후 리포트로 나의 강점과 약점을 파악할 수 있답니다',
      content: <Image image={preview7} css="w-full max-w-[375px] px-[20px]" />,
    },
    {
      title: '테스트는 앱에서 계속됩니다',
      subtitle:
        '입력해주신 휴대폰 번호로 앱 다운로드 링크를 보내드려요. 해당 링크로 계속하시면, 멘탈력 테스트를 이어서 할 수 있어요.',
      content: (
        <div className="px-[20px] pt-[50px]">
          <RegexInput
            value={insideState.mobileNumber}
            type="phone"
            updateValue={updateValue}
            placeholder="휴대폰 번호 (숫자만 입력해주세요)"
            updateError={setIsError}
          />
        </div>
      ),
    },
    {
      title: '접속이 많아 순서대로 처리하고 있어요',
      subtitle: '조금만 기다려주세요. 순서대로 문자를 보내드리고 있습니다.',
    },
  ];

  useEffect(() => {
    if (submit) {
      webviewToast('success');
      goSubmit();
    }
  }, [submit]);

  const handleSubmit = () => {
    setSubmit();
    setButtonState('LOADING');
    setTimeout(() => {
      setButtonState('ACTIVE');
    }, 2000);
  };

  useEffect(() => {
    if (parseInt(id) === 15) {
      setButtonState('INACTIVE');
    }
  }, [id]);

  useEffect(() => {
    if (parseInt(id) === 15) {
      if (insideState.mobileNumber && !isError) {
        setButtonState('ACTIVE');
      } else {
        setButtonState('INACTIVE');
      }
      setButtonText('제출하기');
    }
  }, [isError, insideState.mobileNumber]);

  // useEffect(() => {
  //   if (parseInt(id) === 5) {
  //     if (insideState.answerIdList.length === 0) {
  //       setButtonState('INACTIVE');
  //     } else {
  //       setButtonState('ACTIVE');
  //     }
  //   }
  // }, [insideState]);
  return (
    <div
      className="z-[100]"
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
        // minHeight: '-webkit-fill-available',
      }}
    >
      <TopTitleBottomActionInside
        title={contentList[parseInt(id)].title && contentList[parseInt(id)].title}
        subtitle={contentList[parseInt(id)].subtitle && contentList[parseInt(id)].subtitle}
        content={contentList[parseInt(id)].content}
        isBottom={contentList[parseInt(id)].isBottom}
        buttonText={buttonText}
        buttonState={buttonState}
        bgColor="#000000"
        action={() => {
          if (id === 'result') {
            goNext();
          } else if (parseInt(id) === 15) {
            handleSubmit();
          } else {
            goPreview();
          }
        }}
        insideBlack
      />
    </div>
  );
}
