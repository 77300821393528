import React, { useState, useEffect, useRef } from 'react';
import CounselorCard from '../components/counselor/CounselorCard';
import axios from '../utils/axios';
import { PrimaryActionButton } from '../stories/button/ActionButton';
import { changeHeader, getScrollEvent } from '../utils/webview';
import { showModal, scrollEvent, goToScreen, getRefresh } from '../utils/webview';
import { BUILD_TARGET } from '../config';

export default function Counselor() {
  const [counselorList, setCounselorList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [previousScrollTop, setPreviousScrollTop] = useState(0);
  const [buttonPosition, setButtonPosition] = useState(-74);
  const [isSticky, setIsSticky] = useState(false);
  const [isRefresh, setRefresh] = useState(false);

  const titleRef = useRef();
  const buttonRef = useRef();
  const scrollRef = useRef();
  const interviewRef = useRef();

  const isDev = BUILD_TARGET === 'dev';
  const isBeta = BUILD_TARGET === 'beta';

  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;

  const iOSMobile = /(iPhone|iPod|iPad)/i.test(navigator.platform);

  function refresh() {
    console.log('refresh', counselorList);

    setCounselorList([]);
    setPageNum(0);
    setPageLoaded(false);
    setPreviousScrollTop(0);
    setButtonPosition(-74);
    setIsSticky(false);
    setRefresh(true);
  }

  useEffect(() => {
    console.log('pageNum 바뀜', pageNum);
    if (isRefresh && pageNum === 0) {
      console.log('REFRESH----------------------------------------');
      setRefresh(false);
      getCounselor(pageNum);
    }
  }, [isRefresh, pageNum]);

  useEffect(() => {
    getRefresh(refresh);
    getScrollEvent(scrollRef);
  }, []);

  function showInstruction() {
    console.log('show tutorial');
    showModal({
      url: `${isDev ? `https://dev-m.inside.im/tutorial` : `https://m.inside.im/tutorial`}`,
      title: '이용안내',
    });
  }

  const getCounselor = async (pageNum) => {
    try {
      console.log('데이터 불러와 pageNum: ', pageNum);
      setDataLoaded(false);
      const response = await axios.get(`/counselor/v2/list?page_number=${pageNum}&size=${isDev ? 20 : 50}`);

      if (response.data.code === 0) {
        console.log('response: ', response, response.data.result.counselorList.length, pageNum);
        let result = response.data.result.counselorList;
        for (let i = 0; i < result.length; i++) {
          let parsed_interview = [];
          const temp = result[i].contents.body.split('\n');

          for (let j = 0; j < temp.length; j++) {
            parsed_interview.push(temp[j]);
            if (j < temp.length - 1) {
              parsed_interview.push(<br />);
            }
          }
          result[i].contents.body = parsed_interview;
        }
        console.log('original', counselorList, pageNum);
        const newCounselorList = counselorList.slice().concat(result);
        console.log('new', newCounselorList, pageNum);

        setCounselorList(newCounselorList);
        setDataLoaded(true);
        console.log('PAGENUM', pageNum);
        setPageNum((pageNum) => pageNum + 1);
      }
    } catch (error) {
      console.error('result', error);
    }
  };

  useEffect(() => {
    document.title = '상담';

    console.log('getCounselor');
    getCounselor(pageNum);
  }, []);

  return (
    <div className={`h-screen overflow-hidden  relative`}>
      {/* <div onClick={refresh}>refresh</div> */}

      <div
        className="w-screen h-full  bg-[#F3F5F8]  overflow-auto scrollbar-hide"
        ref={scrollRef}
        onScroll={(e) => {
          console.log('scrollevent: ', counselorList, pageNum);

          if (e.target.scrollTop > titleRef.current.offsetHeight) {
            changeHeader({ backgroundColor: '#FFFFFF', text: '상담' });
            if (e.target.scrollTop > titleRef.current.offsetHeight + buttonRef.current.offsetHeight) {
              if (e.target.scrollTop > previousScrollTop) {
              } else if (e.target.scrollTop < previousScrollTop) {
                setIsSticky(true);
              }

              if (buttonPosition + (previousScrollTop - e.target.scrollTop) <= -74) {
                setButtonPosition(-74);
              } else if (buttonPosition + (previousScrollTop - e.target.scrollTop) >= 0) {
                setButtonPosition(0);
              } else {
                setButtonPosition((buttonPosition) => buttonPosition + (previousScrollTop - e.target.scrollTop));
                // console.log(isSticky, 'buttonposition: ', buttonPosition + (previousScrollTop - e.target.scrollTop));
              }
            }
          } else {
            changeHeader({ backgroundColor: '#F3F5F8', text: '' });
            setIsSticky(false);

            setButtonPosition(-74);
          }

          if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - 1000 && !isRefresh) {
            if (!pageLoaded) {
              console.log('load more', counselorList, pageNum);

              getCounselor(pageNum);
              setPageLoaded(true);
            }
          } else {
            setPageLoaded(false);
          }
          if (isSafari || iOSMobile) {
            scrollEvent(e.target.scrollTop);
          }

          setPreviousScrollTop(e.target.scrollTop);
        }}
      >
        <div
          className="bg-[#F3F5F8]  pt-[12px] pl-[20px] pr-[15px] pb-[10px] flex items-center justify-between"
          ref={titleRef}
        >
          <p className="font-bold text-[24px] leading-[28.8px] text-[#26282C]">상담</p>
          <p
            className="font-normal text-[15px] leading-[18px] text-[#4E4F53] p-[5px]"
            onClick={showInstruction}
            // style={{ WebkitTapHighlightColor: 'rgba(38, 40, 44, 0.2)' }}
          >
            이용안내
          </p>
        </div>
        <div
          className={`w-full z-10 `}
          style={{ top: isSticky ? buttonPosition : '', position: isSticky ? 'sticky' : '' }}
        >
          <div ref={buttonRef}>
            <div className=" bg-[#F3F5F8] pt-[10px] px-[20px]">
              <PrimaryActionButton
                state={'ACTIVE'}
                text={
                  <div className="flex justify-center items-center">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.9404 11.5V11.5C10.9404 11.237 11.0577 10.991 11.2307 10.7929C11.7459 10.2035 12.058 9.43207 12.058 8.58776V6.35265C12.058 4.50103 10.5569 3 8.70531 3C6.85369 3 5.35265 4.50103 5.35265 6.35265V8.58776C5.35265 9.41994 5.65585 10.1813 6.15781 10.7674C6.34284 10.9835 6.4702 11.2493 6.4702 11.5337V11.7102C6.4702 12.1639 6.16576 12.5646 5.74408 12.7321C5.33458 12.8947 4.86071 13.1051 4.40551 13.3161C3.20858 13.871 2.28791 14.9175 2.10064 16.2235C2.04098 16.6395 2 17.0862 2 17.5282V19"
                        stroke="white"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                      />
                      <circle cx="13.9394" cy="13.9394" r="3.93939" stroke="white" strokeWidth="1.6" />
                      <path
                        d="M20 20L16.9697 16.9697"
                        stroke="white"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="ml-[3px]">선생님 찾아보기</p>
                  </div>
                }
                action={() => {
                  goToScreen({ screenName: 'counselorSearch' });
                }}
              />
            </div>
            <div
              className="w-full h-[10px]"
              style={{
                background: 'linear-gradient(to bottom, #F3F5F8, rgba(255,255,255,0))',
              }}
            ></div>
          </div>
        </div>
        <div ref={interviewRef} className="pt-[10px] pb-[20px]">
          {counselorList.length > 0 &&
            counselorList.map((counselor, index) => (
              <div key={index} className="px-[20px] mb-[20px]">
                <CounselorCard
                  id={counselor.counselorId}
                  title={counselor.contents.title}
                  interview={counselor.contents.body}
                  image={counselor.profileImageUrl}
                  introduce={counselor.introduce}
                  name={counselor.name}
                  dataLoaded={dataLoaded}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
