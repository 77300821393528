/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CoachingContext } from '../../coachingContext';
import SleepGraph from '../../components/sleepingReport/SleepGragh3';
import NapGraph from '../../components/sleepingReport/NapGraph';
import StruggleGraph from '../../components/sleepingReport/StruggleGraph';
import SelfCheckHistoryGraph from '../../components/sleepingReport/SelfCheckHistoryGraph';
import SleepEfficiencyGraph from '../../components/sleepingReport/SleepEfficiencyGraph';
import SleepRule from '../../components/sleepingReport/SleepRule';
import SleepSolution from '../../components/sleepingReport/SleepSolution';
import { changeHeader, close } from '../../utils/webview';
import useFetch from '../../hooks/useFetch';
import LockText from '../../components/sleepingReport/LockText';
import { BottomAction } from '../../stories/page/BottomAction';
import ContainerByDevice from '../../components/elements/ContainerByDevice';
import ThoughtNoteModule from '../../components/workbook/course5/ThoughtNoteModule';
import thoughtNoteData from '../../data/thoughtNoteData';
import useSessionStorage from '../../hooks/useSessionStorage';
import webviewToast from '../../development/webviewToast';

const defaultValue = {
  situation: '',
  thought: '',
  thoughtTrapIdList: [],
  newThought: '',
};

export default function SleepingReport() {
  const context = useContext(CoachingContext);

  const [searchParams] = useSearchParams();
  const navigation = searchParams.get('navigation') || 'none';
  const navigate = useNavigate();

  const scrollRef = useRef();
  const contentRef = useRef();
  const [loaded, setLoaded] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [sleepingReport, setSleepingReport] = useState(null);
  const [recordByDateList, setRecordByDateList] = useState(null);
  const [todayCheckData, setTodayCheckData] = useState(null);
  const [trapInfo, setTrapInfo] = useSessionStorage('trapInfo', defaultValue);
  const [sleepingReportData, getSleepingData] = useFetch({
    url: `/sleep/report/detail`,
    bg: '#FFFFFF',
  });
  const [resultHistoryList, setResultHistoryList] = useState(null);
  const [latestResult, getLatestResult] = useFetch({
    url: `/self-check/v2/latest-result/detail`,
    params: { self_check_id: 4 },
    bg: '#FFFFFF',
  });
  useEffect(() => {
    if (latestResult) {
      if (latestResult.result && latestResult.result.resultHistoryList) {
        setResultHistoryList(
          latestResult.result.resultHistoryList.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestResult]);

  useEffect(() => {
    if (sleepingReportData) {
      console.log(sleepingReportData.result);
      setSleepingReport(sleepingReportData.result);
      if (sleepingReportData.result.recordByDateList) {
        setRecordByDateList(
          sleepingReportData.result.recordByDateList.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          }),
        );

        setTodayCheckData(
          sleepingReportData.result.recordByDateList[sleepingReportData.result.recordByDateList.length - 1],
        );
      }
    }
  }, [sleepingReportData]);

  useEffect(() => {
    if (sleepingReportData && latestResult) {
      setLoaded(true);
    }
  }, [sleepingReportData, latestResult]);

  useEffect(() => {
    getSleepingData();
    getLatestResult();
    console.log(navigation);
    changeHeader({ buttonType: navigation });
  }, []);

  const handleScroll = (e) => {
    if (window.scrollY !== 0 || e.target.scrollTop !== undefined) {
      if (e.target.scrollTop > contentRef.current.offsetTop || window.scrollY > contentRef.current.offsetTop) {
        console.log('aaaa');
        setShowHeader(true);
      } else {
        console.log(
          'bbbb',
          window.scrollY,
          e.target.scrollTop,
          window.scrollY || e.target.scrollTop,
          contentRef.current.offsetTop,
        );
        setShowHeader(false);
      }
    }
  };

  useEffect(() => {
    if (showHeader) {
      console.log('헤더 텍스트 노출');
      changeHeader({ backgroundColor: '#FFFFFF', text: '수면 리포트', color: '#000000' });
      scrollRef.current.style.backgroundColor = '#F3F5F8';
    } else {
      if (isNavigate) {
        changeHeader({ backgroundColor: '#FFFFFF', text: '', color: '#000000' });
      } else {
        console.log('헤더 텍스트 비노출');
        changeHeader({ backgroundColor: '#323845', text: '', color: '#FFFFFF' });
        scrollRef.current.style.backgroundColor = '#323845';
      }
    }
  }, [showHeader]);

  useEffect(() => {
    webviewToast('in');
    setIsNavigate(false);
    window.addEventListener('scroll', handleScroll);

    return () => {
      // if (window !== null) {
      console.log('jj');
      window.removeEventListener('scroll', handleScroll);
      // }
    };
  }, []);

  const example = [
    // {
    //   date: '2022-07-22',
    //   inBed: { startDateTime: '2022-07-22T01:00', endDateTime: '2022-07-22T08:00' },
    //   sleep: { startDateTime: '2022-07-22T01:00', endDateTime: '2022-07-22T07:10' },
    //   napTimeMinutes: 60,
    //   struggleTimeMinutes: 120,
    //   ruleList: [
    //     { ruleId: 1, isDone: false },
    //     { ruleId: 2, isDone: false },
    //     { ruleId: 3, isDone: true },
    //   ],
    // },
    // {
    //   date: '2022-07-23',
    //   inBed: { startDateTime: '2022-07-23T00:00', endDateTime: '2022-07-23T08:00' },
    //   sleep: { startDateTime: '2022-07-23T03:00', endDateTime: '2022-07-23T08:00' },
    //   napTimeMinutes: 180,
    //   struggleTimeMinutes: 120,
    //   ruleList: [
    //     { ruleId: 1, isDone: false },
    //     { ruleId: 2, isDone: false },
    //     { ruleId: 3, isDone: true },
    //   ],
    // },
    // {
    //   date: '2022-07-24',
    //   inBed: { startDateTime: '2022-07-24T01:00', endDateTime: '2022-07-24T09:00' },
    //   sleep: { startDateTime: '2022-07-24T02:00', endDateTime: '2022-07-24T07:00' },
    //   napTimeMinutes: 120,
    //   struggleTimeMinutes: 130,
    //   ruleList: [
    //     { ruleId: 1, isDone: false },
    //     { ruleId: 2, isDone: false },
    //     { ruleId: 3, isDone: true },
    //   ],
    // },
    {
      date: '2022-07-25',
      inBed: { startDateTime: '2022-07-25T00:00', endDateTime: '2022-07-25T09:30' },
      sleep: { startDateTime: '2022-07-25T04:00', endDateTime: '2022-07-25T09:00' },
      napTimeMinutes: 300,
      struggleTimeMinutes: 180,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-26',
      inBed: { startDateTime: '2022-07-26T06:00', endDateTime: '2022-07-26T06:00' },
      sleep: { startDateTime: '2022-07-26T06:00', endDateTime: '2022-07-26T06:00' },
      napTimeMinutes: 270,
      struggleTimeMinutes: 240,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-27',
      inBed: { startDateTime: '2022-07-27T20:00', endDateTime: '2022-07-27T20:00' },
      sleep: { startDateTime: '2022-07-27T20:00', endDateTime: '2022-07-27T20:00' },
      napTimeMinutes: 150,
      struggleTimeMinutes: 0,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-28',
      inBed: { startDateTime: '2022-07-28T00:00', endDateTime: '2022-07-28T10:00' },
      sleep: { startDateTime: '2022-07-28T03:00', endDateTime: '2022-07-28T09:30' },
      napTimeMinutes: 210,
      struggleTimeMinutes: 50,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-29',
      inBed: { startDateTime: '2022-07-28T22:30', endDateTime: '2022-07-29T10:00' },
      sleep: { startDateTime: '2022-07-29T01:00', endDateTime: '2022-07-29T07:30' },
      napTimeMinutes: 0,
      struggleTimeMinutes: 40,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-30',
      inBed: { startDateTime: '2022-07-29T22:00', endDateTime: '2022-07-30T10:00' },
      sleep: { startDateTime: '2022-07-30T01:00', endDateTime: '2022-07-30T09:00' },
      napTimeMinutes: 90,
      struggleTimeMinutes: 30,
      ruleList: [
        { ruleId: 1, isDone: true },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-08-04',
      inBed: { startDateTime: '2022-08-04T00:00', endDateTime: '2022-08-04T09:30' },
      sleep: { startDateTime: '2022-08-04T01:00', endDateTime: '2022-08-04T09:00' },
      napTimeMinutes: 0,
      struggleTimeMinutes: 50,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
  ];

  const Element = (
    <div
      className={`w-full h-full overflow-auto scrollbar-hide bg-[#323845] `}
      ref={scrollRef}
      onScroll={(e) => {
        handleScroll(e);
      }}
    >
      {loaded && (
        <div>
          <div className="pt-[12px] pb-[50px] bg-[#323845] text-center text-[#A4A6B0]">
            <p className="text-[15px] leading-[21px] mb-[10px]">{sleepingReport.nickname}님의 수면 목표</p>
            <p
              className={`text-[24px] leading-[30px] font-bold ${
                sleepingReport.objective ? 'text-white' : ' opacity-30'
              }`}
            >
              {sleepingReport.objective || '코스를 진행하며 목표를 설정해요'}
            </p>
          </div>
          <div
            className={`pt-[30px] ${
              navigation !== 'back' ? 'pb-[40px]' : context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'
            } px-[20px]  bg-[#F3F5F8]`}
            ref={contentRef}
          >
            <div className="pb-[50px]">
              <div className="flex justify-between items-center mb-[20px]">
                <p className="text-[20px] leading-[26px] text-[#26282C] font-bold ">📋 수면 처방</p>

                {!sleepingReport.prescription && <LockText text={'7일 차에 처방돼요.'} />}
              </div>

              <div className="flex flex-col gap-[10px]">
                <SleepSolution id={1} time={sleepingReport.prescription && sleepingReport.prescription.inBedTime} />
                <SleepSolution id={2} time={sleepingReport.prescription && sleepingReport.prescription.wakeUpTime} />
              </div>
            </div>
            {recordByDateList && todayCheckData && (
              <div>
                {todayCheckData.ruleList.filter((element) => !element.isDone).length > 0 && (
                  <div className="pb-[50px]">
                    <p className="text-[20px] leading-[26px] text-[#26282C] font-bold mb-[20px]">
                      🚨 오늘은 꼭 지켜주세요
                    </p>
                    <div className="flex flex-col gap-[10px] ">
                      {todayCheckData.ruleList
                        .filter((element) => !element.isDone)
                        .map((rule, index) => (
                          <SleepRule
                            key={`rule${index}`}
                            id={rule.ruleId}
                            today={recordByDateList[recordByDateList.length - 1].date}
                            data={recordByDateList
                              .filter(
                                (each) =>
                                  each.ruleList && each.ruleList.find((element) => element.ruleId === rule.ruleId),
                              )
                              .map((element) => ({
                                date: element.date,
                                isDone: element.ruleList.find((element2) => element2.ruleId === rule.ruleId).isDone,
                              }))}
                            open
                          />
                        ))}
                    </div>
                  </div>
                )}
                {todayCheckData.ruleList.filter((element) => element.isDone).length > 0 && (
                  <div className="pb-[50px]">
                    <p className="text-[20px] leading-[26px] text-[#26282C] font-bold mb-[20px]">👍 잘하고 있어요</p>
                    <div className="flex flex-col gap-[10px] ">
                      {todayCheckData.ruleList
                        .filter((element) => element.isDone)
                        .map((rule, index) => (
                          <SleepRule
                            key={`rule${index}`}
                            id={rule.ruleId}
                            today={recordByDateList[recordByDateList.length - 1].date}
                            data={recordByDateList
                              .filter(
                                (each) =>
                                  each.ruleList && each.ruleList.find((element) => element.ruleId === rule.ruleId),
                              )
                              .map((element) => ({
                                date: element.date,
                                isDone: element.ruleList.find((element2) => element2.ruleId === rule.ruleId).isDone,
                              }))}
                          />
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div>
              <p className="text-[20px] leading-[26px] text-[#26282C] font-bold mb-[20px]">📊 수면 기록</p>
              <div className="flex flex-col gap-[10px] text-[12px] leading-[18px]">
                <SleepEfficiencyGraph efficiency={sleepingReport.efficiency} recordByDateList={recordByDateList} />

                <SleepGraph recordByDateList={recordByDateList} dummyData={example} />
                <StruggleGraph recordByDateList={recordByDateList} dummyData={example} />
                <NapGraph recordByDateList={recordByDateList} dummyData={example} />
                <SelfCheckHistoryGraph initialResultHistoryList={resultHistoryList} />
                {/* <SelfCheckHistoryGraph /> */}
              </div>
            </div>
            {sleepingReport.thoughtNoteList && sleepingReport.thoughtNoteList.length > 0 && (
              <div className="pt-[50px]">
                <p className="text-[20px] leading-[26px] text-[#26282C] font-bold mb-[20px]">📚 생각 노트</p>
                <div className="flex flex-col gap-[10px] text-[12px] leading-[18px]">
                  {sleepingReport.thoughtNoteList.map((thoughtNote, index) => (
                    <ThoughtNoteModule
                      key={`thoughtNote${index}`}
                      title={`${
                        thoughtNoteData.find((element) => element.id === thoughtNote.thoughtTrapIdList[0]).text
                      } ${
                        thoughtNote.thoughtTrapIdList.length > 1 && `외 ${thoughtNote.thoughtTrapIdList.length - 1}개`
                      }`}
                      subTitle={thoughtNote.situation}
                      date={thoughtNote.date || '0000-00-00'}
                      onClick={() => {
                        console.log('생각노트 상세 페이지로 가');
                        setTrapInfo(thoughtNote);
                        setIsNavigate(true);
                        setTimeout(() => {
                          navigate('../workbook/102/5?navigation=back');
                        }, 500);
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <ContainerByDevice css="w-screen">
      {navigation === 'back' || !loaded ? (
        Element
      ) : (
        <BottomAction
          content={Element}
          buttonText={'마치기'}
          buttonState={'ACTIVE'}
          action={() => {
            close({ refresh: true });
          }}
          bgColor={'#F3F5F8'}
          secondary
        />
      )}
    </ContainerByDevice>
  );
}
