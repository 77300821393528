import workbookMessageColorData from '../../data/workbookMessageColorData';
import PropTypes from 'prop-types';

export default function MessageBlockWithTail({ textList, type, my, margin, opposite, cross, isQuiz }) {
  const colorInfo = workbookMessageColorData.find((element) => element.type === type);
  return (
    <div className={`w-full ${isQuiz ? '' : 'overflow-hidden'} z-[50]`}>
      <div
        className={`${my ? 'my-[40px]' : margin ? margin : ''} ${
          cross ? (opposite ? 'translate-x-[-13px]' : 'translate-x-[13px]') : ''
        } `}
      >
        {textList.map((texts, index) => (
          <div
            key={`messageBlockTail${index}`}
            className={`relative text-[17px] leading-[24px]  flex rounded-[16px] ${
              type === 'black_dotted' || isQuiz ? '' : 'mx-[25px]'
            } px-[20px] pt-[12px] pb-[14px]
        ${index !== textList.length - 1 ? 'mb-[10px]' : ''}`}
            style={{ backgroundColor: colorInfo.backgroundColor, color: colorInfo.color, border: colorInfo.border }}
          >
            <div>
              {texts.map((text, index) =>
                index % 2 === 0 ? (
                  <span key={`messageBlockText${index}`}>{text}</span>
                ) : (
                  <span key={`messageBlockText${index}`} className={`bg-[#FFEB3564] pb-[1.5px] `}>
                    {text}
                  </span>
                ),
              )}
            </div>
            {opposite ? (
              <img
                className="h-[19px] absolute bottom-0 left-[-9px]"
                src={colorInfo.tail}
                alt="empty"
                style={{ transform: 'scaleX(-1)' }}
              />
            ) : (
              <img
                className={`h-[19px] absolute bottom-0 ${
                  ['gray'].includes(type)
                    ? 'right-[-8.5px]'
                    : ['black'].includes(type)
                    ? 'right-[-9px]'
                    : ['white'].includes(type)
                    ? 'right-[-9.5px]'
                    : 'right-[-10px]'
                }`}
                src={colorInfo.tail}
                alt="empty"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
MessageBlockWithTail.propTypes = {
  type: PropTypes.oneOf(['black', 'white', 'gray', 'white_dotted', 'black_dotted']),
};
