/* eslint-disable react-hooks/exhaustive-deps */
/* Joe Seifi @joeseifi */
/* A read more component with support for number of lines */

import { update } from 'lodash';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import './read-more.css';

const SPACE_CHARACTER = ' ';

export default function ReadMore(props) {
  const {
    text,
    numberOfLines,
    lineHeight,
    ellipses,
    readMoreLabel,
    showLessLabel,
    showLessButton,
    getReadMoreLoadedState,
  } = props;
  const [readMoreText, setReadMoreText] = useState(null);
  const [readMoreAction, setReadMoreAction] = useState(null);
  const [showingAllText, setShowingAllText] = useState(false);

  const readMoreWrapper = useRef();

  useEffect(() => {
    // console.log('카드 들어옴', text);
    setReadMoreText(text);
  }, []);

  const updateContent = useCallback(() => {
    let trimmedText;
    let teaserWordsArray;

    if (getHasTooMuchContent()) {
      setReadMoreAction(getActionElement(showingAllText));

      //   teaserWordsArray = readMoreText.split(SPACE_CHARACTER);
      //   teaserWordsArray.pop();
      //   trimmedText = `${teaserWordsArray.join(SPACE_CHARACTER)}`;
      //   setReadMoreText(`${trimmedText}${ellipses}`);

      let temp = readMoreText.slice();
      if (temp.length > 0) {
        if (temp[temp.length - 1] === '...') {
          temp.pop();
        }
        if (temp[temp.length - 1].type === 'br') {
          temp.pop();
        } else {
          teaserWordsArray = temp[temp.length - 1].split(SPACE_CHARACTER);
          teaserWordsArray.pop();
          if (teaserWordsArray.length === 0) {
            temp.pop();
          } else {
            temp[temp.length - 1] = teaserWordsArray.join(SPACE_CHARACTER);
          }
        }
        temp.push(ellipses);
        setReadMoreText(temp);
      }
    } else {
      if (readMoreText != null) {
        getReadMoreLoadedState(true); //부모한테 전달
      }
    }
  }, [getActionElement, showingAllText, readMoreText, getReadMoreLoadedState]);

  useEffect(() => {
    updateContent();
  }, [readMoreText, readMoreAction, showingAllText, updateContent]);

  function getHasTooMuchContent() {
    return readMoreWrapper.current.scrollHeight > readMoreWrapper.current.offsetHeight;
  }

  function toggleReadMore() {
    if (!showingAllText) {
      //   console.log('toggle', text);
      setReadMoreText(text);
    }
    setReadMoreAction(getActionElement(!showingAllText));
    setShowingAllText((showingAllText) => !showingAllText);
  }

  function getActionElement(showingAllText) {
    let buttonLabel = showingAllText ? showLessLabel : readMoreLabel;

    if (showingAllText && !showLessButton) {
      console.log('see more');
      return;
    }

    return (
      <button onClick={toggleReadMore} className="read-more__button">
        {buttonLabel}
      </button>
    );
  }

  return (
    <div
      style={{
        maxHeight: showingAllText ? 'none' : `${numberOfLines * lineHeight}em`,
      }}
      className="read-more"
      ref={readMoreWrapper}
    >
      {readMoreText}
      {readMoreAction}
    </div>
  );
}
