import wb0_1_1 from '../image/wb0_1_1.png';
import wb0_1_2 from '../image/wb0_1_2.png';
import wb0_1_3 from '../image/wb0_1_3.png';
import wb0_1_4 from '../image/wb0_1_4.png';
import wb0_1_5 from '../image/wb0_1_5.png';
import wb0_1_6 from '../image/wb0_1_6.png';
import wb0_1_7 from '../image/wb0_1_7.png';
import wb0_1_8 from '../image/wb0_1_8.png';
import wb0_1_9 from '../image/wb0_1_9.png';
import wb0_1_10 from '../image/wb0_1_10.png';
import wb0_2_1 from '../image/wb0_2_1.png';
import wb0_2_2 from '../image/wb0_2_2.png';
import wb0_2_3 from '../image/wb0_2_3.png';
import wb0_2_4 from '../image/wb0_2_4.png';
import wb0_2_5 from '../image/wb0_2_5.png';
import wb0_2_6 from '../image/wb0_2_6.png';
import wb0_3_1 from '../image/wb0_3_1.png';
import wb0_3_2 from '../image/wb0_3_2.png';
import wb0_3_3 from '../image/wb0_3_3.png';
import wb0_3_4 from '../image/wb0_3_4.png';
import wb0_3_5 from '../image/wb0_3_5.png';
import wb0_3_6 from '../image/wb0_3_6.png';
import wb0_4_1 from '../image/wb0_4_1.png';
import wb0_4_2 from '../image/wb0_4_2.png';
import wb0_4_3 from '../image/wb0_4_3.png';
import wb0_4_4 from '../image/wb0_4_4.png';
import wb0_4_5 from '../image/wb0_4_5.png';
import wb0_4_6 from '../image/wb0_4_6.png';
import wb0_4_7 from '../image/wb0_4_7.png';
import wb0_4_8 from '../image/wb0_4_8.png';
import wb0_4_9 from '../image/wb0_4_9.png';
import wb0_5_1 from '../image/wb0_5_1.png';
import wb0_5_2 from '../image/wb0_5_2.png';
import wb0_5_3 from '../image/wb0_5_3.png';
import wb0_5_4 from '../image/wb0_5_4.png';
import wb0_5_5 from '../image/wb0_5_5.png';
import wb0_5_6 from '../image/wb0_5_6.png';
import wb0_5_7 from '../image/wb0_5_7.png';
import wb0_6_1 from '../image/wb0_6_1.png';
import wb0_6_2 from '../image/wb0_6_2.png';
import wb0_6_3 from '../image/wb0_6_3.png';
import wb0_6_4 from '../image/wb0_6_4.png';
import wb0_6_5 from '../image/wb0_6_5.png';
import wb0_6_6 from '../image/wb0_6_6.png';
import wb0_6_7 from '../image/wb0_6_7.png';
import wb0_6_8 from '../image/wb0_6_8.png';
import EmotionalInput from '../components/workbook/EmotionalInput';
import { TextWithBold } from '../components/workbook/TextWithBold';
import NavigateSelectButton from '../components/workbook/NavigateSelectButton';
import QuizTemplate from '../components/workbook/quiz/QuizTemplate';
import AnswerTemplate from '../components/workbook/quiz/AnswerTemplate';
import TextWithDotBoldInline from '../components/workbook/TextWithDotAndBoldInline';
import MessageBlockWithBold from '../stories/workbook/MessageBlockWithBold';
import MessageBlockWithTail from '../stories/workbook/MessageBlockWithTail';
import { MessageBlockWithTailCross } from '../stories/workbook/MessageBlockWithTailCross';

const course0Data = [
  [
    {
      title: '인사이드의 세계로',
      subtitle: '어서오세요, 환영해요.',
      fullImage: wb0_1_5,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '안녕하세요, 인사이드에 오신 걸 환영해요. 인사이드는 ',
                '내 마음을 돌아보기 위한 공간',
                '이에요. 단순히 내 마음의 상태를 기록하고 살펴보고 싶은 분들부터 우울이나 불안, 불면증 등 실제 문제를 겪고 있는 분들까지, 인사이드는 다양한 분들에게 도움을 드릴 수 있을 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요',
    },
    {
      title: '이런 경험, 한 번쯤은 있지 않나요?',
      image: wb0_1_6,
      bg: '#6C38DA',
      texts: [
        {
          text: '스스로 통제할 수 없었던 일상 속 상황부터 구체적인 증상들까지, 나만 겪고 있었을까요?',
        },
        {
          component: (
            <div className="w-full rounded-[16px] bg-[#F3F5F8] py-[20px] mt-[40px] px-[10px]">
              <TextWithDotBoldInline
                texts={[
                  { text: [<span />, '발표', '를 할 때 너무 떨려서 목소리가 잘 나오지 않나요?'] },
                  { text: ['밤에 자려고 누웠는데 정신이 말똥말똥하고 도무지 ', '잠에 들 수가 없나요?'] },
                  { text: [<span />, '낮은 자존감', ' 때문에 인간관계에서 고생했을 수도 있죠.'] },
                  { text: ['일을 하다가 ', '번아웃', '이 와 이젠 아무것도 하기 싫은 지경에 이르렀을지도 모르겠네요.'] },
                  {
                    text: ['어쩌면 ', '우울증, 공황장애, 불면증', '과 같은 질환을 이미 진단 받았을 수도 있을 거예요.'],
                  },
                ]}
                margin="mx-[10px]"
              />
            </div>
          ),
        },
      ],
      buttonText: '그런 것 같아요',
    },
    {
      title: '그래서 인사이드가 만들어졌어요',
      image: wb0_1_7,
      bg: '#1355FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '누구든 한 번쯤은 마음이 힘든 시기가 있어요. 하지만 적절한 해결책을 찾기는 어렵죠. 인사이드는 ',
                '사람들에게 더 쉽고, 더 정확한 정신건강서비스를 제공',
                '하기 위해 만들어졌어요. 어떠한 상황이든, 어떠한 문제든 괜찮아요. 인사이드에서 우리들의 마음에 온전히 집중할 수 있답니다.',
                '\n',
                '인사이드는 내 마음에 집중할 수 있도록 다양한 솔루션들을 제공하고 있어요. 이제부터 ',
                '마음 종합검진, 멘탈케어 코스, 코칭, 비대면 심리상담',
                '과 같은 기능들을 제공해드릴 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '조금 더 자세히 설명해 주세요',
    },
    {
      title: '마음 종합검진',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '내 마음상태가 어느 정도인지 잘 모르겠나요? 괜찮아졌던 마음이 언제 또 나빠질지 걱정이 되나요? 그래서 인사이드가 마음 종합검진을 제공한답니다. ',
                '주기적으로 마음 종합검진을 위한 검사들을 보내드릴 거예요.',
              ]}
            />
          ),
        },
        {
          component: (
            <div
              className={`w-full max-h-[383px] relative overflow-hidden rounded-2xl my-[30px] flex justify-center align-center`}
              style={{ backgroundColor: '#E1E4EB' }}
            >
              <div className={`max-w-[300px] mx-[17px] overflow-hidden`}>
                <img className="w-full h-full object-contain" src={wb0_1_1} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '검사들은 모두 실제 진료에서 활용되고 있는 지표들을 이용한답니다. ',
                '검사를 진행하다보면 내 마음 상태가 어떤지, 어떻게 변화하고 있는지 이해할 수 있을 거예요.',
                ' 일정 기간이 지나면 종합 레포트도 제공해드린답니다. 꾸준한 마음 종합검진을 통해서 내 마음이 어떠한 모습인지 알아보세요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '마음상태를 검사할 수 있군요',
    },
    {
      title: '멘탈케어 코스',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '인사이드는 우울, 불안, 자존감, 수면 등 다양한 주제에 대한 멘탈케어 코스를 제공해요. ',
                '코스',
                '는 ',
                '스스로 하는 정신건강 관리 프로그램',
                '이에요. 인사이드가 제공하는 코스는 과학에 기반하여 만들어졌어요. ',
                '서울대학교 의과대학 출신 의사',
                '들과 ',
                '심리전문가',
                '들이 모여 많은 문헌들을 참고하고 연구를 통해 프로그램을 설계하였답니다. 프로그램은 의학과 심리학에 기반되어 만들어졌고, 특히 ',
                '임상적으로 효과가 입증된 인지행동치료 이론에 근거',
                '하여 만들어졌어요.',
              ]}
            />
          ),
        },
        {
          component: (
            <div className={`max-w-[335px] mx-auto  w-full overflow-hidden flex justify-center my-[30px]`}>
              <img className="w-full mx-auto object-contain" src={wb0_1_2} alt="empty" />
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '인지행동치료는 불안장애, 신체증상, 섭식장애, 수면 등 다양한 정신의학영역에 널리 적용되고 있어요. 인지행동치료는 ',
                '단순한 심리테스트 혹은 심리컨텐츠가 아니랍니다.',
                ' 이미 의학적으로 체계적 문헌고찰(Systematic Review) 및 무작위대조시험(Randomized Controlled Trial)을 통해 ',
                '그 효과가 충분히 입증된 이론',
                '이에요. 하지만 인지행동치료는 정신질환뿐만 아니라 자존감, 스트레스 등 ',
                '다양한 영역에 적용',
                '될 수 있어요. 인사이드는 ',
                '본인의 마음에 관심이 있는 모든 사람들을 위한 코스들을 제공할 계획',
                '이랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '스스로 하는 멘탈케어 코스가 있군요',
    },
    {
      title: '코칭',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '멘탈케어 코스는 스스로 할 수 있도록 만들어졌지만 인사이드 코치와 함께 한다면 더 많은 효과를 볼 수 있답니다. 인사이드 코치는 ',
                '의사들에 의해 교육받은 심리학 관련 종사자',
                ' 혹은 ',
                '2급 상담사',
                '로 구성되어 있답니다.',
              ]}
            />
          ),
        },
        {
          component: (
            <div
              className={`w-full rounded-2xl my-[30px] flex justify-center align-center`}
              style={{ backgroundColor: '#E1E4EB' }}
            >
              <div className={`mx-[20px] overflow-hidden my-[40px]`}>
                <img className="w-full h-full object-contain" src={wb0_1_3} alt="empty" />
              </div>
            </div>
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '인사이드 코치는 채팅을 통해 ',
                '코스를 안내',
                '하고, ',
                '코스에 대한 질문',
                '을 받아요. 자가검사 결과에 따라 ',
                '다른 전문가들을 추천',
                '해주기도 하고, ',
                '도움이 될 만한 컨텐츠들을 제공',
                '하기도 해요. 가끔씩은 ',
                '안부를 묻고, 가벼운 고민 상담을 진행',
                '하기도 하죠. 내 마음을 위해 언제든 대화할 수 있는 나만의 친구라고 생각해 주시면 좋을 것 같아요. 인사이드 코치와 함께라면 더 체계적으로 내 마음을 관리할 수 있을 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '기대돼요',
    },
    {
      title: '비대면 심리상담',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '인사이드 코치는 엄격한 기준으로 선발되었지만, 코치가 전문적인 심리상담을 제공하지는 않아요. 심리상담은 특정한 목적을 가지고 잘 짜여진 기법들을 통해 진행되어야 하거든요.',
                '\n',
                '인사이드에서는 비대면 심리상담을 진행할 수 있답니다. ',
                '휴대폰만 있다면 언제든, 어디서든, 내가 원하는 시간과 장소에서 심리상담',
                '을 받을 수 있어요. 인사이드의 상담사 선생님들은 모두 ',
                '1급 상담사로 심리학 석・박사 이후 3년 이상의 전문적인 수련을 받은 분들',
                '이랍니다. ',
              ]}
            />
          ),
        },
        {
          component: (
            <div
              className={`w-full  relative overflow-hidden rounded-2xl my-[30px] flex justify-center align-center`}
              style={{ backgroundColor: '#E1E4EB' }}
            >
              <div className={`mx-[20px] overflow-hidden my-[21px]`}>
                <img className="w-full h-full object-contain" src={wb0_1_4} alt="empty" />
              </div>
            </div>
          ),
        },

        {
          component: (
            <TextWithBold
              texts={[
                '비전문적인 상담과 뻔한 위로에 오히려 상처를 받았던 적이 있나요? 인사이드에서 국내 최고의 상담사 선생님들과 대화해 보세요. 많은 도움을 주실 수 있을 거랍니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '심리상담을 편하게 할 수 있다니, 좋아요!',
    },
    {
      title: '소개해 드릴 분이 있어요',
      image: wb0_1_8,
      bg: '#3863B6',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '인사이드의 멘탈케어 코스에서 다양한 내용들을 알려주실 분이 계시답니다.',
                '\n',

                '제법 유명한 정신과 의사예요. 그의 나이와 이름을 정확히 아는 사람은 없답니다. 언제나 활짝 웃는 그의 모습 때문에 사람들은 그를 ',
                '닥터 스마일',
                '이라고 불러요. 스스로는 유머감각이 뛰어나다고 생각하지만, 사람들은 그가 선사하는 개그의 연식을 미루어보아 그의 나이를 짐작하곤 해요. 아무튼 젊은 분은 아닌 것 같아요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '신비로운 분이네요',
    },
    {
      title: '아마 내면의 아픔이 있는 분 같아요',
      image: wb0_1_9,
      bg: '#69759E',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '외적으로 풍기는 ',
                '긍정적인 에너지와는 다르게 그의 마음은 굳게 닫혀 있어요.',
                ' 어느 잡지 인터뷰에 따르면, 그가 항상 웃음을 잃지 않을 수 있는 이유는 그 어떤 것도 사랑하지 않기 때문이라고 해요.',
                '\n',
                '그의 인터뷰를 본 어느 학자는 다음과 같이 말했어요. “닥터 스마일의 멋진 성공의 이면에는 심한 애정 결핍이 깔려있다. 그가 아무것도 사랑하지 않는 이유는 그가 베푼 사랑만큼 돌려받지 못할까 두려워서이다. 그는 남들의 사랑을 누구보다 갈구한다. 그렇기에 모든 사람에게 시종일관 웃음으로 대하는 것이다.” 이에 사람들은 닥터스마일의 과거에 대해 온갖 추측을 내놓지만, 아직 사실로 밝혀진 것은 아무것도 없답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '아픈 과거가 있다니 뭔가 마음이 가요',
    },
    {
      title: '뛰어난 분임에는 틀림없어요',
      image: wb0_1_10,
      bg: '#3A3A4B',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '그의 실력과 통찰력은 모두가 인정해요. 때문에 닥터 스마일의 진료실은 항상 문전성시를 이루죠. 아마 그가 가진 불명의 아픔에 대한 호기심과 동질감에 찾아오는 손님도 꽤나 될 거예요.',
                '\n',
                '자신에 대한 온갖 추측이 난무하는 사실을 그도 모르는 것은 아니에요. 그래서 그는 ',
                '인사이드를 통해 자신이 가진 아픔을 공유하고 더 많은 이의 정신건강을 위해 힘 써보려 합니다.',
                <span />,
                '\n',
                '이제 제가 닥터 스마일을 소개해 드릴 텐데요, 만나보시면 많은 도움이 될 거예요. ',
                '처음 뵙는 분들에게는 선물을 주신다는 말도 있으니',
                ' 꼭 한번 만나보세요. 아참, 음악을 좋아하고 고양이를 키우신다고 하니 그런 이야기를 하면 좋아하실 거예요. 자, 그럼 이제 소개해 드릴게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '네, 소개해 주세요',
    },
  ],
  [
    {
      title: '어서오세요, 처음 보는 분이네요.',
      fullImage: wb0_2_1,
      height: 215,
      bg: '#FFC045',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '인사이드에 오신 걸 보니 마음에 관심이 많은 분인가 보군요. 아니면 지금 당장 심적으로 너무 힘든 순간들을 보내고 있는 분일 수도 있겠죠. 어느 경우든 괜찮습니다. 스스로의 마음에 관심을 가지는 건 좋은 일이니까요. 사람들은 모든 게 자기 의지에 달렸다고 착각할 때가 많아요. 실은 우리는 정말 수동적인 존재인데 말이에요. 어찌되었든 ',
                '이렇게 스스로의 마음에 대해 생각해 볼 시간을 가진 건 참 잘 한 일',
                '입니다. ',
                '어쩌면 많은 것들이 바뀔지도 모르죠.',
                ' 물론 긍정적인 방향으로요. 그래도 어떤 목적으로 저를 찾아왔는지 여쭤봐도 될까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '저는 어떤 목적으로 찾아왔냐면요...',
    },
    {
      title: '어떤 목적으로 제 진료실에 방문하셨나요?',
      texts: [
        {
          component: (
            <NavigateSelectButton
              textList={[
                '정신건강에 관심이 많아요. 제 삶에 도움이 되는 다양한 내용들을 배우고 싶어요.',
                '지금 당장 문제가 있는 부분이 있어요. 그런 부분들을 해결하고 싶어요.',
              ]}
            />
          ),
        },
      ],
    },
    {
      title: '좋아요. 잘 찾아오셨어요.',
      image: wb0_2_2,
      bg: '#FFCC16',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '제가 도움을 드릴 일들이 제법 있을 것 같네요. 물론 말씀하신 것 이외에 다른 문제가 있는 건 아닌지도 꾸준히 파악할 거랍니다. 다른 문제를 발견하게 되면 제가 알려드릴게요.',
                '\n',
                '단, 제가 알려드리는 것들을 잘 따라오셔야 합니다. 제가 도움을 드리겠지만, ',
                '언제나 나를 변화시킬 수 있는 건 ‘나 자신’이라는 점을 잊으면 안 됩니다.',
                ' 물론 나를 변화시킨다는 건 쉬운 일이 아니에요. 하지만 불가능한 일도 아니에요. 제가 도와드린다면 더더욱이 그렇죠. 저는 수많은 사람들을 만나며 ',
                '모든 사람들이 다양한 수준의 잠재성을 품고 있다',
                '는 사실을 발견했어요. 마음이라는 건 생각보다 더 위대하답니다. ',
                '중요한 건 내 마음이에요.',
                ' 그러니 저를 믿고 잘 따라오셔야 해요. 노력하다 보면 상황을 더 나아지게 만들 수 있답니다. 다시 한번, 나를 변화시킬 수 있는 건 ‘나 자신’이에요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '명심할게요!',
    },
    {
      title: '그러기 위해선 집중을 해야 해요',
      image: wb0_2_3,
      bg: '#3282A5',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '나를 변화시키기 위해서는 투자가 필요하죠. 시간이든, 노력이든, 나를 위한 투자 말이에요. 내 마음을 돌아보는 과정에 집중을 할 필요가 있어요. 인터넷에서 재미있는 글을 가볍게 읽는 정도로 접근해서는 많은 효과를 보기 힘들답니다. 물론 제가 가볍게 읽을 수 있을 정도로 쉽게 설명을 하겠지만, 그 과정을 대하는 자세는 보다 진지했으면 좋겠어요. 내 마음보다 중요한 건 없잖아요?',
                '\n',
                <span />,
                '시간을 정해두는 것도 좋은 방법',
                '이에요. 보통 ',
                '점심시간',
                '이나 ',
                '취침 전 시간을 많이 활용',
                '하신답니다. 언제든 좋아요. 나를 위해 투자하고, 내 마음을 되돌아보는 시간을 정하고 지키는 게 중요하답니다.',
              ]}
            />
          ),
        },
      ],
      buttonText: '진지하게 해 볼게요!',
    },
    {
      title: '다양한 기법들을 적용해 볼 거예요.',
      image: wb0_2_4,
      bg: '#3969AE',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '많은 것들을 시도해 볼 거랍니다. 가벼운 글이나 영상을 공유할 수도 있어요. 때로는 이미 학계에서 입증된 이론에 기반한 심리 프로그램을 진행하기도 할 거예요. 제 프로그램은 그중에서도 특히 ',
                '인지행동치료',
                '에 기반한 것들이 많아요. 인지행동치료는 ',
                '우리들의 생각, 감정, 행동이 어떻게 서로 영향을 미치는지 살펴보고, 그 과정에서 나타나는 우리들의 불합리한 생각들을 교정',
                '하는 치료예요. 이런, 제가 또 너무 어렵게 이야기 한 것 같네요. 나이가 들면 자꾸 이렇게 상대 입장에서 생각하지 못 한다니까요. 조금 더 쉽게 예시를 하나 들어보죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요!',
    },
    {
      title: '핵심은 우리들의 생각이에요',
      image: wb0_2_5,
      bg: '#FFA14A',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '문제는 사건 그 자체가 아니라 우리가 사건을 어떻게 생각하는가에 달려 있답니다.',
                ' 예를 들어보죠. 길을 가다가 우연히 친구를 봤어요. 그런데 친구가 모른 척하고 지나갔죠. 이때 다음과 같이 두 가지로 생각해 볼 수 있을 거예요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['나를 못 보고 지나갔나봐.'], ['날 무시하고 모른 척 지나가네.']]}
              type="white_dotted"
              my
            />
          ),
        },

        {
          component: (
            <TextWithBold
              texts={[
                '그리고 어떻게 생각하는가에 따라 우리의 기분도 달라지고, 친구를 대하는 나의 태도도 달라지겠죠.',
                '\n',
                '다른 경우에서도 마찬가지예요. 실수를 했을 때, 연인과 이별했을 때, 남들 앞에서 발표를 할 때, 중요한 경기를 앞두고 있을 때, 심지어는 잠이 안 와서 침대에 누워있을 때조차, ',
                '상황을 더 긍정적인 방향으로 이끌어나가는 건 놓여있는 상황 그 자체보다는 우리가 그 상황을 어떻게 받아들이느냐에 달려있답니다.',
                ' 즉, 삶을 더 생산적으로 이끌어나가는 힘은 우리들의 마음에서 비롯되죠.',
              ]}
            />
          ),
        },
      ],
      buttonText: '마음가짐이 중요하단 말 많이 들어봤어요',
    },
    {
      title: '그래서 생각에 집중할 거예요',
      image: wb0_2_6,
      bg: '#FFCA43',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '중요한 게 생각이라면 거기에 더 집중해야겠죠. 우리는 앞으로 나의 생각이 어떻게 작동하는지 살펴볼 거예요. 물론, 구체적인 문제, 예를 들어 우울이나 불안, 혹은 수면 문제와 같은 주제를 개별적으로 다루기도 할 거랍니다.',
                '\n',
                '이렇게 말하고 돌려보내면 대부분 흥미를 잃고 다시 찾아오지 않았단 말이죠... 사람들은 참 흥미를 중요시 여기는 것 같아요. 그래서 다음 수업에는 제가 간단한 내용을 설명하고 ',
                '선물',
                '을 하나 드릴 거예요. ',
                '내 마음을 관리하는 도구',
                '라고 생각하시면 돼요. 별거 아닌 것처럼 보이지만, ',
                '이 도구만으로도 전혀 다른 삶을 살고 있는 사람들이 많답니다.',
                ' 어찌되었든... 조금 쉬고 이따가 또 찾아와서 저랑 이야기해 봐요. 언제든 좋아요. 편할 때 찾아와요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '잠시 쉬었다가 바로 올게요!',
    },
  ],
  [
    {
      title: '잘 쉬었나요? 다시 시작해 보죠.',
      fullImage: wb0_3_1,
      height: 215,
      texts: [
        {
          text: '쉬는 동안 전해드릴 선물을 준비하고 있었어요. 준비하면서 혹시나 흥미를 못 느끼고 도망가버렸으면 어쩌나 했죠. 뭐, 그러면 선물은 다른 사람에게 줬겠죠... 하지만 오셨으니 다행이에요. 뭔가 저랑 함께 많은 것들을 해 볼 수 있는 분이라는 생각이 들었거든요. 한 번 인사 나누고 헤어지기는 아쉬웠어요. 인사가 길었네요. 그러면 이제 시작해 볼까요?',
        },
      ],
      buttonText: '좋아요',
    },
    {
      title: '생각에 집중한다고 했어요',
      image: wb0_3_2,
      bg: '#FFCA43',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '지난 시간에 생각에 집중한다고 말했었죠. 기억하시나요? 같은 상황이라도 우리가 어떻게 생각하느냐에 따라 달라지기 때문이라고 했어요. 찬찬히 생각해 보면 ',
                '우리의 생각이 삶에 대한 태도를 만들고, 때로는 문제를 일으키기도 하는 경우가 정말 많답니다.',
                ' 저를 찾아왔던 한 여성분의 이야기를 들려드릴게요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '명심할게요!',
    },

    {
      title: '앨리스의 이야기예요',
      image: wb0_3_3,
      bg: '#3B60E0',
      texts: [
        {
          text: '남자친구와 연애를 한 지 얼마되지 않은 20대 중반 정도의 여성분이었어요. 편의상 앨리스라고 부를게요. 앨리스는 이전의 연애들이 모두 얼마가지 않아 어긋나버려서 연애에 대해 초조함을 느끼고 있었어요. 그래서 관계에 대해 조언을 구하기 위해 저를 찾아왔어요. 그러던 어느날 앨리스가 저를 찾아와서 이렇게 말했죠.',
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['너무 슬퍼요. 침울하고 자신감이 떨어져요. 사랑받지 못하는 느낌이 들어요.']]}
              type="gray"
              my
              cross
              opposite
            />
          ),
        },
        {
          text: '앨리스는 매우 감정적인 상황이었죠. 일단 감정을 내려놓고 이야기할 필요가 있었어요. 저는 차분히, 그리고 조심스럽게 물었죠.',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[
                ['앨리스, 많이 힘들어 보이네요. 잠시 마음을 추스려봐요. 저랑 이야기해 보죠. 무슨 일이 있었던 거예요?'],
                [
                  '남자친구가 저를 떠날 것 같아요. 매몰차게 제 제안을 거절하다니... 이전에는 이러지 않는데 점점 바뀌는 것 같아 불안하고 초조해요. 어쩌죠.',
                ],
              ]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '조금 뒤 설명하겠지만 ',
                '우리의 생각을 들여다보기 위해서는 객관적인 상황과 주관적인 나의 판단 및 감정을 잘 분리시켜야 합니다.',
                ' 앨리스의 말에는 사실과 판단이 뒤섞여 있었어요. 우선 그것들을 분리시킬 필요가 있었죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                [
                  '많이 힘들었겠어요. 우선, 감정을 조금 추스려야 할 것 같아요. 잠시 여유를 가지죠. 제가 노래를 하나 틀 거예요. 아무 생각하지 말고 노래에만 집중하고 잠시 마음을 쉬게 두어요. 노래가 끝난 뒤에 우리 이야기해 봐요.',
                ],
              ]}
              type={'white'}
              my
              cross
            />
          ),
        },
        {
          text: '그리고 저는 Ryuichi Sakamoto의 <Opus>라는 노래를 틀었어요.',
        },
      ],
      buttonText: '앨리스가 어떤 말을 할까요',
    },
    {
      title: '앨리스의 이야기예요',
      image: wb0_3_4,
      bg: '#6675F8',
      texts: [
        {
          text: '노래가 끝나자 앨리스의 감정도 조금 차분해졌죠. 그리곤 앨리스가 먼저 말을 이었어요.',
        },
        {
          component: (
            <MessageBlockWithTail
              type="gray"
              textList={[
                [
                  '우선 있었던 일만 말씀드리면, 제가 남자친구에게 퇴근하고 잠시 만나고 싶다고 말했고, 남자친구는 “퇴근하고 나서는 너무 피곤하다”라고 말했죠. 그래서 저는 너는 내 생각을 하긴 하는 거냐고 물었고, 남자친구는 왜 그렇게 해석하냐며 짜증을 냈어요. 결국 말다툼이 있었고 남자친구는 너무 지친다는 말을 남기곤 전화기를 껐어요.',
                ],
              ]}
              my
              opposite
              cross
            />
          ),
        },
        {
          text: '이제 어느 정도 사실과 판단이 분리가 되었죠. 앨리스는 슬픔을 느꼈어요. 남자친구가 피곤하다는 이유로 데이트를 거절한 상황이었죠. 이제 저는 다음과 같이 물었죠. 앨리스가 그 상황을 어떻게 생각하고 있는지 살펴보기 위함이었어요.',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[
                ['앨리스, 그런 상황에서 어떤 생각이 들었어요?'],
                [
                  '남자친구가 저를 사랑하지 않는 게 틀림없다고 생각했어요. 정상적인 연인이라면 아무리 피곤해도 얼굴이라도 보고 싶어 하는 거 아닐까 싶기도 했고요. 일상적인 피로감을 무릎쓰고 약속을 잡을 정도로 저를 사랑하지는 않는구나 싶었어요. 저를 진정으로 사랑했다면 저의 우선순위가 더 높았을 테니까요.',
                ],
              ]}
              my
            />
          ),
        },
        {
          text: '이렇게 저는 앨리스의 감정-상황-생각을 분리시키는 작업을 했답니다.',
        },
      ],
      buttonText: '분리시키는 게 어떤 효과가 있나요?',
    },
    {
      title: '생각을 분리시킬 수 있어야 해요',
      image: wb0_3_5,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '상황, 생각, 감정을 분리시키는 것만으로도 우리들의 인지 체계를 매우 객관적으로 살펴볼 수 있답니다. 위에 제가 그린 도식은 심리학에서 흔히 이야기하는 우리들의 인지 체계예요. 즉, 우리는 ',
                '어떠한 상황이 주어졌을 때, 그 상황에 대해 특정한 방향으로 생각을 하고, 그 생각의 결과에 따라 감정을 느끼고 행동',
                '해요. 이를 토대로 생각해 보면, 다시 한번, ',
                '문제는 사건 그 자체가 아니라 우리가 사건을 어떻게 생각하는가에 달려 있다',
                '는 것을 알 수 있어요.',
                '\n',
                '그래서 우리는 생각을 분리시킬 수 있어야 해요. 상황, 생각, 감정을 분리하여 적어두고 찬찬히 살펴보면 다음과 같은 의문이 떠오를 수가 있어요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              type="white_dotted"
              textList={[
                ['이 상황에서 이렇게 생각하는 게 너무 과하진 않았을까?'],
                ['그렇게까지 강한 감정을 보일 만한 일이었을까?'],
                ['적어둔 걸 지금와서 보니 그렇게까지 반응할 일은 아니였던 것 같아.'],
              ]}
              my
            />
          ),
        },
        {
          text: '조만간 더 체계적으로 나의 생각을 분석하는 법 또한 가르쳐드릴 거예요. 하지만 일단 우선은 상황-생각-감정을 분리시키는 게 중요하다는 것만 알아두세요.',
        },
      ],
      buttonText: '이해했어요!',
    },
    {
      title: '이제 선물을 드릴게요',
      image: wb0_3_6,
      bg: '#96CDFF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이렇게 설명만 하면 많은 분들이 어려워하더라고요. 제 진료실을 찾아오는 분들 대부분이 일주일 뒤에 와서는 “어떻게 해야 할지 모르겠어요”라고 했었죠. 그때부터 전 모든 분들에게 ',
                '감정노트',
                '를 선물해 드리고 있어요. 일종의 감정 일기장이라고 생각하면 돼요. 단, ',
                '상황과 생각, 그리고 감정을 분리시켜서 작성하는 내 마음의 일기장',
                '이죠.',
                '\n',
                '저는 감정노트를 사용한 지 제법 됐어요. 지난 몇 년 간의 기록을 꺼내서 분석해 보면 ',
                '스스로가 어떠한 생각을 하는 사람인지, 어떠한 방어기제가 있는지 알 수 있어요.',
                ' 마음을 객관적으로 살펴본다는 건 중요한 일이랍니다. ',
                '나를 이해하고 내가 이 세계와 더 잘 교류할 수 있도록 도와주거든요.',
                ' 참고로 이러한 방식은 ',
                '실제 심리학과 의학의 상담 치료에서도 매우 활발하게 사용하고 있는 기법',
                '이랍니다. 단순한 일기가 아니죠. 꾸준히 사용하다 보면 많은 것들을 이끌어낼 수 있도록 제가 도와드릴 거예요.',
                '\n',
                '가끔은 제가 키우는 고양이에게도 감정노트를 쓰게 하고 싶단 생각을 해요. 가끔 하는 걸 보면 도통 무슨 생각인지 모르겠다 싶을 때가 있거든요. 웃기죠, 사람의 마음에 대해서는 수십 년을 생각했는데 고양이 마음은 한치도 모르겠다니요. 아참, 제가 고양이를 키운다고 이야기했었던가요? 뭐, 고양이가 중요한 건 아니니까 다음에 시간이 있으면 더 이야기하죠. 마치기 전에 질문 하나 할까요?',
              ]}
            />
          ),
        },
      ],
      buttonText: '어떤 질문이요?',
    },
    {
      replaceComponent: (
        <QuizTemplate
          question="상황, 생각, 감정을 분리시키는 게 왜 중요할까요?"
          buttonList={[
            '나를 객관적으로 이해할 수 있어서',
            '기록하기 용이해서',
            '더 풍부한 표현을 위해',
            '그다지 중요하지 않음',
          ]}
          answer="나를 객관적으로 이해할 수 있어서"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="나를 객관적으로 이해할 수 있죠!"
          buttonText="이해했어요!"
          answerInfo="상황, 생각, 감정을 분리시켜서 기록하고 이를 찬찬히 살펴 보면, 스스로가 어떠한 생각을 하는 사람인지, 어떠한 방어기제가 있는지 알 수 있어요. 마음을 객관적으로 살펴본다는 건 중요한 일이랍니다. 나를 이해하고 내가 이 세계와 더 잘 교류할 수 있도록 도와주거든요."
        />
      ),
    },
    {
      title: '잘했어요. 오늘은 이만 하죠.',
      texts: [
        {
          text: [
            '슬슬 피곤하실 거예요. 이미 많은 것들을 배웠죠. 내 생각을 분리시켜 바라볼 수 있다는 것을 알게 된 것만으로도 큰 의미가 있답니다. 그럼 제가 선물해드릴 감정노트를 편하게 이용해 보고 저희는 내일 또 이야기 나눌까요?',
            <br />,
            <br />,
          ],
        },
        {
          text: '감정노트를 사용할 때에는 다음을 명심하세요.',
        },
        {
          component: (
            <TextWithDotBoldInline
              texts={[
                { text: ['기쁨, 슬픔, 불쾌함, 두려움, 화남 등 ', '특정한 감정을 느꼈을 때 이를 기록', '한다.'] },
                {
                  text: [
                    '그때 어떠한 상황이었는지 적는다. 단, ',
                    '상황은 나의 판단을 배제하고 오직 객관적인 사실만 적는다.',
                  ],
                },
                { text: ['그 상황에서 ', '내가 어떻게 생각했는지, 나의 생각을 적는다.'] },
              ]}
              number
              margin="ml-[8px] my-[30px]"
            />
          ),
        },
        {
          text: [
            ' 감정노트의 배경에는 어떠한 원리와 이론이 있는지, 이를 어떻게 더 활용할 수 있는지에 대해서는 다른 코스에서 설명할 거예요. 지금 거기까지 배우기에는 너무 벅찰 거랍니다.',
            <br />,
            <br />,
            '자, 그러면 저는 이제 쉬러가야겠어요. 조금 노곤하네요. 고양이에게 밥을 주고 책을 조금 읽다가 잠시 눈을 붙여야겠어요.',
            <br />,
            <br />,
            '그러면 우리는 내일 만나요. 고생 많았어요.',
          ],
        },
      ],
      buttonText: '즐거운 시간이었어요',
    },
  ],
  [
    {
      title: '반가워요, 잘 지내셨어요?',
      fullImage: wb0_4_1,
      height: 217,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '제가 있는 곳은 오늘 비가 오네요. 이렇게 차분한 날에는 피아노곡이 잘 어울리는 것 같아요. 저는 아침 일찍 일어나 피아노 소품집을 틀어두고 상담 기록들을 분석했어요. 다양한 사람들의 내면의 이야기를 들을 수 있다는 건 정말 감사할 일이죠. 살펴보면 ',
                '모두의 마음이 다 나름의 의미가 있고 가치가 있거든요.',
                ' 다양한 가치를 마주할 수 있는 건 축복받은 일이에요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '반가워요!',
    },
    {
      title: '감정 노트는 어땠나요?',
      image: wb0_4_2,
      bg: '#96CDFF',
      texts: [
        {
          text: '써 볼 만하던가요? 처음엔 감정을 기록하는 게 낯설 수도 있어요. 특히 지금처럼 구체적인 목표를 세우지 않고 쓸 때는 더더욱이 그렇죠. 앞으로 저와 함께 구체적인 주제들을 두고 이야기를 나눌 때에는 기록을 하기 더 쉬울 거예요. 그래도 오늘까지는 가볍게 이야기를 나누는 자리니까, 그냥 편하게 써보도록 해요. 꼭 잘 쓰지 않아도 괜찮아요. 혹시 특별하게 어려운 점이 있었나요?',
        },
      ],
      buttonText: '어려운 점은...',
    },
    {
      replaceComponent: (
        <EmotionalInput
          num={0}
          sum={1}
          title="감정 노트를 사용하는 데에 어려운 점이 있진 않았나요?"
          placeholder="편하게 작성해 보세요"
          buttonText="건너뛰기"
          isFirstPage={true}
          isSkip={true}
        />
      ),
    },
    {
      title: '좋아요, 이제 시작해 볼까요?',
      image: wb0_4_3,
      bg: '#EF7257',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '말씀해 주신 건 제가 한번 생각해 보도록 하죠. 조금 더 개선된 노트를 드릴 수도 있고, 궁금한 부분에 대해 답변을 드리는 메시지를 보낼 수도 있을 것 같아요. 이제 다음 이야기를 진행해 볼까요?',
                '\n',
                '혹시 지난 시간에 했던 앨리스 이야기 기억나세요? 남자친구가 데이트를 거절하자 좌절했던 여성분이었죠. 앨리스의 감정-상황-생각을 구분해 보면 다음과 같을 거예요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithBold
              textList={[
                { bold: '감정', text: [': 슬프고, 침울하고, 자신감이 떨어진다.'], type: 'light_blue' },
                {
                  bold: '상황',
                  text: [': 퇴근 후 잠시 만나자고 했는데 피곤하다는 걸 이유로 거절했다.'],
                  type: 'black',
                },
                {
                  bold: '생각',
                  text: [
                    ': 나를 사랑하지 않는 게 틀림없다. 내가 일보다도 우선순위가 더 낮은 것이다. 정상적인 연인이라면 아무리 피곤해도 보고 싶어하는 게 당연하다.',
                  ],
                  type: 'white_dotted',
                },
              ]}
            />
          ),
        },
      ],
      buttonText: '기억나요!',
    },
    {
      title: '생각을 되짚어 볼 차례예요',
      image: wb0_4_4,
      bg: '#FFBB37',
      texts: [
        {
          text: '감정-상황-생각을 구분해서 적어보았다면 그 기록을 다시 한번 찬찬히 살펴보는 시간이 필요하겠죠? 하지만 당시에 앨리스는 감정적으로 너무 격정적인 상태라 이성적으로 생각하기에는 어려운 상태였어요. 그래서 저는 앨리스의 감정, 상황, 그리고 생각을 수첩에 적어두었죠. 그리고 다음날 조금은 차분해 보이는 앨리스에게 그 기록을 보여줬죠.',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[
                ['앨리스, 어제 이렇게 생각했던 거 기억나요?'],
                ['네, 기억나죠. 어제는 많이 힘들었던 것 같아요. 지금은 그래도 조금 나아졌어요.'],
              ]}
              my
            />
          ),
        },
        {
          component: (
            <TextWithBold
              texts={[
                '앨리스는 제법 차분해진 상태였죠. 그런 상황이 되어야 나의 생각과 감정에 대해 조금 더 객관적으로 되돌아볼 수 있답니다. 즉, ',
                '생각을 되돌아보기 위해서는 시간이 조금 필요해요.',
                ' 저는 앨리스가 조금 더 이성적으로 생각해 볼 여유가 생겼다는 것을 파악하고 이야기를 이어나갔어요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '어떤 이야기를 했나요?',
    },
    {
      title: '“같이 다시 되돌아보죠”',
      image: wb0_4_5,
      bg: '#FFCC16',
      texts: [
        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[
                [
                  '그러면 우리 어제 이야기를 다시 한번 되돌아볼까요. 앨리스의 생각에 대해 마음대로 판단하고 정의내리기 위함은 아니에요. 제게 그런 일을 할 권리는 없죠. 앨리스의 마음은 온전히 앨리스의 것이니까요. 하지만 조금 더 ',
                  '다른 방향으로 생각해 볼 여지는 없었는지, 조금 더 내가 상황을 통제하는 느낌을 가질 순 없었는지',
                  ' 살펴보는 거예요. 이해했나요?',
                ],
                [
                  '물론이죠. 이해해요. 저는 선생님과 마음에 대해 이야기를 하고 싶어서 이 자리에 있는 거니까요. 제 마음에 대해 이야기하는 것에 대해 그렇게 기분 나쁘게 생각하지 않아요. 말씀해 주세요. 어떻게 생각해 볼 수 있었을까요?',
                ],
              ]}
              my
            />
          ),
        },
        {
          text: '앨리스 역시 마음의 자원이 풍부한 분이었죠. 스스로의 마음을 돌아볼 능력과 여유가 있었고요. 사실 몰라서 그렇지 모두가 그렇답니다. 우리 모두 그러한 잠재성이 있어요. 아무튼 저는 앨리스에게 조심스럽게 이야기했어요.',
        },
      ],
      buttonText: '궁금해요',
    },
    {
      title: '“이런 부분은 어떻게 생각하세요?”',
      image: wb0_4_6,
      bg: '#2A69C5',
      texts: [
        {
          text: '우선 상황과 생각이 적혀있는 노트를 내밀었어요.',
        },
        {
          component: (
            <MessageBlockWithBold
              textList={[
                {
                  bold: '상황',
                  text: [': 퇴근 후 잠시 만나자고 했는데 피곤하다는 걸 이유로 거절했다.'],
                  type: 'black',
                },
                {
                  bold: '생각',
                  text: [
                    ': 나를 사랑하지 않는 게 틀림없다. 내가 일보다도 우선순위가 더 낮은 것이다. 정상적인 연인이라면 아무리 피곤해도 보고 싶어하는 게 당연하다.',
                  ],
                  type: 'white_dotted',
                },
              ]}
              my
            />
          ),
        },
        {
          text: '그리고 하나씩 설명했어요.',
        },
        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[
                [
                  '우리들은 생각하는 과정에서 정말 많은 실수를 범한답니다. 저조차 그렇죠. 그리고 그 실수들 중에서는 자주 발생하는 것들이 몇 가지 있어요. 저는 이를 ',
                  '12가지의 생각함정',
                  '이라고 부르죠.',
                ],
                ['생각함정이요?'],
                [
                  '그래요, 생각함정이요. 말 그대로 생각이 함정에 빠지는 거예요. 잘 가다가 갑자기 ‘쿵’하고 함정에 빠지죠. ',
                  '함정에 빠지면 당황스러워요. 감정적인 반응이 나타나기도 하고요. 본인이 상황을 컨트롤하고 있다는 생각이 줄어들죠.',
                  ' 그렇게 되면 합리적인 방향으로 나아갈 수가 없어요. 길을 잘 걸어다니다가 누군가가 파둔 구덩이에 빠진다고 생각해봐요. 갑자기 좁고 어두운 곳에 놓여버리죠.',
                ],
                ['당황스러울 것 같아요. 그러니까 저도 그런 함정에 빠졌다는 거죠?'],
                [
                  '맞아요. 제가 봤을 때는 크게 두 가지 생각 함정이 보이네요. 한번 이야기해 볼까요? 생각이 다르면 언제든 다시 물어봐도 좋아요.',
                ],
                ['좋아요'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '두 가지 함정이 뭘까요?',
    },
    {
      title: '“우선 추측 생각 함정이 있네요”',
      image: wb0_4_7,
      bg: '#EFF2FF',
      texts: [
        {
          text: '저는 첫 번째 함정에 대해 설명했어요.',
        },

        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[
                [
                  '첫 번째는 ',
                  '추측',
                  '이에요. 추측은 ',
                  '충분한 정보가 없을 때 타인이 취하는 행동의 근거를 마음대로 추정하는 것',
                  '을 뜻하죠.',
                ],
                ['예를 들면요?'],
                [
                  '남자친구가 퇴근 후 피곤하여 다음에 보자고 한 것만으로 사랑하지 않는다고 생각할 수 있을까요? 정말로 피곤했을 수도 있죠. 상황만 놓고 살펴보면 ',
                  '남자친구의 감정을 파악하기에는 충분한 정보가 없다는 것',
                  '을 알 수 있어요. 남자친구는 그저 몸이 피곤해서 다음에 보자고 말했을 뿐인 걸요.',
                ],
                ['듣고 보니 그런 것 같기도 해요. 실제 그 날도 야근을 했었거든요...'],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '다른 함정은 무엇인가요?',
    },
    {
      title: '“다음은 당위 부여 함정이에요”',
      image: wb0_4_8,
      bg: '#EFF2FF',
      texts: [
        {
          text: '그리고 이어서 두 번째 함정을 설명했어요.',
        },

        {
          component: (
            <MessageBlockWithTailCross
              types={['white', 'gray']}
              texts={[
                [
                  '첫 번째는 ',
                  '당위 부여',
                  '예요. 당위부여는 ',
                  '나 자신, 다른 사람, 혹은 이 세상이 어떤 모습으로 어떻게 행동해야 하는지 명확히 이해하고 전부 다 내 믿음과 일치하기를 바라는 생각 함정',
                  '을 뜻해요. 주로 ‘해야 한다’라는 표현으로 나타나죠.',
                ],
                ['제가 그런 게 있었던가요?'],
                [
                  '정상적인 연인이라면 아무리 피곤해도 보고 싶어 하는 게 당연하다는 말씀 기억나세요? 아무리 연인이어도 피곤한 건 어떻게 할 수가 없는 걸요. 누구든 힘들고 지칠 때가 있지 않을까요? 연인이라고 모든 상황에서 서로를 가장 우선 생각할 수는 없어요. 각자의 사정이 또 있기 마련인 걸요.',
                ],
                [
                  '음... 그러게요. 사실 아무리 피곤해도 보고 싶어 하는 게 당연한 건 아닌 것 같아요. 피곤한 날도 있겠죠.',
                ],
              ]}
              margin="mt-[40px]"
            />
          ),
        },
      ],
      buttonText: '생각보다 당연한 이야기들이네요',
    },
    {
      title: '맞아요. 당연한 이야기들이랍니다',
      image: wb0_4_9,
      bg: '#6173D9',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '하지만 문제는 우리가 그 당연한 이야기인 생각 함정에 너무도 자주 빠진다는 거예요. ',
                '12개 밖에 되지 않는 생각 함정이지만 우리는 살면서 같은 유형의 생각들을 끊임없이 반복',
                '해요. 그리고 그러한 생각들은 대인관계를 흔들고, 삶에서 행복한 부분을 바라보지 못하게 하며, 나의 자존감을 갉아먹죠. 그래서 생각을 되짚어보고 함정에 빠지진 않았는지 살펴봐야 한답니다. 우리는 그러한 일을 이제 ',
                '‘회고’',
                '라고 부를 거예요.',
                '\n',
                '회고는 겉보기엔 당연해 보여요. 그래서 가볍게 생각하는 분들도 계시죠. 하지만 ',
                '실제로 회고를 해 보면 우리가 얼마나 많은 오류에 빠져있는지 알 수 있어요.',
                ' 더 큰 문제는 ',
                '그런 오류가 거의 무의식적으로 발생한다는 점',
                '이에요.',
                '\n',
                '지금까지 이야기 이해하셨을까요? 말하다 보니 이야기가 길어졌네요. 이쯤에서 쉬어가는 게 좋을 것 같아요. 그 전에 간단한 퀴즈를 내볼게요. 별로 어렵진 않을 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '무엇인가요?',
    },
    {
      replaceComponent: (
        <QuizTemplate
          question="생각 함정을 교정하는 살펴보는 작업을 뭐라고 부른다고 했죠?"
          buttonList={['확고', '회고', '고해', '회상']}
          answer="회고"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="회고라고 한답니다!"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '다양한 생각 함정들에 빠지지 않았는지 살펴보고 이를 교정하는 작업을 ',
                '회고',
                '라고 부르기로 했답니다. 회고를 하다 보면 내가 어떠한 부분에서 생각의 오류를 범하고 있는지 잘 알 수 있답니다. 그러한 오류들을 잡아나가다 보면 보다 합리적으로 생각할 수 있을 거예요.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      title: '좋아요, 잠시 쉴까요?',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '말이 너무 많았던 것 같네요. 항상 설명은 최소한으로 하고 함께 진행한 과제나 활동들을 기반으로 이야기하려고 하지만, 늘 처음 시작은 어쩔 수가 없는 것 같아요. 하지만 ',
                '내가 어떠한 방법으로 내 마음을 들여다 보고 있는지, 그 원리가 무엇인지 알고 그러한 일들을 하는 것과 그렇지 않은 것 사이에는 많은 차이',
                '가 있답니다. 그러니 조금만 더 공부해 보도록 해요.',
                '\n',
                '잠시 쉬었다가 와요. 바람을 쐬도 좋고, 음악을 들어도 좋아요. 저는 노래를 한 곡 듣고 오려고요. Carol Kidd의 <When I dream>이라는 노래 아시나요? 너무 옛날 노래를 이야기했네요. 저를 늙은이라고 생각하시겠어요, 하하.',
                '\n',
                '이어지는 시간에는 12가지의 생각 함정들을 간략하게 설명드릴 거예요. 그리고 선물도 하나 준비했답니다. 그러면 이따 만나요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '이따 만나요!',
      submit: 'EmotionFeedback',
    },
  ],
  [
    {
      title: '잘 쉬고 왔나요? 다시 시작해 보죠.',
      fullImage: wb0_5_1,
      height: 215,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '음악을 들으니 머리가 맑아지는 것 같네요. 때로는 아무것도 하지 않고, 아무 생각도 하지 않고, 가만히 눈을 감고 음악에 집중해 보는 것도 좋은 경험이랍니다. 음표 하나하나, 목소리의 작은 떨림 하나하나에 집중해 보세요. ',
                '소중하고 따뜻한 것들은 생각보다 우리 근처에 있답니다.',
                '\n',
                <span />,
                '자, 이제 시작해 봐요. 이번 시간에는 여섯 가지의 생각 함정에 대해 배울 거예요. 정말 간략히 배울 거랍니다. 보다 자세한 이야기는 다른 코스에서 진행할 거예요. 오늘은 회고 노트를 쓸 수 있을 정도로만 가볍게 살펴보기로 해요. 잘 이해되지 않아도 괜찮아요. 우선은 본인이 이해한대로 받아들이고 노트를 작성해 보는 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '좋아요',
    },
    {
      title: '흑백논리',
      image: wb0_5_2,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '흑백논리 생각 함정은 ',
                '극단적인 두 선택지 사이에서 타협점 없이 모 아니면 도라는 논리로 상황에 접근하는 방식',
                '이에요. 예를 들어볼까요?',
                '\n',
                '꼼꼼하게 일하기 좋아하는 스미스는 상사에게 보고서를 제출하고 나서 보고서에 작은 실수가 있다는 걸 알게 되었어요. 그리고 생각했죠.',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithTail textList={[['이번 보고서는 망했어']]} type={'white_dotted'} my />,
        },
        {
          text: '스미스에겐 중간은 없었답니다.',
        },
      ],
      buttonText: '이해했어요!',
    },
    {
      title: '좁은 시야',
      image: wb0_5_3,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '좁은 시야',
                ' 생각 함정은 ',
                '긍정적인 면을 보지 못하고 부정적인 면에만 집중하는 것을 뜻해요.',
                ' 예를 들어볼게요.',
                '\n',
                '제이크는 이번에 이직을 했어요. 이직한 회사는 급여도 더 높고, 사람들도 더 괜찮았어요. 그런데 업무강도가 조금 더 높았죠. 그래서 제이크는 생각했어요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['이직 괜히 한 것 같아. 이전 회사가 더 좋은 걸.']]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '제이크에게 새로운 회사의 장점은 눈에 들어오지 않았답니다.',
        },
      ],
      buttonText: '어렵지 않네요!',
    },
    {
      title: '일반화',
      image: wb0_5_4,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '일반화',
                ' 생각 함정은 ',
                '한 가지 사건을 가지고 자신 혹은 상황 전체를 전부 평가해버리는 것이에요.',
                ' 예를 들어보죠.',
                '\n',
                '리나는 남자친구와 이별했어요. 남자친구가 더이상 설렘을 느끼지 못한다고 말했거든요. 집에 돌아온 리나는 줄곧 이렇게 생각하고 있어요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['나는 누구랑 연애를 해도 이렇게 끝날 수밖에 없을 거야.']]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '그렇게 리나는 아직 시작하지도 않은 연애들의 결말을 다 정해버렸죠.',
        },
      ],
      buttonText: '그렇군요!',
    },
    {
      title: '추측',
      image: wb0_5_5,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '추측',
                ' 생각 함정은 ',
                '충분한 정보가 없을 때 타인이 취하는 행동의 근거를 마음대로 추정하는 것을 말해요.',
                ' 예를 들어볼까요?',
                '\n',
                '도서관에서 공부를 하고 있던 빌리는 옆에 있는 여학생이 자꾸 키득키득 웃는 걸 보았어요. 그러다가 여학생과 눈이 마주쳤죠. 그러자 여학생은 웃는 걸 멈췄어요. 그래서 빌리는 생각했죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['오늘 입고 온 티셔츠가 너무 유치한가? 내가 입기에는 너무 귀여운 캐릭터가 있는 옷인 것 같아.'],
              ]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '사실 여학생은 친구가 보낸 메시지를 보고 웃었던 것뿐이었죠.',
        },
      ],
      buttonText: '혼자서 추측하는 거군요!',
    },
    {
      title: '좋은 일 회피하기',
      image: wb0_5_6,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '좋은 일 회피하기',
                ' 생각 함정은 ',
                '긍정적인 사건이나 상황을 무시하고 깎아내리는 경향을 뜻해요.',
                ' 예를 들어볼게요.',
                '\n',
                '지나가던 상사가 에이미에게 말을 걸었어요. 이번주에 했던 발표가 좋았다는 내용이었어요. 상사의 말을 들은 에이미는 생각했죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['그냥 인사치레지. 뭐 그렇게 대단한 발표였다고.']]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '사실 그 날 했던 발표는 동료들로부터 가장 높은 점수를 받았었답니다.',
        },
      ],
      buttonText: '본인에게 엄격한 거군요!',
    },
    {
      title: '경험과 다른 결론',
      image: wb0_5_7,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '경험과 다른 결론',
                ' 생각 함정은 ',
                '실제 스스로가 경험한 것과는 전혀 다른 결론을 내리는 경향을 뜻해요.',
                ' 예를 들어볼게요.',
                '\n',
                '제시는 최소 6시간 이상은 자야 제대로 된 하루를 보낼 수 있다고 생각해요. 그리고 어젯밤 잠을 뒤척여 5시간 밖에 못 잔 제시는 다음과 같이 생각했죠.',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithTail textList={[['오늘 하루는 망했어...']]} type={'white_dotted'} my />,
        },
        {
          text: '제시는 작년 대학교 입시 시험 당일날 긴장한 탓에 4시간밖에 못 잤답니다. 그리고 좋은 성적을 받아 명문대에 입학했었죠. ',
        },
      ],
      buttonText: '그렇군요!',
    },
    {
      title: '어땠나요, 별로 어렵지 않았죠?',
      texts: [
        {
          text: [
            '당연한 내용들이 대부분일 거예요. 즉, 우리들은 생각보다 당연한 부분에서 실수를 한답니다. 바꿔 말하면 노력만 하면 얼마든지 고쳐볼 수 있는 여지가 있다는 뜻이죠. 우리 모두 보다 큰 마음의 잠재성을 가지고 있으니까요. 제 오랜 경험에 비추어 판단해 보았을 때 우리 모두가 그래요. 단지 그걸 못 알아차릴 뿐이죠.',
            <br />,
            <br />,
            '좋아요, 여기서 한번 쉬어갈까요? 그 전에 제가 퀴즈를 몇 개만 내보죠. 틀려도 괜찮으니 너무 부담스러워 하진 말고요.',
          ],
        },
      ],
      buttonText: '네, 준비됐어요!',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          buttonList={['흑백논리', '좁은 시야', '일반화', '추측']}
          contents={[
            {
              component: (
                <div>
                  <p>지나가던 친구에게 인사를 했는데 친구가 인사를 받지 않고 지나갔죠.</p>
                  <MessageBlockWithTail
                    textList={[['왜 인사를 했는데 받지 않지? 나를 깔보고 무시하는 게 틀림없어.']]}
                    type={'black_dotted'}
                    margin="my-[30px]"
                  />

                  <p>이런 생각이 들자 친구에게 화가 났어요. 내일 만나면 따져야겠다고 생각했죠.</p>
                </div>
              ),
            },
          ]}
          answer="추측"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="지레짐작 하는 건 추측이죠!"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '사람들은 ',
                '충분한 정보가 없을 때 타인이 취하는 행동의 근거를 마음대로 추정',
                '하기도 한답니다. 친구가 인사를 받지 않은 건 다른 이유가 있을 수도 있죠. 그냥 못 봤을 가능성도 있고요. 하지만 친구가 나를 무시한다고 생각하고 화를 내고 있어요. 우리는 이러한 생각 함정을 ',
                '추측',
                '이라고 배웠어요.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          contents={[
            {
              component: (
                <div>
                  <p>취업을 준비하던 수잔은 한 회사로부터 불합격 통보를 받았어요. 그리고 생각했죠.</p>
                  <MessageBlockWithTail
                    textList={[['서류에서 떨어지다니, 다른 곳들도 마찬가지겠지. 올해 취업할 순 없을 거야.']]}
                    type={'black_dotted'}
                    margin="my-[30px]"
                  />

                  <p>수잔은 준비를 열심히 해야겠다는 의욕을 잃어버렸어요. 그리곤 포기해버렸죠.</p>
                </div>
              ),
            },
          ]}
          buttonList={['흑백논리', '좁은 시야', '일반화', '추측']}
          answer="일반화"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="한 가지로 전체를 판단하고 있죠"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                <span />,
                '우리는 종종 한 가지 사건만으로 전체를 평가해버리죠.',
                ' 수잔이 불합격 통보를 받은 건 여러 회사들 중 한 곳뿐이었는 걸요. 한 곳에서 불합격했다고 해서 다른 회사에서도 불합격하리라는 법은 없죠. 수잔은 ',
                '일반화',
                '의 함정에 빠져있어요.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          contents={[
            {
              component: (
                <div>
                  <p>
                    안나는 올해 근무평가로부터 평균적인 점수를 받았어요. 더 높은 점수를 기대했지만 그렇게 나쁜 점수는
                    아니었죠. 그리고 안나는 다음과 같이 생각했어요.
                  </p>
                  <MessageBlockWithTail
                    textList={[['최악의 평가야. 이런 점수로는 어디서도 인정받지 못 하겠는 걸.']]}
                    type={'black_dotted'}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
          buttonList={['흑백논리', '좁은 시야', '일반화', '추측']}
          answer="흑백논리"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="중간도 있을 수 있어요"
          buttonText="잘 이해돼요!"
          answerInfo={
            <TextWithBold
              texts={[
                <span />,
                '항상 양극단만 있는 건 아니에요.',
                ' 안나는 평균적인 점수를 받았어요. 기대했던 엄청 뛰어난 점수도 아니었지만, 그렇다고 그렇게 나쁜 점수도 아니었어요. 하지만 안나는 점수가 기대에 조금 못 미치자 최악의 평가라고 이야기하고 있죠. 모 아니면 도, 양극단 중 하나만 생각하는 함정, 흑백논리입니다.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      title: '역시 잘 따라오시네요',
      texts: [
        {
          text: [
            '금방 배우시군요. 뛰어난 분인 줄 알았지만 그래도 놀라워요. 물론 제 진료실을 찾아오는 분들이 대부분 마음에 관심이 많은 분들이긴 하지만, 그래도 참 잘했어요. 이런 속도라면 생각보다 빠르게 마음에 대한 깊은 이야기들을 할 수 있을 것 같아요.',
            <br />,
            <br />,
            '이제 정말 조금 쉬고 오기로 하죠. 저는 고양이에게 밥을 주고 와야겠어요. 고양이가 외로움을 많이 타서 조금씩, 자주 밥을 주고 있거든요. 저라도 옆에 있어줘야죠. 자, 그러면 우리는 이따가 만날까요? 고양이가 저를 찾는 소리가 들리네요.',
          ],
        },
      ],
      buttonText: '저도 조금 쉬고 올게요!',
    },
  ],
  [
    {
      title: '이제 마지막 수업이에요',
      fullImage: wb0_6_1,
      height: 215,
      texts: [
        {
          text: '이번 시간에는 나머지 여섯 가지 생각 함정에 대해 배울 거랍니다. 선물도 하나 준비해뒀답니다. 그러니 얼른 마쳐보도록 하죠. 그나저나 충분히 쉬고 오셨나요? 진도를 빠르게 나가는 게 중요한 건 아니에요. 어디까지 본인의 속도에 맞게, 본인이 받아들일 수 있는 정도에 맞춰 진행하면 됩니다. 이해가 잘 안 되면 배웠던 부분을 다시 살펴보아도 괜찮고요. 똑똑한 분이시니 알아서 잘 조율하리라 생각이 들지만요. 그래요, 그럼 이제 시작해 보죠.',
        },
      ],
      buttonText: '좋아요',
    },
    {
      title: '성급한 결론',
      image: wb0_6_2,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '성급한 결론',
                ' 생각 함정은 ',
                '적절한 근거가 없는 상태에서 상황을 해석해버리는 것',
                '을 뜻해요. 예를 들어볼게요.',
                '\n',
                '케이티는 회사에서 발표를 하고 있어요. 그러다가 준비해 온 내용들을 깜빡했죠. 잠시 당황하던 찰나에 다음과 같은 생각이 케이티의 뇌리를 스쳤어요.',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithTail textList={[['끝났다. 망했어.']]} type={'white_dotted'} my />,
        },
        {
          text: '이후 케이티는 나머지 발표를 모두 엉망으로 했답니다.',
        },
      ],
      buttonText: '이해했어요!',
    },
    {
      title: '감정으로 판단하기',
      image: wb0_6_3,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '감정으로 판단하기',
                ' 생각 함정은 ',
                '현재 느끼는 감정이 진리라고 믿고 현실적 근거 없이 감정에 따라 상황을 판단하는 것을 뜻해요.',
                ' 예를 들어볼까요?',
                '\n',
                '사무엘은 중요한 시험을 앞두고 있죠. 누구보다 열심히 공부했죠. 여태까지 모의시험 점수도 좋았어요. 그리고 드디어 시험 당일 아침이 됐죠. 그런데 문득 사무엘은 이런 기분이 드는 거예요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['왜 이렇지. 예감이 좋지 않아. 괜히 다운되고 처지네. 어떻게 하지, 그냥 이번 시험은 포기해야 할까.'],
              ]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '그 날, 당연히 사무엘이 시험을 잘 볼 리는 없었죠.',
        },
      ],
      buttonText: '어렵지 않네요!',
    },
    {
      title: '당위 부여',
      bg: '#EFF2FF',
      image: wb0_6_4,
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '당위부여',
                ' 생각함정은 ',
                '나 자신, 다른 사람, 혹은 이 세상이 어떤 모습으로 어떻게 행동해야 하는지 명확히 이해하고 전부 다 내 믿음과 일치하기를 바라는 생각 함정을 뜻해요.',
                ' 예를 들어볼게요.',
                '\n',
                '앤디는 팀의 리더예요. 총 다섯 명의 팀원이 그를 따르고 있죠. 앤디는 항상 열심히 일해요. 팀원보다 더 일찍 출근하고, 더 늦게 퇴근하죠. 앤디는 다음과 같이 생각하거든요.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[['리더는 항상 모범을 보여야 해. 팀원들에게 안일한 모습을 보여선 안 돼.']]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '그리고 다섯 달 뒤, 회사에서 승승장구 하던 앤디는 결국 퇴사를 하고 말았답니다. 사유는 번아웃으로 인해 유발된 우울증이었죠.',
        },
      ],
      buttonText: '어떤 규칙을 부여하는 거네요',
    },
    {
      title: '꼬리표 붙이기',
      image: wb0_6_5,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '꼬리표 붙이기',
                ' 생각 함정은 ',
                '나 자신 혹은 다른 사람에게 실제로는 존재하지 않는 특성을 영구적으로 부여하는 것을 뜻해요.',
                ' 예를 들어볼까요?',
                '\n',
                '토마스는 어릴 적 친구들로부터 ‘미스터 루저’라고 놀림받은 기억이 있어요. 다소 왜소한 체격과 내성적인 성격 때문이었죠. 그의 뛰어난 글쓰기 능력은 전혀 생각하지 않은 별명이었어요. 토마스는 아직까지도 무슨 일을 하려고 할 때 이런 생각이 들 때가 있어요.',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithTail textList={[['나 같은 루저가 뭘 잘하겠어...']]} type={'white_dotted'} my />,
        },
        {
          text: '전국 글쓰기 대회에서 수상한 지 한 달도 안 된 시점이었죠.',
        },
      ],
      buttonText: '이해했어요!',
    },
    {
      title: '최악의 시나리오 떠올리기',
      image: wb0_6_6,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '최악의 시나리오 떠올리기',
                ' 생각 함정은 ',
                '그리 중요하지 않은 사건에서 가장 나쁜 상황을 상상하는 것을 뜻해요.',
                ' 예를 들어볼게요.',
                '\n',
                '수지에게는 중학생의 딸이 있어요. 오늘은 딸의 생일날이죠. 딸은 친구의 집에서 생일 잔치를 하고 온다고 했죠. 그런데 저녁 10시가 되어도 딸이 아직 귀가 하지 않는 거예요. 수지는 딸에게 전화를 걸엇고, 딸은 전화를 받지 않았어요. 수지는 생각했죠.',
              ]}
            />
          ),
        },
        {
          component: (
            <MessageBlockWithTail
              textList={[
                ['무슨 일이 생긴 게 틀림없어. 경찰에 신고를 해야 하나? 이러다가 영영 못 돌아오면 어떻게 하지?'],
              ]}
              type={'white_dotted'}
              my
            />
          ),
        },
        {
          text: '그 시간 수지는 집 앞에서 친구의 고민을 들어주고 있었죠. 휴대폰에는 20통의 부재중 전화 기록이 찍혀있었답니다.',
        },
      ],
      buttonText: '최악의 경우에 집착하는 거군요',
    },
    {
      title: '과도한 책임감',
      image: wb0_6_7,
      bg: '#EFF2FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                <span />,
                '과도한 책임감',
                ' 생각 함정은 ',
                '나와 관련 없는 일도 나와 관련된 일로 생각하면서 과도한 책임감을 느끼는 것을 뜻해요.',
                ' 예를 들어볼게요.',
                '\n',
                '올리비아는 아들의 중요한 시험날에 집에서 기도를 하기로 마음 먹었어요. 그리고 시험 당일, 집에서 기도를 하던 올리비아는 깜빡 잠에 들었답니다. 그런데 눈을 떠보니 시험 시간이 끝나있는 거예요. 불안한 마음으로 있던 올리비아는 시험을 잘 보지 못했다는 아들의 연락을 듣고는 다음과 같이 생각했죠.',
              ]}
            />
          ),
        },
        {
          component: <MessageBlockWithTail textList={[['내가 잠들지만 않았어도...']]} type={'white_dotted'} my />,
        },
        {
          text: '사실 아들은 어제 저녁에도 늦게까지 컴퓨터 게임을 했답니다.',
        },
      ],
      buttonText: '그렇군요!',
    },
    {
      title: '자, 여기까지예요.',
      texts: [
        {
          text: [
            '이렇게 12가지의 생각 함정에 대해 다 배워보았어요. 내용 자체는 생각보다 별로 어렵지 않죠? 물론 이것보다 더 깊은 내용도 있어요. 하지만 여기서는 다루지 않을 거예요. 너무 깊은 내용을 한번에 다루면 다시 저를 찾아오지 않더라고요. 저는 사람들이 계속 저를 찾아왔으면 좋겠어요. 고양이와 있는 것도 좋지만... 저는 이야기하는 게 좋거든요.',
            <br />,
            <br />,
            '자, 그러면 이번에도 제가 퀴즈를 몇 개만 내볼게요. 시작해볼까요? ',
          ],
        },
      ],
      buttonText: '네, 준비됐어요!',
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={1}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          buttonList={['성급한 결론', '감정으로 판단하기', '당위 부여', '꼬리표 붙이기']}
          contents={[
            {
              component: (
                <div>
                  <p>
                    소피아는 육아를 하고 있어요. 처음이라 서투른 부분이 많기도 하죠. 소피아는 실수를 할 때면 다음과 같이
                    생각해요.
                  </p>
                  <MessageBlockWithTail
                    textList={[['나는 아이 키우는 데에 재능이 없나봐.']]}
                    type={'black_dotted'}
                    margin="mt-[30px]"
                  />
                </div>
              ),
            },
          ]}
          answer="성급한 결론"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="근거 없이 결론 내리고 있죠"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '누구나 육아는 처음 하는 시기가 있답니다. 당연히 실수를 하기도 해요. 더군다나 몸이 피곤하고 정신이 없으면 더 그렇기도 하고요. 하지만 소피아는 초기에 실수 몇 번을 했다고 해서 본인이 육아에는 재능이 없다고 생각하고 좌절하고 있어요. ',
                '적절한 근거가 없이 성급하게 결론을 지어버리는 것, 성급한 결론',
                '이죠.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={2}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          contents={[
            {
              component: (
                <div>
                  <p>
                    엠마는 격렬한 스포츠를 좋아하죠. 특히 킥복싱을 좋아한답니다. 하지만 엠마는 다음과 같이 생각해요.
                  </p>
                  <MessageBlockWithTail
                    textList={[['여자는 조금 더 조신한 취미를 가지고 있어야 해.']]}
                    type={'black_dotted'}
                    margin="my-[30px]"
                  />

                  <p>
                    그래서 엠마는 늘 자기 전 침대 위에서만 킥복싱 컨텐츠들을 찾아본답니다. 밖에선 절대 이야기하지 않죠.
                    답답하더라도 어쩔 수 없어요.
                  </p>
                </div>
              ),
            },
          ]}
          buttonList={['최악의 시나리오 떠올리기', '감정으로 판단하기', '당위 부여', '꼬리표 붙이기']}
          answer="당위 부여"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="반드시 그러해야 한다고 생각할 필요는 없어요!"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '세상에 꼭 그러해야 한다는 규칙은 없답니다. 여성도 격렬한 스포츠를 좋아할 수도 있죠. 여성 중에서도 뛰어난 킥복싱 선수들이 있는 걸요. ‘그러해야 한다’라는 당위를 부여하게 되면 편협한 방식으로 생각할 수밖에 없답니다.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      replaceComponent: (
        <QuizTemplate
          index={3}
          question="아래와 같은 생각의 과정을 뭐라고 할까요?"
          contents={[
            {
              component: (
                <div>
                  <p>
                    에릭은 여자친구와 이별했어요. 여자친구가 해외로 유학을 가게 됐기 때문이었죠. 하지만 에릭은 다음과
                    같이 생각했어요.
                  </p>
                  <MessageBlockWithTail
                    textList={[['내가 조금만 잘했어도...']]}
                    type={'black_dotted'}
                    margin="my-[30px]"
                  />
                  <p>앤디는 후회의 나날들을 보내고 있답니다.</p>
                </div>
              ),
            },
          ]}
          buttonList={['최악의 시나리오 떠올리기', '감정으로 판단하기', '과도한 책임감', '꼬리표 붙이기']}
          answer="과도한 책임감"
        />
      ),
    },
    {
      replaceComponent: (
        <AnswerTemplate
          title="여자친구가 유학 가는 게 앤디의 잘못은 아니죠!"
          buttonText="이해했어요!"
          answerInfo={
            <TextWithBold
              texts={[
                '여자친구가 유학 가는 건 앤디가 어떻게 할 수 있는 문제가 아니에요. 앤디가 잘 대해주지 못해서 그런 상황이 발생한 것도 아니죠. 하지만 앤디는 이별하게 된 이유를 본인에게서 찾고 있어요. 관계에 있어서 과도한 책임감을 느끼고 있는 것이지요.',
              ]}
              mx
            />
          }
        />
      ),
    },
    {
      title: '고생 많았어요. 잘 배우시네요!',
      image: wb0_6_8,
      bg: '#57A4FF',
      texts: [
        {
          component: (
            <TextWithBold
              texts={[
                '이제 기본적인 생각 함정들을 배웠으니 실제 내 마음이 함정에 빠지진 않았는지 살펴봐야겠죠? 그래서 제가 ',
                '회고 노트',
                '를 선물해드릴 거예요. 지난번에 제가 선물해드렸던 감정 노트 기억하시나요? 나의 감정을 기록하고, 그때 상황을 적고, 그 상황에서 어떻게 생각했는지 적어보는 거였죠. 이제 그 기록을 바탕으로 회고를 진행할 거예요.',
                '\n',
                '그런데 앨리스의 사례에서 보았듯이 감정을 기록한 직후에는 회고를 진행하기가 쉽지 않아요. 이미 감정에 휩싸여 있는 상태이기 때문이죠. 그래서 ',
                '회고는 감정을 기록하고 난 이틀 뒤부터 진행',
                '할 수 있답니다. 감정 노트에 기록을 하고, ',
                '이틀 뒤에 회고 노트를 열어보면 회고를 할 수 있는 카드가 있을 거예요.',
                ' 우선은 어떠한 생각 함정이 있는지 살펴보고, 다르게 생각할 순 없었는지 가볍게 적어보도록 해요. 보다 자세한 원리와 작성 방법은 다른 코스에서 설명해드릴 거예요.',
              ]}
            />
          ),
        },
      ],
      buttonText: '회고 노트, 기대돼요!',
    },
    {
      title: '자, 그럼 이제 다음에 만나요',
      texts: [
        {
          text: [
            '지금까지의 수업은 정말 기본적인 내용이었답니다. 이제 이 내용을 바탕으로 개별 코스에서 다양한 주제에 대해 이야기해 볼 거예요. 어떠한 주제를 먼저 하든 상관없어요. 지금 나에게 필요한 코스를 선택하면 된답니다. 특정 코스를 시작하고자 하면 다른 코스를 먼저 들어야 하는 경우가 있을 순 있어요. 그런 순서만 잘 따르면 큰 문제는 없을 거예요. 제가 어떤 코스들을 먼저 다뤄야 하는지 알려드릴 거니 너무 걱정하지 말고요.',
            <br />,
            <br />,
            '자, 그럼 저는 이제 쉬러 가야겠어요. 고양이가 많이 외로워할 것 같기도 해요. 고양이 옆에서 책을 좀 읽다가 잠들까봐요. 그러면 우리는 다음에 뵙도록 하죠. 너무 늦게 찾아오진 말아요. 저도 이야기할 상대가 필요하답니다.',
            <br />,
            <br />,
            '그럼 수고했어요, 이틀 간 즐거운 대화였어요.',
          ],
        },
      ],
      buttonText: '수고했어요!',
    },
  ],
];

export default course0Data;
