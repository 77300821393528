import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import webviewToast from '../../../development/webviewToast';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import moment from 'moment';
import 'moment/locale/ko';
import TimePicker from './TimePicker';
import { requestAppState, showModal, impactFeedback } from '../../../utils/webview';
import axios from '../../../utils/axios2';
import useSessionStorage from '../../../hooks/useSessionStorage';

export default function AlarmUpTimePicker({ type }) {
  const { id, page } = useParams();
  const [searchParams] = useSearchParams();
  const [isClicked, setIsClicked] = useState(false);

  const nowTime = moment(new Date());
  const defaultValue = {
    hour: nowTime.format('H'),
    minute: Math.floor(nowTime.format('mm') * 0.1) * 10,
  };

  const [alarmTime, setAlarmTime] = useSessionStorage('alarmTime', defaultValue);
  const [goToBedTime, setGoToBedTime] = useSessionStorage('goToBedTime', { hour: 23, minute: 0 });

  const [isAlarmSet, setIsAlarmSet] = useSessionStorage('isAlarmSet', false);
  const [hour, setHour] = useState(alarmTime.hour);
  const [minute, setMinute] = useState(alarmTime.minute);

  const [buttonState, setButtonState] = useState('ACTIVE');

  const context = useContext(CoachingContext);
  const navigate = useNavigate();

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  console.log(goToBedTime);

  console.log('지금 시간은:', alarmTime, hour, minute);
  useEffect(() => {
    if (type === 'isSet') {
      setHour(alarmTime.hour === 23 ? 0 : parseInt(alarmTime.hour) + 1);
      setMinute(Math.floor(parseInt(alarmTime.minute) * 0.1) * 10);
    } else {
      setHour(alarmTime.hour);
      setMinute(Math.floor(parseInt(alarmTime.minute) * 0.1) * 10);
    }
  }, []);

  const getHour = (hour) => {
    if (type !== 'isSet') {
      if (alarmTime.hour !== hour) {
        impactFeedback('selection');
      }
      setHour(hour);
      setAlarmTime({ ...alarmTime, hour: hour });
    }
  };

  const getMinute = (minute) => {
    if (type !== 'isSet') {
      if (alarmTime.minute !== minute) {
        impactFeedback('selection');
      }
      setMinute(minute);
      setAlarmTime({ ...alarmTime, minute: minute });
    }
  };

  const divs = document.getElementsByClassName('slick-list');
  for (var i = 0; i < divs.length; i++) {
    divs[i].style.height = '200px';
  }

  const postApi = async () => {
    console.log(type);
    if (type !== 'wakeup') {
      // 기상 시간은 로딩이 필요 없음
      setButtonState('LOADING');
      // 수면 처방에서 알림 설정 했을 때 기상 시각도 설정
    }
    if (type === 'wakeup') {
      postWakeUpTime();
    } else if (type === 'isSet') {
      await postWakeUpNotification().then(() => postSleepNotification());
    } else {
      postNotificationOn();
    }
  };

  const handleSetAlarm = () => {
    if (!isAlarmSet) {
      requestAppState('alarm');
      getAlarmPermission();
    } else {
      postApi();
    }
  };

  const PushIcon = (
    <div className="flex items-center h-[25px]">
      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.35658 0C3.52824 0 1.23542 2.29283 1.23542 5.12117V9.31121L0.362653 10.8981C0.179379 11.2313 0.420459 11.639 0.800761 11.639H11.9124C12.2927 11.639 12.5338 11.2313 12.3505 10.8981L11.4777 9.31121V5.12117C11.4777 2.29282 9.18492 0 6.35658 0ZM4.92532 13.221C4.72706 12.9377 4.61073 12.5929 4.61073 12.221H5.61073H7.10243H8.10243C8.10243 12.5929 7.98611 12.9377 7.78785 13.221C7.47219 13.6719 6.94882 13.9668 6.35658 13.9668C5.76434 13.9668 5.24098 13.6719 4.92532 13.221Z"
          fill="#80838B"
        />
      </svg>

      <p className="ml-[8.24px] text-[15px] leading-[21px]">알람 시각</p>
    </div>
  );

  const WakeUpIcon = (
    <div className="flex items-center h-[25px]">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.8735 2.50781H13.1235V6.25781H11.8735V2.50781ZM16.2484 12.5078C16.2484 14.5789 14.5695 16.2578 12.4984 16.2578C10.4274 16.2578 8.74843 14.5789 8.74843 12.5078C8.74843 10.4367 10.4274 8.75781 12.4984 8.75781C14.5695 8.75781 16.2484 10.4367 16.2484 12.5078ZM5.87056 4.98556L4.98667 5.86944L7.63832 8.52109L8.52221 7.63721L5.87056 4.98556ZM22.4997 13.1328H18.7497V11.8828H22.4997V13.1328ZM20.0103 5.88944L19.1265 5.00556L16.4748 7.65721L17.3587 8.54109L20.0103 5.88944ZM11.8735 18.7544H13.1235V22.5044H11.8735V18.7544ZM17.3594 16.4836L16.4755 17.3675L19.1271 20.0192L20.011 19.1353L17.3594 16.4836ZM6.24805 11.8828V13.1328H2.49805V11.8828H6.24805ZM8.52148 17.3772L7.63759 16.4933L4.98594 19.145L5.86983 20.0288L8.52148 17.3772Z"
          fill="#FFC700"
        />
      </svg>

      <p className="ml-[5px] text-[15px] leading-[21px]">잠에서 깨는 시각</p>
    </div>
  );

  const GoToBedIcon = (
    <div className="flex items-center h-[25px]">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.6735 7.76094C4.6735 6.76682 5.47939 5.96094 6.4735 5.96094H18.5257C19.5198 5.96094 20.3257 6.76682 20.3257 7.76094V11.1783H4.6735V7.76094ZM7.60929 10.3586C7.60929 9.36448 8.41518 8.55859 9.40929 8.55859H15.5919C16.586 8.55859 17.3919 9.36448 17.3919 10.3586V11.1673H7.60929V10.3586ZM4.56914 11.9544C3.9064 11.9544 3.36914 12.4917 3.36914 13.1544V15.1055V17.0631V19.0185H5.32566V17.0631H19.6737V19.0185H21.6302V15.1055H21.63V13.1544C21.63 12.4917 21.0927 11.9544 20.43 11.9544H4.56914Z"
          fill="#A0A5BE"
        />
      </svg>

      <p className="ml-[5px] text-[15px] leading-[21px]">침대에 들어가는 시각</p>
    </div>
  );

  const alarmSetData = [
    {
      icon: WakeUpIcon,
      alarmTitle: '수면 기록 알림',
      setTime: alarmTime,
      alarmHour: hour < 10 ? `0${hour}` : `${hour}`,
    },
    {
      icon: GoToBedIcon,
      alarmTitle: '취침 준비 알림',
      setTime: goToBedTime,
      alarmHour:
        goToBedTime.hour === 0
          ? '23'
          : goToBedTime.hour - 1 < 10
          ? `$0${goToBedTime.hour - 1}`
          : `${goToBedTime.hour - 1}`,
    },
  ];

  const typeInfo = [
    {
      type: 'push',
      title: '매일 잊지 않게 알려드릴게요',
      subtitle: '일어난 뒤 1시간 이내로 코스를 진행해야 해요.',
      icon: PushIcon,
      api: '/sleep/notification/on',
      alarmType: 'sleep_record',
    },
    {
      type: 'wakeup',
      title: '앞으로 몇시에 일어날까요',
      subtitle: '너무 늦은 시간은 피하고, 평소에 깨어나는 시각으로 정해주세요.',
      icon: WakeUpIcon,
      api: '/sleep/expected-wake-up-time/submit',
      alarmType: 'sleep_record',
    },
    {
      type: 'isSet',
      title: '매일 잊지 않게 알려드릴게요',
      subtitle:
        '일어난 뒤 1시간 내에 수면을 기록하세요. 침대 들어가기 1시간 전부터는 몸의 긴장을 풀고 잘 준비를 해주세요.',
      icon: PushIcon,
      api: '/sleep/notification/on',
      alarmType: 'ready_in_bed',
    },
  ];

  const info = typeInfo.find((item) => item.type === type);

  const postWakeUpTime = async () => {
    try {
      const mockDate = new Date();
      mockDate.setHours(0);
      mockDate.setMinutes(0);
      const alarmDate = moment(mockDate).add({ hours: alarmTime.hour, minutes: alarmTime.minute }).format('HH:mm');
      console.log('alarm', alarmDate);
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          typeInfo.find((item) => item.type === 'wakeup').api,
          JSON.stringify({
            type: typeInfo.find((item) => item.type === 'wakeup').alarmType,
            time: alarmDate,
          }),
        )
        .then((response) => {
          webviewToast('suceess 기상 시간: ' + alarmTime.hour + ' ' + alarmTime.minute);
          navigate(`/workbook/${parseInt(id)}/${parseInt(page) + 1}${window.location.search}`);
        });
    } catch (error) {
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const postWakeUpNotification = async () => {
    try {
      const mockDate = new Date();
      mockDate.setHours(0);
      mockDate.setMinutes(0);
      const alarmDate = moment(mockDate).add({ hours: hour, minutes: minute }).format('HH:mm');
      console.log('alarm', alarmDate);
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          info.api,
          JSON.stringify({
            type: 'sleep_record',
            time: alarmDate,
          }),
        )
        .then((response) => {
          webviewToast('suceess 기상 알람: ' + alarmDate);
        });
    } catch (error) {
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const postSleepNotification = async () => {
    try {
      const mockDate = new Date();
      mockDate.setHours(0);
      mockDate.setMinutes(0);
      const alarmDate = moment(mockDate)
        .add({ hours: goToBedTime.hour === 0 ? 23 : goToBedTime.hour - 1, minutes: goToBedTime.minute })
        .format('HH:mm');
      console.log('alarm', alarmDate);
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          info.api,
          JSON.stringify({
            type: 'ready_in_bed',
            time: alarmDate,
          }),
        )
        .then((response) => {
          webviewToast('suceess 수면 알람: ' + alarmDate);
          navigate(`/workbook/${parseInt(id)}/${parseInt(page) + 1}${window.location.search}`);
        });
    } catch (error) {
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const postNotificationOn = async () => {
    try {
      const mockDate = new Date();
      mockDate.setHours(0);
      mockDate.setMinutes(0);
      const alarmDate = moment(mockDate).add({ hours: hour, minutes: minute }).format('HH:mm');
      console.log('alarm', alarmDate);
      await axios(3, searchParams.get('at'), searchParams.get('app_id'))
        .post(
          info.api,
          JSON.stringify({
            type: info.alarmType,
            time: alarmDate,
          }),
        )
        .then((response) => {
          webviewToast('suceess 알람 시간: ' + hour + ' ' + minute + ' ' + info.alarmType);
          navigate(`/workbook/${parseInt(id)}/${parseInt(page) + 1}${window.location.search}`);
        });
    } catch (error) {
      webviewToast('error' + error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getAlarmPermission = () => {
    const eventFromApp = async (event) => {
      setIsAlarmSet(true);
      webviewToast('alarm message: ' + JSON.stringify(event.detail));
      // 권한이 없을 때
      if (!isAlarmSet) {
        if (!event.detail.isEnabled) {
          showModal({ type: 'alarm' });
        } else {
          // 권한 있을 때
          postApi();
        }
      }
    };

    window.addEventListener('alarmPermission', eventFromApp);

    return () => {
      window.removeEventListener('alarmPermission', eventFromApp);
    };
  };

  useEffect(() => {
    if (buttonState === 'LOADING') {
      setTimeout(() => {
        setButtonState('ACTIVE');
      }, 5000);
    }
  }, [buttonState]);

  const hours = Array.from({ length: 24 }, (v, i) => `${i / 10 < 1 ? `0${i}` : i}`);
  console.log(type === 'push');
  return (
    <div className={`w-full px-20px h-[calc(100vh-17px)]`}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div>
          <div
            className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C]  mt-[50px] "
            style={{ wordBreak: 'keep-all' }}
          >
            {info.title}
          </div>
          <p className="text-[17px] leading-[28px] text-[#4E4F53] mt-[24px] mb-[20px] font-light">{info.subtitle}</p>
          {type !== 'isSet' ? (
            <div
              className="flex items-center justify-between rounded-[12px] p-[20px] mb-[40px]"
              style={{ border: '1px solid #26282C' }}
            >
              {info.icon}
              {isClicked ? (
                <div className="text-[16px] leading-[22px] font-bold text-[#A4A6B0]">시간을 선택해주세요</div>
              ) : (
                <div className="text-[16px] leading-[22px] font-bold ">{`${hour}:${minute === 0 ? '00' : minute}`}</div>
              )}
            </div>
          ) : (
            <div className="pt-[20px]">
              {alarmSetData.map((item, index) => (
                <div
                  className={`flex flex-col rounded-[12px] ${index === 0 && 'mb-[10px]'}`}
                  style={{ border: '1px solid #D1D5DC' }}
                >
                  <div className="h-[64px] w-full flex justify-between items-center px-[20px]">
                    {item.icon}
                    <div className="text-[16px] leading-[22px] font-bold text-[#80838B]">{`${item.setTime.hour}:${
                      item.setTime.minute === 0 ? '00' : item.setTime.minute
                    }`}</div>
                  </div>
                  <div className="w-full h-[1px] bg-[#D9D9D980]" />
                  <div
                    className="h-[64px] w-full flex justify-between items-center font-bold bg-[#FAFBFD] px-[20px]"
                    style={{ borderRadius: '0 0 12px 12px' }}
                  >
                    <div className="flex items-center h-[25px]">
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.3563 5C9.528 5 7.23517 7.29283 7.23517 10.1212V14.3112L6.36241 15.8981C6.17913 16.2313 6.42022 16.639 6.80052 16.639H17.9122C18.2925 16.639 18.5335 16.2313 18.3503 15.8981L17.4775 14.3112V10.1212C17.4775 7.29282 15.1847 5 12.3563 5ZM10.9251 18.221C10.7268 17.9377 10.6105 17.5929 10.6105 17.221H11.6105H13.1022H14.1022C14.1022 17.5929 13.9859 17.9377 13.7876 18.221C13.4719 18.6719 12.9486 18.9668 12.3563 18.9668C11.7641 18.9668 11.2407 18.6719 10.9251 18.221Z"
                          fill="#80838B"
                        />
                      </svg>
                      <p className="ml-[5px] text-[15px] leading-[21px]">{item.alarmTitle}</p>
                    </div>
                    <div className="text-[16px] leading-[22px] font-bold">{`${item.alarmHour}:${
                      item.setTime.minute === 0 ? '00' : item.setTime.minute
                    }`}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div>
          {type !== 'isSet' && (
            <TimePicker hour={hour} minute={minute} getHour={getHour} getMinute={getMinute} hours={hours} />
          )}
          <div
            className={`w-full rounded-[16px] mt-[40px] ${
              type !== 'wakeup' ? '' : context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'
            } `}
          >
            <SecondaryActionButton state={buttonState} text={'다음'} action={handleSetAlarm} />
          </div>
          {type !== 'wakeup' && (
            <div
              className={`${
                context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'
              } w-full flex items-start justify-center text-[#A4A6B0] text-[15px] leading-[18px] mt-[20px]`}
            >
              <div className="relative" onClick={goNext}>
                <p>다음에 받기</p>
                <div className="absolute bottom-[0.5px] w-full h-[1px] bg-[#A4A6B0]" style={{}} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
