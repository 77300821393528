import tail_black from '../image/tail_black.png';
import tail_blue from '../image/tail_blue.png';
import tail_dark from '../image/tail_dark.png';
import tail_dotted from '../image/tail_dotted.png';
import tail_gray from '../image/tail_gray.png';
import tail_white from '../image/tail_white.png';
import grayQuoteL from '../image/grayQuoteL.png';
import grayQuoteR from '../image/grayQuoteR.png';
import blueQuoteL from '../image/blueQuoteL.png';
import blueQuoteR from '../image/blueQuoteR.png';

const workbookMessageColorData = [
  {
    type: 'black',
    backgroundColor: '#3A3C40',
    color: '#FFFFFF',
    border: '1px solid #3A3C40',
    tail: tail_black,
  },
  {
    type: 'gray',
    backgroundColor: '#F3F5F8',
    color: '#000000',
    border: '1px solid F3F5F8',
    tail: tail_gray,
    quoteL: grayQuoteL,
    quoteR: grayQuoteR,
  },
  {
    type: 'blue',
    backgroundColor: '#2C4BEC',
    color: '#FFFFFF',
    border: '1px solid #2C4BEC',
  },
  {
    type: 'light_blue',
    backgroundColor: '#EFF2FF',
    color: '#4E4F53',
    border: '1px solid #EFF2FF',
    tail: tail_blue,
    quoteL: blueQuoteL,
    quoteR: blueQuoteR,
  },
  {
    type: 'blue_border',
    backgroundColor: '#EFF2FF',
    color: '#2C4BEC',
    border: '1px solid #2C4BEC',
  },
  {
    type: 'black_dotted',
    backgroundColor: '#1E2023',
    color: '#FAFBFD',
    border: '1px dashed #A4A6B0',
    tail: tail_dark,
  },
  {
    type: 'white_dotted',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px dashed #A4A6B0',
    tail: tail_dotted,
  },
  {
    type: 'white',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid #D1D5DC',
    tail: tail_white,
  },
];

export default workbookMessageColorData;
