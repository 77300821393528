import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from '../../utils/axios2';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context';
import { motion } from 'framer-motion';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { Input } from '../../stories/text/Input';
import { Title } from '../../stories/text/Title';

export default function InputTemplate(props) {
  const { question } = props;
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState('');
  const [viewportSize, setViewportSize] = useState(window.visualViewport.height);
  const [windowSize, setWindowSize] = useState(window.innerHeight);
  const [loaded, setLoaded] = useState(false);
  const [stopFocus, setStopFocus] = useState(false);
  const [forceBlur, setForceBlur] = useState(false);
  const [buttonState, setButtonState] = useState('INACTIVE');

  useEffect(() => {
    setLoaded(true);

    window.visualViewport.addEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    console.log('resize', window.visualViewport.height, window.clientHeight);
    setViewportSize(window.visualViewport.height);
    setWindowSize(window.innerHeight);
    if (window.android) {
      if (window.innerHeight < windowSize) {
        setKeyboardOpen(true);
      } else {
        setKeyboardOpen(false);
      }
    } else {
      if (window.visualViewport.height < viewportSize) {
        setKeyboardOpen(true);
      } else {
        setKeyboardOpen(false);
      }
    }
  };

  const updatename = useCallback(async () => {
    try {
      setButtonState('LOADING');
      const nicknameData = JSON.stringify({ nickname: context.nickname });
      const response = await axios(3, context.authToken, context.appId).post(`/user/nickname/update`, nicknameData);
      console.log('닉네임 업데이트함', response);
      if (response.data.code === 0) {
        console.log('정상');
        setStopFocus(true);
        setButtonState('DONE');
      } else {
        let result = response.data.message;
        console.log('안돼!: ', result);
        setVerifyMessage(result);
        setButtonState('INACTIVE');
      }
    } catch (error) {
      console.log(context.authToken);
      console.error('result', error);
    }
  }, [context.nickname]);

  useEffect(() => {
    console.log('stopfocus change: ', stopFocus);
    if (stopFocus) {
      setTimeout(() => {
        setForceBlur(true);
        navigate(`/coachMatching/2`);
      }, 400);
    }
  }, [stopFocus]);

  useEffect(() => {
    if (context.nickname === '' || verifyMessage !== '') {
      console.log('INACTIVE');
      setButtonState('INACTIVE');
    } else {
      console.log('ACTIVE');
      setButtonState('ACTIVE');
    }
  }, [context.nickname, verifyMessage]);

  function updateValue(value) {
    context.setNickname(value);
  }
  function updateErrorMessage(value) {
    console.log('error message 받음', value);
    setVerifyMessage(value);
  }
  return (
    <motion.div
      animate={{
        height: window.android ? `${windowSize}px` : `${viewportSize}px`,
        paddingBottom: !isKeyboardOpen && context.biggerMargin ? `34px` : `20px`,
      }}
      transition={{ ease: 'circOut', duration: loaded ? 0.15 : 0 }}
      className={`px-[20px] pt-[7px] flex flex-col justify-between`}
    >
      <div>
        <div className="mb-[43px]">
          <Title title={question} />
        </div>
        <Input
          placeholder={'언제든 바꿀 수 있어요 (최대 10자)'}
          value={context.nickname}
          maxLength={'10'}
          errorMessage={verifyMessage}
          stopFocus={stopFocus}
          forceBlur={forceBlur}
          autofocus={true}
          updateValue={updateValue}
          updateErrorMessage={updateErrorMessage}
          noBlank
        />
      </div>

      <PrimaryActionButton state={buttonState} text={'다음'} action={updatename} />
    </motion.div>
  );
}
