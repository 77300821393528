/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context';
import { motion } from 'framer-motion';
import useFetch from '../../hooks/useFetch';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import webviewToast from '../../development/webviewToast';

export default function Analysis() {
  // const context = useContext(CoachingContext);
  const context = useContext(AuthContext);
  const [first, setFirst] = useState(true);
  const [choiceIdList, setChoiceIdList] = useState(null);
  const [hopeToCounselingDateList, setHopeToCounselingDateList] = useState(null);
  const [keywordList, setKeywordList] = useState(null);
  const [showText, setShowText] = useState(null);
  const [done, setDone] = useState(false);

  // const [submitResult, submit] = useFetch({
  //   url: `/counselor/survey-for-recommendation/submit`,
  //   requestBody: JSON.stringify({ choiceIdList: choiceIdList, hopeToCounselingDateList: hopeToCounselingDateList }),
  //   bg: '#2C4BEC',
  // });
  const [submitResult, submit] = useFetch({
    url: `/counselor/survey-for-assign/submit`,
    requestBody: JSON.stringify({ choiceIdList: choiceIdList }),
    bg: '#2C4BEC',
  });

  const goAnalysis2 = useNavigateWithParams('/counselorSearchWizard/analysis2');

  useEffect(() => {
    if (submitResult) {
      webviewToast('keyword:' + submitResult.result.keywordList);
      console.log(submitResult.result);
      setKeywordList(submitResult.result.keywordList);
      context.setCounselor(submitResult.result.counselor);
      for (var i = 0; i < submitResult.result.keywordList.length; i++) {
        eachStepDone(i);
      }
    }
  }, [submitResult]);

  useEffect(() => {
    console.log(showText);
  }, [showText]);

  const duration = 800;

  function eachStepDone(i) {
    setTimeout(() => {
      setShowText(
        Array.from({ length: submitResult.result.keywordList.length }, (v, idx) => (idx <= i - 1 ? true : false)),
      );
      if (i === submitResult.result.keywordList.length - 1) {
        setTimeout(() => {
          setShowText(Array.from({ length: submitResult.result.keywordList.length }, (v, idx) => true));
          setTimeout(() => {
            setFirst(false);
            setTimeout(() => {
              setDone(true);
            }, 200);
          }, 1000);
        }, duration);
      }
    }, i * duration);
  }

  useEffect(() => {
    if (done) {
      goAnalysis2();
    }
  }, [done]);

  useEffect(() => {
    console.log('CHOICES-----------', context.counselorSearchWizardChoice);
    const choiceIdList_temp = context.counselorSearchWizardChoice
      .map((element) => {
        if (element.id !== 'crc-7' && element.id !== 'date') {
          if (element.answer.id !== 'skip') {
            return element.answer.id;
          }
        }
        return '';
      })
      .filter((element) => element !== '');
    const hopeToCounselingDateList_temp = context.counselorSearchWizardChoice.find((element) => element.id === 'date')
      ? context.counselorSearchWizardChoice
          .find((element) => element.id === 'date')
          .answer.map(
            (date) =>
              `${new Date(date).getFullYear()}-${String(new Date(date).getMonth() + 1).padStart(2, '0')}-${String(
                new Date(date).getDate(),
              ).padStart(2, '0')}`,
          )
      : [];
    console.log(choiceIdList_temp, hopeToCounselingDateList_temp);
    setChoiceIdList(choiceIdList_temp);
    setHopeToCounselingDateList(hopeToCounselingDateList_temp);
  }, []);

  useEffect(() => {
    if (choiceIdList !== null) {
      console.log('호출');
      submit();
    }
  }, [choiceIdList]);

  return (
    <div
      className={`w-screen h-screen overflow-hidden  bg-[#2C4BEC] px-[20px] text-white flex flex-col justify-center items-center text-center  font-bold `}
    >
      <motion.div animate={{ opacity: first ? 1 : 0 }} transition={{ duration: 0.2 }} className="opacity-0 pb-[30px]">
        <motion.div
          animate={{
            height: showText && showText[0] ? `${36 * showText.filter((element) => element).length + 24}px` : 0,
          }}
          transition={{ duration: 0.6, ease: 'easeInOut' }}
          className="overflow-hidden h-0 "
        >
          {keywordList &&
            keywordList.map(
              (keyword, index) =>
                showText && (
                  <motion.div
                    key={`keyword${index}`}
                    animate={{ opacity: showText[index] ? 1 : 0 }}
                    transition={{ duration: 0.5, delay: 0.3, ease: 'easeInOut' }}
                    className="text-[30px] leading-[36px] text-[rgba(0,0,0,0.5)] "
                  >
                    #{keyword}
                  </motion.div>
                ),
            )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.2 }}
          className=" text-[24px] leading-[30px] "
        >
          {'답변 내용을 분석하고 있어요'}
        </motion.div>
      </motion.div>
    </div>
  );
}
