/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import anxyTest from '../../image/anxyTest.png';
import { BottomActionAnxy } from '../../stories/page/BottomActionAnxy';
import { motion } from 'framer-motion';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from '../../recoil/common/header/atom';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import gad7 from '../../image/anxy/gad7.png';
import { AnxyLoadingLottie } from '../../stories/animation/AnxyLoadingLottie';
import selfCheckAtom from '../../recoil/common/selfCheck/atom';

export default function AnxyStart(props) {
  const [searchParams] = useSearchParams();
  const GAD = searchParams.get('GAD-7') === 'true';

  const goTest = useNavigateWithParams(`/anxyTest/1`);
  const goTestReplace = useNavigateWithParams(`/anxyTest/1`, true);
  const setHeaderState = useSetRecoilState(headerAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const selfCheckState = useRecoilValue(selfCheckAtom);

  useEffect(() => {
    setProgressBarState((state) => ({ ...state, isProgressBarVisible: false }));
    setHeaderState({ headerButtonType: BUTTON_NONE, headerTitle: '' });
  }, []);

  useEffect(() => {
    if (GAD && selfCheckState.qcList.length > 0) {
      goTestReplace();
    }
  }, [selfCheckState]);

  return !GAD ? (
    <BottomActionAnxy
      content={
        !GAD ? (
          <div className="w-screen h-full bg-[#FFECAB]   flex flex-col justify-center relative">
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5, ease: 'easeInOut' }}
              src={anxyTest}
              alt={'empty'}
              className="w-[420px] min-w-[420px] absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]"
            />
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5, ease: 'easeInOut' }}
              className=" px-[20px] flex flex-col gap-[14px] items-center  text-center text-[#26282C]"
            >
              <p className=" text-[30px] leading-[36px] font-bold text-[#26282C] ">
                2분간의 간단한 퀴즈로 나의 불안 타입을 알아볼게요
              </p>
              <p className=" text-[17px] leading-[23px] opacity-[0.6] ">그리고 어떻게 개선할 수 있을지 살펴봐요.</p>
            </motion.div>
          </div>
        ) : (
          <div className="w-screen h-full  bg-[#F3F5F8]  pt-[20px] flex flex-col ">
            <div className="w-full flex flex-col items-center px-[20px]">
              <p className="bg-[#26282C] rounded-[20px] px-[15px] py-[4px] text-[15px] leading-[21px] font-bold text-white mb-[15px]">
                {`GAD-7(7문항)`}
              </p>

              <p className={`text-[40px] leading-[48px] font-bold text-[#26282C] mb-[10px]`}>{'불안'}</p>
              <p className="pb-[20px] text-center text-[17px] leading-[23px] font-normal text-[#6B6D76] ">
                {'지난 2주간 어땠는지 떠올리며 답해주세요'}
              </p>
            </div>
            <div className="flex-1  pt-[50px] pb-[20px] flex flex-col ">
              <img src={gad7} alt="empty" />
            </div>
          </div>
        )
      }
      buttonText={!GAD ? '불안 타입 알아보기' : '시작하기'}
      buttonState={'ACTIVE'}
      action={() => {
        goTest();
      }}
      bgColor={!GAD ? '#FFECAB' : '#F3F5F8'}
      noGradient
      fadeIn={!GAD}
      noHeader={!GAD}
    />
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <AnxyLoadingLottie />
    </div>
  );
}
