import React, { useState, useEffect } from 'react';
import selfCheckData from '../../data/selfCheckData';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { SecondaryActionButton } from '../../stories/button/ActionButton';

export default function SelfCheckNotStartedCardA(props) {
  const { selfCheckId } = props;
  const [isSoonClicked, setIsSoonClicked] = useState(false);
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === selfCheckId);

  const goSelfCheck = useNavigateWithParams(`/mindScan/${selfCheckId}/0`);

  useEffect(() => {
    if (isSoonClicked) {
      setTimeout(() => {
        setIsSoonClicked(false);
      }, 1000);
    }
  }, [isSoonClicked]);

  return (
    <div className="w-full bg-white rounded-[20px] px-[20px] pt-[30px] pb-[20px]">
      <img
        src={selfCheckInfo.image_square}
        alt={'empty'}
        className="w-[70px] h-[70px] rounded-[70px] mx-auto mb-[15px]"
      />
      <p className="text-[20px] leading-[26px] text-center text-[#26282C] font-bold mb-[18px]">
        {selfCheckInfo.cardATitle}
      </p>
      <div className="w-full bg-[#F3F5F8] rounded-[12px] px-[15px] py-[20px] mb-[20px]">
        {selfCheckInfo.cardADescription.map((each, index) => (
          <p
            className={`text-[15px] leading-[21px] text-[#4E4F53] flex ${
              index !== selfCheckInfo.cardADescription.length - 1 ? 'mb-[5px]' : ''
            }`}
            key={`card${index}`}
          >
            <span className="mr-[3px]">･</span>
            <span className="">{each}</span>
          </p>
        ))}
      </div>
      <SecondaryActionButton
        state="ACTIVE"
        text={`${selfCheckInfo.selfCheckName} 검사하기`}
        action={goSelfCheck}
        medium
      />
    </div>
  );
}
