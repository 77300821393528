import React, { useRef } from 'react';
import { AnxyActionButton } from '../button/ActionButton';
import { Title } from '../text/Title';
import AnxyNotch from '../../components/anxy/AnxyNotch';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import LinkButton from '../button/LinkButton';
import hexOpacity from 'hex-opacity';
import ContainerByDeviceAnxy from '../../components/elements/ContainerByDeviceAnxy';

export function TopTitleBottomActionAnxy(props) {
  const {
    title,
    subtitle,
    content,
    buttonText,
    buttonState,
    action,
    topColor,
    bgColor,
    noHeader,
    transparentHeader,
    buttonHide,
    noGradient,
    linkText,
    linkAction,
  } = props;
  const buttonRef = useRef();
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return noGradient ? (
    <div className="h-screen pt-safe" style={{ backgroundColor: bgColor || '#FFFFFF' }}>
      <AnxyNotch notchColor={bgColor || '#FFFFFF'} noHeader={noHeader} />
      <div className={`h-full pt-[44px] flex flex-col `}>
        <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col`}>
          <div className="pt-[12px] px-[20px] z-[50]" style={{ backgroundColor: topColor }}>
            <Title title={title} />
            {subtitle && <p className="mt-[8px] text-[17px] leading-[23px] text-[#6B6D76]">{subtitle}</p>}
          </div>
          <div className="flex-1">{content}</div>
        </div>
        <div
          className={`fixed bottom-0 w-full w-full relative  ${marginClassName} `}
          style={{
            background: `linear-gradient(to top, ${bgColor || '#ffffff'}, ${hexOpacity.create(
              bgColor || '#ffffff',
              0,
            )} `,
          }}
        >
          {!buttonHide && (
            <div className="px-[20px]">
              <AnxyActionButton
                state={buttonState}
                text={buttonText}
                action={action}
                bgColor={bgColor}
                noGradient
                red
              />
            </div>
          )}
          {linkText && (
            <div className="w-full flex justify-center">
              <LinkButton text={linkText} action={linkAction} noLine />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <ContainerByDeviceAnxy noHeader={noHeader} bgColor={bgColor} transparentHeader={transparentHeader}>
      <div
        className={`h-full flex flex-col `}
        style={{
          minHeight: `${window.innerHeight - 44}px`,
        }}
      >
        <div className="pt-[12px] px-[20px] z-[50]" style={{ backgroundColor: topColor }}>
          <Title title={title} />
          {subtitle && <p className="mt-[8px] text-[17px] leading-[23px] text-[#6B6D76]">{subtitle}</p>}
        </div>
        <div
          className={`flex-1 flex flex-col pb-[80px]`}
          style={{ paddingBottom: `${buttonRef.current && buttonRef.current.offsetHeight + 40}px` }}
        >
          {content}
        </div>

        <div className={`fixed bottom-0 w-full z-[50]`} ref={buttonRef}>
          <div
            className={`px-[20px]   ${marginClassName} `}
            style={{
              background: `linear-gradient(to top, ${bgColor || '#ffffff'}, ${hexOpacity.create(
                bgColor || '#ffffff',
                0,
              )} `,
            }}
          >
            {!buttonHide && (
              <AnxyActionButton state={buttonState} text={buttonText} action={action} bgColor={bgColor} />
            )}
            {linkText && (
              <div className="w-full flex justify-center">
                <LinkButton text={linkText} action={linkAction} noLine />
              </div>
            )}
          </div>
        </div>
      </div>
    </ContainerByDeviceAnxy>
  );
}
