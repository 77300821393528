import { motion, AnimatePresence } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import selfCheckData from '../../data/selfCheckData';
import { useGetWorkbookParams } from '../../hooks/useGetWorkbookParams';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { showWebView } from '../../utils/webview';
import GoalSwiperContentCoaching from './GoalSwiperContentCoaching';

export const ProgramNotStarted = ({ onboardingCase }) => {
  const { title, selfCheckResultList } = onboardingCase;
  const [recommendData, setRecommendData] = useState([]);
  const [eachData, setEachData] = useState();
  const [authToken, appId] = useGetWorkbookParams();
  const [hide, setHide] = useState(0);

  const goToOnboarding = useCallback(() => {
    showWebView({ url: `startOnboarding?at=${authToken}&app_id=${appId}&refresh=true` });
  }, [authToken, appId]);

  const getRecommendData = () => {
    setRecommendData(() => {
      const recommendList = [];
      selfCheckResultList.forEach((resultList) => {
        selfCheckData.forEach((checkData) => {
          if (resultList.selfCheckId === checkData.selfCheckId) {
            recommendList.push({
              selfCheckName: checkData.selfCheckName,
              score: resultList.score,
              isAbnormal: true,
              scorePercentage: Math.round((resultList.score / checkData.maxScore) * 100),
            });
          }
        });
      });
      return recommendList;
    });
  };

  useEffect(() => {
    console.log('hide is', hide);
  }, [hide]);

  const time = 2700;
  const rotation = () => {
    setTimeout(() => {
      setHide((prev) => {
        if (prev === 2) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, time);
  };

  useEffect(() => {
    getRecommendData();
  }, []);

  useEffect(() => {
    if (recommendData.length !== 0) {
      rotation();
      setInterval(() => {
        rotation();
      }, time);
    }
  }, [recommendData]);

  return (
    <div className="">
      <div className="w-full relative bg-white rounded-[20px] overflow-hidden">
        <div className="w-full h-full px-[20px] pt-[30px] pb-[20px] flex flex-col items-between">
          <p className=" w-full px-[4px] text-[#26282C] font-bold leading-[28px] text-[22px]  ">{title}</p>
          <div
            className="relative h-[193px] pl-[13.6px] pr-[14.4px] pt-[20px] px-[15px] pb-[13px] rounded-[15px] bg-[#F3F5F8] my-[25px] text-[#26282C] text-[15px] leading-[21px] font-bold"
            style={{
              border: '0.8px solid rgba(0,0,0,0.1)',
              boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
            }}
          >
            {recommendData.length !== 0 && (
              <motion.div
                className={`opacity-0 h-full text-[15px] leading-[21px] flex flex-col justify-between transition-all`}
                animate={{ opacity: hide === 0 ? 1 : 0 }}
                transition={{ duration: 0.7 }}
              >
                <GoalSwiperContentCoaching step={recommendData[0]} recommendData={recommendData} />
              </motion.div>
            )}
            {recommendData.length !== 0 && (
              <motion.div
                className={`opacity-0  h-full text-[15px] leading-[21px] flex flex-col justify-between transition-all`}
                animate={{ opacity: hide === 1 ? 1 : 0 }}
                transition={{ duration: 0.7 }}
              >
                <GoalSwiperContentCoaching step={recommendData[1]} recommendData={recommendData} />
              </motion.div>
            )}
            {recommendData.length !== 0 && (
              <motion.div
                className={`opacity-0 h-full text-[15px] leading-[21px] flex flex-col justify-between transition-all`}
                animate={{ opacity: hide === 2 ? 1 : 0 }}
                transition={{ duration: 0.7 }}
              >
                <GoalSwiperContentCoaching step={recommendData[2]} recommendData={recommendData} />
              </motion.div>
            )}
          </div>
          <div>
            <PrimaryActionButton state="ACTIVE" text={'시작하기'} action={goToOnboarding} medium />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramNotStarted;
