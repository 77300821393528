import { useEffect, useRef } from 'react';

export default function usePrevious(value) {
  const previous = useRef();

  useEffect(() => {
    // console.log('usePrevious', value);
    previous.current = value;
  }, [value]);

  return previous.current;
}
