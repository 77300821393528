const TextWithDot = ({ texts, bold, margin }) => {
  return (
    <div className={`${margin ? margin : 'my-[30px]'} text-[17px] leading-[22px] text-black`}>
      {texts.map((text, index) => (
        <p className={`ml-[8px] ${index !== texts.length - 1 && 'mb-[6px]'} ${bold ? 'font-bold' : 'font-normal'}`}>
          <div className="flex items-start">
            <span className="mr-[8px]">•</span>
            <span>{text}</span>
          </div>
        </p>
      ))}
    </div>
  );
};

export default TextWithDot;
