import React from 'react';
import { useRecoilValue } from 'recoil';
import progressBarAtom from '../../recoil/common/progressBar/atom';
import { ProgressingBar } from '../../stories/else/ProgressingBar';
import deviceAtom from '../../recoil/common/device';
import anxyColorPalette from '../../data/anxy/contents/anxyColorPalette';

export default function AnxyProgressingBar() {
  const progressBarState = useRecoilValue(progressBarAtom);
  const deviceState = useRecoilValue(deviceAtom);
  return (
    <div
      className={`bg-white  ${
        progressBarState.isProgressBarVisible ? 'w-full  px-[20px] pt-safe visible' : 'h-0 invisible'
      }`}
      style={{ top: deviceState.notchSize, backgroundColor: progressBarState.paddingColor }}
    >
      <div className="pt-[56px]  z-[2000]">
        <ProgressingBar
          maxCompleted={progressBarState.maxCompleted}
          completed={progressBarState.completed}
          color={progressBarState.color ? progressBarState.color : anxyColorPalette.orange}
          baseColor={progressBarState.baseColor ? progressBarState.baseColor : '#F3F5F8'}
        />
      </div>
    </div>
  );
}
