import React, { useState, useEffect, useRef } from 'react';

export default function TestResultChartLabel(props) {
  const { width, isCurrent, isAbnormal, score, ratio, label, isSmall, normalColor } = props;

  const [pointerWidth, setPointerWidth] = useState(0);
  const pointerRef = useRef();
  useEffect(() => {
    if (pointerRef.current) {
      setPointerWidth(pointerRef.current.offsetWidth);
    }
  }, [isCurrent, label, ratio]);

  return (
    <div
      className="mx-[2.5px] "
      style={{
        width: width,
      }}
    >
      {isCurrent && (
        <div className="w-[90%] mx-auto relative">
          <div
            className={` absolute w-full ${isSmall ? 'bottom-[9.03px]' : 'top-[-36px]'}`}
            style={{ left: `${ratio}%`, transform: `translateX(-${isSmall ? 7 / 2 : pointerWidth / 2}px)` }}
          >
            {isSmall ? (
              <div className=" absolute top-[0px] flex flex-col  w-fit left-[-50%] translate-x-[1px] " ref={pointerRef}>
                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                  <path
                    d="M3.92595 5.23075C3.64779 5.659 3.02087 5.659 2.74271 5.23075L0.376454 1.58766C0.071621 1.11834 0.408441 0.497932 0.968071 0.497932L5.70058 0.497931C6.26021 0.497931 6.59704 1.11834 6.2922 1.58766L3.92595 5.23075Z"
                    fill="#26282C"
                  />
                </svg>
              </div>
            ) : (
              <div className="flex flex-col items-center w-fit" ref={pointerRef}>
                <p className="text-[15px leading-[21px] text-[#3A3C40] font-bold mb-[2px]">{score}</p>
                <div>
                  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.64248 6.70885C5.24818 7.31591 4.35952 7.31591 3.96522 6.70885L0.61101 1.5447C0.178904 0.879433 0.656353 8.16847e-07 1.44964 7.47496e-07L8.15806 1.61028e-07C8.95134 9.16765e-08 9.42879 0.879434 8.99668 1.54471L5.64248 6.70885Z"
                      fill="#26282C"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className={`${isSmall ? 'h-[3.53px]' : 'h-[5px]'}  rounded-[20px] mb-[7px]`}
        style={{ backgroundColor: isCurrent ? (isAbnormal ? '#EF3E3E' : normalColor || '#2C4BEC') : '#E1E4EB' }}
      />
      <div
        className={`${isCurrent ? 'font-bold' : 'font-normal'} `}
        style={{
          whiteSpace: 'noWrap',
          color: isCurrent ? (isAbnormal ? '#EF3E3E' : normalColor || '#2C4BEC') : '',
        }}
      >
        {label}
      </div>
    </div>
  );
}
