import { motion } from 'framer-motion';
import { useContext } from 'react';
import toolData from '../../data/toolData';
import { PressedEffect } from '../../stories/button/PressedEffect';
import UnlockLottie from './UnlockLottie';
import { goToScreen, showWebView } from '../../utils/webview';
import { CoachingContext } from '../../coachingContext';

const ToolCard = ({ data, isStarted }) => {
  const { toolId, isLock, webToAppEvent, isFirstUnlocked, legendText, metricText } = data;
  const context = useContext(CoachingContext);
  const toolResource = toolData.find((resource) => resource.toolId === data.toolId);

  const goLink = () => {
    if (!isLock) {
      switch (webToAppEvent.eventName) {
        case 'goEmotionalRecordStart': {
          console.log('emo');
          goToScreen({
            screenName: 'emotionalRecord',
          });
          break;
        }

        case 'goRetrospectNote': {
          goToScreen({
            screenName: 'retrospectNote',
          });
          break;
        }
        case 'showWebView': {
          const message = JSON.parse(webToAppEvent.body);
          showWebView(message);
          break;
        }
        case 'goRuleCard': {
          goToScreen({
            screenName: 'ruleCard',
          });
          break;
        }
        default: {
          console.log('error');
          break;
        }
      }
      // }
    } else {
      if (isStarted) {
        if (toolId === 'tool-2' || toolId === 'tool-8' || toolId === 'tool-9' || toolId === 'tool-10') {
          context.setModalType('NOT_ACHIEVE');
          if (toolId === 'tool-10') {
            context.setCourseId(4);
          } else if (toolId === 'tool-9') {
            context.setCourseId(2);
          } else {
            context.setCourseId(0);
          }
        } else {
          context.setModalType('COMING_SOON');
        }
      } else {
        context.setModalType('NOT_STARTED');
      }
      context.setShowModal(true);
    }
  };
  return (
    <PressedEffect
      element={
        <div className="w-full h-[157px]">
          <div
            className={`rounded-[20px] w-full p-[20px] relative h-[157px]`}
            style={{
              backgroundColor: toolResource.backgroundColor,
            }}
          >
            <div className={`${!isLock && 'justify-between'} flex items-start relative`}>
              <div className="flex items-center">
                <p className="font-bold" style={{ whiteSpace: 'nowrap' }}>
                  {toolResource.title}
                </p>

                {(isLock || isFirstUnlocked) && (
                  <div className="relative w-[11.86px] h-[15px]">
                    {isLock && !isFirstUnlocked && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="15"
                        viewBox="0 0 12 15"
                        fill="none"
                        className="ml-[6px]"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.38999 6.61019H8.47473V4.23728C8.47473 2.83317 7.33647 1.69491 5.93236 1.69491C4.52825 1.69491 3.38999 2.83317 3.38999 4.23728V6.61019ZM1.69508 6.65743C1.45481 6.68883 1.26287 6.74112 1.09202 6.82817C0.715695 7.01992 0.409734 7.32588 0.217987 7.70221C0 8.13003 0 8.69008 0 9.81019V11.8C0 12.9201 0 13.4802 0.217987 13.908C0.409734 14.2843 0.715695 14.5903 1.09202 14.782C1.51984 15 2.0799 15 3.2 15H8.66438C9.78449 15 10.3445 15 10.7724 14.782C11.1487 14.5903 11.4546 14.2843 11.6464 13.908C11.8644 13.4802 11.8644 12.9201 11.8644 11.8V9.81019C11.8644 8.69008 11.8644 8.13003 11.6464 7.70221C11.4546 7.32588 11.1487 7.01992 10.7724 6.82817C10.6016 6.74116 10.4098 6.68888 10.1696 6.65747V4.23728C10.1696 1.8971 8.27254 0 5.93236 0C3.59217 0 1.69508 1.8971 1.69508 4.23728V6.65743Z"
                          fill="black"
                        />
                      </svg>
                    )}
                    {isFirstUnlocked && (
                      <motion.div
                        className="absolute top-[-3.8px] left-[0px]"
                        animate={{ opacity: 0 }}
                        transition={{ duration: 0.2, delay: 1.2 }}
                      >
                        <UnlockLottie />
                      </motion.div>
                    )}
                  </div>
                )}
              </div>

              {!isLock && (
                <motion.div
                  className={`relative top-[-4px] w-[30px] h-[30px] ${isFirstUnlocked ? 'opacity-0' : 'opacity-1'}`}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: 1.4 }}
                >
                  <img src={toolResource.image} alt="empty" className="object-fit " />
                </motion.div>
              )}
            </div>
            {isLock ? (
              <div className="w-[50px] h-[50px] mt-[45px]">
                <img src={toolResource.image} alt="empty" className="object-fit " />
              </div>
            ) : (
              <motion.div className={`relative mt-[37px]`}>
                {isFirstUnlocked && (
                  <motion.div
                    className="w-[50px] h-[50px] absolute opacity-1"
                    animate={{ opacity: 0 }}
                    transition={{ duration: 0.2, delay: 1.4 }}
                  >
                    <img src={toolResource.image} alt="empty" className="object-fit " />
                  </motion.div>
                )}
                <motion.div
                  className={`${isFirstUnlocked ? 'opacity-0' : 'opacity-1'}`}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: 1.4 }}
                >
                  <p className="text-[#4E4F53] text-[14px] leading-[20px]">{legendText}</p>
                  <p className="mt-[2px] text-[22px] leading-[28px] font-bold">{metricText}</p>
                </motion.div>
              </motion.div>
            )}
            {/* <div className="flex justify-between items-end">
              {toolId === 'tool-10' && (
                <div className="mt-[37px]" style={{ visibility: isLock ? 'hidden' : 'visible' }}>
                  <p className="text-[#4E4F53] text-[14px] leading-[20px]">{legendText}</p>
                  <p className="mt-[2px] text-[22px] leading-[28px] font-bold">{metricText}</p>
                </div>
              )}
              {isLock || toolId === 'tool-10' ? (
                <div className="w-[50px] h-[50px] mt-[45px]">
                  <img src={toolResource.image} alt="empty" className="object-fit " />
                </div>
              ) : (
                <div className="mt-[37px]">
                  <p className="text-[#4E4F53] text-[14px] leading-[20px]">{legendText}</p>
                  <p className="mt-[2px] text-[22px] leading-[28px] font-bold">{metricText}</p>
                </div>
              )}
            </div> */}
          </div>
        </div>
      }
      action={goLink}
    />
  );
};

export default ToolCard;
