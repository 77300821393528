import React from 'react';
import { motion } from 'framer-motion';
import { PressedEffect } from '../../stories/button/PressedEffect';
import { PrimaryActionButton, SecondaryActionButton } from '../../stories/button/ActionButton';
import webviewToast from '../../development/webviewToast';
import moment from 'moment';
import { goToScreen } from '../../utils/webview';
import useFetch from '../../hooks/useFetch';
import { useState } from 'react';
import { useEffect } from 'react';

export default function CounselingCard({ data, allData, index, isCounselor, getRefreshAction }) {
  const [counselingIndex, setCounselingIndex] = useState(index);
  const [confirmReservation, getConfirmReservation] = useFetch({
    url: `/counseling/appointment/for-counselor/confirm?appointment_id=${data.appointmentId}`,
    bg: '#F3F5F8',
  });

  useEffect(() => {
    if (confirmReservation) {
      getRefreshAction();
    }
  }, [confirmReservation]);

  const statusType = [
    {
      id: 0,
      name: '예약 대기',
      color: '#6B6D76',
    },
    {
      id: 1,
      name: '예약 확정',
      color: '#2C4BEC',
    },
    {
      id: 2,
      name: '상담 완료',
      color: '#6B6D76',
    },
    {
      id: 3,
      name: '예약 취소',
      color: '#EF3E3E',
    },
  ];

  const goHistory = () => {
    webviewToast('history Id:' + data.historyId);
    goToScreen({
      screenName: 'counselingDetail',
      historyId: data.historyId,
    });
  };

  const goCounselingRoom = () => {
    webviewToast('room Id:' + data.roomId);
    goToScreen({
      screenName: 'counselingRoom',
      roomId: data.roomId,
    });
  };

  const goReview = () => {
    webviewToast('room Id:' + data.roomId);
    goToScreen({
      screenName: 'counselingReview',
      roomId: data.roomId,
    });
  };

  const goConfirmReservation = () => {
    webviewToast('확정' + data.appointmentId);
    getConfirmReservation();
  };

  useEffect(() => {
    const prevCancleLength = allData
      .slice(-1 * index)
      .filter((item) => item.counselingStatus && item.counselingStatus.id === 3).length;
    const newIndex = data.counselingStatus.id === 3 ? index - prevCancleLength + 1 : index - prevCancleLength;
    setCounselingIndex(newIndex);
  }, [index]);

  const isCancled = data.counselingStatus && data.counselingStatus.id === 3;
  const isToday = data.decimalDay === 0;
  const isAm = moment(data.counselingStartDateTime).format('H') > 11 ? '오후' : '오전';

  const isLeave = data.isLeaveUser || (data.userNickname && data.userNickname.includes('탈퇴'));

  return (
    <PressedEffect
      element={
        <div className={`w-full bg-[#FFFFFF] rounded-[16px] justify-center p-[20px] flex-col`}>
          <div className="w-full flex items-center">
            <div
              className="w-[31.2px] shrink-0 font-bold text-[20px] leading-[24px] text-[#26282C] text-center"
              style={{ opacity: isCancled ? 0.3 : 1 }}
            >
              {counselingIndex}
            </div>
            <div className="w-full pl-[15px]">
              <div className="w-full flex justify-between items-center">
                <p
                  className="text-[17px] leading-[23px] font-bold"
                  style={{
                    textDecoration: isCancled ? 'line-through' : '',
                    textDecorationThickness: '1px',
                  }}
                >
                  {moment(data.counselingStartDateTime).format(`M월 D일 ${isAm} h시`)}
                </p>
                {typeof data.decimalDay !== 'undefined' && data.decimalDay && (
                  <div
                    className={`${
                      isToday ? 'bg-[#2C4BEC]' : 'bg-[#6B6D76]'
                    } h-[20px] px-[8px] text-[12px] leading-[18px] flex justify-center items-center rounded-[7px] text-white`}
                    style={{ fontWeight: 400 }}
                  >
                    <p className="mb-[1px]">{`${isToday ? 'Today' : `D-${data.decimalDay}`}`}</p>
                  </div>
                )}
              </div>
              {data.counselingStatus && (
                <p
                  className="text-[#6B6D76] text-[14px] leading-[20px] mt-[4px]"
                  style={{
                    color: statusType.find((item) => item.id === data.counselingStatus.id).color,
                    fontWeight: 400,
                  }}
                >
                  {data.counselingStatus.name}
                </p>
              )}
            </div>
          </div>
          {(data.isRoomCreated || data.isReviewNeeded || data.confirmReservation) && (
            <div className="mt-[15px]">
              {data.isRoomCreated && (
                <PrimaryActionButton state="ACTIVE" text={'상담실 들어가기'} medium action={goCounselingRoom} />
              )}
              {data.isReviewNeeded && (
                <SecondaryActionButton state="ACTIVE" text={'후기 남기기'} medium action={goReview} />
              )}
            </div>
          )}
          {isCounselor && data.counselingStatus.id === 0 && (
            <div className="mt-[15px]">
              <PrimaryActionButton
                state={`${isLeave ? 'INACTIVE' : 'ACTIVE'}`}
                text={'예약 확정하기'}
                medium
                action={goConfirmReservation}
              />
            </div>
          )}
        </div>
      }
      action={goHistory}
      disable={isLeave}
    />
  );
}
