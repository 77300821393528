import React, { useEffect, useState, useRef } from 'react';
import { SecondaryActionButton } from '../../stories/button/ActionButton';
import useShowWebviewWithParams from '../../hooks/useShowWebviewWithParams';
import { scrollEvent } from '../../utils/webview';
import useDetectIOS from '../../hooks/useDetectIOS';

function UserProfile({ user, selected, select }) {
  return (
    <div className="w-[65px] flex flex-col gap-[10px] items-center" onClick={select}>
      <div className="w-[65px] h-[65px] rounded-[50%] relative ">
        {user.userProfileImageUrl ? (
          <div className="w-[65px] h-[65px] rounded-[100%] overflow-hidden relative">
            <img alt="empty" className="w-full h-full object-cover" src={user.userProfileImageUrl} />
            <div
              className={`absolute top-0 w-full h-full rounded-[50%] 
            ${selected ? 'border-[3px] border-[#2C4BEC]' : 'border-[1px] border-[rgba(0,0,0,0.05)]'}`}
            />
          </div>
        ) : (
          <div
            className="bg-[#FAFBFD] w-[65px] h-[65px] rounded-[100%] flex justify-center items-center"
            style={{ border: selected ? '3px solid #2C4BEC' : '1px inset rgba(0, 0, 0, 0.05)' }}
          >
            <p className="font-medium text-[30px] leading-[36px] text-[#D6D8DC] mt-[1px]">
              {user.userNickname.slice(0, 1)}
            </p>
          </div>
        )}
        {user.notify && (
          <div className="absolute top-0 right-0  w-[23px] h-[23px] bg-[#EF3E3E] rounded-[50%] border-[3px] border-[#EFF3F8]"></div>
        )}
      </div>
      <div className={`py-[3px] px-[12px] ${selected ? 'bg-[#2C4BEC] text-white ' : 'text-[#3A3C40]'} rounded-[30px] `}>
        <p className="text-[14px] whitespace-nowrap max-w-[70px] overflow-hidden text-ellipsis ">{user.userNickname}</p>
      </div>
    </div>
  );
}

function createScrollStopListener(scrollRef, callback, timeout) {
  var handle = null;
  var onScroll = function () {
    scrollEvent(1);
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(callback, timeout || 200); // default 200 ms
  };
  scrollRef.current.addEventListener('scroll', onScroll);
  return function () {
    scrollRef.current.removeEventListener('scroll', onScroll);
  };
}

export default function UserProfileList({ userList, getSelectedUser, getXScrollStop }) {
  const scrollRef = useRef();
  const [selectedUserInfo, setSelectedUserInfo] = useState({
    userId: userList[0].userId,
    userNickname: userList[0].userNickname,
    isLeaveUser: userList[0].isLeaveUser,
  });

  const [isIOS] = useDetectIOS(false);

  // useEffect(() => {
  //   createScrollStopListener(
  //     scrollRef,
  //     function () {
  //       console.log('onscrollstop');
  //       getXScrollStop();
  //     },
  //     200,
  //   );
  // }, []);

  const action = () => {
    if (!userList.find((item) => item.userId === selectedUserInfo.userId).isLeaveUser) {
      goUserDetail();
    }
  };

  const goUserDetail = useShowWebviewWithParams(`userInfo?user_id=${selectedUserInfo.userId}`, '#FFFFFF');
  return (
    <div>
      <div
        className="w-screen overflow-x-scroll scrollbar-hide"
        ref={scrollRef}
        onTouchStart={() => {
          if (!isIOS) {
            scrollEvent(1);
          }
        }}
        onTouchEnd={() => {
          if (!isIOS) {
            getXScrollStop();
          }
        }}
      >
        <div className="flex w-fit mx-auto pl-[30px]">
          {userList.map((user, index) => (
            <div key={`user${index}`} className="pr-[30px]">
              <UserProfile
                user={user}
                selected={selectedUserInfo.userId === user.userId}
                select={(e) => {
                  setSelectedUserInfo({
                    userId: user.userId,
                    userNickname: user.userNickname,
                    isLeaveUser: user.isLeaveUser,
                  });
                  getSelectedUser(user.userId, user.isLeaveUser || user.userNickname.includes('탈퇴'));
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-[30px] mb-[10px] px-[20px]">
        <SecondaryActionButton
          state={`${
            selectedUserInfo.isLeaveUser || selectedUserInfo.userNickname.includes('탈퇴') ? 'INACTIVE' : 'ACTIVE'
          }`}
          text={`${selectedUserInfo.userNickname}님 알아보기`}
          action={action}
        />
      </div>
    </div>
  );
}
