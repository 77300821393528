/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import { BottomActionAnxy } from 'stories/page/BottomActionAnxy';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_NONE } from 'recoil/common/header/atom';
import congrats_particle from 'image/congrats_particle.json';
import Lottie from 'components/elements/Lottie';
import { useParams } from 'react-router';
import { anxyToolData } from 'data/anxy/home/anxyToolData';
import homeAtom from 'recoil/anxy/home/atom';
import { CheckLottie } from 'stories/animation/CheckLottie';
import anxySessionData from 'data/anxy/anxySessionData';
import { impactFeedback } from 'utils/webview';

export default function GainTool(props) {
  const { id } = useParams();

  const toolData =
    anxyToolData.find((element) => element.sectionId.includes(id)) ||
    anxySessionData.find((element) => element.sectionId === id);

  const isWorryNote = id.includes('worrynote_upgrade');
  const isBreath = id.includes('breath');

  const goHome = useNavigateWithParams(`/anxyHome`);
  const goworryNote = useNavigateWithParams(`/worryNote`, true);
  const goBreathTool = useNavigateWithParams(`/breath/0`, true);

  const setHeaderState = useSetRecoilState(headerAtom);
  const setHomeType = useSetRecoilState(homeAtom);

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE, headerTitle: '' }));
    impactFeedback('success');
  }, []);

  return (
    <BottomActionAnxy
      content={
        <div className="w-full h-full flex flex-col gap-[30px] justify-center items-center px-[20px] text-[#26282C] relative">
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[50] w-full pointer-events-none">
            <Lottie lottieData={congrats_particle} autoplay />
          </div>
          {toolData.image ? (
            <div className="w-[80px] h-[80px] rounded-[50%] bg-[#E1E4EB] flex items-center justify-center">
              {toolData.image}
            </div>
          ) : (
            <CheckLottie green containerSize="72px" autoplay />
          )}
          <div className="text-[24px] font-bold ">
            {id.includes('upgrade') ? `${toolData.title}을 업그레이드했어요` : `${toolData.title}를 획득했어요`}
          </div>
        </div>
      }
      noHeader
      noGradient
      buttonText={isWorryNote ? '걱정기록하러 가기' : '확인'}
      action={() => {
        setHomeType('anxy');
        if (isWorryNote) {
          goworryNote();
        } else if (isBreath) {
          goBreathTool();
        } else {
          goHome();
        }
      }}
      bgColor={'#F3F5F8'}
    />
  );
}
