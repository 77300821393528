export const checkListData = [
  {
    title: `In the morning \n and during the day`,
    checkList: [
      { id: 'wua', text: 'Wake up at the same time today as yesterday', isDone: false, icon: '🕚' },
      { id: 'dtan', text: 'Don’t take a nap', isDone: false, icon: '😴' },
      { id: 'bcf', text: 'Be caffeine free after lunch', isDone: false, icon: '☕️' },
      { id: 'gal15', text: 'Get at least 15 minutes of exercise', isDone: false, icon: '🏃' },
      { id: 'gal20', text: 'Get at least 20 minutes of sunlight outside', isDone: false, icon: '🌞' },
    ],
  },
  {
    title: `6 Hours before Sleep`,
    checkList: [
      { id: 'aal', text: 'Avoid a large dinner', isDone: false, icon: '🍔' },
      { id: 'aaa', text: 'Avoid alcohol and cigarettes', isDone: false, icon: '🍺' },
      { id: 'tut', text: 'Tidy up the bedroom', isDone: false, icon: '🛏️' },
    ],
  },
  {
    title: `2 Hours before Sleep`,
    checkList: [
      { id: 'dli', text: 'Dim lights in the house', isDone: false, icon: '🛋' },
      { id: 'auy', text: 'Avoid using your phone or laptop', isDone: false, icon: '📱' },
      { id: 'afa', text: 'Avoid food and sugar', isDone: false, icon: '🧁' },
      { id: 'ddw', text: 'Don’t drink water or fluids', isDone: false, icon: '🥛' },
      { id: 'sra', text: 'Set room at a comfortable, cool temperature near 65F or 18C', isDone: false, icon: '🌡️' },
    ],
  },
  {
    title: `Right before \n and during Sleep`,

    checkList: [
      { id: 'mra', text: 'Make room as dark and quiet as possible', isDone: false, icon: '🤫' },
      { id: 'rab', text: 'Read a book or meditate to relax', isDone: false, icon: '😌' },
      { id: 'dgt', text: 'Don’t go to bed unless sleepy', isDone: false, icon: '🥱' },
      { id: 'sat', text: 'Sleep at the same time today as yesterday', isDone: false, icon: '⏰' },
      {
        id: 'iyd',
        text: 'If you don’t fall asleep after 20 minutes, get out of bed and read',
        isDone: false,
        icon: '📖',
      },
    ],
  },
];
