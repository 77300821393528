import { atom } from 'recoil';

export const MODAL_LOGOUT = 'LOGOUT';
export const MODAL_LEAVE = 'LEAVE';
export const MODAL_VIEWED = 'VIEWED';
export const MODAL_NOTIFICATION = 'ALLOW_NOTIFICATION';

const modalAtom = atom({
  key: 'somny_modal',
  default: {
    isModalVisible: false,
    modalType: '',
  },
});

export default modalAtom;
