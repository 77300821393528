import tool_bb from '../../../image/anxy/tool/tool_bb.png';
import tool_aa from '../../../image/anxy/tool/tool_aa.png';
import tool_ca from '../../../image/anxy/tool/tool_ca.png';
import tool_bk from '../../../image/anxy/tool/tool_bk.png';
import tool_dd from '../../../image/anxy/tool/tool_dd.png';
import tool_ss from '../../../image/anxy/tool/tool_ss.png';
import anxyColorPalette from '../contents/anxyColorPalette';

import tool_bb_thumbnail from '../../../image/anxy/tool/tool_bb_thumbnail.png';
import tool_aa_thumbnail from '../../../image/anxy/tool/tool_aa_thumbnail.png';
import tool_bk_thumbnail from '../../../image/anxy/tool/tool_bk_thumbnail.png';
import tool_dd_thumbnail from '../../../image/anxy/tool/tool_dd_thumbnail.png';
import tool_ss_thumbnail from '../../../image/anxy/tool/tool_ss_thumbnail.png';

export const anxyToolData = [
  {
    toolId: 'tool_bb',
    sectionId: 'box_breath_aa',
    url: 'breath',
    title: '심호흡하기',
    text: '숨을 깊게 들이마시고 내쉽니다. 가장 빠르게 안정감을 되찾을 수 있는 방법입니다. 활성화된 교감신경을 잠재우며 흥분을 조절하고 감정을 변화시켜요.',
    textA: [`불안은 꼭 나쁘기만 한 걸까요?`, ' 세션을 듣고 획득할 수 있어요.'],
    hideButton: true,
    imageMarginBottom: 5,
    bgColor: '#4A4D53',
    thumbnailImage: tool_bb_thumbnail,
    image: (
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M30.2321 43H32C34.2091 43 36 41.2091 36 39V39C36 36.7909 34.2091 35 32 35H17"
          stroke="#757575"
          strokeWidth="3"
          strokelinecap="round"
        />
        <path
          d="M13 30H42.5C44.9853 30 47 27.9853 47 25.5V24.8839C47 22.7389 45.2611 21 43.1161 21V21C40.971 21 39.2321 22.7389 39.2321 24.8839V25"
          stroke="#757575"
          strokeWidth="3"
          strokelinecap="round"
        />
        <path
          d="M17 25H31.5C33.9853 25 36 22.9853 36 20.5V19.8839C36 17.7389 34.2611 16 32.1161 16V16C29.971 16 28.2321 17.7389 28.2321 19.8839V20"
          stroke="#757575"
          strokeWidth="3"
          strokelinecap="round"
        />
      </svg>
    ),
    toolImage: tool_bb,
  },
  {
    toolId: 'tool_aa',
    sectionId: 'box_meditate_aa',
    exerciseIdPrefix: 'exercise_meditate',
    url: 'meditate',
    title: '명상하기',
    text: '앞으로 벌어질 미래가 아닌 현재에 집중합니다. 내가 지금 느끼는 감각에 집중하며, 떠오르는 걱정거리들은 모두 흘려보내요.',
    textA: [`내 마음, 지금 여기로 가져와요`, ' 세션을 듣고 획득할 수 있어요.'],
    hideButton: true,
    imageMarginBottom: 5,
    bgColor: '#4A4D53',
    thumbnailImage: tool_aa_thumbnail,
    image: (
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M30.2321 43H32C34.2091 43 36 41.2091 36 39V39C36 36.7909 34.2091 35 32 35H17"
          stroke="#757575"
          strokeWidth="3"
          strokelinecap="round"
        />
        <path
          d="M13 30H42.5C44.9853 30 47 27.9853 47 25.5V24.8839C47 22.7389 45.2611 21 43.1161 21V21C40.971 21 39.2321 22.7389 39.2321 24.8839V25"
          stroke="#757575"
          strokeWidth="3"
          strokelinecap="round"
        />
        <path
          d="M17 25H31.5C33.9853 25 36 22.9853 36 20.5V19.8839C36 17.7389 34.2611 16 32.1161 16V16C29.971 16 28.2321 17.7389 28.2321 19.8839V20"
          stroke="#757575"
          strokeWidth="3"
          strokelinecap="round"
        />
      </svg>
    ),
    toolImage: tool_aa,
  },
  {
    toolId: 'tool_ca',
    sectionId: 'box_record_aa',
    exerciseIdPrefix: 'exercise_record',
    title: '불안 기록하기',
    text: '불안을 느낄 때마다 기록할 수 있는 도구입니다. 기록이 쌓이면 내가 어떤 이유로, 어떻게 반복적으로 불안을 느끼는지 패턴을 알아볼 수 있어요.',
    hideButton: true,
    imageMarginBottom: 9,
    image: (
      <svg width="49" height="44" viewBox="0 0 49 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.892578" y="0.439941" width="35.84" height="43.52" rx="2.88" fill="white" />
        <mask
          id="mask0_2472_58712"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="37"
          height="44"
        >
          <rect x="0.892578" y="0.439941" width="35.84" height="43.52" rx="2.88" fill="white" />
        </mask>
        <g mask="url(#mask0_2472_58712)">
          <rect x="6.01367" y="8.12109" width="25.6" height="2.56" rx="1.28" fill="black" fillOpacity="0.12" />
          <rect x="6.0127" y="14.5203" width="25.6" height="2.56" rx="1.28" fill="black" fillOpacity="0.12" />
          <rect x="6.01367" y="20.9209" width="20.48" height="2.56" rx="1.28" fill="black" fillOpacity="0.12" />
          <rect x="6.0127" y="27.3213" width="14.08" height="2.56" rx="1.28" fill="black" fillOpacity="0.12" />
          <path
            d="M29.4121 72.5316L20.532 29.8569C20.4153 29.2961 21.0997 28.9273 21.5039 29.3329L47.3321 55.2516V72.5316H29.4121Z"
            fill="url(#paint0_linear_2472_58712)"
          />
        </g>
        <path
          d="M24.9587 26.908L46.3438 5.52287C47.2796 4.58711 47.2796 3.06995 46.3438 2.13419C45.4081 1.19843 43.8909 1.19843 42.9551 2.13419L21.57 23.5193L19.7179 27.6644C19.4074 28.3594 20.1187 29.0706 20.8136 28.7601L24.9587 26.908Z"
          fill="#348E46"
        />
        <mask
          id="mask1_2472_58712"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="19"
          y="1"
          width="29"
          height="28"
        >
          <path
            d="M24.9587 26.908L46.3438 5.52287C47.2796 4.58711 47.2796 3.06995 46.3438 2.13419C45.4081 1.19843 43.8909 1.19843 42.9551 2.13419L21.57 23.5193L19.7179 27.6644C19.4074 28.3594 20.1187 29.0706 20.8136 28.7601L24.9587 26.908Z"
            fill="#DD3232"
          />
        </mask>
        <g mask="url(#mask1_2472_58712)">
          <path
            d="M25.1779 27.1516L46.4814 5.84822C47.0226 5.30703 47.0226 4.42959 46.4814 3.8884C45.9402 3.34721 45.0627 3.34721 44.5216 3.8884L23.2181 25.1918L21.4907 27.3596C20.9258 28.0685 21.7449 29.0401 22.5391 28.6032L25.1779 27.1516Z"
            fill="#166926"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2472_58712"
            x1="28.4321"
            y1="27.9619"
            x2="28.4321"
            y2="58.8355"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.5" />
            <stop offset="1" stopColor="#4D4E5C" stopOpacity="0" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    toolImage: tool_ca,
  },
  {
    toolId: 'tool_bk',
    sectionId: 'box_relax_aa',
    exerciseIdPrefix: 'exercise_relax',
    url: 'breath',
    title: '온몸 이완하기',
    text: '신체 구석구석을 긴장시켰다가 이완하는 도구입니다. 빠르게 불안감을 낮출 수 있는 가장 효과적인 불안 관리 도구 중 하나예요.',
    textA: ['어떻게 불안한 몸을 달랠 수 있을까요?', ' 세션을 듣고 획득할 수 있어요.'],
    imageMarginBottom: 10,
    bgColor: anxyColorPalette.yellow,
    image: (
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22.26" cy="22.26" r="22.26" fill="#FAFBFD" />
        <path
          d="M13.75 24.1211C14.2624 25.1626 15.0545 25.8307 15.9435 25.8307C16.8325 25.8307 17.6246 25.1626 18.137 24.1211"
          stroke="#181818"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M25.459 24.1211C25.9714 25.1626 26.7635 25.8307 27.6525 25.8307C28.5415 25.8307 29.3336 25.1626 29.846 24.1211"
          stroke="#181818"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M23.9834 31.8C23.471 32.8415 22.6789 33.5096 21.7899 33.5096C20.9009 33.5096 20.1088 32.8415 19.5964 31.8"
          stroke="#803F3F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M22.1559 17.7217L23.75 26.3906C23.7646 26.4701 23.7539 26.552 23.7194 26.625L22.1254 29.9971C21.9863 30.2913 21.5676 30.2914 21.4285 29.9971L19.8344 26.625C19.7999 26.552 19.7892 26.4701 19.8038 26.3906L21.3979 17.7217C21.4753 17.3008 22.0785 17.3008 22.1559 17.7217Z"
          fill="#CB6932"
        />
        <path
          d="M13.8623 17.3179C15.7933 15.9338 18.8158 15.1509 21.7293 15.087C24.7374 15.0211 27.6371 15.7215 29.5962 17.3179"
          stroke="#181818"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    ),
    thumbnailImage: tool_bk_thumbnail,
    toolImage: tool_bk,
  },
  {
    toolId: 'tool_dd',
    sectionId: 'box_trap_aa',
    exerciseIdPrefix: 'exercise_trap',
    title: '생각함정 찾기',
    url: 'trap',
    text: '나도 모르게 하는 불합리한 사고방식을 발견합니다. 반복해서 훈련해 경지에 이르면 불안한 생각의 태생부터 차단할 수 있게 돼요.',
    textA: ['뭔가 일어날 것처럼 과하게 여기는 생각', ' 세션을 듣고 획득할 수 있어요.'],
    isTailBottom: true,
    imageMarginBottom: 13,
    bgColor: anxyColorPalette.green,
    image: (
      <svg width="47" height="42" viewBox="0 0 47 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 9L10.0001 9.03668C4.39355 9.45002 0 13.3052 0 18C0 22.9706 4.92487 27 11 27C11.6596 27 12.3056 26.9525 12.9332 26.8615C16.0131 29.4173 20.2821 31 25 31C26.2454 31 27.4595 30.8897 28.6287 30.6804C30.5793 32.1221 33.1643 33 36 33C42.0751 33 47 28.9706 47 24C47 21.4754 45.7295 19.1936 43.6831 17.5592C44.5249 16.1921 45 14.6423 45 13C45 7.47715 39.6274 3 33 3C31.8246 3 30.6886 3.14084 29.6151 3.40337C27.5999 1.32958 24.4897 0 21 0C14.9249 0 10 4.02944 10 9ZM14.5 38C16.9853 38 19 36.433 19 34.5C19 32.567 16.9853 31 14.5 31C12.0147 31 10 32.567 10 34.5C10 36.433 12.0147 38 14.5 38ZM9 39.5C9 40.8807 7.65685 42 6 42C4.34315 42 3 40.8807 3 39.5C3 38.1193 4.34315 37 6 37C7.65685 37 9 38.1193 9 39.5Z"
          fill="url(#paint0_linear_2472_57049)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2472_57049"
            x1="23.5"
            y1="0"
            x2="23.5"
            y2="42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#9DA6B4" />
          </linearGradient>
        </defs>
      </svg>
    ),
    thumbnailImage: tool_dd_thumbnail,
    toolImage: tool_dd,
  },
  {
    toolId: 'tool_ss',
    sectionId: 'box_face_aa',
    exerciseIdPrefix: 'exercise_face',
    title: '불안 마주하기',
    url: 'action',
    text: '내가 느끼는 불안감에 정면으로 맞섭니다. 불안한 기억과 생각을 떠올리기도 하고, 불안한 상황에 직접 노출할 용감한 계획을 짜기도 해요.',
    textA: ['내 머리 속의 불안을 마주하기', ' 세션을 듣고 획득할 수 있어요.'],
    isTailBottom: true,
    imageMarginBottom: 10,
    bgColor: anxyColorPalette.orange,
    image: (
      <svg width="46" height="49" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4309 0.782447C16.0437 -0.260817 14.5681 -0.260815 14.1809 0.782449L11.9228 6.86641C11.867 7.0166 11.9781 7.17633 12.1383 7.17633H18.4734C18.6336 7.17633 18.7447 7.0166 18.689 6.8664L16.4309 0.782447ZM30.1946 0.782642C29.8073 -0.260622 28.3317 -0.26062 27.9445 0.782644L25.6864 6.8666C25.6307 7.01679 25.7418 7.17652 25.902 7.17652H32.2371C32.3973 7.17652 32.5084 7.01679 32.4526 6.8666L30.1946 0.782642Z"
          fill="#171222"
        />
        <circle cx="23" cy="26" r="23" fill="#171222" />
        <ellipse cx="18.5398" cy="19.8683" rx="1.4285" ry="2.46741" fill="#FFC700" />
        <ellipse cx="25.4226" cy="19.8683" rx="1.55837" ry="2.46741" fill="#FFC700" />
        <path d="M16.5918 27.356H27.5004" stroke="#B43737" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    ),
    thumbnailImage: tool_ss_thumbnail,
    toolImage: tool_ss,
  },
];
