import React, { useEffect } from 'react';
import SelectMultipleButton from './SelectMultipleButton';
import useSessionStorage from '../../hooks/useSessionStorage';
import defaultState from '../../data/inside/defaultState';

export default function TitleWithSelectButton({ title, data, setButtonState }) {
  const [insideState, setInsideState] = useSessionStorage('insideState', defaultState);

  function select(option) {
    const selected = option;
    setInsideState((state) => ({ ...state, answerIdList: [selected] }));
  }

  useEffect(() => {
    if (insideState.answerIdList.length !== 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [insideState, setButtonState]);

  return (
    <div className="px-[20px]">
      <div className="flex flex-col gap-[50px] pb-[40px]">
        <p className="text-[#FF6F6F] text-[16px] leading-[19.2px] font-semibold px-[4px]">{title}</p>
        {data.map((data, index) => (
          <SelectMultipleButton
            key={`selectMulti${index}`}
            title={data.title}
            textList={data.textList}
            propsName={''}
            setButtonState={setButtonState}
            select={select}
            state={insideState}
          />
        ))}
      </div>
    </div>
  );
}
