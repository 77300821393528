import { useEffect, useState } from 'react';
import webviewToast from '../development/webviewToast';
import { requestAppState } from '../utils/webview';

export default function useCheckNotificationState() {
  const [isAlarmSet, setIsAlarmSet] = useState(false);
  const [isNotificationAllow, setIsNotificationAllow] = useState(false);

  const getAlarmPermission = () => {
    const eventFromApp = async (event) => {
      setIsAlarmSet(true);
      // 권한이 없을 때
      if (!isAlarmSet) {
        if (!event.detail.isEnabled) {
          webviewToast('권한 없음');
        } else {
          setIsNotificationAllow(true);
          webviewToast('권한 있음');
        }
      }
    };

    window.addEventListener('notificationPermission', eventFromApp);

    return () => {
      window.removeEventListener('notificationPermission', eventFromApp);
    };
  };

  const checkNotification = () => {
    requestAppState('notification');
    getAlarmPermission();
  };
  useEffect(() => {
    checkNotification();
    if (window.location.hostname === 'localhost') {
      setIsNotificationAllow(true);
    }
  }, []);

  return { isNotificationAllow, checkNotification };
}
