import bed from '../image/course5/bed.png';
import bedWithZ from '../image/course5/bedWithZ.png';
import bedWithEye from '../image/course5/bedWithEye.png';
import human from '../image/course5/human.png';
import zz from '../image/course5/zz.png';
import alarm from '../image/course5/alarm.png';

const sleepingReportSurveyData = [
  {
    type: 'inBedStart',
    title: '지난 밤 언제 침대에 들어갔나요?',
    subitle: '침대에 들어간 시각',
    icon: bed,
  },
  {
    type: 'sleepStart',
    title: '그리고 언제 잠이 들었나요?',
    subitle: '잠에 든 시각',
    icon: bedWithZ,
  },
  {
    type: 'sleepEnd',
    title: '몇 시에 잠에서 완전히 깼나요?',
    subitle: '잠에서 깬 시각',
    icon: alarm,
  },
  {
    type: 'inBedEnd',
    title: '그리고 언제 침대에서 나왔나요?',
    subitle: '침대에서 나온 시각',
    icon: human,
  },
  {
    type: 'struggleTimeMinutes',
    title: '중간에 깨서 뒤척인 시간이 있나요?',
    subitle: '뒤척인 시간',
    icon: bedWithEye,
    isAreaPicker: true,
  },
  {
    type: 'napTimeMinutes',
    title: '낮잠은 얼마나 잤나요?',
    subitle: '낮잠 잔 시간',
    icon: bedWithZ,
    isAreaPicker: true,
  },
];

export default sleepingReportSurveyData;
