import React, { useEffect, useState, useContext } from 'react';
import { motion } from 'framer-motion';
import { CoachingContext } from '../../coachingContext';

function Toast(props) {
  const { text } = props;
  const [loaded, setLoaded] = useState(false);
  const context = useContext(CoachingContext);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (context.showWorkbookToast) {
      setTimeout(() => {
        context.setShowWorkbookToast(false);
      }, 1000);
    }
  }, [context.showWorkbookToast, context]);
  return (
    <motion.div
      className={`w-screen sticky  px-[20px] opacity-100  bottom-[-22px]  `}
      animate={{ y: context.showWorkbookToast ? `-64px` : '0px' }}
      transition={{ duration: loaded ? 0.3 : 0, ease: 'easeInOut' }}
    >
      <div
        className="w-full rounded-[12px] py-[12px] text-[15px] leading-[18px] text-center text-white"
        style={{
          background: 'rgba(255,255,255,0.1)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        }}
      >
        {text}
      </div>
    </motion.div>
  );
}

export default Toast;
