/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { motion } from 'framer-motion';

export default function MultipleSelectTemplate(props) {
  const { current, question, selected, order, goBack } = props;
  const clicked = selected.filter((element) => element.id === question.id);

  return (
    <motion.div
      // animate={{
      //   opacity: order[current] === question.id ? 1 : 0,
      //   y: order[current - 1] === question.id ? -(window.innerHeight - 74) : 0,
      // }}
      // transition={{
      //   delay:
      //     order[current - 1] === question.id
      //       ? 0.5
      //       : order[current] === question.id
      //       ? current === 0 || goBack
      //         ? 0.2
      //         : 0.9
      //       : 0,
      //   duration: 1,
      //   ease: [0.4, 0, 0.2, 1],
      // }}
      style={{
        willChange: 'transform',
        opacity: order[current] === question.id ? 1 : 0,
        y: order[current - 1] === question.id ? -(window.innerHeight - 74) : 0,
        transitionProperty: 'all',
        transitionDelay:
          order[current - 1] === question.id
            ? '0.5s'
            : order[current] === question.id
            ? current === 0 || goBack
              ? '0.2s'
              : '0.9s'
            : '0s',
        transitionDuration: '1s',
        transitionTimingFunction: [0.4, 0, 0.2, 1],
      }}
      className={`will-change-transform fixed w-[calc(100vw-35px)]  bottom-[49px] opacity-0 ${
        order[current] === question.id ? '' : ' pointer-events-none'
      } `}
    >
      <motion.div
        className={`text-[24px] leading-[30px]  mb-[30px] ${
          order[current] === question.id ? 'opacity-100' : 'opacity-100 '
        } transition-all`}
      >
        {question.title}
      </motion.div>
      <div className={`w-full flex flex-wrap gap-[10px] mb-[30px]`}>
        {question.options.map((each, index) => (
          <motion.div
            id="option"
            key={`multioption${index}`}
            style={{
              backgroundColor:
                clicked && clicked.map((element) => element.answer.text).includes(each.text)
                  ? '#FFFFFF'
                  : 'rgba(0,0,0,0.2)',
              color:
                clicked && clicked.map((element) => element.answer.text).includes(each.text) ? '#2C4BEC' : '#FFFFFF',
              y:
                clicked &&
                order[current - 1] === question.id &&
                !clicked.map((element) => element.answer.text).includes(each.text)
                  ? 10
                  : 0,
              opacity:
                order[current - 1] === question.id &&
                clicked &&
                !clicked.map((element) => element.answer.text).includes(each.text)
                  ? 0
                  : 1,
              transitionDuration: '0.2s',
              transitionTimingFunction: [0.4, 0, 0.2, 1],
            }}
            // animate={{
            //   backgroundColor:
            //     clicked && clicked.map((element) => element.answer.text).includes(each.text)
            //       ? '#FFFFFF'
            //       : 'rgba(0,0,0,0.2)',
            //   color:
            //     clicked && clicked.map((element) => element.answer.text).includes(each.text) ? '#2C4BEC' : '#FFFFFF',
            //   y:
            //     clicked &&
            //     order[current - 1] === question.id &&
            //     !clicked.map((element) => element.answer.text).includes(each.text)
            //       ? 10
            //       : 0,
            //   opacity:
            //     order[current - 1] === question.id &&
            //     clicked &&
            //     !clicked.map((element) => element.answer.text).includes(each.text)
            //       ? 0
            //       : 1,
            // }}
            // transition={{ delay: 0, duration: 0.2, ease: 'easeInOut' }}
            className={`  py-[14px] rounded-[100px]  ${
              each.text.length === 2
                ? 'w-[100px] flex justify-center'
                : each.text.length === 3
                ? 'w-[120px] flex justify-center'
                : 'w-fit px-[18px]'
            }  text-[15px] leading-[21px] flex-none flex items-center`}
          >
            <div className="mr-[8px]">
              <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.51435 3.14647C1.21289 2.8624 0.738226 2.8765 0.454158 3.17796C0.17009 3.47942 0.184189 3.95408 0.485649 4.23815L1.51435 3.14647ZM5.57143 8L5.05708 8.54584C5.34595 8.81805 5.7969 8.81805 6.08578 8.54584L5.57143 8ZM13.5144 1.54584C13.8158 1.26177 13.8299 0.787109 13.5458 0.485649C13.2618 0.184189 12.7871 0.17009 12.4856 0.454158L13.5144 1.54584ZM0.485649 4.23815L5.05708 8.54584L6.08578 7.45416L1.51435 3.14647L0.485649 4.23815ZM6.08578 8.54584L13.5144 1.54584L12.4856 0.454158L5.05708 7.45416L6.08578 8.54584Z"
                  fill={
                    clicked && clicked.map((element) => element.answer.text).includes(each.text) ? `#2C4BEC` : '#FFFFFF'
                  }
                />
              </svg>
            </div>
            <div>{each.text}</div>
          </motion.div>
        ))}
      </div>
      <motion.div
        id="goNext"
        animate={{
          opacity: clicked && clicked.map((element) => element.answer.text).length > 0 ? 1 : 0.3,
        }}
        transition={{ delay: 0, duration: 0.1, ease: 'easeInOut' }}
        className={`w-[54px] h-[54px] rounded-[54px] mx-auto bg-white  flex items-center justify-center transition-all`}
      >
        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.75171 17.3087C7.75171 17.861 8.19942 18.3087 8.75171 18.3087C9.30399 18.3087 9.75171 17.861 9.75171 17.3087L7.75171 17.3087ZM9.75171 1C9.75171 0.447715 9.30399 2.41411e-08 8.75171 0C8.19942 -2.41411e-08 7.75171 0.447715 7.75171 1L9.75171 1ZM7.75171 1L7.75171 17.3087L9.75171 17.3087L9.75171 1L7.75171 1Z"
            fill="#2C4BEC"
          />
          <path
            d="M16 11.7517L8.75168 19L1.50336 11.7517"
            stroke="#2C4BEC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </motion.div>
    </motion.div>
  );
}
