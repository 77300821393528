import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function AccordianItem({ item }) {
  const { id } = useParams();
  const [isSeeMore, setSeeMore] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const titleRef = useRef();
  const detailRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 200);
  }, []);

  function seeAnswer() {
    setRotate((rotate) => rotate + 1);
    setSeeMore((isSeeMore) => !isSeeMore);
  }

  return (
    <div className={`bg-[#F3F5F8] rounded-[20px] w-full mx-auto mb-10px`}>
      <div
        className={`overflow-hidden transition-all duration-200  `}
        style={{
          height:
            isSeeMore && detailRef.current && titleRef.current
              ? `${titleRef.current.offsetHeight + detailRef.current.offsetHeight}px`
              : titleRef.current
              ? `${titleRef.current.offsetHeight}px`
              : '66px',
        }}
      >
        <div className={`w-full h-full bg-[#F3F5F8] rounded-[16px]  px-[24px] `} onClick={seeAnswer}>
          <div className=" flex justify-between pt-[20px] pb-[20px]" ref={titleRef}>
            <p className="text-[17px] leading-[26px] text-black font-semibold inline pr-[22px] ">{item.title}</p>
            <div className="flex items-center">
              <motion.div
                className="inline-block my-auto pr-[1px] "
                animate={{ rotate: 180 * rotate }}
                transition={{ duration: loaded ? 0.2 : 0 }}
              >
                <p>
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 1.5L6 6.5L11 1.5"
                      stroke="#26282C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </motion.div>
            </div>
          </div>
          {
            <motion.div
              className={`pointer-events-none `}
              animate={{ opacity: isSeeMore ? 1 : 0 }}
              transition={{ duration: loaded ? 0.2 : 0 }}
            >
              <div ref={detailRef} className=" pb-[20px] text-[#3A3C40]">
                <div>
                  {item.texts &&
                    item.texts.map((each) => (
                      <span className="text-content leading-content font-normal leading-[28px]">
                        {each.text && (
                          <span className="">
                            <span className={``}>{each.text}</span>
                          </span>
                        )}
                      </span>
                    ))}
                  {item.textList &&
                    item.textList.map((text, index) => (
                      <span className={`text-content leading-content font-normal leading-[28px]`}>
                        {text === '\n' && <br />}
                        {index % 2 === 0 ? (
                          <span>{text}</span>
                        ) : (
                          <span className={`bg-[#FFEB3564] pb-[0.9px] pt-[0.6px] `}>{text}</span>
                        )}
                      </span>
                    ))}
                </div>
              </div>
            </motion.div>
          }
        </div>
      </div>
    </div>
  );
}
