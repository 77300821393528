import React from 'react';
import { ActionButton, AnxyActionButton, InsideBlackActionButton, PrimaryActionButton } from '../button/ActionButton';
import { Title } from '../text/Title';
import AnxyNotch from '../../components/anxy/AnxyNotch';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import { motion } from 'framer-motion';
import InsideTitle from '../../components/inside/InsideTitle';
import present from '../../image/inside/present.png';

export function TopTitleBottomActionInside(props) {
  const {
    title,
    subtitle,
    content,
    buttonText,
    buttonState,
    action,
    topColor,
    bgColor,
    fadeIn,
    noHeader,
    insideBlack,
    isBottom,
  } = props;

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return (
    <div
      className="pt-safe"
      style={{
        backgroundColor: bgColor || '#FFFFFF',
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <AnxyNotch notchColor={bgColor || '#FFFFFF'} />
      <div className={`h-full ${!noHeader && 'pt-[44px]'} flex flex-col ${marginClassName}  `}>
        <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col`}>
          {title && (
            <div className="px-[20px] " style={{ backgroundColor: topColor }}>
              <InsideTitle title={title} insideBlack={insideBlack} />
              {subtitle && (
                <p className="opacity-[0.5] text-white text-[18px] leading-[21.6px] pt-[12px]">{subtitle}</p>
              )}
            </div>
          )}
          <div className="flex-1">{content}</div>
        </div>
        <div className={`flex-none w-full relative `}>
          <div
            className="w-full h-[40px] absolute top-[-38px]"
            style={{
              background: `linear-gradient(to top, ${bgColor ? bgColor : 'white'}, rgba(${
                insideBlack ? '0,0,0' : '255,255,255'
              },0))`,
            }}
          />
          <div className="px-[20px]">
            <motion.div
              initial={{ opacity: fadeIn ? 0 : 1, y: fadeIn ? 0 : 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: 'easeInOut' }}
            >
              {isBottom && (
                <div className="flex justify-center items-center pb-[10px]">
                  <img src={present} alt="empty" className="w-[143px]" />
                </div>
              )}
              {insideBlack ? (
                <InsideBlackActionButton state={buttonState} text={buttonText} action={action} noGradient />
              ) : (
                <PrimaryActionButton state={buttonState} text={buttonText} action={action} noGradient />
              )}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
