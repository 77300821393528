/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { AuthContext } from '../../context';
import { changeHeader } from '../../utils/webview';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import CurrentProcess from '../../components/onboarding/CurrentProcess';
import OnboardingModal from '../../components/elements/OnboardingModal';
import useFetch from '../../hooks/useFetch';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function OnboardingPreview(props) {
  let { id } = useParams();

  const [examId, setExamId] = useState(0);
  const [isEnterprise, setEnterprise] = useState(false);
  const [preSurveyId, setPreSurveyId] = useState('');
  const [goSelfCheckClicked, setGoSelfCheckClicked] = useState(false);
  const context = useContext(AuthContext);
  const contentRef = useRef();
  const chartRef = useRef();

  useEffect(() => {
    if (context.completeStepNumber === parseInt(id)) {
      if (context.canCloseOnboarding) {
        console.log('닫을수있음');

        changeHeader({ buttonType: 'close' });
      } else {
        changeHeader({ buttonType: 'none' });
      }
    }
    window.document.body.style.backgroundColor = 'white';
    context.setShowProgressingbar(false);

    if (parseInt(id) === 0) {
      document.title = '예진지 작성 시작';
    } else if (parseInt(id) === 1) {
      document.title = '검사 시작';
    } else if (parseInt(id) === 2) {
      document.title = '코치 매칭 시작';
    }
  }, []);

  useEffect(() => {
    if (context.showOnboardingModal) {
      changeHeader({ backgroundColor: '#00000080', text: '' });
    } else {
      changeHeader({ backgroundColor: '#FFFFFF', text: '' });
    }
  }, [context.showOnboardingModal]);

  const surveyData = JSON.stringify({
    hasCounselingExperience: context.presurvey.hasCounselingExperience === '네, 있어요' ? true : false,
    hasMedicalTreatmentExperience: context.presurvey.hasMedicalTreatmentExperience === '네, 있어요' ? true : false,
    job: context.presurvey.job,
    position: context.presurvey.position,
    subjectOfTreatmentList: context.presurvey.subjectOfTreatmentList,
    work: context.presurvey.work,
    preferredCoachingStyleList: ['a'],
  });

  const [preSurveySubmitResult, preSurveySubmit] = useFetch({
    url: `/onboarding/pre-survey/submit`,
    requestBody: surveyData,
    bg: '#FFFFFF',
  });
  const [surveyQuestion, getSurveyQuestion] = useFetch({
    url: `/onboarding/self-check/qc/list`,
    params: { pre_survey_id: preSurveyId },
    bg: '#FFFFFF',
  });
  useEffect(() => {
    if (preSurveySubmitResult) {
      setPreSurveyId(preSurveySubmitResult.result.preSurveyId);
    }
  }, [preSurveySubmitResult]);

  useEffect(() => {
    if (preSurveyId !== '') {
      getSurveyQuestion();
    }
  }, [preSurveyId]);

  useEffect(() => {
    if (surveyQuestion) {
      context.setSelfcheckQuestion(surveyQuestion.result.qcListBySelfCheckId);
      setExamId(surveyQuestion.result.qcListBySelfCheckId.findIndex((element) => !element.isSubmittedWhenOnBoarding));
    }
  }, [surveyQuestion]);

  const [useInfo, getUserInfo] = useFetch({
    url: `/user/info/detail`,
    bg: '#FFFFFF',
  });
  useEffect(() => {
    if (useInfo) {
      if (useInfo.result.company) {
        console.log('기업회원');
        setEnterprise(true);
      } else {
        console.log('일반회원');
        setEnterprise(false);
      }
    }
  }, [useInfo]);

  useEffect(() => {
    if (parseInt(id) === 0) {
      getUserInfo();
    } else if (parseInt(id) === 1) {
      if (context.completeStepNumber === 0) {
        console.log('presurvey 제출');
        preSurveySubmit();
      } else {
        getSurveyQuestion();
      }
    }
  }, []);

  useEffect(() => {
    if (context.selfcheckQuestion.length > 0 && goSelfCheckClicked) {
      goSelfCheck();
    }
  }, [context.selfcheckQuestion, goSelfCheckClicked]);

  const goOnboardingEnterprise = useNavigateWithParams(`/onboarding1/0`);
  const goOnboardingCustomer = useNavigateWithParams(`/onboarding1/2`);
  const goSelfCheck = useNavigateWithParams(`/onboarding2/${examId}/0`);
  function goNext() {
    if (parseInt(id) === 0) {
      if (isEnterprise) {
        goOnboardingEnterprise();
      } else {
        goOnboardingCustomer();
      }
    } else if (parseInt(id) === 1) {
      setGoSelfCheckClicked(true);
    }
  }

  const preview = [
    {
      title: '맞춤형 멘탈케어를 위해 몇 가지 세팅을 시작할게요',
      subtitle: '인사이드 회원의 중요 정보는 개인을 식별할 수 없게끔 암호화되어 관리돼요. 안심하고 답변해 주세요.',
    },
    {
      title: '마음 종합검진을 시작할게요',
      subtitle: '해외 유수의 대학, 병원, 연구기관에서 개발한 신뢰도 높은 61문항으로 구성되어 있어요.',
    },
    {
      title: '1:1 전담 코치가 도와드릴게요',
      subtitle: '이들은 모두 심리학, 정신의학, 심리상담 분야에서 경력을 쌓은 전문가들이에요.',
    },
  ];

  const Element = (
    <div className="h-full  flex flex-col">
      <div className={`mt-[15px] px-[20px] font-normal text-[17px] leading-[23px]  text-[#4E4F53]`}>
        {preview[parseInt(id)].subtitle}
      </div>
      <div className="flex-1 relative" ref={contentRef}>
        <div
          className=" absolute "
          ref={chartRef}
          style={{
            top:
              contentRef.current && chartRef.current
                ? Math.max(`${(contentRef.current.offsetHeight - chartRef.current.offsetHeight) / 2}`, '30')
                : '',
          }}
        >
          <CurrentProcess id={parseInt(id)} />
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`w-screen h-screen relative 

      `}
    >
      <div className={`h-full `}>
        <TopTitleBottomAction
          title={[preview[parseInt(id)].title]}
          content={Element}
          buttonText={'다음'}
          buttonState={'ACTIVE'}
          action={goNext}
        />
      </div>
      <OnboardingModal />
      {context.showOnboardingModal && (
        <div
          className={`bg-black ${
            context.showOnboardingModal ? 'opacity-[0.5]' : 'opacity-0'
          } absolute top-0 w-full h-full overflow-auto  z-[20]`}
          onClick={() => context.setShowOnboardingModal(false)}
        />
      )}
    </div>
  );
}
