import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import toolModalAtom from '../../../recoil/anxy/session/toolModalAtom';
import sessionAtom from '../../../recoil/anxy/session/atom';
import { anxyToolData } from '../../../data/anxy/home/anxyToolData';
import WorryRecordListInModal from '../anxy12ndA/WorryRecordListInModal';
import BottomSheetModal from '../../../stories/modal/BottomSheetModal';
import { marginWithHomeIndicator } from '../../../recoil/common/device';
import anxySessionData from '../../../data/anxy/anxySessionData';
import { Caption15, Title24 } from '../../../stories/text/AnxyText';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import homeTypeAtom from '../../../recoil/anxy/home/atom';

export default function AnxyModalWithTool(props) {
  const location = useLocation();
  const [sessionState, setSessionState] = useRecoilState(sessionAtom);
  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const goWorryNoteHistory = useNavigateWithParams('/worryNoteHistory');

  const [currentToolState, setCurrentToolState] = useState();
  const [currentData, setCurrentData] = useState();

  const [modalState, setModalState] = useRecoilState(toolModalAtom);
  const setHomeType = useSetRecoilState(homeTypeAtom);

  useEffect(() => {
    if (currentData && currentData.toolId === 'tool_ca') {
      setModalState((state) => ({ ...state, isModalVisible: false }));
    }
  }, [location]);

  useEffect(() => {
    if (!modalState.isModalVisible) {
      setSessionState((state) => ({ ...state, currentTool: undefined }));
    }
  }, [modalState]);

  useEffect(() => {
    if (sessionState && sessionState.currentTool && sessionState.toolList) {
      const current = sessionState.toolList.find((item) => item.toolId === sessionState.currentTool);
      const currentData = anxyToolData.find((item) => item.toolId === sessionState.currentTool);

      setCurrentToolState(current);
      setCurrentData(currentData);
    }
  }, [sessionState]);

  return (
    <BottomSheetModal
      modalState={modalState}
      setModalState={setModalState}
      content={
        <div>
          {currentData && (
            <div className={`z-[70]`}>
              <div
                className={`w-full bg-[#FFFFFF] text-[15px] leading-[18px] relative`}
                style={{
                  borderRadius: '20px 20px 0 0',
                }}
              >
                {currentData.toolId === 'tool_ca' ? (
                  <div className="flex flex-col justify-center">
                    <div className="flex items-center w-full justify-between mb-[24px] px-[4px]">
                      <Title24 title={'지금 나의 걱정'} />

                      <div className="flex gap-[4px] items-center" onClick={goWorryNoteHistory}>
                        <Caption15 caption={'지난 기록'} />
                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.4"
                            d="M1 11.5L6 6.5L1 1.5"
                            stroke="#26282C"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {/* <p className="font-bold text-[18px] leading-[21.6px] pr-[2px] pb-[12px]">지금 나의 걱정</p> */}
                    <WorryRecordListInModal />
                  </div>
                ) : (
                  <div className="flex flex-col justify-center">
                    <div className="flex items-center pb-[8px]">
                      <p className="font-bold text-[18px] leading-[21.6px] pr-[2px]">{currentData.title}</p>
                      {currentToolState.isLock && (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.78622 10.873H15.2148V7.86695C15.2148 6.0885 13.7757 4.64678 12.0005 4.64678C10.2253 4.64678 8.78622 6.0885 8.78622 7.86695V10.873ZM6.64336 10.885C6.17386 10.9044 5.85749 10.9558 5.59202 11.091C5.21569 11.2828 4.90973 11.5887 4.71799 11.9651C4.5 12.3929 4.5 12.9529 4.5 14.073V18.2996C4.5 19.4197 4.5 19.9798 4.71799 20.4076C4.90973 20.7839 5.21569 21.0899 5.59202 21.2816C6.01984 21.4996 6.57989 21.4996 7.7 21.4996H16.3C17.4201 21.4996 17.9802 21.4996 18.408 21.2816C18.7843 21.0899 19.0903 20.7839 19.282 20.4076C19.5 19.9798 19.5 19.4197 19.5 18.2996V14.073C19.5 12.9529 19.5 12.3929 19.282 11.9651C19.0903 11.5887 18.7843 11.2828 18.408 11.091C18.1427 10.9559 17.8266 10.9045 17.3577 10.885V7.86695C17.3577 4.90287 14.9592 2.5 12.0005 2.5C9.04184 2.5 6.64336 4.90287 6.64336 7.86695V10.885Z"
                            fill="#26282C"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="text-[15px] leading-[21px] text-black opacity-[0.6] pb-[12px]">
                      <p className="">{currentData.text}</p>
                      {currentData.toolId !== 'tool_ca' && (
                        <p className="pt-[8px]">
                          {currentData.textA.map((text, index) => (
                            <span
                              className={`${index === 0 && 'underline decoration-1'}`}
                              onClick={() => {
                                if (index === 0) {
                                  setModalState((state) => ({ ...state, isModalVisible: false }));
                                  const sessionData = anxySessionData.find((item) => item.title === text);
                                  console.log(sessionData);
                                  setSessionState((prev) => ({ ...prev, scrollToSession: sessionData.sectionId }));
                                  setTimeout(() => {
                                    setHomeType('anxy');
                                  }, 200);
                                }
                              }}
                            >{`${index === 0 ? `<${text}>` : text}`}</span>
                          ))}
                        </p>
                      )}
                    </div>
                    <div className="flex items-center bg-[#F3F5F8] rounded-[16px] w-full h-[292px]">
                      <img src={currentData.toolImage} alt="empty" className="w-[129px] m-auto" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}
