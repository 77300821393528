import questionmark from '../image/questionmark.png';
import inside from '../image/inside.png';
import checkbox from '../image/checkbox.png';

import mh1 from '../image/mh1.png';
import mh2 from '../image/mh2.png';
import mh3 from '../image/mh3.png';
import mh4 from '../image/mh4.png';
import mh5 from '../image/mh5.png';
import mh6 from '../image/mh6.png';
import mh7 from '../image/mh7.png';
import mh8 from '../image/mh8.png';
import mh9 from '../image/mh9.png';
import mh10 from '../image/mh10.png';
import mh11 from '../image/mh11.png';
import mh12 from '../image/mh12.png';
import mh13 from '../image/mh13.png';
import mh14 from '../image/mh14.png';
import mh15 from '../image/mh15.png';
import mh16 from '../image/mh16.png';
import mh17 from '../image/mh17.png';
import mh18 from '../image/mh18.png';
import mh19 from '../image/mh19.png';
import mh20 from '../image/mh20.png';
import mh21 from '../image/mh21.png';

const contents = [
  {
    id: 0,
    image: inside,
    category: '인사이드 이야기',
    title: '심리상담, 모두의 경험이 될 수 있도록',
    subtitle: '인사이드 팀이 상담 서비스를 만드는 이유',
    texts: [],
    link: 'https://campaign.inside.im/therapy-for-us',
  },
  {
    id: 1,
    image: questionmark,
    category: '인사이드 이야기',
    title: '왜 영상으로 비대면 상담을 제공해요?',
    subtitle: '전화도 문자도 아니고 왜 꼭 영상 상담일까요',
    texts: [
      {
        text: [
          '상담은 서로에 대한 공감과 신뢰를 기반으로 진행됩니다. 그 과정에서 서로의 얼굴과 눈을 마주보며 다양한 감정을 공유하기도 하지요.',
          <br />,
          <br />,
          '비대면으로 언제든, 어디서든, 내가 원하는 시간과 장소에서 상담을 받을 수 있다는 건 큰 장점이지만, 단순 채팅이나 음성 통화만으로는 제대로 된 상담을 진행하기가 쉽지 않습니다.',
          <br />,
          <br />,
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '그래서 인사이드는 영상 통화를 기반으로 비대면 심리상담을 제공하고 있답니다.',
        bold: true,
        numbering: false,
      },
      {
        text: ' 인사이드에서 상담사 선생님들을 만나고 더 속깊은 이야기들을 나눠보는 건 어떨까요?',
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 2,
    image: checkbox,
    category: '인사이드 이야기',
    title: '인사이드의 상담사는 어떤 분들인가요?',
    subtitle: '자격증만 가지고 있으면 상담을 잘할 수 있나요',
    texts: [
      {
        text: [
          '인사이드는 보다 엄격한 상담사 합류 기준을 적용하고 있습니다. 상담은 우리 마음의 건강과 관련된 부분이고, 체계적으로 교육받은 전문가만 다른 사람의 건강에 영향을 미칠 수 있으니까요.',
          <br />,
          <br />,
          '인사이드에 합류하고 있는 상담사 선생님들은 모두 상담심리사 1급 혹은 임상심리전문가(임상심리사 1급) 자격증을 보유한 분들이랍니다. 두 자격증은 모두 심리 및 상담 관련된 학업을 바탕으로 3~4년 정도의 전문적인 수련 과정을 마치고 인증을 받아야 발급이 가능한 자격증이에요. 또한 인사이드와 함께 하고 있는 선생님들은 모두 관련 분야에서 수년 이상의 경험이 있는 전문가 선생님들이랍니다. ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '인사이드의 선생님들은 더 세심하게 귀 기울이고, 더 체계적으로 도움을 주실 수 있을 거예요.',
        bold: true,
        numbering: false,
      },
      {
        text: [
          <br />,
          <br />,
          '하지만 선생님들의 전문성이 높아질수록 상담 비용이 조금 더 높아질 수밖에 없을 거예요. 그래서 인사이드 팀은 상담비 지원, 기업 제휴, 감정기록의 정기적인 이용에 따른 상담권 제공 등 다양한 장치들을 도입하여 보다 많은 분들이 더 쉽고 부담없이 심리상담을 이용할 수 있도록 노력하고 있답니다!',
          <br />,
          <br />,
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 3,
    image: mh1,
    category: '건강한 마음을 위한 지침서',
    title: '나... 스트레스 받고 있니?',
    subtitle: '스트레스 받은 우리 몸이 보내는 위험신호 6가지',
    texts: [
      {
        text: [
          '치열한 현대 사회 속에서 살아가는 우리는 스트레스를 받는 것에 익숙해져 있습니다.',
          <br />,
          <br />,
          '그러나 이러한 스트레스를 시간이 부족하다는 이유로, 또는 금전적 여유가 부족하다는 이유로 제때 해소하지 못하고 방치하다가 우울, 불안, 공황 증상 등 다양한 정신적인 문제들이 발생하곤 합니다. ',
          <br />,
          <br />,
          "그런데 스트레스라는 게 도대체 뭘까요? 정신적으로 힘들고 피곤하면 스트레스를 받는다고 하는데 그러한 스트레스가 '쌓인다'니, 이상하지 않나요?",
          <br />,
          <br />,
          "물론 스트레스라고 하는 물질이 실제 존재하는 것은 아닙니다. 하지만 정신적으로 고생하다보면 스트레스가 정말 '쌓여서' 우리 몸도 변한다는 사실, 알고 계셨나요? 이번 글에서는 스트레스 받은 우리 몸이 보내는 위험신호에는 어떠한 것들이 있는지 알아보도록 하겠습니다.",
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '1. 체중 증가',
        bold: true,
        numbering: true,
      },
      {
        text: [
          '스트레스를 받으면 우리 몸에서는 호르몬 분비에 변화가 생기기 시작합니다. 특히 부신이라는 장기에서 스트레스에 대응하기 위해 코티솔이라고 불리는 호르몬을 분비하는데요, 코티솔은 혈당 수치를 올리고 식욕을 증가시키는 효과를 가지고 있습니다. 그렇기 때문에 스트레스를 받으면 과식을 하거나 평소에 먹지 않던 야식을 찾게 되는 것이지요. 혹시 최근 들어 과식을 하거나 야식을 자주 찾고 있진 않으신가요? 그렇다면 너무 스트레스를 받고 있는 건 아닌지 생각해보아야겠습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '2. 피부 질환',
        bold: true,
        numbering: true,
      },
      {
        text: [
          "사람의 피부는 스트레스에 매우 민감하게 반응합니다. 예를 들어, 스트레스는 피부에 분포하는 감각신경 섬유를 자극해서 가려움증을 유발할 수 있습니다. 또한 코티솔 분비가 과도하게 증가하면서 피지 분비가 늘어나 얼굴 등에 피부 트러블이 발생하기도 합니다. 팩도 하고, 피부과도 가고, 좋은 화장품을 써도 피부가 좋아지지 않는다고요? 문제의 원인은 '정신적 피로'에 있을 수 있답니다.",
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '3. 잦은 감기',
        bold: true,
        numbering: true,
      },
      {
        text: [
          '오랫동안 스트레스를 받는 와중에 감기몸살에 걸린 경험이 있는 분들이 계시리라 생각됩니다. 코티솔과 카테콜라민과 같은 스트레스 호르몬의 분비가 증가하면 우리 몸의 전체적인 면역 기능이 저하됩니다. 따라서 스트레스를 많이 받으면 인체에 유해한 세균이나 바이러스의 유입을 막고 제거하는 힘이 떨어져 감기에 쉽게 걸릴 수 있습니다. 계속 잔병치레가 많으신가요? 그렇다면 몸보다는 마음에 휴식이 필요한 시기는 아닌지 생각해볼 필요가 있습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '4. 뚜렷한 이유 없는 복통',
        bold: true,
        numbering: true,
      },
      {
        text: [
          '스트레스가 심한 상황에서 복부의 통증을 느끼거나 소화 불량, 복부 팽만 및 위경련 등의 증상이 발생할 수 있습니다. 이는 스트레스로 항진된 교감 신경을 억제하기 위하여 부교감 신경이 활성화되면서 위산 분비를 촉진시켜서 나타나는 증상으로 볼 수 있습니다. 소화제를 먹어도 호전되지 않는 만성적인 소화 불량과 복부 통증, 혹시 스트레스 때문에 발생한 건 아닐까요?',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '5. 혈압 상승',
        bold: true,
        numbering: true,
      },
      {
        text: [
          '스트레스가 누적되면 혈압 상승으로 이어질 수 있습니다. 스트레스를 받았을 때 나오는 호르몬들은 대부분 혈압을 높이도록 작용하기 때문이지요. 혈압 상승은 협심증, 뇌혈관질환, 당뇨병 등 다른 질환이 발병할 위험을 높이는 주범입니다. 뇌혈관질환이나 심장질환을 앓고 계신 분들께서는 마음의 여유를 찾을 수 있도록 더욱 주의해야겠네요!',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '6. 성기능 저하',
        bold: true,
        numbering: true,
      },
      {
        text: [
          '스트레스는 성욕 및 성기능 저하를 유발할 수 있습니다. 스트레스를 받으면 위험에 대처하는 데 중요한 역할을 수행하는 뇌, 심장 및 근육으로 가는 혈류는 증가하지만 성기를 비롯한 이외 기관으로 가는 혈류는 줄어들기 때문입니다. 사랑보다는 일단 생존이 먼저인 것이지요. 마음에 여유를 가지면 더 자신감 있는 날들을 보낼 수도 있답니다!',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: [
          <br />,
          '지금까지 스트레스 받은 우리 몸이 보내는 위험신호 6가지에 대해서 알아보았습니다. 이러한 신체적 변화들은 일상생활을 저해하여 또 다른 스트레스를 야기할 수 있기 때문에 우리 몸이 보내는 위험신호에 귀를 기울여 스트레스를 제때 관리하고 건강하게 해소하는 것이 매우 중요합니다.',
          <br />,
          '혹시 이 글을 읽고 계시는 분들께서도 몸에서 위와 같은 신호를 보내고 있지는 않은지 생각해보시면 좋겠습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: [<br />, <br />, '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.'],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 4,
    image: mh2,
    category: '우울증과 조울증의 모든 것',
    title: '우울한 마음이야 의지로 이겨내는 거 아니야?',
    subtitle: '의지의 문제가 아니라 뇌의 질환이에요',
    texts: [
      {
        text: [
          '우울증은 ‘마음의 감기’라는 별칭을 가지고 있을 만큼 현대사회에서 굉장히 흔하게 찾아볼 수 있는 질환입니다. 건강보험심사평가원이 밝힌 수치에 따르면, 2016년 국내 우울증 환자는 643,102명에 달한다고 합니다. 하지만 또 다른 관점에서 바라보면 ‘감기’라는 단어는 우울증과 어울리지 않는 측면이 있습니다. 감기는 특별히 치료하지 않아도 일주일 정도가 되면 대부분 언제 아팠냐는 듯이 사라지는 반면, 우울증의 경우에는 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '결코 저절로 낫지 않으며 꾸준히 치료',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '를 받아야 합니다. 그렇지만 항우울제를 복용하고 주변의 관심과 도움을 받는다면 잘 회복할 수 있는 질환이기도 합니다.',
          <br />,
          <br />,
          '"의지가 약해서 우울증에 걸린 거야."',
          <br />,
          '"마음을 강하게 먹고 생각을 긍정적으로 바꾸면 나아질 수 있어."',
          <br />,
          '우리 사회에는 우울증을 ‘의지의 문제’로 규정하고 우울증을 앓고 있는 사람들을 ‘의지가 부족하고 마음이 약한 사람들’로 바라보는 그릇된 시선이 존재합니다.',
          <br />,
          <br />,
          '약을 먹는다고 해서 생각이 바뀔 수 있나요? 정신력이 중요한 것 아닌가요?',
          <br />,
          '실제로 우울증으로 진단된 환자분들께서도 우울증이 강한 정신력을 통해 이겨낼 수 있는 질환이라는 생각으로 항우울제 치료에 거부감을 내비치는 경우가 적지 않습니다. 그러나 수십 년간의 연구를 통해 우울증은 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '중추신경계의 신경전달의 기능 이상으로 인해 발생하는 뇌질환',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '이라는 사실이 명백하게 밝혀졌습니다. 많은 우울증 환자에서 뇌 영상을 촬영해 보면 전두엽-변연계의 기능이 저하된 것이 확인됩니다. 우리 뇌의 전두엽이라는 부위는 사고, 판단 및 계획을 담당하며 변연계는 수면, 기억 및 본능과 관련된 기능들을 조절합니다. 또한 우울증 환자의 뇌에서 세로토닌, 도파민, 노르에피네프린 등의 신경전달물질의 수치가 저하된 것이 관찰되기도 합니다. 이러한 뇌의 기능적인 문제들 때문에 우울증에 걸리면 기분이 우울하고 매사에 의욕이 저하되며, 식욕이 떨어지고 숙면을 취할 수 없게 되는 것입니다.',
          <br />,
          <br />,
          '항우울제는 세로토닌 등의 신경전달물질이 담당하는 전두엽-변연계 신경회로의 기능을 개선하여 우울한 기분을 비롯한 우울증에서 나타나는 증상들을 정상으로 되돌리는 역할을 하는 것입니다. 이러한 약물들은 우울증에 대한 효과가 충분히 입증된 바 있습니다.',
          <br />,
          <br />,
          '물론 세상사 모든 일들에서 마음 가짐은 정말 중요하게 작용합니다. 하지만 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ' ‘의지’나 ‘정신력’만으로는 신경전달물질을 변화시킬 수 없습니다.',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '의지로 감염을 낫게 하거나 암을 치료할 수 없듯, 우울증 또한 마찬가지로 의지 박약으로 초래되는 것이 아니며 강한 의지력으로 치료할 수 있는 것도 아닙니다.',
          <br />,
          <br />,
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '우울증은 의지의 문제가 아니라 뇌의 질환입니다.',
        bold: true,
        numbering: false,
      },
      {
        text: [<br />, <br />, '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.'],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 5,
    image: mh3,
    category: '건강한 마음을 위한 지침서',
    title: '번아웃! 이젠 아무것도 못 하겠다',
    subtitle: '번아웃 증후군을 극복하기 위한 생각의 전환',
    texts: [
      {
        text: [
          '최근 들어 직장인들 사이에서 ‘번아웃 증후군’이라는 현상이 큰 이슈가 되고 있습니다. 특히 코로나 사태가 발생하면서 전국의 크고 작은 병원에 수많은 환자들이 몰려들기 시작하면서 코로나19 의료진의 심각한 번아웃이 매체를 통해 널리 알려지기도 했습니다.',
          <br />,
          <br />,
          '그렇다면 번아웃 증후군이란 정확히 무엇일까요? 2019년, 국제보건기구는 제11차 국제질병분류(ICD-11)에 번아웃 증후군을 두고 이를 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '‘구체적으로 업무 환경에 국한되어 나타나며 제대로 해소되지 않은 만성적인 직장 업무 스트레스’',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '로 정의한 바 있습니다. 공식적인 질환은 아니지만 현대 사회에서 수많은 사람들이 경험하고 있는 만큼 중요한 현상으로서 인정한 셈이지요. 국제보건기구는 이에 더하여 아래 세가지를 번아웃 증후군의 증상으로 제시했습니다:',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: [<br />, <br />, '1. 매사에 무기력하고 지친다.', <br />],
        bold: true,
        numbering: false,
      },
      {
        text: ['2. 직장 및 업무에 대해 냉소적인 태도를 취하거나 거부감을 느낀다.', <br />],
        bold: true,
        numbering: false,
      },
      {
        text: ['3. 일의 수행능력과 효율이 떨어진다.', <br />, <br />],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '번아웃 증후군은 육체적인 피로감을 일으킬뿐만 아니라 정신건강까지 해치는 것으로 알려져 있습니다. 하지만 직장인들은 우울감이나 불안함 등이 직장 및 업무 스트레스에서 기인한다는 사실을 잘 인지하지 못합니다. 그렇다보니 그러한 감정의 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '원인을 자신의 낮은 자존감이나 능력 부족으로 돌리는 경우',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '도 많습니다. 이러한 경우에는 부정적인 감정들이 업무와 직장의 울타리를 벗어나 삶 전체에 영향을 끼치게 되므로 결국 삶 자체가 우울해지고 흥미가 없어지기도 합니다. 정작 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '문제는 본인이 아니라 본인에게 과도한 상황',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '에 있지만 이를 쉽사리 파악하지 못하는 것이지요.',
          <br />,
          <br />,
          '일에 대한 피로감이 삶 자체에 대한 피로감이 되는 것을 방지하기 위해서는 업무에서 기인하는 만성적인 스트레스를 스스로 인지하고 이를 적절히 해소하고자 노력하는 자세가 굉장히 중요합니다. 지금부터는 정신건강 전문가들이 전하는 번아웃 중후군을 극복하기 위한 생각의 전환 방법들을 함께 알아보겠습니다.',
          <br />,
          <br />,
          '먼저, 일이 바쁘고 잘 안 될수록 의식적으로 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '자신의 마음을 보살피기 위한 시간',
        bold: true,
        numbering: false,
      },
      {
        text: '을 가져야 합니다. 직장 밖에서는 업무에 대한 생각보다는 ',
        bold: false,
        numbering: false,
      },
      {
        text: '충분한 휴식과 수면',
        bold: true,
        numbering: false,
      },
      {
        text: '을 취하고 ',
        bold: false,
        numbering: false,
      },
      {
        text: '적절한 식사 및 신체활동',
        bold: true,
        numbering: false,
      },
      {
        text: '을 스스로 챙기는 것이 중요합니다. 아울러 아무리 일이 바쁘더라도 집에 돌아와서는 ',
        bold: false,
        numbering: false,
      },
      {
        text: '충분한 회복의 시간',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '을 가져야 합니다. 마라톤 선수가 매일 달리기를 할 수 없는 것처럼 우리도 매일 복잡한 생각들을 하며 살 수는 없습니다. 우리들은 모두 휴식이 필요합니다. 휴식에 대해 죄책감을 느낄 필요는 없습니다.',
          <br />,
          <br />,
          '더 나아가, 직장에서 함께 일하는 동료들과 어려움을 나누고 서로 지지해주는 것이 많은 도움이 될 수 있습니다. ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '서로의 감정에 대해 터놓고 이야기하는 문화',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '는 여러 측면에서 지지적인 효과가 있다는 연구 결과들이 많습니다. 뿐만 아니라 주변 사람들과 마음을 열고 대화를 나눔으로써 번아웃이 비단 자신만의 문제가 아니라 모두가 함께 헤쳐나가야 할 공동의 과제라고 인식을 전환할 수도 있습니다.',
          <br />,
          <br />,
          '또한 음주나 흡연 및 과도한 카페인 섭취는 피하는 것이 좋습니다. 많은 사람들이 술과 담배를 직장 스트레스를 해소하기 위한 수단으로 여기지만, 음주와 흡연은 물질 의존을 키우고 신체건강을 해치기만 할 뿐 번아웃 증후군을 극복하는 데 전혀 도움이 되지 않습니다. 오히려 자신의 마음과 마주하여 소통하는 시간을 가지면서 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '명상이나 호흡법 등을 활용하여 심신을 단련',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '해주는 것이 도움이 될 수 있습니다.',
          <br />,
          <br />,
          '마지막으로, 번아웃이 너무 심해서 혼자 감당하기 힘들다면 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '정신건강의학과나 심리상담센터에 방문',
        bold: true,
        numbering: false,
      },
      {
        text: ['하는 것을고려해보는 것도 좋은 선택지 중 하나입니다. 꼭 약물을 처방 받지 않더라도 '],
        bold: false,
        numbering: false,
      },
      {
        text: '자신을 힘들게 만드는 감정과 생각에 대해 전문가에게 공유하고 상담을 받는 것은 증상을 호전시키는 데 큰 도움',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '이 될 수 있기 때문입니다.',
          <br />,
          <br />,
          '직장 스트레스에 대해 자신을 탓하지 말고, 주변 동료들과 감정을 공유하고, 마음을 보살피기 위한 충분한 휴식을 취한다면 번아웃 증후군을 극복하실 수 있으리라 믿습니다.',
          <br />,
          <br />,
          '인사이드 팀은 대한민국의 직장인 여러분 모두를 응원합니다. 우리 지치지 말아요 :)',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: [<br />, <br />, '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.'],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 6,
    image: mh4,
    category: '불면증 극복 프로젝트',
    title: '안녕히 주무셨어요?',
    subtitle: '수면 부족: 건강한 마음을 위협하는 불청객',
    texts: [
      {
        text: [
          '‘안녕히 주무셨어요?’',
          <br />,
          <br />,
          '우리는 바쁜 일상 속에서 업무와 과제, 집안일 등에 치여 편히 잠들지 못하는 밤을 보내고는 합니다. 또한 할 일이 너무 많은 날에는 능률이 떨어지더라도 휴식과 수면보다는 커피나 에너지 드링크 등을 마시면서 밤을 새는 길을 택하기도 합니다. 수면을 제대로 취하지 못한 상태로 일주일을 바쁘게 보내다가 주말에는 쏟아지는 잠을 이기지 못하고 하루 종일 침대 속에서 시간을 보내기도 하지요.',
          <br />,
          <br />,
          '이 글을 읽고 계시는 여러분들께서도 위의 이야기가 본인 이야기처럼 느껴지지는 않으시나요? 제대로 된 수면을 취하지 못한 다음날 쏟아지는 졸음 때문에 집중력이 저하되어 업무의 효율이 떨어지는 경험은 모두 다 한 번쯤 해보셨을 것으로 생각됩니다. 그런데 수면 부족은 일의 능률뿐만 아니라 우리의 정신건강까지 해친다는 사실, 알고 계셨나요? 이번 글에서는 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '건강한 마음을 유지하기 위해서 잠을 잘 자는 것이 얼마나 중요한지 ',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '말씀드리려고 합니다.',
          <br />,
          <br />,
          '영국의 옥스퍼드 대학교 연구팀이 수면 부족을 경험하고 있는 약 3,800명의 피실험자를 대상으로 인지행동치료를 통해 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['수면의 질을 개선'],
        bold: true,
        numbering: false,
      },
      {
        text: ['시킨 결과, '],
        bold: false,
        numbering: false,
      },
      {
        text: ['우울 및 불안 증상이 20% 정도 감소'],
        bold: true,
        numbering: false,
      },
      {
        text: ['한 것으로 나타났습니다. 이와 더불어 피실험자들이 '],
        bold: false,
        numbering: false,
      },
      {
        text: '주관적으로 느끼는 행복감 또한 약 10% ',
        bold: true,
        numbering: false,
      },
      {
        text: ['정도 높아졌으며 '],
        bold: false,
        numbering: false,
      },
      {
        text: '타인에 대한 신뢰감',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '도 깊어졌다고 합니다. 이러한 연구 결과는 우리에게 굉장히 흥미롭게 다가옵니다. 별다른 약물을 복용하거나 치료를 받지 않아도 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '잠만 잘 자도 덜 우울하고 불안해질 수 있다',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '니 말입니다.',
          <br />,
          <br />,
          '수면이 정신건강에 미치는 영향은 영국뿐만 아니라 우리나라에서 시행된 연구에서도 명백히 관찰되었습니다. 강북삼성병원의 한 연구팀은 약 21만 명의 20-40대 근로자를 대상으로 수면 시간, 직무 스트레스, 우울, 불안 및 자살에 대한 생각을 조사하였습니다. 평균 7시간 잠을 잔 군에서 우울, 불안, 자살 생각의 유병률이 각각 4.3%, 2%, 5%인 반면, 평균 수면시간이 4시간 이하인 군에서는 각각 16%, 9.1%, 12.7%로 약 2-4배 높은 것으로 나타났습니다. 영국 옥스퍼드 대학교에서 진행한 연구와 마찬가지로 잠을 잘 잘수록 우울하거나 불안할 확률이 줄어든 것이지요. 다소 흥미로운 점은 평균 10시간 이상 잠을 자는 군에서는 7시간 군에 비해 우울, 불안 및 자살 생각이 높게 나타났습니다. 이는 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '잠을 무조건 많이 잔다고 좋은 것이 아니',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '라, 활동시간과 수면시간이 적절한 균형을 이루는 선에서 충분한 수면을 취하는 것이 중요하다는 것을 우리에게 시사합니다. ',
          <br />,
          <br />,
          '더 나아가, 충분한 수면은 성인뿐만 아니라 자라나는 어린이와 청소년들에서도 굉장히 중요합니다. 그러나 2014년 질병관리본부에서 발표한 보고서에 따르면, ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '우리나라 영유아의 33.6%가 수면 부족을 경험',
        bold: true,
        numbering: false,
      },
      {
        text: '한 바 있으며 유아기에서 청소년기로 접어들면서 평균 수면시간이 8.5시간에서 5.7시간으로 급격히 줄어든다고 합니다. ',
        bold: false,
        numbering: false,
      },
      {
        text: 'OECD 국가 중 우리나라의 청소년 수면시간은 최저',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '에 속하며, 우리나라 아이들은 평균적으로 다른 나라 아이들에 비해 늦게 잠들고 일찍 일어나는 것으로 나타나고 있습니다. 청소년기의 수면 부족은 우울 및 불안 뿐만 아니라 공격적인 행동, 약물 오남용 및 성 문제까지 이어질 수 있기 때문에 우리 사회의 바람직한 정신건강을 구축하기 위해서는 어른들은 물론이고 아이들까지 충분한 수면을 취할 수 있게 되는 것이 매우 중요할 것입니다.',
          <br />,
          <br />,
          '‘건강한 신체에 건강한 정신이 깃든다’는 말이 있듯이 숙면을 통해 충분히 휴식을 취하고 생기를 되찾는 것이 우리의 정신건강에 얼마나 중요한지 알아보았습니다. 오늘부터 직장에서, 학교에서 아침마다 새로운 인사를 나눠보는 것은 어떨까요?',
          <br />,
          <br />,
          '‘안녕히 주무셨어요?’',
        ],
        bold: false,
        numbering: false,
      },

      {
        text: [<br />, <br />, '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.'],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 7,
    image: mh5,
    category: '우울증과 조울증의 모든 것',
    title: '애완동물을 키우면 우울증에 도움이 된다고?',
    subtitle: '의학자들이 말하는 우울증 예방을 위한 생활습관',
    texts: [
      {
        text: [
          '현대의학에서는 올바른 생활습관의 중요성이 점점 대두되고 있습니다. 질병이 이미 발생한 경우에는 약물 복용 및 수술 등의 의학적인 조치가 중요하지만, 질병의 발생을 처음부터 막는 데에는 올바른 생활습관을 갖추는 것이 중요하다고 알려져 있습니다. 현대사회에서 ‘마음의 감기’로 불리는 우울증도 예외는 아닌데요, 이번 글에서는 한 가지 논문을 통해 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '우울증을 예방하고 튼튼한 정신건강을 키우는 데에 도움이 된다고 알려진 생활습관 6가지',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '를 함께 간단히 살펴보려고 합니다.',
          <br />,
          <br />,
          '이번에 소개해 드리고자 하는 논문은 정신과학에서 권위 있는 학술지로 알려진 BMC Psychiatry에 J. Sarris 등이 2014년에 게재한 ‘Lifestyle medicine for depression (우울증을 위한 생활습관의학)’입니다. 이 논문은 메타분석연구로서, 우울증과 생활습관 사이의 관계를 연구한 논문 150개 이상을 분석하여 우울증의 발생을 유의미하게 감소시키는 것으로 밝혀진 생활습관들을 소개하고 있습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['1. 건강한 식단'],
        bold: true,
        numbering: true,
      },
      {
        text: [
          '영국에서 3,486명을 대상으로 시행한 연구에 따르면, 서구식 식단은 우울증 발병률의 증가와 연관이 있는 것으로 나타났으며 자연 식품을 주로 섭취한 군에서는 우울증의 발병률이 유의미하게 낮은 것으로 파악되었습니다. 또한 한국 및 그리스 등에서 시행한 다수의 연구가 지중해식 식단을 위주로 식사를 하는 것이 우울증의 발병률을 낮춘다는 결과를 내기도 했습니다. 서구식 식단은 전신적인 염증 수치를 나타내는 C-반응단백질 수치를 높이는 반면, 자연 식품으로 구성된 식단을 섭취한 경우에는 신경계의 발달에 필요한 지방산 및 필수 미량 원소를 충분히 제공받을 수 있다고 합니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['2. 신체활동 및 운동'],
        bold: true,
        numbering: true,
      },
      {
        text: [
          '전세계인을 대상으로 시행한 역학 연구에 따르면, 불충분한 신체활동은 우울증 발병률의 증가와 관련이 있으며 소아청소년기에 꾸준히 신체활동과 운동을 한 사람의 경우에는 성인이 되어 우울증이 찾아올 확률이 낮게 나왔습니다. 이러한 맥락에서, 현대 사회에서 신체활동이 전반적으로 줄어듦과 동시에 우울증으로 진단되는 사람들이 늘어나는 현상 간에는 연관성이 있어 보입니다. 신체활동은 사교활동을 늘리고 긍정적인 신체상을 갖추는 데 중요한 역할을 합니다. 또한 체내의 염증을 줄여주고 우울증의 발병과 연관된 것으로 알려진 모노아민 계열의 신경전달물질의 균형을 유지하는 데 도움을 준다고 합니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '3. 마음 챙김 명상',
        bold: true,
        numbering: true,
      },
      {
        text: [
          '요즘에는 정신건강 관리를 위한 방법으로 마음 챙김 명상이 각광을 받고 있으며, 실제로 많은 사람들이 명상에 도움이 되는 음악, 책, 휴대폰 앱 등에 관심을 가지고 있습니다. 다양한 연구에 의하면, 마음 챙김 명상을 꾸준히 하면 우울감 및 불안감을 줄여주는 데 도움이 된다고 합니다. 이러한 효과는 사람들의 주관적인 생각뿐만 아니라, 뇌 영상검사를 통해서 뇌의 구조적인 변화를 불러일으키는 것으로 알려져 있습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '4. 금주 및 금연',
        bold: true,
        numbering: true,
      },
      {
        text: [
          '많은 연구들이 술을 끊은 뒤 얼마 지나지 않아 우울감이 유의미하게 줄어든다는 결과를 발표했습니다. 또한 비흡연자에서 우울증의 발병률이 비교적 낮은 것으로 나타났습니다. 더 나아가, 항우울제를 복용하는 동안 흡연을 하면 담배를 통해 체내에 흡수되는 물질이 항우울제의 효과를 방해한다는 연구도 있었습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '5. 건강한 사회적 상호작용',
        bold: true,
        numbering: true,
      },
      {
        text: [
          '개인의 정신건강에 영향을 미치는 가장 큰 생활습관 중 하나가 바로 사회적 상호작용입니다. 긍정적이고 지지적인 인간관계를 유지하는 것은 정신건강뿐만 아니라 전체적인 신체건강에도 도움을 주는 것으로 알려져 있습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '6. 애완동물 및 동물과의 교감',
        bold: true,
        numbering: true,
      },
      {
        text: [
          '동물 (특히 애완동물)과의 깊은 교감이 우울감을 호전시킬 수 있는 가능성이 제기되고 있습니다. 애완동물을 키우게 되면 교감을 통해서 무조건적인 사랑을 경험할 수 있으며 인생의 또 다른 목표와 원동력이 생기게 됩니다. 실제로 서구에서는 우울한 기분을 치료하기 위해 애완동물이나 말, 돌고래 등의 동물들과 교감하는 동물 보조 치료법을 시행하기도 한다고 합니다. 그러나 이 부분은 아직 의학적으로 증명된 바가 없어 추후 더 많은 연구가 필요한 상황입니다.',
          <br />,
          <br />,
          '<참고 문헌>',
          <br />,
          '[1] Sarris, Jerome & O’Neil, Adrienne & Coulson, Carolyn & Schweitzer, Isaac & Berk, Michael. (2014). Lifestyle Medicine for Depression. BMC psychiatry. 14. 107. 10.1186/1471-244X-14-107. ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: [<br />, <br />, '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.'],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 8,
    image: mh6,
    category: '건강한 마음을 위한 지침서',
    title: '코로나에 감염되면 정신질환이 발생할 확률이 높아진다?',
    subtitle: '코로나는 정신건강에 어떠한 영향을 미칠까?',
    texts: [
      {
        text: [
          '코로나19는 우리의 생활과 관련된 모든 부분을 잠식해오고 있습니다. ‘코로나 때문에,’ ‘코로나 시국에,’ ‘코로나 언제 끝날까’ 등의 말들은 일상적인 대화에서 빠지면 어색할 정도이지요. 코로나에 대해서 생각하고 말하는 것은 어느새 버릇처럼 되어버렸습니다. 코로나19와 우울감이라는 뜻의 ‘blue’가 합성된 ‘코로나 블루’라는 신조어가 탄생할 정도로 우리는 감염에 대한 두려움과 사회적 거리두기 속의 고립으로 많이 힘들고 지친 상황입니다.',
          <br />,
          <br />,
          '최근 코로나19의 확산세가 점점 거세져 하루 확진자 수가 2000, 3000명에 달하면서 우리 주변에도 코로나19에 감염된 지인을 하나둘씩 찾아볼 수 있게 되었습니다. 코로나19에 감염되면 다양한 신체적 합병증이 나타날 수 있다는 것은 이제 모두가 아는 사실입니다. 코로나19 감염에 바이러스 폐렴이나 호흡부전이 중첩되어 호흡기 기능을 망가뜨릴 수 있고, 감염이 전신적으로 퍼지거나 콩팥의 기능을 갑작스럽게 떨어뜨릴 수도 있습니다. 하지만 코로나19에 감염되고 나면 다양한 정신질환이 쉽게 찾아올 수 있다는 사실 또한 여러분께서 알고 계셨나요?',
          <br />,
          <br />,
          '작년 11월, 세계적인 정신의학 학술지인 Lancet Psychiatry에 한 편의 흥미로운 논문이 발표되었습니다. 바로 옥스퍼드 대학교의 연구팀이 게재한 ‘Bidirectional associations between COVID-19 and psychiatric disorder (코로나19와 정신질환 간의 상호관계)’라는 제목의 논문입니다. 옥스퍼드 연구팀은 본 연구를 통해 코로나19와 다양한 정신질환이 서로에게 미치는 영향을 밝혀내고자 했습니다. M. Taquet을 비롯한 연구진은 작년 1월부터 8월까지 미국에서 코로나19에 감염된 62,354명의 데이터를 분석하여 코로나19에 감염되었을 경우 추후 정신질환으로 진단될 위험이 높아지는지 살펴보았습니다.',
          <br />,
          <br />,
          '그 결과 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '코로나19에 감염된 경우, 진단 후 14-90일 이내에 정신질환을 진단받을 확률은 18.1%',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '로 매우 높게 예측되었습니다. 이는 단순 독감 및 피부 감염 등의 비교적 일상적인 질환으로 진단된 후 정신질환에 이환될 확률보다 1.24-1.49배 높은 수치입니다. 코로나19에 감염된 사람들이 가장 많이 진단된 정신질환은 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['불안장애'],
        bold: true,
        numbering: false,
      },
      {
        text: ['와 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['기분장애'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '였으며, 진단 비율은 각각 12.8%, 9.9%로 계산되었습니다. 코로나19는 불면증과 치매에 이환될 확률 또한 높이는 것으로 나타났습니다. 더 나아가 코로나19 확진자는 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '이전에 앓았던 정신질환이 재발할 확률이 일반 대중에 비해 상당히 높았습니다.',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '옥스퍼드 대학교 연구팀이 도출한 결과는 우리에게 굉장히 중요한 사실을 일깨우고 있습니다. 바로 코로나19 확진자의 세심한 정신건강 돌봄 및 관리가 필수적이라는 것이지요. 우리 정부와 사회는 코로나19가 정신건강에 미치는 영향을 유념하면서 확진자의 신체건강뿐만 아니라 정신건강까지 전체적으로 잘 살피기 위한 제도적 장치를 마련할 필요가 있겠습니다. ',
          <br />,
          <br />,
          '코로나가 기승을 부리는 요즘 몸 조심, 마음 조심하세요!',
          <br />,
          <br />,
          '[1] Taquet, Maxime & Luciano, Sierra & Geddes, John & Harrison, Paul. (2020). Bidirectional associations between COVID-19 and psychiatric disorder: retrospective cohort studies of 62 354 COVID-19 cases in the USA. The Lancet Psychiatry. 8. 10.1016/S2215-0366(20)30462-4. ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: [<br />, <br />, '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.'],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 9,
    image: mh7,
    category: '건강한 마음을 위한 지침서',
    title: '좋은 스트레스가 있다고요?',
    subtitle: '부정적으로만 생각했던 우리의 감정들: 여키스-도슨 법칙',
    texts: [
      {
        text: [
          '스트레스는 바쁜 현대 사회에서 우리 삶과 떼려야 뗄 수 없는 관계가 되었습니다. 우리는 대체적으로 스트레스를 부정적인 맥락에서 생각하는 경향이 있습니다. 업무가 마음대로 잘 풀리지 않거나 힘든 일이 있으면 스트레스를 받는다고 느끼고, 이를 맛있는 음식을 먹거나 휴식을 취하면서 해소하려고 노력하지요. 하지만 오래 전부터 스트레스를 연구해온 학자들에 따르면, 스트레스라고 모두 나쁜 것만은 아니라고 합니다. ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '좋은 스트레스(eustress)',
        bold: true,
        numbering: false,
      },
      {
        text: ['와 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['나쁜 스트레스(distress)'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '가 존재한다는 것, 알고 계셨나요? ',
          <br />,
          <br />,
          '우선 스트레스(stress)라는 용어는 ‘팽팽하게 조이다’라는 의미를 지니는 라틴어 ‘stringer’에서 기원하는 것으로 알려져 있습니다. 이처럼 스트레스는 우리의 정신적 혹은 육체적 균형과 안정을 깨뜨리는 외부 자극이 들어오면 이에 반응하여 기존의 평형상태를 유지하려는 힘이라고 할 수 있습니다. 다시 말해 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['스트레스란 변화하는 환경에 적응하는 반응'],
        bold: true,
        numbering: false,
      },
      {
        text: ['이라는 것이지요.', <br />, <br />, '그렇다면 어떤 스트레스가 좋은 스트레스일까요? '],
        bold: false,
        numbering: false,
      },
      {
        text: '좋은 스트레스란 스트레스를 경험함으로써 향상된 수행능력이나 개인의 성장과 같이 긍정적인 결과가 초래되는 것',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '을 말합니다. 예를 들어 직장에서의 적당한 긴장과 각성은 강한 성취욕과 목표의식을 자극해 진급이라는 긍정적인 결과의 원동력으로 작용할 수 있습니다. 오히려 현재 상태에 대해서 그 어떠한 스트레스도 느끼지 않는다면 자신의 역량을 키우고 발전하는 데에 방해요인으로 작용할 수 있겠죠? 반대로 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '나쁜 스트레스',
        bold: true,
        numbering: false,
      },
      {
        text: '는 ',
        bold: false,
        numbering: false,
      },
      {
        text: '저조한 수행이나 실패와 같은 부정적인 결과를 초래하는 것',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '을 말합니다. 실직, 사별, 이혼 등에서 기인하는 스트레스는 우리가 맡은 임무를 다하고 일상생활을 영위하는 것을 방해할 것입니다.',
          <br />,
          <br />,
          '더 나아가, 미국의 심리학자인 로버트 여키스(Robert Yerkes)와 존 도슨(John Dodson)은 스트레스의 수준과 수행능력 간에 흥미로운 상관관계가 존재한다고 말합니다. 이들이 정립한 여키스-도슨 법칙(Yerkes-Dodson Law)에 따르면, ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '스트레스와 수행능력 사이에는 거꾸로 된 U자의 형태를 나타내는 관계',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '가 존재합니다. 이 법칙은 일정 수준에 이르기까지 생리적 및 정신적 각성이 일정 수준에 이르기까지 증가할수록 수행능력이 향상한다는 것을 말하고 있습니다. 반대로 스트레스의 수준이 지나치게 높아지면 수행능력을 오히려 해치는 방향으로 작용합니다.',
          <br />,
          <br />,
          '여키스와 도슨은 미로 상자에 실험 쥐를 풀어놓은 뒤 다양한 강도의 전기 자극을 주어 쥐가 출구를 얼마나 빠르게 찾는지 알아보는 실험을 했습니다. 그 결과 매우 약한 전기 자극에는 쥐가 출구를 찾기 위해 천천히 다녔지만 자극의 강도가 세질수록 눈에 뛰게 민첩해졌다고 합니다. 그러나 전기 자극을 매우 강하게 주니 쥐는 극심한 두려움에 사로잡혀 이전보다 현저히 수행능력이 떨어진 모습을 보였습니다. 이들 심리학자의 실험 결과를 통해 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '최고의 성과를 내기 위해서는 너무 낮거나 높지도 않은 적절한 수준의 스트레스가 필요',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '하다는 것을 알 수 있습니다.',
          <br />,
          <br />,
          '사람마다 ‘적절하다’고 여겨지는 스트레스의 수준은 각기 다를 것입니다. 그렇지만 좋은 스트레스는 최대한 활용하고 나쁜 스트레스는 자신에게 맞는 건강한 방법으로 해소하는 것은 좋은 성과를 내고 발전하는 삶을 살아가기 위해 필요할 것입니다. 여러분께서는 어떤 방식으로 스트레스를 해소하고 계신가요? 여러분을 동기부여 시키는 것이 아니라 지치게 만드는 나쁜 스트레스를 해소하지 못한 채 안고 살아가고 계시진 않은지 자신의 모습을 뒤돌아보는 시간을 가져 보시는 건 어떨까요?',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 10,
    image: mh8,
    category: '정신과 바로 알기',
    title: '정신과 진료를 받으면 공무원 결격사유인가요?',
    subtitle: '정신과 바로알기',
    texts: [
      {
        text: [
          "사람들은 왜 정신과 진료를 주저할까요? 한 연구에 따르면 60대 이상을 제외한 모든 세대에서 '제도적 불이익'과 '사회적 인식' 때문에 정신과 진료를 주저하는 것으로 나타났습니다. 특히 취업준비생이 많은 2-30대는 제도적 불이익에 더욱 민감하게 반응하기도 했습니다.[1]",
          <br />,
          <br />,
          '많은 취업준비생들이 공공기관이나 사기업에서 개인 진료기록을 열람할 수 있다고 생각하여 정신과 진료를 주저하고 있습니다. 특히 공무원을 준비하는 분들께서 이러한 부분에서 걱정이 많으시기도 합니다. 조금만 인터넷을 검색해보면 아래와 같은 질문들이 많은 것을 알 수 있습니다.',
          <br />,
          "   '불면증 때문에 정신과 진료를 받았는데 공무원 채용 과정에서 불이익이 있나요?'",
          <br />,
          "   '시험 준비하다가 마음이 조금 불안하여 정신과에 갔던 적이 있는데 공무원이 되는 데에 제한이 있나요?'",
          <br />,
          '이번 글에서는 실제로 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '정신과 진료가 공무원 결격사유에 해당하는지 ',
        bold: true,
        numbering: false,
      },
      {
        text: ['살펴보도록 하겠습니다.', <br />, <br />, '가장 먼저 짚고 넘어가야 할 부분은 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['공무원채용신체검사의 시점'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '입니다. 정신 및 신체 장애의 정도가 공무원으로서 행정업무를 수행하는 데에 지장이 있지 않은지 확인하는  ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['공무원채용신체검사는 최종합격자 발표 이후에 진행'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '됩니다. 즉 이미 최종합격자를 정한 뒤에 검사를 하기 때문에 지원자가 면접 시 정신과 진료 이력에 대해 직접 밝히지 않는 한 면접위원은 이에 대한 사실을 알 수 없을 뿐더러 그에 따른 불이익을 줄 수도 없습니다.',
          <br />,
          <br />,
          '물론 최종합격자 발표 이후 시행되는 공무원채용신체검사 중에는 정신질환 여부를 묻는 항목이 존재하긴 합니다. 하지만 정신건강복지법에 따르면 ‘정신질환자’란 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '‘망상, 환각, 사고나 기분의 장애 등으로 인하여 독립적으로 일상생활을 영위하는 데 중대한 제약이 있는 사람’',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '으로 명확하게 정의되고 있습니다. 다시 말하자면, 정신질환이란 정신과에서 진료 받은 모든 질환을 일컫는 용어가 아니라 망상, 환각, 사고 또는 기분의 장애 등으로 독립적인 일상생활에 중대한 제약을 초래하는 질환에 국한됩니다. 따라서 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '취업 스트레스로 인한 불안 또는 우울 증세로 몇 개월간 치료 받은 적',
        bold: true,
        numbering: false,
      },
      {
        text: '이 있거나 ',
        bold: false,
        numbering: false,
      },
      {
        text: '학창시절 ADHD로 정신과 진료를 받은 경험',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '이 있는 등 정신건강복지법에서 명시하는 정신질환을 앓은 병력이 없다면, ‘정신질환을 앓은 적이 있느냐’는 질문에 떳떳하게 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '‘아니오’라고 답할 수 있는 것',
        bold: true,
        numbering: false,
      },
      {
        text: ['입니다.', <br />, <br />],
        bold: false,
        numbering: false,
      },
      {
        text: '<경찰공무원 채용 Q&A 모음집>',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '에 따르면 공무원채용신체검사 규정에 따라 업무수행에 큰 지장을 미치는 정신지체 및 정신병은 신체검사 불합격 판정기준이 될 수 있으나, ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '치료가 완료된 질환이나 단순 불면증 등의 정도는 신체검사 불합격 기준에 포함되지 않는다',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '고 명시되어 있습니다. 아울러 정신과 진료기록이 있는 것 자체는 국가공무원법 및 경찰공무원법이 규정하는 결격사유에 해당되지 않으며, 현재 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '경찰청에서는 진료기록에 대한 정보를 제공받지 않는다',
        bold: true,
        numbering: false,
      },
      {
        text: ['고 말하고 있습니다.', <br />, <br />, '따라서 '],
        bold: false,
        numbering: false,
      },
      {
        text: '업무수행에 큰 지장을 야기하는 정신 계통의 질병만이 공무원채용신체검사의 불합격 기준',
        bold: true,
        numbering: false,
      },
      {
        text: '으로 규정되고 있습니다. 한 마디로 ',
        bold: false,
        numbering: false,
      },
      {
        text: '단순 불면증이나 가벼운 우울증 정도로 채용에 불이익이 생기지는 않는다',
        bold: true,
        numbering: false,
      },
      {
        text: '는 의미입니다. 하지만 안타깝게도 2, 30대의 젊은 연령층에서는 정신과 진료기록이 ',
        bold: false,
        numbering: false,
      },
      {
        text: '취업 및 임용에 불리하게 작용한다는 오해 때문에 적절한 치료시기를 놓쳐 오히려 병을 키우게 되는 경우',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '가 적지 않습니다.',
          <br />,
          <br />,
          '이 글을 통해 살펴보았듯이 많은 분들의 생각과는 다르게 정신과 진료 그 자체는 공무원 결격사유에 해당하지 않으며 당사자 본인의 동의 없이는 채용기관에서 진료기록을 절대로 열람할 수도 없습니다. 공무원 임용에 대한 우려로 정신과 진료를 주저하고 계셨다면 정신과에 내원하셔서 필요한 도움을 받으시길 바랍니다.',
          <br />,
          <br />,
          '[1] 사람들은 왜 ‘정신과’에 가지 않을까: 정신건강서비스 이용 장벽의 현황 (서울대학교병원, 박지은 교수, 제 6회 지역사회 정신건강과 커뮤니티케어 정책 세미나',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 11,
    image: mh9,
    category: '정신과 바로 알기',
    title: '정신과 진료기록이 있으면 취업에 악영향을 미치나요?',
    subtitle: '정신과 진료기록과 취업',
    texts: [
      {
        text: [
          '기업 공채 시즌이 다가오면 온라인의 각종 취업 관련 커뮤니티에는 정신과 진료를 받은 경험이 있는 경우 취업하기가 어려운지 문의하는 글들이 올라오기 시작합니다. 이와 관련하여 서울대병원 정신건강의학과 박지은 교수가 진행한 연구에 따르면 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '젊은 연령층에서 정신과 진료의 진입장벽',
        bold: true,
        numbering: false,
      },
      {
        text: ['으로 가장 크게 작용하는 것은 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['제도적 불이익에 대한 두려움'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '이라고 합니다. 아마도 젊은 연령층에서 취업을 준비하는 사람들이 가장 많기 때문이겠지요. 대다수의 기업은 채용시 지원자에게 개인정보 제공 및 수집을 위한 동의를 요구하는데, 이때 정신과에서 진료를 받은 적이 있는 지원자는 기업 측에서 정신과 진료기록 또한 조회하거나 열람하게 되는 것은 아닌가에 대해 두려움을 가지는 경우가 많습니다.',
          <br />,
          <br />,
          '그러나 많은 분들께서 우려하시는 것과 달리 정신과 진료기록은 암호화된 채로 진료를 본 담당 의사의 컴퓨터 하드드라이브에만 저장되며, 환자 본인 및 담당 의사 이외의 다른 사람들이 이러한 정보를 함부로 열람하거나 유출하면 법적인 처벌을 받게 됩니다. ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['정신과 진료기록'],
        bold: true,
        numbering: false,
      },
      {
        text: ['은 간호사를 비롯한 병원 직원, 그리고 심지어 담당 의사를 제외한 다른 의사조차도 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['마음대로 열람할 수가 없습니다.', <br />, <br />, '사기업'],
        bold: true,
        numbering: false,
      },
      {
        text: ['뿐만 아니라 '],
        bold: false,
        numbering: false,
      },
      {
        text: '정부 기관',
        bold: true,
        numbering: false,
      },
      {
        text: '조차도 ',
        bold: false,
        numbering: false,
      },
      {
        text: '지원자의 정신과 수진이력 및 진료기록을 함부로 열람할 수가 없으며',
        bold: true,
        numbering: false,
      },
      {
        text: [
          ', 이를 위반할 시에는 개인정보보호법에 의해 처벌을 받게 됩니다. 이는 기업이 채용 과정에서 개인정보 제공 및 수집에 대한 동의를 받은 상황에서도 마찬가지로 적용됩니다. 즉 기업에서 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '지원자의 진료기록을 조회하기 위해서는 지원자의 신분증 및 위임장이 반드시 필요',
        bold: true,
        numbering: false,
      },
      {
        text: ['하며, 의료기관에서 기업으로 '],
        bold: false,
        numbering: false,
      },
      {
        text: '진료기록을 전달하는 과정에서도 지원자의 동의를 별도로 확보',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '해야 합니다. 따라서 이러한 일련의 동의 및 위임 과정을 거치지 않고 지원자 개인의 진료기록을 임의로 열람하거나 유출하면 법적인 처벌이 내려지게 됩니다.',
          <br />,
          <br />,
          '한편 기업에서 자체적으로 시행하는 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '인적성검사 ',
        bold: true,
        numbering: false,
      },
      {
        text: ['또는 '],
        bold: false,
        numbering: false,
      },
      {
        text: '신체검사',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '에 지원자의 정신 상태를 파악하기 위한 문항이 포함되어 있는 경우도 적지는 않습니다. 그러나 이는 정상적인 사회생활을 영위하는 것이 불가능하거나 범죄를 저지른 경력이 있는 지원자를 선별하기 위한 목적을 지닌다고 볼 수 있겠습니다. 기업은 지원자의 진료이력을 조회할 수 있는 권한이 없으므로, 입사 지원 시 제출하는 개인정보 제공 및 수집 동의서 또는 주민등록번호를 통해 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '기업이 지원자의 진료이력에 접근할 수 있는 방법은 없습니다. ',
        bold: true,
        numbering: false,
      },
      {
        text: '따라서 ',
        bold: false,
        numbering: false,
      },
      {
        text: '정신과 진료기록 자체는 취업에 어떠한 영향도 미치지 못합니다. ',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '많은 취업준비생 분들의 걱정과는 달리 채용하는 기업에서 임의로 진료기록을 조회하는 일은 절대로 있을 수 없습니다. 취업 불이익에 대한 막연한 불안감 때문에 정신과 진료를 받아야 하나 고민하고 계셨다면 이 글이 많은 도움이 되셨기를 바랍니다.',
          <br />,
          <br />,
          '마음이 힘든 와중에도 취업 준비를 하시느라 고생하는 많은 분들께, 어려운 시기에 취업 준비를 하느라 마음이 힘들어진 많은 분들께, 인사이드팀이 응원의 메세지를 보냅니다.',
          <br />,
          <br />,
          '오늘도 고생 많으셨습니다. 내일도 힘내시길 바랍니다!',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 12,
    image: mh10,
    category: '우울증과 조울증의 모든 것',
    title: '나는 우울증일까?',
    subtitle: '우울감 vs. 우울증, 뭐가 다른가요?',
    texts: [
      {
        text: [
          '우울감은 지극히 정상적인 감정입니다. 살아가면서 우울한 기분을 한 번도 경험해보지 못한 사람은 없을 것입니다. 오랜 시간 동안 고대하던 취업에 성공하거나 결혼기념일을 맞이해서 여행을 떠나면 행복하고 기쁜 것이 당연한 것처럼, 열심히 노력했던 일이 잘 풀리지 않거나 주변 사람들과 다툼이 있어 날카로운 말을 주고 받는 일이 생긴다면 슬프거나 우울한 기분을 느끼게 됩니다. 그렇다면 우울감을 경험하는 사람들은 모두 우울증이 있는 것일까요? 이번 글에서는 일반적인 우울감과 우울증의 차이를 살펴보도록 하겠습니다.',
          <br />,
          <br />,
          '먼저 일반적인 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '우울감',
        bold: true,
        numbering: false,
      },
      {
        text: ['은 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['짧은 기간 동안 지속'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '된다는 특징을 가지고 있습니다. 어떤 사람들은 ‘우울감이 하루 동안 추운 날씨라면 우울증은 겨울’이라고 표현하기도 합니다. 이처럼 우울감은 그 기분을 유발했던 일이 해결되거나 시간이 조금 지나면 해결되는 반면, ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['우울증'],
        bold: true,
        numbering: false,
      },
      {
        text: ['은 '],
        bold: false,
        numbering: false,
      },
      {
        text: [' 우울한 기분이 하루 동안 지속되는 일이 2주 이상 나아지지 않는 경우'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '를 말합니다. 일반적인 경우라면 우울한 기분을 툭툭 털어낼 수 있지만, 이러한 기분이 2주 이상 지속되고 의욕, 사고를 포함하여 일상생활 전반에 걸친 변화가 일어난다면 우울증이라고 부를 수 있다는 것입니다.',
          <br />,
          <br />,
          '또한 일반적인 우울감을 경험하고 있는 경우에는 기분이 주변 환경에 따라서 좋아질 수 있습니다. 예를 들어, 중요한 시험을 생각보다 잘 못 보아서 한동안 우울하다가도 친구와 함께 수다를 떨고 웃으면 또 기분이 풀어질 수가 있겠죠. 반면 우울증에서 나타나는 우울감은 주변 환경에 크게 영향을 받지 않으며 좀처럼 호전되지 않습니다. 좋은 일이 있어도 크게 기쁘다는 느낌이 들지 않고 만사에 의욕이 없는 상태로 유지됩니다.',
          <br />,
          <br />,
          '일반적인 우울감과 우울증의 또 다른 차이점은 바로 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '일상 생활에 지장을 일으키는가 여부',
        bold: true,
        numbering: false,
      },

      {
        text: [
          '입니다. 일반적인 우울감이라면 힘들긴 해도 맡은 임무를 수행하거나 일생 생활에 영위하는 데 큰 문제가 없습니다. 반대로 우울증에서는 하루 종일 지속되는 우울한 기분, 의욕 및 집중력 저하, 수면 문제 등으로 인해 일상적인 활동을 해내는 데에 큰 어려움을 느끼게 됩니다.',
          <br />,
          <br />,
          '일반적인 우울감과 우울증은 비슷한 것 같으면서도 그 사이에는 굉장히 중요한 차이점들이 존재하는 것을 알 수 있습니다. 우울증에서 나타나는 우울감 및 의욕 저하는 단순히 주변 환경의 변화나 ‘마음먹기’에 따라 해결할 수 있는 것이 절대 아닙니다. 치료와 꾸준한 관심이 필요한 질환입니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 13,
    image: mh11,
    category: '정신과 바로 알기',
    title: '정신과 진료, 기록이 남나요?',
    subtitle: '정신과 진료, 기록 때문에 망설이고 계신가요?',
    texts: [
      {
        text: [
          '정신과 진료, 기록 때문에 받지 못하고 계신가요?',
          <br />,
          <br />,
          "우리나라의 평생 정신질환 유병률은 25.4%에 달하며, 우리나라는 2013년부터 2019년까지 2017년 단 한 해를 제외하고 모든 해에서 OECD 국가 중 자살률 1 위를 기록한 바 있습니다. 반면 정신건강서비스의 평생 이용률은 22.2%로, 1 년 이용률이 30-40%에 달하는 다른 국가에 비해 현저히 낮은 경향을 보입니다. 이처럼 도움이 필요한 사람들이 많지만 쉽사리 도움을 구하지 못하고 있는 상황의 원인으로는 '제도적 불이익'과 '사회적 인식'에 대한 염려가 있습니다. 특히 그중에서도 정신과 기록에 대한 우려가 가장 큰 원인으로 작용하고 있는 상황입니다.[1]",
          <br />,
          <br />,
          '이처럼 정신과 기록에 대한 오해와 두려움은 정신과 진료의 큰 장벽으로 작용하고 있습니다. 일상생활이 불가능할 정도의 우울 혹은 불안에 시달리면서도 정신과 진료를 받게 되면 기록이 남아 훗날 입시 및 취업 등에서 불이익이 발생할까 걱정되어 진료실의 문턱을 쉽사리 넘지 못하고 있는 것이지요.',
          <br />,
          <br />,
          '이번 글에서는 정신과 진료를 받았을 때 기록이 남는지, 또 누가 열람할 수 있는지 살펴보도록 하겠습니다.',
          <br />,
          <br />,
          '자, 그럼 하나씩 살펴볼까요?',
          <br />,
        ],
        bold: false,
        numbering: false,
      },
      {
        text: 'Q: 정신과 진료를 보면 진료기록이 남지 않나요?',
        bold: true,
        numbering: true,
      },
      {
        text: [
          'A: 환자가 병원에 내원하여 일련의 검사 및 상담을 하고 약을 처방받는 등의 진료를 받으면 의사는 의료법에 따라 진료기록을 작성하고 보관해야 합니다. 이러한 자료는 전자의무기록(EMR)이라는 소프트웨어에 기록 및 저장됩니다. 저장된 진료기록은 진료를 본 의사만 열람할 수 있도록 EMR에 암호화되고 컴퓨터 하드디스크에 암호화된 채로 저장됩니다. 최근 들어서는 의무기록을 하드디스크에 저장하지 않고 온라인 서버에 저장하는 EMR들도 많지만, 이러한 EMR 서비스들 또한 엄격한 기준에 따라 암호화하여 정보를 저장하고 있습니다. 결국 정신과 진료를 보면 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['진료기록은 남지만 '],
        bold: true,
        numbering: false,
      },
      {
        text: ['기록 자체는 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['암호화되어 병원의 EMR 에만 저장'],
        bold: true,
        numbering: false,
      },
      {
        text: ['되며 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['담당 의사만이 이를 열람'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '할 수 있습니다. 의사가 환자 본인의 동의 없이 진료기록을 공개하는 경우에는 법률에 의거하여 처벌을 받게 됩니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: 'Q: 병원 직원도 진료기록을 열람할 수 있나요?',
        bold: true,
        numbering: true,
      },
      {
        text: ['A: '],
        bold: false,
        numbering: false,
      },
      {
        text: '없습니다 ',
        bold: true,
        numbering: false,
      },
      {
        text: ['간호사는 물론이고 담당 의사 외의 '],
        bold: false,
        numbering: false,
      },
      {
        text: '다른 의사조차도 전체 진료기록을 볼 수 없도록 ',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '되어 있습니다. 각 직원은 자신의 업무를 수행하기 위해 필수적인 내용에 한해서만 기록을 열람할 수 있는 권한이 부여됩니다. 병원 직원이 볼 수 있는 내용은 진료비가 얼마 나왔는지, 또는 환자가 어떤 약을 복용하는지 정도이며, 어떠한 형태로든 진료기록을 접하게 되는 모든 관련자는 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '업무상 비밀누설에 관련된 법률을 어기면 처벌',
        bold: true,
        numbering: false,
      },
      {
        text: ['을 받습니다.'],
        bold: false,
        numbering: false,
      },
      {
        text: 'Q: 국민건강보험공단에서는 진료기록을 열람할 수 있지 않나요?',
        bold: true,
        numbering: true,
      },
      {
        text: [
          'A: 많은 분들께서 정신과 진료에 대한 기록이 보험공단에 남는 것이 두려워 진료비를 비보험으로 처리할 것을 요청하시기도 합니다. 그러나 정신과 진료 대부분은 보험 진료이기 때문에 보험공단에 청구되는 진료비에 대한 근거를 제공하기 위해 보험공단에 진료 자료를 전송해야 하는 것은 맞지만, ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '오로지 진료비 청구에 필요한 병명, 투약 및 수납내역만이 전송',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '됩니다. 전체적인 상담 내용 및 진료 과정에 대한 부분은 담당 의사의 컴퓨터를 떠나지 않습니다. 환자가 보험공단 사이트에 공인인증서로 로그인하면 본인이 받아온 진료내역을 조회할 수 있지만, ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '정신건강의학과 진료기록을 비롯한 민감한 개인 정보가 포함된 기록들',
        bold: true,
        numbering: false,
      },
      {
        text: ['은 본인마저도 쉽게 조회할 수 없습니다. 이는 '],
        bold: false,
        numbering: false,
      },
      {
        text: '본인이 직접 보험공단에 방문했을 때만 조회, 발급',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '할 수 있습니다.',
          <br />,
          <br />,
          '지금까지 알아보았듯이 정신과 진료기록은 환자 본인과 담당 의사를 제외하면 아무도 열람할 수 없습니다. 기록에 대한 걱정 때문에 정신과 진료를 주저하고 계셨다면 더 이상 힘든 마음을 참지 마시고 가까운 의원에 내원하셔서 필요한 도움을 받으시길 바랍니다.',
          <br />,
          <br />,
          '[1] 사람들은 왜 ‘정신과’에 가지 않을까: 정신건강서비스 이용 장벽의 현황 (서울대학교병원, 박지은 교수, 제6회 지역사회 정신건강과 커뮤니티케어 정책 세미나)',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 14,
    image: mh12,
    category: '정신과 바로 알기',
    title: '정신과 진료 기록이 있으면 보험 가입이 힘든가요?',
    subtitle: '정신과 진료기록과 보험가입 문제',
    texts: [
      {
        text: [
          '정신과 진료와 관련하여 가장 많이들 여쭤보는 주제가 무엇일까요? 바로 보험입니다.',
          <br />,
          <br />,
          '많은 분들께서 정신과 진료를 망설이는 가장 큰 이유 중 하나는 바로 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '정신과 진료기록이 있으면 보험 가입이 불가능하다는 생각',
        bold: true,
        numbering: false,
      },
      {
        text: [
          "입니다. 인터넷 검색을 하다 보면 정신질환을 앓은 적이 있다는 사실을 밝혔다가 보험 판매원으로부터 보험 가입을 거절당한 사례를 종종 발견할 수 있습니다. 어떤 의사들은 '차라리 보험 가입 시 정신과 진료 경험에 대한 사실을 밝히지 마라'는 말을 하기도 하지요. 정신과 진료와 보험, 대체 어떻게 해야 하는 것일까요?",
          <br />,
          <br />,
          '저희는 정신과 진료 유경험자의 보험 가입과 관련하여 객관적인 정보를 전해드리기 위해 2010년 3월 대한신경정신의학회에서 발간한 <민간보험 가입에 차별 받는 환자에 대한 대책>이라는 책자의 내용을 소개하고자 합니다.',
          <br />,
          <br />,
          '대한신경정신의학회는 보험가입 시 환자 및 보호자가 알아야 할 10가지로 다음 내용을 명시하였습니다:',
          <br />,
          <br />,
          '1. 정신질환을 앓고 있다는 이유만으로 보험가입에 차별받지는 않습니다. (다만, 심각한 신체질환에서 보험가입이 거절되는 경우와 유사한 이유로 보험가입이 제한 되는 경우는 있습니다.)',
          <br />,
          <br />,
          '2. 정신과 치료 경력을 숨기지 마십시오. (고지 의무 위반으로 오히려 손해를 보실 수 있습니다.)',
          <br />,
          <br />,
          '3. 가입하려는 보험상품의 명칭과 보험회사를 명확히 확인해 놓으시고, 보험 모집원 등의 명함을 받아 놓으십시오.',
          <br />,
          <br />,
          '4. 단순히 정신질환을 앓고 있다는 이유만으로 보험 가입이 거절될 경우에는, 본 안내문의 내용을 보험 모집원에게 읽어 주십시오.',
          <br />,
          <br />,
          '5. 보험 모집원이 보험 가입을 거절하는 경우, 가입이 제한되는 이유를 문서로 작성 해 줄 것을 요구하십시오.',
          <br />,
          <br />,
          '6. 보험 모집원의 판단에 의한 가입 제한이 아니라, 보험회사의 공식적인 가입 심사를 받게 해 줄 것을 요구하십시오.',
          <br />,
          <br />,
          '7. 보험회사에서 공식적인 가입 거절 이유를 밝히기를 거부할 때에는, 환자 혹은 보호자께서 직접 그 내용을 문서로 남기십시오.',
          <br />,
          <br />,
          '8. 뚜렷한 이유 없이 가입이 거절되는 경우 감독기관에 민원(진정)을 하시거나, 분쟁 상담을 하십시오.',
          <br />,
          <br />,
          '9. 보험상품과 보험회사에 따라 가입 기준에 차이가 있을 수 있습니다. 다른 상품이나 다른 회사의 상품에 가입하는 것도 고려해 보십시오.',
          <br />,
          <br />,
          '10.  보다 구체적인 문의사항이 있으신 경우 담당 주치의 선생님과 상의하십시오.',
          <br />,
          <br />,
          '단순히 정신질환을 앓고 있거나 정신과에서 진료를 받은 경험이 있다는 이유만으로 보험 가입을 거절하는 것은 정당하지 못한 차별입니다. 우선 보험 가입 거절은 보험회사 차원의 공식적인 의사결정이 아닌 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['보험 판매원의 자체적인 판단'],
        bold: true,
        numbering: false,
      },
      {
        text: ['에 불과하거나 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['적법하지 못한 거절'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '일 가능성이 높습니다. 따라서 정신질환을 앓고 있거나 앓았던 적이 있다는 내용을 고지하였을 때 보험 판매원이 가입을 거절한다면, 위에 명시되어 있는 것과 같이 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['가입이 제한되는 이유를 서면으로 작성 '],
        bold: true,
        numbering: false,
      },
      {
        text: ['받고 '],
        bold: false,
        numbering: false,
      },
      {
        text: '보험회사의 공식적인 가입 심사를 받을 수 있도록 요청',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '하시는 것이 좋습니다.',
          <br />,
          <br />,
          '보험회사 차원에서 뚜렷한 이유 없이 보험 가입을 거절하는 경우도 있습니다. 가입 요청자가 앓고 있는 정신질환의 정확한 진단명이나 현재 상태는 고려하지 않은 채, 정신질환의 병력이 있다는 이유만으로 자살에 대한 사망위험 가능성이나 재해사고에 대한 가능성이 높아진다는 판단을 내리기도 합니다. 하지만 정신질환의 병력이 있는 가입자 또한 여느 질환과 마찬가지로 진단명, 경과기간, 중증도 및 치료 반응 등에 의거하여 가입 여부가 결정되어야만 합니다. 그러므로 대한신경정신의학회는 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '뚜렷한 이유 없이 가입이 거절되는 경우',
        bold: true,
        numbering: false,
      },
      {
        text: ['에는 '],
        bold: false,
        numbering: false,
      },
      {
        text: '국가인권위원회, 금융민원센터, 보험소비자연맹 ',
        bold: true,
        numbering: false,
      },
      {
        text: ['등의 '],
        bold: false,
        numbering: false,
      },
      {
        text: '감독기관에 분쟁 상담',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '을 요청하는 것을 권고합니다. 또한 보험 상품과 보험사에 따라서 가입 기준에 차이가 존재할 수 있으므로 다른 상품이나 회사의 상품에 가입하는 것도 고려하는 것을 제안하고 있습니다.',
          <br />,
          <br />,
          '다만, 대한신경정신의학회는 보험 가입 신청 시 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '정신과 치료 경력을 숨기지 말라',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '고 말합니다. 실제로 보험사는 가입자의 동의 및 위임 없이는 의료기관에 수진이력 및 진료기록의 열람을 신청할 수 없으며, 의료기관 또한 별도로 가입자의 동의를 얻지 않고는 이러한 자료를 보험사에 전달하는 것이 불가능합니다. 따라서 보험 가입 신청서에 정신질환을 앓거나 정신과 진료를 받았던 이력을 공개하지 않는다면 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '보험사가 이러한 내용에 대해서 알 수 있는 방법은 없습니다. ',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '그러나 정신과 치료 이력을 고의로 은폐하는 것은 고지 의무 위반으로 추후에 오히려 손해를 불러일으킬 수 있습니다. 이는 정신질환뿐만 아니라 고혈압, 간질환, 당뇨 등 다른 신체질환의 병력이 있는 가입자에게도 동일하게 적용되는 사항이며, 병력을 정확히 명시하지 않거나 병력과 관련하여 정확한 정보 및 상태를 파악할 수 없는 경우에는 부득이하게 보험 가입이 어려울 수 있습니다.',
          <br />,
          <br />,
          '인터넷이나 뉴스 매체 등을 통해 접할 수 있는 분들의 이야기처럼 정신과 수진이력이 있는 사람은 없는 사람에 비해 민간 보험에 가입하는 데에 많은 어려움과 차별을 겪는 것은 사실입니다. 인사이드 팀은 이러한 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '정신질환자에 대한 차별과 경직된 시선이 우리 사회가 앞으로 해결해 나가야 하는 중대한 과제이며 의무',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '로 여기고 있습니다. 인사이드 팀 또한 진료를 받는 분들께서 보험 가입에 불이익을 받지 않도록 부단히 노력하겠습니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 15,
    image: mh13,
    category: '정신과 바로 알기',
    title: '정신과에서는 어떠한 치료를 하나요?',
    subtitle: '정신과의 다양한 치료 방법',
    texts: [
      {
        text: [
          '인터넷에서 정신건강 관련 커뮤니티를 살펴보면 ‘저는 정신과 진료를 받아야 할까요, 아니면 상담센터에 가봐야 할까요’라는 내용의 질문을 굉장히 쉽게 찾아볼 수 있습니다. 이러한 질문에 가장 흔히 돌아오는 답변은 ‘정신과는 약만 처방해주고, 상담을 원하면 상담센터에 가야 한다’는 것입니다. 정신과에 내원해본 경험이 없다면 정신과에서 어떠한 치료들이 이루어지는지 잘 알지 못하는 상태이기에 이러한 고민을 하는 것이 당연해 보이기도 합니다.',
          <br />,
          <br />,
          '하지만 대중적인 생각과는 달리 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '정신과는 약만 처방해 주는 곳이 아닙니다. ',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '정신과 치료에서 약물 치료가 커다란 비중을 차지하는 것은 사실이지만 증상 및 진단에 따라서 다양한 비약물적 치료 방법들이 활용되고 있습니다. 실제로 정신과에서 약물 치료만 단독으로 시행하는 일은 거의 없으며, 보통은 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['약물치료를 상담치료 및 심리치료와 병행하는 것이 일반적'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '입니다. 이번 글에서는 대표적인 약물 및 비약물적 치료에는 어떠한 것들이 있는지 간단히 소개해드리고자 합니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['약물치료'],
        bold: true,
        numbering: true,
      },
      {
        text: [
          '정신과에서 처방하는 약물은 대부분 뇌의 신경전달물질에 작용합니다. 여기서 말하는 신경전달물질이란 세로토닌이나 도파민처럼 뇌에서 분비되어 감정, 인지, 사고를 비롯하여 전반적인 정신 상태에 영향을 주는 물질을 일컫습니다. 우울증을 포함한 다양한 정신질환에서 신경전달물질의 불균형이 질병의 원인으로 밝혀져 있으며, 이러한 불균형을 교정해줄 수 있는 다양한 약물들이 오랜 사용의 역사를 거쳐 그 효과를 입증 받아 왔습니다.',
          <br />,
          <br />,
          '우울한 기분을 치료하기 위한 항우울제 중 선택적 세로토닌 재흡수 억제제(SSRI)가 가장 널리 활용되고 있습니다. SSRI에는 플루옥세틴(프로작), 파록세틴(팍실), 에스시탈로프람(렉사프로) 등 다양한 약물이 존재하며, 우울증에 대한 효과는 비슷하지만 각기 다른 약리학적 특성 및 부작용을 가지고 있습니다. 따라서 정신과 전문의는 환자 개개인의 특성에 따라서 같은 SSRI 약물 내에서도 다양한 약물과 용량을 처방하게 되며, 효과 및 부작용을 확인하는 정기적인 상담 치료를 통해 약물을 조절합니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['심리치료'],
        bold: true,
        numbering: true,
      },
      {
        text: [
          '심리치료는 다양한 심리적인 기법들로 하여금 정신적인 어려움을 겪고 있는 내담자가 자신의 문제를 인지하고 바람직한 방향으로 변화할 수 있도록 돕는 치료 방법입니다. 심리치료에도 다양한 기법이 존재하며, 일부 질환에서는 약물 치료와 더불어 최우선으로 고려하는 치료 형태이기도 합니다.',
          <br />,
          <br />,
          '1) 인지행동치료(CBT)',
          <br />,
          '인지행동치료는 잘못된 사고가 행동과 기분의 부정적인 변화를 초래한다는 사실에 전제를 두고 있습니다. 따라서 인지행동치료는 다양한 방법을 통해 환자가 가지고 있는 부정적 혹은 자멸적 사고방식을 최소화하여 우울, 불안 등의 증상을 해소하려는 목적을 지니고 있습니다. 특히 강박장애 및 불안장애에서 효과가 매우 잘 입증되어 있습니다.',
          <br />,
          <br />,
          '2) 정신분석',
          <br />,
          '이는 20세기 초에 지그문트 프로이드가 개발한 방법으로 가장 오래된 심리치료에 해당합니다. 일반적으로 환자가 편안한 자세를 취한 뒤에 마음 속에 떠오르는 모든 생각을 이야기하는 자유 연상의 방식으로 진행됩니다. 정신분석은 과거 대인관계의 패턴이 현재에 어떠한 영향을 미치는지 이해하는 것에 일부 초점을 맞추고 있습니다.',
          <br />,
          <br />,
          '그 외에도 여러 사람이 상호작용을 하면서 치료를 받는 집단 심리상담, 미술 및 음악 치료 등이 있습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '반복적 경두개자기자극술 (rTMS)',
        bold: true,
        numbering: true,
      },
      {
        text: [
          'rTMS는 뇌를 안전하게 자극하는 자기장을 반복적으로 두개골에 통과시켜 뇌의 신경세포를 활성 또는 억제하는 치료 방법입니다. 이는 지금까지 운동 및 인지기능을 개선하기 위한 목적으로 널리 사용되어 왔으나, 최근 우울증을 비롯한 다양한 정신질환에 대해서도 효과가 있는 것으로 밝혀지고 있습니다. 환자에게 고통을 유발하지 않으며 편안하게 의자에 누운 상태로 간편하게 시행할 수 있기 때문에 대학병원이 아닌 정신과 의원에서도 흔히 시행하고 있습니다.',
          <br />,
          <br />,
          '지금까지 정신과에서 시행하는 다양한 치료 방법들에 대해서 살펴보았습니다. 정신과는 약만 처방한다는 편견과는 달리 약물치료와 다양한 비약물치료가 병행되는 경우가 많다는 것을 알 수 있었습니다. 실제 정신과 의원에서 부속 심리상담센터를 운영하여 정신과 전문의 진료와 임상심리사 상담을 동시에 받을 수 있는 곳도 적지 않습니다. 다만 정신건강에 대한 문제는 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '약물이나 상담 하나만으로 해결되지는 않습니다. ',
        bold: true,
        numbering: false,
      },
      {
        text: ['정신과 약물치료와 상담, 그리고 공인된 전문가들이 운영하는 상담센터에서 시행하는 심리상담 등 '],
        bold: false,
        numbering: false,
      },
      {
        text: '다양한 정신건강서비스들을 활용하여 마음의 문제에 대해 접근할 수 있어야 합니다. ',
        bold: true,
        numbering: false,
      },

      {
        text: [
          '인사이드 팀도 객관적이고 세세한 조사를 통해 마음이 힘든 분들께서 보다 더 전문성있고 바람직한 정신건강서비스를 제공받을 수 있도록 최선을 다하겠습니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 16,
    image: mh14,
    category: '정신과 바로 알기',
    title: '정신과는 이상한 사람만 가는 곳 아닌가요?',
    subtitle: '정신과는 언제 방문해야 할까?',
    texts: [
      {
        text: [
          '‘저도 정신과에 가야 하나요?’',
          <br />,
          '‘어떤 경우에 정신과에 가야 하나요?’',
          <br />,
          <br />,
          '다양한 심리적인 어려움을 경험하시는 분들께서 흔히 위와 같은 고민에 빠지시곤 합니다. 인사이드 팀이 224명을 대상으로 시행한 자체 설문조사에 따르면, 일반인 세 명 중 한 명은 ‘정신과 진료를 받기 원했지만 주저했던 적이 있다’고 답했습니다. 또한 정신과 진료를 받아본 사람들은 긴 고민의 시간을 지나 정신과에 처음 방문하기로 결심한 가장 흔한 이유로 ‘증상 악화’를 꼽았습니다. 많은 분들께서 정신과에 쉽게 내원하지 못 하는 것은 정신질환 및 정신과 진료에 대한 사회적인 인식에서 기인하기도 하지만, ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '정신과에서 어떤 질환이나 문제들을 치료하는가',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '에 대한 부족한 이해 또한 커다란 부분을 차지하는 것으로 보입니다.',
          <br />,
          <br />,
          '실제로 뉴스나 인터넷 매체에서 다뤄지는 단편적인 모습만 보고 조현병이나 조울증과 같이 입원 치료를 요하는 질환을 앓고 있는 사람만 정신과에서 치료를 받는다고 오해하기도 합니다. 그러나 조현병이나 조울증으로 진단을 받으신 분들 중 증상이 매우 경하게 나타나는 경우에도 꾸준히 정신과 진료를 받으시는 경우가 많으며, 정신과에서는 이들 질환을 제외하고도 굉장히 다양한 문제들에 대해 근거중심의 의학적 치료를 제공하고 있습니다. 현대사회에서 감기만큼 매우 흔해서 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['‘마음의 감기’로 불리는 우울증'],
        bold: true,
        numbering: false,
      },
      {
        text: ['과 최근 연예인들의 이야기를 통해 많이 알려진 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['공황장애'],
        bold: true,
        numbering: false,
      },
      {
        text: ['는 물론이며, 심하지 않은 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['불안 증세'],
        bold: true,
        numbering: false,
      },
      {
        text: ['나'],
        bold: false,
        numbering: false,
      },
      {
        text: ['가벼운 수준의 수면장애'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '도 정신과의 진료 범위에 속합니다.',
          <br />,
          <br />,
          '요즘에는 정신과에 대한 사회적인 시선이 개선되면서 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '취업, 대인관계에서 비롯되는 스트레스',
        bold: true,
        numbering: false,
      },
      {
        text: ['나'],
        bold: false,
        numbering: false,
      },
      {
        text: '다른 사람에게 쉽게 터놓을 수 없는 고민',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '으로 정신과를 찾는 이용자 수가 점차 증가하는 추세입니다. 정신과 진료를 받는 사람은 정해져 있지 않으며 정신과 치료를 요하는 증상에 대한 명확한 기준 또한 존재하지 않습니다. 바꿔말하면 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: '어떠한 이유로든 정신적인 문제로 인해 정상적인 일상생활을 영위하는 데 어려움을 겪고 있다면 정신과 진료를 통해 도움을 받는 것이 가능',
        bold: true,
        numbering: false,
      },
      {
        text: ['합니다. 아울러 정신과 치료는 약물 처방 뿐만 아니라 '],
        bold: false,
        numbering: false,
      },
      {
        text: '상담을 통한 정신치료, 인지행동요법 및 정신분석 등 다채로운 방법들로 구성',
        bold: true,
        numbering: false,
      },
      {
        text: [
          '되어 있습니다. 따라서 설령 호소하고 계시는 증상이 약물 치료를 요할 정도로 심각하지는 않을지라도 이를 경감시킬 수 있는 다양한 검증된 치료 방법들이 마련되어 있다는 것을 인지하셨으면 합니다.',
          <br />,
          <br />,
          '인사이드 팀은 다리가 아프면 정형외과에, 열이 나면 내과에 가듯이, 마음이 힘들면 당연하게 정신과에 내원할 수 있는 사회를 꿈꿉니다. 마음이 힘들어서 생활을 하는 데에 어려움을 느끼시거나 주변 사람들에게 쉽게 터놓을 수 없는 고민으로 고생하고 계시다면 정신건강의학과 전문의와 이야기를 나눠보시는 것을 권해드립니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 17,
    image: mh15,
    category: '우울증과 조울증의 모든 것',
    title: '예민한 우리 자녀, 혹시 우울증은 아닐까?',
    subtitle: '소아청소년 우울증의 놓치지 말아야 할 특징 3가지',
    texts: [
      {
        text: [
          '아이들도 우울증에 걸릴 수 있다는 사실, 혹시 알고 계셨나요? 성인 우울증만큼 흔하지는 않지만 소아기 및 청소년기에서 발달 중인 아이들에게도 우울증이 찾아올 수 있습니다. 다 큰 성인은 자신의 기분이나 생각을 표현할 수 있기 때문에 ‘우울하다’고 호소하며 우울증이 발견되는 경우가 많습니다. 그러나 아이들의 경우에는 스스로 느끼고 있는 감정을 표현하는 것이 서툴기 때문에 직접적으로 우울감을 표시하는 일이 드뭅니다. 더 나아가 소아청소년기라는 시기의 특성상 우울증의 증상이 어른의 전형적인 증상과는 다른 형태로 나타나는 경우가 많습니다. 따라서 이번 글을 통해서는 소아청소년 우울증의 놓치지 말아야 할 특징 3가지를 알아보도록 하겠습니다.',
          <br />,
          <br />,
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['1) 사소한 일에 짜증을 내고 예민하게 반응한다.', <br />],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '아이들은 우울감, 의욕 저하 또는 죄책감과 같은 기분 증상을 호소하기 보다는 사소한 일에 예민하게 반응하고 짜증이나 화를 잘 내는 모습을 보이기도 합니다. 특히 아이가 어릴수록 자신의 감정을 스스로 인지하거나 표현하는 것이 숙달되지 않아서 우울증이 짜증이나 분노로 표출되는 일이 많습니다. 우울증이 찾아오면 평소 온순했던 아이가 행동이 과격해지거나 적대적거나 극단적인 말을 할 수 있습니다.',
          <br />,
          <br />,
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['2) 특별한 원인 없이 여기저기 아프다고 한다.', <br />],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '소아청소년의 경우, 정서의 부정적인 변화가 몸의 반응으로 나타나게 됩니다. 이를 전문 용어로 ‘신체화’라고 부르는데요, 아이들은 ‘우울하다’고 직접적으로 표현하기보다는 머리나 배의 통증이나 소화불량과 같은 소화기 장애 등의 모호한 증상을 호소하곤 합니다.',
          <br />,
          <br />,
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['3) 등교를 거부하거나 평소에 비해 성적이 떨어진다.'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '평소에는 학교를 잘 다니던 아이에게 우울증이 찾아오면 학교 생활에 대한 부담감을 느끼면서 등교를 거부하거나 무단으로 결석할 수 있습니다. 그리고 외출을 잘 하지 않고 혼자 방에서만 지내려고 합니다. 사고가 둔화되면서 집중력과 학습 능력이 부족해져 성적이 떨어지는 경우도 있습니다.',
          <br />,
          <br />,
          '지금까지 알아보았듯이, 성인 우울증은 주로 우울감, 의욕 저하, 죄책감 등으로 나타나는 반면 소아청소년 우울증은 짜증스럽고 예민한 정서, 신체 증상 및 학교 생활의 문제 등으로 표출될 수 있습니다. 이러한 비전형적인 모습은 소아청소년 우울증의 진단을 어렵게 만들기도 합니다.',
          <br />,
          <br />,
          '2005년에 서울특별시 소아청소년정신보건센터에서 조사한 바에 따르면, 부모를 통해 진단한 소아청소년기 우울증의 유병률은 0.9%였지만 소아청소년이 스스로 보고한 우울증의 유병률은 7.4%에 달했다고 합니다. 이러한 차이는 부모님들께서 소아청소년 우울증의 특징을 잘 알지 못하고 계신다는 것을 의미합니다. 이 글을 읽고 계시는 분들께서는 위에 말씀드린 특징을 참고하셔서 아이의 정신건강에 주의를 기울이면 좋을 것 같습니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 18,
    image: mh16,
    category: '건강한 마음을 위한 지침서',
    title: '너무 불안한 나, 공황장애는 아닐까?',
    subtitle: '공황장애 바로 알기',
    texts: [
      {
        text: [
          '공황장애라는 질병은 10년 전만 해도 사람들에게 잘 알려지지 않았지만 현재는 우리에게 매우 친숙한 질환입니다. 지금까지 많은 연예인들이 공황장애로 진단이나 치료를 받는다는 사실을 매체를 통해 공개하면서 대중 또한 자연스럽게 공황장애의 증상이나 심각성에 대해서 인식하게 되었습니다. 실제로 공황장애로 진단된 환자의 수가 2010년에는 5만 명에 불과했으나 2015년에는 10만 명, 그리고 2017년에는 14만 4천 명으로 급격한 속도로 증가하고 있는 상황입니다.',
          <br />,
          <br />,
          '그렇다면 공황장애는 정확히 어떤 질환일까요? 많은 연예인이 겪었다는 사실이 알려져 있지만 공황장애는 비단 연예인들만의 병이 아닙니다. 이번 글에서는 어떤 경우 공황장애로 진단될 수 있는지 살펴보는 시간을 가져보도록 하겠습니다.',
          <br />,
          <br />,
          '공황장애에 대한 설명을 드리기에 앞서 공황발작이라는 개념을 먼저 짚고 넘어갈 필요가 있습니다. ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['공황발작'],
        bold: true,
        numbering: false,
      },
      {
        text: ['이란 '],
        bold: false,
        numbering: false,
      },
      {
        text: [
          '극심한 공포감이 예상치 못한 상황에 갑자기 말려오는 것으로 심장이 빨리 뛰거나 숨을 쉬기 어려운 등의 신체증상이 동반되어 죽을 것 같은 두려움을 느끼는 증상',
        ],
        bold: true,
        numbering: false,
      },
      {
        text: ['을 일컫습니다. 이러한 '],
        bold: false,
        numbering: false,
      },
      {
        text: ['공황발작이 반복적으로 발생한 경우에 비로소 공황장애라는 진단'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '을 내릴 수 있습니다. 다시 말해서, 한 차례의 공황발작을 경험했다고 해서 바로 공황장애로 진단되지는 않는다는 것이지요.',
          <br />,
          <br />,
          '그렇다면 공황발작의 증상으로는 정확히 어떤 것들이 있을까요? 다음 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['13개의 증상 중 4개 이상'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '에 해당한다면 공황발작으로 정의할 수 있습니다:',
          <br />,
          '- 심장이 두근거리거나 맥박이 빨라지는 느낌',
          <br />,
          '- 땀이 남',
          <br />,
          '- 손발 또는 몸이 떨림',
          <br />,
          '- 숨이 가빠지는 느낌',
          <br />,
          '- 질식할 것 같은 느낌',
          <br />,
          '- 가슴 부위의 통증 또는 답답한 느낌',
          <br />,
          '- 오심 또는 속이 불편함',
          <br />,
          '- 어지럽거나 쓰러질 것 같은 느낌',
          <br />,
          '- 비현실감, 세상 또는 자신이 달라진 듯한 느낌',
          <br />,
          '- 미칠 것만 같은 공포감',
          <br />,
          '- 죽을 것만 같은 두려움',
          <br />,
          '- 손발의 이상한 감각',
          <br />,
          '- 몸이 화끈거리는 느낌',
          <br />,
          <br />,
          '공황장애로 진단되기 위해서는 공황발작이 반복적으로 발생하는 것뿐만 아니라 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['공황발작이 다시 발생하는 것에 대한 지속적인 두려움 또한 동반'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '되어야 합니다. 특히 공황발작의 영향이나 결과에 대해 걱정하거나, 공황발작으로 인해 외출을 회피하는 등의 현저한 행동 변화가 발생하기도 합니다.',
          <br />,
          <br />,
          '공황장애가 발병하는 정확한 원인은 아직 밝혀지지 않았으나 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['다양한 환경적인 원인으로 인해 유발'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '될 수 있는 것으로 알려져 있습니다. 흔히 타인과의 갈등 등으로 인한 극심한 스트레스 상황에서 갑작스럽게 나타날 수 있습니다. 이를 반대로 생각해보면 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['평소에 심리적인 압박감과 신체적인 압박감을 잘 조절한다면 공황장애의 증상을 개선시킬 수 있다'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '는 말이기도 합니다. 아울러 스트레스, 피로, 음주 및 카페인 섭취 등은 공황장애의 증상을 악화시키는 방향으로 작용할 수 있기 때문에 생활 속에서 이러한 요소들을 적절히 관리하는 것이 중요하겠습니다.',
          <br />,
          <br />,
          '공황장애는 ',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['발병 기간이 짧을수록 예후가 좋기 때문에 신속하게 발견하여 치료하는 것이 중요'],
        bold: true,
        numbering: false,
      },
      {
        text: [
          '합니다. 이 글을 읽고 계신 분들께서도 위와 같은 증상들을 경험하고 있다면 주저하지 마시고 정신과에 방문하셔서 상담을 받아 보시길 권고드립니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 19,
    image: mh17,
    category: '불면증 극복 프로젝트',
    title: '불면증은 수면제로 치료되지 않아요!',
    subtitle: '잠 잘 자는 방법 6가지',
    texts: [
      {
        text: [
          '쉽게 잠에 들지 못하거나 숙면을 취하는 것에 어려움을 느끼는 사람들이 참 많습니다. 어쩌면 지금 이 글을 읽고 계신 분들께서도 불면증의 증상들을 경험하고 계실지 모르겠습니다. 불면증의 치료라고 하면 흔히 수면제를 떠올리는 경우가 많습니다. 수면제나 수면유도제가 불면증의 증상을 일시적으로 완화시켜 숙면을 취할 수 있도록 도와주는 것은 사실입니다. 하지만 수면제를 장기간 계속 복용하게 되면 약에 중독이 되거나 내성이 생기기 때문에 처음에는 한 알만 먹어도 잠이 오지만 시간이 지나면 2, 3알을 먹어도 전혀 효과를 보지 못하는 상황에 놓이게 될 수 있습니다.',
          <br />,
          <br />,
          '불면증을 근본적으로 치료하는 방법은 올바른 수면위생을 갖추는 것입니다. 수면위생이라는 개념에 대해서 들어보셨나요? 올바른 수면위생을 갖춘다는 것은 생활 패턴이나 주변 환경 등을 숙면을 취하기 위해 적합한 상태로 유지시키는 것을 말합니다. 정신과에서는 좋은 수면위생을 갖추도록 돕기 위해 상담치료의 일종인 인지행동치료(CBT)를 시행하기도 합니다. 수면에 관련된 잘못된 생각과 행동을 교정함으로써 수면제의 도움 없이도 숙면을 취할 수 있도록 도와주는 것이지요.',
          <br />,
          <br />,
          '이번 글에서는 불면증을 이겨내기 위한 올바른 수면위생 몇 가지를 나눠보고자 합니다.',
          <br />,
          '자, 그럼 하나씩 살펴볼까요?',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['1. 매일 규칙적인 시간에 잠자리에 들고, 정해진 시간에 일어나는 것이 좋습니다.'],
        bold: true,
        numbering: true,
      },
      {
        text: [
          '불규칙한 수면시간은 불면증의 가장 큰 원인 중 하나입니다. 수면은 우리 몸의 생체리듬에 영향을 받는데, 매일 다른 시간에 자고 일어나면 이 리듬이 깨지기 때문에 깊은 잠을 잘 수 없습니다. 따라서 규칙적인 수면 습관을 가지는 게 중요합니다. 특히 정해진 시간에 일어나는 것을 잘 지키는 것이 특히 중요합니다. 불면증을 앓고 있는 환자분들은 잠이 안 와 늦게 자고, 그에 따라 늦게 일어나고, 또 잠이 오지 않고 하는 악순환을 반복하는 경우가 많습니다. 이러한 악순환의 고리를 끊기 위해서는 아무리 늦게 잠들든 간에 정해진 시간에 일어나는 규칙을 잘 지키는 것이 중요합니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['2. 잠자는 환경은 조용하고 안락해야 합니다.'],
        bold: true,
        numbering: true,
      },
      {
        text: [
          '침실은 어둡고 조용한 환경을 조성해야 하며 너무 덥거나 춥지 않도록 온도를 조절해야 합니다. 또한 환기가 잘 되고 쾌적한 습도가 유지된다면 더더욱 숙면을 취하는 데 도움이 됩니다. 도움이 된다면 입면 시 귀마개 또는 안대를 착용해도 좋습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['3. 잠자리는 잠만 자는 공간입니다.'],
        bold: true,
        numbering: true,
      },
      {
        text: [
          "잠자리는 말 그대로 잠만 자는 공간이라고 생각하셔야 합니다. 일과 중 침대에서 TV를 보거나 독서를 하는 등의 다른 일을 하는 것은 잠을 방해하는 행위입니다. 뿐만 아니라 침대 위에서 다른 활동들을 하게 되면 우리 몸이 침대를 '다른 무언가를 하는 공간'으로 인지하게 되어 침대에 누워도 잠이 잘 오지 않는 상황이 발생하게 됩니다. ",
          <br />,
          <br />,
          '또한 침대에서 시계를 보거나 휴대폰을 확인하는 것을 피해야 합니다. 어두운 방 안에서 빛을 보게 되면 그 자체로 수면리듬이 망가지게 됩니다. 따라서 침대에서 휴대폰을 켜 시계를 보는 행위는 피해주시는 것이 좋겠습니다. 수면 도중에 깨어 잠이 오지 않는다면 휴대폰을 보거나 무작정 침대에 누워 계시지 마시고 침대에서 일어나 다른 일을 하시는 것을 추천드립니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['4. 흡연, 음주 및 카페인 섭취를 조절해야 합니다.'],
        bold: true,
        numbering: true,
      },
      {
        text: [
          '커피를 포함한 카페인이 함유된 음료를 피하셔야 하며, 드시더라도 오전에 한 잔으로 제한하는 것이 좋습니다. 또한 자기 전에 흡연이나 음주는 삼가해야 합니다. 술은 처음에 수면을 유도할 수는 있지만 깊은 잠에 드는 것을 방해하기 때문에 결과적으로 숙면에 악영향을 미칩니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['5. 매일 규칙적으로 운동을 하는 것이 좋습니다.'],
        bold: true,
        numbering: true,
      },
      {
        text: [
          '낮 시간에 신체활동이 충분히 이루어진 경우 밤에 잠에 드는 것이 훨씬 수월합니다. 다만 저녁 시간이나 잠 들기 전에 운동을 하게 되면 오히려 몸이 각성되어 잠에 드는 것을 방해할 수 있기 때문에 이것은 삼가하시는 것이 좋습니다.',
        ],
        bold: false,
        numbering: false,
      },
      {
        text: ['6. 과도한 긴장이나 스트레스를 피하셔야 합니다.'],
        bold: true,
        numbering: true,
      },

      {
        text: [
          '숙면은 몸이 이완된 상태에서 잘 이루어집니다. 긴장이나 스트레스 때문에 몸과 마음이 경직되어 있는 경우에는 깊은 잠에 들기 어렵습니다. 따라서 평소에 스트레스를 해소할 수 있는 적절한 취미생활 또는 휴식을 가지는 것이 중요하겠습니다. 잠들기 전 명상과 같은 이완요법을 시도해보는 것도 좋은 방법이겠네요!',
          <br />,
          <br />,
          '지금까지 숙면을 취하는 데 도움이 되는 팁들을 알아보았습니다. 참고하시어 모두 ‘꿀잠’ 주무실 수 있도록 기원하겠습니다 :)',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 20,
    image: mh18,
    category: '정신과 바로 알기',
    title: '정신과 약은 평생 먹어야 하나요?',
    subtitle: '정신과 약에 대한 오해와 진실 1',
    texts: [
      {
        text: [
          '정신과에 내원하여 약물 처방을 받았거나 이미 정신과 약을 복용하고 계신 분들이 가장 흔하게 하시는 질문 중 하나가 바로 ‘정신과 약을 한번 복용하기 시작하면 평생 지속해야 하나요?’ 입니다. 이러한 궁금증이나 걱정 속에는 정신과에서 처방하는 약물에 대한 막연한 거부감이 자리잡고 있는 것으로 보입니다.',
          <br />,
          <br />,
          '사실 ‘정신과 약’이라는 것은 존재하지 않습니다. 내과 등 다른 분과에서 기침, 고혈당 혹은 가려움증 등의 증상에 효과가 있는 약물을 투약하듯이, 정신과에서도 마찬가지로 우울, 불안, 환각 등의 증상을 개선시키는 것이 증명된 약물을 처방하는 것입니다. 약물이 ‘뇌’라는 부위에서 작용을 하는 점이 특수할 뿐 실제로 다를 것은 하나도 없습니다.',
          <br />,
          <br />,
          '더 나아가 정신과 약이라고 해서 한번 복용하기 시작하면 평생 동안 먹어야 하는 것은 아닙니다. 물론 조현병 및 조울증과 같은 주요 정신질환의 경우에는 급성기의 증상이 완화된 뒤에도 재발을 방지하고 안정적인 상태를 유지하기 위한 약물의 유지치료가 진행되어야만 합니다. 그러나 우울, 불안, 불면과 같은 증상을 조절하기 위해 약을 복용하는 경우에는 증상이 호전되고, 더 나아가 일정 기간 이상 안정적인 상태가 유지된다면 담당 정신과 전문의의 의학적인 판단 하에 약을 감량하거나 중단할 수 있습니다. ',
          <br />,
          <br />,
          '그러나 약물 복용 기간에 대해 천편일률적으로 적용할 수 있는 기준은 존재하지 않습니다. 약물치료의 중단에 대한 결정을 내리기 위해서는 증상이 안정적으로 조절되고 있는지, 이전에 약을 중단했을 때 상태가 어땠는지, 약을 중단한다면 재발의 위험성이 얼마나 되는지 등 매우 다양한 요소들이 충분히 고려되어야 합니다. 따라서 약물을 전문의의 판단에 의해서가 아니라 자의적으로 끊는 것보다 위험한 것은 없습니다. 약물치료 시작 후 본인의 증상이 주관적으로 좋아졌다고 느끼더라도 충분한 기간에 걸친 유지치료 과정을 거치지 않고 약물을 중단한다면, 이후 증상이 악화되거나 잦은 재발로 인해 더 큰 고생을 하게 되는 경우가 있습니다. 따라서 약물 복용과 관련된 의사결정을 내리기 전에는 담당 정신과 전문의에게 진료를 받는 것이 필요합니다.',
          <br />,
          <br />,
          '앞서 설명 드렸듯이, 정신과 약을 반드시 평생 동안 복용해야 하는 것은 아닙니다. 그러나 역으로 생각해보면, 정신과 약을 충분한 기간에 걸쳐 복용할 수 있다는 것은 그만큼 정신과 약의 효과 및 안전성이 오랜 역사를 거쳐 매우 잘 입증되었음을 의미합니다. 따라서 정신과 약에 대한 막연한 거부감 때문에 무조건 약을 빨리 줄이거나 끊으려는 급한 마음은 효과적인 치료의 측면에서 결코 바람직하지 않습니다. 정신과 약을 처방 받으면 이를 평생 복용해야 한다는 근거 없는 두려움으로 약물 치료를 거부하는 일 또한 있어서는 안 되겠습니다. 다리에 상처가 나면 연고가 필요하듯, 마음에 상처가 나도 약이 필요하기 마련입니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 21,
    image: mh19,
    category: '정신과 약 백과사전',
    title: '정신과 약은 중독되기 쉽나요?',
    subtitle: '정신과 약에 대한 오해와 진실 2',
    texts: [
      {
        text: [
          '정신과 진료를 받으면서 약물을 복용해야 하는 상황에 놓이게 되었을 때 많은 분들께서 정신과 약을 복용하기 시작하면 중독되어 ‘습관성으로’ 약에 의존하게 되지 않을까 걱정하시곤 합니다. 이같은 걱정은 사실과는 다른 왜곡된 시선으로 정신과 약물을 조명하는 영화, 드라마 등의 장면에서 비롯되기도 합니다. 정신과 진료 및 약물치료를 대하는 막연한 사회적 거부감 또한 분명히 이러한 편견에 영향을 미칠 것입니다.',
          <br />,
          <br />,
          '그러나 정신과 약물을 바라보는 곱지 않은 시선과는 달리 대부분의 정신과 약물은 중독성이 없어서 안전하게 복용할 수 있습니다. 우선 흔히 말하는 정신과 약물에도 항우울제, 기분안정제, 항불안제 등 굉장히 여러 가지 종류가 있으며 약물마다 각기 다른 작용 원리 및 약리학적 특성을 지니기 때문에 ‘정신과 약물’이라는 하나의 큰 울타리 안에 묶어두고 이해하는 것은 적절하지 않습니다. 정신과 약물 중에서 수면제와 항불안제의 일종인 신경안정제를 제외하면 신체적인 의존을 유발하지 않기 때문에 장기간 복용하더라도 중독되지 않습니다. 항불안제라는 카테고리 내에도 신경안정제와는 다른 기전으로 작용하는 약물들이 존재하는데, 이들 또한 중독을 일으키지 않습니다.',
          <br />,
          <br />,
          '수면제와 안정제는 중독성을 지니기 때문에 정신과 전문의는 이러한 약물은 증상을 조절하기 위해 필요한 용량만을 단기간에 처방합니다. 졸피뎀과 같은 수면제를 여러 의원에서 복수로 처방 받는 것을 금하는 장치가 마련되어 있는 것도 수면제에 중독되는 것을 방지하기 위한 것입니다. 정신과 전문의가 처방하는 용량과 용법 그대로 복용한다면 수면제나 안정제도 내성이 생기지 않고 안전하게 사용할 수 있습니다.',
          <br />,
          <br />,
          '오히려 내성 혹은 중독을 지나치게 우려하여 약물을 자의적으로 중단하거나 용량을 임의로 조절하는 경우에 내성이 발생할 수 있습니다. 더 나아가 약물을 용법대로 복용하지 않게 되면 약물이 체내에서 충분한 효과를 발휘하지 못해 증상이 쉽게 재발할 수 있습니다. 증상이 재발한 경우에는 약물의 용량을 처음 발생했을 때보다 더욱 늘려서 처방 받기 때문에 약물에 중독되는 것을 과도하게 걱정하다가 원치 않게 더 많은 약물을 복용하게 될 가능성이 높습니다.',
          <br />,
          <br />,
          '지금까지 살펴보았듯이 대다수의 정신과 약은 중독에 대한 걱정 없이 안심하고 복용할 수 있습니다. 정신과 약을 안전하게 복용하기 위해서는 전문의의 지도에 맞게 그대로 복용하고 약물에 대한 의사결정은 담당 전문의와 함께 상의하여 내리는 것이 중요합니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 22,
    image: mh20,
    category: '우울증과 조울증의 모든 것',
    title: '나, 올해도 가을 타나 봐....',
    subtitle: '계절성 정동장애, 들어보셨어요?',
    texts: [
      {
        text: [
          '‘나 가을 타나 봐',
          <br />,
          '니가 불어오는 이 밤',
          <br />,
          '나 혼자서 가을 타나 봐.’',
          <br />,
          <br />,
          '유명 발라드 그룹 바이브의 명곡 <가을 타나 봐>의 일부입니다. 이 곡, 들어본 적 있으신가요? 노랫말과 같이 우리는 실제로 날씨가 쌀쌀해지고 낙엽이 떨어지는 가을이 되면 ‘가을 탄다’는 이야기를 참 많이 합니다. 문학작품에서도 가을이라는 계절은 고독, 외로움, 그리고 슬픔의 상징으로 자주 등장하곤 하지요.',
          <br />,
          <br />,
          '그런데 이 ‘가을 탄다’는 것이 단순한 가을 감성이 아니라 일종의 기분장애일 수 있다면 어떨 것 같으신가요? 이번 글에서는 가을, 겨울에 접어들면서 계절의 변화에 따라 나타나는 계절성 정동장애에 대해 전해드리고자 합니다.',
          <br />,
          <br />,
          '계절성 정동장애란, 말 그대로 매년 특정한 기간에 우울감을 반복적으로 호소하는 경우를 일컫습니다. 여름에 특징적으로 우울해지는 경우도 더러 있지만, 계절성 정동장애는 가을, 겨울에 시작해서 봄이 되면 회복에 이르는 형태로 나타나는 것이 대부분이지요.',
          <br />,
          <br />,
          '계절성 정동장애의 증상은 일반적으로 ‘우울증’이라고 불리는 주요 우울장애의 증상과 다소 다른 양상을 보입니다. 일반적인 우울증은 우울한 기분과 더불어 잠에 잘 들지 못하며, 식욕이 저하되고 체중이 줄어드는 증상으로 나타나는 경우가 많습니다. 반면 계절성 정동장애는 평소보다 잠을 많이 자는 과다 수면이 나타나며, 무기력증이 심해 하루 종일 누워서 지내려고 하는 모습을 보입니다. 이에 더하여, 식욕 과다로 탄수화물 섭취가 늘어 체중 증가로 이어지기도 합니다.',
          <br />,
          <br />,
          '그렇다면 계절성 정동장애는 도대체 왜 생기는 걸까요? 정확한 원인은 밝혀지지 않았지만, 전문가들은 가을 및 겨울에 햇빛의 양과 일조시간이 줄어드는 것과 연관성이 크다고 말합니다. 우리 몸의 자연적인 생체 리듬은 햇빛의 영향을 굉장히 많이 받습니다. 해가 뜨면 몸이 이것을 활동의 신호로 받아들이고, 해가 지면 휴식의 신호로 받아들여 잠에 들게 되는 것이지요.',
          <br />,
          <br />,
          '그런데 겨울철이 되면 햇빛의 양과 낮에 해가 떠 있는 시간이 줄어들기 때문에 생체 리듬을 망가뜨려 우울한 기분, 무기력증, 활동량 저하, 과다 수면 등의 증상을 일으키게 됩니다. 계절성 정동장애는 해가 짧은 북반구 국가에서, 그리고 밤에 근무하는 순환 근무자에서 더욱 흔히 발생한다고 합니다.',
          <br />,
          <br />,
          '따라서 계절성 정동장애의 증상을 호전시키는 데는 햇빛을 쐬는 것이 굉장히 중요합니다. 가을 및 겨울철에 우울감을 경험하는 경우 낮 시간에 햇빛 속에서 활동하는 시간을 늘리고, 햇빛을 많이 받을 수 있도록 주변 환경을 조성하는 것이 좋습니다. 예를 들어, 낮 시간에 커튼이나 블라인드를 걷어 두고 활동 반경을 햇빛이 들어오는 창문 쪽을 향하도록 하는 식으로 말이죠. 실제로 정신과에서는 계절성 정동장애를 치료하기 위해 일조량의 원리를 활용하여 매일 일정한 기간 동안 강한 광선에 노출시키는 광선요법을 사용하기도 합니다.',
          <br />,
          <br />,
          '지금 이 글을 읽고 계시는 여러분께서도 매년 가을을 타는 것 같다는 생각을 하지는 않으셨나요?',
          <br />,
          <br />,
          '매년 같은 시기에 반복적으로 우울감과 무기력감으로 고생하셨다면 이번 기회에 정신과에 방문하셔서 상담을 받아보시는 것은 어떨까요? 햇빛을 많이 보고 활동적으로 생활하시는 것도 큰 도움이 될 수 있답니다. 인사이드팀은 여러분의 활기차고 햇살 가득한 가을을 기원합니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
  {
    id: 23,
    image: mh21,
    category: '건강한 마음을 위한 지침서',
    title: '네가 밉지만 가지 마. 제발 내 곁에 계속 머물러줘.',
    subtitle: '경계성 인격장애를 아시나요?',
    texts: [
      {
        text: [
          '‘여자친구가 경계성 인격장애인 것 같은데 어떻게 해야 할까요?’',
          <br />,
          '‘남편이 저를 못 믿고 저에 대한 집착이 너무 심해요. 혹시 경계성 성격장애 아닐까요?’',
          <br />,
          <br />,
          '여러분께서 경계성 인격장애에 대해서 한 두 번이라도 들어보거나 접해본 적이 있으실 것입니다. 현재 교제 중인 이성 친구 또는 배우자의 성격과 행동을 묘사하면서 경계성 인격장애가 아닌지, 또 어떻게 대처해야 하는지 묻는 글들이 인터넷 커뮤니티에 최근 들어 정말 많이 올라옵니다.',
          <br />,
          <br />,
          '얼마 전 모 연예인이 스스로 경계성 인격장애를 앓고 있다고 용기 있게 밝힌 이후, 뉴스 기사가 쏟아지면서 더 많은 사람들이 경계성 인격장애에 대해 알게 되었습니다.',
          <br />,
          <br />,
          '그렇다면 경계성 인격장애는 도대체 무엇일까요? 인터넷 커뮤니티에 올라오는 글들처럼 단순히 ‘너랑 헤어지면 나 어떻게 될지 모른다’고 말하는 이별에 대한 두려움을 가진 사람들은 모두 경계성 인격장애일까요? 이번 글에서는 경계성 인격장애를 제대로 알기 위해 경계성 인격장애의 증상을 설명드리려고 합니다.',
          <br />,
          <br />,
          '물론 이별이나 분리에 대한 극심한 공포는 경계성 인격장애의 핵심 증상 중 하나입니다. 그러나 경계성 인격장애에서는 ‘헤어짐’이 아니라 ‘버려짐’에 대한 두려움이 지배적입니다. 경계성 인격장애를 앓고 있는 환자는 정서적 불안이 심한 편이며 대인관계에 있어 매우 불안정하며 극단적인 자세를 보입니다.',
          <br />,
          <br />,
          '연인, 가족, 친구 등의 누군가에게 돌봄을 받고 있다고 느낄 때에는 그 사람을 극찬하고 지나치게 이상화시키는 경향이 있습니다. 그러다 돌보는 사람이 곁에 없을 때면 외로움과 공허함 등의 우울 증상을 경험하게 됩니다. 어떠한 이유로든 그 사람과의 관계를 잃을 수 있다는 위협이 발생하면, 이제껏 따뜻한 모습으로 이상화되었던 그 사람의 이미지가 잔인한 박해자의 이미지로 격추됩니다. 결국 그 사람과의 이별이 가까워지면 버려짐에 대한 극심한 공포가 발생하여 이를 막기 위해 분노를 표출하거나 자해 및 자살 시도 등의 위험한 행동을 보이기도 합니다.',
          <br />,
          <br />,
          '경계성 인격장애의 또 다른 특징은 감정과 생각이 쉽게 바뀐다는 점입니다. 특히 자신에게 잘해주던 사람이 돌변하여 자신을 홀대한다는 인식이 생기면 심하게 화를 내는 등 감정이 급속하고 격렬하게 바뀔 수 있지요. 또한 대인관계에 관련하여 거절 및 비판에 매우 민감하게 반응하기 때문에 이러한 상황도 감정 기복의 원인으로 작용할 수 있습니다. 다만, 이러한 감정 변화는 대부분 수 시간 이내 원래대로 돌아오는 편이며 수 일 이상 지속되는 경우는 거의 없습니다.',
          <br />,
          <br />,
          '이에 더하여, 앞서 말씀드린 것처럼 자해, 자살 시도 및 위협 등의 자살 관련 행동은 경계성 인격장애에서 매우 흔히 찾아볼 수 있습니다. 그러나 이러한 행동은 실제로 자신을 해하거나 목숨을 끊기 위한 목적이 아니라 자신을 버린다고 생각하는 사람에게 일종의 경고의 메시지를 보내기 위해 충동적으로 이루어지는 것으로 보입니다. 경계성 인격장애 환자의 자살 위험은 일반 대중에 비해 약 40배가 높다고 합니다.',
          <br />,
          <br />,
          '지금까지 경계성 인격장애에서 나타날 수 있는 증상에 대해 함께 알아보았습니다. 경계성 인격장애를 앓고 있는 사람은 질환의 이름 그대로 ‘경계’에 서서 아슬아슬 줄타기를 하는 모습으로 대인관계에 임하게 됩니다. 자신을 보살펴주는 누군가를 얻게 되어도 온전히 행복하지 못하고, 그 사람에게 버려질 것 같은 극심한 두려움에 사로잡혀 공허하고 초조한 감정이 드는 것이지요.',
          <br />,
          <br />,
          '경계성 인격장애의 치료로는 상담을 통한 정신치료가 매우 효과적인 것으로 알려져 있습니다. 정신치료로 하여금 치료진과 안정적이고 신뢰할 수 있는 인간관계를 발전시키는 것을 연습함으로써 경계성 인격장애의 증상은 충분히 호전될 수 있습니다.',
          <br />,
          <br />,
          '우리 사회는 경계성 인격장애에 대한 막연한 두려움을 버리고 더 많은 관심을 가져야 합니다. 경계성 인격장애를 앓고 있는 사람들이 적절한 도움을 받아 안정적인 마음으로 인간관계에 임할 수 있도록 따뜻한 시선과 도움의 손길이 필요합니다.',
          <br />,
          <br />,
          '* 인사이드팀이 게재하는 모든 컨텐츠는 현직 의사들의 자문과 검토를 받았습니다.',
        ],
        bold: false,
        numbering: false,
      },
    ],
    link: '',
  },
];

export default contents.reverse();
