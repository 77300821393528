import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NotificationSettingSection } from '../../../components/anxy/notification/NotificationSettingSection';
import ContainerByDeviceAnxy from '../../../components/elements/ContainerByDeviceAnxy';
import webviewToast from '../../../development/webviewToast';
import useFetchAnxy from '../../../hooks/useFetchAnxy';
import useCheckNotificationState from '../../../hooks/useCheckNotificationState';
import { marginWithHomeIndicator } from '../../../recoil/common/device';
import notificationSettingAtom from '../../../recoil/common/notification/settingAtom';
import { goToSetting } from '../../../utils/webview';

const getSortedArray = (array) => {
  array = new Set(array);
  array = [...array];
  console.log(array);
  array.sort(function (a, b) {
    const aHour = parseInt(a.split(':')[0]);
    const aMin = parseInt(a.split(':')[1]);

    const bHour = parseInt(b.split(':')[0]);
    const bMin = parseInt(b.split(':')[1]);

    if (aHour < 4 && bHour < 4) {
      return aHour * 60 + aMin - (bHour * 60 + bMin);
    } else if (aHour < 4) {
      return 1;
    } else if (bHour < 4) {
      return -1;
    } else {
      return aHour * 60 + aMin - (bHour * 60 + bMin);
    }
  });
  return array;
};

export function AnxyNotificationSetting() {
  const [sessionList, setSessionList] = useState([]);
  const [worryNoteList, setWorrynoteList] = useState([]);
  const [notificationState, setNotificationState] = useRecoilState(notificationSettingAtom);

  const [loadNotification, getNotification] = useFetchAnxy({
    url: '/anxy/v2/notification/setting/load',
  });

  const [saveNotification, setSaveNotification] = useFetchAnxy({
    url: '/anxy/v2/notification/setting/save',
    requestBody: {
      session: {
        timeList: getSortedArray(notificationState.session.timeList),
      },
      worryNote: {
        timeList: getSortedArray(notificationState.worryNote.timeList),
      },
    },
  });

  const { isNotificationAllow } = useCheckNotificationState();
  // const isNotificationAllow = true;
  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  useEffect(() => {
    if (saveNotification) {
      const sortedState = {
        session: {
          timeList: getSortedArray(notificationState.session.timeList),
        },
        worryNote: {
          timeList: getSortedArray(notificationState.worryNote.timeList),
        },
      };
      webviewToast('알림 설정: ' + JSON.stringify(sortedState));
    }
  }, [saveNotification]);

  useEffect(() => {
    if (loadNotification) {
      if (loadNotification.result) {
        setNotificationState(loadNotification.result);
      }
    }
  }, [loadNotification]);

  console.log('last', notificationState.worryNote.timeList);
  useEffect(() => {
    setSessionList(getSortedArray(notificationState.session.timeList));
    setWorrynoteList(getSortedArray(notificationState.worryNote.timeList));

    if (isNotificationAllow) {
      setSaveNotification();
    }
  }, [notificationState]);

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <ContainerByDeviceAnxy bgColor="#F1EEEB">
      <div className={`${marginClassName} px-[20px] pt-[24px] pb-[20px] flex flex-col gap-[12px]`}>
        {!isNotificationAllow && (
          <div
            className="relative rounded-[20px] bg-[#EF670B] p-[20px] flex justify-between items-center"
            onClick={() => goToSetting()}
          >
            <p className="text-[17px] leading-[23px] text-white font-bold">먼저 설정에서 알림을 허용해주세요</p>
            <div className="flex items-center justify-center right-[0px] absolute w-[60px] h-[60px]">
              <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.5 12.5L7.5 7.5L2.5 2.5"
                  stroke="#FFFFFF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        )}
        <div
          className="flex flex-col gap-[60px] pb-[20px]"
          tabIndex="0"
          style={{
            opacity: isNotificationAllow ? 1 : 0.6,
          }}
        >
          <NotificationSettingSection
            state={notificationState}
            setState={setNotificationState}
            timeList={sessionList}
            type="session"
          />
          <NotificationSettingSection
            state={notificationState}
            setState={setNotificationState}
            timeList={worryNoteList}
            type="worryNote"
          />
        </div>
      </div>
    </ContainerByDeviceAnxy>
  );
}
