import React from 'react';
import { PrimaryActionButton } from '../button/ActionButton';
import { Title } from '../../stories/text/Title';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';

export function TopTitleBottomAction(props) {
  const { title, subtitle, content, buttonText, buttonState, action, topColor, bgColor, webHeader } = props;

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return (
    <div className={`h-screen flex flex-col ${marginClassName} ${webHeader && 'pt-[44px]'} `}>
      <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col`}>
        <div className="pt-[12px] px-[20px] " style={{ backgroundColor: topColor }}>
          <Title title={title} />
          {subtitle && <p className="mt-[8px] text-[17px] leading-[23px] text-[#6B6D76]">{subtitle}</p>}
        </div>
        <div className="flex-1">{content}</div>
      </div>
      <div className={`flex-none w-full relative `}>
        <div
          className="w-full h-[40px] absolute top-[-40px]"
          style={{ background: `linear-gradient(to top, ${bgColor ? bgColor : 'white'}, rgba(255,255,255,0))` }}
        />
        <div className="px-[20px]">
          <PrimaryActionButton state={buttonState} text={buttonText} action={action} />
        </div>
      </div>
    </div>
  );
}
