import React, { useEffect } from 'react';
import ContainerByDeviceAnxy from 'components/elements/ContainerByDeviceAnxy';
import headerAtom, { BUTTON_BACK } from 'recoil/common/header/atom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { SubTitle18, Title24 } from 'stories/text/AnxyText';
import anxyColorPalette from 'data/anxy/contents/anxyColorPalette';
import { changeISOToFormatString } from 'logic/method';
import { useParams, useNavigate } from 'react-router-dom';
import userAtom from 'recoil/anxy/user/atom';
import hexOpacity from 'hex-opacity';
import useMixpanelEventAnxy from 'hooks/useMixpanelEventAnxy';

export default function WorryNoteHistoryDetail() {
  // 데이터 로드 여부
  let { worryNoteId } = useParams();
  worryNoteId = parseInt(worryNoteId);
  const navigate = useNavigate();
  const setHeaderState = useSetRecoilState(headerAtom);
  const { worryNoteHistory } = useRecoilValue(userAtom);
  const worryNoteHistoryDetail = worryNoteHistory.find((element) => element.worryNoteId === worryNoteId);

  const setMixpanel = useMixpanelEventAnxy({
    eventName: '지난 기록 상세',
  });

  useEffect(() => {
    // header, layout settings
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_BACK,
    }));
    if (!worryNoteHistory) {
      navigate(-1);
    } else {
      setMixpanel();
    }
  }, []);

  const mappingData = [
    { title: '신체', key: 'bodyList' },
    { title: '생각', key: 'thought' },
    { title: '행동', key: 'behaviorList' },
  ];
  return (
    <ContainerByDeviceAnxy bgColor={anxyColorPalette.oat}>
      <div className="flex flex-col px-[20px] pt-[12px] pb-[100px]">
        <div className="text-[60px] font-bold text-anxyBlack text-center mb-[36px]">
          {worryNoteHistoryDetail.reason !== '심호흡하기' && worryNoteHistoryDetail.score}
          {worryNoteHistoryDetail.reason === '심호흡하기' && (
            <div className="flex items-center justify-center gap-[10px]">
              {worryNoteHistoryDetail.previousScore}
              <svg width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 22L29 12.5L20 3"
                  stroke="#26282C"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line x1="25.5" y1="12.5" x2="2.5" y2="12.5" stroke="#26282C" strokeWidth="5" strokeLinecap="round" />
              </svg>
              {worryNoteHistoryDetail.score}
            </div>
          )}
        </div>

        <div className="flex flex-col items-center justify-center gap-[16px] mb-[60px] overflow-hidden">
          <Title24 title={worryNoteHistoryDetail.reason} className="text-center w-full" />
          <SubTitle18
            subtitle={changeISOToFormatString(worryNoteHistoryDetail.submittedAt, 'DD h시 m분')}
            color="rgba(0,0,0,0.4)"
          />
        </div>
        <div className="w-full max-w-[500px] mx-auto flex flex-col gap-[30px]">
          {mappingData
            .filter((element) => worryNoteHistoryDetail[element.key] && worryNoteHistoryDetail[element.key].length > 0)
            .map((each, index) => (
              <div className="w-full  flex justify-between gap-[50px]">
                <SubTitle18
                  subtitle={each.title}
                  color={hexOpacity.create(anxyColorPalette.black, 0.4)}
                  className="shrink-0"
                />
                <SubTitle18
                  subtitle={
                    Array.isArray(worryNoteHistoryDetail[each.key])
                      ? worryNoteHistoryDetail[each.key].join(', ')
                      : worryNoteHistoryDetail[each.key]
                  }
                  // subtitle={'aekfvngsjnvslkdnbl kfgbnlkfbdkngkbdbmkfgdmlkbkmslkbfd'}
                  className="flex-1 "
                />
              </div>
            ))}
        </div>
      </div>
    </ContainerByDeviceAnxy>
  );
}
