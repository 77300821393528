import selfCheckData from '../../data/selfCheckData';

export function getSelfCheckState(selfCheckId, score) {
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === selfCheckId);

  // console.log(selfCheckData.find((element) => element.selfCheckName === selfCheckName).scores);
  for (let i = selfCheckInfo.result.length - 1; i >= 0; i--) {
    if (score >= selfCheckInfo.result[i].min) {
      return i;
    }
  }
}

export function ifSuperHealthy(selfCheckId, score) {
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckId === selfCheckId);
  // console.log(Math.ceil(selfCheckInfo.minScore + (selfCheckInfo.maxScore - selfCheckInfo.minScore) * 0.01));
  return score < Math.ceil(selfCheckInfo.minScore + (selfCheckInfo.maxScore - selfCheckInfo.minScore) * 0.01);
}
