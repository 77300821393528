import withHeart from '../../image/inside/withHeart.png';
import talk from '../../image/inside/talk.png';
import highfive from '../../image/inside/highfive.png';
import findout from '../../image/inside/findout.png';
import control from '../../image/inside/control.png';
import help from '../../image/inside/help.png';
import point from '../../image/inside/point.png';
import enjoy from '../../image/inside/enjoy.png';
import rest from '../../image/inside/rest.png';
import relax from '../../image/inside/relax.png';

export const objectDataList = [
  {
    id: 1,
    text: '내 의견을 명확하게 표현하고 싶어요.',
    planStepList: [
      {
        title: '내 작은 생각도 소중히',
        subtitle: '나의 생각과 감정을 알아차려요. 작은 생각도 억압하지 않고 꺼내보는 연습을 하고 습관화 시킬 거예요.',
        image: withHeart,
      },
      {
        title: '불편한 얘기도 잘해요',
        subtitle:
          '껄끄러운 상황에서도 상대방이 기분 좋게 받아들일 수 있는 표현들을 배워요. 나의 요구도 잘 표현할 수 있어요.',
        image: talk,
      },
      {
        title: '솔직하고, 담백하게',
        subtitle:
          '자기확신을 기반으로 한 솔직담백한 말하기가 가능해져요. 타인의 말 속에서 조언, 비판, 비난을 구분하여 받아들일 수 있어요.',
        image: highfive,
      },
    ],
  },
  {
    id: 2,
    text: '나의 감정을 잘 컨트롤 하고 싶어요.',
    planStepList: [
      {
        title: '감정 신호 알아채기',
        subtitle: '내가 느끼는 감정을 있는 그대로 알아차리는 연습을 해요. 기쁨, 불안 등 모두 신호라는 것을 알게 돼요.',
        image: findout,
      },
      {
        title: '감정을 다스리는 법',
        subtitle: '내가 느끼는 감정을 구분하고, 불필요한 감정이 생겼다면 거기서 벗어날 수 있는 나만의 방법을 만들어요.',
        image: control,
      },
      {
        title: '긍정 에너지의 선순환',
        subtitle:
          '이전보다 삶에서 행복함을 많이 느껴요. 행복한 감정에 에너지를 더 많이 쓰는 훈련이 되었기 때문이에요. 긍정의 선순환이 시작돼요.',
        image: highfive,
      },
    ],
  },
  {
    id: 3,
    text: '의사 결정을 현명하게 하고 싶어요.',
    planStepList: [
      {
        title: '주도적인 의사 결정',
        subtitle:
          '다른 사람들의 의견을 분별력있게 수용하는 연습을 해요. 나의 결정에는 다른 사람의 양해를 구하지 않아도 된다는 것을 깨달아요.',
        image: control,
      },
      {
        title: '도움을 구할 줄 알아요',
        subtitle:
          '타인에게 도움을 청하는 걸 망설이지 않아요. 타인의 말 속에서 조언, 비판, 비난을 구분하여 받아들일 수 있어요.',
        image: help,
      },
      {
        title: '나만의 기준',
        subtitle:
          '복잡한 문제에 접근하는 나만의 기준을 만들어요. 업무의 맥을 짚는 질문을 할 줄 알고, 흑백논리에서 벗어나 더 나은 대안을 제시할 수 있어요.',
        image: point,
      },
    ],
  },
  {
    id: 4,
    text: '어려운 상황을 회피하지 않고 도전하고 싶어요.',
    planStepList: [
      {
        title: '무의식 속 회피 발견',
        subtitle:
          '‘나는 못 할거야’ 라는 무의식적인 생각을 고칠거예요. 일어나지 않은 상황에 대해 불안함을 느끼지 않는 방법도 배워요.',
        image: findout,
      },
      {
        title: '근거 있는 자신감',
        subtitle:
          '나의 두려움을 알아채고, 통제하는 연습을 해요. 나의 작은 성공도 기뻐하고 인정하는 자신감 루틴을 만들어요.',
        image: highfive,
      },
      {
        title: '피하지 않고 즐기는 나',
        subtitle:
          '어려운 과제가 생겨도 성장의 발판이라 생각해 기분이 좋아요. 모든 사람들은 저마다 잘하는 분야가 있고, 나 또한 있다고 확신하는 마인드셋을 가졌어요.',
        image: enjoy,
      },
    ],
  },
  {
    id: 5,
    text: '제대로 휴식하는 방법을 알고 싶어요.',
    planStepList: [
      {
        title: '나에게 친절한 나',
        subtitle:
          '휴식을 온전히 즐기지 못하고 있는 나를 알아차릴 거예요. 그냥 쉬어도 불안하지 않도록, 나에게 친절한 사람이 되는 것을 연습해요.',
        image: withHeart,
      },
      {
        title: '휴식 시간을 소중하게 관리해요',
        subtitle:
          '소속감 등을 느낄 수 있는 사회적 휴식 등 수많은 휴식 방법을 탐구하고 나에게 가장 효과적인 것을 찾아요.',
        image: rest,
      },
      {
        title: '10분만 쉬어도 급속 충전',
        subtitle:
          '스트레스로부터 해방되는 법을 체득해요. 짧은 휴식을 통해서도 에너지 급속 충전이 가능한 상태로 거듭나요.',
        image: relax,
      },
    ],
  },
];
