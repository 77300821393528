import React from 'react';
import selfCheckData from '../../data/selfCheckData';
import { getSelfCheckState } from './selfCheckLogic';
import { PressedEffect } from '../../stories/button/PressedEffect';

export default function AbnormalCard(props) {
  const { selfCheckInfo, scrollToResult } = props;

  return (
    <PressedEffect
      element={
        <div
          className="w-full h-[65px] rounded-[16px] bg-white flex items-center justify-between pl-[24px] pr-[20px]"
          // onClick={() => {
          //   scrollToResult(selfCheckInfo.selfCheckId);
          // }}
        >
          <p className="text-[17px] leading-[23px] font-bold text-[#26282C]">{selfCheckInfo.selfCheckName}</p>
          <p className="text-[16px] leading-[22px] font-bold text-[#EF3E3E]">
            {
              selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).result[
                getSelfCheckState(selfCheckInfo.selfCheckId, selfCheckInfo.score)
              ].label
            }
          </p>
        </div>
      }
      disable={false}
      action={() => {
        scrollToResult(selfCheckInfo.selfCheckId);
      }}
    />
  );
}
