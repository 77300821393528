/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';

export function TextArea(props) {
  const {
    placeholder,
    value,
    forceBlur,
    autofocus,
    updateValue,
    noBorder,
    height,
    isBlur,
    hideCaret,
    textareaBgColor,
    caretColor,
    noPadding,
  } = props;
  const [isFocused, setFocused] = useState(autofocus);
  const [inputValue, setInputValue] = useState(value);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      const end = inputRef.current.value.length;
      inputRef.current.setSelectionRange(end, end);
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (typeof inputValue === 'string') {
      updateValue(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (forceBlur) {
      inputRef.current.blur();
    }
  }, [forceBlur]);

  return (
    <textarea
      enterKeyHint="next"
      className={`w-full ${!noPadding && 'rounded-[12px]'} border-[1px] outline-none
        ${!noPadding ? 'px-[20px] py-[14px]' : 'pb-[35px]'}  
      font-regular text-[17px] leading-[26px] placeholder:text-[#A4A6B0] text-black  scrollbar-hide`}
      style={{
        height: height || '100%',
        resize: 'none',
        backgroundColor: textareaBgColor || '#FAFBFD',
        borderColor: noBorder ? textareaBgColor || '#FAFBFD' : isFocused ? '#26282C' : '#D1D5DC',
        caretColor: hideCaret ? '#ffffff00' : caretColor || '#3953D9',
      }}
      placeholder={placeholder}
      value={inputValue}
      ref={inputRef}
      onClick={(e) => {
        e.target.focus();
      }}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onFocus={(e) => {
        setFocused(true);
      }}
      onBlur={(e) => {
        if (!forceBlur && !isBlur) {
          e.target.focus();
        }
      }}
      autoFocus={autofocus}
    ></textarea>
  );
}
