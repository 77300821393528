/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../../recoil/common/device';
import SleepEfficiencyGraph from '../../../components/somny/sleepReport/SleepEfficiencyGraph';
import SleepGraph from '../../../components/somny/sleepReport/SleepGraph';
import BarGraph from '../../../components/somny/sleepReport/BarGraph';
import SleepCard from '../../../image/somny/prescription_sleep.png';
import WakeUpCard from '../../../image/somny/prescription_wakeUp.png';

export default function SleepReport({ sleepingReportData }) {
  const [loaded, setLoaded] = useState(false);
  const [recordByDateList, setRecordByDateList] = useState(null);

  const scrollRef = useRef();
  const contentRef = useRef();
  const [isNavigate, setIsNavigate] = useState(false);

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  console.log(recordByDateList);
  useEffect(() => {
    setIsNavigate(false);
  }, []);

  useEffect(() => {
    if (sleepingReportData) {
      setRecordByDateList(
        sleepingReportData.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        }),
      );
      setLoaded(true);
    }
  }, [sleepingReportData]);

  const example = [
    {
      date: '2022-07-25',
      inBed: { startDateTime: '2022-07-25T00:00', endDateTime: '2022-07-25T09:30' },
      sleep: { startDateTime: '2022-07-25T04:00', endDateTime: '2022-07-25T09:00' },
      napTimeMinutes: 300,
      struggleTimeMinutes: 180,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-26',
      inBed: { startDateTime: '2022-07-26T01:00', endDateTime: '2022-07-26T08:30' },
      sleep: { startDateTime: '2022-07-26T03:00', endDateTime: '2022-07-26T08:00' },
      napTimeMinutes: 270,
      struggleTimeMinutes: 240,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-27',
      inBed: { startDateTime: '2022-07-27T02:00', endDateTime: '2022-07-27T09:00' },
      sleep: { startDateTime: '2022-07-27T04:00', endDateTime: '2022-07-27T08:00' },
      napTimeMinutes: 150,
      struggleTimeMinutes: 100,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-28',
      inBed: { startDateTime: '2022-07-28T00:00', endDateTime: '2022-07-28T10:00' },
      sleep: { startDateTime: '2022-07-28T03:00', endDateTime: '2022-07-28T09:30' },
      napTimeMinutes: 210,
      struggleTimeMinutes: 50,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-29',
      inBed: { startDateTime: '2022-07-28T22:30', endDateTime: '2022-07-29T10:00' },
      sleep: { startDateTime: '2022-07-29T01:00', endDateTime: '2022-07-29T07:30' },
      napTimeMinutes: 80,
      struggleTimeMinutes: 40,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-07-30',
      inBed: { startDateTime: '2022-07-29T22:00', endDateTime: '2022-07-30T10:00' },
      sleep: { startDateTime: '2022-07-30T01:00', endDateTime: '2022-07-30T09:00' },
      napTimeMinutes: 90,
      struggleTimeMinutes: 30,
      ruleList: [
        { ruleId: 1, isDone: true },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
    {
      date: '2022-08-04',
      inBed: { startDateTime: '2022-08-04T00:00', endDateTime: '2022-08-04T09:30' },
      sleep: { startDateTime: '2022-08-04T01:00', endDateTime: '2022-08-04T09:00' },
      napTimeMinutes: 0,
      struggleTimeMinutes: 50,
      ruleList: [
        { ruleId: 1, isDone: false },
        { ruleId: 2, isDone: false },
        { ruleId: 3, isDone: true },
      ],
    },
  ];

  return (
    loaded && (
      <div className={`w-full flex flex-col pb-[20px] pt-[44px] relative`} ref={scrollRef}>
        <SleepEfficiencyGraph />
        <div className={`px-[20px]  bg-[#060727] ${marginClassName}`} ref={contentRef}>
          <div className="pb-[50px]">
            <p className="text-[20px] leading-[26px] text-white font-bold">수면 처방</p>
            <p className="text-[15px] leading-[21px] text-[#A4A6B0] pt-[6px] pb-[20px]">
              수면 처방을 받으려면 수면 기록을 더 해주세요
            </p>
            <div className="flex gap-[21px] max-w-[400px] mx-auto">
              <div
                className="flex items-center w-full rounded-[20px]"
                style={{
                  background: 'linear-gradient(180deg, #222455 0%, #131542 100%)',
                }}
              >
                <img src={SleepCard} alt="empty" className="object-contain" />
              </div>
              <div
                className="flex items-center w-full rounded-[20px]"
                style={{
                  background: 'linear-gradient(180deg, #222455 0%, #404279 100%)',
                }}
              >
                <img src={WakeUpCard} alt="empty" className="object-contain" />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[10px] text-[12px] leading-[18px]">
            <p className="text-[20px] leading-[26px] text-white font-bold pb-[10px]">수면 기록</p>

            <SleepGraph recordByDateList={recordByDateList} dummyData={example} />
            <BarGraph recordByDateList={recordByDateList} dummyData={example} fieldName="struggleTimeMinutes" />
            <BarGraph recordByDateList={recordByDateList} dummyData={example} fieldName="napTimeMinutes" />
          </div>
        </div>
      </div>
    )
  );
}
