import React from 'react';
import coupon_2 from '../image/coupon_2.png';
import coupon_3 from '../image/coupon_3.png';

import CouponItem from '../components/checkin/CouponItem';

function CouponInfo() {
  return (
    <div className="w-screen mb-[20px] select-none">
      <CouponItem
        steps={[
          ['STEP 1', '원하는 선생님을 선택하고,', coupon_2],
          ['STEP 2', '결제 시점에 사용할 쿠폰을 선택하세요.', coupon_3],
        ]}
      />
    </div>
  );
}

export default CouponInfo;
