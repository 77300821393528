/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import { changeHeader } from '../../utils/webview';
import useFetch from '../../hooks/useFetch';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function StartOnboarding() {
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState('');
  const canClose = searchParams.get('can_close');
  const isCloseWithRefresh = searchParams.get('refresh');

  const [onboardingStep, getOnboardingStep] = useFetch({
    url: `/onboarding/step/detail`,
    bg: '#FFFFFF',
  });
  const goCurrentStep = useNavigateWithParams(`/onboardingPreview/${step}`);
  const goFinishOnboarding = useNavigateWithParams(`/finishOnboarding`);
  const context = useContext(AuthContext);

  useEffect(() => {
    if (onboardingStep) {
      console.log('current step: ', onboardingStep.result.completeStepNumber);
      setStep(onboardingStep.result.completeStepNumber);
      context.setCompleteStepNumber(onboardingStep.result.completeStepNumber);
    }
  }, [onboardingStep]);

  useEffect(() => {
    if (canClose === 'false') {
      changeHeader({ buttonType: 'none' });
      context.setCanCloseOnboarding(false);
    } else {
      changeHeader({ buttonType: 'close' });
    }

    if (isCloseWithRefresh === 'true') {
      context.setOnboardingRefresh(true);
    } else {
      context.setOnboardingRefresh(false);
    }
    window.document.body.style.backgroundColor = 'white';
    getOnboardingStep();
    context.setShowProgressingbar(false);
  }, []);

  useEffect(() => {
    if (step !== '') {
      if (step !== 0) {
        context.setShowOnboardingModal(true);
      }
      if (step >= 2) {
        goFinishOnboarding();
      } else {
        goCurrentStep();
      }
    }
  }, [step]);

  return <div className={`w-screen h-screen  `} />;
}
