/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useEffect } from 'react';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from '../../../recoil/common/header/atom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FullPageAnxy } from '../../../stories/page/FullPageAnxy';
import useLoadImage from '../../../hooks/useLoadImage';
import { keepScreenEvent } from '../../../utils/webview';
import sessionAtom from '../../../recoil/anxy/session/atom';
import statusAtom from '../../../recoil/anxy/home/statusAtom';
import { TopTitleBottomActionSomny } from '../../../stories/page/TopTitleBottomActionSomny';
import { SleepTailAudio } from './SleepTaleAudio';
import { SelectReadyButtonSomny } from '../../../components/somny/sleepTale/SelectReadyButtonSomny';
import { CheckLottie } from '../../../stories/animation/CheckLottie';
import useMixpanelEventSomny from '../../../hooks/useMixpanelEventSomny';
import { somnyFairyTaleData } from '../../../data/somny/somnyFairyTaleData';

export default function SleepTaleTemplate({ resultList }) {
  const [searchParams] = useSearchParams();
  const preloadImage = useLoadImage(somnyFairyTaleData.map((item) => item.image));

  const { taleId, id } = useParams();
  const [buttonText, setButtonText] = useState('다음');
  const [buttonState, setButtonState] = useState('ACTIVE');

  const [sessionState, setSessionState] = useRecoilState(sessionAtom);
  const [headerState, setHeaderState] = useRecoilState(headerAtom);

  const [bgColor, setBgColor] = useState('#060727');

  const statusState = useRecoilValue(statusAtom);

  const taleData = somnyFairyTaleData.find((item) => item.taleId === taleId);

  const setMixPanel = useMixpanelEventSomny({
    eventName: '동화 진입',
    params: {
      제목: taleData.title,
    },
  });

  const goNext = useNavigateWithParams(`/somnyTale/${taleId}/${parseInt(id) + 1}`);
  const goToPayment = useNavigateWithParams(`../somnyPayment`);

  const completedAtBeforePayment =
    sessionState.sessionList &&
    sessionState.sessionList.length !== 0 &&
    sessionState.sessionList.find((item) => item.sectionId === 'box_meditate_aa').completedAt;
  const goToComingSoon = useNavigateWithParams(`../anxyComingSoon?date=${completedAtBeforePayment}`);

  useEffect(() => {
    if (id === '0') {
      setMixPanel();
      preloadImage();
      // imagePreload();
      // imagePreload2();
    }
  }, [id]);

  const contentList = [
    {
      title: '잠에 들 준비를 마쳤나요?',
      content: (
        <div className="h-full flex items-center justify-center">
          <SelectReadyButtonSomny setButtonState={setButtonState} isSomny />
        </div>
      ),
      buttonText: '다음',
    },
    {
      isFullPage: true,
      content: (
        <div className="h-full">
          <SleepTailAudio setButtonState={setButtonState} setBgColor={setBgColor} action={goNext} />
        </div>
      ),
      bgColor: '#060727',
    },
    {
      content: (
        <div className="h-full flex flex-col items-center justify-center">
          <CheckLottie containerSize="77px" somny />
          <p className="text-[24px] leading-[29px] font-bold text-white pt-[30px]">동화를 마쳤어요</p>
        </div>
      ),
      buttonText: '확인',
      bgColor: '#060727',
    },
  ];

  useEffect(() => {
    if (isEnd) {
      setButtonText('완료');
    }
  }, [id]);

  const goToHome = useNavigateWithParams('/somnyHome');

  const isEnd = parseInt(id) === contentList.length - 1;

  const handleEnd = () => {
    keepScreenEvent({ isKeepScreenOn: false });
    goToHome();
  };

  const handleGoToPayment = () => {
    if (statusState.isInReview) {
      goToComingSoon();
    } else {
      goToPayment();
    }
  };

  const currentData = contentList[parseInt(id)];

  useEffect(() => {
    if (isEnd) {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE }));
    } else {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#FFFFFF', headerTitle: '' }));
    }
  }, [id]);
  return (
    <>
      {contentList[parseInt(id)].isFullPage ? (
        <FullPageAnxy
          title={currentData.title && currentData.title}
          subtitle={currentData.subtitle && currentData.subtitle}
          content={currentData.content}
          isBottom={currentData.isBottom}
          buttonText={buttonText}
          buttonState={buttonState}
          bgColor={'#060727'}
          action={goNext}
          insideBlack
          noHeader
        />
      ) : (
        <TopTitleBottomActionSomny
          title={currentData.title && currentData.title}
          subtitle={currentData.subtitle && currentData.subtitle}
          content={currentData.content}
          isBottom={currentData.isBottom}
          buttonText={currentData.buttonText ? currentData.buttonText : '확인'}
          buttonState={buttonState}
          bgColor={bgColor}
          action={isEnd ? handleEnd : goNext}
          insideBlack
          buttonHide={currentData.buttonHide}
          isApp
        />
      )}
    </>
  );
}
