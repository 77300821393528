import React, { useState, useEffect } from 'react';
import selfCheckData from '../../data/selfCheckData';
import TestResultChartLabel from './TestResultChartLabel';

export default function TestResultChart(props) {
  const { examName, isAbnormal, score, isSmall, normalColor } = props;
  const selfCheckInfo = selfCheckData.find((element) => element.selfCheckName === examName);
  const [result, setResult] = useState(0);
  const [ratio, setRatio] = useState(0);

  useEffect(() => {
    for (let i = selfCheckInfo.result.length - 1; i >= 0; i--) {
      if (score >= selfCheckInfo.result[i].min) {
        let min = selfCheckInfo.result[i].min;
        let max = selfCheckInfo.result[i].max;
        setResult(i);
        setRatio(((score - min) / (max - min)) * 100);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`w-full ${
        isSmall ? 'text-[8.47px] leading-[12.7px] tracking-[-0.21px]' : 'text-[12px] leading-[18px]'
      }  text-center font-normal text-[#A4A6B0] flex items-start`}
    >
      <div>{selfCheckInfo.minScore}</div>
      <div className="flex-1  mx-[2.5px] flex justify-between mt-[7px]">
        {selfCheckData
          .find((element) => element.selfCheckName === examName)
          .result.map((step, index) => (
            <TestResultChartLabel
              key={`testResult${index}`}
              width={`${100 / selfCheckInfo.result.length}%`}
              isCurrent={isSmall ? selfCheckInfo.result.length - 1 === index : result === index}
              isAbnormal={isAbnormal}
              score={score}
              ratio={isSmall ? 100 : ratio}
              label={step.label}
              isSmall={isSmall}
              normalColor={normalColor}
            />
          ))}
      </div>

      <div>{selfCheckInfo.maxScore}</div>
    </div>
  );
}
