import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';
import headerAtom, { BUTTON_BACK } from '../../recoil/common/header/atom';
import { RegexInput } from '../text/RegexInput';
import { RoundBottomAction } from './RoundBottomAction';

export function SubmitPhoneNumber(props) {
  const { action, isAnxy, isSomny, isLoomy, mixpanelEvent } = props;

  const [phoneNumber, setPhoneNumber] = useState('');
  const [isError, setIsError] = useState(false);

  const [buttonText, setButtonText] = useState('출시 알림 신청하기');
  const [buttonState, setButtonState] = useState('ACTIVE');

  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    if (phoneNumber && !isError) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [isError, phoneNumber]);

  const dataByType = [
    {
      projectName: 'anxy',
      textColor: '#26282C',
      bgColor: '#FFFFFF',
    },
    {
      projectName: 'somny',
      textColor: '#FFFFFF',
      bgColor: '#121B40',
    },
    {
      projectName: 'loomy',
      textColor: '#11383B',
      bgColor: '#F3F0E7',
    },
  ];

  const currentData = isAnxy ? dataByType[0] : isSomny ? dataByType[1] : dataByType[2];

  useEffect(() => {
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_BACK,
      headerColor: currentData.textColor,
      headerTitle: '',
    }));
  }, []);

  const setMixPanel = useMixpanelWeb({
    eventName: mixpanelEvent,
    projectName: currentData.projectName,
  });
  const handleAction = () => {
    setMixPanel();
    action();
  };

  return (
    <RoundBottomAction
      content={
        <div className="h-full flex flex-col">
          <div className={`text-[${currentData.textColor}] px-[20px] pt-[12px]`}>
            <p className={`text-[24px] leading-[30px] font-bold`}>{'23년 1월, 앱이 곧 출시돼요'}</p>
            <p className="font-light opacity-[0.8] text-[17px] leading-[28px] pt-[24px]">
              {'완성도를 높이는 마무리 작업을 진행하고 있어요. 출시 알림을 받으시려면 휴대폰 번호를 입력해주세요.'}
            </p>
          </div>
          <div className="flex-1 flex flex-col">
            <div className="px-[20px] pt-[50px]">
              <RegexInput
                value={phoneNumber}
                type="phone"
                updateValue={setPhoneNumber}
                placeholder="휴대폰 번호 (숫자만 입력해주세요)"
                updateError={setIsError}
              />
            </div>
          </div>
        </div>
      }
      noGradient
      buttonText={buttonText}
      buttonState={buttonState}
      action={handleAction}
      bgColor={currentData.bgColor}
      fixedRoot
      isAnxy={isAnxy}
      isSomny={isSomny}
      isLoomy={isLoomy}
    />
  );
}
