import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context';
import { MultipleSelectButton } from '../../stories/button/MultipleSelectButton';
import { TopTitleBottomAction } from '../../stories/page/TopTitleBottomAction';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function MultipleSelectTemplate(props) {
  const { category, question, options } = props;
  const [buttonState, setButtonState] = useState('INACTIVE');

  const navigate = useNavigate();
  const context = useContext(AuthContext);

  var step = [];
  if (category === 'onboarding1') {
    step = context.presurvey.subjectOfTreatmentList;
  } else if (category === 'coachMatching') {
  }

  const [answer, setAnswer] = useState(step);
  const goSelfCheck = useNavigateWithParams(`/onboardingPreview/1`);
  function goNext() {
    console.log('answer: ', answer);
    if (category === 'onboarding1') {
      context.presurvey['subjectOfTreatmentList'] = answer;
      navigate(`/onboardingPreview/1?app_id=${context.appId}`);
      goSelfCheck();
    }
    // else if (category === 'coachMatching') {
    //   setTimeout(() => {
    //     navigate(`/coachMatching/2`);
    //   }, 400);
    // }
  }

  function select(option) {
    const selected = option;
    if (answer.includes(selected)) {
      let filteredArray = answer.filter((item) => item !== selected);
      setAnswer(filteredArray);
    } else {
      setAnswer((answer) => [...answer, selected]);
    }
  }

  function isClicked(option) {
    if (option !== '') {
      console.log('이미클릭함');
      select(option);
    }
  }
  useEffect(() => {
    if (answer.length !== 0) {
      setButtonState('ACTIVE');
    } else {
      setButtonState('INACTIVE');
    }
  }, [answer]);

  const Element = (
    <div className={`h-full  pt-[43px] pb-[30px] px-[20px] `}>
      {options.map((option, index) => (
        <MultipleSelectButton
          key={index}
          selected={answer.includes(option)}
          option={option}
          text={option}
          isClicked={isClicked}
        />
      ))}
    </div>
  );

  return (
    <div className={`h-full `}>
      <TopTitleBottomAction
        title={question}
        content={Element}
        buttonText={'다음'}
        buttonState={buttonState}
        action={goNext}
      />
    </div>
  );
}
