import face1 from './images/face1.png';
import face2 from './images/face2.png';
import face3 from './images/face3.png';
import face4 from './images/face4.png';
import face5 from './images/face5.png';
import face6 from './images/face6.png';

const faceByScoreData = [
  {
    score: 0,
    image: face1,
  },
  {
    score: 20,
    image: face2,
  },
  {
    score: 40,
    image: face3,
  },
  {
    score: 60,
    image: face4,
  },
  {
    score: 80,
    image: face5,
  },
  {
    score: 100,
    image: face6,
  },
];

export default faceByScoreData;
