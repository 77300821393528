/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import webviewToast from '../../development/webviewToast';

export function PlaceholderInput(props) {
  const { placeholder, value, titleHeight, viewportSize, autofocus, updateValue, isAndroid } = props;

  const [isShort, setIsShort] = useState(false);
  const [isFirst, setFirst] = useState(true);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      const end = inputRef.current.value.length;
      inputRef.current.setSelectionRange(end, end);
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (viewportSize - titleHeight - 183 < 100) {
      console.log(viewportSize - titleHeight - 183);
      setIsShort(true);
    } else {
      setIsShort(false);
    }
  }, [viewportSize]);

  // useEffect(() => {
  //   inputRef.current.onfocus = () => {
  //     window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  //   };
  // });

  return (
    <div>
      <textarea
        enterkeyhint="next"
        id="message"
        className={`w-full text-[17px] leading-[23px] flex outline-none bg-[#FAFBFD] rounded-[10px] px-[20px] py-[14px] placeholder:text-[#A4A6B0] placeholder:text-[17px] placeholder:leading-[23px]  caret-[#3953D9] caret-[2px]
        ${isShort ? 'my-[20px]' : 'my-[40px]'}
        `}
        style={{
          // textAlign: 'center',
          WebkitOverflowScrolling: 'touch',
          wordBreak: 'keep-all',
          resize: 'none',
          height: isShort ? `${viewportSize - titleHeight - 189}px` : `${viewportSize - titleHeight - 232}px`,
          minHeight: '120px',
        }}
        placeholder={placeholder}
        value={value}
        ref={inputRef}
        onClick={(e) => {
          e.target.focus();
          window.scrollTo(0, 0);
        }}
        onChange={(e) => {
          updateValue(e.target.value);
        }}
        onBlur={(e) => {
          if (isAndroid && isFirst) {
            e.target.focus();
            setFirst(false);
          }
        }}
        autoFocus={autofocus}
      />
    </div>
  );
}
