import React from 'react';
import AnxyNotch from '../anxy/AnxyNotch';
import { useRecoilValue } from 'recoil';
import { marginWithHomeIndicator } from '../../recoil/common/device';
import { motion } from 'framer-motion';
import present from '../../image/inside/present.png';
import { LoomyActionButton } from '../../stories/button/RoundActionButton';

export function LoomySessionTemplate(props) {
  const {
    title,
    subtitle,
    content,
    additionalContent,
    buttonText,
    buttonState,
    action,
    topColor,
    noHeader,
    isBottom,
    bgColor,
    notchColor,
  } = props;

  const marginClassName = useRecoilValue(marginWithHomeIndicator);

  return (
    <div
      className="pt-safe"
      style={{
        background: bgColor,
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      {!noHeader && <AnxyNotch notchColor={notchColor ? notchColor : bgColor} />}
      <div className={`h-full ${!noHeader && 'pt-[44px]'} flex flex-col ${marginClassName}  `}>
        <div className={`flex-1 overflow-scroll scrollbar-hide flex flex-col`}>
          {title && (
            <div className="px-[20px] pt-[12px] text-center" style={{ backgroundColor: topColor }}>
              <p className="text-[20px] leading-[26px] font-bold text-[#26282C]]">{title}</p>
              {subtitle && typeof subtitle === 'string' && (
                <p className="font-light opacity-[0.8] text-white text-[17px] leading-[28px] pt-[24px]">{subtitle}</p>
              )}
              {subtitle && typeof subtitle !== 'string' && (
                <p className="pt-[24px]">
                  {subtitle.map((text, index) => (
                    <span
                      className={`${
                        index % 2 === 0 ? 'font-light' : 'font-bold'
                      } opacity-[0.8] text-white text-[17px] leading-[28px]`}
                    >
                      {text}
                    </span>
                  ))}
                </p>
              )}
            </div>
          )}
          <div className="flex-1 flex flex-col items-center justify-center object-contain relative">
            {content}
            {additionalContent}
          </div>
        </div>
        <div className={`flex-none w-full relative `}>
          <div className="px-[20px]">
            <div>
              {isBottom && (
                <div className="flex justify-center items-center pb-[10px]">
                  <img src={present} alt="empty" className="w-[143px]" />
                </div>
              )}
              <LoomyActionButton state={buttonState} text={buttonText} action={action} noGradient />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
