import { useState } from 'react';
import { goToScreen } from '../../utils/webview';

const SelectCounselorBySpecialty = ({ recommendList, isScrolling }) => {
  const [buttonDown, setButtonDown] = useState(false);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [clickedId, setClickedId] = useState();

  const specialtyList = [
    { name: '인간관계', idList: [1] },
    { name: '가족/연인', idList: [2] },
    { name: '우울・불안', idList: [3, 4] },
    { name: '직장・학교', idList: [7, 8] },
    { name: '스트레스', idList: [5] },
    { name: '자존감', idList: [6] },
    { name: '중독', idList: [9] },
    { name: '정체성', idList: [10] },
    { name: '섭식문제', idList: [11] },
  ];

  const pressedEnd = (idList) => {
    setButtonDown(false);
    goToScreen({
      screenName: 'counselorSearch',
      counselorSpecialtyIdList: idList,
    });
  };

  return (
    <div className={`px-[20px] mb-[60px]`}>
      <p className="text-[20px] leading-[26px] font-bold  mb-[13px]">분야별 선생님</p>
      <div
        className="overflow-hidden mb-[40px] bg-white rounded-[16px] text-[15px] leading-[21px] text-[#3A3C40]"
        style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}
      >
        {specialtyList.map((item, index) => (
          <div
            className={`flex items-center justify-center py-[16px] mb-[-2px] ml-[-2px]
            ${buttonDown && clickedId === index ? 'bg-[#F3F5F880]' : 'bg-white'} 
          `}
            style={{ borderBottom: '1px solid #F3F5F8', borderLeft: '1px solid #F3F5F8' }}
            onTouchStart={(e) => {
              setButtonDown(true);
              setClickedId(index);
              setTouchStartY(e.changedTouches[0].clientY);
              setTouchStartX(e.changedTouches[0].clientX);
            }}
            onTouchEnd={(e) => {
              if (
                document
                  .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
                  .includes(e.currentTarget) &&
                Math.abs(e.changedTouches[0].clientY - touchStartY) < 10 &&
                Math.abs(e.changedTouches[0].clientX - touchStartX) < 10
              ) {
                pressedEnd(item.idList);
              }
              setButtonDown(false);
            }}
            onTouchCancel={() => buttonDown(false)}
            onTouchEndCapture={() => buttonDown(false)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCounselorBySpecialty;
