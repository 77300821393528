import { useEffect } from 'react';
import { useState } from 'react';

const WorryNoteInput = ({ index, title, placeholder, currentRef, nextRef, param, data, setData }) => {
  const [text, setText] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  console.log(text);
  useEffect(() => {
    setText(data[param]);
  }, [data, param]);

  const handleChange = (e) => {
    let value = e.target.value;
    setText(value);

    const updated = { ...data };
    updated[param] = value;
    console.log(updated);
    setData(updated);
  };

  useEffect(() => {
    if (isFocus) {
      if (currentRef.current) {
        currentRef.current.style.border = '1px solid #000000';
      }
    } else {
      if (currentRef.current) {
        currentRef.current.style.border = '1px solid #D1D5DC';
      }
    }
  }, [isFocus]);

  return (
    <div key={title}>
      <p className="text-[20px] leading-[26px] font-bold mb-[12px]">{title}</p>
      <textarea
        ref={currentRef}
        enterkeyhint="next"
        className="w-full text-[17px] leading-[23px] flex outline-none bg-[#FAFBFD] rounded-[10px] px-[20px] py-[14px] placeholder:text-[#A4A6B0] placeholder:text-[17px] placeholder:leading-[23px]  caret-[#3953D9] caret-[2px]"
        placeholder={placeholder}
        style={{
          WebkitOverflowScrolling: 'touch',
          resize: 'none',
          height: '120px',
          overflowY: 'scroll',
        }}
        value={text}
        onChange={handleChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
    </div>
  );
};

export default WorryNoteInput;
