/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { RoundBottomAction } from '../../../stories/page/RoundBottomAction';
import { useParams } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import loomy from '../../../image/loomy/loomy.png';
import useMixpanelWeb from '../../../hooks/useMixpanelWeb';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';

export default function LoomyAnalysis(props) {
  const { typeData } = props;
  const { id } = useParams();

  const { selfCheckResult } = useRecoilValue(selfCheckAtom);

  const type = selfCheckResult[0].id;

  const currentTypeData = typeData.find((element) => element.id === type);
  const goNext = useNavigateWithParams(`/loomyTest/analysis${parseInt(id.substring(8)) + 1}`);
  const goWalkthrough = useNavigateWithParams(`/loomyWalkthrough/0`);
  const setHeaderState = useSetRecoilState(headerAtom);

  const setMixpanel = useMixpanelWeb({
    eventName: id === 'analysis1' ? '(LT) 검사 결과 2' : id === 'analysis2' ? '(LT) 검사 결과 3' : '(LT) 검사 결과 4',
    projectName: 'loomy',
  });

  useEffect(() => {
    console.log(selfCheckResult);
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK }));

    setMixpanel();
  }, []);

  return (
    <RoundBottomAction
      content={
        <div className="w-full h-full pt-[12px] text-[#11383B] px-[20px] flex flex-col gap-[32px]">
          {id !== 'analysis3' && (
            <div className="w-full rounded-[12px] bg-[#D9D8B7] p-[24px] ">
              <p className="text-[17px] leading-[24px]">
                내 우울의 원인은 <span className="font-bold">{currentTypeData.title}</span> {currentTypeData.image}
              </p>
            </div>
          )}
          <div className="flex-1 flex flex-col gap-[12px]">
            <p className="w-fit px-[8px] py-[4px] bg-[#11383B] rounded-[4px] tracking-[3px] text-[13px] text-white font-bold  ">
              {id === 'analysis1' ? 'WHY' : id === 'analysis2' ? 'KEY POINT' : 'SOLUTION'}
            </p>
            <p className="font-bold text-[24px]  ">
              {id === 'analysis1'
                ? currentTypeData.reason.title
                : id === 'analysis2'
                ? currentTypeData.keypoint.title
                : currentTypeData.solution.title}
            </p>
            <p className="text-[17px] leading-[24px] ">
              {id === 'analysis1'
                ? currentTypeData.reason.text
                : id === 'analysis2'
                ? currentTypeData.keypoint.text
                : currentTypeData.solution.text}
            </p>
            {id === 'analysis3' && (
              <div className="flex-1 flex items-center justify-center  ">
                <img src={loomy} alt={'empty'} className="max-w-[180px] mx-auto" />
              </div>
            )}
          </div>
        </div>
      }
      buttonText={id !== 'analysis3' ? '다음' : 'Loomy 앱 알아보기'}
      buttonBgColor={'#11383B'}
      buttonTextColor={'#ffffff'}
      action={id !== 'analysis3' ? goNext : goWalkthrough}
      bgColor="#F3F0E7"
      fixedRoot
    />
  );
}
