import React, { useContext, useEffect } from 'react';
import { CoachingContext } from '../../coachingContext';

import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { showWebView } from '../../utils/webview';

import { useModalDrag } from '../../hooks/useModalDrag';

export default function NotStartedModal() {
  const context = useContext(CoachingContext);

  const goToCourse = () => {
    showWebView({
      url: `course/${context.courseId}?at=${context.authToken}&app_id=${context.appId}`,
    });
    context.setShowModal(false);
  };

  const closeModal = () => {
    context.setShowModal(false);
  };

  const goToOnboarding = () => {
    showWebView({ url: `startOnboarding?at=${context.authToken}&app_id=${context.appId}&refresh=true` });

    context.setShowModal(false);
  };

  const data = [
    {
      type: 'NOT_STARTED',
      title: '먼저 프로그램을 세팅해주세요',
      info: '몇 가지 설정으로 맞춤 프로그램을 시작하세요.',
      buttonText: '세팅하기',
      action: () => goToOnboarding(),
    },
    {
      type: 'COMING_SOON',
      title: '준비 중인 코스의 도구예요',
      info: '코스 출시 후에 이용해주세요.',
      buttonText: '확인',
      action: () => closeModal(),
    },
    {
      type: 'NOT_ACHIEVE',
      title: '아직 획득하지 않은 도구예요',
      info: '코스를 진행하면서 획득할 수 있어요.',
      buttonText: '코스 알아보기',
      action: () => goToCourse(),
    },
  ];

  const dataPerType = data.find((item) => item.type === context.modalType);
  const { sheet, showModal } = useModalDrag(context.showModal);
  useEffect(() => {
    context.setShowModal(showModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <div
      className={`w-screen fixed opacity-100  bottom-[-100px] z-[30]`}
      style={{
        transform: `${context.showModal ? 'translateY(-100px)' : 'translateY(250px)'}`,
        transition: `all ${context.showModal ? 0.3 : 0.2}s ease-in-out`,
        WebkitOverflowScrolling: 'touch',
      }}
      // style={{ WebkitOverflowScrolling: 'touch',

      // }}
      // animate={{ bottom: context.showModal ? `0px` : '-200px' }}
      // transition={{ duration: context.showModal ? 0.3 : 0.2, ease: 'easeInOut' }}
    >
      <div
        ref={sheet}
        className={`w-full bg-[#FFFFFF] px-[20px] pt-[28px] text-[15px] leading-[18px] text-center pb-[20px]`}
        style={{
          borderRadius: '20px 20px 0px 0px',
          boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.05)',
        }}
      >
        <p className="text-[#26282C] text-[20px] leading-[24px] font-semibold">{dataPerType.title}</p>
        <p className="text-[#26282C] text-[17px] leading-[26px] text-[#A4A6B0] mt-[8px] mb-[24px]">
          {dataPerType.info}
        </p>
        <PrimaryActionButton state="ACTIVE" text={dataPerType.buttonText} action={dataPerType.action} />
      </div>
    </div>
  );
}
