import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { changeHeader, impactFeedback } from '../../utils/webview';
import { SelectButtonEmojiCircle } from '../../stories/button/SelectButtonEmojiCircle';
import feedbackQ from '../../data/feedbackQ';
import { close } from '../../utils/webview';
import { CoachingContext } from '../../coachingContext';
import axios from '../../utils/axios2';
import { motion } from 'framer-motion';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

export default function Feedback() {
  const [answer, setAnswer] = useState('');
  const [disable, setDisable] = useState(false);
  const context = useContext(CoachingContext);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('at');
  const appId = searchParams.get('app_id');
  const activityId = searchParams.get('activity_id');

  useEffect(() => {
    document.title = '피드백';

    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'close' });
  }, []);

  const feedbackSubmit = async () => {
    try {
      const feedbackData = JSON.stringify({
        activityId: activityId,
        choiceOfSatisfaction: context.userFeedback.choiceOfSatisfaction,
        choiceOfDifficulty: context.userFeedback.choiceOfDifficulty,
      });
      console.log('feedbackData: ', feedbackData);
      const response = await axios(3, authToken, appId).post(`/activity/feedback/submit`, feedbackData);
      console.log('오늘활동 피드백 submit', response.data);
      if (response.data.code === 0) {
        setTimeout(() => {
          impactFeedback('success');
          close({ refresh: true });
        }, 800);
      }
    } catch (error) {
      console.error('result', error);
    }
  };

  function isClicked(option) {
    if (option !== '') {
      setDisable(true);
      select(option);
    }
  }

  const goNext = useNavigateWithParams(`/feedback/${parseInt(id) + 1}`);
  function select(option) {
    console.log('answers: ', id);
    setAnswer(option.id);
    if (parseInt(id) === 0) {
      context.userFeedback['choiceOfSatisfaction'] = option.id;
    } else if (parseInt(id) === 1) {
      context.userFeedback['choiceOfDifficulty'] = option.id;
    }

    if (parseInt(id) < 1) {
      setTimeout(() => {
        goNext();
      }, 300);
    } else {
      console.log(context.userFeedback);
      feedbackSubmit();
    }
  }
  return (
    <div className={`w-screen h-screen flex flex-col items-center justify-center bg-[#EFF2FF] `}>
      {parseInt(id) < 2 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: 'linear', delay: parseInt(id) === 0 ? 0.2 : 0 }}
          exit={{ opacity: 0 }}
          className="w-full translate-y-[-40px]"
        >
          <div
            className="w-full px-[20px]  font-bold text-[24px] leading-[28.8px]  text-center mb-[20px] "
            style={{ wordBreak: 'keep-all' }}
          >
            {feedbackQ[id].question}
          </div>

          <div
            className={`w-full ${
              feedbackQ[id].options.length === 2 ? 'w-[200px] ' : 'w-[275px]'
            }  flex justify-between  mx-auto shrink-0`}
          >
            {feedbackQ[id].options.map((option, index) => (
              <SelectButtonEmojiCircle
                key={`option${index}`}
                selected={answer === option.id}
                option={option}
                text={option.label}
                isClicked={isClicked} //버튼 컴포넌트에서 알려줌
                disable={disable} // 버튼 컴포넌트로 알려줌
                emoji={option.emoji}
              />
            ))}
          </div>
        </motion.div>
      )}
    </div>
  );
}
