/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Onboarding2Template2 from '../../components/onboarding/Onboarding2Template';
import MiddleTemplate from '../../components/onboarding/MiddleTemplate';
import useFetch from '../../hooks/useFetch';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';

import { AuthContext } from '../../context';
import { changeHeader } from '../../utils/webview';
import Test from '../../development/Test';

function Onboarding2() {
  let { exam_id, qc_id } = useParams();
  const [selfCheckData, setSelfCheckData] = useState([]);
  const [skip, setSkip] = useState(false);
  const context = useContext(AuthContext);

  useEffect(() => {
    if (parseInt(qc_id) > 0) {
      document.title = `${context.selfcheckQuestion[parseInt(exam_id)].selfCheckName} ${qc_id}`;
    } else {
      document.title = `${context.selfcheckQuestion[parseInt(exam_id)].selfCheckName} 검사 시작`;
    }
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [selfCheckSubmitResult, selfCheckSubmit] = useFetch({
    url: `/onboarding/self-check/submit`,
    requestBody: selfCheckData,
    bg: '#FFFFFF',
  });

  function submit() {
    console.log('answers: ', exam_id, context.selfcheckAnswer, context.selfcheckQuestion);
    const idx = context.selfcheckAnswer.findIndex(
      (element) => element.selfCheckId === context.selfcheckQuestion[parseInt(exam_id) - 1].selfCheckId,
    );
    console.log(idx, context.selfcheckAnswer.slice(0, idx + 1));
    const selfCheckData = JSON.stringify({
      selfCheckQcSubmitList: context.selfcheckAnswer.slice(0, idx + 1),
    });
    // console.log(idx, context.selfcheckQuestion, context.selfcheckAnswer, selfCheckData);
    setSelfCheckData(selfCheckData);
  }

  useEffect(() => {
    if (selfCheckData.length > 0) {
      selfCheckSubmit();
    }
  }, [selfCheckData]);

  useEffect(() => {
    changeHeader({ buttonType: 'back' });
    if (parseInt(qc_id) === 0) {
      context.setShowProgressingbar(false);
      if (parseInt(exam_id) > 0) {
        submit();
      }
    } else {
      context.setShowProgressingbar(true);
    }

    context.setMaxCompleted(context.selfcheckQuestion[parseInt(exam_id)].qcList.length);
    context.setCompleted(parseInt(qc_id));
  }, []);
  const tempData = [
    {
      selfCheckId: 1,
      selfCheckQcIdList: [
        { selfCheckChoiceId: 4, selfCheckQuestionId: 1 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 2 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 3 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 4 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 5 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 6 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 7 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 8 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 9 },
      ],
    },
    {
      selfCheckId: 2,
      selfCheckQcIdList: [
        { selfCheckChoiceId: 4, selfCheckQuestionId: 10 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 11 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 12 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 13 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 14 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 15 },
        { selfCheckChoiceId: 4, selfCheckQuestionId: 16 },
      ],
    },
    {
      selfCheckId: 3,
      selfCheckQcIdList: [
        { selfCheckChoiceId: 5, selfCheckQuestionId: 17 },
        { selfCheckChoiceId: 5, selfCheckQuestionId: 18 },
        { selfCheckChoiceId: 5, selfCheckQuestionId: 19 },
        { selfCheckChoiceId: 5, selfCheckQuestionId: 20 },
        { selfCheckChoiceId: 5, selfCheckQuestionId: 21 },
        { selfCheckChoiceId: 5, selfCheckQuestionId: 22 },
        { selfCheckChoiceId: 5, selfCheckQuestionId: 23 },
        { selfCheckChoiceId: 5, selfCheckQuestionId: 24 },
        { selfCheckChoiceId: 5, selfCheckQuestionId: 25 },
        { selfCheckChoiceId: 5, selfCheckQuestionId: 26 },
      ],
    },
    {
      selfCheckId: 4,
      selfCheckQcIdList: [
        { selfCheckChoiceId: 2, selfCheckQuestionId: 27 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 28 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 29 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 30 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 31 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 32 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 33 },
      ],
    },
    {
      selfCheckId: 5,
      selfCheckQcIdList: [
        { selfCheckChoiceId: 2, selfCheckQuestionId: 34 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 35 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 36 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 37 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 38 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 39 },
      ],
    },
    {
      selfCheckId: 6,
      selfCheckQcIdList: [
        { selfCheckChoiceId: 2, selfCheckQuestionId: 40 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 41 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 42 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 43 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 44 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 45 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 46 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 47 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 48 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 49 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 50 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 51 },
      ],
    },
    {
      selfCheckId: 7,
      selfCheckQcIdList: [
        { selfCheckChoiceId: 2, selfCheckQuestionId: 52 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 53 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 54 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 55 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 56 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 57 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 58 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 59 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 60 },
        { selfCheckChoiceId: 3, selfCheckQuestionId: 61 },
      ],
    },
  ];
  const goSkip = () => {
    context.setSelfcheckAnswer(tempData);
    setSkip(true);
  };
  const goEndTest = useNavigateWithParams(`/endtest`);
  useEffect(() => {
    if (skip) {
      goEndTest();
    }
  }, [skip]);

  console.log(qc_id);
  return (
    <div className={`w-screen ${parseInt(qc_id) === 0 ? 'h-screen' : 'h-[calc(100vh-17px)]'}  `}>
      <Test
        element={
          parseInt(qc_id) !== 0 && (
            <div className="flex justify-end">
              <p
                className="mr-10px mt-10px z-[10] bg-[#3953D9] w-[40px] flex justify-center text-white "
                onClick={goSkip}
              >
                스킵
              </p>
            </div>
          )
        }
      />

      {parseInt(qc_id) === 0 && <MiddleTemplate id={parseInt(exam_id)} />}
      {parseInt(qc_id) !== 0 && <Onboarding2Template2 exam_id={parseInt(exam_id)} qc_id={parseInt(qc_id)} />}
    </div>
  );
}

export default Onboarding2;
