/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SelfCheckTemplate from '../../../components/selfCheck/SelfCheckTemplate';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import deviceAtom from '../../../recoil/common/device';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import useMixpanelWeb from '../../../hooks/useMixpanelWeb';

export default function AnxyWebQA(props) {
  const { qcList } = props;

  const setHeaderState = useSetRecoilState(headerAtom);
  const deviceState = useRecoilValue(deviceAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const [selfCheckState, setSelfCheckState] = useRecoilState(selfCheckAtom);

  const setMixpanel = useMixpanelWeb({ eventName: '(AT) 검사 시작', projectName: 'anxy' });

  let { id } = useParams();
  id = parseInt(id);

  const [answer, setAnswer] = useState('');
  const [disable, setDisable] = useState(false);

  const goNextQuestion = useNavigateWithParams(`/anxyWebTest/${parseInt(id) + 1}`);
  const goEndTest = useNavigateWithParams(`/anxyWebTest/end`);
  const goFirst = useNavigateWithParams('/anxyWebTest/0');

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerColor: '#26282C' }));
    if (!qcList[id - 1]) {
      console.log(qcList);
      goFirst();
    }
  }, []);

  useEffect(() => {
    if (
      selfCheckState.selfCheckAnswer.find(
        (element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId,
      )
    ) {
      const selected = selfCheckState.selfCheckAnswer.find(
        (element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId,
      );
      console.log(selected.selfCheckChoiceId);
      setAnswer(selected.selfCheckChoiceId);
    }
  }, []);

  function select(option) {
    setAnswer(option.selfCheckChoiceId);
    var arr1 = selfCheckState.selfCheckAnswer.slice();

    if (arr1.find((element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId)) {
      arr1 = arr1.filter((element) => element.selfCheckQuestionId !== qcList[id - 1].selfCheckQuestionId);
    }
    if (option.score !== undefined) {
      arr1.push({
        selfCheckQuestionId: qcList[id - 1].selfCheckQuestionId,
        selfCheckChoiceId: option.selfCheckChoiceId,
        score: option.score,
      });
    } else {
      arr1.push({
        selfCheckQuestionId: qcList[id - 1].selfCheckQuestionId,
        selfCheckChoiceId: option.selfCheckChoiceId,
      });
    }

    console.log('answers: ', arr1);
    setSelfCheckState((state) => ({ ...state, selfCheckAnswer: arr1 }));

    setTimeout(() => {
      if (parseInt(id) < qcList.length) {
        // console.log('다음 문항으로');
        goNextQuestion();
      } else if (parseInt(id) === qcList.length) {
        goEndTest();
      }
    }, 400);
  }

  function isClicked(option) {
    if (option !== '') {
      setDisable(true);
      select(option);
    }
  }

  useEffect(() => {
    setProgressBarState({ isProgressBarVisible: true, maxCompleted: qcList.length, completed: parseInt(id) });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerTitle: '' }));
    if (id === 1) {
      setMixpanel();
    }
  }, []);

  return (
    <div className={`w-screen bg-white `} style={{ height: window.innerHeight - (deviceState.notchSize + 44 + 17) }}>
      {qcList[id - 1] && (
        <SelfCheckTemplate
          question={qcList[id - 1].question}
          optionList={qcList[id - 1].choices}
          answer={answer}
          isClicked={isClicked}
          disable={disable}
          anxy
        />
      )}
    </div>
  );
}
