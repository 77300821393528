import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { hasTouch } from 'detect-touch';
import { motion } from 'framer-motion';

export function SelectButtonEmojiCircle(props) {
  const { selected, option, text, isClicked, disable, emoji } = props;
  const [buttonDown, setButtonDown] = useState(false);

  function mouseUp(e) {
    setButtonDown(false);
  }

  useEffect(() => {
    // console.log(hasTouch);
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  return (
    <div className={`w-[75px] flex flex-col items-center `}>
      <motion.div animate={{ scale: buttonDown && !disable ? 0.95 : 1 }} transition={{ duration: 0.2 }}>
        <div
          className={`w-[75px] h-[75px] rounded-[75px] 
                mb-[10px] flex items-center flex justify-center items-center bg-[rgba(152,163,224,0.1)] relative
           `}
          onMouseDown={(e) => {
            if (!hasTouch) {
              console.log('mousedown');
              setButtonDown(true);
            }
          }}
          onClick={(e) => {
            if (!hasTouch && !disable) {
              console.log('action ');
              isClicked(option);
            }
          }}
          onTouchStart={(e) => {
            if (!buttonDown && !disable) {
              setButtonDown(true);
            }
          }}
          onTouchEnd={(e) => {
            setButtonDown(false);

            if (
              document
                .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
                .includes(e.currentTarget) &&
              !disable
            ) {
              console.log('click');
              isClicked(option);
            }
          }}
        >
          <div
            className={`w-full h-full absolute rounded-[75px] bg-qna ${
              selected ? 'scale-100' : 'scale-0'
            } transition-all`}
          />
          <div className={`w-[35px] h-[35px] rounded-[35px]  pointer-events-none absolute`}>
            <img src={emoji} alt={'empty'} className="w-full h-full" />
          </div>
        </div>
      </motion.div>
      <div
        className={`select-none text-[15px]  leading-[18px] whitespace-nowrap 
       ${selected ? ' font-bold text-[#2C4BEC] ' : ' font-normal text-[#80838B]'}`}
      >
        {text}
      </div>
    </div>
  );
}
