import { useCallback } from 'react';
import selfCheckData from '../../data/selfCheckData';
import { useGetWorkbookParams } from '../../hooks/useGetWorkbookParams';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { showWebView } from '../../utils/webview';
import TestResultChart from '../selfCheck/TestResultChart';

export const OnboardingNotStarted = ({ onboardingCase }) => {
  const { selfCheckId, title } = onboardingCase;
  console.log(selfCheckId);
  const recommendCheckItem = selfCheckData.filter((item) => item.selfCheckId === selfCheckId)[0];
  const [authToken, appId] = useGetWorkbookParams();

  const goToOnboarding = useCallback(() => {
    showWebView({ url: `startOnboarding?at=${authToken}&app_id=${appId}&refresh=true` });
  }, [authToken, appId]);
  return (
    <div className="">
      <div className="w-full relative bg-white rounded-[20px] overflow-hidden">
        <div className="w-full h-full px-[20px] pt-[30px] pb-[20px] flex flex-col items-between">
          <p className=" w-full px-[4px] text-[#26282C] font-bold leading-[28px] text-[22px]  ">{title}</p>
          <div className="w-full h-[237px] overflow-hidden px-[15px]">
            <div
              className="h-[403px] pl-[13.6px] pr-[14.4px] pt-[20px] flex flex-col items-center px-[15px] rounded-[15px] bg-[#EDF3FB] mt-[25px] text-[#26282C] text-[15px] leading-[21px] font-bold"
              style={{
                border: '0.8px solid rgba(0,0,0,0.1)',
                boxShadow: '0px 16px 32px -16px rgba(55, 67, 90, 0.4)',
              }}
            >
              <div>🚨 지금 바로 관리가 필요해요</div>
              <div className="w-full bg-white rounded-[11.2873px] mt-[20px] py-[17px]">
                <p className="font-bold ml-[16.93px] text-[#EF3E3E] text-[14px] leading-[18.34px] tracking-[-0.21px]">
                  {recommendCheckItem.selfCheckName}
                </p>
                <div className="pl-[15.13px] pr-[10.13px] mt-[15.13px]">
                  <TestResultChart
                    examName={recommendCheckItem.selfCheckName}
                    isAbnormal
                    score={recommendCheckItem.maxScore}
                    isSmall
                  />
                </div>
              </div>
              <div className="w-full h-[100px] bg-white rounded-[11.2873px] mt-[5.8px] py-[17px]">
                <p className="font-bold ml-[16.93px] text-[#EF3E3E] text-[14px] leading-[18.34px] tracking-[-0.21px]">
                  번아웃
                </p>
              </div>
            </div>
          </div>
          <div>
            <PrimaryActionButton state="ACTIVE" text={'종합검진 받기'} action={goToOnboarding} medium />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingNotStarted;
