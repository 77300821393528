import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoachingContext } from '../../../coachingContext';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { SecondaryActionButton } from '../../../stories/button/ActionButton';
import sleepingReportSurveyData from '../../../data/sleepingReportSurveyData';
import moment, { defaultFormat } from 'moment';
import 'moment/locale/ko';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { impactFeedback } from '../../../utils/webview';
import SlideTimePickerWithArea from './SlideTimePickerWithArea';

const defaultValue = {
  inBed: {
    endDateTime: '',
    startDateTime: '',
  },
  napTimeMinutes: 0,
  sleep: {
    endDateTime: '',
    startDateTime: '',
  },
  struggleTimeMinutes: 0,
};

const isoFormat = 'YYYY-MM-DDTHH:mm:ss';

const getTimeDiff = (startTime, endTime) => {
  return moment.duration(moment(endTime).diff(startTime));
};

// start: 48시간 전, end: 현재 시각(분은 내림), selected: 이전 기록

export default function SurveyInNap({ type, isMarketing }) {
  const { id, page } = useParams();

  const [sleepingSurvey, setSleepingSurvey] = useSessionStorage('sleepingSurvey', defaultValue);
  const context = useContext(CoachingContext);

  const beforeTime = moment(sleepingSurvey.inBed.startDateTime).subtract({ hours: 12 }).format(isoFormat);
  const [startTime, setStartTime] = useState(beforeTime);
  const [endTime, setEndTime] = useState(sleepingSurvey.inBed.startDateTime);
  const [selectedTime, setSelectedTime] = useState(beforeTime);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [prevTime, setPrevTime] = useState(0);

  console.log('시작, 끝', startTime, endTime);

  useEffect(() => {
    if (sleepingSurvey.napTimeMinutes !== 0) {
      const updateTime = moment(startTime).add({ minutes: prevTime }).format(isoFormat);
      console.log('prev', startTime, updateTime);
      setSelectedTime(updateTime);
      setSelectedMinute(sleepingSurvey.napTimeMinutes);
      setPrevTime(sleepingSurvey.napTimeMinutes);
    }
  }, [sleepingSurvey]);

  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);
  const goNextInMarketing = useNavigateWithParams(`/sleepChallengers/${parseInt(id) + 1}`);

  const reportData = sleepingReportSurveyData.find((item) => item.type === type);

  const sendFeedback = () => {
    impactFeedback('selection');
  };

  const changeSelectedTime = (changedTime) => {
    console.log('시간 바뀜 ', changedTime);
    setSelectedTime(() => changedTime);

    const minDiff = getTimeDiff(startTime, changedTime);
    const minuteDiffDivByTen = minDiff.asMinutes();
    setSelectedMinute(() => minuteDiffDivByTen);
  };

  useEffect(() => {
    sendFeedback();
  }, [selectedTime]);

  const submit = () => {
    const updated = { ...sleepingSurvey };
    updated.napTimeMinutes = selectedMinute;
    console.log('updated:', updated);
    setSleepingSurvey(updated);

    setTimeout(() => {
      if (isMarketing) {
        goNextInMarketing();
      } else {
        goNext();
      }
    }, 400);
  };

  console.log('sleeping survey: ', sleepingSurvey);

  return (
    <div className={`w-full ${isMarketing ? 'h-full' : 'h-[calc(100vh-17px)]'} overflow-hidden`}>
      <div className={`flex flex-col justify-between w-full h-full overflow-auto scrollbar-hide`}>
        <div className="px-[20px] ">
          <div
            className="flex-none font-bold text-[24px] leading-[30px]  text-[#26282C]  mt-[50px] "
            style={{ wordBreak: 'keep-all' }}
          >
            {reportData.title}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-[110px]">
          <div className="w-[30px]">
            <img className="" src={reportData.icon} alt="empty" />
          </div>
          <p className="mt-[10px] font-bold text-[40px] leading-[48px]">{`${
            selectedMinute / 60 >= 1 && selectedMinute % 60 !== 0
              ? `${parseInt(selectedMinute / 60)}시간 ${selectedMinute % 60}분`
              : selectedMinute / 60 >= 1
              ? `${parseInt(selectedMinute / 60)}시간`
              : `${selectedMinute}분`
          }`}</p>
        </div>
        <div className={`${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'} w-full rounded-[16px]`}>
          <div className="relative w-screen">
            <SlideTimePickerWithArea
              startTime={startTime}
              endTime={endTime}
              selectedTime={selectedTime}
              changeSelectedTime={changeSelectedTime}
              prevTime={prevTime}
            />
          </div>
          <div className={`w-full px-[20px] mt-[50px] ${isMarketing ? 'pb-[20px]' : ''}`}>
            <SecondaryActionButton state="ACTIVE" text={'다음'} action={submit} />
          </div>
        </div>
      </div>
    </div>
  );
}
