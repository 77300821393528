import React, { useState, useEffect } from 'react';
import selfCheckData from '../../data/selfCheckData';
import { getSelfCheckState } from '../selfCheck/selfCheckLogic';

export default function GoalSwiperContent(props) {
  const { step } = props;
  const [goalStep, setGoalStep] = useState('');

  useEffect(() => {
    const selfCheckInfo = selfCheckData.find((element) => element.selfCheckName === step.selfCheckName);
    setGoalStep(selfCheckInfo.result[getSelfCheckState(selfCheckInfo.selfCheckId, parseInt(step.score * 0.45))].label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const graphExposeRange = 160;
  return (
    <div className="px-[50px] mb-[93px]">
      <div className="mb-[15px]">
        <p className="text-[20px] leading-[26px] font-bold text-[#26282C] mb-[5px]">{step.selfCheckName}</p>
        <p className="text-[16px] leading-[22px] font-normal text-[#80838B]"> {goalStep} 범위로 개선할 수 있어요</p>
      </div>
      <div className=" h-[220px] pb-[10px] pt-[34px] border-t-[#E1E4EB] border-b-[#A4A6B0] border-y-[1px] relative">
        {selfCheckData
          .find((element) => element.selfCheckName === step.selfCheckName)
          .result.map(
            (each, index) =>
              index <
                selfCheckData.find((element) => element.selfCheckName === step.selfCheckName).result.length - 1 && (
                <div
                  key={`goal${index}`}
                  className="w-full h-[1px] bg-[#E1E4EB] opacity-50 absolute "
                  style={{
                    top: `${
                      (100 /
                        selfCheckData.find((element) => element.selfCheckName === step.selfCheckName).result.length) *
                      (index + 1)
                    }%`,
                  }}
                />
              ),
          )}
        <div className="h-full  flex justify-center  relative">
          <div
            className="z-0 w-[2px] absolute left-[50%] translate-x-[-72px] bottom-[-10px]  overflow-hidden"
            style={{ height: (graphExposeRange * step.scorePercentage) / 100 + 10 }}
          >
            <svg
              width="2"
              height={`${(graphExposeRange * step.scorePercentage) / 100 + 10}`}
              viewBox={`0 0 2 ${(graphExposeRange * step.scorePercentage) / 100 + 10}`}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={`M 1,${(graphExposeRange * step.scorePercentage) / 100 + 10} L 1,0`}
                stroke="#D1D5DC"
                strokeDasharray="4"
              />
            </svg>
          </div>
          <div className=" absolute left-[50%] translate-x-[-86px] bottom-[-43px] whitespace-nowrap text-[18px] leading-[24px] font-normal text-[#80838B]">
            오늘
          </div>

          <div
            className="z-0 w-[2px] absolute left-[50%] translate-x-[70px] bottom-[-10px] "
            style={{ height: ((graphExposeRange * step.scorePercentage) / 100) * 0.45 + 10 }}
          >
            <svg
              width="2"
              height={`${((graphExposeRange * step.scorePercentage) / 100) * 0.45 + 10}`}
              viewBox={`0 0 2 ${((graphExposeRange * step.scorePercentage) / 100) * 0.45 + 10}`}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={`M 1,${((graphExposeRange * step.scorePercentage) / 100) * 0.45 + 10} L 1,0`}
                stroke="#2C4BEC"
                strokeDasharray="4"
              />
            </svg>
          </div>
          <div className=" absolute left-[50%] translate-x-[57px] bottom-[-43px] whitespace-nowrap text-[18px] leading-[24px] font-bold text-[#26282C]">
            목표
          </div>
          <div
            className={`z-20 absolute left-[50%] translate-x-[calc(-50%-71px)] `}
            style={{ bottom: (graphExposeRange * step.scorePercentage) / 100 }}
          >
            <div className="relative">
              <div className=" w-[14px] h-[14px] rounded-[10px] border-[2px] border-[#EFF2FF] bg-qna" />
              <div className="absolute top-[-27px] left-[50%] translate-x-[-50%] text-[15px] leading-[21px] font-normal text-[#3A3C40] mb-[3px]">
                {step.score}
              </div>
            </div>
          </div>
          <div
            className="z-20 absolute left-[50%] translate-x-[calc(-50%+71px)] "
            style={{ bottom: ((graphExposeRange * step.scorePercentage) / 100) * 0.45 }}
          >
            <div className="relative">
              <div className=" w-[14px] h-[14px] rounded-[10px] border-[2px] border-[#EFF2FF] bg-qna" />
              <div className="absolute top-[-49px] left-[50%] translate-x-[-50%] text-[15px] leading-[21px] font-normal text-[#3A3C40] mb-[3px] ">
                <div className="relative">
                  <p className="absolute top-[5px] left-[50%] translate-x-[-50%] text-[15px] leading-[21px] font-bold text-white">
                    {parseInt(step.score * 0.45)}
                  </p>
                  <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="43" height="33" rx="16.5" fill="#3A3C40" />
                    <path
                      d="M23.0522 38.0896C22.2518 39.0747 20.7482 39.0747 19.9478 38.0896L15 32L28 32L23.0522 38.0896Z"
                      fill="#3A3C40"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className=" absolute " style={{ bottom: ((graphExposeRange * step.scorePercentage) / 100) * 0.45 }}>
            <div className="relative " style={{ height: ((graphExposeRange * step.scorePercentage) / 100) * 0.55 }}>
              <div className="translate-y-[-9px]">
                <svg
                  width="144"
                  height={`${((graphExposeRange * step.scorePercentage) / 100) * 0.55 + 5}`}
                  viewBox={`0 0 144 ${((graphExposeRange * step.scorePercentage) / 100) * 0.55 + 5}`}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d={
                      step.score === 0
                        ? `M 0,2 L 144,2`
                        : `M 0,2 C 60 0 81 ${((graphExposeRange * step.scorePercentage) / 100) * 0.55 - 2 + 5} 144 ${
                            ((graphExposeRange * step.scorePercentage) / 100) * 0.55 - 2 + 5
                          }`
                    }
                    stroke="url(#paint0_radial_317_8762)"
                    strokeWidth="3px"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_317_8762"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(143.759 40.088) rotate(-171.957) scale(145.187 692.013)"
                    >
                      <stop stopColor="#5513F0" />
                      <stop offset="1" stopColor="#2C4BEC" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
