import React from 'react';
import { body1 } from '../../resources/course-5';

export default function Script() {
  return (
    <div className={`w-screen  px-[20px] pt-[40px] pb-[74px] text-[15px] leading-[18px]  `}>
      <div className="text-[17px] leading-[28px] text-[#4E4F53] ">
        {`안녕하세요. 오늘도 잘 찾아오셨습니다. 조금씩 수면이 개선되는 느낌이 드는지 모르겠네요. 별다른 진척이 없는 것 같더라도 아직 코스의 절반도 지나지 않았으니 조금만 더 참고 진행해보시길 바랍니다. 분명 많은 도움이 있을거예요. 
          \n\n${body1}`}
      </div>
    </div>
  );
}
