/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { AuthContext } from '../../context';

import BetterMeStart from './BetterMeStart';
import BetterMeQA from './BetterMeQA';
import BetterMeEnd from './BetterMeEnd';
import BetterMeAnalysis from './BetterMeAnalysis';

export default function BetterMe() {
  let { state, id } = useParams();
  const context = useContext(AuthContext);

  const choices = [
    { selfCheckChoiceId: 1, score: 0.1, choice: '전혀' },
    { selfCheckChoiceId: 2, score: 0.2, choice: '거의 아님' },
    { selfCheckChoiceId: 3, score: 0.3, choice: '가끔' },
    { selfCheckChoiceId: 4, score: 1, choice: '자주' },
    { selfCheckChoiceId: 5, score: 2, choice: '매우 자주' },
    { selfCheckChoiceId: 6, score: 3, choice: '항상' },
  ];

  const qcList = [
    {
      id: 1,
      question: '살이 찌는 것이 두렵다.',
    },
    {
      id: 2,
      question: '배가 고파도 식사를 하지 않는다.',
    },
    {
      id: 3,
      question: '나는 음식에 집착하고 있다.',
    },
    {
      id: 4,
      question: '억제할 수 없이 폭식을 한 적이 있다.',
    },
    {
      id: 5,
      question: '음식을 작은 조각으로 나누어 먹는다.',
    },
    {
      id: 6,
      question: '내가 먹고 있는 음식의 영양분과 열량을 알고 먹는다.',
    },
    {
      id: 7,
      question: '빵이나 감자 같은 탄수화물이 많은 음식은 특히 피한다.',
    },
    {
      id: 8,
      question: '내가 음식을 많이 먹으면 다른 사람들이 좋아하는 것 같다.',
    },
    {
      id: 9,
      question: '먹고 난 다음 토한다.',
    },
    {
      id: 10,
      question: '먹고 난 다음 심한 죄책감을 느낀다.',
    },
    {
      id: 11,
      question: '좀 더 날씬해져야겠다는 생각을 떨쳐 버릴 수가 없다.',
    },
    {
      id: 12,
      question: '운동을 할 때 운동으로 인해 없어질 열량에 대해 계산하거나 생각한다.',
    },
    {
      id: 13,
      question: '남들이 내가 너무 말랐다고 생각한다.',
    },
    {
      id: 14,
      question: '내가 살이 쪘다는 생각을 떨쳐 버릴 수가 없다.',
    },
    {
      id: 15,
      question: '식사시간이 다른 사람보다 더 길다.',
    },
    {
      id: 16,
      question: '설탕이 든 음식은 피한다.',
    },
    {
      id: 17,
      question: '체중조절을 위해 다이어트용 음식을 먹는다.',
    },
    {
      id: 18,
      question: '음식이 나의 인생을 지배한다는 생각이 든다.',
    },
    {
      id: 19,
      question: '음식에 대한 자신의 조절능력을 과시한다.',
    },
    {
      id: 20,
      question: '다른 사람들이 나에게 음식을 먹도록 강요하는 것 같이 느껴진다.',
    },
    {
      id: 21,
      question: '음식에 대해 많은 시간과 에너지를 투자한다.',
    },
    {
      id: 22,
      question: '단 음식을 먹고 나면 마음이 편치 않다.',
    },
    {
      id: 23,
      question: '체중을 줄이기 위해 운동이나 다른 것을 하고 있다.',
    },
    {
      id: 24,
      question: '위가 비어 있는 느낌이 좋다.',
    },
    {
      id: 25,
      question: '새로운 기름진 음식 먹는 것을 즐긴다.',
    },
    {
      id: 26,
      question: '식사 후에 토하고 싶은 충동을 느낀다.',
    },
  ];
  return (
    <div className={`w-screen  ${context.showProgressingbar ? 'h-[calc(100vh-17px)]' : 'h-full'}  `}>
      {parseInt(id) === 0 && <BetterMeStart state={state} />}
      {parseInt(id) > 0 && !isNaN(parseInt(id)) && <BetterMeQA choices={choices} qcList={qcList} state={state} />}
      {id === 'end' && <BetterMeEnd state={state} />}
      {id === 'analysis' && <BetterMeAnalysis state={state} />}
    </div>
  );
}
