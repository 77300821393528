/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Progressbar from 'react-js-progressbar';
import sleepReportBg from '../../../image/somny/sleepReport_bg.png';

export default function SleepEfficiencyGraph(props) {
  const { efficiency, recordByDateList } = props;

  return (
    <div className="w-full relative overflow-hidden">
      <div className="absolute top-[-3px] pl-[18px] pr-[28.54px]">
        <img src={sleepReportBg} alt="empty" className="object-contain" />
      </div>
      <div className="px-[20px] pt-[24px] pb-[60px]">
        <div className="w-[180px] h-[180px] mx-auto mb-[15px] relative" id="selfCheckEnd">
          <Progressbar
            input={efficiency ? efficiency : 0}
            pathWidth={20}
            pathColor={'#2C4BEC'} // use an array for gradient color.
            trailWidth={20}
            trailColor="#222455" // use a string for solid color.
            textStyle={{ fontSize: '0px' }} // middle text style
            pathLinecap={'round'}
            pathShadow={'none'}
            animation={{
              duration: 0,
              delay: 0,
              ease: 'easeInSine',
            }}
          ></Progressbar>
          <div className="absolute top-[50%] translate-y-[calc(-50%)] left-[50%] translate-x-[-50%]  text-center">
            <div className="text-[14px] leading-[20px] text-[#A4A6B0] mb-[4px]">{'수면 효율'}</div>
            <div
              className={`whitespace-nowrap  text-[30px] leading-[36px] ${
                !efficiency ? 'text-white' : 'text-[#26282C]'
              }  `}
            >
              {!efficiency ? (
                <span className="font-regular">
                  -<span className="font-extrabold">%</span>
                </span>
              ) : (
                `${efficiency}%`
              )}
            </div>
          </div>
        </div>
        <div className="w-fit text-[15px] leading-[21px] text-[#A4A6B0] mx-auto ">
          {efficiency ? (
            efficiency >= 85 ? (
              recordByDateList && recordByDateList.length >= 7 && recordByDateList.length < 14 ? (
                '잘 자고 있어요.'
              ) : (
                '다음 주엔 20분 정도 일찍 자도록 처방 예정이에요.'
              )
            ) : (
              '다음 주엔 20분 정도 늦게 자도록 처방 예정이에요.'
            )
          ) : (
            <div className="text-center">
              <div>{`효율을 계산하려면 \n 수면 기록을 더 해주세요`}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
