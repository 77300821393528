import React, { useEffect, useState } from 'react';
import ContainerByDeviceAnxy from '../../components/elements/ContainerByDeviceAnxy';
import Test from '../../development/Test';
import { updateNotch } from '../../utils/webview';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_MORE } from '../../recoil/common/header/atom';
import { HEADER_SIZE } from '../../data/betterMe/betterMeConstant';
import deviceAtom from '../../recoil/common/device';
import { LoadingLottie } from '../../stories/animation/LoadingLottie';
import betterMeSessionData from '../../data/betterMe/bettermeSessionData';
import SessionCarousel from '../../components/somny/SessionCarousel';

export default function BetterMeHome() {
  const [sessionList, setSessionList] = useState(betterMeSessionData);
  const [isLoaded, setIsLoaded] = useState(false);

  const deviceState = useRecoilValue(deviceAtom);

  const setHeaderState = useSetRecoilState(headerAtom);

  const initializeState = () => {
    setHeaderState((state) => ({
      ...state,
      headerButtonType: BUTTON_MORE,
      headerTitle: '',
      headerColor: '#26282C',
    }));
  };

  useEffect(() => {
    // header, layout settings
    document.title = 'Better Me 홈';
    document.body.style.backgroundColor = '#FFFFFF';

    updateNotch({ isFullScreen: true });

    initializeState();
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, []);

  return isLoaded ? (
    <ContainerByDeviceAnxy>
      <div
        className="relative w-full"
        style={{
          height: `calc(100vh - ${HEADER_SIZE * 2}px - ${deviceState.notchSize}px)`,
        }}
      >
        <div className={`py-[50px] h-full flex items-center`}>
          <SessionCarousel sessionList={sessionList} isBetty />
        </div>
      </div>
    </ContainerByDeviceAnxy>
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <LoadingLottie containerSize="50px" />
    </div>
  );
}
