/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import { hasHomeIndicator, getNotchSize } from '../utils/webview';
import { AuthContext } from '../context';
import { CoachingContext } from '../coachingContext';
import useDetectIOS from '../hooks/useDetectIOS';
import deviceAtom from '../recoil/common/device';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import backgroundColorAtom from '../recoil/common/backgroundColor/atom';

export default function Setting(props) {
  const { child } = props;
  const [isIOS] = useDetectIOS();

  const context = useContext(AuthContext);
  const context2 = useContext(CoachingContext);
  const setDeviceState = useSetRecoilState(deviceAtom);
  const backgroundColor = useRecoilValue(backgroundColorAtom);

  const location = useLocation();

  useEffect(() => {
    context.setBiggerMargin && hasHomeIndicator((hasHomeIndicator) => context.setBiggerMargin(hasHomeIndicator));
    context2.setBiggerMargin && hasHomeIndicator((hasHomeIndicator) => context2.setBiggerMargin(hasHomeIndicator));
    hasHomeIndicator((hasHomeIndicator) => setDeviceState((state) => ({ ...state, hasHomeIndicator })));
    getNotchSize(setDeviceState);

    setDeviceState((state) => ({ ...state, isIOS }));
  }, [isIOS]);

  useEffect(() => {
    if (location.pathname.includes('session/') || location.pathname.includes('trial/')) {
      document.body.style.backgroundColor = backgroundColor;
    } else {
      document.body.style.backgroundColor = '';
    }
  }, [location, backgroundColor]);

  return child;
}
