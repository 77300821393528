/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { motion } from 'framer-motion';

export default function SingleSelectTemplate(props) {
  const { current, question, selected, order, goBack } = props;
  const clicked = selected.find((element) => element.id === question.id);

  return (
    <motion.div
      // animate={{
      //   opacity: order[current] === question.id ? 1 : 0,
      //   y: order[current - 1] === question.id ? -(window.innerHeight - 74) : 0,
      // }}
      // transition={{
      //   delay:
      //     order[current - 1] === question.id
      //       ? 0.5
      //       : order[current] === question.id
      //       ? current === 0 || goBack
      //         ? 0.2
      //         : 0.9
      //       : 0,
      //   duration: 1,
      //   ease: [0.4, 0, 0.2, 1],
      // }}
      style={{
        willChange: 'transform',
        opacity: order[current] === question.id ? 1 : 0,
        y: order[current - 1] === question.id ? -(window.innerHeight - 74) : 0,
        transitionProperty: 'all',
        transitionDelay:
          order[current - 1] === question.id
            ? '0.5s'
            : order[current] === question.id
            ? current === 0 || goBack
              ? '0.2s'
              : '0.9s'
            : '0s',
        transitionDuration: '1s',
        transitionTimingFunction: [0.4, 0, 0.2, 1],
      }}
      className={`fixed w-[calc(100vw-35px)]  bottom-[49px] opacity-0 ${
        order[current] === question.id ? '' : ' pointer-events-none'
      } 

      `}
    >
      <motion.div
        className={`text-[24px] leading-[30px]  mb-[30px] ${
          order[current] === question.id ? 'opacity-100' : 'opacity-100 '
        } transition-all`}
      >
        {question.title}
      </motion.div>
      <div className={`w-full flex flex-wrap gap-[10px]`}>
        {question.options.map((each, index) => (
          <motion.div
            key={`option${index}`}
            id="option"
            style={{
              backgroundColor: clicked && clicked.answer.text === each.text ? '#FFFFFF' : 'rgba(0,0,0,0.2)',
              color: clicked && clicked.answer.text === each.text ? '#2C4BEC' : '#FFFFFF',
              y: clicked && clicked.answer.text !== each.text ? 10 : 0,
              opacity: order[current - 1] === question.id && clicked && clicked.answer.text !== each.text ? 0 : 1,
              transitionDuration: '0.2s',
              transitionTimingFunction: [0.4, 0, 0.2, 1],
            }}
            // animate={{
            //   backgroundColor: clicked && clicked.answer.text === each.text ? '#FFFFFF' : 'rgba(0,0,0,0.2)',
            //   color: clicked && clicked.answer.text === each.text ? '#2C4BEC' : '#FFFFFF',
            //   y: clicked && clicked.answer.text !== each.text ? 10 : 0,
            //   opacity: order[current - 1] === question.id && clicked && clicked.answer.text !== each.text ? 0 : 1,
            // }}
            // transition={{ delay: 0, duration: 0.2, ease: 'easeInOut' }}
            className={`  py-[14px] rounded-[100px]  
            ${
              each.text.length === 2
                ? 'w-[100px] flex justify-center'
                : each.text.length === 3
                ? 'w-[120px] flex justify-center'
                : 'w-fit px-[18px]'
            } text-[15px] leading-[21px] flex-none  `}
          >
            {each.text}
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}
