import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import stopInputFocusAtom from '../../recoil/common/stopInputFocus/atom';
import { useSetRecoilState } from 'recoil';
import { ProgressingBar } from './ProgressingBar';
import anxyColorPalette from '../../data/anxy/contents/anxyColorPalette';

export const Header = ({
  buttonType,
  title,
  color,
  bgColor,
  maxCompleted,
  completed,
  progressBarColor,
  progressBarBaseColor,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goMyPage = useNavigateWithParams('/myPage');
  const goSomnyMyPage = useNavigateWithParams('/somnyMyPage');
  const goSomnyMyPageInReview = useNavigateWithParams('/somnyMyPageInReview');

  const goLoomyMyPage = useNavigateWithParams('/loomyMyPage');
  const goBetterMeMyPage = useNavigateWithParams('/betterMeMyPage');

  const setStopInputFocusState = useSetRecoilState(stopInputFocusAtom);
  const goHome = useNavigateWithParams('/anxyHome');
  const goSomnyHome = useNavigateWithParams('/somnyHome');

  const Back = () => {
    return (
      <div>
        <svg width="52" height="44" viewBox="0 0 52 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M25 14L17 22L25 30"
            stroke={color ? color : '#26282C'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };

  const Close = () => {
    return (
      <svg width="52" height="44" viewBox="0 0 52 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33 15L19 29"
          stroke={color ? color : '#26282C'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 15L33 29"
          stroke={color ? color : '#26282C'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const More = () => {
    return (
      <svg width="51" height="44" viewBox="0 0 51 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M34 16H18"
          stroke={color ? color : '#26282C'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34 22H18"
          stroke={color ? color : '#26282C'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34 28H18"
          stroke={color ? color : '#26282C'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const headerInfo = [
    {
      buttonType: 'back',
      position: 'left',
      component: <Back />,
      action: () => {
        console.log(window.location.pathname);
        if (window.location.pathname.includes('userLeaveAnxy')) {
          console.log('stopfocus');
          setStopInputFocusState(true);
          // } else if (window.location.pathname.includes('payment')) {
          //   navigate(-1);
        } else {
          navigate(-1);
        }
      },
    },
    {
      buttonType: 'close',
      position: 'left',
      component: <Close />,
      action: () => {
        if (location.pathname.toLowerCase() === '/somnypayment') {
          goSomnyHome();
        } else {
          goHome();
        }
      },
    },
    {
      buttonType: 'more',
      position: 'right',
      component: <More />,
      action: () => {
        if (location.pathname.toLowerCase() === '/somnyhomeinreview') {
          goSomnyMyPageInReview();
        } else if (location.pathname.toLowerCase() === '/somnyhome') {
          goSomnyMyPage();
        } else if (location.pathname.toLowerCase() === '/loomyhome') {
          goLoomyMyPage();
        } else if (location.pathname.toLowerCase() === '/bettermehome') {
          goBetterMeMyPage();
        } else {
          goMyPage();
        }
      },
    },
    {
      buttonType: 'none',
      position: 'left',
      component: <div className="w-[52px]" />,
      action: () => {},
    },
  ];

  const headerContent = headerInfo.find((item) => item.buttonType === buttonType);
  return (
    <div
      className={`fixed top-0  left-0 w-screen z-[200] text-[17px] leading-[26px]  font-semibold pt-safe pointer-events-none  `}
      style={{ color: color || '#26282C', backgroundColor: bgColor }}
    >
      <div className={`relative w-full h-[44px] flex ${headerContent.position === 'right' && 'flex justify-end '}`}>
        <div onClick={headerContent.action} className="w-[52px] pointer-events-auto">
          {headerContent.component}
        </div>
        {maxCompleted && completed && (
          <div className="flex-1 pr-[20px]  flex flex-col justify-center">
            <ProgressingBar
              maxCompleted={maxCompleted}
              completed={completed}
              color={progressBarColor ? progressBarColor : anxyColorPalette.black}
              baseColor={progressBarBaseColor ? progressBarBaseColor : `${anxyColorPalette.black}14`}
              height={4}
            />
          </div>
        )}
        {typeof title === 'string' ? (
          <div className=" absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]"> {title}</div>
        ) : (
          <div
            className={`absolute w-[calc(100%-52px)] top-0 ${
              headerContent.position === 'right' ? 'left-0' : 'right-0'
            } h-full `}
          >
            {title}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
