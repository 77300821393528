import React, { useState, useEffect } from 'react';
import { FullPageAnxy } from '../../../stories/page/FullPageAnxy';
import { useRecoilState, useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import CardWithTail from '../../../components/anxy/anxy12ndB/CardWithTail';
import { anxyToolData } from '../../../data/anxy/home/anxyToolData';
import sessionAtom from '../../../recoil/anxy/session/atom';
import toolModalAtom from '../../../recoil/anxy/session/toolModalAtom';
import useLoadImage from '../../../hooks/useLoadImage';
import AnxyModalWithTool from '../../../components/anxy/modal/AnxyModalWithTool';

export default function ManagementTool(props) {
  const [selectedToolId, setSelectedToolId] = useState();
  const translateY = 180;
  const setHeaderState = useSetRecoilState(headerAtom);
  const setSessionState = useSetRecoilState(sessionAtom);
  const [modalState, setModalState] = useRecoilState(toolModalAtom);
  const loadImage = useLoadImage(anxyToolData.map((item) => item.toolImage));

  const r = Math.min(window.innerWidth - 30, 345) / 2 - 65;

  function getRadian(x) {
    return (x * Math.PI) / 180;
  }

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerTitle: '', headerColor: '#26282C' }));
    loadImage();
  }, []);

  return (
    <FullPageAnxy
      content={
        <div
          className={`w-screen h-full flex flex-col gap-[30px] justify-center items-center pb-[80px]`}
          onClick={() => {
            setSelectedToolId();
          }}
        >
          <AnxyModalWithTool />
          <p className="text-[24px] leading-[30px] text-[#26282C] font-bold">불안 관리 도구</p>

          <div className="w-full pb-[100%]  relative ">
            {anxyToolData.map((each, index) => (
              <div key={`tool${index}`}>
                <div
                  className="absolute w-[120px] h-[120px] rounded-[50%] bg-[#3A3C40] left-[50%] top-[50%]
                  flex flex-col items-center justify-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSessionState((state) => ({ ...state, currentTool: each.toolId }));
                    setTimeout(() => {
                      setModalState((state) => ({ ...state, isModalVisible: true }));
                    }, 0);
                  }}
                  style={{
                    transform:
                      index === 0
                        ? `translate(-50%,calc(-50% - ${r}px ))`
                        : index === 1
                        ? `translate(calc(-50% + ${r * Math.cos(getRadian(18))}px),calc(-50% - ${
                            r * Math.sin(getRadian(18))
                          }px ))`
                        : index === 2
                        ? `translate(calc(-50% + ${r * Math.sin(getRadian(36))}px),calc(-50% + ${
                            r * Math.cos(getRadian(18)) - 15
                          }px ))`
                        : index === 3
                        ? `translate(calc(-50% - ${r * Math.sin(getRadian(36))}px),calc(-50% + ${
                            r * Math.cos(getRadian(18)) - 15
                          }px ))`
                        : `translate(calc(-50% - ${r * Math.cos(getRadian(18))}px),calc(-50% - ${
                            r * Math.sin(getRadian(18))
                          }px ))`,
                  }}
                >
                  {each.image}
                  <p
                    className="text-[12px] leading-[18px] text-white font-bold opacity-[0.4]"
                    style={{ marginTop: each.imageMarginBottom }}
                  >
                    {each.title}
                  </p>
                </div>

                {selectedToolId && selectedToolId === each.id && (
                  <div
                    className="absolute left-[50%] top-[50%] z-[50] will-change-[filter]"
                    style={{
                      transform:
                        index === 0
                          ? `translate(-50%,calc(-50% - ${r - translateY + 30}px ))`
                          : index === 1
                          ? `translate(calc(-50%),calc(-50% - ${r * Math.sin(getRadian(18)) - translateY}px ))`
                          : index === 2
                          ? `translate(calc(-50%),calc(-50% + ${r * Math.cos(getRadian(18)) - 15 - translateY}px ))`
                          : index === 3
                          ? `translate(calc(-50%),calc(-50% + ${r * Math.cos(getRadian(18)) - 15 - translateY}px ))`
                          : `translate(calc(-50%),calc(-50% - ${r * Math.sin(getRadian(18)) - translateY + 30}px ))`,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <CardWithTail
                      title={each.title}
                      text={each.text}
                      buttonText={'체험하기'}
                      buttonColor={'black'}
                      tailMoveAmount={
                        index === 0
                          ? 0
                          : index === 1
                          ? r * Math.cos(getRadian(18))
                          : index === 2
                          ? r * Math.sin(getRadian(36))
                          : index === 3
                          ? -r * Math.sin(getRadian(36))
                          : -r * Math.cos(getRadian(18))
                      }
                      isTailBottom={each.isTailBottom}
                      hideButton={each.hideButton}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      }
      bgColor={'#F3F5F8'}
    />
  );
}
