import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import faceByScoreData from '../../../data/anxy/faceByScroreData';
import useNavigateSession from '../../../hooks/useNavigateSession';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useTouchScroll } from '../../../hooks/useTouchScroll';
import worryNoteAtom from '../../../recoil/anxy/worryNote/atom';
import backgroundColorAtom from '../../../recoil/common/backgroundColor/atom';
import { marginWithHomeIndicator } from '../../../recoil/common/device';
import { AnxyActionButton } from '../../../stories/button/ActionButton';

function DragEmotionFace({ title }) {
  const { id, page } = useParams();
  const [scores, setScores] = useState(0);

  const { sheet, score } = useTouchScroll();
  const marginClassName = useRecoilValue(marginWithHomeIndicator);
  const [worryNoteState, setWorryNoteState] = useRecoilState(worryNoteAtom);
  const backgroundColor = useRecoilValue(backgroundColorAtom);

  useEffect(() => {
    setScores(score);
    setWorryNoteState((state) => ({ ...state, score: parseInt(score) }));
  }, [score, setWorryNoteState]);

  const goToNextPage = useNavigateSession({});

  const facialIndexByScore = parseInt(score / 20);
  const facialImage = faceByScoreData[facialIndexByScore].image;
  return (
    <div
      ref={sheet}
      className={`pt-safe w-full h-screen overflow-auto scrollbar-hide relative px-[20px] text-white flex flex-col justify-between`}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div>
        <p className="font-left font-bold text-[24px] leading-[30px] pt-[51px]">{title}</p>
      </div>
      <div className="flex justify-center">
        <div className="w-[250px]">
          <img alt="emtpy" src={facialImage} className="w-full h-full object-contain" />
        </div>
      </div>
      <div className={`${marginClassName} w-full`}>
        <div className="flex flex-col items-center pb-[30px]">
          <p className="text-[18px] leading-[24px] font-bold">불안 점수</p>
          <p className="text-[50px] leading-[60px] font-bold h-[60px]">{parseInt(score)}</p>
          <p className="text-[18px] leading-[24px] opacity-[0.5] pt-[30px]">위 아래로 드래그 하세요</p>
        </div>
        <AnxyActionButton state={'ACTIVE'} text={'다음'} action={goToNextPage} />
      </div>
    </div>
  );
}

export default DragEmotionFace;
