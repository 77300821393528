import React, { useState, useEffect, useContext, useRef } from 'react';

import { AuthContext } from '../../context';
import TestResultDetail from '../../components/onboarding/TestResultDetail';
import { changeHeader } from '../../utils/webview';
import chart from '../../image/chart.png';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useDetectIOS from '../../hooks/useDetectIOS';

export default function Result() {
  const [sortedResult, setSortedResult] = useState([]);
  const [isIOS] = useDetectIOS();
  const context = useContext(AuthContext);
  const titleRef = useRef();
  const scrollRef = useRef();

  useEffect(() => {
    document.title = '검사 결과';

    changeHeader({ backgroundColor: '#EFF2FF', text: '', buttonType: 'none' });
    window.document.body.style.backgroundColor = '#EFF2FF';
  }, []);

  useEffect(() => {
    setSortedResult(
      context.selfcheckResult
        .filter((element) => element.isAbnormal)
        .concat(context.selfcheckResult.filter((element) => !element.isAbnormal)),
    );
    context.setGoal(
      context.selfcheckResult
        .filter((element) => element.isAbnormal)
        .concat(context.selfcheckResult.filter((element) => !element.isAbnormal))
        .slice(0, 3),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goGoal = useNavigateWithParams(`/goal`);

  function goNext() {
    // navigate('/goal');
    goGoal();
  }

  return (
    <div className={`${isIOS && 'h-screen overflow-hidden'} w-screen bg-[#EFF2FF] relative`} ref={scrollRef}>
      <div
        className={`w-full ${
          context.biggerMargin ? 'h-[calc(100%-88px)]' : 'h-[calc(100%-74px)]'
        } relative overflow-x-hidden overflow-y-scroll scrollbar-hide`}
      >
        <div className="pt-[7px] pb-[40px]  px-[20px]">
          <img src={chart} alt={'empty'} className="w-[55px] mb-[20px]" />
          <div className="font-bold text-[28px] leading-[34px] text-[#26282C]  mb-[50px]" ref={titleRef}>
            {'마음 종합검진 결과예요'}
          </div>
          {context.selfcheckResult.filter((element) => element.isAbnormal).length > 0 && (
            <div className="mb-[50px]">
              <div className="text-[20px] leading-[26px] font-bold text-[#26282C] mb-[20px]">
                {'🚨 지금 바로 관리가 필요해요'}
              </div>
              {context.selfcheckResult
                .filter((element) => element.isAbnormal)
                .map((result, index) => (
                  <div
                    key={`result1${index}`}
                    className={`h-[70px] px-[24px] py-[23px] bg-white rounded-[16px]  flex items-center justify-between 
                font-bold ${
                  index === context.selfcheckResult.filter((element) => element.isAbnormal).length - 1
                    ? 'mb-0'
                    : 'mb-[10px]'
                }
              `}
                  >
                    <p className="text-[18px] leading-[24px] text-[#26282C] ">{result.selfCheckName}</p>
                    <p className="text-[16px] leading-[22px] text-[#EF3E3E]">{result.diagnosisSummary}</p>
                  </div>
                ))}
            </div>
          )}
          {context.selfcheckResult.filter((element) => !element.isAbnormal).length > 0 && (
            <div className="mb-[50px]">
              <div className="text-[20px] leading-[26px] font-bold text-[#26282C] mb-[20px]">
                {'👀 꾸준히 경과를 지켜봐주세요'}
              </div>
              {context.selfcheckResult
                .filter((element) => !element.isAbnormal)
                .map((result, index) => (
                  <div
                    key={`result2${index}`}
                    className={`h-[70px] px-[24px] py-[23px] bg-white rounded-[16px]  flex items-center justify-between 
                 font-bold ${
                   index === context.selfcheckResult.filter((element) => !element.isAbnormal).length - 1
                     ? 'mb-0'
                     : 'mb-[10px]'
                 }
              `}
                  >
                    <p className="text-[18px] leading-[24px] text-[#26282C] ">{result.selfCheckName}</p>
                    <p className="text-[16px] leading-[22px] text-[#2C4BEC]">{result.diagnosisSummary}</p>
                  </div>
                ))}
            </div>
          )}
          <div className={`${!isIOS && 'pb-[75px]'}`}>
            <div className="text-[20px] leading-[26px]  font-bold text-[#26282C] mb-[20px]">{'상세 진단'}</div>
            {sortedResult.map((result, index) => (
              <div
                key={`result3${index}`}
                className={` ${index === sortedResult.length - 1 ? 'mb-0' : 'mb-[10px]'}
             `}
              >
                <TestResultDetail
                  examName={result.selfCheckName}
                  summary={result.diagnosisTitle}
                  detail={result.diagnosisBody}
                  isAbnormal={result.isAbnormal}
                  score={result.score}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`w-full pt-[1px] fixed `}
        style={{ top: window.innerHeight - (context.biggerMargin ? 129 : 115) }}
      >
        <div
          className="w-full h-[40px]"
          style={{ background: 'linear-gradient(to top, #EFF2FF, rgba(255,255,255,0))' }}
        />
        <div className={`px-[20px] bg-[#EFF2FF] ${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'}`}>
          <PrimaryActionButton state={'ACTIVE'} text={'다음'} action={goNext} />
        </div>
      </div>
    </div>
  );
}
