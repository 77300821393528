import { useRecoilValue } from 'recoil';
import worryNoteAtom from '../../../recoil/anxy/worryNote/atom';

const WorryNoteStaticInput = ({ title, name }) => {
  const worryNoteState = useRecoilValue(worryNoteAtom);
  return (
    <div className="text-white">
      <p className="text-[17px] leading-[23px] font-bold mb-[13px]">{title}</p>
      <div className="w-full rounded-[12px] bg-[#0000004D] text-white text-[17px] leading-[23px] py-[14px] px-[20px]">
        {worryNoteState[name]}
      </div>
    </div>
  );
};

export default WorryNoteStaticInput;
