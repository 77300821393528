import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

function Number(props) {
  const { num, isAnxy } = props;
  return (
    <div
      className={`${
        isAnxy
          ? 'w-[32.5px] h-[60px] text-[#EF670B] text-[50px] leading-[59.67px]'
          : ' w-[60px] h-[107px] text-[90px] leading-[108px]'
      } relative flex items-center justify-center font-bold`}
    >
      {num}
    </div>
  );
}

export function AnimatedNumber(props) {
  const { num, certainNumber, upward, isAnxy } = props;
  const numberGrpWrpRef = useRef();
  const num0Ref = useRef();
  const num1Ref = useRef();
  const num2Ref = useRef();
  const num3Ref = useRef();
  const num4Ref = useRef();
  const num5Ref = useRef();
  const num6Ref = useRef();
  const num7Ref = useRef();
  const num8Ref = useRef();
  const num9Ref = useRef();
  const initiaNumRefs = !upward
    ? [
        { ref: num0Ref, num: 0 },
        { ref: num1Ref, num: 1 },
        { ref: num2Ref, num: 2 },
        { ref: num3Ref, num: 3 },
        { ref: num4Ref, num: 4 },
        { ref: num5Ref, num: 5 },
        { ref: num6Ref, num: 6 },
        { ref: num7Ref, num: 7 },
        { ref: num8Ref, num: 8 },
        { ref: num9Ref, num: 9 },
      ]
    : [
        // { ref: num0Ref, num: 0 },
        // { ref: num1Ref, num: 1 },
        // { ref: num2Ref, num: 2 },
        // { ref: num3Ref, num: 3 },
        // { ref: num4Ref, num: 4 },
        // { ref: num5Ref, num: 5 },
        // { ref: num6Ref, num: 6 },
        // { ref: num7Ref, num: 7 },
        // { ref: num8Ref, num: 8 },
        // { ref: num9Ref, num: 9 },
        { ref: num9Ref, num: 9 },
        { ref: num8Ref, num: 8 },
        { ref: num7Ref, num: 7 },
        { ref: num6Ref, num: 6 },
        { ref: num5Ref, num: 5 },
        { ref: num4Ref, num: 4 },
        { ref: num3Ref, num: 3 },
        { ref: num2Ref, num: 2 },
        { ref: num1Ref, num: 1 },
        { ref: num0Ref, num: 0 },
      ];
  const [numRefs, setNumRefs] = useState(initiaNumRefs.slice(num + 1).concat(initiaNumRefs.slice(0, num + 1)));

  useEffect(() => {
    console.log('처음', num);
    if (certainNumber) {
      setTimeout(() => {
        animateNum(certainNumber);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    console.log('숫자 바뀜', num);
    animateNum(num);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [num]);

  useEffect(() => {
    console.log('순서 바뀜');
    gsap.to(numberGrpWrpRef.current, 0, {
      y: -numRefs.find((element) => element.num === num).ref.current.offsetTop,
    });
  }, [numRefs]);

  function animateNum(num) {
    gsap.killTweensOf(numberGrpWrpRef.current);
    gsap.to(numberGrpWrpRef.current, 2, {
      y: !upward
        ? -numRefs.find((element) => element.num === num).ref.current.offsetTop
        : numRefs.find((element) => element.num === num).ref.current.offsetTop,
    });
    if (!certainNumber) {
      setTimeout(() => {
        console.log('리스트 순서 바꿔');
        !upward
          ? setNumRefs(initiaNumRefs.slice(num + 1).concat(initiaNumRefs.slice(0, num + 1)))
          : setNumRefs(initiaNumRefs.slice(0, num + 1).concat(initiaNumRefs.slice(num + 1)));
      }, 2500);
    }
  }

  return (
    <div className="w-full relative overflow-hidden">
      <div className={`${isAnxy ? 'w-[32.5px] h-[60px]' : 'w-[60px] h-[107px]'} flex`}>
        <div className="w-full relative" ref={numberGrpWrpRef}>
          {numRefs.map((numRef, index) => (
            <div key={`numRef${index}`} ref={numRef.ref}>
              <Number num={numRef.num} isAnxy={isAnxy} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
