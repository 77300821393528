/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import FirstMindScanStart from './FirstMindScanStart';
import FirstMindScanPreview from './FirstMindScanPreview';
import FirstMindScanQA from './FirstMindScanQA';

export default function FirstMindCheckUp(props) {
  let { id, state } = useParams();
  const context = useContext(AuthContext);

  const root = document.getElementById('root');
  root.style.position = 'fixed';
  root.style.overflow = 'hidden';

  return (
    <div>
      {!id && <FirstMindScanStart />}
      {parseInt(state) === 0 && <FirstMindScanPreview id={parseInt(id)} />}
      {id && parseInt(state) !== 0 && (
        <FirstMindScanQA selfCheckQuestion={context.selfcheckQuestion[context.selfCheckOrder[parseInt(id)] - 1]} />
      )}
    </div>
  );
}
