import SelectButton from './SelectButton';

export default function SelectMultipleButton({ title, textList, setButtonState, select, state }) {
  function isClicked(option) {
    if (option !== '') {
      select(option);
    }
  }

  return (
    <div className="flex flex-col gap-[12px]">
      <p className="text-[#FF6F6F] text-[18px] leading-[19.2px] font-semibold px-[4px]">{title}</p>
      {textList.map((option, index) => (
        <SelectButton
          key={`selectButton${index}`}
          selected={state.answerIdList.find((item) => item.id === option.id)}
          option={option}
          text={option.text}
          isClicked={isClicked}
        />
      ))}
    </div>
  );
}
