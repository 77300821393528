const defaultValue = {
  inBed: {
    endDateTime: '',
    startDateTime: '',
  },
  ruleList: [],
  casList: [],
  napTimeMinutes: 0,
  sleep: {
    endDateTime: '',
    startDateTime: '',
  },
  struggleTimeMinutes: 0,
};

export default defaultValue;
