import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context';
import { PrimaryActionButton } from '../../stories/button/ActionButton';
import { useModalDrag } from '../../hooks/useModalDrag';

export default function OnboardingModal(props) {
  const context = useContext(AuthContext);

  const closeModal = () => {
    context.setShowOnboardingModal(false);
  };
  const { sheet, showModal } = useModalDrag(context.showOnboardingModal);
  useEffect(() => {
    context.setShowOnboardingModal(showModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <div
      className={`w-screen absolute bottom-[0px] z-[30] `}
      style={{
        transform: `${context.showOnboardingModal ? 'translateY(0px)' : 'translateY(250px)'}`,
        transition: `all ${context.showOnboardingModal ? 0.3 : 0.2}s ease-in-out`,
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <div
        ref={sheet}
        className={`w-full bg-[#FFFFFF] px-[20px] pt-[28px] text-[15px] leading-[18px] text-center
        ${context.biggerMargin ? 'pb-[34px]' : 'pb-[20px]'}`}
        style={{
          borderRadius: '20px 20px 0px 0px',
          boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.05)',
        }}
      >
        <p className="text-[#26282C] text-[20px] leading-[24px] font-semibold">예전에 진행했던 곳부터 시작할게요</p>
        <p className="text-[#A4A6B0] text-[17px] leading-[26px]  mt-[8px] mb-[24px]">
          끝까지 마치고 맞춤 프로그램을 이용하세요.
        </p>
        <PrimaryActionButton state="ACTIVE" text={'확인'} action={closeModal} />
      </div>
    </div>
  );
}
