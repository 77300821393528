const onboarding1Q = [
  {
    id: 0,
    question: ['어떤 일을 하시나요?'],
    type: 'select',
    options: [
      '관리, 행정, 지원',
      '연구, 기술',
      '보건, 의료',
      '문화, 예술, 스포츠',
      '금융, 회계, 법률',
      '서비스',
      '판매, 영업',
      '건설, 운송, 제조',
      '기타',
    ],
  },
  {
    id: 1,
    question: '직책이 무엇인가요?',
    type: 'select',
    options: ['일반 사원', '중간 관리자', '최고 관리자'],
  },
  {
    id: 2,
    question: '직업이 무엇인가요?',
    type: 'select',
    options: ['직장인', '전문직', '공무원', '사업가', '프리랜서', '주부', '학생', '무직'],
  },
  {
    id: 3,
    question: '심리상담을 받아본 적이 있나요?',
    type: 'select',
    options: ['네, 있어요', '아니요, 없어요'],
  },

  {
    id: 4,
    question: '정신과 진료를 받아본 적이 있나요?',
    type: 'select',
    options: ['네, 있어요', '아니요, 없어요'],
  },
  {
    id: 5,
    question: '어떤 문제로 방문했었나요?',
    type: 'multipleselect',
    options: ['우울', '불안', '공황장애', '강박', '수면장애', '트라우마', '인격장애', '섭식장애', '중독', '기타'],
  },
];

export default onboarding1Q;
