import React, { useState } from 'react';
import { SituationInput } from './SituationInput';

const UserEmotionInputForm = ({ info, word, list, updateSituation }) => {
  const { type, placeholder } = info;

  console.log(list);

  const [text, setText] = useState('' || list.situation);
  function updateValue(value) {
    setText(value);
    const updated = {
      word: word,
      situation: value,
    };
    console.log(updated);
    updateSituation(updated);
  }

  return (
    <div className="mb-[30px] text-[#4E4F53]">
      <p className="mb-[10px] font-bold">{`${type}. “${word}” 감정을 느꼈던 상황`}</p>
      <div className="mx-[1px]">
        <SituationInput placeholder={placeholder} value={text} updateValue={updateValue} />
      </div>
    </div>
  );
};

export default UserEmotionInputForm;
