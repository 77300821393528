import { UnlockLottie } from '../../stories/animation/UnlockLottie';
import { PressedEffect } from '../../stories/button/PressedEffect';
import { motion } from 'framer-motion';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { useState } from 'react';
import { CheckLottie } from '../../stories/animation/CheckLottie';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import useInterval from '../../hooks/useInterval';
import { getCountdown } from '../../logic/method';
import somnyAtom from '../../recoil/somny/atom';
import webviewToast from '../../development/webviewToast';

export default function SessionCard({ sessionData, index, item, isLoomy, isBetty }) {
  const { sessionId, isFirstUnlocked, completedAt, isFirstComplete } = item;

  const [checkAppear, setCheckAppear] = useState(false);
  const [checkDisappear, setCheckDisappear] = useState(false);
  const [lockAppear, setLockAppear] = useState(false);
  const [leftDate, setLeftDate] = useState('');

  const [somnyState, setSomnyState] = useRecoilState(somnyAtom);
  const goToSession = useNavigateWithParams(`../somnySessionInReview/0`);
  const goToLoomySession = useNavigateWithParams(`../loomySession/0`);
  const goToBettySession = useNavigateWithParams(`../betterMeSession/0`);

  const currentData = sessionData.find((item) => item.sessionId === sessionId);

  const timerId = useInterval(
    () => {
      if (somnyState.completedAt) {
        setLeftDate(getCountdown(somnyState.completedAt, 4, 'd일 h시간 m분'));
      } else {
        clearInterval(timerId);
      }
    },
    1000,
    somnyState.completedAt,
  );

  useEffect(() => {
    if (somnyState.completedAt) {
      setLeftDate(getCountdown(somnyState.completedAt, 4, 'd일 h시간 m분'));
    }
    return () => {
      clearInterval(timerId);
    };
  }, []);

  // Pressed Effect Action
  const handleAction = (index) => {
    handleNavigate();
  };

  // Modal Action
  const handleNavigate = () => {
    if (isLoomy) {
      goToLoomySession();
    } else if (isBetty) {
      goToBettySession();
    } else {
      goToSession();
    }
  };

  useEffect(() => {
    if (isFirstComplete) {
      setTimeout(() => {
        setCheckAppear(true);
        setTimeout(() => {
          setCheckDisappear(true);
        }, 100);
      }, 900);
    }
  }, [isFirstComplete]);

  const CheckSvg = () => {
    return (
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.9688 21.0765C44.755 21.8876 44.7348 23.1826 43.9236 23.9688L28.4474 38.9688C27.6541 39.7377 26.3936 39.7377 25.6003 38.9688L16.0765 29.7381C15.2653 28.9518 15.245 27.6569 16.0313 26.8457C16.8175 26.0345 18.1124 26.0143 18.9236 26.8005L27.0239 34.6515L41.0765 21.0313C41.8876 20.245 43.1826 20.2653 43.9688 21.0765Z"
          fill="#398047"
        />
      </svg>
    );
  };
  const SessionInfoText = () => {
    return (
      <div className="w-full absolute top-[40px] text-white px-[35px] z-[50]">
        <div className="w-full flex flex-col text-white ">
          <div className="flex items-center mb-[8px]">
            <p
              className="text-[28px] leading-[34px] font-bold"
              style={{
                wordBreak: 'keep-all',
              }}
            >
              {currentData.title}
            </p>
          </div>
          <div>
            <p className="text-[18px] leading-[24px] font-bold opacity-[0.6] font-normal">{currentData.subtitle}</p>
          </div>
        </div>
      </div>
    );
  };

  const PlayTime = () => {
    return (
      <div className="text-white flex items-center mb-[10px]">
        <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.5 4.63397C9.16667 5.01887 9.16667 5.98113 8.5 6.36603L1.75 10.2631C1.08333 10.648 0.25 10.1669 0.25 9.39711L0.25 1.60289C0.25 0.833085 1.08333 0.35196 1.75 0.73686L8.5 4.63397Z"
            fill="white"
          />
        </svg>
        <p className="ml-[5px] text-[15px] leading-[21px]">{`${currentData.playTime}분`}</p>
      </div>
    );
  };

  const Title = ({ title }) => {
    return (
      <p
        style={{
          whiteSpace: 'nowrap',
        }}
        className={`text-left text-[28px] leading-[34px] font-bold ${
          isLoomy || isBetty ? 'text-[#80838B]' : 'text-white'
        }`}
      >{`${title !== undefined ? title : ''}`}</p>
    );
  };

  const CheckIcon = () => {
    return (
      <div className="relative ml-[10px]">
        {completedAt && (
          <motion.div style={{ opacity: checkAppear || (!isFirstComplete && completedAt) ? 1 : 0 }}>
            <CheckSvg />
          </motion.div>
        )}
        {isFirstComplete && (
          <div className="absolute top-0 w-full h-full flex justify-center items-center">
            <motion.div style={{ opacity: checkDisappear ? 0 : 1 }} transition={{ duration: 1 }}>
              <CheckLottie containerSize={'62px'} greenInvert />
            </motion.div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col items-center relative w-full max-h-[537px]">
      <div className={``}>
        <div className={`flex h-[34px] justify-between items-end px-[6px] w-full overflow-hidden mb-[15px]`}>
          <Title title={currentData.sessionName} />
          {somnyState.completedAt && index === 1 && (
            <motion.span
              className="text-right text-[17px] leading-[26px] font-semibold z-[50] mb-[1px]"
              initial={{ opacity: somnyState.isFirst ? 0 : 1 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 1 }}
              style={{
                color: isLoomy || isBetty ? '#26282C' : '#E1E4EB',
                whiteSpace: 'nowrap',
                width: window.innerWidth <= 340 ? '65px' : 'calc(100% - 65px)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <span
                style={{
                  color: isLoomy || isBetty ? '#EF670B' : '#738FC8',
                }}
              >
                {leftDate}
              </span>
              <span
                style={{
                  color: isLoomy || isBetty ? '#26282C' : '#E1E4EB',
                }}
              >
                {' 남았어요'}
              </span>
            </motion.span>
          )}
        </div>
        <PressedEffect
          element={
            <div className={`h-470px relative`} style={{}}>
              <div className="w-full h-full overflow-hidden rounded-[20px] relative ">
                <SessionInfoText />
                <div
                  className="bg-black w-full h-full absolute top-0 rounded-[20px] opacity-[0.5]"
                  style={{
                    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 53.67%, rgba(0, 0, 0, 0.5) 100%)',
                    transform: 'matrix(1, 0, 0, -1, 0, 0)',
                  }}
                />
                {isFirstUnlocked && (
                  <motion.div
                    animate={{ opacity: 0 }}
                    transition={{ duration: 0.2, delay: 2.2 }}
                    className="z-[40] bg-black w-full h-full absolute top-0 rounded-[20px] opacity-[0.5]"
                  />
                )}
                {isFirstUnlocked && lockAppear && (
                  <div className="z-[40] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[50]">
                    <motion.div animate={{ opacity: 0 }} transition={{ duration: 0.2, delay: 1.2 }}>
                      <UnlockLottie />
                    </motion.div>
                  </div>
                )}
                {parseInt(index) !== 0 && !isLoomy && (
                  <div className="z-[40] bg-black w-full h-full absolute top-0 rounded-[20px] opacity-[0.3]" />
                )}
                <img className="w-full max-h-[450px]" src={currentData.image} alt="empty" />
                {parseInt(index) !== 0 && (
                  <div className="z-[40] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <UnlockLottie isStop />
                  </div>
                )}
                <div className="z-[50] absolute bottom-[30px] flex justify-between px-[35px] w-full items-end">
                  <PlayTime />
                  <CheckIcon />
                </div>
              </div>
            </div>
          }
          action={() => handleAction(index)}
          disable={parseInt(index) !== 0}
        />
      </div>
    </div>
  );
}
