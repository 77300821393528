/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { AnimatedNumber } from '../../stories/animation/AnimatedNumber';
import { motion } from 'framer-motion';

export default function Subtraction() {
  const [num, setNum] = useState(1000);
  const wrapperRef = useRef();

  useEffect(() => {
    initAnimation();
  }, []);

  function initAnimation() {
    setTimeout(() => {
      setNum(993);
    }, 2000);
  }

  function rotateAnimation() {
    function updateNumber() {
      setNum((num) => Math.max(0, num - 7));
    }
    setInterval(updateNumber, 5000);
  }

  useEffect(() => {
    if (num === 993) {
      rotateAnimation();
    }
  }, [num]);

  console.log('num is', num);

  return (
    <div className="w-full h-[107px] flex flex-col items-center justify-center">
      <div className="w-full flex justify-center items-center" ref={wrapperRef}>
        <motion.div
          animate={{ x: -43 }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            delay: 2,
          }}
          className="flex justify-center absolute"
        >
          <motion.div
            className="flex"
            animate={{
              opacity: num < 1000 ? 0 : 1,
            }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}
          >
            <AnimatedNumber num={parseInt(num / 1000)} />
            <p className="text-[90px] leading-[107px] font-bold mr-[5px]">,</p>
          </motion.div>
          {num >= 100 && <AnimatedNumber num={parseInt((num % 1000) / 100)} digit={1} />}
          {num >= 10 && <AnimatedNumber num={parseInt((num % 100) / 10)} digit={2} />}
          <AnimatedNumber num={num % 10} digit={3} />
        </motion.div>
      </div>
    </div>
  );
}
