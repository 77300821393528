/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import somnyChairLottie from '../../stories/animation/somny_chair.json';

import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import headerAtom, { BUTTON_BACK, BUTTON_NONE } from '../../recoil/common/header/atom';
import { TopTitleBottomActionSomny } from '../../stories/page/TopTitleBottomActionSomny';
import useMixpanelWeb from '../../hooks/useMixpanelWeb';
import Lottie from '../../components/elements/Lottie';
import { somnyImageList } from '../../data/somnyImageList';
import { useState } from 'react';
import useLoadImage from '../../hooks/useLoadImage';

export default function SomnyPreview() {
  let { id } = useParams();
  id = parseInt(id);

  const [buttonText, setButtonText] = useState('다음');
  const preloadImage = useLoadImage(somnyImageList);

  const eventNameList = [
    '(S) 도입부 1',
    '(S) 도입부 2',
    '(S) 핵심 경험 1',
    '(S) 핵심 경험 2',
    '(S) 핵심 경험 3',
    '(S) 핵심 경험 4',
    '(S) 핵심 경험 5',
    '(S) 핵심 경험 6',
    '(S) 핵심 경험 7',
    '(S) 핵심 경험 8',
    '(S) 효과',
  ];
  const setMixPanel = useMixpanelWeb({
    eventName: eventNameList[parseInt(id)],
    projectName: 'somny',
  });

  const setHeaderState = useSetRecoilState(headerAtom);

  useEffect(() => {
    setMixPanel();

    if (id === 0) {
      preloadImage();
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_NONE, headerColor: '#FFFFFF', headerTitle: '' }));
    } else {
      setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerColor: '#FFFFFF', headerTitle: '' }));
    }

    if (contentList[id].buttonText) {
      setButtonText(contentList[id].buttonText);
    }
  }, [id]);

  useEffect(() => {
    const body = document.body;
    body.style.position = 'fixed';
    body.style.overflow = 'hidden';
    document.title = 'Somny, 오늘 밤을 위한 수면 관리';
  }, []);

  const goNext = useNavigateWithParams(`../somnyPreview/${parseInt(id) + 1}`);
  const goSubmit = useNavigateWithParams(`../submitInfo/0?type=somny`);

  const BottomImage = ({ image, css, lottie }) => (
    <div className={`h-full relative max-w-[375px] mx-auto flex items-end pt-[20px] ${css}`}>
      <div className="relative">
        <img src={image} alt="empty" className="object-contain " />
        {lottie && (
          <div className="absolute top-[186px] left-[50%] translate-x-[-50%]">
            <Lottie lottieData={lottie} loop autoplay width="220px" height="220px" />
          </div>
        )}
      </div>
    </div>
  );

  const CenteredImage = ({ image, css }) => (
    <div className={`${css} h-full relative max-w-[375px] mx-auto flex items-center py-[20px]`}>
      <div className="relative">
        <img src={image} alt="empty" className="object-contain " />
      </div>
    </div>
  );

  const contentList = [
    {
      title: 'Somny는 매일매일 수면을 도와주는 앱이에요',
      content: <BottomImage image={somnyImageList[0]} css="px-[56px]" />,
    },
    {
      title: 'Somny에서는 세 가지 요소를 이용하여 수면을 관리해요',
      content: <CenteredImage image={somnyImageList[1]} css="px-[10px]" />,
    },
    {
      title: 'Somny는 뜨개질을 좋아하는 토끼랍니다',
      subtitle:
        '많은 사람들이 somny와 함께 불면증을 극복하고 더 깊이 잠들 수 있었어요. 사람들은 어떻게 somny와 함께 더 깊게 잠들 수 있었을까요?',
      content: <BottomImage image={somnyImageList[2]} css="px-[56px]" />,
    },
    {
      title: '밤에는 수면 동화를 들려줄 거예요',
      subtitle: 'somny는 매일 다양한 수면 동화를 들려준답니다',
      content: <BottomImage image={somnyImageList[3]} />,
    },
    {
      title: '자는 동안 곁에서 지켜줘요',
      subtitle: '밤 사이 somny는 뜨개질을 하며 내 곁을 지켜요',
      content: <BottomImage image={somnyImageList[4]} lottie={somnyChairLottie} />,
    },
    {
      title: '아침이 되면 잘 잤는지 확인해요',
      subtitle: '아침엔 somny가 수면기록으로 안부를 물어요',
      content: <BottomImage image={somnyImageList[5]} />,
    },
    {
      title: '수면 시간을 관리해 줄 거예요',
      subtitle: 'Somny는 기록을 바탕으로 나의 수면을 관리해요',
      content: <BottomImage image={somnyImageList[6]} />,
    },
    {
      title: '낮엔 새로운 수면 동화를 준비해요',
      subtitle: '수면기록을 확인한 somny는 잠자며 동화를 찾아요',
      content: <BottomImage image={somnyImageList[7]} />,
    },
    {
      title: '오후엔 뜨개를 선물받아요',
      subtitle: 'Somny의 뜨개엔 수면 이야기와 활동이 있어요',
      content: <BottomImage image={somnyImageList[8]} />,
    },
    {
      title: '다시 밤이에요, somny를 만나요',
      subtitle: 'somny는 일어나 낮에 발견한 수면 동화를 들려줘요',
      content: <BottomImage image={somnyImageList[9]} />,
    },
    {
      title: '4주 동안 반복하면 포근한 밤을 \n 되찾을 거예요',
      content: <CenteredImage image={somnyImageList[10]} />,
    },
  ];
  return (
    <div
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      {contentList[id].replaceComponent ? (
        contentList[id].replaceComponent
      ) : (
        <TopTitleBottomActionSomny
          title={contentList[id].title && contentList[id].title}
          subtitle={contentList[id].subtitle && contentList[id].subtitle}
          content={contentList[id].content}
          buttonText={buttonText}
          buttonState={'ACTIVE'}
          action={() => {
            if (id === contentList.length - 1) {
              goSubmit();
            } else {
              goNext();
            }
          }}
        />
      )}
    </div>
  );
}
