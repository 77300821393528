import * as React from 'react';
import AppleLogin from 'react-apple-login';
import apple_logo from '../../image/apple_logo.png';
import apple_logo_white from '../../image/apple_logo_white.png';

import { login } from '../../utils/webview';
import { AnxyActionButton } from '../../stories/button/ActionButton';

export default function AppleButton({ white }) {
  const cliendId = 'com.Anxy.dev.orwell.health';
  const redirectUrl = 'https://dev-m.inside.im';

  return (
    <div className="w-full">
      {/* <AppleLogin clientId={cliendId} redirectURI={redirectUrl} responseType="code id_token" responseMode="fragment" /> */}
      <AnxyActionButton
        state={'ACTIVE'}
        activeColor={white ? '#FFFFFF' : '#000000'}
        inactiveColor={'#E1E4EB'}
        textColor={white ? '#3A3C40' : '#ffffff'}
        text={
          <div className="flex justify-center gap-[10px]">
            <img src={white ? apple_logo_white : apple_logo} alt={'empty'} className="w-[24px] " />
            <p> Apple 로그인</p>
          </div>
        }
        action={() => {
          login();
          // window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${cliendId}&redirect_uri=${redirectUrl}&response_type=code id_token&response_mode=fragment`;
        }}
      />
    </div>
  );
}
