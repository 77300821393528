/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import EndProcessing from '../../components/selfCheck/EndProcessing';
import { AuthContext } from '../../context';
import { useNavigate } from 'react-router-dom';

export default function Somny2Analysis(props) {
  const navigate = useNavigate();
  const { types } = props;
  const context = useContext(AuthContext);
  const score = getScore();
  const typeResult = types.map((element) => getTypeScore(element));

  useEffect(() => {
    document.title = `somny 로딩`;
    console.log(context.qcAnswerList);
    context.setShowProgressingbar(false);
    context.setMaxCompleted(0);
    context.setCompleted(0);
  }, []);

  function getScore() {
    const scoreList = context.qcAnswerList.filter((element) => !element.type).map((element) => element.score);
    return arraySum(scoreList);
  }

  function getTypeScore(type) {
    const score = context.qcAnswerList
      .filter((element) => element.type === type)
      .filter((element) => element.selfCheckChoiceId === 7).length;

    return score;
  }

  function arraySum(arr) {
    return arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
  }

  function isDone() {
    navigate(`/somnyTest/result?score=${score}&type=${typeResult}`);
  }

  useEffect(() => {
    console.log(score, typeResult);
  }, []);

  return (
    <div
      className={`w-screen`}
      style={{
        height: 'calc(var(--vh,1vh) * 100)',
        minHeight: 'calc(var(--vh.1vh) * 100)',
      }}
    >
      <EndProcessing steps={['검진 답변 분석 중', '결과지 구성 중']} isDone={isDone} />
    </div>
  );
}
