import React, { useState, useEffect } from 'react';
import { hasTouch } from 'detect-touch';
import { motion } from 'framer-motion';
import webviewToast from '../../development/webviewToast';

export function PressedEffect(props) {
  const { element, className, disable, action, stopBubbling } = props;
  const [buttonDown, setButtonDown] = useState(false);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);

  function mouseUp(e) {
    setButtonDown(false);
  }

  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  }, []);

  return (
    <motion.div
      animate={{ scale: buttonDown && !disable ? 0.95 : 1 }}
      transition={{ duration: 0.2 }}
      className={`w-full h-full ${className}`}
      onMouseDown={(e) => {
        if (!hasTouch) {
          setButtonDown(true);
        }
      }}
      onClick={(e) => {
        if (!stopBubbling) {
          e.stopPropagation();
        }
        if (!disable) {
          action();
        }
      }}
      onTouchStart={(e) => {
        setTouchStartY(e.changedTouches[0].clientY);
        setTouchStartX(e.changedTouches[0].clientX);
        if (!stopBubbling) {
          e.stopPropagation();
        }
        if (!disable) {
          setButtonDown(true);
        }
      }}
      onTouchMove={(e) => {
        if (
          Math.abs(e.changedTouches[0].clientY - touchStartY) > 0 ||
          Math.abs(e.changedTouches[0].clientX - touchStartX) > 0
        ) {
          setButtonDown(false);
        }
      }}
      onTouchEnd={(e) => {
        setButtonDown(false);
      }}
      onTouchCancel={() => {
        setButtonDown(false);
      }}
      onTouchEndCapture={() => {
        setButtonDown(false);
      }}
    >
      {element}
    </motion.div>
  );
}
