import emoji_great from '../image/emoji_great.png';
import emoji_good from '../image/emoji_good.png';
import emoji_soso from '../image/emoji_soso.png';
import emoji_not_good from '../image/emoji_not_good.png';
import emoji_hursh from '../image/emoji_hursh.png';
import emoji_tired from '../image/emoji_tired.png';
import emoji_exploded from '../image/emoji_exploded.png';
import emoji_unstable from '../image/emoji_unstable.png';
import emoji_bad from '../image/emoji_bad.png';
import emoji_terrible from '../image/emoji_terrible.png';
import emoji_sad from '../image/emoji_sad.png';
import selfCheck1 from '../image/selfCheck1.png';
import selfCheck2 from '../image/selfCheck2.png';
import selfCheck3 from '../image/selfCheck3.png';
import selfCheck4 from '../image/selfCheck4.png';
import selfCheck5 from '../image/selfCheck5.png';
import selfCheck6 from '../image/selfCheck6.png';
import selfCheck7 from '../image/selfCheck7.png';
import selfCheck8 from '../image/selfCheck8.png';
import selfCheck9 from '../image/selfCheck9.png';
import selfCheck1_small from '../image/selfCheck1_small.png';
import selfCheck2_small from '../image/selfCheck2_small.png';
import selfCheck3_small from '../image/selfCheck3_small.png';
import selfCheck4_small from '../image/selfCheck4_small.png';
import selfCheck5_small from '../image/selfCheck5_small.png';
import selfCheck6_small from '../image/selfCheck6_small.png';
import selfCheck7_small from '../image/selfCheck7_small.png';
import selfCheck1_square from '../image/selfCheck1_square.png';
import selfCheck2_square from '../image/selfCheck2_square.png';
import selfCheck3_square from '../image/selfCheck3_square.png';
import selfCheck4_square from '../image/selfCheck4_square.png';
import selfCheck5_square from '../image/selfCheck5_square.png';
import selfCheck6_square from '../image/selfCheck6_square.png';
import selfCheck7_square from '../image/selfCheck7_square.png';
import selfCheck8_square from '../image/selfCheck8_square.png';
import selfCheck9_square from '../image/selfCheck9_square.png';
import selfCheck1_result from '../image/selfCheck1_result.png';
import selfCheck2_result from '../image/selfCheck2_result.png';
import selfCheck3_result from '../image/selfCheck3_result.png';
import selfCheck4_result from '../image/selfCheck4_result.png';
import selfCheck5_result from '../image/selfCheck5_result.png';
import selfCheck6_result from '../image/selfCheck6_result.png';
import selfCheck7_result from '../image/selfCheck7_result.png';
import selfCheck8_result from '../image/selfCheck8_result.png';
import selfCheck9_result from '../image/selfCheck9_result.png';
import selfCheck1_card from '../image/selfCheck1_card.png';
import selfCheck2_card from '../image/selfCheck2_card.png';
import selfCheck3_card from '../image/selfCheck3_card.png';
import selfCheck4_card from '../image/selfCheck4_card.png';
import selfCheck5_card from '../image/selfCheck5_card.png';
import selfCheck6_card from '../image/selfCheck6_card.png';
import selfCheck7_card from '../image/selfCheck7_card.png';
import selfCheck8_card from '../image/selfCheck8_card.png';
import selfCheck9_card from '../image/selfCheck9_card.png';

import selfTip_bed from '../image/selfTip_bed.png';
import selfTip_bomb from '../image/selfTip_bomb.png';
import selfTip_box from '../image/selfTip_box.png';
import selfTip_calender from '../image/selfTip_calender.png';
import selfTip_exercise from '../image/selfTip_exercise.png';
import selfTip_goal from '../image/selfTip_goal.png';
import selfTip_note from '../image/selfTip_note.png';
import selfTip_pencil from '../image/selfTip_pencil.png';
import selfTip_selfcare from '../image/selfTip_selfcare.png';
import selfTip_sofa from '../image/selfTip_sofa.png';
import selfTip_sun from '../image/selfTip_sun.png';
import selfTip_counseling from '../image/selfTip_counseling.png';

const selfCheckData = [
  {
    selfCheckId: 1,
    selfCheckName: '우울',
    examName: 'PHQ-9',
    image: selfCheck1,
    image_small: selfCheck1_small,
    image_square: selfCheck1_square,
    image_result: selfCheck1_result,
    image_card: selfCheck1_card,
    minScore: 0,
    maxScore: 27,
    result: [
      { label: '정상', min: 0, max: 4, emoji: emoji_great, isAbnormal: false },
      { label: '가벼움', min: 5, max: 9, emoji: emoji_good, isAbnormal: false },
      { label: '중간 정도', min: 10, max: 14, emoji: emoji_soso, isAbnormal: true },
      { label: '심함', min: 15, max: 19, emoji: emoji_bad, isAbnormal: true },
      { label: '극심함', min: 20, max: 27, emoji: emoji_terrible, isAbnormal: true },
    ],
    tag: {
      normal: { image: '⛑', bg: '#FFF0B9', text: '혈중 행복 농도 이상 무' },
      superior: { image: '🗿', bg: '#50C0FF', text: '딴딴한 티타늄 멘탈' },
    },
    guide: '지난 2주를 떠올리며 답해주세요',
    numberOfQuestion: 9,
    description: {
      title: '그래서 우울증이 뭔가요?',
      text: '우울증은 기분, 의욕, 관심, 사고 내용, 수면, 신체활동 동의 전반적인 정신 기능이 지속적으로 저하되어 일상생활을 어렵게 만드는 상태를 말해요. 하지만 일시적으로 우울한 기분을 느낀다고 해서 모두 우울증으로 진단되지 않아요. 즐거운 일이 있을 때 즐겁고, 슬픈 일이 있을 때 슬퍼하는 것은 지극히 자연스러운 것이랍니다.',
    },
    symptom: {
      title: '아래 증상으로 진단할 수 있어요',
      text: [
        '2주 이상 지속되는 우울감',
        '일반적으로 즐거움을 주는 활동에 대한 흥미의 감소',
        '식욕과 체중의 감소 또는 증가',
        '수면의 어려움 또는 과도한 수면',
        '죄책감, 무가치감 또는 무력감',
        '비관적인 사고',
        '주의집중력 및 기억력 저하',
        '죽음 또는 자살에 대한 생각, 자살시도',
        '지속적인 신체의 통증 또는 이유 없는 소화 장애',
      ],
    },
    source: 'Pfizer에서 개발한 우울증 심각도 척도 Patient Health Questionnaire-9(PHQ-9)',
    cardATitle: '우울증이 의심되나요?',
    cardADescription: [
      '우울감이 2주 이상 지속되고 있어요',
      '죄책감, 무가치감 또는 무력감을 느껴요.',
      '일상에서 흥미나 즐거움을 느끼지 못해요.',
    ],
    cardBTitle: '기분이 다운되어 모든 일에 \n 즐거움을 느끼지 못하나요?',
    selfTip: [
      { image: selfTip_pencil, title: '감정기록, 회고하기', subtitle: '부정적인 생각을 다스려요.' },
      { image: selfTip_exercise, title: '꾸준히 운동하기', subtitle: '엔도르핀은 긍정적인 시각을 갖게 해요.' },
      { image: selfTip_calender, title: '규칙적으로 숙면하기', subtitle: '충분한 잠은 우울감을 줄여줘요.' },
    ],
  },
  {
    selfCheckId: 2,
    selfCheckName: '불안',
    examName: 'GAD-7',
    image: selfCheck2,
    image_small: selfCheck2_small,
    image_square: selfCheck2_square,
    image_result: selfCheck2_result,
    image_card: selfCheck2_card,
    minScore: 0,
    maxScore: 21,
    result: [
      { label: '정상', min: 0, max: 4, emoji: emoji_great, isAbnormal: false },
      { label: '가벼움', min: 5, max: 9, emoji: emoji_good, isAbnormal: false },
      { label: '중간 정도', min: 10, max: 14, emoji: emoji_soso, isAbnormal: true },
      { label: '심함', min: 15, max: 21, emoji: emoji_unstable, isAbnormal: true },
    ],
    tag: {
      normal: { image: '🍵', bg: '#CEFDAE', text: '복잡한 세상 편안하게 살자' },
      superior: { image: '🛌', bg: '#A2C6EF', text: '흔들리지 않는 편안함' },
    },
    guide: '지난 2주를 떠올리며 답해주세요',
    numberOfQuestion: 7,
    description: {
      title: '그래서 불안장애가 뭔가요?',
      text: '중요한 시험을 앞두고 있거나 많은 사람들 앞에서 발표를 하게 되었을 때 일시적으로 불안감을 경험하는 것은 지극히 정상적인 일이에요. 하지만 비정상적이고 과도한 불안과 걱정으로 인해 일상생활에 방해를 받는 상태에 이르게 된다면 이를 불안장애라고 말할 수 있어요. 특히 불안이나 근심의 대상이 건강, 경제적인 문제, 취직 등 구체적인 경우도 있는 반면, 무언가 끔찍한 일이 일어날 것만 같은 막연한 느낌처럼 근거가 없는 불안도 있답니다.',
    },
    symptom: {
      title: '아래 증상으로 진단할 수 있어요',
      text: [
        '사소한 일에도 지나치게 걱정함',
        '마음이 항상 불안한 생각과 걱정거리로 차 있음',
        '초조하고 예민하여 잘 놀람',
        '일에 집중하기가 어려움',
        '불면증',
        '근육이 긴장되어 있음',
        '가슴이 두근거리고 심장박동과 호흡이 빠름',
        '쉽게 피로함',
        '입이 마르거나 소변을 자주 보게 됨',
      ],
    },
    source: 'Pfizer에서 개발한 범불안장애 심각척도 Generalized Anxiety Disorder Questionnaire(GAD-7)',
    cardATitle: '불안함을 느끼나요?',
    cardADescription: [
      '사소한 일에도 지나치게 걱정해요.',
      '항상 불안한 생각과 걱정거리가 많아요.',
      '초조하고 예민하여 잘 놀라요.',
    ],
    cardBTitle: '사소한 일도 대처하기 어렵고 \n 항상 초조하고 예민한가요?',
    selfTip: [
      { image: selfTip_sofa, title: '심호흡과 명상하기', subtitle: '심박수를 낮추고 안정을 찾아요.' },
      { image: selfTip_exercise, title: '꾸준히 운동하기', subtitle: '엔도르핀은 긍정적인 시각을 갖게 해요.' },
      { image: selfTip_calender, title: '규칙적으로 숙면하기', subtitle: '충분한 잠은 우울감을 줄여줘요.' },
    ],
  },
  {
    selfCheckId: 3,
    selfCheckName: '스트레스',
    examName: 'PSS',
    image: selfCheck3,
    image_small: selfCheck3_small,
    image_square: selfCheck3_square,
    image_result: selfCheck3_result,
    image_card: selfCheck3_card,
    minScore: 0,
    maxScore: 40,
    result: [
      { label: '정상', min: 0, max: 12, emoji: emoji_great, isAbnormal: false },
      { label: '가벼움', min: 13, max: 15, emoji: emoji_good, isAbnormal: false },
      { label: '중간 정도', min: 16, max: 18, emoji: emoji_soso, isAbnormal: true },
      { label: '심함', min: 19, max: 40, emoji: emoji_exploded, isAbnormal: true },
    ],
    tag: {
      normal: { image: '🥊', bg: '#F9AEAF', text: '원샷원킬 스트레스 킬러' },
      superior: { image: '🔮', bg: '#B447B1', text: '스트레스를 조종하는 마법사' },
    },
    guide: '지난 한 달을 떠올리며 답해주세요',
    numberOfQuestion: 10,
    description: {
      title: '그래서 스트레스가 뭔가요?',
      text: '스트레스는 우리의 정신적 혹은 육체적 균형과 안정을 깨뜨리는 외부 자극이 들어오면, 이에 반응하여 기존의 평형상태를 유지하려는 힘이라고 할 수 있어요. 즉 스트레스란 변화하는 환경에 적응하는 반응이라는 것이지요. 따라서 스트레스가 항상 나쁜 것이라고 할 순 없어요. 적정 수준의 긴장과 각성은 성취욕과 목표의식을 자극해 긍정적인 결과의 원동력으로 작용하니까요. 오히려 현재 상태에 대해서 그 어떠한 스트레스도 느끼지 않는다면 자신의 역량을 키우고 발전하는 데에 방해요인으로 작용할 수 있겠죠? \n 그러나 도저히 본인의 능력으로는 감당하기 힘든 상황에 처했거나 이미 지칠 대로 지쳐버린 상태에서 새로운 자극이 주어져서 유발되는 스트레스는 몸과 마음에 나쁜 영향을 미치고 건강을 해쳐요. 이와 같은 나쁜 스트레스가 적절히 해소되지 못하고 쌓이게 되면 우울증이나 불안 장애를 비롯한 정신 질환은 물론이고 긴장성 두통, 소화불량, 고혈압 등의 다양한 신체 질환의 위험인자로 작용할 수 있답니다',
    },
    symptom: {
      title: '아래 증상으로 진단할 수 있어요',
      text: [
        '불안감, 우울한 기분',
        '주의산만, 집중력 약화',
        '기억력 감소',
        '신경과민, 분노',
        '피로, 불면증',
        '두통, 근육통, 복통',
        '식욕부진, 소화불량, 설사, 변비 등의 소화기계 증상',
      ],
    },
    source:
      'Dr. Sheldon Cohen이 개발하고 미국정신의학협회 ASA로부터 허가받은 지각된 스트레스 척도 Perceived Stress Scale(PSS)',
    cardATitle: '스트레스로 지쳐있나요?',
    cardADescription: [
      '불안감, 우울한 기분을 느껴요.',
      '주의가 산만하고, 집중을 잘 못해요.',
      '기억력이 평소보다 떨어지고, 피로해요.',
    ],
    cardBTitle: '반복되는 스트레스가 해소되지 못하고 \n 많이 지친 상태인가요?',
    selfTip: [
      { image: selfTip_pencil, title: '감정 기록, 회고하기', subtitle: '스트레스를 대하는 현명한 힘을 키워요.' },
      { image: selfTip_sofa, title: '심호흡과 명상하기', subtitle: '판단 없이 순간에 집중하는 법을 배워요.' },
      // { image: selfTip_exercise, title: '꾸준히 운동하기', subtitle: '매우 효과적인 스트레스 해소법이에요.' },
      { image: selfTip_counseling, title: '5회기 이상 심리상담', subtitle: '스트레스를 관리하는 방법을 터득해요.' },
    ],
  },
  {
    selfCheckId: 4,
    selfCheckName: '불면증',
    examName: 'ISQI',
    image: selfCheck4,
    image_small: selfCheck4_small,
    image_square: selfCheck4_square,
    image_result: selfCheck4_result,
    image_card: selfCheck4_card,
    minScore: 0,
    maxScore: 28,
    result: [
      { label: '정상', min: 0, max: 7, emoji: emoji_great, isAbnormal: false },
      { label: '가벼움', min: 8, max: 14, emoji: emoji_good, isAbnormal: false },
      { label: '중간 정도', min: 15, max: 21, emoji: emoji_soso, isAbnormal: true },
      { label: '심함', min: 22, max: 28, emoji: emoji_tired, isAbnormal: true },
    ],
    tag: {
      normal: { image: '⏲', bg: '#E2FFB2', text: '삐빅- 생체시계 정상입니다' },
      superior: { image: '☕', bg: '#EDC584', text: '잠이 보약ㅇ빈ㄷ ㅏ...꿀잠zzz' },
    },
    guide: '지난 2주를 떠올리며 답해주세요',
    numberOfQuestion: 7,
    description: {
      title: '그래서 불면증이 뭔가요?',
      text: '불면증은 잠에 들기 어렵거나, 자는 도중 자주 깨거나, 잠을 충분히 자도 개운하지 않다고 느끼는 등의 여러 가지 증상이 복합적으로 또는 단독으로 나타나는 것을 말해요. \n 불면증의 지속 기간이 한 달 미만인 경우에는 일시적 불면증이라고 하는데, 이는 세 명 중 한 명이 경험할 정도로 비교적 흔하답니다. 반면 지속 기간이 한 달을 넘어가면 만성적 불면증이라고 하며 이는 일상생활에 큰 지장을 초래할 수 있기 때문에 반드시 적절한 치료를 받아야 해요. \n 불면증은 이렇다 할만한 원인을 찾기 어려운 경우가 대부분이지만 수면무호흡증, 하지불안증후군 등의 다른 질환에 의해 이차적으로 유발될 수도 있기 때문에 상세히 원인을 파악하는 것도 상당히 중요해요.',
    },
    symptom: {
      title: '아래 증상으로 진단할 수 있어요',
      text: [
        '잠자리에 누운 뒤 잠들기까지 30분 이상 걸린다.',
        '잠자리에 누우면 정신이 또렷해진다.',
        '자는 도중에 깨는 일이 잦다.새벽에 깬 후 다시 잠에 들기가 힘들다.',
        '잠을 충분히 자고 일어나도 개운하지 않다.',
        '밤에 잠들지 못할 것 같아 불안하고 두렵다.',
      ],
    },
    source: '인사이드 자체 연구팀이 의사들과 함께 개발한 불면증 심각도 척도',
    cardATitle: '수면 때문에 괴롭나요?',
    cardADescription: ['잠들기까지 30분 이상 걸려요.', '자는 도중에 깨는 일이 잦아요.', '충분히 자도 개운하지 않아요.'],
    cardBTitle: '잠에 들기 어렵거나, \n 잠을 충분히 자도 개운하지 않나요?',
    selfTip: [
      { image: selfTip_bed, title: '잠자리를 분리하기', subtitle: '잠자는 행동을 명확히 구분해요.' },
      { image: selfTip_sun, title: '낮잠은 한 시간 이내로', subtitle: '낮잠은 숙면의 적이에요.' },
      { image: selfTip_calender, title: '규칙적으로 숙면하기', subtitle: '루틴한 생체 리듬을 만들어요.' },
    ],
  },
  {
    selfCheckId: 5,
    selfCheckName: '성인 ADHD',
    examName: 'ASRS',
    image: selfCheck5,
    image_small: selfCheck5_small,
    image_square: selfCheck5_square,
    image_result: selfCheck5_result,
    image_card: selfCheck5_card,
    minScore: 0,
    maxScore: 6,
    result: [
      { label: '가능성 적음', min: 0, max: 3, emoji: emoji_great, isAbnormal: false },
      { label: '가능성 있음', min: 4, max: 6, emoji: emoji_not_good, isAbnormal: true },
    ],
    tag: {
      normal: { image: '⚔️', bg: '#9250FF', text: '집중력은 나의 무기' },
      superior: { image: '🎯', bg: '#F67D7F', text: '잡념이 뭐예요? 집중의 신' },
    },
    guide: '지난 6개월을 떠올리며 답해주세요',
    numberOfQuestion: 6,
    description: {
      title: '그래서 성인 ADHD가 뭔가요?',
      text: '주의력결핍 과잉행동장애(ADHD)는 산만함, 과다행동 및 충동성을 특징으로 하는 질환이에요. ADHD는 뇌 내에서 집중력을 조절하는 도파민, 노르에피네프린 등의 신경전달물질의 불균형 때문에 발병하여 일상생활의 여러 기능 영역에 걸쳐 지장을 일으키는 것으로 알려져 있답니다. ADHD는 흔히 아동기에 발병하지만 성인에서도 충분히 나타날 수 있어요. ',
    },
    symptom: {
      title: '아래 증상으로 진단할 수 있어요',
      text: [
        '시작한 일을 마무리 짓는 것이 어렵다.',
        '세밀한 부분을 간과하는 실수를 자주 범한다.',
        '별로 중요하지 않은 소리나 자극 때문에 쉽게 산만해진다.',
        '한 가지 일에 과도하게 집중해서 시간 개념을 망각한다.',
        '지각하는 일이 잦고, 물건을 쉽게 잃어버린다.',
        '가만히 앉아 있는 것이 어렵게 느껴진다.',
        '쉽게 지루해 하며, 자극적이고 흥분되는 일을 추구한다.',
        '다른 사람의 대화에 자주 끼어든다.',
        '무례하거나 부적절한 생각을 거르지 못하고 그대로 내뱉는다.',
        '감정기복이 심하고 작은 일에도 예민하게 반응한다.',
      ],
    },
    source:
      'WHO와 Dr.Lenard Adler, Ronald C. Kessler, Thomas Spencer 등을 포함한 워크 그룹의 공동작업으로 개발된 성인 ADHD 자가보고 척도 Adult ADHD Self-Report Scale(ASRS)',
    cardATitle: '산만하고 집중하기 어렵나요?',
    cardADescription: [
      '시작한 일을 마무리 짓는 것이 어려워요.',
      '작은 실수를 반복해요.',
      '소리나 자극 때문에 쉽게 산만해져요.',
    ],
    cardBTitle: '산만하거나 충동적인 행동 때문에 \n 일상을 유지하기 어렵나요?',
    selfTip: [
      { image: selfTip_note, title: '항상 메모하기', subtitle: '할 일과 약속을 잊지 않도록 도와줘요.' },
      { image: selfTip_box, title: '주변 정리 정돈하기', subtitle: '말끔한 환경에서 또렷한 정신이 나와요.' },
      { image: selfTip_calender, title: '규칙적으로 숙면하기', subtitle: '충분한 잠은 집중력을 높여요.' },
    ],
  },
  {
    selfCheckId: 6,
    selfCheckName: '번아웃',
    examName: 'BAT',
    image: selfCheck6,
    image_small: selfCheck6_small,
    image_square: selfCheck6_square,
    image_result: selfCheck6_result,
    image_card: selfCheck6_card,
    minScore: 12,
    maxScore: 60,
    result: [
      { label: '위험 없음', min: 12, max: 30, emoji: emoji_great, isAbnormal: false },
      { label: '중간 정도', min: 31, max: 35, emoji: emoji_soso, isAbnormal: true },
      { label: '높은 위험', min: 36, max: 60, emoji: emoji_hursh, isAbnormal: true },
    ],
    tag: {
      normal: { image: '⚡️', bg: '#FF5C00', text: '지치지 않는 에너자이저' },
      superior: { image: '😉', bg: '#FFBB55', text: '일도 삶도 놓치지 않을 거예요' },
    },
    guide: '평소를 떠올리며 답해주세요',
    numberOfQuestion: 12,
    description: {
      title: '그래서 번아웃 증후군이 뭔가요?',
      text: '번아웃 증후군은 의욕적으로 일에 집중하던 사람이 극심한 정신적, 신체적 피로감을 호소하며 무기력해지는 현상이라고 할 수 있어요. 번아웃 증후군은 그 자체로는 질환이 아니지만, 번아웃이 찾아오면 육체적인 피로감을 일으킬 뿐만 아니라 정신건강까지 해치는 것으로 알려져 있답니다.',
    },
    symptom: {
      title: '아래 증상으로 진단할 수 있어요',
      text: [
        '맡은 일을 수행하는 것에 정서적으로 지쳐 있다.',
        '일을 마치거나 퇴근할 때면 완전히 지쳐 있다.',
        '아침에 일어나 출근할 생각만 하면 피곤하다.',
        '업무에 대해 심적 부담과 긴장을 느낀다.',
        '업무를 수행할 때 무기력하고 싫증이 난다.',
        '업무에 대한 관심이 크게 줄어들었다.',
        '소극적이고 방어적인 자세로 맡은 일을 수행하게 된다.',
        '본인의 직무 기여도에 대해 냉소적이다.',
        '업무에서 기인하는 스트레스를 해소하려고 과식, 흡연, 음주를 즐긴다.',
        '최근 짜증이 늘고 심리적 여유가 부족하다.',
      ],
    },
    source: '직무소진 평가 척도 Burnout Assessment Tool(BAT)',
    cardATitle: '번아웃으로 고생인가요?',
    cardADescription: [
      '일을 할 때 무기력하고 싫증이 나요.',
      '일을 마치거나 퇴근할 때 완전히 지쳐요.',
      '출근할 생각만 하면 피곤해요.',
    ],
    cardBTitle: '최근 육체적인 피로감과 함께 \n 무기력함을 느끼고 있나요?',
    selfTip: [
      { image: selfTip_selfcare, title: '의식적으로 나를 챙기기', subtitle: '일보다 소중한 건 자기 자신이에요.' },
      { image: selfTip_bomb, title: '음주, 흡연, 카페인 피하기', subtitle: '보다 좋은 방법으로 피로를 해소해요.' },
      { image: selfTip_sofa, title: '심호흡과 명상하기', subtitle: '마음의 힘을 튼튼하게 단련해요.' },
    ],
  },
  {
    selfCheckId: 7,
    selfCheckName: '자존감',
    examName: 'RSES',
    image: selfCheck7,
    image_small: selfCheck7_small,
    image_square: selfCheck7_square,
    image_result: selfCheck7_result,
    image_card: selfCheck7_card,
    minScore: 0,
    maxScore: 30,
    result: [
      { label: '낮음', min: 0, max: 14, emoji: emoji_not_good, isAbnormal: true },
      { label: '평균', min: 15, max: 25, emoji: emoji_soso, isAbnormal: false },
      { label: '평균 이상', min: 26, max: 30, emoji: emoji_great, isAbnormal: false },
    ],
    reverse: true,
    tag: {
      normal: { image: '🍒', bg: '#FFC6B5', text: '긍정 열매 한 트럭' },
      superior: { image: '🛡', bg: '#FF9A89', text: '나를 지키는 강철방패' },
    },
    guide: '평소를 떠올리며 답해주세요',
    numberOfQuestion: 10,
    description: {
      title: '그래서 자존감이 뭔가요?',
      text: '자아존중감이란 자신이 사랑받을 만한 가치가 있는 존재이며 목표하는 성과를 일궈낼 수 있는 능력을 지닌 사람이라고 스스로 믿는 마음가짐이라고 할 수 있어요. 그런데 머리로는 이해해도 실제로 마음 속에 담고 행동으로 실천하기엔 낯설고 멀게만 느껴지는 것이 자존감이기도 한데요. 정신건강의 관점에서 자존감을 가꾸는 것은 매우 중요하답니다.',
    },
    symptom: {
      title: '자존감이 낮으면 무엇이 안좋나요?',
      text: [
        '베트남에 거주하는 1,100명 이상의 학생들을 대상으로 진행한 설문조사에 따르면 자존감이 낮은 학생은 높은 학생에 비해 우울증 증상을 경험할 위험이 약 5.72배 높은 것으로 나타났어요. 게다가 자존감이 낮은 학생은 불안 증상을 경험할 확률이 두 배 이상 높았으며, 자살사고 및 시도의 빈도 또한 높게 예측되었다고 해요. 어떠한 이유에서든 자존감이 건강하지 못하면 실패나 위기 상황을 극복하기 어려워하고 쉽게 좌절하여 불안과 우울에 빠질 수 있다는 뜻이겠지요.',
      ],
    },
    source: '자아존중감 평가 척도 Resenberg Self-Esteem Scale(RSES)',
    cardATitle: '자존감이 낮은 것 같나요?',
    cardADescription: [
      '타인에게 받는 평가에 대해 민감해요.',
      '긴장을 자주 하고 불안함을 자주 느껴요.',
      '스스로에 대한 생각이 많아요.',
    ],
    cardBTitle: '타인에게 받는 평가에 민감하고, \n 스스로에 대한 생각이 많은가요?',
    selfTip: [
      { image: selfTip_pencil, title: '감정 기록, 회고하기', subtitle: '나를 바라보는 긍정적인 시각을 길러요.' },
      { image: selfTip_goal, title: '목표하고 해내기', subtitle: '작은 일이라도 계획해서 이뤄내요.' },
      { image: selfTip_exercise, title: '꾸준히 운동하기', subtitle: '엔도르핀은 자신감을 상승시켜요.' },
    ],
  },
  {
    selfCheckId: 8,
    selfCheckName: '삶의 만족도',
    examName: 'SWLS',
    image: selfCheck8,
    image_small: selfCheck6_small,
    image_square: selfCheck8_square,
    image_result: selfCheck8_result,
    image_card: selfCheck8_card,
    minScore: 0,
    maxScore: 20,
    result: [
      { label: '매우 낮음', min: 0, max: 2, emoji: emoji_sad, isAbnormal: true },
      { label: '낮음', min: 3, max: 5, emoji: emoji_terrible, isAbnormal: true },
      { label: '약간 낮음', min: 6, max: 9, emoji: emoji_bad, isAbnormal: true },
      { label: '보통', min: 10, max: 14, emoji: emoji_soso, isAbnormal: false },
      { label: '높음', min: 15, max: 18, emoji: emoji_good, isAbnormal: false },
      { label: '매우 높음', min: 19, max: 20, emoji: emoji_great, isAbnormal: false },
    ],
    reverse: true,
    tag: {
      normal: { image: '⚡️', bg: '#FF5C00', text: '지치지 않는 에너자이저' },
      superior: { image: '😉', bg: '#FFBB55', text: '일도 삶도 놓치지 않을 거예요' },
    },
    guide: '평소를 떠올리며 답해주세요',
    numberOfQuestion: 5,
    // description: {
    //   title: '그래서 번아웃 증후군이 뭔가요?',
    //   text: '번아웃 증후군은 의욕적으로 일에 집중하던 사람이 극심한 정신적, 신체적 피로감을 호소하며 무기력해지는 현상이라고 할 수 있어요. 번아웃 증후군은 그 자체로는 질환이 아니지만, 번아웃이 찾아오면 육체적인 피로감을 일으킬 뿐만 아니라 정신건강까지 해치는 것으로 알려져 있답니다.',
    // },
    // symptom: {
    //   title: '아래 증상으로 진단할 수 있어요',
    //   text: [
    //     '맡은 일을 수행하는 것에 정서적으로 지쳐 있다.',
    //     '일을 마치거나 퇴근할 때면 완전히 지쳐 있다.',
    //     '아침에 일어나 출근할 생각만 하면 피곤하다.',
    //     '업무에 대해 심적 부담과 긴장을 느낀다.',
    //     '업무를 수행할 때 무기력하고 싫증이 난다.',
    //     '업무에 대한 관심이 크게 줄어들었다.',
    //     '소극적이고 방어적인 자세로 맡은 일을 수행하게 된다.',
    //     '본인의 직무 기여도에 대해 냉소적이다.',
    //     '업무에서 기인하는 스트레스를 해소하려고 과식, 흡연, 음주를 즐긴다.',
    //     '최근 짜증이 늘고 심리적 여유가 부족하다.',
    //   ],
    // },
    source:
      '심리학자 Diener, Emmons, Larsen, & Griffin. 가 만든 삶의 만족 척도 Satisfaction With Life Scale(SWLS)을 사용해요.',
    cardATitle: '나의 삶은 만족스러운가요?',
    cardADescription: [
      '하루가 답답하고 무기력해요.',
      '내가 잘 살고 있는지 의문이 들어요.',
      '지금보다 더 나은 삶을 살고 싶어요.',
    ],
    cardBTitle: '최근 육체적인 피로감과 함께 \n 무기력함을 느끼고 있나요?',
    selfTip: [
      { image: selfTip_selfcare, title: '의식적으로 나를 챙기기', subtitle: '일보다 소중한 건 자기 자신이에요.' },
      { image: selfTip_bomb, title: '음주, 흡연, 카페인 피하기', subtitle: '보다 좋은 방법으로 피로를 해소해요.' },
      { image: selfTip_sofa, title: '심호흡과 명상하기', subtitle: '마음의 힘을 튼튼하게 단련해요.' },
    ],
  },
  {
    selfCheckId: 9,
    selfCheckName: '직무 만족도',
    examName: 'SWWS',
    image: selfCheck9,
    image_small: selfCheck7_small,
    image_square: selfCheck9_square,
    image_result: selfCheck9_result,
    image_card: selfCheck9_card,
    minScore: 0,
    maxScore: 40,
    result: [
      { label: '매우 낮음', min: 0, max: 9, emoji: emoji_exploded, isAbnormal: true },
      { label: '낮음', min: 10, max: 19, emoji: emoji_soso, isAbnormal: true },
      { label: '보통', min: 20, max: 29, emoji: emoji_good, isAbnormal: false },
      { label: '높음', min: 30, max: 40, emoji: emoji_great, isAbnormal: false },
    ],
    reverse: true,
    tag: {
      normal: { image: '🍒', bg: '#FFC6B5', text: '긍정 열매 한 트럭' },
      superior: { image: '🛡', bg: '#FF9A89', text: '나를 지키는 강철방패' },
    },
    guide: '평소를 떠올리며 답해주세요',
    numberOfQuestion: 10,
    // description: {
    //   title: '그래서 자존감이 뭔가요?',
    //   text: '자아존중감이란 자신이 사랑받을 만한 가치가 있는 존재이며 목표하는 성과를 일궈낼 수 있는 능력을 지닌 사람이라고 스스로 믿는 마음가짐이라고 할 수 있어요. 그런데 머리로는 이해해도 실제로 마음 속에 담고 행동으로 실천하기엔 낯설고 멀게만 느껴지는 것이 자존감이기도 한데요. 정신건강의 관점에서 자존감을 가꾸는 것은 매우 중요하답니다.',
    // },
    // symptom: {
    //   title: '자존감이 낮으면 무엇이 안좋나요?',
    //   text: [
    //     '베트남에 거주하는 1,100명 이상의 학생들을 대상으로 진행한 설문조사에 따르면 자존감이 낮은 학생은 높은 학생에 비해 우울증 증상을 경험할 위험이 약 5.72배 높은 것으로 나타났어요. 게다가 자존감이 낮은 학생은 불안 증상을 경험할 확률이 두 배 이상 높았으며, 자살사고 및 시도의 빈도 또한 높게 예측되었다고 해요. 어떠한 이유에서든 자존감이 건강하지 못하면 실패나 위기 상황을 극복하기 어려워하고 쉽게 좌절하여 불안과 우울에 빠질 수 있다는 뜻이겠지요.',
    //   ],
    // },
    source:
      '인사이드 자체 연구팀이 의사들과 함께 개발한 직무 만족 검사 척도 I-SWS (Inside Satisfaction with Work Scale)을 사용해요.',
    cardATitle: '회사 생활은 만족스러운가요?',
    cardADescription: [
      '성취감을 느끼기 어려워요',
      '동료들과의 관계가 편안하지 않아요.',
      '내 미래가 불투명하게 느껴져요.',
    ],
    cardBTitle: '타인에게 받는 평가에 민감하고, \n 스스로에 대한 생각이 많은가요?',
    selfTip: [
      { image: selfTip_pencil, title: '감정 기록, 회고하기', subtitle: '나를 바라보는 긍정적인 시각을 길러요.' },
      { image: selfTip_goal, title: '목표하고 해내기', subtitle: '작은 일이라도 계획해서 이뤄내요.' },
      { image: selfTip_exercise, title: '꾸준히 운동하기', subtitle: '엔도르핀은 자신감을 상승시켜요.' },
    ],
  },
];

export default selfCheckData;
