import React from 'react';
import { logout } from '../../utils/webview';
import { DonePage } from '../../stories/page/DonePage';

export default function UserLeave2() {
  function userLogout() {
    logout();
  }

  return (
    <DonePage
      title={'탈퇴가 완료되었어요'}
      subtitle={'더욱 노력하는 인사이드가 될게요.'}
      actionButton
      buttonText={'확인'}
      buttonState={'ACTIVE'}
      action={userLogout}
    />
  );
}
