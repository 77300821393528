/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { AuthContext } from '../../context';

import SelfCheckStart from './SelfCheckStart';
import SelfCheckQA from './SelfCheckQA';
import SelfCheckEnd from './SelfCheckEnd';
import SelfCheckAnalysis from './SelfCheckAnalysis';
import SelfCheckResult from './SelfCheckResult';

export default function SelfCheck() {
  let { state } = useParams();
  const context = useContext(AuthContext);

  return (
    <div className={`w-screen  ${context.showProgressingbar ? 'h-[calc(100vh-17px)]' : 'h-full'}  `}>
      {parseInt(state) === 0 && <SelfCheckStart />}
      {parseInt(state) > 0 && !isNaN(parseInt(state)) && <SelfCheckQA selfCheckQuestion={context.selfcheckQuestion} />}
      {state === 'end' && <SelfCheckEnd />}
      {state === 'analysis' && <SelfCheckAnalysis />}
      {state === 'result' && <SelfCheckResult />}
    </div>
  );
}
