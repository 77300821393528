import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { hasTouch } from 'detect-touch';
// import { useNavigateWithParam } from '../../hooks/useWorkbookNavigate';
import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import { CoachingContext } from '../../coachingContext';

export default function NavigateSelectButton({ title, textList, answer, pageIndex }) {
  const { id, page } = useParams();
  const [clickedId, setClickedId] = useState();
  const [isPressed, setIsPressed] = useState(false);
  const [select, setSelect] = useState('');
  const [myAnswer, setMyAnswer] = useState('');
  const goNext = useNavigateWithParams(`/workbook/${parseInt(id)}/${parseInt(page) + 1}`);

  const context = useContext(CoachingContext);
  function mouseUp(e) {
    setIsPressed(false);
    setMyAnswer(select);
  }

  useEffect(() => {
    if (!hasTouch) {
      function watchMouseUp() {
        window.addEventListener('mouseup', mouseUp);
      }
      watchMouseUp();
      return () => {
        window.addEventListener('mouseup', mouseUp);
      };
    }
  });

  useEffect(() => {
    if (!hasTouch) {
      goNext();
    }
  }, [myAnswer]);

  return (
    <div className="mt-[40px]">
      {textList.map((text, index) => (
        <motion.div
          animate={{ scale: isPressed && clickedId === index ? 0.95 : 1 }}
          transition={{ duration: 0.2 }}
          className={`text-left px-20px py-12px w-full rounded-[12px] text-[17px] leading-[26px] ${
            index !== textList.length - 1 && 'mb-10px'
          }  text-[#26282C] font-normal
          ${myAnswer === text && 'font-semibold text-[#3953D9] bg-[#F5F6FD]'}}

          `}
          style={{
            border: myAnswer === text ? '1px solid #3953D9' : '1px solid #D1D5DC',
          }}
          onMouseDown={(e) => {
            if (!hasTouch) {
              setClickedId(index);
              setIsPressed(true);
              setSelect(e.currentTarget.textContent);
            }
          }}
          onTouchStart={(e) => {
            setClickedId(index);
            setIsPressed(true);
          }}
          onTouchEnd={(e) => {
            setIsPressed(false);

            if (
              document
                .elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
                .includes(e.currentTarget)
            ) {
              const updated = context.selectedPage;
              updated[pageIndex ? pageIndex : 0] = index;
              context.setSelectedPage(updated);
              console.log(updated);
              setMyAnswer(e.currentTarget.textContent);
              goNext();
            }
          }}
        >
          <p className="mb-[2px]">{text.replace('\n', <br />)}</p>
        </motion.div>
      ))}
    </div>
  );
}
