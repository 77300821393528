import React from 'react';
import selfCheckData from '../../data/selfCheckData';
import { ifSuperHealthy } from './selfCheckLogic';
import { getSelfCheckState } from './selfCheckLogic';
import { PressedEffect } from '../../stories/button/PressedEffect';

export default function HealthyCard(props) {
  const { selfCheckInfo, scrollToResult } = props;

  return (
    <PressedEffect
      element={
        <div
          className="w-full rounded-[16px] bg-white flex items-center  p-[15px] pr-[20px]"
          // onClick={() => {
          //   scrollToResult(selfCheckInfo.selfCheckId);
          // }}
        >
          <div
            className="shrink-0 w-[60px] h-[60px] rounded-[8px]  mr-[15px] overflow-hidden flex items-center justify-center"
            style={{
              backgroundColor: ifSuperHealthy(selfCheckInfo.selfCheckId, selfCheckInfo.score)
                ? selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).tag.superior.bg
                : selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).tag.normal.bg,
            }}
          >
            <div className="text-[38px] leading-[48px] font-bold">
              {ifSuperHealthy(selfCheckInfo.selfCheckId, selfCheckInfo.score)
                ? selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).tag.superior.image
                : selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).tag.normal.image}
            </div>
          </div>
          <div className="w-full">
            <p className="text-[17px] leading-[23px] font-bold text-[#26282C] mb-[3px]">
              {ifSuperHealthy(selfCheckInfo.selfCheckId, selfCheckInfo.score)
                ? selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).tag.superior.text
                : selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).tag.normal.text}
            </p>
            <div className="w-full flex justify-between items-center">
              <div className="text-[15px] leading-[21px] ">
                <span className="font-bold text-[#6B6D76] mr-[5px]">{selfCheckInfo.selfCheckName}</span>
                <span className="font-normal text-[#2C4BEC]">
                  {
                    selfCheckData.find((element) => element.selfCheckId === selfCheckInfo.selfCheckId).result[
                      getSelfCheckState(selfCheckInfo.selfCheckId, selfCheckInfo.score)
                    ].label
                  }
                </span>
              </div>
              {ifSuperHealthy(selfCheckInfo.selfCheckId, selfCheckInfo.score) && (
                <div className="shrink-0 w-[54px]  bg-qna rounded-[10px] text-center ">
                  <p className="text-[12px] leading-[18px] font-bold text-white whitespace-nowrap"> 상위 1%</p>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      disable={false}
      action={() => {
        scrollToResult(selfCheckInfo.selfCheckId);
      }}
    />
  );
}
