/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SelfCheckTemplate from '../../../components/selfCheck/SelfCheckTemplate';
import useNavigateWithParams from '../../../hooks/useNavigateWithParams';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import headerAtom, { BUTTON_BACK } from '../../../recoil/common/header/atom';
import progressBarAtom from '../../../recoil/common/progressBar/atom';
import deviceAtom from '../../../recoil/common/device';
import selfCheckAtom from '../../../recoil/common/selfCheck/atom';
import useMixpanelWeb from '../../../hooks/useMixpanelWeb';

export default function LoomyQA(props) {
  const { qcList, choices } = props;

  const setHeaderState = useSetRecoilState(headerAtom);
  const deviceState = useRecoilValue(deviceAtom);
  const setProgressBarState = useSetRecoilState(progressBarAtom);
  const [selfCheckState, setSelfCheckState] = useRecoilState(selfCheckAtom);

  let { id } = useParams();
  id = parseInt(id);

  const [answer, setAnswer] = useState('');
  const [disable, setDisable] = useState(false);

  const setMixpanel = useMixpanelWeb({
    eventName: `(LT) 검사 시작`,
    projectName: 'loomy',
  });

  const goNextQuestion = useNavigateWithParams(`/loomyTest/${parseInt(id) + 1}`);
  const goEndTest = useNavigateWithParams(`/loomyTest/end`);

  useEffect(() => {
    setHeaderState((state) => ({ ...state, headerColor: '#26282C' }));
    if (!qcList[id - 1]) {
      console.log(qcList);
    }
  }, []);

  useEffect(() => {
    if (
      selfCheckState.selfCheckAnswer.find(
        (element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId,
      )
    ) {
      const selected = selfCheckState.selfCheckAnswer.find(
        (element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId,
      );
      console.log(selected.selfCheckChoiceId);
      setAnswer(selected.selfCheckChoiceId);
    }
  }, []);

  function select(option) {
    setAnswer(option.selfCheckChoiceId);
    var arr1 = selfCheckState.selfCheckAnswer.slice();

    if (arr1.find((element) => element.selfCheckQuestionId === qcList[id - 1].selfCheckQuestionId)) {
      arr1 = arr1.filter((element) => element.selfCheckQuestionId !== qcList[id - 1].selfCheckQuestionId);
    }

    arr1.push({
      selfCheckQuestionId: qcList[id - 1].selfCheckQuestionId,
      selfCheckChoiceId: option.selfCheckChoiceId,
      score: option.score,
    });

    console.log('answers: ', arr1);
    setSelfCheckState((state) => ({ ...state, selfCheckAnswer: arr1 }));

    setTimeout(() => {
      if (id < qcList.length) {
        // console.log('다음 문항으로');
        goNextQuestion();
      } else if (id === qcList.length) {
        goEndTest();
      }
    }, 400);
  }

  function isClicked(option) {
    if (option !== '') {
      setDisable(true);
      select(option);
    }
  }

  useEffect(() => {
    document.body.style.backgroundColor = '#F3F0E7';
    setProgressBarState({
      isProgressBarVisible: true,
      maxCompleted: qcList.length,
      completed: parseInt(id),
      paddingColor: '#F3F0E7',
      baseColor: '#D9D8B7',
      color: '#11383B',
    });
    setHeaderState((state) => ({ ...state, headerButtonType: BUTTON_BACK, headerTitle: '' }));
    if (id === 1) {
      setMixpanel();
    }
  }, []);

  return (
    <div
      className={`w-screen bg-[#F3F0E7] `}
      style={{ height: window.innerHeight - (deviceState.notchSize + 44 + 17) }}
    >
      {qcList[id - 1] && (
        <SelfCheckTemplate
          question={qcList[id - 1].question}
          optionList={choices}
          answer={answer}
          isClicked={isClicked}
          disable={disable}
          loomy
        />
      )}
    </div>
  );
}
