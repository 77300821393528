import loomy from '../../image/loomy/loomy.png';
import session1_2 from '../../image/loomy/session/session1_2.png';
import session1_3 from '../../image/loomy/session/session1_3.png';
import session1_4 from '../../image/loomy/session/session1_4.png';
import session1_5 from '../../image/loomy/session/session1_5.png';
import session1_6 from '../../image/loomy/session/session1_6.png';
import session1_7 from '../../image/loomy/session/session1_7.png';
import session1_8 from '../../image/loomy/session/session1_8.png';

export const loomySessionImage = [
  loomy,
  session1_2,
  session1_3,
  session1_4,
  session1_5,
  session1_6,
  session1_7,
  session1_8,
];
