import React, { useState, useEffect, useRef, useContext } from 'react';
import { changeHeader, close } from '../../utils/webview';
import { SecondaryActionButton } from '../../stories/button/ActionButton';
import { motion } from 'framer-motion';
import lottie from 'lottie-web';

import check_animation from '../../image/check_animation.png';
import { CoachingContext } from '../../coachingContext';

import useNavigateWithParams from '../../hooks/useNavigateWithParams';
import useFetch from '../../hooks/useFetch';

export default function CourseDone() {
  const context = useContext(CoachingContext);

  const container = useRef();
  const contentRef = useRef();
  const [recommendedCourse, setRecommendedCourse] = useState([]);

  // const example = [
  //   {
  //     courseId: 'course-1',
  //     description:
  //       '내 생각은 어떻게 만들어졌을까요? 과거의 일들을 따라가다 보면 내 감정을 더 잘 이해할 수 있을 거예요.내 생각은 어떻게 만들어졌을까요? 과거의 일들을 따라가다 보면 내 감정을 더 잘 이해할 수 있을 거예요',
  //     name: '생각의 발자취 따라가기',
  //   },
  //   {
  //     courseId: 'course-2',
  //     description: '내 생각은 어떻게 만들어졌을까요? 과거의 일들을 따라가다 보면 내 감정을 더 잘 이해할 수 있을 거예요',
  //     name: '생각의 발자취 따라가기',
  //   },
  //   {
  //     courseId: 'course-3',
  //     description: '내 생각은 어떻게 만들어졌을까요? 과거의 일들을 따라가다 보면 내 감정을 더 잘 이해할 수 있을 거예요',
  //     name: '생각의 발자취 따라가기',
  //   },
  // ];

  const [recommendedCourseResult, getRecommendedCourse] = useFetch({
    url: `/course/recommendation/list`,
  });
  useEffect(() => {
    if (recommendedCourseResult) {
      console.log(recommendedCourseResult);
      setRecommendedCourse(recommendedCourseResult.result.courseList);
      context.setRecommendedCourse(recommendedCourseResult.result.courseList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedCourseResult]);

  useEffect(() => {
    document.title = '코스 완료 축하';
    changeHeader({ backgroundColor: '#2C4BEC', text: '', buttonType: 'none' });

    getRecommendedCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../image/toolopen.json'),
    });
    return () => instance.destroy();
  }, []);

  const goNextPage = useNavigateWithParams(`/recommendedCourse`);

  function goRecommendedCourse() {
    if (recommendedCourse.length === 0) {
      close({ refresh: true });
    } else {
      goNextPage();
    }
  }

  return (
    <div
      className={`h-screen bg-gradientA w-screen overflow-hidden
    `}
    >
      <div
        className={`${
          context.biggerMargin ? 'h-[calc(100%-88px)]' : 'h-[calc(100%-74px)]'
        } relative flex flex-col items-center justify-center`}
      >
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeIn' }}
          viewport={{ once: true }}
          ref={contentRef}
          className="w-full relative z-[50]"
        >
          <div className=" w-fit absolute top-[-100px]  left-[50%] translate-x-[-50%]">
            <div className="" ref={container} />
          </div>
          <div className={`w-full`}>
            <img src={check_animation} alt={'empty'} />
          </div>
          <div className="pt-[6px] px-[20px]  text-white" style={{ textAlign: 'center' }}>
            <p className="text-[24px] leading-[30px] font-bold mb-[8px]">코스를 마쳤어요</p>
            <p className="text-[17px] leading-[23px]  font-normal opacity-[0.7]">
              마친 활동은 언제든 다시 할 수 있어요.
            </p>
          </div>
        </motion.div>
      </div>
      <div className={`px-20px ${context.biggerMargin ? 'h-[88px]' : 'h-[74px]'} w-full rounded-[16px]`}>
        <SecondaryActionButton state={'ACTIVE'} text={'확인'} action={goRecommendedCourse} />
      </div>
    </div>
  );
}
